import { types } from 'redux/actions/marketview-data'

export const initialState = {
  loading: false,
  data: undefined,
  error: undefined,
}

export function reducer(state = initialState, action) {
  const { payload, error, type } = action

  switch (type) {
    case types.REQUEST_MARKETVIEW_DATA: {
      return {
        ...state,
        loading: true,
        error: {
          ...state.error,
          [payload.key]: undefined,
        },
      }
    }

    case types.POST_MARKETVIEW_DATA: {
      return {
        ...state,
        loading: true,
        error: {
          ...state.error,
          [payload.key]: undefined,
        },
      }
    }

    case types.RECEIVE_MARKETVIEW_DATA: {
      // When an error occurs this is unfortunately return as a HTTP 200
      // end therefore it ends up here as a succesfull response. But
      // if it contains a 'message' field with a type 'Error' it is
      // actually an error so we code for that here
      if (payload.data.message && payload.data.message.type === 'Error') {
        return {
          ...state,
          loading: false,
          data: {
            ...state.data,
            [payload.key]: undefined,
          },
          error: {
            ...state.error,
            [payload.key]: payload.data.message.description,
          },
        }
      }

      const { responseData } = payload.data
      const data = responseData.length > 1 ? responseData : responseData[0]

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.key]: data,
        },
        error: {
          ...state.error,
          [payload.key]: undefined,
        },
      }
    }

    case types.FAIL_MARKETVIEW_DATA_REQUEST: {
      return {
        ...state,
        loading: false,
        error,
      }
    }

    default:
      return state
  }
}
