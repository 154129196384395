import { connect } from 'react-redux'
import { resetPassword } from 'redux/actions/auth'
import ResetPassword from '../views/reset-password'

const mapStateToProps = ({ auth }) => ({ auth })

const mapDispatchToProps = {
  resetPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
