/*
 * Due to the filters in the stock view not having a way
 * to submit but are designed to be submitted whenever they
 * changed this filter would call the update handler on
 * on every keystroke. To overcome this local state of the
 * filter is being administrated and the update to the
 * actual filter is being debounced.
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import styled from 'styled-components'
import FilterCollapser from 'components/molecules/filter-collapser'
import SearchInput from 'components/molecules/search-input'

const debouncedUpdate = debounce((onFilterUpdate, id, value) => {
  onFilterUpdate(id, value)
}, 1000)

const InputContainer = styled.div`
  margin: ${({ theme }) => theme.sizings.lvl2} 0;
`

function PriceFilter({ initallyOpen, name, onFilterUpdate, title, value }) {
  const [firstRender, setFirstRender] = useState(true)
  const [localValue, setLocalValue] = useState(value)

  function handleUpdate(changedValue) {
    setLocalValue(changedValue)
  }

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
    }
  }, [firstRender, setFirstRender])

  useEffect(() => {
    if (firstRender) {
      return
    }
    debouncedUpdate(onFilterUpdate, name, localValue)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue])

  // Whenver value is reset to an empty string set localValue to it
  useEffect(() => {
    if (value === '' && localValue !== '') {
      setLocalValue(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <FilterCollapser initallyOpen={initallyOpen} title={title}>
      <InputContainer>
        <SearchInput
          name={name}
          onChange={handleUpdate}
          value={localValue}
          onSubmit={() => onFilterUpdate(name, localValue)}
        />
      </InputContainer>
    </FilterCollapser>
  )
}

PriceFilter.propTypes = {
  initallyOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  value: PropTypes.string,
  title: PropTypes.string.isRequired,
}

PriceFilter.defaultProps = {
  initallyOpen: null,
  value: '',
}

export default PriceFilter
