import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { scrollTo } from 'utilities/utils'
import { media } from 'utilities/styled'

import EnhancedCard from 'components/layouts/enhanced-card'
import Typography from 'components/molecules/typography'
import LoadingIndicator from 'components/atoms/loading-indicator'
import PageHeader from 'components/organisms/page-header'

import CarBpmDeclarationStep1Form from './car-bpm-declaration-step-1-form'
import CarBpmDeclarationStep1Table from './car-bpm-declaration-step-1-table'

const Title = styled(Typography)`
  font-size: 20px;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
  ${media.tablet`
    margin-bottom:  ${({ theme }) => theme.sizings.lvl3};
  `}
`

const Container = styled.div`
  background: ${({ theme }) => theme.colors.brandFoxtrot};
  padding: 0 ${({ theme }) => theme.sizings.lvl3};
  ${media.desktop`
    padding: 0 60px;
  `};
`

const ContentContainer = styled(EnhancedCard)`
  overflow: visible;
  margin: 0 -${({ theme }) => theme.sizings.lvl3} ${({ theme }) =>
      theme.sizings.lvl3} -${({ theme }) => theme.sizings.lvl3};
  ${media.tablet`
    margin: 0 0 ${({ theme }) => theme.sizings.lvl3} 0;
  `};
`

const CarBpmDeclarationStep1 = () => {
  const { t } = useTranslation()
  const extraFilters = useRef()
  const bpmDeclarationForm = useSelector((state) => state.form.bpmDeclaration)
  const editionsForBpmDeclaration = useSelector(
    (state) => state.data.editionsForBpmDeclaration,
  )
  const editionsForBpmDeclarationData = editionsForBpmDeclaration?.data

  useEffect(() => {
    if (editionsForBpmDeclarationData && extraFilters.current) {
      scrollTo(extraFilters.current)
    }
  }, [editionsForBpmDeclarationData])

  return (
    <Container>
      <PageHeader mainTitle={t('car')} status={t('bpmDeclaration')} />

      <ContentContainer>
        <Title type="Level1Heading">{t('findYourCar')}</Title>
        <CarBpmDeclarationStep1Form />
      </ContentContainer>

      <div ref={extraFilters}>
        {editionsForBpmDeclaration?.loading && (
          <LoadingIndicator height="100px" />
        )}
        {!editionsForBpmDeclaration?.loading &&
          bpmDeclarationForm?.submitSucceeded && (
            <ContentContainer>
              <CarBpmDeclarationStep1Table />
            </ContentContainer>
          )}
      </div>
    </Container>
  )
}

export default CarBpmDeclarationStep1
