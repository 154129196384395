import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import TextLink from 'components/atoms/text-link'
import Text from 'components/atoms/text'

const Container = styled.div`
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Item = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
`

const StyledText = styled(Text)`
  text-transform: uppercase;
`

const StyledTextLink = styled(TextLink)`
  text-transform: uppercase;
`

const Seperator = styled(Text)`
  margin: 0 ${({ theme }) => theme.sizings.lvl1};
`

/**
 * Segments a workflow into steps to make it easier to go through it. This reduces
 * [cognitive load](https://uxplanet.org/8-ways-to-reduce-cognitive-load-part-2-4b0f9d8ef5ad)
 * and speeds up the process.
 *

 */
function FlowStepper({ className, items, onChange, coloredSteps }) {
  const containerRef = useRef()
  const stepItemsRefs = useRef([])
  const activeIndex = items.findIndex((item) => item.active)
  const activeKey = items.find((item) => item.active)?.key
  const history = useHistory()

  useEffect(() => {
    const activeOrCompletedRefs = stepItemsRefs?.current?.filter(
      (item) =>
        typeof item?.dataset === 'object' && 'completed' in item?.dataset,
    )
    const offset = activeOrCompletedRefs?.reduce(
      (previousOffsetWidth, currentRef) => {
        return previousOffsetWidth + currentRef.offsetWidth
      },
      0,
    )

    containerRef.current.scrollTo({
      left: offset,
      behavior: 'smooth',
    })
  }, [containerRef, stepItemsRefs, activeIndex, activeKey])

  const handleChange = async (newIndex) => {
    if (onChange && newIndex !== undefined) {
      return onChange(newIndex, activeIndex, 'flow-stepper', activeKey)
    }
    return true
  }

  return (
    <Container className={className} ref={containerRef}>
      {items.map(({ active, disabled, label, link }, index) => {
        const numberedLabel = `${index + 1}. ${label}`
        const completed = index < activeIndex

        return (
          <Item
            key={index.toString()}
            ref={(ref) => {
              stepItemsRefs.current[index] = ref
            }}
            data-completed={completed || undefined}
          >
            {active || disabled ? (
              <StyledText
                color={
                  active
                    ? 'actionsCta'
                    : completed
                      ? 'grassyGreen'
                      : 'inactiveTextColor'
                }
                text={numberedLabel}
                weight={400}
              />
            ) : (
              <StyledTextLink
                weight={400}
                color={coloredSteps && completed ? 'statusComplete' : undefined}
                text={numberedLabel}
                // to={link}
                onClick={async () => {
                  await handleChange(index)
                  history.push(link)
                }}
              />
            )}
            {index < items.length - 1 && (
              <Seperator text=">" color="brandCharlie" />
            )}
          </Item>
        )
      })}
    </Container>
  )
}

FlowStepper.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      id: PropTypes.string,
      completed: PropTypes.bool,
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
    }),
  ).isRequired,
  coloredSteps: PropTypes.bool,
}

FlowStepper.defaultProps = {
  onChange: undefined,
  className: null,
  coloredSteps: false,
}

export default FlowStepper
