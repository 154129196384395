import React from 'react'

// import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client'
import { GET_DEALERS_WITH_CSP } from 'config/graphql/csp'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import LoadingIndicator from 'components/atoms/loading-indicator'
import ExpandableBars from 'components/layouts/expandable-bars'
import SummaryLine from 'components/molecules/summary-line'
import Typography from 'components/molecules/typography'
import DealerNoCSPMessage from 'components/organisms/dealer-no-csp-message'
import GeneralDataError from 'components/organisms/general-data-error'

import DealerSiteWidget from './dealer-specific/dealer-site-widget'

function CarServicePlanDealerSiteWidget() {
  const { loading, error, data } = useQuery(GET_DEALERS_WITH_CSP)
  const { t } = useTranslation()
  const { pathname } = useLocation()

  if (loading) {
    return <LoadingIndicator error={null} />
  }
  if (error) {
    console.error(error)
    return (
      <>
        <Typography type="Level1Heading">
          {t('carServicePlanDealerSiteWidgetTab.heading')}
        </Typography>
        <GeneralDataError />
      </>
    )
  }

  const trailingPathPattern = /edit-car-services\/(\d+)\/$/
  const matches = pathname.match(trailingPathPattern)
  const initialExpandedRowId =
    Array.isArray(matches) && matches.length > 0 ? matches[1] : null

  return data.dealers &&
    Array.isArray(data.dealers.edges) &&
    data.dealers.edges.length ? (
    <>
      <Typography type="Level1Heading">
        {t('carServicePlanDealerSiteWidgetTab.heading')}
      </Typography>
      <Typography type="ExplanationParagraph">
        {t('carServicePlanDealerSiteWidgetTab.explanation')}
      </Typography>
      <ExpandableBars
        initialExpandedRowId={initialExpandedRowId}
        rows={data.dealers.edges.map((edge) => ({
          id: edge.node.id,
          header: (
            <SummaryLine
              name={edge.node.name}
              details={[
                { label: t('clientNumber'), value: edge.node.uccId },
                { value: `${edge.node.address} ${edge.node.city}` },
              ]}
            />
          ),
          details: edge.node.carServicePlan ? (
            <DealerSiteWidget dealerId={edge.node.id} />
          ) : (
            <DealerNoCSPMessage />
          ),
        }))}
      />
    </>
  ) : (
    <Typography type="ExplanationParagraph">{t('noDealersFound')}</Typography>
  )
}

// CarServicePlanDealerSiteWidget.propTypes = {};

export default CarServicePlanDealerSiteWidget
