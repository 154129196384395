import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import FramedImage from 'components/atoms/framed-image'
import LicensePlate from 'components/atoms/license-plate'
import { useTranslation } from 'react-i18next'

const Container = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 85px;
  height: 60px;
  justify-content: flex-end;
  overflow: hidden;

  > * {
    opacity: 0.9;
  }
`

const PostionedImage = styled(FramedImage)`
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const PositionedLicensePlate = styled(LicensePlate)`
  margin: 2px;
`

const MinimalCarInfo = ({ image, licensePlate, imageAlt }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <PostionedImage
        src={image}
        errorMessage={t('noCarPhotosAvailable')}
        alt={imageAlt}
      />
      <PositionedLicensePlate size="tiny" value={licensePlate} />
    </Container>
  )
}

MinimalCarInfo.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string.isRequired,
  licensePlate: PropTypes.string.isRequired,
}

MinimalCarInfo.defaultProps = {
  image: null,
}

export default MinimalCarInfo
