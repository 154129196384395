import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import languageUtilities from 'utilities/language'

import { Languages } from 'config/enums'

import Icon from 'components/atoms/icon'
import NewDropdownSelect from 'components/molecules/new-dropdown-select'

const StyledNewDropdownSelect = styled(NewDropdownSelect)`
  max-width: 58px;

  label + .MuiInput-formControl {
    margin-top: 0;
  }

  [aria-haspopup='listbox'] span span:last-of-type {
    display: none;
  }

  [aria-haspopup='listbox'][aria-expanded='true'] span span:last-of-type {
    display: inline;
  }

  [aria-haspopup='listbox'] span span:first-of-type {
    margin-left: ${({ theme }) => theme.sizings.lvl0};
  }
`

const StyledLabel = styled.span`
  display: flex;
  padding: ${({ theme }) => theme.sizings.lvl0};
`

const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.sizings.lvl1};
`

const StyledSpan = styled.span`
  font-family: ${({ theme }) => theme.font};
`

const LanguagePicker = () => {
  const { t } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState(
    languageUtilities.getLanguage(),
  )

  const changeCurrentLanguage = (newLanguage) => {
    languageUtilities.setLanguage(newLanguage)
    setCurrentLanguage(newLanguage)
  }

  const items = Object.values(Languages).map((languageCode) => ({
    label: (
      <StyledLabel>
        <StyledIcon type={`flag-${languageCode}`} />
        <StyledSpan>{t(`languagesPicker.${languageCode}`)}</StyledSpan>
      </StyledLabel>
    ),
    value: languageCode,
  }))

  return (
    <StyledNewDropdownSelect
      items={items}
      value={currentLanguage}
      onChange={changeCurrentLanguage}
      selectionRequired
    />
  )
}

export default LanguagePicker
