import React from 'react'
import PropTypes from 'prop-types'
import Typography from 'components/molecules/typography'
import { useTranslation } from 'react-i18next'

const GeneralDataError = ({ error }) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography type="Level2Heading">{t('problemsFoundHeading')}</Typography>

      {error ? (
        <>
          <Typography type="ExplanationParagraph">
            {t('problemsWhenRetrievingDataApiError')}
          </Typography>
          <Typography type="ExplanationParagraph">
            &quot;{error}&quot;
          </Typography>
        </>
      ) : (
        <Typography type="ExplanationParagraph">
          {' '}
          {t('problemsWhenRetrievingData')}
        </Typography>
      )}
    </>
  )
}

GeneralDataError.propTypes = {
  error: PropTypes.string,
}

GeneralDataError.defaultProps = {
  error: undefined,
}

export default GeneralDataError
