import Button from 'components/atoms/button'
import Label from 'components/atoms/label'
import LoadingButton from 'components/atoms/loading-button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import NewTextInput from 'components/atoms/new-text-input'
import DocumentContainer from 'components/molecules/document-container'
import { FormikTextInput } from 'components/molecules/formik-field'
import SelectFileButton from 'components/molecules/select-file-button'
import Typography from 'components/molecules/typography'
import { RdwCertificateForm as ValidationSchema } from 'config/validation-schemas'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getRdwCertificate, uploadRdwCertificate } from 'redux/actions/settings'
import styled from 'styled-components/macro'
import { formatDate } from 'utilities/format'
import { media } from 'utilities/styled'
import toast from 'utilities/toast'
import { createFormData } from 'utilities/utils'

const CertificateForm = styled(Form)`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.sizings.lvl2};
  align-items: flex-start;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.sizings.lvl2};
  align-items: flex-start;
`

const StatusText = styled(Typography)`
  margin-top: 0;
  margin-bottom: 0;
`

const CertificateDocument = styled(DocumentContainer)`
  max-width: ${({ theme }) => theme.sizeByFactor(50)};
  > p {
    white-space: pre-wrap;
  }
  // width: 100%;
`

const SelectCertificateButton = styled(SelectFileButton)`
  display: inline-block;
`

const SelectFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl2};
  align-items: flex-start;

  ${media.tablet`
    flex-direction: row;
    align-items: center;
    min-height: ${({ theme }) => theme.sizeByFactor(8)};
  `}
`

const StyledField = styled(Field)`
  width: 100%;
  max-width: ${({ theme }) => theme.sizeByFactor(50)};
`

const ReplaceButton = styled(Button)`
  margin-top: ${({ theme }) => theme.sizings.lvl4};
`

const SubmitButton = styled(LoadingButton)`
  margin-top: ${({ theme }) => theme.sizings.lvl4};
`

const LocationRDWCertificate = ({ locationId, ...restProps }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const certificate = useSelector((state) => state.settings.rdwCertificate)
  const [replaceCertificate, setReplaceCertificate] = React.useState(false)
  // const isInitialized = React.useRef(false)
  const hasCertificate = React.useMemo(() => {
    return (
      certificate &&
      certificate[locationId] &&
      !!certificate[locationId].data?.filename &&
      !replaceCertificate
    )
  }, [locationId, certificate, replaceCertificate])

  React.useEffect(() => {
    dispatch(getRdwCertificate({ vestiging_id: locationId }))
  }, [dispatch, locationId])

  // React.useEffect(() => {
  //   if (
  //     certificate &&
  //     certificate[locationId] &&
  //     certificate[locationId].data?.filename &&
  //     !isInitialized.current
  //   ) {
  //     setHasCertificate(!!certificate[locationId].data.filename)
  //     isInitialized.current = true
  //   }
  // }, [certificate, locationId])

  if (
    !certificate ||
    !certificate[locationId] ||
    certificate[locationId].loading ||
    certificate[locationId].error
  ) {
    return <LoadingIndicator error={certificate?.error} />
  }

  const {
    testsuccess: certificateIsValid,
    filename,
    // created,
  } = certificate[locationId].data

  const handleFormSubmit = (
    { certificate, ...restValues },
    { setSubmitting },
  ) => {
    setSubmitting(true)
    const file = certificate.get('file')
    const { name } = file
    const data = createFormData(restValues)
    data.append('id', locationId)
    data.append('file', file, name)

    try {
      dispatch(uploadRdwCertificate(data))
        .then(() => {
          setSubmitting(false)
          setReplaceCertificate(false)
          dispatch(getRdwCertificate({ vestiging_id: locationId }))
          toast.success(
            data.message || t('rdwCertificateSettings.uploadSuccess'),
          )
        })
        .finally(() => {
          setSubmitting(false)
        })
    } catch (e) {
      console.error(e)
      setSubmitting(false)
    }
  }

  const labelText = t(
    certificateIsValid
      ? 'rdwCertificateSettings.certificateValid'
      : 'rdwCertificateSettings.certificateInvalid',
  )
  const labelColor = certificateIsValid ? 'statusOk' : 'statusErrorLvl1'

  return (
    <div {...restProps}>
      {hasCertificate ? (
        <Container>
          {filename && (
            <StatusText type="BodyParagraph">
              {`${t('rdwCertificateSettings.certificateStatus')}: `}
              <Label text={labelText} backgroundColor={labelColor} />
            </StatusText>
          )}
          <CertificateDocument
            uri={certificate[locationId].data.filename}
            title={t('rdwCertificateSettings.certificateInfo', {
              title: certificate[locationId].data.filename,
              date: formatDate(
                certificate[locationId].data.created,
                'YYYY-MM-DD HH:mm',
                'DD-MM-YYYY',
              ),
              time: formatDate(
                certificate[locationId].data.created,
                'YYYY-MM-DD HH:mm',
                'HH:mm',
              ),
            })}
          />
          <ReplaceButton
            onClick={() => {
              setReplaceCertificate(true)
            }}
          >
            {t('rdwCertificateSettings.replaceCertificate')}
          </ReplaceButton>
        </Container>
      ) : (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={{
            certificate: undefined,
            password: '',
          }}
          validationSchema={ValidationSchema(t)}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <CertificateForm>
              <SelectFileContainer>
                <SelectCertificateButton
                  id="certificate"
                  allowedFileExtensions={['.pfx', '.p12']}
                  text={t('rdwCertificateSettings.chooseCertificate')}
                  onChange={(formData) => {
                    setFieldValue('certificate', formData, false)
                  }}
                />
                {values.certificate && (
                  <NewTextInput
                    disabled
                    value={values.certificate.get('file').name}
                    label={t('rdwCertificateSettings.chosenCertificate')}
                  />
                )}
              </SelectFileContainer>
              <StyledField
                name="password"
                label={t('rdwCertificateSettings.password')}
                component={FormikTextInput}
                disabled={isSubmitting}
                type="password"
                filled
                required
              />
              <SubmitButton
                isLoading={isSubmitting}
                type="submit"
                disabled={!values.certificate}
                level="cta"
              >
                {t('rdwCertificateSettings.uploadCertificate')}
              </SubmitButton>
            </CertificateForm>
          )}
        </Formik>
      )}
    </div>
  )
}

LocationRDWCertificate.propTypes = {
  locationId: PropTypes.string.isRequired,
}

export default LocationRDWCertificate
