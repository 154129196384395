import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/client'
import moment from 'moment'

import Button from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import NewTextInput from 'components/atoms/new-text-input'
import Typography from 'components/molecules/typography'
import EnhancedTable, {
  TableDataText,
} from 'components/organisms/enhanced-table'
import PageHeader from 'components/organisms/page-header'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'

import {
  GET_CAR_MODEL_TYPES,
  GET_DEALERS_WITH_CSP,
  GET_PROSPECTS,
} from 'config/graphql/csp'
import { CSP_SALES_FLOW } from 'config/routes'

import { DateFormats, formatDate } from 'utilities/format'

import StartServiceCalculationWithoutLicensePlate, {
  urlParamKeys,
} from './start-service-calculation-forms/without-license-plate'

const Card = styled.section`
  background-color: ${({ theme }) => theme.colors.sectionBackground};
  display: flex;
  padding: ${({ theme }) => theme.sizings.lvl3};
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl4};
`

const ResultsSection = styled.section`
  display: flex;
  gap: ${({ theme }) => theme.sizings.lvl4};
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
  padding-top: ${({ theme }) => theme.sizings.lvl2};
`
const ResultsHeader = styled.header`
  display: flex;
  gap: 0;
  flex-direction: column;
  > * {
    margin: 0;
  }
`

const FilterSection = styled.section`
  display: flex;
  gap: 0;
  flex-direction: column;
`

const FiltersHeader = styled.header`
  display: flex;
  gap: 0;
  align-content: space-between;
`

const FiltersContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.sizings.lvl2};
  > * {
    flex: 1 1 auto;
  }
`

const RangeFilterLayout = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.sizings.lvl1};
  flex-wrap: nowrap;
  align-items: center;
  > * {
    flex: 0 1 auto;
  }
`

const Table = ({ rows }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const columns = [
    {
      id: 'type',
      orderId: 'type',
      label: t('type'),
    },
    {
      id: 'fuelType',
      orderId: 'fuelType',
      label: t('fuel'),
    },
    {
      id: 'engineCapacity',
      orderId: 'engineCapacity',
      label: t('cspNoLicensePlateView.results.headings.engineCapacity'),
    },
    {
      id: 'kilowatt',
      orderId: 'kilowatt',
      label: t('kw'),
    },
    {
      id: 'from',
      orderId: 'from',
      label: t(
        'cspNoLicensePlateView.results.headings.carProductionPeriodFrom',
      ),
    },
    {
      id: 'till',
      orderId: 'till',
      label: t(
        'cspNoLicensePlateView.results.headings.carProductionPeriodTill',
      ),
    },
  ]

  const mappedRows = rows.map((row) => ({
    type: {
      data: row.name,
      component: (
        <TableDataText>
          {row.name}
          <br />
          <Button
            noPadding
            level="option"
            onClick={() => goToSalesFlow(row.tcoId)}
          >
            {t('select')}
          </Button>
        </TableDataText>
      ),
    },
    fuelType: {
      data: row.fuelType,
      component: `${row.fuelType}${row?.fuelTypeSecondary ? `/ ${row.fuelTypeSecondary}` : ''}`,
    },
    engineCapacity: {
      data: row.engineCapacity,
      component: row.engineCapacity,
    },
    kilowatt: {
      data: String(row.kilowatt),
      component: row.kilowatt,
    },
    from: {
      data: String(moment(row.from, 'YYYY-MM-DD').unix()),
      component: formatDate(row.from, 'YYYY-MM-DD', DateFormats.YEAR_MONTH),
    },
    till: {
      data: String(moment(row.till, 'YYYY-MM-DD').unix()),
      component: formatDate(row.till, 'YYYY-MM-DD', DateFormats.YEAR_MONTH),
    },
  }))

  const sortRows = (rows, direction, by) => {
    const sortedRows = rows.sort((a, b) => {
      if (typeof a[by].data === 'string' && typeof b[by].data === 'string') {
        const smallerA = a[by].data
          .toUpperCase()
          .localeCompare(b[by].data.toUpperCase())
        if (direction === 'asc') {
          return smallerA ? -1 : 1
        }
        if (direction === 'desc') {
          return smallerA ? 1 : -1
        }
      }
      return 0
    })
    return sortedRows
  }

  const [orderDirection, setOrderDirection] = useState('asc')
  const [orderBy, setOrderBy] = useState('type')

  const sortedRows = useMemo(
    () => sortRows(mappedRows, orderDirection, orderBy),
    [mappedRows, orderDirection, orderBy],
  )

  const handleSort = (direction, by) => {
    setOrderDirection(direction)
    setOrderBy(by)
  }

  const goToSalesFlow = (tcoId) => {
    const params = new URLSearchParams(location.search)
    params.set('tcoId', tcoId)
    history.push(`${CSP_SALES_FLOW}/0?${params.toString()}`)
  }

  return (
    <EnhancedTable
      selectable={false}
      onOrder={handleSort}
      orderBy={orderBy}
      orderDirection={orderDirection}
      columns={columns}
      rows={sortedRows}
    />
  )
}

Table.propTypes = {
  rows: PropTypes.array.isRequired,
}

const CspNoLicensePlateView = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const paramsValues = {}
  let allParamValuesPrefilled = true
  for (const key in urlParamKeys) {
    paramsValues[key] = searchParams.get(key) || null
    if (!paramsValues[key]) {
      allParamValuesPrefilled = false
    }
  }

  const [typeFilterValue, setTypeFilterValue] = useState('')
  const [fromCCFilter, setFromCCFilter] = useState('')
  const [toCCFilter, setToCCFilter] = useState('')
  const [fromKWFilter, setFromKWFilter] = useState('')
  const [toKWFilter, setToKWFilter] = useState('')

  const {
    loading: prospectsLoading,
    error: prospectsError,
    data: prospectsData,
  } = useQuery(GET_PROSPECTS)

  const {
    loading: dealersLoading,
    error: dealersError,
    data: dealersData,
  } = useQuery(GET_DEALERS_WITH_CSP)

  const {
    loading: carModelTypesLoading,
    error: carModelTypesError,
    data: carModelTypes,
  } = useQuery(GET_CAR_MODEL_TYPES, {
    variables: {
      brandId: paramsValues.carBrand,
      modelId: paramsValues.carModel,
      fuelType: paramsValues.carFuelType,
      engineCapacityFrom: fromCCFilter ? parseInt(fromCCFilter, 10) : null,
      engineCapacityTo: toCCFilter ? parseInt(toCCFilter, 10) : null,
      kwFrom: fromKWFilter ? parseInt(fromKWFilter, 10) : null,
      kwTo: toKWFilter ? parseInt(toKWFilter, 10) : null,
      registrationDate: `${paramsValues.carBuildYear}-01-01`,
    },
    skip: !allParamValuesPrefilled,
    context: {
      debounceKey: 'csp-no-license-plate', // debounceKey is needed to make the Apollo debounce link work (see /src/config/apollo.js)
      debounceTimeout: 300, // Setting this to 300ms so the user has some time to enter the filtered data
    },
  })

  if (prospectsLoading || dealersLoading) {
    return <LoadingIndicator error={null} size="small" />
  }

  if (prospectsError || dealersError || carModelTypesError) {
    console.error(prospectsError, dealersError, carModelTypesError)
    return (
      <>
        <Typography type="Level2Heading">
          {t('problemsFoundHeading')}
        </Typography>
        <Typography type="ExplanationParagraph">
          {t('problemsWhenRetrievingData')}
        </Typography>
      </>
    )
  }

  const prospectTypes = prospectsData.prospects.map((prospectType) => ({
    label: prospectType.label,
    value: prospectType.key,
  }))

  const dealers = dealersData.dealers.edges.map((edge) => ({
    label: edge.node.name,
    value: edge.node.id,
    disabled: edge.node.carServicePlan.isDisabled,
  }))

  const carTypesData = Array.isArray(carModelTypes?.types)
    ? carModelTypes.types
    : null

  return (
    <AppLayoutContainer pageTitle={t('cspNoLicensePlateView.pageTitle')}>
      <>
        <PageHeader
          mainTitle={t('carServicePlan')}
          status={t('cspNoLicensePlateView.pageHeading')}
          hasBorderBottom={false}
        />
        <Card>
          <StartServiceCalculationWithoutLicensePlate
            prospectTypes={prospectTypes}
            dealers={dealers}
          />
          <ResultsSection>
            <ResultsHeader>
              <Typography type="Level2Heading" styledAs="oldheading">
                {t('cspNoLicensePlateView.selectCarType.title')}
              </Typography>
              {carModelTypesLoading && (
                <Typography type="Level3Heading" styledAs="subheading">
                  {t('loading')}
                </Typography>
              )}
              {!carModelTypesLoading && !!carTypesData?.length && (
                <Typography type="Level3Heading" styledAs="subheading">
                  {t('cspNoLicensePlateView.selectCarType.subtitle', {
                    count: carTypesData.length,
                  })}
                </Typography>
              )}
            </ResultsHeader>

            <FilterSection>
              <FiltersHeader>
                <Typography type="Level2Heading">
                  {t('cspNoLicensePlateView.filterResults.heading')}
                </Typography>
              </FiltersHeader>
              <FiltersContent>
                <NewTextInput
                  label={t('version')}
                  filled
                  value={typeFilterValue}
                  onChange={(value) => setTypeFilterValue(value.toLowerCase())}
                />
                <RangeFilterLayout>
                  <NewTextInput
                    label={t('cspNoLicensePlateView.filterResults.fromCC')}
                    filled
                    type="integer"
                    value={fromCCFilter}
                    onChange={(value) => setFromCCFilter(value)}
                  />
                  –{/* en dash to denote range  */}
                  <NewTextInput
                    label={t('cspNoLicensePlateView.filterResults.toCC')}
                    filled
                    type="integer"
                    value={toCCFilter}
                    onChange={(value) => setToCCFilter(value)}
                  />
                </RangeFilterLayout>
                <RangeFilterLayout>
                  <NewTextInput
                    label={t('cspNoLicensePlateView.filterResults.fromKW')}
                    filled
                    type="integer"
                    value={fromKWFilter}
                    onChange={(value) => setFromKWFilter(value)}
                  />
                  –{/* en dash to denote range  */}
                  <NewTextInput
                    label={t('cspNoLicensePlateView.filterResults.toKW')}
                    filled
                    type="integer"
                    value={toKWFilter}
                    onChange={(value) => setToKWFilter(value)}
                  />
                </RangeFilterLayout>
              </FiltersContent>
            </FilterSection>

            {Array.isArray(carTypesData) && !carModelTypesLoading && (
              <Table
                rows={carTypesData.filter((row) =>
                  typeFilterValue
                    ? row.name.toLowerCase().includes(typeFilterValue)
                    : true,
                )}
              />
            )}

            {carModelTypesLoading && (
              <LoadingIndicator error={null} size="small" />
            )}
          </ResultsSection>
        </Card>
      </>
    </AppLayoutContainer>
  )
}

export default CspNoLicensePlateView
