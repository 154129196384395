import React, { useEffect, useRef } from 'react'
import { ApolloProvider, useQuery, useMutation } from '@apollo/client'
import { CSPapolloClient, ApiV4ApolloClient } from 'config/apollo'
import { useDispatch } from 'react-redux'
import { requestData, receiveData, failRequest } from 'redux/actions/data'

export const withApolloProvider = (Component, Client) => {
  const Provider = (props) => {
    const MemoedApolloClient = React.useMemo(() => Client(), [])
    return (
      <ApolloProvider client={MemoedApolloClient}>
        <Component {...props} />
      </ApolloProvider>
    )
  }

  return Provider
}

// This hook creates an Apollo query or mutation, using either the useQuery or useMutatio hook, and
// stores it's result in the Redux store. The reduxStoreId variable determins where the data is
// stored in the Redux state tree.
export const useReduxQueryOrMutation = (
  query,
  options = {},
  reduxStoreId,
  isMutation = false,
) => {
  const dispatch = useDispatch()
  const isCurrentlyLoading = useRef(false)
  const { onCompleted, onError } = options || {}
  const hook = isMutation ? useMutation : useQuery
  const result = hook(query, {
    ...options,
    onCompleted: (response) => {
      dispatch(receiveData(reduxStoreId, response))
      isCurrentlyLoading.current = false
      if (onCompleted) {
        onCompleted(response)
      }
    },
    onError: (error) => {
      dispatch(failRequest(reduxStoreId, error))
      isCurrentlyLoading.current = false
      if (onError) {
        onError(error)
      }
    },
  })

  const { loading } = result

  useEffect(() => {
    if (loading && !isCurrentlyLoading.current) {
      dispatch(requestData(reduxStoreId, options))
      isCurrentlyLoading.current = true
    }
  }, [loading, dispatch, reduxStoreId, options])

  return result
}

export const withApolloV4Provider = (Component) =>
  withApolloProvider(Component, ApiV4ApolloClient)
export const withApolloCSPProvider = (Component) =>
  withApolloProvider(Component, CSPapolloClient)
