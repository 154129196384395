import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import MomentPropTypes from 'react-moment-proptypes'
import * as d3 from 'd3'

import { formatDate } from 'utilities/format'
import Icon from 'components/atoms/icon'
import Tooltip from 'components/atoms/new-tooltip'
import Typography from 'components/molecules/typography'

const InnerContainer = styled.div`
  position: relative;
  padding: 0 22px 0 48px;
`

const Line = styled.div`
  background-color: ${({ theme }) => `${theme.colors.chartsTick}66`};
  height: 1px;
  width: 100%;
`

const TicksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`

const TickContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 40px;
`

const Tick = styled.div`
  background-color: ${({ theme }) => `${theme.colors.chartsTick}66`};
  height: 10px;
  width: 1px;
`

const EventsContainer = styled.div`
  width: 100%;
`

const EventsInnerContainer = styled.div`
  height: 40px;
  position: relative;
`

const EventContainer = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  left: ${({ $position }) => $position}%;
  position: absolute;
  top: -11px;
  transform: translateX(-50%);
`

const EventTick = styled.div`
  background-color: ${({ theme }) => theme.colors.actionsStandard};
  height: 20px;
  width: 2px;
`

const EventIcon = styled(Icon)`
  background-color: ${({ theme }) => theme.colors.actionsStandard};
  box-sizing: content-box;
  padding: ${({ theme }) => theme.sizings.lvl1};
  border-radius: ${({ theme }) => theme.sizings.lvl3};
  border: 1px solid ${({ theme }) => theme.colors.brandGolf};
`

const Car = styled(Icon)`
  bottom: 0;
  left: ${({ $position }) => $position}%;
  position: absolute;
  transform: translateX(-46%);
`

const FinalMessageText = styled(Typography)`
  bottom: -60px;
  left: 100%;
  position: absolute;
  transform: translateX(-46%);
`

/**
 * A line that shows events using icons over time.
 */
function EventsTimeline({ className, events, fullTimeRange, finalMessage }) {
  const tickCount = d3.timeMondays(fullTimeRange[0], fullTimeRange[1]).length
  const filteredEvents = events.filter(
    (event) =>
      fullTimeRange[0].isBefore(event.x) && fullTimeRange[1].isAfter(event.x),
  )

  return (
    <div className={className}>
      <InnerContainer>
        <TicksContainer>
          {[...Array(tickCount)].map((_, i) => (
            <TickContainer key={i.toString()}>
              <Tick />
            </TickContainer>
          ))}
          <Car color="chartsTick" type="car" $position={100} width="48" />
          {finalMessage && (
            <FinalMessageText type="ExplanationParagraph">
              {finalMessage}
            </FinalMessageText>
          )}
        </TicksContainer>
        <Line />
        <EventsContainer>
          <EventsInnerContainer>
            {filteredEvents.map((event, index) => {
              const eventTime = moment(event.x)
              return (
                <EventContainer
                  key={index.toString()}
                  $position={Math.round(
                    ((eventTime - fullTimeRange[0]) /
                      (fullTimeRange[1] - fullTimeRange[0])) *
                      100,
                  )}
                >
                  <EventTick />
                  <Tooltip
                    title={
                      <>
                        Week{' '}
                        {d3.timeMondays(fullTimeRange[0], eventTime).length}
                        &nbsp; ({formatDate(eventTime)}) : <br />
                        {event.label}
                      </>
                    }
                  >
                    <EventIcon color="brandGolf" size="md" type={event.icon} />
                  </Tooltip>
                </EventContainer>
              )
            })}
          </EventsInnerContainer>
        </EventsContainer>
      </InnerContainer>
    </div>
  )
}

EventsTimeline.propTypes = {
  className: PropTypes.string,
  /** The events to be plotted on the line */
  events: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }),
  ).isRequired,
  finalMessage: PropTypes.string,
  fullTimeRange: PropTypes.arrayOf(MomentPropTypes.momentObj).isRequired,
}

EventsTimeline.defaultProps = {
  finalMessage: null,
  className: null,
}

export default EventsTimeline
