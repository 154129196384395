import { gql } from '@apollo/client'
import warrantyPriceRuleFragment from '../fragments/warrantyPriceRule'
import warrantyProgramFragment from '../fragments/warrantyProgram'
// import warrantyProviderFragment from '../fragments/warrantyProvider'

export const GET_WARRANTY_PROGRAM_QUERY = gql`
  query getWarrantyProgram($id: ID) {
    warrantyProgram(id: $id) {
      ...warrantyProgramInfo
    }
  }
  ${warrantyProgramFragment}
`

export const GET_WARRANTY_PROGRAMS_QUERY = gql`
  query {
    warrantyPrograms(first: 9999) {
      edges {
        node {
          ...warrantyProgramInfo
        }
      }
    }
  }
  ${warrantyProgramFragment}
`

export const GET_WARRANTY_PROGRAMS_FOR_OVERVIEW_QUERY = gql`
  query {
    warrantyPrograms(first: 9999) {
      edges {
        node {
          id
          name
          ownRiskPeriod
          brands {
            name
          }
          warrantyProvider {
            name
          }
          warrantyPriceRules {
            id
          }
        }
      }
    }
  }
`

export const GET_WARRANTY_PROGRAMS_FOR_DROPDOWN_QUERY = gql`
  query {
    warrantyPrograms(first: 9999) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const ADD_WARRANTY_PROGRAM_QUERY = gql`
  mutation AddWarrantyProgram(
    $warrantyProviderId: ID!
    $ownRiskPeriod: Int!
    $name: String!
    $manufacturerWarranty: Boolean!
    $priceCalculation: priceCalculationType!
    $maxMileage: Int!
    $externalId: String
  ) {
    createWarrantyProgram(
      warrantyProviderId: $warrantyProviderId
      ownRiskPeriod: $ownRiskPeriod
      manufacturerWarranty: $manufacturerWarranty
      priceCalculation: $priceCalculation
      maxMileage: $maxMileage
      externalId: $externalId
      name: $name
    ) {
      ...warrantyProgramInfo
    }
  }
  ${warrantyProgramFragment}
`

export const UPDATE_WARRANTY_PROGRAM_QUERY = gql`
  mutation UpdateWarrantyProgram(
    $id: ID!
    $ownRiskPeriod: Int!
    $name: String!
    $manufacturerWarranty: Boolean!
    $priceCalculation: priceCalculationType!
    $maxMileage: Int!
    $externalId: String
  ) {
    updateWarrantyProgram(
      id: $id
      ownRiskPeriod: $ownRiskPeriod
      manufacturerWarranty: $manufacturerWarranty
      priceCalculation: $priceCalculation
      maxMileage: $maxMileage
      externalId: $externalId
      name: $name
    ) {
      ...warrantyProgramInfo
    }
  }
  ${warrantyProgramFragment}
`

export const ADD_PRICE_RULE_QUERY = gql`
  mutation AddWarrantyProgramPriceRule(
    $warrantyProgramId: ID!
    $brandId: ID
    $modelId: ID
    $engineCapacityFrom: Int
    $engineCapacityTo: Int
    $kilowattFrom: Int
    $kilowattTo: Int
    $ageFrom: Int
    $ageTo: Int
    $mileageFrom: Int
    $mileageTo: Int
    $duration: simplifiedTimeRange
    $priceForDuration: Float
  ) {
    createWarrantyPriceRule(
      warrantyProgramId: $warrantyProgramId
      brandId: $brandId
      modelId: $modelId
      engineCapacityFrom: $engineCapacityFrom
      engineCapacityTo: $engineCapacityTo
      kilowattFrom: $kilowattFrom
      kilowattTo: $kilowattTo
      ageFrom: $ageFrom
      ageTo: $ageTo
      mileageFrom: $mileageFrom
      mileageTo: $mileageTo
      duration: $duration
      priceForDuration: $priceForDuration
    ) {
      ...warrantyPriceRuleInfo
    }
  }
  ${warrantyPriceRuleFragment}
`

export const UPDATE_PRICE_RULE_QUERY = gql`
  mutation UpdateWarrantyProgramPriceRule(
    $id: ID!
    $brandId: ID
    $modelId: ID
    $engineCapacityFrom: Int
    $engineCapacityTo: Int
    $kilowattFrom: Int
    $kilowattTo: Int
    $ageFrom: Int
    $ageTo: Int
    $mileageFrom: Int
    $mileageTo: Int
    $duration: simplifiedTimeRange
    $priceForDuration: Float
  ) {
    updateWarrantyPriceRule(
      id: $id
      brandId: $brandId
      modelId: $modelId
      engineCapacityFrom: $engineCapacityFrom
      engineCapacityTo: $engineCapacityTo
      kilowattFrom: $kilowattFrom
      kilowattTo: $kilowattTo
      ageFrom: $ageFrom
      ageTo: $ageTo
      mileageFrom: $mileageFrom
      mileageTo: $mileageTo
      duration: $duration
      priceForDuration: $priceForDuration
    ) {
      ...warrantyPriceRuleInfo
    }
  }
  ${warrantyPriceRuleFragment}
`

export const DELETE_PRICE_RULE_QUERY = gql`
  mutation removeWarrantyPriceRule($id: ID!) {
    deleteWarrantyPriceRule(id: $id) {
      id
      warrantyProgramId
      brand {
        id
      }
    }
  }
`

export const LINK_WARRANTY_PROGRAM_CAR_SERVICE_PLAN = gql`
  mutation connectWarrantyProgramCarServicePlan(
    $carServicePlanId: ID!
    $warrantyProgramId: ID!
  ) {
    linkWarrantyProgramCarServicePlan(
      carServicePlanId: $carServicePlanId
      warrantyProgramId: $warrantyProgramId
    ) {
      id
    }
  }
`

export const UNLINK_WARRANTY_PROGRAM_CAR_SERVICE_PLAN = gql`
  mutation unlinkWarrantyProgramCarServicePlan($id: ID!) {
    unlinkWarrantyProgramCarServicePlan(id: $id) {
      id
    }
  }
`

export const GET_WARRANTY_PROGRAMS_BY_LICENSE_PLATE = gql`
  query GetWarrantyProgramsByLicensePlate(
    $dealerId: ID!
    $licensePlate: ID
    $carId: ID
    $currentMileage: Int
    $hasFactoryWarranty: Boolean
  ) {
    warrantyProgramsByLicenseplate(
      dealerId: $dealerId
      carId: $carId
      licenseplate: $licensePlate
      currentMileage: $currentMileage
      hasFactoryWarranty: $hasFactoryWarranty
    ) {
      ...warrantyProgramInfo
    }
  }
  ${warrantyProgramFragment}
`

export const UPLOAD_WARRANTY_PROGRAM_DOCUMENT = gql`
  mutation UploadWarrantyProgramDocument(
    $warrantyProgramId: ID!
    $file: Upload!
  ) {
    uploadWarrantyProgramDocument(
      warrantyProgramId: $warrantyProgramId
      file: $file
    ) {
      id
      documentationUri
      documentationFullUri
    }
  }
`
export const GET_PRICE_ADDITIONS_BY_LICENSE_PLATE = gql`
  query priceAdditionByLicenseplate(
    $dealerId: ID!
    $licenseplate: String
    $carId: ID
    $mileagePerYear: Int!
    $currentMileage: Int!
  ) {
    priceAdditionByLicenseplate(
      dealerId: $dealerId
      licenseplate: $licenseplate
      carId: $carId
      mileagePerYear: $mileagePerYear
      currentMileage: $currentMileage
    ) {
      id
      amount
      reason
      applicableTo
    }
  }
`
