import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useQuery, useMutation } from '@apollo/client'

import toast from 'utilities/toast'
import {
  GET_CSP_CONTRACT_LAYOUT_FOR_DEALER,
  UPLOAD_CSP_CONTRACT_LAYOUT,
} from 'config/graphql/csp'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Typography from 'components/molecules/typography'
import SelectFileButton from 'components/molecules/select-file-button'
import GeneralDataError from 'components/organisms/general-data-error'

const Container = styled.div`
  max-width: 600px;
`

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const UploadButton = styled(SelectFileButton)`
  display: flex;
  && label {
    width: auto;

    &:hover,
    &:focus {
      * {
        fill: ${({ theme }) => theme.colors.brandGolf};
      }
    }
  }
`

// Stationary types (required when uploading header/footer for contract)
const HEADER = 'HEADER'
const FOOTER = 'FOOTER'

// Allowed header/footer image types
const allowedFileExtensions = ['.jpg', '.jpeg', '.JPG', '.JPEG', '.png', '.PNG']

const CarServicePlanContractLayoutSettings = ({ dealerId }) => {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(
    GET_CSP_CONTRACT_LAYOUT_FOR_DEALER,
    {
      variables: {
        id: dealerId,
      },
    },
  )
  const [uploadCspContractLayout] = useMutation(UPLOAD_CSP_CONTRACT_LAYOUT)

  if (loading) {
    return <LoadingIndicator error={null} />
  }
  if (error) {
    console.error(error)
    return (
      <>
        <Typography type="Level1Heading">
          {t('carServicePlanGeneralTab.heading')}
        </Typography>
        <GeneralDataError />
      </>
    )
  }

  const {
    id: carServicePlanId,
    contractHeaderFullUri,
    contractFooterFullUri,
  } = data.dealer.carServicePlan

  const handleUpload = (file, type) => {
    const upload = uploadCspContractLayout({
      variables: {
        type,
        carServicePlanId,
        file,
      },
      context: {
        hasUploads: true,
      },
    })

    upload.catch(() => {
      toast.error(t('carServicePlanContractLayoutTab.uploadError'))
    })
  }

  return (
    <Container>
      <Typography type="Level2Heading">
        {t('carServicePlanContractLayoutTab.headerUpload.heading')}
      </Typography>
      <Typography type="ExplanationParagraph">
        {t('carServicePlanContractLayoutTab.headerUpload.body')}
      </Typography>
      {contractHeaderFullUri && (
        <StyledImage
          src={contractHeaderFullUri}
          alt={t('carServicePlanContractLayoutTab.headerUpload.imageAlt')}
        />
      )}
      <UploadButton
        id="uploadHeader"
        icon="add"
        iconSize="md"
        text={t('carServicePlanContractLayoutTab.headerUpload.buttonLabel')}
        allowedFileExtensions={allowedFileExtensions}
        onChange={(formData) => {
          handleUpload(formData.get('file'), HEADER)
        }}
      />
      <Typography type="Level2Heading">
        {t('carServicePlanContractLayoutTab.footerUpload.heading')}
      </Typography>
      <Typography type="ExplanationParagraph">
        {t('carServicePlanContractLayoutTab.footerUpload.body')}
      </Typography>
      {contractFooterFullUri && (
        <StyledImage
          src={contractFooterFullUri}
          alt={t('carServicePlanContractLayoutTab.footerUpload.imageAlt')}
        />
      )}
      <UploadButton
        id="uploadFooter"
        icon="add"
        iconSize="md"
        text={t('carServicePlanContractLayoutTab.footerUpload.buttonLabel')}
        allowedFileExtensions={allowedFileExtensions}
        onChange={(formData) => {
          handleUpload(formData.get('file'), FOOTER)
        }}
      />
    </Container>
  )
}

CarServicePlanContractLayoutSettings.propTypes = {
  dealerId: PropTypes.string.isRequired,
}

CarServicePlanContractLayoutSettings.defaultProps = {}

export default CarServicePlanContractLayoutSettings
