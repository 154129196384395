import { gql } from '@apollo/client'

export const GET_VIN_BY_LICENSE_PLATE = gql`
  query vin($carfileId: ID, $licenseplate: String!) {
    vin(carfileId: $carfileId, licenseplate: $licenseplate) {
      vin
    }
  }
`

export const GET_BMS_DATA = gql`
  query mobaCheck($carfileId: ID, $vin: String) {
    mobaCheck(carfileId: $carfileId, vin: $vin) {
      entries {
        auto_id
      }
    }
  }
`
