import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { addLicensePlateNumberDialogFormValidation } from 'config/validation-schemas'

import Button from 'components/atoms/button'
import LoadingButton from 'components/atoms/loading-button'
import Dialog from 'components/molecules/flexible-dialog'
import { FormikLicensePlateInput } from 'components/molecules/formik-field'
import Typography from 'components/molecules/typography'

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.sizings.lvl3};
  > * {
    flex: 1 0;
    width: ${({ theme }) => theme.sizeByFactor(40)};
  }
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`

const Buttons = styled.div`
  display: flex;
`

const AddLicensePlateDialog = ({
  onToggleClick,
  onClose,
  isLoading,
  licensePlate,
  handleLicensePlateChange,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open
      closeHandler={onClose}
      overflowVisible
      title={t('carFileSummary.addLicensePlateDialog.title')}
      content={
        <>
          <Typography type="BodyParagraph">
            {t('carFileSummary.addLicensePlateDialog.description')}
          </Typography>
          <Formik
            validationSchema={addLicensePlateNumberDialogFormValidation(t)}
            validateOnChange
            validateOnBlur
            validateOnMount
            enableReinitialize
            initialValues={{ licensePlate }}
            onSubmit={({ licensePlate }) => {
              handleLicensePlateChange(licensePlate)
            }}
          >
            {({ isValid }) => (
              <Form>
                <FormRow>
                  <Field
                    name="licensePlate"
                    label={t(
                      'carFileSummary.addLicensePlateDialog.form.licensePlate.label',
                    )}
                    component={FormikLicensePlateInput}
                    disabled={isLoading}
                    filled
                  />
                </FormRow>
                <Controls>
                  <Button
                    level="option"
                    onClick={onClose}
                    text={t('cancel')}
                    noPadding
                  />
                  <Buttons>
                    <Button
                      level="option"
                      onClick={onToggleClick}
                      text={t(
                        'carFileSummary.addLicensePlateDialog.enterWithVinNumber',
                      )}
                      isDisabled={isLoading}
                    />
                    <LoadingButton
                      level="cta"
                      type="submit"
                      disabled={!isValid}
                      isLoading={isLoading}
                    >
                      {t('save')}
                    </LoadingButton>
                  </Buttons>
                </Controls>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  )
}

AddLicensePlateDialog.propTypes = {
  onToggleClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  licensePlate: PropTypes.string.isRequired,
  handleLicensePlateChange: PropTypes.func.isRequired,
}

AddLicensePlateDialog.defaultProps = {
  isLoading: false,
}

export default AddLicensePlateDialog
