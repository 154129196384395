import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'
import { ThemeContext } from 'styled-components'

/**
 * Line chart.
 *
 * Wrapper around the
 * [Chart.js line chart](https://www.chartjs.org/docs/latest/charts/line.html)
 * using [react-chartjs-2](https://github.com/jerairrest/react-chartjs-2).
 *

 */
function LineChart({ className, data, options }) {
  const theme = useContext(ThemeContext)
  const processedData = {
    ...data,
    datasets: data.datasets.map((dataset) => {
      const color = theme.colors[dataset.color]
      return {
        borderWidth: 2,
        borderColor: color,
        pointBackgroundColor: color,
        pointRadius: 4,
        fill: false,
        spanGaps: true,
        ...dataset,
      }
    }),
  }
  const processedOptions = {
    ...options,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false, // By default hide the data labels
      },
    },
    scales: {
      ...options.scales,
      yAxes: options.scales.yAxes.map((axes) => ({
        ...axes,
        ticks: {
          ...axes.ticks,
          fontFamily: theme.font,
          fontStyle: 300,
        },
      })),
    },
  }

  return (
    <div className={className}>
      <Line
        className={className}
        data={processedData}
        options={processedOptions}
      />
    </div>
  )
}

export const propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
        ).isRequired,
      }),
    ).isRequired,
  }).isRequired,
  options: PropTypes.object,
}

LineChart.propTypes = propTypes

LineChart.defaultProps = {
  className: undefined,
  options: undefined,
}

export default LineChart
