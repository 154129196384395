import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const CustomSpan = styled.span`
  color: ${({ theme }) => theme.colors.stateNegative};
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  strong {
    font-weight: bold;
  }
`

const ErrorText = ({ children, className, ...restProps }) => (
  <CustomSpan data-test-e2e="error-text" className={className} {...restProps}>
    {children}
  </CustomSpan>
)

ErrorText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

ErrorText.defaultProps = {
  className: null,
}

export default ErrorText
