import React from 'react'
import { useTranslation } from 'react-i18next'
import { postDynamicEndpoint } from 'redux/actions/data'
import LoadingButton, { propTypes } from 'components/atoms/loading-button'
import { useDispatch, useSelector } from 'react-redux'
import { openOverlay } from 'redux/actions/ui'
import useEditMasterDataForm from 'hooks/use-edit-master-data-form'

const CarfileEditMasterDataButton = ({ children, ...restProps }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const carfile = useSelector((state) => state?.data?.carfile)
  // The edit master data button needs a bunch of info from the Redux state to open the overlay in
  // which the data can be edited. The overlay is a ComplexForm, so it should be
  const editForm = useEditMasterDataForm()

  const handleEditMasterData = (e) => {
    e.preventDefault()
    if (!editForm || !carfile?.data) {
      return
    }

    dispatch(
      openOverlay('form', {
        data: carfile.data,
        icon: editForm.icon,
        formCategory: editForm.category,
        formId: editForm.id,
        storeFormId: editForm.id,
        onSubmit: (formState) =>
          dispatch(
            postDynamicEndpoint({
              endpoint: editForm.form.endpoint,
              reload: editForm.form.reload,
              redirect: editForm.form.redirect,
              ...formState,
            }),
          ),
        submitText: editForm.submitText,
        title: editForm.title,
        enableReinitialize: true,
      }),
    )
  }

  return (
    <LoadingButton
      level="option"
      icon="edit"
      iconSize="md"
      onClick={handleEditMasterData}
      isLoading={editForm.isLoading || carfile.loading}
      {...restProps}
    >
      {children || t('editMasterData')}
    </LoadingButton>
  )
}

CarfileEditMasterDataButton.propTypes = propTypes

CarfileEditMasterDataButton.defaultProps = {
  rounding: 0,
}

export default CarfileEditMasterDataButton
