import { gql } from '@apollo/client'

export const GET_FUEL_TYPES = gql`
  query FuelTypes {
    fuelTypes {
      id
      label # TODO: based on the current CSP API this prop seems to be replaced by name.
      value # and this one does not exist
    }
  }
`
