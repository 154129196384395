import React, { useCallback, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Webcam from 'react-webcam'
import FramedImage from 'components/atoms/framed-image'
import Button from 'components/atoms/button'

const PreviewContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto;
  position: relative;
`

const PreviewInnerContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
`

const StyledWebcam = styled(Webcam)`
  max-height: 100%;
  max-width: 100%;
`

const StyledFramedImage = styled(FramedImage)`
  max-height: 100%;
  max-width: 100%;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;

  & > *:nth-child(2) {
    margin-top: ${({ theme }) => theme.sizings.lvl4};
  }
`

const CaptureButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.actionsCta};
  border-radius: 80px;
  height: 80px;
  right: 20px;
  top: 20px;
  width: 80px;
`

function CameraCapture({ className, onError, onUpload }) {
  const { t } = useTranslation()
  const webcamRef = useRef(null)
  const [capture, setCapture] = useState()
  const videoConstraints = {
    facingMode: 'environment',
    height: 1080,
    width: 1920,
  }

  const handleCapture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setCapture(imageSrc)
  }, [])

  const handleAgain = useCallback(() => {
    setCapture(null)
  }, [])

  const handleUsePhoto = useCallback(() => {
    onUpload(capture)
    setCapture(null)
  }, [capture, onUpload])

  return (
    <div className={className}>
      <PreviewContainer>
        <PreviewInnerContainer>
          {capture ? (
            <StyledFramedImage alt="preview" ratio={16 / 9} src={capture} />
          ) : (
            <StyledWebcam
              ref={webcamRef}
              minScreenshotWidth={1920}
              onUserMediaError={onError}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          )}
          <ButtonsContainer>
            {capture ? (
              <>
                <Button onClick={handleAgain} text={t('again')} />
                <Button
                  level="cta"
                  onClick={handleUsePhoto}
                  text={t('usePhoto')}
                />
              </>
            ) : (
              <CaptureButton
                icon="camera"
                level="minimal"
                onClick={handleCapture}
                size="large"
              />
            )}
          </ButtonsContainer>
        </PreviewInnerContainer>
      </PreviewContainer>
    </div>
  )
}

CameraCapture.propTypes = {
  className: PropTypes.string,
  onError: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
}

CameraCapture.defaultProps = {
  className: null,
}

export default CameraCapture
