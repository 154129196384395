import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import Text from 'components/atoms/text'
import DropdownMenu from 'components/atoms/dropdown-menu'

const Container = styled.div`
  ${media.desktop`
    align-items: center;
    display: flex;
  `};
`

const StyledText = styled(Text)`
  display: none;
  margin-right: 5px;

  ${media.desktop`
    display: block;
  `};
`

function StockSortOptions({ items, onSortOptionChange, selectedSortOption }) {
  const { t } = useTranslation()

  return (
    <Container>
      <StyledText text={`${t('sortBy')}:`} />
      <DropdownMenu
        items={items}
        onChange={onSortOptionChange}
        selected={selectedSortOption}
      />
    </Container>
  )
}

StockSortOptions.propTypes = {
  items: PropTypes.array,
  onSortOptionChange: PropTypes.func.isRequired,
  // @TODO This prop having multiple type is a little weird
  selectedSortOption: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}

StockSortOptions.defaultProps = {
  items: [],
}

export default StockSortOptions
