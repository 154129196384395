import * as React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { Field, Form, Formik } from 'formik'
import styled from 'styled-components/macro'

import { CarFileStatusEnumUpperCase, CarKindEnum } from 'config/enums'
import {
  CHECKLIST_WITH_CLUSTERS,
  CHECKLISTS_FOR_DEALER,
  CREATE_CHECKLIST,
  UPDATE_CHECKLIST,
  DEALER_CLUSTERS,
} from 'config/graphql/v4'
import {
  ChecklistForm as ChecklistFormSchema,
  ChecklistFormWithClusters,
} from 'config/validation-schemas'

import { media } from 'utilities/styled'
import toast from 'utilities/toast'

import LoadingButton from 'components/atoms/loading-button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import {
  FormikLabeledCheckBoxGroup,
  FormikTextInput,
} from 'components/molecules/formik-field'
import Typography from 'components/molecules/typography'

const CheckboxGroup = styled(FormikLabeledCheckBoxGroup)`
  margin-top: ${({ theme }) => theme.sizings.lvl3};
`

const ButtonGroup = styled.div`
  margin-top: ${({ theme }) => theme.sizings.lvl3};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${({ theme }) => theme.sizings.lvl1};
  text-align: center;

  ${media.tablet`
    flex-direction: row;
    align-items: center;
  `}
`

const StyledLoadingButton = styled(LoadingButton)`
  width: 100%;

  ${media.tablet`
    width: auto;
  `}
`

const StyledFormikLabeledCheckBoxGroup = styled(FormikLabeledCheckBoxGroup)`
  margin-top: ${({ theme }) => theme.sizings.lvl3};
  width: calc(100% - ${({ theme }) => theme.sizings.lvl3});

  > legend {
    text-align: initial; // prevents parent elements from overruling this styling
  }

  > .checkboxes {
    display: grid;
    // auto generate the number of columns needed based on number of items, within the
    // horizontal space available.
    grid-template-columns: repeat(
      auto-fill,
      ${({ theme }) => theme.sizeByFactor(30)}
    );
    column-gap: ${({ theme }) => theme.sizings.lvl2};
    grid-auto-rows: min-content; // make sure the rows' height it the minimum required.
  }
`

const ChecklistForm = ({
  locationId,
  cluster,
  status,
  checklistId,
  onAfterSave,
}) => {
  const { t } = useTranslation()

  const { data, loading, error } = useQuery(CHECKLIST_WITH_CLUSTERS, {
    variables: {
      id: checklistId,
    },
    skip: !checklistId,
  })

  const {
    data: dealerClustersData,
    loading: dealerClustersLoading,
    error: dealerClustersError,
  } = useQuery(DEALER_CLUSTERS, {
    skip: !cluster,
  })

  const [createChecklist, { loading: createSubmitting }] = useMutation(
    CREATE_CHECKLIST,
    {
      refetchQueries: [
        ...(locationId
          ? [
              {
                query: CHECKLISTS_FOR_DEALER,
                variables: {
                  locationId,
                  status,
                },
              },
            ]
          : []),
        ...(cluster
          ? [
              {
                query: DEALER_CLUSTERS,
              },
            ]
          : []),
      ],
      awaitRefetchQueries: true,
    },
  )
  const [updateChecklist, { loading: updateSubmitting }] = useMutation(
    UPDATE_CHECKLIST,
    {
      refetchQueries: [
        ...(cluster
          ? [
              {
                query: DEALER_CLUSTERS,
              },
            ]
          : []),
      ],
    },
  )

  if (loading || dealerClustersLoading) {
    return <LoadingIndicator error={null} />
  }
  if (error || dealerClustersError) {
    console.error(error || dealerClustersError)
    return (
      <div>
        <Typography type="Level2Heading">
          {t('problemsFoundHeading')}
        </Typography>
        <Typography type="ExplanationParagraph">
          {t('problemsWhenRetrievingData')}
        </Typography>
      </div>
    )
  }

  const kinds =
    data && data.checklist
      ? data.checklist.soort.map((soort) => soort.soort)
      : []
  const initialValues = {
    name: data && data.checklist ? data.checklist.name : '',
    kinds,
    saveAndCreateItemsClicked: false,
    clusterIds: data?.checklist?.clusters
      ? data.checklist.clusters.map(({ id }) => id)
      : cluster
        ? [cluster.id]
        : [],
  }

  const onFormSubmit = (values) => {
    const { name, kinds } = values
    const mutation = checklistId ? updateChecklist : createChecklist
    const variables = {
      name,
      types: kinds,
    }

    if (checklistId) {
      // Existing checklist
      variables.id = checklistId
    } else {
      // New checklist
      variables.status = status
      if (locationId) {
        variables.locationId = locationId
      }
    }
    if (values.clusterIds) {
      variables.clusterIds = {
        sync: values.clusterIds,
      }
    }

    mutation({
      variables,
    })
      .then(({ data }) => {
        toast.success(t('checklistsSettings.checklistForm.checklistSaved'))
        typeof onAfterSave === 'function' && onAfterSave(values, data)
      })
      .catch((error) => {
        console.error(error)
        toast.error(t('checklistsSettings.checklistForm.checklistSaveError'))
      })
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      enableReinitialize
      validationSchema={
        Array.isArray(dealerClustersData?.dealerClusters)
          ? ChecklistFormWithClusters(t)
          : ChecklistFormSchema(t)
      }
      onSubmit={onFormSubmit}
    >
      {({ setFieldValue, handleSubmit }) => (
        <Form>
          <Typography type="Level2Heading">
            {t('checklistsSettings.checklistForm.basicInformation')}
          </Typography>
          <Field
            name="name"
            label={t('checklistsSettings.checklistForm.checklistName')}
            component={FormikTextInput}
            filled
            required
          />
          <Field
            name="kinds"
            label={t('checklistsSettings.checklistForm.carKind')}
            items={Object.values(CarKindEnum).map((kind) => ({
              value: kind,
              label: t(`carKinds.${kind}`),
            }))}
            component={CheckboxGroup}
            required
          />

          {Array.isArray(dealerClustersData?.dealerClusters) && (
            <Field
              name="clusterIds"
              label={t('checklistsSettings.checklistForm.clusters')}
              items={dealerClustersData.dealerClusters.map((cluster) => ({
                value: cluster.id,
                label: cluster.name,
              }))}
              component={StyledFormikLabeledCheckBoxGroup}
              required
            />
          )}
          <ButtonGroup>
            <StyledLoadingButton
              type="submit"
              isLoading={createSubmitting || updateSubmitting}
              onClick={(e) => {
                e.preventDefault()
                setFieldValue('saveAndCreateItemsClicked', false, false)
                handleSubmit()
              }}
            >
              {t('save')}
            </StyledLoadingButton>
            {!checklistId && (
              <StyledLoadingButton
                type="submit"
                isLoading={createSubmitting || updateSubmitting}
                onClick={(e) => {
                  e.preventDefault()
                  setFieldValue('saveAndCreateItemsClicked', true, false)
                  handleSubmit()
                }}
                level="cta"
              >
                {t('checklistsSettings.checklistForm.saveAndCreateItems')}
              </StyledLoadingButton>
            )}
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  )
}

ChecklistForm.propTypes = {
  locationId: PropTypes.string,
  cluster: PropTypes.object,
  status: PropTypes.oneOf(Object.values(CarFileStatusEnumUpperCase)).isRequired,
  checklistId: PropTypes.string,
  /**
   * This function receives the form data, and the result of the mutation. The form data object
   * contains a prop called 'saveAndCreateItemsClicked', which can be used to check if the used
   * clicked the regular save button, or the "save and create items" button.
   */
  onAfterSave: PropTypes.func,
}

ChecklistForm.defaultProps = {
  cluster: null,
  locationId: null,
}

export default ChecklistForm
