import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import Typography from 'components/molecules/typography'
import LoadingButton from 'components/atoms/loading-button'
import {
  FormikTextInput,
  FormikSwitch,
  FormikLabeledCheckBoxGroup,
} from 'components/molecules/formik-field'

import { AccessoryForm as AccessoryFormSchema } from 'config/validation-schemas'

import { media } from 'utilities/styled'

const ButtonGroup = styled.div`
  margin-top: ${({ theme }) => theme.sizings.lvl3};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${({ theme }) => theme.sizings.lvl1};
  text-align: center;

  ${media.tablet`
    flex-direction: row;
    align-items: center;
  `}
`

const SwitchGroup = styled(FormikSwitch)`
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`

const StyledLoadingButton = styled(LoadingButton)`
  width: 100%;

  ${media.tablet`
    width: auto;
  `}
`

const StyledForm = styled(Form)`
  width: 100%;

  ${media.tablet`
    width: auto;
    min-width: ${({ theme }) => theme.widths.maxFieldWidth};
  `}
`

const StyledFormikLabeledCheckBoxGroup = styled(FormikLabeledCheckBoxGroup)`
  text-transform: capitalize;
  margin-top: ${({ theme }) => theme.sizings.lvl4};
`

const StyledFormikTextInput = styled(FormikTextInput)`
  margin-top: ${({ theme }) => theme.sizings.lvl3};
`

export const ACCESSORY_FORM_SWITCH_GROUP_SETTINGS = {
  activated: 'notDisabled',
  deactivated: 'disabled',
}

// VEHICLE TYPES USED IN FRONTEND AND I18N
const VEHICLE_TYPES = ['passengercar', 'camper', 'motorbike', 'microcar']

// VEHICLE TYPES USED IN THE BACKEND
const VEHICLE_TYPES_MAP_FOR_BE = {
  passengercar: 'personenauto',
  camper: 'camper',
  motorbike: 'motor',
  microcar: 'brommobiel',
}

const AccessoryForm = ({
  accessoryId,
  isNewAccessory,
  handleCreateEditAccessorySubmit,
  getAccessoriesData,
  createAccessoryLoading,
  updateAccessoryLoading,
}) => {
  const { t } = useTranslation()
  const openedAccessory = getAccessoriesData?.find(
    (item) => item.node.id === accessoryId,
  )?.node

  const initialValues = isNewAccessory
    ? {
        omschrijving: undefined,
      }
    : {
        omschrijving: openedAccessory.omschrijving,
        disabled: !openedAccessory.disabled
          ? ACCESSORY_FORM_SWITCH_GROUP_SETTINGS.activated
          : ACCESSORY_FORM_SWITCH_GROUP_SETTINGS.deactivated,
        translationDE:
          openedAccessory.translations.find(
            (translation) => translation.language === 'DE',
          )?.translation || undefined,
        translationFR:
          openedAccessory.translations.find(
            (translation) => translation.language === 'FR',
          )?.translation || undefined,
        translationEN:
          openedAccessory.translations.find(
            (translation) => translation.language === 'EN',
          )?.translation || undefined,
        vehicleTypes: openedAccessory.vehicleTypes,
      }

  return (
    <Formik
      validateOnChange
      validateOnBlur
      validateOnMount
      initialValues={initialValues}
      enableReinitialize
      validationSchema={AccessoryFormSchema(t)}
      onSubmit={handleCreateEditAccessorySubmit}
    >
      {({ handleSubmit, values, isValid }) => (
        <StyledForm>
          <Typography type="Level2Heading">
            {t('accessoriesSettings.overlay.form.title')}
          </Typography>

          <Field
            name="omschrijving"
            label={t(
              'accessoriesSettings.overlay.form.inputs.omschrijving.label',
            )}
            component={FormikTextInput}
            required
            filled
            disabled={createAccessoryLoading || updateAccessoryLoading}
          />

          <Field
            name="translationEN"
            label={t(
              'accessoriesSettings.overlay.form.inputs.translationEn.label',
            )}
            component={StyledFormikTextInput}
            disabled={createAccessoryLoading || updateAccessoryLoading}
            filled
          />

          <Field
            name="translationDE"
            label={t(
              'accessoriesSettings.overlay.form.inputs.translationDe.label',
            )}
            component={FormikTextInput}
            disabled={createAccessoryLoading || updateAccessoryLoading}
            filled
          />

          <Field
            name="translationFR"
            label={t(
              'accessoriesSettings.overlay.form.inputs.translationFr.label',
            )}
            component={FormikTextInput}
            disabled={createAccessoryLoading || updateAccessoryLoading}
            filled
          />

          <Field
            name="vehicleTypes"
            label={t(
              'accessoriesSettings.overlay.form.inputs.vehicleTypes.label',
            )}
            items={VEHICLE_TYPES.map((vehicleType) => ({
              label: t(vehicleType),
              value: VEHICLE_TYPES_MAP_FOR_BE[vehicleType],
            }))}
            component={StyledFormikLabeledCheckBoxGroup}
            disabled={createAccessoryLoading || updateAccessoryLoading}
            filled
          />

          {!isNewAccessory && (
            <Field
              name="disabled"
              labelLeft={t(
                'accessoriesSettings.overlay.form.inputs.disabled.labelDeactivated',
              )}
              valueLeft={ACCESSORY_FORM_SWITCH_GROUP_SETTINGS.deactivated}
              labelRight={t(
                'accessoriesSettings.overlay.form.inputs.disabled.labelActivated',
              )}
              valueRight={ACCESSORY_FORM_SWITCH_GROUP_SETTINGS.activated}
              checked={
                values.disabled ===
                ACCESSORY_FORM_SWITCH_GROUP_SETTINGS.deactivated
              }
              component={SwitchGroup}
              filled
            />
          )}

          <ButtonGroup>
            <StyledLoadingButton
              disabled={!isValid}
              type="submit"
              isLoading={createAccessoryLoading || updateAccessoryLoading}
              onClick={handleSubmit}
              level="cta"
            >
              {!isNewAccessory
                ? t('accessoriesSettings.overlay.form.submit.update')
                : t('accessoriesSettings.overlay.form.submit.create')}
            </StyledLoadingButton>
          </ButtonGroup>
        </StyledForm>
      )}
    </Formik>
  )
}

AccessoryForm.propTypes = {
  accessoryId: PropTypes.string.isRequired,
  isNewAccessory: PropTypes.bool.isRequired,
  handleCreateEditAccessorySubmit: PropTypes.func.isRequired,
  getAccessoriesData: PropTypes.array,
  createAccessoryLoading: PropTypes.bool,
  updateAccessoryLoading: PropTypes.bool,
}

AccessoryForm.defaultProps = {
  getAccessoriesData: [],
  createAccessoryLoading: false,
  updateAccessoryLoading: false,
}

export default AccessoryForm
