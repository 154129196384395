export const types = {
  SET_LANGUAGE: 'SET_LANGUAGE',
}

export function setLanguage(languageCode) {
  return {
    type: types.SET_LANGUAGE,
    payload: languageCode,
  }
}
