import { useMutation } from '@apollo/client'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { personTitles } from 'config/data'
import {
  ADD_CUSTOMER,
  ADD_DRIVER,
  GET_CONTRACT,
  UPDATE_CAR,
  UPDATE_CUSTOMER,
  UPDATE_DRIVER,
} from '../../../../config/graphql/csp'
import {
  CSPSalesFlowStep3CustomerCompanySchema,
  CSPSalesFlowStep3CustomerPrivateSchema,
} from '../../../../config/validation-schemas'
import toast from '../../../../utilities/toast'

import moment from 'moment'
import { useSelector } from 'react-redux'
import { toKebabCase } from 'utilities/format'
import Button from '../../../atoms/button'
import LoadingIndicator from '../../../atoms/loading-indicator'
import Switch from '../../../atoms/switch'
import CustomerSearchInput from '../../../molecules/customer-search-input'
import {
  FormikDatePicker,
  FormikSelectInput,
  FormikTextInput,
} from '../../../molecules/formik-field'
import Typography from '../../../molecules/typography'

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
  > *:first-child {
    padding-right: ${({ theme }) => theme.sizings.lvl2};
  }
`
const StyledDropDownSelect = styled(FormikSelectInput)`
  min-width: 120px;
  max-width: 50%;
`
const StyledTextInput = styled(FormikTextInput)`
  flex: 1;
  max-width: 50%;
`

const StyledDateField = styled(FormikDatePicker)`
  flex: 1;
  max-width: 50%;
`

const StyledSwitch = styled(Switch)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`
const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.sizings.lvl3};
`
const StyledCustomerSearchInput = styled(CustomerSearchInput)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const bankDataFields = {
  title: 'subheadingBankData',
  rows: [
    {
      id: 'bank',
      fields: [
        { id: 'bankName', label: 'bank', type: 'text' },
        { id: 'iban', label: 'iban', type: 'text' },
      ],
    },
  ],
}

const vehicleChassisfield = {
  id: 'vehicle',
  fields: [{ id: 'vin', label: 'vin', type: 'text' }],
}

const vehicleLicensePlateFields = {
  id: 'vehicleLicensePlateFields',
  fields: [
    { id: 'licensePlate', label: 'licenseplate', type: 'text' },
    { id: 'registrationDate', label: 'carRegistrationDate', type: 'date' },
  ],
}

const mainDriverFields = {
  title: 'subheadingMainDriver',
  rows: [
    {
      id: 'title',
      fields: [
        {
          id: 'title',
          label: 'title',
          type: 'select',
          items: [personTitles.MR, personTitles.MS],
        },
      ],
    },
    {
      id: 'names',
      fields: [
        { id: 'firstName', label: 'firstName', type: 'text' },
        { id: 'lastName', label: 'lastName', type: 'text' },
      ],
    },
    {
      id: 'street',
      fields: [
        { id: 'street', label: 'street', type: 'text' },
        { id: 'streetNr', label: 'houseNumber', type: 'text' },
      ],
    },
    {
      id: 'location',
      fields: [
        { id: 'zipCode', label: 'postalcode', type: 'text' },
        { id: 'city', label: 'place', type: 'text' },
      ],
    },
    {
      id: 'contact',
      fields: [
        { id: 'telephone', label: 'phoneNumber', type: 'text' },
        { id: 'email', label: 'email', type: 'text' },
      ],
    },
  ],
}
const mainDriverFieldsPrivate = JSON.parse(JSON.stringify(mainDriverFields))
mainDriverFieldsPrivate.rows.splice(
  mainDriverFieldsPrivate.rows.length - 1,
  0,
  {
    id: 'country',
    fields: [{ id: 'country', label: 'country', type: 'text' }],
  },
)

const companyDataFields = {
  title: 'subheadingCompanyInfo',
  rows: [
    {
      id: 'companyName',
      fields: [{ id: 'companyName', label: 'companyName', type: 'text' }],
    },
    {
      id: 'street',
      fields: [
        { id: 'streetCompany', label: 'street', type: 'text' },
        { id: 'streetNrCompany', label: 'houseNumber', type: 'text' },
      ],
    },
    {
      id: 'location',
      fields: [
        { id: 'zipCodeCompany', label: 'postalcode', type: 'text' },
        { id: 'cityCompany', label: 'place', type: 'text' },
      ],
    },
    {
      id: 'country',
      fields: [{ id: 'country', label: 'country', type: 'text' }],
    },
    {
      id: 'contact',
      fields: [
        { id: 'phoneNumber', label: 'phoneNumber', type: 'text' },
        { id: 'emailCompany', label: 'email', type: 'text' },
      ],
    },
  ],
}

const companyFormFields = [companyDataFields, mainDriverFields, bankDataFields]

const privateFormFields = [mainDriverFieldsPrivate, bankDataFields]

const getFieldComponent = (type) => {
  switch (type) {
    case 'text':
      return StyledTextInput
    case 'date':
      return StyledDateField
    default:
      return StyledDropDownSelect
  }
}

const CspSalesCustomerDriverForm = ({
  onSubmit,
  showVehicleFields,
  primaryActionLabel,
  readOnly,
  initialCustomer,
  initialDriver,
  initialCar,
  contractId,
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const dealerId = new URLSearchParams(location.search).get('dealerId')
  const [activeCustomer, setActiveCustomer] = useState(initialCustomer)
  const [isCompany, setIsCompany] = useState(false)
  const [addCustomer] = useMutation(ADD_CUSTOMER)
  const [addDriver] = useMutation(ADD_DRIVER)

  // The updateCustomer mutation has a conditional refetch. This component (csp-sales-customer-driver-form) is used twice, once in the create CSP flow when a contractId doesn't exist and
  // once when the contractId does exist (in `contract-details.js`). In the last case, if the contract already exists, "GET_CONTRACT" should be refetched after updating the customer data.
  // When the customer data gets updated, a new PDF gets generated in the backend.
  const [updateCustomer] = useMutation(
    UPDATE_CUSTOMER,
    contractId
      ? {
          refetchQueries: [
            { query: GET_CONTRACT, variables: { id: contractId } },
          ],
        }
      : undefined,
  )
  const [updateDriver] = useMutation(UPDATE_DRIVER)
  const [updateCar] = useMutation(UPDATE_CAR)
  const hasCspLicensePlateSwitch = useSelector(
    (state) => state?.auth?.userDetails?.csp_licenseplate_switch,
  )
  const hasLicensePlateFields =
    (hasCspLicensePlateSwitch && initialCar) || !initialCar?.licensePlate

  const vehicleDataFields = {
    title: 'subheadingVehicleData',
    rows: [],
  }

  if (hasLicensePlateFields) {
    vehicleDataFields.rows.push(vehicleLicensePlateFields)
  }
  if (showVehicleFields) {
    vehicleDataFields.rows.push(vehicleChassisfield)
  }

  const companyFormFieldsWithVehicleFields = [
    companyDataFields,
    mainDriverFields,
    vehicleDataFields,
    bankDataFields,
  ]

  const privateFormFieldsWithVehicleFields = [
    mainDriverFieldsPrivate,
    vehicleDataFields,
    bankDataFields,
  ]

  useEffect(() => {
    setIsCompany(Boolean(activeCustomer && activeCustomer.isCompany))
  }, [activeCustomer])

  useEffect(() => {
    setActiveCustomer(initialCustomer)
  }, [initialCustomer])

  let fieldSchema
  let validationSchema
  if (isCompany) {
    validationSchema = CSPSalesFlowStep3CustomerCompanySchema(
      t,
      showVehicleFields,
      hasLicensePlateFields,
    )
    fieldSchema =
      showVehicleFields || hasLicensePlateFields
        ? companyFormFieldsWithVehicleFields
        : companyFormFields
  } else {
    validationSchema = CSPSalesFlowStep3CustomerPrivateSchema(
      t,
      showVehicleFields,
      hasLicensePlateFields,
    )
    fieldSchema =
      showVehicleFields || hasLicensePlateFields
        ? privateFormFieldsWithVehicleFields
        : privateFormFields
  }

  /** This loop builds the initialValues by looping over the schema,
   * and filling from relevant initial objects, depending on whether its a company customer or not
   * */
  const initialValues = {}
  fieldSchema.forEach((section) =>
    section.rows.forEach((row) =>
      row.fields.forEach((field) => {
        const fieldIdSplit = field.id.split('Company')
        if (fieldIdSplit.length > 1) {
          initialValues[field.id] =
            activeCustomer && activeCustomer[fieldIdSplit[0]]
              ? activeCustomer[fieldIdSplit[0]]
              : ''
        } else if (initialDriver && initialDriver[field.id]) {
          initialValues[field.id] = initialDriver[field.id]
        } else {
          initialValues[field.id] =
            activeCustomer && activeCustomer[field.id]
              ? activeCustomer[field.id]
              : ''
        }
      }),
    ),
  )

  if (!activeCustomer && !initialValues.country) {
    initialValues.country = 'Nederland'
  }

  if (hasCspLicensePlateSwitch && initialCar) {
    initialValues.licensePlate = initialCar.licensePlate || undefined
    initialValues.registrationDate = initialCar.registrationDate
  }

  const handleAddDriver = async (payload) => {
    const driver = await addDriver({
      variables: {
        ...payload,
      },
    })
    if (driver && driver.data && driver.data.createDriver) {
      return driver.data.createDriver
    }
    throw new Error('Error from adding driver')
  }
  const handleUpdateDriver = async (payload) => {
    const modifiedDriver = await updateDriver({
      variables: { ...payload },
    })
    if (
      modifiedDriver &&
      modifiedDriver.data &&
      modifiedDriver.data.updateDriver
    ) {
      return modifiedDriver.data.updateDriver
    }
    throw new Error('Error from updating driver')
  }
  const handleAddCustomer = async (payload) => {
    const modifiedCustomer = await addCustomer({
      variables: {
        dealerId,
        isCompany,
        ...payload,
      },
    })
    if (
      modifiedCustomer &&
      modifiedCustomer.data &&
      modifiedCustomer.data.createCustomer
    ) {
      return modifiedCustomer.data.createCustomer
    }
    throw new Error('Error from adding customer')
  }
  const handleUpdateCustomer = async (payload) => {
    const modifiedCustomer = await updateCustomer({
      variables: {
        id: activeCustomer.id,
        ...payload,
      },
    })
    if (
      modifiedCustomer &&
      modifiedCustomer.data &&
      modifiedCustomer.data.updateCustomer
    ) {
      return modifiedCustomer.data.updateCustomer
    }
    throw new Error('Error from adding customer')
  }

  const handleUpdateCar = async (payload) => {
    const modifiedCar = await updateCar({
      variables: {
        id: initialCar.carId,
        ...payload,
      },
    })
    if (modifiedCar && modifiedCar.data && modifiedCar.data.updateCar) {
      return modifiedCar.data.updateCar
    }
    throw new Error('Error from adding car')
  }

  const handleSubmitForm = async (payload) => {
    const customerPayload = isCompany
      ? {
          dealerId,
          isCompany,
          title: payload.title,
          firstName: payload.firstName,
          lastName: payload.lastName,
          street: payload.streetCompany,
          streetNr: payload.streetNrCompany,
          country: payload.country,
          zipCode: payload.zipCodeCompany,
          city: payload.cityCompany,
          email: payload.emailCompany,
          iban: payload.iban,
          bankName: payload.bankName,
          companyName: payload.companyName,
          phoneNumber: payload.phoneNumber,
        }
      : { ...payload, isCompany, dealerId }

    const customerResponse = activeCustomer
      ? await handleUpdateCustomer(customerPayload)
      : await handleAddCustomer(customerPayload)

    const driverPayload = isCompany
      ? {
          customerId: customerResponse.id,
          title: payload.title,
          firstName: payload.firstName,
          lastName: payload.lastName,
          zipCode: payload.zipCode,
          city: payload.city,
          email: payload.email,
          telephone: payload.telephone,
          street: payload.street,
          streetNr: payload.streetNr,
        }
      : { customerId: customerResponse.id, ...payload }

    const driverResponse =
      Array.isArray(customerResponse.drivers) &&
      customerResponse.drivers.length > 0
        ? await handleUpdateDriver({
            id: customerResponse.drivers[0].id,
            ...driverPayload,
          })
        : await handleAddDriver(driverPayload)

    let updateCarPayload = {}
    if (hasLicensePlateFields || (hasCspLicensePlateSwitch && initialCar)) {
      updateCarPayload = {
        registrationDate: moment(payload.registrationDate).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
        licensePlate: payload.licensePlate,
      }
      await handleUpdateCar(updateCarPayload)
    }

    return onSubmit({
      customerId: customerResponse.id,
      customer: customerResponse,
      driverId: driverResponse.id,
      driver: driverResponse,
      lastName: payload.lastName,
      bankName: payload.bankName,
      iban: payload.iban,
      vin: payload.vin,
      ...updateCarPayload,
    })
  }

  const requiredFields = isCompany
    ? [
        'iban',
        'bankName',
        'title',
        'firstName',
        'lastName',
        'street',
        'streetNr',
        'zipCode',
        'city',
        'telephone',
        'email',
        'zipCodeCompany',
        'emailCompany',
      ]
    : [
        'iban',
        'bankName',
        'lastName',
        'street',
        'streetNr',
        'zipCode',
        'city',
        'telephone',
        'email',
      ]

  if (showVehicleFields) {
    requiredFields.push('vin')
  }
  if (hasLicensePlateFields) {
    requiredFields.push('licensePlate')
  }

  return (
    <>
      {!initialCustomer && (
        <StyledCustomerSearchInput
          onChange={(val) => setActiveCustomer(val)}
          data-test-e2e="customer-input"
        />
      )}
      {!activeCustomer && (
        <StyledSwitch
          disabled={readOnly}
          labelLeft={t('private')}
          labelRight={t('business')}
          checked={isCompany}
          onChange={setIsCompany}
          data-test-e2e="private-business-switch"
        />
      )}
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          try {
            await handleSubmitForm(values)
          } catch (e) {
            console.error(e)
            toast.error(t('cspSalesFlow.errorSubmittingCustomer'))
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting }) => (
          <Form
            data-test-e2e={
              isCompany
                ? 'csp-business-customer-form'
                : 'csp-private-customer-form'
            }
          >
            {fieldSchema.map((formSection) => (
              <div key={`formSection_${formSection.title}`}>
                <Typography type="Level2Heading">
                  {t(`cspSalesFlow.steps.customerData.${formSection.title}`)}
                </Typography>
                {formSection.rows.map((sectionRow) => (
                  <FormRow
                    key={`formSection_${formSection.title}_row_${sectionRow.id}`}
                  >
                    {sectionRow.fields.map((field) => (
                      <Field
                        disabled={readOnly || isSubmitting}
                        key={`formSection_${formSection.title}_row_${sectionRow.id}_field_${field.id}`}
                        name={field.id}
                        id={toKebabCase(`${sectionRow.id}-${field.id}`)}
                        label={t(field.label)}
                        filled
                        items={
                          field.items
                            ? field.items.map((item) => ({
                                value: item,
                                label: t(item),
                              }))
                            : []
                        }
                        component={getFieldComponent(field.type)}
                        required={requiredFields.includes(field.id)}
                      />
                    ))}
                  </FormRow>
                ))}
              </div>
            ))}

            {onSubmit && !readOnly && (
              <ActionsContainer>
                {isSubmitting && <LoadingIndicator size="small" />}
                <Button
                  disabled={isSubmitting}
                  level="cta"
                  type="submit"
                  data-test-e2e="button-submit-customer-driver-form"
                >
                  {primaryActionLabel ||
                    t('cspSalesFlow.steps.basicData.nextButtonLabel')}
                </Button>
              </ActionsContainer>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}

CspSalesCustomerDriverForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  showVehicleFields: PropTypes.bool,
  primaryActionLabel: PropTypes.string,
  readOnly: PropTypes.bool,
  initialCustomer: PropTypes.object,
  initialDriver: PropTypes.object,
  initialCar: PropTypes.shape({
    carId: PropTypes.string.isRequired,
    registrationDate: PropTypes.string.isRequired,
    licensePlate: PropTypes.string,
  }),
  contractId: PropTypes.string,
}

CspSalesCustomerDriverForm.defaultProps = {
  showVehicleFields: false,
  primaryActionLabel: undefined,
  readOnly: false,
  initialCustomer: undefined,
  initialDriver: undefined,
  initialCar: undefined,
  contractId: undefined,
}

export default CspSalesCustomerDriverForm
