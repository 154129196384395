import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { isValid } from 'redux-form'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { clearData, deleteDamageImages } from 'redux/actions/data'
import { closeOverlay } from 'redux/actions/ui'

import { media } from 'utilities/styled'
import { translateForm } from 'utilities/form-utils'

import LoadingIndicator from 'components/atoms/loading-indicator'
import Overlay from 'components/molecules/overlay'
import ComplexForm from 'components/molecules/complex-form'
import NextPrevCloseButtons from 'components/molecules/next-prev-close-buttons'
import MediaForm from 'components/organisms/media-form'

const ChildContainer = styled.div`
  width: 100%;

  ${media.tablet`
    width: 50%;
  `}
`

const StyledMediaForm = styled(MediaForm)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

function TechnicalDamageOverlay({ data, onSubmit }) {
  const { t } = useTranslation()
  const [formValues, setFormValues] = useState(data || {})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formDataList, setFormDataList] = useState([])
  const [filesList, setFilesList] = useState(
    data
      ? data.images.map((image) => ({
          id: image.id,
          src: image.url,
          thumb: image.url,
        }))
      : [],
  )
  const dispatch = useDispatch()
  const overlayId = 'technicalDamage'
  const damages = useSelector((state) => state.data.damage)
  const damageId = data && data.id
  const damage =
    damages && damages.data && damages.data.find((dmg) => dmg.id === damageId)

  const forms = useSelector((state) => state.forms)
  const form = forms.damage.data.technical
  const translatedForm = translateForm(form.fieldsets, t)
  const isFormValid = useSelector((state) => isValid('technicalDamage')(state))

  const carId = useSelector((state) => state?.data?.carfile?.data?.auto_id)

  // Make sure addTechnicalDamage data is cleared whenever the
  // component gets unmounted
  useEffect(() => () => dispatch(clearData('addTechnicalDamage')), [dispatch])

  function handleChange(values) {
    setFormValues(values)
  }

  function handleImagesChange(formData) {
    setFormDataList((oldValues) => [...oldValues, formData])

    const file = formData.get('file')
    const imageSrc = URL.createObjectURL(file)
    setFilesList((oldFilesList) => [
      ...oldFilesList,
      {
        category: '',
        date: file.lastModifiedDate,
        id: file.name,
        name: file.name,
        position: oldFilesList.length + 1,
        size: file.size,
        src: imageSrc,
        thumb: imageSrc,
        type: 'image',
      },
    ])
  }

  function handleImagesDelete(names) {
    if (filesList.length < 1) {
      return false
    }

    setFilesList(filesList.filter((file) => !names.includes(file.id)))

    if (
      formDataList.length > 0 ||
      formDataList.every((formData) => !names.includes(formData.get('name')))
    ) {
      // Removes the attached images from the form.
      // If we don't do this, we visually (UI state) remove the image, but it'll still be uploaded and show in the damages overview
      setFormDataList((formDataList) =>
        formDataList.filter(
          (formData) => !names.includes(formData.get('name')),
        ),
      )

      return dispatch(
        deleteDamageImages(names, {
          auto_id: carId || damage.auto_id,
        }),
      )
    }

    return true
  }

  async function handleSubmit(values) {
    setIsSubmitting(true)

    if (onSubmit) {
      await onSubmit(
        {
          ...formValues,
          ...values,
          id: damageId,
        },
        formDataList,
      )
    }

    setIsSubmitting(false)
    dispatch(closeOverlay(overlayId))
    return true
  }

  return (
    <Overlay
      close={() => dispatch(closeOverlay(overlayId))}
      icon="comments"
      text={t('technicalDamageDetails')}
      title={t('changeTechnicalDamage')}
    >
      <ChildContainer>
        {isSubmitting ? (
          <LoadingIndicator size="small" />
        ) : (
          <>
            <ComplexForm
              data={formValues}
              fieldsets={translatedForm}
              formId="technicalDamage"
              onChange={handleChange}
              onSubmit={handleSubmit}
              enableReinitialize
            />
            <StyledMediaForm
              items={filesList}
              onUpload={handleImagesChange}
              onDelete={handleImagesDelete}
              level="standard"
              type="images"
              noCustomCamera
            />

            <NextPrevCloseButtons
              disableCloseButton={!isFormValid}
              closeText={t('save')}
              onClose={() => handleSubmit(formValues)}
            />
          </>
        )}
      </ChildContainer>
    </Overlay>
  )
}

TechnicalDamageOverlay.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
}

TechnicalDamageOverlay.defaultProps = {
  data: null,
  onSubmit: null,
}

export default TechnicalDamageOverlay
