import React from 'react'
import styled from 'styled-components/macro'
import SvgConfetti from 'components/atoms/svg-confetti'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  padding: 0;
  position: relative;
`

const StyledSvgConfetti = styled(SvgConfetti)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  max-width: 100%;
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  padding-top: 12%;
`

const ConfettiBackground = ({ children, className, ...restProps }) => (
  <Wrapper className={className} {...restProps}>
    <StyledSvgConfetti />
    <Content>{children}</Content>
  </Wrapper>
)

ConfettiBackground.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

ConfettiBackground.defaultProps = {
  className: null,
  children: null,
}

export default ConfettiBackground
