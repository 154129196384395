import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { placeB2bCarFileInterest } from 'redux/actions/data'

import { media } from 'utilities/styled'

import { CarFileStatusEnumLowerCase, CarFileStatusEnumUpperCase } from 'config/enums'

import Button from 'components/atoms/button'
import ExplanationParagraph from 'components/atoms/explanation-paragraph'
import GalleryImage from 'components/atoms/gallery-image'
import Icon from 'components/atoms/icon'
import InlineBodyText from 'components/atoms/inline-body-text'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Tooltip from 'components/atoms/tooltip'
import CarBrandAndModel from 'components/molecules/car-brand-and-model'
import CarShareBuyPrice from 'components/molecules/carfile-car-share-buy-price'
import CarShareBidMeta from 'components/molecules/carfile-car-share-bid-meta'
import CarfilePrices from 'components/molecules/carfile-prices'
import CarfileSpecifications from 'components/molecules/carfile-specifications'
import CarfileStatus from 'components/molecules/carfile-status'
import CarfileSummaryHeader from 'components/molecules/carfile-summary-header'
import CarfileTags from 'components/organisms/carfile-tags'
import FlexibleDialog from 'components/molecules/flexible-dialog'
import Gallery from 'components/molecules/gallery'
import ImageCarousel from 'components/molecules/image-carousel'
import StandDuration from 'components/molecules/stand-duration'
import CarfileCSPPrices from 'components/views/car-file-csp-prices'
import CarfileEditMasterDataButton from 'components/molecules/carfile-edit-master-data-button'
import CarfileRequestBmsDataButton from 'components/molecules/carfile-request-bms-data-button'

import { useCarFileType } from 'hooks/use-car-file-type'

const StyledCarfileSummary = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.desktop`
    align-items: flex-start;
    flex-direction: row;
  `}
`

const CarouselContainer = styled.div`
  display: block;

  ${media.desktop`
    display: none;
  `}
`

const DataContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.bottom};
  background-color: ${({ theme }) => theme.colors.brandGolf};
  padding: 24px;

  ${media.desktop`
    width: 60%;
    padding: 40px;
  `}
`

const StyledCarfileSpecifications = styled(CarfileSpecifications)`
  padding-top: 30px;

  ${media.tablet`
    section {
      grid-template-columns: 1fr 1fr 1fr;
    }
  `}

  ${media.desktop`
    section {
      grid-template-columns: 1fr 1fr;
    }
  `}

  ${media.tv`
    section {
      grid-template-columns: 1fr 1fr 1fr;
    }
  `}
`

const InnerPadding = styled.div`
  padding: ${({ theme }) => theme.sizeByFactor(3)};
`

const SalesBlockContainer = styled.div`
  > *:first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.brandDelta};
    margin: 0 auto;
    width: calc(100% - ${({ theme }) => theme.sizeByFactor(3)} * 2);
    padding-top: ${({ theme }) => theme.sizeByFactor(3)};
    padding-bottom: ${({ theme }) => theme.sizeByFactor(3)};
  }
`

const SalesBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.brandFoxtrot};
  margin-top: 30px;
`
const StyledStandDuration = styled(StandDuration)`
  margin-top: 20px;
`

const InterestedSection = styled.section`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.sizings.lvl1};

  > * {
    flex: 0 0 auto;
  }
`

const InterestingCar = styled.section`
  background-color: ${({ theme }) => theme.colors.sectionBackground};
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: ${({ theme }) => theme.sizings.lvl4};
  `
// Wrapped by InterestingCar:
const CarImage = styled(GalleryImage)`
  max-height: 100px;
  display: block;
  flex: 0 0 150px !important;
  overflow: hidden;
`
// Wrapped by InterestingCar:
const StyledCarBrandAndModel = styled(CarBrandAndModel)`
  flex: 1 0;
  padding: ${({ theme }) => theme.sizings.lvl2};
`

const CtaThankYouMessage = styled(InlineBodyText)`
  color: ${({ theme }) => theme.colors.actionsCta};
  font-weight: 400;
  font-size: ${({ theme }) => theme.sizings.lvl2};
  & + * {
  /**
   * Overrides a little space trick in InlinedBodyText
   */
    margin-left: 0;
  }
`

const EditContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
  gap: ${({ theme }) => theme.sizings.lvl1};
`

const EditContainerButtonDivider = styled.div`
  height: ${({ theme }) => theme.sizings.lvl2};
  width: 1px;
  background-color: ${({ theme }) => theme.colors.brandDelta};
`

const GalleryAndTagsContainer = styled.div`
  display: none;

  ${media.desktop`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 40%;
    gap: ${({ theme }) => theme.sizings.lvl2};
  `}
`

const CarfileTagSelectorMobile = styled(CarfileTags)`
  display: flex;
  padding: ${({ theme }) => theme.sizings.lvl2};

  ${media.desktop`
    display: none;
  `}
`

const CarfileTagSelectorDesktop = styled(CarfileTags)`
  display: none;
  
  ${media.desktop`
    padding-right: ${({ theme }) => theme.sizings.lvl2};
    display: flex;
  `}
`

const CarShareBuySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${({ theme }) => theme.sizings.lvl2};

  ${media.phone`
    align-items: center;
    flex-direction: row;
  `}
`

const CarShareBidSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap ${({ theme }) => theme.sizings.lvl2};
  border-top: solid 1px ${({ theme }) => theme.colors.brandDelta};
  padding-top: ${({ theme }) => theme.sizeByFactor(2.5)};
  margin-top: ${({ theme }) => theme.sizeByFactor(2.5)};

  ${media.phone`
    flex-direction: row;
    gap ${({ theme }) => theme.sizings.lvl3};
  `}
`

const CarShareSectionMeta = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl1};
`

function CarfileSummary ({
  carfile,
  imagesSetType,
  carfileLoading,
  carId,
  carStatus,
  images,
  imagesLoading,
  openImageOverlay,
  openOverlay,
  salesView,
  setCarKind,
  toggleSalesView,
  setCarStatus,
  updateCarfile,
  showInternalPricingInfo,
  showStayTime,
  carImageLabelText,
  showInterestedButton,
  showCspCalculationButton,
  handleCarShareClickBuyNowButton,
  handleCarShareClickIncreaseBidButton,
  showEditMasterDataButton,
  showRequestBmsDataButton,
  onProgressClick,
  ...restProps
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [interestedDialogIsOpen, setInterestedDialogIsOpen] = React.useState(false)
  const { isCarShareCarFile } = useCarFileType()

  const b2bCarFileInterest = useSelector(state => {
    if (carfile?.id && Array.isArray(state?.data?.b2bCarFileInterests)) {
      return state.data.b2bCarFileInterests.find(item => item.carFileId === carfile.id)
    } else {
      return null
    }
  })

  // Car and CarShare
  const carfileStatus = carfile?.status
  const statusLabel = useMemo(() => {
    if (carImageLabelText) {
      return carImageLabelText
    }

    if (!carfileStatus) {
      return ''
    }

    // When the passed 'status' is a string in the CarFileStatusEnum (V3 / coming from the backend), then display the translated string for this status
    if (Object.values(CarFileStatusEnumLowerCase).includes(carfileStatus)) {
      return t(`carFileStatussesV3.${carfileStatus}`)
    }

    // When the above logic doesn't match, display the string that either has been given to this component or null, which shows no label in the Gallery Image
    return carfileStatus
  }, [t, carImageLabelText, carfileStatus])
  const carHasPrice = !!carfile?.inkoopprijs && carfile?.inkoopprijs !== '0'

  // CarShare only
  const carShareCarHasBid = carfile?.carshare_bid?.has_bid
  const carShareCarCanReceiveBidOrBuy = carfile?.carshare_can_bid
  const carShareCarHasPurchasePending = carfile?.carshare_bid?.is_purchase_pending
  const carShareCarCurrentBid = carfile?.carshare_bid?.current_bid
  const carShareCarBiddingDeadline = carfile?.carshare_bid?.deadline
  const showCarShareBuyNowSection = typeof handleCarShareClickBuyNowButton === 'function'
  const showCarShareBidSection = typeof handleCarShareClickIncreaseBidButton === 'function' && !carShareCarHasPurchasePending && carShareCarCanReceiveBidOrBuy

  return (
    <>
      <StyledCarfileSummary
        {...restProps}
      >
        {!imagesLoading && images && !!images.length &&
        (
          <CarouselContainer data-test-e2e="carousel-container">
            <ImageCarousel
              label={statusLabel}
              images={images}
              ratio={16 / 9}
            />
          </CarouselContainer>
        )}
        <GalleryAndTagsContainer>
          <Gallery
            data-test-e2e="carousel"
            label={statusLabel}
            images={images}
            imagesSetType={imagesSetType}
            openImageOverlay={openImageOverlay}
            loading={imagesLoading}
          />
          <CarfileTagSelectorDesktop readOnly={isCarShareCarFile} />
        </GalleryAndTagsContainer>
        <DataContainer>
          {carfileLoading
            ? <LoadingIndicator />
            : (
              <>
                <CarfileSummaryHeader
                  brand={carfile.brand}
                  brandIsNotMapped={carfile.brandIsNotMapped}
                  carId={carId}
                  kind={carfile.kind}
                  license={carfile.license}
                  model={carfile.model}
                  modelIsNotMapped={carfile.modelIsNotMapped}
                  setCarKind={setCarKind}
                  type={carfile.type}
                  salesView={salesView}
                  toggleSalesView={toggleSalesView}
                  vin={carfile.chassisnr}
                />
                <SalesBlock>
                  <InnerPadding>
                    {showInternalPricingInfo && (
                      <CarfilePrices
                        carfile={carfile}
                        purchaseprice={parseFloat(carfile.inkoopprijs_view)}
                        sellingprice={carfile.sellingprice}
                        salesView={salesView}
                        taxMargin={carfile.taxMargin}
                      />
                    )}

                    {
                      (showStayTime && (!salesView && (
                        carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.binnen] ||
                        carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verkocht] ||
                        carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.afgeleverd]
                      ))) && (
                        <StyledStandDuration title={t('staytime')} days={carfile.staytime} />
                      )
                    }
                    {updateCarfile && setCarStatus && openOverlay && (
                      <CarfileStatus
                        carfile={carfile}
                        carId={carId}
                        carStatus={carStatus}
                        mileage={carfile.km_binnen}
                        openOverlay={openOverlay}
                        salesView={salesView}
                        setCarStatus={setCarStatus}
                        updateCarfile={updateCarfile}
                        deliveryDate={carfile.afleverdatum}
                        onProgressClick={onProgressClick}
                      />
                    )}
                    {(showCarShareBuyNowSection || showCarShareBidSection) && (
                      <>
                        {showCarShareBuyNowSection && (
                          <CarShareBuySection>
                            <CarShareSectionMeta>
                              <CarShareBuyPrice carFileData={carfile} />
                            </CarShareSectionMeta>
                            {carHasPrice && (
                              <Button onClick={handleCarShareClickBuyNowButton} level="cta" disabled={carShareCarHasPurchasePending || !carShareCarCanReceiveBidOrBuy}>
                                {carShareCarHasPurchasePending ? t('purchasePending') : !carShareCarCanReceiveBidOrBuy ? t('sold') : t('buyNow')}
                              </Button>
                            )}
                          </CarShareBuySection>
                        )}
                        {showCarShareBidSection && (
                          <CarShareBidSection>
                            {carShareCarHasBid && (
                              <CarShareSectionMeta>
                                <CarShareBidMeta
                                  currentBid={carShareCarCurrentBid}
                                  biddingDeadline={carShareCarBiddingDeadline}
                                />
                              </CarShareSectionMeta>
                            )}
                            <Button onClick={handleCarShareClickIncreaseBidButton} level="standard">
                              {carShareCarHasBid ? t('modifyBid') : t('submitBid')}
                            </Button>
                          </CarShareBidSection>
                        )}
                      </>
                    )}
                    {showInterestedButton && (
                      <InterestedSection>
                        {(!b2bCarFileInterest ||
                        b2bCarFileInterest.successful === false) &&
                      (
                        <>
                          <Button
                            onClick={() => setInterestedDialogIsOpen(true)}
                            level="cta"
                          >
                            {t('carFileSummary.interestedButton')}
                          </Button>
                          <Tooltip
                            message={t('carFileSummary.interestedExplanation')}
                          >
                            <Icon
                              color="actionsStandard"
                              type="info"
                              size="md"
                            />
                          </Tooltip>
                        </>
                      )}
                        {b2bCarFileInterest?.successful && (
                          <>
                            <CtaThankYouMessage variant="bold">
                              {t('carFileSummary.interestedSuccess')}
                            </CtaThankYouMessage>
                            <Tooltip
                              message={t('carFileSummary.interestedSuccessMessage')}
                            >
                              <Icon
                                color="actionsStandard"
                                type="info"
                                size="md"
                              />
                            </Tooltip>
                          </>
                        )}

                        <FlexibleDialog
                          open={interestedDialogIsOpen}
                          closeHandler={() => setInterestedDialogIsOpen(false)}
                          onSubmit={() => {
                            dispatch(placeB2bCarFileInterest({ id: carfile.id }))
                            setInterestedDialogIsOpen(false)
                          }}
                          title={t('carFileSummary.interestedDialog.heading')}
                          cancel
                          submitText={t('carFileSummary.interestedDialog.submitText')}
                          content={(
                            <>
                              <InterestingCar>
                                <CarImage
                                  src={(images && images.length && images[0]) ? images[0].src : ''}
                                  aria-describedby="#interesting-car-name"
                                  alt={`${carfile.brand} ${carfile.model} ${carfile.type}`}
                                  ratio={1.33}
                                />
                                <StyledCarBrandAndModel
                                  id="interesting-car-name"
                                  carBrand={carfile.brand}
                                  carModel={carfile.model}
                                  carType={carfile.type}
                                  size="small"
                                />
                              </InterestingCar>
                              <ExplanationParagraph>
                                {t('carFileSummary.interestedDialog.message')}
                              </ExplanationParagraph>
                            </>
                          )}
                        />
                      </InterestedSection>
                    )}
                  </InnerPadding>
                  {
                    // showCspCalculationButton is only "true" for the regular carfile, for the B2B carfile this button should NOT be shown
                    showCspCalculationButton && (
                      <SalesBlockContainer>
                        <CarfileCSPPrices carfile={carfile} />
                      </SalesBlockContainer>
                    )
                  }
                </SalesBlock>
                <StyledCarfileSpecifications
                  body={carfile.body}
                  bodyIsNotMapped={carfile.bodyIsNotMapped}
                  buildyear={carfile.buildyear}
                  color={carfile.color}
                  fuel={carfile.fuel}
                  fuelIsNotMapped={carfile.fuelIsNotMapped}
                  id="summary-specifications"
                  mileage={carfile.mileage}
                  manufacterd={carfile.manufacterd}
                  metallic={carfile.metallic}
                  nap={carfile.nap}
                  noOfDoors={carfile.deuren}
                  powerHP={carfile.powerHP}
                  powerKW={carfile.powerKW}
                  taxMargin={carfile.taxMargin}
                  transmission={carfile.schakeling_label}
                  dealerId={carfile.vestiging_id}
                  data-test-e2e="specifications"
                  carId={carId}
                  bmsData={carfile?.moba}
                  pickUpLocationCity={carfile?.carshare_externe_partij?.plaats}
                  pickUpLocationCountry={carfile?.carshare_externe_partij?.land}
                  vin={carfile.chassisnr}
                />

                {(showEditMasterDataButton || showRequestBmsDataButton) && (
                  <EditContainer>
                    {
                    // showEditMasterDataButton is only "true" for the regular carfile, for the B2B carfile this button should NOT be shown
                      showEditMasterDataButton && <CarfileEditMasterDataButton />
                    }
                    {
                    // showRequestBmsDataButton is only "true" for the regular carfile, for the B2B carfile this button should NOT be shown
                      showRequestBmsDataButton && (
                        <>
                          <EditContainerButtonDivider />
                          <CarfileRequestBmsDataButton
                            carfile={carfile}
                            isLoading={carfileLoading}
                          />
                        </>
                      )
                    }
                  </EditContainer>
                )}
              </>
            )}
        </DataContainer>
      </StyledCarfileSummary>
      <CarfileTagSelectorMobile readOnly={isCarShareCarFile} />
    </>
  )
}

CarfileSummary.propTypes = {
  carId: PropTypes.string.isRequired,
  carfile: PropTypes.object,
  carfileLoading: PropTypes.bool,
  carStatus: PropTypes.string,
  setCarStatus: PropTypes.func,
  images: PropTypes.array,
  imagesLoading: PropTypes.bool,
  openImageOverlay: PropTypes.func.isRequired,
  openOverlay: PropTypes.func,
  setCarKind: PropTypes.func,
  toggleSalesView: PropTypes.func,
  salesView: PropTypes.bool,
  updateCarfile: PropTypes.func,
  showCspCalculationButton: PropTypes.bool,
  showEditMasterDataButton: PropTypes.bool,
  showRequestBmsDataButton: PropTypes.bool,
  /**
   * Shows the pricing info that is only for internal use and should not be
   * shown to people outside of the dealership:
   */
  showInternalPricingInfo: PropTypes.bool,
  /**
   * the stay time will only be shown if the salesView is active
   * and the status is 'binnen' or 'verkocht'
   */
  showStayTime: PropTypes.bool,
  carImageLabelText: PropTypes.string,
  showInterestedButton: PropTypes.bool,
  handleCarShareClickBuyNowButton: PropTypes.func,
  handleCarShareClickIncreaseBidButton: PropTypes.func,
  imagesSetType: PropTypes.oneOf([
    'regular-car-file',
    'b2b-car-file',
  ]),
  onProgressClick: PropTypes.func,
}

CarfileSummary.defaultProps = {
  carfile: {},
  carfileLoading: true,
  carStatus: null,
  images: null,
  imagesLoading: true,
  updateCarfile: null,
  setCarKind: null,
  setCarStatus: null,
  showInternalPricingInfo: false,
  showStayTime: false,
  carImageLabelText: null,
  toggleSalesView: null,
  salesView: false,
  showInterestedButton: false,
  handleCarShareClickBuyNowButton: null,
  handleCarShareClickIncreaseBidButton: null,
  imagesSetType: 'regular-car-file',
  showCspCalculationButton: true,
  showEditMasterDataButton: true,
  showRequestBmsDataButton: true,
  onProgressClick: null,
}

export default CarfileSummary
