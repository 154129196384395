import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/atoms/icon'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  margin: 0.75em 0 0.25em 0;
`

const StyledIcon = styled(Icon)`
  display: inline-block;
`

const ValueItem = styled.small`
  font-size: ${({ theme }) => theme.smallFontSize};
  color: ${({ theme }) => theme.colors.text};
  &[title] {
    cursor: help;
  }
`

/**
 * Used to display a tiny list of stats for a certain entity,
 * like a car, car file or advertisement.
 *
 * The list is kept tiny to make sure it uses the least amount of
 * space as possible, by using icons instead of labels.
 * Even though it requires label-icon combination for each value,
 * only the icon is initially displayed.
 * The label is displayed only when hovered over the value's icon.
 * This keeps the list accessible (easy to understand).
 */
const TinyStatsList = ({ stats }) => (
  <Wrapper>
    {stats.map((stat, index) => (
      <span key={String(index)}>
        <ValueItem title={stat.label}>
          <StyledIcon title={stat.label} size="sm" type={stat.icon} />
          &ensp;
          {stat.value}
        </ValueItem>
        {index + 1 < stats.length && <ValueItem>&emsp;|&emsp;</ValueItem>}
      </span>
    ))}
  </Wrapper>
)

TinyStatsList.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
}

export default TinyStatsList
