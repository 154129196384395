import React from 'react'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import PropTypes from 'prop-types'

const StyledDashboardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: ${({ theme }) => theme.sizings.lvl3};

  ${media.tablet`
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  `}

  ${media.tv`
    gap: ${({ theme }) => theme.sizings.lvl4};
  `}
`

const DashboardGrid = ({ columns, children }) => (
  <StyledDashboardGrid columns={columns}>{children}</StyledDashboardGrid>
)

DashboardGrid.propTypes = {
  /** The amount of columns this grid has */
  columns: PropTypes.number.isRequired,
  children: PropTypes.node,
}

DashboardGrid.defaultProps = {
  children: null,
}

export default DashboardGrid
