import React, { useContext } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  GET_DEALER_QUERY,
  GET_WARRANTY_PROGRAMS_QUERY,
  GET_WARRANTY_PROVIDERS_AND_CAR_MODELS_QUERY,
  LINK_WARRANTY_PROGRAM_CAR_SERVICE_PLAN,
} from 'config/graphql/csp'
import Overlay from 'components/molecules/overlay'
import LoadingIndicator from 'components/atoms/loading-indicator'
import WarrantyProgramTableForm from 'components/organisms/car-service-plan/warranty-program-table-form'
import { DealerContext } from 'contexts/dealer'

const LayoutContainer = styled.div`
  flex: 1;
`

function WarrantyProgramLink() {
  const { t } = useTranslation()
  const { dealerId } = useParams()
  const history = useHistory()
  const { setWarrantyProgramMutating } = useContext(DealerContext)

  const { data: dealerData, error: dealerError } = useQuery(GET_DEALER_QUERY, {
    variables: {
      id: dealerId,
    },
  })

  const [connectWarrantyProgramCarServicePlan] = useMutation(
    LINK_WARRANTY_PROGRAM_CAR_SERVICE_PLAN,
    {
      refetchQueries: [
        {
          query: GET_DEALER_QUERY,
          variables: { id: dealerId },
        },
      ],
      awaitRefetchQueries: true,
    },
  )
  const { data: warrantyProgramsData, error: warrantyProgramsError } = useQuery(
    GET_WARRANTY_PROGRAMS_QUERY,
  )
  const warrantyProvidersAndCarModelsQuery = useQuery(
    GET_WARRANTY_PROVIDERS_AND_CAR_MODELS_QUERY,
  )

  const onSubmit = async (payload) => {
    setWarrantyProgramMutating(true)
    try {
      return connectWarrantyProgramCarServicePlan({
        variables: {
          carServicePlanId: dealerData.dealer.carServicePlan.id,
          warrantyProgramId: payload.id,
        },
      }).then((_) => {
        setWarrantyProgramMutating(false)
        history.goBack()
      })
    } catch (e) {
      setWarrantyProgramMutating(false)
      history.goBack()
      throw new Error(e)
    }
  }

  const usedWarrantyProgramIds = dealerData
    ? dealerData.dealer.carServicePlan.usedWarrantyPrograms.map(
        (warrantyProgram) => warrantyProgram.warrantyProgram.id,
      )
    : []

  return (
    <Overlay
      close={() => history.goBack()}
      title={t('carServicePlanAdminProgramsNewProgramOverlay.linkHeading')}
      icon="cleaning"
    >
      <LayoutContainer>
        {warrantyProgramsData && dealerData ? (
          <WarrantyProgramTableForm
            initiallyDisabled
            enableEditing={false}
            onSubmit={onSubmit}
            warrantyPrograms={warrantyProgramsData.warrantyPrograms.edges.map(
              (edge) => {
                if (usedWarrantyProgramIds.indexOf(edge.node.id) !== -1) {
                  return { ...edge.node, disabled: true }
                }
                return edge.node
              },
            )}
            carBrandsAndModels={
              warrantyProvidersAndCarModelsQuery.data
                ? warrantyProvidersAndCarModelsQuery.data.brands
                : []
            }
            warrantyProviders={
              warrantyProvidersAndCarModelsQuery.data
                ? warrantyProvidersAndCarModelsQuery.data.warrantyProviders
                    .edges
                : []
            }
          />
        ) : (
          <LoadingIndicator error={warrantyProgramsError || dealerError} />
        )}
      </LayoutContainer>
    </Overlay>
  )
}

export default WarrantyProgramLink
