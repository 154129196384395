import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import LinearProgress from '@material-ui/core/LinearProgress'
import InlineBodyText from 'components/atoms/inline-body-text'
import BodyHeading from 'components/atoms/body-heading'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${(props) => props.theme.sizings.lvl0}
    ${(props) => props.theme.sizings.lvl2};
  align-items: center;
  min-height: ${(props) => props.theme.sizings.lvl3};
`

const Title = styled(BodyHeading)`
  flex: 0 0 100%;
  margin: 0;
`

const StyledLinearProgress = styled(LinearProgress)`
  flex: 1 1;

  &.MuiLinearProgress-colorPrimary {
    background-color: ${(props) => props.theme.colors.disabledInputBackground};
  }

  &.MuiLinearProgress-colorSecondary {
    background-color: ${(props) => props.theme.colors.stateNegative};
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => props.theme.colors.statePositive};
  }

  .MuiLinearProgress-barColorSecondary {
    background-color: ${(props) => props.theme.colors.stateNegative};
  }
`

const PercentageCounter = styled(InlineBodyText)`
  flex: 0 0 35px;
`
const Message = styled(InlineBodyText)`
  flex: 0 0 100%;
  color: ${({ $inError, theme }) => $inError && theme.colors.stateNegative};
`
/**
 * The progressBar wraps [Material UI's LinearProgress component](https://material-ui.com/components/progress/#linear-determinate)
 * and enhances it with a title, a status message and error state.
 */
function ProgressBar({ title, progress, message, inError }) {
  return (
    <Container>
      {title && <Title level="5">{title}</Title>}
      <StyledLinearProgress
        color={inError ? 'secondary' : 'primary'}
        variant="determinate"
        value={progress > 100 ? 100 : progress}
      />
      <PercentageCounter>
        {!inError && `${Math.round(progress)}%`}
      </PercentageCounter>
      <Message $inError={inError}>{message}</Message>
    </Container>
  )
}

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  message: PropTypes.string,
  title: PropTypes.string,
  inError: PropTypes.bool,
}

ProgressBar.defaultProps = {
  title: '',
  message: '',
  inError: false,
}

export default ProgressBar
