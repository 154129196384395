import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const StyledI = styled.i`
  display: inline-block;
  color: ${({ $color, theme }) => $color || theme.colors.inputPlaceholder};
  height: ${({ theme }) => theme.sizings.lvl2};
  width: ${({ theme }) => theme.sizings.lvl2};
  line-height: ${({ theme }) => theme.sizings.lvl2};
  border: 1px solid
    ${({ $color, theme }) => $color || theme.colors.inputPlaceholder};
  border-radius: 50%;
  text-align: center;
  font-size: 0.8em;

  &::before {
    content: 'i';
  }
`

const ExplanationIcon = ({ color, ...restProps }) => (
  <StyledI $color={color} {...restProps} />
)

ExplanationIcon.propTypes = {
  color: PropTypes.string,
}

export default ExplanationIcon
