import { connect } from 'react-redux'
import { forgotPassword } from 'redux/actions/auth'
import ForgotPassword from '../views/forgot-password'

const mapStateToProps = ({ auth }) => ({ auth })

const mapDispatchToProps = {
  forgotPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
