import formsApi from 'utilities/forms-data'

export const types = {
  REQUEST_FORMS: 'REQUEST_FORMS',
  RECEIVE_FORMS: 'RECEIVE_FORMS',
  FAIL_FORMS_REQUEST: 'FAIL_FORMS_REQUEST',
}

function requestForms(id, payload) {
  return {
    type: types.REQUEST_FORMS,
    id,
    payload,
  }
}

function receiveForms(id, data) {
  return {
    type: types.RECEIVE_FORMS,
    id,
    data,
  }
}

function failRequest(id, payload) {
  return {
    type: types.FAIL_FORMS_REQUEST,
    id,
    payload,
  }
}

export function getForms(id, params, subId) {
  const constructedId = subId ? `${id}_${subId}` : id
  return (dispatch, getState) => {
    const instance = getState().auth.instanceId

    dispatch(requestForms(constructedId))

    formsApi
      .getForms(id, instance, params)
      .then((response) => {
        if (!response) {
          dispatch(failRequest(constructedId, 'no response'))
          return Promise.reject(new Error(`no response for ${constructedId}`))
        }

        dispatch(receiveForms(constructedId, response))
        return Promise.resolve()
      })
      .catch((error) => {
        dispatch(failRequest(constructedId, error))
      })
  }
}
