import api from 'utilities/api'

function login(data, instance) {
  return api.post('/cl/v3/login/login', data, instance)
}

function logout(instance) {
  return api.post('/cl/v3/dashboard/logout', null, instance)
}

function getUserDetails(instance) {
  return api
    .get('/cl/v3/account/mydetails', instance)
    .then((response) => response.data.data[0])
}

function forgotPassword(data) {
  return api.post('/cl/v3/ucc/recovery', {
    ...data,
    url: `${window.location.origin}/reset-password`,
  })
}

function resetPassword(data) {
  return api.post('/cl/v3/ucc/recoverysetpassword', data)
}

export default {
  login,
  logout,
  getUserDetails,
  forgotPassword,
  resetPassword,
}
