import { gql } from '@apollo/client'

export default gql`
  fragment customerInfo on Customer {
    id
    dealerId
    title
    firstName
    lastName
    street
    streetNr
    zipCode
    city
    country
    email
    iban
    bankName
    isCompany
    companyName
    phoneNumber
    drivers {
      id
    }
  }
`
