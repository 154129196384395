import { gql } from '@apollo/client'
import documentFragment from '../fragments/document'

export const GET_CONTRACT_DOCUMENTS = gql`
  query GetContractDocuments($contractId: ID!) {
    contract(id: $contractId) {
      id
      customer {
        title
        lastName
        isCompany
        companyName
      }
      documents {
        ...document
      }
    }
  }
  ${documentFragment}
`

export const UPLOAD_SIGNED_CONTRACT_DOCUMENT = gql`
  mutation UploadSignedContractDocument($documentId: ID!, $file: Upload!) {
    uploadSignedContractDocument(documentId: $documentId, file: $file) {
      ...document
    }
  }
  ${documentFragment}
`

export const UPLOAD_SIGNED_DOCUMENT = gql`
  mutation UploadSignedDocument($documentId: ID!, $file: Upload!) {
    uploadSignedDocument(documentId: $documentId, file: $file) {
      ...document
    }
  }
  ${documentFragment}
`

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: ID!) {
    deleteDocument(id: $id) {
      ...document
    }
  }
  ${documentFragment}
`
