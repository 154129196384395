// EXTERNAL
import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

// INTERNAL
import BodyParagraph from 'components/atoms/body-paragraph'
import NewDropdownSelect from 'components/molecules/new-dropdown-select'

const BpmCalculationResultAppraiserForm = ({
  appraiserItems,
  appraiserId,
  setAppraiserId,
}) => {
  const { t } = useTranslation()

  return appraiserItems ? (
    <>
      <BodyParagraph type="ExplanationParagraph">
        {t('bpmCalculation.askOneOfOurTaxationPartners')}
      </BodyParagraph>
      {Array.isArray(appraiserItems) && appraiserItems.length && (
        <NewDropdownSelect
          id="appraisal"
          items={appraiserItems}
          onChange={setAppraiserId}
          label={t('bpmCalculation.selectTaxationPartner')}
          value={appraiserId}
          selectionRequired
        />
      )}
    </>
  ) : null
}

BpmCalculationResultAppraiserForm.propTypes = {
  appraiserItems: PropTypes.array,
  setAppraiserId: PropTypes.func.isRequired,
  appraiserId: PropTypes.any,
}

BpmCalculationResultAppraiserForm.defaultProps = {
  appraiserItems: [],
  appraiserId: '',
}

export default BpmCalculationResultAppraiserForm
