import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.sizings.lvl3};
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

/**
 * Note: Only use this component in the context of the management info page.
 * It's seperated into a molecule because it's being re-used in different charts,
 * yet it's not meant to be used outside of the management info page.
 */
function ManagementInfoChartControls({ className, children }) {
  return <Container className={className}>{children}</Container>
}

ManagementInfoChartControls.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ManagementInfoChartControls
