import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useLocation, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isSubmitting } from 'redux-form'

import * as routes from 'config/routes'

import { media } from 'utilities/styled'
import { getBackendNapValue, scrollToTop } from 'utilities/utils'
import { mapCarFileType } from 'utilities/mapping'

import { getCarfile } from 'redux/actions/data'

import LoadingIndicator from 'components/atoms/loading-indicator'
import PageHeader from 'components/organisms/page-header'
import CarEntryTabs from 'components/organisms/car-entry-tabs'
import CarEntryHeader from 'components/molecules/car-entry-header'
import FlowStepper from 'components/molecules/flow-stepper'
import FullWidthTopBarLayout from 'components/layouts/full-width-top-bar-layout'
import Link from 'components/atoms/link'

const Container = styled.div`
  padding: ${({ theme }) => theme.sizings.lvl2} 20px;
  ${media.desktop`
      padding: ${({ theme }) => theme.sizings.lvl2} 60px;
  `}
`

const StyledPageHeader = styled(PageHeader)`
  padding: 0 0 ${({ theme }) => theme.sizings.lvl4};
  ${media.tablet`
    padding: ${({ theme }) => theme.sizings.lvl3} 0;
  `}
`

const StyledCarEntryHeader = styled(CarEntryHeader)`
  border-bottom: ${({ theme }) =>
    `${theme.colors.darkOnLightBorder} 1px solid`};
`

function CarEntryStep2({
  createCarfile,
  forms,
  getForms,
  getNap,
  match,
  nap,
  updateCarfile,
  carFileId,
  carFile,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const carFileLoading = useSelector((state) => {
    const { data } = state
    return (
      (data.createCarfile && data.createCarfile.loading) ||
      (data.updateCarfile && data.updateCarfile.loading)
    )
  })
  const isFormSubmitting = useSelector(
    (state) => state?.form?.carEntry && isSubmitting('carEntry')(state),
  )

  const stepperItems = [
    {
      active: location.pathname === `${match.url}/basic`,
      label: t('basicData'),
      link: `${match.url}/basic`,
    },
    {
      active: location.pathname === `${match.url}/specifications`,
      disabled: location.pathname === `${match.url}/basic`,
      label: t('specifications'),
      link: `${match.url}/specifications`,
    },
    {
      active: location.pathname === `${match.url}/history`,
      disabled: location.pathname === `${match.url}/basic`,
      label: t('history'),
      link: `${match.url}/history`,
    },
    {
      active: location.pathname === `${match.url}/equipment`,
      disabled: location.pathname === `${match.url}/basic`,
      label: t('equipment'),
      link: `${match.url}/equipment`,
    },
  ]

  const napValue = nap && nap.data && nap.data.nap
  const napBackendValue = getBackendNapValue(napValue)
  const car = {
    ...carFile,
    nap: napBackendValue,
  }
  const formId = 'carEntry'

  useEffect(() => {
    if (carFileId && !carFile) {
      dispatch(getCarfile(carFileId))
    }
  }, [carFile, carFileId, dispatch])

  useEffect(() => {
    getForms('carfile', { kenteken: car.kenteken, auto_id: carFileId })
    // has no dependencies, so it will only run once on componentDidMount and
    // on at that time. This ensures that a clean version of the forms config
    // is retrieved for car entry, otherwise that forms for the last visited
    // carFile are used.
    // documented in: https://app.clickup.com/t/6unbvp
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!forms) {
      getForms('carfile', { kenteken: car.kenteken, auto_id: carFileId })
    }
  }, [car.kenteken, carFileId, forms, getForms])

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <Container>
      <StyledPageHeader
        mainTitle={mapCarFileType(t, car?.voertuigsoort_mapped)}
        status={t('carFileEnrichment.titlePart2')}
        subtitle={t('step22AddData')}
        explanationContent={
          <Link to={`${routes.CAR_FILE}/${carFileId}`}>
            {t('carFileEnrichment.carFileExplanation', { carFileId })}
          </Link>
        }
      />
      <FullWidthTopBarLayout
        paddingLevelTopBar={2}
        paddingLevelMain={0}
        topBarNoPaddingSlot={
          <StyledCarEntryHeader
            brand={car.merk}
            model={car.model}
            type={car.type}
            licensePlate={car.kenteken}
            mileage={car.km_binnen}
            getNap={getNap}
            nap={nap}
            buttonTo={`${routes.CAR_ENRICHMENT}/${carFileId}/1`}
          />
        }
        topBarSlot={<FlowStepper items={stepperItems} />}
        mainSlot={
          !forms || forms.loading || carFileLoading || isFormSubmitting ? (
            <LoadingIndicator />
          ) : (
            <CarEntryTabs
              car={car}
              createCarfile={createCarfile}
              formId={formId}
              forms={forms.data}
              getForms={getForms}
              updateCarfile={updateCarfile}
            />
          )
        }
      />
    </Container>
  )
}

CarEntryStep2.propTypes = {
  createCarfile: PropTypes.func.isRequired,
  forms: PropTypes.object,
  getForms: PropTypes.func.isRequired,
  getNap: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  nap: PropTypes.object,
  updateCarfile: PropTypes.func.isRequired,
  carFileId: PropTypes.string.isRequired,
  carFile: PropTypes.object.isRequired,
}

CarEntryStep2.defaultProps = {
  forms: null,
  nap: undefined,
}

export default withRouter(CarEntryStep2)
