import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import EnhancedCard from './enhanced-card'

const TopBar = styled(EnhancedCard)`
  // 20px is hardcoded to counter <Container> (search "theme.sizings.lvl2} 20px;" troughout the app)
  margin: -${({ theme }) => theme.sizings.lvl2} -20px
    ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
      margin: 0 0 19px;
    `};
`
const MainContent = styled(EnhancedCard)`
  overflow: visible;
  margin: 0 -20px;

  ${media.tablet`
      margin: 0;
    `};
`

const FullWidthTopBarLayout = ({
  topBarSlot,
  topBarNoPaddingSlot,
  mainSlot,
  paddingLevelTopBar,
  paddingLevelMain,
}) => (
  <>
    {(topBarSlot || topBarNoPaddingSlot) && (
      <TopBar
        className="TopBar"
        media={topBarNoPaddingSlot}
        paddingLevel={paddingLevelTopBar || paddingLevelMain}
      >
        {topBarSlot}
      </TopBar>
    )}
    <MainContent paddingLevel={paddingLevelMain} className="MainContent">
      {mainSlot}
    </MainContent>
  </>
)

FullWidthTopBarLayout.propTypes = {
  topBarSlot: PropTypes.node,
  topBarNoPaddingSlot: PropTypes.node,
  mainSlot: PropTypes.node.isRequired,
  /** Padding level refers to the sizings in theme.js */
  paddingLevelTopBar: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  paddingLevelMain: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
}

FullWidthTopBarLayout.defaultProps = {
  topBarSlot: null,
  topBarNoPaddingSlot: null,
  paddingLevelTopBar: undefined,
  paddingLevelMain: 4,
}

export default FullWidthTopBarLayout
