/* eslint-disable camelcase */
import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import ContentSeparator from 'components/atoms/content-separator'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Tooltip from 'components/atoms/new-tooltip'
import Button from 'components/atoms/button'
import Icon from 'components/atoms/icon'
import Checkbox from 'components/atoms/new-checkbox'
import Label from 'components/atoms/label'
import Typography from 'components/molecules/typography'
import FlexibleDialog from 'components/molecules/flexible-dialog'
import TypeaheadTextInput from 'components/molecules/typeahead-text-input'
import GeneralDataError from 'components/organisms/general-data-error'
import EnhancedTable, {
  TableDataText,
} from 'components/organisms/enhanced-table'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`
const Title = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`
const AsyncWarningContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`
const StyledTypeaheadTextInput = styled(TypeaheadTextInput)`
  min-width: 330px;
  .MuiAutocomplete-popupIndicatorOpen {
    transform: rotate(0deg);
  }
`
const StyledActionButton = styled(Button)`
  float: right;
`
const StyledTableDataText = styled(TableDataText)`
  text-transform: capitalize;
`
const ActionsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
`
const StyledContentSeparators = styled(ContentSeparator)`
  height: auto;
`
const SaveButton = styled(Button)`
  margin-top: ${({ theme }) => theme.sizings.lvl2};
  margin-left: auto;
`

export const statusColors = {
  active: 'statusOk',
  inactive: 'statusErrorLvl1',
  default: 'statusNeutral',
}

/**
 * Layout for all settings tab which allows user to enable and disable portals
 */
function PortalsSettings({
  getPortals,
  getActivePortals,
  portals,
  activePortals,
  getPortalPreaddConfirmation,
  portalPreaddConfirmation,
  addPortal,
  removePortal,
  addAllVehiclesToPortal,
  removeAllVehiclesFromPortal,
  setPortalDefault,
  params,
}) {
  const { t } = useTranslation()
  const [tableRows, setTableRows] = useState([])
  const [stagingAddPortal, setStagingAddPortal] = useState(null)
  const [stagingRemovePortal, setStagingRemovePortal] = useState(null)
  const [stagingAddAllVehiclesPortal, setStagingAddAllVehiclesPortal] =
    useState(null)
  const [stagingRemoveAllVehiclesPortal, setStagingRemoveAllVehiclesPortal] =
    useState(null)
  const [activePortalsState, setActivePortalsState] = useState([])
  const [modifiedPortals, setModifiedPortals] = useState([])
  // Used to control the opening/closing of typeahead results
  const [portalSearchValue, setPortalSearchValue] = useState('')
  const { vestiging_id } = params

  const loading =
    !portals ||
    !activePortals ||
    (portals && portals.loading) ||
    (activePortals && activePortals.loading)

  const tableColumns = [
    {
      id: 'description',
      label: t('description'),
    },
    {
      id: 'vehiclesAmount',
      label: t('vehiclesAmount'),
    },
    {
      id: 'defaultOn',
      label: t('defaultOn'),
      tooltip: t('defaultOnPortalsTooltip'),
    },
    {
      id: 'group',
      label: t('group'),
      tooltip: t('groupPortalsTooltip'),
    },
    {
      id: 'status',
      label: t('status'),
    },
    {
      id: 'actions',
      label: t('actions'),
      alignRight: true,
    },
  ]

  const onDefaultOnChangeSubmit = () => {
    const oldActivePortals = activePortals.data
    const changedPortals = activePortalsState.filter(
      (newActivePortal) =>
        oldActivePortals.find(
          (oldActivePortal) => oldActivePortal.id === newActivePortal.id,
        ).defaultOn !== newActivePortal.defaultOn,
    )

    const portaldefault = {}
    changedPortals.forEach((changedPortal) => {
      portaldefault[`default_${changedPortal.id}`] =
        changedPortal.defaultOn === '1'
    })
    setPortalDefault({ portaldefault }, { vestiging_id })
  }

  const createTableRow = useCallback(
    (portal) => {
      const { id, omschrijving, selectie, status, groep, defaultOn, nobulk } =
        portal
      const disabled = modifiedPortals.includes(id)

      return {
        description: {
          component: <StyledTableDataText>{omschrijving}</StyledTableDataText>,
          data: omschrijving,
        },
        vehiclesAmount: {
          component: <StyledTableDataText>{selectie}</StyledTableDataText>,
          data: selectie,
        },
        defaultOn: {
          component: (
            // eslint-disable-next-line react/jsx-indent
            <Checkbox
              checked={defaultOn === '1'}
              disabled={nobulk || disabled}
              id={`${id}_defaultOn_checkbox`}
              onChange={(val) => {
                setActivePortalsState((portals) =>
                  portals.map((portal) =>
                    portal.id === id
                      ? { ...portal, defaultOn: val ? '1' : '0' }
                      : portal,
                  ),
                )
              }}
            />
          ),
          data: defaultOn,
        },
        group: {
          component: <StyledTableDataText>{groep}</StyledTableDataText>,
          data: groep,
        },
        status: {
          component: status ? (
            <StyledTableDataText>
              <Label
                backgroundColor={statusColors[status] || statusColors.default}
                text={t(status)}
              />
            </StyledTableDataText>
          ) : (
            <></>
          ),
          data: status,
        },
        actions: {
          component: (
            <ActionsContainer>
              <Tooltip
                title={
                  nobulk
                    ? t('featureNotAvailableForThisPortal')
                    : t('advertiseAllCars')
                }
                arrow
                placement="left"
              >
                <StyledActionButton
                  iconColor={
                    nobulk || disabled ? 'inactiveTextColor' : 'actionsStandard'
                  }
                  disabled={nobulk || disabled}
                  type="submit"
                  level="option"
                  icon="import"
                  onClick={() => setStagingAddAllVehiclesPortal(portal)}
                />
              </Tooltip>
              <StyledContentSeparators variant="vertical" paddingLevel={1} />
              <Tooltip
                title={t('removeAllCarsFromPlatform')}
                arrow
                placement="left"
              >
                <StyledActionButton
                  iconColor={disabled ? 'inactiveTextColor' : 'actionsStandard'}
                  disabled={disabled}
                  type="submit"
                  level="option"
                  icon="export"
                  onClick={() => setStagingRemoveAllVehiclesPortal(portal)}
                />
              </Tooltip>
              <StyledContentSeparators variant="vertical" paddingLevel={1} />
              <Tooltip title={t('deactivatePortal')} arrow placement="left">
                <StyledActionButton
                  iconColor="actionsStandard"
                  type="submit"
                  level="option"
                  icon="forbidden"
                  onClick={() => setStagingRemovePortal(portal)}
                />
              </Tooltip>
            </ActionsContainer>
          ),
          data: 'action',
          alignRight: true,
        },
      }
    },
    [modifiedPortals, t],
  )

  useEffect(() => {
    if (activePortalsState && activePortalsState.length > 0) {
      const rows = activePortalsState.map((portal) => createTableRow(portal))
      setTableRows(rows)
    }
  }, [createTableRow, activePortalsState])

  useEffect(() => {
    getPortals({
      vestiging_id,
    })
    getActivePortals({
      vestiging_id,
    })
  }, [getPortals, getActivePortals, vestiging_id])

  useEffect(() => {
    if (activePortals && activePortals.data && activePortals.data.length) {
      // Make a clone of the active portals data. No direct manipulation of Redux state objects!
      setActivePortalsState(activePortals.data.map((portal) => ({ ...portal })))
    }
  }, [activePortals])

  useEffect(() => {
    if (stagingAddPortal && stagingAddPortal.id) {
      getPortalPreaddConfirmation({
        portal_id: stagingAddPortal.id,
        vestiging_id,
      })
    }
  }, [stagingAddPortal, vestiging_id, getPortalPreaddConfirmation])

  return (
    <>
      <HeaderContainer>
        <Title type="Level1Heading">{t('activePortals')}</Title>
        {!loading && (
          <StyledTypeaheadTextInput
            open={!!portalSearchValue}
            popupIcon={<Icon type="search" />}
            options={portals.data}
            getOptionLabel={(option) => option.omschrijving}
            onInputChange={(e, val) => setPortalSearchValue(val)}
            onChange={(portal) => {
              setPortalSearchValue('')
              setStagingAddPortal(portal)
            }}
            filterOptions={(options, state) => {
              const re = new RegExp(`^${state.inputValue}`, 'i')
              return options.filter((option) => option.omschrijving.match(re))
            }}
            filled
            label={t('searchPortalsToActivate')}
          />
        )}
      </HeaderContainer>

      {modifiedPortals.length > 0 && (
        <AsyncWarningContainer>
          <Typography type="ErrorText">
            {t('actionExecutedInMinutes')}
          </Typography>
        </AsyncWarningContainer>
      )}

      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {portals && portals.data ? (
            <>
              <EnhancedTable
                selectable={false}
                columns={tableColumns}
                rows={tableRows}
              />
              <SaveButton
                text={t('saveChanges')}
                level="cta"
                onClick={onDefaultOnChangeSubmit}
              />
            </>
          ) : (
            <GeneralDataError />
          )}
        </>
      )}

      {stagingAddPortal && (
        <FlexibleDialog
          content={
            portalPreaddConfirmation && !portalPreaddConfirmation.loading ? (
              <Typography type="BodyParagraph">
                {portalPreaddConfirmation.data.tekst}
              </Typography>
            ) : (
              <LoadingIndicator />
            )
          }
          onCancel={() => setStagingAddPortal(null)}
          cancelText={t('cancel')}
          onSubmit={() => {
            addPortal({ portal_id: stagingAddPortal.id, vestiging_id })
            setStagingAddPortal(null)
          }}
          submitText={t('excecute')}
          title={t('activation')}
          cancel
          open
        />
      )}

      {stagingRemovePortal && (
        <FlexibleDialog
          content={
            <>
              <Typography type="BodyParagraph">
                {t('portalDeactivateConfirmMessage')}
              </Typography>
              <Typography type="BodyParagraph">
                {t('actionExecutedInMinutes')}
              </Typography>
            </>
          }
          onCancel={() => setStagingRemovePortal(null)}
          cancelText={t('cancel')}
          onSubmit={() => {
            removePortal({ portal_id: stagingRemovePortal.id, vestiging_id })
            setModifiedPortals((oldState) => [
              ...oldState,
              stagingRemovePortal.id,
            ])
            setStagingRemovePortal(null)
          }}
          submitText={t('portalDeactivate')}
          title={t('portalDeactivateTitle', {
            name: stagingRemovePortal.omschrijving,
          })}
          cancel
          open
        />
      )}

      {stagingAddAllVehiclesPortal && (
        <FlexibleDialog
          content={
            <>
              <Typography type="BodyParagraph">
                {t('actionConfirmMessage')}
              </Typography>
              <Typography type="BodyParagraph">
                {t('actionExecutedInMinutes')}
              </Typography>
            </>
          }
          onCancel={() => setStagingAddAllVehiclesPortal(null)}
          cancelText={t('cancel')}
          onSubmit={() => {
            addAllVehiclesToPortal({
              portal_id: stagingAddAllVehiclesPortal.id,
              vestiging_id,
            })
            setModifiedPortals((oldState) => [
              ...oldState,
              stagingAddAllVehiclesPortal.id,
            ])
            setStagingAddAllVehiclesPortal(null)
          }}
          submitText={t('actionConfirm')}
          title={t('portalAddAllVehiclesTitle', {
            name: stagingAddAllVehiclesPortal.omschrijving,
          })}
          cancel
          open
        />
      )}

      {stagingRemoveAllVehiclesPortal && (
        <FlexibleDialog
          content={
            <>
              <Typography type="BodyParagraph">
                {t('actionConfirmMessage')}
              </Typography>
              <Typography type="BodyParagraph">
                {t('actionExecutedInMinutes')}
              </Typography>
            </>
          }
          onCancel={() => setStagingRemoveAllVehiclesPortal(null)}
          cancelText={t('cancel')}
          onSubmit={() => {
            removeAllVehiclesFromPortal({
              portal_id: stagingRemoveAllVehiclesPortal.id,
              vestiging_id,
            })
            setModifiedPortals((oldState) => [
              ...oldState,
              stagingRemoveAllVehiclesPortal.id,
            ])
            setStagingRemoveAllVehiclesPortal(null)
          }}
          submitText={t('actionConfirm')}
          title={t('portalRemoveAllVehiclesTitle', {
            name: stagingRemoveAllVehiclesPortal.omschrijving,
          })}
          cancel
          open
        />
      )}
    </>
  )
}

PortalsSettings.propTypes = {
  getPortals: PropTypes.func.isRequired,
  getActivePortals: PropTypes.func.isRequired,
  portals: PropTypes.object,
  activePortals: PropTypes.object,
  getPortalPreaddConfirmation: PropTypes.func.isRequired,
  addPortal: PropTypes.func.isRequired,
  removePortal: PropTypes.func.isRequired,
  addAllVehiclesToPortal: PropTypes.func.isRequired,
  removeAllVehiclesFromPortal: PropTypes.func.isRequired,
  setPortalDefault: PropTypes.func.isRequired,
  portalPreaddConfirmation: PropTypes.object,
  params: PropTypes.shape({
    vestiging_id: PropTypes.string.isRequired,
  }).isRequired,
}

PortalsSettings.defaultProps = {
  portals: null,
  activePortals: null,
  portalPreaddConfirmation: null,
}

export default PortalsSettings
