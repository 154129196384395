import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

import ContentSeparator from 'components/atoms/content-separator'
import NewDropdownSelect from 'components/molecules/new-dropdown-select'
import Typography from 'components/molecules/typography'
import LineAndBarChart from 'components/molecules/line-and-bar-chart'
import EventsTimeline from 'components/molecules/events-timeline'
import LegendBoxes from 'components/molecules/legend-boxes'
import LegendBox from 'components/molecules/legend-box'
import GeneralDataError from 'components/organisms/general-data-error'

const StyledEventsTimeline = styled(EventsTimeline)`
  margin: 0 10px;
`

const TimelineInPeriodsChartContainer = styled.article`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 16px;
`

const ResultsTitle = styled(Typography)`
  margin: 0;
`
const PeriodFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StyledNewDropdownSelect = styled(NewDropdownSelect)`
  min-width: 120px;
  width: 33%;
`

/**
 * Display a set of consecutive events grouped by periods, such as weeks.
 * This way the information about a period can be shown as bars and points on a line.
 *
 * Under the hood this component uses chart.js which allows for a great degree of flexibility
 * on how the data is provided to the component. The only crucial thing is to point
 * out how the data should be shown within a period. As part of a bar (within the period)
 * or of a point on a line (spanning all periods).
 *
 * Each period can furthermore be annotated with a marker to point out key events for that period.
 * To point out the current day on the time a special marker is shown,
 * which can be overruled with a custom element.
 */
function TimelineInPeriodsChart({
  startTicksAtTime,
  mainHeading,
  linesHeading,
  barsHeading,
  datasets,
  keyEvents,
  barLegendsSlot,
  lowerCornerMessage,
  showPeriodFilter,
  periodFilterOptions,
}) {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  let periodsFilterOptions = [{ value: 0, label: t('wholePeriod') }]
  if (showPeriodFilter) {
    periodsFilterOptions = [
      ...periodsFilterOptions,
      ...periodFilterOptions.map((periodLength) => ({
        value: periodLength,
        label: t('lastXWeeks', { periodLength }),
      })),
    ]
  }

  const [periodFilter, setPeriodFilter] = useState(
    periodsFilterOptions[periodsFilterOptions.length - 1].value,
  )
  const [disabledDatasets, setDisabledDatasets] = useState(
    new Set(['Mijn inkoopprijs', 'AUTOTELEX']),
  )
  const [selectedBarSegments, setSelectedBarSegments] = useState(
    datasets
      .filter((set) => set.displayedAs === 'BAR' && Array.isArray(set.segments))
      .map((set) => ({
        stack: set.label,
        segments: set.segments.map((segment) => ({
          label: segment.label,
          id: segment.label,
          selected: !disabledDatasets.has(set.label),
        })),
      })),
  )

  useEffect(() => {
    setSelectedBarSegments(
      datasets
        .filter(
          (set) => set.displayedAs === 'BAR' && Array.isArray(set.segments),
        )
        .map((set) => ({
          stack: set.label,
          segments: set.segments.map((segment) => ({
            label: segment.label,
            id: segment.label,
            selected: !disabledDatasets.has(set.label),
          })),
        })),
    )
  }, [datasets, setSelectedBarSegments, disabledDatasets])

  const setSelectedDataset = (datasetLabel, needsToBeShown) => {
    if (needsToBeShown) {
      disabledDatasets.delete(datasetLabel)
    } else {
      disabledDatasets.add(datasetLabel)
    }
    setDisabledDatasets(new Set(disabledDatasets))
  }

  const setSelectedSegment = (stack, updatedSegmentSelection) => {
    const newConfig = [...selectedBarSegments]
    const index = newConfig.findIndex((dataset) => dataset.stack === stack)
    newConfig[index].segments = updatedSegmentSelection
    setSelectedBarSegments(newConfig)
  }

  if (datasets.length) {
    // Get all the bar times in on array
    const allBarTimes = datasets
      .filter((set) => set.displayedAs === 'BAR')
      .map((set) =>
        []
          .concat(...set.segments.map((segment) => segment.data))
          .map((datum) => datum.x),
      )
      .flat()
    // Get all the times for all data types in one array
    const allTimes = [startTicksAtTime].concat(
      ...datasets
        .filter((set) => set.displayedAs === 'LINE')
        .map((set) => set.data.map((datum) => datum.x)),
      ...allBarTimes,
      ...keyEvents.map((datum) => datum.x),
    )

    allTimes.sort((a, b) => a - b) // earlier to later.
    const allUniqueSortedBarTimes = [...new Set(allBarTimes)]
      .map((time) => moment(time))
      .sort((a, b) => a - b)

    // Get the full time range based on all times
    const today = new Date()
    const fullTimeRange = [
      periodFilter
        ? moment(today).endOf('week').subtract(periodFilter, 'weeks')
        : moment(allTimes[0]).startOf('week'),
      moment(today).endOf('week'),
    ]

    // Create dataset that chartjs can understand
    const lineDataSets = datasets
      .filter((dataset) => dataset.displayedAs === 'LINE')
      .map((dataset) => ({
        ...dataset,
        type: 'line',
      }))

    // Create dataset that chartjs can understand
    const barsDataSets = []
    datasets
      .filter((dataset) => dataset.displayedAs === 'BAR')
      .forEach((dataset) => {
        const baseColour = theme.chartColorScheme[dataset.color]
        const getSegmentColour = d3
          .scaleLinear()
          .domain([0, dataset.segments.length - 1])
          .range([255, 100])

        dataset.segments.forEach((segment, segmentIndex) => {
          barsDataSets.push({
            // inform chart.js of which 'stack' this is part of:
            stack: dataset.label,
            label: segment.label,
            /**
             *  Due to https://github.com/chartjs/Chart.js/issues/5405 bug,
             *  for missing dates that exist on a different dataset,
             *  but on this data set dont have a value, we have to include y:0
             *  Otherwise stacked bars are hovering
             * */
            data: allUniqueSortedBarTimes.map((time) => {
              const timeFormatted = time.format('YYYY-MM-DD')
              return (
                segment.data.find((data) => data.x === timeFormatted) || {
                  x: timeFormatted,
                  y: 0,
                }
              )
            }),
            type: 'bar',
            color: `${baseColour}${parseInt(getSegmentColour(segmentIndex), 10).toString(16)}`,
          })
        })
      })

    return (
      <TimelineInPeriodsChartContainer>
        {mainHeading && (
          <Typography type="Level1Heading">{mainHeading}</Typography>
        )}
        {linesHeading && (
          <Typography type="Level2Heading">{linesHeading}</Typography>
        )}

        <LegendBoxes arrangeAs="row">
          <>
            {datasets
              .filter((set) => set.displayedAs === 'LINE')
              .map((dataset) => {
                // if extra legend props were provided via the legend prop
                // they are applied here:
                const extraProps = {}
                if (dataset.legend && Object.keys(dataset.legend).length > 0) {
                  Object.assign(extraProps, dataset.legend)
                }
                return (
                  <LegendBox
                    key={dataset.label}
                    legendColor={theme.chartColorScheme[dataset.color]}
                    label={dataset.label}
                    selected={!disabledDatasets.has(dataset.label)}
                    onSelect={(value) =>
                      setSelectedDataset(dataset.label, value)
                    }
                    {...extraProps}
                  />
                )
              })}
          </>
        </LegendBoxes>

        {barsHeading && (
          <Typography type="Level2Heading">{barsHeading}</Typography>
        )}
        <LegendBoxes arrangeAs="row">
          <>
            {datasets
              .filter((set) => set.displayedAs === 'BAR')
              .map((dataset) => (
                <LegendBox
                  key={dataset.label}
                  legendColor={theme.chartColorScheme[dataset.color]}
                  label={t(dataset.label)}
                  selected={!disabledDatasets.has(dataset.label)}
                  onSelect={(value) => setSelectedDataset(dataset.label, value)}
                  subCategories={
                    selectedBarSegments.filter(
                      (datasetWithSegments) =>
                        datasetWithSegments.stack === dataset.label,
                    )[0].segments
                  }
                  onSelectSubCategories={(newSelection) =>
                    setSelectedSegment(dataset.label, newSelection)
                  }
                />
              ))}
            {barLegendsSlot && barLegendsSlot}
          </>
        </LegendBoxes>

        <ContentSeparator paddingLevel={1} />

        <PeriodFilterContainer>
          <ResultsTitle type="Level1Heading">
            {t('onlineTrafficMonitor')}
          </ResultsTitle>
          {showPeriodFilter && (
            <StyledNewDropdownSelect
              filled
              selectionRequired
              items={periodsFilterOptions}
              value={periodFilter}
              label={t('displayPeriod')}
              onChange={(val) => setPeriodFilter(val)}
            />
          )}
        </PeriodFilterContainer>

        <LineAndBarChart
          fullTimeRange={fullTimeRange}
          datasets={[
            // filter out datasets that have been unchecked in the legend boxes:
            ...lineDataSets.filter(
              (dataset) => !disabledDatasets.has(dataset.label),
            ),
            // for bar the identifier is stored in the 'stack' prop,
            // This helps chart.js identify to which bar stack a segment belongs:
            ...barsDataSets
              // filter out datasets that have been unchecked in the legend boxes:
              .filter((dataset) => {
                if (!disabledDatasets.has(dataset.stack)) {
                  const segments = selectedBarSegments.find(
                    (datasetWithSegments) =>
                      dataset.stack === datasetWithSegments.stack,
                  )
                  if (Array.isArray(segments.segments)) {
                    const matchingSegment = segments.segments.find(
                      (segment) => segment.label === dataset.label,
                    )
                    return matchingSegment ? matchingSegment.selected : false
                  }
                  return false
                }
                return false
              }),
          ]}
          chartOptions={{
            layout: {
              padding: {
                // Padding required because data label is clipped when bar is full height
                top: 20,
              },
            },
            plugins: {
              zoom: {
                pan: {
                  enabled: false,
                },
                zoom: {
                  enabled: false,
                },
              },
              datalabels: {
                anchor: 'end',
                align: 'top',
                display: (ctx) => {
                  const value = ctx.dataset.data[ctx.dataIndex]
                  return (
                    value &&
                    periodFilter !== 0 &&
                    periodFilter < 10 &&
                    !!ctx.dataset.stack &&
                    ctx.datasetIndex ===
                      ctx.chart.$stackTotalizer.utmost[ctx.dataset.stack]
                  )
                },
                formatter: (value, ctx) =>
                  ctx.chart.$stackTotalizer.totals[ctx.dataIndex][
                    ctx.dataset.stack
                  ],
              },
            },
          }}
        />
        {keyEvents.length && (
          <StyledEventsTimeline
            events={keyEvents}
            fullTimeRange={fullTimeRange}
            finalMessage={lowerCornerMessage}
          />
        )}
      </TimelineInPeriodsChartContainer>
    )
  }
  return <GeneralDataError />
}

TimelineInPeriodsChart.propTypes = {
  startTicksAtTime: PropTypes.string.isRequired,
  /** Can be omitted */
  mainHeading: PropTypes.string,
  linesHeading: PropTypes.string,
  barsHeading: PropTypes.string,
  datasets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      displayedAs: PropTypes.oneOf(['LINE', 'BAR']),
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
            .isRequired,
          y: PropTypes.any.isRequired,
        }),
      ),
    }),
  ),
  keyEvents: PropTypes.arrayOf(
    PropTypes.shape({
      atTime: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }),
  ),
  barLegendsSlot: PropTypes.node,
  /** optional message to show on the lower right corner */
  lowerCornerMessage: PropTypes.string,
  /** Show or hide the period filter */
  showPeriodFilter: PropTypes.bool,
  /** Array of numbers that will be used in the period selector. Eg 8 is the 8 last weeks */
  periodFilterOptions: PropTypes.arrayOf(PropTypes.number),
}

TimelineInPeriodsChart.defaultProps = {
  mainHeading: null,
  linesHeading: null,
  barsHeading: null,
  datasets: [],
  keyEvents: [],
  barLegendsSlot: null,
  lowerCornerMessage: null,
  showPeriodFilter: true,
  periodFilterOptions: [8],
}

export default TimelineInPeriodsChart
