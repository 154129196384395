import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import LocationsSettings from 'components/redux-containers/locations-settings-container'
import DeliverySegmentsSettings from 'components/redux-containers/delivery-segments-settings-container'
import DeliveryPackagesSettings from 'components/redux-containers/delivery-packages-settings-container'
import DeliveryPortalsSettings from 'components/redux-containers/delivery-portals-settings-container'
import DeliveryAccessoriesSettings from 'components/redux-containers/delivery-accessories-settings-container'

function LocationsDeliveryPackagesSettings({ match }) {
  const { t } = useTranslation()

  const tabItems = [
    {
      label: t('segments'),
      to: `${match.url}/segments`,
      component: (props) => <DeliverySegmentsSettings {...props} />,
    },
    {
      label: t('accessories'),
      to: `${match.url}/accessories`,
      component: (props) => <DeliveryAccessoriesSettings {...props} />,
    },
    {
      label: t('deliveryPackages'),
      to: `${match.url}/delivery-packages`,
      component: (props) => <DeliveryPackagesSettings {...props} />,
    },
    {
      label: t('portalSettings'),
      to: `${match.url}/portal-settings`,
      component: (props) => <DeliveryPortalsSettings {...props} />,
    },
  ]

  return (
    <LocationsSettings title={t('locationsOverview')} tabItems={tabItems} />
  )
}

LocationsDeliveryPackagesSettings.propTypes = {
  match: PropTypes.object.isRequired,
}

LocationsDeliveryPackagesSettings.defaultProps = {}

export default withRouter(LocationsDeliveryPackagesSettings)
