import { types } from 'redux/actions/data'

export const initialState = {}

export function reducer(state = initialState, action) {
  const { data, error, id, payload, type, params } = action

  switch (type) {
    case types.ADD_NEW_CAR_DATA: {
      const previousData = state.newCar ? state.newCar.data : {}
      return {
        ...state,
        newCar: {
          ...state.newCar,
          data: {
            ...previousData,
            ...data,
          },
        },
      }
    }

    case types.REQUEST_DATA: {
      if (id === 'b2bCarFileInterests') {
        const newItem = {
          carFileId: payload.id,
          loading: false,
          successful: true,
        }
        return {
          ...state,
          [id]: [
            ...(state[id] && Array.isArray(state[id]) ? state[id] : []),
            newItem,
          ],
        }
      } else {
        return {
          ...state,
          [id]: {
            ...state[id],
            error: null,
            loading: true,
            payload,
          },
        }
      }
    }

    case types.POST_DATA: {
      return {
        ...state,
        [id]: {
          ...state[id],
          error: null,
          loading: true,
          payload,
        },
      }
    }

    case types.RECEIVE_DATA: {
      if (id === 'b2bCarFileInterests') {
        const newItems = state[id]
        const indexToUpdate = newItems.findIndex(
          (item) => params.id === item.carFileId,
        )
        newItems[indexToUpdate] = {
          carFileId: params.id,
          loading: false,
          successful: true,
        }
        return {
          ...state,
          [id]: [...newItems],
        }
      } else {
        const newState = {
          ...state,
          [id]: {
            ...state[id],
            loading: false,
            data,
          },
        }
        if (id === 'carfile') {
          newState.accessoriesHighlights = data.accessoires
            .filter((acc) => acc.highlight)
            .map((acc) => acc.acc_id)
        }
        return newState
      }
    }

    case types.CLEAR_DATA: {
      return {
        ...state,
        [id]: {
          loading: false,
        },
      }
    }

    case types.FAIL_REQUEST: {
      return {
        ...state,
        [id]: {
          ...state[id],
          loading: false,
          error,
        },
      }
    }

    case types.SET_ACCESSORIES_HIGHLIGHT: {
      const { id, highlight } = payload
      const newAccessories = state.accessoriesHighlights || []
      return {
        ...state,
        accessoriesHighlights: highlight
          ? [...newAccessories, id]
          : newAccessories.filter((stateId) => stateId !== id),
      }
    }

    default:
      return state
  }
}
