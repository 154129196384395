import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation, Trans } from 'react-i18next'
import { useQuery } from '@apollo/client'

import { cspPackageTypes, warrantyPackageTypesPeriods } from 'config/data'
import { GET_CALCULATIONS, calculationSourceEnum } from 'config/graphql/csp'

import { formatMileage } from 'utilities/format'

import LoadingIndicator from 'components/atoms/loading-indicator'
import ContentSeperator from 'components/atoms/content-separator'
import Typography from 'components/molecules/typography'
import IconList from 'components/molecules/icon-list'
import PlanChoice from 'components/organisms/plan-choice'
import GeneralDataError from 'components/organisms/general-data-error'
import Button from 'components/atoms/button'

import { mapCspPackageTypeToCspPackageName } from '../../settings/car-service-plan/dealer-specific/main-settings'

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin: 0;
  }
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const PrintOverviewButton = styled(Button)`
  @media print {
    display: none;
  }
`

const IncludesExcludesExplanation = styled(Typography)`
  ${({ $oneServicePlan }) => ($oneServicePlan ? '' : 'text-align: center;')}
`

const ExceedMileageWarning = styled(Typography)`
  display: block;
  padding-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const ExcludesListContainer = styled.div`
  max-width: 700px;
  ${({ $oneServicePlan }) => ($oneServicePlan ? '' : 'margin: 0 auto;')}
`

const ServicePlanContainerWrapper = styled.div`
  overflow-x: auto;
  display: flex;
  justify-content: center;

  @media print {
    overflow-x: hidden;
  }
`

const ServicePlanContainer = styled.div`
  padding-top: ${({ theme }) => theme.sizings.lvl2};
  min-width: 800px;
  max-width: 1200px;
  display: grid;
  grid-template-columns: ${({ $columns }) =>
    $columns === 1 ? '1fr 2fr' : `repeat(${$columns}, 1fr)`};
  gap: 0px 40px;

  @media print {
    min-width: 0; // To hide the horizontal scrollbar when printing
  }
`

const ServicePlanInclusiveListExclusive = styled.section`
  flex: 2.5;
`

const ServicePlanInclusiveListExclusiveInner = styled.div`
  margin-left: ${({ theme }) => theme.sizings.lvl4};
`

const ServicePlanExclusiveList = ({ oneServicePlan, carServices }) => {
  const { t } = useTranslation()
  const exclusiveList = carServices.map((carService) => ({
    content: carService.commercialName,
  }))

  return (
    <>
      <IncludesExcludesExplanation
        type="ExplanationParagraph"
        $oneServicePlan={oneServicePlan}
      >
        <Trans>
          {oneServicePlan
            ? t('cspSalesFlow.steps.warrantyPlan.notIncludedNotification')
            : t('cspSalesFlow.steps.warrantyPlan.allNotIncludedNotification')}
        </Trans>
      </IncludesExcludesExplanation>
      <ExcludesListContainer $oneServicePlan={oneServicePlan}>
        <IconList
          items={exclusiveList}
          columns={2}
          icon="cross"
          iconColour="stateNegative"
        />
      </ExcludesListContainer>
    </>
  )
}
ServicePlanExclusiveList.propTypes = {
  oneServicePlan: PropTypes.bool,
  carServices: PropTypes.arrayOf(
    PropTypes.shape({
      commercialName: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
ServicePlanExclusiveList.defaultProps = {
  oneServicePlan: false,
}

const ServicePlanInclusiveList = ({
  servicePlanPlus,
  period,
  oneServicePlan,
  carServices,
  maxMileage,
  packageType,
}) => {
  const { t } = useTranslation()
  const inclusiveList = carServices
    .filter((carService) => carService.packageTypes.includes(packageType))
    .map((carService) =>
      carService.commercialName.map((groupedCommercialName) => ({
        content: groupedCommercialName,
      })),
    )
    .flat()

  if (servicePlanPlus && maxMileage) {
    inclusiveList.unshift({
      content: (
        <Trans>
          {t(
            `cspSalesFlow.steps.warrantyPlan.packageIncludesList.${period}monthWarrantyMileage`,
            { maxMileage: formatMileage(maxMileage) },
          )}
        </Trans>
      ),
    })
  }

  return (
    <>
      {oneServicePlan && (
        <Typography type="ExplanationParagraph">
          <Trans>
            {t('cspSalesFlow.steps.warrantyPlan.includedNotification')}
          </Trans>
        </Typography>
      )}
      <IconList
        items={inclusiveList}
        columns={oneServicePlan ? 2 : 1}
        icon="check"
        iconColour="statePositive"
      />
    </>
  )
}

ServicePlanInclusiveList.propTypes = {
  servicePlanPlus: PropTypes.bool,
  oneServicePlan: PropTypes.bool,
  period: PropTypes.number.isRequired,
  maxMileage: PropTypes.number,
  carServices: PropTypes.arrayOf(
    PropTypes.shape({
      commercialName: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
  packageType: PropTypes.string,
}

ServicePlanInclusiveList.defaultProps = {
  servicePlanPlus: false,
  oneServicePlan: false,
  maxMileage: undefined,
  packageTypes: '',
}

export const getServicePlanIcon = (packageType) => {
  switch (packageType) {
    case cspPackageTypes.LOYAL: {
      return 'oil'
    }
    case cspPackageTypes.BASIC: {
      return 'oil24'
    }
    case cspPackageTypes.PLUS_24_MONTHS: {
      return 'oil24plus'
    }
    default: {
      return 'oil'
    }
  }
}

export const getServicePlanIconWidth = (packageType) => {
  switch (packageType) {
    case cspPackageTypes.LOYAL: {
      return '49'
    }
    case cspPackageTypes.BASIC:
    case cspPackageTypes.PLUS_24_MONTHS: {
      return '85'
    }
    default: {
      return '49'
    }
  }
}

const CspSalesServicePlans = ({
  payload: {
    carId,
    dealerId,
    licensePlate,
    warrantyProgramId,
    currentMileage,
    mileagePerYear,
    hasFactoryWarranty,
    additionalFeeIds,
    additionalInput,
  },
  onSubmit,
}) => {
  const { data, loading, error } = useQuery(GET_CALCULATIONS, {
    variables: {
      dealerId,
      licensePlate,
      carId,
      warrantyProgramId,
      currentMileage,
      mileagePerYear,
      hasFactoryWarranty,
      // parameter in query has a typo (FeesIds vs FeeIds):
      additionalFeesIds: Array.isArray(additionalFeeIds)
        ? additionalFeeIds
        : [],
      source: calculationSourceEnum.flow,
      additionalInput: additionalInput
        ? Object.keys(additionalInput).map((additionalInputKey) => ({
            key: additionalInputKey,
            value: additionalInput[additionalInputKey],
          }))
        : [],
    },
  })
  const { t } = useTranslation()

  if (loading) {
    return <LoadingIndicator />
  }

  if (error) {
    return <GeneralDataError error={error.message} />
  }

  const servicePlans = data.calculations.results.map((servicePlan) => ({
    ...servicePlan,
    isServicePlanPlus: servicePlan.package.packageType.indexOf('PLUS') !== -1,
  }))
  const maxMileage = data.calculations.warrantyProgram
    ? data.calculations.warrantyProgram.maxMileage
    : null
  const mileagePerMonth = mileagePerYear / 12
  let exceedMileageMonthPrediction = 0
  if (maxMileage && currentMileage < maxMileage) {
    const remainingMileage = maxMileage - currentMileage
    exceedMileageMonthPrediction = Math.ceil(remainingMileage / mileagePerMonth)
  }

  const servicePlanSubmit = (servicePlan) => {
    if (onSubmit) {
      onSubmit({
        servicePlan,
        packageCalculationResultId: servicePlan.id,
        calculationId: data.calculations.id,
      })
    }
  }

  return (
    <>
      <HeadingContainer>
        <Typography type="Level1Heading">
          {servicePlans.length > 1
            ? t('cspSalesFlow.steps.warrantyPlan.headingMultipleChoices')
            : t('cspSalesFlow.steps.warrantyPlan.headingOneChoice')}
        </Typography>
        {data.calculations?.specificationDocument?.fullUri && (
          <PrintOverviewButton
            color="actionsStandard"
            text={t('printProposition')}
            href={data.calculations.specificationDocument.fullUri}
            data-test-e2e="button-print-plans"
            level="minimal"
          />
        )}
      </HeadingContainer>

      {/* If the user has selected a warranty program,
      AND it exceeds the prediction, show a warning */}
      {data.calculations.warrantyProgram &&
        exceedMileageMonthPrediction <= 12 && (
          <ExceedMileageWarning type="ErrorText">
            <Trans>
              {exceedMileageMonthPrediction === 0
                ? t('cspSalesFlow.steps.warrantyPlan.exceededMileageWarning')
                : t('cspSalesFlow.steps.warrantyPlan.exceedMileageWarning', {
                    month: t(`months.${exceedMileageMonthPrediction}`),
                  })}
            </Trans>
          </ExceedMileageWarning>
        )}

      {servicePlans.length > 1 ? (
        <>
          <ServicePlanContainerWrapper>
            <ServicePlanContainer $columns={servicePlans.length}>
              {servicePlans.map((servicePlan) => {
                const isHighlighted =
                  servicePlan.package.packageType === 'BASIC'

                return (
                  <PlanChoice
                    key={servicePlan.id}
                    id={servicePlan.id}
                    highlighted={isHighlighted}
                    icon={getServicePlanIcon(servicePlan.package.packageType)}
                    iconWidth={getServicePlanIconWidth(
                      servicePlan.package.packageType,
                    )}
                    heading={mapCspPackageTypeToCspPackageName(
                      servicePlan.package.packageType,
                    )}
                    body={
                      servicePlan.isServicePlanPlus
                        ? t(
                            'cspSalesFlow.steps.warrantyPlan.completeMaintenanceAndWarranty',
                            {
                              months:
                                warrantyPackageTypesPeriods[
                                  servicePlan.package.packageType
                                ],
                            },
                          )
                        : t(
                            'cspSalesFlow.steps.warrantyPlan.completeMaintenance',
                            {
                              months:
                                warrantyPackageTypesPeriods[
                                  servicePlan.package.packageType
                                ],
                            },
                          )
                    }
                    price={servicePlan.resultGross}
                    priceDiscounted={servicePlan.resultNet}
                    onSubmit={
                      onSubmit
                        ? () => servicePlanSubmit(servicePlan)
                        : undefined
                    }
                    data-test-e2e="plan-choice"
                  >
                    <ServicePlanInclusiveList
                      servicePlanPlus={servicePlan.isServicePlanPlus}
                      carServices={
                        servicePlan.package.carServicePlan.carServicesIncluded
                      }
                      period={
                        warrantyPackageTypesPeriods[
                          servicePlan.package.packageType
                        ]
                      }
                      maxMileage={maxMileage}
                      packageType={servicePlan.package.packageType}
                    />
                  </PlanChoice>
                )
              })}
            </ServicePlanContainer>
          </ServicePlanContainerWrapper>
          <ContentSeperator />
          <ServicePlanExclusiveList
            carServices={
              servicePlans[0].package.carServicePlan.carServicesExcluded
            }
          />
          <ContentSeperator />
        </>
      ) : (
        (() => {
          const servicePlan = servicePlans[0]
          return (
            <ServicePlanContainer $columns={servicePlans.length}>
              <PlanChoice
                id={servicePlan.id}
                highlighted
                singular
                icon="oil"
                iconWidth={servicePlans[0].isServicePlanPlus ? '85' : '70'}
                heading={
                  servicePlan.package.description || servicePlan.package.name
                }
                body={
                  servicePlans[0].isServicePlanPlus
                    ? t(
                        'cspSalesFlow.steps.warrantyPlan.completeMaintenanceAndWarranty',
                        {
                          months:
                            warrantyPackageTypesPeriods[
                              servicePlan.package.packageType
                            ],
                        },
                      )
                    : t('cspSalesFlow.steps.warrantyPlan.completeMaintenance', {
                        months:
                          warrantyPackageTypesPeriods[
                            servicePlan.package.packageType
                          ],
                      })
                }
                price={servicePlan.resultGross}
                priceDiscounted={servicePlan.resultNet}
                onSubmit={
                  onSubmit ? () => servicePlanSubmit(servicePlan) : undefined
                }
                data-test-e2e="plan-choice"
              />
              <ServicePlanInclusiveListExclusive>
                <ServicePlanInclusiveListExclusiveInner>
                  <ServicePlanInclusiveList
                    servicePlanPlus={false}
                    period={
                      warrantyPackageTypesPeriods[
                        servicePlan.package.packageType
                      ]
                    }
                    carServices={
                      servicePlan.package.carServicePlan.carServicesIncluded
                    }
                    oneServicePlan
                  />
                  <ContentSeperator />
                  <ServicePlanExclusiveList
                    oneServicePlan
                    carServices={
                      servicePlan.package.carServicePlan.carServicesExcluded
                    }
                  />
                </ServicePlanInclusiveListExclusiveInner>
              </ServicePlanInclusiveListExclusive>
            </ServicePlanContainer>
          )
        })()
      )}
    </>
  )
}

CspSalesServicePlans.propTypes = {
  payload: PropTypes.shape({
    carId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    // car: PropTypes.shape({
    //   id: PropTypes.string.isRequired,
    // }).isRequired,
    dealerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    licensePlate: PropTypes.string,
    warrantyProgramId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    currentMileage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    mileagePerYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    hasFactoryWarranty: PropTypes.bool,
    additionalFeeIds: PropTypes.any.isRequired,
    additionalInput: PropTypes.object,
  }).isRequired,
  onSubmit: PropTypes.func,
}

CspSalesServicePlans.defaultProps = {
  onSubmit: undefined,
}

export default CspSalesServicePlans
