import React, { useState } from 'react'
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import moment from 'moment'
import { media } from 'utilities/styled'
import NewDropdownSelect from 'components/molecules/new-dropdown-select'
import NewTextInput from 'components/atoms/new-text-input'
import NewDatePicker from 'components/atoms/new-date-picker'
import Icon from 'components/atoms/icon'
import FiltersOptionsBar from 'components/layouts/filters-options-bar'
import InvoicesTable, {
  ActionIconButton,
} from 'components/views/car-service-plan/invoices-table'
import TextLink from 'components/atoms/text-link'
import { useQuery } from '@apollo/client'
import { GET_INVOICES } from 'config/graphql/csp'
import LoadingIndicator from 'components/atoms/loading-indicator'
import GeneralDataError from 'components/organisms/general-data-error'
import {
  invoiceStatuses,
  invoiceStatusColors,
  getInvoiceStatusLabels,
} from 'config/data'

const ContractStatusSelect = styled(NewDropdownSelect)`
  align-self: stretch;
  ${media.tablet`
    order: 1;
  `}
  ${media.desktop`
    order: initial;
  `}
`

const StyledDatePicker = styled(NewDatePicker)`
  align-self: stretch;
  ${media.tablet`
    order: 3;
  `}
  ${media.desktop`
    order: initial;
  `}
`

const SearchQueryInput = styled(NewTextInput)`
  align-self: stretch;
  ${media.tablet`
    order: 2;
  `}
  ${media.desktop`
    order: initial;
  `}
`

const ResetFiltersBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
    justify-content: flex-end;
  `}
`

const { ALL } = invoiceStatuses

const formatInvoicesForInvoicesTable = (invoices, t) =>
  invoices.map((invoice) => {
    const { status, date, uri, ...restProps } = invoice

    const invoiceStatusLabels = getInvoiceStatusLabels(t)

    const actions = (
      <ActionIconButton
        icon="carFileCreatedExternally"
        iconSize="lg"
        onClick={() => {
          window.open(uri, '_blank')
        }}
      />
    )

    return {
      date: moment(invoice.date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      status: {
        label: invoiceStatusLabels[status],
        color: invoiceStatusColors[status],
      },
      actions,
      ...restProps,
    }
  })

const FilterableInvoicesTable = () => {
  const { t } = useTranslation()
  const [invoiceStatus, setInvoiceStatus] = useState(ALL)
  const [searchQuery, setSearchQuery] = useState('')
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()

  const { loading, error, data } = useQuery(GET_INVOICES)
  if (loading) {
    return <LoadingIndicator error={null} size="small" />
  }
  if (error) {
    console.error(error)
    return <GeneralDataError />
  }

  const handleResetFilters = () => {
    setInvoiceStatus(ALL)
    setSearchQuery('')
    setFromDate(null)
    setToDate(null)
  }

  const invoiceStatusLabels = getInvoiceStatusLabels(t)

  // Setup the values for the invoice status dropdown filter
  const invoiceStatusItems = Object.values(invoiceStatuses).map((status) => ({
    value: invoiceStatuses[status],
    label: invoiceStatusLabels[status],
  }))

  const filtersSet = invoiceStatus !== ALL || searchQuery || fromDate || toDate

  // Filter the results
  const filteredInvoices = data.invoices.edges
    // Normalize the data
    .map((edge) => ({
      dealer: edge.node.dealer.name,
      dealerId: edge.node.dealer.id,
      address: edge.node.dealer.address,
      city: edge.node.dealer.city,
      date: edge.node.date,
      status: edge.node.status,
      uri: edge.node.invoiceFullUri,
    }))
    // Filter by status
    .filter((row) => invoiceStatus === ALL || row.status === invoiceStatus)
    // Filter by from/to date
    .filter((row) => {
      if (!fromDate && !toDate) {
        return true
      } // No date filters set, no filtering required
      const rowDate = moment(row.date, 'YYYY-MM-DD')
      if (fromDate && rowDate.isBefore(fromDate)) {
        return false // The row's from date is before the filter's date
      }
      if (toDate && rowDate.isAfter(toDate)) {
        return false // The row's to date is after the filter's date
      }
      return true
    })
    .filter((row) => {
      // Filter the rows by search query
      if (!searchQuery) {
        return true
      } // No search query set, no filtering required
      // Look at every column's data prop and see if the search query occurs there
      const lowerCaseQuery = searchQuery.toLowerCase()
      return Object.keys(row).filter((column) => {
        // Try to convert the data to a string so the query can be checked
        try {
          const stringValue = row[column].toString()
          // Check to see if the search query occurs in the data value
          return stringValue.toLowerCase().indexOf(lowerCaseQuery) >= 0
        } catch (parseError) {
          // Not convertible to a string, so not searchable
          return false
        }
      }).length
    })
  const formattedRows = formatInvoicesForInvoicesTable(filteredInvoices, t)

  return (
    <>
      <FiltersOptionsBar>
        <ContractStatusSelect
          label={t('carServicePlanAdminMonitoringOverview.invoiceStatus')}
          filled
          onChange={setInvoiceStatus}
          value={invoiceStatus}
          selectionRequired
          items={invoiceStatusItems}
        />
        <StyledDatePicker
          name="fromDate"
          label={t('carServicePlanAdminMonitoringOverview.invoiceDateFrom')}
          onChange={setFromDate}
          value={fromDate}
          filled
        />
        <StyledDatePicker
          name="toDate"
          label={t('carServicePlanAdminMonitoringOverview.invoiceDateTo')}
          onChange={setToDate}
          value={toDate}
          filled
        />
        <SearchQueryInput
          label={t('carServicePlanAdminMonitoringOverview.searchForInvoices')}
          filled
          icon={<Icon type="search" />}
          onChange={setSearchQuery}
          value={searchQuery}
        />
      </FiltersOptionsBar>
      <ResetFiltersBar>
        <TextLink
          onClick={handleResetFilters}
          text={t('resetFilters')}
          disabled={!filtersSet}
        />
      </ResetFiltersBar>
      <InvoicesTable rows={formattedRows} />
    </>
  )
}

FilterableInvoicesTable.propTypes = {}

FilterableInvoicesTable.defaultProps = {}

export default FilterableInvoicesTable
