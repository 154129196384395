/*
 * Action creators very similar to the generic data action creators
 * but different in the sense that a lot of settings fetch the same
 * data for a different location or a different user for instance.
 * That's why the 'id' in data is replaced by 'setting' here and 'id'
 * for the settings actions is used to indicate for which location, user
 * or other sub category the data is fetched.
 */

import i18next from 'i18next'
import dataApi from 'utilities/settings-data'
import toast from 'utilities/toast'

// @TODO This loading toast code shows one toast for all
// loading processes until all are done but is extremely
// far from ideal especially because of the setTimeout
// of a seconds which is the least amount necessary for
// this to (visually) work (for it to be fixed within
// the timebox agreed).
const loadingToastId = 'loading'
const loadings = []

function triggerLoadingToast() {
  loadings.push(true)

  if (loadings.length > 1) {
    return
  }

  toast.info(i18next.t('loading'), {
    autoClose: false,
    toastId: loadingToastId,
  })
}

function killLoadingToast() {
  setTimeout(() => {
    loadings.pop()

    if (loadings.length) {
      return
    }

    toast.dismiss(loadingToastId)
  }, 1000)
}

export const types = {
  REQUEST_SETTING: 'REQUEST_SETTING',
  POST_SETTING: 'POST_SETTING',
  RECEIVE_SETTING: 'RECEIVE_SETTING',
  CLEAR_SETTING: 'CLEAR_SETTING',
  FAIL_SETTING_REQUEST: 'FAIL_SETTING_REQUEST',
}

function requestSetting(setting, id, payload) {
  return {
    type: types.REQUEST_SETTING,
    id,
    setting,
    payload,
  }
}

function postSetting(setting, id, payload) {
  return {
    type: types.POST_SETTING,
    id,
    setting,
    payload,
  }
}

function receiveSetting(setting, id, data) {
  return {
    type: types.RECEIVE_SETTING,
    id,
    setting,
    data,
  }
}

function failRequestSetting(setting, id, error) {
  return {
    type: types.FAIL_SETTING_REQUEST,
    id,
    setting,
    error,
  }
}

function getSetting(setting, id, apiMethod, data, showLoadingToast = true) {
  return (dispatch, getState) => {
    const instance = getState().auth.instanceId

    dispatch(requestSetting(setting, id, data))

    showLoadingToast && triggerLoadingToast()

    return apiMethod(instance, data)
      .then((response) => {
        if (!response) {
          showLoadingToast && killLoadingToast()
          dispatch(failRequestSetting(setting, id), 'no response')
          toast.error('no response')
          return Promise.reject(new Error('Could not get setting'))
        }

        dispatch(receiveSetting(setting, id, response))
        showLoadingToast && killLoadingToast()
        return Promise.resolve()
      })
      .catch((error) => {
        dispatch(failRequestSetting(setting, id, error))
        showLoadingToast && killLoadingToast()
        toast.error(error)
      })
  }
}

function setSetting(
  setting,
  id,
  apiMethod,
  data,
  callback,
  showLoadingToast = true,
) {
  return (dispatch, getState) => {
    const instance = getState().auth.instanceId

    dispatch(postSetting(setting, id, data))

    showLoadingToast && triggerLoadingToast(setting, id)

    return apiMethod(instance, data)
      .then((response) => {
        dispatch(receiveSetting(setting, id, response))

        if (callback) {
          callback(dispatch, response)
        }
        showLoadingToast && killLoadingToast()

        return Promise.resolve()
      })
      .catch((error) => {
        dispatch(failRequestSetting(setting, id, error))
        showLoadingToast && killLoadingToast()
        toast.error(error)
      })
  }
}

export function getBackgroundImage(data) {
  return getSetting(
    'backgroundImage',
    data.id,
    dataApi.getBackgroundImage,
    data,
  )
}

export function addBackgroundImage(data) {
  return setSetting(
    'addBackgroundImage',
    data.id,
    dataApi.addBackgroundImage,
    data,
    (dispatch) => dispatch(getBackgroundImage({ id: data.id })),
  )
}

export function getCompanyLogo(data) {
  return getSetting('companyLogo', data.id, dataApi.getCompanyLogo, data)
}

export function addCompanyLogo(data) {
  return setSetting(
    'addCompanyLogo',
    data.id,
    dataApi.addCompanyLogo,
    data,
    (dispatch) => dispatch(getCompanyLogo({ id: data.id })),
  )
}

export function addDeliveryAccessories(data) {
  return setSetting(
    'addDeliveryAccessories',
    data.id,
    dataApi.addDeliveryAccessories,
    data,
  )
}

export function getDeliveryAccessories(data) {
  return getSetting(
    'deliveryAccessories',
    data.id,
    dataApi.getDeliveryAccessories,
    data,
  )
}

export function getDeliverySegments(data) {
  return getSetting(
    'deliverySegments',
    data.id,
    dataApi.getDeliverySegments,
    data,
  )
}

export function getLibraryLayers() {
  return getSetting('libraryLayers', 'libraryLayers', dataApi.getLibraryLayers)
}
export function getCarPhotoLayers(data) {
  return getSetting('carPhotoLayers', data.id, dataApi.getCarPhotoLayers, data)
}

export function getCarPhotoLayersForCarFile(data) {
  return getSetting(
    'carPhotoLayers',
    data.id,
    dataApi.getCarPhotoLayersForCarFile,
    data,
  )
}

export function updateLayersSettings(data) {
  return setSetting(
    'updateLayersSettings',
    data.vestiging_id,
    dataApi.updateLayersSettings,
    data,
    (dispatch) =>
      dispatch(
        getCarPhotoLayers({
          id: data.vestiging_id,
          vestiging_id: data.vestiging_id,
        }),
      ),
  )
}

/**
 * Action to retrieve the UpdateUser form config:
 */
export function getUpdateUser(data) {
  return getSetting('updateUser', data.id, dataApi.getUpdateUser, data)
}

export function deleteDeliverySegment(data) {
  return setSetting(
    'deleteDeliverySegment',
    data.id,
    dataApi.deleteDeliverySegment,
    data,
    (dispatch) => dispatch(getDeliverySegments({ id: data.vestiging_id })),
  )
}

export function getDeliveryPackages(data) {
  return getSetting(
    'deliveryPackages',
    data.id,
    dataApi.getDeliveryPackages,
    data,
  )
}

export function getExpectedPhoto(data) {
  return getSetting('expectedPhoto', data.id, dataApi.getExpectedPhoto, data)
}

export function addExpectedPhoto(data) {
  return setSetting(
    'addExpectedPhoto',
    data.id,
    dataApi.addExpectedPhoto,
    data,
    (dispatch) => dispatch(getExpectedPhoto({ id: data.id })),
  )
}

// New methods
export function getExpectedPhotos(data, showLoadingToast) {
  return getSetting(
    'expectedPhotos',
    data.id,
    dataApi.getExpectedPhotos,
    data,
    showLoadingToast,
  )
}

export function addExpectedPhotos(data) {
  const loadingToast = toast.info('0 %')

  return setSetting(
    'addExpectedPhotos',
    data.id,
    dataApi.addExpectedPhotos,
    data,
    (dispatch) => {
      toast.update('success', loadingToast, 'Success', { autoClose: true })
      dispatch(getExpectedPhotos({ id: data.id }, false))
    },
    false,
  )
}

export function deleteExpectedPhotos(data) {
  const loadingToast = toast.info('0 %')

  return setSetting(
    'deleteExpectedPhotos',
    data.id,
    dataApi.deleteExpectedPhotos,
    data.imageIds,
    (dispatch) => {
      toast.update('success', loadingToast, 'Success', { autoClose: true })
      dispatch(getExpectedPhotos({ id: data.id }, false))
    },
    false,
  )
}

export function sortExpectedPhotos(data) {
  return setSetting(
    'sortExpectedPhotos',
    data.id,
    dataApi.sortExpectedPhotos,
    data.sortOrder,
    (dispatch) => {
      dispatch(getExpectedPhotos({ id: data.id }, false))
    },
    false,
  )
}

// /New methods

export function updateLayer(data) {
  return setSetting(
    'updateLayer',
    data.locationId,
    dataApi.updateLayer,
    data,
    (dispatch) =>
      dispatch(
        getCarPhotoLayers({
          id: data.locationId,
          vestiging_id: data.locationId,
        }),
      ),
  )
}
export function updateLayerWithLibrary(data) {
  return setSetting(
    'updateLayerWithLibrary',
    data.locationId,
    dataApi.updateLayerWithLibrary,
    data,
    (dispatch) =>
      dispatch(
        getCarPhotoLayers({
          id: data.locationId,
          vestiging_id: data.locationId,
        }),
      ),
  )
}

export function deleteLayer(data) {
  return setSetting(
    'deleteLayer',
    data.locationId,
    dataApi.deleteLayer,
    data,
    (dispatch) =>
      dispatch(
        getCarPhotoLayers({
          id: data.locationId,
          vestiging_id: data.locationId,
        }),
      ),
  )
}

export function getTransportCompanies(data) {
  return getSetting(
    'transportCompanies',
    data.id,
    dataApi.getTransportCompanies,
  )
}

export function deleteTransportCompany(data) {
  return setSetting(
    'deleteTransportCompany',
    data.id,
    dataApi.deleteTransportCompany,
    data,
    (dispatch) => dispatch(getTransportCompanies({ id: data.id })),
  )
}

export function getCleaningCompanies(data) {
  return getSetting('cleaningCompanies', data.id, dataApi.getCleaningCompanies)
}

export function getB2bPurchasingCompanies(data) {
  return getSetting(
    'b2bPurchasingCompanies',
    data.id,
    dataApi.getB2bPurchasingCompanies,
  )
}

export function deleteCleaningCompany(data) {
  return setSetting(
    'deleteCleaningCompany',
    data.id,
    dataApi.deleteCleaningCompany,
    data,
    (dispatch) => dispatch(getCleaningCompanies({ id: data.id })),
  )
}

export function deleteB2bPurchasingCompany(data) {
  return setSetting(
    'deleteB2bPurchasingCompany',
    data.id,
    dataApi.deleteB2bPurchasingCompany,
    data,
    (dispatch) => dispatch(getB2bPurchasingCompanies({ id: data.id })),
  )
}

export function deleteDeliveryPackage(data) {
  return setSetting(
    'deleteDeliveryPackage',
    data.id,
    dataApi.deleteDeliveryPackage,
    data,
    (dispatch) => dispatch(getDeliveryPackages({ id: data.vestiging_id })),
  )
}

export function getRdwCertificate(data) {
  return getSetting(
    'rdwCertificate',
    data.vestiging_id,
    dataApi.getRdwCertificate,
    data,
  )
}

export function uploadRdwCertificate(data, callback) {
  return setSetting(
    'uploadRdwCertificate',
    data.id,
    dataApi.uploadRdwCertificate,
    data,
    callback,
  )
}
