import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { FormikSelectInput } from 'components/molecules/formik-field'

const StyledDropDownSelect = styled(FormikSelectInput)`
  min-width: 120px;
`

const CspSalesBasicAdditionalInputField = ({ id, choices, label }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(String(choices[0].id))

  return (
    <Field
      name={`additionalInput.${id}`}
      items={choices.map((item) => ({
        label: item.label,
        value: String(item.id),
      }))}
      label={t(label)}
      component={StyledDropDownSelect}
      onChange={setValue}
      value={value}
      filled
      required
    />
  )
}

CspSalesBasicAdditionalInputField.propTypes = {
  id: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
}

export default CspSalesBasicAdditionalInputField
