import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import Dialog from 'components/molecules/flexible-dialog'
import { useTranslation, Trans } from 'react-i18next'
import Typography from 'components/molecules/typography'
import { contractContentsAvailablePlaceholders } from 'config/data'

const StyledTypography = styled(Typography)`
  &:first-child {
    margin-top: 0;
  }
`

const StyledDL = styled.dl`
  font-family: ${({ theme }) => theme.font};
  font-size: ${({ theme }) => theme.baseFontSize};
  font-weight: ${({ theme }) => theme.baseFontWeight};
  line-height: ${({ theme }) => theme.baseLineHeight};
  margin: 1em 0;
`

const inheritStyles = css`
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
`

const StyledTerm = styled.dt`
  ${inheritStyles}
  font-weight: 500;
`

const StyledDefinition = styled.dd`
  ${inheritStyles}
  font-weight: inherit;
  margin-bottom: ${({ theme }) => theme.sizings.lvl0};
`

const StyledLink = styled.a`
  ${inheritStyles}
  font-weight: inherit;

  &:active,
  &:visited {
    color: inherit;
  }
`

const InstructionsDialog = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      closeHandler={onClose}
      title={t('carServicePlanAdminContractContents.instructionsDialog.title')}
      open
      cancel
      cancelText={t('close')}
      content={
        <>
          <StyledTypography type="Level3Heading">
            {t(
              'carServicePlanAdminContractContents.instructionsDialog.markdownTitle',
            )}
          </StyledTypography>
          <StyledTypography type="BodyParagraph">
            <Trans
              i18nKey="CarServicePlanAdminContractContents.instructionsDialog.markdownInstructions"
              values={{
                linkText: t(
                  'carServicePlanAdminContractContents.instructionsDialog.linkText',
                ),
              }}
              components={[
                <StyledLink
                  key="trans-component-link"
                  href="https://daringfireball.net/projects/markdown/syntax"
                  target="_blank"
                >
                  linkText
                </StyledLink>,
              ]}
            />
          </StyledTypography>

          <StyledTypography type="Level3Heading">
            {t(
              'carServicePlanAdminContractContents.instructionsDialog.signatureSpecialCaseHeading',
            )}
          </StyledTypography>
          <StyledTypography type="BodyParagraph">
            {t(
              'carServicePlanAdminContractContents.instructionsDialog.markdownSignatureSpecialCase',
            )}
          </StyledTypography>

          <StyledTypography type="Level3Heading">
            {t(
              'carServicePlanAdminContractContents.instructionsDialog.placeholdersTitle',
            )}
          </StyledTypography>
          <StyledTypography type="BodyParagraph">
            {t(
              'carServicePlanAdminContractContents.instructionsDialog.placeholdersInstructions',
            )}
          </StyledTypography>
          <StyledDL>
            {contractContentsAvailablePlaceholders.map((placeholder) => (
              <React.Fragment key={placeholder}>
                <StyledTerm>{placeholder}</StyledTerm>
                <StyledDefinition>
                  {t(
                    `carServicePlanAdminContractContents.instructionsDialog.availablePlaceholders.${placeholder}`,
                  )}
                </StyledDefinition>
              </React.Fragment>
            ))}
          </StyledDL>
        </>
      }
    />
  )
}

InstructionsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
}

InstructionsDialog.defaultProps = {}

export default InstructionsDialog
