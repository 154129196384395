import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { media } from 'utilities/styled'

import { CarKindEnum, ManagementInfoStockDays123Enum } from 'config/enums'

import EnhancedCard from 'components/layouts/enhanced-card'
import ManagementInfoChartAverageStandtimeLast12Months from 'components/organisms/management-info/chart-average-stand-time-last-12-months'
import ManagementInfoChartTurnoverRateLast12Months from 'components/organisms/management-info/chart-turnover-rate-last-12-months'
import ManagementInfoChartStandtime123Last12Months from 'components/organisms/management-info/chart-standtime-123-last-12-months'
import ManagementInfoChartStockStandtime123Last12Months from 'components/organisms/management-info/chart-stock-standtime-123-last-12-months'
import ManagementInfoChartCapitalStandtime123Last12Months from 'components/organisms/management-info/chart-capital-standtime-123-last-12-months'

import { DEFAULT_VALUE_STANDTIME } from 'components/views/management-info/index'

const Grid = styled.section`
  ${media.tv`
    grid-template-columns: 1fr 1fr;
  `}

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.sizings.lvl3};
  padding-bottom: ${({ theme }) => theme.sizings.lvl8};
`

const ManagementInfoTabStandtime = ({ dealerNumbers }) => {
  const { t } = useTranslation()

  const carKindFilters = [
    {
      label: t('managementInfo.charts.carKindFilters.allCars'),
      value: 'ALL',
    },
    {
      label: t(
        'managementInfo.charts.carKindFilters.allCarsExcludingCommercialCars',
      ),
      value: 'EXCEPT_TRADE',
    },
    {
      label: t('managementInfo.charts.carKindFilters.privatePurchase'),
      value: CarKindEnum.particulier,
    },
    {
      label: t('managementInfo.charts.carKindFilters.privateTradeIn'),
      value: CarKindEnum.particulierinruil,
    },
    {
      label: t('managementInfo.charts.carKindFilters.commercial'),
      value: CarKindEnum.handel,
    },
    {
      label: t('managementInfo.charts.carKindFilters.demo'),
      value: CarKindEnum.demo,
    },
    {
      label: t('managementInfo.charts.carKindFilters.rental'),
      value: CarKindEnum.huur,
    },
    {
      label: t('managementInfo.charts.carKindFilters.new'),
      value: CarKindEnum.nieuw,
    },
    {
      label: t('managementInfo.charts.carKindFilters.import'),
      value: CarKindEnum.import,
    },
    {
      label: t('managementInfo.charts.carKindFilters.oldtimer'),
      value: CarKindEnum.oldtimer,
    },
    {
      label: t('managementInfo.charts.carKindFilters.damaged'),
      value: CarKindEnum.schadeAuto,
    },
  ]

  const standtimeFilters = [
    {
      label: t('managementInfo.charts.standtimeFilters.allStandtimes'),
      value: DEFAULT_VALUE_STANDTIME,
    },
    {
      label: t('managementInfo.charts.standtimeFilters.standtime1'),
      value: ManagementInfoStockDays123Enum.STOCK_DAYS_1,
    },
    {
      label: t('managementInfo.charts.standtimeFilters.standtime2'),
      value: ManagementInfoStockDays123Enum.STOCK_DAYS_2,
    },
    {
      label: t('managementInfo.charts.standtimeFilters.standtime3'),
      value: ManagementInfoStockDays123Enum.STOCK_DAYS_3,
    },
  ]

  return (
    <Grid>
      <EnhancedCard shadow="bottom">
        <ManagementInfoChartAverageStandtimeLast12Months
          filters={carKindFilters}
          dealerNumbers={dealerNumbers}
        />
      </EnhancedCard>
      <EnhancedCard shadow="bottom">
        <ManagementInfoChartTurnoverRateLast12Months
          filters={carKindFilters}
          dealerNumbers={dealerNumbers}
        />
      </EnhancedCard>
      <EnhancedCard shadow="bottom">
        <ManagementInfoChartStandtime123Last12Months
          filters={standtimeFilters}
          dealerNumbers={dealerNumbers}
        />
      </EnhancedCard>
      <EnhancedCard shadow="bottom">
        <ManagementInfoChartStockStandtime123Last12Months
          filters={standtimeFilters}
          dealerNumbers={dealerNumbers}
        />
      </EnhancedCard>
      <EnhancedCard shadow="bottom">
        <ManagementInfoChartCapitalStandtime123Last12Months
          filters={standtimeFilters}
          dealerNumbers={dealerNumbers}
        />
      </EnhancedCard>
    </Grid>
  )
}

ManagementInfoTabStandtime.propTypes = {
  dealerNumbers: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string).isRequired,
  ]),
}

export default ManagementInfoTabStandtime
