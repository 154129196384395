import React from 'react'
import PropTypes from 'prop-types'
import BodyHeading from 'components/atoms/body-heading'
import BodyParagraph from 'components/atoms/body-paragraph'
import ExplanationParagraph from 'components/atoms/explanation-paragraph'
import ErrorText from 'components/atoms/error-text'
import InlineBodyText from 'components/atoms/inline-body-text'

/**
 * Generic component that serializes typographic elements into HTML elements
 *
 * Replacement for the [Text](/#/Atoms/Text) component.
 */
function Typography({ type, children, className, ...restProps }) {
  switch (type) {
    case 'Level1Heading':
      return (
        <BodyHeading level="1" className={className} {...restProps}>
          {children}
        </BodyHeading>
      )
    case 'Level2Heading':
      return (
        <BodyHeading level="2" className={className} {...restProps}>
          {children}
        </BodyHeading>
      )
    case 'Level3Heading':
      return (
        <BodyHeading level="3" className={className} {...restProps}>
          {children}
        </BodyHeading>
      )
    case 'Level4Heading':
      return (
        <BodyHeading level="4" className={className} {...restProps}>
          {children}
        </BodyHeading>
      )
    case 'Level5Heading':
      return (
        <BodyHeading level="5" className={className} {...restProps}>
          {children}
        </BodyHeading>
      )
    case 'ExplanationParagraph':
      return (
        <ExplanationParagraph className={className} {...restProps}>
          {children}
        </ExplanationParagraph>
      )
    case 'BodyParagraph':
      return (
        <BodyParagraph className={className} {...restProps}>
          {children}
        </BodyParagraph>
      )
    case 'InlineBodyText':
      return (
        <InlineBodyText className={className} {...restProps}>
          {children}
        </InlineBodyText>
      )
    case 'InlineBodyTextBold':
      return (
        <InlineBodyText variant="bold" className={className} {...restProps}>
          {children}
        </InlineBodyText>
      )
    case 'ErrorText':
    case 'ErrorValue':
      return (
        <ErrorText className={className} {...restProps}>
          {children}
        </ErrorText>
      )
    default:
      return <>{children}</>
  }
}

Typography.propTypes = {
  /**
   * - Level1Heading -> [BodyHeading, level 1 variant](/#/Atoms/BodyHeading)
   * - Level2Heading -> [BodyHeading, level 2 variant](/#/Atoms/BodyHeading)
   * - Level3Heading -> [BodyHeading, level 3 variant](/#/Atoms/BodyHeading)
   * - Level4Heading -> [BodyHeading, level 4 variant](/#/Atoms/BodyHeading)
   * - Level5Heading -> [BodyHeading, level 5 variant](/#/Atoms/BodyHeading)
   * - ExplanationParagraph -> [ExplanationParagraph]((/#/Atoms/ExplanationParagraph)
   * - BodyParagraph -> [BodyParagraph]((/#/Atoms/BodyParagraph)
   * - InlineBodyText -> [InlineBodyText]((/#/Atoms/InlineBodyText)
   * - InlineBodyTextBold -> [InlineBodyText]((/#/Atoms/InlineBodyText)
   * - ErrorText -> [ErrorText]((/#/Atoms/ErrorText)
   * - InlineBodyText -> [InlineBodyText]((/#/Atoms/InlineBodyText)
   *
   * Many of the components Typography facilitates have additional properties like `variant`.
   * These can be provided too and will be passed along and applied.
   */
  type: PropTypes.oneOf([
    'Level1Heading',
    'Level2Heading',
    'Level3Heading',
    'Level4Heading',
    'Level5Heading',
    'ExplanationParagraph',
    'BodyParagraph',
    'InlineBodyText',
    'InlineBodyTextBold',
    'ErrorText',
    'ErrorValue',
  ]).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
}

Typography.defaultProps = {
  children: '',
  className: null,
}

export default Typography
