import { useQuery } from '@apollo/client'
import LoadingIndicator from 'components/atoms/loading-indicator'
import PieChart from 'components/atoms/pie-chart'
import DashboardGrid from 'components/layouts/dashboard-grid'
import DashboardGridCard from 'components/layouts/dashboard-grid-card'
import IconHeading from 'components/molecules/icon-heading'
import Typography from 'components/molecules/typography'
import GeneralDataError from 'components/organisms/general-data-error'
import PageHeader from 'components/organisms/page-header'
import TodosWithHeader from 'components/organisms/todos-with-header'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import ContractTabs from 'components/views/car-service-plan/contract-tabs'
import StartServiceCalculation from 'components/views/car-service-plan/start-service-calculation'
import {
  contractStatusColors,
  contractStatuses,
  getContractStatusLabels,
} from 'config/data'
import {
  GET_CONTRACT_STATUSES,
  GET_CONTRACT_EXTENSION_REQUESTS_MINIMAL,
} from 'config/graphql/csp'
import {
  CSPDashboardContext,
  CSPDashboardProvider,
} from 'contexts/csp-dashboard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { formatDate } from 'utilities/format'
import { media } from 'utilities/styled'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import {
  CSP_CONTRACT_TABS_OVERVIEW,
  CSP_CONTRACT_TABS_EXTENSION_REQUESTS,
} from 'config/routes'

const Container = styled.div`
  padding: ${({ theme }) => theme.sizings.lvl2} 20px;
  ${media.desktop`
      padding: ${({ theme }) => theme.sizings.lvl2} 60px;
  `}
`

const PieChartContainer = styled.div`
  max-width: 400px;
`

const ActiveContractsText = styled(Typography)`
  margin-bottom: 0;
`

const ContractsCard = styled.div`
  ${media.tablet`
    grid-column: span ${({ $columnSpan }) => $columnSpan};
  `}
`

const {
  ACTIVE,
  SIGNED,
  INCOMPLETE,
  UNSIGNED,
  EXPIRED,
  STOPPED,
  CANCELED,
  ACTIVE_BUT_CHANGES_PENDING,
} = contractStatuses

export const getTodoStatusTexts = (t) => ({
  [INCOMPLETE]: t('carServicePlanDashboard.contractsAreIncomplete'),
  [UNSIGNED]: t('carServicePlanDashboard.contractsAreUnsigned'),
  [EXPIRED]: t('carServicePlanDashboard.contractsAreExpired'),
  [STOPPED]: t('carServicePlanDashboard.contractsAreStopped'),
  [CANCELED]: t('carServicePlanDashboard.contractsAreCanceled'),
  [ACTIVE_BUT_CHANGES_PENDING]: t(
    'carServicePlanDashboard.contractsActiveButChangedAndUnsigned',
  ),
})

const getContractYear = (contract) =>
  formatDate(contract.activationDate, 'YYYY-MM-DD', 'YYYY')

const formatReportData = (contracts, t) => {
  const currentYear = `${new Date().getFullYear()}`
  const items = []
  const contractStatusLabels = getContractStatusLabels(t)
  const reportStatuses = [
    ACTIVE,
    SIGNED,
    INCOMPLETE,
    UNSIGNED,
    EXPIRED,
    STOPPED,
    CANCELED,
  ]
  reportStatuses.forEach((status) => {
    const count = contracts.filter((contract) => {
      const contractYear = getContractYear(contract)
      return contract.status === status && contractYear === currentYear
    }).length
    if (count > 0) {
      items.push({
        value: count,
        label: contractStatusLabels[status],
        color: contractStatusColors[status],
      })
    }
  })
  return items
}

const CarServicePlanDashboard = () => {
  const { t } = useTranslation()
  const {
    loading: loadingStatuses,
    error: statusesError,
    data: statusesData,
  } = useQuery(GET_CONTRACT_STATUSES)
  const {
    loading: loadingExtensionRequests,
    error: extensionRequestsError,
    data: extensionRequestsData,
  } = useQuery(GET_CONTRACT_EXTENSION_REQUESTS_MINIMAL)
  const { setContractStatusFilter } = React.useContext(CSPDashboardContext)
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()

  if (loadingStatuses || loadingExtensionRequests) {
    return <LoadingIndicator error={null} />
  }
  if (statusesError || extensionRequestsError) {
    if (statusesError) {
      console.error(statusesError)
    }
    if (extensionRequestsError) {
      console.error(extensionRequestsError)
    }
    return (
      <AppLayoutContainer pageTitle={t('dashboard')}>
        <Container>
          <PageHeader
            mainTitle={t('CarServicePlan')}
            status={t('dashboard')}
            hasBorderBottom={false}
          />
          <GeneralDataError />
        </Container>
      </AppLayoutContainer>
    )
  }

  const todoCriteria = [
    {
      status: ACTIVE,
      customStatus: ACTIVE_BUT_CHANGES_PENDING,
      extraConditions: (contract) => {
        return (
          contract.mutations.length > 0 &&
          contract.mutations.some((mutation) => mutation.status === 'PENDING')
        )
      },
    },
    {
      status: INCOMPLETE,
    },
    {
      status: UNSIGNED,
    },
  ]

  const formatTodosData = (contracts, extensionRequests) => {
    const items = []

    const todoStatusTexts = getTodoStatusTexts(t)
    todoCriteria.forEach((todoCriterion) => {
      const count = contracts.filter((contract) => {
        const statusMatches = contract.status === todoCriterion.status
        if (statusMatches && todoCriterion?.extraConditions) {
          return statusMatches && todoCriterion.extraConditions(contract)
        }
        return statusMatches
      }).length

      if (count > 0) {
        items.push({
          count,
          title:
            todoStatusTexts[todoCriterion.customStatus || todoCriterion.status],
          onClick: () => {
            setContractStatusFilter({
              status: todoCriterion.customStatus || todoCriterion.status,
            })
            // Only push to the history state if the user is not already on the contract overview tab
            const newPath = `${match.url}${CSP_CONTRACT_TABS_OVERVIEW}`
            if (location.pathname !== newPath) {
              history.push(newPath)
            }
          },
        })
      }
    })
    if (extensionRequests.length > 0) {
      items.push({
        count: extensionRequests.length,
        title: t('carServicePlanDashboard.extensionRequests'),
        onClick: () => {
          // Only push to the history state if the user is not already on the extension requests tab
          const newPath = `${match.url}${CSP_CONTRACT_TABS_EXTENSION_REQUESTS}`
          if (location.pathname !== newPath) {
            history.push(newPath)
          }
        },
      })
    }
    return items
  }

  const contracts = statusesData.contracts.edges.map((edge) => edge.node)
  const extensionRequests = !extensionRequestsData?.extensionRequests
    ? []
    : extensionRequestsData.extensionRequests.filter(
        (request) => request.status !== 'APPROVED',
      )
  const todosData = formatTodosData(contracts, extensionRequests)
  const reportData = formatReportData(contracts, t)
  const currentYear = `${new Date().getFullYear()}`
  const activeContractsInPreviousYears = contracts.filter(
    (contract) =>
      contract.status === ACTIVE && getContractYear(contract) !== currentYear,
  ).length

  return (
    <Container>
      <PageHeader
        mainTitle={t('CarServicePlan')}
        status={t('dashboard')}
        hasBorderBottom={false}
      />

      <DashboardGrid columns={2}>
        <DashboardGridCard columnSpan={2}>
          <StartServiceCalculation />
        </DashboardGridCard>

        <DashboardGridCard columnSpan={1}>
          <TodosWithHeader items={todosData} />
        </DashboardGridCard>

        <DashboardGridCard columnSpan={1}>
          <IconHeading iconType="pieChart">
            {t('carServicePlanDashboard.report', { year: currentYear })}
          </IconHeading>

          <PieChartContainer>
            <PieChart data={reportData} />
          </PieChartContainer>
          {activeContractsInPreviousYears > 0 && (
            <ActiveContractsText type="BodyParagraph">
              {t(
                `carServicePlanDashboard.${activeContractsInPreviousYears === 1 ? 'activeContractsInPreviousYearsSingular' : 'activeContractsInPreviousYears'}`,
                { count: activeContractsInPreviousYears },
              )}
            </ActiveContractsText>
          )}
        </DashboardGridCard>

        <ContractsCard $columnSpan={2}>
          <ContractTabs />
        </ContractsCard>
      </DashboardGrid>
    </Container>
  )
}

const CarServicePlanDashboardWithContextProvider = (props) => (
  <CSPDashboardProvider>
    <CarServicePlanDashboard {...props} />
  </CSPDashboardProvider>
)

export default CarServicePlanDashboardWithContextProvider
