import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

/**
 * This hook uses a selector to get the "edit master data" menu item. It uses that menu item data
 * (which is provided by the back-end) to get the "edit master data" complex form. You can then use
 * this data to open an overlay with this form in it.
 *
 * Note: this hook does not take care of the fetching of the menu item or the edit master data form.
 * That should be handled separately. This just checks what's in the Redux state and returns it.
 */

const useEditMasterDataForm = () => {
  const { t } = useTranslation()

  const actionBarItems = useSelector((state) => state?.data?.actionBarItems)
  const menuItem = useSelector((state) =>
    state?.data?.actionBarItems?.data?.contextmenu?.find(
      (item) => item.id === 'gegevensAanpassen',
    ),
  )
  const title = menuItem?.label
  const icon = menuItem?.icon
  const category = menuItem?.overlay?.form_category || ''
  const id = menuItem?.overlay?.form_id || ''
  const formCategory = useSelector((state) => state?.forms?.[category])
  const form = formCategory?.data?.[id]

  // The default text for the submit button is 'Save changes'. Some overlays might require a
  // different text in that button. This piece of code checks the `overlaySubmitButtonTexts` object
  // in the translations file to see if there is a translation for that text available. It uses
  // form_category and form_id to do that. See the translations file for more info.
  const translationKey = `overlaySubmitButtonTexts.${category}.${id}`
  const translatedSubmitText = t(translationKey)
  const submitText =
    translatedSubmitText !== translationKey
      ? translatedSubmitText
      : t('saveChanges')

  const isLoading =
    !actionBarItems ||
    actionBarItems.loading ||
    !formCategory ||
    formCategory.loading

  return {
    title,
    icon,
    category,
    id,
    form,
    submitText,
    isLoading,
  }
}

export default useEditMasterDataForm
