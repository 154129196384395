import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'

function getColor(type) {
  const colorName = {
    info: 'actionsStandard',
    success: 'statePositive',
    warning: 'actionsCta',
    error: 'stateNegative',
  }[type]

  return colorName
}

const Container = styled.div`
  align-items: flex-start;
  background: ${({ theme, type }) => theme.colors[getColor(type)]}33;
  border-top: 1px solid;
  color: ${({ theme, type }) => theme.colors[getColor(type)]};
  display: flex;
  padding: 16px;
`

const StyledIcon = styled(Icon)`
  margin-right: 16px;
`

const Close = styled(Icon)`
  margin-left: auto;
`

const Title = styled(Text)`
  margin-bottom: 12px;
`

const Message = styled(Text)`
  white-space: pre-wrap;
`

function Toast({ className, message, type }) {
  const { t } = useTranslation()

  const color = getColor(type)
  const titleText = {
    info: t('info'),
    success: `${t('success')}!`,
    warning: `${t('payAttention')}!`,
    error: `${t('error')}!`,
  }[type]
  const icon = {
    info: 'checkCircleOutline',
    success: 'checkCircleOutline',
    warning: 'errorOutline',
    error: 'errorOutline',
  }[type]

  return (
    <Container className={className} type={type}>
      <StyledIcon color={color} size="md" type={icon} />
      <div>
        <Title color={color} text={titleText} />
        <Message type="h5" text={message} />
      </div>
      <Close color={color} size="sm" type="close" />
    </Container>
  )
}

Toast.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error']).isRequired,
}

Toast.defaultProps = {
  className: null,
}

export default Toast
