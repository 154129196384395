import * as React from 'react'
import { useSelector } from 'react-redux'
import storage from 'utilities/storage'
import { storageKeys } from 'config/storage'

/**
 * This hook takes the selected location ID (Demo Bedrijf 1, Demo Bedrijf UsedCarController, etc.)
 * from the session storage and returns a React state that is pre-filled with it. It also adds an
 * effect that stores the selected location back to the session storage if it changes.
 *
 * The return value of this hook is a regular state object, same as what is returned from
 * `React.useState`. Just like with regular state values, you can provide an `initialValue`, which
 * will override whatever is in the local storage. You can also provide a `fallbackValue`, in case
 * the `initialValue` is `undefined` and nothing is in the local storage.
 **/

const useSelectedLocationId = (initialValue, fallbackValue = '0') => {
  const userLocationId = useSelector(
    ({ auth }) => auth && auth.userDetails && auth.userDetails.location,
  )
  const storedLocationId = storage.getSession(storageKeys.SELECTED_LOCATION_ID)
  const state =
    initialValue !== undefined
      ? initialValue
      : storedLocationId || userLocationId || fallbackValue
  const [selectedLocation, setSelectedLocation] = React.useState(state)

  React.useEffect(() => {
    storage.setSession(storageKeys.SELECTED_LOCATION_ID, selectedLocation)
  }, [selectedLocation])

  const handleStorageUpdate = React.useCallback(
    (event) => {
      if (
        event.key !== storageKeys.SELECTED_LOCATION_ID ||
        event.newValue === selectedLocation ||
        event.oldValue === event.newValue
      ) {
        return
      }
      setSelectedLocation(event.newValue)
    },
    [selectedLocation],
  )

  React.useEffect(() => {
    // window.addEventListener('storage', handleStorageUpdate)
    // window.addEventListener('setLocalStorage', handleStorageUpdate)
    window.addEventListener('setSessionStorage', handleStorageUpdate)
    return () => {
      // window.removeEventListener('storage', handleStorageUpdate)
      // window.removeEventListener('setLocalStorage', handleStorageUpdate)
      window.removeEventListener('setSessionStorage', handleStorageUpdate)
    }
  }, [handleStorageUpdate])

  return [selectedLocation, setSelectedLocation]
}

export default useSelectedLocationId
