import React from 'react'
import PropTypes from 'prop-types'

import EnhancedCard from 'components/layouts/enhanced-card'
import ManagementInfoChartTradeInValuationRatio from 'components/organisms/management-info/chart-trade-in-valuation-ratio'

const ManagementInfoTabTaxation = ({ dealerNumbers }) => (
  <EnhancedCard shadow="bottom">
    <ManagementInfoChartTradeInValuationRatio dealerNumbers={dealerNumbers} />
  </EnhancedCard>
)

ManagementInfoTabTaxation.propTypes = {
  dealerNumbers: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string).isRequired,
  ]),
}

export default ManagementInfoTabTaxation
