import { gql } from '@apollo/client'

export const UPDATE_CSP = gql`
  mutation updateCSP($id: ID!, $mechanicCarRate: Float) {
    updateCarServicePlan(id: $id, mechanicCarRate: $mechanicCarRate) {
      id
      mechanicCarRate
    }
  }
`

export const UPDATE_CSP_AS_ADMIN = gql`
  mutation updateCSPAsAdmin(
    $id: ID!
    $invoiceFee: Float
    $isDisabled: Boolean
  ) {
    updateCarServicePlanAsAdmin(
      id: $id
      invoiceFee: $invoiceFee
      isDisabled: $isDisabled
    ) {
      id
      mechanicCarRate
      isDisabled
    }
  }
`

export const GET_CSP_TRANSACTION_LOGS = gql`
  query GetCSPTransactionLogs(
    $first: Int!
    $after: String
    $transactionType: TransactionLogTypeEnum
    $createdAt: DateRange
  ) {
    transactionLogs(
      createdAt: $createdAt
      transactionType: $transactionType
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
      }
      edges {
        node {
          id
          licensePlate
          type
          message
          employee {
            firstname
            lastname
            username
          }
          createdAt
        }
        cursor
      }
    }
  }
`
