import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { reset } from 'redux-form'
import { useDispatch } from 'react-redux'
import { media } from 'utilities/styled'
import { clearData } from 'redux/actions/data'
import Overlay from 'components/molecules/overlay'
import { translateForm } from 'utilities/form-utils'
import ComplexForm from 'components/molecules/complex-form'
import CarComments from 'components/redux-containers/car-comments-container'

const ChildContainer = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.sizings.lvl6};
  grid-template-columns: repeat(1, 1fr);
  width: 100%;

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}
`

function CommentsOverlay({ addCarComment, closeOverlay, data, forms }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const overlayId = 'comment'
  const form = forms.comments.data.comment
  const formId = 'carFileComments'
  const translatedForm = translateForm(form.fieldsets, t)

  const onClose = () => {
    dispatch(reset(formId))
    clearData('carComments')
    closeOverlay(overlayId)
  }

  function handleSubmit(values) {
    addCarComment(values)
    dispatch(reset(formId))
  }

  return (
    <Overlay close={onClose} icon="comments" title={t('comments')}>
      <ChildContainer>
        <ComplexForm
          data={data}
          fieldsets={translatedForm}
          formId={formId}
          onSubmit={handleSubmit}
          submitText={t('addComment')}
          level="cta"
          resetOnSubmit
          enableReinitialize
        />
        <CarComments carId={data.auto_id} />
      </ChildContainer>
    </Overlay>
  )
}

CommentsOverlay.propTypes = {
  addCarComment: PropTypes.func.isRequired,
  closeOverlay: PropTypes.func.isRequired,
  data: PropTypes.object,
  forms: PropTypes.object,
}

CommentsOverlay.defaultProps = {
  data: null,
  forms: null,
}

export default CommentsOverlay
