import BodyHeading from 'components/atoms/body-heading'
import BodyParagraph from 'components/atoms/body-paragraph'
import Icon from 'components/atoms/icon'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

const Message = styled(BodyParagraph)`
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
  font-size: ${({ theme }) => theme.baseFontSize};
  padding: 0 ${({ theme }) => theme.sizings.lvl5};
`

const Container = styled.section`
  border: 2px solid ${({ theme, $type }) => theme.colors[getColor($type)]}33;
  background: ${({ theme }) => theme.colors.sectionBackground};
`
const StyledBodyHeading = styled(BodyHeading)`
  background: ${({ theme, $type }) => theme.colors[getColor($type)]}33;
  color: ${({ theme, $type }) => theme.colors[getColor($type)]};
  /* border-bottom: 2px solid ${({ theme, $type }) =>
    theme.colors[getColor($type)]}33; */
  margin: 0;
  padding: ${({ theme }) => theme.sizings.lvl1}
    ${({ theme }) => theme.sizings.lvl5};
  line-height: ${({ theme }) => theme.sizings.lvl3};
  position: relative;

  > *:first-child {
    position: absolute;
    top: ${({ theme }) => theme.sizings.lvl1};
    left: ${({ theme }) => theme.sizings.lvl1};
  }
`
function getColor(type) {
  const colorName = {
    info: 'actionsStandard',
    success: 'statePositive',
    warning: 'actionsCta',
    error: 'stateNegative',
  }[type]

  return colorName
}

/**
 * The StatusMessage is a version of the [Toast](/#/Atoms/Toast) component that allows you to show the same information, but as part of the content.
 * This can be especially helpful when showing status messages about actions within a wizard of dialog window.
 *
 * By default it will show a info status message. Optionally you can pass a heading, just like [Toast](/#/Atoms/Toast).
 */
const StatusMessage = ({
  headingLevel,
  heading,
  children,
  type,
  className,
}) => {
  const { t } = useTranslation()
  const titleText = {
    info: t('info'),
    success: t('success'),
    warning: t('payAttention'),
    error: t('error'),
  }[type]
  const icon = {
    info: 'info',
    success: 'checkCircleOutline',
    warning: 'errorOutline',
    error: 'errorOutline',
  }[type]

  return (
    <Container $type={type} className={className}>
      <StyledBodyHeading $type={type} level={headingLevel}>
        <Icon color={getColor(type)} type={icon} size="lg" />
        {heading || titleText}
      </StyledBodyHeading>
      <Message variant="bold">{children}</Message>
    </Container>
  )
}

StatusMessage.propTypes = {
  headingLevel: PropTypes.oneOf(['1', '2', '3', '4', '5']),
  heading: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
}

StatusMessage.defaultProps = {
  headingLevel: '3',
  heading: null,
  type: 'info',
  className: null,
}

export default StatusMessage
