import { connect } from 'react-redux'
import { postDynamicEndpoint } from 'redux/actions/data'
import {
  getTransportCompanies,
  deleteTransportCompany,
} from 'redux/actions/settings'

import { openOverlay } from 'redux/actions/ui'
import TransportCompaniesSettings from '../organisms/transport-companies-settings'

const mapStateToProps = ({ settings }) => ({
  transportCompanies: settings.transportCompanies,
})

const mapDispatchToProps = {
  getTransportCompanies,
  deleteTransportCompany,
  openOverlay,
  postDynamicEndpoint,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransportCompaniesSettings)
