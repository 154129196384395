import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { verifyAuthentication } from 'redux/actions/auth'
import { setMarketViewData } from 'redux/actions/marketview-data'
import FlexibleDialog from 'components/molecules/flexible-dialog'
import Text from 'components/atoms/text'
import TextInput from 'components/atoms/new-text-input'

const ContentText = styled(Text)`
  white-space: pre-wrap;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const RdwNumberTitle = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

const RdwNumberText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

function Content({ rdwError, rdwNumber, onChangeRdwNumber }) {
  const { t } = useTranslation()

  return (
    <>
      <ContentText text={t('termsMarketviewText')} />
      <RdwNumberTitle text={t('rdwNumber')} type="h5" />
      <RdwNumberText text={t('pleaseEnterRdwNumber')} />
      <TextInput
        error={rdwError}
        label={t('rdwNumber')}
        onChange={onChangeRdwNumber}
        value={rdwNumber}
      />
    </>
  )
}

Content.propTypes = {
  rdwError: PropTypes.bool,
  rdwNumber: PropTypes.string,
  onChangeRdwNumber: PropTypes.func.isRequired,
}

Content.defaultProps = {
  rdwError: false,
  rdwNumber: null,
}

/**
 * The dialog for the Marketview terms & conditions.
 *

 */
function MarketviewTermsDialog({ closeHandler }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [rdwNumber, setRdwNumber] = useState('')
  const [rdwError, setRdwError] = useState(false)

  return (
    <FlexibleDialog
      title={t('termsMarketviewTitle')}
      closeHandler={closeHandler}
      content={
        <Content
          rdwError={rdwError}
          rdwNumber={rdwNumber}
          onChangeRdwNumber={setRdwNumber}
        />
      }
      submitText={t('acceptMarketviewTerms')}
      onSubmit={() => {
        if (!rdwNumber.length) {
          setRdwError(true)
          return
        }
        dispatch(setMarketViewData('acceptTerms', { rdwNumber })).then(() =>
          dispatch(verifyAuthentication()),
        )
      }}
      open
    />
  )
}

MarketviewTermsDialog.propTypes = {
  /** This prop is only to be used in the UI lib example */
  closeHandler: PropTypes.func,
}

MarketviewTermsDialog.defaultProps = {
  closeHandler: null,
}

export default MarketviewTermsDialog
