import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getQueryStringFromObject } from 'utilities/utils'
import toast from 'utilities/toast'
import Icon from 'components/atoms/icon'
import Link from 'components/atoms/link'
import Text from 'components/atoms/text'

const Container = styled(Link)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.isolatedBackground};
  display: flex;
  padding: 12px;
`

const Counter = styled(Text)`
  flex: 0 0 auto;
  line-height: 1em;
  margin-right: 0.5em;
  width: 32px;
`

const StyledText = styled(Text)`
  line-height: 1em;
`

const Chevron = styled(Icon)`
  flex: 0 0 auto;
  margin-left: auto;
`

function getLink(target, filters) {
  if (target === 'stock') {
    const filterQuery = filters && getQueryStringFromObject(filters)
    return `/stock?${filterQuery}`
  }

  if (target === 'rdw') {
    const filterQuery = filters && getQueryStringFromObject(filters)
    return `/rdw-stock?${filterQuery}`
  }

  if (target === 'tabmarktplaats') {
    return '/advertisements/marktplaats'
  }

  return null
}

function Todo({ className, count, filters, target, title, url, onClick }) {
  const { t } = useTranslation()
  const link = getLink(target, filters)
  const href = url

  // show an error toast if the todo has a target which the Todo component
  // is not able to handle yet
  const handleClick = () => {
    if (onClick) {
      onClick()
    } else if (!link && !href) {
      toast.error(t('nl'))
    }
  }
  return (
    <Container
      className={className}
      href={href}
      onClick={handleClick}
      to={link}
    >
      <Counter color="actionsCta" type="bodyLarge" text={count} />
      <StyledText type="input" text={title} />
      <Chevron type="arrowForward" color="brandAlfa" height="12" />
    </Container>
  )
}

Todo.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  filters: PropTypes.object,
  target: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  onClick: PropTypes.func,
}

Todo.defaultProps = {
  className: null,
  filters: null,
  target: null,
  url: null,
  onClick: null,
}

export default Todo
