import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextLink from 'components/atoms/text-link'
import { formatDataTestE2eAttr } from 'utilities/format'
import Typography from './typography'

const StyledFormControl = styled(FormControl)`
  width: 100%;

  .MuiFilledInput-root {
    background-color: ${({ theme }) => theme.colors.fieldBackground};
  }
  .MuiFilledInput-root.Mui-error {
    background-color: ${({ theme }) => theme.colors.errorInputBackground};
  }

  ${({ error, required, theme, $value }) =>
    required &&
    !error &&
    [null, ''].includes($value) &&
    `
  .MuiInput-underline:before,
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after,
  .MuiFilledInput-underline:hover:not(.Mui-disabled):before {
    border-color: ${theme.colors.actionsCta};
  }

  .MuiFilledInput-root {
    background-color: ${theme.colors.requiredInputBackground};
  }
  `}
`

const Error = styled(Typography)`
  display: block;
  margin-left: 12px;
  margin-top: ${({ theme }) => theme.sizings.lvl0};
`

const StyledTextLink = styled(TextLink)`
  display: block;
  text-align: right;
  margin-top: ${({ theme }) => theme.sizings.lvl1};
`

/**
 * Dropdown select component which makes use of
 * [select input of Material-UI](https://material-ui.com/components/selects/).
 *
 * Whenever a selected item is passed that is not part of the items array it
 * should be added as an option.
 */

function NewDropdownSelect({
  className,
  disabled,
  error,
  filled,
  id,
  items,
  onChange,
  onBlur,
  label,
  required,
  value,
  selectionRequired,
  optinRequired,
  name,
  ...restProps
}) {
  const { t } = useTranslation()
  // Remember the initial selected item
  // so it can be added to the item options if it isn't present already
  const initialSelectedItem = useRef(value)
  const [hasOptedIn, setHasOptedIn] = useState(!optinRequired)

  function handleChange(event) {
    onChange(event.target.value)
  }

  const selectedIsItem = items
    // casting both values to string, because the values can match in meaning ("1" vs 1),
    // but their types may be different. Resulting in a raw number being displayed as selected,
    // instead of the option it maps to.
    .find((item) => String(item.value) === String(initialSelectedItem.current))

  // If there is selected item and it is not part of the items array add it
  // to the items array.
  if (initialSelectedItem && initialSelectedItem.current && !selectedIsItem) {
    items.push({
      label: initialSelectedItem.current,
      value: initialSelectedItem.current,
    })
  }

  return (
    <StyledFormControl
      $value={value}
      className={className}
      error={!!error}
      required={required}
      selected={value}
      variant={filled ? 'filled' : undefined}
      data-test-e2e={formatDataTestE2eAttr(id || name)}
      name={name}
      {...restProps}
    >
      <InputLabel htmlFor={id} disabled={disabled}>
        {label}
      </InputLabel>
      <Select
        inputProps={{
          'data-test-e2e': formatDataTestE2eAttr(id || name, 'input'),
          'aria-labelledby': id,
        }}
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={disabled || !hasOptedIn}
        MenuProps={{
          'data-test-e2e': formatDataTestE2eAttr(id || name, 'menu'),
        }}
      >
        {!selectionRequired && (
          <MenuItem value={null}>--{t('resetSelection')}--</MenuItem>
        )}
        {items.map((item, index) => (
          <MenuItem
            key={index.toString()}
            value={typeof item.value === 'undefined' ? index : item.value}
            disabled={item.disabled || !hasOptedIn}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {typeof error !== 'string' && optinRequired && !hasOptedIn && (
        <StyledTextLink
          onClick={() => {
            setHasOptedIn(true)
          }}
          text={t('edit')}
        />
      )}
      {typeof error === 'string' && <Error type="ErrorText">{error}</Error>}
    </StyledFormControl>
  )
}

export const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Render the input with a filled background if truthy */
  filled: PropTypes.bool,
  id: PropTypes.string,
  /** An array of object(s) as input wich populated the option list */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node, // Label can be anything that can be rendered. See: https://legacy.reactjs.org/docs/rendering-elements.html
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool, // consider using radio's or toggles instead.
      ]).isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  onBlur: PropTypes.func,
  /** Supplied onChange handler */
  onChange: PropTypes.func.isRequired,
  /** Placeholder text for floating label */
  label: PropTypes.string, // Was "isRequired" before, but floating label is not always used (e.g. in language-picker.js)
  required: PropTypes.bool,
  /** The currently selected value of the dropdown */
  value: PropTypes.any.isRequired,
  /** Won't render the empty selection option if true */
  selectionRequired: PropTypes.bool,
  optinRequired: PropTypes.bool,
  name: PropTypes.string,
}

NewDropdownSelect.propTypes = propTypes

NewDropdownSelect.defaultProps = {
  className: undefined,
  disabled: false,
  onBlur: undefined,
  error: false,
  id: undefined,
  filled: false,
  required: false,
  selectionRequired: false,
  optinRequired: false,
}

export default NewDropdownSelect
