import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { media } from 'utilities/styled'
import toast from 'utilities/toast'
import { formatUnit, formatFromToLabel, formatPrice } from 'utilities/format'
import { CSPWarrantyProgramFormSchema } from 'config/validation-schemas'
import Typography from 'components/molecules/typography'
import Button from 'components/atoms/button'
import LoadingButton from 'components/atoms/loading-button'
import ContentSeperator from 'components/atoms/content-separator'
import TextLink from 'components/atoms/text-link'
import Icon from 'components/atoms/icon'
import Tooltip from 'components/atoms/new-tooltip'
import {
  FormikTextInput,
  FormikSelectInput,
} from 'components/molecules/formik-field'
import EnhancedTable, {
  TableDataText,
} from 'components/organisms/enhanced-table'
import FullWidthTopBarLayout from '../../layouts/full-width-top-bar-layout'

const TopBarContainer = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  > * {
    margin: ${({ theme }) => theme.sizings.lvl1} 0;
  }
`
const StyledDropDownSelect = styled(FormikSelectInput)`
  flex: 1;
  min-width: 180px;
  flex-basis: 25%;
  ${media.tablet`
    flex: 0;
  `}
`
const StyledTextInput = styled(FormikTextInput)`
  flex: 1;
  flex-basis: 25%;
  min-width: 180px;
  ${media.tablet`
    flex: 0;
  `}
`
const StyledCellTextInput = styled(FormikTextInput)`
  min-width: 120px;
`
const TextInputSeperator = styled.span`
  padding: 0 ${({ theme }) => theme.sizings.lvl1};
  &:before {
    content: '-';
  }
`
const ActionsContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizings.lvl2};
  display: flex;
  justify-content: space-between;
`
const VerticallyAlignedInlineContainer = styled.div`
  display: flex;
  align-items: center;
`
const ProviderNameContainer = styled.div`
  align-self: center;
`
const ProviderNameLabel = styled(Typography)`
  margin: 0 0 ${({ theme }) => theme.sizings.lvl1} 0;
  color: ${(props) => props.theme.colors.text};
  font-weight: normal;
`
const ProviderName = styled(Typography)`
  font-weight: bold;
  margin: 0;
  color: ${(props) => props.theme.colors.text};
`
const InfoIconContainer = styled.div`
  margin: 0 ${({ theme }) => theme.sizings.lvl1};
`

const TopBarRightAlignedContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`
const CopyButton = styled(LoadingButton)`
  margin-left: ${({ theme }) => theme.sizings.lvl2};
`
const ActionsButton = styled(Button)`
  &[disabled] {
    opacity: 0.4;
    * {
      fill: ${({ theme }) => theme.colors.actionsStandard};
    }

    &:hover,
    &:focus {
      & * {
        fill: ${({ theme }) => theme.colors.actionsStandard};
      }
    }
  }
`

const durationOptions = [
  {
    label: '0 - 12',
    value: 'ZERO_TO_TWELVE_MONTHS',
    years: 1,
  },
  {
    label: '12 - 24',
    value: 'TWELVE_TO_TWENTYFOUR_MONTHS',
    years: 2,
  },
  {
    label: '24 - 36',
    value: 'TWENTYFOUR_TO_THIRTYSIX_MONTHS',
    years: 3,
  },
]

function WarrantyProgramTableForm({
  warrantyProgram,
  warrantyPrograms,
  onSubmit,
  carBrandsAndModels,
  warrantyProviders,
  initiallyDisabled,
  enableEditing,
  enableReinitializeForm,
}) {
  const { t } = useTranslation()
  // const [formDisabled, setFormDisabled ] = useState(initiallyDisabled || warrantyPrograms)
  const [activeWarrantyProgram, setActiveWarrantyProgram] =
    useState(warrantyProgram)

  const warrantyProviderOptions = warrantyProviders.map((edge) => ({
    value: edge.node.id,
    label: edge.node.name,
  }))
  const ownRiskPeriodOptions = [0, 3, 6, 12].map((option) => ({
    value: option,
    label: t(
      `carServicePlanAdminProgramsNewProgramOverlay.inputOptions.ownRiskPeriod.${option}`,
    ),
  }))
  const manufacturerWarrantyOptions = [
    {
      value: false,
      label: t(
        'carServicePlanAdminProgramsNewProgramOverlay.inputOptions.manufacturerWarranty.without',
      ),
    },
    {
      value: true,
      label: t(
        'carServicePlanAdminProgramsNewProgramOverlay.inputOptions.manufacturerWarranty.with',
      ),
    },
  ]
  const priceCalculationOptions = [
    {
      value: 'PER_MONTH',
      label: t(
        'carServicePlanAdminProgramsNewProgramOverlay.inputOptions.priceCalculation.permonth',
      ),
    },
    {
      value: 'PER_DAY',
      label: t(
        'carServicePlanAdminProgramsNewProgramOverlay.inputOptions.priceCalculation.perday',
      ),
    },
  ]
  const brandOptions = carBrandsAndModels
    .map((make) => ({ value: make.id, label: make.name }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const modelOptions = (brandId) => {
    const selectedBrand = carBrandsAndModels.find(
      (brand) => brand.id === brandId,
    )
    if (selectedBrand) {
      return selectedBrand.models
        .map((model) => ({ value: model.id, label: model.name }))
        .sort((a, b) => a.label.localeCompare(b.label))
    }
    return []
  }
  const addProgramTableColumns = [
    {
      id: 'brandId',
      orderId: 'brandId',
      label: t(
        'carServicePlanAdminProgramsNewProgramOverlay.addPlansTableHeadings.brandId',
      ),
      width: 10,
    },
    {
      id: 'modelId',
      orderId: 'modelId',
      label: t(
        'carServicePlanAdminProgramsNewProgramOverlay.addPlansTableHeadings.modelId',
      ),
      width: 10,
    },
    {
      id: 'age',
      orderId: 'age',
      label: t(
        'carServicePlanAdminProgramsNewProgramOverlay.addPlansTableHeadings.age',
      ),
      width: 14,
    },
    {
      id: 'mileage',
      orderId: 'mileage',
      label: t(
        'carServicePlanAdminProgramsNewProgramOverlay.addPlansTableHeadings.mileage',
      ),
      width: 14,
    },
    {
      id: 'duration',
      orderId: 'duration',
      label: t(
        'carServicePlanAdminProgramsNewProgramOverlay.addPlansTableHeadings.duration',
      ),
      width: 14,
    },
    {
      id: 'priceForDuration',
      orderId: 'priceForDuration',
      label: t(
        'carServicePlanAdminProgramsNewProgramOverlay.addPlansTableHeadings.priceForDuration',
      ),
      width: 10,
    },
    {
      id: 'engineCapacity',
      orderId: 'engineCapacity',
      label: t(
        'carServicePlanAdminProgramsNewProgramOverlay.addPlansTableHeadings.engineCapacity',
      ),
      width: 14,
    },
    {
      id: 'kilowatt',
      orderId: 'kilowatt',
      label: t(
        'carServicePlanAdminProgramsNewProgramOverlay.addPlansTableHeadings.kilowatt',
      ),
      width: 14,
    },
    {
      id: 'actions',
      label: t(
        'carServicePlanAdminProgramsNewProgramOverlay.addPlansTableHeadings.actions',
      ),
      width: '90px',
    },
  ]

  const rowSchema = {
    brandId: {
      type: 'select',
      items: brandOptions,
      data: '',
    },
    modelId: {
      type: 'contextualSelect',
      context: 'brandId',
      items: modelOptions,
      data: '',
    },
    age: {
      type: 'fromToNumber',
      to: '',
      from: '',
    },
    mileage: {
      type: 'fromToNumber',
      unit: 'km',
      to: '',
      from: '',
    },
    duration: {
      type: 'select',
      items: durationOptions,
      data: '',
    },
    priceForDuration: {
      type: 'priceForDuration',
      unit: 'currency_euro',
      data: '',
    },
    engineCapacity: {
      type: 'fromToNumber',
      unit: 'capacity_cc',
      to: '',
      from: '',
    },
    kilowatt: {
      type: 'fromToNumber',
      unit: 'power_kw',
      to: '',
      from: '',
    },
    actions: {
      type: 'editAndDelete',
    },
  }

  // Add the "resetOnChange" property to all fields which have a contextualSelect
  Object.entries(rowSchema)
    .filter(([_, object]) => object.type === 'contextualSelect')
    .forEach(([id, object]) => {
      rowSchema[object.context].resetOnChange = id
    })

  const getFormikEmptyPriceRuleRow = () => {
    const emptyRow = {}
    Object.keys(rowSchema).forEach((key) => {
      emptyRow[key] =
        rowSchema[key].type === 'fromToText' ? { from: '', to: '' } : ''
    })
    return emptyRow
  }

  const getFormikEmptyFormSchema = () => ({
    warrantyProviderId: '',
    warrantyProgramId: '',
    name: '',
    ownRiskPeriod: '',
    manufacturerWarranty: undefined,
    priceCalculation: '',
    warrantyPriceRules: [getFormikEmptyPriceRuleRow()],
  })

  const formikToPayload = (formik) => {
    const copiedFormik = JSON.parse(JSON.stringify(formik))
    const payloadPriceRules = copiedFormik.warrantyPriceRules.map(
      (priceRule) => {
        const payloadPriceRule = priceRule
        Object.keys(payloadPriceRule).forEach((key) => {
          if (
            typeof payloadPriceRule[key] === 'object' &&
            payloadPriceRule[key] !== null
          ) {
            payloadPriceRule[`${key}From`] = payloadPriceRule[key].from
            payloadPriceRule[`${key}To`] = payloadPriceRule[key].to
            delete payloadPriceRule[key]
          }
        })
        // Remove unwanted fields
        delete payloadPriceRule.actions
        delete payloadPriceRule.brandLabel
        delete payloadPriceRule.modelLabel

        // Remove all empty fields and parse all number fields
        Object.keys(payloadPriceRule).forEach((key) => {
          if (
            payloadPriceRule[key] === '' ||
            payloadPriceRule[key] === null ||
            payloadPriceRule[key] === undefined
          ) {
            payloadPriceRule[key] = null
            return
          }

          const match = key.match(/(From|To)$/)
          const schemaKey = match ? key.slice(0, match.index) : key
          if (!rowSchema[schemaKey]) {
            return
          }

          if (rowSchema[schemaKey].unit === 'currency_euro') {
            payloadPriceRule[key] = parseFloat(payloadPriceRule[key])
          } else if (
            ['number', 'fromToNumber'].includes(rowSchema[schemaKey].type)
          ) {
            payloadPriceRule[key] = parseInt(payloadPriceRule[key])
          }
        })

        return payloadPriceRule
      },
    )

    Object.keys(copiedFormik)
      .filter(
        (key) =>
          copiedFormik[key] === '' ||
          copiedFormik[key] === null ||
          copiedFormik[key] === undefined,
      )
      .forEach((key) => {
        copiedFormik[key] = null
      })

    return {
      ...copiedFormik,
      maxMileage:
        copiedFormik.maxMileage !== null
          ? parseInt(copiedFormik.maxMileage)
          : copiedFormik.maxMileage,
      warrantyPriceRules: payloadPriceRules,
    }
  }

  const payloadToFormik = (payload) => {
    const copiedPayload = JSON.parse(JSON.stringify(payload))
    const warrantyPriceRules = payload.warrantyPriceRules || []
    const formikPriceRules = warrantyPriceRules.map((priceRule) => {
      const formikPriceRule = JSON.parse(JSON.stringify(priceRule))
      Object.keys(priceRule).forEach((key) => {
        const fromSplit = key.split('From')
        if (fromSplit.length > 1) {
          const valueFrom =
            priceRule[key] === null || priceRule[key] === undefined
              ? ''
              : priceRule[key]
          const valueTo =
            priceRule[`${fromSplit[0]}To`] === null ||
            priceRule[`${fromSplit[0]}To`] === undefined
              ? ''
              : priceRule[`${fromSplit[0]}To`]
          formikPriceRule[fromSplit[0]] = { from: valueFrom, to: valueTo }
          delete formikPriceRule[key]
          delete formikPriceRule[`${fromSplit[0]}To`]
        } else if (priceRule[key] === null || priceRule[key] === undefined) {
          formikPriceRule[key] = ''
        }
      })
      formikPriceRule.brandId = formikPriceRule.brand
        ? formikPriceRule.brand.id
        : ''
      formikPriceRule.modelId = formikPriceRule.model
        ? formikPriceRule.model.id
        : ''
      return formikPriceRule
    })
    Object.keys(copiedPayload).forEach((key) => {
      if (copiedPayload[key] === undefined || copiedPayload[key] === null) {
        copiedPayload[key] = ''
      }
    })
    return {
      ...copiedPayload,
      warrantyPriceRules: formikPriceRules,
      warrantyProviderId: payload.warrantyProvider.id,
    }
  }

  const TopBarSeperator = (
    <div>
      <ContentSeperator variant="vertical" paddingLevel={2} />
    </div>
  )
  const WarrantyProvider = warrantyProgram
    ? warrantyProviderOptions.find(
        (provider) => provider.value === warrantyProgram.warrantyProvider.id,
      )
    : ''

  const validationSchema = CSPWarrantyProgramFormSchema(t)

  // Ordering

  /**
   * The `rowOrderSchema` dictates how rows are sorted when a user clicks on a column head. The
   * `orderId` values given in the `tableColumns` array (a bit further above) should be the keys in
   * this object. The values should be functions which accept `row` as an argument. The return
   * value of this function can either be a number, a string, or an array of strings/numbers. If
   * you return a string or number, those will be used as values to compare rows when ordering. If
   * an array is returned, the values will be used in sequence to compare the rows if the previous
   * value in the array is equal. This means you are returning more than one columns to order the
   * results on. This can be useful when you have a column that has the same value in it.
   *
   * For instance: when you try to sort the "brand" column. Most tables will use the same brand for
   * all the rows in that column. If you provide an array which contains `[brandId, id]`, the rows
   * will be sorted on the `id` property of the row, if the `brandId` is the same for any rows that
   * are being compared when ordering.
   */

  const formatFromToValuesForOrdering = (prop) => [prop.from || 0, prop.to || 0]
  const fallbackToRowIdForOrdering = (value, row) => {
    const rowId = row.id ? parseInt(row.id) : ''
    return Array.isArray(value) ? [...value, rowId] : [value, rowId]
  }

  const rowOrderSchema = {
    brandId: (row) =>
      fallbackToRowIdForOrdering(
        row.brandId
          ? rowSchema.brandId.items.find((item) => item.value === row.brandId)
              .label
          : '',
        row,
      ),
    modelId: (row) =>
      fallbackToRowIdForOrdering(
        [
          row.brandId && row.modelId
            ? rowSchema.modelId
                .items(row.brandId)
                .find((item) => item.value === row.modelId).label
            : '',
          row.brandId
            ? rowSchema.brandId.items.find((item) => item.value === row.brandId)
                .label
            : '',
        ],
        row,
      ),
    age: (row) =>
      fallbackToRowIdForOrdering(formatFromToValuesForOrdering(row.age), row),
    mileage: (row) =>
      fallbackToRowIdForOrdering(
        formatFromToValuesForOrdering(row.mileage),
        row,
      ),
    duration: (row) =>
      fallbackToRowIdForOrdering(
        rowSchema.duration
          ? rowSchema.duration.items.find((item) => item.value === row.duration)
              .years
          : 0,
        row,
      ),
    priceForDuration: (row) =>
      fallbackToRowIdForOrdering(row.priceForDuration || 0, row),
    engineCapacity: (row) =>
      fallbackToRowIdForOrdering(
        formatFromToValuesForOrdering(row.engineCapacity),
        row,
      ),
    kilowatt: (row) =>
      fallbackToRowIdForOrdering(
        formatFromToValuesForOrdering(row.kilowatt),
        row,
      ),
  }

  const [orderDirection, setOrderDirection] = useState('asc')
  const [orderBy, setOrderBy] = useState(null)

  const compare = (a, b) => {
    if (Number.isFinite(a) && Number.isFinite(b)) {
      return a - b
    } else {
      return `${a}`.localeCompare(`${b}`)
    }
  }

  const orderRows = (rows, direction, by) => {
    if (rows.length === 0) {
      return
    }

    rows.sort((a, b) => {
      const valueA = Object.prototype.hasOwnProperty.call(rowOrderSchema, by)
        ? rowOrderSchema[by](a)
        : a[by]
      const valueB = Object.prototype.hasOwnProperty.call(rowOrderSchema, by)
        ? rowOrderSchema[by](b)
        : b[by]
      if (valueA === undefined || valueB === undefined) {
        console.warn(`Tried to sort row on non-existent property "${by}"`)
        return 0
      }
      if (Array.isArray(valueA) && Array.isArray(valueB)) {
        // Multiple values to compare
        let result = 0
        let i = 0
        const length = valueA.length
        while (i < length && result === 0) {
          result = compare(valueA[i], valueB[i])
          i++
        }
        return result
      } else {
        return compare(valueA, valueB)
      }
    })

    if (direction === 'desc') {
      rows.reverse()
    }

    // Update the sorting values in the state
    setOrderDirection(direction)
    setOrderBy(by)
    return rows
  }

  const initialValues = activeWarrantyProgram
    ? payloadToFormik(activeWarrantyProgram)
    : getFormikEmptyFormSchema()

  const [rowsInEditMode, setRowsInEditMode] = useState(
    initialValues && !initiallyDisabled
      ? initialValues.warrantyPriceRules.map((row) => row.id)
      : [],
  )

  // A function which returns an element which will represent one cell in a row.
  // This will mostly return input fields, or action buttons.
  const getRowCellComponent = useCallback(
    (
      index,
      id,
      { unit, items, type, context, resetOnChange },
      values,
      setValues,
      setFieldValue,
      validationSchema,
    ) => {
      const rowName = `warrantyPriceRules[${index}]`
      const name = `${rowName}.${id}`
      const row = values.warrantyPriceRules[index]
      const rowIsEditable = !row.id || rowsInEditMode.includes(row.id)
      const fieldValue = row[id]
      switch (type) {
        case 'select': {
          const onChange = (value) => {
            setFieldValue(name, value)
            if (resetOnChange) {
              setFieldValue(`${rowName}.${resetOnChange}`, '')
            }
          }
          const selectedLabel = (items || []).find(
            (item) => item.value === fieldValue,
          )?.label
          const label = selectedLabel || fieldValue
          return rowIsEditable ? (
            <Field
              name={name}
              validationSchema={validationSchema}
              label={t(
                `carServicePlanAdminProgramsNewProgramOverlay.addPlansTableInputLabels.${id}`,
              )}
              items={items || []}
              filled
              component={StyledDropDownSelect}
              // disabled={formDisabled}
              onChange={onChange}
            />
          ) : (
            label
          )
        }
        case 'contextualSelect': {
          const itemsFormatted = items(row[context])
          const selectedLabel = (itemsFormatted || []).find(
            (item) => item.value === fieldValue,
          )?.label
          const label = selectedLabel || fieldValue

          return rowIsEditable ? (
            <Field
              name={name}
              validationSchema={validationSchema}
              label={t(
                `carServicePlanAdminProgramsNewProgramOverlay.addPlansTableInputLabels.${id}`,
              )}
              items={itemsFormatted}
              filled
              component={StyledDropDownSelect}
            />
          ) : (
            label
          )
        }
        case 'number':
        case 'text':
          return rowIsEditable ? (
            <Field
              name={name}
              validationSchema={validationSchema}
              label={t(
                `carServicePlanAdminProgramsNewProgramOverlay.addPlansTableInputLabels.${id}`,
              )}
              unit={unit}
              type={type === 'number' && 'number'}
              filled
              component={StyledCellTextInput}
            />
          ) : unit === 'currency_euro' ? (
            formatPrice(fieldValue, true)
          ) : (
            `${fieldValue}${unit && ' ' + formatUnit(unit)}`
          )
        case 'fromToNumber':
        case 'fromToText':
          return rowIsEditable ? (
            <VerticallyAlignedInlineContainer>
              <Field
                name={`${name}.from`}
                validationSchema={validationSchema}
                label={t(
                  'carServicePlanAdminProgramsNewProgramOverlay.addPlansTableInputLabels.from',
                )}
                unit={unit}
                type={type === 'fromToNumber' ? 'number' : undefined}
                filled
                component={StyledCellTextInput}
              />
              <TextInputSeperator />
              <Field
                name={`${name}.to`}
                validationSchema={validationSchema}
                label={t(
                  'carServicePlanAdminProgramsNewProgramOverlay.addPlansTableInputLabels.to',
                )}
                unit={unit}
                type={type === 'fromToNumber' && 'number'}
                filled
                component={StyledCellTextInput}
              />
            </VerticallyAlignedInlineContainer>
          ) : (
            formatFromToLabel(fieldValue.from, fieldValue.to, type, unit)
          )
        case 'editAndDelete':
          return rowIsEditable || !enableEditing ? (
            <ActionsButton
              level="option"
              icon="deleteCarfile"
              onClick={() => {
                const newValues = JSON.parse(JSON.stringify(values))
                newValues.warrantyPriceRules.splice(index, 1)
                return setValues(newValues)
              }}
              disabled={!enableEditing}
            />
          ) : (
            <ActionsButton
              level="option"
              icon="edit"
              onClick={() => {
                row.id && setRowsInEditMode((rowIds) => [...rowIds, row.id])
              }}
            />
          )
        case 'priceForDuration': {
          const selectedDuration = durationOptions.find(
            (duration) =>
              duration.value === values.warrantyPriceRules[index].duration,
          )
          const pricePerYear =
            values.warrantyPriceRules[index].priceForDuration /
            (selectedDuration ? selectedDuration.years : 0)
          const pricePerMonth = pricePerYear / 12
          const pricePerDay = pricePerYear / 365.25
          return (
            <VerticallyAlignedInlineContainer>
              {rowIsEditable ? (
                <Field
                  name={name}
                  validationSchema={validationSchema}
                  label={t(
                    `carServicePlanAdminProgramsNewProgramOverlay.addPlansTableInputLabels.${id}`,
                  )}
                  unit={unit}
                  type="number"
                  filled
                  component={StyledCellTextInput}
                  // disabled={formDisabled}
                />
              ) : (
                <TableDataText>{formatPrice(fieldValue, true)}</TableDataText>
              )}
              {pricePerYear > 0 && selectedDuration && (
                <InfoIconContainer>
                  <Tooltip
                    title={
                      <>
                        <div>{`${t('carServicePlanAdminProgramsNewProgramOverlay.pricePerYear')} ${formatPrice(pricePerYear)}`}</div>
                        <div>{`${t('carServicePlanAdminProgramsNewProgramOverlay.pricePerMonth')} ${formatPrice(pricePerMonth)}`}</div>
                        <div>{`${t('carServicePlanAdminProgramsNewProgramOverlay.pricePerDay')} ${formatPrice(pricePerDay)}`}</div>
                      </>
                    }
                    placement="right"
                    arrow
                  >
                    <Icon
                      type="info"
                      width="17"
                      height="17"
                      color="actionsStandard"
                    />
                  </Tooltip>
                </InfoIconContainer>
              )}
            </VerticallyAlignedInlineContainer>
          )
        }
        default:
          return <></>
      }
    },
    [rowsInEditMode, enableEditing, t],
  )

  return (
    <Formik
      enableReinitialize={enableReinitializeForm}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={
        activeWarrantyProgram
          ? payloadToFormik(activeWarrantyProgram)
          : getFormikEmptyFormSchema()
      }
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        try {
          toast.info(
            t('carServicePlanAdminProgramsNewProgramOverlay.savingChanges'),
            {
              autoClose: 10000, // some time to read the message.
            },
          )

          await onSubmit(formikToPayload(values))
          setSubmitting(false)
        } catch (e) {
          console.error(e)
          toast.error(
            t(
              'carServicePlanAdminProgramsNewProgramOverlay.errorSubmittingForm',
            ),
          )
          setSubmitting(false)
        }
      }}
    >
      {({ values, setValues, isSubmitting, setFieldValue }) => (
        <Form>
          <FullWidthTopBarLayout
            paddingLevelMain={2}
            topBarSlot={
              <TopBarContainer>
                {warrantyProgram ? (
                  <ProviderNameContainer>
                    <ProviderNameLabel type="Level3Heading">
                      {t('provider').toUpperCase()}
                    </ProviderNameLabel>
                    <ProviderName type="Level2Heading">
                      {WarrantyProvider && WarrantyProvider.label.toUpperCase()}
                    </ProviderName>
                  </ProviderNameContainer>
                ) : (
                  <Field
                    name="warrantyProviderId"
                    validationSchema={validationSchema}
                    items={warrantyProviderOptions}
                    label={t(
                      'carServicePlanAdminProgramsNewProgramOverlay.inputLabels.warrantyProviderId',
                    )}
                    filled
                    component={StyledDropDownSelect}
                    onChange={async (val) => {
                      await setActiveWarrantyProgram(undefined)
                      await setValues({
                        ...getFormikEmptyFormSchema(),
                        warrantyProviderId: val,
                      })
                      setRowsInEditMode([])
                    }}
                    // disabled={!warrantyPrograms}
                  />
                )}
                {warrantyPrograms &&
                Array.isArray(warrantyPrograms) &&
                warrantyPrograms.length > 1 ? (
                  <>
                    {TopBarSeperator}
                    <Field
                      name="id"
                      validationSchema={validationSchema}
                      items={warrantyPrograms
                        // eslint-disable-next-line max-len
                        .filter(
                          (program) =>
                            program.warrantyProvider.id ===
                            values.warrantyProviderId,
                        )
                        .map((program) => ({
                          value: program.id,
                          label: program.name,
                          disabled: program.disabled,
                        }))}
                      label={t(
                        'carServicePlanAdminProgramsNewProgramOverlay.inputLabels.warrantyProgramId',
                      )}
                      filled
                      onChange={async (val) => {
                        const newActiveWarrantyProgram = warrantyPrograms.find(
                          (program) => program.id === val,
                        )
                        await setFieldValue('id', val)
                        await setActiveWarrantyProgram(newActiveWarrantyProgram)
                        if (newActiveWarrantyProgram) {
                          setRowsInEditMode(
                            !initiallyDisabled
                              ? newActiveWarrantyProgram.warrantyPriceRules.map(
                                  (row) => row.id,
                                )
                              : [],
                          )
                        }
                      }}
                      component={StyledDropDownSelect}
                      disabled={!values.warrantyProviderId}
                    />
                    <TopBarRightAlignedContainer>
                      <TextLink
                        text={t(
                          'cspAdminDealerDetails.linkingWarrantyPrograms.manageWarrantyPrograms',
                        )}
                        to="/settings/car-service-plan/programs-overview"
                      />
                      <CopyButton
                        level="cta"
                        type="submit"
                        isLoading={isSubmitting}
                        disabled={isSubmitting || !activeWarrantyProgram}
                      >
                        {t(
                          'cspAdminDealerDetails.linkingWarrantyPrograms.linkWarrantyProgramButton',
                        )}
                      </CopyButton>
                    </TopBarRightAlignedContainer>
                  </>
                ) : (
                  <>
                    {TopBarSeperator}
                    <Field
                      name="name"
                      validationSchema={validationSchema}
                      label={t(
                        'carServicePlanAdminProgramsNewProgramOverlay.inputLabels.name',
                      )}
                      filled
                      component={StyledTextInput}
                      // disabled={formDisabled}
                    />
                    {TopBarSeperator}
                    <Field
                      name="ownRiskPeriod"
                      validationSchema={validationSchema}
                      items={ownRiskPeriodOptions}
                      label={t(
                        'carServicePlanAdminProgramsNewProgramOverlay.inputLabels.ownRiskPeriod',
                      )}
                      filled
                      component={StyledDropDownSelect}
                      // disabled={formDisabled}
                    />
                    {TopBarSeperator}
                    <Field
                      name="manufacturerWarranty"
                      validationSchema={validationSchema}
                      items={manufacturerWarrantyOptions}
                      label={t(
                        'carServicePlanAdminProgramsNewProgramOverlay.inputLabels.manufacturerWarranty',
                      )}
                      filled
                      component={StyledDropDownSelect}
                      // disabled={formDisabled}
                    />
                    {TopBarSeperator}
                    <Field
                      name="priceCalculation"
                      validationSchema={validationSchema}
                      items={priceCalculationOptions}
                      label={t(
                        'carServicePlanAdminProgramsNewProgramOverlay.inputLabels.priceCalculation',
                      )}
                      filled
                      component={StyledDropDownSelect}
                      // disabled={formDisabled}
                    />
                    {TopBarSeperator}
                    <Field
                      name="maxMileage"
                      validationSchema={validationSchema}
                      unit="km"
                      label={t(
                        'carServicePlanAdminProgramsNewProgramOverlay.inputLabels.maxMileage',
                      )}
                      filled
                      component={StyledTextInput}
                      // disabled={formDisabled}
                    />
                    {TopBarSeperator}
                    <Field
                      name="externalId"
                      validationSchema={validationSchema}
                      label={t(
                        'carServicePlanAdminProgramsNewProgramOverlay.inputLabels.externalId',
                      )}
                      filled
                      component={StyledTextInput}
                      // disabled={formDisabled}
                    />
                    {/* {formDisabled && !warrantyPrograms && (
                        <>
                          {TopBarSeperator}
                          <EditButton
                            iconColor="actionsStandard"
                            type="submit"
                            level="option"
                            icon="edit"
                            text={t('carServicePlanAdminProgramsNewProgramOverlay.editWarrantyProgram')}
                            onClick={() => setFormDisabled(false)}
                          />
                        </>
                      )} */}
                  </>
                )}
              </TopBarContainer>
            }
            mainSlot={
              warrantyPrograms && !activeWarrantyProgram ? (
                ''
              ) : (
                <section>
                  <EnhancedTable
                    onOrder={(direction, by) => {
                      const orderedRows = orderRows(
                        JSON.parse(JSON.stringify(values.warrantyPriceRules)),
                        direction,
                        by,
                      )
                      setValues({
                        ...values,
                        warrantyPriceRules: orderedRows,
                      })
                    }}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                    selectable={false}
                    verticalCellBorders
                    columns={addProgramTableColumns}
                    rows={values.warrantyPriceRules.map((_, index) => {
                      const enhancedTableRow = {}
                      Object.keys(rowSchema).forEach((key) => {
                        enhancedTableRow[key] = {}
                        enhancedTableRow[key].component = getRowCellComponent(
                          index,
                          key,
                          { ...rowSchema[key] },
                          values,
                          setValues,
                          setFieldValue,
                          validationSchema,
                        )
                      })
                      return enhancedTableRow
                    })}
                  />
                  {enableEditing && (
                    <ActionsContainer>
                      <TextLink
                        onClick={() => {
                          values.warrantyPriceRules.push(
                            getFormikEmptyPriceRuleRow(),
                          )
                          setValues(values)
                        }}
                        text={t(
                          'carServicePlanAdminProgramsNewProgramOverlay.addNewPriceRule',
                        )}
                      />
                      <LoadingButton
                        level="cta"
                        isLoading={isSubmitting}
                        type="submit"
                      >
                        {t('save')}
                      </LoadingButton>
                    </ActionsContainer>
                  )}
                </section>
              )
            }
          />
        </Form>
      )}
    </Formik>
  )
}

WarrantyProgramTableForm.propTypes = {
  // if a warranty program is provided, then the form will be prefilled
  warrantyProgram: PropTypes.object,
  // If an array of warranty programs is provided, then the table form will be used for linking (assigning)
  warrantyPrograms: PropTypes.array,
  carBrandsAndModels: PropTypes.array.isRequired,
  warrantyProviders: PropTypes.array.isRequired,
  /* If true, all form rows are initially disabled
  and an button is shown to edit the individual row. This
  requires the "enableEditing" prop to be set to "true". */
  initiallyDisabled: PropTypes.bool,
  /* Enable the ability to edit every line using the edit button.
  This property is ignored if initiallyDisabled is set to "false" */
  enableEditing: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  enableReinitializeForm: PropTypes.bool,
}

WarrantyProgramTableForm.defaultProps = {
  warrantyProgram: undefined,
  warrantyPrograms: undefined,
  initiallyDisabled: false,
  enableEditing: true,
  enableReinitializeForm: true,
}

export default WarrantyProgramTableForm
