/*
 * This is a similar component to StockResultsHeader and StockHeader.
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { media } from 'utilities/styled'
import Text from 'components/atoms/text'
import OptionsBar from 'components/layouts/options-bar'

const Container = styled.header`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  ${media.desktop`
    align-items: center;
    flex-direction: row;
  `}
`

const StyledResultsCount = styled(Text)`
  margin-bottom: 16px;

  ${media.desktop`
    margin-bottom: 0;
  `}
`

const StyledOptionsBar = styled(OptionsBar)`
  margin-bottom: 0;

  ${media.desktop`
    border-left: 1px solid ${({ theme }) => theme.colors.brandDelta};
    margin-left: 15px;
    padding-left: 15px;
  `}
`

function SearchResultsHeader({ noOfResults, filtering, sorting, special }) {
  const { t } = useTranslation()
  const resultsText = noOfResults
    ? t('numberOfResults', { count: noOfResults })
    : t('noResults')

  return (
    <Container>
      <StyledResultsCount type="h4" text={resultsText} />
      <StyledOptionsBar
        leftAligned={[sorting, ...filtering]}
        rightAligned={special}
      />
    </Container>
  )
}

SearchResultsHeader.propTypes = {
  noOfResults: PropTypes.number.isRequired,
  filtering: PropTypes.arrayOf(PropTypes.node),
  sorting: PropTypes.node,
  special: PropTypes.arrayOf(PropTypes.node),
}

SearchResultsHeader.defaultProps = {
  filtering: [],
  sorting: null,
  special: [],
}

export default SearchResultsHeader
