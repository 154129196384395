import { types } from 'redux/actions/auth'
import { types as languageActionsTypes } from 'redux/actions/language'

import storage from 'utilities/storage'

// The CRED_ variables store credentials to be used for automatically
// populating the login form. The ones without CRED_ for keeping the user
// logged in.

const INSTANCEID_STORAGEID = 'instanceId'
export const JWT_STORAGEID = 'jwt'

const CRED_CUSTOMERID_STORAGEID = 'credCustomerId'
const CRED_USERNAME_STORAGEID = 'credUsername'
const CRED_PASSWORD_STORAGEID = 'credPassword'
const CRED_REMEMBER_STORAGEID = 'credRemember'

export const initialState = {
  customerId: storage.getValue(CRED_CUSTOMERID_STORAGEID),
  instanceId: storage.getValue(INSTANCEID_STORAGEID, false),
  error: null,
  username: storage.getValue(CRED_USERNAME_STORAGEID),
  password: storage.getValue(CRED_PASSWORD_STORAGEID),
  rememberCredentials: !!storage.getValue(CRED_REMEMBER_STORAGEID),
  stayLoggedIn: false,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_LOGIN: {
      if (action.status === 'SUCCESS') {
        if (action.payload.rememberCredentials) {
          storage.setPermanent(
            CRED_CUSTOMERID_STORAGEID,
            action.payload.customerId,
          )
          storage.setPermanent(CRED_USERNAME_STORAGEID, action.payload.username)
          storage.setPermanent(CRED_PASSWORD_STORAGEID, action.payload.password)
          storage.setPermanent(
            CRED_REMEMBER_STORAGEID,
            action.payload.rememberCredentials,
          )
        } else {
          storage.setPermanent(CRED_CUSTOMERID_STORAGEID, '')
          storage.setPermanent(CRED_USERNAME_STORAGEID, '')
          storage.setPermanent(CRED_PASSWORD_STORAGEID, '')
          storage.setPermanent(CRED_REMEMBER_STORAGEID, '')
        }
        if (action.payload.stayLoggedIn) {
          storage.setPermanent(INSTANCEID_STORAGEID, action.payload.instanceId)
          storage.setPermanent(JWT_STORAGEID, action.payload.jwt)
        } else {
          storage.setPermanent(INSTANCEID_STORAGEID, '')
          storage.setPermanent(JWT_STORAGEID, '')
        }
        storage.setSession(INSTANCEID_STORAGEID, action.payload.instanceId)
        storage.setSession(JWT_STORAGEID, action.payload.jwt)
      }

      return {
        ...state,
        ...action.payload,
        loginStatus: action.status,
      }
    }

    case types.SET_LOGOUT: {
      storage.setSession(INSTANCEID_STORAGEID, '')
      storage.setPermanent(INSTANCEID_STORAGEID, '')

      return {
        ...state,
        ...action.payload,
        error: null,
        loginStatus: action.status,
        instanceId: null,
        customerId: storage.getValue(CRED_CUSTOMERID_STORAGEID),
        username: storage.getValue(CRED_USERNAME_STORAGEID),
        password: storage.getValue(CRED_PASSWORD_STORAGEID),
      }
    }

    case types.SET_VERIFY_AUTH: {
      return {
        ...state,
        ...action.payload,
        verifyAuthStatus: action.status,
      }
    }
    case types.SET_FORGOT_PASSWORD: {
      return {
        ...state,
        ...action.payload,
        forgotPasswordStatus: action.status,
      }
    }
    case types.SET_RESET_PASSWORD: {
      return {
        ...state,
        ...action.payload,
        resetPasswordStatus: action.status,
      }
    }
    case languageActionsTypes.SET_LANGUAGE: {
      return {
        ...state,
        userDetails: { ...state.userDetails, ...{ taal_code: action.payload } },
      }
    }
    default:
      return state
  }
}
