// EXTERNAL
import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/atoms/icon'
import styled from 'styled-components/macro'
import { v4 as uuidV4 } from 'uuid'

import Checkbox from 'components/atoms/new-checkbox'

// This component is actually just a checkbox, disguised as an icon.
const Label = styled.label`
  padding: 9px;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  opacity: ${({ $disabled }) => ($disabled ? '0.5' : '1')};
`
const HiddenCheckbox = styled(Checkbox)`
  display: none;
`

const IconCheckbox = ({
  checked,
  disabled,
  id,
  onChange,
  className,
  icon,
  iconChecked,
  ...restProps
}) => (
  <Label className={className} $disabled={disabled} {...restProps}>
    <HiddenCheckbox
      checked={checked}
      disabled={disabled}
      id={id || uuidV4()}
      onChange={onChange}
    />
    <Icon type={checked && iconChecked ? iconChecked : icon} size="md" />
  </Label>
)

IconCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconChecked: PropTypes.string,
}

IconCheckbox.defaultProps = {
  disabled: false,
  onChange: null,
  id: null,
  className: null,
}

export default IconCheckbox
