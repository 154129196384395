export const stackTotalizer = {
  id: 'stackTotalizer',
  beforeUpdate: (chart) => {
    const totals = {}
    const utmost = {}
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      if (!!dataset.stack && chart.isDatasetVisible(datasetIndex)) {
        utmost[dataset.stack] = datasetIndex
        dataset.data.forEach(({ y: value }, index) => {
          totals[index] = totals[index] || {}
          totals[index][dataset.stack] =
            (totals[index][dataset.stack] || 0) + value
        })
      }
    })

    // eslint-disable-next-line no-param-reassign
    chart.$stackTotalizer = {
      totals,
      utmost,
    }
  },
}
