import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import LoadingIndicator from 'components/atoms/loading-indicator'
import LabeledCheckbox from 'components/atoms/labeled-checkbox'
import Button from 'components/atoms/button'
import Typography from 'components/molecules/typography'
import MediaForm from 'components/organisms/media-form'

const ControlsContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`
const SubmitButton = styled(Button)`
  margin-left: auto;
`

function CarLayersForm({
  className,
  carPhotoLayers,
  getCarPhotoLayersForCarFile,
  carFile,
  onSubmit,
}) {
  const { t } = useTranslation()
  const { vestiging_id: locationId, all_layers: initialPhotoOverlayAllPhotos } =
    carFile

  const [photoOverlayAllPhotos, setPhotoOverlayAllPhotos] = useState(
    initialPhotoOverlayAllPhotos,
  )
  const [selectedLayer, setSelectedLayer] = useState()

  useEffect(() => {
    if (!selectedLayer && carFile.layer_id) {
      setSelectedLayer(carFile.layer_id)
    }
  }, [selectedLayer, carFile])

  useEffect(() => {
    getCarPhotoLayersForCarFile({
      id: locationId,
      vestiging_id: locationId,
    })
  }, [getCarPhotoLayersForCarFile, locationId])

  if (carPhotoLayers && !carPhotoLayers[locationId]) {
    return (
      <Typography type="ExplanationParagraph">{t('noLayersYet')}</Typography>
    )
  }

  if (!carPhotoLayers || carPhotoLayers[locationId].loading) {
    return <LoadingIndicator />
  }

  const handleSubmit = () =>
    onSubmit({
      layer_id: selectedLayer,
      all_layers: photoOverlayAllPhotos,
    })

  return (
    <>
      <ControlsContainer>
        <LabeledCheckbox
          checked={photoOverlayAllPhotos}
          id="photoOverlayAllPhotos"
          onChange={setPhotoOverlayAllPhotos}
          label={t('photoOverlayAllPhotos')}
        />
      </ControlsContainer>
      {carPhotoLayers[locationId].data.overlays.length < 1 ? (
        <Typography type="ExplanationParagraph">{t('noLayersYet')}</Typography>
      ) : (
        selectedLayer !== undefined && (
          <MediaForm
            className={className}
            type="images"
            uploadButtonText="Select"
            onChange={(val) => val[0] !== undefined && setSelectedLayer(val[0])}
            exclusiveSelect
            showLabelsOnImages
            items={carPhotoLayers[locationId].data.overlays.map((layer) => ({
              id: layer.id,
              selected: layer.id === selectedLayer,
              name: layer.omschrijving,
              src: layer.url,
              type: 'image',
            }))}
          />
        )
      )}
      <SubmitButton
        type="submit"
        onClick={handleSubmit}
        text={t('saveChanges')}
        level="cta"
      />
    </>
  )
}

CarLayersForm.propTypes = {
  className: PropTypes.string,
  carPhotoLayers: PropTypes.object,
  getCarPhotoLayersForCarFile: PropTypes.func.isRequired,
  carFile: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
}

CarLayersForm.defaultProps = {
  className: '',
  carPhotoLayers: null,
  onSubmit: PropTypes.func,
}

export default CarLayersForm
