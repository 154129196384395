import { useSelector } from 'react-redux'
import { marketViewStorageIdsPrefix } from 'config/data'

export default function useMarketviewStoragePrefix() {
  const { userDetails } = useSelector(({ auth }) => auth)
  const customerId = userDetails && userDetails.customerId
  const username = userDetails && userDetails.username

  return `${marketViewStorageIdsPrefix}${customerId}_${username}_`
}
