import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FilterCollapser from 'components/molecules/filter-collapser'
import Dropdown from 'components/molecules/new-dropdown-select'

const StyledDropdown = styled(Dropdown)`
  &.MuiFormControl-root {
    margin-top: ${({ theme }) => theme.sizings.lvl2};

    &:last-child {
      margin-bottom: ${({ theme }) => theme.sizings.lvl2};
    }
  }
`

/**
 * Filter component that renders two dropdowns with selection
 * options.
 *

 */

function FromToFilter({
  initallyOpen,
  fromOptions,
  fromLabel,
  fromId,
  fromValue,
  onChange,
  title,
  toId,
  toOptions,
  toLabel,
  toValue,
}) {
  return (
    <FilterCollapser initallyOpen={initallyOpen} title={title}>
      <StyledDropdown
        items={fromOptions}
        onChange={(val) => onChange(fromId, val)}
        label={fromLabel}
        value={fromValue}
      />
      <StyledDropdown
        items={toOptions}
        onChange={(val) => onChange(toId, val)}
        label={toLabel}
        value={toValue}
      />
    </FilterCollapser>
  )
}

FromToFilter.propTypes = {
  /** Control whether the filter is initially opened or collapsed */
  initallyOpen: PropTypes.bool,
  /** The placeholder / label for the from dropdown */
  fromLabel: PropTypes.string.isRequired,
  /** The options for the from dropdown */
  fromOptions: PropTypes.array.isRequired,
  /** The from id for the filter that will be passed as the first argument
   * of the onChange handler */
  fromId: PropTypes.string.isRequired,
  /** The from value of the filter */
  fromValue: PropTypes.string,
  /** The onChange handler */
  onChange: PropTypes.func.isRequired,
  /** The placeholder / label for the to dropdown */
  toLabel: PropTypes.string.isRequired,
  /** The from id for the filter that will be passed as the first argument
   * of the onChange handler */
  toId: PropTypes.string.isRequired,
  /** The options for the to dropdown */
  toOptions: PropTypes.array.isRequired,
  /** The title of the filter */
  title: PropTypes.string.isRequired,
  /** The to value of the filter */
  toValue: PropTypes.string,
}

FromToFilter.defaultProps = {
  initallyOpen: true,
  fromValue: '',
  toValue: '',
}

export default FromToFilter
