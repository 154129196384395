import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Label from 'components/atoms/label'

function getColor(days) {
  if (days < 41) {
    return 'chartsIsOk'
  }
  if (days < 61) {
    return 'chartsIsLvl1Bad'
  }
  if (days < 81) {
    return 'chartsIsLvl2Bad'
  }
  return 'chartsIsLvl3Bad'
}

/**
 * A label for staytime which changes it's color depending on the
 * amount of days passed.
 *
 * ### Colors
 * - 0-40 days: chartsIsOk
 * - 40-60 days: chartsIsLvl1Bad
 * - 60-80 days: chartsIsLvl2Bad
 * - 80+ days: chartsIsLvl3Bad
 *

 */

function StaytimeLabel({ days }) {
  const { t } = useTranslation()
  const color = getColor(days)

  return <Label backgroundColor={color} text={`${days} ${t('dys')}`} />
}

StaytimeLabel.propTypes = {
  /** The number of days */
  days: PropTypes.number.isRequired,
}

export default StaytimeLabel
