import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { CAR_SHARE_CAR_FILE } from 'config/routes'

import { media } from 'utilities/styled'
import { scrollTo } from 'utilities/utils'

import useDynamicEndpointDone from 'hooks/use-dynamic-endpoint-done'

import LoadingIndicator from 'components/atoms/loading-indicator'
import Tabs from 'components/layouts/tabs'
import DamageCards from 'components/molecules/damage-cards'
import EquipmentForm from 'components/molecules/equipment-form'
import Typography from 'components/molecules/typography'
import CarfileSummary from 'components/organisms/carfile-summary'
import DynamicStaticValuesCollections from 'components/organisms/dynamic-static-values-collections'
import GeneralDataError from 'components/organisms/general-data-error'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import TopdownCalculationForm from 'components/redux-containers/topdown-calculation-form-container'
import CarFileCarShareBuyNowDialog from 'components/molecules/carfile-car-share-buy-now-dialog'
import CarFileCarShareAddBidDialog from 'components/molecules/carfile-car-share-add-bid-dialog'
import CarAssetsFormForDocuments from 'components/views/car-file/tabs/documents'
import CarfileHeader from 'components/molecules/carfile-header'

const Container = styled.article`
  flex-direction: column;

  ${media.desktop`
    align-items: stretch;
    display: flex;
    padding: 0 60px;
  `}
`

const StyledCarfileHeader = styled(CarfileHeader)`
  display: none;

  ${media.desktop`
    display: flex;
  `}
`

const StyledCarfileSummary = styled(CarfileSummary)`
  ${media.desktop`
    margin-bottom: ${({ theme }) => theme.sizings.lvl6};
  `}
`

const TopdownCalculationFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl2};
  max-width: ${({ theme }) => theme.sizeByFactor(50)};
`

const DIALOGS = {
  CONFIRM_BUY_NOW: 'CONFIRM_BUY_NOW',
  CONFIRM_ADD_BID: 'CONFIRM_ADD_BID',
}

const CarShareCarFile = ({
  clearData,
  getDamage,
  getBids,
  getCarfile,
  getCarAssets,
  getForms,
  updateCarfile,
  openImageOverlay,
  postDynamicEndpoint,
  carAssets,
  carDamage,
  carFile,
  forms,
  dynamicEndpoint,
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const match = useRouteMatch()
  const history = useHistory()

  const tabsContainerRef = useRef()
  const [confirmDialog, setConfirmDialog] = useState(undefined)

  const carId = match.params.id
  const carShareLocationId = match.params.carShareLocationId

  const carStatus = carFile?.data?.status
  const carImages = carAssets?.data?.filter(({ type }) => type === 'image')
  const carDocuments = carAssets?.data?.filter(
    ({ type }) => type === 'document',
  )

  const carShareCarHasBidPending = carFile?.data?.carshare_bid?.is_bid_pending
  const carShareCarHasPurchasePending =
    carFile?.data?.carshare_bid?.is_purchase_pending
  const carShareCarCanReceiveBidOrBuy = carFile?.data?.carshare_can_bid

  const topdownSharedBidForm = forms?.topdownSharedBidForm
  const specificationsFormSetup =
    forms?.carfile?.data?.specifications_general_form
  const specificationHistoryFormSetup =
    forms?.carfile?.data?.specifications_historical_form

  const calculationPathRegex = new RegExp(
    `${CAR_SHARE_CAR_FILE}/[0-9]+/calculation$`,
    'i',
  )
  const isCalculationTab = calculationPathRegex.test(location.pathname)

  useDynamicEndpointDone(
    dynamicEndpoint,
    async () => {
      // Reset the UI
      setConfirmDialog(undefined)

      // Fetch the updated carfile
      await getBids({ auto_id: carId, vestiging_id: carShareLocationId })
      await getCarfile(carId, carShareLocationId)
    },
    [dynamicEndpoint],
  )

  const handleClickBuyNow = () => {
    if (confirmDialog === DIALOGS.CONFIRM_BUY_NOW) {
      return
    }

    setConfirmDialog(DIALOGS.CONFIRM_BUY_NOW)
  }

  const handleSaveCalculation = async (
    updatedCarFile,
    getUpdatedCarFile = true,
  ) => {
    // Update the carFile with the values the user just added in the topdown calculation form
    await updateCarfile(
      // We're manually adding "carshare_vestiging_id" to the data, this is used by the BE to save this data specifically for this location
      { ...updatedCarFile, carshare_vestiging_id: carShareLocationId },
      carId,
      carShareLocationId,
      true,
    )

    // Fetch the complex form that will be displayed in the confirm bid dialog
    getForms('topdownSharedBidForm', {
      auto_id: carId,
      vestiging_id: carShareLocationId,
    })

    // Re-fetch the complex form(s) that are used in the Topdown calculation form
    getForms('valuation', { auto_id: carId, vestiging_id: carShareLocationId })

    if (getUpdatedCarFile) {
      // Fetch the updated carfile
      getCarfile(carId, carShareLocationId)
    }
  }

  const handleSubmitAddBid = async (updatedCarFile) => {
    if (confirmDialog === DIALOGS.CONFIRM_ADD_BID) {
      return
    }

    await handleSaveCalculation(updatedCarFile, false)

    // Open the confirm bid modal
    setConfirmDialog(DIALOGS.CONFIRM_ADD_BID)
  }

  const handleClickIncreaseBidButton = () => {
    // Check if we're not on the /calculations path
    if (!isCalculationTab) {
      // Navigate to the /calculations path
      history.push(`${match.url}/calculation`)
    }

    // Scroll to the tabs container - this is done manually because the scrollTo in the tabs conflict with opening the flexible dialog.
    scrollTo(tabsContainerRef.current, {
      vertical: 'start',
      horizontal: 'center',
    })
  }

  const handleCloseDialog = () => setConfirmDialog(undefined)

  useEffect(() => {
    // Generic data
    getCarfile(carId, carShareLocationId)
    getForms('carfile', { auto_id: carId, vestiging_id: carShareLocationId })

    // Data for the assets (images and documents) tabs
    getCarAssets(carId)

    // Data for the damages tab
    getDamage({ auto_id: carId, vestiging_id: carShareLocationId })

    // The forms for the Topdown component
    getForms('valuation', { auto_id: carId, vestiging_id: carShareLocationId })

    // The form for the add bid / buy now modal
    getForms('topdownSharedBidForm', {
      auto_id: carId,
      vestiging_id: carShareLocationId,
    })

    // The forms for the PriceChecks in the Topdown component
    getForms('bids', { auto_id: carId, vestiging_id: carShareLocationId })
    getBids({ auto_id: carId, vestiging_id: carShareLocationId })
  }, [
    carId,
    clearData,
    getCarAssets,
    getCarfile,
    getDamage,
    getForms,
    carShareLocationId,
    getBids,
  ])

  const tabItems = []

  tabItems.push({
    label: t('specifications'),
    to: `${match.url}/specifications`,
    component: () => (
      <>
        {specificationsFormSetup && !carFile.loading && (
          <DynamicStaticValuesCollections
            formSetup={specificationsFormSetup}
            values={carFile.data}
          />
        )}
      </>
    ),
  })

  tabItems.push({
    label: t('history'),
    to: `${match.url}/history`,
    component: () => (
      <>
        {specificationHistoryFormSetup && !carFile.loading && (
          <DynamicStaticValuesCollections
            formSetup={specificationHistoryFormSetup}
            values={carFile.data}
          />
        )}
      </>
    ),
  })

  tabItems.push({
    label: t('equipment'),
    to: `${match.url}/equipment`,
    component: () => (
      <>
        {carFile.data && (
          <EquipmentForm
            // We're filtering this in the FE, even though in the B2B carfile the BE doesn't send any accessoire with selected === '0'.
            // TODO: This should be fixed in the BE.
            carAccessories={carFile.data.accessoires.filter(
              (accessoire) => accessoire.selected === '1',
            )}
            dataIsStatic
          />
        )}
      </>
    ),
  })

  tabItems.push({
    label: t('damage'),
    to: `${match.url}/damage`,
    component: () =>
      carDamage?.loading === true ? (
        <LoadingIndicator />
      ) : (
        <>
          {!Array.isArray(carDamage?.data) ||
          (Array.isArray(carDamage?.data) && carDamage?.data.length === 0) ? (
            <Typography type="ExplanationParagraph">
              {t('noB2bDamage')}
            </Typography>
          ) : (
            <DamageCards damages={carDamage.data} />
          )}
        </>
      ),
  })

  tabItems.push({
    label: t('calculation'),
    to: `${match.url}/calculation`,
    component: (props) =>
      !carFile?.data || carFile?.loading || forms?.valuation?.loading ? (
        <LoadingIndicator />
      ) : (
        <TopdownCalculationFormWrapper>
          <TopdownCalculationForm
            car={carFile.data}
            // eslint-disable-next-line react/prop-types
            config={carFile.data.topdown_config}
            forms={forms.valuation.data}
            onSubmit={handleSubmitAddBid}
            submitText={
              carShareCarHasBidPending ? t('modifyBid') : t('submitBid')
            }
            optionButtonText={t('saveCalculation')}
            onOptionButtonClick={handleSaveCalculation}
            nextPrevCloseButtonsProps={{
              disableCloseButton:
                carShareCarHasPurchasePending || !carShareCarCanReceiveBidOrBuy,
            }}
            {...props}
            getForms={getForms}
          />
        </TopdownCalculationFormWrapper>
      ),
  })

  tabItems.push({
    label: t('documents'),
    to: `${match.url}/documents`,
    component: (props) => (
      <CarAssetsFormForDocuments
        carFile={carFile?.data}
        carFileId={carId}
        items={carDocuments}
        // eslint-disable-next-line react/prop-types
        level="standard"
        disabled
        readOnly
        {...props}
      />
    ),
  })

  if (carFile?.data === false && carFile?.loading === false) {
    return <GeneralDataError error={t('errorRetrievingCar')} />
  }

  return (
    <AppLayoutContainer pageTitle={t('carFile')}>
      <Container>
        <StyledCarfileHeader
          carfile={carFile?.data}
          loading={carFile?.loading}
          carShareLocationId={carShareLocationId}
        />
        <StyledCarfileSummary
          carImageLabelText={t('carShare.carFileSummaryImageLabel')}
          carfile={carFile.data}
          carfileLoading={carFile.loading}
          carId={match.params.id}
          images={carImages}
          imagesLoading={carAssets ? carAssets.loading : true}
          openImageOverlay={openImageOverlay}
          carStatus={carStatus}
          handleCarShareClickBuyNowButton={handleClickBuyNow}
          handleCarShareClickIncreaseBidButton={handleClickIncreaseBidButton}
          showCspCalculationButton={false}
          showEditMasterDataButton={false}
          showRequestBmsDataButton={false}
        />
        <div ref={tabsContainerRef}>
          <Tabs items={tabItems}>
            {tabItems.map((item) => (
              <item.component key={item.label} />
            ))}
          </Tabs>
        </div>
        <CarFileCarShareBuyNowDialog
          open={!!(confirmDialog === DIALOGS.CONFIRM_BUY_NOW && carFile?.data)}
          onCancel={handleCloseDialog}
        />
        <CarFileCarShareAddBidDialog
          open={
            !!(
              confirmDialog === DIALOGS.CONFIRM_ADD_BID &&
              topdownSharedBidForm?.data?.fieldsets
            )
          }
          onCancel={handleCloseDialog}
        />
      </Container>
    </AppLayoutContainer>
  )
}

CarShareCarFile.propTypes = {
  clearData: PropTypes.func.isRequired,
  getDamage: PropTypes.func.isRequired,
  getBids: PropTypes.func.isRequired,
  getCarfile: PropTypes.func.isRequired,
  getCarAssets: PropTypes.func.isRequired,
  getForms: PropTypes.func.isRequired,
  updateCarfile: PropTypes.func.isRequired,
  openImageOverlay: PropTypes.func.isRequired,
  postDynamicEndpoint: PropTypes.func.isRequired,
  carAssets: PropTypes.object,
  carDamage: PropTypes.object,
  carFile: PropTypes.object,
  forms: PropTypes.object,
  dynamicEndpoint: PropTypes.object,
}

CarShareCarFile.defaultProps = {
  carAssets: {},
  carDamage: null,
  carFile: {},
  forms: null,
  dynamicEndpoint: null,
}

export default CarShareCarFile
