/*
 * This is a similar component to StockResultsHeader. It is a rewrite of it
 * for the rdsStockView with a little less functionality since not all functinality
 * that is used for the regular stock view is needed in the rdwStockPage and the
 * intention is for this component to replace the StockResultsheader and add the
 * necessary functionality needed for the stock page (mainly the salesToggle and mobile
 * filters button) to this component at that time.
 */

import Button from 'components/atoms/button'
import DisplayModeToggle from 'components/atoms/display-mode-toggle'
import Text from 'components/atoms/text'
import Toggle from 'components/atoms/toggle'
import StockSortOptions from 'components/molecules/stock-sort-options'
import { DISPLAY_MODE_DEFAULT } from 'config/consts'
import { DisplayModeEnum } from 'config/enums'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'

const Container = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    'filters sort'
    'count  controls';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  ${media.desktop`
    grid-template-areas: "count controls";
    grid-template-rows: auto;
  `}

  ${media.desktop`
    display: flex;
  `};
`

const StyledResultsCount = styled(Text)`
  grid-area: count;
`

const ToggleMobileFiltersButton = styled(Button)`
  ${media.desktop`
    display: none;
  `};
`

const StyledSortOptions = styled.div`
  grid-area: sort;

  ${media.desktop`
    border-left: 1px solid ${({ theme }) => theme.colors.brandDelta};
    padding-left: 15px;
  `}
`
const StyledControls = styled.div`
  grid-area: controls;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  gap: ${({ theme }) => theme.sizeByFactor(3)};
  ${media.desktop`
      gap: ${({ theme }) => theme.sizeByFactor(5)};
  `};
`
function StockHeader({
  hideMobileFiltersButton,
  onSortOptionChange,
  salesView,
  selectedSortOption,
  sortOptions,
  toggleMobileFilters,
  toggleSalesView,
  total,
  displayMode,
  setDisplayMode,
  ...restProps
}) {
  const { t } = useTranslation()
  const resultsText = total
    ? t('numberOfResults', { count: total })
    : t('noResults')

  return (
    <Container sortOptions={sortOptions} {...restProps}>
      <StyledResultsCount type="h4" text={resultsText} />
      {!hideMobileFiltersButton && (
        <ToggleMobileFiltersButton
          onClick={toggleMobileFilters}
          selected={0}
          text={t('showFilters')}
          data-test-e2e="show-filters-mobile"
        />
      )}
      {sortOptions && (
        <StyledSortOptions>
          <StockSortOptions
            items={sortOptions}
            onSortOptionChange={onSortOptionChange}
            selectedSortOption={selectedSortOption}
          />
        </StyledSortOptions>
      )}
      <StyledControls>
        {displayMode && setDisplayMode && (
          <DisplayModeToggle
            displayMode={displayMode}
            setDisplayMode={setDisplayMode}
          />
        )}
        {toggleSalesView && (
          <Toggle
            name="salesViewToggle"
            checked={salesView}
            onChange={toggleSalesView}
            testId="salesToggle"
            title="Sales"
          />
        )}
      </StyledControls>
    </Container>
  )
}

StockHeader.propTypes = {
  hideMobileFiltersButton: PropTypes.bool,
  onSortOptionChange: PropTypes.func,
  salesView: PropTypes.bool,
  selectedSortOption: PropTypes.string,
  sortOptions: PropTypes.arrayOf(PropTypes.object),
  toggleSalesView: PropTypes.func,
  toggleMobileFilters: PropTypes.func,
  total: PropTypes.number,
  displayMode: PropTypes.oneOf(Object.values(DisplayModeEnum)),
  setDisplayMode: PropTypes.func,
}

StockHeader.defaultProps = {
  onSortOptionChange: null,
  hideMobileFiltersButton: false,
  salesView: false,
  selectedSortOption: null,
  sortOptions: null,
  toggleMobileFilters: null,
  toggleSalesView: null,
  total: null,
  displayMode: DISPLAY_MODE_DEFAULT,
}

export default StockHeader
