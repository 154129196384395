import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Text, { styles as textStyles } from 'components/atoms/text'

function getBorderColor({ deselected, error, readOnly, theme }) {
  if (error) {
    return deselected
      ? theme.colors.actionsCtaTertiery
      : theme.colors.actionsCta
  }
  if (readOnly) {
    return 'transparent'
  }
  return deselected ? theme.colors.brandDelta : theme.colors.brandAlfa
}

const Container = styled.div``

const StyledTextarea = styled.textarea`
  background: ${({ error, theme }) =>
    error ? theme.colors.actionsCtaTertiery : theme.colors.brandFoxtrot};
  padding: 8px;
  width: 100%;
  min-height: 175px;
  border: none;
  outline: none;
  resize: none;
  border-bottom: 1px solid ${getBorderColor};
  ${textStyles.textarea};

  &::placeholder {
    ${textStyles.menu};
    color: ${({ theme }) => theme.colors.brandCharlie};
  }
`

function Textarea({
  error,
  id,
  legend,
  maxLength,
  name,
  onChange,
  readOnly,
  required,
  title,
  value,
  ...restProps
}) {
  const [focussed, setFocussed] = useState(false)
  const constructedTitle = title && `${title}${required ? ' *' : ''}`

  function handleFocus() {
    setFocussed(true)
  }

  function handleBlur() {
    setFocussed(false)
  }

  return (
    <Container error={error} {...restProps}>
      <StyledTextarea
        error={error}
        id={id || name}
        maxLength={maxLength}
        name={name}
        deselected={!focussed}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={(e) => handleFocus(e.target)}
        placeholder={constructedTitle}
        readOnly={readOnly}
        value={value}
      />
      {legend && <Text color="brandCharlie" text={legend} />}
    </Container>
  )
}

Textarea.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string,
  legend: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
}

Textarea.defaultProps = {
  error: null,
  id: null,
  legend: null,
  maxLength: null,
  readOnly: false,
  required: false,
  title: null,
  value: '',
}

export default Textarea
