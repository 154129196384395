import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import Icon from 'components/atoms/icon'
import Button from 'components/atoms/button'
import Typography from 'components/molecules/typography'

import { media } from 'utilities/styled'

const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.sizings.lvl2};
`

const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const StyledTypography = styled(Typography)`
  margin: 0;
  text-transform: none;
  font-weight: bold;
`

const StyledCloseButton = styled(Button)`
  margin-left: auto;

  svg * {
    fill: ${({ theme }) => theme.colors.actionsStandard} !important;
  }
`

const StyledNewsItem = styled.div`
  ${media.tablet`
    width: 420px;
    background-color: white;
    padding: ${({ theme }) => theme.sizings.lvl3};
    box-shadow: ${({ theme }) => theme.shadows.bottom};
  `}
`

const StyledNewsItemImage = styled.div`
  background-image: url('${({ $backgroundImage }) => $backgroundImage}');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 20vh;

  ${media.tablet`
    height: 150px;
  `}
`

const StyledNewsItemTitle = styled(Typography)`
  margin: ${({ theme }) => theme.sizings.lvl2} 0 0;
  text-transform: none;
  font-weight: 300;
  line-height: 1.35;
`

const StyledNewsItemIntro = styled(Typography)`
  margin: ${({ theme }) => theme.sizings.lvl1} 0
    ${({ theme }) => theme.sizings.lvl2};
`

const StyledNewsItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a:last-of-type {
    padding-right: 0;
  }
`

const NavigationBarNewsContent = forwardRef(
  ({ newsItem, handleClose }, ref) => {
    const { t } = useTranslation()

    return (
      <StyledNewsItem ref={ref}>
        <DialogTitle>
          <StyledIcon type="newsItemsFolded" />
          <StyledTypography type="Level4Heading">
            {t('importantNews')}
          </StyledTypography>
          <StyledCloseButton
            level="minimal"
            icon="close"
            iconSize="md"
            iconColor="darkestBackground"
            onClick={handleClose}
          />
        </DialogTitle>
        {newsItem?.img && (
          <StyledNewsItemImage $backgroundImage={newsItem?.img} />
        )}
        <StyledNewsItemTitle type="Level4Heading">
          {newsItem.title}
        </StyledNewsItemTitle>
        <StyledNewsItemIntro type="BodyParagraph">
          {newsItem.intro}
        </StyledNewsItemIntro>
        <StyledNewsItemFooter>
          <Button
            level="cta"
            href={`${newsItem.link}?utm_source=ucc_app&utm_medium=aandacht_popup_lees_verder_button`}
          >
            {t('readMore')}
          </Button>
          <Button
            level="option"
            href="https://go-remarketing.com/actueel/?utm_source=ucc_app&utm_medium=aandacht_popup_nieuwsoverzicht_button"
          >
            {t('goToNewsOverview')}
          </Button>
        </StyledNewsItemFooter>
      </StyledNewsItem>
    )
  },
)

NavigationBarNewsContent.propTypes = {
  newsItem: PropTypes.shape({
    img: PropTypes.string,
    title: PropTypes.string.isRequired,
    intro: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
}

NavigationBarNewsContent.defaultProps = {
  newsItem: null,
}

export default NavigationBarNewsContent
