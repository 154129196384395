import CarEnrichment from 'components/views/car-enrichment/index'
import { connect } from 'react-redux'
import {
  addNewCarData,
  clearData,
  createCarfile,
  getAllAccessories,
  getCarBrands,
  getCarModels,
  getCarTypes,
  getEditions,
  getLicensePlate,
  getNap,
  updateCarfile,
} from 'redux/actions/data'
import { getForms } from 'redux/actions/forms'

const mapStateToProps = ({ data, forms }) => ({
  carBrands: data.carBrands,
  carModels: data.carModels,
  carTypes: data.carTypes,
  edition: data.edition,
  editions: data.editions,
  licensePlate: data.licensePlate,
  newCar: data.newCar,
  nap: data.nap,
  forms: forms.carfile,
})

const mapDispatchToProps = {
  addNewCarData,
  clearData,
  createCarfile,
  getAllAccessories,
  getCarBrands,
  getCarModels,
  getCarTypes,
  getEditions,
  getForms,
  getLicensePlate,
  getNap,
  updateCarfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(CarEnrichment)
