/*
 * This component is almost an exact copy of TransportCompanySettings
 * and parts of it could, and propbably should, be abstracted and
 * reused by both components but since the designs for these components
 * are not final yet and changes to them are expected the code is copied
 * for now until final decisions have been made by the stake holders'
 * reviews.
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import OptionsBar from 'components/layouts/options-bar'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import ExpandableBars from 'components/layouts/expandable-bars'
import SummaryLine from 'components/molecules/summary-line'
import SettingsForm from 'components/redux-containers/settings-form-container'

function CleaningCompaniesSettings({
  getCleaningCompanies,
  cleaningCompanies,
  deleteCleaningCompany,
  openOverlay,
  postDynamicEndpoint,
}) {
  const { t } = useTranslation()
  const cleaningCompaniesData =
    cleaningCompanies && cleaningCompanies.all && cleaningCompanies.all.data
  const cleaningCompaniesLoading =
    !cleaningCompanies ||
    !cleaningCompanies.all ||
    cleaningCompanies.all.loading
  const [currentRelation, setCurrentRelation] = useState(null)
  // Normally the subId is used to specify for which a location
  // a setting is loaded but these settings are cmpany wide and
  // therefore the dummy id 'all' is used which can later be changed
  // in case these settings have to become location specific.
  const subId = 'all'

  useEffect(() => {
    getCleaningCompanies({ id: subId })
  }, [getCleaningCompanies])

  function handleClickAddRelation() {
    openOverlay('dynamicForm', {
      formId: 'cleaningCompany',
      onSubmit: (values, endpoint) => {
        postDynamicEndpoint({
          endpoint,
          ...values,
        })
      },
      icon: 'preCalculation',
      onUpdateSuccess: () => getCleaningCompanies({ id: subId }),
      submitText: t('saveChanges'),
      title: t('addCleaningCompany'),
    })
  }

  function handleClickDelete(relation) {
    if (window.confirm(t('areYouSure'))) {
      deleteCleaningCompany({
        id: subId,
        company_id: relation.id,
      })
    }
  }

  return (
    <>
      <OptionsBar
        leftAligned={[
          <Text type="h2" text={t('cleaningCompanies')} key="title" />,
        ]}
        rightAligned={[
          <Button
            onClick={handleClickAddRelation}
            text={t('addCleaningCompany')}
            key="addButton"
          />,
        ]}
      />
      {cleaningCompaniesData && !!cleaningCompaniesData.length && (
        <ExpandableBars
          fullRowIsClickable={false}
          rows={cleaningCompaniesData.map((relation, index) => ({
            id: relation.id,
            header: (
              <SummaryLine
                key={index.toString()}
                name={relation.bedrijf}
                details={[]}
                actions={[
                  {
                    action: () => handleClickDelete(relation),
                    text: t('delete'),
                  },
                ]}
              />
            ),
            onRowClick: () => setCurrentRelation(relation.id),
            details: (
              <SettingsForm
                formId="cleaningCompany"
                params={{ company_id: currentRelation }}
                subId={relation.id}
                onUpdateSuccess={() => getCleaningCompanies({ id: subId })}
              />
            ),
          }))}
        />
      )}
      {!cleaningCompaniesLoading && !cleaningCompaniesData.length && (
        <Text text={t('noCleaningCompaniesYet')} type="floatingLabelLabel" />
      )}
    </>
  )
}

CleaningCompaniesSettings.propTypes = {
  getCleaningCompanies: PropTypes.func.isRequired,
  cleaningCompanies: PropTypes.object,
  deleteCleaningCompany: PropTypes.func.isRequired,
  openOverlay: PropTypes.func.isRequired,
  postDynamicEndpoint: PropTypes.func.isRequired,
}

CleaningCompaniesSettings.defaultProps = {
  cleaningCompanies: null,
}

export default CleaningCompaniesSettings
