import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const Card = styled.li`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
  border: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};

  > section {
    padding: ${({ theme }) => theme.sizings.lvl2};
  }

  > footer {
    border-top: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
  }
`

const SectionItemContainer = styled.div`
  margin: ${({ theme }) => theme.sizings.lvl2} 0;
`

const SectionItemHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

const ActionContainer = styled.div`
  display: block;
  margin: ${({ theme }) => theme.sizings.lvl2} 0;
  text-align: center;
`

/**
 * Takes tabular data and displays it in a series of cards.
 * Useful for displaying tabular data in a mobile friendly way.
 */
function TextualDataCards({ columns, rows, actions }) {
  return (
    <ol>
      {rows.map((row, index) => (
        <Card key={index.toString()}>
          <section>
            {columns.map(
              (column, columnIndex) =>
                column &&
                row[columnIndex] && (
                  <SectionItemContainer key={columnIndex.toString()}>
                    <SectionItemHeader>{column}</SectionItemHeader>
                    {row[columnIndex]}
                  </SectionItemContainer>
                ),
            )}
          </section>
          {actions && !!actions.length && (
            <footer>
              {Array.isArray(actions[index]) &&
                actions[index].map((action, actionIndex) => (
                  <ActionContainer key={actionIndex.toString()}>
                    {action}
                  </ActionContainer>
                ))}
            </footer>
          )}
        </Card>
      ))}
    </ol>
  )
}

TextualDataCards.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
}

TextualDataCards.defaultProps = {
  actions: [],
}

export default TextualDataCards
