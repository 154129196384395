import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import FilterCollapser from 'components/molecules/filter-collapser'
import Checkbox from 'components/atoms/checkbox'
import TextLink from 'components/atoms/text-link'
import { toKebabCase } from 'utilities/format'

const StyledCheckbox = styled(Checkbox)`
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`

const StyledTextLink = styled(TextLink)`
  margin-top: ${({ theme }) => theme.sizings.lvl1};
`

function CheckboxFilter({
  name,
  filterOptions,
  initallyOpen,
  onFilterUpdate,
  selectedFilters,
  title,
}) {
  const { t } = useTranslation()
  const [showHiddenValues, setShowHiddenValues] = useState(false)
  const maxOptions = 8
  // If there are slightly more than maxOptions, show the other ones too.
  // Otherwise some one values might be hidden, while the rest is shown.
  const hideOptions = maxOptions + 5 < filterOptions.length
  const visibleOptions =
    hideOptions && !showHiddenValues
      ? filterOptions.filter((item, index) => index < maxOptions)
      : filterOptions
  const toggleText = !showHiddenValues
    ? t('showMoreFilterValues')
    : t('showLessFilterValues')

  function toggleHidden() {
    setShowHiddenValues(!showHiddenValues)
  }

  function handleChange(value, checked) {
    const newSelectedFilters = checked
      ? [...selectedFilters, value]
      : selectedFilters.filter((v) => v !== value)

    onFilterUpdate(name, newSelectedFilters)
  }

  return (
    <FilterCollapser initallyOpen={initallyOpen} title={t(title)}>
      <>
        {visibleOptions.map((option, index) => (
          <StyledCheckbox
            key={index.toString()}
            checked={selectedFilters.indexOf(option.value) !== -1}
            id={`${name}_${option.value}`}
            name={`${name}_${option.value}`}
            onChange={(checked) => handleChange(option.value, checked)}
            selected={selectedFilters}
            title={t(option.label)}
            value={String(option.value)}
            data-test-e2e={toKebabCase(`filter-option-${name}-${option.value}`)}
          />
        ))}
        {hideOptions && (
          <StyledTextLink onClick={toggleHidden} text={toggleText} />
        )}
      </>
    </FilterCollapser>
  )
}

CheckboxFilter.propTypes = {
  name: PropTypes.string.isRequired,
  filterOptions: PropTypes.array.isRequired,
  initallyOpen: PropTypes.bool,
  onFilterUpdate: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array,
  title: PropTypes.string.isRequired,
}

CheckboxFilter.defaultProps = {
  initallyOpen: null,
  selectedFilters: [],
}

export default CheckboxFilter
