import React from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { CarFileStatusEnumLowerCase } from 'config/enums'
import { DEALER_CLUSTERS, DELETE_DEALER_CLUSTER } from 'config/graphql/v4'

import { withApolloV4Provider } from 'utilities/apollo'
import toast from 'utilities/toast'

import ActionIconButton from 'components/atoms/action-icon-button'
import BodyHeading from 'components/atoms/body-heading'
import Button from 'components/atoms/button'
import Icon from 'components/atoms/icon'
import LoadingIndicator from 'components/atoms/loading-indicator'
import NewTooltip from 'components/atoms/new-tooltip'
import ExpandableBars from 'components/layouts/expandable-bars'
import Tabs from 'components/layouts/tabs'
import FlexibleDialog from 'components/molecules/flexible-dialog'
import Labels from 'components/molecules/labels'
import SummaryLine from 'components/molecules/summary-line'
import Typography from 'components/molecules/typography'

import ClusterFormOverlay from './cluster-form-overlay'
import { StatusChecklistsSettings } from './status-checklists-settings'

const PositionedNewTooltip = styled(NewTooltip)`
  vertical-align: middle;
`

const NewClusterButton = styled(Button)`
  align-self: flex-start;
`
const ClustersOverview = ({ match }) => {
  const { t } = useTranslation()
  const [showNewClusterOverlay, setShowNewClusterOverlay] =
    React.useState(false)
  const [clusterToUpdate, setClusterToUpdate] = React.useState(null)
  const [clusterToRemove, setClusterToRemove] = React.useState(null)
  const [isBusy, setIsBusy] = React.useState(false)
  const {
    data: dealerClusters,
    loading: dealerClustersLoading,
    error: dealerClustersError,
    refetch,
  } = useQuery(DEALER_CLUSTERS, {
    fetchPolicy: 'network-only',
  })

  const refetchQueriesForClusters = [
    {
      query: DEALER_CLUSTERS,
    },
  ]

  const [deleteDealerCluster] = useMutation(DELETE_DEALER_CLUSTER, {
    refetchQueries: refetchQueriesForClusters,
  })

  const dealerNrsWithChecklistAccess = useSelector((state) => {
    const dealersRights = state?.auth?.userDetails?.dealers
    if (Array.isArray(dealersRights)) {
      return dealersRights
        .filter((dealer) => dealer.rechten.flexflow)
        .map((dealer) => dealer.dealernr)
    }
    return []
  })

  const handleUpdateCluster = (cluster) => {
    setClusterToUpdate(cluster)
  }

  const handleDeleteCluster = async () => {
    if (clusterToRemove?.id && clusterToRemove?.name) {
      const { id, name } = clusterToRemove
      try {
        await deleteDealerCluster({
          variables: {
            id,
          },
        })
        setClusterToRemove(null)
        toast.success(
          t('checklistsSettings.deleteClusterDialog.successMessage', { name }),
        )
      } catch (error) {
        toast.error(
          t('checklistsSettings.deleteClusterDialog.errorMessage', {
            name,
            error,
          }),
        )
      }
    } else {
      toast.error(t('checklistsSettings.deleteClusterDialog.dataErrorMessage'))
    }
  }

  const showDeleteClusterDialog = (cluster) => {
    setClusterToRemove(cluster)
  }

  const handleChange = () => {
    setIsBusy(true)
    refetch().finally((...args) => {
      setIsBusy(false)
    })
  }

  return (
    <>
      <BodyHeading level="2">
        {t('checklistsSettings.checklistsPerCluster.sectionHeading')}
        &ensp;
        <PositionedNewTooltip
          title={t(
            'checklistsSettings.checklistsPerCluster.sectionHeadingHelpText',
          )}
        >
          <Icon
            size="md"
            type="info"
            color="actionsStandard"
            title={t(
              'checklistsSettings.checklistsPerCluster.sectionHeadingHelpIcon',
            )}
          />
        </PositionedNewTooltip>
      </BodyHeading>
      {dealerClustersLoading || dealerClustersError ? (
        <LoadingIndicator error={dealerClustersError} />
      ) : (
        <ExpandableBars
          rows={dealerClusters.dealerClusters.map((cluster) => ({
            id: `cluster-${cluster.id}`,
            header: (
              <SummaryLine
                key={`cluster-header-${cluster.id}`}
                name={cluster.name}
                details={[
                  {
                    value: t(
                      'checklistsSettings.checklistsPerCluster.header.noOfDealers',
                      { count: cluster.companies.length },
                    ),
                  },
                  {
                    value: (
                      <>
                        <ActionIconButton
                          icon="edit"
                          width="20"
                          height="20"
                          onClick={() => handleUpdateCluster(cluster)}
                        />
                        &ensp;
                        <ActionIconButton
                          icon="delete"
                          width="20"
                          height="20"
                          onClick={() => showDeleteClusterDialog(cluster)}
                        />
                      </>
                    ),
                  },
                ]}
              />
            ),
            onRowClick: () => {},
            details: (
              <>
                <Typography type="InlineBodyText">
                  {t(
                    'checklistsSettings.checklistsPerCluster.details.containsDealers',
                  )}
                </Typography>
                <Labels
                  texts={cluster.companies.map((dealer) => dealer.name)}
                  gapLevel={0}
                />
                <Tabs
                  items={Object.keys(CarFileStatusEnumLowerCase).map(
                    (status) => ({
                      label: t(`carFileStatusses.${status}`),
                      to: `${match.url}/flexflows-per-cluster/cluster/${cluster.id}/${status}`,
                    }),
                  )}
                  small
                  fullScreenHeightOnMobile={false}
                >
                  {Object.keys(CarFileStatusEnumLowerCase).map((status) => (
                    <StatusChecklistsSettings
                      key={`${cluster.name}-${status}`}
                      status={status}
                      cluster={cluster}
                      checklists={cluster.checklists.filter(
                        (checklist) => checklist.status === status,
                      )}
                      refetchQueries={refetchQueriesForClusters}
                      disableActions={isBusy}
                      onAfterChecklistSave={(...args) => {
                        handleChange()
                      }}
                      onAfterChecklistDelete={(...args) => {
                        handleChange()
                      }}
                      onAfterChecklistItemSave={(...args) => {
                        handleChange()
                      }}
                      onAfterChecklistItemDelete={(...args) => {
                        handleChange()
                      }}
                      onBeforeChecklistItemOrderChange={(...args) => {
                        setIsBusy(true)
                      }}
                      onAfterChecklistItemOrderChange={(...args) => {
                        handleChange()
                      }}
                    />
                  ))}
                </Tabs>
              </>
            ),
          }))}
        />
      )}
      <NewClusterButton
        noPadding
        level="option"
        onClick={() => setShowNewClusterOverlay(true)}
      >
        + {t('checklistsSettings.checklistsPerCluster.addClusterButtonText')}
      </NewClusterButton>
      {showNewClusterOverlay && (
        <ClusterFormOverlay
          dealerNrsWithChecklistAccess={dealerNrsWithChecklistAccess}
          onClose={() => setShowNewClusterOverlay(false)}
          refetchQueries={refetchQueriesForClusters}
        />
      )}
      {clusterToUpdate && (
        <ClusterFormOverlay
          cluster={clusterToUpdate}
          dealerNrsWithChecklistAccess={dealerNrsWithChecklistAccess}
          onClose={() => setClusterToUpdate(null)}
          refetchQueries={refetchQueriesForClusters}
        />
      )}
      <FlexibleDialog
        open={!!clusterToRemove}
        title={t('checklistsSettings.deleteClusterDialog.title', {
          name: clusterToRemove?.name || '',
        })}
        content={t('checklistsSettings.deleteClusterDialog.message')}
        cancel
        cancelText={t('cancel')}
        onCancel={() => setClusterToRemove(null)}
        submitText={t(
          'checklistsSettings.deleteClusterDialog.submitButtonText',
        )}
        onSubmit={handleDeleteCluster}
      />
    </>
  )
}

ClustersOverview.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default withApolloV4Provider(ClustersOverview)
