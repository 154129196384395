import LocationsSettings from 'components/redux-containers/locations-settings-container'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as React from 'react'
import { withApolloV4Provider } from 'utilities/apollo'
import LocationRDWCertificate from 'components/organisms/location-rdw-certificate'

const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl0};
`

const LocationsRDWSettings = ({ match }) => {
  const { t } = useTranslation()
  const tabItems = [
    {
      label: t('rdwCertificateSettings.title'),
      to: `${match.url}/certificaat`,
      // eslint-disable-next-line react/prop-types
      component: ({ locationId }) => (
        <LocationRDWCertificate locationId={locationId} />
      ),
    },
  ]

  return (
    <StyledArticle>
      <LocationsSettings
        title={t('rdwSettings')}
        tabItems={tabItems}
        appFeature="rdw"
        appFeatureNotAllowedMessageId="rdwCertificateSettings.locationNotAllowed"
      />
    </StyledArticle>
  )
}

LocationsRDWSettings.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(withApolloV4Provider(LocationsRDWSettings))
