import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'

import { withApolloV4Provider } from 'utilities/apollo'

import {
  QUERY_MANAGEMENT_INFO_CHART_123,
  QUERY_MANAGEMENT_INFO_CHART_123_WITH_STOCK_DAY_SELECTED,
} from 'config/graphql/v4'
import {
  DATE_FORMAT,
  LINE_CHART_Y_MARGIN_BOTTOM,
  LINE_CHART_Y_MARGIN_TOP,
  LINE_CHART_Y_MIN,
} from 'config/consts'
import { ManagementInfoStockDays123Enum } from 'config/enums'

import ManagementInfoChart from 'components/molecules/management-info-chart'
import ManagementInfoChartControls from 'components/molecules/management-info-chart-controls'
import ManagementInfoChartContainer from 'components/molecules/management-info-chart-container'
import ManagementInfoChartLegend from 'components/molecules/management-info-chart-legend'
import ManagementInfoChartLoadingIndicator from 'components/molecules/management-info-chart-loading-indicator'
import ManagementInfoChartSelect from 'components/molecules/management-info-chart-select'
import ManagementInfoChartTitle from 'components/molecules/management-info-chart-title'
import ManagementInfoChartTitleLoadingIndicator from 'components/molecules/management-info-chart-title-loading-indicator'

import { DEFAULT_VALUE_STANDTIME } from 'components/views/management-info/index'

const StyledManagementInfoChartLegend = styled(ManagementInfoChartLegend)`
  opacity: ${({ $loading }) => ($loading ? 0.25 : 1)};
`

const ChartLoadingIndicator = styled(ManagementInfoChartLoadingIndicator)`
  opacity: ${({ $loading }) => ($loading ? 1 : 0)};
`

const StyledManagementInfoChart = styled(ManagementInfoChart)`
  opacity: ${({ $loading }) => ($loading ? 0.0625 : 1)};
`

const ManagementInfoChartStandtime123Last12Months = ({
  dealerNumbers,
  filters,
}) => {
  const { t } = useTranslation()
  const [selectedFilter, setSelectedFilter] = useState(DEFAULT_VALUE_STANDTIME)
  const [initialDataLoaded, setInitialDataLoaded] = useState(false)

  const dateToday = moment(new Date()).format(DATE_FORMAT)
  const dateTodayOneYearAgo = moment(dateToday)
    .subtract(1, 'year')
    .format(DATE_FORMAT)

  const [
    unfilteredQuery,
    { data: unfilteredData, loading: unfilteredDataLoading },
  ] = useLazyQuery(QUERY_MANAGEMENT_INFO_CHART_123, {
    // This prevents Apollo to automatically re-query when the component re-renders
    nextFetchPolicy: 'standby',
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setInitialDataLoaded(true)
    },
  })

  const [filteredQuery, { data: filteredData, loading: filteredDataLoading }] =
    useLazyQuery(QUERY_MANAGEMENT_INFO_CHART_123_WITH_STOCK_DAY_SELECTED, {
      // This prevents Apollo to automatically re-query when the component re-renders
      nextFetchPolicy: 'standby',
      fetchPolicy: 'network-only',
    })

  const unfilteredChartData = unfilteredData?.allStandtimes.StockDays123s || []
  const filteredChartDataFirst = filteredData?.first?.StockDays123s || []
  const filteredChartDataSecond = filteredData?.second?.StockDays123s || []
  const filteredChartDataThird = filteredData?.third?.StockDays123s || []

  const isFiltered = selectedFilter !== DEFAULT_VALUE_STANDTIME
  const filterIndex = Number(selectedFilter.match(/\d+/)?.[0])

  const chartData = {
    datasets: [
      // Data set for "Gemiddelde statijd 1" and "Eigen statijd"
      {
        label: t(
          `managementInfo.charts.standtime123Last12Months.legends.${isFiltered ? 'filteredStandtime' : 'unfilteredStandtime'}.first`,
        ),
        data: isFiltered
          ? filteredChartDataFirst.map((day) => ({
              x: day.createdAt,
              y: Number(day[`stock_days_${filterIndex}`]),
            }))
          : unfilteredChartData.map((day) => ({
              x: day.createdAt,
              y: Number(day.stock_days_1),
            })),
        color: 'chartsComparisonGroup',
        steppedLine: false,
        cubicInterpolationMode: 'monotone',
        pointRadius: 2,
      },

      // Data set for "Gemiddelde statijd 2" and "Vergelijkingsgroep"
      {
        label: t(
          `managementInfo.charts.standtime123Last12Months.legends.${isFiltered ? 'filteredStandtime' : 'unfilteredStandtime'}.second`,
        ),
        data: isFiltered
          ? filteredChartDataSecond.map((day) => ({
              x: day.createdAt,
              y: Number(day[`stock_days_${filterIndex}`]),
            }))
          : unfilteredChartData.map((day) => ({
              x: day.createdAt,
              y: Number(day.stock_days_2),
            })),
        color: 'chartsTarget',
        steppedLine: false,
        cubicInterpolationMode: 'monotone',
        pointRadius: 2,
      },

      // Data set for "Gemiddelde statijd 3" and "Best-in-class"
      {
        label: t(
          `managementInfo.charts.standtime123Last12Months.legends.${isFiltered ? 'filteredStandtime' : 'unfilteredStandtime'}.third`,
        ),
        data: isFiltered
          ? filteredChartDataThird.map((day) => ({
              x: day.createdAt,
              y: Number(day[`stock_days_${filterIndex}`]),
            }))
          : unfilteredChartData.map((day) => ({
              x: day.createdAt,
              y: Number(day.stock_days_3),
            })),
        color: 'chartsMyself',
        steppedLine: false,
        cubicInterpolationMode: 'monotone',
        pointRadius: 2,
      },
    ],
  }

  const chartDataPoints = [
    ...chartData.datasets.map((dataset) => dataset.data),
  ].flat()
  const chartYMin = Math.floor(
    Number(
      chartDataPoints.reduce(
        (prev, curr) => (curr.y < prev ? curr.y : prev),
        Infinity,
      ),
    ) * LINE_CHART_Y_MARGIN_BOTTOM,
  )
  const chartYMax = Math.ceil(
    Number(
      chartDataPoints.reduce(
        (prev, curr) => (curr.y > prev ? curr.y : prev),
        LINE_CHART_Y_MIN,
      ),
    ) * LINE_CHART_Y_MARGIN_TOP,
  )

  const chartOptions = {
    animation: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label || ''
          return `${label}: ${tooltipItem.yLabel} ${t('managementInfo.charts.standtime123Last12Months.legends.unit')}`
        },
      },
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t(
              'managementInfo.charts.standtime123Last12Months.legends.yAxis',
            ),
          },
          ticks: {
            max: chartYMax,
            min: chartYMin <= 10 ? 0 : chartYMin,
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          distribution: 'series',
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: t(
              'managementInfo.charts.standtime123Last12Months.legends.xAxis',
            ),
          },
          time: {
            unit: 'week',
            displayFormats: {
              week: 'W',
            },
          },
        },
      ],
    },
  }

  const chartLegendItems = [
    // Legend for "Gemiddelde statijd 1" and "Eigen statijd"
    {
      label: t(
        `managementInfo.charts.standtime123Last12Months.legends.${isFiltered ? 'filteredStandtime' : 'unfilteredStandtime'}.first`,
      ),
      color: 'chartsComparisonGroup',
      backgroundColor: 'fieldBackground',
      value: isFiltered
        ? filteredData?.first?.[`averageStockDays${filterIndex}`]
        : unfilteredData?.allStandtimes?.averageStockDays1,
    },

    // Legend for "Gemiddelde statijd 2" and "Vergelijkingsgroep"
    {
      label: t(
        `managementInfo.charts.standtime123Last12Months.legends.${isFiltered ? 'filteredStandtime' : 'unfilteredStandtime'}.second`,
      ),
      color: 'chartsTarget',
      backgroundColor: 'fieldBackground',
      value: isFiltered
        ? filteredData?.second?.[`averageStockDays${filterIndex}`]
        : unfilteredData?.allStandtimes?.averageStockDays2,
    },

    // Legend for "Gemiddelde statijd 3" and "Best-in-class"
    {
      label: t(
        `managementInfo.charts.standtime123Last12Months.legends.${isFiltered ? 'filteredStandtime' : 'unfilteredStandtime'}.third`,
      ),
      color: 'chartsMyself',
      backgroundColor: 'fieldBackground',
      value: isFiltered
        ? filteredData?.third?.[`averageStockDays${filterIndex}`]
        : unfilteredData?.allStandtimes?.averageStockDays3,
    },
  ]

  useEffect(() => {
    if (selectedFilter === DEFAULT_VALUE_STANDTIME) {
      unfilteredQuery({
        variables: {
          dealerNrs: dealerNumbers,
          fromDate: dateTodayOneYearAgo,
          tillDate: dateToday,
        },
      })
    } else {
      filteredQuery({
        variables: {
          dealerNrs: dealerNumbers,
          fromDate: dateTodayOneYearAgo,
          tillDate: dateToday,
          stockDaysType: selectedFilter,
          includeStockDays1:
            selectedFilter === ManagementInfoStockDays123Enum.STOCK_DAYS_1,
          includeStockDays2:
            selectedFilter === ManagementInfoStockDays123Enum.STOCK_DAYS_2,
          includeStockDays3:
            selectedFilter === ManagementInfoStockDays123Enum.STOCK_DAYS_3,
        },
      })
    }
  }, [
    dateToday,
    dateTodayOneYearAgo,
    dealerNumbers,
    selectedFilter,
    filteredQuery,
    unfilteredQuery,
  ])

  return (
    <>
      <div>
        <ManagementInfoChartTitle>
          {t('managementInfo.charts.standtime123Last12Months.title')}
          {(unfilteredDataLoading || filteredDataLoading) &&
            !initialDataLoaded && <ManagementInfoChartTitleLoadingIndicator />}
        </ManagementInfoChartTitle>
        <ManagementInfoChartControls>
          <ManagementInfoChartSelect
            items={filters}
            onChange={setSelectedFilter}
            value={selectedFilter}
            label={t('filters')}
          />
          <StyledManagementInfoChartLegend
            $loading={unfilteredDataLoading || filteredDataLoading}
            arrangeAs="row"
            items={chartLegendItems}
            unit={t(
              'managementInfo.charts.standtime123Last12Months.legends.unit',
            )}
          />
        </ManagementInfoChartControls>
      </div>
      <ManagementInfoChartContainer>
        <ChartLoadingIndicator
          $loading={
            (unfilteredDataLoading || filteredDataLoading) && initialDataLoaded
          }
          loadingText={t('managementInfo.charts.loadingData')}
        />
        <StyledManagementInfoChart
          $loading={
            (unfilteredDataLoading || filteredDataLoading) && initialDataLoaded
          }
          options={chartOptions}
          data={chartData}
        />
      </ManagementInfoChartContainer>
    </>
  )
}

ManagementInfoChartStandtime123Last12Months.propTypes = {
  dealerNumbers: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string).isRequired,
  ]),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default withApolloV4Provider(ManagementInfoChartStandtime123Last12Months)
