import { gql } from '@apollo/client'
import brandInfoFragment from './brand'
import modelInfoFragment from './model'

export default gql`
  fragment carInfo on Car {
    id
    carFileId
    dealerId
    brand {
      ...brandInfo
    }
    model {
      ...modelInfo
    }
    mileage
    engineCapacity
    registrationDate
    licensePlate
    carPictures {
      type
      url
    }
  }
  ${brandInfoFragment}
  ${modelInfoFragment}
`
