import React from 'react'
import TextInput, {
  propTypes as textInputPropTypes,
  defaultProps as textInputDefaultProps,
} from 'components/atoms/new-text-input'
import { withApolloV4Provider } from 'utilities/apollo'
import LoadingButton from 'components/atoms/loading-button'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { GENERATE_CAR_ADVERTISEMENT_TEXT } from 'config/graphql/v4'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import ExplanationIcon from 'components/atoms/explanation-icon'
import Tooltip from 'components/atoms/new-tooltip'

const ActionsContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizings.lvl1};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.sizings.lvl1};
`

const CarAdvertisementTextInput = ({
  className,
  carFileId,
  language,
  value,
  onChange,
  disabled,
  data: _, // Filter out the data prop, we don't need it
  ...restProps
}) => {
  const { t } = useTranslation()
  const [generateText, { loading }] = useLazyQuery(
    GENERATE_CAR_ADVERTISEMENT_TEXT,
    {
      variables: { carFileId, language },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data?.generateText?.text) {
          onChange(data.generateText.text)
        }
      },
    },
  )

  return (
    <div className={className}>
      <TextInput
        onChange={onChange}
        value={value}
        disabled={loading || disabled}
        {...restProps}
      />
      <ActionsContainer>
        <LoadingButton
          isLoading={loading}
          onClick={(e) => {
            if (value) {
              if (
                window.confirm(
                  t('generateCarAdvertisementText.confirmOverwrite'),
                )
              ) {
                generateText()
              }
            } else {
              generateText()
            }
          }}
        >
          {t('generateCarAdvertisementText.buttonLabel')}
        </LoadingButton>
        <Tooltip
          title={t('generateCarAdvertisementText.explanation')}
          width={200}
        >
          <ExplanationIcon />
        </Tooltip>
      </ActionsContainer>
    </div>
  )
}

CarAdvertisementTextInput.propTypes = {
  ...textInputPropTypes,
  carFileId: PropTypes.string.isRequired,
  language: PropTypes.string,
}

CarAdvertisementTextInput.defaultProps = {
  ...textInputDefaultProps,
}

export default withApolloV4Provider(CarAdvertisementTextInput)
