import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Overlay from 'components/molecules/overlay'
import MediaForm from 'components/organisms/media-form'

const StyledMediaForm = styled(MediaForm)`
  width: 100%;
`

function SelectMediaOverlay({
  closeOverlay,
  title,
  text,
  onSubmitText,
  onSubmit,
  items,
  type,
  exclusiveSelect,
}) {
  return (
    <Overlay
      close={closeOverlay}
      icon="media"
      count={items.length}
      title={title}
      text={text}
    >
      <StyledMediaForm
        level="standard"
        uploadButtonText={onSubmitText}
        onSelect={onSubmit}
        items={items}
        type={type}
        exclusiveSelect={exclusiveSelect}
      />
    </Overlay>
  )
}

SelectMediaOverlay.propTypes = {
  closeOverlay: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  onSubmitText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      src: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['image', 'document']).isRequired,
    }),
  ).isRequired,
  type: PropTypes.oneOf(['images', 'documents']),
  exclusiveSelect: PropTypes.bool,
}

SelectMediaOverlay.defaultProps = {
  type: 'images',
  text: undefined,
  exclusiveSelect: false,
}

export default SelectMediaOverlay
