import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getObjectFromQueryString, handleChangeQuery } from 'utilities/utils'
import LoadingIndicator from 'components/atoms/loading-indicator'
// import SearchResultsOverview from 'components/layouts/search-results-overview';
import SimpleSearchResults from 'components/organisms/simple-search-results'
import DropDownFilter from 'components/molecules/drop-down-filter'

function TransactionsOverview({
  history,
  location,
  getTransactions,
  transactions,
  transactionTypes,
  getTransactionTypes,
}) {
  const { t } = useTranslation()
  // this view's state is maintained through the query string in the URL:
  const queryFilters = getObjectFromQueryString(location.search)
  const compiledFilters = {
    filter_type: queryFilters.filter_type,
    page: queryFilters.page,
  }
  if (queryFilters.filter_month && queryFilters.filter_year) {
    compiledFilters.filter_monthyear = `${queryFilters.filter_month}-${queryFilters.filter_year}`
  }

  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth() + 1
  // generate a list of 20 years in the past starting from this year:
  const yearsInThePast = 20
  const years = []
  for (let year = currentYear; year > currentYear - yearsInThePast; year -= 1) {
    years.push({
      label: String(year),
      id: String(year),
    })
  }
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    .filter((item) => {
      if (
        queryFilters.filter_year &&
        queryFilters.filter_year === String(currentYear)
      ) {
        return currentMonth >= item
      }
      return true
    })
    .map((id) => ({
      label: t(`months.${id}`),
      id: String(id),
    }))
  // do not show the months (in the current year) that are in the future:p
  //

  useDeepCompareEffect(() => {
    getTransactions(compiledFilters)
  }, [getTransactions, compiledFilters])

  useEffect(() => {
    if (transactionTypes) {
      return
    }
    getTransactionTypes()
  }, [getTransactionTypes, transactionTypes])

  function handleFilterUpdate(key, value) {
    handleChangeQuery(location, history, key, String(value), key !== 'page')
  }

  return (
    <>
      {transactions && transactions.loading === false && transactions.data ? (
        <SimpleSearchResults
          filtering={[
            <DropDownFilter
              key="filter-type"
              filterOptions={
                transactionTypes && transactionTypes.data
                  ? [
                      { label: t('transactionType'), id: '' },
                      ...transactionTypes.data,
                    ]
                  : []
              }
              onFilterUpdate={(newValue) =>
                handleFilterUpdate('filter_type', newValue)
              }
              selectedFilter={
                queryFilters.filter_type ? queryFilters.filter_type : ''
              }
            />,
            <DropDownFilter
              key="filter-year"
              filterOptions={[{ label: t('year'), id: '' }, ...years]}
              onFilterUpdate={(newValue) =>
                handleFilterUpdate('filter_year', newValue)
              }
              selectedFilter={queryFilters.filter_year}
            />,
            <DropDownFilter
              key="filter-month"
              filterOptions={[{ label: t('month'), id: '' }, ...months]}
              onFilterUpdate={(newValue) =>
                handleFilterUpdate('filter_month', newValue)
              }
              selectedFilter={queryFilters.filter_month}
            />,
          ]}
          data={transactions.data.data}
          noDataMessage={t('noTransactionsFound')}
          dataMapping={{
            licensePlate: {
              label: t('licensePlate'),
              getter: (d) => String(d.kenteken).toUpperCase(),
            },
            employee: {
              label: t('employee'),
              getter: (d) => d.medewerker,
            },
            description: {
              label: t('description'),
              getter: (d) => d.melding,
            },
            date: {
              label: t('date'),
              getter: (d) => d.datum,
            },
          }}
          ordering={['licensePlate', 'employee', 'description', 'date']}
          onChangePage={(newPage) => handleFilterUpdate('page', newPage)}
          page={Number(queryFilters.page || 1)}
          itemsPerPage={30}
          totalItems={Number(transactions.data.total)}
        />
      ) : (
        <LoadingIndicator />
      )}
    </>
  )
}

TransactionsOverview.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getTransactions: PropTypes.func.isRequired,
  transactions: PropTypes.object,
  getTransactionTypes: PropTypes.func.isRequired,
  transactionTypes: PropTypes.object,
}

TransactionsOverview.defaultProps = {
  transactions: null,
  transactionTypes: null,
}

export default withRouter(TransactionsOverview)
