import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import Definition from 'components/atoms/definition'
import GalleryImage from 'components/atoms/gallery-image'
import LicensePlate from 'components/atoms/license-plate'
import Text from 'components/atoms/text'
import NapTag from 'components/molecules/nap-tag'

// All sections have same width to ensure their content
// is displayed in a visual consistent way (aka not messy).
const sectionWidth = 130

const Container = styled.div`
  align-items: flex-start;
  display: flex;
`

const StyledImage = styled.div`
  flex: 1 0 auto;
  height: 75px;
  width: ${sectionWidth}px;
  min-width: ${sectionWidth}px;
  max-width: ${sectionWidth}px;
`

const StyledHeader = styled.div`
  flex: 1 0 auto;
  margin: 0 ${({ theme }) => theme.sizings.lvl1};
  width: ${sectionWidth}px;
  min-width: ${sectionWidth}px;
  max-width: ${sectionWidth}px;

  > * {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const ExtraInfoContainer = styled.section`
  min-width: ${sectionWidth + 8}px;
  padding: 0 0 0 ${({ theme }) => theme.sizings.lvl1};
  border-left: 1px solid ${({ theme }) => theme.colors.brandDelta};
`

const StyledModel = styled(Text)`
  margin-top: 5px;
`

const StyledLicensePlate = styled(LicensePlate)`
  margin-top: ${({ theme }) => theme.sizings.lvl1};
`

const StyledNapTag = styled(NapTag)`
  margin-top: ${({ theme }) => theme.sizings.lvl1};
`

function CarfileAdCard({
  brand,
  className,
  image,
  license,
  manufactured,
  model,
  nap,
  napDetails,
  ...restProps
}) {
  const { t } = useTranslation()

  return (
    <Container className={className} {...restProps}>
      <StyledImage>
        <GalleryImage
          src={image}
          customErrorMessage=""
          alt=""
          ratio={16 / 9}
          blurredBackground
        />
      </StyledImage>
      <StyledHeader>
        <Text type="h5b" text={brand} />
        <StyledModel type="h4" text={model} />
        <StyledLicensePlate size="small" value={license} />
      </StyledHeader>
      {(manufactured || nap) && (
        <ExtraInfoContainer>
          {manufactured && (
            <Definition title={t('yearOfBuild')} value={manufactured} />
          )}
          {nap !== undefined && (
            <StyledNapTag state={nap} toolTipMessage={napDetails} />
          )}
        </ExtraInfoContainer>
      )}
    </Container>
  )
}

CarfileAdCard.propTypes = {
  brand: PropTypes.string.isRequired,
  className: PropTypes.string,
  image: PropTypes.string,
  license: PropTypes.string.isRequired,
  manufactured: PropTypes.string,
  model: PropTypes.string.isRequired,
  nap: PropTypes.bool,
  napDetails: PropTypes.string,
}

CarfileAdCard.defaultProps = {
  className: null,
  image: null,
  manufactured: undefined,
  nap: null,
  napDetails: null,
}

export default CarfileAdCard
