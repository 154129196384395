import React, { useContext, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import { useMediaLayout } from 'use-media'
import { useTranslation } from 'react-i18next'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { reset } from 'redux-form'
import { useHistory } from 'react-router-dom'

import { translateListItems } from 'utilities/utils'

import Button from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Menu from 'components/organisms/menu'
import Modal from 'components/molecules/modal'
import { processItems } from 'components/organisms/action-bar'
import PopupMenu from 'components/molecules/popup-menu'
import Icon from 'components/atoms/icon'

import * as routes from 'config/routes'

const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }

  svg {
    fill: transparent;

    g {
      stroke: ${({ theme }) => theme.colors.actionsStandardSecondary};
    }
  }
`

const StyledPopupMenu = styled(PopupMenu)`
  .actual-menu {
    top: 0;
    right: 0;
    min-width: 280px;

    div {
      width: 100%;
    }

    .actual-menu {
      position: relative;
      width: 100%;
      padding: 0 ${({ theme }) => theme.sizeByFactor(2)};
      box-shadow: none;
    }

    > p:first-child {
      font-size: ${({ theme }) => theme.sizeByFactor(1.75)};
      padding-left: ${({ theme }) => theme.sizeByFactor(2)};
      padding-right: ${({ theme }) => theme.sizeByFactor(2)};
    }
  }
`

const StyledMenu = styled(Menu)`
  div {
    position: relative;
    width: 100%;
  }

  .actual-menu {
    position: relative;
    width: 100%;
    padding: ${({ theme }) => theme.sizeByFactor(1)};
    box-shadow: none;
    right: 0;
    top: 0;
  }
`

const StyledTitle = styled.span`
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.sizeByFactor(1)};
  align-items: center;

  > span {
    width: auto !important;
  }
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  position: relative;
  top: -${({ theme }) => theme.sizeByFactor(0.25)};
`

const StyledCloseButton = styled(Button)`
  position: relative;
  margin-left: auto;
  top: -${({ theme }) => theme.sizeByFactor(0.25)};
  right: -${({ theme }) => theme.sizeByFactor(1.25)};

  > span {
    width: auto !important;
    height: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: ${({ theme }) => theme.sizeByFactor(1.925)};
    height: ${({ theme }) => theme.sizeByFactor(1.925)};
  }
`

const CarFileCardContextMenu = ({
  carId,
  clearData,
  contextMenuItems,
  data,
  dynamicEndpoint,
  forms,
  getCarfile,
  getDynamicEndpoint,
  getForms,
  openOverlay,
  postDynamicEndpoint,
  ui,
}) => {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })
  const [menuOpen, setMenuOpen] = useState(false)

  const loadingCarfile = useSelector((state) => state.data?.carfile?.loading)
  const loadingFormsGeneral = useSelector(
    (state) => state.data?.forms?.general?.loading,
  )
  const loadingFormsCarfile = useSelector(
    (state) => state.data?.forms?.carfile?.loading,
  )
  const loadingFormsRdw = useSelector(
    (state) => state.data?.forms?.rdw?.loading,
  )
  const loadingFormsCleanup = useSelector(
    (state) => state.data?.forms?.cleanup?.loading,
  )
  const loadingFormsTransport = useSelector(
    (state) => state.data?.forms?.cleanup?.loading,
  )

  const loading =
    loadingCarfile ||
    loadingFormsGeneral ||
    loadingFormsCarfile ||
    loadingFormsRdw ||
    loadingFormsCleanup ||
    loadingFormsTransport
  const dynamicEndpointData =
    dynamicEndpoint && dynamicEndpoint.data && dynamicEndpoint.data.data
  const hasOverlay = ui?.overlay?.id

  const translatedItems =
    contextMenuItems && translateListItems(contextMenuItems, t)

  // Action bar items may have a conditions field in their show field
  // which determines whether an action bar should be visible based on
  // the state in the front end. The following code filters out items
  // that don't meet these conditions.
  const visibleItemsForState =
    translatedItems &&
    translatedItems.filter((item) => {
      if (item.show && item.show.conditions && data) {
        const conditionFields = item.show.conditions
        const conditionsMet = conditionFields.reduce((metResult, condition) => {
          const allConditionsMet =
            metResult ||
            Object.entries(condition).reduce(
              (allMetResult, [conditionKey, conditionValues]) =>
                // @ TODO Temporary workaround: whenever the conditionValues
                // is not an array it is used to check wheter an item in
                // the data exists or not.
                allMetResult || Array.isArray(conditionValues)
                  ? conditionValues.indexOf(data[conditionKey]) !== -1
                  : !!data[conditionKey],
              false,
            )
          return metResult || allConditionsMet
        }, false)

        return conditionsMet
      }

      return true
    })

  const processedItems = processItems(
    visibleItemsForState,
    data,
    forms,
    getDynamicEndpoint,
    openOverlay,
    postDynamicEndpoint,
    t,
  )

  const handleOpenMenu = () => {
    setMenuOpen(true)
  }

  const handleCloseMenu = () => {
    setMenuOpen(false)
  }

  const items = processedItems?.map((item) => {
    const itemProps = {
      ...item,
      disabled: !!loading,
      label: t(item.label),
    }

    if (typeof item.onClick === 'function') {
      itemProps.onClick = () => {
        handleCloseMenu()
        item.onClick()
      }
    }

    return itemProps
  })

  useEffect(() => {
    if (hasOverlay) {
      handleCloseMenu()
    }
  }, [hasOverlay])

  useEffect(() => {
    if (menuOpen) {
      getCarfile(carId)
      getForms('general')
      getForms('carfile', { auto_id: carId })
      getForms('rdw', { auto_id: carId })
      getForms('cleanup', { auto_id: carId })
      getForms('transport', { auto_id: carId })
      getForms('bids', { auto_id: carId })
    }
    if (!menuOpen) {
      dispatch(reset('rdw'))
      dispatch(reset('cleanup'))
      dispatch(reset('transport'))
      dispatch(reset('carfile'))
      dispatch(reset('general'))
      dispatch(reset('bids'))
    }
  }, [menuOpen, getForms, carId, dispatch, getCarfile])

  // Whenever the dynamicEndpoint action results in a response that
  // contains an auto_id (which is not the current one we're viewing
  // if we are in the carfile view) we conclude a new car has been
  // created by that call and redirect to its carfile with the following
  // effect
  useEffect(() => {
    if (!data || !dynamicEndpointData) {
      return
    }

    const dataCarId = data.auto_id
    const dynamicCarId = dynamicEndpointData.auto_id
    if (dynamicCarId && dynamicCarId !== dataCarId) {
      clearData('dynamicEndpoint')
      history.push(`${routes.CAR_FILE}/${dynamicCarId}`)
    }
  }, [clearData, data, dynamicEndpointData, history])

  // For some dynamicEndpoints it is necessary to reload the data visible
  // in the currently active view. Since this most probably requires a architectural
  // change to the frontend we have decided to solve this for now with a hard refresh
  // @TODO Refactor this functionality. A ticket has been added to scrumwise that
  // explains the issue and suggests a couple of solutions:
  // https://scrumwise.com/scrum/#/backlog-item/1311-fed-refactor-reload-functionaliteit-voor-action-bar-items/id-171135-2432-67
  const previousLoading = useRef()

  useEffect(() => {
    if (!dynamicEndpoint) {
      return
    }

    if (previousLoading.current && !dynamicEndpoint.loading) {
      if (dynamicEndpoint.payload.redirect) {
        history.push(dynamicEndpoint.payload.redirect)
      } else if (dynamicEndpoint.payload.reload) {
        if (typeof dynamicEndpoint.payload.reload === 'string') {
          history.push(dynamicEndpoint.payload.reload)
        }
        window.location.reload()
      }
    }
    previousLoading.current = dynamicEndpoint.loading
  }, [dynamicEndpoint, history])

  if (!contextMenuItems || !contextMenuItems.length) {
    return null
  }

  if (!isMobile) {
    return (
      <ClickAwayListener onClickAway={handleCloseMenu}>
        <li>
          <StyledPopupMenu
            title={
              <StyledTitle>
                {t('actions')}
                {loading && <StyledLoadingIndicator size="extraSmall" />}
                <StyledCloseButton
                  level="minimal"
                  icon="close"
                  iconColor="darkestBackground"
                  onClick={handleCloseMenu}
                />
              </StyledTitle>
            }
            items={items}
            positionMenu={{ toLeft: true }}
            isMenuActive={menuOpen}
            isMenuLoading={loading}
          >
            <span onClick={handleOpenMenu}>
              <StyledIcon width="24px" height="24px" type="dots" tag="span" />
            </span>
          </StyledPopupMenu>
        </li>
      </ClickAwayListener>
    )
  }

  if (isMobile) {
    return (
      <>
        <li onClick={handleOpenMenu}>
          <StyledIcon width="24px" height="24px" type="dots" />
        </li>
        <Modal
          title={
            <StyledTitle>
              {t('actions')}
              {loading && <StyledLoadingIndicator size="extraSmall" />}
            </StyledTitle>
          }
          showCloseButton
          open={menuOpen}
          closeHandler={handleCloseMenu}
        >
          <StyledMenu variant="popup" isMenuActive={menuOpen} items={items} />
        </Modal>
      </>
    )
  }
}

CarFileCardContextMenu.propTypes = {
  carId: PropTypes.string.isRequired,
  /** provided by wrapping HOC in container: */
  clearData: PropTypes.func.isRequired,
  contextMenuItems: PropTypes.array,
  data: PropTypes.object,
  forms: PropTypes.object,
  getCarfile: PropTypes.func.isRequired,
  getDynamicEndpoint: PropTypes.func.isRequired,
  getForms: PropTypes.func.isRequired,
  openOverlay: PropTypes.func.isRequired,
  postDynamicEndpoint: PropTypes.func.isRequired,
  ui: PropTypes.object,
}

CarFileCardContextMenu.defaultProps = {
  /** provided by wrapping HOC in container: */
  contextMenuItems: null,
  data: null,
  dynamicEndpoint: null,
  forms: null,
  ui: null,
}

export default CarFileCardContextMenu
