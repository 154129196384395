import { connect } from 'react-redux'

import { postDynamicEndpoint } from 'redux/actions/data'
import { getForms } from 'redux/actions/forms'

import SettingsForm from '../molecules/settings-form'

const mapStateToProps = ({ data, forms }) => ({
  dynamicEndpoint: data.dynamicEndpoint,
  forms,
})

const mapDispatchToProps = {
  getForms,
  postDynamicEndpoint,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsForm)
