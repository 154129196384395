import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { STOCK } from 'config/routes'

import Breadcrumbs from 'components/molecules/breadcrumbs'
import CarfileMetaData from 'components/molecules/carfile-meta-data'
import LoadingIndicator from 'components/atoms/loading-indicator'

const Container = styled.div`
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

function CarfileHeader({
  carfile,
  className,
  loading,
  salesView,
  toggleSalesView,
  carShareLocationId,
}) {
  const { t } = useTranslation()
  const carShareLocation = useSelector(
    (state) =>
      state.auth?.userDetails?.dealers?.find(
        (dealer) => dealer.id === carShareLocationId,
      )?.bedrijfsnaam,
  )

  if (loading || !carfile) {
    return (
      <Container className={className}>
        <LoadingIndicator />
      </Container>
    )
  }

  const breadcrumbsItems =
    carShareLocationId && carShareLocation
      ? [
          { label: t('stock'), to: STOCK },
          {
            label: carShareLocation,
            to: `${STOCK}?vestiging_id=${carShareLocationId}`,
          },
          {
            label: t(`carFileStatussesV3.${carfile.status}`),
            to: `${STOCK}?status=${carfile.status}&vestiging_id=${carShareLocationId}`,
          },
          { label: `${t('carFile')} ${carfile.id}` },
        ]
      : [
          { label: t('stock'), to: STOCK },
          { label: carfile.location, to: `${STOCK}?status=${carfile.status}` },
          {
            label: t(`carFileStatussesV3.${carfile.status}`),
            to: `${STOCK}?status=${carfile.status}&vestiging_id=${carfile.vestiging_id}`,
          },
          { label: `${t('carFile')} ${carfile.id}` },
        ]

  return (
    <Container className={className}>
      <Breadcrumbs items={breadcrumbsItems} />
      <CarfileMetaData
        created={carfile.created}
        createdBy={carfile.createdBy}
        salesView={salesView}
        toggleSalesView={toggleSalesView}
      />
    </Container>
  )
}

CarfileHeader.propTypes = {
  carfile: PropTypes.object,
  className: PropTypes.string,
  loading: PropTypes.bool,
  salesView: PropTypes.bool,
  toggleSalesView: PropTypes.func,
  carShareLocationId: PropTypes.string,
}

CarfileHeader.defaultProps = {
  carfile: null,
  className: null,
  loading: true,
  salesView: false,
  toggleSalesView: null,
  carShareLocationId: null,
}

export default CarfileHeader
