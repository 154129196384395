import React from 'react'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/organisms/page-header'
import EnhancedCard from 'components/layouts/enhanced-card'
import StartServiceCalculation from 'components/views/car-service-plan/start-service-calculation'

const Container = styled.div`
  padding: ${({ theme }) => theme.sizings.lvl2} 20px;
  ${media.desktop`
      padding: ${({ theme }) => theme.sizings.lvl2} 60px;
  `}
`

const CarServicePlanSalesFlowDemo = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <PageHeader
        mainTitle={t('trial')}
        status={t('calculation')}
        hasBorderBottom={false}
      />
      <EnhancedCard>
        <StartServiceCalculation demo />
      </EnhancedCard>
    </Container>
  )
}

export default CarServicePlanSalesFlowDemo
