import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'

const baseStyles = css`
  font-family: ${(props) => props.theme.font};
  margin: ${(props) => props.theme.sizings.lvl4} 0
    ${(props) => props.theme.sizings.lvl2} 0;
  &:first-child {
    margin-top: 0;
  }
`

const handleStyledAs = ({ $styledAs, theme }) => {
  switch ($styledAs) {
    case 'oldheading':
      return `
      font-weight: 500;
      line-height: 24px;
      color: ${theme.colors.text};
      font-size: 20px;
    `
    case 'mainheading':
      return `
    color: ${theme.colors.text};
    font-weight: 400;
  `
    case 'subheading':
      return `
    color: ${theme.colors.level2Heading};
    font-size: ${theme.headingH2.size};
    font-weight: ${theme.headingH2.weight};
  `
    default:
      return ''
  }
}

const CustomH1 = styled.h1`
  ${baseStyles}
  color: ${(props) => props.theme.colors.text};
  font-size: 24px;
  font-weight: ${(props) => props.theme.headingH1.weight};
  ${({ $styledAs, theme }) => handleStyledAs({ $styledAs, theme })}
`

const CustomH2 = styled.h2`
  ${baseStyles}
  font-weight: ${(props) => props.theme.headingH2.weight};
  ${({ theme }) => handleStyledAs({ $styledAs: 'subheading', theme })}
  ${({ $styledAs, theme }) => handleStyledAs({ $styledAs, theme })}
`

const CustomH3 = styled.h3`
  ${baseStyles}
  color: ${(props) => props.theme.colors.text};
  font-size: 14px;
  font-weight: 500;
  ${({ $styledAs, theme }) => handleStyledAs({ $styledAs, theme })}
`

const CustomH4 = styled.h4`
  ${baseStyles}
  color: ${(props) => props.theme.colors.text};
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  ${handleStyledAs}
`

const CustomH5 = styled.h5`
  ${baseStyles}
  color: ${(props) => props.theme.colors.text};
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  ${handleStyledAs}
`

/**
 * BodyHeading is used in app content. Like above tables or or other page/view related content
 *
 * Not to be confused with PageTitles.
 */
const BodyHeading = ({
  level,
  children,
  className,
  styledAs,
  ...restProps
}) => (
  <>
    {level === '1' && (
      <CustomH1 className={className} $styledAs={styledAs} {...restProps}>
        {children}
      </CustomH1>
    )}
    {level === '2' && (
      <CustomH2 className={className} $styledAs={styledAs} {...restProps}>
        {children}
      </CustomH2>
    )}
    {level === '3' && (
      <CustomH3 className={className} $styledAs={styledAs} {...restProps}>
        {children}
      </CustomH3>
    )}
    {level === '4' && (
      <CustomH4 className={className} $styledAs={styledAs} {...restProps}>
        {children}
      </CustomH4>
    )}
    {level === '5' && (
      <CustomH5 className={className} $styledAs={styledAs} {...restProps}>
        {children}
      </CustomH5>
    )}
  </>
)

BodyHeading.propTypes = {
  /**
   * Displays a level1 heading by default.
   */
  level: PropTypes.oneOf(['1', '2', '3', '4', '5']),
  styledAs: PropTypes.oneOf(['subheading', 'mainheading', 'oldheading']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

BodyHeading.defaultProps = {
  level: '1',
  className: null,
  styledAs: null,
}

export default BodyHeading
