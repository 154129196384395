import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useQuery, useMutation } from '@apollo/client'

import {
  CAR_FILE_CHECKLIST_SUMMARY,
  CAR_FILE_CHECKLIST_UPDATE_CHECKED_ITEMS,
} from 'config/graphql/v4'
import { CarFileStatusEnumUpperCase, carFileStatusesOrder } from 'config/enums'

import { withApolloV4Provider } from 'utilities/apollo'
import toast from 'utilities/toast'

import { carFileChecklistProgressNeedsUpdate } from 'redux/actions/ui'

import Text from 'components/atoms/text'
import LoadingIndicator from 'components/atoms/loading-indicator'
import CarFileChecklistStatussesTable from 'components/views/car-file/tabs/checklists/car-file-checklist-statusses-table'
import Typography from 'components/molecules/typography'

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: ${({ theme }) => theme.sizings.lvl3};
`

const StyledCarFileChecklistStatussesTable = styled(
  CarFileChecklistStatussesTable,
)`
  margin-top: ${({ theme }) => theme.sizings.lvl4};
`

const NoChecklistsText = styled(Typography)`
  margin-bottom: 0;
`

const ChecklistsTab = ({ carFileId, status, hasDestination, ...restProps }) => {
  const { t } = useTranslation()

  const { data, loading, error } = useQuery(CAR_FILE_CHECKLIST_SUMMARY, {
    variables: {
      carFileId,
    },
    notifyOnNetworkStatusChange: true,
  })

  // Traverse through the CarFileStatusEnum to ensure the statusses are rendered in proper order
  const checklistsPerStatus =
    data &&
    data.carFileChecklistSummary &&
    data.carFileChecklistSummary.statuses
      ? data.carFileChecklistSummary.statuses
      : []
  const currentStatusIndex = carFileStatusesOrder.indexOf(status)
  const formattedData = checklistsPerStatus
    .slice()
    .sort(
      (a, b) =>
        carFileStatusesOrder.indexOf(a.status) -
        carFileStatusesOrder.indexOf(b.status),
    )
    .map((obj, i) => ({
      ...obj,
      statusOrder: Math.min(Math.max(i - currentStatusIndex, -1), 1),
      checklists: obj.checklists.map((checklist) => ({
        ...checklist,
        checklistItems: checklist.checklistItems.map(
          ({ checkedBy, startOnChecklistItem, ...item }) => ({
            ...item,
            checkedBy: checkedBy
              ? {
                  id: checkedBy.id,
                  firstName: checkedBy.voornaam,
                  lastName: checkedBy.achternaam,
                }
              : undefined,
            startedAt:
              startOnChecklistItem?.checkedAt || obj.startedAt || undefined,
            waitFor:
              startOnChecklistItem && !startOnChecklistItem.checkedAt
                ? startOnChecklistItem.name
                : undefined,
          }),
        ),
      })),
    }))

  /**
   * This piece of code takes the current status from the URL, and opens the corresponding status
   * bar in the CarFileChecklistStatussesTable component. It is commented out because the client
   * requested that the tab automatically changes when the status of the car file changes. Thus,
   * the status of the car should be leading, not the status that's in the URL.
   */
  // const { location: { pathname } } = useHistory()
  // const path = pathname.replace(match.url, '')
  // const statusFromUrl = path.split('/').filter(part => !!part && part !== 'flexflows').shift()
  // const initialStatus = statusFromUrl ? Object.keys(CarFileStatusEnumLowerCase).find(key => CarFileStatusEnumLowerCase[key] === statusFromUrl) : status

  // Submit the checked checklist items mutation. We have to do this here, in this component,
  // because the API requires us to submit ALL checked items for ALL checklists).
  const dispatch = useDispatch()
  const [updateCheckedItems, { loading: checklistIsSubmitting }] = useMutation(
    CAR_FILE_CHECKLIST_UPDATE_CHECKED_ITEMS,
    {
      refetchQueries: [
        {
          query: CAR_FILE_CHECKLIST_SUMMARY,
          variables: {
            carFileId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        // Set a variable in the top level redux store to indicate that the progress in the car file
        // summary should be updated. See 'components/molecules/carfile-status.js' for more info on
        // why this is needed.
        dispatch(carFileChecklistProgressNeedsUpdate(true, carFileId))
      },
    },
  )

  const handleChecklistSubmit = (checklist, checkedItemIds) => {
    const { id } = checklist
    // We need to submit ALL checked items, including the checklists that were not edited...
    const checkedItemIdsFromOtherLists = checklistsPerStatus.flatMap((obj) =>
      obj.checklists
        .filter((checklist) => checklist.id !== id)
        .flatMap((checklist) =>
          checklist.checklistItems
            .filter((item) => item.checked)
            .map((item) => item.id),
        ),
    )

    const update = updateCheckedItems({
      variables: {
        carFileId,
        checklistItemIds: [...checkedItemIdsFromOtherLists, ...checkedItemIds],
      },
    })

    update
      .then(() => {
        toast.success(t('carFileChecklists.tab.checklistSaved'))
      })
      .catch((e) => {
        toast.error(t('carFileChecklists.tab.checklistError'))
      })
  }

  const notFoundMessage =
    formattedData.length <= 0
      ? hasDestination
        ? t('carFileChecklists.tab.noChecklists')
        : t('carFileChecklists.tab.fillOutDestination')
      : undefined

  return (
    <>
      <Text type="h2">{t('carFileChecklists.tab.title')}</Text>
      {loading || error ? (
        <StyledLoadingIndicator error={error ? t('genericError') : undefined} />
      ) : notFoundMessage ? (
        <NoChecklistsText type="BodyParagraph">
          {notFoundMessage}
        </NoChecklistsText>
      ) : (
        <StyledCarFileChecklistStatussesTable
          data-test-e2e="car-file-checklist-statusses-table"
          currentStatus={status}
          checklistStatusses={formattedData}
          initialExpandedStatus={status}
          carFileId={carFileId}
          onChecklistSubmit={handleChecklistSubmit}
          checklistIsSubmitting={checklistIsSubmitting}
          {...restProps}
        />
      )}
    </>
  )
}

ChecklistsTab.propTypes = {
  carFileId: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(CarFileStatusEnumUpperCase)), // The current status of the car file
  hasDestination: PropTypes.bool, // False means
}

export default withApolloV4Provider(ChecklistsTab)
