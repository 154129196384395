// @TODO We're using dangerouslySetInnerHTML in this component
// which should be removed once the backend is able to output
// plain text instead of html
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import ExpandableRow from 'components/molecules/expandable-row'
import Checkbox from 'components/atoms/checkbox'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import { formatDataTestE2eAttr } from 'utilities/format'

const Date = styled(Text)`
  margin-left: 8px;
`

function Portal({ date, log, onChange, selected, status, title }) {
  const statusIcon = {
    inactive: 'publish',
    error: 'publishedError',
    ok: 'published',
    update: 'publishedWait',
  }[status]
  const statusColor = {
    inactive: 'brandAlfa',
    error: 'stateNegative',
    ok: 'statePositive',
  }[status]

  return (
    <ExpandableRow
      main={
        <>
          <Checkbox
            checked={selected}
            onChange={onChange}
            name={title}
            data-test-e2e={formatDataTestE2eAttr('portal', title)}
          />
          <Text type="tabSmall" text={title} />
        </>
      }
      details={
        status && [
          <React.Fragment key="icon-with-date">
            <Icon color="brandCharlie" size="md" type="create" />
            <Date color="brandCharlie" text={date} />
          </React.Fragment>,
          <React.Fragment key="icon-without-date">
            <Icon color={statusColor} type={statusIcon} />
          </React.Fragment>,
        ]
      }
    >
      <Text
        color={statusColor}
        type="body"
        dangerouslySetInnerHTML={{ __html: log }}
      />
    </ExpandableRow>
  )
}

Portal.propTypes = {
  date: PropTypes.string.isRequired,
  log: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
}

Portal.defaultProps = {
  log: null,
  status: null,
  selected: false,
}

export default Portal
