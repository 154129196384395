import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getMenus,
  getActionBarItems,
  getActionMenuItems,
  getTodos,
} from 'redux/actions/data'
import { logout } from 'redux/actions/auth'
import { closeImageOverlay, openOverlay } from 'redux/actions/ui'
import { getForms } from 'redux/actions/forms'
import AppLayout from '../layouts/app-layout'

const mapStateToProps = ({ auth, data }) => ({
  actionsMenu: data.menus && data.menus.data && data.menus.data.actions,
  auth,
  carAssets: data.carAssets,
  mainMenu: data.menus && data.menus.data && data.menus.data.main,
  settingsMenu: data.menus && data.menus.data && data.menus.data.settings,
})

const mapDispatchToProps = {
  closeImageOverlay,
  getForms,
  getMenus,
  getActionBarItems,
  getActionMenuItems,
  getTodos,
  logout,
  openOverlay,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AppLayout))
