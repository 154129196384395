import AuthLayout from 'components/layouts/auth-layout'
import ForgotPasswordForm from 'components/organisms/forgot-password-form'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

function ForgotPassword({ auth, forgotPassword }) {
  const { t } = useTranslation()
  const [feedback, setFeedback] = useState('')
  const renderedOnce = useRef()

  const { error, forgotPasswordStatus } = auth

  const errorTexts = {
    unknown_error: t('wrongLoginCredentials'),
  }

  useEffect(() => {
    if (!renderedOnce.current) {
      renderedOnce.current = true
    } else if (forgotPasswordStatus === 'SUCCESS') {
      setFeedback(t('forgotPasswordSuccess'))
    }
  }, [forgotPasswordStatus, t])

  const errorText = errorTexts[error] || error

  return (
    <AuthLayout pageTitle={t('forgotPassword')}>
      <ForgotPasswordForm
        error={errorText}
        feedback={feedback}
        onSubmit={forgotPassword}
      />
    </AuthLayout>
  )
}

ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  forgotPassword: PropTypes.func.isRequired,
}

export default ForgotPassword
