import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components/macro'
import { Pie } from 'react-chartjs-2'

// NOTE ABOUT THIS COMPONENT:
// This chart is based on ChartJS and is drawn in a <canvas> element.
// ChartJS tries to fit the graph and it's labels into this <canvas> element.
// The labels might be cut-off, because of the async loading of the custom 'work-sans' font.
// The font is loaded after the first render of this component,
// and it doesn't trigger a redraw in ChartJS.
// To fix this, 'Arial' is used as the fallback font,
// since the width of this font is about equal to the custom 'work-sans' font.

const Container = styled.div`
  position: relative;
`

const getData = (data, theme) => ({
  labels: data.map((dataPoint) => dataPoint.label),
  datasets: [
    {
      data: data.map((dataPoint) => dataPoint.value),
      backgroundColor: data.map(
        (dataPoint) => theme.colors[dataPoint.color] || dataPoint.color,
      ),
      borderWidth: 0,
    },
  ],
})

const getOptions = (theme) => ({
  responsive: true,
  layout: {
    padding: 0,
  },
  plugins: {
    datalabels: {
      display: false, // By default hide the data labels
    },
  },
  legend: {
    position: 'right',
    labels: {
      fontFamily: `${theme.font}, Arial`, // Adding Arial to prevent label cut-off
      fontColor: theme.colors.text,
      fontSize: parseInt(theme.baseFontSize, 10),
    },
  },
})

const PieChart = ({ data, className }) => {
  const theme = useContext(ThemeContext)
  const formattedData = getData(data, theme)
  const options = getOptions(theme)

  return (
    <Container className={className}>
      <Pie data={formattedData} options={options} />
    </Container>
  )
}

PieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The values of all data objects will be added up and the
       * component will calculate the relative proportion of each
       */
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      /**
       * The name of a theme color, or any CSS color string
       */
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
}

PieChart.defaultProps = {
  className: null,
}

export default PieChart
