import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { media } from 'utilities/styled'

import LabeledText from 'components/atoms/labeled-text'
import Typography from 'components/molecules/typography'

const Container = styled.div`
  ${media.desktop`
    align-items: start;
    display: grid;
    grid-template-columns: 1fr auto;
  `};
`

const SellingPriceContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;

  & > *:last-child {
    margin-bottom: ${({ theme }) => theme.sizeByFactor(0.25)};
  }
`

const StyledSellingPrice = styled(LabeledText)`
  padding-right: ${({ theme }) => theme.sizeByFactor(1)};
`

const CarShareBuyPrice = ({ carFileData }) => {
  const { t } = useTranslation()

  const carHasPrice = carFileData?.inkoopprijs

  const taxLabelText =
    carFileData?.taxMargin === 'marge' ? t('inclBtw') : t('exclBtw')
  const showTaxLabelText = carFileData?.taxMargin !== 'onbekend'

  return (
    <Container>
      <SellingPriceContainer>
        <StyledSellingPrice
          title={t('purchasePrice')}
          value={carHasPrice || t('unknown')}
          price={!!carHasPrice}
          highlight
        />
        {!!carHasPrice && showTaxLabelText && (
          <Typography type="InlineBodyTextBold">{taxLabelText}</Typography>
        )}
      </SellingPriceContainer>
    </Container>
  )
}

CarShareBuyPrice.propTypes = {
  carFileData: PropTypes.object,
}

CarShareBuyPrice.defaultProps = {
  carFileData: {},
}

export default CarShareBuyPrice
