import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Text from 'components/atoms/text'
import NoImagePlaceholder from 'components/atoms/no-image-placeholder'
import AspectRatioImage from 'components/atoms/aspect-ratio-image'
import { useTranslation } from 'react-i18next'
import { formatDataTestE2eAttr } from 'utilities/format'

const Container = styled.div`
  height: inherit;
  position: relative;
`

const Label = styled(Text)`
  background-color: ${({ theme }) => theme.colors.brandGolf}};
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 16px;
  text-transform: capitalize;
`

const StyledNoImagePlaceholder = styled(NoImagePlaceholder)`
  max-height: 100%;
`

function GalleryImage({
  alt,
  blurredBackground,
  src,
  label,
  customErrorMessage,
  ratio,
  ...restProps
}) {
  // When no image was provided the image goes directly inError:
  const [inError, setInError] = useState(!src)
  const { t } = useTranslation()

  useEffect(() => {
    setInError(false)
  }, [src])

  return (
    <Container {...restProps}>
      {inError || !src ? (
        <StyledNoImagePlaceholder
          message={
            typeof customErrorMessage === 'string'
              ? customErrorMessage
              : t('noCarPhotosAvailable')
          }
          ratio={ratio}
        />
      ) : (
        <AspectRatioImage
          alt={alt}
          blurredBackground={blurredBackground}
          src={src}
          onError={() => setInError(true)}
          ratio={ratio}
        />
      )}
      {label && (
        <Label
          type="input"
          text={label}
          data-test-e2e={formatDataTestE2eAttr('gallery-label', label)}
        />
      )}
    </Container>
  )
}

GalleryImage.propTypes = {
  alt: PropTypes.string.isRequired,
  blurredBackground: PropTypes.bool,
  src: PropTypes.string,
  label: PropTypes.string,
  customErrorMessage: PropTypes.string,
  ratio: PropTypes.number,
}

GalleryImage.defaultProps = {
  blurredBackground: false,
  label: null,
  src: null,
  customErrorMessage: null,
  ratio: undefined,
}

export default GalleryImage
