import { connect } from 'react-redux'

import { postDynamicEndpoint } from 'redux/actions/data'
import {
  getB2bPurchasingCompanies,
  deleteB2bPurchasingCompany,
} from 'redux/actions/settings'
import { openOverlay } from 'redux/actions/ui'

import B2bPurchasingCompaniesSettings from 'components/views/settings/b2b-purchasing-settings'

const mapStateToProps = ({ settings }) => ({
  purchasingCompanies: settings.b2bPurchasingCompanies,
})

const mapDispatchToProps = {
  getB2bPurchasingCompanies,
  deleteB2bPurchasingCompany,
  openOverlay,
  postDynamicEndpoint,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(B2bPurchasingCompaniesSettings)
