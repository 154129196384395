import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Comment from 'components/atoms/comment'

const Container = styled.div`
  overflow: auto;
`

const StyledComment = styled(Comment)`
  margin-bottom: 16px;
`

function CarComments({ carId, comments, getComments }) {
  useEffect(() => {
    getComments({
      auto_id: carId,
    })
  }, [carId, getComments])

  const sortedComments =
    comments &&
    comments.data &&
    comments.data.sort((firstComment, secondComment) => {
      const firstDate = new Date(firstComment.tijd).getTime()
      const secondDate = new Date(secondComment.tijd).getTime()

      return secondDate - firstDate
    })

  return (
    <Container>
      {!comments && <LoadingIndicator />}
      {sortedComments &&
        sortedComments.map((comment, index) => (
          <StyledComment
            key={index.toString()}
            commenter={comment.gebruiker}
            comment={comment.tekst}
            date={comment.tijd}
          />
        ))}
    </Container>
  )
}

CarComments.propTypes = {
  carId: PropTypes.string.isRequired,
  comments: PropTypes.object,
  getComments: PropTypes.func.isRequired,
}

CarComments.defaultProps = {
  comments: null,
}

export default CarComments
