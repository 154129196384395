import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import Link from 'components/atoms/link'
import AuthForm from 'components/molecules/auth-form'

const Container = styled.div`
  width: 100%;
`

const StyledAuthForm = styled(AuthForm)`
  box-shadow: ${({ theme }) => theme.shadows.bottom};
  background-color: ${({ theme }) => theme.colors.brandGolf};
  padding: 32px;
`

const StyledLink = styled(Link)`
  display: block;
  margin: 0 auto;
  margin-top: 32px;
  text-align: center;
`

function LoginForm({ auth, error, onSubmit }) {
  const { t } = useTranslation()

  const fields = [
    {
      name: 'customer',
      title: t('customerNumber'),
      validator: (values, index) =>
        values[index].length < 1 && t('customerNumberRequired'),
      value: auth.customerId,
    },
    {
      name: 'user',
      title: t('username'),
      validator: (values, index) =>
        values[index].length < 1 && t('usernameRequired'),
      value: auth.username,
    },
    {
      name: 'password',
      title: t('password'),
      type: 'password',
      validator: (values, index) =>
        values[index].length < 1 && t('passwordRequired'),
      value: auth.password,
    },
    {
      name: 'rememberCredentials',
      title: t('rememberCredentials'),
      type: 'checkbox',
      validator: () => false,
      value: auth.rememberCredentials,
    },
    {
      name: 'stayLoggedIn',
      title: t('stayLoggedIn'),
      type: 'checkbox',
      validator: () => false,
    },
  ]

  return (
    <Container>
      <StyledAuthForm
        error={error}
        fields={fields}
        level="cta"
        onSubmit={onSubmit}
        submitText={t('login')}
        title={t('login')}
      />
      <StyledLink to="/forgot-password">{t('forgotPassword')}</StyledLink>
    </Container>
  )
}

LoginForm.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

LoginForm.defaultProps = {
  error: null,
}

export default LoginForm
