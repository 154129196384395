import { connect } from 'react-redux'
import {
  clearData,
  getB2bPurchaseInterests,
  deleteB2bPurchaseInterests,
  postDynamicEndpoint,
  getLocationsDetails,
} from 'redux/actions/data'
import { openOverlay } from 'redux/actions/ui'
import B2bPurchasingProfileSettings from '../views/settings/b2b-purchasing-profile-settings'

const mapStateToProps = ({ data }) => ({
  locationsDetails: data.locationsDetails,
  b2bPurchaseInterests: data.b2bPurchaseInterests,
})

const mapDispatchToProps = {
  clearData,
  getLocationsDetails,
  getB2bPurchaseInterests,
  deleteB2bPurchaseInterests,
  openOverlay,
  postDynamicEndpoint,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(B2bPurchasingProfileSettings)
