import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Text from 'components/atoms/text'
import Progress from 'components/atoms/progress'

const StyledHeader = styled.div`
  display: flex;
  margin-top: 5px;
`

const StyledText = styled(Text)`
  margin-left: auto;
  padding-left: 20px;
`

function StandDuration({ days, title, ...restProps }) {
  let progressValue
  if (days < 21) {
    progressValue = 33.33
  } else if (days < 41) {
    progressValue = 66.66
  } else {
    progressValue = 100
  }

  const { t } = useTranslation()

  return (
    <div {...restProps}>
      <Progress value={progressValue} color="gradients.positiveToNegative" />
      <StyledHeader>
        <Text text={title} />
        <StyledText type="h5" text={t('day', { count: days })} />
      </StyledHeader>
    </div>
  )
}

StandDuration.propTypes = {
  days: PropTypes.number,
  title: PropTypes.string.isRequired,
}

StandDuration.defaultProps = {
  days: 0,
}

export default StandDuration
