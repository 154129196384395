import React from 'react'
import PropTypes from 'prop-types'
import LegendBox from 'components/molecules/legend-box'
import LegendInputBox from 'components/molecules/legend-input-box'
import styled from 'styled-components/macro'

const Container = styled.ul`
  gap: ${({ theme }) => theme.sizings.lvl2};
  display: grid;
  ${(props) =>
    props.arrangeAs === 'column' &&
    `
    grid-column: 1fr;
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
  `}
  ${(props) =>
    props.arrangeAs === 'row' &&
    `
    // grid-row: 1fr;
    // grid-auto-column: 1fr;
    // grid-auto-flow: column;
    display:flex;
    flex-direction: row;
    > * {
      flex: 1 1;
    }
  `}
`

/**
 * This component generates a set of LegendBoxes based on a set of items.
 * It can be best combined with some type of data visualisation.
 */
const LegendBoxes = ({
  className,
  items,
  unit,
  arrangeAs,
  parentIsDarker,
  children,
}) => (
  <Container className={className} arrangeAs={arrangeAs}>
    {items.map((item) =>
      item.fieldAction && typeof item.fieldAction === 'function' ? (
        <LegendInputBox
          key={item.label}
          legendColor={item.color}
          legendBackgroundColor={item.backgroundColor}
          parentIsDarker={parentIsDarker}
          value={item.value}
          label={item.extendedLabel ? item.extendedLabel : item.label}
          unit={item.unit}
          onChange={item.fieldAction}
        />
      ) : (
        <LegendBox
          key={item.label}
          legendColor={item.color}
          legendBackgroundColor={item.backgroundColor}
          parentIsDarker={parentIsDarker}
          value={item.value}
          subLabel={item.subLabel && item.subLabel}
          label={item.extendedLabel ? item.extendedLabel : item.label}
          unit={item.unit || unit}
          optionalValue={item.additionalValue}
        />
      ),
    )}
    {children}
  </Container>
)
LegendBoxes.propTypes = {
  /**
   * An array of items you want to display in the chart
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      parentIsDarker: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
    }),
  ),
  /**
   * A collection of children (like [LegendBox](/#/Molecules/LegendBox)) to display.
   */
  children: PropTypes.node,
  unit: PropTypes.string,
  className: PropTypes.string,
  arrangeAs: PropTypes.oneOf(['row', 'column']),
  parentIsDarker: PropTypes.bool,
}

LegendBoxes.defaultProps = {
  unit: '',
  parentIsDarker: false,
  className: null,
  arrangeAs: 'column',
  items: [],
  children: null,
}

export default LegendBoxes
