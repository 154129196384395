import React, { useContext } from 'react'
import { Doughnut } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components/macro'
import Text from 'components/atoms/text'

const StyledDonut = styled(Doughnut)`
  position: relative;
  z-index: 0;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TitleContainer = styled.div`
  display: flex;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ semi }) => (semi === true ? 'flex-end' : 'center')};
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const ResultContainer = styled.div`
  display: flex;
  font-size: 32px;
  font-weight: 500;
  color: ${({ theme, color }) => theme.colors[color]};
  align-items: baseline;
  text-align: bottom;
  position: absolute;
  z-index: 1;
`

const UnitContainer = styled.span`
  font-size: 50%;
`

const getOptions = (title, semi) => {
  const options = {
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false, // By default hide the data labels
      },
    },
    tooltips: {
      enabled: true,
    },
    title: {
      display: false,
      text: title,
      position: 'top',
      fontSize: 26,
      fontFamily: 'work-sans',
    },
    cutoutPercentage: 60,
    rotation: semi ? 1 * Math.PI : -0.5 * Math.PI,
    circumference: semi ? 1 * Math.PI : 2 * Math.PI,
  }
  return { ...options }
}

function getChartData(
  title,
  filledTitle,
  unfilledTitle,
  value,
  unit,
  color,
  backgroundColor,
  borderColor,
) {
  const data = {
    labels: [filledTitle, unfilledTitle],
    weight: 1,
    datasets: [
      {
        label: title,
        data: [value, 100 - value],
        backgroundColor: [color, backgroundColor],
        hoverBackgroundColor: [`${color}aa`, `${backgroundColor}aa`],
        borderColor: [borderColor, borderColor],
        hoverBorderColor: [borderColor, borderColor],
      },
    ],
  }
  return data
}

/**
 * Wrapper component around
 * [react-chartjs-2](http://jerairrest.github.io/react-chartjs-2/)
 * Doughnut chart
 *
 */
function DonutChart({
  className,
  title,
  filledTitle,
  unfilledTitle,
  value,
  unit,
  color,
  backgroundColor,
  semi,
}) {
  const { colors } = useContext(ThemeContext)
  const chartData = getChartData(
    title,
    filledTitle,
    unfilledTitle,
    value,
    unit,
    colors[color],
    colors[backgroundColor],
    colors.brandGolf,
  )

  return (
    <Container className={className}>
      <TitleContainer>
        <Text type="h2" text={title} />
      </TitleContainer>
      <ChartContainer semi={semi}>
        <ResultContainer semi={semi} color={color}>
          {value}
          <UnitContainer>{unit}</UnitContainer>
        </ResultContainer>
        <StyledDonut
          data={chartData}
          options={getOptions(title, semi)}
          width={200}
          height={semi ? 100 : 200}
        />
      </ChartContainer>
    </Container>
  )
}

DonutChart.propTypes = {
  className: PropTypes.string,
  /** Title for this chart */
  title: PropTypes.string.isRequired,
  /** Title for the tooltip of the filled part this chart */
  filledTitle: PropTypes.string.isRequired,
  /** Title for the tootltip of the unfilled part this chart */
  unfilledTitle: PropTypes.string.isRequired,
  /** Value */
  value: PropTypes.number,
  /** Base unit */
  unit: PropTypes.string,
  /** One of the theme color names */
  color: PropTypes.string,
  /** One of the theme color names */
  backgroundColor: PropTypes.string,
  /** Semi if true for rendering as semi donut chart */
  semi: PropTypes.bool,
}

DonutChart.defaultProps = {
  className: undefined,
  unit: '%',
  color: 'actionsStandard',
  backgroundColor: 'isolatedBackground',
  semi: false,
  value: undefined,
}

export default DonutChart
