import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import LabeledCheckbox from 'components/atoms/labeled-checkbox'
import { FormLabel, FormGroup } from '@material-ui/core'
import Typography from 'components/molecules/typography'

const StyledFormLabel = styled(FormLabel)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
  &.MuiFormLabel-root {
    color: ${({ theme }) => theme.colors.inputPlaceholder};
  }
`

const Error = styled(Typography)`
  display: block;
  margin-top: ${({ theme }) => theme.sizings.lvl0};
`

const LabeledCheckboxGroup = ({
  label,
  error,
  required,
  items,
  value,
  disabled,
  onChange,
  className,
  wrapLabelOnLineBreak,
}) => {
  const handleChange = (id, checked) => {
    if (typeof onChange === 'function') {
      onChange(id, checked)
    }
  }

  return (
    <FormGroup className={className}>
      {label && (
        <StyledFormLabel
          component="legend"
          error={!!error}
          required={!!required}
        >
          {label}
        </StyledFormLabel>
      )}
      {items.length > 0 && (
        <div className="checkboxes">
          {items.map((item) => (
            <LabeledCheckbox
              key={item.value}
              id={item.value}
              label={item.label || item.value}
              price={item.price}
              disabled={
                typeof item.disabled === 'boolean' ? item.disabled : disabled
              }
              wrapLabelOnLineBreak={
                typeof item.wrapLabelOnLineBreak === 'boolean'
                  ? item.wrapLabelOnLineBreak
                  : wrapLabelOnLineBreak
              }
              onChange={(checked) => {
                handleChange(item.value, checked)
              }}
              checked={value?.includes(item.value) || false}
            />
          ))}
        </div>
      )}
      {typeof error === 'string' && (
        <Error type="ErrorText" className="error">
          {error}
        </Error>
      )}
    </FormGroup>
  )
}

LabeledCheckboxGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired, // This is the value
      label: PropTypes.node,
      price: PropTypes.number,
      disabled: PropTypes.bool,
      wrapLabelOnLineBreak: PropTypes.bool,
    }),
  ),
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  wrapLabelOnLineBreak: PropTypes.bool,
  onChange: PropTypes.func, // Gets the id of the item and the
  required: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

export default LabeledCheckboxGroup
