import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import RadioFilter from 'components/molecules/radio-filter'
import CheckboxFilter from 'components/molecules/checkbox-filter'
import PriceFilter from 'components/molecules/price-filter'
import SearchFilter from 'components/molecules/search-filter'
import TextFilter from 'components/molecules/text-filter'
import DateFilter from 'components/molecules/date-filter'
import DateRangeFilter from 'components/molecules/date-range-filter'
import TextLink from 'components/atoms/text-link'
import DropdownFilter from 'components/molecules/dropdown-filter'
import FromToFilter from 'components/molecules/from-to-filter'
import { toKebabCase } from 'utilities/format'

const InnerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.brandGolf};
  display: inline-table;
  width: 100%;
`

const FilterContainer = styled.div`
  padding: ${({ theme }) => theme.sizings.lvl2};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.brandDelta};
  }
`

const ResetLink = styled(TextLink)`
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`
/**

 */
function Filters({
  className,
  filters,
  onUpdate,
  onResetFilters,
  selectedFilters,
}) {
  const { t } = useTranslation()
  return (
    <div className={className}>
      <InnerContainer>
        {filters.map((filter, index) => {
          const filterHasBeenUsed = filter.id in selectedFilters
          const selectedFilter = selectedFilters[filter.id]
          const initiallyOpen = index < 3 || selectedFilter || filterHasBeenUsed

          return (
            <FilterContainer
              key={index.toString()}
              data-test-e2e={`filter-${toKebabCase(filter.label)}`}
            >
              {filter.type === 'radio' &&
                Array.isArray(filter.items) &&
                filter.items[0] && (
                  <RadioFilter
                    initallyOpen={!!initiallyOpen}
                    name={filter.id}
                    title={filter.label}
                    filterOptions={filter.items}
                    onFilterUpdate={onUpdate}
                    selectedFilter={
                      selectedFilters[filter.id] ||
                      String(filter.items[0].value)
                    }
                  />
                )}
              {filter.type === 'checkbox' && (
                <CheckboxFilter
                  initallyOpen={!!initiallyOpen}
                  name={filter.id}
                  title={filter.label}
                  filterOptions={filter.items}
                  onFilterUpdate={onUpdate}
                  selectedFilters={
                    Array.isArray(selectedFilter)
                      ? selectedFilter
                      : [selectedFilter].filter(Boolean)
                  }
                />
              )}
              {filter.type === 'range' && (
                <PriceFilter
                  initiallyOpen={
                    !!initiallyOpen ||
                    !!selectedFilters[filter.id[0]] ||
                    !!selectedFilters[filter.id[1]]
                  }
                  minMaxIds={filter.id}
                  selectedFilters={selectedFilters}
                  title={filter.label}
                  onFilterUpdate={onUpdate}
                />
              )}
              {filter.type === 'search' && (
                <SearchFilter
                  initallyOpen={!!initiallyOpen}
                  name={filter.id}
                  value={selectedFilter}
                  title={filter.label}
                  onFilterUpdate={onUpdate}
                />
              )}
              {filter.type === 'text' && (
                <TextFilter
                  initiallyOpen={!!initiallyOpen}
                  type={filter.inputType}
                  name={filter.id}
                  value={selectedFilter}
                  title={filter.label}
                  onChange={onUpdate}
                  placeholder={filter.placeholder}
                  unit={filter.unit}
                />
              )}
              {filter.type === 'date' && (
                <DateFilter
                  initallyOpen={!!initiallyOpen}
                  name={filter.id}
                  value={selectedFilter}
                  title={filter.label}
                  onFilterUpdate={onUpdate}
                />
              )}
              {filter.type === 'dateRange' && (
                <DateRangeFilter
                  initallyOpen={!!initiallyOpen}
                  id={filter.id}
                  fromId={filter.fromId}
                  toId={filter.toId}
                  startDate={selectedFilters[filter.fromId]}
                  endDate={selectedFilters[filter.toId]}
                  title={filter.label}
                  onFilterUpdate={onUpdate}
                />
              )}
              {filter.type === 'select' && (
                <DropdownFilter
                  initallyOpen={!!initiallyOpen}
                  name={filter.id}
                  value={selectedFilter}
                  title={filter.label}
                  onFilterUpdate={onUpdate}
                  items={filter.items}
                />
              )}
              {filter.type === 'fromTo' && (
                <FromToFilter
                  initallyOpen={!!initiallyOpen}
                  fromId={filter.fromId}
                  fromLabel={filter.fromLabel}
                  fromOptions={filter.fromOptions}
                  fromTitle={filter.fromLabel}
                  fromValue={selectedFilters[filter.fromId]}
                  title={filter.label}
                  toId={filter.toId}
                  toLabel={filter.toLabel}
                  toOptions={filter.toOptions}
                  toTitle={filter.toLabel}
                  toValue={selectedFilters[filter.toId]}
                  onChange={onUpdate}
                  value={onUpdate}
                />
              )}
            </FilterContainer>
          )
        })}
      </InnerContainer>
      <ResetLink text={t('resetFilters')} onClick={onResetFilters} />
    </div>
  )
}

Filters.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
}

Filters.defaultProps = {
  className: null,
}

export default Filters
