import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Formik, Form, Field } from 'formik'
import moment from 'moment'
import toast from 'utilities/toast'
import Button from '../../../atoms/button'
import LoadingIndicator from '../../../atoms/loading-indicator'
import Dialog from '../../../molecules/flexible-dialog'
import {
  FormikDatePicker,
  FormikSelectInput,
} from '../../../molecules/formik-field'
import { getMileagePerYearOptions } from 'config/data'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  ACCEPT_MILEAGE_CHANGE_FOR_CONTRACT,
  RECALCULATE_MILEAGE,
} from 'config/graphql/csp'
import PlanChoice from 'components/organisms/plan-choice'
import StatusMessage from 'components/molecules/status-message'

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.sizings.lvl3};
  > * {
    flex: 1 0;
    width: ${({ theme }) => theme.sizeByFactor(40)};
  }
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`

function ChangeContractDialog({ onClose, contract }) {
  const { t } = useTranslation()
  const [recalculateMileage, recalculatedPlan] =
    useLazyQuery(RECALCULATE_MILEAGE)
  const handleFormSubmit = async (values) => {
    if (!recalculatedPlan?.data) {
      try {
        await recalculateMileage({
          variables: {
            mileagePerYear: values.mileagePerYear,
            contractId: parseInt(contract.id, 10),
          },
        })
      } catch (e) {
        toast.error(e.toString())
      }
    } else {
      try {
        await acceptMileage({
          variables: {
            contractId: parseInt(contract.id, 10),
            startDate: values.startDate.format('YYYY-MM-DD'),
            packageCalculationResult: parseInt(
              recalculatedPlan.data.recalculateMileage.results[0].id,
              10,
            ),
          },
        })
        toast.success(
          t(
            'carServicePlanContractDetails.changeContract.dialog.successMessage',
          ),
        )
      } catch (e) {
        toast.error(e.toString())
      }
      onClose()
    }
  }

  const [acceptMileage] = useMutation(ACCEPT_MILEAGE_CHANGE_FOR_CONTRACT)

  return (
    <Dialog
      closeHandler={onClose}
      open
      overflowVisible
      title={t('carServicePlanContractDetails.changeContract.dialog.heading')}
      data-test-e2e="change-contract-dialog"
      content={
        <>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              mileagePerYear: null,
              startDate: moment(),
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)
              try {
                await handleFormSubmit(values)
              } catch (e) {
                toast.error(e.toString())
              }
              setSubmitting(false)
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                {!recalculatedPlan?.data ? (
                  <FormRow>
                    <Field
                      name="mileagePerYear"
                      label={t(
                        'carServicePlanContractDetails.changeContract.dialog.mileagePerYear.label',
                      )}
                      items={getMileagePerYearOptions(t)}
                      filled
                      component={FormikSelectInput}
                    />
                    <Field
                      name="startDate"
                      label={t(
                        'carServicePlanContractDetails.changeContract.dialog.startDate.label',
                      )}
                      filled
                      isOutsideRange={(day) =>
                        day.isBefore(moment().subtract(1, 'days'))
                      }
                      component={FormikDatePicker}
                    />
                  </FormRow>
                ) : (
                  <>
                    {Array.isArray(
                      recalculatedPlan.data.recalculateMileage.results,
                    ) &&
                    recalculatedPlan.data.recalculateMileage.results[0]
                      ?.package ? (
                      <PlanChoice
                        highlighted
                        icon="oil"
                        highlightedText="Uw nieuwe plan"
                        heading={
                          recalculatedPlan.data.recalculateMileage.results[0]
                            .package.description
                        }
                        price={contract.packageCalculationResult.resultNet}
                        priceDiscounted={
                          recalculatedPlan.data.recalculateMileage.results[0]
                            .resultNet
                        }
                        submitText={t(
                          'carServicePlanContractDetails.changeContract.dialog.confirmButton',
                        )}
                        onSubmit={() => handleFormSubmit(values)}
                      />
                    ) : (
                      <StatusMessage
                        type="error"
                        heading={t(
                          'carServicePlanContractDetails.changeContract.dialog.noRecalculationResultsHeading',
                        )}
                      >
                        {t(
                          'carServicePlanContractDetails.changeContract.dialog.noRecalculationResults',
                        )}
                      </StatusMessage>
                    )}
                  </>
                )}
                <Controls>
                  <Button
                    level="option"
                    onClick={onClose}
                    text={t('cancel')}
                    noPadding
                    data-test-e2e="button-cancel"
                  />
                  {isSubmitting && <LoadingIndicator size="small" />}
                  {!recalculatedPlan?.data && (
                    <Button
                      level="cta"
                      disabled={isSubmitting}
                      type="submit"
                      data-test-e2e="button-recalculate"
                    >
                      {t(
                        'carServicePlanContractDetails.changeContract.dialog.calculateButton',
                      )}
                    </Button>
                  )}
                </Controls>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  )
}

ChangeContractDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  contract: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    packageCalculationResult: PropTypes.shape({
      resultNet: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
}

export default ChangeContractDialog
