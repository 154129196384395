import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import Pill from 'components/atoms/pill'
import StaticValuesCollection from 'components/molecules/static-values-collection'
import FieldNotMappedTooltip from 'components/molecules/field-not-mapped-tooltip'

import {
  getNapPillLevel,
  translateListItems,
  formatMileage,
} from 'utilities/utils'
import { media } from 'utilities/styled'

import useDealerLocationCountryCode from 'hooks/use-location-country-code'

const StyledCarfileSpecifications = styled.div`
  // Show ellipsis for the items that overflow the container (e.g. carrosserie)
  section div {
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }

  ${media.desktop`
    display: flex;
    flex-wrap: wrap;
  `};
`

const Nap = styled(Pill)`
  margin-left: ${({ theme }) => theme.sizings.lvl1};
`

const StyledFieldNotMappedTooltip = styled(FieldNotMappedTooltip)`
  margin-left: ${({ theme }) => theme.sizings.lvl1};
  position: relative;
  top: 2px;
`

function CarfileSpecifications({
  carId,
  body,
  bodyIsNotMapped,
  buildyear,
  color,
  fuel,
  fuelIsNotMapped,
  metallic,
  mileage,
  nap,
  noOfDoors,
  powerHP,
  powerKW,
  taxMargin,
  transmission,
  bmsData,
  pickUpLocationCity,
  pickUpLocationCountry,
  vin,
  ...restProps
}) {
  const { t } = useTranslation()
  const { locationCountryCodeIsNl } = useDealerLocationCountryCode()

  const hasBmsData =
    !!bmsData.length &&
    bmsData.some((bmsDataPoints) => bmsDataPoints.auto_id === carId)

  let definitions = [
    {
      label: 'mileage',
      titleComponent: locationCountryCodeIsNl ? (
        <Nap level={getNapPillLevel(nap)} text="NAP" />
      ) : undefined,
      value: formatMileage(mileage),
    },
    {
      label: 'yearOfBuild',
      value: buildyear,
    },
    {
      label: 'vin',
      value: vin || '-',
    },
    {
      label: 'fuel',
      titleComponent: fuelIsNotMapped ? (
        <StyledFieldNotMappedTooltip
          placement="top-end"
          arrow
          fieldName={t('fuel').toLowerCase()}
        />
      ) : undefined,
      value: fuel,
    },
    {
      label: 'taxMargin',
      value: taxMargin,
    },
    {
      label: 'body',
      titleComponent: bodyIsNotMapped ? (
        <StyledFieldNotMappedTooltip fieldName={t('body').toLowerCase()} />
      ) : undefined,
      value: `${t(body)} - ${noOfDoors} ${t('doors')}`,
    },
    {
      label: 'color',
      // When the value of metallic is "0" (string), just show the color value.
      // When the value of metallic is "1" (string), append the word " - metallic" to the color.
      // Confirmed with the BE that this value comes back as a string (19 jan '24)
      value: metallic === '1' ? `${color} - ${t('metallic')}` : color,
    },
    {
      label: 'transmission',
      value: transmission,
    },
    {
      label: 'power',
      value: `${powerHP} PK / ${powerKW} KW`,
    },
  ]

  if (pickUpLocationCity) {
    definitions = [
      ...definitions,
      { label: t('pickUpLocationCity'), value: pickUpLocationCity },
    ]
  }

  if (pickUpLocationCountry) {
    definitions = [
      ...definitions,
      { label: t('pickUpLocationCountry'), value: pickUpLocationCountry },
    ]
  }

  // When the user has requested BMS data, these definitions should also be shown
  if (hasBmsData) {
    const carFileBmsData = bmsData.find(
      (bmsDataPoints) => bmsDataPoints.auto_id === carId,
    )

    definitions = [
      ...definitions,
      ...[
        {
          label: t('carFileSummary.bmsData.stateOfHealth'),
          value: `${carFileBmsData?.soh}%`,
        },
        {
          label: t('carFileSummary.bmsData.stateOfCharge'),
          value: `${carFileBmsData?.soc}%`,
        },
      ],
    ]
  }

  const translatedDefinitions = translateListItems(definitions, t).map(
    (definition) => ({ title: definition.label, ...definition }),
  )

  return (
    <StyledCarfileSpecifications {...restProps}>
      <StaticValuesCollection collection={translatedDefinitions} />
    </StyledCarfileSpecifications>
  )
}

CarfileSpecifications.propTypes = {
  carId: PropTypes.string.isRequired,
  buildyear: PropTypes.string,
  body: PropTypes.string,
  bodyIsNotMapped: PropTypes.bool,
  color: PropTypes.string,
  fuel: PropTypes.string,
  fuelIsNotMapped: PropTypes.bool,
  manufactured: PropTypes.string,
  metallic: PropTypes.string,
  mileage: PropTypes.number,
  nap: PropTypes.bool,
  noOfDoors: PropTypes.string,
  powerHP: PropTypes.string,
  powerKW: PropTypes.string,
  taxMargin: PropTypes.string,
  transmission: PropTypes.string,
  bmsData: PropTypes.arrayOf(
    PropTypes.shape({
      auto_id: PropTypes.string.isRequired,
      soc: PropTypes.string.isRequired,
      soh: PropTypes.string.isRequired,
    }),
  ).isRequired,
  pickUpLocationCity: PropTypes.string,
  pickUpLocationCountry: PropTypes.string,
  vin: PropTypes.string,
}

CarfileSpecifications.defaultProps = {
  body: undefined,
  buildyear: undefined,
  color: undefined,
  fuel: undefined,
  manufactured: undefined,
  metallic: '0', // Default value from the backend is a string "0" for when it's not metallic, when a car is metallic, the value is "1" (string)
  mileage: null,
  nap: undefined,
  noOfDoors: null,
  powerHP: '-',
  powerKW: '-',
  taxMargin: undefined,
  transmission: undefined,
  bmsData: [],
  pickUpLocationCity: undefined,
  pickUpLocationCountry: undefined,
  vin: undefined,
}

export default CarfileSpecifications
