import { connect } from 'react-redux'
import { postDynamicEndpoint } from 'redux/actions/data'
import {
  getCleaningCompanies,
  deleteCleaningCompany,
} from 'redux/actions/settings'

import { openOverlay } from 'redux/actions/ui'
import CleaningCompaniesSettings from '../views/settings/cleaning-companies-settings'

const mapStateToProps = ({ settings }) => ({
  cleaningCompanies: settings.cleaningCompanies,
})

const mapDispatchToProps = {
  getCleaningCompanies,
  deleteCleaningCompany,
  openOverlay,
  postDynamicEndpoint,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CleaningCompaniesSettings)
