import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import NewTextInput from 'components/atoms/new-text-input'
import Icon from 'components/atoms/icon'

const Container = styled.form`
  // background-color: ${({ theme }) => theme.colors.brandGolf};
  display: flex;
  position: relative;
  /* move container to it's own z-level plane ensures that children of
  underlying positioned element are not rendered on the same z-level plane */
  z-index: 0;
`

/**
 * Generic search field that can be used in many contexts.
 *
 * Please note that it can communicate it's typed text on each letter
 * typed and when a user hits Enter.
 * Both or either method can be used to trigger a search this way.
 */
function SearchInput({ className, name, onChange, onSubmit, value }) {
  const { t } = useTranslation()

  function handleSubmit(event) {
    event.preventDefault()
    onSubmit(event)
  }

  return (
    <Container className={className} onSubmit={handleSubmit}>
      <NewTextInput
        name={name || 'searchQuery'}
        onChange={onChange}
        label={t('search')}
        value={value}
        icon={<Icon size="lg" type="search" />}
        filled
      />
      <button
        aria-label={t('search')}
        style={{ display: 'none' }}
        type="submit"
      />
    </Container>
  )
}

SearchInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
}

SearchInput.defaultProps = {
  className: null,
  name: null,
  value: null,
}

export default SearchInput
