import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import Button from 'components/atoms/button'

import { CSP_SALES_FLOW } from 'config/routes'

import { media } from 'utilities/styled'

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  > * {
    width: 100%;
  }

  ${media.tablet`
    flex-wrap: nowrap;
    align-items: center;
  `}

  ${media.desktop`
    flex-wrap: wrap;
    align-items: flex-start;
  `}

  ${media.tv`
    flex-wrap: nowrap;
    align-items: center;
  `}
`

const StyledButton = styled(Button)`
  width: auto;
  margin-right: auto;
`

const CarfileCSPPrices = ({ className, carfile, showCspCalculationLink }) => {
  const { t } = useTranslation()
  const {
    dealernr: dealerId,
    kenteken: licensePlate,
    km_binnen: mileage,
  } = carfile

  return (
    <Container className={className}>
      {showCspCalculationLink && (
        <StyledButton
          to={`${CSP_SALES_FLOW}/0?carFileId=${carfile.id}&dealerId=${dealerId}&licensePlate=${licensePlate}&currentMileage=${mileage}`}
          type="button"
          text={t('calculateMaintenanceCosts')}
          data-test-e2e="link-calculate-costs"
        />
      )}
    </Container>
  )
}

CarfileCSPPrices.propTypes = {
  carfile: PropTypes.object.isRequired,
  className: PropTypes.string,
  showCspCalculationLink: PropTypes.bool,
}

CarfileCSPPrices.defaultProps = {
  className: null,
  showCspCalculationLink: true,
}

export default CarfileCSPPrices
