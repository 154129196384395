import { connect } from 'react-redux'
import { postDynamicEndpoint, getPdfPreview } from 'redux/actions/data'
import { getForms } from 'redux/actions/forms'
import PdfSettingsForm from '../organisms/pdf-settings-form'

const mapStateToProps = ({ data, forms, auth }) => ({
  dynamicEndpoint: data.dynamicEndpoint,
  forms,
  pdfPreview: data.pdfPreview,
  auth,
})

const mapDispatchToProps = {
  getForms,
  getPdfPreview,
  postDynamicEndpoint,
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfSettingsForm)
