import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import FlexibleDialog, {
  propTypes as dialogPropTypes,
} from 'components/molecules/flexible-dialog'
import NewDropDownSelect from 'components/molecules/new-dropdown-select'
import { MarktplaatsBulkActions } from 'config/enums'
import { media } from 'utilities/styled'
import LoadingButton from 'components/atoms/loading-button'
import { useDispatch, useSelector } from 'react-redux'
import { getLocations, createMarktplaatsBulkAction } from 'redux/actions/data'
import Typography from 'components/molecules/typography'
import toast from 'utilities/toast'

const SelectRow = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.sizings.lvl2};
  column-gap: ${({ theme }) => theme.sizings.lvl4};
  margin-top: ${({ theme }) => theme.sizings.lvl4};

  ${media.tablet`
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  `}
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizings.lvl4};
`

const Intro = styled(Typography)`
  margin: 0;
  white-space: pre-line;
`

const Select = styled(NewDropDownSelect)`
  width: 100%;

  ${media.tablet`
    width: ${({ theme }) => theme.sizeByFactor(30)};
  `}
`

const MarktplaatsBulkActionsDialog = ({
  preselectedLocationId,
  onClose,
  ...restProps
}) => {
  const { t } = useTranslation()
  const [selectedLocationId, setSelectedLocationId] = React.useState(
    preselectedLocationId || '0',
  )
  const [selectedBulkAction, setSelectedBulkAction] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const bulkActionItems = Object.values(MarktplaatsBulkActions).map(
    (value) => ({
      label: t(`marktplaatsBulkActionsDialog.actions.${value}`),
      value,
    }),
  )

  const dispatch = useDispatch()
  const locations = useSelector((state) => state?.data?.locations)

  React.useEffect(() => {
    if (!locations?.data && !locations?.loading && !locations?.error) {
      dispatch(getLocations())
    }
  }, [locations, dispatch])

  const handleSubmit = () => {
    setIsSubmitting(true)
    dispatch(
      createMarktplaatsBulkAction({
        vestiging_id: selectedLocationId !== '0' ? selectedLocationId : null,
        action: selectedBulkAction,
      }),
    )
      .then((data) => {
        setIsSubmitting(false)
        if (data && data.success) {
          toast.success(t('marktplaatsBulkActionsDialog.success'))
          onClose && onClose()
        }
      })
      .catch((error) => {
        console.error(error)
        setIsSubmitting(false)
      })
  }

  const mappedLocations =
    locations && locations.data
      ? locations.data.map((location) => ({
          ...location,
          label: location.label,
          value: location.id.length ? location.id : '0',
        }))
      : []

  return (
    <FlexibleDialog
      title={t('marktplaatsBulkActionsDialog.title')}
      disableBackdropClick={isSubmitting}
      onClose={onClose}
      content={
        <>
          <Intro type="ExplanationParagraph">
            {t('marktplaatsBulkActionsDialog.intro')}
          </Intro>
          <SelectRow>
            <Select
              label={t('dealer')}
              items={mappedLocations}
              onChange={setSelectedLocationId}
              value={selectedLocationId}
              selectionRequired
              filled
              data-test-e2e="select-dealer"
            />
            <Select
              label={t('marktplaatsBulkActionsDialog.actionLabel')}
              items={bulkActionItems}
              onChange={setSelectedBulkAction}
              value={selectedBulkAction}
              selectionRequired
              required
              filled
              data-test-e2e="select-action"
            />
          </SelectRow>
          <Controls>
            <LoadingButton
              level="option"
              onClick={onClose}
              text={t('cancel')}
              disabled={isSubmitting}
              noPadding
              data-test-e2e="button-cancel"
            />
            {!!mappedLocations.length > 0 && (
              <LoadingButton
                level="cta"
                isLoading={isSubmitting}
                disabled={!selectedBulkAction}
                type="submit"
                onClick={handleSubmit}
                data-test-e2e="button-save"
              >
                {t('marktplaatsBulkActionsDialog.executeAction')}
              </LoadingButton>
            )}
          </Controls>
        </>
      }
      {...restProps}
      data-test-e2e="marktplaats-bulk-actions-dialog"
    />
  )
}

const { title, content, ...dialogRestPropTypes } = dialogPropTypes

MarktplaatsBulkActionsDialog.propTypes = {
  ...dialogRestPropTypes,
  preselectedLocationId: PropTypes.string,
}

export default MarktplaatsBulkActionsDialog
