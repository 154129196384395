import LoadingIndicator from 'components/atoms/loading-indicator'
import ComplexForm from 'components/molecules/complex-form'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { postDynamicEndpoint } from 'redux/actions/data'
import { getForms } from 'redux/actions/forms'
import { translateForm } from 'utilities/form-utils'
import PropTypes from 'prop-types'
import toast from 'utilities/toast'
import Typography from 'components/molecules/typography'
import styled from 'styled-components/macro'

const ResponseText = styled(Typography)`
  white-space: pre-wrap;
  margin-top: 0;
`

/**
 * This shows a form that allows the user to check within RDW
 * the ownership of that car.
 *
 * It also allows the user to check if a car ownership has been processed by RDW.
 */
const RdwCheckCarForm = ({ carFileId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const form = useSelector((state) => state?.forms?.rdwCheckCar)
  const [formValues, setFormValues] = React.useState({})
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [responseText, setResponseText] = React.useState(null)

  React.useEffect(() => {
    if (!form) {
      dispatch(getForms('rdwCheckCar', { auto_id: carFileId }))
    }
  }, [dispatch, form, carFileId])

  const handleFormChange = (values) => {
    setFormValues(values)
  }

  const onSubmit = () => {
    setIsSubmitting(true)
    setResponseText(null)
    return dispatch(
      postDynamicEndpoint({
        endpoint: form.data.form.endpoint,
        ...formValues,
      }),
    )
      .then((response) => {
        if (response?.success) {
          setResponseText(response.data.message)
          toast.success(response.data.message, { autoClose: 10000 })
        }
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const translatedForm = translateForm(form?.data?.form?.fieldsets || [], t)

  return !form || form.loading || form.error ? (
    <LoadingIndicator error={form?.error} />
  ) : (
    <>
      {responseText && (
        <>
          <ResponseText type="BodyParagraph">{responseText}</ResponseText>
        </>
      )}
      <ComplexForm
        level="cta"
        data={formValues}
        fieldsets={translatedForm}
        formId="rdwCheckCar"
        onChange={handleFormChange}
        onSubmit={onSubmit}
        submitText={t('rdwCarTransfer.checkCarTab.submitButtonText')}
        enableReinitialize
        isLoading={isSubmitting}
      />
    </>
  )
}

RdwCheckCarForm.propTypes = {
  carFileId: PropTypes.string,
}

RdwCheckCarForm.defaultProps = {
  carFileId: null,
}

export default RdwCheckCarForm
