import * as React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Overlay from 'components/molecules/overlay'
import AccessoryForm from 'components/views/settings/accessories/accessory-form'

const AccessoryOverlay = ({
  accessoryId,
  isNewAccessory,
  handleCloseAddEditAccessoryOverlay,
  handleCreateEditAccessorySubmit,
  getAccessoriesData,
  createAccessoryLoading,
  updateAccessoryLoading,
}) => {
  const { t } = useTranslation()

  return (
    <Overlay
      title={
        isNewAccessory
          ? t('accessoriesSettings.overlay.title.add')
          : t('accessoriesSettings.overlay.title.edit')
      }
      icon="checklist"
      close={handleCloseAddEditAccessoryOverlay}
    >
      <AccessoryForm
        accessoryId={accessoryId}
        isNewAccessory={isNewAccessory}
        getAccessoriesData={getAccessoriesData}
        createAccessoryLoading={createAccessoryLoading}
        updateAccessoryLoading={updateAccessoryLoading}
        handleCreateEditAccessorySubmit={handleCreateEditAccessorySubmit}
      />
    </Overlay>
  )
}

AccessoryOverlay.propTypes = {
  accessoryId: PropTypes.string.isRequired,
  isNewAccessory: PropTypes.bool.isRequired,
  handleCloseAddEditAccessoryOverlay: PropTypes.func.isRequired,
  handleCreateEditAccessorySubmit: PropTypes.func.isRequired,
  getAccessoriesData: PropTypes.array,
  createAccessoryLoading: PropTypes.bool,
  updateAccessoryLoading: PropTypes.bool,
}

AccessoryOverlay.defaultProps = {
  getAccessoriesData: [],
  createAccessoryLoading: false,
  updateAccessoryLoading: false,
}

export default AccessoryOverlay
