import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Formik, Form, Field } from 'formik'
import toast from 'utilities/toast'
import Button from '../../../atoms/button'
import LoadingIndicator from '../../../atoms/loading-indicator'
import Dialog from '../../../molecules/flexible-dialog'
import { FormikTextInput } from '../../../molecules/formik-field'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  ACCEPT_MILEAGE_CORRECTION,
  GET_CONTRACT,
  PERFORM_MILEAGE_CHECK,
} from 'config/graphql/csp'
import StatusMessage from 'components/molecules/status-message'
import { formatPrice } from 'utilities/format'
import Typography from 'components/molecules/typography'

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.sizings.lvl3};
  > * {
    flex: 1 0;
    width: ${({ theme }) => theme.sizeByFactor(40)};
  }
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.sizings.lvl3};
  align-items: center;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`

function DoMileageCheckDialog({ onClose, contract }) {
  const { t } = useTranslation()
  const [doMileageCheck, mileageCheckResult] = useLazyQuery(
    PERFORM_MILEAGE_CHECK,
  )
  const [acceptMileage] = useMutation(ACCEPT_MILEAGE_CORRECTION, {
    refetchQueries: [{ query: GET_CONTRACT, variables: { id: contract.id } }],
    awaitRefetchQueries: true,
  })
  const handleFormSubmit = async (values) => {
    if (!mileageCheckResult?.data) {
      await doMileageCheck({
        variables: {
          mileage: parseInt(values.mileage),
          contractId: parseInt(contract.id),
        },
      })
    } else {
      try {
        await acceptMileage({
          variables: {
            mileageId: parseInt(mileageCheckResult.data.performMileageCheck.id),
          },
        })
        toast.success(
          t('carServicePlanContractDetails.mileageCheck.dialog.successMessage'),
        )
      } catch (e) {
        toast.error(e.toString())
      }
      onClose()
    }
  }

  return (
    <Dialog
      closeHandler={onClose}
      open
      overflowVisible
      title={t('carServicePlanContractDetails.mileageCheck.dialog.heading')}
      content={
        <>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              mileage: null,
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)
              try {
                await handleFormSubmit(values)
              } catch (e) {
                toast.error(e.toString())
              }
              setSubmitting(false)
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {!mileageCheckResult?.data ? (
                  <FormRow>
                    <Field
                      name="mileage"
                      label={t(
                        'carServicePlanContractDetails.mileageCheck.dialog.mileage.label',
                      )}
                      filled
                      type="integer"
                      component={FormikTextInput}
                    />
                  </FormRow>
                ) : (
                  <>
                    {mileageCheckResult.data.performMileageCheck
                      .correctionPrice ? (
                      <StatusMessage
                        heading={t(
                          'carServicePlanContractDetails.mileageCheck.dialog.costsToSettle.heading',
                        )}
                      >
                        {t(
                          'carServicePlanContractDetails.mileageCheck.dialog.costsToSettle.message',
                          {
                            ...mileageCheckResult.data.performMileageCheck,
                          },
                        )}
                        <br />
                        <br />
                        <Typography type="InlineBodyText" variant="bold">
                          {formatPrice(
                            mileageCheckResult.data.performMileageCheck
                              .correctionPrice,
                          )}
                        </Typography>
                        <br />
                        <br />
                        {t(
                          'carServicePlanContractDetails.mileageCheck.dialog.costsToSettle.question',
                        )}
                      </StatusMessage>
                    ) : (
                      <StatusMessage
                        type="success"
                        heading={t(
                          'carServicePlanContractDetails.mileageCheck.dialog.noCostsToSettle.heading',
                        )}
                      >
                        {t(
                          'carServicePlanContractDetails.mileageCheck.dialog.noCostsToSettle.message',
                        )}
                      </StatusMessage>
                    )}
                  </>
                )}
                <Controls>
                  {!mileageCheckResult?.data ? (
                    <>
                      <Button
                        level="option"
                        onClick={onClose}
                        text={t('cancel')}
                        noPadding
                      />
                      {isSubmitting && <LoadingIndicator size="small" />}
                      <Button level="cta" disabled={isSubmitting} type="submit">
                        {t(
                          'carServicePlanContractDetails.mileageCheck.dialog.calculateButton',
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      {mileageCheckResult.data.performMileageCheck
                        .correctionPrice ? (
                        <>
                          <Button disabled={isSubmitting} onClick={onClose}>
                            {t(
                              'carServicePlanContractDetails.mileageCheck.dialog.doNotSettleExtraCostButton',
                            )}
                          </Button>
                          <Button
                            level="cta"
                            disabled={isSubmitting}
                            type="submit"
                          >
                            {t(
                              'carServicePlanContractDetails.mileageCheck.dialog.doSettleExtraCostButton',
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button onClick={onClose}>
                          {t(
                            'carServicePlanContractDetails.mileageCheck.dialog.closeButton',
                          )}
                        </Button>
                      )}
                    </>
                  )}
                </Controls>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  )
}

DoMileageCheckDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  contract: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    packageCalculationResult: PropTypes.shape({
      resultNet: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
}

export default DoMileageCheckDialog
