// EXTERNAL
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { translateForm } from 'utilities/form-utils'
import moment from 'moment'

// INTERNAL
import toast from 'utilities/toast'
import { CAR_BPM_DECLARATION } from 'config/routes'
import TextLink from 'components/atoms/text-link'
import EnhancedTable from 'components/organisms/enhanced-table'
import Typography from 'components/molecules/typography'
import ComplexForm from 'components/molecules/complex-form'
import Text from 'components/atoms/text'
import { getFilteredEditionsForBpmDeclaration } from 'redux/actions/data'
import data from 'utilities/data'
import { debounce } from 'utilities/utils'

const { createCarfileByNat } = data

const Title = styled(Typography)`
  font-size: 20px;
  margin-bottom: ${({ theme }) => theme.sizings.lvl0};
`

const FiltersForm = styled(ComplexForm)`
  margin-top: ${({ theme }) => theme.sizings.lvl2};
  margin-left: 0;
`

const ResetLink = styled(TextLink)`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
  text-align: right;
`

const CarBpmDeclarationStep1Table = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [isCreatingCarFile, setIsCreatingCarFile] = useState(false)
  const instanceId = useSelector((state) => state.auth.instanceId)
  const bpmDeclarationForm = useSelector((state) => state.form.bpmDeclaration)
  const bpmDeclarationFormData = {
    date: moment(bpmDeclarationForm?.values?.datum).format('YYYY-MM-DD'),
    km: bpmDeclarationForm?.values?.km,
    vin: bpmDeclarationForm?.values?.vin,
  }
  const editionsForBpmDeclaration = useSelector(
    (state) => state.data.editionsForBpmDeclaration,
  )
  const [orderBy, setOrderBy] = useState('naam')
  const [orderDirection, setOrderDirection] = useState('asc')
  const [enabledOrdering, setEnabledOrdering] = useState(false)
  const [blockFilterRequest, setBlockFilterRequest] = useState(true)
  const [cars, setCars] = useState(editionsForBpmDeclaration?.data?.rows)
  const numberOfResults = editionsForBpmDeclaration?.data?.count
  const filterFieldsets = editionsForBpmDeclaration?.data?.filters?.fieldsets
  const translatedFilterFieldsets =
    filterFieldsets && translateForm(filterFieldsets, t)

  const handleCreateCarFileByNat = async (token, nat, formData) => {
    if (!isCreatingCarFile) {
      setIsCreatingCarFile(true)

      toast.info(t('bpmDeclarationTable.processingCarFile'))

      createCarfileByNat(token, {
        uitvoering: nat,
        km: formData.km,
        datum: formData.date,
        vin: formData.vin,
      })
        .then((response) => {
          history.push(
            `${CAR_BPM_DECLARATION}/2/basic?auto_id=${response.data.data.auto_id}`,
          )
        })
        .catch((error) => {
          toast.error(error)
          setIsCreatingCarFile(false)
        })
    }
  }

  const handleChangeFilters = debounce((filters) => {
    if (!blockFilterRequest || !!Object.values(filters).some((o) => o !== '')) {
      dispatch(getFilteredEditionsForBpmDeclaration(filters))
    }
    setBlockFilterRequest(false)
  }, 300)

  const columns = [
    {
      id: 'type',
      label: t('bpmDeclarationTable.tableHeader.type'),
      orderId: 'type',
    },
    {
      id: 'brandstof',
      label: t('bpmDeclarationTable.tableHeader.fuel'),
    },
    {
      id: 'carrosserie',
      label: t('bpmDeclarationTable.tableHeader.body'),
    },
    {
      id: 'kwPk',
      label: t('bpmDeclarationTable.tableHeader.kwHp'),
    },
    {
      id: 'importPeriode',
      label: t('bpmDeclarationTable.tableHeader.importPeriod'),
    },
  ]

  const handleOrder = (direction, by) => {
    if (enabledOrdering) {
      setOrderDirection(direction)
      setCars((prevCars) => prevCars.reverse())
    }
    setOrderBy(by)
    setEnabledOrdering(true)
  }

  useEffect(() => {
    if (Array.isArray(translatedFilterFieldsets)) {
      setBlockFilterRequest(
        !translatedFilterFieldsets[0]?.fields.some(
          (field) => !!field.value === true,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Title type="Level1Heading">{t('selectVersion')}</Title>
      <Typography type="InlineBodyText">
        {numberOfResults > 0
          ? t('numberOfResults', { count: numberOfResults })
          : t('noResultsFound')}
      </Typography>
      {translatedFilterFieldsets && (
        <FiltersForm
          fieldsets={translatedFilterFieldsets}
          formId="carVersionSelectorFilters"
          onChange={handleChangeFilters}
          enableReinitialize
        />
      )}
      <ResetLink
        text={t('resetFilters')}
        onClick={() => dispatch(getFilteredEditionsForBpmDeclaration())}
      />
      {numberOfResults > 0 && (
        <EnhancedTable
          onOrder={handleOrder}
          orderBy={orderBy}
          orderDirection={orderDirection}
          columns={columns}
          rows={cars?.map((car) => ({
            nat: {
              data: car.nat,
            },
            datum: {
              data: bpmDeclarationFormData.date,
            },
            type: {
              component: (
                <>
                  <Text text={car.naam} />
                  <TextLink
                    text={t('select')}
                    onClick={() =>
                      handleCreateCarFileByNat(
                        instanceId,
                        car.nat,
                        bpmDeclarationFormData,
                      )
                    }
                  />
                </>
              ),
            },
            brandstof: {
              component: <Text text={car.brandstof_naam_lang} />,
            },
            carrosserie: {
              component: <Text text={car.carrosserie_naam_lang} />,
            },
            kwPk: {
              component: (
                <Text text={`${car.vermogenkw} / ${car.vermogenpk}`} />
              ),
            },
            importPeriode: {
              component: (
                <>
                  <Text
                    text={t(
                      'bpmDeclarationTable.tableBody.importPeriod.fromDate',
                      {
                        date: `${car.maand_start}-${car.jaar_start}`,
                      },
                    )}
                  />
                  <Text
                    text={t(
                      'bpmDeclarationTable.tableBody.importPeriod.toDate',
                      {
                        date:
                          car.maand_eind && car.jaar_eind
                            ? `${car.maand_eind}-${car.jaar_eind}`
                            : 'n.n.b.',
                      },
                    )}
                  />
                </>
              ),
            },
          }))}
          selectable={false}
          selectedText={t('carsSelected')}
          totalResults={numberOfResults}
          noDataMessage={t('noCarFoundBasedOnFilters')}
        />
      )}
    </>
  )
}

export default CarBpmDeclarationStep1Table
