import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const DealerContext = createContext({
  warrantyProgramMutating: false,
  setWarrantyProgramMutating: (_) => {},
  priceMatrixMutating: false,
  setPriceMatrixMutating: (_) => {},
})

export const DealerProvider = ({ children }) => {
  const [warrantyProgramMutating, setWarrantyProgramMutating] = useState(false)
  const [priceMatrixMutating, setPriceMatrixMutating] = useState(false)

  const value = {
    warrantyProgramMutating,
    setWarrantyProgramMutating,
    priceMatrixMutating,
    setPriceMatrixMutating,
  }

  return (
    <DealerContext.Provider value={value}>{children}</DealerContext.Provider>
  )
}

DealerProvider.propTypes = {
  children: PropTypes.node,
}
