// EXTERNAL
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { reset } from 'redux-form'
import { useDispatch } from 'react-redux'

// INTERNAL
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import { clearData } from '../../../redux/actions/data'
import CarBpmDeclarationStep1 from './steps/car-bpm-declaration-step-1'
import CarBpmDeclarationStep2 from './steps/car-bpm-declaration-step-2'

function CarBpmDeclarationView() {
  const { t } = useTranslation()
  const { step } = useParams()
  const dispatch = useDispatch()

  // Clear all car entry data when (re-)entering this view
  // TODO -> make this a custom hook.
  useEffect(() => {
    dispatch(clearData('newCar'))
    dispatch(clearData('edition'))
    dispatch(clearData('licensePlate'))
    dispatch(clearData('nap'))
    dispatch(clearData('carAssets'))
    dispatch(clearData('carTypes'))
    dispatch(clearData('carModels'))
    dispatch(clearData('carBrands'))
    dispatch(clearData('createdCarfileByEdition'))
    dispatch(clearData('carfile'))
    dispatch(clearData('forms'))
    dispatch(clearData('editions'))
    dispatch(clearData('editionsForBpmDeclaration'))
    dispatch(reset('carEntry'))
    dispatch(reset('carValuation'))
    dispatch(reset('bpmDeclaration'))
  }, [step, dispatch])

  return (
    <AppLayoutContainer pageTitle={t('bpmDeclaration')}>
      {
        {
          1: <CarBpmDeclarationStep1 />,
          2: <CarBpmDeclarationStep2 />,
        }[step]
      }
    </AppLayoutContainer>
  )
}

export default CarBpmDeclarationView
