import React from 'react'
import PropTypes from 'prop-types'
import IframeOverlay from 'components/organisms/iframe-overlay'
import FormOverlay from 'components/organisms/form-overlay'
import DynamicFormOverlay from 'components/redux-containers/dynamic-form-overlay-container'
import SearchOverlay from 'components/organisms/search-overlay'
import TodosOverlay from 'components/organisms/todos-overlay'
import CommentsOverlay from 'components/organisms/comments-overlay'
import OpticalDamageOverlay from 'components/organisms/optical-damage-overlay'
import TechnicalDamageOverlay from 'components/organisms/technical-damage-overlay'

const componentsMap = {
  comments: CommentsOverlay,
  iframe: IframeOverlay,
  form: FormOverlay,
  dynamicForm: DynamicFormOverlay,
  opticalDamage: OpticalDamageOverlay,
  technicalDamage: TechnicalDamageOverlay,
  search: SearchOverlay,
  todos: TodosOverlay,
}

function Overlays({ overlay, ...restProps }) {
  if (!overlay) {
    return false
  }

  const Component = componentsMap[overlay.id]

  return <Component {...restProps} {...overlay.props} />
}

Overlays.propTypes = {
  forms: PropTypes.object.isRequired,
  closeOverlay: PropTypes.func.isRequired,
  overlay: PropTypes.shape({
    id: PropTypes.oneOf(Object.keys(componentsMap)).isRequired,
    props: PropTypes.object,
  }).isRequired,
  todos: PropTypes.object.isRequired,
}

export default Overlays
