// EXTERNAL
import React, { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Formik, Form, Field, useFormikContext } from 'formik'
import styled from 'styled-components/macro'
import Grid from '@material-ui/core/Grid'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

// INTERNAL
import { openOverlay, closeOverlay } from 'redux/actions/ui'
import Link from 'components/atoms/link'
import Button from 'components/atoms/button'
import { updateCarfile, getCarfile } from 'redux/actions/data'
import { FormikTextInput } from 'components/molecules/formik-field'
import Text from 'components/atoms/text'
import { topdownBpmCalculationFormSchema } from 'config/validation-schemas'
import Typography from 'components/molecules/typography'

// COMPONENT
const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const StyledField = styled(Field)`
  margin-bottom: ${({ theme, type }) =>
    type === 'hidden' ? 0 : theme.sizings.lvl2};
`

const ContextInputField = ({ name, ...props }) => {
  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    const calculateProfit = (saleValue) =>
      Number(saleValue) -
      (Number(values.visualReconditioningCosts) +
        Number(values.technicalReconditioningCosts)) -
      Number(values.totalPurchasingValueInclRestBpm)

    // Calculate total reconditioning costs
    setFieldValue(
      'totalReconditioningCosts',
      Number(values.visualReconditioningCosts) +
        Number(values.technicalReconditioningCosts),
    )

    // Calculate total purchasing costs
    setFieldValue(
      'totalPurchasingValueInclRestBpm',
      Number(values.purchasingValue) + Number(values.restBpm),
    )

    // Update expected sale value
    setFieldValue(
      'grossProfitPotentialTotals',
      Number(values.expectedSaleValue) !== 0
        ? calculateProfit(Number(values?.expectedSaleValue))
        : calculateProfit(Number(values?.saleValueFromEuroTax)),
    )
  }, [values, setFieldValue])

  return <StyledField name={name} {...props} />
}

ContextInputField.propTypes = {
  name: PropTypes.string.isRequired,
}

const TopdownBpmCalculationForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const carFile = useSelector(
    (state) => state.data && state.data.carfile && state.data.carfile.data,
  )

  const carId = carFile.auto_id
  const totalReconditioningCosts =
    Number(carFile?.kosten_opt) + Number(carFile?.kosten_tech)
  const totalPurchasingValueInclRestBpm =
    Number(carFile?.inkoopprijs) + Number(carFile?.eurotaxrestbpm)
  const expectSaleValue = carFile?.verkoop
    ? carFile?.verkoop
    : carFile?.eurotaxwaarde

  const initialValues = {
    saleValueFromEuroTax: carFile?.etbb_eurotaxwaarde || '',
    expectedSaleValue: carFile?.verkoop || '',
    visualReconditioningCosts: carFile?.kosten_opt || '',
    technicalReconditioningCosts: carFile?.kosten_tech || '',
    totalReconditioningCosts: totalReconditioningCosts || '',
    purchasingValue: carFile?.inkoopprijs_view || '',
    restBpm: carFile?.eurotaxrestbpm || '',
    totalPurchasingValueInclRestBpm: totalPurchasingValueInclRestBpm || '',
    grossProfitPotentialTotals:
      expectSaleValue -
      totalReconditioningCosts -
      totalPurchasingValueInclRestBpm,
  }

  const onSubmitUpdates = (values) => {
    const updatedCarFile = {
      ...carFile,
      ...{
        verkoop: values.expectedSaleValue,
        kosten_opt: values.visualReconditioningCosts,
        kosten_tech: values.technicalReconditioningCosts,
      },
    }

    dispatch(updateCarfile(updatedCarFile, carId))
  }

  const handleChangeValuesClick = () => {
    dispatch(
      openOverlay('form', {
        data: carFile,
        enableReinitialize: true,
        formCategory: 'carfile',
        formId: 'basics_form_extra',
        icon: 'edit',
        storeFormId: 'basics_form_extra',
        submitText: t('saveChanges'),
        title: t('editMasterData'),
        onSubmit: (values) => {
          dispatch(updateCarfile({ ...carFile, ...values }, carId))
          closeOverlay()
        },
      }),
    )
  }

  /**
   * When the user directly navigates here from BPM generation
   * without refreshing, we need to fetch the car file again
   * so the values are visible in the form.
   */
  useEffect(() => {
    if (carFile && carFile?.hasBpmValuation && !carFile?.eurotaxrestbpm) {
      dispatch(getCarfile(carId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carId, carFile?.hasBpmCalculation, carFile?.eurotaxrestbpm])

  return (
    <>
      <StyledText type="h2">{t('calculation')}</StyledText>
      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={topdownBpmCalculationFormSchema(t)}
        onSubmit={onSubmitUpdates}
      >
        {({ isValid }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Form>
                <Typography type="Level2Heading">
                  {t('topdownBpmCalculationForm.expectedSaleValue')}
                </Typography>
                <ContextInputField
                  name="saleValueFromEuroTax"
                  label={t('topdownBpmCalculationForm.saleValueFromEuroTax')}
                  type="number"
                  unit="currency_euro"
                  filled
                  component={FormikTextInput}
                  disabled
                />
                <ContextInputField
                  name="expectedSaleValue"
                  label={t('topdownBpmCalculationForm.expectedSaleValue')}
                  type="number"
                  unit="currency_euro"
                  filled
                  component={FormikTextInput}
                  disabled={false}
                />
                <Typography type="Level2Heading">
                  {t('topdownBpmCalculationForm.expectedReconditioningCosts')}
                </Typography>
                <ContextInputField
                  name="visualReconditioningCosts"
                  label={t(
                    'topdownBpmCalculationForm.visualReconditioningCosts',
                  )}
                  type="number"
                  unit="currency_euro"
                  filled
                  component={FormikTextInput}
                  disabled={false}
                />
                <ContextInputField
                  name="technicalReconditioningCosts"
                  label={t(
                    'topdownBpmCalculationForm.technicalReconditioningCosts',
                  )}
                  type="number"
                  unit="currency_euro"
                  filled
                  component={FormikTextInput}
                  disabled={false}
                />
                <ContextInputField
                  name="totalReconditioningCosts"
                  label={t(
                    'topdownBpmCalculationForm.totalReconditioningCosts',
                  )}
                  type="number"
                  unit="currency_euro"
                  filled
                  component={FormikTextInput}
                  disabled
                />
                <Typography type="Level2Heading">
                  {t('topdownBpmCalculationForm.purchasingValue')}
                </Typography>

                <Typography type="ExplanationParagraph">
                  <Trans i18nKey="topdownBpmCalculationForm.editingThePurchasingValues">
                    <Link to="#overlay" onClick={handleChangeValuesClick}>
                      {/* hashtag added because the <Link> component creates a div when there's no "to" or "href" prop - and that breaks the design */}
                      {{ page: t('topdownBpmCalculationForm.masterData') }}
                    </Link>
                  </Trans>
                </Typography>

                <ContextInputField
                  name="purchasingValue"
                  label={t('topdownBpmCalculationForm.purchasingValue')}
                  type="number"
                  unit="currency_euro"
                  filled
                  component={FormikTextInput}
                  readonly
                  disabled
                />
                <ContextInputField
                  name="restBpm"
                  label={t('topdownBpmCalculationForm.restBpm')}
                  unit="currency_euro"
                  filled
                  component={FormikTextInput}
                  readonly
                  disabled
                />
                <ContextInputField
                  name="totalPurchasingValueInclRestBpm"
                  label={t(
                    'topdownBpmCalculationForm.totalPurchasingValueInclRestBpm',
                  )}
                  unit="currency_euro"
                  filled
                  component={FormikTextInput}
                  readonly
                  disabled
                />
                <Typography type="Level2Heading">
                  {t('topdownBpmCalculationForm.grossProfitPotential')}
                </Typography>
                <ContextInputField
                  name="grossProfitPotentialTotals"
                  label={t(
                    'topdownBpmCalculationForm.grossProfitPotentialTotals',
                  )}
                  unit="currency_euro"
                  filled
                  component={FormikTextInput}
                  readonly
                  disabled
                />
                <Button
                  disabled={!isValid}
                  type="submit"
                  level="cta"
                  text={t('save')}
                />
              </Form>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  )
}

export default TopdownBpmCalculationForm
