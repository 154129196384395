// EXTERNAL
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// INTERNAL
import Typography from 'components/molecules/typography'
import ExpandButton from 'components/molecules/expand-button'
import LoadingIndicator from 'components/atoms/loading-indicator'

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${(props) => props.theme.sizings.lvl2};
  padding-bottom: ${(props) => props.theme.sizings.lvl2};
`

const StyledDiv = styled('div')`
  display: flex;
  align-items: center;
`

const ContentDiv = styled('div')`
  padding-bottom: ${(props) => props.theme.sizings.lvl2};
`

const StyledTypography = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0;
`

const StyledWrapper = styled.div`
  display: block;
  width: 100%;
  cursor: pointer;
  border-top: solid 1px ${(props) => props.theme.colors.darkOnLightBorder};
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: auto;
  margin-left: ${(props) => props.theme.sizings.lvl1};
`

const NewExpandableRow = ({
  title,
  titleType,
  isInitiallyExpanded,
  children,
  ...restProps
}) => {
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded)
  const [isExpandedLoading, setIsExpandedLoading] =
    useState(isInitiallyExpanded)
  const contentRef = useRef()

  useEffect(() => {
    if (!contentRef.current && isExpandedLoading) {
      setIsExpanded(() => true)
    }
    if (contentRef.current && isExpandedLoading) {
      setIsExpanded(() => false)
    }
  }, [isExpandedLoading])

  useEffect(() => {
    if (
      (contentRef.current && isExpanded) ||
      (!contentRef.current && !isExpanded)
    ) {
      setIsExpandedLoading(() => false)
    }
  }, [isExpanded, contentRef])

  useEffect(() => {
    setIsExpanded(isInitiallyExpanded)
  }, [isInitiallyExpanded])

  return (
    <StyledWrapper {...restProps}>
      <Header onClick={() => setIsExpandedLoading(() => true)}>
        <StyledDiv>
          <StyledTypography type={titleType}>{title}</StyledTypography>
          {isExpandedLoading && <StyledLoadingIndicator size="extraSmall" />}
        </StyledDiv>
        <ExpandButton isExpanded={isExpanded} />
      </Header>
      {isExpanded && <ContentDiv ref={contentRef}>{children}</ContentDiv>}
    </StyledWrapper>
  )
}

NewExpandableRow.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleType: PropTypes.string,
  isInitiallyExpanded: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

NewExpandableRow.defaultProps = {
  className: null,
  isInitiallyExpanded: false,
  titleType: 'Level5Heading',
}

export default NewExpandableRow
