import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import Link from 'components/atoms/link'
import Icon from 'components/atoms/icon'

const Container = styled.div`
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.brandDelta};
  padding: 12px;

  &:not(:last-child) {
    border-bottom: none;
  }
`

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const Body = styled.div`
  padding: 16px 0px;

  ${media.tablet`
    padding: 16px 16px;
  `}
`

const InnerContainer = styled.div`
  display: flex;

  & > * {
    margin-bottom: auto;
    margin-top: auto;
  }
`

const Detail = styled.div`
  display: flex;
`

const Seperator = styled.div`
  background: ${({ theme }) => theme.colors.brandDelta};
  margin: 0 16px;
  width: 1px;
`

function ExpandableRow({ children, details, main }) {
  const [expanded, setExpanded] = useState(false)

  const expandIcon = expanded ? 'close' : 'add'

  return (
    <Container>
      <Header>
        <InnerContainer>{main}</InnerContainer>
        <InnerContainer>
          {details &&
            details.map((detail, index) => (
              <Detail key={index.toString()}>
                {detail}
                <Seperator />
              </Detail>
            ))}
          <Link onClick={() => setExpanded(!expanded)}>
            <Icon color="brandCharlie" size="sm" type={expandIcon} />
          </Link>
        </InnerContainer>
      </Header>
      {expanded && <Body>{children}</Body>}
    </Container>
  )
}

ExpandableRow.propTypes = {
  children: PropTypes.node.isRequired,
  details: PropTypes.node,
  main: PropTypes.node.isRequired,
}

ExpandableRow.defaultProps = {
  details: null,
}

export default ExpandableRow
