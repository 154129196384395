import React from 'react'
import styled from 'styled-components'

import LineChart, { propTypes } from 'components/molecules/line-chart'

const StyledLineChart = styled(LineChart)`
  transition: opacity ease-in 0.125s;
`

/**
 * Note: Only use this component in the context of the management info page.
 * It's seperated into a molecule because it's being re-used in different charts,
 * yet it's not meant to be used outside of the management info page.
 */
function ManagementInfoChart({ className, options, data }) {
  return <StyledLineChart className={className} options={options} data={data} />
}

ManagementInfoChart.propTypes = propTypes

export default ManagementInfoChart
