import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MaterialTooltip from '@material-ui/core/Tooltip'
import themes from 'config/theme'

const ChildContainer = React.forwardRef((props, ref) => (
  <span {...props} ref={ref} style={{ display: 'inline-block' }} />
))

// Using withStyles instead of styled-components here since there
// does not seem to be a way to style the tooltip itself (instead
// of the wrapper component) without withStyles.
const StyledTooltip = withStyles(() => ({
  tooltip: {
    fontFamily: themes.ucc.font,
    fontSize: 12,
    color: themes.ucc.colors.textOnDarkBackground,
    opacity: 1,
    fontWeight: 400,
    maxWidth: (props) => props.width || '50em', // no wider than about 60 characters per line, which is still fairly wide.
  },
}))(MaterialTooltip)

/**
 * General tooltip. Replaces the old Tooltip component
 */
function NewTooltip(props) {
  const { children } = props
  return (
    <StyledTooltip {...props}>
      <ChildContainer>{children}</ChildContainer>
    </StyledTooltip>
  )
}

NewTooltip.propTypes = {
  children: PropTypes.element.isRequired,
  /** The text to be shown in the tooltip */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  placement: PropTypes.oneOf([
    'top-start',
    'top',
    'top-end',
    'left-start',
    'left',
    'left-end',
    'right-start',
    'right',
    'right-end',
    'bottom-start',
    'bottom',
    'bottom-end',
  ]),
  arrow: PropTypes.bool,
  width: PropTypes.number,
  // indicatorUnderline: PropTypes.bool,
}
NewTooltip.defaultProps = {
  placement: 'bottom',
  arrow: false,
  width: undefined,
  // indicatorUnderline: false,
}

export default NewTooltip
