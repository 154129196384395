import React from 'react'
import PropTypes from 'prop-types'
import Text from 'components/atoms/text'
import SimpleTable from 'components/layouts/simple-table'
import ComplexField from 'components/molecules/complex-field'
import Pill from '../atoms/pill'

function getIsHidden(rowItem, values) {
  if (!rowItem.hidden) {
    return false
  }

  return rowItem.hidden.reduce((isHidden, conditional) => {
    if (conditional.operator !== 'equals') {
      console.error(
        "There is a required condition operator defined in a form field which the frontend can't handle at the moment:",
        conditional.operator,
      )
      return isHidden
    }
    return isHidden || values[conditional.field] === conditional.values
  }, false)
}

function FormTable({ field, onChange, values, ...restProps }) {
  const { rows } = field.data

  function handleChange(index, value, name) {
    const newValues = {
      ...values,
      [name]: value,
    }
    onChange(newValues)
  }

  function trinaryToPillMapping(value) {
    if (value === 1) {
      return 'positive'
    }
    if (value === -1) {
      return 'negative'
    }
    // 0, null, undefined, or other falsy things (should be 0, though)
    return 'neutral'
  }

  return (
    <SimpleTable
      columns={field.data.columns.map((column, index) => (
        <Text key={`${index}`} type="floatingLabelLabel" text={column} />
      ))}
      rows={rows.map((row) =>
        row.map((rowItem) => {
          const processedField = {
            ...rowItem,
            type: getIsHidden(rowItem, values) ? 'hidden' : rowItem.type,
          }

          return processedField.readOnly &&
            processedField.type === 'arrayOfTrinaries' ? (
            // arrayOfTrinaries can only be displayed when readOnly
            // since there is no interactive field for them yet.
            processedField.value.map((item, index) => (
              <React.Fragment key={`${index}`}>
                <Pill
                  level={trinaryToPillMapping(item.value)}
                  text={item.label}
                />
                &nbsp;
              </React.Fragment>
            ))
          ) : (
            <ComplexField
              defaultValues={values}
              errors={[]}
              field={processedField}
              fieldValueIndex={rowItem.name}
              handleChange={handleChange}
              values={values}
            />
          )
        }),
      )}
      data={field.data}
      legend={field.legend}
      {...restProps}
    />
  )
}

FormTable.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

export default FormTable
