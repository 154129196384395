import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import OptionsBar from 'components/layouts/options-bar'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import ExpandableBars from 'components/layouts/expandable-bars'
import SummaryLine from 'components/molecules/summary-line'
import SettingsForm from 'components/redux-containers/settings-form-container'

function B2bPurchasingCompaniesSettings({
  purchasingCompanies,
  getB2bPurchasingCompanies,
  deleteB2bPurchasingCompany,
  openOverlay,
  postDynamicEndpoint,
}) {
  const { t } = useTranslation()
  const purchasingCompaniesData = purchasingCompanies?.all?.data || []
  const purchasingCompaniesLoading = purchasingCompanies?.all?.loading

  const [currentRelation, setCurrentRelation] = useState(null)
  // Normally the subId is used to specify for which a location
  // a setting is loaded but these settings are cmpany wide and
  // therefore the dummy id 'all' is used which can later be changed
  // in case these settings have to become location specific.
  const subId = 'all'

  useEffect(() => {
    getB2bPurchasingCompanies({ id: subId })
  }, [getB2bPurchasingCompanies])

  function handleClickAddRelation() {
    openOverlay('dynamicForm', {
      formId: 'b2bPurchasingCompany',
      onSubmit: (values, endpoint) => {
        postDynamicEndpoint({
          endpoint,
          ...values,
        })
      },
      icon: 'b2b',
      onUpdateSuccess: () => getB2bPurchasingCompanies({ id: subId }),
      submitText: t('saveChanges'),
      title: t('addB2bPurchasingCompany'),
    })
  }

  function handleClickDelete(relation) {
    if (window.confirm(t('areYouSure'))) {
      deleteB2bPurchasingCompany({
        id: subId,
        company_id: relation.id,
      })
    }
  }

  return (
    <>
      <OptionsBar
        leftAligned={[
          <Text type="h2" text={t('purchasingCompanies')} key="title" />,
        ]}
        rightAligned={[
          <Button
            onClick={handleClickAddRelation}
            text={t('addB2bPurchasingCompany')}
            key="addButton"
          />,
        ]}
      />
      {purchasingCompaniesData && !!purchasingCompaniesData.length && (
        <ExpandableBars
          fullRowIsClickable={false}
          rows={purchasingCompaniesData.map((relation, index) => ({
            id: relation.id,
            header: (
              <SummaryLine
                key={index.toString()}
                name={relation.bedrijf}
                details={[]}
                actions={[
                  {
                    action: () => handleClickDelete(relation),
                    text: t('delete'),
                  },
                ]}
              />
            ),
            onRowClick: () => setCurrentRelation(relation.id),
            details: (
              <SettingsForm
                formId="b2bPurchasingCompany"
                params={{ company_id: currentRelation }}
                subId={relation.id}
                onUpdateSuccess={() => getB2bPurchasingCompanies({ id: subId })}
              />
            ),
          }))}
        />
      )}
      {!purchasingCompaniesLoading && !purchasingCompaniesData.length && (
        <Text
          text={t('noB2bPurchasingCompaniesYet')}
          type="floatingLabelLabel"
        />
      )}
    </>
  )
}

B2bPurchasingCompaniesSettings.propTypes = {
  purchasingCompanies: PropTypes.object,
  getB2bPurchasingCompanies: PropTypes.func.isRequired,
  deleteB2bPurchasingCompany: PropTypes.func.isRequired,
  openOverlay: PropTypes.func.isRequired,
  postDynamicEndpoint: PropTypes.func.isRequired,
}

B2bPurchasingCompaniesSettings.defaultProps = {
  purchasingCompanies: null,
}

export default B2bPurchasingCompaniesSettings
