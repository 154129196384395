import { CarChecklistItemStatusses } from 'config/enums'
import * as routes from 'config/routes'
import moment from 'moment'

const currentYear = moment().year()

export * from './units'

// Setting apiUrl to localhost when mocking the server because
// mocking the uccapp endpoint is not allowed by the service
// worker as it is not the same origin.
export const apiUrl = process.env.REACT_APP_MSW
  ? 'http://localhost:3000'
  : process.env.REACT_APP_API_URL

export const v4GraphqlApi = process.env.REACT_APP_V4_API_URL
export const v4TranslationsApi = process.env.REACT_APP_V4_TRANSLATIONS_API_URL
export const carServicePlanApi = process.env.REACT_APP_CSP_API_URL
export const releaseCSPEmail = 'vrijgeven@usedcarcontroller.nl'

// NOTE: THIS IS TEMPORARY. IT IS ONLY USED TO GET A DIFFERENT LIST OF POSSIBLE MILEAGES PER YEAR FOR ONE CLIENT.
const envDealerIdsWithSpecialMileages = process.env
  .REACT_APP_DEALER_IDS_WITH_SPECIAL_MILEAGES
  ? process.env.REACT_APP_DEALER_IDS_WITH_SPECIAL_MILEAGES.split(',').map(
      (id) => {
        const idToTrim = typeof id === 'string' ? id : `${id}`
        return idToTrim.trim()
      },
    )
  : undefined
export const dealerIdsWithSpecialMileages = envDealerIdsWithSpecialMileages || [
  '751',
]
// /NOTE

// contains a-z and 0-9 and any of '!@#$%^&*' and is 7 to 20 characters long
export const passwordRequirementsRegex =
  /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(.{7,20})/
export const licensePlans = {
  pro: 'pro',
  basic: 'basic',
  light: 'light',
  carServicePlan: 'csp',
}

export const marketViewStorageIdsPrefix = 'MV_'

export const marketViewRoutes = [routes.MARKTVIEW, `${routes.SETTINGS}/my-data`]
export const cspRoutes = [
  routes.CSP,
  routes.CSP_SALES_FLOW,
  routes.CSP_CONTRACT_FILE,
  routes.CSP_DEMO_SALES_FLOW,
  `${routes.SETTINGS}/*`,
  routes.ADMINISTRATION_DIRECT_DEBIT_FILES,
  routes.ADMINISTRATION_CSP_TRANSACTIONS,
]

export const marketViewFilterIds = {
  buildYearFrom: 'buildyearfrom',
  buildYearTo: 'buildyearto',
  competitivePosition: 'competitorposition',
  datefrom: 'datefrom',
  dateto: 'dateto',
  company: 'company',
  comparisonGroup: 'comparisongroup',
  area: 'territory',
  make: 'make',
  model: 'model',
  fuel: 'fuel',
  body: 'body',
  destination: 'mutationtype',
  targetStayTime: 'targetstaytime',
  stockTargetConversionRate: 'stocktargetconverstionrate',
}

export const defaultMarketView = {
  competetitorPosition: '28',
  datefrom: `${currentYear}0101`,
  dateto: `${currentYear}1231`,
}

export const destinationOptions = [
  {
    label: 'carKinds.PARTICULIER',
    value: 'particulier',
  },
  {
    label: 'carKinds.PARTICULIERINRUIL',
    value: 'particulierinruil',
  },
  {
    label: 'carKinds.HANDEL',
    value: 'handel',
  },
  {
    label: 'carKinds.HUUR',
    value: 'huur',
  },
  {
    label: 'carKinds.OLDTIMER',
    value: 'oldtimer',
  },
  {
    label: 'carKinds.SCHADEAUTO',
    value: 'schadeauto',
  },
  {
    label: 'carKinds.IMPORT',
    value: 'import',
  },
  {
    label: 'carKinds.DEMO',
    value: 'demo',
  },
  {
    label: 'carKinds.NIEUW',
    value: 'nieuw',
  },
]

export const exBtwBodies = [
  'bedrijfsauto',
  'chassis cabine',
  'dubbele cabine',
  'gesloten bestel',
  'koelwagen',
  'meubelbak',
  'meubelbak met laadklep',
  'open laadbak/dubbele cabine',
]

export const expectedOptions = [
  {
    label: '< 1 Maanden',
    value: '0',
  },
  {
    label: '1 Maand',
    value: '1',
  },
  {
    label: '2 Maanden',
    value: '2',
  },
  {
    label: '4 Maanden',
    value: '4',
  },
]

export const rdwStockSortOptions = [
  {
    label: 'rdwStockSortBy.licensePlateAscending', // 'Kenteken (oplopend)',
    value: 'sort=licenseplate&dir=ASC',
  },
  {
    label: 'rdwStockSortBy.licensePlateDescending', // Kenteken (aflopend)',
    value: 'sort=licenseplate&dir=DESC',
  },
  {
    label: 'rdwStockSortBy.makeAscending', // Merk (oplopend)',
    value: 'sort=make&dir=ASC',
  },
  {
    label: 'rdwStockSortBy.makeDescending', // Merk (aflopend)',
    value: 'sort=make&dir=DESC',
  },
  {
    label: 'rdwStockSortBy.modelAscending', // Model (oplopend)',
    value: 'sort=modelrdw&dir=ASC',
  },
  {
    label: 'rdwStockSortBy.modelDescending', // Model (aflopend)',
    value: 'sort=modelrdw&dir=DESC',
  },
  {
    label: 'rdwStockSortBy.part1Ascending', // Deel 1 (oplopend)',
    value: 'sort=deel1&dir=ASC',
  },
  {
    label: 'rdwStockSortBy.part1Descending', // Deel 1 (aflopend)',
    value: 'sort=deel1&dir=DESC',
  },
  {
    label: 'rdwStockSortBy.part2Ascending', // 'Deel 2 (oplopend)',
    value: 'sort=deel2&dir=ASC',
  },
  {
    label: 'rdwStockSortBy.part2Descending', // Deel 2 (aflopend)',
    value: 'sort=deel2&dir=DESC',
  },
  {
    label: 'rdwStockSortBy.standtimeAscending', // Sta (oplopend)',
    value: 'sort=sta&dir=ASC',
  },
  {
    label: 'rdwStockSortBy.standtimeDescending', // Sta (aflopend)',
    value: 'sort=sta&dir=DESC',
  },
  {
    label: 'rdwStockSortBy.ownerAscending', // Eigenaar (oplopend)',
    value: 'sort=eigenaar&dir=ASC',
  },
  {
    label: 'rdwStockSortBy.ownerDescending', // Eigenaar (aflopend)',
    value: 'sort=eigenaar&dir=DESC',
  },
]

/**
 * @param {function} t The react-i18n-next translation function
 * @returns {Array} An array of options for a material UI select field
 */
export const getMileagePerYearOptions = (t, dealerId = null) => {
  // NOTE: THIS IS TEMPORARY. IT IS ONLY USED TO GET A DIFFERENT LIST OF POSSIBLE MILEAGES PER YEAR FOR ONE CLIENT.
  if (dealerId && dealerIdsWithSpecialMileages.includes(`${dealerId}`)) {
    return [
      {
        value: 15000,
        label: t('mileagePerYearOptions', { start: 0, end: 15000 }),
      },
      {
        value: 20000,
        label: t('mileagePerYearOptions', { start: 15000, end: 20000 }),
      },
    ]
  }
  // /NOTE

  const automaticRange = {
    start: 10000,
    end: 100000,
    step: 5000,
  }
  const options = [
    // The first two options are very custom:
    {
      value: 7500,
      label: t('mileagePerYearOptions', { start: 0, end: 7500 }),
    },
    {
      value: automaticRange.start,
      label: t('mileagePerYearOptions', {
        start: 7500,
        end: automaticRange.start,
      }),
    },
  ]

  for (
    let currentStep = automaticRange.start;
    currentStep < automaticRange.end;
    currentStep += automaticRange.step
  ) {
    options.push({
      value: currentStep + automaticRange.step,
      label: t('mileagePerYearOptions', {
        start: currentStep,
        end: currentStep + automaticRange.step,
      }),
    })
  }
  return options
}

export const targetContractStatuses = {
  INCOMPLETE: 'CANCELED',
  UNSIGNED: 'CANCELED',
  SIGNED: 'CANCELED',
  ACTIVE: 'STOPPED',
}

/**
 * CSP contract statuses:
 */
export const contractStatuses = {
  ALL: 'ALL',
  ALL_EXCLUDING_CANCELED: 'ALL_EXCLUDING_CANCELED',
  ACTIVE: 'ACTIVE', // signed and is within its start-end dates range
  ACTIVE_BUT_CHANGES_PENDING: 'ACTIVE_BUT_CHANGES_PENDING', // means it is active, but some unsigned contractual changes are pending
  SIGNED: 'SIGNED', // signed, but not started yet
  INCOMPLETE: 'INCOMPLETE',
  UNSIGNED: 'UNSIGNED',
  INCOMPLETE_AND_UNSIGNED: 'INCOMPLETE_AND_UNSIGNED',
  EXPIRED: 'EXPIRED', // signed, but ended, due to being after the end-date
  STOPPED: 'STOPPED',
  CANCELED: 'CANCELED', // manually stopped
}

export const contractStatusColors = {
  ACTIVE: 'statusOk',
  SIGNED: 'statusFinalized',
  INCOMPLETE: 'statusNeutral',
  UNSIGNED: 'statusWarningLvl1',
  EXPIRED: 'statusWarningLvl2',
  STOPPED: 'statusErrorLvl1',
  CANCELED: 'statusErrorLvl2',
}

export const getContractStatusLabels = (t) => ({
  ALL: t('carServicePlanDashboard.contractStatuses.all'),
  ALL_EXCLUDING_CANCELED: t(
    'carServicePlanDashboard.contractStatuses.allExcludingCanceled',
  ),
  ACTIVE: t('carServicePlanDashboard.contractStatuses.active'),
  ACTIVE_BUT_CHANGES_PENDING: t(
    'carServicePlanDashboard.contractStatuses.activeButChanged',
  ),
  SIGNED: t('carServicePlanDashboard.contractStatuses.signed'),
  INCOMPLETE: t('carServicePlanDashboard.contractStatuses.incomplete'),
  UNSIGNED: t('carServicePlanDashboard.contractStatuses.unsigned'),
  INCOMPLETE_AND_UNSIGNED: t(
    'carServicePlanDashboard.contractStatuses.incompleteAndUnsigned',
  ),
  EXPIRED: t('carServicePlanDashboard.contractStatuses.expired'),
  STOPPED: t('carServicePlanDashboard.contractStatuses.stopped'),
  CANCELED: t('carServicePlanDashboard.contractStatuses.canceled'),
})

export const invoiceStatuses = {
  ALL: 'ALL',
  UNPAID: 'UNPAID',
  PAID: 'PAID',
  DOWNLOADED: 'DOWNLOADED',
}

export const prospects = {
  WORKSHOP: 'WORKSHOP',
  SALES: 'SALES',
}

// NOTE: because of pragmatic reasons the backed has fixed enums. We'll have to do some mapping.
// The display(!) name of the LOYAL package is "Basic"
// The display(!) name of the BASIC package is "Plus"
// The display(!) name of PLUS_24_MONTHS remains the same.
// The PLUS_36_MONTHS package will be discontinued.
export const cspPackageTypes = {
  LOYAL: 'LOYAL',
  BASIC: 'BASIC',
  PLUS_24_MONTHS: 'PLUS_24_MONTHS',
  PLUS_36_MONTHS: 'PLUS_36_MONTHS',
}

// TODO: Check with backend if these values are correct
export const cspPackageTypePeriods = {
  LOYAL: 12,
  BASIC: 12,
  PLUS_24_MONTHS: 24,
  PLUS_36_MONTHS: 36,
}

export const invoiceStatusColors = {
  UNPAID: 'statusErrorLvl2',
  PAID: 'statusWarningLvl1',
  DOWNLOADED: 'statusOk',
}

// These colors are used in ChecklistStatusBadge to indicate the state of a car for a checklist item.
export const carChecklistItemStatusColors = {
  [CarChecklistItemStatusses.pendingStart]: 'statusIrrelevant', // Used for border color and text
  [CarChecklistItemStatusses.started]: 'statusNeutral',
  [CarChecklistItemStatusses.startedSoonDelayed]: 'chartsIsLvl1Bad',
  [CarChecklistItemStatusses.delayed]: 'statusErrorLvl1', // Also used to underline items with status completedAfterDelay
  [CarChecklistItemStatusses.completed]: 'statePositive',
  [CarChecklistItemStatusses.completedAfterDelay]: 'statePositive',
}

export const bidColors = {
  verkoopprijs: 'actionsStandard',
  purchasePrice: 'stateNegative',
  indicata: 'brandAlfa',
  restwaarde: 'chartsIsLvl1Bad',
}

export const warrantyPackageTypesPeriods = {
  LOYAL: 24,
  BASIC: 24,
  PLUS_24_MONTHS: 24,
  PLUS_36_MONTHS: 36,
}

export const getInvoiceStatusLabels = (t) => ({
  ALL: t('carServicePlanAdminMonitoringOverview.invoiceStatuses.all'),
  UNPAID: t('carServicePlanAdminMonitoringOverview.invoiceStatuses.unpaid'),
  PAID: t('carServicePlanAdminMonitoringOverview.invoiceStatuses.paid'),
  DOWNLOADED: t(
    'carServicePlanAdminMonitoringOverview.invoiceStatuses.downloaded',
  ),
})

export const documentStatuses = {
  UNSIGNED: 'UNSIGNED',
  SIGNED: 'SIGNED',
  SIGNATURE_NOT_REQUIRED: 'SIGNATURE_NOT_REQUIRED',
}

export const contractTemplateTypes = {
  WARRANTY_CONTRACT: 'WARRANTY_CONTRACT',
  CLIENT_CONTRACT: 'CLIENT_CONTRACT',
  INVOICE_TEMPLATE: 'INVOICE_TEMPLATE',
  CONTRACT_ACTIVATION_MAIL: 'CONTRACT_ACTIVATION_MAIL',
  CONTRACT_CANCELLATION_MAIL: 'CONTRACT_CANCELLATION_MAIL',
  UNSIGNED_CONTRACT_MAIL: 'UNSIGNED_CONTRACT_MAIL',
  UPLOAD_DOCUMENT_CONTRACT_MAIL: 'UPLOAD_DOCUMENT_CONTRACT_MAIL',
}

export const contractContentsAvailablePlaceholders = [
  '{Customer Name}',
  '{Customer Address}',
  '{Customer Street}',
  '{Customer Street Number}',
  '{Customer Zip Code}',
  '{Customer City}',
  '{Customer Country}',
  '{Customer Email}',
  '{Customer IBAN}',
  '{Dealer Name}',
  '{Dealer Address}',
  '{Dealer City}',
  '{Dealer Zip Code}',
  '{Dealer Country}',
  '{Warranty Provider}',
  '{Warranty Program}',
  '{Warranty Details}',
  '{Subscription fee}',
  '{Date of Delivery}',
  '{Max Mileage}',
  '{Car Brand}',
  '{Car Model}',
  '{Mileage}',
  '{Mileage per Year}',
  '{Starting Date of Contract}',
  '{End Date of Contract}',
  '{First Year of Registration}',
  '{License Plate}',
  '{Period of Contract}',
  '{Elements of the Service Pack}',
  '{Dealer Car Service Plan}',
  '{Chamber of Commerce}',
  '{Date of Sale}',
  '{Mandate ID}',
  '{Contract Duration}',
  '{Pagebreak}',
]

export const contractContentsPagebreak = {
  placeholder: '{Pagebreak}',
  html: '<!--PAGEBREAK--><p style="page-break-after: always;">&nbsp;</p><p style="page-break-before: always;">&nbsp;</p><!--/PAGEBREAK-->',
}

export const transactionLogTypes = {
  ENRICHMENT: 'ENRICHMENT',
  CALCULATION: 'CALCULATION',
  CONTRACT_PROGRESS: 'CONTRACT_PROGRESS',
}

export const getTransactionLogTypeLabels = (t) => ({
  ENRICHMENT: t('carServicePlanTransactions.transactionLogTypes.enrichment'),
  CALCULATION: t('carServicePlanTransactions.transactionLogTypes.calculation'),
  CONTRACT_PROGRESS: t(
    'carServicePlanTransactions.transactionLogTypes.contractProgress',
  ),
})

export const personTitles = {
  MR: 'MR',
  MS: 'MS',
}

export const termsDocumentAllowedFileExtensions = ['.pdf', '.PDF']

/**
 * A standard way to define different types of periods and
 * communicate them across UI components.
 * This way we can extend this to non specific periods like
 * months, quarters (of a year), years etc.
 *
 * Each component needs to define for themselves how to
 * deal with these types of periods.
 */
export const PeriodTypes = {
  week: 'week',
}

export const noDataPlaceholder = '-'
