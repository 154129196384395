import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import moment from 'moment'
import LoadingButton from 'components/atoms/loading-button'
import Checklist from 'components/views/car-file/tabs/checklists/checklist'
import { CarKindEnum } from 'config/enums'

const SubmitButton = styled(LoadingButton)`
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`

// Util function
const formatChecklistItems = (checklistItems, checkedItemIds, t) =>
  checklistItems.map(
    ({
      id,
      name,
      description,
      checked,
      checkedBy,
      checkedAt,
      startedAt,
      waitFor,
      maxDuration,
    }) => {
      let tag
      if (checked && checkedBy && checkedAt) {
        const checkedAtDate = moment(checkedAt)
        tag = t('carFileChecklists.tab.checkedAtBy', {
          date: checkedAtDate.format('DD-MM-YY'),
          time: checkedAtDate.format('HH:mm'),
          name: `${checkedBy.firstName} ${checkedBy.lastName}`,
        })
      } else if (maxDuration) {
        const maxDaysText = t(maxDuration === 1 ? 'day' : 'day_plural', {
          count: maxDuration,
        })
        tag = t('carFileChecklists.tab.maxDuration', { maxDays: maxDaysText })
        if (waitFor || startedAt) {
          let additionalTagText
          if (waitFor) {
            additionalTagText = t('carFileChecklists.tab.afterFinishing', {
              item: waitFor,
            })
          } else {
            const daysLeft =
              maxDuration -
              moment.duration(moment(new Date()).diff(startedAt)).as('days')
            const timeLeftText = t(daysLeft === 1 ? 'day' : 'day_plural', {
              count: Math.max(Math.round(daysLeft), 0),
            })
            additionalTagText = t('carFileChecklists.tab.timeLeft', {
              timeLeft: timeLeftText,
            })
          }
          tag = `${tag} (${additionalTagText})`
        }
      }

      return {
        id,
        name,
        description,
        tag,
        tagIsMuted: checked,
        isChecked: checkedItemIds.includes(id),
      }
    },
  )

const ChecklistForm = ({
  name,
  types,
  checklistItems,
  onSubmit,
  isSubmitting,
}) => {
  const { t } = useTranslation()

  const [checkedItemIds, setCheckedItemIds] = React.useState(
    checklistItems.filter((item) => item.checked).map((item) => item.id),
  )
  const [formattedChecklistItems, setFormattedChecklistItems] = React.useState(
    formatChecklistItems(checklistItems, checkedItemIds, t),
  )

  React.useEffect(() => {
    setFormattedChecklistItems(
      formatChecklistItems(checklistItems, checkedItemIds, t),
    )
  }, [checklistItems, checkedItemIds, t])

  const handleSubmit = (e) => {
    e.preventDefault()
    typeof onSubmit === 'function' && onSubmit(checkedItemIds)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Checklist
        name={name}
        types={types}
        checklistItems={formattedChecklistItems}
        onItemChange={(item, isChecked) => {
          setCheckedItemIds((checkedItemIds) =>
            isChecked
              ? [...checkedItemIds, item.id]
              : checkedItemIds.filter((id) => id !== item.id),
          )
        }}
      />
      <SubmitButton
        isLoading={isSubmitting}
        type="submit"
        level="cta"
        data-test-e2e="button-submit-checklist"
      >
        {t('save')}
      </SubmitButton>
    </form>
  )
}

export const propTypes = {
  name: PropTypes.string.isRequired,
  totalProgress: PropTypes.shape({
    total: PropTypes.number,
    checked: PropTypes.number,
  }),
  types: PropTypes.arrayOf(PropTypes.oneOf(Object.values(CarKindEnum))),
  checklistItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      description: PropTypes.string,
      checked: PropTypes.bool,
      checkedBy: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
      startedAt: PropTypes.string,
      waitFor: PropTypes.string,
      checkedAt: PropTypes.string,
      maxDuration: PropTypes.number,
    }),
  ),
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
}

ChecklistForm.propTypes = propTypes

export default ChecklistForm
