import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useDispatch, useSelector } from 'react-redux'

import { InputParamsContext } from 'contexts/marketview'

import { getMarketviewData } from 'redux/actions/marketview-data'

import LoadingIndicator from 'components/atoms/loading-indicator'
import ComparisonDonutCharts from 'components/organisms/comparison-donut-charts'

import useAbortController from 'hooks/use-abort-controller'

/**
 * Component to fetch and show sales outside district data from
 * the Cartalk Marketview API
 *

 */

function SalesOutsideDistrict() {
  const inputParams = useContext(InputParamsContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const salesOutsideTerritory = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.data &&
      state.marketviewData.data.salesOutsideDistrict,
  )
  const error = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.error &&
      state.marketviewData.error.salesOutsideDistrict,
  )
  const salesOutsideDistrict =
    salesOutsideTerritory &&
    salesOutsideTerritory.salesOutsideTerritory &&
    salesOutsideTerritory.salesOutsideTerritory[0]
  const salesOutsideDistrictComparisonGroup =
    salesOutsideTerritory &&
    salesOutsideTerritory.salesOutsideTerritoryComparisonGroup &&
    salesOutsideTerritory.salesOutsideTerritoryComparisonGroup[0]
  const {
    ref: salesOutsideDistrictAbortControllerRef,
    abort: abortSalesOutsideDistrictRequest,
  } = useAbortController()

  useDeepCompareEffect(() => {
    if (!inputParams) {
      return
    }

    abortSalesOutsideDistrictRequest()

    dispatch(
      getMarketviewData(
        'salesOutsideDistrict',
        inputParams,
        salesOutsideDistrictAbortControllerRef.current.signal,
      ),
    )
  }, [dispatch, inputParams])

  if (!salesOutsideDistrict || !salesOutsideDistrictComparisonGroup) {
    return <LoadingIndicator error={error} />
  }

  return (
    <ComparisonDonutCharts
      unit={t('cars')}
      mainTitleLeft={t('mySalesOutsideDistrict')}
      legendColorLeft="actionsStandard"
      hoverColorLeft="actionsStandard"
      percentageLeft={salesOutsideDistrict.salesOutsideTerritoryPercentage}
      leftTitleLeft={t('salesOutsideDistrict')}
      leftValueLeft={salesOutsideDistrict.salesOwnOutsideTerritory}
      rightTitleLeft={t('mySales')}
      rightValueLeft={salesOutsideDistrict.salesOwn}
      mainTitleRight={t('salesOutsideDistrictComparisonGroup')}
      legendColorRight="brandAlfa"
      hoverColorRight="brandAlfa"
      percentageRight={
        salesOutsideDistrictComparisonGroup.salesOutsideTerritoryPercentage
      }
      leftTitleRight={t('salesOutsideDistrictComparisonGroup')}
      leftValueRight={
        salesOutsideDistrictComparisonGroup.salesOutsideTerritoryComparisonGroup
      }
      rightTitleRight={t('salesComparisonGroup')}
      rightValueRight={salesOutsideDistrictComparisonGroup.salesTotal}
    />
  )
}

SalesOutsideDistrict.propTypes = {}

SalesOutsideDistrict.defaultProps = {}

export default SalesOutsideDistrict
