import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import styled from 'styled-components/macro'
import { CSPSalesFlowStep1UpdateVehicle } from '../../../../config/validation-schemas'
import Button from '../../../atoms/button'
import LoadingIndicator from '../../../atoms/loading-indicator'
import Typography from '../../../molecules/typography'
import Dialog from '../../../molecules/flexible-dialog'
import { GET_PRICE_ADDITIONS_BY_LICENSE_PLATE } from 'config/graphql/csp'
import { FormikLabeledCheckBox } from 'components/molecules/formik-field'
import { useQuery } from '@apollo/client'

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`
const CancelLink = styled(Button)`
  padding: ${({ theme }) => theme.sizings.lvl3} 0;
`
const LoadingIndicatorStyled = styled(LoadingIndicator)`
  margin-top: ${({ theme }) => theme.sizings.lvl4};
`

function CspSalesUpdateAddtionalFeesDialog({ payload, handleClose, onSubmit }) {
  const { t } = useTranslation()

  const { data: priceAdditionsData } = useQuery(
    GET_PRICE_ADDITIONS_BY_LICENSE_PLATE,
    {
      variables: {
        dealerId: payload.dealerId,
        licenseplate: payload.licensePlate,
        mileagePerYear: payload.mileagePerYear,
        currentMileage: payload.currentMileage,
      },
      skip: !payload.licensePlate,
    },
  )

  const handleSubmit = (values) => {
    onSubmit(values.additionalFeeIds)
    handleClose()
  }

  const priceAddtionFees = priceAdditionsData?.priceAdditionByLicenseplate

  if (
    !priceAddtionFees ||
    (Array.isArray(priceAddtionFees) && priceAddtionFees.length === 0)
  ) {
    return null // When there are no fees, do not show the dialog at all.
  }
  return (
    <Dialog
      disableBackdropClick
      open
      closeHandler={handleClose}
      title={t('cspSalesFlow.steps.updateAdditionalFeesDialog.heading')}
      content={
        <div>
          <Typography type="ExplanationParagraph">
            {t('cspSalesFlow.steps.updateAdditionalFeesDialog.body')}
          </Typography>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              additionalFeeIds: [],
            }}
            validationSchema={CSPSalesFlowStep1UpdateVehicle(t)}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)
              try {
                handleSubmit(values)
              } catch (e) {
                console.error(e)
              }
              setSubmitting(false)
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <div role="group" aria-labelledby="additions">
                  {priceAddtionFees.map((addition) => (
                    <Field
                      key={addition.id}
                      value={addition.id}
                      name="additionalFeeIds"
                      label={addition.reason}
                      price={addition.amount}
                      wrapLabelOnLineBreak
                      filled
                      component={FormikLabeledCheckBox}
                    />
                  ))}
                </div>
                {isSubmitting && <LoadingIndicatorStyled size="small" />}
                <Controls>
                  <CancelLink level="option" onClick={handleClose}>
                    Annuleren
                  </CancelLink>
                  <Button
                    level="cta"
                    disabled={isSubmitting}
                    onClick={() => handleSubmit(values)}
                    type="submit"
                  >
                    {t(
                      'cspSalesFlow.steps.updateAdditionalFeesDialog.ctaButtonLabel',
                    )}
                  </Button>
                </Controls>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  )
}

CspSalesUpdateAddtionalFeesDialog.propTypes = {
  payload: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default CspSalesUpdateAddtionalFeesDialog
