import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Text from 'components/atoms/text'
import SettingsForm from 'components/redux-containers/settings-form-container'

function DeliveryPortalsSettings({
  deliveryPackages,
  getDeliveryPackages,
  locationId,
  ...restProps
}) {
  const { t } = useTranslation()
  const packages =
    deliveryPackages &&
    deliveryPackages[locationId] &&
    deliveryPackages[locationId].data &&
    deliveryPackages[locationId].data.rows
  const hasPackages = packages && packages.length > 0

  useEffect(() => {
    getDeliveryPackages({
      id: locationId,
    })
  }, [getDeliveryPackages, locationId])

  return (
    <>
      {!hasPackages ? (
        <Text text={t('noDeliveryPackagesYet')} type="floatingLabelLabel" />
      ) : (
        <SettingsForm formId="portalSettings" {...restProps} />
      )}
    </>
  )
}

DeliveryPortalsSettings.propTypes = {
  deliveryPackages: PropTypes.object,
  getDeliveryPackages: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
}

DeliveryPortalsSettings.defaultProps = {
  deliveryPackages: null,
}

export default DeliveryPortalsSettings
