import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/client'

import { CarFileStatusEnumLowerCase, ChecklistsShowTypes } from 'config/enums'
import { CHECKLIST_NOTIFICATIONS } from 'config/graphql/v4'

import { formatChecklistsOverlayUrl } from 'utilities/format'
import { withApolloV4Provider } from 'utilities/apollo'

import Typography from 'components/molecules/typography'
import Overlay from 'components/molecules/overlay'
import Todos from 'components/molecules/todos'
import LoadingIndicator from 'components/atoms/loading-indicator'

const Container = styled.div`
  width: 100%;
`

const StyledTodos = styled(Todos)`
  width: 100%;
`

function TodosOverlay({ closeOverlay, todos, locationId }) {
  const overlayId = 'todos'
  const { t } = useTranslation()
  const history = useHistory()

  const {
    data: checklistNotificationsData,
    loading: checklistNotificationsLoading,
    error,
  } = useQuery(CHECKLIST_NOTIFICATIONS, {
    variables: {
      locationId,
    },
  })

  const loading = todos?.loading || checklistNotificationsLoading

  const todoItems = todos?.data?.items || []
  const checklistItems =
    checklistNotificationsData?.notifications?.map((notification) => ({
      count: notification.amount,
      title: notification.title,
      onClick: () => {
        const statusValue = notification.data.find(
          (item) => item.key === 'status',
        )?.value
        const status = statusValue
          ? CarFileStatusEnumLowerCase[statusValue]
          : undefined
        const checklistId = notification.data.find(
          (item) => item.key === 'checklist_id',
        )?.value
        history.push(
          formatChecklistsOverlayUrl(locationId, status, checklistId, {
            show: ChecklistsShowTypes.ALL_EXPIRED,
          }),
          {
            referrerUrl: `${history.location.pathname}${history.location.search}`,
          },
        )
      },
    })) || []
  const count = todoItems.length + checklistItems.length

  return (
    <Overlay
      close={() => closeOverlay(overlayId)}
      count={loading ? 0 : count}
      icon="todo"
      title={t('toDos')}
    >
      {error ? (
        <LoadingIndicator error={error} />
      ) : (
        <Container>
          <Typography type="Level2Heading">{t('normalTodos')}</Typography>
          <StyledTodos
            closeOverlay={closeOverlay}
            items={todoItems}
            onTodoClick={() => closeOverlay(overlayId)}
            loading={!!todos?.loading}
          />
        </Container>
      )}
    </Overlay>
  )
}

TodosOverlay.propTypes = {
  closeOverlay: PropTypes.func.isRequired,
  todos: PropTypes.object,
  locationId: PropTypes.string,
}

TodosOverlay.defaultProps = {
  todos: null,
}

export default withApolloV4Provider(TodosOverlay)
