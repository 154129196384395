// EXTERNAL
import React from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import styled from 'styled-components/macro'
import { v4 as uuidV4 } from 'uuid'

// INTERNAL
import { formatPrice } from 'utilities/utils'
import OverflowingTextWithToolTip from 'components/molecules/overflowing-text-with-tooltip'
import Checkbox from 'components/atoms/new-checkbox'

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-items: flex-start;

  .MuiFormControlLabel-label {
    padding: 10px 0;
  }
`

const StyledSpanPrice = styled.span`
  color: ${(props) => props.theme.colors.textTonedDown};
  width: 100%;
  display: block;
`

/**
 * Labeled checkbox component wrapping
 * [Material UI's Checkbox](https://material-ui.com/components/checkboxes/)
 */
function LabeledCheckbox({
  checked,
  disabled,
  id,
  label,
  onChange,
  className,
  price,
  wrapLabelOnLineBreak,
  ...restProps
}) {
  const { 'data-test-e2e': dataTestE2e } = restProps
  return (
    <StyledFormControlLabel
      className={className}
      control={
        <Checkbox
          checked={checked}
          disabled={disabled}
          id={id || uuidV4()}
          onChange={onChange}
        />
      }
      label={
        <>
          {wrapLabelOnLineBreak ? (
            label
          ) : (
            <OverflowingTextWithToolTip text={label || ''} />
          )}
          {price && <StyledSpanPrice>{formatPrice(price)}</StyledSpanPrice>}
        </>
      }
      data-test-e2e={dataTestE2e}
    />
  )
}

LabeledCheckbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  className: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wrapLabelOnLineBreak: PropTypes.bool,
}

LabeledCheckbox.defaultProps = {
  checked: false,
  disabled: false,
  onChange: null,
  label: null,
  id: null,
  className: null,
  price: null,
  wrapLabelOnLineBreak: false,
}

export default LabeledCheckbox
