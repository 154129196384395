import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Icon from 'components/atoms/icon'
import Toggle from 'components/atoms/toggle'
import InlineBodyText from 'components/atoms/inline-body-text'

const Container = styled.div`
  align-items: center;
  display: flex;
`

const CarfileMetaDataIcon = styled(Icon)`
  margin: 0 8px 0 0;
`

const CarfileMetaDataText = styled(InlineBodyText)`
  text-transform: capitalize;
`

const CarfileMetaDataSeparator = styled(InlineBodyText)`
  margin: 0 24px;
`

function CarfileMetaData({
  className,
  created,
  createdBy,
  salesView,
  toggleSalesView,
}) {
  const createdDate = created.split(' ')[0]

  return (
    <Container className={className}>
      <CarfileMetaDataIcon color="brandCharlie" type="create" height="14" />
      <CarfileMetaDataText type="menu" color="brandCharlie">
        {createdDate}
      </CarfileMetaDataText>
      {createdBy && (
        <>
          <CarfileMetaDataSeparator type="menu" color="brandDelta">
            |
          </CarfileMetaDataSeparator>
          <CarfileMetaDataIcon color="brandCharlie" type="user" height="14" />
          <CarfileMetaDataText type="menu" color="brandCharlie">
            {createdBy}
          </CarfileMetaDataText>
        </>
      )}
      {toggleSalesView && (
        <>
          <CarfileMetaDataSeparator type="menu" color="brandDelta">
            |
          </CarfileMetaDataSeparator>
          <Toggle
            name="salesViewToggle"
            checked={salesView}
            onChange={toggleSalesView}
            title="Sales"
          />
        </>
      )}
    </Container>
  )
}

CarfileMetaData.propTypes = {
  created: PropTypes.string,
  createdBy: PropTypes.string,
  className: PropTypes.string,
  salesView: PropTypes.bool,
  toggleSalesView: PropTypes.func,
}

CarfileMetaData.defaultProps = {
  created: null,
  createdBy: null,
  className: null,
  salesView: false,
  toggleSalesView: null,
}

export default CarfileMetaData
