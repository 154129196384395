import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import MuiSwitch from '@material-ui/core/Switch'

const Label = styled.label`
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  color: ${({ $isChecked, theme }) =>
    $isChecked ? theme.colors.text : theme.colors.textTonedDown};
`
/**
 * Switch component based on MaterialUI's switch [Material UI's Switch](https://material-ui.com/api/switch/)
 * Switches toggle the state of a single setting on or off,
 * or accepts two labels and switches between two settings.
 */
function Switch({
  checked,
  className,
  disabled,
  id,
  onChange,
  labelLeft,
  valueLeft,
  labelRight,
  valueRight,
  ...restProps
}) {
  const isUsingCustomValues = !!valueRight && !!valueLeft
  const isChecked = isUsingCustomValues ? checked === valueRight : checked

  return (
    <div className={className} {...restProps}>
      {labelLeft && (
        <Label
          onClick={() => onChange(isUsingCustomValues ? valueLeft : false)}
          $active={isUsingCustomValues ? checked === valueLeft : !checked}
        >
          {labelLeft}
        </Label>
      )}
      <MuiSwitch
        checked={isChecked}
        disabled={disabled}
        id={id}
        onChange={(event) => {
          if (isUsingCustomValues) {
            onChange(isChecked ? valueLeft : valueRight)
          } else {
            onChange(event.target.checked)
          }
        }}
      />
      {labelRight && (
        <Label
          onClick={() => onChange(isUsingCustomValues ? valueRight : true)}
          $isChecked={isUsingCustomValues ? checked === valueRight : checked}
        >
          {labelRight}
        </Label>
      )}
    </div>
  )
}

Switch.propTypes = {
  // Checked can be a string (when custom values are used) or a boolean (when no custom values are used)
  checked: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.bool.isRequired,
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  valueLeft: PropTypes.string,
  valueRight: PropTypes.string,
}

Switch.defaultProps = {
  className: undefined,
  disabled: false,
  id: undefined,
  onChange: null,
  labelLeft: undefined,
  labelRight: undefined,
  valueLeft: undefined,
  valueRight: undefined,
}

export default Switch
