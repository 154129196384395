import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import Button from 'components/atoms/button'
import Link from 'components/atoms/link'

const Container = styled.div`
  & > * {
    margin-bottom: ${({ theme }) => theme.sizings.lvl2};
  }
`

function ActionsMenu({ closeMenu, items, className }) {
  return (
    <Container className={className}>
      {items.map((item, index) => (
        <Link key={index.toString()} onClick={closeMenu}>
          <Button icon={item.icon} text={item.label} to={item.link} alignLeft />
        </Link>
      ))}
    </Container>
  )
}

ActionsMenu.propTypes = {
  className: PropTypes.string,
  closeMenu: PropTypes.func,
  items: PropTypes.array,
}

ActionsMenu.defaultProps = {
  closeMenu: null,
  items: null,
}

export default ActionsMenu
