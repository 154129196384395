import { useMutation, useQuery } from '@apollo/client'
import Button from 'components/atoms/button'
import ErrorText from 'components/atoms/error-text'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Text from 'components/atoms/text'
import DocumentContainer from 'components/molecules/document-container'
import IconList from 'components/molecules/icon-list'
import SelectFileButton from 'components/molecules/select-file-button'
import Typography from 'components/molecules/typography'
import GeneralDataError from 'components/organisms/general-data-error'
import PlanChoice from 'components/organisms/plan-choice'
import { documentStatuses, warrantyPackageTypesPeriods } from 'config/data'
import {
  DELETE_DOCUMENT,
  GET_CONTRACTS,
  GET_CONTRACT_DOCUMENTS,
  UPLOAD_SIGNED_CONTRACT_DOCUMENT,
} from 'config/graphql/csp'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import toast from 'utilities/toast'

const Container = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.sizings.lvl6};
  grid-template-columns: repeat(1, 1fr);

  ${media.desktop`
    grid-template-columns: repeat(2, 1fr);
  `}
`

const MediaGrid = styled.div`
  display: grid;
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
  grid-gap: ${({ theme }) => theme.sizings.lvl2};
  grid-template-columns: repeat(1, 1fr);
  user-select: none;
  width: 100%;

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.desktop`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.tv`
    grid-template-columns: repeat(2, 1fr);
  `}
`

const StyledDocumentTitle = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
  font-weight: 400;
`

const UploadButton = styled(SelectFileButton)`
  text-align: center;
`

const StyledErrorText = styled(ErrorText)`
  display: block;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const StyledPlanChoice = styled(PlanChoice)`
  margin: 0 auto;

  ${media.desktop`
    max-width: 400px;
    box-shadow: ${({ theme }) => theme.shadows.bottom};
    margin-top: ${({ theme }) => theme.sizings.lvl2};
  `}
`

const { UNSIGNED, SIGNED, SIGNATURE_NOT_REQUIRED } = documentStatuses

const CspSalesDocuments = ({ onSubmit, payload }) => {
  const { t } = useTranslation()
  const { servicePlan } = payload
  const contractId = String(payload.id)
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const [formError, setFormError] = useState()
  const { loading, error, data } = useQuery(GET_CONTRACT_DOCUMENTS, {
    variables: {
      contractId,
    },
    fetchPolicy: 'network-only',
  })
  const refetchQueries = {
    refetchQueries: [
      {
        query: GET_CONTRACT_DOCUMENTS,
        variables: {
          contractId,
        },
        fetchPolicy: 'network-only',
      },
      { query: GET_CONTRACTS, fetchPolicy: 'network-only' },
    ],
    awaitRefetchQueries: true,
  }
  const [uploadSignedContractDocument, { loading: uploading }] = useMutation(
    UPLOAD_SIGNED_CONTRACT_DOCUMENT,
    refetchQueries,
  )
  const [deleteDocument] = useMutation(DELETE_DOCUMENT, refetchQueries)

  if (loading) {
    return <LoadingIndicator />
  }
  if (
    error ||
    !data.contract ||
    !Array.isArray(data.contract.documents) ||
    data.contract.documents.length === 0
  ) {
    console.error(error || 'No contract or documents found!')
    return (
      <>
        <Typography type="Level1Heading">
          {t('cspSalesFlow.steps.documents.headingNoClientName')}
        </Typography>
        <GeneralDataError />
      </>
    )
  }

  const lastName = data.contract.customer.lastName || ''
  let headingText
  if (data.contract.customer.isCompany && data.contract.customer.companyName) {
    headingText = t('cspSalesFlow.steps.documents.heading', {
      clientName: data.contract.customer.companyName,
    })
  } else if (data.contract.customer.title && lastName) {
    const clientName = `${t(data.contract.customer.title)} ${lastName}`
    headingText = t('cspSalesFlow.steps.documents.heading', { clientName })
  } else {
    headingText = t('cspSalesFlow.steps.documents.headingNoClientName')
  }

  // Normalize the data
  const documents = data.contract.documents.map((document) => ({
    id: document.id,
    key: document.id,
    parentDocumentId: document.documentId,
    uri: document.fullUri || '',
    status: document.status,
    useFilenameAsTitle: false,
    title: document.filename,
    documentIcon: document.filename.split('.').pop(),
    action1Label: t('download'),
    onAction1Click: () => {
      window.open(document.fullUri, '_blank')
    },
  }))

  const termsDocuments = documents.filter(
    (document) => document.status === SIGNATURE_NOT_REQUIRED,
  )
  const unsignedDocuments = documents.filter(
    (document) => document.status === UNSIGNED,
  )
  const signedDocuments = documents
    .filter((document) => document.status === SIGNED)
    .map((signedDocument) => {
      const selected = selectedDocuments.includes(signedDocument.id)
      return {
        selected,
        onSelect: () => {
          if (selected) {
            setSelectedDocuments(
              selectedDocuments.filter(
                (selectedDocument) => selectedDocument !== signedDocument.id,
              ),
            )
          } else {
            setSelectedDocuments([...selectedDocuments, signedDocument.id])
          }
        },
        action2label: t('delete'),
        onAction2Click: selected
          ? () => {
              const deletion = deleteDocument({
                variables: {
                  id: signedDocument.id,
                },
              })

              deletion
                .then(() => {
                  toast.success(
                    t('cspSalesFlow.steps.documents.documentDeleted'),
                  )
                })
                .catch(() => {
                  toast.error(
                    t('cspSalesFlow.steps.documents.documentDeletionError'),
                  )
                })
            }
          : null,
        ...signedDocument,
      }
    })

  const handleUpload = (file, unsignedDocument) => {
    const upload = uploadSignedContractDocument({
      variables: {
        documentId: unsignedDocument.id,
        file,
      },
      context: {
        hasUploads: true,
      },
    })

    upload
      .then(() => {
        toast.success(t('cspSalesFlow.steps.documents.uploadFinished'))
      })
      .catch(() => {
        toast.error(t('cspSalesFlow.steps.documents.uploadError'))
      })
  }

  const planChoiceUsps = [
    {
      content: t(
        'cspSalesFlow.steps.warrantyPlan.packageIncludesList.completeMaintenance',
      ),
    },
  ]
  // Only show X months warranty for CSP plus:
  if (servicePlan.isServicePlanPlus) {
    planChoiceUsps.push({
      content: (
        <Trans>
          {t(
            `cspSalesFlow.steps.warrantyPlan.packageIncludesList.${warrantyPackageTypesPeriods[servicePlan.package.packageType]}monthWarranty`,
          )}
        </Trans>
      ),
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const errorMessage =
      signedDocuments.length !== unsignedDocuments.length
        ? t('cspSalesFlow.steps.documents.notEveryDocumentIsSigned')
        : null
    setFormError(errorMessage)
    if (signedDocuments.length === unsignedDocuments.length) {
      onSubmit({ status: 'SIGNED' })
    }
  }

  const submitDisabled = signedDocuments.length < unsignedDocuments.length

  return (
    <>
      <Typography type="Level1Heading">{headingText}</Typography>
      <Container>
        <form onSubmit={handleSubmit}>
          {termsDocuments.length > 0 && (
            <>
              <Typography type="Level2Heading">
                {t('cspSalesFlow.steps.documents.termsDocuments')}
              </Typography>

              <MediaGrid>
                {termsDocuments.map((termsDocument, termsDocumentIndex) => (
                  <DocumentContainer
                    {...termsDocument}
                    key={`terms-documen-${termsDocumentIndex}`}
                  />
                ))}
              </MediaGrid>
            </>
          )}
          <Typography type="Level2Heading">
            {t('cspSalesFlow.steps.documents.downloadDocumentsToSign')}
          </Typography>

          <MediaGrid>
            {unsignedDocuments.map(
              (unsignedDocument, unsignedDocumentIndex) => (
                <DocumentContainer
                  {...unsignedDocument}
                  key={`unsigned-document-${unsignedDocumentIndex}`}
                  data-test-e2e="unsigned-contract"
                />
              ),
            )}
          </MediaGrid>
          <Typography type="Level2Heading">
            {t('cspSalesFlow.steps.documents.uploadSignedDocuments')}
          </Typography>
          <MediaGrid>
            {unsignedDocuments.map((unsignedDocument) => {
              const matchingSignedDocument =
                signedDocuments.filter(
                  (signedDocument) =>
                    signedDocument.parentDocumentId === unsignedDocument.id,
                )[0] || null
              return (
                <div key={unsignedDocument.key}>
                  {matchingSignedDocument ? (
                    <DocumentContainer
                      {...matchingSignedDocument}
                      data-test-e2e="signed-contract"
                    />
                  ) : (
                    <>
                      <StyledDocumentTitle>
                        {unsignedDocument.title}
                      </StyledDocumentTitle>
                      <UploadButton
                        id={`uploadSignedContract_${unsignedDocument.id}`}
                        text={t('cspSalesFlow.steps.documents.uploadDocument')}
                        allowedFileExtensions={['.doc', '.docx', '.pdf']}
                        onChange={(formData) => {
                          handleUpload(formData.get('file'), unsignedDocument)
                        }}
                        disabled={uploading}
                        data-test-e2e="button-upload-contract"
                      />
                    </>
                  )}
                </div>
              )
            })}
          </MediaGrid>
          {formError && <StyledErrorText>{formError}</StyledErrorText>}
          <Button
            type="submit"
            level="cta"
            disabled={submitDisabled}
            data-test-e2e="finish-contract"
          >
            {t('cspSalesFlow.steps.documents.finishContract')}
          </Button>
        </form>

        <div>
          <StyledPlanChoice
            id={servicePlan.id}
            highlighted
            singular
            icon={
              servicePlan.isServicePlanPlus
                ? `oil${warrantyPackageTypesPeriods[servicePlan.package.packageType]}`
                : 'oil'
            }
            iconWidth={servicePlan.isServicePlanPlus ? '85' : '70'}
            heading={
              servicePlan.package.description || servicePlan.package.name
            }
            body={
              servicePlan.isServicePlanPlus
                ? t(
                    'cspSalesFlow.steps.warrantyPlan.completeMaintenanceAndWarranty',
                    {
                      months:
                        warrantyPackageTypesPeriods[
                          servicePlan.package.packageType
                        ],
                    },
                  )
                : t('cspSalesFlow.steps.warrantyPlan.completeMaintenance', {
                    months:
                      warrantyPackageTypesPeriods[
                        servicePlan.package.packageType
                      ],
                  })
            }
            price={servicePlan.resultGross}
            priceDiscounted={servicePlan.resultNet}
          >
            <IconList
              items={planChoiceUsps}
              columns={1}
              icon="check"
              iconColour="statePositive"
            />
          </StyledPlanChoice>
        </div>
      </Container>
    </>
  )
}

CspSalesDocuments.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    servicePlan: PropTypes.object.isRequired,
    status: PropTypes.string,
  }).isRequired,
}

export default CspSalesDocuments
