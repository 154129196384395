import { data } from 'config'

/**
 * Form structures that are not fetched from the backend but hardcoded
 * on the frontend should be defined here.
 */

const damageLocationOptions = [
  {
    label: 'rearBumper',
    value: 1,
  },
  {
    label: 'tailgate',
    value: 2,
  },
  {
    label: 'rearWindow',
    value: 3,
  },
  {
    label: 'roof',
    value: 4,
  },
  {
    label: 'wireRack',
    value: 5,
  },
  {
    label: 'lrDoor',
    value: 6,
  },
  {
    label: 'lrWindow',
    value: 7,
  },
  {
    label: 'lrScreen',
    value: 8,
  },
  {
    label: 'lfDoor',
    value: 9,
  },
  {
    label: 'lfWindow',
    value: 10,
  },
  {
    label: 'lfScreen',
    value: 11,
  },
  {
    label: 'bonnet',
    value: 12,
  },
  {
    label: 'rrDoor',
    value: 13,
  },
  {
    label: 'rrWindow',
    value: 14,
  },
  {
    label: 'rrScreen',
    value: 15,
  },
  {
    label: 'rfDoor',
    value: 16,
  },
  {
    label: 'rfWindow',
    value: 17,
  },
  {
    label: 'rfScreen',
    value: 18,
  },
  {
    label: 'frontBumper',
    value: 19,
  },
  {
    label: 'frontWindow',
    value: 20,
  },
  // "Other" has value 21, but some options were added at a later date. To be backwards compatible,
  // the value of these options should not change. Yet, the order is custom so "other" is at the end
  // with value 21.
  {
    label: 'lRearscreen',
    value: 22,
  },
  {
    label: 'rRearscreen',
    value: 23,
  },
  {
    label: 'lDoorstep',
    value: 24,
  },
  {
    label: 'rDoorstep',
    value: 25,
  },
  {
    label: 'lMirror',
    value: 26,
  },
  {
    label: 'rMirror',
    value: 27,
  },
  {
    label: 'lrRim',
    value: 28,
  },
  {
    label: 'lfRim',
    value: 29,
  },
  {
    label: 'rrRim',
    value: 30,
  },
  {
    label: 'rfRim',
    value: 31,
  },
  {
    label: 'other',
    value: 21,
  },
]

const damageTypeOptions = [
  {
    label: 'dents',
    value: 1,
  },
  {
    label: 'fracture',
    value: 2,
  },
  {
    label: 'fractures',
    value: 3,
  },
  {
    label: 'scratch',
    value: 4,
  },
  {
    label: 'scratches',
    value: 5,
  },
  {
    label: 'stoneChipping',
    value: 6,
  },
  {
    label: 'hailDamage',
    value: 7,
  },
  {
    label: 'renew',
    value: 8,
  },
  {
    label: 'rustFormation',
    value: 9,
  },
  {
    label: 'rotten',
    value: 10,
  },
  {
    label: 'clearCoatComesOff',
    value: 11,
  },
  {
    label: 'restyleDent',
    value: 12,
  },
  {
    label: 'technicalGeneral',
    value: 13,
  },
  {
    label: 'opticalGeneral',
    value: 14,
  },
]

export default {
  comments: {
    data: {
      comment: {
        fieldsets: [
          {
            maxColumns: 1,
            fields: [
              {
                name: 'auto_id',
                required: true,
                title: 'carId',
                type: 'hidden',
                variety: 'floatingLabel',
              },
              {
                name: 'message',
                placeholder: 'typeYourCommentHere',
                required: true,
                title: 'message',
                type: 'textarea',
                variety: 'floatingLabel',
              },
            ],
          },
        ],
      },
    },
  },
  damage: {
    data: {
      optical1: {
        fieldsets: [
          {
            maxColumns: 1,
            title: 'location',
            fields: [
              {
                name: 'id',
                type: 'hidden',
                title: 'id',
              },
              {
                name: 'location_id',
                options: damageLocationOptions,
                title: 'location',
                type: 'select',
              },
            ],
          },
        ],
      },
      optical2: {
        fieldsets: [
          {
            maxColumns: 1,
            title: 'location',
            fields: [
              {
                name: 'id',
                type: 'hidden',
                title: 'id',
              },
              {
                name: 'location_id',
                options: damageLocationOptions,
                required: true,
                title: 'location',
                type: 'select',
              },
            ],
          },
          {
            maxColumns: 1,
            title: 'kind',
            fields: [
              {
                name: 'type_id',
                options: damageTypeOptions,
                required: true,
                title: 'kind',
                type: 'select',
              },
            ],
          },
          {
            maxColumns: 1,
            title: 'description',
            fields: [
              {
                name: 'costs',
                required: true,
                title: 'damageCosts',
                unit: 'currency_euro',
                variety: 'floatingLabel',
              },
              {
                name: 'description',
                placeholder: 'typeYourDamageDescriptionHere',
                required: true,
                title: 'description',
                type: 'textarea',
                variety: 'floatingLabel',
              },
            ],
          },
        ],
      },
      technical: {
        fieldsets: [
          {
            maxColumns: 1,
            title: 'description',
            fields: [
              {
                name: 'id',
                type: 'hidden',
                title: 'id',
              },
              {
                name: 'location_id',
                required: true,
                type: 'hidden',
                title: 'location',
                value: 1,
              },
              {
                name: 'costs',
                required: true,
                title: 'damageCosts',
                unit: 'currency_euro',
                variety: 'floatingLabel',
              },
              {
                name: 'description',
                placeholder: 'typeYourDamageDescriptionHere',
                required: true,
                title: 'description',
                type: 'textarea',
                variety: 'floatingLabel',
              },
            ],
          },
        ],
      },
    },
  },
  support: {
    data: {
      question: {
        fieldsets: [
          {
            maxColumns: 1,
            fields: [
              {
                name: 'subject',
                required: true,
                title: 'subject',
                variety: 'floatingLabel',
              },
              {
                name: 'message',
                placeholder: 'typeYourMessageHere',
                required: true,
                title: 'message',
                type: 'textarea',
                variety: 'floatingLabel',
              },
            ],
          },
        ],
      },
    },
  },
  pawnList: {
    data: {
      requestPawnList: {
        fieldsets: [
          {
            description: 'requestPawnListDescription', // translation id
            maxColumns: 1,
            fields: [
              {
                name: 'peildatum',
                required: true,
                title: 'referenceDate',
                variety: 'floatingLabel',
                type: 'date',
              },
            ],
          },
        ],
      },
    },
  },
  topDown: {
    data: {
      salesValue: {
        fieldsets: [
          {
            title: 'expectedRecoveryCosts',
            fields: [
              {
                name: 'verkoop',
                title: 'expecetedTradeValue',
                variety: 'floatingLabel',
                required: true,
                type: 'number',
              },
            ],
          },
        ],
      },
    },
  },
  licensePlate: {
    data: {
      valuation: {
        fieldsets: [
          {
            title: 'version',
            fields: [
              {
                name: 'destination',
                title: 'destination',
                required: true,
                options: data.destinationOptions,
                type: 'select',
                variety: 'floatingLabel',
              },
              {
                name: 'merk',
                title: 'brand',
                required: true,
                variety: 'floatingLabel',
              },
              {
                name: 'modelserie',
                title: 'model',
                required: true,
                variety: 'floatingLabel',
              },
              {
                name: 'type',
                title: 'type',
                required: true,
                variety: 'floatingLabel',
              },
              {
                name: 'km_binnen',
                title: 'mileage',
                required: true,
                variety: 'floatingLabel',
              },
              {
                name: 'expected',
                title: 'expected',
                options: data.expectedOptions,
                required: true,
                type: 'select',
                variety: 'floatingLabel',
              },
            ],
          },
        ],
      },
      entry: {
        fieldsets: [
          {
            title: 'version',
            fields: [
              {
                name: 'merk',
                title: 'brand',
                required: true,
                variety: 'floatingLabel',
              },
              {
                name: 'modelserie',
                title: 'model',
                required: true,
                variety: 'floatingLabel',
              },
              {
                name: 'type',
                title: 'type',
                required: true,
                variety: 'floatingLabel',
              },
              {
                name: 'km_binnen',
                title: 'mileage',
                required: true,
                variety: 'floatingLabel',
              },
            ],
          },
        ],
      },
    },
  },
}
