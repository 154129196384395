import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import AuthLayout from 'components/layouts/auth-layout'
import Link from 'components/atoms/link'
import ResetPasswordForm from 'components/organisms/reset-password-form'
import { getObjectFromQueryString } from 'utilities/utils'

function ResetPassword({ auth, history, resetPassword }) {
  const { t } = useTranslation()
  const [feedback, setFeedback] = useState(false)
  const renderedOnce = useRef()
  const queryParams = getObjectFromQueryString(history.location.search)

  const { error, resetPasswordStatus } = auth

  const { customer, email, key, user } = queryParams

  const errorTexts = {
    unknown_error: t('unknown_error'),
  }

  useEffect(() => {
    if (!renderedOnce.current) {
      renderedOnce.current = true
    } else if (resetPasswordStatus === 'SUCCESS') {
      setFeedback(true)
    }
  }, [resetPasswordStatus, t])

  function handleSubmit(formData) {
    resetPassword({
      customer,
      email,
      key,
      password: formData.password,
      user,
    })
  }

  const errorText = errorTexts[error] || error
  const feedbackJsx = (
    <Trans i18nKey="resetPasswordSuccess">
      <Link to="/login">{{ page: 'Login' }}</Link>
    </Trans>
  )

  return (
    <AuthLayout pageTitle={t('resetPassword')}>
      <ResetPasswordForm
        error={errorText}
        feedback={feedback && feedbackJsx}
        onSubmit={handleSubmit}
      />
    </AuthLayout>
  )
}

ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
}

export default ResetPassword
