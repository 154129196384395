import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import toast from 'utilities/toast'

import { contractStatuses, warrantyPackageTypesPeriods } from 'config/data'
import { CarFileStatusEnumLowerCase, CarFileStatusEnumUpperCase } from 'config/enums'
import {
  ADD_INCOMPLETE_CONTRACT,
  ADD_UNSIGNED_CONTRACT,
  GET_CONTRACTS,
  SET_CONTRACT_UNSIGNED,
  UPDATE_CONTRACT,
} from 'config/graphql/csp'

import LoadingIndicator from 'components/atoms/loading-indicator'
import IconList from 'components/molecules/icon-list'
import Typography from 'components/molecules/typography'
import PlanChoice from 'components/organisms/plan-choice'

import { mapCspPackageTypeToCspPackageName } from '../../settings/car-service-plan/dealer-specific/main-settings'

import CspSalesChangeVehicleStatusDialog from './csp-sales-changevehiclestatusdialog'
import CspSalesCustomerDriverForm from './csp-sales-customer-driver-form'

import { getServicePlanIcon, getServicePlanIconWidth } from './csp-sales-serviceplans'

const TwoColumnGrid = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
`
const PlanContainer = styled.div`
  padding: ${({ theme }) => theme.sizings.lvl4} ${({ theme }) => theme.sizings.lvl5};
`

const addIncompleteContractRequiredFields = [
  'dealerId',
  'carId',
  'customerId',
  'activationDate',
  'packageCalculationResultId',
  'driverId' ]
const contractUnsignedRequiredFields = [...addIncompleteContractRequiredFields, 'vin' ]
const { UNSIGNED } = contractStatuses

const CspSalesCustomerData = ({
  onSubmit,
  payload,
}) => {
  const {
    servicePlan,
    customer,
    driver,
    car,
  } = payload
  const { t } = useTranslation()
  const mutationOptions = { refetchQueries: [{ query: GET_CONTRACTS } ] }
  const [addIncompleteContract,
    addIncompleteContractResult ] = useMutation(ADD_INCOMPLETE_CONTRACT, mutationOptions)
  const [setContractToUnsigned,
    setContractToUnsignedResult ] = useMutation(SET_CONTRACT_UNSIGNED, mutationOptions)
  const [addUnsignedContract,
    addUnsignedContractResult ] = useMutation(ADD_UNSIGNED_CONTRACT, mutationOptions)
  const [updateContract,
    updateContractResult ] = useMutation(UPDATE_CONTRACT, mutationOptions)
  const [localPayload, setLocalPayload ] = useState({})
  const [changeVehicleStatusDialogOpen, setChangeVehicleStatusDialogOpen ] = useState(false)

  const queriesAndMutations = [
    addIncompleteContractResult,
    setContractToUnsignedResult,
    addUnsignedContractResult,
    updateContractResult,
  ]

  if (queriesAndMutations.some((query) => query.error)) {
    toast.error(t('cspSalesFlow.errorSubmittingContract'))
  }

  if (queriesAndMutations.some((query) => query.loading)) {
    return <LoadingIndicator />
  }

  const handleSubmit = async (values) => {
    const newPayload = { ...payload, ...values }
    setLocalPayload(newPayload)

    const canMakeIncompleteContract = !addIncompleteContractRequiredFields.some(
      (key) => !Object.prototype.hasOwnProperty.call(newPayload, key),
    )
    // BASIC packages require same fields as incomplete contract for unsigned contract,
    // so we can check if the package is basic here
    const canMakeUnsignedContract = !servicePlan.isServicePlanPlus
      ? canMakeIncompleteContract
      : contractUnsignedRequiredFields.every(
        (key) => Object.prototype.hasOwnProperty.call(newPayload, key) && newPayload[key],
      )

    let response
    if (canMakeUnsignedContract) {
      if (payload.id) {
        if (payload.status && payload.status === UNSIGNED) {
          const data = await updateContract({ variables: newPayload })
          response = data.data.updateContract
        } else {
          const data = await setContractToUnsigned({ variables: newPayload })
          response = data.data.setContractToUnsigned
        }
      } else {
        const data = await addUnsignedContract({ variables: newPayload })
        response = data.data.addUnsignedContract
      }
    } else if (canMakeIncompleteContract) {
      if (payload.id) {
        const data = await updateContract({ variables: newPayload })
        response = data.data.updateContract
      } else {
        const data = await addIncompleteContract({ variables: newPayload })
        response = data.data.addIncompleteContract
      }
    }

    return onSubmit({ ...response, ...values })
  }

  const handleFormSubmit = async (values) => {
    const newLocalPayload = { ...localPayload, ...values }
    setLocalPayload(newLocalPayload)

    if (payload.carFileId &&
        payload.carFile &&
        payload.carFile.dealernr &&
        // eslint-disable-next-line eqeqeq
        payload.carFile.dealernr == payload.dealerId &&
        payload.carFile.status !== CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verkocht] &&
        payload.carFile.status !== CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.afgeleverd] &&
        !changeVehicleStatusDialogOpen
    ) {
      setChangeVehicleStatusDialogOpen(true)
    } else {
      await handleSubmit(newLocalPayload)
    }
  }

  const planChoiceUsps = [
    { content: t('cspSalesFlow.steps.warrantyPlan.packageIncludesList.completeMaintenance') },
  ]
  // Only show X months warranty for CSP plus:
  if (servicePlan.isServicePlanPlus) {
    planChoiceUsps.push(
      { content: <Trans>{t(`cspSalesFlow.steps.warrantyPlan.packageIncludesList.${warrantyPackageTypesPeriods[servicePlan.package.packageType]}monthWarranty`)}</Trans> },
    )
  }

  return (
    <TwoColumnGrid>
      <div>
        <Typography
          type="Level1Heading"
        >{t('cspSalesFlow.steps.customerData.heading')}
        </Typography>
        <Typography type="ExplanationParagraph">
          {t('cspSalesFlow.steps.customerData.body')}
        </Typography>
        <CspSalesCustomerDriverForm
          onSubmit={handleFormSubmit}
          showVehicleFields={servicePlan.isServicePlanPlus}
          initialCustomer={customer}
          initialDriver={driver}
          initialCar={car ? {
            ...car,
            licensePlate: car.licensePlate || undefined,
          } : undefined}
          primaryActionLabel={t('cspSalesFlow.steps.saveAndCloseModal.step1.makeContract')}
        />
      </div>
      <PlanContainer>
        <PlanChoice
          id={servicePlan.id}
          highlighted
          singular
          icon={getServicePlanIcon(servicePlan.package.packageType)}
          iconWidth={getServicePlanIconWidth(servicePlan.package.packageType)}
          heading={mapCspPackageTypeToCspPackageName(servicePlan.package.packageType)}
          body={servicePlan.isServicePlanPlus
            ? t('cspSalesFlow.steps.warrantyPlan.completeMaintenanceAndWarranty', { months: warrantyPackageTypesPeriods[servicePlan.package.packageType] })
            : t('cspSalesFlow.steps.warrantyPlan.completeMaintenance', { months: warrantyPackageTypesPeriods[servicePlan.package.packageType] })}
          price={servicePlan.resultGross}
          priceDiscounted={servicePlan.resultNet}
          data-test-e2e="plan-choice"
        >
          <IconList
            items={planChoiceUsps}
            columns={1}
            icon="check"
            iconColour="statePositive"
          />
        </PlanChoice>
      </PlanContainer>
      {changeVehicleStatusDialogOpen && (
        <CspSalesChangeVehicleStatusDialog
          onClose={() => setChangeVehicleStatusDialogOpen(false)}
          onSubmit={handleFormSubmit}
          carFileId={payload.carFileId ? parseInt(payload.carFileId, 10) : undefined}
          lastName={localPayload.lastName}
        />
      )}
    </TwoColumnGrid>
  )
}

CspSalesCustomerData.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    id: PropTypes.string,
    carFile: PropTypes.object,
    carFileId: PropTypes.string,
    dealerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number ]),
    servicePlan: PropTypes.object.isRequired,
    customer: PropTypes.object,
    driver: PropTypes.object,
    status: PropTypes.string,
    car: PropTypes.shape({
      carId: PropTypes.string.isRequired,
      registrationDate: PropTypes.string.isRequired,
      licensePlate: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default CspSalesCustomerData
