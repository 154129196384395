import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDealerLocationName from 'hooks/use-location-dealer-name'

import Button from 'components/atoms/button'
import CarShareUploadDialog from 'components/molecules/car-share-upload-dialog'

const CarShareUploadButton = () => {
  const { t } = useTranslation()
  const [dialogOpen, setDialogOpen] = useState(false)
  const { dealerName } = useDealerLocationName()

  const handleToggleDialogVisbility = () => {
    setDialogOpen((open) => !open)
  }

  return (
    <>
      <Button
        type="button"
        level="option"
        icon="uploadAlt"
        iconSize="lg"
        text={t('carShare.uploadButton')}
        onClick={handleToggleDialogVisbility}
      />
      <CarShareUploadDialog
        title={t('carShare.uploadDialog.title')}
        description={t('carShare.uploadDialog.description', { dealerName })}
        open={dialogOpen}
        closeHandler={handleToggleDialogVisbility}
      />
    </>
  )
}

export default CarShareUploadButton
