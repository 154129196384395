import fr from './fr'
import nl from './nl'
import en from './en'
import de from './de'

export default {
  fr,
  nl,
  de,
  en,
}
