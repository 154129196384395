import { gql } from '@apollo/client'

export const checklistItemBasicInfo = gql`
  fragment checklistItemBasicInfo on ChecklistItem {
    id
    name
    alertMessage
    triggerChecklistItemId
    blocking
    maxDuration
    reminderAfterDays
    description
    order
  }
`

export const checklistItemAdvancedInfo = gql`
  fragment checklistItemAdvancedInfo on ChecklistItem {
    notificationUsers {
      id
      voornaam
      achternaam
      email
    }
    automationTriggers {
      id
      key
      label
    }
    reminderUsers {
      id
      voornaam
      achternaam
      email
    }
    checklist {
      id
      items {
        id
        name
        order
      }
    }
  }
`
