import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import DebounceLink from 'apollo-link-debounce'

import { carServicePlanApi, v4GraphqlApi } from 'config/data'
import { JWT_STORAGEID } from 'redux/reducers/auth'

import language from 'utilities/language'
import storage from 'utilities/storage'
import toast from 'utilities/toast'

const DEFAULT_DEBOUNCE_TIMEOUT = 100

const uploadAndBatchHTTPLink = (options) =>
  ApolloLink.split(
    (operation) => operation.getContext().hasUploads,
    createUploadLink(options),
    new BatchHttpLink(options),
  )

export const CSPapolloClient = () =>
  new ApolloClient({
    link: from([
      onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
          const context = operation.getContext()
          if (!context.suppressErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
              console.error('🤖[GraphQL Car Service Plan error]:', {
                Message: message,
                Location: locations,
                Path: path,
              })
              toast.error(message)
            })
          }
        }
        if (networkError) {
          console.error(`🤖[Car Service Plan Network error]: ${networkError}`)
        }
      }),
      new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
      uploadAndBatchHTTPLink({
        uri: carServicePlanApi,
        headers: {
          Authorization: `Bearer ${storage.getValue(JWT_STORAGEID, false)}`,
          'Accept-Language': language.getLanguage(),
        },
      }),
    ]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  })

export const ApiV4ApolloClient = () =>
  new ApolloClient({
    link: from([
      onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
          const context = operation.getContext()
          if (!context.suppressErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
              console.error('🤖[GraphQL API V4 error]:', {
                Message: message,
                Location: locations,
                Path: path,
              })
              toast.error(message)
            })
          }
        }
        if (networkError) {
          console.error(`🤖[API V4 Network error]: ${networkError}`)
        }
      }),
      new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
      uploadAndBatchHTTPLink({
        uri: v4GraphqlApi,
        headers: {
          Authorization: `Bearer ${storage.getValue(JWT_STORAGEID, false)}`,
          'Accept-Language': language.getLanguage(),
        },
      }),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        CarFileChecklistSummary: {
          keyFields: ['carFileId'],
        },
        FlexflowOverviewCarResult: {
          keyFields: ['car', ['id']],
        },
      },
    }),
    connectToDevTools: true,
  })

/**
 * This Apollo client is meant to be used for setting up a graphQL connection
 * for specific dealer from outside the UCC app.
 */
export const DealerSpecificApolloClient = (apiKey) =>
  new ApolloClient({
    link: from([
      onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
          const context = operation.getContext()
          if (!context.suppressErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
              console.error('🤖[GraphQL error]:', {
                Message: message,
                Location: locations,
                Path: path,
              })
              toast.error(message)
            })
          }
        }
        if (networkError) {
          console.error(`🤖[error]: ${networkError}`)
        }
      }),
      new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
      uploadAndBatchHTTPLink({
        uri: carServicePlanApi,
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Accept-Language': language.getLanguage(),
        },
      }),
    ]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  })
