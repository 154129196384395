import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { isValid } from 'redux-form'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { clearData, deleteDamageImages } from 'redux/actions/data'
import { closeOverlay } from 'redux/actions/ui'

import { media } from 'utilities/styled'
import { translateForm } from 'utilities/form-utils'

import Overlay from 'components/molecules/overlay'
import LoadingIndicator from 'components/atoms/loading-indicator'
import ComplexForm from 'components/molecules/complex-form'
import NextPrevCloseButtons from 'components/molecules/next-prev-close-buttons'
import MediaForm from 'components/organisms/media-form'
import SegmentedFormStep from 'components/organisms/segmented-form-step'
import OpticalCarDamagePicker from 'components/molecules/optical-car-damage-picker'
import Button from 'components/atoms/button'

const ChildContainer = styled.div`
  width: 100%;

  ${media.tablet`
    width: 50%;
  `}
`

const StyledMediaForm = styled(MediaForm)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

const StyledButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ theme }) => theme.sizings.lvl1};

  ${media.tablet`
    margin-left: 
  `}
`

const StyledSegmentedFormStep = styled(SegmentedFormStep)`
  max-width: 404px;
`

function Step1({ data, onChange, onNext, onSubmit }) {
  const { t } = useTranslation()
  return (
    <StyledSegmentedFormStep
      onNext={onNext}
      onSubmit={onSubmit}
      level="cta"
      nextTab={onNext}
      next={!!onNext}
    >
      <>
        <OpticalCarDamagePicker
          selected={data.location_id || null}
          onChange={onChange}
        />
        <StyledButton
          id="Anders"
          name="Anders"
          title={t('differentOpticalCarDamage')}
          checked={data.location_id === '21'}
          onClick={() => {
            onChange('21')
            onNext()
          }}
        >
          {t('otherLocations')}
        </StyledButton>
      </>
    </StyledSegmentedFormStep>
  )
}

Step1.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
}

Step1.defaultProps = {
  onNext: null,
}

function Step2({
  data,
  onChange,
  onPrevious,
  onSubmit,
  onImagesChange,
  onImageDelete,
  images,
}) {
  const { t } = useTranslation()
  const forms = useSelector((state) => state.forms)
  const form = forms.damage.data.optical2
  const translatedForm = translateForm(form.fieldsets, t)
  const [formValues, setFormValues] = useState({})
  const isFormValid = useSelector((state) => isValid('opticalDamage')(state))

  const handleFormChange = (values) => {
    setFormValues(values)
    onChange(values)
  }

  return (
    <>
      <ComplexForm
        data={data}
        fieldsets={translatedForm}
        formId="opticalDamage"
        onChange={handleFormChange}
        onSubmit={onSubmit}
        dontSubmitOnPrevious
        enableReinitialize
      />
      <StyledMediaForm
        items={images}
        onUpload={onImagesChange}
        onDelete={onImageDelete}
        level="standard"
        type="images"
        noCustomCamera
      />
      <NextPrevCloseButtons
        disableCloseButton={!isFormValid}
        closeText={t('save')}
        onClose={() => onSubmit(formValues)}
        previousTab={onPrevious}
      />
    </>
  )
}

Step2.propTypes = {
  data: PropTypes.object.isRequired,
  images: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  onImagesChange: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
}

Step2.defaultProps = {
  images: [],
  onPrevious: null,
  onSubmit: null,
}

function OpticalDamageOverlay({ data, noSteps, onSubmit }) {
  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  const [formValues, setFormValues] = useState(data || {})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formDataList, setFormDataList] = useState([])
  const [filesList, setFilesList] = useState(
    data
      ? data.images.map((image) => ({
          id: image.id,
          src: image.url,
          thumb: image.url,
        }))
      : [],
  )
  const dispatch = useDispatch()
  const overlayId = 'opticalDamage'
  const stepText =
    !noSteps &&
    [t('step1OpticalDamageDetails'), t('step2OpticalDamageDetails')][step]
  const damages = useSelector((state) => state.data.damage)
  const damageId = data && data.id
  const damage =
    damages && damages.data && damages.data.find((dmg) => dmg.id === damageId)

  const carId = useSelector((state) => state?.data?.carfile?.data?.auto_id)

  // Make sure addOpticalDamage data is cleared whenever the
  // component gets unmounted
  useEffect(() => () => dispatch(clearData('addOpticalDamage')), [dispatch])

  function handleOpticalCarDamageChange(value) {
    setFormValues({
      location_id: value,
    })
    handleNext()
  }

  function handleChange(values) {
    setFormValues(values)
  }

  function handleImagesChange(formData) {
    setFormDataList((oldValues) => [...oldValues, formData])

    const file = formData.get('file')
    const imageSrc = URL.createObjectURL(file)
    setFilesList((oldFilesList) => [
      ...oldFilesList,
      {
        category: '',
        date: file.lastModifiedDate,
        id: file.name,
        name: file.name,
        position: oldFilesList.length + 1,
        size: file.size,
        src: imageSrc,
        thumb: imageSrc,
        type: 'image',
      },
    ])
  }

  function handleImagesDelete(names) {
    if (filesList.length < 1) {
      return false
    }

    setFilesList(filesList.filter((file) => !names.includes(file.id)))

    if (
      formDataList.length > 0 ||
      formDataList.every((formData) => !names.includes(formData.get('name')))
    ) {
      // Removes the attached images from the form.
      // If we don't do this, we visually (UI state) remove the image, but it'll still be uploaded and show in the damages overview
      setFormDataList((formDataList) =>
        formDataList.filter(
          (formData) => !names.includes(formData.get('name')),
        ),
      )

      return dispatch(
        deleteDamageImages(names, {
          auto_id: carId || damage.auto_id,
        }),
      )
    }

    return true
  }

  async function handleSubmit(values) {
    if (noSteps) {
      setIsSubmitting(true)
      if (onSubmit) {
        await onSubmit(
          {
            ...formValues,
            ...values,
            id: damageId,
          },
          formDataList,
        )
      }
      dispatch(closeOverlay(overlayId))
      return true
    }

    if (step < 1) {
      return true
    }

    if (onSubmit && step === 1) {
      setIsSubmitting(true)

      await onSubmit(
        {
          ...formValues,
          ...values,
          id: damageId,
        },
        formDataList,
      )

      setIsSubmitting(false)
      dispatch(closeOverlay(overlayId))
    }

    return true
  }

  function handlePrevious() {
    setStep(step - 1)
  }

  function handleNext() {
    setStep(step + 1)
  }

  if (noSteps) {
    return (
      <Overlay
        close={() => dispatch(closeOverlay(overlayId))}
        icon="comments"
        text={stepText || ''}
        title={t('changeOpticalDamage')}
      >
        <ChildContainer>
          <Step2
            data={formValues}
            images={filesList}
            onChange={handleChange}
            onImagesChange={handleImagesChange}
            onImageDelete={handleImagesDelete}
            onSubmit={handleSubmit}
          />
        </ChildContainer>
      </Overlay>
    )
  }

  return (
    <Overlay
      close={() => dispatch(closeOverlay(overlayId))}
      icon="comments"
      text={stepText}
      title={t('addOpticalDamage')}
    >
      <ChildContainer>
        {isSubmitting ? (
          <LoadingIndicator size="small" />
        ) : (
          <>
            {step === 0 && (
              <Step1
                data={formValues}
                onChange={handleOpticalCarDamageChange}
                onNext={handleNext}
                onSubmit={handleSubmit}
              />
            )}
            {step === 1 && (
              <Step2
                data={formValues}
                images={filesList}
                onPrevious={handlePrevious}
                onChange={handleChange}
                onImagesChange={handleImagesChange}
                onImageDelete={handleImagesDelete}
                onSubmit={handleSubmit}
              />
            )}
          </>
        )}
      </ChildContainer>
    </Overlay>
  )
}

OpticalDamageOverlay.propTypes = {
  data: PropTypes.object,
  noSteps: PropTypes.bool,
  onSubmit: PropTypes.func,
}

OpticalDamageOverlay.defaultProps = {
  data: null,
  noSteps: false,
  onSubmit: null,
}

export default OpticalDamageOverlay
