import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import debounce from 'lodash/debounce'

import { InputParamsContext } from 'contexts/marketview'

import { getMarketviewData } from 'redux/actions/marketview-data'

import { marketViewFilterIds } from 'config/data'

import {
  formatLicenseplate,
  handleChangeQuery,
  getObjectFromQueryString,
} from 'utilities/utils'
import { formatDate } from 'utilities/format'
import marketviewData from 'utilities/marktview-data'
import storage from 'utilities/storage'

import LoadingIndicator from 'components/atoms/loading-indicator'
import EnhancedTable from 'components/organisms/enhanced-table'
import Text from 'components/atoms/text'
import TextLink from 'components/atoms/text-link'
import StaytimeLabel from 'components/atoms/staytime-label'
import ContentSeparator from 'components/atoms/content-separator'
import CompareTopicsChart from 'components/organisms/compare-topics-chart'
import SegmentedRangeChart from 'components/organisms/segmented-range-chart'
import MarketviewTableFilters from 'components/molecules/marketview-table-filters'

import useMarketviewStoragePrefix from 'hooks/use-marketview-storage-prefix'
import useAbortController from 'hooks/use-abort-controller'

const DEFAULT_STAY_TIME = 60 // in days

const Title = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const InfoText = styled(Text)`
  display: block;
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const StyledTableFilters = styled(MarketviewTableFilters)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const debounceStayTimeUpdate = debounce(
  (stayTimeUpdate, targetStaytimeStorageId, newStayTime) => {
    stayTimeUpdate(newStayTime)
    storage.setPermanent(targetStaytimeStorageId, newStayTime)
  },
  200,
)

function MarketviewStayTime() {
  const history = useHistory()
  const location = useLocation()
  const inputParams = useContext(InputParamsContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const token = useSelector(({ auth }) => auth?.instanceId)
  const storagePrefix = useMarketviewStoragePrefix()
  const targetStaytimeStorageId = `${storagePrefix}${marketViewFilterIds.targetStayTime}`
  const targetstaytime =
    getObjectFromQueryString(location.search).targetstaytime ||
    storage.getValue(targetStaytimeStorageId)
  const targetStayTime = targetstaytime || DEFAULT_STAY_TIME
  const [tempTargetStayTime, setTempTargetStayTime] = useState(targetStayTime)
  const [selectedLicensePlates, setSelectedLicensePlates] = useState([])
  const [page, setPage] = useState(1)
  const resultsPerPage = 24
  const [orderBy, setOrderBy] = useState('stocktime')
  const [orderDirection, setOrderDirection] = useState('desc')
  const carsToSell = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.data &&
      state.marketviewData.data.stockSellThese &&
      (state.marketviewData.data.stockSellThese.recordSet === null
        ? []
        : state.marketviewData.data.stockSellThese.recordSet),
  )
  const stayTimeImpact = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.data &&
      state.marketviewData.data.stockSellThese &&
      state.marketviewData.data.stockSellThese.averageStayTime &&
      state.marketviewData.data.stockSellThese.averageStayTime[0] &&
      state.marketviewData.data.stockSellThese.averageStayTime[0]
        .averageStayTimeImpact,
  )
  const totalCarsToSell = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.data &&
      state.marketviewData.data.stockSellThese &&
      state.marketviewData.data.stockSellThese.totalRecords &&
      state.marketviewData.data.stockSellThese.totalRecords[0].total,
  )
  const error = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.error &&
      state.marketviewData.error.stockSellThese,
  )

  const stockStayTime = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.data &&
      state.marketviewData.data.stockStayTime,
  )

  const stockStayTimeError = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.error &&
      state.marketviewData.error.stockStayTime,
  )

  const importer = useSelector(
    ({ auth }) => !!auth?.userDetails?.marketview_isimporteur,
  )

  const {
    ref: stockStayTimeAbortControllerRef,
    abort: abortStockStayTimeRequest,
  } = useAbortController()
  const {
    ref: stockSellTheseAbortControllerRef,
    abort: abortStockSellTheseRequest,
  } = useAbortController()
  const {
    ref: stockSellTheseAllAbortControllerRef,
    abort: abortStockSellTheseAllRequest,
  } = useAbortController()
  const {
    ref: stockSellTheseAllExportAbortControllerRef,
    abort: abortStockSellTheseAllExportRequest,
  } = useAbortController()

  function updateTargetStayTime(newTarget) {
    setTempTargetStayTime(newTarget)
    debounceStayTimeUpdate(
      (newStaytime) =>
        handleChangeQuery(
          location,
          history,
          marketViewFilterIds.targetStayTime,
          newStaytime,
        ),
      targetStaytimeStorageId,
      newTarget,
    )
  }

  let columns = [
    {
      id: 'licenseplate',
      orderId: 'licenseplate',
      label: t('licensePlate'),
    },
    {
      id: 'brand',
      orderId: 'brand',
      label: t('brand'),
    },
    {
      id: 'model',
      orderId: 'model',
      label: t('model'),
    },
    {
      id: 'type',
      orderId: 'type',
      label: t('type'),
    },
    {
      id: 'yearOfBuild',
      orderId: 'buildYear',
      label: t('yearOfBuild'),
    },
    {
      id: 'in',
      exportId: 'in',
      orderId: 'stocktime',
      label: t('inStock'),
    },
    {
      id: 'stocktime',
      orderId: 'stocktime',
      label: t('staytime'),
    },
  ]

  if (importer) {
    columns = [
      {
        id: 'company',
        label: t('company'),
      },
      ...columns,
    ]
  }

  function getParams(all) {
    const selectedLicensePlatesAsParam = []
    if (selectedLicensePlates.length) {
      selectedLicensePlatesAsParam[0] = {
        key: 'selectedlicenseplates',
        value: selectedLicensePlates.join('|'),
      }
    }

    let params = [
      ...inputParams,
      ...selectedLicensePlatesAsParam,
      {
        key: 'stocktime',
        value: String(targetStayTime),
      },
      {
        key: 'orderby',
        value: orderBy,
      },
      {
        key: 'orderdirection',
        value: orderDirection,
      },
    ]

    if (all) {
      params = [
        ...params,
        {
          key: 'rowstart',
          value: '0',
        },
      ]
    } else {
      params = [
        ...params,
        {
          key: 'rows',
          value: resultsPerPage.toString(),
        },
        {
          key: 'rowstart',
          value: ((page - 1) * resultsPerPage).toString(),
        },
      ]
    }

    return params
  }

  function fetchTableData(all) {
    const fetchId = all ? 'stockSellTheseAll' : 'stockSellThese'

    if (!inputParams) {
      return Promise.resolve()
    }

    const params = getParams(all)

    let signal

    if (fetchId === 'stockSellThese') {
      abortStockSellTheseRequest()
      signal = stockSellTheseAbortControllerRef.current.signal
    }

    if (fetchId === 'stockSellTheseAll') {
      abortStockSellTheseAllRequest()
      signal = stockSellTheseAllAbortControllerRef.current.signal
    }

    return dispatch(getMarketviewData(fetchId, params, signal))
  }

  useDeepCompareEffect(() => {
    if (!inputParams) {
      return
    }

    fetchTableData()
  }, [
    dispatch,
    inputParams,
    orderBy,
    orderDirection,
    page,
    selectedLicensePlates,
    targetStayTime,
  ])

  useDeepCompareEffect(() => {
    if (!inputParams) {
      return
    }

    abortStockStayTimeRequest()

    dispatch(
      getMarketviewData(
        'stockStayTime',
        [
          ...inputParams,
          {
            key: 'targetstaytime',
            value: String(targetStayTime),
          },
        ],
        stockStayTimeAbortControllerRef.current.signal,
      ),
    )
  }, [dispatch, inputParams, page, targetStayTime])

  let yourLongStanders = null

  function handleSelect(selected) {
    const selectedCars = carsToSell.filter(
      (car, index) => selected.indexOf(index) !== -1,
    )

    setSelectedLicensePlates(selectedCars.map((car) => car.licenseplate))
  }

  function handleOrder(direction, by) {
    setOrderDirection(direction)
    setOrderBy(by)
  }

  function handleDownloadAll() {
    const params = getParams(true)

    abortStockSellTheseAllExportRequest()

    marketviewData.getMarketviewExport({
      type: 'stockSellTheseAll',
      token,
      body: params,
      queryParams: {
        source: 'standing-time',
      },
      signal: stockSellTheseAllExportAbortControllerRef.current.signal,
    })
  }

  if (!Array.isArray(carsToSell)) {
    yourLongStanders = <LoadingIndicator error={error} />
  } else {
    yourLongStanders = (
      <>
        <Title text={t('yourLongStanders')} type="h2" />
        {!!carsToSell.length && (
          <>
            <InfoText
              text={t('yourLongStandersAdviceText')}
              type="floatingLabelLabel"
            />
            <InfoText
              text={t('yourLongStandersExplanation')}
              type="floatingLabelLabel"
            />
          </>
        )}
        <EnhancedTable
          columns={columns}
          rows={carsToSell.map((carToSell) => ({
            company: {
              component: <Text text={carToSell.name} />,
              data: carToSell.name,
            },
            licenseplate: {
              component: carToSell.carId ? (
                <TextLink
                  text={formatLicenseplate(carToSell.licenseplate)}
                  href={`/car/${carToSell.carId}`}
                />
              ) : (
                <Text text={formatLicenseplate(carToSell.licenseplate)} />
              ),
              data: carToSell.licenseplate,
            },
            brand: {
              component: <Text text={carToSell.make} />,
              data: carToSell.make,
            },
            model: {
              component: <Text text={carToSell.model} />,
              data: carToSell.model,
            },
            type: {
              component: <Text text={carToSell.type} />,
              data: carToSell.type,
            },
            yearOfBuild: {
              component: <Text text={carToSell.buildYear} />,
              data: carToSell.buildYear,
            },
            in: {
              component: (
                <Text
                  text={formatDate(
                    moment().subtract(carToSell.stocktime, 'days'),
                  )}
                />
              ),
              data: formatDate(moment().subtract(carToSell.stocktime, 'days')),
            },
            stocktime: {
              component: <StaytimeLabel days={carToSell.stocktime} />,
              data: carToSell.stocktime,
            },
          }))}
          onOrder={handleOrder}
          onSelect={handleSelect}
          orderBy={orderBy}
          orderDirection={orderDirection}
          selectedText={`${t('carsSelected')} | ${t('meanImpact')} ${t('day', { count: stayTimeImpact })}`}
          onChangePage={setPage}
          onDownloadAll={handleDownloadAll}
          page={page}
          resultsPerPage={resultsPerPage}
          totalResults={totalCarsToSell}
          noDataMessage={t('noCarFoundBasedOnFilters')}
          clearSelectionOnUpdate={false}
        />
      </>
    )
  }

  const toPercentage = (value) => (value ? `${value}%` : '')

  // map data into the desired structure for itemsToCompare prop of CompareTopicsChart
  const preparedData = [
    {
      label: t('myAverageStayTimeLabel'),
      extendedLabel: t('myAverageStayTime'),
      color: 'chartsMyself',
      value: stockStayTime ? Number(stockStayTime.averageStayTime) : 0,
    },
    {
      label: t('comparisonGroupLabel'),
      extendedLabel: t('averageStayTimeComparisonGroup'),
      color: 'chartsComparisonGroup',
      value: stockStayTime
        ? Number(stockStayTime.averageStayTimeComparisonGroup)
        : 0,
      additionalValue: stockStayTime
        ? toPercentage(stockStayTime.averageStayTimePercentage)
        : null,
    },
    {
      label: t('targetLabel'),
      extendedLabel: t('averageStayTimeTarget'),
      color: 'chartsTarget',
      value: Number(tempTargetStayTime),
      fieldAction: updateTargetStayTime,
      unit: t('unitDays'),
    },
  ]

  const colourSequence = [
    'chartsIsOk',
    'chartsIsOk',
    'chartsIsLvl1Bad',
    'chartsIsLvl2Bad',
    'chartsIsLvl3Bad',
  ]
  const segments =
    stockStayTime && stockStayTime.staytimeGrouped
      ? stockStayTime.staytimeGrouped.map((segment, index) => ({
          label: `${segment.days}${segment.days === '80' ? '+' : ''} ${t('unitDaysAbbreviated')}`,
          items: [
            {
              label: t('myStock'),
              color: colourSequence[index],
              value: stockStayTime.staytimeGrouped[index].quantity,
              additionalValue: toPercentage(
                stockStayTime.staytimeGrouped[index].Percentage,
              ),
            },
            {
              label: t('comparisonGroupLabelAbbrev'),
              color: 'chartsComparisonGroup',
              value:
                stockStayTime.staytimeGroupedComparisonGroup[index].quantity,
              // eslint-disable-next-line max-len
              additionalValue: toPercentage(
                stockStayTime.staytimeGroupedComparisonGroup[index].Percentage,
              ),
            },
          ],
        }))
      : null

  return (
    <>
      {stockStayTimeError ? (
        <p>{t('noData')}</p>
      ) : (
        <CompareTopicsChart
          heading={t('averageStayTimeHeading')}
          itemsToCompare={preparedData}
          unit={t('unitDays')}
        />
      )}
      <ContentSeparator />
      {segments && (
        <SegmentedRangeChart
          heading={t('standingTimesVsStockCompositionHeading')}
          segments={segments}
          unit={t('unitCars')}
        />
      )}
      <ContentSeparator />
      <StyledTableFilters />
      {yourLongStanders}
    </>
  )
}

MarketviewStayTime.propTypes = {}

export default MarketviewStayTime
