import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Overlay from 'components/molecules/overlay'
import { Field, Form, Formik } from 'formik'
import styled from 'styled-components/macro'
import {
  FormikLabeledCheckBoxGroup,
  FormikTextInput,
} from 'components/molecules/formik-field'
import { useMutation, useQuery } from '@apollo/client'
import {
  UPDATE_DEALER_CLUSTER,
  CREATE_DEALER_CLUSTER,
  DEALERS,
} from 'config/graphql/v4'
import LoadingIndicator from 'components/atoms/loading-indicator'
import LoadingButton from 'components/atoms/loading-button'
import toast from 'utilities/toast'
import { ClusterForm } from 'config/validation-schemas'

const StyledForm = styled(Form)`
  width: 100%; // to provide space for the checkboxes
`
const StyledLoadingButton = styled(LoadingButton)`
  margin-top: ${({ theme }) => theme.sizeByFactor(3)};
`

const maxColumnWidthFactor = 60
const FieldNormalWidth = styled(Field)`
  max-width: ${({ theme }) => theme.sizeByFactor(maxColumnWidthFactor)};
`

const StyledFormikLabeledCheckBoxGroup = styled(FormikLabeledCheckBoxGroup)`
  margin-top: ${({ theme }) => theme.sizings.lvl3};
  width: calc(100% - ${({ theme }) => theme.sizings.lvl3});

  > legend {
    text-align: initial; // prevents parent elements from overruling this styling
  }

  > .checkboxes {
    display: grid;
    // auto generate the number of columns needed based on number of items, within the
    // horizontal space available.
    grid-template-columns: repeat(
      auto-fill,
      ${({ theme }) => theme.sizeByFactor(30)}
    );
    row-gap: ${({ theme }) => theme.sizings.lvl2};
    grid-auto-rows: min-content; // make sure the rows' height it the minimum required.
  }
`

const ClusterFormOverlay = ({
  onClose,
  dealerNrsWithChecklistAccess,
  cluster,
  refetchQueries,
  ...restProps
}) => {
  const { t } = useTranslation()

  const [createDealerCluster, { loading: createDealerClusterIsSubmitting }] =
    useMutation(CREATE_DEALER_CLUSTER, {
      refetchQueries,
      awaitRefetchQueries: true,
    })
  const [updateDealerCluster, { loading: updateDealerClusterIsSubmitting }] =
    useMutation(UPDATE_DEALER_CLUSTER, {
      refetchQueries,
      awaitRefetchQueries: true,
    })
  const {
    data: dealersData,
    loading: dealersLoading,
    error: dealersError,
  } = useQuery(DEALERS)
  // Not all dealers need to be shown:
  const dealerWithChecklistsAccess = Array.isArray(dealersData?.bedrijven)
    ? [
        ...dealersData.bedrijven
          .filter((dealer) =>
            dealerNrsWithChecklistAccess.includes(dealer.dealernr),
          )
          .map((dealer) => ({
            label: dealer.name,
            value: dealer.id,
          })),
      ]
    : []

  const onFormSubmit = async (values, { resetForm }) => {
    const { name, dealers } = values
    try {
      if (cluster?.id) {
        await updateDealerCluster({
          variables: {
            id: cluster.id,
            name,
            dealers: {
              sync: dealers,
            },
          },
        })
        toast.success(
          t('checklistsSettings.clusterOverlay.clusterModified', { name }),
        )
      } else {
        await createDealerCluster({
          variables: {
            name,
            dealers: {
              sync: dealers,
            },
          },
        })
        toast.success(
          t('checklistsSettings.clusterOverlay.clusterAdded', { name }),
        )
      }
      resetForm()
      onClose()
    } catch (error) {
      toast.error(error)
    }
  }

  const formIsSubmitting =
    updateDealerClusterIsSubmitting || createDealerClusterIsSubmitting

  return (
    <Overlay
      title={
        cluster?.name
          ? t('checklistsSettings.clusterOverlay.titleModify', {
              name: cluster.name,
            })
          : t('checklistsSettings.clusterOverlay.titleAdd')
      }
      icon="cluster"
      close={() => {
        typeof onClose === 'function' && onClose()
      }}
      {...restProps}
    >
      {dealersLoading || dealersError ? (
        <LoadingIndicator error={dealersError} />
      ) : (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            name: cluster ? cluster.name : '',
            dealers: cluster
              ? cluster.companies.map((dealer) => dealer.id)
              : [],
          }}
          validationSchema={ClusterForm(t)}
          enableReinitialize
          onSubmit={onFormSubmit}
        >
          {({ handleSubmit }) => (
            <StyledForm>
              <FieldNormalWidth
                name="name"
                label={t(
                  'checklistsSettings.clusterForm.fields.nameCluster.label',
                )}
                component={FormikTextInput}
                filled
                required
                disabled={formIsSubmitting}
              />
              <Field
                label={t(
                  'checklistsSettings.clusterForm.fields.dealersInCluster.label',
                )}
                name="dealers"
                items={dealerWithChecklistsAccess}
                component={StyledFormikLabeledCheckBoxGroup}
                required
                disabled={formIsSubmitting}
              />
              <StyledLoadingButton
                level="cta"
                isLoading={formIsSubmitting}
                onClick={handleSubmit}
              >
                {cluster
                  ? t('checklistsSettings.clusterForm.buttonModify.label')
                  : t('checklistsSettings.clusterForm.buttonAdd.label')}
              </StyledLoadingButton>
            </StyledForm>
          )}
        </Formik>
      )}
    </Overlay>
  )
}

ClusterFormOverlay.propTypes = {
  onClose: PropTypes.func.isRequired,
  dealerNrsWithChecklistAccess: PropTypes.arrayOf(PropTypes.string).isRequired,
  cluster: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        dealernr: PropTypes.string.isRequired,
      }),
    ),
  }),
  refetchQueries: PropTypes.array.isRequired,
}

ClusterFormOverlay.defaultProps = {
  cluster: null,
}

export default ClusterFormOverlay
