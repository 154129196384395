import { gql } from '@apollo/client'

export const GET_PRICE_MATRICES = gql`
  query GetPriceMatrices($carServicePlanId: ID!) {
    priceMatrices(carServicePlanId: $carServicePlanId, first: 9000) {
      edges {
        node {
          id
          name
          maxMileagePerYear
        }
      }
    }
  }
`

export const GET_PRICE_MATRIX = gql`
  query GetPriceMatrix($id: ID!) {
    priceMatrix(id: $id) {
      id
      name
      carServicePlan {
        id
      }
      priceMatrixRules {
        id
        brand {
          id
        }
        model {
          id
        }
        ageFrom
        ageTo
        mileageFrom
        mileageTo
        kwFrom
        kwTo
        fuelType
        pricePerKm
      }
      maxMileagePerYear
    }
  }
`

export const CREATE_PRICE_MATRIX = gql`
  mutation CreatePriceMatrix(
    $name: String!
    $carServicePlanId: ID!
    $maxMileagePerYear: Int
  ) {
    createPriceMatrix(
      name: $name
      carServicePlanId: $carServicePlanId
      maxMileagePerYear: $maxMileagePerYear
    ) {
      id
    }
  }
`

export const UPDATE_PRICE_MATRIX = gql`
  mutation UpdatePriceMatrix(
    $id: ID!
    $name: String!
    $maxMileagePerYear: Int
  ) {
    updatePriceMatrix(
      id: $id
      name: $name
      maxMileagePerYear: $maxMileagePerYear
    ) {
      id
    }
  }
`

export const DELETE_PRICE_MATRIX = gql`
  mutation DeletePriceMatrix($id: ID!) {
    deletePriceMatrix(id: $id) {
      id
    }
  }
`

export const CREATE_PRICE_MATRIX_RULE = gql`
  mutation CreatePriceMatrixRule(
    $priceMatrixId: ID!
    $brandId: ID
    $modelId: ID
    $ageFrom: Int
    $ageTo: Int
    $mileageFrom: Int
    $mileageTo: Int
    $kwFrom: Int
    $kwTo: Int
    $fuelType: String
    $pricePerKm: Float
  ) {
    createPriceMatrixRule(
      priceMatrixId: $priceMatrixId
      brandId: $brandId
      modelId: $modelId
      ageFrom: $ageFrom
      ageTo: $ageTo
      mileageFrom: $mileageFrom
      mileageTo: $mileageTo
      kwFrom: $kwFrom
      kwTo: $kwTo
      fuelType: $fuelType
      pricePerKm: $pricePerKm
    ) {
      id
    }
  }
`

export const UPDATE_PRICE_MATRIX_RULE = gql`
  mutation UpdatePriceMatrixRule(
    $id: ID!
    $brandId: ID
    $modelId: ID
    $ageFrom: Int
    $ageTo: Int
    $mileageFrom: Int
    $mileageTo: Int
    $kwFrom: Int
    $kwTo: Int
    $fuelType: String
    $pricePerKm: Float
  ) {
    updatePriceMatrixRule(
      id: $id
      brandId: $brandId
      modelId: $modelId
      ageFrom: $ageFrom
      ageTo: $ageTo
      mileageFrom: $mileageFrom
      mileageTo: $mileageTo
      kwFrom: $kwFrom
      kwTo: $kwTo
      fuelType: $fuelType
      pricePerKm: $pricePerKm
    ) {
      id
    }
  }
`

export const DELETE_PRICE_MATRIX_RULE = gql`
  mutation DeletePriceMatrixRule($id: ID!) {
    deletePriceMatrixRule(id: $id) {
      id
    }
  }
`
