import { useSelector } from 'react-redux'

import { CountryCodes } from 'config/enums'

const useDealerLocationCountryCode = () => {
  const dealers = useSelector((state) => state.auth?.userDetails?.dealers)
  const locationId = useSelector((state) => state.auth?.userDetails?.location)
  const locationCountryCode = dealers.find(
    (dealer) => dealer.id === locationId,
  )?.country_code

  const locationCountryCodeIsNl = locationCountryCode === CountryCodes.NL
  const locationCountryCodeIsBe = locationCountryCode === CountryCodes.BE
  const locationCountryCodeIsFr = locationCountryCode === CountryCodes.FR
  const locationCountryCodeIsDe = locationCountryCode === CountryCodes.DE

  return {
    locationCountryCode,
    locationCountryCodeIsNl,
    locationCountryCodeIsBe,
    locationCountryCodeIsFr,
    locationCountryCodeIsDe,
  }
}

export default useDealerLocationCountryCode
