import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import styled from 'styled-components/macro'

import { CarFileStatusEnumUpperCase } from 'config/enums'
import {
  CHECKLISTS_FOR_DEALER,
  DELETE_CHECKLIST,
  DELETE_CHECKLIST_ITEM,
  SET_CHECKLIST_ITEM_ORDER,
} from 'config/graphql/v4'

import { withApolloV4Provider } from 'utilities/apollo'
import toast from 'utilities/toast'

import ActionIconButton from 'components/atoms/action-icon-button'
import Button from 'components/atoms/button'
import Icon from 'components/atoms/icon'
import LoadingIndicator from 'components/atoms/loading-indicator'
import NewTooltip from 'components/atoms/new-tooltip'
import Text from 'components/atoms/text'
import Labels from 'components/molecules/labels'
import Typography from 'components/molecules/typography'
import ChecklistItemOverlay from 'components/views/settings/checklists/checklist-item-overlay'
import ChecklistOverlay from 'components/views/settings/checklists/checklist-overlay'
import ChecklistTable from 'components/views/settings/checklists/checklist-table'

const Header = styled.header`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

const NoChecklistsFound = styled(Typography)`
  margin-bottom: 0;
`

const DropdownIconWrapper = styled.div`
  flex-shrink: 0;
  align-self: center;
  cursor: pointer;
  padding-right: ${({ theme }) => theme.sizings.lvl0};
`

const DropdownIcon = styled(Icon)`
  transform: ${({ $isExpanded }) => `rotate(${$isExpanded ? '180' : '0'}deg)`};
`

const StyledTypography = styled(Typography)`
  margin-top: 0;
  margin-bottom: 0;
`

const Name = styled(Text)`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.sizings.lvl2};
`

const ChecklistsContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizings.lvl3};
`

const ChecklistRow = styled.div`
  & + & {
    margin-top: ${({ theme }) => theme.sizings.lvl3};
  }
`

const ChecklistHeader = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > * {
    flex-shrink: 0;
  }
`

const ActionsContainer = styled.div`
  margin-left: ${({ theme }) => theme.sizings.lvl2};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.sizings.lvl1};
`

const StyledChecklistTable = styled(ChecklistTable)`
  margin: ${({ theme }) => theme.sizings.lvl2} 0;
`

const PositionedNewTooltip = styled(NewTooltip)`
  vertical-align: middle;
`

/**
 * Wrapper component that provides checklist based on a
 * locationId.
 */
const ForLocationWrapper = ({ locationId, status }) => {
  const { t } = useTranslation()

  const { data, loading, error } = useQuery(CHECKLISTS_FOR_DEALER, {
    variables: {
      locationId,
      status,
    },
  })

  const refetchQueries = [
    {
      query: CHECKLISTS_FOR_DEALER,
      variables: {
        locationId,
        status,
      },
    },
  ]

  return loading || error ? (
    <LoadingIndicator error={error ? t('genericError') : undefined} />
  ) : (
    <StatusChecklistsSettings
      locationId={locationId}
      status={status}
      checklists={data?.checklists || []}
      refetchQueries={refetchQueries}
    />
  )
}

ForLocationWrapper.propTypes = {
  status: PropTypes.oneOf(Object.values(CarFileStatusEnumUpperCase)).isRequired,
  // This property will probably be passed by the LocationSettings component.
  locationId: PropTypes.string.isRequired,
}

export const StatusChecklistsSettingsForLocation =
  withApolloV4Provider(ForLocationWrapper)

/**
 * Shows an overview of checklist settings for a specific status.
 */
export const StatusChecklistsSettings = ({
  locationId,
  cluster,
  checklists,
  refetchQueries,
  status,
  disableActions,
  onAfterChecklistSave,
  onAfterChecklistDelete,
  onAfterChecklistItemSave,
  onAfterChecklistItemDelete,
  onAfterChecklistItemOrderChange,
  onBeforeChecklistItemOrderChange,
}) => {
  const { t } = useTranslation()

  const [expandedChecklistId, setExpandedChecklistId] = React.useState()
  const NEW_ID = 'new'
  const [addEditChecklistId, setAddEditChecklistId] = React.useState()
  const [addEditChecklistItemData, setAddEditChecklistItemData] =
    React.useState()

  const handleExpandCollapseClick = (id) => {
    setExpandedChecklistId((expandedListId) =>
      expandedListId === id ? null : id,
    )
  }

  const [setItemOrder, { error: setItemOrderError }] = useMutation(
    SET_CHECKLIST_ITEM_ORDER,
  )

  const [
    deleteChecklist,
    {
      data: deleteChecklistData,
      loading: deletingChecklist,
      error: deleteChecklistError,
    },
  ] = useMutation(DELETE_CHECKLIST, {
    refetchQueries,
    awaitRefetchQueries: true,
  })

  const [
    deleteChecklistItem,
    {
      data: deleteChecklistItemData,
      loading: deletingChecklistItem,
      error: deleteChecklistItemError,
    },
  ] = useMutation(DELETE_CHECKLIST_ITEM, {
    refetchQueries,
    awaitRefetchQueries: true,
  })

  // Handle checklist manipulation notifications for users
  React.useEffect(() => {
    if (deleteChecklistError) {
      toast.error(t('checklistsSettings.tab.deleteChecklistError'))
    }
  }, [deleteChecklistError, t])

  React.useEffect(() => {
    if (deleteChecklistData) {
      toast.success(
        t('checklistsSettings.tab.checklistDeleted', {
          name: deleteChecklistData.deleteChecklist.name,
        }),
      )
    }
  }, [deleteChecklistData, t])

  // Handle checklist item manipulation notifications for users
  React.useEffect(() => {
    if (setItemOrderError) {
      toast.error(t('checklistsSettings.tab.orderChangeError'))
    }
  }, [setItemOrderError, t])

  React.useEffect(() => {
    if (deleteChecklistItemError) {
      toast.error(t('checklistsSettings.tab.deleteChecklistItemError'))
    }
  }, [deleteChecklistItemError, t])

  React.useEffect(() => {
    if (deleteChecklistItemData?.deleteChecklistItem) {
      const { deleteChecklistItem: item } = deleteChecklistItemData
      toast.success(
        t('checklistsSettings.tab.checklistItemDeleted', {
          name: item.name,
          checklistName: item.checklist.name,
        }),
      )
    }
  }, [deleteChecklistItemData, t])

  const handleItemOrderChange = (checklistId, checklistItemId, isDown) => {
    const checklistItemIds = checklists
      .find((checklist) => checklist.id === checklistId)
      .items.slice() // Make a copy to make it mutable
      .sort((a, b) => {
        const orderA = typeof a.order === 'number' ? a.order : 9999
        const orderB = typeof b.order === 'number' ? b.order : 9999
        return orderA - orderB
      })
      .map((item) => item.id)

    const i = checklistItemIds.indexOf(checklistItemId)
    const k = isDown ? i + 1 : i - 1

    const temp = checklistItemIds[k]
    checklistItemIds[k] = checklistItemIds[i]
    checklistItemIds[i] = temp

    typeof onBeforeChecklistItemOrderChange === 'function' &&
      onBeforeChecklistItemOrderChange({
        checklistId,
        checklistItemIds,
      })

    setItemOrder({
      variables: {
        checklistId,
        checklistItemIds,
      },
      optimisticResponse: {
        // This immediately updates the cache, so the UI stays responsive.
        setChecklistItemOrder: {
          id: checklistId,
          __typename: 'Checklist',
          items: checklistItemIds.map((id, i) => ({
            id,
            order: i + 1,
            __typename: 'ChecklistItem',
          })),
        },
      },
    }).then((data) => {
      typeof onAfterChecklistItemOrderChange === 'function' &&
        onAfterChecklistItemOrderChange({
          checklistId,
          checklistItemIds,
          data,
        })
    })
  }

  // Handlers for checklist
  const handleAddEditChecklist = (checklistId) => {
    setAddEditChecklistId(checklistId)
  }

  const handleDeleteChecklist = (checklistId) => {
    if (window.confirm(t('checklistsSettings.tab.confirmDeleteChecklist'))) {
      deleteChecklist({
        variables: {
          id: checklistId,
        },
      }).then((data) => {
        typeof onAfterChecklistDelete === 'function' &&
          onAfterChecklistDelete(data)
      })
    }
  }

  // Handlers for checklist items

  const handleAddEditChecklistItem = (
    checklistId,
    checklistItemId,
    index,
    isPartOfACluster,
  ) => {
    setAddEditChecklistItemData({
      isPartOfACluster,
      checklistId,
      checklistItemId,
      insertAtIndex: index,
    })
  }

  const handleDeleteChecklistItem = (checklistItemId) => {
    if (
      window.confirm(t('checklistsSettings.tab.confirmDeleteChecklistItem'))
    ) {
      deleteChecklistItem({
        variables: {
          id: checklistItemId,
        },
      }).then((data) => {
        typeof onAfterChecklistItemDelete === 'function' &&
          onAfterChecklistItemDelete(data)
      })
    }
  }

  return (
    <>
      <Header>
        <StyledTypography type="Level2Heading">
          {t('checklistsSettings.tab.composeChecklists')}
        </StyledTypography>
        <Button
          level="option"
          onClick={() => {
            handleAddEditChecklist(NEW_ID)
          }}
          data-test-e2e="button-add-checklist"
        >
          + {t('checklistsSettings.tab.addChecklist')}
        </Button>
      </Header>
      {checklists.length > 0 ? (
        <ChecklistsContainer data-test-e2e="checklists-container">
          {checklists.map(({ id, name, soort, items, parentId }) => {
            const itemsOrdered = items
              .slice() // Make a copy to make it mutable
              .sort((a, b) => {
                const orderA = typeof a.order === 'number' ? a.order : 9999
                const orderB = typeof b.order === 'number' ? b.order : 9999
                return orderA - orderB
              })
            const isPartOfACluster = !!(typeof parentId === 'string' || cluster)

            return (
              <ChecklistRow
                key={id}
                $isExpanded={expandedChecklistId === id}
                data-test-e2e="checklist"
              >
                <ChecklistHeader>
                  <DropdownIconWrapper
                    onClick={(e) => {
                      e.preventDefault()
                      handleExpandCollapseClick(id)
                    }}
                  >
                    <DropdownIcon
                      size="md"
                      type="arrowDropDown"
                      $isExpanded={expandedChecklistId === id}
                    />
                  </DropdownIconWrapper>
                  <Name
                    type="tabSmall"
                    data-test-e2e="checklist-name"
                    onClick={(e) => {
                      e.preventDefault()
                      handleExpandCollapseClick(id)
                    }}
                  >
                    {name}
                  </Name>
                  <Labels
                    texts={soort.map((soort) => t(`carKinds.${soort.soort}`))}
                    includeWrapper={false}
                    gapLevel={0}
                  />
                  <ActionsContainer>
                    {(locationId && parentId === null) || cluster !== null ? (
                      <>
                        <ActionIconButton
                          icon="edit"
                          width="20"
                          height="20"
                          onClick={(e) => {
                            e.preventDefault()
                            handleAddEditChecklist(id, !!parentId)
                          }}
                          disabled={
                            disableActions ||
                            deletingChecklist ||
                            deletingChecklistItem
                          }
                        />
                        <ActionIconButton
                          icon="delete"
                          width="20"
                          height="20"
                          onClick={(e) => {
                            e.preventDefault()
                            handleDeleteChecklist(id)
                          }}
                          disabled={
                            disableActions ||
                            deletingChecklist ||
                            deletingChecklistItem
                          }
                        />
                      </>
                    ) : (
                      <PositionedNewTooltip
                        title={t(
                          'checklistsSettings.tab.checklistParentIsAClusterHelpText',
                        )}
                      >
                        <Icon size="md" type="info" color="actionsStandard" />
                      </PositionedNewTooltip>
                    )}
                  </ActionsContainer>
                </ChecklistHeader>
                {expandedChecklistId === id && (
                  <>
                    <StyledChecklistTable
                      items={itemsOrdered}
                      onOrderChange={(itemId, isDown) => {
                        handleItemOrderChange(id, itemId, isDown)
                      }}
                      onItemEditClick={(itemId) => {
                        handleAddEditChecklistItem(
                          id,
                          itemId,
                          undefined,
                          isPartOfACluster,
                        )
                      }}
                      onItemAddAtIndexClick={(index) => {
                        handleAddEditChecklistItem(id, NEW_ID, index)
                      }}
                      onItemDeleteClick={(itemId) => {
                        handleDeleteChecklistItem(itemId)
                      }}
                      noDataMessage={
                        parentId
                          ? t(
                              'checklistsSettings.tab.noClusterChecklistItemsFound',
                            )
                          : t('checklistsSettings.tab.noChecklistItemsFound')
                      }
                      data-test-e2e="checklist-table"
                      disableActions={
                        disableActions ||
                        deletingChecklist ||
                        deletingChecklistItem
                      }
                    />
                    {!parentId && (
                      <Button
                        level="option"
                        noPadding
                        data-test-e2e="link-add-checklist-item"
                        onClick={() => {
                          setAddEditChecklistItemData({
                            isPartOfACluster,
                            checklistId: id,
                            checklistItemId: NEW_ID,
                          })
                        }}
                      >
                        + {t('checklistsSettings.tab.addChecklistItem')}
                      </Button>
                    )}
                  </>
                )}
              </ChecklistRow>
            )
          })}
        </ChecklistsContainer>
      ) : (
        <NoChecklistsFound type="BodyParagraph">
          {t('checklistsSettings.tab.noChecklistsFound')}
        </NoChecklistsFound>
      )}
      {addEditChecklistId && (
        <ChecklistOverlay
          locationId={locationId}
          cluster={cluster}
          status={status}
          checklistId={
            addEditChecklistId !== NEW_ID ? addEditChecklistId : undefined
          }
          onClose={() => {
            setAddEditChecklistId(null)
          }}
          onAfterSave={(values, data) => {
            const checklistId =
              addEditChecklistId === NEW_ID
                ? data.createChecklist.id
                : data.updateChecklist.id
            const isPartOfACluster =
              data.createChecklist?.isPartOfACluster ||
              data.updateChecklist?.isPartOfACluster
            if (values.saveAndCreateItemsClicked) {
              setAddEditChecklistItemData({
                checklistId,
                checklistItemId: NEW_ID,
                isPartOfACluster,
              })
            }
            setExpandedChecklistId(checklistId)
            setAddEditChecklistId(null)
            if (onAfterChecklistSave) {
              onAfterChecklistSave(values, data)
            }
          }}
        />
      )}

      {addEditChecklistItemData && addEditChecklistItemData.checklistId && (
        <ChecklistItemOverlay
          locationId={locationId}
          cluster={cluster}
          isPartOfACluster={addEditChecklistItemData.isPartOfACluster}
          checklistId={addEditChecklistItemData.checklistId}
          checklistItemId={
            addEditChecklistItemData.checklistItemId !== NEW_ID
              ? addEditChecklistItemData.checklistItemId
              : undefined
          }
          insertAtIndex={addEditChecklistItemData.insertAtIndex}
          onClose={() => {
            setAddEditChecklistItemData(null)
          }}
          onAfterSave={(values, data) => {
            if (!values.saveAndAddAnotherClicked) {
              setAddEditChecklistItemData(null)
            } else if (addEditChecklistItemData.insertAtIndex) {
              // The user wanted to add an item at an index, and clicked "save and add another one"
              // We need to update the index, else items will be added in reverse order
              setAddEditChecklistItemData((data) => ({
                ...data,
                insertAtIndex: data.insertAtIndex + 1,
              }))
            }
            typeof onAfterChecklistItemSave === 'function' &&
              onAfterChecklistItemSave(
                values,
                data,
                addEditChecklistItemData.checklistItemId === NEW_ID,
              )
          }}
        />
      )}
    </>
  )
}

const clusterOrLocationIdAreProvided = (
  { cluster, locationId },
  propName,
  componentName,
) => {
  const valueOfProp = cluster || locationId
  if (!valueOfProp) {
    return new Error(
      `Either cluster or locationId needs to be supplied to ${componentName}. Neither one was provided. Validation failed.`,
    )
  }
  if (
    cluster &&
    (typeof cluster !== 'object' || Array.isArray(cluster) || cluster === null)
  ) {
    return new Error(
      `Invalid value for cluster prop was supplied to ${componentName}. Validation failed.`,
    )
  }
}

StatusChecklistsSettings.propTypes = {
  status: PropTypes.oneOf(Object.values(CarFileStatusEnumUpperCase)).isRequired,
  // This property will probably be passed by the LocationSettings component.
  locationId: clusterOrLocationIdAreProvided,
  // This property will probably be passed by the ClustersOverview component.
  cluster: clusterOrLocationIdAreProvided,
  checklists: PropTypes.array.isRequired,
  refetchQueries: PropTypes.array.isRequired,
  disableActions: PropTypes.bool,
  onAfterChecklistSave: PropTypes.func,
  onAfterChecklistDelete: PropTypes.func,
  onAfterChecklistItemSave: PropTypes.func,
  onAfterChecklistItemDelete: PropTypes.func,
  onAfterChecklistItemOrderChange: PropTypes.func,
  onBeforeChecklistItemOrderChange: PropTypes.func,
}

StatusChecklistsSettings.defaultProps = {
  locationId: null,
  clusterId: null,
  cluster: null,
}

export default withApolloV4Provider(StatusChecklistsSettings)
