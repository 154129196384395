import React, { useState } from 'react'
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import { useQuery, useMutation } from '@apollo/client'
import Showdown from 'showdown'
import TurndownService from 'turndown'
import Typography from 'components/molecules/typography'
import NewTextInput from 'components/atoms/new-text-input'
import {
  GET_CONTRACT_TEMPLATES,
  CREATE_CONTRACT_TEMPLATE,
  UPDATE_CONTRACT_TEMPLATE,
} from 'config/graphql/csp'
import toast from 'utilities/toast'
import LoadingIndicator from 'components/atoms/loading-indicator'
import GeneralDataError from 'components/organisms/general-data-error'
import { contractTemplateTypes, contractContentsPagebreak } from 'config/data'
import Button from 'components/atoms/button'
import { htmlEncode, htmlDecode } from 'utilities/utils'
import InstructionsDialog from 'components/views/settings/car-service-plan/admin/contract-contents/instructions-dialog'

const { CLIENT_CONTRACT } = contractTemplateTypes

const HeaderContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `}
`

const StyledBodyParagraph = styled(Typography)`
  ${media.tablet`
    margin-bottom: 0;
  `}
`

const FooterContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`

const FormError = styled(Typography)`
  display: block;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
    width: auto;
    margin-right: ${({ theme }) => theme.sizings.lvl2};
    margin-bottom: 0;
  `}
`

const SubmitContainer = styled.div`
  width: auto;
  margin-right: 0;
  margin-left: auto;
  display: inline-flex;
  align-items: center;
`

const StyledButton = styled(Button)`
  display: inline-flex;
  margin-right: ${({ theme }) => theme.sizings.lvl2};
  margin-left: 0;

  ${media.tablet`
    order: 2;
    margin-right: 0;
    margin-left: ${({ theme }) => theme.sizings.lvl2};
  `}
`

// This functions takes the contract contents that come from the back-end (encoded HTML),
// and prepares them for use in the Markdown editor.
// The reverse of this function is prepareEditorDataForBackend,
// which is defined further down.
const prepareBackendDataForEditor = (data) => {
  // Decode the data, so we have proper HTML to work with
  const html = htmlDecode(data)

  // Add pagebreaks and replace the [placeholder tags] with {placeholder tags},
  // to avoid confusion with [markdown] tags.
  const pagebreakRegex = new RegExp(contractContentsPagebreak.html, 'gm')
  const formattedHTML = html
    .replace(pagebreakRegex, contractContentsPagebreak.placeholder)
    .replace(/\[(.*?)\]/gm, '{$1}')

  // Turn the HTML content into Markdown
  // Using Turndown for this, since their HTML to Markdown conversion is way better then Showdown's
  return new TurndownService().turndown(formattedHTML)
}

// This functions takes the contract contents that come from the editor (Markdown),
// and prepares them for use in the UPDATE_CONTRACT_TEMPLATE mutation.
// The reverse of this function is prepareBackendDataForEditor,
// which is defined before this function.
const prepareEditorDataForBackend = (data) => {
  // Turn the Markdown content into HTML
  const converter = new Showdown.Converter({ simpleLineBreaks: true })
  converter.setFlavor('original')

  /**
   * This horizontal rule is used to display
   */
  const signatureLine = `<hr
    style="border: 0 none;border-bottom: 1px solid currentColor;
    height: 1px; width: 15rem;
    margin: 4rem 0;"
  />`

  const dataWithSignatureLines = data.replace(
    // The <hr> element get translated back into '* * * '
    // when it comes back from the backend by the HTML to markdown converter.
    // Also more than 2 ellipsis character need to be checked and of course
    // more than 3 dots (3 dots represent an ellipsis).
    /(\*\s\*\s\*|…{2,100}|\.{4,100})/gi,
    signatureLine,
  )

  const html = converter.makeHtml(dataWithSignatureLines)

  // Add the pagebreaks, and replace the {placeholder tags} with [placeholder tags],
  // which the back-end expects.
  const pagebreakRegex = new RegExp(contractContentsPagebreak.placeholder, 'gm')
  const formattedHTML = html
    .replace(pagebreakRegex, contractContentsPagebreak.html)
    .replace(/\{(.*?)\}/gm, '[$1]')

  // Encode the HTML
  return htmlEncode(formattedHTML)
}

const ContractContents = () => {
  const { t } = useTranslation()
  const [contractTemplate, setContractTemplate] = useState({})
  const [showInstructionsDialog, setShowInstructionsDialog] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formError, setFormError] = useState()
  const { loading, error } = useQuery(GET_CONTRACT_TEMPLATES, {
    onCompleted: (result) => {
      if (result && result.templates && result.templates.edges) {
        const templateNode = result.templates.edges.find(
          (edge) => edge.node.type === CLIENT_CONTRACT,
        )

        if (templateNode) {
          /**
           * The ...template variable needs to contain everything that
           * comes from the back-end, since it is being used in the
           * updateContractTemplate mutation. This mutation expects the
           * exact same object that comes from this query, except for
           * the __typename property. The __typename property is only
           * here to filter it out and it is not used in the rest of
           * this file. So, ...template contains all the other props
           * from templateNode.node, except for __typename and contents.
           * Contents are prepared and then reinserted into the state.
           */
          const { __typename, contents, ...template } = templateNode.node
          setContractTemplate({
            contents: prepareBackendDataForEditor(contents),
            ...template,
          })
        }
      }
    },
  })

  const [createContractTemplate] = useMutation(CREATE_CONTRACT_TEMPLATE, {
    refetchQueries: [{ query: GET_CONTRACT_TEMPLATES }],
    awaitRefetchQueries: true,
  })
  const [updateContractTemplate] = useMutation(UPDATE_CONTRACT_TEMPLATE, {
    refetchQueries: [{ query: GET_CONTRACT_TEMPLATES }],
    awaitRefetchQueries: true,
  })

  if (loading) {
    return <LoadingIndicator error={null} />
  }
  if (error) {
    console.error(error)
    return (
      <>
        <Typography type="Level1Heading">
          {t('carServicePlanAdminContractContents.title')}
        </Typography>
        <GeneralDataError />
      </>
    )
  }

  const handleChange = (data) => {
    setContractTemplate({
      ...contractTemplate,
      contents: data,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setFormError(null)

    if (!contractTemplate.contents) {
      setFormError(
        t('fieldIsRequired', {
          fieldName: t(
            'carServicePlanAdminContractContents.contractContentsFieldName',
          ),
        }),
      )
      return
    }
    setIsSubmitting(true)

    const preparedContents = prepareEditorDataForBackend(
      contractTemplate.contents,
    )

    // Check if this is a new contract template or an existing one
    if (!contractTemplate.id) {
      // This is a new contract template
      createContractTemplate({
        variables: {
          name: t(
            'carServicePlanAdminContractContents.defaultContractTemplateName',
          ),
          type: CLIENT_CONTRACT,
          contents: preparedContents,
        },
      })
        .then((result) => {
          // Contract template was created, save the created contract template in the state...
          setContractTemplate({
            ...contractTemplate,
            id: result.data.createTemplate.id,
            name: result.data.createTemplate.name,
            type: result.data.createTemplate.type,
          })
          // ...and let the user know the contract template was created
          toast.success(
            t('formFeedback.partSaved', {
              part: t('carServicePlanAdminContractContents.title'),
            }),
          )
        })
        .catch((mutationError) => {
          // An error occured while creating the contract template, let the user know
          console.error(mutationError)
          toast.error(
            t('formFeedback.partSaveError', {
              part: t(
                'CarServicePlanAdminContractContents.title',
              ).toLowerCase(),
            }),
          )
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    } else {
      // This is an existing contract template
      updateContractTemplate({
        variables: {
          ...contractTemplate,
          contents: preparedContents,
        },
      })
        .then(() => {
          // Contract template was updated, let the user know
          toast.success(
            t('formFeedback.partSaved', {
              part: t('CarServicePlanAdminContractContents.title'),
            }),
          )
        })
        .catch((mutationError) => {
          // An error occured while updating the contract template, let the user know
          console.error(mutationError)
          toast.error(
            t('formFeedback.partSaveError', {
              part: t(
                'CarServicePlanAdminContractContents.title',
              ).toLowerCase(),
            }),
          )
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    }
  }

  const value = contractTemplate.contents || ''

  return (
    <>
      <form onSubmit={handleSubmit}>
        <HeaderContainer>
          <div>
            <Typography type="Level1Heading">
              {t('CarServicePlanAdminContractContents.title')}
            </Typography>
            <StyledBodyParagraph type="ExplanationParagraph">
              {t('carServicePlanAdminContractContents.body')}
            </StyledBodyParagraph>
            <StyledBodyParagraph type="ExplanationParagraph">
              <Button
                level="option"
                icon="info"
                iconSize="md"
                onClick={() => {
                  setShowInstructionsDialog(true)
                }}
              >
                {t('carServicePlanAdminContractContents.instructions')}
              </Button>
            </StyledBodyParagraph>
          </div>
          <SubmitContainer>
            <StyledButton
              type="submit"
              level="cta"
              text={t('save')}
              disabled={isSubmitting}
            />
            {isSubmitting && <LoadingIndicator size="small" />}
          </SubmitContainer>
        </HeaderContainer>
        <NewTextInput
          label={t('CarServicePlanAdminContractContents.title')}
          value={value}
          onChange={handleChange}
          onBlur={(event) => {
            handleChange(event.target.value)
          }}
          rows={20}
          filled
          required
          multiline
        />
        <FooterContainer>
          {formError && <FormError type="ErrorText">{formError}</FormError>}
          <SubmitContainer>
            <StyledButton
              type="submit"
              level="cta"
              text={t('save')}
              disabled={isSubmitting}
            />
            {isSubmitting && <LoadingIndicator size="small" />}
          </SubmitContainer>
        </FooterContainer>
      </form>
      {showInstructionsDialog && (
        <InstructionsDialog
          onClose={() => {
            setShowInstructionsDialog(false)
          }}
        />
      )}
    </>
  )
}

ContractContents.propTypes = {}

ContractContents.defaultProps = {}

export default ContractContents
