import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import CarServicePlanGeneralSettings from './general-settings'
import CarServicePlanDealerSiteWidget from './dealer-site-widget'
import CarServicePlanContractLayout from './contract-layout'
import Tabs from '../../../layouts/tabs'

function CarServicePlanSettings({ match }) {
  const { t } = useTranslation()
  const cspTabItems = [
    {
      label: t('carServicePlanGeneralTab.title'),
      to: `${match.url}/car-service-plan/general`,
      component: React.useCallback(() => <CarServicePlanGeneralSettings />, []),
    },
    {
      label: t('carServicePlanContractLayoutTab.title'),
      to: `${match.url}/car-service-plan/contract`,
      component: React.useCallback(() => <CarServicePlanContractLayout />, []),
    },
    {
      label: t('carServicePlanDealerSiteWidgetTab.title'),
      to: `${match.url}/car-service-plan/dealer-site-widget`,
      component: React.useCallback(
        () => <CarServicePlanDealerSiteWidget />,
        [],
      ),
    },
  ]

  return (
    <Tabs items={cspTabItems} small>
      {cspTabItems.map((tabConfig, index) => (
        <tabConfig.component key={index.toString()} />
      ))}
    </Tabs>
  )
}

CarServicePlanSettings.propTypes = {
  match: PropTypes.object.isRequired,
}

export default CarServicePlanSettings
