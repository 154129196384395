import { gql } from '@apollo/client'

export const GENERATE_CAR_ADVERTISEMENT_TEXT = gql`
  query generateText($carFileId: ID!, $language: String) {
    generateText(carfileId: $carFileId, language: $language) {
      id
      usage {
        promptTokens
        completionTokens
        totalTokens
      }
      text
    }
  }
`
