import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import Searchbox from 'components/molecules/searchbox'
import OverlaysContainer from 'components/redux-containers/overlays-container'
import ImageCarousel from 'components/molecules/image-carousel'
import Overlay from 'components/molecules/overlay'
import { useSelector } from 'react-redux'

const ImageOverlay = styled(Overlay)`
  height: 100vh;
`

const StyledImageCarousel = styled(ImageCarousel)`
  height: 100vh;
`

const SearchboxContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.brandFoxtrot};
  box-shadow: 0 16px 26px -23px rgba(0, 0, 0, 0.5);
  display: none;
  padding: 24px;
  position: absolute;
  right: ${({ theme }) => theme.widths.actionBarWidth};
  top: 0;
  width: 320px;

  ${media.desktop`
    display: block;
  `};
`

function LayoutOverlays({ carAssets, closeImageOverlay, closeSearchbox, ui }) {
  const b2bCarImages = useSelector((state) =>
    Array.isArray(state?.data?.b2bCarFileAssets?.data)
      ? state.data.b2bCarFileAssets.data.filter(({ type }) => type === 'image')
      : [],
  )
  const carImages =
    ui.imagesSetType === 'b2b-car-file'
      ? b2bCarImages
      : carAssets &&
        carAssets.data &&
        carAssets.data.filter(({ type }) => type === 'image')

  return (
    <>
      {ui.imageOverlayOpen && (
        <ImageOverlay close={closeImageOverlay}>
          {carImages && (
            <StyledImageCarousel
              images={carImages}
              initialSlide={ui.imageOverlayImageIdx}
              fullHeight
              arrows
            />
          )}
        </ImageOverlay>
      )}
      {ui.searchbox && (
        <SearchboxContainer>
          <Searchbox onSubmit={closeSearchbox} />
        </SearchboxContainer>
      )}
      {ui.overlay && <OverlaysContainer overlay={ui.overlay} />}
    </>
  )
}

LayoutOverlays.propTypes = {
  carAssets: PropTypes.object,
  closeImageOverlay: PropTypes.func.isRequired,
  closeSearchbox: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
}

LayoutOverlays.defaultProps = {
  carAssets: null,
}

export default LayoutOverlays
