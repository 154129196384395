import { gql } from '@apollo/client'

export const CAR_CUTTER_ACTIVATION_OPTIONS = gql`
  query carCutterActivationOptions {
    carCutterActivationOptions {
      id
      value
      label
    }
  }
`

export const CAR_CUTTER_SETTINGS = gql`
  query carCutterSettings($dealerId: ID!) {
    carCutterSettings(dealerId: $dealerId) {
      dealerId
      layer {
        id
        thumbnailUri
      }
      background {
        id
        type
        thumbnailUri
      }
      activation
    }
  }
`
export const UPDATE_CAR_CUTTER_SETTINGS = gql`
  mutation carCutterSettingsUpdate(
    $dealerId: ID!
    $activation: CarCutterActivationOptionEnum!
    $layerId: ID
    $backgroundId: ID
    $backgroundType: CarCutterBackgroundTypeEnum
  ) {
    carCutterSettingsUpdate(
      id: $dealerId
      activation: $activation
      layerId: $layerId
      backgroundId: $backgroundId
      backgroundType: $backgroundType
    ) {
      dealerId
      layer {
        id
        thumbnailUri
      }
      background {
        id
        type
        thumbnailUri
      }
      activation
    }
  }
`
export const CAR_CUTTER_BACKGROUNDS = gql`
  query carCutterBackgrounds {
    carCutterBackgrounds {
      id
      thumbnailUri
      type
      backgroundId
    }
  }
`

export const CAR_CUTTER_LAYERS = gql`
  query carCutterLayers {
    carCutterLayers {
      id
      thumbnailUri
    }
  }
`
export const CAR_CUTTER_UPLOAD_BACKGROUND = gql`
  mutation carCutterUploadBackground($file: Upload!) {
    carCutterUploadBackground(file: $file) {
      id
    }
  }
`

export const CAR_CUTTER_UPLOAD_LAYER = gql`
  mutation carCutterUploadLayer($file: Upload!) {
    carCutterUploadLayer(file: $file) {
      id
    }
  }
`

export const CAR_CUTTER_CONVERT = gql`
  mutation carCutterConvert($carImageId: ID!) {
    carCutterConvert(carImageId: $carImageId) {
      id
    }
  }
`
