import { connect } from 'react-redux'
import {
  getLibraryLayers,
  getCarPhotoLayers,
  updateLayersSettings,
  updateLayer,
  updateLayerWithLibrary,
  deleteLayer,
} from 'redux/actions/settings'
import CarPhotoLayersSettings from '../views/settings/car-photo-layers-settings'

const mapStateToProps = ({ settings }) => ({
  carPhotoLayers: settings.carPhotoLayers,
  libraryLayers: settings.libraryLayers,
})

const mapDispatchToProps = {
  getLibraryLayers,
  getCarPhotoLayers,
  updateLayersSettings,
  updateLayer,
  updateLayerWithLibrary,
  deleteLayer,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarPhotoLayersSettings)
