import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Text from 'components/atoms/text'

const Container = styled.div`
  align-items: center;
  display: flex;
`

const StyledInput = styled.input`
  flex: 0 0 auto;
  margin: 0 ${({ theme }) => theme.sizings.lvl2} 0 0;
  min-width: auto;
  cursor: pointer;
`

const Label = styled(Text)`
  &::first-letter {
    text-transform: capitalize;
  }
`

function RadioButton({
  checked,
  id,
  name,
  onChange,
  title,
  value,
  ...restProps
}) {
  return (
    <Container {...restProps}>
      <StyledInput
        checked={checked}
        id={id}
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <Label tag="label" htmlFor={id} type="input" text={title} />
    </Container>
  )
}

RadioButton.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
}

RadioButton.defaultProps = {
  checked: false,
  id: null,
  value: '',
}

export default RadioButton
