/*
 * Due to the filters not having a way to submit but are designed
 * to be submitted whenever they changed this filter would call
 * the update handler on on every keystroke. To overcome this
 * local state of the filter is being administrated and the update
 * to the actual filter is being debounced.
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import styled from 'styled-components'
import FilterCollapser from 'components/molecules/filter-collapser'
import TextInput from 'components/atoms/new-text-input'

const debouncedUpdate = debounce((onFilterUpdate, id, value) => {
  onFilterUpdate(id, value)
}, 1000)

const StyledTextInput = styled(TextInput)`
  margin: ${({ theme }) => theme.sizings.lvl2} 0;
`

/**
 * Filter component for generic text input.
 *

 */
function TextFilter({
  initiallyOpen,
  name,
  onChange,
  placeholder,
  title,
  type,
  unit,
  value,
}) {
  const [firstRender, setFirstRender] = useState(true)
  const [localValue, setLocalValue] = useState(value)

  function handleUpdate(val) {
    setLocalValue(val)
  }

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
    }
  }, [firstRender, setFirstRender])

  useEffect(() => {
    if (firstRender) {
      return
    }
    debouncedUpdate(onChange, name, localValue)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue])

  // Whenver value is reset to an empty string set localValue to it
  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <FilterCollapser initallyOpen={initiallyOpen} title={title}>
      <StyledTextInput
        name={name}
        onChange={handleUpdate}
        placeholder={placeholder}
        label={placeholder}
        type={type}
        unit={unit}
        value={localValue}
      />
    </FilterCollapser>
  )
}

TextFilter.propTypes = {
  initiallyOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string.isRequired,
}

TextFilter.defaultProps = {
  initiallyOpen: null,
  unit: undefined,
  type: undefined,
  value: '',
}

export default TextFilter
