import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import TextLink from 'components/atoms/text-link'
import LabeledText from 'components/atoms/labeled-text'
import LegendBoxContainer from 'components/atoms/legend-box-container'
import LoadingIndicator from 'components/atoms/loading-indicator'
import { useTranslation } from 'react-i18next'

const StyledLegendBoxContainer = styled(LegendBoxContainer)`
  display: flex;
  justify-content: space-between;
`

const Image = styled.img`
  margin: auto;
  margin-left: 0;
  width: 50%;
`

const ContentContainer = styled.div`
  display: grid;
  text-align: right;
  grid-gap: ${({ theme }) => theme.sizings.lvl1};
  grid-template-rows: repeat(2, 1fr);
  padding-left: ${({ theme }) => theme.sizings.lvl1};
`

/**
 * Legend price box it toggles between the pricecheck and the price value
 *
 * @deprecated use LegendBox instead. Has better UX.
 */

function LegendPriceBox({
  loading,
  legendColor,
  onClickLink,
  parentIsDarker,
  image,
  linkText,
  tradingPrice,
  sellPrice,
}) {
  const { t } = useTranslation()

  return (
    <StyledLegendBoxContainer
      legendColor={legendColor}
      parentIsDarker={parentIsDarker}
    >
      <Image src={image} alt={linkText} />
      <ContentContainer>
        {loading && <LoadingIndicator />}
        {!loading && tradingPrice && (
          <LabeledText
            title={t('tradingValue')}
            price
            value={
              typeof tradingPrice === 'string'
                ? tradingPrice
                : parseFloat(tradingPrice)
            }
          />
        )}
        {!loading && sellPrice && (
          <LabeledText
            title={t('sellValue')}
            price
            value={
              typeof sellPrice === 'string' ? sellPrice : parseFloat(sellPrice)
            }
          />
        )}
        {!loading && !tradingPrice && !sellPrice && (
          <TextLink onClick={onClickLink} text={linkText} />
        )}
      </ContentContainer>
    </StyledLegendBoxContainer>
  )
}

LegendPriceBox.propTypes = {
  loading: PropTypes.bool,
  /** LegendColor, one of the theme color names */
  legendColor: PropTypes.string,
  /** A click handler for clicking the link */
  onClickLink: PropTypes.func.isRequired,
  /** For enabling the dark mode if parent is darker */
  parentIsDarker: PropTypes.bool,
  /** ImageUrl text */
  image: PropTypes.string.isRequired,
  /** Label text */
  linkText: PropTypes.string.isRequired,
  /** Price value of data */
  tradingPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sellPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

LegendPriceBox.defaultProps = {
  loading: false,
  legendColor: undefined,
  parentIsDarker: false,
  sellPrice: undefined,
  tradingPrice: undefined,
}

export default LegendPriceBox
