import { connect } from 'react-redux'
import {
  getLocations,
  getRdwStockResults,
  updateRdwItem,
} from 'redux/actions/data'
import RdwStockView from '../views/rdw-stock-view'

const mapStateToProps = ({ data }) => ({
  locations: data.locations,
  rdwStockResults: data.rdwStockResults,
  rdwItemUpdate: data.rdwItemUpdate,
})

const mapDispatchToProps = {
  getLocations,
  getRdwStockResults,
  updateRdwItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(RdwStockView)
