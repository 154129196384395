import { connect } from 'react-redux'
import {
  getB2bCarFile,
  getCarAssets,
  rotateImages,
  updateCarfile,
} from 'redux/actions/data'
import {
  openOverlay,
  openImageOverlay,
  toggleSalesView,
} from 'redux/actions/ui'
import { getForms } from 'redux/actions/forms'
import B2bCarFile from '../views/b2b-car-file/index'

const mapStateToProps = ({ data, forms, ui, auth }) => ({
  carAssets: data.b2bCarFileAssets,
  b2bCarFile: data.b2bCarFile,
  carKind: data.carkind,
  forms: forms.b2bCarFile,
  salesView: ui.salesView,
  carAssetsSort: data.carAssetsSort,
})

const mapDispatchToProps = {
  getB2bCarFile,
  getCarAssets,
  getForms,
  openOverlay,
  openImageOverlay,
  rotateImages,
  toggleSalesView,
  updateCarfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(B2bCarFile)
