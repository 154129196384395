import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import LoadingIndicator from 'components/atoms/loading-indicator'
import CrossLinkedDataTable from 'components/layouts/cross-linked-data-table'
import AdsOverviewFilter from 'components/molecules/ads-overview-filter'
import BranchLocationPicker from 'components/molecules/branch-location-picker'
import SearchInput from 'components/molecules/search-input'
import Paging from 'components/molecules/paging'
import Text from 'components/atoms/text'
import CarfileAdCard from 'components/molecules/carfile-ad-card'
import AdStatusIcon from 'components/molecules/ad-status-icon'
import Tooltip from 'components/atoms/tooltip'
import Link from 'components/atoms/link'
import { formatDataTestE2eAttr } from 'utilities/format'
import useSelectedLocationId from 'hooks/use-selected-location-id'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
const StyledFilters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`

const StyledPlatformName = styled(Text)`
  display: inline-block;
  height: 100px;
  text-align: left;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
`

const StyledPaging = styled(Paging)`
  margin: 20px auto 0 auto;
`

/**
 * Utility function to help calculate to which direction a certain
 * tooltip needs to pop out towards in order to pop inwards into the
 * overview
 * @param {Object} position consisting out of a `rowIdx` and `y` property.
 * @param {Number} noOfColumns the amount of columns
 * @param {Number} noOfRows the amount of rows
 * @returns {String} The position as in 'left', 'right', 'top' or 'bottom'.
 */
function popInwards({ rowIdx, columnIdx }, noOfRows, noOfColumns) {
  let position = 'bottom'
  // the ones below are for 'edge' cases 😉:
  position = rowIdx > noOfRows - 2 ? 'top' : position
  position = rowIdx < 2 ? 'bottom' : position
  position = columnIdx >= noOfColumns - 2 ? 'left' : position
  position = columnIdx < 2 ? 'right' : position
  // and these ones are for 'corner' cases 😂:
  position = rowIdx < 2 && columnIdx >= noOfColumns - 2 ? 'left-top' : position
  position = rowIdx < 2 && columnIdx < 2 ? 'right-top' : position
  position =
    rowIdx > noOfRows - 2 && columnIdx >= noOfColumns - 2
      ? 'left-bottom'
      : position
  position = rowIdx >= noOfRows - 2 && columnIdx < 2 ? 'right-bottom' : position
  // I am a nerd!
  return position
}

function AdsOverview({
  ads,
  filters,
  getAds,
  getAdsFilters,
  getLocations,
  locations,
}) {
  const [selectedFilter, setSelectedFilter] = useState(0)
  const [selectedLocation, setSelectedLocation] = useSelectedLocationId()
  const [completedSearchTerm, setCompletedSearchTerm] = useState('')
  const [typedSearchTerm, setTypedSearchTerm] = useState('')
  const [page, setPage] = useState(1)

  const resultsPerPage = 12

  function handleOnFilterChange(value) {
    setPage(1)
    setSelectedFilter(value)
  }

  function handleOnLocationChange(value) {
    setPage(1)
    setSelectedLocation(value)
  }

  function handleOnSearch(e) {
    e.preventDefault()
    setPage(1)
    setCompletedSearchTerm(typedSearchTerm)
  }

  function handleChangePage(newPage) {
    setPage(newPage)
  }

  useEffect(() => {
    getAds({
      filterId: selectedFilter,
      lines: resultsPerPage,
      locationId: selectedLocation,
      page,
      search: completedSearchTerm,
    })
  }, [
    getAds,
    selectedFilter,
    selectedLocation,
    completedSearchTerm,
    page,
    resultsPerPage,
  ])

  useEffect(() => {
    getAdsFilters()
  }, [getAdsFilters])

  useEffect(() => {
    getLocations()
  }, [getLocations])

  const mappedLocations =
    locations &&
    locations.data &&
    locations.data.map((location) => ({
      ...location,
      id: location.id.length ? location.id : '0',
    }))

  const loading =
    ads && filters && locations
      ? ads.loading || filters.loading || locations.loading
      : true

  if (loading) {
    return <LoadingIndicator />
  }

  // Prepare all content for CrossLinkedDataTable component:
  const controls = (
    <div>
      <form onSubmit={handleOnSearch}>
        <SearchInput
          name="search"
          value={typedSearchTerm}
          onChange={(value) => setTypedSearchTerm(value)}
          onSubmit={handleOnSearch}
        />
      </form>
      <StyledFilters>
        <AdsOverviewFilter
          filters={filters.data}
          onFilterChange={handleOnFilterChange}
          selectedFilter={selectedFilter}
        />
        <BranchLocationPicker
          locations={mappedLocations}
          onLocationChange={handleOnLocationChange}
          selectedLocation={selectedLocation}
        />
      </StyledFilters>
    </div>
  )

  const columnHeadings = ads.data
    ? ads.data.portals.map((portal, portalIndex) => (
        <StyledPlatformName
          key={`portal-${portalIndex}`}
          text={`${portal.label} (${portal.count})`}
          data-test-e2e={formatDataTestE2eAttr('column-heading', portal.label)}
        />
      ))
    : []

  const rowHeadings = ads.data
    ? ads.data.rows.map((carFile, carFileIndex) => (
        <Link
          key={`car-file-${carFileIndex}`}
          to={`/car/${carFile.auto_id}`}
          data-test-e2e="car-file-link"
        >
          <CarfileAdCard
            brand={carFile.brand}
            model={carFile.model}
            image={carFile.image}
            license={carFile.license}
            manufactured={carFile.manufactured}
            nap={carFile.nap_ok}
            napDetails={carFile.nap_text}
            data-test-e2e="car-file-ad-card"
          />
        </Link>
      ))
    : []

  const rowCells = ads.data
    ? ads.data.rows.map((carFile, rowIdx) =>
        ads.data.portals.map((portal, columnIdx) => (
          <Tooltip
            key={`tooltip-${columnIdx}`}
            message={
              carFile.portals[portal.short] &&
              carFile.portals[portal.short].status_text
            }
            position={popInwards(
              {
                rowIdx,
                columnIdx,
              },
              ads.data.rows.length,
              ads.data.portals.length,
            )}
            data-test-e2e={formatDataTestE2eAttr('portal', portal.label)}
          >
            <AdStatusIcon
              status={
                carFile.portals[portal.short] &&
                carFile.portals[portal.short].status
              }
            />
          </Tooltip>
        )),
      )
    : [['']]

  return (
    <>
      {!loading && ads.data && filters.data && filters.data && (
        <Container>
          <CrossLinkedDataTable
            rowHeight={92}
            columnWidth={60}
            rowHeaderWidth={410}
            columnHeaderHeight={140}
            controls={controls}
            columnHeadings={columnHeadings}
            rowHeadings={rowHeadings}
            rowCells={rowCells}
          />
          {ads.data.total > resultsPerPage && (
            <StyledPaging
              activePage={page}
              onChangePage={handleChangePage}
              perPage={resultsPerPage}
              total={ads.data.total}
            />
          )}
        </Container>
      )}
    </>
  )
}

AdsOverview.propTypes = {
  ads: PropTypes.object,
  filters: PropTypes.object,
  getAds: PropTypes.func.isRequired,
  getAdsFilters: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  locations: PropTypes.object,
}

AdsOverview.defaultProps = {
  ads: null,
  filters: null,
  locations: null,
}

export default AdsOverview
