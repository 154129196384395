import CarAssetsForm, {
  propTypes as carAssetsFormPropTypes,
} from 'components/organisms/car-assets-form'
import { DocumentCategories } from 'config/enums'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addCarDocumentAssets,
  getCarDocumentAssets,
  setCarAssetCategory,
} from 'redux/actions/data'
import toast from 'utilities/toast'

const CarAssetsFormForDocuments = ({
  carFileId,
  addCarAssets,
  ...restProps
}) => {
  const { t } = useTranslation()
  const [category, setCategory] = React.useState(DocumentCategories.INTERNAL)
  const [selectedDocumentIds, setSelectedDocumentIds] = React.useState([])
  const otherCategory =
    category === DocumentCategories.INTERNAL
      ? DocumentCategories.COMMERCIAL
      : DocumentCategories.INTERNAL

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getCarDocumentAssets(carFileId))
  }, [dispatch, carFileId])

  const documentAssets = useSelector((state) =>
    Array.isArray(state?.data?.carDocumentAssets?.data)
      ? state.data.carDocumentAssets.data.filter(
          ({ type }) => type === 'document',
        )
      : [],
  )

  const filteredDocumentAssets = React.useMemo(
    () => documentAssets.filter((asset) => asset.category === category),
    [category, documentAssets],
  )

  const moveToCategoryHandler = async () => {
    const promises = selectedDocumentIds.map((id) =>
      dispatch(setCarAssetCategory(id, otherCategory)),
    )
    await Promise.all(promises)
      .then((results) => {
        const errors = []
        if (results.error) {
          errors.push(results.error)
        }
        if (errors.length > 0) {
          errors.forEach((error) => {
            console.error(error)
          })
          throw new Error(t('carFileDocumentsTab.moveToCategoryError'))
        }
        toast.success(
          t('carFileDocumentsTab.moveToCategorySuccess', {
            category: getLabelForCategory(otherCategory),
          }),
        )
        dispatch(getCarDocumentAssets(carFileId))
      })
      .catch((error) => {
        console.error(error)
        toast.error(error)
      })
  }

  const handleUpload = async (formData) => {
    await dispatch(
      addCarDocumentAssets(
        formData,
        carFileId,
        category,
        t('photoSuccesfullyUploaded'),
      ),
    )
    dispatch(getCarDocumentAssets(carFileId))
  }

  const handleChange = (documents) => {
    setSelectedDocumentIds(documents)
  }

  const getLabelForCategory = (category) =>
    t(`carFileDocumentsTab.categoryFilter.valueLabels.${category}`)

  // Filter out any unused stuff from the restProps
  const { staticContext, ...filteredRestProps } = restProps

  return (
    <CarAssetsForm
      {...filteredRestProps}
      // We override the `handleUpload` function, so we don't need the `addCarAssets` function,
      // since it is only called in the `handleUpload` function. This dummy function is here to
      // satisfy the `propTypes` check.
      addCarAssets={() => {}}
      type="documents"
      items={filteredDocumentAssets}
      itemsFilter={{
        onChange: setCategory,
        label: t('carFileDocumentsTab.categoryFilter.label'),
        filterOptions: Object.values(DocumentCategories).map((value) => ({
          label: getLabelForCategory(value),
          value,
        })),
      }}
      itemsFilterValue={category}
      onChange={handleChange}
      onUpload={handleUpload}
      customOptions={[
        {
          handler: moveToCategoryHandler,
          buttonText: t('carFileDocumentsTab.moveToCategory', {
            category: getLabelForCategory(otherCategory),
          }),
        },
      ]}
      allowImageAsDocumentUpload
    />
  )
}

const { itemsFilter, itemsFilterValue, type, ...restPropTypes } =
  carAssetsFormPropTypes

CarAssetsFormForDocuments.propTypes = {
  ...restPropTypes,
  carFileId: PropTypes.string.isRequired,
}

CarAssetsFormForDocuments.defaultProps = {
  ...CarAssetsForm.defaultProps,
}

export default CarAssetsFormForDocuments
