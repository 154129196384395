import { connect } from 'react-redux'
import {
  getStockFilters,
  getStockResults,
  getStockSortOptions,
} from 'redux/actions/data'
import { toggleSalesView } from 'redux/actions/ui'
import Stock from 'components/views/stock'

const mapStateToProps = ({ data, ui }) => ({
  salesView: ui.salesView,
  stockFilters: data.stockFilters,
  stockResults: data.stockResults,
  stockSortOptions: data.stockSortOptions,
})

const mapDispatchToProps = {
  getStockFilters,
  getStockSortOptions,
  getStockResults,
  toggleSalesView,
}

export default connect(mapStateToProps, mapDispatchToProps)(Stock)
