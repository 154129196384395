import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { media } from 'utilities/styled'

import LabeledText from 'components/atoms/labeled-text'

const Container = styled.div`
  gap: ${({ theme }) => theme.sizings.lvl2};
  display: flex;
  flex-direction: column;

  ${media.phone`
    flex-direction: row;
  `};

  ${media.desktop`
    flex-direction: column;
    align-items: start;
  `};

  ${media.tv`
    flex-direction: row;
  `};
`

const CarShareBidMeta = ({ currentBid, biddingDeadline }) => {
  const { t } = useTranslation()

  // If both the current bid and the bidding deadline are not set, return early
  if (!currentBid && !biddingDeadline) {
    return null
  }

  return (
    <Container>
      {currentBid && (
        <LabeledText
          title={t('currentBid')}
          value={currentBid}
          price={!!currentBid}
        />
      )}
      {biddingDeadline && (
        <LabeledText
          title={t('biddingDeadline')}
          value={`${biddingDeadline} CET`}
        />
      )}
    </Container>
  )
}

CarShareBidMeta.propTypes = {
  currentBid: PropTypes.string,
  biddingDeadline: PropTypes.string,
}

CarShareBidMeta.defaultProps = {
  currentBid: undefined,
  biddingDeadline: undefined,
}

export default CarShareBidMeta
