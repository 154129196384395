import Button from 'components/atoms/button'
import IconCheckbox from 'components/atoms/icon-checkbox'
import LabeledCheckbox from 'components/atoms/labeled-checkbox'
import LicensePlateInput from 'components/atoms/license-plate-input'
import AccessoiresSelect from 'components/atoms/new-accessoires-select'
import DatePicker from 'components/atoms/new-date-picker'
import NewOptionsSelect from 'components/atoms/new-options-select'
import TextInput from 'components/atoms/new-text-input'
import NewTooltip from 'components/atoms/new-tooltip'
import PackageSelect from 'components/atoms/package-select'
import Text from 'components/atoms/text'
import TextLink from 'components/atoms/text-link'
import ToolTip from 'components/atoms/tooltip'
import CarAdvertisementTextInput from 'components/molecules/car-advertisement-text-input'
import FormTable from 'components/molecules/form-table'
import DropdownSelect from 'components/molecules/new-dropdown-select'
import EnhancedTable from 'components/organisms/enhanced-table'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { formatDataTestE2eAttr } from 'utilities/format'

const Container = styled.div`
  display: ${({ $hidden }) => ($hidden ? 'none' : 'block')};
`

const ExtraLabeling = styled.div`
  padding: 0 0 0 ${({ theme }) => theme.sizings.lvl1};
  line-height: ${({ theme }) => theme.sizings.lvl3};
  display: grid;
  grid-template-columns: 1fr ${({ theme }) => theme.sizings.lvl2};
  grid-gap: ${({ theme }) => theme.sizings.lvl1};
`

const ExplanationIcon = styled.i`
  display: inline-block;
  color: ${({ theme }) => theme.colors.darkOnLightBorder};
  height: ${({ theme }) => theme.sizings.lvl2};
  width: ${({ theme }) => theme.sizings.lvl2};
  line-height: ${({ theme }) => theme.sizings.lvl2};
  border: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
  border-radius: 50%;
  text-align: center;
  font-size: 0.8em;

  &::before {
    content: 'i';
  }
`

const CheckboxHighlightWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: default;
`

const LabeledCheckboxFullWidth = styled(LabeledCheckbox)`
  margin-right: 0;
`

const HighlightContainer = styled.div`
  height: auto;
  margin-top: 2px;
`

const HighlightCheckbox = styled(IconCheckbox)`
  height: auto;
  display: block;
  color: ${({ disabled }) => (disabled ? '#b9c3c6' : '#ffa200')};

  svg {
    path {
      fill: currentColor;
    }
  }
`

const FieldLink = styled(TextLink)`
  margin-top: ${({ theme }) => theme.sizings.lvl1};
`

function FormField({
  className,
  error,
  explanation,
  optinRequired,
  field,
  fieldValueIndex,
  handleChange,
  handleHighlightChange,
  handleClickOverlay,
  hint,
  openOverlay,
  value,
  price,
}) {
  const { t } = useTranslation()
  const translatedError = typeof error === 'string' ? t(error) : error

  /**
   * Some field titles contain a ":" which causes a bug in useTranslation.
   */
  const translatedTitle =
    typeof field.title === 'string' && field.title.includes(':')
      ? field.title
      : t(field.title)

  function handleButtonClick(fld) {
    if (fld.overlay) {
      openOverlay(fld.overlay.type, fld.overlay.params)
    }
  }

  // Filter out all props that shouldn't be passed to the fields
  const {
    hidden,
    filled,
    fieldValueIndex: _,
    // eslint-disable-next-line camelcase
    confirm_edit,
    ...fieldRestProps
  } = field

  return (
    <Container
      className={className}
      $hidden={field.type === 'hidden'}
      data-test-e2e={formatDataTestE2eAttr(field.name, 'container')}
    >
      {(hint || explanation) && (
        <ExtraLabeling>
          <Text type="hint">{hint}</Text>
          {explanation && (
            <ToolTip message={explanation}>
              <ExplanationIcon />
            </ToolTip>
          )}
        </ExtraLabeling>
      )}
      {(() => {
        switch (field.type) {
          case 'button':
            return (
              <Button
                onClick={() => handleButtonClick(field)}
                text={translatedTitle}
                disabled={field.readOnly}
                data-test-e2e={formatDataTestE2eAttr(field.name, 'button')}
              />
            )
          case 'checkbox':
            if (field.variety === 'withHighlight') {
              const HighlightComponent = value ? (
                <HighlightCheckbox
                  className="highlight-checkbox"
                  icon="star"
                  iconChecked="starFilled"
                  checked={field.isHighlight || false}
                  id={field.name}
                  disabled={field.highlightReadOnly || false}
                  onChange={(checked) =>
                    handleHighlightChange(checked, field.name)
                  }
                  data-test-e2e={formatDataTestE2eAttr('checkbox-highlight')}
                  data-test-e2e-checkbox-name={formatDataTestE2eAttr(
                    field.name,
                  )}
                  data-test-e2e-checked={field.isHighlight || false}
                />
              ) : undefined

              return (
                <CheckboxHighlightWrapper
                  data-test-e2e={formatDataTestE2eAttr(
                    'checkbox-highlight-wrapper',
                  )}
                >
                  <LabeledCheckboxFullWidth
                    checked={!!value}
                    id={field.name}
                    label={translatedTitle}
                    onChange={(checked) => {
                      handleChange(checked, field.name)
                      if (!checked && field.isHighlight) {
                        // The checkbox was unchecked, yet the field was still marked as a highlight.
                        // The highlight should be removed by calling the back-end, so let's do that.
                        handleHighlightChange(false, field.name)
                      }
                    }}
                    price={field.price}
                    disabled={field.readOnly}
                    data-test-e2e="checkbox"
                    data-test-e2e-checkbox-name={formatDataTestE2eAttr(
                      field.name,
                    )}
                    data-test-e2e-checked={!!value}
                  />
                  {!!value && (
                    <HighlightContainer>
                      {field.highlightTooltip ? (
                        <NewTooltip
                          title={field.highlightTooltip}
                          width={350}
                          arrow
                        >
                          {HighlightComponent}
                        </NewTooltip>
                      ) : (
                        HighlightComponent
                      )}
                    </HighlightContainer>
                  )}
                </CheckboxHighlightWrapper>
              )
            }
            return (
              <LabeledCheckbox
                checked={!!value}
                id={field.name}
                label={translatedTitle}
                onChange={(checked) => handleChange(checked, field.name)}
                price={field.price}
                disabled={field.readOnly}
                data-test-e2e={formatDataTestE2eAttr('checkbox')}
                data-test-e2e-checkbox-name={formatDataTestE2eAttr(field.name)}
                data-test-e2e-checked={!!value}
              />
            )
          case 'options_select':
            return (
              <NewOptionsSelect
                label={translatedTitle}
                name={field.name}
                options={field.options || []}
                onChange={handleChange}
                newIndicator
                filled
                disabled={field.readOnly}
                data-test-e2e={formatDataTestE2eAttr(field.name)}
              />
            )
          case 'accessoires_select':
            return (
              <AccessoiresSelect
                label={translatedTitle}
                name={field.name}
                options={field.options || []}
                onChange={handleChange}
                newIndicator
                disabled={field.readOnly}
                data-test-e2e={formatDataTestE2eAttr(field.name)}
              />
            )
          case 'package_select':
            return (
              <PackageSelect
                value={value}
                items={field.items}
                name={field.name}
                onChange={handleChange}
                title={translatedTitle}
                disabled={field.readOnly}
                price={price}
                fieldValueIndex={fieldValueIndex}
                data-test-e2e={formatDataTestE2eAttr(field.name)}
              />
            )
          case 'select':
            return (
              <DropdownSelect
                error={translatedError}
                label={translatedTitle}
                name={field.name}
                id={field.name}
                items={field.options}
                onChange={(val) => handleChange(val, field.name)}
                required={!!field.required}
                value={value}
                filled
                disabled={field.readOnly}
                optinRequired={optinRequired}
              />
            )
          case 'table': {
            const { columns } = field.data
            const formattedColumns = columns.map((column) => ({
              id: column,
              label: column,
            }))
            const formattedRows = field.data.rows.map((row) => {
              const newRow = {}
              row.forEach((cell, columnIndex) => {
                newRow[columns[columnIndex]] = {
                  data: cell,
                  component: cell,
                }
              })
              return newRow
            })
            return (
              <EnhancedTable
                onMobileSwitchToValueCollection
                columns={formattedColumns}
                rows={formattedRows}
                selectable={false}
                legend={field.legend}
                data-test-e2e={formatDataTestE2eAttr('enhanced-table')}
              />
            )
          }
          case 'formTable':
            return (
              <FormTable field={field} onChange={handleChange} values={value} />
            )
          case 'textarea':
            return (
              <TextInput
                {...fieldRestProps}
                error={translatedError}
                label={translatedTitle}
                onChange={handleChange}
                title={field.placeholder}
                value={value}
                filled
                multiline
                disabled={field.readOnly}
                id={field.name}
                // params={{
                //   'data-test-e2e': testName,
                //   inputProps: {
                //     'data-test-e2e': generateTestName('input'),
                //   },
                //   ...field.params,
                // }}
              />
            )
          case 'link':
            return (
              <TextLink
                href={field.href}
                to={field.link}
                text={field.value}
                disabled={field.readOnly}
                data-test-e2e={formatDataTestE2eAttr(field.name)}
              />
            )
          case 'info':
            return (
              <Text
                hidden={field.type === 'hidden'}
                type="body"
                text={field.value}
                data-test-e2e={formatDataTestE2eAttr(field.name, 'info')}
              />
            )
          case 'date':
            return (
              <DatePicker
                error={translatedError}
                label={translatedTitle}
                name={field.name}
                onChange={(val) => handleChange(val, field.name)}
                required={field.required}
                value={value}
                filled
                disabled={field.readOnly}
                clearable={!field.required}
              />
            )
          case 'licensePlate':
            return (
              <LicensePlateInput
                {...fieldRestProps}
                error={translatedError}
                // label={translatedTitle}
                onChange={(event) =>
                  handleChange(event.target.value, field.name)
                }
                value={value}
                disabled={field.readOnly}
                name={field.name}
              />
            )
          case 'advertisementtextareagpt':
            /**
             * Special type of field which generates an advertisement text for a car via the back-end.
             *
             * Note: The `fieldRestProps` object should contain `carFileId`. You can use the
             * `injectCarFileDataInComplexFormFieldsets` function (located in `utilities/data.js`) to
             * inject this into the field.
             */
            return (
              <CarAdvertisementTextInput
                {...fieldRestProps}
                error={translatedError}
                label={translatedTitle}
                onChange={handleChange}
                title={field.placeholder}
                value={value}
                filled
                multiline
                disabled={field.readOnly}
                id={field.name}
                carFileId={field.data.carFileId}
                language={field.data.language}
              />
            )
          default:
            if (field.variety === 'floatingLabel') {
              return (
                <TextInput
                  {...fieldRestProps}
                  error={translatedError}
                  label={translatedTitle}
                  onChange={(val) => handleChange(val, field.name)}
                  value={value}
                  filled
                  disabled={field.readOnly}
                  id={field.name}
                  // params={{
                  //   'data-test-e2e': testName,
                  //   inputProps: {
                  //     'data-test-e2e': generateTestName('input'),
                  //   },
                  //   ...field.params,
                  // }}
                  // inputProps={{
                  //   'data-test-e2e': generateTestName('input'),
                  // }}
                />
              )
            }
            return (
              <TextInput
                {...fieldRestProps}
                error={translatedError}
                label={translatedTitle}
                onChange={handleChange}
                value={value}
                unit={field.unit}
                disabled={field.readOnly}
                id={field.name}
                // params={{
                //   'data-test-e2e': testName,
                //   inputProps: {
                //     'data-test-e2e': generateTestName('input'),
                //   },
                //   ...field.params,
                // }}
                // inputProps={{
                //   'data-test-e2e': generateTestName('input'),
                // }}
              />
            )
        }
      })()}
      {field.link && field.link.overlay && (
        <FieldLink
          onClick={() =>
            handleClickOverlay(field.link, fieldValueIndex, field.name)
          }
          text={t(field.link.label)}
          data-test-e2e={formatDataTestE2eAttr(field.name, 'field-link')}
        />
      )}
    </Container>
  )
}

FormField.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  explanation: PropTypes.string,
  optinRequired: PropTypes.bool,
  field: PropTypes.object.isRequired,
  fieldValueIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  handleChange: PropTypes.func,
  handleHighlightChange: PropTypes.func,
  handleClickOverlay: PropTypes.func,
  hint: PropTypes.string,
  openOverlay: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  className: PropTypes.string,
  price: PropTypes.string,
}

FormField.defaultProps = {
  error: false,
  explanation: null,
  optinRequired: false,
  handleClickOverlay: null,
  hint: null,
  openOverlay: null,
  handleChange: () => {},
  handleHighlightChange: () => {},
  className: '',
  price: '',
}

export default FormField
