import { contractStatuses } from 'config/data'
import PropTypes from 'prop-types'
import * as React from 'react'

export const CSPDashboardContext = React.createContext({
  contractStatusFilter: { status: contractStatuses.INCOMPLETE_AND_UNSIGNED },
  setContractStatusFilter: (_) => {},
})

export const CSPDashboardProvider = ({ children }) => {
  const [contractStatusFilter, setContractStatusFilter] = React.useState({
    status: contractStatuses.INCOMPLETE_AND_UNSIGNED,
  })

  const value = {
    contractStatusFilter,
    setContractStatusFilter,
  }

  return (
    <CSPDashboardContext.Provider value={value}>
      {children}
    </CSPDashboardContext.Provider>
  )
}

CSPDashboardProvider.propTypes = {
  children: PropTypes.node,
}
