import LoadingIndicator from 'components/atoms/loading-indicator'
import CarfileCard from 'components/molecules/carfile-card'
import Paging from 'components/molecules/paging'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'

const Container = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr;
  margin-bottom: 40px;

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `};

  ${media.tv`
    grid-template-columns: 1fr 1fr 1fr;
  `};

  ${media.hd`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`

const StyledPaging = styled(Paging)`
  margin: 0 auto 40px auto;
`

function SearchResults({
  getSearchResults,
  page,
  q,
  resultsPerPage,
  salesView,
  searchResults,
  setPage,
}) {
  useEffect(() => {
    if (q) {
      getSearchResults(
        {
          filter: q,
        },
        page,
        resultsPerPage,
      )
    }
  }, [getSearchResults, page, q, resultsPerPage])

  const data = searchResults && searchResults.data
  const loading = searchResults && searchResults.loading
  const items = data && searchResults.data.items
  const totalResults =
    searchResults && searchResults.data && searchResults.data.total

  if (!items || loading) {
    return <LoadingIndicator />
  }

  return (
    <>
      <Container>
        {items.map((result, idx) => (
          <CarfileCard
            key={idx.toString()}
            appraisal={result.appraisal}
            brand={result.brand}
            carId={String(result.id)}
            expected={result.expected}
            image={result.image}
            km={result.mileage}
            license={result.license}
            vin={result.chassisnr}
            link={`/car/${result.id}`}
            margin={result.margin}
            location={result.bedrijfsnaam}
            reportingCode={result.meldcode}
            model={result.model}
            month={result.month ? String(result.month) : undefined}
            sellingPrice={result.sellingPrice}
            salesView={salesView}
            staytime={result.staytime}
            status={result.status}
            type={result.type}
            cleaningOrderIsIssued={result.poets && result.poets !== 'geen'}
            year={result.year}
            isCarShare={!!result.is_carshare}
            carShareBids={result?.carshare_bids}
          />
        ))}
      </Container>
      {totalResults > resultsPerPage && (
        <StyledPaging
          activePage={page}
          onChangePage={setPage}
          perPage={resultsPerPage}
          total={totalResults}
        />
      )}
    </>
  )
}

SearchResults.propTypes = {
  getSearchResults: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  q: PropTypes.string.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  salesView: PropTypes.bool,
  setPage: PropTypes.func.isRequired,
  searchResults: PropTypes.object,
}

SearchResults.defaultProps = {
  salesView: false,
  searchResults: null,
}

export default SearchResults
