import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import styled from 'styled-components/macro'
import { useRouteMatch } from 'react-router-dom'

import { media } from 'utilities/styled'

import { postDynamicEndpoint } from 'redux/actions/data'

import CarShareBuyPrice from 'components/molecules/carfile-car-share-buy-price'
import FlexibleDialog from 'components/molecules/flexible-dialog'
import CarEntryHeader from 'components/molecules/car-entry-header'
import Typography from 'components/molecules/typography'

const StyledCarEntryHeader = styled(CarEntryHeader)`
  background-color: ${({ theme }) => theme.colors.sectionBackground};
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const StyledFlexibleDialog = styled(FlexibleDialog)`
  .MuiPaper-root {
    overflow: hidden auto;
  }

  ${media.tablet`
    .MuiPaper-root {
      min-width: ${({ theme }) => `${theme.metrics.phone}px`};
    }
  `}
`

const CarFileCarShareBuyNowDialog = ({ className, open, onCancel }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const carFile = useSelector((state) => state?.data?.carfile)
  const dynamicEndpoint = useSelector((state) => state?.data?.dynamicEndpoint)
  const topdownSharedBidForm = useSelector(
    (state) => state?.forms?.topdownSharedBidForm,
  )
  const carAssets = useSelector((state) => state?.data?.carAssets)

  const carId = match.params.id
  const carShareLocationId = match.params.carShareLocationId

  const carFileData = carFile?.data
  const carPicture =
    carAssets?.data?.find(
      (picture) =>
        picture?.position === 1 && picture?.category === 'commercieel',
    )?.src || undefined
  const carYear = moment(carFileData?.registrationDate).year()

  const handleClickConfirmBuyNow = () =>
    dispatch(
      postDynamicEndpoint({
        endpoint: topdownSharedBidForm?.data?.endpoint,
        ...{
          auto_id: carId,
          vestiging_id: carShareLocationId,
          buynow: true,
        },
      }),
    )

  return (
    <StyledFlexibleDialog
      className={className}
      content={
        <>
          <StyledCarEntryHeader
            imagePath={carPicture}
            brand={carFileData?.brand}
            model={carFileData?.model}
            type={carFileData?.type}
            mileage={
              carFileData?.mileage ? String(carFileData.mileage) : undefined
            }
            year={carYear}
          />
          <CarShareBuyPrice carFileData={carFileData} />
          <Typography type="ExplanationParagraph">
            {t('carShare.confirmBuyNowDialogExplanation')}
          </Typography>
        </>
      }
      cancel
      cancelText={t('cancel')}
      onCancel={onCancel}
      onSubmit={handleClickConfirmBuyNow}
      submitText={t('confirmPurchase')}
      title={t('buyNow')}
      open={open}
      isLoading={dynamicEndpoint?.loading === true}
    />
  )
}

CarFileCarShareBuyNowDialog.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

CarFileCarShareBuyNowDialog.defaultProps = {
  className: null,
  carFileData: {},
  carAssetsData: [],
  isLoading: false,
}

export default CarFileCarShareBuyNowDialog
