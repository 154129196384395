import { ApolloProvider, useLazyQuery, useQuery } from '@apollo/client'
import { DealerSpecificApolloClient } from 'config/apollo'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router'
import PropTypes from 'prop-types'
import {
  GET_CAR_FROM_LICENSE_PLATE_QUERY,
  GET_YEARLY_MILEAGES,
  REQUEST_MAINTENANCE_OFFER,
} from 'config/graphql/csp'
import CarFileHeaderForWidget from 'components/organisms/car-file-header-for-widget'
import LoadingIndicator from 'components/atoms/loading-indicator'
import styled from 'styled-components/macro'
import BodyHeading from 'components/atoms/body-heading'
import { useTranslation } from 'react-i18next'
import Typography from 'components/molecules/typography'
import IconList from 'components/molecules/icon-list'
import { Field, Form, Formik } from 'formik'
import {
  FormikSelectInput,
  FormikTextInput,
} from 'components/molecules/formik-field'

import Button from 'components/atoms/button'
import toast from 'utilities/toast'
import { media } from 'utilities/styled'
import PricingPerPeriod, {
  PeriodType,
} from 'components/molecules/pricing-per-period'
import { formatMileage, formatPrice } from 'utilities/format'
import Label from 'components/atoms/label'
import Icon from 'components/atoms/icon'
import NewTooltip from 'components/atoms/new-tooltip'
import { CspDealerSiteWidgetForm as ValidationSchema } from 'config/validation-schemas'
import InlineBodyText from 'components/atoms/inline-body-text'

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.baseSize * 5}px;
  padding: ${({ theme }) => theme.baseSize * 3}px;
  background-color: ${({ theme }) => theme.colors.defaultBackground};

  ${media.tablet`
    display: grid;
    grid-template-areas:
    'heading heading'
    'form details'
    ;
    grid-template-columns: 1fr 1fr;
  `}
`

const StyledBodyHeading = styled(BodyHeading)`
  grid-area: heading;
`

const StyledForm = styled(Form)`
  grid-area: form;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.baseSize * 5}px;
  order: 2;
  ${media.phone`
    order: 1;
  `}
`

const Article = styled.article`
  order: 1;
  ${media.phone`
    order: 2;
  `}
  grid-area: details;

  padding: ${({ theme }) => theme.baseSize * 4}px
    ${({ theme }) => theme.baseSize * 5}px;
  background-color: ${({ theme }) => theme.colors.sectionBackground};

  > *:not(:last-child) {
    padding-bottom: ${({ theme }) => theme.baseSize * 4}px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.defaultBackground};
    margin-bottom: ${({ theme }) => theme.baseSize * 4}px;
  }
`
const StyledIconList = styled(IconList)`
  li p {
    color: ${({ theme }) => theme.colors.text} !important;
  }
`

const StyledFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.baseSize * 1.5}px;

  h2 {
    margin-bottom: ${({ theme }) => theme.baseSize}px;
  }

  p {
    margin: 0 0 ${({ theme }) => theme.baseSize}px 0;
  }

  // easy, pragmatic solution, as opposed to making a styled component for each field
  .CspDealerSiteWidgetForm-field {
    max-width: ${({ theme }) => theme.baseSize * 50}px;
  }
`

const StyledButton = styled(Button)`
  margin-top: -${({ theme }) => theme.baseSize}px;
  align-self: flex-start;
  width: initial;

  > * {
    text-transform: initial;
    font-weight: 600;
  }
`

const PlanSummarySection = styled.section`
  display: grid;
  grid-template-areas:
    'icon price'
    'icon savings';
  grid-template-columns: auto auto;
  background-color: ${({ theme }) => theme.colors.alternativeBackground};
  padding: ${({ theme }) => theme.baseSize * 3}px;
  max-width: ${({ theme }) => theme.baseSize * 50}px;
  margin: ${({ theme }) => theme.baseSize * 3}px 0;
`

const StyledIcon = styled(Icon)`
  grid-area: icon;
  > * {
    fill: ${({ theme }) => theme.colors.level2Heading};
    stroke: ${({ theme }) => theme.colors.level2Heading};
  }
`

const StyledPricingPerPeriod = styled(PricingPerPeriod)`
  grid-area: price;
  position: relative;
  left: ${({ theme }) => -(theme.baseSize * 1.5)}px;

  > * {
    color: ${({ theme }) => theme.colors.level2Heading};
  }
`

const StyledInlineBodyText = styled(InlineBodyText)`
  display: block;
  margin-bottom: ${({ theme }) => theme.baseSize}px;
`

const SavingContainer = styled.div`
  grid-area: savings;
  display: inline-flex;
  align-items: center;
  align-self: end;
  padding-bottom: ${({ theme }) => theme.baseSize}px;
`

const StyledParagraph = styled(Typography)`
  max-width: ${({ theme }) => theme.baseSize * 50}px;
  margin: 0; // pragmatic design choice, letting PlanSummarySection deal with spacing.
`
const StyledH2Heading = styled(Typography)`
  margin: 0 0 ${({ theme }) => theme.baseSize / 2}px 0;
`

function CspDealerSiteWidgetForm({ currentMileage, licensePlate }) {
  const { t } = useTranslation()
  const [carServices, setCarServices] = useState([])
  const [basicPackageName, setBasicPackageName] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const spanRef = useRef()

  const validationSchema = ValidationSchema(t)

  const urlParams = new URLSearchParams(window.location.search)
  const iframeId = urlParams.get('widgetId')
  const successRedirectUrl = urlParams.get('successRedirectUrl')
  const successRedirectMethod = urlParams.get('successRedirectMethod')

  const {
    data: carData,
    loading: carLoading,
    error: carError,
  } = useQuery(GET_CAR_FROM_LICENSE_PLATE_QUERY, {
    variables: {
      licensePlate,
    },
  })

  const {
    data: yearlyMileageData,
    loading: yearlyMileageLoading,
    error: yearlyMileageError,
  } = useQuery(GET_YEARLY_MILEAGES)

  const [
    requestMaintenanceOffer,
    {
      data: maintenanceOfferData,
      loading: maintenanceOfferLoading,
      error: maintenanceOfferError,
    },
  ] = useLazyQuery(REQUEST_MAINTENANCE_OFFER)

  useEffect(() => {
    if (carData?.carByLicensePlate) {
      const basicPackage =
        carData.carByLicensePlate.dealer.carServicePlan.packages.filter(
          (carServicePackage) => carServicePackage.packageType === 'LOYAL',
        )[0]

      setBasicPackageName(basicPackage.description || basicPackage.name)

      setCarServices(
        basicPackage.carServicePlanIncludes.flatMap(({ commercialName }) =>
          commercialName.map((content) => ({ content })),
        ),
      )
    }
  }, [carData, setCarServices])

  const offer = maintenanceOfferData?.requestMaintenanceOffer
  const offerResults = offer?.results
  const car = carData?.carByLicensePlate || {
    brand: { name: undefined },
    model: { name: undefined },
  }

  const moneySaved =
    typeof offer?.results?.[0].resultNet === 'number' &&
    typeof offer?.results?.[0].resultGross === 'number'
      ? // money saved be displayed in terms of years, but info is provided in a monthly form.
        // so * 12 to get the yearly form:
        formatPrice(
          (offer.results?.[0].resultGross - offer.results?.[0].resultNet) * 12,
        )
      : null

  const handleOnSubmit = async ({
    mileagePerYear,
    firstName,
    lastName,
    email,
    phone,
  }) => {
    setIsSubmitting(false)

    try {
      await requestMaintenanceOffer({
        variables: {
          lead: {
            firstName,
            lastName,
            email,
            phone,
          },
          licensePlate,
          currentMileage,
          mileagePerYear,
        },
      })

      setIsSubmitting(true)
    } catch (error) {
      toast.error(error)

      setIsSubmitting(true)
    }
  }

  // This function handles the redirect that the developer has set in the "successRedirectMethod" method.
  // If it hasn't been set, this doesn't do anything, this supports the older widgets without any redirect method.
  // If it's "redirect", the parent page redirects (the full website)
  // If it's "background", this component opens an iFrame in the background
  const handleRedirect = useCallback(() => {
    if (!successRedirectMethod || !iframeId) {
      return
    }

    const mergedSearchParams = new URLSearchParams()
    const redirectUrl = new URL(successRedirectUrl)
    const redirectUrlSearchParams = redirectUrl.searchParams

    if (redirectUrlSearchParams.size) {
      redirectUrlSearchParams.forEach((value, key) => {
        mergedSearchParams.append(key, value)
      })
    }

    if (currentMileage || car?.mileage) {
      mergedSearchParams.append(
        'mileage',
        formatMileage(currentMileage || car?.mileage),
      )
    }

    if (car?.registrationDate) {
      mergedSearchParams.append('registrationYear', car.registrationDate)
    }

    if (car?.licensePlate) {
      mergedSearchParams.append('licensePlate', car.licensePlate)
    }

    if (car?.brand?.name) {
      mergedSearchParams.append('carBrand', car.brand.name)
    }

    if (car?.model?.name) {
      mergedSearchParams.append('carModel', car.model.name)
    }

    if (moneySaved) {
      mergedSearchParams.append('yearlySavings', moneySaved)
    }

    if (offerResults?.[0]?.resultNet) {
      mergedSearchParams.append(
        'monthlyPriceNetto',
        formatPrice(offerResults[0].resultNet),
      )
    }

    if (offerResults?.[0]?.resultGross) {
      mergedSearchParams.append(
        'monthlyPriceGross',
        formatPrice(offerResults[0].resultGross),
      )
    }

    const redirectUrlMerged = `${redirectUrl.origin}${redirectUrl.pathname}?${mergedSearchParams.toString()}`

    if (successRedirectMethod === 'redirect') {
      const anchorLink = document.createElement('a')

      anchorLink.target = '_parent'
      anchorLink.href = redirectUrlMerged
      spanRef.current.appendChild(anchorLink)
      anchorLink.click()
      return
    }

    if (successRedirectMethod === 'background') {
      const backgroundIframe = document.createElement('iframe')

      backgroundIframe.src = redirectUrlMerged
      backgroundIframe.style.display = 'none'
      backgroundIframe.style.width = '1px'
      backgroundIframe.style.height = '1px'

      spanRef.current.appendChild(backgroundIframe)
    }
  }, [
    successRedirectMethod,
    iframeId,
    successRedirectUrl,
    currentMileage,
    car.mileage,
    car.registrationDate,
    car.licensePlate,
    car.brand.name,
    car.model.name,
    moneySaved,
    offerResults,
  ])

  useEffect(() => {
    if (
      !offerResults ||
      !car ||
      !(!!successRedirectUrl && !!successRedirectMethod)
    ) {
      return
    }

    handleRedirect()
  }, [
    car,
    offerResults,
    currentMileage,
    moneySaved,
    successRedirectUrl,
    successRedirectMethod,
    handleRedirect,
  ])

  return (
    <>
      <span ref={spanRef} />
      <Main>
        {maintenanceOfferData ? (
          <StyledBodyHeading>
            {t('cspDealerSiteWidgetForm.resultsHeading')}
          </StyledBodyHeading>
        ) : (
          <StyledBodyHeading>
            {t('cspDealerSiteWidgetForm.initialHeading')}
          </StyledBodyHeading>
        )}
        {carLoading ||
        yearlyMileageLoading ||
        carError ||
        yearlyMileageError ? (
          <LoadingIndicator error={carError} />
        ) : (
          <>
            {maintenanceOfferData || maintenanceOfferLoading ? (
              <article>
                {maintenanceOfferLoading && (
                  <LoadingIndicator error={maintenanceOfferError} />
                )}
                {offer && (
                  <>
                    <StyledH2Heading type="Level2Heading">
                      {t(
                        'cspDealerSiteWidgetForm.details.servicePlan.heading',
                        {
                          basicPackageName,
                        },
                      )}
                    </StyledH2Heading>
                    <StyledParagraph type="BodyParagraph">
                      {t('cspDealerSiteWidgetForm.results.planContext', {
                        mileagePerYear: formatMileage(
                          offer.mileagePerYear,
                          null,
                        ),
                      })}
                    </StyledParagraph>
                    <PlanSummarySection>
                      <StyledIcon type="oil" width="70" />
                      <StyledPricingPerPeriod
                        data-test-e2e="CspDealerSiteWidgetForm-pricing-per-period"
                        price={offer.results?.[0].resultNet}
                        periodType={PeriodType.MONTH}
                        showFromText
                      />
                      <SavingContainer>
                        <Label
                          text={t(
                            'cspDealerSiteWidgetForm.results.moneySaved',
                            {
                              moneySaved,
                            },
                          )}
                          backgroundColor="chartIsOK"
                          weight={600}
                          size="small"
                        />
                        <NewTooltip
                          width={20}
                          title={t(
                            'cspDealerSiteWidgetForm.results.moneySavedExplanation',
                          )}
                        >
                          <Icon type="info" size="md" />
                        </NewTooltip>
                      </SavingContainer>
                    </PlanSummarySection>
                    <StyledParagraph type="BodyParagraph">
                      {t('cspDealerSiteWidgetForm.results.thankYouMessage')}
                    </StyledParagraph>
                  </>
                )}
              </article>
            ) : (
              <Formik
                enableReinitialize
                initialValues={{
                  mileagePerYear: yearlyMileageData.yearlyMileages[0].value,
                  firstName: '',
                  lastName: '',
                  email: '',
                  phone: '',
                }}
                onSubmit={handleOnSubmit}
                validationSchema={validationSchema}
              >
                {({ handleSubmit }) => (
                  <StyledForm>
                    <StyledFieldset>
                      <Typography type="Level2Heading">
                        {t('cspDealerSiteWidgetForm.form.mileage.heading')}
                      </Typography>
                      <Field
                        className="CspDealerSiteWidgetForm-field"
                        data-test-e2e="CspDealerSiteWidgetForm-mileage"
                        name="mileagePerYear"
                        label={t(
                          'cspDealerSiteWidgetForm.form.mileage.fields.mileagePerYear.label',
                        )}
                        explanation={t(
                          'cspDealerSiteWidgetForm.form.mileage.fields.mileagePerYear.info',
                        )}
                        filled
                        items={yearlyMileageData.yearlyMileages}
                        component={FormikSelectInput}
                        disabled={isSubmitting}
                        validationSchema={validationSchema}
                      />
                    </StyledFieldset>
                    <StyledFieldset>
                      <Typography type="Level2Heading">
                        {t('cspDealerSiteWidgetForm.form.personalia.heading')}
                      </Typography>
                      <Typography type="ExplanationParagraph">
                        {t(
                          'cspDealerSiteWidgetForm.form.personalia.explanation',
                        )}
                      </Typography>
                      <Field
                        className="CspDealerSiteWidgetForm-field"
                        data-test-e2e="CspDealerSiteWidgetForm-firstName"
                        name="firstName"
                        label={t(
                          'cspDealerSiteWidgetForm.form.personalia.fields.firstName.label',
                        )}
                        filled
                        component={FormikTextInput}
                        disabled={isSubmitting}
                        validationSchema={validationSchema}
                      />
                      <Field
                        className="CspDealerSiteWidgetForm-field"
                        data-test-e2e="CspDealerSiteWidgetForm-lastName"
                        name="lastName"
                        label={t(
                          'cspDealerSiteWidgetForm.form.personalia.fields.lastName.label',
                        )}
                        filled
                        component={FormikTextInput}
                        disabled={isSubmitting}
                        validationSchema={validationSchema}
                      />
                      <Field
                        className="CspDealerSiteWidgetForm-field"
                        data-test-e2e="CspDealerSiteWidgetForm-email"
                        name="email"
                        label={t(
                          'cspDealerSiteWidgetForm.form.personalia.fields.email.label',
                        )}
                        explanation={t(
                          'cspDealerSiteWidgetForm.form.personalia.fields.email.info',
                        )}
                        filled
                        component={FormikTextInput}
                        disabled={isSubmitting}
                        validationSchema={validationSchema}
                      />
                      <Field
                        className="CspDealerSiteWidgetForm-field"
                        data-test-e2e="CspDealerSiteWidgetForm-phone"
                        name="phone"
                        label={t(
                          'cspDealerSiteWidgetForm.form.personalia.fields.phone.label',
                        )}
                        explanation={t(
                          'cspDealerSiteWidgetForm.form.personalia.fields.phone.info',
                        )}
                        filled
                        component={FormikTextInput}
                        disabled={isSubmitting}
                        validationSchema={validationSchema}
                      />
                    </StyledFieldset>
                    <StyledButton
                      rounding={0}
                      type="submit"
                      size="large"
                      onClick={handleSubmit}
                      data-test-e2e="CspDealerSiteWidgetForm-submit"
                    >
                      {t('cspDealerSiteWidgetForm.form.submitButton.label')}
                    </StyledButton>
                  </StyledForm>
                )}
              </Formik>
            )}
            <Article>
              <CarFileHeaderForWidget
                car={carData.carByLicensePlate}
                customMileage={currentMileage}
              />
              <section>
                <Typography type="Level2Heading">
                  {t('cspDealerSiteWidgetForm.details.servicePlan.heading', {
                    basicPackageName,
                  })}
                </Typography>
                <StyledInlineBodyText variant="bold">
                  {t('cspDealerSiteWidgetForm.details.servicePlan.description')}
                </StyledInlineBodyText>
                <StyledIconList
                  columns={2}
                  items={carServices}
                  icon="check"
                  iconColour="statePositive"
                />
              </section>
            </Article>
          </>
        )}
      </Main>
    </>
  )
}

CspDealerSiteWidgetForm.propTypes = {
  currentMileage: PropTypes.number.isRequired,
  licensePlate: PropTypes.string.isRequired,
}

/**
 * A Sandwich, cuz it is a Provider with a yummy component sandwiched in it:
 */

const CspDealerSiteWidgetFormSandwich = () => {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const apiKey = urlParams.get('widgetApiKey')
  const currentMileage = parseInt(urlParams.get('currentMileage'), 10)
  const licensePlate = urlParams.get('licensePlate').replace(/-/g, '')
  const props = {
    currentMileage,
    licensePlate,
  }
  // const MemoedApolloClient = React.useMemo(() => DealerSpecificApolloClient(apiKey), [apiKey])

  return (
    <ApolloProvider client={DealerSpecificApolloClient(apiKey)}>
      <CspDealerSiteWidgetForm {...props} />
    </ApolloProvider>
  )
}

export default CspDealerSiteWidgetFormSandwich
