import { connect } from 'react-redux'
import MobileHeader from '../organisms/mobile-header'

const mapStateToProps = ({ data }) => ({
  todos: data.todos,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader)
