import React from 'react'
// import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client'
import DealerNoCSPMessage from 'components/organisms/dealer-no-csp-message'
import GeneralDataError from 'components/organisms/general-data-error'
import { GET_DEALERS_WITH_CSP } from 'config/graphql/csp'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import LoadingIndicator from '../../../atoms/loading-indicator'
import ExpandableBars from '../../../layouts/expandable-bars'
import SummaryLine from '../../../molecules/summary-line'
import Typography from '../../../molecules/typography'
import CarServicePlanMainSettings from './dealer-specific/main-settings'

function CarServicePlanGeneralSettings() {
  const { loading, error, data } = useQuery(GET_DEALERS_WITH_CSP)
  const { t } = useTranslation()
  const { pathname } = useLocation()

  if (loading) {
    return <LoadingIndicator error={null} />
  }
  if (error) {
    console.error(error)
    return (
      <>
        <Typography type="Level1Heading">
          {t('carServicePlanGeneralTab.heading')}
        </Typography>
        <GeneralDataError />
      </>
    )
  }

  const trailingPathPattern = /edit-car-services\/(\d+)\/$/
  const matches = pathname.match(trailingPathPattern)
  const initialExpandedRowId =
    Array.isArray(matches) && matches.length > 0 ? matches[1] : null

  return data.dealers &&
    Array.isArray(data.dealers.edges) &&
    data.dealers.edges.length ? (
    <>
      <Typography type="Level1Heading">
        {t('carServicePlanGeneralTab.heading')}
      </Typography>
      <ExpandableBars
        initialExpandedRowId={initialExpandedRowId}
        rows={data.dealers.edges.map((edge) => ({
          id: edge.node.id,
          header: (
            <SummaryLine
              name={edge.node.name}
              details={[
                { label: t('clientNumber'), value: edge.node.uccId },
                { value: `${edge.node.address} ${edge.node.city}` },
                {
                  value: edge.node.email ? edge.node.email : t('emailUnknown'),
                },
                {
                  iconLabel: {
                    icon: 'modificationAndDate',
                    label: t('lastModifiedOn'),
                  },
                  value:
                    edge.node.carServicePlan &&
                    edge.node.carServicePlan.lastModified
                      ? edge.node.carServicePlan.lastModified
                      : t('unknown'),
                },
              ]}
            />
          ),
          details: edge.node.carServicePlan ? (
            <CarServicePlanMainSettings
              dealerId={edge.node.id}
              carServicePlanId={edge.node.carServicePlan.id}
            />
          ) : (
            <DealerNoCSPMessage />
          ),
        }))}
      />
    </>
  ) : (
    <Typography type="ExplanationParagraph">{t('noDealersFound')}</Typography>
  )
}

// CarServicePlanGeneralSettings.propTypes = {};

export default CarServicePlanGeneralSettings
