import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { unitsOfMeasurement, unitTypes } from 'config/data'
import { formatInputValue } from 'utilities/utils'
import Text, { styles as textStyles } from 'components/atoms/text'
import DatePicker from 'components/atoms/date-picker'

function getBorderColor({ deselected, error, theme }) {
  if (error) {
    return deselected
      ? theme.colors.errorBorderInactive
      : theme.colors.errorBorderActive
  }
  return deselected ? theme.colors.brandDelta : theme.colors.brandAlfa
}

function getMinInputUnitWidth({ type }) {
  return (
    {
      date: '9em',
    }[type] || '5em'
  )
}

const Container = styled.div``

const Field = styled.div`
  background: ${({ error, theme }) =>
    error ? theme.colors.errorBackground : theme.colors.fieldBackground};
  min-height: 36px;
  border-radius: 0;
  display: flex;
  padding: 0 8px;
  border-bottom: 1px solid
    ${(props) => (props.readOnly ? 'transparent' : `${getBorderColor(props)}`)};
  justify-content: space-between;
`

const InputUnitContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 ${getMinInputUnitWidth};
  justify-content: flex-end;
`

const InputUnitInnerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex: 1 0 100%;
`

const StyledInput = styled.input`
  ${textStyles.floatingLabelInput}
  background: none;
  border: none;
  border-radius: 0;
  margin: 0 8px 0 0;
  outline: none;
  padding: 5px 0;
  text-align: right;
  width: 100%;

  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

const Title = styled(Text)`
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::first-letter {
    text-transform: uppercase;
  }
`

const Unit = styled(Text)`
  color: ${({ theme }) => theme.colors.brandCharlie};
  margin: 0 8px 0 0;
  flex: 1 0 auto;
`

const ErrorMessage = styled(Text)`
  margin-top: ${({ theme }) => theme.sizings.lvl0};
`

function FilledTextInput({
  className,
  error,
  maxlength,
  name,
  onChange,
  required,
  readOnly,
  title,
  type,
  value,
  unit,
}) {
  const [focussed, setFocussed] = useState(false)
  const inputEl = useRef(null)

  const unitType = unitTypes[unit]

  function handleFocus() {
    if (!readOnly) {
      setFocussed(true)
    }
  }

  function handleBlur() {
    setFocussed(false)
  }

  const constructedTitle = `${title}${required ? '*' : ''}:`

  const formattedValue = formatInputValue(value, type)

  return (
    <Container className={className} htmlFor={name}>
      <Field deselected={!focussed} error={error} readOnly={readOnly}>
        {title && (
          <Title
            tag="label"
            type="menu"
            htmlFor={name}
            text={constructedTitle}
            title={constructedTitle}
            color="inputLabel"
          />
        )}
        <InputUnitContainer type={type}>
          <InputUnitInnerContainer>
            {type === 'date' ? (
              <DatePicker
                onChange={(newDate) => onChange({ target: { value: newDate } })}
                value={formattedValue}
              />
            ) : (
              <StyledInput
                autoCapitalize="none"
                name={name}
                id={name}
                maxLength={maxlength}
                onBlur={handleBlur}
                onChange={onChange}
                onFocus={(e) => handleFocus(e.target)}
                type={type || unitType}
                value={formattedValue}
                readOnly={readOnly}
                ref={inputEl}
              />
            )}
            {unit && (
              <Unit tag="span" type="menu" text={unitsOfMeasurement[unit]} />
            )}
          </InputUnitInnerContainer>
        </InputUnitContainer>
      </Field>
      {typeof error === 'string' && (
        <ErrorMessage color="stateNegative" text={error} type="menu" />
      )}
    </Container>
  )
}

FilledTextInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  maxlength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  title: PropTypes.string,
  type: PropTypes.string,
  unit: PropTypes.oneOf(Object.keys(unitsOfMeasurement)),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
}

FilledTextInput.defaultProps = {
  className: null,
  error: false,
  maxlength: null,
  onChange: null,
  readOnly: false,
  required: false,
  title: null,
  type: null,
  unit: null,
  value: '',
}

export default FilledTextInput
