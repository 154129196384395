/* eslint-disable camelcase */

import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getObjectFromQueryString } from 'utilities/utils'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import CarEntryValuationStep2 from './steps/car-entry-valuation-step2'
import CarEntryValuationStep1Manual from './steps/car-entry-valuation-step1-manual'
import CarEntryValuationStep1NoLicense from './steps/car-entry-valuation-step1-no-license'
import CarEntryValuationStep1 from './steps/car-entry-valuation-step1'

function CarValuationView ({
  createCarfile,
  location,
  match,
  newCar,
  updateCarfile,
  deleteCarAssets,
  sortCarAssets,
  rotateImages,
  ...restProps
}) {
  const { t } = useTranslation()
  const { step } = match.params
  const query = getObjectFromQueryString(location.search)
  const { kenteken, car_id, mileage } = query

  const prefilledLicensePlate = kenteken && kenteken !== 'null' ? kenteken : ''
  const prefilledMileage = mileage && mileage !== 'null' ? Number(mileage) : null

  const newCarData = {
    auto_id: car_id,
    ...newCar && newCar.data,
  }

  return (
    <AppLayoutContainer pageTitle={t('carEntry')}>
      {{
        1: (
          <CarEntryValuationStep1
            newCar={newCar}
            prefilledLicensePlate={prefilledLicensePlate}
            prefilledMileage={prefilledMileage}
            addNewCarData={restProps.addNewCarData}
            clearData={restProps.clearData}
            getLicensePlate={restProps.getLicensePlate}
            licensePlate={restProps.licensePlate}
            dataProviders={restProps.dataProviders}
            getDataProviders={restProps.getDataProviders}
          />
        ),
        2: (
          <CarEntryValuationStep2
            createCarfile={createCarfile}
            newCar={newCarData}
            updateCarfile={updateCarfile}
            deleteCarAssets={deleteCarAssets}
            sortCarAssets={sortCarAssets}
            rotateImages={rotateImages}
            {...restProps}
            valuation
          />
        ),
        nolicenseplate: (
          <CarEntryValuationStep1NoLicense
            car={newCar && newCar.data}
            newCar={newCarData}
            {...restProps}
          />
        ),
        manual: (
          <CarEntryValuationStep1Manual
            createCarfile={createCarfile}
            newCar={newCar && newCar.data}
            updateCarfile={updateCarfile}
            {...restProps}
          />
        ),
      }[step]}
    </AppLayoutContainer>
  )
}

CarValuationView.propTypes = {
  createCarfile: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  newCar: PropTypes.object,
  updateCarfile: PropTypes.func.isRequired,
  deleteCarAssets: PropTypes.func.isRequired,
  sortCarAssets: PropTypes.func.isRequired,
  rotateImages: PropTypes.func.isRequired,
}

CarValuationView.defaultProps = {
  newCar: null,
}

export default CarValuationView
