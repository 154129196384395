import React, { useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import Button from 'components/atoms/button'
import Checkbox from 'components/atoms/checkbox'
import Text from 'components/atoms/text'
import TextInput from 'components/atoms/text-input'
import LanguagePicker from 'components/molecules/language-picker'

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const Error = styled(Text)`
  margin-bottom: 20px;
`

const StyledTextInput = styled(TextInput)`
  margin-bottom: 32px;
`

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 32px;
`

function getDefaultValue(field) {
  switch (field.type) {
    case 'checkbox':
      return field.value || false
    default:
      return field.value || ''
  }
}

function AuthForm({
  error,
  feedback,
  fields,
  language,
  level,
  onSubmit,
  submitText,
  title,
  ...restProps
}) {
  const defaultValues = fields.map(getDefaultValue)
  const defaultErrors = fields.map(() => false)
  const [values, setValues] = useState(defaultValues)
  const [errors, setErrors] = useState(defaultErrors)

  function handleChange(index, value) {
    setErrors([...errors.slice(0, index), false, ...errors.slice(index + 1)])
    setValues([...values.slice(0, index), value, ...values.slice(index + 1)])
  }

  function handleSubmit(event) {
    event.preventDefault()

    const errs = fields.map((field, index) => field.validator(values, index))
    const foundError = errs.reduce((err, result) => err || result, false)

    setErrors(errs)

    if (foundError) {
      return
    }

    const formData = {}

    values.forEach((value, index) => {
      formData[fields[index].name] = value
    })

    onSubmit(formData)
  }

  return (
    <div {...restProps}>
      <Header>
        <Text type="h1" text={title} />
        <LanguagePicker />
      </Header>
      {error && !feedback && (
        <Error type="body" text={error} color="stateNegative" />
      )}
      {feedback && <Text type="body" text={feedback} />}
      {!feedback && (
        <form onSubmit={handleSubmit}>
          {fields.map((field, index) => {
            if (field.type === 'checkbox') {
              return (
                <StyledCheckbox
                  key={index.toString()}
                  name={field.name}
                  onChange={(e) => handleChange(index, e)}
                  checked={values[index]}
                  title={field.title}
                />
              )
            }
            return (
              <StyledTextInput
                key={index.toString()}
                error={errors[index]}
                name={field.name}
                onChange={(e) => handleChange(index, e.target.value)}
                title={field.title}
                type={field.type}
                value={values[index]}
              />
            )
          })}
          <Button level={level} size="large" type="submit">
            {submitText}
          </Button>
        </form>
      )}
    </div>
  )
}

AuthForm.propTypes = {
  error: PropTypes.string,
  feedback: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.node,
  ]),
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  level: PropTypes.oneOf(['standard', 'cta']),
  language: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

AuthForm.defaultProps = {
  error: null,
  feedback: null,
  language: false,
  level: 'standard',
}

export default AuthForm
