import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components/macro'
import { media } from 'utilities/styled'
import { useMediaLayout } from 'use-media'
import { useTranslation } from 'react-i18next'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Text from 'components/atoms/text'
import Link from 'components/atoms/link'
import Todo from 'components/molecules/todo'

const Container = styled.div`
  width: 100%;
`

const Grid = styled.div`
  column-gap: 48px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;

  ${media.desktop`
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  `};
`

const StyledTodo = styled(Todo)`
  margin-bottom: 16px;
`

function Todos({ closeOverlay, columns, items, loading }) {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.desktop - 1 })

  return (
    <Container>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Grid columns={columns}>
          {items &&
            items.map((item, index) => {
              if (isMobile && item.type === 'pdf') {
                return null
              }
              if (closeOverlay) {
                return (
                  <Link key={index.toString()} onClick={closeOverlay}>
                    <StyledTodo {...item} />
                  </Link>
                )
              }
              return <StyledTodo key={index.toString()} {...item} />
            })}
          {items && !items.length && (
            <Text text={t('goodJobNoTodos')} type="h5" />
          )}
        </Grid>
      )}
    </Container>
  )
}

Todos.propTypes = {
  closeOverlay: PropTypes.func,
  columns: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  loading: PropTypes.bool,
}

Todos.defaultProps = {
  closeOverlay: null,
  columns: 2,
  items: null,
  loading: true,
}

export default Todos
