import api from 'utilities/api'

const idEndpointsMap = {
  adsSettings: '/cl/v3/company/formvestigingadvertereninstellingen',
  bids: '/cl/v3/bidservice/forms',
  carfile: '/cl/v3/carfile/forms',
  rdw: '/cl/v3/vrijwaren/formvrijwaren',
  b2bCarFile: '/cl/v3/carfile/b2bforms',
  b2bPurchasingCompany: '/cl/v3/externepartij/formcarshareimport',
  cleaningCompany: '/cl/v3/externepartij/formpoets',
  cleanup: '/cl/v3/cleanup/forms',
  deliverySegments: '/cl/v3/afleverpakketten/FormApSegmentInstellingen',
  deliveryPackages: '/cl/v3/afleverpakketten/FormApAfleverpakketten',
  general: '/cl/v3/general/forms',
  updateUser: '/cl/v3/account/FormGebruikerDetails',
  myData: '/cl/v3/account/formmijngegevens',
  pdf: '/cl/v3/pdfconfig/formpdf',
  pdfFactorySettings: '/cl/v3/pdfconfig/factorysettings',
  topdown: '/cl/v3/deduction/formtopdown',
  topdownSharedBidForm: '/cl/v3/topdown/sharedbidform',
  transport: '/cl/v3/transport/forms',
  transportCompany: '/cl/v3/externepartij/formtransport',
  companyLocationAddress: '/cl/v3/company/formvestigingadres',
  marktplaatsSettingsForm: '/cl/v3/company/FormVestigingMarktplaats',
  portalSettings: '/cl/v3/afleverpakketten/FormApPortalInstellingen',
  addB2bRelations: '/cl/v3/b2bprospect/formIsB2bHandelaar',
  addB2bSalesInterest: '/cl/v3/b2bprospect/formB2bInteresse',
  editB2bSalesInterest: '/cl/v3/b2bprospect/formB2bInteresse',
  addB2bPurchasingInterest: '/cl/v3/b2bprospect/formB2bInteresse',
  formB2BSalesLocation: '/cl/v3/b2bprospect/FormIsB2bHandelaar',
  valuation: '/cl/v3/carfile/taxforms',
  bpmDeclaration: '/cl/v3/carfile/bpmtaxforms',
  rdwRecordCar: '/cl/v3/vrijwaren/formvastleggen',
  rdwRetractCar: '/cl/v3/vrijwaren/formintrekken',
  rdwCheckCar: '/cl/v3/vrijwaren/formraadplegen', // form config for checking ownership of car within RDW
  rdwIndemnifyCar: '/cl/v3/vrijwaren/formvrijwaren',
}

function getForms(id, instance, params) {
  const endpoint = idEndpointsMap[id]

  if (!endpoint) {
    console.error(`No form endpoint for id ${id}`)
  }

  return api
    .get(endpoint, instance, params)
    .then((response) => response.data.data)
}

export default {
  getForms,
}
