import { connect } from 'react-redux'
import { getAds, getAdsFilters, getLocations } from 'redux/actions/data'
import Ads from 'components/views/ads'

const mapStateToProps = ({ data }) => ({
  ads: data.ads,
  filters: data.adsFilters,
  locations: data.locations,
})

const mapDispatchToProps = {
  getAds,
  getAdsFilters,
  getLocations,
}

export default connect(mapStateToProps, mapDispatchToProps)(Ads)
