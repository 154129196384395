import { gql } from '@apollo/client'

export default gql`
  fragment checklistInfo on Checklist {
    id
    name
    status
    soort {
      id
      soort
    }
    parent {
      id
      name
    }
    company {
      name
    }
  }
`
