import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { reset } from 'redux-form'
import useDynamicEndpointDone from 'hooks/use-dynamic-endpoint-done'
import Overlay from 'components/molecules/overlay'
import { translateForm } from 'utilities/form-utils'
import LoadingIndicator from 'components/atoms/loading-indicator'
import ComplexForm from 'components/molecules/complex-form'

/*
 * A form overlay that fetches the form from the backend by itself
 */
function DynamicFormOverlay({
  closeOverlay,
  data,
  formId,
  formParams,
  forms,
  getForms,
  icon,
  onSubmit,
  dynamicEndpoint,
  onUpdateSuccess,
  subId,
  submitText,
  text,
  title,
}) {
  // Since ComplexForm only defines its default values on the initial
  // render the form values are not updated if the data passed to it
  // changes during it's lifetime. Since I did not want to change that in
  // a form that is used everywhere in the app for an issue that currently only
  // occurs here, I choose to keep loading state here which is true by
  // default until the form is fetched and therefore the most recent
  // data of the form (that may already have been fetched before and therefore
  // shows old data) is shown.
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const previousLoading = useRef(false)
  const { t } = useTranslation()
  const overlayId = 'form'
  const id = subId ? `${formId}_${subId}` : formId
  const form = forms[id] && forms[id].data
  const formLoading = forms && forms[id] && forms[id].loading
  const translatedForm = form && translateForm(form.fieldsets, t)

  useEffect(() => {
    getForms(formId, formParams, subId)
  }, [formId, formParams, getForms, subId])

  useEffect(() => {
    if (previousLoading.current && !formLoading) {
      setLoading(false)
    }
    previousLoading.current = formLoading
  }, [formLoading, forms])

  function handleSubmit(values) {
    onSubmit(values, form.endpoint)
  }

  useDynamicEndpointDone(
    dynamicEndpoint,
    () => {
      if (onUpdateSuccess) {
        onUpdateSuccess()
      }
      dispatch(reset(id))
      closeOverlay()
    },
    [],
  )

  return (
    <Overlay
      close={() => closeOverlay(overlayId)}
      icon={icon}
      text={text}
      title={title}
    >
      {loading && <LoadingIndicator height="100px" />}
      {!loading && (
        <ComplexForm
          data={data}
          fieldsets={translatedForm}
          formId={id}
          onSubmit={handleSubmit}
          submitText={submitText}
          level="cta"
          enableReinitialize
        />
      )}
    </Overlay>
  )
}

DynamicFormOverlay.propTypes = {
  closeOverlay: PropTypes.func.isRequired,
  data: PropTypes.object,
  formId: PropTypes.string,
  formParams: PropTypes.object,
  forms: PropTypes.object,
  getForms: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dynamicEndpoint: PropTypes.object,
  onUpdateSuccess: PropTypes.func,
  subId: PropTypes.string,
  submitText: PropTypes.string.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
}

DynamicFormOverlay.defaultProps = {
  data: null,
  formParams: null,
  forms: null,
  formId: null,
  subId: null,
  text: null,
  dynamicEndpoint: null,
  onUpdateSuccess: () => {},
}

export default DynamicFormOverlay
