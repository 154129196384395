import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import toast from 'utilities/toast'
import { GET_DEALERS_WITH_CSP } from '../../../../../../config/graphql/csp'
import Typography from '../../../../../molecules/typography'
import LoadingIndicator from '../../../../../atoms/loading-indicator'
import Icon from '../../../../../atoms/icon'
import Button from '../../../../../atoms/button'
import NewTextInput from '../../../../../atoms/new-text-input'
import EnhancedTable from '../../../../../organisms/enhanced-table'

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.sizings.lvl2};
`
const StyledActionButton = styled(Button)`
  float: right;
  &:last-child {
    padding-right: ${({ theme }) => theme.sizings.lvl1};
  }
`
const SearchField = styled(NewTextInput)`
  min-width: 300px;
`

function CarServicePlanAdminDealersOverview() {
  const { loading, error, data } = useQuery(GET_DEALERS_WITH_CSP)
  // const [deleteDealer] = useMutation(DELETE_DEALER,
  //   { refetchQueries: [{ query: GET_DEALERS }], awaitRefetchQueries: true });
  const [searchValue, setSearchValue] = useState()
  const [tableRows, setTableRows] = useState([])
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const createTableRow = useCallback(
    ({ id, name, city, address, uccId, carServicePlan }, currentPath) => {
      const actionTo = carServicePlan
        ? `${currentPath}${currentPath.endsWith('/') ? '' : '/'}details/${id}/`
        : null
      const actionOnClick = !carServicePlan
        ? () => {
            toast.error(t('dealerNoCSPBody'))
          }
        : null

      return {
        name: {
          component: name,
          data: name,
        },
        city: {
          component: city,
          data: city,
        },
        address: {
          component: address,
          data: address,
        },
        uccId: {
          component: uccId,
          data: uccId,
        },
        actions: {
          component: (
            <>
              {/* <StyledActionButton
              iconColor="actionsStandard"
              type="submit"
              level="option"
              icon="deleteCarfile"
              onClick={() => deleteDealer({ variables: { id } })}
            /> */}
              <StyledActionButton
                iconColor="actionsStandard"
                type="submit"
                level="option"
                icon="edit"
                to={actionTo}
                onClick={actionOnClick}
              />
            </>
          ),
          data: 'action',
          alignRight: true,
        },
      }
    },
    [t],
  )

  useEffect(() => {
    const rows = data
      ? data.dealers.edges.map((edge) => createTableRow(edge.node, pathname))
      : []
    setTableRows(rows)
  }, [createTableRow, data, pathname])

  const filteredTableRows = () => {
    const searchColumns = ['name', 'city', 'address', 'uccId']
    // This next line case insenstivly filters data
    // The search keys are the columns it will look in.
    // eslint-disable-next-line max-len
    return searchValue
      ? tableRows.filter(
          (row) =>
            searchColumns.filter(
              (rowKey) =>
                row[rowKey].data
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) !== -1,
            ).length > 0,
        )
      : tableRows
  }

  const ProvidersOverviewTableColumns = [
    {
      id: 'name',
      label: t('carServicePlanAdminDealersOverview.dealersTableHeadings.name'),
    },
    {
      id: 'city',
      label: t('carServicePlanAdminDealersOverview.dealersTableHeadings.city'),
    },
    {
      id: 'address',
      label: t(
        'carServicePlanAdminDealersOverview.dealersTableHeadings.address',
      ),
    },
    {
      id: 'uccId',
      label: t('carServicePlanAdminDealersOverview.dealersTableHeadings.uccId'),
    },
    {
      id: 'actions',
      label: t(
        'carServicePlanAdminDealersOverview.dealersTableHeadings.actions',
      ),
      alignRight: true,
    },
  ]

  return error || loading ? (
    <LoadingIndicator error={error && 'Error retrieving warranty programs'} />
  ) : (
    <>
      <FlexContainer>
        <div>
          <Typography type="Level1Heading">
            {t('carServicePlanAdminDealersOverview.header')}
          </Typography>
          <Typography type="ExplanationParagraph">
            {t('carServicePlanAdminDealersOverview.body')}
          </Typography>
        </div>
        <SearchField
          filled
          onChange={(val) => setSearchValue(val)}
          value={searchValue}
          label={t('carServicePlanAdminDealersOverview.searchLabel')}
          icon={<Icon type="search" color="brandCharlie" />}
        />
      </FlexContainer>
      <EnhancedTable
        selectable={false}
        columns={ProvidersOverviewTableColumns}
        rows={filteredTableRows()}
      />
    </>
  )
}

export default CarServicePlanAdminDealersOverview
