import { connect } from 'react-redux'
import { getLocations } from 'redux/actions/data'

import ManagementInfoView from '../views/management-info'

const mapStateToProps = ({ auth, data }) => ({
  auth,
  locations: data.locations,
})

const mapDispatchToProps = {
  getLocations,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagementInfoView)
