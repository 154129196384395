import { gql } from '@apollo/client'

export const READ_TAGS = gql`
  query readTags {
    tags {
      id
      label
      systemTag
    }
  }
`

export const UPDATE_TAGS = gql`
  mutation updateTags($tags: [InputTag!]!) {
    tagsUpdate(tags: $tags) {
      id
      label
      systemTag
    }
  }
`

export const TAG_CARFILE_SYNC = gql`
  mutation tagCarSync($carFileId: ID!, $tags: TagSync) {
    tagCarSync(carFileId: $carFileId, tags: $tags) {
      tags {
        id
        label
        systemTag
      }
    }
  }
`
