/**
 * This component has got a little complex since it facilitates the backend
 * returning and is expecting multiple values for this field which is supposed
 * to be controlled by only one. So the PackageSelect is checked whenever all
 * of its child checkboxes are checked and it should pass all the ids of the
 * child checkboxes state instead of the main state of the package select.
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import Checkbox from 'components/atoms/new-checkbox'
import { formatPrice } from 'utilities/utils'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import OverflowingTextWithToolTip from 'components/molecules/overflowing-text-with-tooltip'
import FormField from 'components/molecules/form-field'

const TitleContainer = styled.div`
  display: flex;
`

const OptionsContainer = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.sizings.lvl1}
    ${(props) => props.theme.sizings.lvl3};
  grid-template-columns: repeat(1, 1fr);
  margin-left: ${(props) => props.theme.sizings.lvl3};

  ${media.tablet`
    grid-gap: ${(props) => props.theme.sizings.lvl0} ${(props) => props.theme.sizings.lvl3};
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.desktop`
    grid-template-columns: repeat(3, 1fr);
  `}
`

const LabelContainer = styled.span`
  display: block;
  padding: 10px 0;
`

const StyledSpanPrice = styled.span`
  color: ${(props) => props.theme.colors.textTonedDown};
  width: 100%;
  display: block;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-items: flex-start;
`

const selectedStates = {
  NONE: 'NONE',
  SOME: 'SOME',
  ALL: 'ALL',
}

/**
 * Checkbox that selects/deselects multiple sub-checkboxes for selecting
 * equipment packages of a car.
 */
function PackageSelect({
  items,
  name,
  onChange,
  title,
  value: valueFromProps,
  price,
  fieldValueIndex,
  ...restProps
}) {
  const { NONE, SOME, ALL } = selectedStates

  const selectedItems = valueFromProps || []
  const selectedState =
    selectedItems.length === 0
      ? NONE
      : selectedItems.length === items.length
        ? ALL
        : SOME

  // If the main checkbox changes, let's trigger an onChange event for all it's child checkboxes
  const handleMainCheckboxChange = () => {
    if (selectedState !== ALL) {
      // Enable all items
      onChange(items.map((item) => item.name))
    } else {
      // We have to manually trigger the handleHighlightChange handler, because the sub-checkboxes do not have their onChange handlers triggered when the main checkbox is ticked
      // This is very dirty, I know. But there is no other way to fix this.
      items.forEach((item) => {
        if (
          item.type === 'checkbox' &&
          item.variety === 'withHighlight' &&
          item.onHighlightChange &&
          item.isHighlight
        ) {
          item.onHighlightChange(false, item.name)
        }
      })
      onChange([])
    }
  }

  // Individual item has changed, let's handle it here
  const handleChange = (checked, item) => {
    let newSelectedItems = [...selectedItems]
    if (checked) {
      newSelectedItems.push(item.name)
    } else {
      newSelectedItems = newSelectedItems.filter((value) => value !== item.name)
    }
    onChange(newSelectedItems)
  }

  return (
    <div {...restProps}>
      <TitleContainer>
        <StyledFormControlLabel
          control={
            <Checkbox
              indeterminate={selectedState === SOME}
              checked={selectedState === ALL}
              id={name}
              onChange={handleMainCheckboxChange}
              title=""
            />
          }
          label={
            <LabelContainer>
              <OverflowingTextWithToolTip text={title} />
              {price && <StyledSpanPrice>{formatPrice(price)}</StyledSpanPrice>}
            </LabelContainer>
          }
        />
      </TitleContainer>
      <OptionsContainer>
        {items.map((item, index) => {
          const { price, value, onHighlightChange, ...restItem } = item
          return (
            <FormField
              key={index.toString()}
              field={restItem}
              handleChange={(checked) => handleChange(checked, item)}
              handleHighlightChange={onHighlightChange}
              price={price}
              value={selectedItems.includes(item.name)}
              fieldValueIndex={fieldValueIndex}
            />
          )
        })}
      </OptionsContainer>
    </div>
  )
}

PackageSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  fieldValueIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      checked: PropTypes.bool,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  price: PropTypes.string,
}

PackageSelect.defaultProps = {
  price: '',
  items: [],
  value: [],
}

export default PackageSelect
