import { gql } from '@apollo/client'

export default gql`
  fragment driverInfo on Driver {
    id
    customerId
    title
    firstName
    lastName
    zipCode
    city
    email
    telephone
    street
    streetNr
  }
`
