import React, { useState, useContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useMediaLayout } from 'use-media'
import { Field } from 'redux-form'
import { validateFields } from 'utilities/form-utils'
import { media } from 'utilities/styled'
import FormField from 'components/molecules/form-field'
import StaticValuesCollection from 'components/molecules/static-values-collection'
import Text from 'components/atoms/text'
import Typography from 'components/molecules/typography'
import Button from 'components/atoms/button'
import moment from 'moment'
import ExpandButton from 'components/molecules/expand-button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import { useSelector } from 'react-redux'
import api from 'utilities/api'

function RenderField({
  field,
  fieldValueIndex,
  handleClickOverlay,
  openOverlay,
  input,
  price,
  meta: { error, touched },
}) {
  const inputValue = moment.isMoment(input.value)
    ? moment(input.value).format('YYYY-MM-DD')
    : input.value

  return (
    <FormField
      error={touched && error}
      field={field}
      hint={field.hint ? field.hint : ''}
      explanation={field.explanation ? field.explanation : ''}
      optinRequired={!!field.confirm_edit}
      fieldValueIndex={fieldValueIndex}
      handleChange={input.onChange}
      handleHighlightChange={field.onHighlightChange}
      handleClickOverlay={handleClickOverlay}
      openOverlay={openOverlay}
      value={inputValue}
      price={price}
    />
  )
}

RenderField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldValueIndex: PropTypes.number.isRequired,
  handleClickOverlay: PropTypes.func.isRequired,
  openOverlay: PropTypes.func,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onHighlightChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.bool,
    ]),
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    touched: PropTypes.bool,
  }).isRequired,
  price: PropTypes.string,
}

RenderField.defaultProps = {
  openOverlay: null,
  price: '',
}

const DefaultFieldset = styled.fieldset`
  cursor: ${(props) =>
    props.expandable && !props.$hasClickableTitle ? 'pointer' : 'initial'};
  padding-bottom: ${(props) => props.theme.sizings.lvl2};
  ${media.desktop`
    width: ${({ widthPercentage }) => widthPercentage || 100}%;
  `};
  border-top: ${(props) =>
    props.nested !== true &&
    props.expandable === true &&
    `solid 1px ${props.theme.colors.darkOnLightBorder};`};
`

const SmallFieldset = styled.fieldset`
  cursor: ${(props) =>
    props.expandable && !props.$hasClickableTitle ? 'pointer' : 'initial'};
  display: flex;
  border: 1px solid red;
  min-height: 40px;
  margin-bottom: ${(props) => props.theme.sizings.lvl4};
  padding: ${(props) => props.theme.sizings.lvl2};
  border: 1px solid ${({ theme }) => theme.colors.brandDelta};

  ${media.desktop`
    width: ${({ childrenCount }) => (childrenCount === 2 ? '50%' : '100%')};
  `};
`

const DefaultFieldsetContent = styled.div`
  padding-top: ${(props) => props.theme.sizings.lvl2};
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.sizings.lvl1}
    ${(props) => props.theme.sizings.lvl3};

  ${media.desktop`
    grid-template-columns: ${({ maxColumns }) => (maxColumns ? `repeat(${maxColumns}, 1fr)` : 'repeat(2, 1fr)')};
    grid-gap: ${(props) => props.theme.sizings.lvl2} ${(props) => props.theme.sizings.lvl3};
    width: ${({ maxColumns }) => (maxColumns === 1 ? '100%' : 'auto')};
  `}
  ${media.tv`
    grid-template-columns: ${({ maxColumns }) => (maxColumns ? `repeat(${maxColumns}, 1fr)` : 'repeat(3, 1fr)')};
  `}
  ${media.hd`
    grid-template-columns: ${({ maxColumns }) => (maxColumns ? `repeat(${maxColumns}, 1fr)` : 'repeat(4, 1fr)')};
  `}
`

const SingleColumnContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.sizings.lvl2}
    ${(props) => props.theme.sizings.lvl3};
  max-width: ${({ theme }) => theme.widths.maxFieldWidth};
`

const SmallFieldsetContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.sizings.lvl2}
    ${(props) => props.theme.sizings.lvl3};

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}
  ${media.desktop`
    grid-template-columns: ${({ childrenCount }) =>
      childrenCount === 2 || childrenCount === 1
        ? 'repeat(2, 1fr)'
        : 'repeat(3, 1fr)'};
  `}
  ${media.tv`
    grid-template-columns: ${({ childrenCount }) =>
      childrenCount === 2 || childrenCount === 1
        ? 'repeat(2, 1fr)'
        : 'repeat(4, 1fr)'};
  `}
`

const StyledDiv = styled('div')`
  cursor: ${(props) =>
    props.expandable && !props.$hasClickableTitle ? 'pointer' : 'initial'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${(props) => props.theme.sizings.lvl2};
  border-top: ${(props) =>
    props.nested !== true &&
    props.expandable === true &&
    `solid 1px ${props.theme.colors.darkOnLightBorder};`};
`

const StyledLegend = styled('legend')`
  display: flex;
  align-items: center;
`

const StyledText = styled(Text)`
  display: flex;
  margin-bottom: ${({ theme, $expandable }) =>
    !$expandable ? theme.sizings.lvl2 : 0};
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: auto;
  margin-left: ${(props) => props.theme.sizings.lvl1};
`

const FormNestedTitle = styled(Typography)`
  margin-bottom: ${(props) => props.theme.sizings.lvl2};
`

const FieldsetDescription = styled(Typography)`
  display: block;
  margin-bottom: ${(props) => props.theme.sizings.lvl2};
`

const ReadOnlyEditLink = styled(Button)`
  margin-top: ${({ theme }) => theme.sizings.lvl1};
`

const staticValueCollectionExemptFieldTypes = [
  'table',
  'package_select',
  'options_select',
]

function FieldSet({
  description,
  title,
  type,
  initiallyReadOnly,
  index,
  fields,
  openOverlay,
  handleClickOverlay,
  handleHighlightChange,
  maxColumns,
  singleColumn,
  values,
  widthPercentage,
  nested,
  expandable,
  isInitiallyExpanded,
  price,
  customSelectOptions,
  updateCustomOptions,
  fieldToUpdateOnFieldUpdate,
  ...restProps
}) {
  const { t } = useTranslation()
  const instanceId = useSelector((state) => state.auth.instanceId)
  const valid = Object.keys(validateFields(values, fields)).length === 0
  const canBeStaticValueCollection = fields.some((field) =>
    staticValueCollectionExemptFieldTypes.includes(field.type),
  )
  const [readOnly, setReadOnly] = useState(
    initiallyReadOnly && !canBeStaticValueCollection ? valid : false,
  )
  const [fieldSetInitiated, setFieldSetInitiated] = useState(false)

  const StyledFieldset = type === 'small' ? SmallFieldset : DefaultFieldset

  let StyledFieldsetContent = <div />
  if (!singleColumn) {
    StyledFieldsetContent =
      type === 'small' ? SmallFieldsetContent : DefaultFieldsetContent
  } else {
    StyledFieldsetContent = SingleColumnContent
  }

  const theme = useContext(ThemeContext)
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded)
  const [isExpandedLoading, setIsExpandedLoading] = useState(false)
  const hasVisibleFields = fields.some((field) => field?.type !== 'hidden')
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })
  const contentRef = useRef()

  let titleType = 'h2'
  if (type === 'small') {
    titleType = 'floatingLabelLabel'
  }

  useEffect(() => {
    if (!contentRef.current && isExpandedLoading) {
      setIsExpanded(() => true)
    }
    if (contentRef.current && isExpandedLoading) {
      setIsExpanded(() => false)
    }
  }, [isExpandedLoading])

  useEffect(() => {
    if (
      (contentRef.current && isExpanded) ||
      (!contentRef.current && !isExpanded)
    ) {
      setIsExpandedLoading(() => false)
    }
  }, [isExpanded, contentRef])

  useEffect(() => {
    setIsExpanded(isInitiallyExpanded)
  }, [isInitiallyExpanded])

  // otherwise the linter want to apply useCallback, but that's not needed:
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleConnectedSelectField = (newValue, field) =>
    api
      .get(fieldToUpdateOnFieldUpdate[field.name].updateEndPoint, instanceId, {
        [field.name]: newValue,
      })
      .then((response) => {
        updateCustomOptions(
          fieldToUpdateOnFieldUpdate[field.name].name,
          response.data.data.map((item) => ({
            value: item.id || undefined,
            ...item,
          })),
        )
      }) // error handling is done by api.get internally.

  // on mount a connected field can already have a value set. if so,
  // then the field that needs to update based on that field needs to have it's options update too:
  useEffect(() => {
    if (
      fields.length &&
      Object.keys(fieldToUpdateOnFieldUpdate).length &&
      Object.keys(values).length &&
      !fieldSetInitiated
    ) {
      fields.forEach((field) => {
        if (fieldToUpdateOnFieldUpdate[field.name] && values[field.name]) {
          handleConnectedSelectField(values[field.name], field)
        }
      })
      setFieldSetInitiated(true) // prevent this from ending up in an infinite loop.
    }
  }, [
    fieldSetInitiated,
    fieldToUpdateOnFieldUpdate,
    fields,
    handleConnectedSelectField,
    values,
  ])

  return (
    <StyledFieldset
      key={index.toString()}
      childrenCount={fields.length}
      maxColumns={maxColumns}
      widthPercentage={widthPercentage}
      expandable={expandable}
      $hasClickableTitle={!!title || !!description}
      {...restProps}
    >
      {(title || description) && (
        <StyledDiv
          expandable={expandable}
          onClick={() => {
            if (expandable && isExpanded) {
              setIsExpanded(false)
            }
            if (expandable && !isExpanded) {
              setIsExpandedLoading(true)
            }
          }}
        >
          <StyledLegend>
            {nested ? (
              <FormNestedTitle type="Level2Heading">{t(title)}</FormNestedTitle>
            ) : (
              <>
                <StyledText type={titleType} $expandable={expandable}>
                  {t(title)}
                </StyledText>
                {isExpandedLoading && (
                  <StyledLoadingIndicator size="extraSmall" />
                )}
              </>
            )}
          </StyledLegend>
          {description && (
            <FieldsetDescription
              type="floatingLabelLabel"
              text={t(description)}
            />
          )}
          {expandable && (
            <ExpandButton
              isExpanded={isExpanded}
              data-test-e2e="expand-button"
            />
          )}
        </StyledDiv>
      )}

      {(readOnly && expandable && isExpanded && hasVisibleFields) ||
      (readOnly && !expandable && hasVisibleFields) ? (
        <>
          <StaticValuesCollection
            collection={fields.map((field) => ({
              ...field,
              value:
                field.value !== undefined ? field.value : values[field.name],
            }))}
            columns={isMobile ? 2 : 4}
          />
          <ReadOnlyEditLink
            level="option"
            noPadding
            text={t('gegevensAanpassen')}
            onClick={() => setReadOnly((oldState) => !oldState)}
          />
        </>
      ) : null}

      {(!readOnly && expandable && isExpanded) || (!readOnly && !expandable) ? (
        <StyledFieldsetContent
          ref={contentRef}
          childrenCount={fields.length}
          maxColumns={maxColumns}
          data-test-e2e="fieldset-content"
        >
          {fields.map((field) => (
            <Field
              key={field.fieldValueIndex.toString()}
              name={field.name}
              component={RenderField}
              field={{
                ...field,
                options:
                  customSelectOptions[field.name] || field.options || undefined,
              }}
              onChange={
                fieldToUpdateOnFieldUpdate[field.name]
                  ? (newValue) => {
                      handleConnectedSelectField(newValue, field)
                    }
                  : undefined
              }
              onHighlightChange={field.onHighlightChange}
              fieldValueIndex={field.fieldValueIndex}
              handleClickOverlay={handleClickOverlay}
              openOverlay={openOverlay}
              price={price}
            />
          ))}
        </StyledFieldsetContent>
      ) : null}
    </StyledFieldset>
  )
}

FieldSet.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  index: PropTypes.number.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  openOverlay: PropTypes.func,
  handleClickOverlay: PropTypes.func,
  // This is used in the equipment form to mark accessories as highlights
  handleHighlightChange: PropTypes.func,
  maxColumns: PropTypes.number,
  initiallyReadOnly: PropTypes.bool,
  singleColumn: PropTypes.bool,
  widthPercentage: PropTypes.number,
  values: PropTypes.object.isRequired,
  /** True if fieldset is nested in another fieldset */
  nested: PropTypes.bool,
  expandable: PropTypes.bool,
  isInitiallyExpanded: PropTypes.bool,
  price: PropTypes.string,
  customSelectOptions: PropTypes.object,
  updateCustomOptions: PropTypes.func,
  fieldToUpdateOnFieldUpdate: PropTypes.object,
}

FieldSet.defaultProps = {
  description: undefined,
  title: undefined,
  type: undefined,
  openOverlay: undefined,
  handleClickOverlay: undefined,
  handleHighlightChange: undefined,
  maxColumns: undefined,
  initiallyReadOnly: false,
  singleColumn: false,
  widthPercentage: undefined,
  nested: false,
  expandable: false,
  isInitiallyExpanded: false,
  price: '',
  customSelectOptions: {},
  updateCustomOptions: () => {},
  fieldToUpdateOnFieldUpdate: {},
}

export default FieldSet
