import { gql } from '@apollo/client'
import customerInfoFragment from '../fragments/customer'

export const GET_CUSTOMERS = gql`
  query {
    customers(first: 9999) {
      edges {
        node {
          ...customerInfo
        }
      }
    }
  }
  ${customerInfoFragment}
`

export const ADD_CUSTOMER = gql`
  mutation createCustomer(
    $dealerId: ID!
    $title: String
    $firstName: String
    $lastName: String!
    $street: String
    $streetNr: String
    $zipCode: String
    $city: String
    $email: String
    $iban: String
    $bankName: String
    $isCompany: Boolean
    $companyName: String
    $phoneNumber: String
    $country: String
  ) {
    createCustomer(
      dealerId: $dealerId
      title: $title
      firstName: $firstName
      lastName: $lastName
      street: $street
      streetNr: $streetNr
      zipCode: $zipCode
      city: $city
      email: $email
      iban: $iban
      bankName: $bankName
      isCompany: $isCompany
      companyName: $companyName
      phoneNumber: $phoneNumber
      country: $country
    ) {
      ...customerInfo
    }
  }
  ${customerInfoFragment}
`

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $id: ID!
    $title: String
    $firstName: String
    $lastName: String
    $street: String
    $streetNr: String
    $zipCode: String
    $city: String
    $email: String
    $iban: String
    $bankName: String
    $isCompany: Boolean
    $companyName: String
    $phoneNumber: String
    $country: String
  ) {
    updateCustomer(
      id: $id
      title: $title
      firstName: $firstName
      lastName: $lastName
      street: $street
      streetNr: $streetNr
      zipCode: $zipCode
      city: $city
      email: $email
      iban: $iban
      bankName: $bankName
      isCompany: $isCompany
      companyName: $companyName
      phoneNumber: $phoneNumber
      country: $country
    ) {
      ...customerInfo
    }
  }
  ${customerInfoFragment}
`
