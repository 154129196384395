import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Link from 'components/atoms/link'
import InlineBodyText from 'components/atoms/inline-body-text'

const Container = styled.div`
  display: flex;
`

const BreadcrumbsItem = styled.div`
  display: flex;
`

const BreadcrumbsText = styled(InlineBodyText)`
  text-transform: capitalize;
`

const BreadcrumbsSeparator = styled(InlineBodyText)`
  margin: 0 6px;
`

function Breadcrumbs({ items, ...restProps }) {
  return (
    <Container {...restProps}>
      {items.map((item, index) => (
        <React.Fragment key={index.toString()}>
          {items.length !== index + 1 ? (
            <BreadcrumbsItem>
              <Link title={item.label} to={item.to}>
                <BreadcrumbsText type="menu" color="actionsStandard">
                  {item.label}
                </BreadcrumbsText>
              </Link>
              <BreadcrumbsSeparator type="menu" color="brandCharlie">
                &gt;
              </BreadcrumbsSeparator>
            </BreadcrumbsItem>
          ) : (
            <BreadcrumbsText type="menu" color="brandCharlie">
              {item.label}
            </BreadcrumbsText>
          )}
        </React.Fragment>
      ))}
    </Container>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string,
    }),
  ).isRequired,
}

export default Breadcrumbs
