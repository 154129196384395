import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { translateListItems } from 'utilities/utils'
import { useTranslation } from 'react-i18next'
import DropdownMenu from 'components/atoms/dropdown-menu'

// TODO Values should be in english but the backend accepts dutch parameters
const items = [
  {
    label: 'private',
    value: 'particulier',
  },
  {
    label: 'trade',
    value: 'handel',
  },
  {
    label: 'demo',
    value: 'demo',
  },
  {
    label: 'rental',
    value: 'huur',
  },
  {
    label: 'new',
    value: 'nieuw',
  },
  {
    label: 'import',
    value: 'import',
  },
  {
    label: 'oldtimer',
    value: 'oldtimer',
  },
  {
    label: 'damagedcar',
    value: 'schadeauto',
  },
]

function CarfileKindSwitch({ carId, selected, setCarKind, ...restProps }) {
  const { t } = useTranslation()
  const [kindValue, setKindValue] = useState(null)

  const translatedItems = translateListItems(items, t)

  useEffect(() => {
    setKindValue(selected)
  }, [selected])

  function onChange(value) {
    setKindValue(value)

    if (typeof setCarKind === 'function') {
      setCarKind({
        data: {
          auto_id: carId,
          soort: value,
        },
      })
    }
  }

  return (
    <div {...restProps}>
      <DropdownMenu
        items={translatedItems}
        onChange={onChange}
        selected={kindValue || items[0].value}
        disabled={!setCarKind}
      />
    </div>
  )
}

CarfileKindSwitch.propTypes = {
  carId: PropTypes.string.isRequired,
  selected: PropTypes.string,
  setCarKind: PropTypes.func,
}

CarfileKindSwitch.defaultProps = {
  selected: items[0].label,
  setCarKind: null,
}

export default CarfileKindSwitch
