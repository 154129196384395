import { gql } from '@apollo/client'
import cspContract from '../fragments/cspContract'

export const GET_CALCULATIONS = gql`
  query calculations(
    $dealerId: ID!
    $licensePlate: String
    $carId: ID
    $warrantyProgramId: ID
    $currentMileage: Int!
    $mileagePerYear: Int!
    $hasFactoryWarranty: Boolean
    $additionalFeesIds: [ID!]
    $source: CalculationSourceEnum
    $additionalInput: [KeyValueInput!]
  ) {
    calculations(
      dealerId: $dealerId
      licensePlate: $licensePlate
      carId: $carId
      warrantyProgramId: $warrantyProgramId
      currentMileage: $currentMileage
      mileagePerYear: $mileagePerYear
      hasFactoryWarranty: $hasFactoryWarranty
      additionalFeesIds: $additionalFeesIds
      source: $source
      additionalInput: $additionalInput
    ) {
      id
      licensePlate
      warrantyProgram {
        id
        maxMileage
      }
      mileagePerYear
      specificationDocument {
        fullUri
      }
      results {
        id
        package {
          id
          name
          carServicePlan {
            monthlySubscription
            invoiceFee
            carServicesIncluded {
              commercialName
              packageTypes
            }
            carServicesExcluded {
              commercialName
            }
          }
          packageType
          description
          maintenanceClientTariff
        }
        resultNet
        resultGross
      }
    }
  }
`

export const CALCULATE_CSP_PACKAGE_PRICES = gql`
  query CalculateCSPPrices(
    $dealerId: ID!
    $licensePlate: String!
    $warrantyProgramId: ID
    $currentMileage: Int!
    $mileagePerYear: Int!
    $hasFactoryWarranty: Boolean
    $source: CalculationSourceEnum
  ) {
    calculations(
      dealerId: $dealerId
      licensePlate: $licensePlate
      warrantyProgramId: $warrantyProgramId
      currentMileage: $currentMileage
      mileagePerYear: $mileagePerYear
      hasFactoryWarranty: $hasFactoryWarranty
      source: $source
    ) {
      results {
        package {
          description
          packageType
        }
        resultNet
      }
    }
  }
`
export const RECALCULATE_MILEAGE = gql`
  query RecalculateMileage($mileagePerYear: Int!, $contractId: Int!) {
    recalculateMileage(
      mileagePerYear: $mileagePerYear
      contractId: $contractId
    ) {
      id
      licensePlate
      warrantyProgram {
        id
        maxMileage
      }
      mileagePerYear
      results {
        id
        package {
          id
          name
          carServicePlan {
            monthlySubscription
            invoiceFee
            carServicesIncluded {
              commercialName
            }
            carServicesExcluded {
              commercialName
            }
          }
          packageType
          description
          maintenanceClientTariff
        }
        resultNet
        resultGross
      }
    }
  }
`

export const ACCEPT_MILEAGE_CHANGE_FOR_CONTRACT = gql`
  mutation acceptChangeMileage(
    $contractId: ID!
    $startDate: Date!
    $packageCalculationResult: Int!
  ) {
    acceptChangeMileage(
      contractId: $contractId
      startDate: $startDate
      packageCalculationResult: $packageCalculationResult
    ) {
      id
      contract {
        ...cspContract
      }
    }
  }
  ${cspContract}
`

export const PERFORM_MILEAGE_CHECK = gql`
  query performMileageCheck($contractId: ID!, $mileage: Int!) {
    performMileageCheck(contractId: $contractId, mileage: $mileage) {
      id
      previousMileage {
        id
        mileage
        date
        corrected
      }
      newMileage {
        id
        mileage
        date
        corrected
      }
      estimatedMileage
      diff
      correctionPrice
    }
  }
`
export const ACCEPT_MILEAGE_CORRECTION = gql`
  mutation acceptMileageCorrection($mileageId: Int!) {
    acceptMileageCorrection(mileageId: $mileageId) {
      id
      corrected
    }
  }
`
