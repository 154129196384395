import { gql } from '@apollo/client'
import checklistInfo from '../fragments/checklist'
import {
  checklistItemAdvancedInfo,
  checklistItemBasicInfo,
} from '../fragments/checklist-item'

export const DEALERS = gql`
  query dealers {
    bedrijven {
      name
      id
      dealernr
    }
  }
`
export const CREATE_DEALER_CLUSTER = gql`
  mutation createDealerCluster($name: String!, $dealers: BedrijvenSync) {
    createDealerCluster(name: $name, dealers: $dealers) {
      id
      name
      companies {
        id
        name
        dealernr
      }
    }
  }
`

export const UPDATE_DEALER_CLUSTER = gql`
  mutation updateDealerCluster(
    $id: ID!
    $name: String
    $dealers: BedrijvenSync
  ) {
    updateDealerCluster(id: $id, name: $name, dealers: $dealers) {
      id
      name
      companies {
        id
        name
        dealernr
      }
    }
  }
`

export const DEALER_CLUSTERS = gql`
  query dealerClusters {
    dealerClusters {
      id
      name
      companies {
        id
        name
        dealernr
      }
      checklists {
        ...checklistInfo
        clusters {
          id
          name
        }
        items {
          ...checklistItemBasicInfo
          ...checklistItemAdvancedInfo
        }
      }
    }
  }
  ${checklistInfo}
  ${checklistItemBasicInfo}
  ${checklistItemAdvancedInfo}
`

export const DELETE_DEALER_CLUSTER = gql`
  mutation deleteDealerCluster($id: ID!) {
    deleteDealerCluster(id: $id) {
      id
    }
  }
`
