export const CarFileStatusEnumUpperCase = {
  lopend: 'LOPEND',
  verwacht: 'VERWACHT',
  binnen: 'BINNEN',
  verkocht: 'VERKOCHT',
  incompleet: 'INCOMPLEET',
  afgeleverd: 'AFGELEVERD',
}

// The order in which a car moves through it's statusses.
// JavaScript does not guarantee that looping over an object's key/values, happens in order (from
// top to bottom). Thus, looping over CarFileEnum's key/values to determin the order of a car's
// statuses is not safe. So, this array can be used for that.
export const carFileStatusesOrder = [
  CarFileStatusEnumUpperCase.lopend,
  CarFileStatusEnumUpperCase.verwacht,
  CarFileStatusEnumUpperCase.binnen,
  CarFileStatusEnumUpperCase.verkocht,
  CarFileStatusEnumUpperCase.afgeleverd,
]

// Previously named "CarFileStatusUrlEnum", renamed to "CarFileStatusEnumLowerCase" to be inline with the above CarFileSatusEnum
// This is primarily used to construct the url for the checklists overlay. I.e. /checklists/{status}
export const CarFileStatusEnumLowerCase = {
  [CarFileStatusEnumUpperCase.lopend]: 'lopend',
  [CarFileStatusEnumUpperCase.verwacht]: 'verwacht',
  [CarFileStatusEnumUpperCase.binnen]: 'binnen',
  [CarFileStatusEnumUpperCase.verkocht]: 'verkocht',
  [CarFileStatusEnumUpperCase.afgeleverd]: 'afgeleverd',
}

/**
 * TODO: To some degree there is an overlap between destinationOptions in src/config/data
 * and this Enum. See if this can be resolved somehow, so it can be maintained easier.
 */
export const CarKindEnum = {
  handel: 'HANDEL',
  particulier: 'PARTICULIER',
  particulierinruil: 'PARTICULIERINRUIL',
  nieuw: 'NIEUW',
  demo: 'DEMO',
  huur: 'HUUR',
  import: 'IMPORT',
  oldtimer: 'OLDTIMER',
  schadeAuto: 'SCHADEAUTO',
}

export const getCarFileStatusFromV3Value = (v3StatusValue) => {
  let statusEnumValue
  switch (v3StatusValue) {
  case 'lopend':
    statusEnumValue = CarFileStatusEnumUpperCase.lopend
    break
  case 'verwacht':
    statusEnumValue = CarFileStatusEnumUpperCase.verwacht
    break
  case 'binnen':
    statusEnumValue = CarFileStatusEnumUpperCase.binnen
    break
  case 'verkocht':
    statusEnumValue = CarFileStatusEnumUpperCase.verkocht
    break
  case 'afgeleverd':
    statusEnumValue = CarFileStatusEnumUpperCase.afgeleverd
    break
  default:
    break
  }

  return statusEnumValue
}

export const NotificationTargets = {
  flexflow: 'flexflow',
}

// These are the prefined filter options shown per checklist
export const ChecklistsShowTypes = {
  ALL: 'all',
  ALL_EXPIRED: 'all-expired',
}

// Possible car checklist statusses
export const CarChecklistItemStatusses = {
  pendingStart: 'PENDING_START',
  started: 'STARTED',
  startedSoonDelayed: 'STARTED_SOON_DELAYED',
  delayed: 'DELAYED',
  completed: 'COMPLETED',
  completedAfterDelay: 'COMPLETED_AFTER_DELAY',
}

// Filter options for the overlay
export const ChecklistItemsFilterOptions = {
  ALL: 'ALL',
  STARTED_SOON_DELAYED: 'STARTED_SOON_DELAYED',
  DELAYED: 'DELAYED',
  COMPLETED: 'COMPLETED',
  COMPLETED_AFTER_DELAY: 'COMPLETED_AFTER_DELAY',
  NOT_COMPLETED: 'NOT_COMPLETED',
}

// These values should correspond to what the API expects as it's categories for car assets
export const DocumentCategories = {
  INTERNAL: 'intern',
  COMMERCIAL: 'commercieel',
}

/**
 * This code base can be built for two apps:
 */
export const AppTypes = {
  WIDGETS: 'widgets',
  UCC: 'ucc',
}

// These values should correspond to what the API expects as it's categories for images
export const ImageCategories = {
  INTERNAL: 'intern',
  COMMERCIAL: 'commercieel',
}

export const ImageTypes = {
  ORIGINAL: 'ORIGINAL',
  PUBLISHED: 'PUBLISHED',
}

// The values in this object correspond to what the back-end expects in the bulk actions API
export const MarktplaatsBulkActions = {
  RAISE_POSITIONS: 'omhoogplaatsen',
  DAY_HIGHLIGHT: 'dagtopper',
  SEVEN_DAY_HIGHLIGHT: 'dagtopper7',
  EYE_CATCHER: 'blikvanger',
}

// IDs for the bid providers (from the backend)
export const BidsProviderIds = {
  AUTOTELEX: 'restwaarde',
  INDICATA: 'indicata',
}

export const Languages = {
  // DE: 'de', // TODO: commented out for now, until the BE supports "de" language (https://app.clickup.com/t/86bwtgmfq)
  EN: 'en',
  FR: 'fr',
  NL: 'nl',
}

// Some API's (Indicata for example, or Marktplaats) don't work outside of the Netherlands.
// These countries are used to create exceptions to show / hide certain components in the frontend.
// The logic to decide whether to show / hide a component is based on the `country_code` for a dealer.
export const CountryCodes = {
  BE: 'be',
  DE: 'de',
  NL: 'nl',
  FR: 'fr',
  // EN: 'en', // commented out - not available in backend yet
}

export const ManagementInfoCalculationInterval = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
}

export const ManagementInfoStockDays123Enum = {
  STOCK_DAYS_1: 'STOCK_DAYS_1',
  STOCK_DAYS_2: 'STOCK_DAYS_2',
  STOCK_DAYS_3: 'STOCK_DAYS_3',
}

// App themes. These are the whitelabel variants of the application.
export const AppThemes = {
  UCC: 'ucc',
  AUTOPRESENTATIE: 'autopresentatie',
  TODRIVE: 'todrive',
}

// Only used to make a link with the i18n system, which should be camelCase.
// There might be strings specific for a whitelabel version.
// 't(`supportEmail.Ucc`)' or 't(`payoff.Autopresentatie`)'
// Manually defined so we can do this: "AppThemesInternationalization.TODRIVE" in the codebase, not this: "AppThemesInternationalization[AppThemes.TODRIVE]"
export const AppThemesInternationalization = {
  UCC: 'ucc',
  AUTOPRESENTATIE: 'autopresentatie',
  TODRIVE: 'toDrive',
}

/**
 *  Used by both CarIdentityPlate and CarFileIdTag components to determine the size.
 */
export const CarIdentityPlateSizes = {
  TINY: 'tiny',
  SMALL: 'small',
}

/** Used for display modes in result overviews */
export const DisplayModeEnum = {
  grid: 'grid',
  list: 'list',
}
