// EXTERNAL
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

// INTERNAL
import { translateForm } from 'utilities/form-utils'
import Tabs from 'components/layouts/tabs'
import CarDataForm from 'components/molecules/cardata-form'
import EquipmentForm from 'components/redux-containers/equipment-form-container'
import { getObjectFromQueryString } from 'utilities/utils'
import LoadingIndicator from 'components/atoms/loading-indicator'

const CarBpmDeclarationStep2Tabs = ({ onChange, handleSubmit }) => {
  const { t } = useTranslation()
  const match = useRouteMatch()
  const location = useLocation()
  const queryParams = getObjectFromQueryString(location.search)
  const carId = queryParams && queryParams.auto_id
  const forms = useSelector((state) => state.forms)
  const formId = 'bpmDeclaration'
  const car = useSelector(
    (state) => state.data && state.data.carfile && state.data.carfile.data,
  )

  if (!car || !forms?.bpmDeclaration?.data?.basics_form?.fieldsets) {
    return <LoadingIndicator />
  }

  const basicsForm = translateForm(
    forms?.bpmDeclaration?.data?.basics_form?.fieldsets,
    t,
  )

  const bpmDetermationForm = translateForm(
    forms?.bpmDeclaration?.data?.eurotaxbpmbepaling?.fieldsets,
    t,
  )

  const tabItems = [
    {
      label: t('basicData'),
      to: `${match.url}/basic`,
      component: (props) => (
        <CarDataForm
          initiallyReadOnly
          carFile={car}
          fieldsets={basicsForm}
          formId={formId}
          level="cta"
          onSubmit={handleSubmit}
          {...props}
          next
          scrollToTopOnError
        />
      ),
    },
    {
      disabled: !carId,
      label: t('equipment'),
      to: `${match.url}/equipment`,
      component: (props) => (
        <EquipmentForm
          {...props}
          extraData={{
            ...car,
            accessoires_data_edition: car.accessoires_data_edition,
            accessoires_data_licenseplate: car.accessoires_data_licenseplate,
            altcode: car.altcode,
          }}
          carAccessories={car.accessoires}
          formId={formId}
          onSubmit={handleSubmit}
          next
          previous
          addAccessoiresWithCosts
          onlyExpandFirstInitially
        />
      ),
    },
    {
      disabled: !carId,
      label: t('bpmDetermation'),
      to: `${match.url}/bpm-determation`,
      component: (props) => (
        <CarDataForm
          initiallyReadOnly
          carFile={car}
          fieldsets={bpmDetermationForm}
          formId={formId}
          level="cta"
          onSubmit={handleSubmit}
          previous
          closeText={t('goToCarFile')}
          onClose={() => {}} // The ComplexForm component calls the "onSubmit" prop after closing this prevents a double submission
          {...props}
          scrollToTopOnError
          finish
        />
      ),
    },
  ]
  return (
    <Tabs items={tabItems} onChange={onChange} hideNavigation>
      {tabItems.map((item, index) => (
        <item.component key={index.toString()} />
      ))}
    </Tabs>
  )
}

export default CarBpmDeclarationStep2Tabs

CarBpmDeclarationStep2Tabs.propTypes = {
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
}

CarBpmDeclarationStep2Tabs.defaultProps = {
  onChange: undefined,
}
