import React from 'react'
import { toast } from 'react-toastify'
import Toast from 'components/atoms/toast'

const commonOptions = {
  className: 'ucc__toast',
  bodyClassName: 'ucc__toast__body',
}

// This constant is used to override the autoclose timeout for react-toastify, specifically for the *upload* flow toasts.
// Reason for the override is that there's been user feedback regarding the toasts staying open for too long after uploading media.
// See ClickUp ticket: https://app.clickup.com/t/2501750/UCC-5805
export const TOAST_AUTOCLOSE_SHORT_DURATION = 1750

export default {
  dismiss: toast.dismiss,
  isActive: toast.isActive,
  update(type, id, msg, options = {}) {
    toast.update(id, {
      render: (
        // eslint-disable-next-line react/jsx-indent
        <Toast message={msg} type={type} />
      ),
      ...options,
      ...commonOptions,
    })
  },
  info(msg, options = {}) {
    return toast.info(<Toast message={msg} type="info" />, {
      ...options,
      ...commonOptions,
    })
  },
  success(msg, options = {}) {
    return toast.success(<Toast message={msg} type="success" />, {
      ...options,
      ...commonOptions,
    })
  },
  warning(msg, options = {}) {
    return toast.warning(<Toast message={msg} type="warning" />, {
      ...options,
      ...commonOptions,
    })
  },
  error(msg, options = {}) {
    if (typeof msg === 'string') {
      return toast.error(<Toast message={msg} type="error" />, {
        ...options,
        ...commonOptions,
      })
    }
  },
}
