/* eslint-disable react/jsx-indent */ // TODO: Remove once linting setup is fixed

import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { formatPrice } from 'utilities/format'
import { media } from 'utilities/styled'
import { formatMileage, getMonthNameByIndex } from 'utilities/utils'

import {
  CarFileStatusEnumLowerCase,
  CarFileStatusEnumUpperCase,
} from 'config/enums'

import Definition from 'components/atoms/definition'
import GalleryImage from 'components/atoms/gallery-image'
import Icon from 'components/atoms/icon'
import NewTooltip from 'components/atoms/new-tooltip'
import Text from 'components/atoms/text'
import CarBrandAndModel, {
  CarBrandAndModelLayoutTypes,
} from 'components/molecules/car-brand-and-model'
import CarIdentityPlate from 'components/molecules/car-identity-plate'
import CarFileCardBids, {
  CarFileCardBidsLayoutType,
  CarFileCardBidsSize,
  defaultProps as carFileCardBidsDefaultProps,
  propTypes as carFileCardBidsPropTypes,
} from 'components/molecules/carfile-card-bids'
import CarFileCardContextMenu from 'components/molecules/carfile-card-context-menu-container'
import Typography from 'components/molecules/typography'

const smallLayout = css`
  grid-template-columns: min-content minmax(0, 3fr) min-content;
  grid-template-rows: auto min-content;
  grid-gap: 0;
  grid-template-areas: 'gallery description actions';
`

const largeLayout = css`
  grid-gap: ${({ theme }) => theme.sizings.lvl1};
  grid-template-columns: min-content minmax(0, 1fr) min-content max-content;
  grid-template-rows: auto min-content;
  grid-template-areas:
    'gallery description licensePlate summary'
    'gallery description actions summary';
`

const Container = styled.article`
  box-shadow: ${({ theme }) => theme.shadows.bottomSoft};
  background-color: ${({ theme }) => theme.colors.brandGolf};
  display: grid;
  ${({ $layoutSizeType }) =>
    $layoutSizeType === LayoutSizeTypes.LARGE && largeLayout};
  ${({ $layoutSizeType }) =>
    $layoutSizeType === LayoutSizeTypes.SMALL && smallLayout};
`

const StyledGalleryImageLink = styled(Link)`
  /**
    Using 22.2 times the base size, instead of 22, for the width otherwise the rendering engine of the
    vertical height on the image is slightly off.
    Probably because of a rounding issue in the calculation of the height of the image.
   */
  width: ${({ theme, $layoutSizeType }) =>
    theme.sizeByFactor($layoutSizeType === LayoutSizeTypes.LARGE ? 22.2 : 10)};
  grid-area: gallery;
  text-decoration: none;

  ${media.tv`
    height: 100%;

    & label,
    & figure {
      height: 100%;
    }
  `}
`

const DescriptionContainer = styled(Link)`
  grid-area: description;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: ${({ theme }) => theme.sizeByFactor(1.5)}
    ${({ theme }) => theme.sizings.lvl2};
`

const ActionsContainer = styled.div`
  grid-area: description;
  align-self: end;
`

const StyledSummary = styled(Link)`
  margin-block: ${({ theme }) => theme.sizeByFactor(1.5)};
  margin-right: ${({ theme }) => theme.sizeByFactor(1.5)};
  text-decoration: none;
  display: block;
  grid-area: summary;

  ${({ $layoutSizeType, theme }) =>
    $layoutSizeType === LayoutSizeTypes.LARGE &&
    `
    background-color: ${theme.colors.brandFoxtrot};
  `}
`

const StyledLabeledTexts = styled.div`
  display: flex;
`

const StyledDefinition = styled(Definition)`
  display: block;
  border-left: 1px solid ${({ theme }) => theme.colors.brandDelta};
  padding: 0 ${({ theme }) => theme.sizings.lvl2};
  margin: ${({ theme }) => theme.sizings.lvl2} 0;

  &:first-child {
    border-left: 0;
  }

  span {
    line-height: 1;
    padding-top: ${({ theme }) => theme.sizeByFactor(0.5)};
  }

  strong {
    display: block;
    line-height: 1;
    padding-top: ${({ theme }) => theme.sizeByFactor(1)};
    padding-bottom: ${({ theme }) => theme.sizeByFactor(0.5)};
  }

  ${media.tv`
    min-width: 140px;
  `}
`

const DescriptionDetails = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding-top: ${({ theme }) => theme.sizings.lvl2};
  gap: 0 ${({ theme }) => theme.sizings.lvl2};

  p,
  span,
  div {
    line-height: 1.625;
    padding: 0;
    font-size: 13px;
    position: relative;

    &:after {
      color: ${({ theme }) => theme.colors.brandDelta};
      content: '|';
      position: relative;
      left: ${({ theme }) => theme.sizings.lvl1};
    }

    &:last-child:after {
      content: '';
    }
  }

  ${media.phone`
    p,
    span,
    div {
      font-size: ${({ theme }) => theme.baseFontSize};
    }
  `}
`

const StyledExpected = styled(Text)`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.brandDelta};
  display: flex;
  padding: ${({ theme }) => theme.sizings.lvl2};
`

const CarFileLabels = styled.ul`
  grid-area: actions;
  display: flex;
  flex-direction: row-reverse;
  gap: ${({ theme }) => theme.sizeByFactor(1)};
  align-self: center;

  // Use small layout for desktop, because of the width restriction caused by the filters
  ${media.desktop`
    align-self: center;
  `}

  // Ath TV size there is enough width for the large layout again
  ${media.tv`
    align-self: flex-end;
    padding-bottom: ${({ theme }) => theme.sizeByFactor(1.5)};
  `}

  > li {
    display: block;
    height: ${({ theme }) => theme.sizeByFactor(3)};
    width: ${({ theme }) => theme.sizeByFactor(3)};
    border-radius: 50%;
    background-color: #fff;
  }
`

const StyledCarIdentityPlate = styled(CarIdentityPlate)`
  display: block;
  border-radius: 0;

  ${({ $layoutSizeType, theme }) =>
    $layoutSizeType === LayoutSizeTypes.LARGE &&
    `
    margin-top: ${theme.sizeByFactor(1.5)};
    margin-left: auto;
  `}

  ${({ $layoutSizeType, theme }) =>
    $layoutSizeType === LayoutSizeTypes.SMALL &&
    `
      max-width: ${theme.sizeByFactor(10)};
  `}

  ${media.tv`
    display: none;
  `}
`

const StyledCarIdentityPlateTV = styled(CarIdentityPlate)`
  grid-area: licensePlate;
  align-self: start;
  margin-top: 0;
  display: none;

  ${({ $layoutSizeType, theme }) =>
    $layoutSizeType === LayoutSizeTypes.LARGE &&
    `
    margin-top: ${theme.sizeByFactor(1.5)};
    margin-left: auto;
  `}

  ${({ $layoutSizeType, theme }) =>
    $layoutSizeType === LayoutSizeTypes.SMALL &&
    `
      max-width: ${theme.sizeByFactor(10)};
  `}

  ${media.tv`
    display: block;
  `}
`

const StyledCarFileCardBids = styled(CarFileCardBids)`
  line-height: 1;
  padding: 0;

  ${media.tablet`
    padding-left: 0;
  `}

  ${media.desktop`
    padding-left: 0;
  `}

  ${media.tv`
    min-width: 205px;
    padding: ${({ theme }) => theme.sizings.lvl2};

    & div > span:last-of-type {
      position: relative;
      margin-top: ${({ theme }) => theme.sizeByFactor(-0.325)};
    }
  `}
`

export const LayoutSizeTypes = {
  LARGE: 'large',
  SMALL: 'small',
}

/**
 * List view version of the CarFileCard component.
 * This component displays a car file card in a list view format. It displays the content in 2
 * modes: small and large. The small mode is used for mobile and desktop, while the large mode is
 * used for tablet and TV. This has to do with the available width in the layout.
 */
function CarFileCardForList({
  carId,
  actions,
  appraisal,
  brand,
  createdExternally,
  expected,
  image,
  license,
  vin,
  km,
  link,
  location,
  margin,
  model,
  month,
  reportingCode,
  type,
  salesView,
  hideSummary,
  sellingPrice,
  status,
  staytime,
  year,
  publishedOnline,
  cleaningOrderIsIssued,
  hasRemarks,
  isCarShare,
  carShareBids,
  layoutSizeType,
  ...restProps
}) {
  const { t } = useTranslation()

  const carLabel = useMemo(() => {
    // When it's a carShare car, we want to display a static translation string
    if (isCarShare) {
      return t('carShare.carFileStockOverviewLabel')
    }

    // When the passed 'status' is a string in the CarFileStatusEnum (V3 / coming from the backend), then display the translated string for this status
    if (Object.values(CarFileStatusEnumLowerCase).includes(status)) {
      return t(`carFileStatussesV3.${status}`)
    }

    // When the above logic doesn't match, display the string that either has been given to this component or null, which shows no label in the Gallery Image
    return status
  }, [t, isCarShare, status])

  const appraisalIsANumber = typeof appraisal === 'number'
  const appraisalText = appraisalIsANumber
    ? formatPrice(appraisal)
    : t('pricesMissing')
  const sellingPriceIsANumber = typeof sellingPrice === 'number'
  const sellingPriceText = sellingPriceIsANumber
    ? formatPrice(sellingPrice)
    : t('pricesMissing')
  const showCarFileCardBids = isCarShare
    ? !!carShareBids?.deadline
    : !!carShareBids?.requested

  return (
    <Container {...restProps} $layoutSizeType={layoutSizeType}>
      <StyledGalleryImageLink to={link} $layoutSizeType={layoutSizeType}>
        <GalleryImage
          src={image}
          alt={`A photo of ${carLabel}`}
          ratio={16 / 9}
          blurredBackground
        />
        <StyledCarIdentityPlate
          carFileId={carId}
          licensePlate={license}
          vin={vin}
          size={layoutSizeType === LayoutSizeTypes.LARGE ? 'small' : 'tiny'}
          $layoutSizeType={layoutSizeType}
        />
      </StyledGalleryImageLink>

      <DescriptionContainer to={link}>
        <CarBrandAndModel
          carBrand={brand}
          carModel={model}
          carType={type}
          size="small"
          layoutType={
            layoutSizeType === LayoutSizeTypes.LARGE
              ? CarBrandAndModelLayoutTypes.MODEL_TYPE_INLINED
              : CarBrandAndModelLayoutTypes.INLINED
          }
          data-test-e2e="car-brand-and-model"
        />
        <DescriptionDetails $layoutSizeType={layoutSizeType}>
          {typeof km !== 'undefined' && (
            <Text type="body" text={formatMileage(km)} />
          )}
          {(month || year) && (
            <Text
              type="body"
              text={`${t(getMonthNameByIndex(month))} ${year}`}
            />
          )}
          {typeof staytime !== 'undefined' && !salesView && (
            <Text type="body" text={`${staytime} ${t('stayDays')}`} />
          )}
          {location && <Text type="body" text={location} />}
          {layoutSizeType === LayoutSizeTypes.SMALL && (
            <>
              {status !==
                CarFileStatusEnumLowerCase[
                  CarFileStatusEnumUpperCase.lopend
                ] && (
                <NewTooltip title={t('sellingPrice')}>
                  <Typography type="InlineBodyText" weight="normal">
                    {sellingPriceText}
                  </Typography>
                </NewTooltip>
              )}
              {status ===
                CarFileStatusEnumLowerCase[
                  CarFileStatusEnumUpperCase.lopend
                ] && (
                <NewTooltip title={t('appraisal')}>
                  <Typography type="InlineBodyText" weight="normal">
                    {appraisalText}
                  </Typography>
                </NewTooltip>
              )}
              {!salesView && !!margin && (
                <NewTooltip title={t('margin')}>{margin}</NewTooltip>
              )}
              {expected && !salesView && (
                <StyledExpected
                  text={`${t('inStockExpected')}: ${moment(expected).format(
                    'DD-MM-YYYY',
                  )}`}
                />
              )}
              {showCarFileCardBids && (
                <StyledCarFileCardBids
                  size={CarFileCardBidsSize.SMALL}
                  layoutType={CarFileCardBidsLayoutType.HORIZONTAL}
                  carShareBids={carShareBids}
                />
              )}
            </>
          )}
        </DescriptionDetails>
      </DescriptionContainer>

      <StyledCarIdentityPlateTV
        carFileId={carId}
        licensePlate={license}
        vin={vin}
        size={layoutSizeType === LayoutSizeTypes.LARGE ? 'small' : 'tiny'}
        $layoutSizeType={layoutSizeType}
      />

      <CarFileLabels>
        {!isCarShare && (
          <>
            <CarFileCardContextMenu
              carId={carId}
              reportingCode={reportingCode}
              mileage={km}
              license={license}
              isCarShare={isCarShare}
            />
            {cleaningOrderIsIssued && (
              <li>
                <NewTooltip title={t('carFileCleaningOrderIsIssued')}>
                  <Icon
                    type="cleaning"
                    color="actionsStandard"
                    width="22"
                    height="22"
                  />
                </NewTooltip>
              </li>
            )}
            {publishedOnline && (
              <li>
                <NewTooltip title={t('carFilePublishedOnline')}>
                  <Icon
                    color="actionsStandard"
                    size="lg"
                    type="carFilePublishedOnline"
                  />
                </NewTooltip>
              </li>
            )}
            {hasRemarks && (
              <li>
                <NewTooltip title={t('carFileHasRemarks')}>
                  <Icon
                    color="actionsStandard"
                    size="lg"
                    type="carFileHasRemarks"
                  />
                </NewTooltip>
              </li>
            )}
            {/* Only show the createdExternally icon shen createdExternally === 1
                it can also be 2 but this means that it was altered within UCC after
                importing it from the external source
                */}
            {createdExternally === 1 && (
              <li>
                <NewTooltip title={t('carFileCreatedExternally')}>
                  <Icon
                    color="actionsStandard"
                    size="lg"
                    type="carFileCreatedExternally"
                  />
                </NewTooltip>
              </li>
            )}
          </>
        )}
      </CarFileLabels>
      {actions && <ActionsContainer>{actions}</ActionsContainer>}
      {layoutSizeType === LayoutSizeTypes.LARGE && (
        <StyledSummary to={link} $layoutSizeType={layoutSizeType}>
          <StyledLabeledTexts>
            {showCarFileCardBids && (
              <StyledCarFileCardBids
                size={CarFileCardBidsSize.MEDIUM}
                layoutType={CarFileCardBidsLayoutType.VERTICAL}
                carShareBids={carShareBids}
              />
            )}
            {status !==
              CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.lopend] && (
              <StyledDefinition
                title={t('sellingPrice')}
                value={
                  sellingPriceIsANumber ? sellingPrice : t('pricesMissing')
                }
                type={sellingPriceIsANumber ? 'number' : undefined}
                unit={sellingPriceIsANumber ? 'currency_euro' : undefined}
              />
            )}
            {status ===
              CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.lopend] && (
              <StyledDefinition
                title={t('appraisal')}
                value={appraisalIsANumber ? appraisal : t('pricesMissing')}
                type={appraisalIsANumber ? 'number' : undefined}
                unit={appraisalIsANumber ? 'currency_euro' : undefined}
              />
            )}
            {!salesView && !!margin && (
              <StyledDefinition title={t('margin')} value={margin} />
            )}
          </StyledLabeledTexts>
          {expected && !salesView && (
            <StyledExpected
              text={`${t('inStockExpected')}: ${moment(expected).format(
                'DD-MM-YYYY',
              )}`}
            />
          )}
        </StyledSummary>
      )}
    </Container>
  )
}

CarFileCardForList.propTypes = {
  actions: PropTypes.object,
  appraisal: PropTypes.number,
  brand: PropTypes.string,
  createdExternally: PropTypes.number,
  expected: PropTypes.string,
  carId: PropTypes.string.isRequired,
  image: PropTypes.string,
  license: PropTypes.string,
  vin: PropTypes.string,
  link: PropTypes.string.isRequired,
  km: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  month: PropTypes.string,
  margin: PropTypes.number,
  model: PropTypes.string,
  owner: PropTypes.string,
  part1: PropTypes.string,
  part2: PropTypes.string,
  reportingCode: PropTypes.string, // meldcode
  type: PropTypes.string,
  salesView: PropTypes.bool,
  location: PropTypes.string,
  /**
   * optionally hide the summary block containing pricing info and standing/stay-time
   */
  hideSummary: PropTypes.bool,
  sellingPrice: PropTypes.number,
  status: PropTypes.string,
  staytime: PropTypes.number,
  year: PropTypes.number,
  publishedOnline: PropTypes.bool,
  cleaningOrderIsIssued: PropTypes.bool,
  hasRemarks: PropTypes.bool,
  isCarShare: PropTypes.bool,
  layoutSizeType: PropTypes.oneOf(Object.values(LayoutSizeTypes)).isRequired,
  ...carFileCardBidsPropTypes,
}

CarFileCardForList.defaultProps = {
  actions: null,
  appraisal: null,
  brand: '-',
  createdExternally: 0,
  expected: null,
  image: null,
  license: undefined,
  vin: undefined,
  location: undefined,
  km: 0,
  margin: null,
  month: null,
  model: '-',
  owner: null,
  part1: null,
  part2: null,
  reportingCode: null, // meldcode
  type: null,
  salesView: false,
  hideSummary: false,
  sellingPrice: null,
  status: null,
  staytime: null,
  year: null,
  publishedOnline: false,
  cleaningOrderIsIssued: false,
  hasRemarks: false,
  isCarShare: false,
  ...carFileCardBidsDefaultProps,
}

export default CarFileCardForList
