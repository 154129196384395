import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import marketviewData from 'utilities/marktview-data'
import { getInputParamsFromParams } from 'utilities/marketview-utils'

import PageHeader from 'components/organisms/page-header'

import useAbortController from 'hooks/use-abort-controller'

const Container = styled.main`
  padding: 0 60px;
`

const MainContainer = styled.div`
  display: flex;
`

const FiltersContainer = styled.div`
  flex: 0 0 200px;
`

const ChildContainer = styled.div`
  padding-left: 20px;
  width: 100%;
`

/**
 * Layout for the Marktview.
 *

 */
const MarktviewLayout = ({
  children,
  dateRange,
  filtersSlot,
  selectedParams,
}) => {
  const token = useSelector(({ auth }) => auth?.instanceId)
  const { t } = useTranslation()
  const inputParams = selectedParams && getInputParamsFromParams(selectedParams)
  const [exportIsLoading, setExportIsLoading] = React.useState(false)
  const {
    ref: competitivePositionExportAbortControllerRef,
    abort: abortCompetitivePositionExportRequest,
  } = useAbortController()

  // TODO: Move out this logic from the layout.
  async function handleExport() {
    if (!inputParams) {
      return
    }

    setExportIsLoading(true)

    abortCompetitivePositionExportRequest()

    await marketviewData.getMarketviewExport({
      type: 'competitivePositionExport',
      token,
      body: inputParams,
      queryParams: {
        source: 'competitive-position',
      },
      signal: competitivePositionExportAbortControllerRef.current.signal,
    })

    setExportIsLoading(false)
  }

  return (
    <Container>
      <PageHeader
        buttonText={t('downloadXls')}
        buttonOnClickHandler={handleExport}
        buttonIsLoading={exportIsLoading}
        dateRange={dateRange}
        mainTitle="market"
        status="view"
        titleHasSpacing={false}
      />
      <MainContainer>
        <FiltersContainer>{filtersSlot}</FiltersContainer>
        <ChildContainer>{children}</ChildContainer>
      </MainContainer>
    </Container>
  )
}

MarktviewLayout.propTypes = {
  filtersSlot: PropTypes.node,
  children: PropTypes.node.isRequired,
  dateRange: PropTypes.string,
  selectedParams: PropTypes.object,
}

MarktviewLayout.defaultProps = {
  filtersSlot: null,
  dateRange: null,
  selectedParams: undefined,
}

export default MarktviewLayout
