import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import Chip from '@material-ui/core/Chip'
import NewTextInput from 'components/atoms/new-text-input'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import Icon from 'components/atoms/icon'

const filter = createFilterOptions()

const StyledAutocomplete = styled(Autocomplete)`
  ${({ $hasValues, $chipSize }) =>
    $hasValues &&
    $chipSize !== 'small' &&
    `
  .MuiAutocomplete-inputRoot {
    padding-top: 22px;
    padding-bottom: 2px;
  }`}
`

const StyledChip = styled(Chip)`
  background-color: ${({ theme }) => theme.colors.defaultBackground};
  color: ${({ theme }) => theme.colors.brandBravo};
  font-size: ${({ size, theme }) =>
    size === 'small' ? theme.smallFontSize : theme.baseFontSize};
  line-height: ${({ size, theme }) =>
    size === 'small' ? '16px' : theme.baseLineHeight};
  border-radius: ${({ theme }) => theme.sizings.lvl0};

  .MuiChip-label {
    font-family: ${({ theme }) => theme.font};
    padding-right: ${({ theme }) => theme.sizeByFactor(1.5)};
  }
`

const ChipDeleteButton = styled.div`
  padding: 2px;
  background-color: transparent;
  border-radius: 9999px;
  min-width: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.isolatedBackground};
  }
`

const StyledOption = styled.span`
  color: ${({ theme, $isNew }) =>
    $isNew ? theme.colors.actionsCta : theme.colors.brandBravo};
`

const normalizeValue = (value) =>
  value?.map((val) =>
    typeof val === 'string' ? { value: val } : { ...val },
  ) || []
const getSelectedOptions = (value, options) =>
  value.map((val) => options.find((option) => option.value === val.value))

function NewOptionsSelect({
  className,
  filled,
  error,
  label,
  placeholder,
  required,
  options,
  defaultValue,
  value,
  filterSelectedOptions,
  onChange,
  newIndicator,
  disableAddOption,
  chipSize,
  ...restProps
}) {
  const { t } = useTranslation()
  const normalizedDefaultValue = normalizeValue(defaultValue)
  const initialDefaultValue = getSelectedOptions(
    normalizedDefaultValue,
    options,
  )
  const normalizedValue = normalizeValue(value)
  const selectedValue = Array.isArray(value)
    ? getSelectedOptions(normalizedValue, options)
    : undefined
  const [hasValues, setHasValues] = useState(
    selectedValue?.length > 0 || initialDefaultValue.length > 0,
  )

  const handleChange = (event, values, reason, details) => {
    if (!onChange) {
      return
    }

    const normalizedValues =
      values
        ?.slice()
        .map((val) =>
          typeof val === 'string' ? { value: val, isNew: true } : { ...val },
        ) || []
    setHasValues(normalizedValues.length > 0)
    const formattedValues = newIndicator
      ? normalizedValues.map((option) => ({
          ...option,
          value:
            option.isNew && newIndicator ? `n${option.value}` : option.value,
        }))
      : normalizedValues
    onChange(formattedValues)
  }

  return (
    <StyledAutocomplete
      $hasValues={hasValues}
      $chipSize={chipSize}
      multiple
      options={options}
      defaultValue={initialDefaultValue}
      value={selectedValue}
      filterSelectedOptions={filterSelectedOptions}
      freeSolo={!disableAddOption}
      onChange={handleChange}
      filterOptions={(opts, params) => {
        const filtered = filter(opts, params)
        // Suggest the creation of a new value
        if (params.inputValue !== '' && !disableAddOption) {
          filtered.push({
            isNew: true,
            value: params.inputValue,
            label: `"${params.inputValue}" ${t('add')}`,
          })
        }

        return filtered
      }}
      getOptionLabel={(option) =>
        typeof option?.label === 'string' ? option.label : option?.value
      }
      getOptionSelected={(option, value) => option?.value === value?.value}
      renderOption={({ isNew, label }) => (
        <StyledOption $isNew={isNew}>{label}</StyledOption>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const tagProps = getTagProps({ index })
          let label = option?.label || option?.value || option

          if (option?.isNew) {
            label = option.value
          }

          return (
            <StyledChip
              $isFreeSolo={option?.isNew}
              key={tagProps.key}
              label={label}
              size={chipSize}
              deleteIcon={
                <ChipDeleteButton
                  role="button"
                  tabindex={tagProps.tabIndex}
                  data-tag-index={tagProps['data-tag-index']}
                  onClick={tagProps.onDelete}
                >
                  <Icon type="close" width="8" heigth="8" />
                </ChipDeleteButton>
              }
              {...tagProps}
            />
          )
        })
      }
      renderInput={(params) => (
        <NewTextInput
          className={className}
          filled={filled}
          error={error}
          label={label}
          placeholder={placeholder}
          required={required}
          params={params}
          value={hasValues ? '1' : ''}
        />
      )}
      {...restProps}
    />
  )
}

NewOptionsSelect.propTypes = {
  // Some common options
  className: PropTypes.string,
  filled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,

  // NewOptionsSelect specific options
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ),
  defaultValue: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string,
      }),
    ]),
  ),
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string,
      }),
    ]),
  ),
  filterSelectedOptions: PropTypes.bool,
  newIndicator: PropTypes.bool,
  onChange: PropTypes.func,
  disableAddOption: PropTypes.bool,
  chipSize: PropTypes.oneOf(['small', 'medium']),
}

NewOptionsSelect.defaultProps = {
  options: [],
  defaultValue: [],
  disabled: false,
  filterSelectedOptions: true,
  label: undefined,
  placeholder: undefined,
  newIndicator: false,
  onChange: undefined,
  disableAddOption: false,
  chipSize: 'small',
}

export default NewOptionsSelect
