import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { media } from 'utilities/styled'
import styled from 'styled-components/macro'
import { useQuery, useMutation } from '@apollo/client'
import {
  CHECKLIST_WITH_ITEM_DETAILS,
  USERS,
  AUTOMATIONS,
  CREATE_CHECKLIST_ITEM,
  UPDATE_CHECKLIST_ITEM,
  SET_CHECKLIST_ITEM_ORDER,
} from 'config/graphql/v4'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Typography from 'components/molecules/typography'
import { Formik, Form, Field } from 'formik'
import { ChecklistItemForm as ChecklistItemFormSchema } from 'config/validation-schemas'
import {
  FormikTextInput,
  FormikLabeledCheckBox,
  FormikSelectInput,
  FormikLabeledCheckBoxGroup,
  FormikOptionsSelect,
} from 'components/molecules/formik-field'
import LoadingButton from 'components/atoms/loading-button'
import toast from 'utilities/toast'
import Labels from 'components/molecules/labels'
import Text from 'components/atoms/text'

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 100%;
  height: 100%;
`

const ErrorWrapper = styled.div`
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`

const TitleTypesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.sizings.lvl0};
  margin-bottom: ${({ theme }) => theme.sizings.lvl5};
`

const Title = styled(Text)`
  margin-right: ${({ theme }) => theme.sizings.lvl1};
`

const FieldSmallWidth = styled(Field)`
  max-width: ${({ theme }) => theme.sizeByFactor(30)};
`

const FieldNormalWidth = styled(Field)`
  max-width: ${({ theme }) => theme.sizeByFactor(60)};
`

const LabeledCheckbox = styled(FormikLabeledCheckBox)`
  display: inline-flex;
`

const FieldsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${({ theme }) => theme.sizings.lvl2};
`

const FieldsSubGroup = styled(FieldsGroup)`
  margin-top: ${({ theme }) => theme.sizings.lvl1};
`

const AutomationCheckboxes = styled(FormikLabeledCheckBoxGroup)`
  margin-left: ${({ theme }) => theme.sizings.lvl3};
  width: calc(100% - ${({ theme }) => theme.sizings.lvl3});
  max-width: ${({ theme }) => theme.sizeByFactor(80)};

  > .checkboxes {
    display: flex;
    flex-direction: column;
  }
`

const ButtonGroup = styled.div`
  margin-top: ${({ theme }) => theme.sizings.lvl3};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${({ theme }) => theme.sizings.lvl1};
  text-align: center;
  max-width: ${({ theme }) => theme.sizeByFactor(60)};

  ${media.tablet`
    flex-direction: row;
    align-items: center;
  `}
`

const StyledLoadingButton = styled(LoadingButton)`
  width: 100%;

  ${media.tablet`
    width: auto;
  `}
`

const ChecklistItemForm = ({
  locationId,
  cluster, // if provided the form is opened from cluster overview
  isPartOfACluster, // if set to true, cluster is null it is opened from dealer overview
  checklistId,
  checklistItemId,
  insertAtIndex,
  onAfterSave,
  ...restProps
}) => {
  const { t } = useTranslation()
  const openedFromDealerOverviewButPartOfACluster =
    isPartOfACluster && locationId

  // Data fetching and manipulation

  // There is no dedicated "Get checklist item" endpoint. Checklist items have to be fetched
  // through their Checklist, which does have a dedicated item.
  const { data, loading, error } = useQuery(CHECKLIST_WITH_ITEM_DETAILS, {
    variables: {
      id: checklistId,
    },
  })

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useQuery(USERS, {
    variables: {
      locationId,
    },
    skip: !locationId,
  })

  const {
    data: automationsData,
    loading: automationsLoading,
    error: automationsError,
  } = useQuery(AUTOMATIONS)

  const [createChecklistItem, { loading: createSubmitting }] = useMutation(
    CREATE_CHECKLIST_ITEM,
  )
  const [updateChecklistItem, { loading: updateSubmitting }] = useMutation(
    UPDATE_CHECKLIST_ITEM,
  )
  const [setItemOrder, { loading: itemOrderSubmitting }] = useMutation(
    SET_CHECKLIST_ITEM_ORDER,
  )
  // /Data fetching and manipulation

  // Initial setup of data for form
  const checklistItems = data?.checklist?.items || []
  const checklistItem = checklistItemId
    ? checklistItems.find((item) => item.id === checklistItemId)
    : undefined

  const CHECKLIST_ACTIVATION_VALUE = 'CHECKLIST_ACTIVATION'

  const triggerChecklistItemIdFallback = checklistItem
    ? CHECKLIST_ACTIVATION_VALUE
    : ''
  const initialValues = {
    name: checklistItem?.name,
    description: checklistItem?.description,
    notificationUserIds:
      checklistItem?.notificationUsers?.map((user) => user.id) || [],
    isOptional: checklistItem ? !checklistItem.blocking : false,
    limitDuration:
      typeof checklistItem?.maxDuration === 'number' ||
      !!checklistItem?.alertMessage ||
      !!checklistItem?.checklistItemId,
    maxDuration: checklistItem?.maxDuration || '',
    alertMessage: checklistItem?.alertMessage || '',
    triggerChecklistItemId:
      checklistItem?.triggerChecklistItemId || triggerChecklistItemIdFallback,
    checkAutomatically: checklistItem?.automationTriggers?.length > 0,
    automationTriggerIds:
      checklistItem?.automationTriggers?.map(
        (automationTrigger) => automationTrigger.id,
      ) || [],
    sendReminder: checklistItem?.reminderUsers?.length > 0,
    reminderAfterDays: checklistItem?.reminderAfterDays || '',
    reminderUserIds: checklistItem?.reminderUsers?.map((user) => user.id) || [],
    saveAndAddAnotherClicked: false,
  }

  const otherChecklistItems = data?.checklist?.items
    ? data.checklist.items.filter((item) => item.id !== checklistItemId)
    : []

  const triggerChecklistItemOptions = otherChecklistItems.map((item) => ({
    value: item.id,
    label: t('checklistsSettings.checklistItemForm.finishingOfItem', {
      item: item.name,
    }),
  }))

  triggerChecklistItemOptions.unshift({
    value: CHECKLIST_ACTIVATION_VALUE,
    label: t('checklistsSettings.checklistItemForm.checklistActivation'),
  })

  const userOptions = usersData?.users
    ? usersData.users.map((user) => ({
        value: user.id,
        label: [user.voornaam, user.achternaam]
          .filter((str) => !!str)
          .join(' '),
      }))
    : []
  // const selectedNotificationUserOptions = userOptions.filter(({ value }) => initialValues.notificationUserIds.includes(value))
  // const selectedReminderUserOptions = userOptions.filter(({ value }) => initialValues.notificationUserIds.includes(value))

  const automationTriggerItems = automationsData?.automations
    ? automationsData.automations.map((automation) => ({
        value: automation.id,
        label: automation.label,
      }))
    : []

  // /Initial setup of data for form

  // Handle loading/error states
  if (loading || usersLoading || automationsLoading) {
    return (
      <Centered>
        <LoadingIndicator error={null} />
      </Centered>
    )
  }
  if (error || usersError || automationsError) {
    if (error) {
      console.error(error)
    }
    if (usersError) {
      console.error(usersError)
    }
    if (automationsError) {
      console.error(automationsError)
    }

    return (
      <ErrorWrapper>
        <Typography type="Level2Heading">
          {t('problemsFoundHeading')}
        </Typography>
        <Typography type="ExplanationParagraph">
          {t('problemsWhenRetrievingData')}
        </Typography>
      </ErrorWrapper>
    )
  }
  // /Handle loading/error states

  // Submit handler of the form
  const onFormSubmit = (values, { resetForm }) => {
    const {
      name,
      description,
      notificationUserIds,
      isOptional,
      limitDuration,
      maxDuration,
      alertMessage,
      triggerChecklistItemId,
      checkAutomatically,
      automationTriggerIds,
      sendReminder,
      reminderAfterDays,
      reminderUserIds,
      saveAndAddAnotherClicked,
    } = values
    const mutation = checklistItem ? updateChecklistItem : createChecklistItem
    const maxDurationParsed = parseInt(maxDuration)
    const reminderAfterDaysParsed = parseInt(reminderAfterDays)

    const variables = {
      name,
      description,
      notificationUserIds: {
        sync: notificationUserIds,
      },
      blocking: !isOptional,
      maxDuration:
        limitDuration && !isNaN(maxDurationParsed) ? maxDurationParsed : null,
      alertMessage: limitDuration ? alertMessage : null,
      triggerChecklistItemId:
        limitDuration && triggerChecklistItemId !== CHECKLIST_ACTIVATION_VALUE
          ? triggerChecklistItemId
          : null,
      automationTriggerIds: {
        sync: checkAutomatically ? automationTriggerIds : [],
      },
      reminderAfterDays:
        sendReminder && !isNaN(reminderAfterDaysParsed)
          ? reminderAfterDaysParsed
          : null,
      reminderUserIds: {
        sync: sendReminder ? reminderUserIds : [],
      },
    }

    if (checklistItemId) {
      // Update existing checklist item
      variables.id = checklistItemId
    } else {
      // Create new checklist item
      variables.checklistId = checklistId
    }

    mutation({
      variables,
    })
      .then(({ data }) => {
        const callback = () => {
          toast.success(
            t('checklistsSettings.checklistItemForm.checklistItemSaved'),
          )
          if (saveAndAddAnotherClicked) {
            resetForm()
          }
          typeof onAfterSave === 'function' && onAfterSave(values, data)
        }

        if (
          !checklistItemId &&
          typeof insertAtIndex === 'number' &&
          data?.createChecklistItem?.id
        ) {
          // We have to reorder the checklist items

          // Normalize the index, so it is at least 0
          const index = Math.max(insertAtIndex, 0)
          // Get existing checklist item ID's in order
          const checklistItemIds = checklistItems
            .slice()
            .sort((a, b) => {
              const orderA = typeof a.order === 'number' ? a.order : 9999
              const orderB = typeof b.order === 'number' ? b.order : 9999
              return orderA - orderB
            })
            .map((item) => item.id)

          // Insert the new checklist item's id at the selected index
          checklistItemIds.splice(index, 0, data.createChecklistItem.id)
          setItemOrder({
            variables: {
              checklistId,
              checklistItemIds,
            },
            optimisticResponse: {
              setChecklistItemOrder: {
                id: checklistId,
                __typename: 'Checklist',
                items: checklistItemIds.map((id, i) => ({
                  id,
                  order: i + 1,
                  __typename: 'ChecklistItem',
                })),
              },
            },
          })
            .then((response) => {
              // Re-ordering done
              callback()
            })
            .catch((error) => {
              // Re-ordering error
              // The checklist item was already saved, only the re-ordering failed. Notify the user of this.
              console.error(error)
              toast.warning(
                t(
                  'checklistsSettings.checklistItemForm.checklistItemReorderingError',
                ),
              )
              callback()
            })
        } else {
          // Checklist item saved
          callback()
        }
      })
      .catch((error) => {
        // Error while saving checklist item
        console.error(error)
        toast.error(
          t('checklistsSettings.checklistItemForm.checklistItemSaveError'),
        )
      })
  }
  // /Submit handler of the form

  return (
    <>
      {/* TODO: Move this title stuff out of this component, to it's parent. Due to time constraints this is still here. */}
      {data?.checklist && (
        <TitleTypesWrapper>
          {data.checklist.name && (
            <Title type="tabSmall">{data?.checklist?.name}</Title>
          )}
          {data.checklist.soort.length > 0 && (
            <Labels
              texts={data.checklist.soort.map((soort) =>
                t(`carKinds.${soort.soort}`),
              )}
              includeWrapper={false}
              gapLevel={0}
            />
          )}
        </TitleTypesWrapper>
      )}
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={ChecklistItemFormSchema(t)}
        onSubmit={onFormSubmit}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form>
            <Typography type="Level2Heading">
              {t('checklistsSettings.checklistItemForm.basicInformation')}
            </Typography>
            <FieldsGroup>
              <FieldSmallWidth
                name="name"
                label={t(
                  'checklistsSettings.checklistItemForm.checklistItemName',
                )}
                component={FormikTextInput}
                filled
                required
                disabled={openedFromDealerOverviewButPartOfACluster}
              />
              <FieldNormalWidth
                name="description"
                label={t('checklistsSettings.checklistItemForm.description', {
                  maxChars: 150,
                })}
                component={FormikTextInput}
                multiline
                filled
                params={{
                  inputProps: { maxLength: 150 },
                }}
                rows={4}
                disabled={openedFromDealerOverviewButPartOfACluster}
              />
              <FieldNormalWidth
                name="notificationUserIds"
                label={t(
                  'checklistsSettings.checklistItemForm.sendNotificationTo.label',
                )}
                component={FormikOptionsSelect}
                options={userOptions}
                filled
                placeholder={t(
                  'checklistsSettings.checklistItemForm.sendNotificationTo.placeholder',
                )}
                disabled={!!cluster}
              />
              <FieldNormalWidth
                name="isOptional"
                label={t('checklistsSettings.checklistItemForm.itemIsOptional')}
                component={LabeledCheckbox}
                wrapLabelOnLineBreak
                disabled={openedFromDealerOverviewButPartOfACluster}
              />
            </FieldsGroup>

            {/* Max duration stuff */}
            <Typography type="Level2Heading">
              {t('checklistsSettings.checklistItemForm.maxDuration')}
            </Typography>
            <Field
              name="limitDuration"
              label={t('checklistsSettings.checklistItemForm.limitDuration')}
              component={LabeledCheckbox}
              disabled={openedFromDealerOverviewButPartOfACluster}
            />
            {values.limitDuration && (
              <FieldsSubGroup>
                <FieldSmallWidth
                  name="maxDuration"
                  label={t('checklistsSettings.checklistItemForm.maxDuration')}
                  component={FormikTextInput}
                  type="number"
                  unit={t('unitDays')}
                  filled
                  required
                  disabled={openedFromDealerOverviewButPartOfACluster}
                />
                <FieldNormalWidth
                  name="alertMessage"
                  label={t(
                    'checklistsSettings.checklistItemForm.alertMessageLong',
                  )}
                  component={FormikTextInput}
                  filled
                  required
                  disabled={openedFromDealerOverviewButPartOfACluster}
                />
                <FieldSmallWidth
                  name="triggerChecklistItemId"
                  label={t(
                    'checklistsSettings.checklistItemForm.durationStartsWhen',
                  )}
                  component={FormikSelectInput}
                  items={triggerChecklistItemOptions}
                  filled
                  required
                  disabled={openedFromDealerOverviewButPartOfACluster}
                />
              </FieldsSubGroup>
            )}
            {/* /Max duration stuff */}

            {/* Automation stuff */}
            <Typography type="Level2Heading">
              {t('checklistsSettings.checklistItemForm.automate')}
            </Typography>
            <Field
              name="checkAutomatically"
              label={t(
                'checklistsSettings.checklistItemForm.checkAutomatically',
              )}
              component={LabeledCheckbox}
              disabled={openedFromDealerOverviewButPartOfACluster}
            />
            {values.checkAutomatically && (
              <Field
                name="automationTriggerIds"
                items={automationTriggerItems}
                component={AutomationCheckboxes}
                required
                disabled={openedFromDealerOverviewButPartOfACluster}
              />
            )}
            {/* /Automation stuff */}

            {/* Reminder stuff */}
            <Typography type="Level2Heading">
              {t('checklistsSettings.checklistItemForm.reminder')}
            </Typography>
            <Field
              name="sendReminder"
              label={t('checklistsSettings.checklistItemForm.sendReminder')}
              component={LabeledCheckbox}
              disabled={!!cluster}
            />
            {values.sendReminder && (
              <FieldsSubGroup>
                <FieldSmallWidth
                  name="reminderAfterDays"
                  label={t(
                    'checklistsSettings.checklistItemForm.sendReminderAfter',
                  )}
                  component={FormikTextInput}
                  type="number"
                  unit={t('unitDays')}
                  filled
                  required={!!cluster === false}
                  disabled={!!cluster}
                />
                <FieldNormalWidth
                  name="reminderUserIds"
                  label={t(
                    'checklistsSettings.checklistItemForm.sendReminderTo',
                  )}
                  component={FormikOptionsSelect}
                  options={userOptions}
                  filled
                  required={!!cluster === false}
                  disabled={!!cluster}
                />
              </FieldsSubGroup>
            )}
            {/* /Reminder stuff */}

            <ButtonGroup>
              <StyledLoadingButton
                type="submit"
                isLoading={
                  createSubmitting || updateSubmitting || itemOrderSubmitting
                }
                onClick={(e) => {
                  e.preventDefault()
                  setFieldValue('saveAndAddAnotherClicked', false, false)
                  handleSubmit()
                }}
              >
                {t('save')}
              </StyledLoadingButton>
              {!checklistItemId && (
                <StyledLoadingButton
                  type="submit"
                  isLoading={
                    createSubmitting || updateSubmitting || itemOrderSubmitting
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    setFieldValue('saveAndAddAnotherClicked', true, false)
                    handleSubmit()
                  }}
                  level="cta"
                >
                  {t('checklistsSettings.checklistItemForm.saveAndAddAnother')}
                </StyledLoadingButton>
              )}
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </>
  )
}

export const propTypes = {
  locationId: PropTypes.string,
  cluster: PropTypes.object,
  isPartOfACluster: PropTypes.bool,
  checklistId: PropTypes.string.isRequired,
  checklistItemId: PropTypes.string,
  /**
   * Set this to insert a new checklist item at a specific index in the checklist. This is
   * 0-indexed (0 = will be added as first item). Is only used if checklistItemId is left empty
   * (so, only when adding an item, not when editing).
   **/
  insertAtIndex: PropTypes.number, // Is 0-index based (0 = first item of list)
  /**
   * This function receives the form data, and the result of the mutation. The form data object
   * contains a prop called 'saveAndAddAnother', which can be used to check if the used clicked the
   * regular save button, or the "save and add another" button.
   **/
  onAfterSave: PropTypes.func,
}

export const defaultProps = {
  locationId: null,
  cluster: null,
  isPartOfACluster: false,
}

ChecklistItemForm.propTypes = propTypes
ChecklistItemForm.defaultProps = defaultProps

export default ChecklistItemForm
