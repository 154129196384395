import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Link from 'components/atoms/link'
import AuthForm from 'components/molecules/auth-form'

const Container = styled.div`
  width: 100%;
`

const StyledAuthForm = styled(AuthForm)`
  background-color: ${({ theme }) => theme.colors.brandGolf};
  padding: 32px;
  margin-bottom: 32px;
`

const StyledLink = styled(Link)`
  display: block;
  margin: 0 auto;
  margin-bottom: 32px;
  text-align: center;
`

function ResetPasswordForm({ error, feedback, onSubmit }) {
  const { t } = useTranslation()

  const fields = [
    {
      errorText: t('password'),
      name: 'password',
      title: t('password'),
      type: 'password',
      validator: (values, index) =>
        values[index].length < 1 && t('passwordRequired'),
    },
    {
      name: 'passwordRepeat',
      title: t('passwordRepeat'),
      type: 'password',
      validator: (values, index) => {
        if (values[index].length < 1) {
          return t('passwordRequired')
        }
        return values[index] !== values[0] && t('passwordRepeatDoesntMatch')
      },
    },
  ]

  return (
    <Container>
      <StyledAuthForm
        error={error}
        feedback={feedback}
        fields={fields}
        onSubmit={onSubmit}
        submitText={t('resetPassword')}
        title={t('recoverPassword')}
      />
      <StyledLink to="/login">{t('backToLogin')}</StyledLink>
    </Container>
  )
}

ResetPasswordForm.propTypes = {
  error: PropTypes.string,
  feedback: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.node,
  ]),
  onSubmit: PropTypes.func.isRequired,
}

ResetPasswordForm.defaultProps = {
  error: null,
  feedback: null,
}

export default ResetPasswordForm
