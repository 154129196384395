import { gql } from '@apollo/client'
import customerInfo from './customer'
import carInfo from './car'
import driverInfo from './driver'

export default gql`
  fragment cspContract on Contract {
    id
    dealerId
    packageCalculationResult {
      id
      resultNet
      resultGross
      package {
        name
        description
        packageType
      }
      calculation {
        warrantyProgram {
          id
          warrantyProvider {
            id
            name
          }
        }
      }
    }
    packageCalculationResultId
    package {
      name
      description
      packageType
      carServicePlan {
        usedWarrantyPrograms {
          warrantyProgramId
        }
      }
    }
    calculation {
      saleDate
      currentMileage
      mileagePerYear
      results {
        id
        resultNet
        resultGross
      }
    }
    car {
      ...carInfo
    }
    customer {
      ...customerInfo
    }
    driver {
      ...driverInfo
    }
    documents {
      id
      documentId
      fullUri
      status
      filename
      uri
      createdAt
    }
    activationDate
    expirationDate
    period
    status
    reason {
      message
    }
    stopDate
    explanation
  }
  ${carInfo}
  ${customerInfo}
  ${driverInfo}
`
