/* eslint-disable camelcase */

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { getAppTheme } from 'utilities/utils'

import { AppThemes } from 'config/enums'

import Menu from './menu'
import ActionBarSection from './action-bar-section'
import { useCarFileType } from 'hooks/use-car-file-type'

const Container = styled.aside`
  background-color: white;
  height: inherit;
  overflow: ${({ hasMobileLayout }) => (hasMobileLayout ? 'auto' : '')};
`

function SideActionsMenu({
  className,
  appLevelItems,
  overviewLevelItems,
  detailsLevelItems,
  hasMobileLayout,
  appLevelHeading,
  overviewLevelHeading,
  detailsLevelHeading,
  onClose,
  ...restProps
}) {
  const { themeName } = getAppTheme()
  const uccLicense = useSelector(({ auth }) => auth?.userDetails?.pakket)

  const { isCarFile, isRegularCarFile, isB2bCarFile, isCarShareCarFile } =
    useCarFileType()

  if (
    !uccLicense &&
    !(Array.isArray(overviewLevelHeading) && overviewLevelHeading.length) &&
    !(Array.isArray(detailsLevelItems) && detailsLevelItems.length)
  ) {
    return null
  }

  return (
    <Container
      className={className}
      hasMobileLayout={hasMobileLayout}
      {...restProps}
    >
      {hasMobileLayout ? (
        <>
          {uccLicense && appLevelItems.length > 0 && (
            <Menu
              backgroundColor="brandBravo"
              color="brandGolf"
              items={appLevelItems}
              title={appLevelHeading}
              hasMobileLayout
              closeMenu={onClose}
            />
          )}
          {overviewLevelItems.length > 0 && (
            <Menu
              backgroundColor="brandDelta"
              items={overviewLevelItems}
              title={overviewLevelHeading}
              hasMobileLayout
              closeMenu={onClose}
            />
          )}
          {detailsLevelItems.length > 0 && (
            <Menu
              backgroundColor="brandGolf"
              items={detailsLevelItems}
              title={detailsLevelHeading}
              hasMobileLayout
              closeMenu={onClose}
            />
          )}
        </>
      ) : (
        <>
          {uccLicense ? (
            <ActionBarSection
              backgroundColor="brandBravo"
              iconDefaultColor="brandGolf"
              iconHoverColor="brandGolf"
              items={appLevelItems}
              title={appLevelHeading}
            />
          ) : null}
          {(!isCarFile ||
            isRegularCarFile ||
            isB2bCarFile ||
            isCarShareCarFile) && (
            <>
              <ActionBarSection
                backgroundColor="brandDelta"
                iconDefaultColor={
                  themeName === AppThemes.TODRIVE ? 'actionsStandard' : 'text'
                }
                iconHoverColor={
                  themeName === AppThemes.AUTOPRESENTATIE ||
                  themeName === AppThemes.TODRIVE
                    ? 'textOnDarkBackground'
                    : 'text'
                }
                items={overviewLevelItems}
                title={overviewLevelHeading}
              />
              <ActionBarSection
                backgroundColor="brandGolf"
                iconDefaultColor={
                  themeName === AppThemes.TODRIVE ? 'actionsStandard' : 'text'
                }
                iconHoverColor={
                  themeName === AppThemes.AUTOPRESENTATIE ||
                  themeName === AppThemes.TODRIVE
                    ? 'textOnDarkBackground'
                    : 'text'
                }
                items={detailsLevelItems}
                title={detailsLevelHeading}
              />
            </>
          )}
        </>
      )}
    </Container>
  )
}

SideActionsMenu.propTypes = {
  className: PropTypes.string,
  appLevelItems: PropTypes.array.isRequired,
  appLevelHeading: PropTypes.string,
  overviewLevelItems: PropTypes.array.isRequired,
  overviewLevelHeading: PropTypes.string,
  detailsLevelItems: PropTypes.array.isRequired,
  detailsLevelHeading: PropTypes.string,
  hasMobileLayout: PropTypes.bool,
  /**
   * What to do onClose, will be triggered by MenuItem children
   */
  onClose: PropTypes.func,
}

SideActionsMenu.defaultProps = {
  className: null,
  hasMobileLayout: false,
  appLevelHeading: null,
  overviewLevelHeading: null,
  detailsLevelHeading: null,
  onClose: () => {},
}

export default SideActionsMenu
