import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/client'
import moment from 'moment'

import { withApolloV4Provider } from 'utilities/apollo'

import { QUERY_MANAGEMENT_INFO_CHART_TURNOVER_RATE } from 'config/graphql/v4'
import {
  DATE_FORMAT,
  LINE_CHART_Y_MARGIN_BOTTOM,
  LINE_CHART_Y_MARGIN_TOP,
  LINE_CHART_Y_MIN,
} from 'config/consts'

import ManagementInfoChart from 'components/molecules/management-info-chart'
import ManagementInfoChartControls from 'components/molecules/management-info-chart-controls'
import ManagementInfoChartContainer from 'components/molecules/management-info-chart-container'
import ManagementInfoChartLegend from 'components/molecules/management-info-chart-legend'
import ManagementInfoChartLoadingIndicator from 'components/molecules/management-info-chart-loading-indicator'
import ManagementInfoChartSelect from 'components/molecules/management-info-chart-select'
import ManagementInfoChartTitle from 'components/molecules/management-info-chart-title'
import ManagementInfoChartTitleLoadingIndicator from 'components/molecules/management-info-chart-title-loading-indicator'

import { DEFAULT_VALUE_CAR_KIND } from 'components/views/management-info/index'

const StyledManagementInfoChartLegend = styled(ManagementInfoChartLegend)`
  opacity: ${({ $loading }) => ($loading ? 0.25 : 1)};
`

const StyledLoadingIndicator = styled(ManagementInfoChartLoadingIndicator)`
  opacity: ${({ $loading }) => ($loading ? 1 : 0)};
`

const StyledManagementInfoChart = styled(ManagementInfoChart)`
  opacity: ${({ $loading }) => ($loading ? 0.0625 : 1)};
`

const ManagementInfoChartTurnoverRateLast12Months = ({
  dealerNumbers,
  filters,
}) => {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = useState(DEFAULT_VALUE_CAR_KIND)
  const [initialDataLoaded, setInitialDataLoaded] = useState(false)

  const dateToday = moment(new Date()).format(DATE_FORMAT)
  const dateTodayOneYearAgo = moment(dateToday)
    .subtract(1, 'year')
    .format(DATE_FORMAT)

  const { data, loading } = useQuery(
    QUERY_MANAGEMENT_INFO_CHART_TURNOVER_RATE,
    {
      variables: {
        dealerNrs: dealerNumbers,
        type: selectedItem || DEFAULT_VALUE_CAR_KIND,
        fromDate: dateTodayOneYearAgo,
        tillDate: dateToday,
      },
      onCompleted: () => {
        setInitialDataLoaded(true)
      },
    },
  )

  const firstData = data?.first.stockTurns || []
  const secondData = data?.second.stockTurns || []
  const thirdData = data?.third.stockTurns || []

  const chartData = {
    datasets: [
      // Data set for "Mijn gemiddelde omloopsnelheid"
      {
        label: t(
          'managementInfo.charts.turnoverRateLast12Months.legends.first',
        ),
        data: firstData.map((day) => ({
          x: day.createdAt,
          y: Number(day.stockturn),
        })),
        color: 'chartsComparisonGroup',
        steppedLine: false,
        cubicInterpolationMode: 'monotone',
        pointRadius: 2,
      },

      // Data set for "Omloopsnelheid vergelijkingsgroep"
      {
        label: t(
          'managementInfo.charts.turnoverRateLast12Months.legends.second',
        ),
        data: secondData.map((day) => ({
          x: day.createdAt,
          y: Number(day.stockturn),
        })),
        color: 'chartsTarget',
        steppedLine: false,
        cubicInterpolationMode: 'monotone',
        pointRadius: 2,
      },

      // Data set for "Omloopsnelheid best-in-class"
      {
        label: t(
          'managementInfo.charts.turnoverRateLast12Months.legends.third',
        ),
        data: thirdData.map((day) => ({
          x: day.createdAt,
          y: Number(day.stockturn),
        })),
        color: 'chartsMyself',
        steppedLine: false,
        cubicInterpolationMode: 'monotone',
        pointRadius: 2,
      },
    ],
  }

  const chartDataPoints = [
    ...chartData.datasets.map((dataset) => dataset.data),
  ].flat()
  const chartYMin = Math.floor(
    Number(
      chartDataPoints.reduce(
        (prev, curr) => (curr.y < prev ? curr.y : prev),
        Infinity,
      ),
    ) * LINE_CHART_Y_MARGIN_BOTTOM,
  )
  const chartYMax = Math.ceil(
    Number(
      chartDataPoints.reduce(
        (prev, curr) => (curr.y > prev ? curr.y : prev),
        LINE_CHART_Y_MIN,
      ),
    ) * LINE_CHART_Y_MARGIN_TOP,
  )

  const chartOptions = {
    animation: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label || ''
          return `${label}: ${tooltipItem.yLabel} ${t('managementInfo.charts.turnoverRateLast12Months.legends.unit')}`
        },
      },
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: t(
              'managementInfo.charts.turnoverRateLast12Months.legends.yAxis',
            ),
          },
          ticks: {
            max: chartYMax,
            min: chartYMin <= 10 ? 0 : chartYMin,
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          distribution: 'series',
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: t(
              'managementInfo.charts.turnoverRateLast12Months.legends.xAxis',
            ),
          },
          time: {
            unit: 'week',
            displayFormats: {
              week: 'W',
            },
          },
        },
      ],
    },
  }

  const chartLegendItems = [
    // Legend for "Mijn gemiddelde omloopsnelheid"
    {
      label: t('managementInfo.charts.turnoverRateLast12Months.legends.first'),
      color: 'chartsComparisonGroup',
      backgroundColor: 'fieldBackground',
      value: data?.first?.averageStockTurn,
    },

    // Legend for "Omloopsnelheid vergelijkingsgroep"
    {
      label: t('managementInfo.charts.turnoverRateLast12Months.legends.second'),
      color: 'chartsTarget',
      backgroundColor: 'fieldBackground',
      value: data?.second?.averageStockTurn,
    },

    // Legend for "Omloopsnelheid best-in-class"
    {
      label: t('managementInfo.charts.turnoverRateLast12Months.legends.third'),
      color: 'chartsMyself',
      backgroundColor: 'fieldBackground',
      value: data?.third?.averageStockTurn,
    },
  ]

  return (
    <>
      <div>
        <ManagementInfoChartTitle>
          {t('managementInfo.charts.turnoverRateLast12Months.title')}
          {loading && !initialDataLoaded && (
            <ManagementInfoChartTitleLoadingIndicator />
          )}
        </ManagementInfoChartTitle>
        <ManagementInfoChartControls>
          <ManagementInfoChartSelect
            items={filters}
            onChange={setSelectedItem}
            label={t('filters')}
            value={selectedItem}
          />
          <StyledManagementInfoChartLegend
            $loading={loading}
            arrangeAs="row"
            items={chartLegendItems}
            unit={t(
              'managementInfo.charts.turnoverRateLast12Months.legends.unit',
            )}
          />
        </ManagementInfoChartControls>
      </div>
      <ManagementInfoChartContainer>
        <StyledLoadingIndicator
          $loading={loading && initialDataLoaded}
          loadingText={t('managementInfo.charts.loadingData')}
        />
        <StyledManagementInfoChart
          $loading={loading && initialDataLoaded}
          options={chartOptions}
          data={chartData}
        />
      </ManagementInfoChartContainer>
    </>
  )
}

ManagementInfoChartTurnoverRateLast12Months.propTypes = {
  dealerNumbers: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string).isRequired,
  ]),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default withApolloV4Provider(ManagementInfoChartTurnoverRateLast12Months)
