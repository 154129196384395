import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'

import Button from 'components/atoms/button'
import Typography from 'components/molecules/typography'

const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    align-items: flex-end;
  }

  .MuiPaper-root {
    background: ${({ theme }) => theme.colors.isolatedBackground};
    box-shadow: ${({ theme }) => theme.shadows.modal};
    margin: 0;
    width: 100%;
  }

  .MuiBackdrop-root {
    background: ${({ theme }) => theme.colors.brandAlfa}cc;
  }

  .MuiDialogContent-root {
    background-color: white;
    padding: ${({ theme }) => theme.sizings.lvl2};
  }
`

const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

const StyledTypography = styled(Typography)`
  margin: 0;
`

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: flex-start;

  &:hover span,
  span {
    color: ${({ theme }) => theme.colors.darkestBackground};
  }

  :active:not([disabled]) path,
  path {
    fill: ${({ theme }) => theme.colors.darkestBackground} !important;
  }

  > *:last-child {
    padding-left: ${({ theme }) => theme.sizings.lvl1};
  }
`

const StyledCloseButton = styled(Button)`
  margin-left: auto;
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

/**
 * This component is used to give the user multiple options to continue a flow
 */
function SelectModal({
  className,
  closeHandler,
  disableBackdropClick,
  open,
  options,
  title,
  showCloseButton,
  ...restProps
}) {
  const handleOptionClick = (option) => {
    if (option.handler) {
      option.handler()
    }
    if (closeHandler) {
      closeHandler()
    }
  }

  return (
    <StyledDialog
      PaperProps={{ square: true }}
      open={open}
      className={className}
      disableBackdropClick={disableBackdropClick}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      {...restProps}
    >
      <DialogContent data-test-e2e="dialog-content">
        <DialogTitle>
          {title && (
            <StyledTypography type="Level5Heading">{title}</StyledTypography>
          )}
          {showCloseButton && closeHandler && (
            <StyledCloseButton
              level="minimal"
              icon="close"
              iconColor="darkestBackground"
              onClick={closeHandler}
            />
          )}
        </DialogTitle>
        {options.map((option) => (
          <StyledButton
            size="large"
            icon={option?.icon}
            iconColor="darkestBackground"
            onClick={() => handleOptionClick(option)}
            level="option"
            text={option.label}
            key={option.label}
            data-test-e2e={option.testId}
          />
        ))}
      </DialogContent>
    </StyledDialog>
  )
}
SelectModal.propTypes = {
  className: PropTypes.string,
  /** Event handler for closing the modal */
  closeHandler: PropTypes.func,
  /** If true, clicking the backdrop will not fire the onClose callback. */
  disableBackdropClick: PropTypes.bool,
  /** Open / closes state boolean */
  open: PropTypes.bool,
  /** Link options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      handler: PropTypes.func,
      testId: PropTypes.string,
    }),
  ).isRequired,
  /** Modal title */
  title: PropTypes.string,
  /** Shows a close button in the modal */
  showCloseButton: PropTypes.bool,
}

SelectModal.defaultProps = {
  className: null,
  closeHandler: null,
  disableBackdropClick: false,
  open: false,
  title: null,
  showCloseButton: false,
}

export default SelectModal
