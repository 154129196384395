import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Overlay from 'components/molecules/overlay'
import ChecklistItemForm, {
  defaultProps,
  propTypes as formPropTypes,
} from 'components/views/settings/checklists/checklist-item-form'
import styled from 'styled-components/macro'

const StyledOverlay = styled(Overlay)`
  .header {
    margin-bottom: ${({ theme }) => theme.sizings.lvl1};
  }
`

const FormWrapper = styled.div`
  width: 100%;
`

const ChecklistItemOverlay = ({
  locationId,
  cluster,
  isPartOfACluster,
  checklistId,
  checklistItemId,
  onAfterSave,
  onClose,
  insertAtIndex,
  ...restProps
}) => {
  const { t } = useTranslation()

  return (
    <StyledOverlay
      title={t(
        `checklistsSettings.checklistItemOverlay.${checklistItemId ? 'titleEdit' : 'titleAdd'}`,
      )}
      icon="checklist"
      close={() => {
        typeof onClose === 'function' && onClose()
      }}
      {...restProps}
    >
      <FormWrapper>
        <ChecklistItemForm
          locationId={locationId}
          cluster={cluster}
          isPartOfACluster={isPartOfACluster}
          checklistId={checklistId}
          checklistItemId={checklistItemId}
          insertAtIndex={insertAtIndex}
          onAfterSave={onAfterSave}
        />
      </FormWrapper>
    </StyledOverlay>
  )
}

ChecklistItemOverlay.propTypes = {
  ...formPropTypes,
  onClose: PropTypes.func,
}

ChecklistItemOverlay.defaultProps = defaultProps

export default ChecklistItemOverlay
