import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import styled from 'styled-components/macro'

import { CSP } from '../../../../config/routes'
import { CSPSalesFlowStep1UpdateVehicle } from '../../../../config/validation-schemas'
import {
  UPDATE_CAR,
  GET_WARRANTY_PROGRAMS_BY_LICENSE_PLATE,
  GET_CAR_FROM_LICENSE_PLATE_QUERY,
} from '../../../../config/graphql/csp'
import { FormikSelectInput } from '../../../molecules/formik-field'
import Button from '../../../atoms/button'
import LoadingIndicator from '../../../atoms/loading-indicator'
import TextLink from '../../../atoms/text-link'
import Typography from '../../../molecules/typography'
import Dialog from '../../../molecules/flexible-dialog'

const FormRow = styled.div`
  padding-top: ${({ theme }) => theme.sizings.lvl2};
  display: flex;
  justify-content: space-between;
  > * {
    width: 100%;
    padding-right: ${({ theme }) => theme.sizings.lvl3};
    &:last-child {
      padding-right: 0;
    }
  }
`
const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`
const CancelLink = styled(TextLink)`
  padding: ${({ theme }) => theme.sizings.lvl3} 0;
`
const LoadingIndicatorStyled = styled(LoadingIndicator)`
  margin-top: ${({ theme }) => theme.sizings.lvl4};
`

function CspSalesUpdateVehicleDialog({ car, payload }) {
  const history = useHistory()
  const { t } = useTranslation()
  const mutationOptions = {
    refetchQueries: [
      {
        query: GET_WARRANTY_PROGRAMS_BY_LICENSE_PLATE,
        variables: {
          dealerId: payload.dealerId,
          licensePlate: payload.licensePlate,
          currentMileage: payload.currentMileage,
        },
      },
      {
        query: GET_CAR_FROM_LICENSE_PLATE_QUERY,
        variables: {
          licensePlate: payload.licensePlate,
          dealerId: payload.dealerId,
        },
      },
    ],
    awaitRefetchQueries: true,
  }
  const [updateCar] = useMutation(UPDATE_CAR, mutationOptions)

  const handleSubmit = async (values) => {
    const { modelId } = values
    return updateCar({
      variables: {
        id: car.id,
        brandId: car.brand.id,
        modelId,
      },
    })
  }

  let vehicleModels = []
  let initialValueModelId
  if (
    car &&
    car.brand &&
    car.brand.models &&
    Array.isArray(car.brand.models) &&
    car.brand.models.length > 0
  ) {
    vehicleModels = car.brand.models.map((model) => ({
      label: model.name,
      value: model.id,
    }))
    initialValueModelId = car.brand.models[0].id
  }

  return (
    <Dialog
      disableBackdropClick
      open
      title={t('cspSalesFlow.steps.updateVehicleModal.heading')}
      content={
        <div>
          <Typography type="ExplanationParagraph">
            {t('cspSalesFlow.steps.updateVehicleModal.body', {
              licensePlate: car.licensePlate,
            })}
          </Typography>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              modelId: initialValueModelId,
            }}
            validationSchema={CSPSalesFlowStep1UpdateVehicle(t)}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)
              try {
                await handleSubmit(values)
              } catch (e) {
                console.error(e)
              }
              setSubmitting(false)
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormRow>
                  <Field
                    name="modelId"
                    items={vehicleModels}
                    label={t(
                      'cspSalesFlow.steps.updateVehicleModal.fields.modelId',
                    )}
                    filled
                    component={FormikSelectInput}
                  />
                </FormRow>

                {isSubmitting && <LoadingIndicatorStyled size="small" />}
                <Controls>
                  <CancelLink
                    onClick={() => history.push(CSP)}
                    text={t('cancel')}
                  />
                  <Button level="cta" disabled={isSubmitting} type="submit">
                    {t(
                      'cspSalesFlow.steps.updateVehicleModal.primaryActionLabel',
                    )}
                  </Button>
                </Controls>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  )
}

CspSalesUpdateVehicleDialog.propTypes = {
  car: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    brand: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      models: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
    licensePlate: PropTypes.string.isRequired,
  }).isRequired,
  payload: PropTypes.object.isRequired,
}

export default CspSalesUpdateVehicleDialog
