import i18next from 'i18next'
import i18nextChainedBackend from 'i18next-chained-backend'
import i18nextHttpBackend from 'i18next-http-backend'
import i18nextResourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'

// Internal dependencies
import { v4TranslationsApi } from 'config/data'

import language from 'utilities/language'

const i18n = async () => {
  const languageCode = language.getLanguage()

  return i18next
    .use(i18nextChainedBackend)
    .use(initReactI18next)
    .init({
      backend: {
        backends: [
          // First, make a call to the UCC translations API to load the most recent translations
          i18nextHttpBackend,
          // Third, if the API fails on load and has failed during build, fall back to the static translations files in the repository (which are most likely outdated)
          i18nextResourcesToBackend(
            (languageCode) => import(`../translations/${languageCode}.json`),
          ),
        ],
        backendOptions: [
          {
            loadPath: `${v4TranslationsApi}/frontend.json?lang={{lng}}`,
          },
        ],
      },
      load: 'languageOnly',
      resources: {},
      // debug: true,
      partialBundledLanguages: true,
      fallbackLng: languageCode,
      interpolation: {
        escapeValue: false,
      },
    })
    .then(() => {
      language.setLanguage(languageCode)
    })
}

export default i18n
