import TextLink from 'components/atoms/text-link'
import EnhancedTable, {
  TableDataText,
  propTypes as enhancedTablePropTypes,
} from 'components/organisms/enhanced-table'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { formatLicenseplate } from 'utilities/utils'
import Typography from 'components/molecules/typography'

const StyledTableDataText = styled(TableDataText)`
  font-weight: 400;
  // margin-top: ${({ theme }) => theme.sizings.lvl0};
`

const ActionsContainer = styled.div`
  display: inline-flex;
  position: relative;
  top: 2px;
  align-items: center;
`

const Explanation = styled(Typography)`
  margin-bottom: 0;
`

const ContractExtensionRequestsTable = ({ rows, selectable, ...restProps }) => {
  const { t } = useTranslation()

  const formattedRows = React.useMemo(
    () =>
      rows.map((row) => ({
        _attrs: {
          'data-test-e2e': `contract-row-${row.id}`,
        },
        licensePlate: {
          component: (
            <StyledTableDataText>
              {row.licensePlateLink ? (
                <TextLink
                  to={row.licensePlateLink}
                  text={formatLicenseplate(row.licensePlate)}
                />
              ) : (
                formatLicenseplate(row.licensePlate)
              )}
            </StyledTableDataText>
          ),
          data: '',
        },
        clientLastName: {
          component: row.clientLastName ? (
            <StyledTableDataText>{row.clientLastName}</StyledTableDataText>
          ) : (
            <></>
          ),
          data: '',
        },
        postalcode: {
          component: row.postalcode ? (
            <StyledTableDataText>{row.postalcode}</StyledTableDataText>
          ) : (
            <></>
          ),
          data: '',
        },
        package: {
          component: <StyledTableDataText>{row.package}</StyledTableDataText>,
          data: '',
        },
        endDate: {
          component: <StyledTableDataText>{row.endDate}</StyledTableDataText>,
          data: '',
        },
        duration: {
          component: <StyledTableDataText>{row.duration}</StyledTableDataText>,
          data: '',
        },
        oldPrice: {
          component: <StyledTableDataText>{row.oldPrice}</StyledTableDataText>,
          data: '',
        },
        newPrice: {
          component: <StyledTableDataText>{row.newPrice}</StyledTableDataText>,
          data: '',
        },
        actions: {
          component: <ActionsContainer>{row.actions}</ActionsContainer>,
          data: '',
        },
      })),
    [rows],
  )

  return (
    <>
      <EnhancedTable
        data-test-e2e="contract-extensions-requests-table"
        alignCellContent="middle"
        columns={[
          {
            id: 'licensePlate',
            label: t('licensePlate'),
          },
          {
            id: 'clientLastName',
            label: t(
              'carServicePlanContractExtensionRequestsTable.clientLastName',
            ),
          },
          {
            id: 'postalcode',
            label: t('postalcode'),
          },
          {
            id: 'package',
            label: t('carServicePlanContractExtensionRequestsTable.package'),
          },
          {
            id: 'endDate',
            label: t('carServicePlanContractExtensionRequestsTable.endDate'),
          },
          {
            id: 'duration',
            label: t(
              'carServicePlanContractExtensionRequestsTable.contractTime',
            ),
          },
          {
            id: 'oldPrice',
            label: t('carServicePlanContractExtensionRequestsTable.oldPrice'),
          },
          {
            id: 'newPrice',
            label: t('carServicePlanContractExtensionRequestsTable.newPrice'),
          },
          {
            id: 'actions',
            label: t('carServicePlanContractExtensionRequestsTable.actions'),
            alignRight: true,
          },
        ]}
        rows={formattedRows}
        selectedText={t(
          'carServicePlanContractExtensionRequestsTable.selectedText',
        )}
        {...restProps}
      />
      {formattedRows.length > 0 && (
        <Explanation type="ExplanationParagraph">
          {t(
            'carServicePlanContractExtensionRequestsTable.newPriceExplanation',
          )}
        </Explanation>
      )}
    </>
  )
}

const { columns, selectedText, ...filteredEnhancedTablePropTypes } =
  enhancedTablePropTypes

ContractExtensionRequestsTable.propTypes = {
  ...filteredEnhancedTablePropTypes,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      licensePlate: PropTypes.string,
      licensePlateLink: PropTypes.string,
      clientLastName: PropTypes.string,
      postalcode: PropTypes.string,
      package: PropTypes.string.isRequired,
      endDate: PropTypes.string,
      duration: PropTypes.string.isRequired,
      oldPrice: PropTypes.string,
      newPrice: PropTypes.string,
      actions: PropTypes.node.isRequired,
    }),
  ).isRequired,
}

export default React.memo(ContractExtensionRequestsTable)
