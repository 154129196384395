import { connect } from 'react-redux'
import {
  addExpectedPhotos,
  getExpectedPhotos,
  deleteExpectedPhotos,
  sortExpectedPhotos,
} from 'redux/actions/settings'
import ExpectedPhotoForm from '../organisms/expected-photo-form'

const mapStateToProps = ({ settings }) => ({
  expectedPhoto: settings.expectedPhoto,
  expectedPhotos: settings.expectedPhotos,
})

const mapDispatchToProps = {
  addExpectedPhotos,
  getExpectedPhotos,
  deleteExpectedPhotos,
  sortExpectedPhotos,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpectedPhotoForm)
