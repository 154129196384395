// EXTERNAL
import { useLazyQuery } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components/macro'
import { useMediaLayout } from 'use-media'

// INTERNAL
import ContentSeperator from 'components/atoms/content-separator'
import BodyHeading from 'components/atoms/body-heading'
import BodyParagraph from 'components/atoms/body-paragraph'
import Button from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Text from 'components/atoms/text'
import LegendBox from 'components/molecules/legend-box'
import LegendBoxes from 'components/molecules/legend-boxes'
import Typography from 'components/molecules/typography'
import PlanChoice from 'components/organisms/plan-choice'
import {
  CALCULATE_BPM,
  GENERATE_BPM_REPORT,
  GET_BPM_APPRAISERS,
  SEND_BPM_APPRAISAL_REQUEST,
} from 'config/graphql/v4'
import * as routes from 'config/routes'
import { getCarfile, updateCarfile } from 'redux/actions/data'
import { withApolloV4Provider } from 'utilities/apollo'
import data from 'utilities/data'
import { formatDate } from 'utilities/format'
import { media } from 'utilities/styled'
import toast from 'utilities/toast'
import { formatPrice, roundWithDecimals, scrollTo } from 'utilities/utils'

// COMPONENT
import BpmCalculationResultAppraiserForm from './bpm-calculation-result-appraiser-form'
import BpmCalculationResultConfirmationDialog from './bpm-calculation-result-confirmation-dialog'
import BpmCalculationResultGroups from './bpm-calculation-result-groups'

const { getEurotaxValue } = data

const ErrorTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.stateNegative};
`

const StyledLegendBoxes = styled(LegendBoxes)`
  display: flex;
  flex-direction: column;
  ${media.tablet`
    flex-direction: row;
  `}
`

const StyledLegendBox = styled(LegendBox)`
  width: 100%;
`

const PlanChoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizings.lvl2};
  ${media.tablet`
    flex-direction: row;
  `}
`

// Override H1 in the PlanChoice elements to match the design
const StyledPlanChoice = styled(PlanChoice)`
  width: 100%;
  & > h1 {
    font-size: 16px;
  }
`

const FlexContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
`

const DivWithBorder = styled.div`
  border-bottom: ${({ theme }) => theme.colors.darkOnLightBorder} 1px solid;
`

const FlexParagraph = styled(BodyParagraph)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: ${({ bold }) => (bold ? 700 : 300)};
  & span {
    text-align: left;
  }
  & span:last-child {
    flex-shrink: 0;
  }
`

const FlexBodyHeading = styled(BodyHeading)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ marginTop }) => (marginTop ? '1em' : 0)};
  margin-bottom: 0;
  font-size: 20px;
`

const StyledButton = styled(Button)`
  margin-top: ${(props) => props.theme.sizings.lvl3};
  margin-bottom: ${(props) => props.theme.sizings.lvl3};
`
const CalculateButton = styled(Button)`
  width: 100%;
`

const StyledTypography = styled(Typography)`
  margin-bottom: ${(props) => props.theme.sizings.lvl3};
`

const resultTypes = {
  historicPreviousLegislationBpm: 'HISTORIC_PREVIOUS_LEGISLATION',
  historicBpm: 'HISTORIC',
  currentBpm: 'CURRENT',
}

const mostBenificialResultTypes = {
  deprication: 'DEPRICATION',
  priceList: 'PRICE_LIST',
}

const initialState = {
  bpmResultsData: {},
  selectedResult: resultTypes.current,
  selectedResultData: {},
  mostBeneficialResultType: mostBenificialResultTypes.dep,
  selectedConsideredResult: {
    resultId: '', // RESULT ID COMING FROM BE
    resultType: '', // CONSIDERED RESULT DEPRICATION OR PRICE_LIST FROM BE
  },
}

function reducer(state, action) {
  switch (action.type) {
    case 'setBpmResultsData':
      return {
        ...state,
        bpmResultsData: { ...state.bpmResultsData, ...action.payload },
      }
    case 'selectResult':
      return { ...state, selectedResult: action.payload }
    case 'setSelectedResultData':
      return { ...state, selectedResultData: action.payload }
    case 'setMostBeneficialResultType':
      return { ...state, mostBeneficialResultType: action.payload }
    case 'setSelectedConsideredResult':
      return { ...state, selectedConsideredResult: action.payload }
    default:
      throw new Error()
  }
}

const BpmCalculation = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const divRef = useRef()
  const carFile = useSelector((state) => state?.data?.carfile?.data)
  const carId = carFile.auto_id
  const dispatch = useDispatch()
  const [state, componentDispatch] = useReducer(reducer, initialState)
  const {
    bpmResultsData,
    selectedResultData,
    selectedConsideredResult,
    selectedResult,
    mostBeneficialResultType,
  } = state
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [appraiserId, setAppraiserId] = useState()
  const instanceId = useSelector(
    (instanceState) => instanceState.auth.instanceId,
  )
  const [loadingEurotaxPrice, setLoadingEurotaxPrice] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })

  // const {
  //   data: grossBpmCo2DifferenceData,
  //   error: grossBpmCo2DifferenceError,
  //   loading: grossBpmCo2DifferenceLoading,
  // } = useQuery(CALCULATE_GROSS_BPM_CO2_DIFFERENCE, {
  //   variables: { carFileId: carId },
  //   fetchPolicy: 'no-cache',
  // });

  const [getBpmResultsCurrent, { loading: bpmResultsCurrentLoading }] =
    useLazyQuery(CALCULATE_BPM, {
      variables: { carFileId: carId, type: resultTypes.currentBpm },
      fetchPolicy: 'no-cache',
      context: {
        suppressErrors: true,
      },
      onCompleted: (bpmResultsCurrentData) => {
        componentDispatch({
          type: 'setBpmResultsData',
          payload: { currentBpm: bpmResultsCurrentData?.calculateBpm },
        })
      },
      onError: (error) => {
        setErrorMessages((messages) => [
          ...messages,
          `${t('bpmCalculation.currentBpm')}: ${error.message}`,
        ])
      },
    })

  const [getBpmResultsHistoric, { loading: bpmResultsHistoricLoading }] =
    useLazyQuery(CALCULATE_BPM, {
      variables: { carFileId: carId, type: resultTypes.historicBpm },
      fetchPolicy: 'no-cache',
      context: {
        suppressErrors: true,
      },
      onCompleted: (bpmResultsHistoricData) => {
        componentDispatch({
          type: 'setBpmResultsData',
          payload: { historicBpm: bpmResultsHistoricData?.calculateBpm },
        })
      },
      onError: (error) => {
        setErrorMessages((messages) => [
          ...messages,
          `${t('bpmCalculation.historicBpm')}: ${error.message}`,
        ])
      },
    })

  const [
    getBpmResultsHistoricPreviousLegislation,
    { loading: bpmResultsHistoricPreviousLegislationLoading },
  ] = useLazyQuery(CALCULATE_BPM, {
    variables: {
      carFileId: carId,
      type: resultTypes.historicPreviousLegislationBpm,
    },
    fetchPolicy: 'no-cache',
    context: {
      suppressErrors: true,
    },
    onCompleted: (bpmResultsHistoricPreviousLegislationData) => {
      componentDispatch({
        type: 'setBpmResultsData',
        payload: {
          historicPreviousLegislationBpm:
            bpmResultsHistoricPreviousLegislationData?.calculateBpm,
        },
      })
    },
    onError: (error) => {
      setErrorMessages((messages) => [
        ...messages,
        `${t('bpmCalculation.historicPreviousLegislationBpm')}: ${
          error.message
        }`,
      ])
    },
  })

  const [
    getBpmAppraisers,
    { data: bpmAppraisersData, loading: bpmAppraisersLoading },
  ] = useLazyQuery(GET_BPM_APPRAISERS, {
    variables: { dealerId: carFile.dealernr },
    fetchPolicy: 'no-cache',
    onError: (errorMessage) => {
      toast.error(errorMessage)
    },
  })

  const appraiserItems = Array.isArray(bpmAppraisersData?.bpmAppraisers)
    ? bpmAppraisersData.bpmAppraisers.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    : null

  const [
    sendEmailToAppraiser,
    {
      called: sendEmailToAppraiserCalled,
      loading: sendEmailToAppraiserLoading,
    },
  ] = useLazyQuery(SEND_BPM_APPRAISAL_REQUEST, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      toast.success(t('bpmCalculation.taxationPartnerEmailSent'))
    },
    onError: (errorMessage) => {
      toast.error(errorMessage)
    },
  })

  const [sendEmailToBolsenbroek, { loading: sendEmailToBolsenbroekLoading }] =
    useLazyQuery(SEND_BPM_APPRAISAL_REQUEST, {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        toast.success(t('bpmCalculation.taxationPartnerEmailSent'))
      },
      onError: (errorMessage) => {
        toast.error(errorMessage)
      },
    })

  const [
    generateBpmReport,
    { called: generateBpmReportCalled, loading: generateBpmReportLoading },
  ] = useLazyQuery(GENERATE_BPM_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      history.push(`${routes.CAR_FILE}/${carId}/bpm/documents`)
      toast.success(t('bpmCalculation.createdBpmDeclarationForm'))
    },
    onError: (errorMessage) => {
      toast.error(errorMessage)
    },
  })

  const queryLoading =
    bpmResultsCurrentLoading ||
    bpmResultsHistoricLoading ||
    bpmResultsHistoricPreviousLegislationLoading ||
    bpmAppraisersLoading

  useEffect(() => {
    const grossValues = {}

    Object.keys(bpmResultsData).forEach((key) => {
      grossValues[key] = bpmResultsData[key]?.consideredResults
        ?.filter((result) => result.success === true)
        ?.reduce((prev, curr) => (prev.value < curr.value ? prev : curr))?.value
    })

    const cheapestBpmResult = Object.keys(grossValues).length
      ? Object.keys(grossValues).reduce((prev, curr) =>
          grossValues[prev] <= grossValues[curr] ? prev : curr,
        )
      : null

    if (cheapestBpmResult) {
      componentDispatch({
        type: 'selectResult',
        payload: resultTypes[cheapestBpmResult],
      })
      componentDispatch({
        type: 'setSelectedResultData',
        payload: bpmResultsData[cheapestBpmResult],
      })
    }
  }, [bpmResultsData])

  useEffect(() => {
    if (carFile.hasRequestedBpmValuation) {
      getBpmResultsCurrent()
      getBpmResultsHistoric()
      getBpmResultsHistoricPreviousLegislation()
      getBpmAppraisers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carFile.hasRequestedBpmValuation])

  useEffect(() => {
    if (selectedResultData?.consideredResults) {
      const consideredResults = selectedResultData?.consideredResults
        .filter((result) => result.success === true)
        .map((result) => ({
          type: result?.deprecation?.type,
          value: result.value,
        }))

      const lowestConsideredResult = consideredResults.reduce((prev, curr) =>
        prev.value < curr.value ? prev : curr,
      )
      componentDispatch({
        type: 'setMostBeneficialResultType',
        payload: lowestConsideredResult.type,
      })
    }
  }, [selectedResultData])

  const [dialogFormData, setDialogFormData] = useState({
    rsin: '',
    bsn: '',
    vin: '',
    date: new Date(),
  })

  useEffect(() => {
    if (carFile?.rsin) {
      setDialogFormData((values) => ({ ...values, rsin: carFile.rsin }))
    }
    if (carFile?.bsn) {
      setDialogFormData((values) => ({ ...values, bsn: carFile.bsn }))
    }
    if (carFile?.chassisnr) {
      setDialogFormData((values) => ({
        ...values,
        vin: carFile.chassisnr,
      }))
    }
    if (carFile?.etbb_regdatum) {
      setDialogFormData((values) => ({
        ...values,
        date: carFile.etbb_regdatum,
      }))
    }
  }, [carFile])

  const depricationResult = selectedResultData?.consideredResults?.find(
    (result) =>
      result?.deprecation?.type === mostBenificialResultTypes.deprication,
  )

  const priceListResult = selectedResultData?.consideredResults?.find(
    (result) =>
      result?.deprecation?.type === mostBenificialResultTypes.priceList,
  )

  const handleSubmitDialog = () => {
    generateBpmReport({
      variables: {
        resultId: selectedConsideredResult.resultId,
        deprecrationType: selectedConsideredResult.resultType,
        rsin: dialogFormData.rsin,
        bsn: dialogFormData.bsn,
        vin: dialogFormData.vin,
        date: formatDate(dialogFormData.date, undefined, 'YYYY-MM-DD'),
      },
    })
  }

  const getBpmCalculation = async () => {
    setLoadingEurotaxPrice(true)

    getEurotaxValue(instanceId, {
      auto_id: carId,
    })
      .then((response) => {
        const updatedCarFile = {
          ...carFile,
          ...{
            etbb_eurotaxwaarde: response.eurotaxwaarde,
            etbb_eurotaxwaarde_trade: response.eurotaxwaarde_trade,
            hasBpmValuation: true,
            hasRequestedBpmValuation: true,
          },
        }
        dispatch(updateCarfile(updatedCarFile, carId))
      })
      .catch((apiError) => {
        dispatch(getCarfile(carId)) // Get the car file again - this eurotax call made changes to the BE values when it fails
        toast.error(apiError)
      })
  }

  useEffect(() => {
    scrollTo(divRef.current)
  }, [getBpmResultsCurrent])

  const hasResults = !!(
    bpmResultsData?.currentBpm ||
    bpmResultsData?.historicBpm ||
    bpmResultsData?.historicPreviousLegislationBpm
  )

  return (
    <div ref={divRef}>
      {isDialogOpen && (
        <BpmCalculationResultConfirmationDialog
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          handleSubmitDialog={handleSubmitDialog}
          dialogFormData={dialogFormData}
          setDialogFormData={setDialogFormData}
          isLoading={generateBpmReportCalled && generateBpmReportLoading}
        />
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={8} lg={9}>
          <Text type="h2">{t('bpmCalculation.bpmCalculation')}</Text>
        </Grid>
      </Grid>

      {/* {grossBpmCo2DifferenceError ? (
        <GeneralDataError error={grossBpmCo2DifferenceError.message} />
      ) : null} */}

      {/* Commented out due to law restrictions. See ticket https://app.clickup.com/t/jkak4w */}
      {/* {grossBpmCo2DifferenceData ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <StyledTypographyTitle type="BodyParagraph">
              {t('bpmCalculation.listPrice')}
            </StyledTypographyTitle>
            <StyledTypographyValue type="Level5Heading">
              {formatPrice(
                grossBpmCo2DifferenceData.calculateGrossBpmCo2Difference
                  .originalMarketPrice,
              )}
            </StyledTypographyValue>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledTypographyTitle type="BodyParagraph">
              {t('bpmCalculation.bpmCorrectionBasedOnCo2')}
            </StyledTypographyTitle>
            <StyledTypographyValue type="Level5Heading">
              {formatPrice(
                grossBpmCo2DifferenceData.calculateGrossBpmCo2Difference
                  .co2BpmCorrection,
              )}
            </StyledTypographyValue>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledTypographyTitle type="BodyParagraph">
              {t('bpmCalculation.listPriceCorrectedForCo2')}
            </StyledTypographyTitle>
            <StyledTypographyValue type="Level5Heading">
              {formatPrice(
                grossBpmCo2DifferenceData.calculateGrossBpmCo2Difference
                  .co2CorrectedMarketPrice,
              )}
            </StyledTypographyValue>
          </Grid>
        </Grid>
      ) : null} */}

      {queryLoading && <LoadingIndicator />}

      {(!queryLoading && !carFile.hasRequestedBpmValuation) ||
      (carFile.hasRequestedBpmValuation && hasResults) ? (
        <StyledButton
          disabled={loadingEurotaxPrice}
          onClick={getBpmCalculation}
          level="cta"
          text={
            carFile.hasRequestedBpmValuation
              ? t('bpmCalculation.redoBpmCalculation')
              : t('bpmCalculation.makeBpmCalculation')
          }
        />
      ) : null}

      {!queryLoading && carFile.hasRequestedBpmValuation && !hasResults && (
        <>
          <Typography type="ExplanationParagraph">
            {t('bpmCalculation.bpmCalculationNotPossible')}
          </Typography>
          {errorMessages.length &&
            errorMessages.map((error, index) => (
              <ErrorTypography
                type="ExplanationParagraph"
                key={`error-${index.toString()}`}
              >
                {error}
              </ErrorTypography>
            ))}
        </>
      )}

      {!queryLoading && hasResults && carFile.hasRequestedBpmValuation && (
        <>
          <Typography type="Level2Heading">
            {t('bpmCalculation.step1Title')}
          </Typography>
          <StyledLegendBoxes>
            {bpmResultsData?.currentBpm && (
              <StyledLegendBox
                legendColor="chartsIsOk"
                label={t('bpmCalculation.currentBpm')}
                value={formatPrice(
                  bpmResultsData?.currentBpm?.grossResult.value_gross,
                  true,
                  true,
                  true,
                )}
                selected={selectedResult === resultTypes.currentBpm}
                onSelect={() => {
                  componentDispatch({
                    type: 'selectResult',
                    payload: resultTypes.currentBpm,
                  })
                  componentDispatch({
                    type: 'setSelectedResultData',
                    payload: bpmResultsData?.currentBpm,
                  })
                }}
              />
            )}
            {bpmResultsData?.historicBpm && (
              <StyledLegendBox
                legendColor="actionsCta"
                label={t('bpmCalculation.historicBpm')}
                value={formatPrice(
                  bpmResultsData?.historicBpm?.grossResult.value_gross,
                  true,
                  true,
                  true,
                )}
                selected={selectedResult === resultTypes.historicBpm}
                onSelect={() => {
                  componentDispatch({
                    type: 'selectResult',
                    payload: resultTypes.historicBpm,
                  })
                  componentDispatch({
                    type: 'setSelectedResultData',
                    payload: bpmResultsData?.historicBpm,
                  })
                }}
              />
            )}
            {bpmResultsData?.historicPreviousLegislationBpm && (
              <StyledLegendBox
                legendColor="actionsStandard"
                label={t('bpmCalculation.historicPreviousLegislationBpm')}
                value={formatPrice(
                  bpmResultsData?.historicPreviousLegislationBpm?.grossResult
                    .value_gross,
                  true,
                  true,
                  true,
                )}
                selected={
                  selectedResult === resultTypes.historicPreviousLegislationBpm
                }
                onSelect={() => {
                  componentDispatch({
                    type: 'selectResult',
                    payload: resultTypes.historicPreviousLegislationBpm,
                  })
                  componentDispatch({
                    type: 'setSelectedResultData',
                    payload: bpmResultsData?.historicPreviousLegislationBpm,
                  })
                }}
              />
            )}
          </StyledLegendBoxes>
          <StyledTypography type="Level2Heading">
            {t('bpmCalculation.step2Title')}
          </StyledTypography>
          <PlanChoiceWrapper>
            <StyledPlanChoice
              disabled={isMobile}
              highlighted={
                mostBeneficialResultType ===
                mostBenificialResultTypes.deprication
              }
              highlightedText={t('mostBeneficial')}
              heading={t('bpmCalculation.bpmBasedOnDeprication')}
              fullBodyHeight
              body={
                <FlexContainer>
                  {depricationResult?.specificationLines && (
                    <BpmCalculationResultGroups
                      groups={depricationResult.specificationLines}
                    />
                  )}
                  <DivWithBorder>
                    <FlexParagraph bold>
                      <span>{t('bpmCalculation.grossBpm')}</span>
                      <span>
                        {formatPrice(
                          depricationResult?.value_gross,
                          true,
                          true,
                          true,
                        )}
                      </span>
                    </FlexParagraph>
                    <FlexParagraph>
                      <span>
                        {t('bpmCalculation.ageDiscount', {
                          percentage: `${roundWithDecimals(
                            depricationResult?.deprecation?.factor * 100,
                            2,
                          )}%`,
                        })}
                      </span>
                      <span>
                        {formatPrice(
                          depricationResult?.value_gross -
                            depricationResult?.value,
                          true,
                          true,
                          true,
                        )}
                      </span>
                    </FlexParagraph>
                  </DivWithBorder>
                  <FlexBodyHeading level="5" marginTop>
                    <span>{t('bpmCalculation.restBpm')}</span>
                    <span>
                      {formatPrice(depricationResult?.value, true, true, true)}
                    </span>
                  </FlexBodyHeading>
                  <FlexParagraph>
                    <span>{t('bpmCalculation.profitPotential')}</span>
                    <span>
                      {depricationResult?.profitPotential !== null
                        ? formatPrice(
                            depricationResult?.profitPotential,
                            true,
                            true,
                            true,
                          )
                        : t('unknown')}
                    </span>
                  </FlexParagraph>
                </FlexContainer>
              }
              onSubmit={
                !isMobile
                  ? () => {
                      componentDispatch({
                        type: 'setSelectedConsideredResult',
                        payload: {
                          resultId: selectedResultData.id,
                          resultType: mostBenificialResultTypes.deprication,
                        },
                      })
                      setIsDialogOpen(true)
                    }
                  : null
              }
              submitText={
                !isMobile
                  ? t('bpmCalculation.createIndicativeBpmDeclarationForm')
                  : null
              }
            >
              {isMobile && (
                <Typography type="ExplanationParagraph">
                  {t(
                    'bpmCalculation.createIndicativeBpmDeclarationFormOnlyMobile',
                  )}
                </Typography>
              )}
            </StyledPlanChoice>
            <StyledPlanChoice
              disabled={isMobile}
              highlighted={
                mostBeneficialResultType === mostBenificialResultTypes.priceList
              }
              highlightedText={t('mostBeneficial')}
              heading={t('bpmCalculation.bpmBasedOnIndicativeResidualValue')}
              fullBodyHeight
              body={
                priceListResult.success ? (
                  <FlexContainer>
                    {priceListResult?.specificationLines && (
                      <BpmCalculationResultGroups
                        groups={priceListResult.specificationLines}
                      />
                    )}
                    <DivWithBorder>
                      <FlexParagraph bold>
                        <span>{t('bpmCalculation.grossBpm')}</span>
                        <span>
                          {formatPrice(
                            priceListResult?.value_gross,
                            true,
                            true,
                            true,
                          )}
                        </span>
                      </FlexParagraph>
                      <FlexParagraph>
                        <span>
                          {t('bpmCalculation.depreciationBpm', {
                            percentage: `${roundWithDecimals(
                              priceListResult?.deprecation?.factor * 100,
                              2,
                            )}%`,
                          })}
                        </span>
                        <span>
                          {formatPrice(
                            priceListResult?.value_gross -
                              priceListResult?.value,
                            true,
                            true,
                            true,
                          )}
                        </span>
                      </FlexParagraph>
                    </DivWithBorder>
                    <FlexBodyHeading level="5" marginTop>
                      <span>{t('bpmCalculation.restBpm')}</span>
                      <span>
                        {formatPrice(priceListResult?.value, true, true, true)}
                      </span>
                    </FlexBodyHeading>
                    <FlexParagraph>
                      <span>{t('bpmCalculation.profitPotential')}</span>
                      <span>
                        {priceListResult?.profitPotential !== null
                          ? formatPrice(
                              priceListResult?.profitPotential,
                              true,
                              true,
                              true,
                            )
                          : t('unknown')}
                      </span>
                    </FlexParagraph>
                  </FlexContainer>
                ) : (
                  <FlexParagraph>{priceListResult.message}</FlexParagraph>
                )
              }
              onSubmit={
                !isMobile && priceListResult.success
                  ? () => {
                      componentDispatch({
                        type: 'setSelectedConsideredResult',
                        payload: {
                          resultId: selectedResultData.id,
                          resultType: mostBenificialResultTypes.priceList,
                        },
                      })
                      setIsDialogOpen(true)
                    }
                  : null
              }
              submitText={
                !isMobile && priceListResult.success
                  ? t('bpmCalculation.createIndicativeBpmDeclarationForm')
                  : null
              }
            >
              {isMobile && priceListResult.success && (
                <Typography type="ExplanationParagraph">
                  {t(
                    'bpmCalculation.createIndicativeBpmDeclarationFormOnlyMobile',
                  )}
                </Typography>
              )}

              {isMobile && <ContentSeperator paddingLevel={3} />}

              <Typography type="ExplanationParagraph">
                {t('bpmCalculation.createOptimalBpmDeclarationMessage')}
              </Typography>
              <CalculateButton
                disabled={sendEmailToBolsenbroekLoading}
                onClick={() =>
                  sendEmailToBolsenbroek({
                    variables: {
                      carId,
                      appraiserId: 1, // Hardcoded for Bolsenbroek & Partners, see https://app.clickup.com/t/2501750/UCC-4919
                    },
                  })
                }
              >
                {sendEmailToBolsenbroekLoading ? (
                  <LoadingIndicator size="small" />
                ) : (
                  t('bpmCalculation.createOptimalBpmDeclarationForm')
                )}
              </CalculateButton>
            </StyledPlanChoice>

            <StyledPlanChoice
              disabled={
                typeof appraiserId === 'undefined' ||
                (sendEmailToAppraiserCalled && sendEmailToAppraiserLoading)
              }
              heading={t('bpmCalculation.bpmBasedOnTaxationReport')}
              fullBodyHeight
              body={
                bpmAppraisersLoading ||
                (sendEmailToAppraiserCalled && sendEmailToAppraiserLoading) ? (
                  <LoadingIndicator />
                ) : (
                  <BpmCalculationResultAppraiserForm
                    appraiserItems={appraiserItems}
                    appraiserId={appraiserId}
                    setAppraiserId={setAppraiserId}
                  />
                )
              }
              onSubmit={() =>
                sendEmailToAppraiser({
                  variables: {
                    carId,
                    appraiserId,
                  },
                })
              }
              submitText={t('bpmCalculation.contactMe')}
            />
          </PlanChoiceWrapper>
        </>
      )}
    </div>
  )
}

export default withApolloV4Provider(BpmCalculation)
