import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { media } from 'utilities/styled'
import { useTranslation } from 'react-i18next'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import BodyHeading from 'components/atoms/body-heading'
import Button from 'components/atoms/button'
import LoadingButton from 'components/atoms/loading-button'

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background: ${({ theme }) => theme.colors.isolatedBackground};
    box-shadow: ${({ theme }) => theme.shadows.modal};
    overflow: ${({ $overflowVisible }) =>
      $overflowVisible ? 'visible' : 'hidden'};
    padding: ${({ theme }) => theme.sizings.lvl3};

    ${media.tablet`
      padding: ${({ theme }) => theme.sizings.lvl4};
    `}
  }

  .MuiBackdrop-root {
    background: ${({ theme }) => theme.colors.brandAlfa}cc;
  }

  .MuiDialogContent-root {
    padding: 0;
    overflow: ${({ $overflowVisible }) =>
      $overflowVisible ? 'visible' : 'hidden'};
  }

  .MuiDialogActions-root {
    // patch vertical alignment with content:
    padding: ${({ theme }) => theme.sizings.lvl4} 0 0 0;
    flex-direction: column;

    & > :not(:first-child) {
      margin-left: 0px;
      margin-top: ${({ theme }) => theme.sizings.lvl1};
    }

    ${media.tablet`
      flex-direction: row;
      justify-content: space-between;
      & > :not(:first-child) {
        margin-top: 0;
        margin-left: ${({ theme }) => theme.sizings.lvl1};
        margin-top: 0;
      }
    `}
  }
`

const Title = styled(BodyHeading)`
  flex: 0 0 auto;
  padding-bottom: ${({ theme }) => theme.sizings.lvl3};
`

/**
 * This component is dialog window/popup that appears in the center of the screen
 * and adapts it's size depending on the content
 */

function FlexibleDialog({
  cancel,
  cancelText,
  className,
  closeHandler,
  content,
  disableBackdropClick,
  open,
  onCancel,
  onSubmit,
  submitText,
  title,
  disabled,
  overflowVisible,
  isLoading,
  ...restProps
}) {
  const { t } = useTranslation()

  return (
    <StyledDialog
      open={open}
      className={className}
      disableBackdropClick={disableBackdropClick}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      $overflowVisible={overflowVisible}
      {...restProps}
      PaperProps={{
        'data-test-e2e': 'dialog',
        ...restProps?.PaperProps,
        square: true,
      }}
    >
      <Title level="2" styledAs="oldheading">
        {title}
      </Title>
      {content && <DialogContent>{content}</DialogContent>}
      {((onSubmit && submitText) || cancel) && (
        <DialogActions>
          {cancel && (
            <Button
              noPadding
              level="option"
              disabled={disabled || isLoading}
              onClick={onCancel || closeHandler}
              text={cancelText || t('cancel')}
            />
          )}
          {onSubmit && submitText && (
            <LoadingButton
              disabled={disabled}
              isLoading={isLoading}
              level="cta"
              onClick={onSubmit}
              text={submitText}
            />
          )}
        </DialogActions>
      )}
    </StyledDialog>
  )
}

export const propTypes = {
  /** Boolean to determine whether a cancel button should be rendered */
  cancel: PropTypes.bool,
  /** Text for the cancel button, defaults to Cancel if not passed */
  cancelText: PropTypes.string,
  className: PropTypes.string,
  /** Event handler for closing the modal */
  closeHandler: PropTypes.func,
  /** Main content */
  content: PropTypes.node,
  /** If true, clicking the backdrop will not fire the onClose callback. */
  disableBackdropClick: PropTypes.bool,
  /** Event handler for clicking the cancel button, defaults to the closeHandler
   * if not passed */
  onCancel: PropTypes.func,
  /** Event handler for clicking the submit button */
  onSubmit: PropTypes.func,
  /** Open / closes state boolean */
  open: PropTypes.bool,
  /** Text for the submit button */
  submitText: PropTypes.string,
  /** Title to be rendered at the top of the dialog */
  title: PropTypes.string.isRequired,
  /** Disablse the buttons e.g. when an async call is processing */
  disabled: PropTypes.bool,
  /** Forces overflow: visible for the date picker to be visible */
  overflowVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
}

FlexibleDialog.propTypes = propTypes

export const defaultProps = {
  className: null,
  closeHandler: null,
  cancel: false,
  cancelText: null,
  disableBackdropClick: false,
  onCancel: null,
  onSubmit: null,
  submitText: '',
  open: false,
  disabled: false,
  overflowVisible: false,
  content: null,
  isLoading: false,
}

FlexibleDialog.defaultProps = defaultProps

export default FlexibleDialog
