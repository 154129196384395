import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { formatMileage } from 'utilities/format'

import CarIdentityPlate from 'components/molecules/car-identity-plate'
import GalleryImage from 'components/atoms/gallery-image'
import CarBrandAndModel from 'components/molecules/car-brand-and-model'
import Typography from 'components/molecules/typography'

// A compact card which contains a car's basic info (image, license plate, brand, model, type, mileage and year)
const Card = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.sizings.lvl2};
`

const ImageAndLicensePlateContainer = styled.div`
  flex-shrink: 0;
  min-width: ${({ theme }) => theme.sizeByFactor(12)};
  position: relative;
`

const CarImage = styled(GalleryImage)`
  width: 100%;

  & svg {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`

const CarInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${({ theme }) => theme.sizeByFactor(1.5)};
  flex-grow: 0;
`

const StyledCarIdentityPlate = styled(CarIdentityPlate)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  ${({ theme, $styleForCarFileTag }) =>
    $styleForCarFileTag &&
    `
    background-color: white;
    border: none;
    border-radius: 0;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    font-size: ${theme.sizeByFactor(1.25)};
  `}
`

const MetaData = styled(Typography)`
  text-align: left;
  color: ${({ theme }) => theme.colors.brandCharlie};
  margin: 0;
`

const Separator = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.darkOnLightBorder};
  line-height: 1;
  height: auto;
`

const CarCard = ({
  id,
  licensePlate,
  image,
  brand,
  vin,
  model,
  type,
  mileage,
  date,
  ...restProps
}) => {
  const metaDataParts = []

  if (typeof mileage === 'string' || typeof mileage === 'number') {
    metaDataParts.push(formatMileage(mileage))
  }

  if (date) {
    metaDataParts.push(date)
  }

  return (
    <Card {...restProps}>
      <ImageAndLicensePlateContainer>
        <CarImage
          src={image}
          customErrorMessage=""
          alt={`${brand} ${model}`}
          ratio={8 / 5}
          blurredBackground
        />
        <StyledCarIdentityPlate
          $styleForCarFileTag={!licensePlate && !vin}
          carFileId={id}
          licensePlate={licensePlate}
          vin={vin}
          size="small"
        />
      </ImageAndLicensePlateContainer>
      <CarInfo>
        <CarBrandAndModel
          carBrand={brand}
          carModel={model}
          size="small"
          data-test-e2e="car-brand-and-model"
        />
        {metaDataParts.length > 0 && (
          <MetaData type="BodyParagraph">
            {metaDataParts.map((metaData, i) => (
              <React.Fragment key={`linePart-${i}`}>
                {i > 0 && <Separator>&nbsp;|&nbsp;</Separator>}
                {metaData}
              </React.Fragment>
            ))}
          </MetaData>
        )}
      </CarInfo>
    </Card>
  )
}

export const propTypes = {
  id: PropTypes.string.isRequired,
  licensePlate: PropTypes.string.isRequired,
  image: PropTypes.string, // A placeholder is shown if this is not provided
  brand: PropTypes.string,
  vin: PropTypes.string,
  model: PropTypes.string,
  type: PropTypes.string,
  mileage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // This is without the "km" suffix
  date: PropTypes.string, // This string will be used as-is, it will not be formatted.
}

CarCard.propTypes = {
  ...propTypes,
}

export default CarCard
