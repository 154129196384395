import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import Button, { propTypes as buttonPropTypes } from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'

const StyledButton = styled(Button)`
  > span {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.sizeByFactor(1.5)};
  }
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  flex-shrink: 0;
  flex-grow: 0;
  width: auto;
`

const LoadingButton = ({
  isLoading,
  text,
  loadingText,
  disabled: disabledFromProps,
  children,
  ...restProps
}) => {
  const normalContent = children || text
  const loadingContent =
    typeof loadingText === 'string' ? loadingText : normalContent
  const content = isLoading ? loadingContent : normalContent
  const disabled = isLoading || disabledFromProps

  return (
    <StyledButton disabled={disabled} {...restProps}>
      {isLoading && <StyledLoadingIndicator size="extraSmall" />}
      {content}
    </StyledButton>
  )
}

export const propTypes = {
  ...buttonPropTypes,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
}

LoadingButton.propTypes = propTypes

LoadingButton.defaultProps = {
  ...Button.defaultProps,
  loadingText: null,
}

export default LoadingButton
