import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useDynamicEndpointDone from 'hooks/use-dynamic-endpoint-done'
import Text from 'components/atoms/text'
import TextLink from 'components/atoms/text-link'
import Button from 'components/atoms/button'
import TextTable from 'components/molecules/text-table'
import SimpleTable from 'components/layouts/simple-table'

const Header = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};

  & > *:first-child {
    margin-right: ${({ theme }) => theme.sizings.lvl6};
  }

  & > *:last-child {
    margin-left: auto;
    margin-right: 0;
  }
`

const Table = styled(SimpleTable)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl5};
`

const NoPackagesTable = styled(TextTable)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl5};
`

const SegmentTitle = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

function DeliveryPackagesSettings({
  deliveryPackages,
  dynamicEndpoint,
  getDeliveryPackages,
  deleteDeliveryPackage,
  locationId,
  openOverlay,
  postDynamicEndpoint,
}) {
  const { t } = useTranslation()
  const segments =
    deliveryPackages &&
    deliveryPackages[locationId] &&
    deliveryPackages[locationId].data &&
    deliveryPackages[locationId].data.rows
  const hasSegments = segments && segments.length > 0

  useEffect(() => {
    getDeliveryPackages({
      id: locationId,
    })
  }, [getDeliveryPackages, locationId])

  useDynamicEndpointDone(
    dynamicEndpoint,
    () => {
      getDeliveryPackages({
        id: locationId,
      })
    },
    [dynamicEndpoint, getDeliveryPackages, locationId],
  )

  function openPackagesOverlay(formParams) {
    openOverlay('dynamicForm', {
      formId: 'deliveryPackages',
      formParams,
      icon: 'administration',
      onSubmit: (values, endpoint) => {
        postDynamicEndpoint({
          endpoint,
          ...values,
          vestiging_id: locationId,
        })
      },
      subId: formParams && formParams.groep_id,
      submitText: t('saveDeliveryPackage'),
      title: t('newDeliveryPackage'),
    })
  }

  function handleClickNewPackage() {
    openPackagesOverlay({ vestiging_id: locationId })
  }

  function handleClickModify(pckg) {
    openPackagesOverlay({ vestiging_id: locationId, pakket_id: pckg.id })
  }

  function removeDeliveryPackage(pckg) {
    if (window.confirm(t('areYouSure'))) {
      deleteDeliveryPackage({ vestiging_id: locationId, pakket_id: pckg.id })
    }
  }

  return (
    <>
      <Header>
        {!hasSegments && (
          <Text
            text={t('noDeliverySegmentsYetPackages')}
            type="floatingLabelLabel"
          />
        )}
        <Button
          level="cta"
          onClick={handleClickNewPackage}
          text={t('newDeliveryPackage')}
        />
      </Header>
      {hasSegments &&
        segments.map((segment, index) => {
          const packages = segment.pakketten
          return (
            <div key={index.toString()}>
              <SegmentTitle
                key={index.toString()}
                text={segment.omschrijving}
                type="h3"
              />
              {packages && packages.length > 0 ? (
                <Table
                  columns={[
                    <Text
                      key="table-column-description"
                      text={t('description')}
                      type="floatingLabelLabel"
                    />,
                    <Text
                      key="table-column-extra-costs"
                      text={t('extraCostsIncBtw')}
                      type="floatingLabelLabel"
                    />,
                  ]}
                  rows={packages.map((pckg) => [
                    <Text
                      key="table-column-row-description"
                      text={pckg.omschrijving}
                      type="boldLabel"
                    />,
                    <Text
                      key="table-column-row-price"
                      text={`€ ${pckg.meerprijs}`}
                      type="boldLabel"
                    />,
                  ])}
                  actions={packages.map((pckg) => [
                    <TextLink
                      key="table-column-action-modify"
                      text={t('modify')}
                      onClick={() => handleClickModify(pckg)}
                    />,
                    <TextLink
                      key="table-column-action-delete"
                      text={t('delete')}
                      onClick={() => removeDeliveryPackage(pckg)}
                    />,
                  ])}
                />
              ) : (
                <NoPackagesTable
                  columns={[t('description'), t('extraCostsIncBtw')]}
                  rows={[[t('noDeliveryPackage'), null]]}
                />
              )}
            </div>
          )
        })}
    </>
  )
}

DeliveryPackagesSettings.propTypes = {
  deliveryPackages: PropTypes.object,
  dynamicEndpoint: PropTypes.object,
  getDeliveryPackages: PropTypes.func.isRequired,
  deleteDeliveryPackage: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
  openOverlay: PropTypes.func.isRequired,
  postDynamicEndpoint: PropTypes.func.isRequired,
}

DeliveryPackagesSettings.defaultProps = {
  deliveryPackages: null,
  dynamicEndpoint: null,
}

export default DeliveryPackagesSettings
