import React, { useContext } from 'react'
import Overlay from 'components/molecules/overlay'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  GET_PRICE_MATRIX,
  UPDATE_PRICE_MATRIX,
  CREATE_PRICE_MATRIX_RULE,
  UPDATE_PRICE_MATRIX_RULE,
  DELETE_PRICE_MATRIX_RULE,
  GET_DEALER_AND_GET_CAR_MODELS,
  GET_FUEL_TYPES,
} from 'config/graphql/csp'
import PriceMatrixTableForm from 'components/organisms/car-service-plan/price-matrix-table-form'
import LoadingIndicator from 'components/atoms/loading-indicator'
import { DealerContext } from 'contexts/dealer'

const LayoutContainer = styled.div`
  flex: 1;
`

function PriceMatrixDetails({ initiallyDisabled }) {
  const { t } = useTranslation()
  const { priceMatrixId, dealerId } = useParams()
  const history = useHistory()

  // Queries
  const {
    data: priceMatrixData,
    error: priceMatrixError,
    client,
  } = useQuery(GET_PRICE_MATRIX, {
    fetchPolicy: 'network-only',
    variables: {
      id: priceMatrixId,
    },
  })

  const { data: dealerAndCarModelsData, error: dealerAndCarModelsError } =
    useQuery(GET_DEALER_AND_GET_CAR_MODELS, {
      variables: { dealerId },
    })

  const { data: fuelTypesData, error: fuelTypesError } =
    useQuery(GET_FUEL_TYPES)

  const { setPriceMatrixMutating } = useContext(DealerContext)

  const [updatePriceMatrix] = useMutation(UPDATE_PRICE_MATRIX)
  const [updatePriceMatrixRule] = useMutation(UPDATE_PRICE_MATRIX_RULE)
  const [deletePriceMatrixRule] = useMutation(DELETE_PRICE_MATRIX_RULE)
  const [createPriceMatrixRule] = useMutation(CREATE_PRICE_MATRIX_RULE)

  const handleUpdatePriceMatrix = async ({
    id,
    name,
    maxMileagePerYear,
    priceMatrixRules,
  }) => {
    setPriceMatrixMutating(true)
    const updatePriceMatrixPayload = {
      variables: {
        id,
        name,
        maxMileagePerYear,
      },
    }
    // Compares the payload price rules with old price rule to find deleted IDs
    const oldPriceMatrixRules =
      priceMatrixData.priceMatrix.priceMatrixRules || []
    const deletedPriceMatrixRuleIds = oldPriceMatrixRules
      .map((oldPriceRule) => oldPriceRule.id)
      .filter(
        (oldPriceRuleId) =>
          !priceMatrixRules.find(
            (currentPriceRule) => currentPriceRule.id === oldPriceRuleId,
          ),
      )

    try {
      await updatePriceMatrix(updatePriceMatrixPayload)
      const mutations = []
      if (
        Array.isArray(deletedPriceMatrixRuleIds) &&
        deletedPriceMatrixRuleIds.length > 0
      ) {
        deletedPriceMatrixRuleIds.forEach((priceRuleId) => {
          mutations.push(
            deletePriceMatrixRule({ variables: { id: priceRuleId } }),
          )
        })
      }
      if (Array.isArray(priceMatrixRules) && priceMatrixRules.length > 0) {
        priceMatrixRules.forEach((rule) => {
          const payload = rule
          delete payload.actions
          const mutation = payload.id
            ? updatePriceMatrixRule({
                variables: {
                  ...payload,
                },
              })
            : createPriceMatrixRule({
                variables: {
                  ...payload,
                  priceMatrixId: id,
                },
              })
          mutations.push(mutation)
        })
      }

      return Promise.all(mutations).then((_) => {
        const { priceMatrix } = priceMatrixData
        if (priceMatrix) {
          /**
           * HACK: Clear out stale price matrix from local cache
           * so it no longer shows up in the UI. This prevents
           * a user from updating a already updated program, creating a
           * race condition.
           */
          client.cache.evict({
            id: client.cache.identify(priceMatrix),
          })
          // clean up loose end, recommended by Apollo:
          client.cache.gc()
        }

        setPriceMatrixMutating(false)
        history.goBack()
      })
    } catch (e) {
      setPriceMatrixMutating(false)
      history.goBack()
      throw new Error(e)
    }
  }

  return (
    <Overlay
      close={() => history.goBack()}
      title={t('carServicePlanAdminPriceMatrixOverlay.detailsHeading')}
      icon="cleaning"
    >
      <LayoutContainer>
        {(priceMatrixData || !priceMatrixId) &&
        dealerAndCarModelsData &&
        fuelTypesData ? (
          <PriceMatrixTableForm
            onSubmit={handleUpdatePriceMatrix}
            priceMatrix={priceMatrixData.priceMatrix}
            dealerName={dealerAndCarModelsData.dealer.name}
            carServicePlanId={dealerAndCarModelsData.dealer.carServicePlan.id}
            carBrandsAndModels={
              dealerAndCarModelsData ? dealerAndCarModelsData.brands : []
            }
            fuelTypes={fuelTypesData ? fuelTypesData.fuelTypes : []}
            enableReinitializeForm={false}
            initiallyDisabled={initiallyDisabled}
          />
        ) : (
          <LoadingIndicator
            error={
              priceMatrixError || dealerAndCarModelsError || fuelTypesError
            }
          />
        )}
      </LayoutContainer>
    </Overlay>
  )
}

PriceMatrixDetails.propTypes = {
  initiallyDisabled: PropTypes.bool,
}

PriceMatrixDetails.defaultProps = {
  initiallyDisabled: false,
}

export default PriceMatrixDetails
