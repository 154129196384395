import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import LocationsSettings from 'components/redux-containers/locations-settings-container'
import CompanyLogoForm from 'components/redux-containers/company-logo-form-container'
import BackgroundImageForm from 'components/redux-containers/background-image-form-container'
import SettingsForm from 'components/redux-containers/settings-form-container'

function LocationsMarktplaatsSettings({ match }) {
  const { t } = useTranslation()
  const tabItems = [
    {
      label: t('settings'),
      to: `${match.url}/settings`,
      component: (props) => (
        <SettingsForm formId="marktplaatsSettingsForm" {...props} />
      ),
    },
    {
      label: t('companyLogo'),
      to: `${match.url}/company-logo`,
      component: (props) => <CompanyLogoForm {...props} />,
    },
    {
      label: t('backgroundImage'),
      to: `${match.url}/background-image`,
      component: (props) => <BackgroundImageForm {...props} />,
    },
  ]

  return (
    <LocationsSettings title={t('locationsOverview')} tabItems={tabItems} />
  )
}

LocationsMarktplaatsSettings.propTypes = {
  match: PropTypes.object.isRequired,
}

LocationsMarktplaatsSettings.defaultProps = {}

export default withRouter(LocationsMarktplaatsSettings)
