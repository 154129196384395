import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import MediaForm from 'components/organisms/media-form'

function CompanyLogoForm({
  addCompanyLogo,
  companyLogo,
  getCompanyLogo,
  locationId,
}) {
  const { t } = useTranslation()
  const loading = companyLogo && companyLogo.loading
  const photo =
    companyLogo && companyLogo[locationId] && companyLogo[locationId].data

  useEffect(() => {
    getCompanyLogo({ id: locationId })
  }, [getCompanyLogo, locationId])

  function handleUpload(image) {
    addCompanyLogo({
      id: locationId,
      image,
    })
  }

  return (
    <>
      {!loading && photo && (
        <MediaForm
          level="standard"
          items={[
            {
              name: t('uploadYourCompanyLogo'),
              type: 'image',
              src: photo,
            },
          ]}
          onUpload={handleUpload}
          title={t('uploadYourCompanyLogo')}
          type="images"
          uploadButtonText={t('uploadPhoto')}
          single
        />
      )}
    </>
  )
}

CompanyLogoForm.propTypes = {
  addCompanyLogo: PropTypes.func.isRequired,
  companyLogo: PropTypes.object,
  getCompanyLogo: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
}

CompanyLogoForm.defaultProps = {
  companyLogo: null,
}

export default CompanyLogoForm
