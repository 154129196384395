import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'

import * as routes from 'config/routes'

import { getCarfile } from 'redux/actions/data'

import { translateForm } from 'utilities/form-utils'
import { mapCarFileType } from 'utilities/mapping'
import { media } from 'utilities/styled'
import {
  getBackendNapValue,
  getObjectFromQueryString,
  scrollToTop,
} from 'utilities/utils'

import LoadingIndicator from 'components/atoms/loading-indicator'
import FullWidthTopBarLayout from 'components/layouts/full-width-top-bar-layout'
import CarDataForm from 'components/molecules/cardata-form'
import PageHeader from 'components/organisms/page-header'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.brandFoxtrot};
  padding: 0 20px;

  ${media.desktop`
    padding: 0 60px;
  `};
`

function CarEntryValuationStep1Manual({
  createCarfile,
  forms,
  getForms,
  nap,
  newCar,
  updateCarfile,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const queryParams = getObjectFromQueryString(location.search)
  const carId = queryParams && queryParams.auto_id
  const carFile = useSelector(
    (state) => state.data && state.data.carfile && state.data.carfile.data,
  )
  const napValue = nap && nap.data && nap.data.nap
  const napBackendValue = getBackendNapValue(napValue)
  const car = {
    ...newCar,
    ...carFile,
    nap: napBackendValue,
  }
  const formId = 'carValuation'
  const formTopId = `${formId}-top`
  const formTop =
    useSelector(
      (state) =>
        state.form && state.form[formTopId] && state.form[formTopId].values,
    ) || {}

  useEffect(() => {
    if (carId && !carFile) {
      dispatch(getCarfile(carId))
    }
  }, [carFile, carId, dispatch])

  useEffect(() => {
    getForms('valuation')
    // has no dependencies, so it will only run once on componentDidMount and
    // on at that time. This ensures that a clean version of the forms config
    // is retrieved for car entry, otherwise that forms for the last visited
    // carFile are used.
    // documented in: https://app.clickup.com/t/6unbvp
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!forms) {
      getForms('valuation')
    }
  }, [car.kenteken, carId, forms, getForms])

  useEffect(() => {
    scrollToTop()
  }, [])

  async function handleSubmit(data) {
    const carfileData = {
      kenteken: car.kenteken,
      ...data,
      ...formTop,
    }

    if (carId) {
      updateCarfile(
        {
          ...car,
          ...carfileData,
        },
        carId,
      )
    } else {
      const response = await createCarfile({
        ...car,
        ...carfileData,
      })
      history.push(
        `${routes.VALUATION}/2?auto_id=${response.data.data.auto_id}`,
      )
    }

    return true
  }

  const valuationProcessStartLink = useSelector((state) => {
    const actions = state?.data?.menus?.data?.actions
    if (Array.isArray(actions)) {
      const link = actions.find((action) => action.id === 'valuation')?.link
      if (link) {
        return link
      }
    }
    return `${routes.VALUATION}/1`
  })

  return (
    <Container>
      <PageHeader
        buttonTo={valuationProcessStartLink}
        mainTitle={mapCarFileType(t, car?.voertuigsoort_mapped)}
        status={t('valuation')}
        subTitle={t('manualEntry')}
      />
      <FullWidthTopBarLayout
        mainSlot={
          !forms || forms.loading ? (
            <LoadingIndicator />
          ) : (
            <CarDataForm
              carFile={car}
              fieldsets={[
                ...translateForm(forms.data.basics_form_extra.fieldsets, t),
              ]}
              formId={formId}
              level="cta"
              onSubmit={handleSubmit}
              manual
              next
              scrollToTopOnError
            />
          )
        }
      />
    </Container>
  )
}

CarEntryValuationStep1Manual.propTypes = {
  createCarfile: PropTypes.func.isRequired,
  forms: PropTypes.object,
  getForms: PropTypes.func.isRequired,
  nap: PropTypes.object,
  newCar: PropTypes.object,
  updateCarfile: PropTypes.func.isRequired,
}

CarEntryValuationStep1Manual.defaultProps = {
  forms: null,
  nap: undefined,
  newCar: null,
}

export default withRouter(CarEntryValuationStep1Manual)
