import { connect } from 'react-redux'
import { getLocations, getStayTime, getLocationTodos } from 'redux/actions/data'
import { openOverlay } from 'redux/actions/ui'
import DashboardView from '../views/dashboard-view'

const mapStateToProps = ({ auth, data }) => ({
  auth,
  locations: data.locations,
  locationTodos: data.locationTodos,
  stayTime: data.stayTime,
})

const mapDispatchToProps = {
  getLocations,
  getStayTime,
  getLocationTodos,
  openOverlay,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView)
