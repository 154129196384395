import { connect } from 'react-redux'
import { openOverlay } from 'redux/actions/ui'
import { sendPawnListRequest } from 'redux/actions/data'

import LocationContactSettings from '../views/settings/location-contact-settings'

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  openOverlay,
  sendPawnListRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationContactSettings)
