/* eslint-disable no-unused-expressions  */

import { createGlobalStyle } from 'styled-components'
import { styles as textStyles } from 'components/atoms/text'

export default createGlobalStyle`
  .SingleDatePicker {
    padding: 2px 0px 4px;
  }

  .MuiFilledInput-root .SingleDatePicker {
    padding: 26px 12px 8px 10px;
  }

  .SingleDatePicker_picker {
    z-index: 2000;
  }

  .SingleDatePicker,
  .SingleDatePickerInput,
  .DateInput,
  .DateInput_input {
    width: 100%;
  }

  .DateInput_input {
    ${textStyles.body};
    font-weight: 400;

    &::placeholder {
      ${textStyles.body};
      color: ${({ theme }) => theme.colors.inputPlaceholder};
      font-weight: 400;
    }
  }

  .DateInput,
  .DateInput_input,
  .DateInput_input__focused,
  .SingleDatePickerInput {
    background-color: transparent;
  }

  .DateInput_input__focused {
    border-bottom: none;
  }

  .DateInput_fang {
    display: none;
  }

  .DateInput_input__small {
    padding: 0;
  }

  .DayPicker_weekHeader {
    margin-top: ${({ theme }) => theme.sizings.lvl1};

    * {
      ${textStyles.floatingLabelLabel}
    }
  }

  .DayPickerNavigation_button__horizontalDefault {
    padding: 3px 4px;
    top: 32px;
  }

  .CalendarDay {
    vertical-align: middle;
  }

  .CalendarMonth_table {
    * {
      ${textStyles.input}
    }
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${({ theme }) => theme.colors.actionsStandard};
    border-color: ${({ theme }) => theme.colors.actionsStandard};
  }

  .DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right-color: ${({ theme }) => theme.colors.actionsStandardSecondary};
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right-color: ${({ theme }) => theme.colors.actionsStandard};

  }
`
