import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Overlay from 'components/molecules/overlay'

const Iframe = styled.iframe`
  height: 100%;
  padding: 10%;
  width: 100%;
`

function IframeOverlay({ closeOverlay, url }) {
  const overlayId = 'iframe'

  return (
    <Overlay close={() => closeOverlay(overlayId)}>
      <Iframe src={url} title="iframe" />
    </Overlay>
  )
}

IframeOverlay.propTypes = {
  closeOverlay: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
}

export default IframeOverlay
