/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

import data from 'utilities/data'
import { formatPrice } from 'utilities/format'
import { media } from 'utilities/styled'
import { convertStringToType } from 'utilities/utils'

import {
  CarFileStatusEnumLowerCase,
  CarFileStatusEnumUpperCase,
} from 'config/enums'
import { contractStatuses, prospects } from 'config/data'
import {
  ADD_INCOMPLETE_CONTRACT,
  GET_CAR_BY_TCO,
  GET_CAR_FROM_LICENSE_PLATE_QUERY,
  GET_CONTRACT,
} from 'config/graphql/csp'
import {
  CAR_FILE,
  CSP,
  CSP_DEMO_SALES_FLOW,
  CSP_SALES_FLOW,
} from 'config/routes'

import LoadingIndicator from 'components/atoms/loading-indicator'
import TextLink from 'components/atoms/text-link'
import FullWidthTopBarLayout from 'components/layouts/full-width-top-bar-layout'
import CarEntryHeader from 'components/molecules/car-entry-header'
import FlowStepper from 'components/molecules/flow-stepper'
import Typography from 'components/molecules/typography'
import GeneralDataError from 'components/organisms/general-data-error'
import PageHeader from 'components/organisms/page-header'

import CspSalesBasic from './steps/csp-sales-basic'
import CspSalesCustomerData from './steps/csp-sales-customer'
import CspSalesDocuments from './steps/csp-sales-documents'
import CspSalesResetDialog from './steps/csp-sales-resetdialog'
import CspSalesSaveAndExit from './steps/csp-sales-saveandexit'
import CspSalesServicePlans from './steps/csp-sales-serviceplans'
import CspSalesSuccess from './steps/csp-sales-success'
import CspSalesUpdateAddtionalFeesDialog from './steps/csp-sales-update-addtional-fees-dialog'
import CspSalesUpdateVehicleDialog from './steps/csp-sales-updatevehicledialog'

const StyledCarEntryHeader = styled(CarEntryHeader)`
  border-bottom: ${({ theme, $hasBorderBottom }) =>
    $hasBorderBottom ? `${theme.colors.darkOnLightBorder} 1px solid` : '0'};
`
const Container = styled.div`
  padding: ${({ theme }) => theme.sizings.lvl2} 20px;
  ${media.desktop`
        padding: ${({ theme }) => theme.sizings.lvl2} 60px;
    `}

  @media print {
    padding: 0;

    .TopBar .MuiCardContent-root {
      display: none;
    }

    .MainContent {
      > .MuiCardContent-root {
        padding: 0;
      }
    }
  }
`
const ErrorContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`
const ErrorTextLink = styled(TextLink)`
  display: inline;
  font-size: 12px;
  font-family: sans-serif;
  text-decoration: underline;
`

const StyledPageHeader = styled(PageHeader)`
  padding: 0 0 ${({ theme }) => theme.sizings.lvl4};
  ${media.tablet`
    padding: ${({ theme }) => theme.sizings.lvl3} 0;
  `}
`

const step0RequiredFields = [
  // 1. Base data
  'dealerId',
  'currentMileage',
  'prospect',
]
const step1RequiredFields = [
  // 2. Pick CSP plan
  'dealerId',
  'currentMileage',
  'mileagePerYear',
  'hasFactoryWarranty',
]
const step2RequiredFields = [
  // 3. Customer details
  'activationDate',
  'servicePlan',
  'dealerId',
  'carId',
  'packageCalculationResultId',
]
const step3RequiredFields = [
  // 4. Documents
  'id',
  'servicePlan',
  'dealerId',
  'carId',
  'packageCalculationResultId',
]
const step4RequiredFields = [
  // Success page
  'id',
  'status',
]
const addIncompleteContractRequiredFields = [
  'dealerId',
  'carId',
  'customerId',
  'packageCalculationResultId',
  'driverId',
]

const stepRequiredFields = [
  step0RequiredFields,
  step1RequiredFields,
  step2RequiredFields,
  step3RequiredFields,
  step4RequiredFields,
]
const allowedUrlParams = [].concat(...stepRequiredFields, [
  'warrantyProgramId',
  'carFileId',
  'warrantyProviderId',
  'warrantyProviderName',
  'additionalFeeIds',
  'additionalInput',
  'tcoId',
  'carBuildYear',
  'carId',
  'licensePlate',
])

const urlparamsThatAreArrays = ['additionalFeeIds']

const urlparamsThatAreObjects = ['additionalInput']

const { UNSIGNED, SIGNED, ACTIVE } = contractStatuses

const constructSearchParams = (payload) => {
  const searchParams = new URLSearchParams()
  if (payload.id) {
    // A contract ID was found, use that and filter out any irrelevant data
    searchParams.set('id', payload.id)
  } else {
    // No contract ID was found, construct the search params according to the allowed keys
    Object.keys(payload).forEach((key) => {
      if (payload[key] !== undefined && allowedUrlParams.includes(key)) {
        if (
          urlparamsThatAreArrays.includes(key) &&
          Array.isArray(payload[key])
        ) {
          payload[key].forEach((itemValue) => {
            searchParams.append(key, itemValue)
          })
        } else if (
          urlparamsThatAreObjects.includes(key) &&
          typeof payload[key] === 'object'
        ) {
          Object.keys(payload[key]).forEach((nestedKey) => {
            searchParams.set(`${key}_${nestedKey}`, payload[key][nestedKey])
          })
        } else if (typeof payload[key] !== 'object') {
          searchParams.set(key, payload[key])
        }
      }
    })
  }
  return searchParams
}

function CSPSalesFlow({ userDetails, match }) {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const firstUpdate = useRef(true)
  const activeStepForwardsCheckDone = useRef(false)
  const { stepId } = match.params
  const [activeStep, setActiveStep] = useState(
    stepId !== undefined ? parseInt(stepId, 10) : 0,
  )
  const userHasUCCLicense = userDetails && userDetails.pakket !== false
  const searchParams = new URLSearchParams(location.search)
  const instanceId = useSelector((state) => state.auth.instanceId)
  const [disabledDealer, setDisabledDealer] = useState(false)
  const [confirmResetModalOpen, setConfirmResetModalOpen] = useState(false)
  const [saveAndCloseModalOpen, setSaveAndCloseModalOpen] = useState(false)
  const [updateVehicleModalOpen, setUpdateVehicleModalOpen] = useState(false)
  const [updateAdditionalFeesDialogOpen, setUpdateAdditionalFeesDialogOpen] =
    useState(false)

  const urlPayload = {}

  allowedUrlParams.forEach((key) => {
    if (urlparamsThatAreArrays.includes(key)) {
      urlPayload[key] = searchParams.getAll(key).map(convertStringToType)
    } else if (urlparamsThatAreObjects.includes(key)) {
      const searchParamObject = {}

      searchParams.forEach((searchParamValue, searchParamKey) => {
        const prefix = `${key}_`
        if (searchParamKey.includes(prefix)) {
          const searchParamKeyWithoutPrefix = searchParamKey.replace(prefix, '')
          searchParamObject[searchParamKeyWithoutPrefix] = searchParamValue
        }
      })

      urlPayload[key] = searchParamObject
    } else {
      const value = searchParams.get(key)
      if (value) {
        urlPayload[key] = convertStringToType(value)
      }
    }
  })

  /** This payload will get new attributes on each step submission.
   * Once all steps are complete it will be used as the payload for the mutation. */
  const [payload, setPayload] = useState(urlPayload)

  const contract = useQuery(GET_CONTRACT, {
    variables: {
      id: payload.id,
    },
    skip: !payload.id,
    fetchPolicy: 'network-only',
  })
  const carByTco = useQuery(GET_CAR_BY_TCO, {
    variables: {
      tcoId: payload.tcoId,
      registrationDate: `${payload.carBuildYear}-01-01`,
      dealerId: payload.dealerId,
    },
    skip: !payload.tcoId || !payload.dealerId,
    fetchPolicy: 'network-only',
  })
  const car = useQuery(GET_CAR_FROM_LICENSE_PLATE_QUERY, {
    variables: {
      licensePlate: payload.licensePlate,
      dealerId: payload.dealerId,
    },
    skip: !payload.licensePlate || !payload.dealerId,
    fetchPolicy: 'network-only',
  })
  const [addIncompleteContract, addIncompleteContractResult] = useMutation(
    ADD_INCOMPLETE_CONTRACT,
  )

  useEffect(() => {
    if (stepId !== activeStep) {
      setActiveStep(stepId !== undefined ? parseInt(stepId, 10) : 0)
    }
  }, [stepId])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    history.push({
      pathname: `${CSP_SALES_FLOW}/${activeStep}`,
      search: `?${searchParams.toString()}`,
    })
  }, [activeStep, history])

  useEffect(() => {
    const newSearchParams = constructSearchParams(payload)
    history.replace({
      pathname: `${CSP_SALES_FLOW}/${activeStep}`,
      search: `?${newSearchParams.toString()}`,
    })
  }, [payload, history])

  useEffect(() => {
    if (carByTco?.data?.carByTcoId || car?.data?.carByLicensePlate) {
      const carData = carByTco.data?.carByTcoId || car.data?.carByLicensePlate
      setPayload((oldPayload) => ({
        ...oldPayload,
        car: {
          carId: carData.id,
          registrationDate: carData.registrationDate,
          licensePlate: carData.licensePlate,
        },
        carId: carData.id,
        carFileId: carData.carFileId || oldPayload.carFileId,
        additionalInputOptions: carData.additionalInputOptions || [],
      }))
      setUpdateVehicleModalOpen(!carData.model)
      setDisabledDealer(carData.dealer.carServicePlan.isDisabled)
    }
  }, [car, carByTco])

  useEffect(() => {
    // @todo: update this to retrieve the carFile data through GraphQL:
    if (payload.carFileId && instanceId) {
      const fetchCarFile = async () => {
        const response = await data.getCarfile(instanceId, {
          carId: payload.carFileId,
        })
        if (Object.keys(response).length) {
          setPayload((oldPayload) => ({
            ...oldPayload,
            carFile: response,
          }))
        }
      }
      fetchCarFile()
    }
  }, [payload.carFileId, instanceId])

  useEffect(() => {
    if (contract.data && contract.data.contract) {
      setPayload((oldPayload) => ({
        ...oldPayload,
        ...contract.data.contract,
        carId: contract.data.contract.car.id,
        carFileId: contract.data.contract.car.carFileId || payload.carFileId,
        licensePlate: contract.data.contract.car.licensePlate,
        currentMileage: contract.data.contract.calculation.currentMileage,
        mileagePerYear: contract.data.contract.calculation.mileagePerYear,
        warrantyProgramId: contract.data.contract.packageCalculationResult
          .calculation.warrantyProgram
          ? contract.data.contract.packageCalculationResult.calculation
              .warrantyProgram.id
          : undefined,
        warrantyProviderId: contract.data.contract.packageCalculationResult
          .calculation.warrantyProgram
          ? contract.data.contract.packageCalculationResult.calculation
              .warrantyProgram.warrantyProvider.id
          : undefined,
        warrantyProviderName: contract.data.contract.packageCalculationResult
          .calculation.warrantyProgram
          ? contract.data.contract.packageCalculationResult.calculation
              .warrantyProgram.warrantyProvider.name
          : undefined,
        servicePlan: {
          ...contract.data.contract.packageCalculationResult,
          isServicePlanPlus:
            contract.data.contract.packageCalculationResult.package.packageType.indexOf(
              'PLUS',
            ) !== -1,
        },
      }))
    }
  }, [payload.carFileId, contract, contract.data])

  const isLoading =
    contract.loading ||
    carByTco.loading ||
    car.loading ||
    addIncompleteContractResult.loading
  const hasError =
    addIncompleteContractResult.error ||
    (car.error && payload.licensePlate) ||
    contract.error
  const requiresAdditionalLoading =
    !!contract.data && !carByTco.data && !car.data

  const hasInputForCarData = payload.licensePlate || payload.tcoId
  const incorrectPayloadMessage =
    (payload.currentMileage === undefined ||
      !payload.dealerId ||
      !hasInputForCarData) &&
    !payload.id
      ? t('cspSalesFlow.incorrectPayload')
      : undefined

  const incorrectPayloadPage = (
    <Container>
      <PageHeader
        mainTitle={t('cspSalesFlow.heading')}
        status={t('cspSalesFlow.headingStatus')}
      />
      {(incorrectPayloadMessage || isLoading || requiresAdditionalLoading) &&
        !hasError && <LoadingIndicator error={incorrectPayloadMessage} />}
      {hasError && <GeneralDataError />}
    </Container>
  )

  /** Check if the contract has all required fields for the active step
   * and contract is in correct status */
  if (
    isLoading ||
    requiresAdditionalLoading ||
    hasError ||
    incorrectPayloadMessage
  ) {
    return incorrectPayloadPage
  }

  // Do some checks if the provided data matches the active step. Either send the user forward or
  // backwards, based on the data.

  // This first check is to make sure clients with a signed or active contract can never edit their
  // contract in the CSP flow.
  if (!activeStepForwardsCheckDone.current) {
    activeStepForwardsCheckDone.current = true
    if (payload.status === SIGNED || payload.status === ACTIVE) {
      setActiveStep(4)
      return incorrectPayloadPage
    }
  }

  // Check if the required fields are present for the current step
  const activeStepIsValid =
    stepRequiredFields[activeStep] &&
    stepRequiredFields[activeStep].every((requiredFieldName) => {
      return payload[requiredFieldName] !== undefined
    }) &&
    // Step 3 (Documenten): SIGNED and ACTIVE contracts need to be allowed in this step, because the
    // payload will update after the user uploads a signed document in this step. An initial check
    // (for when the user enters the CSP flow) is done above.
    !(
      activeStep === 3 &&
      payload.status !== UNSIGNED &&
      payload.status !== SIGNED &&
      payload.status !== ACTIVE
    ) &&
    !(
      activeStep === 4 &&
      payload.status !== SIGNED &&
      payload.status !== ACTIVE
    ) // Step 4 (Thanks page)

  if (activeStep > 0 && !activeStepIsValid) {
    setActiveStep((oldStep) => oldStep - 1)
    return incorrectPayloadPage
  }

  const handleNextStep = () => {
    setActiveStep((activeStep) => activeStep + 1)
  }

  const handleStepSubmit = async (values) => {
    const newPayload = { ...payload, ...values }
    setPayload(newPayload)

    const canMakeIncompleteContract = !addIncompleteContractRequiredFields.some(
      (key) => !Object.prototype.hasOwnProperty.call(newPayload, key),
    )

    switch (activeStep) {
      // Submission of step 2: Picking a service plan calculation
      case 1:
        if (values.packageCalculationResultId && !saveAndCloseModalOpen) {
          setSaveAndCloseModalOpen(true)
        } else if (saveAndCloseModalOpen) {
          if (values.customerId) {
            if (canMakeIncompleteContract) {
              await addIncompleteContract({ variables: newPayload })
            }
          } else {
            setSaveAndCloseModalOpen(false)
            handleNextStep()
          }
        }
        break

      // Submission of step 3, Customer Info
      case 2:
        if (
          values.status === 'UNSIGNED' &&
          payload.servicePlan.packageType !== 'BASIC'
        ) {
          handleNextStep()
        }
        break

      // Submission of step 1: Basic info
      // Submission of step 4: Document uploads
      // These steps do not require additional handling, so using the default case for them.
      default:
        handleNextStep()
        break
    }
  }

  const resetFlow = () => {
    const params = new URLSearchParams()
    const neededPayloadKeys = [
      'dealerId',
      'licensePlate',
      'currentMileage',
      'mileagePerYear',
      'activationDate',
      'hasFactoryWarranty',
      'prospect',
    ]

    neededPayloadKeys.forEach((key) => {
      if (payload[key]) {
        params.set(key, payload[key])
      }
    })

    if (payload.demo) {
      history.push(`${CSP_DEMO_SALES_FLOW}?${params.toString()}`)
    } else {
      history.push(`${CSP}?${params.toString()}`)
    }
  }

  const handleResetFlow = () => {
    if (payload.demo) {
      resetFlow()
    } else if (!confirmResetModalOpen) {
      setConfirmResetModalOpen(true)
    }
  }

  const handleOnChangeMileagePerYear = (newMileagePerYear) => {
    setPayload((oldPayload) => ({
      ...oldPayload,
      mileagePerYear: newMileagePerYear,
    }))
    setUpdateAdditionalFeesDialogOpen(activeStep === 1)
  }

  const onAdditionalFeesChange = (additionalFeeIds) => {
    setPayload((oldPayload) => ({ ...oldPayload, additionalFeeIds }))
  }

  const steps = [
    // step 0
    {
      label: t('cspSalesFlow.steps.basicData.title'),
      id: 'basic',
      content: (
        <CspSalesBasic
          onSubmit={handleStepSubmit}
          disabledDealer={disabledDealer}
          payload={payload}
          hasUCCLicense={userHasUCCLicense}
          hideWarrantyProgramSelection={payload.prospect === prospects.WORKSHOP}
        />
      ),
    },
    // step 1
    {
      label: t('cspSalesFlow.steps.warrantyPlan.title'),
      id: 'warrantyPlan',
      content: payload.mileagePerYear ? (
        <CspSalesServicePlans
          onSubmit={payload.demo ? undefined : handleStepSubmit}
          payload={payload}
        />
      ) : (
        ''
      ),
    },
    // step 2
    {
      label: t('cspSalesFlow.steps.customerData.title'),
      id: 'customerData',
      content:
        payload.servicePlan && payload?.car?.carId ? (
          <CspSalesCustomerData onSubmit={handleStepSubmit} payload={payload} />
        ) : (
          ''
        ),
    },
    // step 3
    {
      label: t('cspSalesFlow.steps.documents.title'),
      id: 'documents',
      content:
        payload.id && payload.servicePlan ? (
          <CspSalesDocuments payload={payload} onSubmit={handleStepSubmit} />
        ) : (
          ''
        ),
    },
    // step 4
    {
      label: 'Success',
      id: 'success',
      content: payload.id ? <CspSalesSuccess payload={payload} /> : '',
    },
  ]
  steps[activeStep].active = true

  // Only show save and close button in step 3 and 4 (steps are 0-indexed)
  const showSaveAndClose = [2, 3].includes(activeStep)

  const carData =
    carByTco.data?.carByTcoId || car.data?.carByLicensePlate || payload?.car
  if (carByTco.loading || car.loading || !carData) {
    return <LoadingIndicator />
  }

  // Get picture of car
  let carPicture
  if (Array.isArray(carData?.carPictures) && carData.carPictures.length > 0) {
    carPicture =
      carData.carPictures.find((picture) => picture.type === 'EXTERIOR') ||
      carData.carPictures[0]
  }

  return (
    <Container>
      <StyledPageHeader
        mainTitle={t('cspSalesFlow.heading')}
        status={t('cspSalesFlow.headingStatus')}
        optionsSlot={
          showSaveAndClose ? (
            <TextLink
              text={t('saveAndClose')}
              onClick={() => setSaveAndCloseModalOpen(true)}
            />
          ) : null
        }
      />
      <FullWidthTopBarLayout
        topBarNoPaddingSlot={
          <StyledCarEntryHeader
            imagePath={carPicture ? carPicture.url : undefined}
            brand={carData.brand.name}
            model={carData.model ? carData.model.name : undefined}
            mileage={carData.mileage || payload.currentMileage}
            mileagePerYear={
              activeStep !== 0 ? payload.mileagePerYear : undefined
            }
            mileagePerYearOnChange={
              activeStep === 1 ? handleOnChangeMileagePerYear : null
            }
            type={
              payload.carFile && payload.carFile.type
                ? payload.carFile.type
                : null
            }
            buttonOnClick={activeStep === 4 ? null : handleResetFlow}
            licensePlate={payload.licensePlate}
            year={moment(carData.registrationDate).year()}
            price={
              payload.carFile && payload.carFile.sellingprice
                ? formatPrice(
                    parseFloat(payload.carFile.sellingprice),
                    false,
                    true,
                    true,
                  )
                : ''
            }
            $hasBorderBottom={activeStep !== steps.length - 1}
            dealerId={payload.dealerId}
          />
        }
        topBarSlot={
          activeStep !== steps.length - 1 && !payload.demo ? (
            <FlowStepper
              items={steps
                .filter((step) => step.id !== 'success')
                .map((step, index) => {
                  if (index > activeStep) {
                    return { ...step, disabled: true }
                  }
                  return step
                })}
              onChange={(val) => setActiveStep(val)}
            />
          ) : null
        }
        mainSlot={
          <>
            {payload.carFile &&
              payload.carFile.status ===
                CarFileStatusEnumLowerCase[
                  CarFileStatusEnumUpperCase.lopend
                ] && (
                <ErrorContainer>
                  <Typography type="ErrorText">
                    {t('cspSalesFlow.errorCarStatus')}
                  </Typography>
                  <ErrorTextLink
                    color="stateNegative"
                    text={t('cspSalesFlow.errorCarStatusLink')}
                    to={`${CAR_FILE}/${carData.carFileId}`}
                  />
                </ErrorContainer>
              )}
            {steps[activeStep].content}
          </>
        }
        paddingLevelTopBar={activeStep === steps.length - 1 ? 0 : 2}
        paddingLevelMain={activeStep === steps.length - 1 ? 0 : 4}
      />
      {confirmResetModalOpen && (
        <CspSalesResetDialog
          onReset={() => {
            resetFlow()
          }}
          onCancel={() => {
            setConfirmResetModalOpen(false)
          }}
          data-test-e2e="modal-confirm-reset"
        />
      )}
      {saveAndCloseModalOpen && (
        <CspSalesSaveAndExit
          onClose={() => setSaveAndCloseModalOpen(false)}
          onContinue={() => {
            setSaveAndCloseModalOpen(false)
            handleNextStep()
          }}
          payload={payload}
          initialStep={activeStep > 1 ? 1 : 0}
          data-test-e2e="modal-save-and-exit"
        />
      )}
      {updateVehicleModalOpen && (
        <CspSalesUpdateVehicleDialog
          car={carData}
          payload={payload}
          data-test-e2e="modal-update-vehicle"
        />
      )}
      {updateAdditionalFeesDialogOpen && (
        <CspSalesUpdateAddtionalFeesDialog
          payload={payload}
          handleClose={() => setUpdateAdditionalFeesDialogOpen(false)}
          onSubmit={onAdditionalFeesChange}
          data-test-e2e="modal-update-additional-fees"
        />
      )}
    </Container>
  )
}

CSPSalesFlow.propTypes = {
  userDetails: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default CSPSalesFlow
