import { gql } from '@apollo/client'
import contractLayoutFragment from '../fragments/contractLayout'
import cspContract from '../fragments/cspContract'

export const UPDATE_CONTRACT = gql`
  mutation updateContract(
    $id: ID!
    $driverId: ID
    $packageCalculationResultId: ID!
  ) {
    updateContract(
      id: $id
      driverId: $driverId
      packageCalculationResultId: $packageCalculationResultId
    ) {
      id
      status
    }
  }
`

export const ADD_INCOMPLETE_CONTRACT = gql`
  mutation addIncompleteContract(
    $dealerId: ID!
    $driverId: ID
    $carId: ID!
    $customerId: ID!
    $packageCalculationResultId: ID!
    $activationDate: Date!
  ) {
    addIncompleteContract(
      dealerId: $dealerId
      driverId: $driverId
      carId: $carId
      customerId: $customerId
      packageCalculationResultId: $packageCalculationResultId
      activationDate: $activationDate
    ) {
      id
      status
    }
  }
`

export const ADD_UNSIGNED_CONTRACT = gql`
  mutation addUnsignedContract(
    $dealerId: ID!
    $carId: ID!
    $customerId: ID!
    $driverId: ID!
    $packageCalculationResultId: ID!
    $activationDate: Date!
    $vin: String
    $iban: String!
    $bankName: String!
  ) {
    addUnsignedContract(
      dealerId: $dealerId
      carId: $carId
      customerId: $customerId
      driverId: $driverId
      packageCalculationResultId: $packageCalculationResultId
      activationDate: $activationDate
      vin: $vin
      iban: $iban
      bankName: $bankName
    ) {
      id
      status
    }
  }
`

export const SET_CONTRACT_UNSIGNED = gql`
  mutation setContractToUnsigned(
    $id: ID!
    $driverId: ID!
    $packageCalculationResultId: ID!
    $activationDate: Date!
    $vin: String
    $iban: String!
    $bankName: String!
  ) {
    setContractToUnsigned(
      id: $id
      driverId: $driverId
      packageCalculationResultId: $packageCalculationResultId
      activationDate: $activationDate
      vin: $vin
      iban: $iban
      bankName: $bankName
    ) {
      id
      status
    }
  }
`

export const GET_CONTRACT = gql`
  query GetContract($id: ID) {
    contract(id: $id) {
      ...cspContract
    }
  }
  ${cspContract}
`

export const SET_CONTRACT_TO_ACTIVE = gql`
  mutation SetContractToActive($id: ID!) {
    setContractToActive(id: $id) {
      id
    }
  }
`

export const GET_CONTRACT_STATUSES = gql`
  query GetContractStatuses {
    contracts(first: 9999) {
      edges {
        node {
          id
          status
          activationDate
          mutations {
            id
            status
            startDate
          }
        }
      }
    }
  }
`

export const GET_CONTRACTS = gql`
  query GetContracts {
    contracts(first: 9999) {
      edges {
        node {
          id
          car {
            licensePlate
            carFileId
          }
          customer {
            lastName
            zipCode
          }
          package {
            name
          }
          id
          activationDate
          expirationDate
          period
          status
          mutations {
            id
            status
            startDate
          }
        }
      }
    }
  }
`

export const GET_CONTRACT_TEMPLATES = gql`
  query GetContractTemplates {
    templates(first: 9999) {
      edges {
        node {
          id
          name
          type
          contents
        }
      }
    }
  }
`

export const CREATE_CONTRACT_TEMPLATE = gql`
  mutation CreateContractTemplate(
    $name: String!
    $type: contractDocumentType!
    $contents: String!
  ) {
    createTemplate(name: $name, type: $type, contents: $contents) {
      id
      name
      type
    }
  }
`

export const UPDATE_CONTRACT_TEMPLATE = gql`
  mutation UpdateContractTemplate(
    $id: ID!
    $name: String!
    $type: contractDocumentType!
    $contents: String!
  ) {
    updateTemplate(id: $id, name: $name, type: $type, contents: $contents) {
      id
    }
  }
`

export const DELETE_CONTRACT_TEMPLATE = gql`
  mutation UpdateContractTemplate($id: ID!) {
    deleteTemplate(id: $id) {
      id
    }
  }
`

export const GET_CSP_CONTRACT_LAYOUT_FOR_DEALER = gql`
  query getDealer($id: ID) {
    dealer(id: $id) {
      name
      id
      carServicePlan {
        ...contractLayout
      }
    }
  }
  ${contractLayoutFragment}
`

export const UPLOAD_CSP_CONTRACT_LAYOUT = gql`
  mutation UploadStationary(
    $type: StationaryTypeEnum!
    $carServicePlanId: ID!
    $file: Upload!
  ) {
    uploadStationary(
      type: $type
      carServicePlanId: $carServicePlanId
      file: $file
    ) {
      ...contractLayout
    }
  }
  ${contractLayoutFragment}
`

export const CANCEL_CONTRACT = gql`
  mutation setContractToCanceled(
    $id: ID!
    $reasonId: ID!
    $explanation: String
  ) {
    setContractToCanceled(
      id: $id
      reasonId: $reasonId
      explanation: $explanation
    ) {
      id
      status
    }
  }
`

export const STOP_CONTRACT = gql`
  mutation setContractToStopped(
    $id: ID!
    $reasonId: ID!
    $explanation: String
    $stopDate: Date!
  ) {
    setContractToStopped(
      id: $id
      reasonId: $reasonId
      explanation: $explanation
      stopDate: $stopDate
    ) {
      id
      status
    }
  }
`

export const GET_REASONS = gql`
  query getReasons($status: reasonStatusEnum!) {
    reasonsByStatus(status: $status, first: 9999) {
      edges {
        node {
          id
          message
          status
          explanationRequired
        }
      }
    }
  }
`

export const GET_CONTRACT_EXTENSION_REQUESTS = gql`
  query getContractExtensionRequests($ids: [ID!]) {
    extensionRequests(ids: $ids) {
      id
      status
      contract {
        id
        car {
          id
          licensePlate
          carFileId
        }
        customer {
          id
          lastName
          zipCode
        }
        package {
          id
          name
        }
        expirationDate
        period
        packageCalculationResult {
          id
          resultNet
        }
      }
      packageCalculationResult {
        id
        resultNet
      }
      document {
        id
        uri
        fullUri
      }
    }
  }
`

export const GET_CONTRACT_EXTENSION_REQUESTS_MINIMAL = gql`
  query getContractExtensionRequests($ids: [ID!]) {
    extensionRequests(ids: $ids) {
      id
      status
      contract {
        id
        car {
          id
          licensePlate
          carFileId
        }
        customer {
          id
          lastName
        }
      }
      packageCalculationResult {
        id
        resultNet
      }
    }
  }
`

export const UPDATE_CONTRACT_EXTENSION_REQUEST = gql`
  mutation updateContractExtensionRequest(
    $id: ID!
    $status: ExtensionRequestStatusEnum!
  ) {
    updateExtensionRequest(id: $id, status: $status) {
      id
      status
      contract {
        id
      }
    }
  }
`
