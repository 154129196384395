import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import LoadingIndicator from 'components/atoms/loading-indicator'
import BodyParagraph from 'components/atoms/body-paragraph'

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl1};
  pointer-events: none;
  transition: opacity ease-in 0.125s;
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: auto;
  height: auto;
`

/**
 * Note: Only use this component in the context of the management info page.
 * It's seperated into a molecule because it's being re-used in different charts,
 * yet it's not meant to be used outside of the management info page.
 */
function ManagementInfoChartLoadingIndicator({ className, loadingText }) {
  return (
    <Container className={className}>
      <StyledLoadingIndicator />
      <BodyParagraph weight={500}>{loadingText}</BodyParagraph>
    </Container>
  )
}

ManagementInfoChartLoadingIndicator.propTypes = {
  className: PropTypes.string,
  loadingText: PropTypes.string.isRequired,
}

export default ManagementInfoChartLoadingIndicator
