import { types } from 'redux/actions/marktview-params'

export const initialState = {
  loading: false,
  data: null,
  selected: [],
}

export function reducer(state = initialState, action) {
  const { data, error, type } = action

  switch (type) {
    case types.REQUEST_MARKETVIEW_PARAMS: {
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    }

    case types.RECEIVE_MARKETVIEW_PARAMS: {
      // When an error occurs this is unfortunately return as a HTTP 200
      // end therefore it ends up here as a succesfull response. But
      // if it contains a 'message' field with a type 'Error' it is
      // actually an error so we code for that here
      if (data.message && data.message.type === 'Error') {
        return {
          ...state,
          loading: false,
          error: data.message.description,
        }
      }
      return {
        ...state,
        loading: false,
        data,
        error: undefined,
      }
    }

    case types.FAIL_MARKETVIEW_PARAMS_REQUEST: {
      return {
        ...state,
        loading: false,
        error,
      }
    }

    default:
      return state
  }
}
