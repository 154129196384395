import { gql } from '@apollo/client'

export const UPDATE_PACKAGE = gql`
  mutation UpdatePackage($id: ID!, $description: String, $disabled: Boolean) {
    updatePackage(id: $id, description: $description, disabled: $disabled) {
      id
      description
      disabled
    }
  }
`
