import PropTypes from 'prop-types'
import React, { useRef, forwardRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import DialogContentText from '@material-ui/core/DialogContentText'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import useDeepCompareEffect from 'use-deep-compare-effect'
import styled from 'styled-components/macro'
import { useLocation, useHistory } from 'react-router-dom'

import { marketViewFilterIds, defaultMarketView } from 'config/data'

import { getMarketviewParams } from 'redux/actions/marktview-params'
import { getMarketviewData } from 'redux/actions/marketview-data'

import {
  getFiltersFromMetaData,
  getInputParamsFromParams,
  getSelectedParamsFromStorage,
  translateParamOptions,
} from 'utilities/marketview-utils'
import {
  handleChangeQuery,
  handleChangeQueries,
  getObjectFromQueryString,
  objectToQueryString,
} from 'utilities/utils'
import storage from 'utilities/storage'
import { media } from 'utilities/styled'
import toast from 'utilities/toast'

import { InputParamsContext, ParamOptionsContext } from 'contexts/marketview'

import useMarketviewStoragePrefix from 'hooks/use-marketview-storage-prefix'
import useAbortController from 'hooks/use-abort-controller'

import ContentSeparator from 'components/atoms/content-separator'
import TabHeader from 'components/atoms/tab-header'
import AppLayout from 'components/redux-containers/app-layout-container'
import MarktviewLayout from 'components/layouts/marktview-layout'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Filters from 'components/molecules/filters'
import MarketviewTermsDialog from 'components/views/market-view/marketview-terms-dialog'
import MarktviewTabs from 'components/views/market-view/marktview-tabs'
import MarketviewCompetitivePosition from 'components/views/market-view/marketview-competitive-position'
import SalesPotential from 'components/views/market-view/sales-potential'

/*
 * Modal to show when loading the data
 */

const StyledDialog = styled(Dialog)`
  pointer-events: none;

  .MuiDialog-container {
    align-items: flex-end;
  }

  .MuiDialog-paper {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  :first-child {
    padding-top: ${({ theme }) => theme.sizeByFactor(2)};
    padding-bottom: ${({ theme }) => theme.sizeByFactor(2)};
  }
`

const StyledDialogContentText = styled(DialogContentText)`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.sizeByFactor(2)};
  font-weight: 500;
  margin-bottom: 0;
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  flex: 0;
  margin-right: ${({ theme }) => theme.sizings.lvl2};
`

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const MarketViewLoading = ({ loading }) => {
  const { t } = useTranslation()

  return (
    <StyledDialog
      open={loading}
      TransitionComponent={Transition}
      hideBackdrop
      disableScrollLock
    >
      <StyledDialogContent>
        <StyledLoadingIndicator size="medium" />
        <StyledDialogContentText>
          {t('dataIsLoadingOneMomentPlease')}
        </StyledDialogContentText>
      </StyledDialogContent>
    </StyledDialog>
  )
}

MarketViewLoading.propTypes = {
  loading: PropTypes.bool.isRequired,
}

/*
 * View for the Marktview.
 */

const Container = styled.div`
  flex-direction: column;
  width: 100%;

  ${media.desktop`
    align-items: flex-start;
    display: flex;
  `}
`

function MarktviewView() {
  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation()
  const userDetails = useSelector(
    (state) => state.auth && state.auth.userDetails,
  )
  const marketviewParamsLoading = useSelector(
    (state) => state.marketviewParams.loading,
  )
  const marketviewParamsData = useSelector(
    (state) => state.marketviewParams.data,
  )
  const marketviewParams =
    marketviewParamsData && marketviewParamsData.responseData[0]
  const marketviewParamsError = useSelector(
    (state) => state.marketviewParams && state.marketviewParams.error,
  )

  if (marketviewParamsError) {
    const error =
      typeof marketviewParamsError === 'string'
        ? `Market View Params: ${marketviewParamsError}`
        : t('genericError')
    toast.error(error)
  }

  const competitivePositionError = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.error &&
      state.marketviewData.error.competitivePosition,
  )

  if (competitivePositionError) {
    const error =
      typeof competitivePositionError === 'string'
        ? `Competitive Position: ${competitivePositionError}`
        : t('genericError')
    toast.error(error)
  }

  const competitivePosition = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.data &&
      state.marketviewData.data.competitivePosition,
  )
  const marketviewDataLoading = useSelector(
    (state) => state.marketviewData.loading,
  )
  const storagePrefix = useMarketviewStoragePrefix()

  const storedSelectedParams = getSelectedParamsFromStorage(storagePrefix)
  let selectedParams = getObjectFromQueryString(location.search)
  const history = useHistory()
  const { ref: paramsAbortControllerRef, abort: abortParamsRequest } =
    useAbortController()
  const {
    ref: competitivePositionAbortControllerRef,
    abort: abortCompetetivePositionRequest,
  } = useAbortController()

  // If user selects new params this is set to true.
  // afterwards it is never set to false
  const paramsWhereChosen = useRef(false)
  if (Object.keys(selectedParams).length) {
    paramsWhereChosen.current = true
  }

  // allow the params stored in stored in localStorage to be applied,
  // when no params are defined in the url:
  if (
    paramsWhereChosen.current === false &&
    Object.keys(storedSelectedParams).length
  ) {
    paramsWhereChosen.current = true
    history.push(
      `${location.pathname}?${objectToQueryString(storedSelectedParams)}`,
    )
  }

  const paramOptions =
    marketviewParams && getFiltersFromMetaData(marketviewParams, selectedParams)
  const translatedParamOptions =
    paramOptions && translateParamOptions(paramOptions, t)

  // Select all first options of radio filters by default to be overwritten by the
  // selected option if there is one later (when ultimately constructing the
  // selectedParams object)
  const radioParamDefaults =
    paramOptions &&
    paramOptions.reduce((result, option) => {
      if (option.type === 'radio' && option.items[0]) {
        return {
          ...result,
          [option.id]: option.items[0].value,
        }
      }
      return result
    }, {})

  // Remove view specific params
  delete selectedParams[marketViewFilterIds.stockTargetConversionRate]
  delete selectedParams[marketViewFilterIds.targetStayTime]

  // Since the Cartalk API requires a competitorposition to be
  // at all times we provide a default here
  selectedParams = paramOptions && {
    ...radioParamDefaults,
    ...selectedParams,
    competitorposition:
      selectedParams.competitorposition ||
      defaultMarketView.competetitorPosition,
    // in some cases selectedParams.datefrom is stored as an object in localStorage
    datefrom:
      selectedParams.datefrom && typeof selectedParams.datefrom === 'string'
        ? selectedParams.datefrom
        : defaultMarketView.datefrom,
    // in some cases selectedParams.dateto is stored as an object in localStorage
    dateto:
      selectedParams.dateto && typeof selectedParams.dateto === 'string'
        ? selectedParams.dateto
        : defaultMarketView.dateto,
  }

  const inputParams = selectedParams && getInputParamsFromParams(selectedParams)
  const marketviewTermsAccepted = userDetails && userDetails.marketview_av

  useDeepCompareEffect(() => {
    if (!marketviewTermsAccepted) {
      return
    }
    abortParamsRequest()
    dispatch(
      getMarketviewParams(inputParams, paramsAbortControllerRef.current.signal),
    )
  }, [dispatch, marketviewTermsAccepted, inputParams])

  useDeepCompareEffect(() => {
    if (!inputParams) {
      return
    }
    abortCompetetivePositionRequest()
    dispatch(
      getMarketviewData(
        'competitivePosition',
        inputParams,
        competitivePositionAbortControllerRef.current.signal,
      ),
    )
  }, [dispatch, inputParams])

  const mutationDates = marketviewParams && marketviewParams.mutationDates[0]
  const dateRange =
    mutationDates &&
    t('dateRange', {
      from: moment(mutationDates.minimumFromDate).format('D MMM YYYY'),
      to: moment(mutationDates.maximumToDate).format('D MMM YYYY'),
    })

  // Logic associated with the filter options:

  function handleChangeFilters(filter, value) {
    if (filter === 'timePeriod') {
      handleChangeQueries(
        location,
        history,
        ['datefrom', 'dateto'],
        [value.datefrom, value.dateto],
      )
      storage.setPermanent(`${storagePrefix}datefrom`, value.datefrom)
      storage.setPermanent(`${storagePrefix}dateto`, value.dateto)
    } else {
      handleChangeQuery(location, history, filter, value)
      storage.setPermanent(`${storagePrefix}${filter}`, value)

      // Clear the model filter whenever the make filter is changed
      if (filter === marketViewFilterIds.make) {
        handleChangeQueries(
          location,
          history,
          [filter, marketViewFilterIds.model],
          [value, null],
        )
        storage.removePermanent(`${storagePrefix}${marketViewFilterIds.model}`)
      }
    }

    // Clear the area filters whenever the company filter is changed
    if (filter === marketViewFilterIds.company) {
      handleChangeQueries(
        location,
        history,
        [
          filter,
          ...(selectedParams[marketViewFilterIds.area]
            ? [marketViewFilterIds.area]
            : []),
        ],
        [value, null, null],
      )

      storage.removePermanent(`${storagePrefix}${marketViewFilterIds.area}`)
    }
  }

  function handleResetFilters() {
    history.push(location.pathname)
  }

  const filtersSlotsContent = translatedParamOptions ? (
    <Filters
      filters={translatedParamOptions}
      onUpdate={handleChangeFilters}
      selectedFilters={selectedParams}
      onResetFilters={handleResetFilters}
    />
  ) : null

  return (
    <AppLayout pageTitle="Marketview">
      {!userDetails ? (
        <LoadingIndicator />
      ) : (
        <>
          {!marketviewTermsAccepted ? (
            <MarketviewTermsDialog />
          ) : (
            <>
              <MarktviewLayout
                dateRange={dateRange}
                filtersSlot={filtersSlotsContent}
                selectedParams={selectedParams}
              >
                <Container>
                  {competitivePosition && (
                    <>
                      <MarketviewCompetitivePosition
                        data={competitivePosition}
                      />
                      <ContentSeparator />
                      <SalesPotential data={competitivePosition} />
                    </>
                  )}
                  <ContentSeparator />
                  {inputParams && (
                    <ParamOptionsContext.Provider
                      value={translatedParamOptions}
                    >
                      <InputParamsContext.Provider value={inputParams}>
                        <TabHeader
                          text={t('variablesToImproveYourPreformace')}
                        />
                        <MarktviewTabs />
                      </InputParamsContext.Provider>
                    </ParamOptionsContext.Provider>
                  )}
                </Container>
              </MarktviewLayout>
              <MarketViewLoading
                loading={
                  !!(
                    marketviewDataLoading ||
                    marketviewParamsLoading ||
                    !userDetails ||
                    !competitivePosition
                  )
                }
              />
            </>
          )}
        </>
      )}
    </AppLayout>
  )
}

export default MarktviewView
