import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Overlay from 'components/molecules/overlay'
import { useMutation, useQuery } from '@apollo/client'
import {
  CAR_CUTTER_BACKGROUNDS,
  CAR_CUTTER_LAYERS,
  CAR_CUTTER_UPLOAD_BACKGROUND,
  UPDATE_CAR_CUTTER_SETTINGS,
  CAR_CUTTER_UPLOAD_LAYER,
} from 'config/graphql/v4'
import LoadingIndicator from 'components/atoms/loading-indicator'
import BodyHeading from 'components/atoms/body-heading'
import Button from 'components/atoms/button'
import MediaForm from 'components/organisms/media-form'
import SelectFileButton from 'components/molecules/select-file-button'

const Grid = styled.section`
  display: grid;
  gap: ${(props) => props.theme.sizings.lvl4}
    ${(props) => props.theme.sizings.lvl8};
  grid-template-areas:
    'steps options'
    'form form';
  width: 100%;
`

const Steps = styled.header`
  grid-area: steps;
`

const FormOptions = styled.div`
  grid-area: options;
  display: flex;
  justify-content: flex-end;
`

const PositionedMediaForm = styled(MediaForm)`
  grid-area: form;
`

const PhotoStudioSettingsOverlay = ({ closeOverlay, dealerId, activation }) => {
  const { t } = useTranslation()
  // although only one can be selected MediaForm communicates it as an array of choices
  const [backgroundIdChoice, setBackgroundIdChoice] = React.useState(null)
  const [layerIdChoice, setLayerIdChoice] = React.useState(null)
  const [step, setStep] = React.useState(1)

  const backgroundOptions = useQuery(CAR_CUTTER_BACKGROUNDS)
  const backgrounds = backgroundOptions?.data?.carCutterBackgrounds

  const [updateSettingsMutation] = useMutation(UPDATE_CAR_CUTTER_SETTINGS)

  const storeBackgroundIdChoice = useCallback((backgroundIdChoices) => {
    if (backgroundIdChoices.length) {
      setBackgroundIdChoice(backgroundIdChoices[0])
    }
  }, [])

  const persistBackgroundId = useCallback(() => {
    const background = backgrounds.filter(
      (background) => background.id === backgroundIdChoice,
    )[0]
    updateSettingsMutation({
      variables: {
        dealerId,
        activation,
        backgroundId: background.backgroundId,
        backgroundType: background.type,
      },
    })
    setStep(2)
  }, [
    activation,
    backgroundIdChoice,
    backgrounds,
    dealerId,
    updateSettingsMutation,
  ])

  const layerOptions = useQuery(CAR_CUTTER_LAYERS)

  const storeLayerIdChoice = useCallback((layerIdChoices) => {
    if (layerIdChoices.length) {
      setLayerIdChoice(layerIdChoices[0])
    }
  }, [])

  const persistlayerId = useCallback(() => {
    updateSettingsMutation({
      variables: {
        dealerId,
        activation,
        layerId: layerIdChoice,
      },
    })
    closeOverlay()
  }, [
    updateSettingsMutation,
    dealerId,
    activation,
    closeOverlay,
    layerIdChoice,
  ])

  const [persistNewBackground] = useMutation(CAR_CUTTER_UPLOAD_BACKGROUND, {
    refetchQueries: [{ query: CAR_CUTTER_BACKGROUNDS }],
    awaitRefetchQueries: true,
  })
  const [persistNewLayer] = useMutation(CAR_CUTTER_UPLOAD_LAYER, {
    refetchQueries: [{ query: CAR_CUTTER_LAYERS }],
    awaitRefetchQueries: true,
  })

  const handleNewBackgroundUpload = (formData) => {
    const file = formData.get('file')
    if (file) {
      persistNewBackground({
        variables: {
          file,
        },
        context: {
          hasUploads: true,
        },
      })
    }
  }

  const handleNewLayerUpload = (formData) => {
    const file = formData.get('file')
    if (file) {
      persistNewLayer({
        variables: {
          file,
        },
        context: {
          hasUploads: true,
        },
      })
    }
  }

  return (
    <Overlay
      title={t('photoStudioSettings.overlay.title')}
      icon="media"
      close={closeOverlay}
    >
      {step === 1 && (
        <>
          {backgroundOptions.loading && (
            <LoadingIndicator error={backgroundOptions.error} />
          )}
          {backgroundOptions?.data?.carCutterBackgrounds && (
            <Grid>
              <Steps>
                <BodyHeading styledAs="oldheading" level="2">
                  {t('photoStudioSettings.overlay.step1.heading')}
                </BodyHeading>
              </Steps>
              <FormOptions>
                <SelectFileButton
                  level="option"
                  id="newBackground"
                  allowedFileExtensions={['png', 'jpg', 'jpeg']}
                  onChange={handleNewBackgroundUpload}
                  text={t('photoStudioSettings.overlay.step1.uploadBgButton')}
                />
                <Button
                  level="cta"
                  onClick={persistBackgroundId}
                  disabled={!backgroundIdChoice}
                >
                  {t('photoStudioSettings.overlay.step1.useBgButton')}
                </Button>
              </FormOptions>
              <PositionedMediaForm
                // the inverse of multiSelect:
                exclusiveSelect
                level="cta"
                single
                onChange={storeBackgroundIdChoice}
                type="images"
                items={backgroundOptions?.data?.carCutterBackgrounds.map(
                  (backgroundOption) => ({
                    id: backgroundOption.id,
                    name: backgroundOption.id,
                    src: backgroundOption.thumbnailUri,
                    type: 'image',
                  }),
                )}
              />
            </Grid>
          )}
        </>
      )}
      {step === 2 && (
        <>
          {backgroundOptions.loading && (
            <LoadingIndicator error={backgroundOptions.error} />
          )}
          {backgroundOptions?.data?.carCutterBackgrounds && (
            <Grid>
              <Steps>
                <BodyHeading styledAs="oldheading" level="2">
                  {t('photoStudioSettings.overlay.step2.heading')}
                </BodyHeading>
              </Steps>
              <FormOptions>
                <SelectFileButton
                  level="option"
                  id="newLayer"
                  allowedFileExtensions={['png']}
                  onChange={handleNewLayerUpload}
                  text={t(
                    'photoStudioSettings.overlay.step2.uploadLayerButton',
                  )}
                />
                <Button
                  level="cta"
                  onClick={persistlayerId}
                  disabled={!layerIdChoice}
                >
                  {t('photoStudioSettings.overlay.step2.useLayerButton')}
                </Button>
              </FormOptions>
              <PositionedMediaForm
                // the inverse of multiSelect:
                exclusiveSelect
                level="cta"
                single
                onChange={storeLayerIdChoice}
                type="images"
                items={layerOptions?.data?.carCutterLayers.map(
                  (layerOption) => ({
                    id: layerOption.id,
                    name: layerOption.id,
                    src: layerOption.thumbnailUri,
                    type: 'image',
                  }),
                )}
              />
            </Grid>
          )}
        </>
      )}
    </Overlay>
  )
}

PhotoStudioSettingsOverlay.propTypes = {
  closeOverlay: PropTypes.func.isRequired,
  dealerId: PropTypes.string.isRequired,
  activation: PropTypes.string.isRequired,
}

export default PhotoStudioSettingsOverlay
