// EXTERNAL
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

// INTERNAL
import Button from 'components/atoms/button'
import NewTextInput from 'components/atoms/new-text-input'
import { getCarfile } from 'redux/actions/data'
import data from 'utilities/data'
import toast from 'utilities/toast'
import { getObjectFromQueryString } from 'utilities/utils'

const { addCarAccessoiresInBpmDeclaration } = data

const filter = createFilterOptions()

const StyledChip = styled(Chip)`
  background-color: ${({ theme }) => theme.colors.defaultBackground};
  .MuiChip-deleteIcon {
    color: ${({ theme }) => theme.colors.text};
  }
`

function NewAccessoiresSelect({
  options,
  defaultValue,
  filterSelectedOptions,
  label,
  placeholder,
  onChange,
  newIndicator,
  ...restProps
}) {
  const { t } = useTranslation()
  const mountedRef = useRef()
  const inputRef = useRef()
  const [optionAmount, setAmount] = useState()
  const [isAddingOption, setIsAddingOption] = useState(false)
  const instanceId = useSelector((state) => state.auth.instanceId)
  const location = useLocation()
  const queryParams = getObjectFromQueryString(location.search)
  const carId = queryParams && queryParams.auto_id
  const [selectedOption, setSelectedOption] = useState(defaultValue)
  const dispatch = useDispatch()

  const handleBlur = () => {
    setSelectedOption(inputRef.current.value)
  }

  const handleChange = (event, values, reason, details) => {
    if (details?.option?.value) {
      setSelectedOption(details.option.value)
    } else if (details?.option) {
      setSelectedOption(details.option)
    } else {
      setSelectedOption(defaultValue)
    }
  }

  const handleChangeAmount = (val) => {
    setAmount(val)
  }

  const handleAddOption = async () => {
    if (isAddingOption) {
      return
    }

    setIsAddingOption(true)

    addCarAccessoiresInBpmDeclaration(instanceId, {
      omschrijving: selectedOption,
      bedrag: optionAmount,
      auto_id: carId,
    })
      .then(() => {
        if (!mountedRef.current) {
          return
        }
        setSelectedOption(defaultValue)
        setAmount()
        dispatch(getCarfile(carId))
      })
      .catch((error) => {
        toast.error(error)
      })
      .finally(() => {
        if (!mountedRef.current) {
          return
        }
        setIsAddingOption(false)
      })
  }

  return (
    <Grid ref={mountedRef} container spacing={2}>
      <Grid item xs={6} md={3}>
        <Autocomplete
          {...restProps}
          key={setSelectedOption}
          options={options.map((option) => option.label)}
          defaultValue={selectedOption}
          value={selectedOption}
          filterSelectedOptions={filterSelectedOptions}
          freeSolo
          multiple={false}
          onChange={handleChange}
          onBlur={handleBlur}
          filterOptions={(opts, params) => {
            const filtered = filter(opts, params)

            if (params.inputValue !== '') {
              filtered.push({
                value: params.inputValue,
                label: `"${params.inputValue}" ${t('add')}`,
              })
            }

            return filtered
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option
            }
            if (option?.label) {
              return option.label
            }
            return ''
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <StyledChip
                key={index.toString()}
                label={option.value || option}
                size="small"
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <NewTextInput
              ref={inputRef}
              params={params}
              filled
              label={t('optionName')}
              placeholder={placeholder}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NewTextInput
          filled
          label={t('amount')}
          type="number"
          unit="currency_euro"
          value={optionAmount}
          onChange={handleChangeAmount}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={handleAddOption}
          disabled={isAddingOption || !selectedOption}
          type="button"
          text={t('optionsAddOption')}
        />
      </Grid>
    </Grid>
  )
}

NewAccessoiresSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
  ),
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  filterSelectedOptions: PropTypes.bool,
  label: PropTypes.string,
  newIndicator: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

NewAccessoiresSelect.defaultProps = {
  options: [],
  defaultValue: [],
  filterSelectedOptions: true,
  label: undefined,
  placeholder: undefined,
  newIndicator: false,
  onChange: undefined,
}

export default NewAccessoiresSelect
