export function mapCarFileType(t, carFileType) {
  const carTypes = {
    persoonsauto: t('car'),
    bedrijfsauto: t('companyCar'),
    motor: t('motorbike'),
    brommobiel: t('microcar'),
  }

  return carTypes[carFileType] || t('car')
}
