import { connect } from 'react-redux'
import { getTransactions, getTransactionTypes } from 'redux/actions/data'
import TransactionsOverview from '../views/transactions-overview'

const mapStateToProps = ({ data }) => ({
  transactions: data.transactions,
  transactionTypes: data.transactionTypes,
})

const mapDispatchToProps = {
  getTransactions,
  getTransactionTypes,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionsOverview)
