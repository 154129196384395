import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import LocationsSettings from 'components/redux-containers/locations-settings-container'
import PortalsSettings from 'components/redux-containers/portals-settings-container'
import SettingsForm from 'components/redux-containers/settings-form-container'
import ExpectedPhotoForm from 'components/redux-containers/expected-photo-form-container'
import CarPhotoLayersSettings from 'components/redux-containers/car-photo-layers-settings-container'
import PhotoStudioSettings from './photo-studio-settings'

function LocationsAdvertisementSettings({ match, userHasPortalManager }) {
  const { t } = useTranslation()

  const tabItems = [
    {
      label: t('settings'),
      to: `${match.url}/settings`,
      component: (props) => <SettingsForm formId="adsSettings" {...props} />,
    },
    {
      label: t('expectedPhoto'),
      to: `${match.url}/expected-photo`,
      component: (props) => <ExpectedPhotoForm {...props} />,
    },
    {
      label: t('layers'),
      to: `${match.url}/layers`,
      component: (props) => <CarPhotoLayersSettings {...props} />,
    },
    {
      label: t('photoStudioSettings.tabLabel'),
      to: `${match.url}/photo-studio`,
      component: (props) => <PhotoStudioSettings {...props} />,
    },
  ]

  if (userHasPortalManager) {
    tabItems.push({
      label: t('portals'),
      to: `${match.url}/portals`,
      component: (props) => <PortalsSettings {...props} />,
    })
  }

  return (
    <LocationsSettings title={t('locationsOverview')} tabItems={tabItems} />
  )
}

LocationsAdvertisementSettings.propTypes = {
  match: PropTypes.object.isRequired,
  userHasPortalManager: PropTypes.bool,
}

LocationsAdvertisementSettings.defaultProps = {
  userHasPortalManager: false,
}

export default withRouter(LocationsAdvertisementSettings)
