import { gql } from '@apollo/client'

export const GET_INVOICES = gql`
  query GetInvoices {
    invoices(first: 9999) {
      edges {
        node {
          dealer {
            id
            name
            address
            city
          }
          status
          invoiceFullUri
          date
        }
      }
    }
  }
`

export const GET_INVOICE_STATUSES = gql`
  query GetInvoices {
    invoices(first: 9999) {
      edges {
        node {
          status
          date
        }
      }
    }
  }
`

export const GET_INVOICES_BY_DEALER_ID = gql`
  query GetInvoicesByDealerId($dealerId: ID!) {
    invoicesByDealerId(dealerId: $dealerId) {
      id
      invoiceFullUri
      status
      sepaFullUri
      sepaExcelFullUri
      paymentUri
      date
      contractCount
      amount
    }
  }
`
