import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Label, { propTypes } from 'components/atoms/label'

const Container = styled.div`
  display: flex;
  align-items: baseline;
`

const StyledLabel = styled(Label)`
  margin-right: ${({ theme, $gapLevel, $disableRightMargin }) =>
    $disableRightMargin || typeof $gapLevel !== 'number'
      ? 'inherit'
      : theme.sizings[`lvl${$gapLevel}`]};
`

const Labels = ({
  texts,
  includeWrapper,
  gapLevel,
  className,
  ...restProps
}) => {
  const uniqueTexts = [...new Set(texts)]
  return includeWrapper ? (
    <Container className={className} $gapLevel={gapLevel}>
      {uniqueTexts.map((text, i) => (
        <StyledLabel
          key={text}
          $gapLevel={gapLevel}
          $disableRightMargin={i === uniqueTexts.length - 1}
          text={text}
          {...restProps}
        />
      ))}
    </Container>
  ) : (
    <>
      {uniqueTexts.map((text, i) => (
        <StyledLabel
          key={text}
          $gapLevel={gapLevel}
          $disableRightMargin={i === uniqueTexts.length - 1}
          text={text}
          {...restProps}
        />
      ))}
    </>
  )
}

const { text, ...labelPropTypes } = propTypes

Labels.propTypes = {
  ...labelPropTypes,
  // If true, the wrapping element is removed and the labels are displayed inline
  inline: PropTypes.bool,
  texts: PropTypes.arrayOf(PropTypes.string).isRequired,
  // Set this to 'null' to remove the gap between labels completely
  gapLevel: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8]),
}

Labels.defaultProps = {
  backgroundColor: 'inactiveBackground',
  size: 'small',
  textColor: 'text',
  inline: false,
  gapLevel: 1,
}

export default Labels
