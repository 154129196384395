import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import ActionBarItem from 'components/molecules/action-bar-item'
import { formatDataTestE2eAttr } from 'utilities/format'

const Container = styled.div`
  background-color: ${({ backgroundColor, theme }) =>
    theme.colors[backgroundColor]};
  padding: 16px 0;
`

function ActionBarSection({
  backgroundColor,
  iconDefaultColor,
  iconHoverColor,
  items,
}) {
  return (
    <Container backgroundColor={backgroundColor}>
      {items &&
        items.map(({ id, showHoverLabel, ...item }, index) => (
          <ActionBarItem
            key={index.toString()}
            iconDefaultColor={iconDefaultColor}
            iconHoverColor={iconHoverColor}
            showHoverLabel={showHoverLabel}
            data-test-e2e={formatDataTestE2eAttr('action-bar-item', id)}
            {...item}
          />
        ))}
    </Container>
  )
}

ActionBarSection.propTypes = {
  backgroundColor: PropTypes.string,
  iconDefaultColor: PropTypes.string,
  iconHoverColor: PropTypes.string,
  items: PropTypes.array,
}

ActionBarSection.defaultProps = {
  backgroundColor: undefined,
  iconDefaultColor: undefined,
  iconHoverColor: undefined,
  items: null,
}

export default ActionBarSection
