import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { HashLink as RouterLink } from 'react-router-hash-link'
import { styles as textStyles } from 'components/atoms/text'

export const linkStyles = css`
  ${textStyles.body};
  color: ${({ $color, theme }) =>
    theme.colors[$color] || theme.colors.actionsStandard};
  text-decoration: none;

  &[disabled] {
    cursor: not-allowed;
    &,
    * {
      color: ${({ theme }) => theme.colors.inactiveTextColor};
    }
  }
`

const StyledLink = styled(RouterLink)`
  ${linkStyles};
`

const StyledA = styled.a`
  ${linkStyles};
`

const StyledDiv = styled.div`
  ${linkStyles};
  cursor: pointer;
`

/**
 * Links to be wrapped around any children.
 *
 * It accepts either an 'href' prop for external links, 'to' prop for internal
 * links or an 'onClick' prop for click events. These props are mutually exclusive.
 */
function Link({
  ariaLabel,
  className,
  children,
  color,
  disabled,
  href,
  onClick,
  to,
  title,
  ...restProps
}) {
  let LinkComponent

  if (href) {
    LinkComponent = StyledA
  } else if (to) {
    LinkComponent = StyledLink
  } else {
    LinkComponent = StyledDiv
  }

  return (
    <LinkComponent
      aria-label={ariaLabel}
      $color={color}
      className={className}
      disabled={disabled}
      href={href}
      onClick={onClick}
      to={to}
      title={title}
      target={href ? '_blank' : null}
      smooth
      {...restProps}
    >
      {children}
    </LinkComponent>
  )
}

Link.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  /** Color of the text */
  color: PropTypes.string,
  /** Boolean to disable the link */
  disabled: PropTypes.bool,
  /** Link to an external source that will be opened in a new tab */
  href: PropTypes.string,
  /** Click handler */
  onClick: PropTypes.func,
  /** Link to an internal source */
  to: PropTypes.string,
  title: PropTypes.string,
}

Link.defaultProps = {
  ariaLabel: null,
  color: null,
  className: null,
  disabled: false,
  href: null,
  onClick: null,
  to: null,
  title: null,
}

export default Link
