import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useQuery, useMutation } from '@apollo/client'
import { Formik, Form, Field } from 'formik'

import { cspPackageTypes } from 'config/data'
import { CSPEditCarServicesFormSchema } from 'config/validation-schemas'
import {
  CREATE_CARSERVICE_INCLUDED,
  READ_CARSERVICE_INCLUDED,
  UPDATE_CARSERVICE_INCLUDED,
  DELETE_CARSERVICE_INCLUDED,
} from 'config/graphql/csp'

import { media } from 'utilities/styled'
import toast from 'utilities/toast'
import { formatPrice } from 'utilities/format'

import {
  FormikTextInput,
  FormikLabeledCheckBox,
} from 'components/molecules/formik-field'
import Overlay from 'components/molecules/overlay'
import EnhancedCard from 'components/layouts/enhanced-card'
import EnhancedTable, {
  TableDataText,
  FooterTableCell,
  FooterTableRow,
} from 'components/organisms/enhanced-table'
import Button from 'components/atoms/button'
import GeneralDataError from 'components/organisms/general-data-error'
import TextLink from 'components/atoms/text-link'
import ContentSeparator from 'components/atoms/content-separator'
import LoadingIndicator from 'components/atoms/loading-indicator'
import DealerNoCSPMessage from 'components/organisms/dealer-no-csp-message'

const Card = styled(EnhancedCard)`
  width: 100%;
`

const FieldStyles = css`
  width: 100%;
  input.MuiFilledInput-input {
    padding: 10px 12px;
  }
`

const CommercialTextInput = styled(FormikTextInput)`
  min-width: 300px;
  ${FieldStyles}
`

const NumberInput = styled(FormikTextInput)`
  max-width: 120px;
  min-width: 100px;
  ${FieldStyles}
`

const StyledTableDataText = styled(TableDataText)`
  padding-top: ${(props) => props.theme.sizings.lvl1};
`

const StyledCurrencyData = styled(StyledTableDataText)`
  // text-align: right;
  display: flex;
  // justify-content: space-between;
  color: inherit;
  min-width: ${({ theme }) => theme.sizeByFactor(10)};

  &::before {
    content: '€';
    text-align: left;
  }
`

const StyledCurrencyFooterData = styled(StyledCurrencyData)`
  padding-top: 0;
`

const ActionsContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
`

const Line = styled(ContentSeparator)`
  height: ${({ theme }) => theme.sizings.lvl2};
`

const ActionButton = styled(Button)`
  &[disabled] {
    opacity: 0.3;
    * {
      fill: ${({ theme }) => theme.colors.actionsStandard};
    }

    &:hover,
    &:focus {
      & * {
        fill: ${({ theme }) => theme.colors.actionsStandard};
      }
    }
  }
`

const AddCarServiceLink = styled(TextLink)`
  text-transform: none;
  margin: ${({ theme }) => theme.sizings.lvl1} 0;
`

const SubmitRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
  margin-top: ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
    justify-content: flex-end;
  `}
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: auto;
`

const SubmitButton = styled(Button)`
  margin-left: 0;
  margin-right: ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
    margin-right: 0;
    margin-left: ${({ theme }) => theme.sizings.lvl2};
    order: 2;
  `}
`

const StyledFormikLabeledCheckbox = styled(FormikLabeledCheckBox)`
  margin: 0;
  justify-content: center;

  > span:last-of-type {
    display: none;
  }
`

const StyledSpan = styled.span`
  display: block;
  margin-top: ${({ theme }) => theme.sizings.lvl0};
  font-size: ${({ theme }) => theme.smallFontSize};
`

const NEW_CAR_SERVICE_ID = 'NEW'

function calculateCustomerPrice(clientTariff, costPerYear) {
  return costPerYear * (clientTariff / 100)
}

function sumUpArray(arrayofNumbers) {
  return Array.isArray(arrayofNumbers) && arrayofNumbers.length > 0
    ? arrayofNumbers.reduce(
        (prevValue, currentValue) => Number(prevValue) + Number(currentValue),
      )
    : 0
}

function handleNumbersUpdate(id, rowId, value, values, setFieldValue) {
  // Formik sends NaN as a value when a number field is empty.
  // Replacing that with an empty string here.
  const correctedValue = Number.isNaN(value) ? '' : value
  const costPerYear =
    id === 'costPerYear'
      ? correctedValue
      : values.carServices[rowId].costPerYear
  const clientTariff =
    id === 'clientTariff'
      ? correctedValue
      : values.carServices[rowId].clientTariff
  const customerPrice = calculateCustomerPrice(
    clientTariff || 0,
    costPerYear || 0,
  )

  // Creates a new object (does not keep the reference)
  const clonedValues = JSON.parse(JSON.stringify(values)).carServices

  // Updates the values in the row
  clonedValues[rowId] = {
    ...clonedValues[rowId],
    clientTariff,
    costPerYear,
    customerPrice,
  }

  setFieldValue('carServices', clonedValues)
}

const getEmptyRow = () => ({
  id: NEW_CAR_SERVICE_ID,
  commercialName: '',
  clientTariff: 0,
  costPerYear: 0,
  packageTypes: {
    [cspPackageTypes.LOYAL]: false,
    [cspPackageTypes.BASIC]: false,
    [cspPackageTypes.PLUS_24_MONTHS]: false,
  },
  deletable: true,
  editableCostPerYear: true,
  editablePackageTypes: true,
  multiLine: false,
  carService: null,
  carServicePlan: {
    invoiceFee: 1.75,
  },
  readonly: false,
})

const setUpInitialValues = (data) => {
  const carServicesRaw = data?.carServiceIncludes || []

  let resetId = 0

  // Some of the car services have a "multiline" attribute. These services
  // will be displayed as separate rows in the configuration modal, but have the
  // same properties as their "parent".
  // The parent is the first item in the commercialName array of strings
  // The key / value "readonly" is used only for the frontend, to show / hide certain fields
  // The key / value "resetId" is used only for the frontend, to reset the values
  const carServicesMultiline = carServicesRaw.reduce(
    (previousRows, currentRow) => {
      if (currentRow.multiLine && currentRow.commercialName.length > 1) {
        const splitRows = currentRow.commercialName.map(
          (commercialName, commercialNameIndex) => ({
            ...currentRow,
            commercialName,
            clientTariff:
              commercialNameIndex === 0 ? currentRow.clientTariff : 0,
            costPerYear: commercialNameIndex === 0 ? currentRow.costPerYear : 0,
            readonly: commercialNameIndex !== 0,
            resetId: resetId++,
          }),
        )

        return [...previousRows, ...splitRows]
      }

      return [
        ...previousRows,
        {
          ...currentRow,
          commercialName: currentRow.commercialName[0],
          commercialNameIndex: 0,
          readonly: false,
          resetId: resetId++,
        },
      ]
    },
    [],
  )

  const carServicesWithConvertedTariff = carServicesMultiline.map(
    (carService) => {
      const clientTariff = Math.round(carService.clientTariff * 100)

      return {
        ...carService,
        clientTariff,
        customerPrice: calculateCustomerPrice(
          clientTariff,
          carService.costPerYear,
        ),
      }
    },
  )

  const carServices = carServicesWithConvertedTariff.map((carService) => {
    const { packageTypes } = carService
    const packageTypesArray = Array.isArray(packageTypes) ? packageTypes : []

    return {
      ...carService,
      packageTypes: {
        [cspPackageTypes.LOYAL]: packageTypesArray.includes(
          cspPackageTypes.LOYAL,
        ),
        [cspPackageTypes.BASIC]: packageTypesArray.includes(
          cspPackageTypes.BASIC,
        ),
        [cspPackageTypes.PLUS_24_MONTHS]: packageTypesArray.includes(
          cspPackageTypes.PLUS_24_MONTHS,
        ),
      },
    }
  })

  // When there's no carservices, make an empty new one
  if (carServices.length === 0) {
    const newRow = getEmptyRow()
    carServices.push(newRow)
  }

  return { carServices }
}

const OverlayContainer = ({ children, ...restProps }) => {
  const { t } = useTranslation()

  return (
    <Overlay title={t('editCarServices.title')} icon="tweak" {...restProps}>
      <Card paddingLevel={4}>{children}</Card>
    </Overlay>
  )
}

OverlayContainer.propTypes = {
  children: PropTypes.node,
}

OverlayContainer.defaultProps = {
  children: undefined,
}

const CarServicesOverlay = ({ onClose, carServicePlanId, invoiceFee }) => {
  const { t } = useTranslation()
  const [deletedCarServices, setDeletedCarServices] = useState([])

  const { loading, error, data } = useQuery(READ_CARSERVICE_INCLUDED, {
    variables: {
      carServicePlanId,
    },
  })

  const refetchQueries = [
    {
      query: READ_CARSERVICE_INCLUDED,
      variables: {
        carServicePlanId,
      },
    },
  ]
  const [persistUpdatedCarService] = useMutation(UPDATE_CARSERVICE_INCLUDED, {
    refetchQueries,
    awaitRefetchQueries: true,
  })
  const [createNewCarService] = useMutation(CREATE_CARSERVICE_INCLUDED, {
    refetchQueries,
    awaitRefetchQueries: true,
  })
  const [deleteCarService] = useMutation(DELETE_CARSERVICE_INCLUDED, {
    refetchQueries,
    awaitRefetchQueries: true,
  })

  if (error) {
    console.error(error)
  }

  if (loading || error) {
    return (
      <OverlayContainer close={onClose}>
        {loading && <LoadingIndicator />}
        {error && <GeneralDataError />}
      </OverlayContainer>
    )
  }

  if (!data.carServiceIncludes || !data.carServiceIncludes.length > 0) {
    return (
      <OverlayContainer close={onClose}>
        <DealerNoCSPMessage />
      </OverlayContainer>
    )
  }

  const initialValues = setUpInitialValues(data)

  // Setup the reset values as an object, where the keys is the resetId of the car service.
  // This makes it easier to reset the values of a specific row.
  const resetValues = {}

  initialValues.carServices.forEach((carService) => {
    resetValues[carService.resetId] = { ...carService }
  })

  const handleSubmit = (values) => {
    const { carServices } = values

    const mutations = []

    const mappedCarServices = carServices
      .reduce((previousService, currentService) => {
        // When the current service ID is already found in the array, concatinate
        // it with the previous object. These are items that need to have an array
        // of strings for the key "commercialName" in the backend.
        const parentService = previousService.find(
          (service) =>
            service.id === currentService.id &&
            service.id !== NEW_CAR_SERVICE_ID,
        )

        if (parentService) {
          parentService.commercialName.push(currentService.commercialName)

          // The parent service has been updated, return "false" for the current car service.
          // This was just a 'multiline' service to show in the front-end.
          return [...previousService, false]
        }

        // Find which package types are checked in the form, and map it to an array
        // of strings that the backend expects.
        const packageTypes = Object.keys(cspPackageTypes).filter(
          (packageType) => !!currentService.packageTypes[packageType],
        )

        // Convert the costPerYear
        const costPerYear =
          typeof currentService.costPerYear === 'string'
            ? parseFloat(currentService.costPerYear)
            : currentService.costPerYear

        // Convert the client tariff
        let clientTariff =
          typeof currentService.clientTariff === 'string'
            ? parseFloat(currentService.clientTariff)
            : currentService.clientTrariff
        if (typeof clientTariff === 'number') {
          clientTariff = clientTariff / 100
        }

        // Map the object to the structure the backend expects
        const mappedService = {
          commercialName: [currentService.commercialName], // Needs to be an array of strings. In the frontend, it's a string.
          clientTariff,
          costPerYear,
          packageTypes,
          id: currentService.id,
          deletable: currentService.deletable,
          editableCostPerYear: currentService.editableCostPerYear,
          editablePackageTypes: currentService.editablePackageTypes,
          multiLine: currentService.multiLine,
        }

        return [...previousService, mappedService]
      }, [])
      .filter(Boolean)

    // Loop over the table row values.
    // Each row will be pushed to the mutations array and then fired to GraphQL.
    mappedCarServices.forEach((currentService) => {
      let mutation

      // When the ID is NOT equal to the NEW_CAR_SERVICE_ID constant, it means the user
      // has edited this row in the frontend, but it already exists in the backend.
      // We need to call the "persistUpdatedCarService" mutation so this service can be updated.
      if (currentService.id !== NEW_CAR_SERVICE_ID) {
        mutation = persistUpdatedCarService({
          variables: {
            ...currentService,
          },
        })
      }

      // When the ID is equal to the NEW_CAR_SERVICE_ID constant, it means the user
      // has created this row in the frontend. We need to call the "createNewCarService"
      // so this service can be created in the backend.
      if (currentService.id === NEW_CAR_SERVICE_ID) {
        mutation = createNewCarService({
          variables: {
            ...currentService,
            carServicePlanId,
            deletable: true,
            multiLine: false,
            editableCostPerYear: true,
            editablePackageTypes: true,
          },
        })
      }

      mutations.push(mutation)
    })

    deletedCarServices.forEach((carServiceId) => {
      // Create the "deleteCarService" mutation, which deletes this service from the backend
      const mutation = deleteCarService({
        variables: {
          carServiceId,
        },
      })

      // Push the mutation to the mutation array.
      mutations.push(mutation)
    })

    // Wait for all mutations to finish
    Promise.all(mutations)
      .then(() => {
        toast.success(
          t('formFeedback.partSaved', {
            part: t('editCarServices.servicePackages'),
          }),
        )
        onClose()
      })
      .catch(() => {
        toast.error(
          t('formFeedback.partSaveError', {
            part: t('EditCarServices.servicePackages').toLowerCase(),
          }),
        )
      })
  }

  const getCalculationValues = (values) => {
    const validRows = values.carServices.filter(
      (carService) => carService.costPerYear > 0 && carService.clientTariff > 0,
    )

    const totalCostsForDealer = sumUpArray(
      values.carServices.map((service) => service.costPerYear || 0),
    )
    const totalCostsForClient = sumUpArray(
      validRows.map((service) => service.customerPrice || 0),
    )
    const clientSaves = totalCostsForDealer - totalCostsForClient

    /**
     * The price per month always receives a UCC fee. That fee is not
     * explicitly shown in the interface, but it does need to be
     * included in the calculation. The formula is: invoiceFee + 21% tax.
     */
    const UCCFee = invoiceFee * 1.21
    const customerPricePerMonth = (totalCostsForClient / 12 + UCCFee).toFixed(2)

    return {
      totalCostsForDealer,
      totalCostsForClient,
      clientSaves,
      customerPricePerMonth,
    }
  }

  return (
    <OverlayContainer close={onClose}>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={CSPEditCarServicesFormSchema(t)}
      >
        {({ values, isSubmitting, setFieldValue }) => {
          const calculationValues = getCalculationValues(values)

          return (
            <Form>
              <EnhancedTable
                selectable={false}
                verticalCellBorders
                columns={[
                  {
                    id: 'name',
                    label: t('editCarServices.tableHeadings.name'),
                  },
                  {
                    id: 'commercialName',
                    label: t('editCarServices.tableHeadings.commercialName'),
                  },
                  {
                    id: `packageTypes.[${cspPackageTypes.LOYAL}]`,
                    label: t(
                      'EditCarServices.tableHeadings.packageTypes.loyal',
                    ),
                    width: '70px',
                    alignCenter: true,
                  },
                  {
                    id: `packageTypes.[${cspPackageTypes.BASIC}]`,
                    label: t(
                      'EditCarServices.tableHeadings.packageTypes.basic',
                    ),
                    width: '70px',
                    alignCenter: true,
                  },
                  {
                    id: `packageTypes.[${cspPackageTypes.PLUS_24_MONTHS}]`,
                    label: t(
                      'EditCarServices.tableHeadings.packageTypes.plus24',
                    ),
                    width: '70px',
                    alignCenter: true,
                  },
                  {
                    id: 'costPerYear',
                    label: t('editCarServices.tableHeadings.costPerYear'),
                  },
                  {
                    id: 'clientTariff',
                    label: t('editCarServices.tableHeadings.clientTariff'),
                  },
                  {
                    id: 'customerPrice',
                    label: t('EditCarServices.tableHeadings.customerPrice'),
                    width: '140px',
                  },
                  {
                    id: 'rowActions',
                    label: t('editCarServices.tableHeadings.actions'),
                    alignRight: true,
                  },
                ]}
                rows={values.carServices.map((carService, carServiceIndex) => {
                  let name = ''

                  if (carService.carService?.name) {
                    name = carService.carService.name
                  } else if (carService.carService === null) {
                    name = t('EditCarServices.ownAddition')
                  }

                  // Saves the original values if it's not a new created service
                  const originalValues =
                    carService.id !== NEW_CAR_SERVICE_ID
                      ? {
                          commercialName:
                            resetValues[carServiceIndex].commercialName,
                          packageTypes:
                            resetValues[carServiceIndex].packageTypes,
                          costPerYear: resetValues[carServiceIndex].costPerYear,
                          clientTariff:
                            resetValues[carServiceIndex].clientTariff,
                        }
                      : {}

                  // Saves the updated values if it's not a new created service
                  const updatedValues =
                    carService.id !== NEW_CAR_SERVICE_ID
                      ? {
                          commercialName:
                            values.carServices[carServiceIndex].commercialName,
                          packageTypes:
                            values.carServices[carServiceIndex].packageTypes,
                          costPerYear:
                            values.carServices[carServiceIndex].costPerYear,
                          clientTariff:
                            values.carServices[carServiceIndex].clientTariff,
                        }
                      : {}

                  const isResetButtonDisabled = () => {
                    // When it's a new service created in the frontend, always disable the reset button
                    if (carService.id === NEW_CAR_SERVICE_ID || isSubmitting) {
                      return true
                    }

                    if (
                      JSON.stringify(originalValues) ===
                      JSON.stringify(updatedValues)
                    ) {
                      return true
                    } else {
                      return false
                    }
                  }

                  const handleResetButtonClick = () => {
                    // When it's a new service created in the frontend, do not handle the reset button click
                    if (carService.id === NEW_CAR_SERVICE_ID) {
                      return null
                    }

                    if (
                      JSON.stringify(originalValues) ===
                      JSON.stringify(updatedValues)
                    ) {
                      return null
                    } else {
                      // Creates a new object (does not keep the reference)
                      const clonedValues = JSON.parse(
                        JSON.stringify(values),
                      ).carServices

                      // Overrides the value for the specific row index that the user needs to have rest to original values
                      clonedValues[carServiceIndex] =
                        resetValues[carServiceIndex]

                      // Resets the values in Formik's state
                      setFieldValue('carServices', clonedValues)
                    }
                  }

                  const isDeleteButtonDisabled = () => {
                    return isSubmitting || !carService.deletable
                  }

                  const handleDeleteButtonClick = () => {
                    // Creates a new object (does not keep the reference)
                    const clonedValues = JSON.parse(
                      JSON.stringify(values),
                    ).carServices

                    // Removes the carservice from the frontend
                    const removedCarService = clonedValues.splice(
                      carServiceIndex,
                      1,
                    )[0]

                    // If the deleted item is not an item that just has been created in the frontend
                    // Add the delected car service ID to the "deletedCarServices" state.
                    // This state will be used to trigger the delete mutation in the backend
                    if (removedCarService.id !== NEW_CAR_SERVICE_ID) {
                      setDeletedCarServices([
                        ...deletedCarServices,
                        removedCarService.id,
                      ])
                    }

                    // Updates the Formik state. The deleted carservice was removed with splice.
                    setFieldValue('carServices', clonedValues)
                  }

                  return {
                    name: {
                      component: (
                        <StyledTableDataText>{name}</StyledTableDataText>
                      ),
                      data: name,
                    },
                    commercialName: {
                      component: (
                        <Field
                          name={`carServices[${carServiceIndex}].commercialName`}
                          label=""
                          filled
                          component={CommercialTextInput}
                        />
                      ),
                    },
                    [`packageTypes.[${cspPackageTypes.LOYAL}]`]: {
                      component: (
                        <Field
                          name={`carServices[${carServiceIndex}].packageTypes.${cspPackageTypes.LOYAL}`}
                          component={StyledFormikLabeledCheckbox}
                          disabled={!carService.editablePackageTypes}
                        />
                      ),
                    },
                    [`packageTypes.[${cspPackageTypes.BASIC}]`]: {
                      component: (
                        <Field
                          name={`carServices[${carServiceIndex}].packageTypes.${cspPackageTypes.BASIC}`}
                          component={StyledFormikLabeledCheckbox}
                          disabled={!carService.editablePackageTypes}
                        />
                      ),
                    },
                    [`packageTypes.[${cspPackageTypes.PLUS_24_MONTHS}]`]: {
                      component: (
                        <Field
                          name={`carServices[${carServiceIndex}].packageTypes.${cspPackageTypes.PLUS_24_MONTHS}`}
                          component={StyledFormikLabeledCheckbox}
                          disabled={!carService.editablePackageTypes}
                        />
                      ),
                    },
                    costPerYear: {
                      component: carService.readonly === false && (
                        <Field
                          name={`carServices[${carServiceIndex}].costPerYear`}
                          label=""
                          unit="currency_euro"
                          type="number"
                          filled
                          disabled={!carService.editableCostPerYear}
                          onChange={(value) =>
                            handleNumbersUpdate(
                              'costPerYear',
                              carServiceIndex,
                              value,
                              values,
                              setFieldValue,
                            )
                          }
                          component={NumberInput}
                        />
                      ),
                    },
                    clientTariff: {
                      component: carService.readonly === false && (
                        <Field
                          name={`carServices[${carServiceIndex}].clientTariff`}
                          label=""
                          filled
                          unit="percent"
                          type="number"
                          onChange={(value) =>
                            handleNumbersUpdate(
                              'clientTariff',
                              carServiceIndex,
                              value,
                              values,
                              setFieldValue,
                            )
                          }
                          component={NumberInput}
                        />
                      ),
                    },
                    customerPrice: {
                      component: !Number.isNaN(
                        Number(carService.customerPrice),
                      ) &&
                        carService.readonly === false && (
                          <StyledCurrencyData>
                            {formatPrice(
                              carService.customerPrice,
                              true,
                              false,
                              true,
                            )}
                          </StyledCurrencyData>
                        ),
                      data: carService.customerPrice,
                    },
                    rowActions: {
                      component: (
                        <ActionsContainer>
                          <ActionButton
                            level="option"
                            icon="resetSquaredOff"
                            disabled={isResetButtonDisabled()}
                            onClick={handleResetButtonClick}
                          />
                          <Line variant="vertical" paddingLevel={1} />
                          <ActionButton
                            level="option"
                            icon="deleteCarfile"
                            disabled={isDeleteButtonDisabled()}
                            onClick={handleDeleteButtonClick}
                          />
                        </ActionsContainer>
                      ),
                    },
                  }
                })}
                customTableFooter={
                  <>
                    <FooterTableRow>
                      <FooterTableCell colSpan={9}>
                        <AddCarServiceLink
                          onClick={() => {
                            // Creates a new empty row
                            const newRow = getEmptyRow()

                            // Clones the current values
                            const carServicesClone = Object.assign(
                              {},
                              values,
                            )?.carServices

                            // Pushes the new row to the array
                            carServicesClone.push(newRow)

                            // Adds the modified clone to the Formik state
                            setFieldValue('carServices', carServicesClone)
                          }}
                          text={`+ ${t('editCarServices.addCarService')}`}
                        />
                      </FooterTableCell>
                    </FooterTableRow>

                    <FooterTableRow isPrimaryRow>
                      <FooterTableCell colSpan={5}>
                        {t('EditCarServices.totalCosts')}
                      </FooterTableCell>
                      <FooterTableCell colSpan={2}>
                        <StyledCurrencyFooterData>
                          {formatPrice(
                            calculationValues.totalCostsForDealer,
                            true,
                            false,
                            true,
                          )}
                        </StyledCurrencyFooterData>
                      </FooterTableCell>
                      <FooterTableCell>
                        <StyledCurrencyFooterData>
                          {formatPrice(
                            calculationValues.totalCostsForClient,
                            true,
                            false,
                            true,
                          )}
                        </StyledCurrencyFooterData>
                      </FooterTableCell>
                      <FooterTableCell>&nbsp;</FooterTableCell>
                    </FooterTableRow>

                    <FooterTableRow>
                      <FooterTableCell colSpan={5} />
                      <FooterTableCell colSpan={2}>
                        {t('EditCarServices.benefitForCustomer')}
                        <StyledSpan>
                          {t(
                            'EditCarServices.perMonthPriceForCustomerInclSurtax',
                          )}
                        </StyledSpan>
                      </FooterTableCell>
                      <FooterTableCell>
                        <StyledCurrencyFooterData>
                          {formatPrice(
                            calculationValues.clientSaves,
                            true,
                            false,
                            true,
                          )}
                        </StyledCurrencyFooterData>
                      </FooterTableCell>
                      <FooterTableCell>&nbsp;</FooterTableCell>
                    </FooterTableRow>

                    <FooterTableRow>
                      <FooterTableCell colSpan={5} />
                      <FooterTableCell colSpan={2}>
                        {t('editCarServices.perMonthPriceForCustomer')}
                      </FooterTableCell>
                      <FooterTableCell>
                        <StyledCurrencyFooterData>
                          {formatPrice(
                            calculationValues.customerPricePerMonth,
                            true,
                            false,
                            true,
                          )}
                        </StyledCurrencyFooterData>
                      </FooterTableCell>
                      <FooterTableCell>&nbsp;</FooterTableCell>
                    </FooterTableRow>
                  </>
                }
              />
              <SubmitRow>
                <SubmitButton level="cta" type="submit" disabled={isSubmitting}>
                  {t('save')}
                </SubmitButton>
                {isSubmitting && <StyledLoadingIndicator size="small" />}
              </SubmitRow>
            </Form>
          )
        }}
      </Formik>
    </OverlayContainer>
  )
}

CarServicesOverlay.propTypes = {
  onClose: PropTypes.func.isRequired,
  carServicePlanId: PropTypes.string.isRequired,
  invoiceFee: PropTypes.number,
}

CarServicesOverlay.defaultProps = {
  invoiceFee: 0,
}

export default CarServicesOverlay
