import { gql } from '@apollo/client'

export const SET_ACCESSORIES_HIGHLIGHT = gql`
  mutation setAccessoriesHighlight($id: ID!, $highlight: Boolean!) {
    setAccessoriesHighlight(id: $id, highlight: $highlight) {
      highlight
    }
  }
`

export const GET_USER_INPUT_ACCESSORIES = gql`
  query userInputAccessories {
    userInputAccessories(first: 9999) {
      edges {
        node {
          vehicleTypes
          id
          omschrijving
          disabled
          gebruikt
          userInput
          translations {
            language
            original
            translation
            localizationId
          }
        }
      }
    }
  }
`

export const CREATE_USER_INPUT_ACCESSORY = gql`
  mutation createUserInputAccessory(
    $omschrijving: String!
    $vehicleTypes: [String]
  ) {
    createUserInputAccessory(
      omschrijving: $omschrijving
      vehicleTypes: $vehicleTypes
    ) {
      vehicleTypes
      id
      omschrijving
      disabled
      gebruikt
      userInput
      translations {
        language
        original
        translation
        localizationId
      }
    }
  }
`

export const UPDATE_USER_INPUT_ACCESSORY = gql`
  mutation updateUserInputAccessory(
    $id: ID!
    $omschrijving: String
    $disabled: Boolean
    $vehicleTypes: [String]
  ) {
    updateUserInputAccessory(
      id: $id
      omschrijving: $omschrijving
      disabled: $disabled
      vehicleTypes: $vehicleTypes
    ) {
      vehicleTypes
      id
      omschrijving
      disabled
      gebruikt
      userInput
      translations {
        language
        original
        translation
        localizationId
      }
    }
  }
`

export const DELETE_USER_INPUT_ACCESSORY = gql`
  mutation deleteUserInputAccessory($id: ID!) {
    deleteUserInputAccessory(id: $id) {
      vehicleTypes
      id
      omschrijving
      disabled
      gebruikt
      userInput
      translations {
        language
        original
        translation
        localizationId
      }
    }
  }
`

export const DELETE_USER_INPUT_ACCESSORY_LOCALIZATION = gql`
  mutation deleteUserInputAccessory($id: ID!) {
    deleteUserInputAccessory(id: $id) {
      vehicleTypes
      id
      omschrijving
      disabled
      gebruikt
      userInput
      translations {
        language
        original
        translation
        localizationId
      }
    }
  }
`

export const UPDATE_USER_INPUT_ACCESSORY_LOCALIZATION = gql`
  mutation updateUserInputAccessoryLocalization(
    $id: ID!
    $language: LanguageCodeEnum!
    $text: String!
  ) {
    updateLocalization(id: $id, language: $language, text: $text) {
      language
      original
      translation
      localizationId
    }
  }
`
