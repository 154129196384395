import { connect } from 'react-redux'
import { getForms } from 'redux/actions/forms'
import { postDynamicEndpoint } from 'redux/actions/data'
import {
  deleteDeliverySegment,
  getDeliverySegments,
} from 'redux/actions/settings'
import { openOverlay } from 'redux/actions/ui'
import DeliverySegmentsSettings from '../views/settings/delivery-segments-settings'

const mapStateToProps = ({ data, settings }) => ({
  deliverySegments: settings.deliverySegments,
  dynamicEndpoint: data.dynamicEndpoint,
})

const mapDispatchToProps = {
  deleteDeliverySegment,
  getDeliverySegments,
  getForms,
  openOverlay,
  postDynamicEndpoint,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliverySegmentsSettings)
