import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { ThemeContext } from 'styled-components'

/**
 * This component allows a user to easily compare a set of items.
 * It uses a bar chart to achieve this. This is also why all the values have the same unit.
 *
 */
const CompareGroupedValuesChart = ({ className, groupedItems, noOfTicks }) => {
  const themeContext = useContext(ThemeContext)
  const transparentizeColour = (hex) => `${hex}aa`
  const labels = groupedItems.map((group) => group.label)
  const datasets = []
  const itemsLength = groupedItems[0].items.length

  // pre-fill the datasets:
  for (let index = 0; index < itemsLength; index += 1) {
    datasets[index] = {
      categoryPercentage: 0.7,
      barPercentage: 1,
      maxBarThickness: themeContext.baseSize * 6,
      borderWidth: 0,
      backgroundColor: [],
      hoverBackgroundColor: [],
      data: [],
      label: null,
    }
  }

  // map labels, colours and values in the datasets the bar charts expects:
  groupedItems.forEach((group, groupIndex) => {
    group.items.forEach((item, itemIndex) => {
      const dataSet = datasets[itemIndex]
      const colorValue = themeContext.colors[item.color]
      dataSet.label = item.label
      dataSet.data[groupIndex] = item.value
      dataSet.backgroundColor[groupIndex] = colorValue
      dataSet.hoverBackgroundColor[groupIndex] =
        transparentizeColour(colorValue)
    })
  })

  const convertedData = {
    labels,
    datasets,
  }
  // eslint-disable-next-line max-len
  const maxValuesPerGroup = groupedItems.map((group) =>
    Math.max(...group.items.map((item) => item.value)),
  )
  const maxValue = Math.max(...maxValuesPerGroup)
  return (
    <section
      className={className}
      style={{
        minHeight: themeContext.baseSize * 50,
      }}
    >
      <Bar
        data={convertedData}
        options={{
          responsive: true,
          aspectRatio: 2,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          plugins: {
            datalabels: {
              display: false, // By default hide the data labels
            },
          },
          title: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  beginAtZero: true,
                  // reduce the amount of ticks, making the chart look cleaner
                  // + 10% extra breathing room (maxValue / 10)
                  stepSize: Math.round((maxValue + maxValue / 10) / noOfTicks),
                },
              },
            ],
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        }}
      />
    </section>
  )
}
CompareGroupedValuesChart.propTypes = {
  /**
   * An array of items you want to display in the chart
   */
  groupedItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  /**
   * Number of ticks (horizontal lines) you want to shown in the chart
   */
  noOfTicks: PropTypes.number,
  className: PropTypes.string,
}

CompareGroupedValuesChart.defaultProps = {
  noOfTicks: 5,
  className: null,
}

export default CompareGroupedValuesChart
