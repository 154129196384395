import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components/macro'
import { useMediaLayout } from 'use-media'

import {
  CarFileStatusEnumUpperCase,
  CarFileStatusEnumLowerCase,
  ChecklistItemsFilterOptions,
} from 'config/enums'
import { CHECKLISTS_WIDGET_STATUSSES } from 'config/graphql/v4'
import { DASHBOARD as DASHBOARD_ROUTE } from 'config/routes'

import { withApolloV4Provider } from 'utilities/apollo'
import { formatSingleChecklistsOverlayUrl } from 'utilities/format'

import Icon from 'components/atoms/icon'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Text from 'components/atoms/text'
import Tabs from 'components/layouts/tabs'
import Typography from 'components/molecules/typography'
import ChecklistsAccordion from 'components/views/checklists/checklists-accordion'
import { SORT_ON_PARAM } from 'components/views/checklists/single-checklist-overlay'

const HeaderTitleContainer = styled.header`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.sizings.lvl1};
  margin-bottom: ${({ theme }) => theme.sizings.lvl1}};
`

const StyledTabs = styled(Tabs)`
  & .SubItemContainer {
    margin-left: 0;
    margin-right: 0;
  }

  & .TabChildContainer {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
`

export const CHECKLIST_PARAM = 'checklist'
export const ROW_INDEX_PARAM = 'rij'

const ChecklistsDashboardWidget = ({ locationId, ...restProps }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search.replace(/^\?/, ''))

  const checklistIdFromUrl = searchParams.get(CHECKLIST_PARAM)
  const rowIndexFromUrl = Number(searchParams.get(ROW_INDEX_PARAM)) || null

  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.phone - 1 })

  const [expandedChecklistRowIndex, setExpandedChecklistRowIndex] =
    useState(rowIndexFromUrl)

  const onRowExpandCollapseClick = ({
    id,
    isExpanded: isCurrentlyExpanded,
  }) => {
    /**
     * Update the URL based on the expanded/collapsed row.
     *
     * Note: implementing this with useState, and useEffect apparently completely remounts this
     * whole component, which causes problems. Thus, updating the url directly in this handler.
     **/
    const searchParams = new URLSearchParams(location.search.replace(/^\?/, ''))

    if (isCurrentlyExpanded) {
      searchParams.delete(CHECKLIST_PARAM)
    } else {
      searchParams.set(CHECKLIST_PARAM, id)
    }

    const params = searchParams.toString()
    const newUrl = `${location.pathname}${params.length > 0 ? `?${params}` : ''}`

    history.replace(newUrl)
  }

  const { data, loading, error } = useQuery(CHECKLISTS_WIDGET_STATUSSES, {
    variables: {
      locationId,
    },
  })

  if (loading || error) {
    return <LoadingIndicator error={error ? t('genericError') : undefined} />
  }

  // The checklistId is required
  // The checklistItemId prop is optional
  // The statusFilter prop should be a ChecklistItemsFilterOptions, or can be left empty
  const openChecklistsOverlay = (
    checklistId,
    checklistItemId,
    statusFilter,
  ) => {
    const params = {
      status: statusFilter,
    }
    if (checklistItemId) {
      params[SORT_ON_PARAM] = checklistItemId
    }
    const url = formatSingleChecklistsOverlayUrl(
      checklistId,
      checklistItemId,
      params,
    )
    history.push(url, {
      referrerUrl: `${history.location.pathname}${history.location.search}`,
    })
  }

  const onNameClick = (checklistResult, e) => {
    openChecklistsOverlay(checklistResult.checklist.id)
  }

  const onTotalCarsClick = (checklistResult, e) => {
    openChecklistsOverlay(checklistResult.checklist.id)
  }

  const onDelayedCarsClick = (checklistResult, e) => {
    openChecklistsOverlay(
      checklistResult.checklist.id,
      undefined,
      ChecklistItemsFilterOptions.DELAYED,
    )
  }

  const onItemTotalCarsClick = (checklistItemResult, e) => {
    openChecklistsOverlay(
      checklistItemResult.checklistItem.checklist.id,
      checklistItemResult.checklistItem.id,
      ChecklistItemsFilterOptions.NOT_COMPLETED,
    )
  }

  const onItemDelayedCarsClick = (checklistItemResult, e) => {
    openChecklistsOverlay(
      checklistItemResult.checklistItem.checklist.id,
      checklistItemResult.checklistItem.id,
      ChecklistItemsFilterOptions.DELAYED,
    )
  }

  const onExpandableChecklistRowClick = (rowIndex) => {
    setExpandedChecklistRowIndex((previousRowIndex) => {
      const newIndex = previousRowIndex === rowIndex ? null : rowIndex
      const searchParams = new URLSearchParams(
        location.search.replace(/^\?/, ''),
      )

      if (typeof newIndex === 'number') {
        searchParams.set(ROW_INDEX_PARAM, rowIndex)
      } else {
        searchParams.delete(ROW_INDEX_PARAM)
      }

      const params = searchParams.toString()
      const newUrl = `${location.pathname}${params.length > 0 ? `?${params}` : ''}`

      history.replace(newUrl)

      return newIndex
    })
  }

  const tabItems = [
    CarFileStatusEnumUpperCase.lopend,
    CarFileStatusEnumUpperCase.verwacht,
    CarFileStatusEnumUpperCase.binnen,
    CarFileStatusEnumUpperCase.verkocht,
    CarFileStatusEnumUpperCase.afgeleverd,
  ].map((status) => ({
    label: t(`carFileStatusses.${status}`),
    to: `${DASHBOARD_ROUTE.replace(/\/?$/, '')}/${CarFileStatusEnumLowerCase[status]}`,
    key: status,
    count:
      data && Array.isArray(data[status])
        ? data[status].reduce((count, result) => count + result.delayed, 0)
        : undefined,
    component: (props) => (
      <>
        <Typography type="Level2Heading">{t('checklists')}</Typography>
        <ChecklistsAccordion
          key={status}
          {...props}
          onNameClick={!isMobile ? onNameClick : undefined}
          onRowExpandCollapseClick={onRowExpandCollapseClick}
          status={status}
          locationId={locationId}
          onTotalCarsClick={!isMobile ? onTotalCarsClick : undefined}
          onDelayedCarsClick={!isMobile ? onDelayedCarsClick : undefined}
          onItemTotalCarsClick={!isMobile ? onItemTotalCarsClick : undefined}
          onItemDelayedCarsClick={
            !isMobile ? onItemDelayedCarsClick : undefined
          }
          initialExpandedRowId={checklistIdFromUrl}
          expandedChecklistRowIndex={expandedChecklistRowIndex}
          setExpandedChecklistRowIndex={setExpandedChecklistRowIndex}
          onExpandableChecklistRowClick={onExpandableChecklistRowClick}
        />
      </>
    ),
  }))

  return (
    <div {...restProps}>
      <HeaderTitleContainer data-test-e2e="card-title">
        <Icon type="checklist" color="text" />
        <Text
          text={t('checklistsDashboardWidget.title')}
          type="h3"
          data-test-e2e="card-title-text"
        />
      </HeaderTitleContainer>
      <StyledTabs items={tabItems} fullScreenHeightOnMobile={false} small>
        {tabItems.map((item) => (
          <item.component key={item.key} />
        ))}
      </StyledTabs>
    </div>
  )
}

ChecklistsDashboardWidget.propTypes = {
  locationId: PropTypes.string,
}

export default withApolloV4Provider(ChecklistsDashboardWidget)
