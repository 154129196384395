import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { addVinNumberDialogFormValidation } from 'config/validation-schemas'

import Button from 'components/atoms/button'
import LoadingButton from 'components/atoms/loading-button'
import Dialog from 'components/molecules/flexible-dialog'
import { FormikTextInput } from 'components/molecules/formik-field'
import Typography from 'components/molecules/typography'

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.sizings.lvl3};
  > * {
    flex: 1 0;
    width: ${({ theme }) => theme.sizeByFactor(40)};
  }
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`

const Buttons = styled.div`
  display: flex;
`

const AddVinNumberDialog = ({
  onToggleClick,
  onClose,
  isLoading,
  vin,
  handleVinChange,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open
      closeHandler={onClose}
      overflowVisible
      title={t('carFileSummary.addVinNumberDialog.title')}
      content={
        <>
          <Typography type="BodyParagraph">
            {t('carFileSummary.addVinNumberDialog.description')}
          </Typography>
          <Formik
            validationSchema={addVinNumberDialogFormValidation(t)}
            validateOnChange
            validateOnBlur
            validateOnMount
            enableReinitialize
            initialValues={{ vin }}
            onSubmit={({ vin }) => {
              handleVinChange(vin)
            }}
          >
            {({ isValid }) => (
              <Form>
                <FormRow>
                  <Field
                    name="vin"
                    label={t(
                      'carFileSummary.addVinNumberDialog.form.vin.label',
                    )}
                    component={FormikTextInput}
                    filled
                  />
                </FormRow>
                <Controls>
                  <Button
                    level="option"
                    onClick={onClose}
                    text={t('cancel')}
                    noPadding
                  />
                  <Buttons>
                    {typeof onToggleClick === 'function' && (
                      <Button
                        level="option"
                        onClick={onToggleClick}
                        text={t(
                          'carFileSummary.addLicensePlateDialog.enterWithLicensePlate',
                        )}
                        isDisabled={isLoading}
                      />
                    )}
                    <LoadingButton
                      level="cta"
                      type="submit"
                      disabled={!isValid}
                      isLoading={isLoading}
                    >
                      {t('save')}
                    </LoadingButton>
                  </Buttons>
                </Controls>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  )
}

AddVinNumberDialog.propTypes = {
  onToggleClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  vin: PropTypes.string.isRequired,
  handleVinChange: PropTypes.func.isRequired,
}

AddVinNumberDialog.defaultProps = {
  isLoading: false,
  onToggleClick: undefined,
}

export default AddVinNumberDialog
