import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'

import { withApolloV4Provider } from 'utilities/apollo'
import { media } from 'utilities/styled'

import { QUERY_MANAGEMENT_INFO_CHART_TRADE_IN_VALUATION_RATIO } from 'config/graphql/v4'
import {
  DATE_FORMAT,
  LINE_CHART_Y_MARGIN_BOTTOM,
  LINE_CHART_Y_MARGIN_TOP,
  LINE_CHART_Y_MIN,
} from 'config/consts'

import ManagementInfoChart from 'components/molecules/management-info-chart'
import ManagementInfoChartControls from 'components/molecules/management-info-chart-controls'
import ManagementInfoChartContainer from 'components/molecules/management-info-chart-container'
import ManagementInfoChartLegend from 'components/molecules/management-info-chart-legend'
import ManagementInfoChartLoadingIndicator from 'components/molecules/management-info-chart-loading-indicator'
import ManagementInfoChartSelect from 'components/molecules/management-info-chart-select'
import ManagementInfoChartTitle from 'components/molecules/management-info-chart-title'
import ManagementInfoChartTitleLoadingIndicator from 'components/molecules/management-info-chart-title-loading-indicator'

import { DEFAULT_VALUE_QUARTER } from 'components/views/management-info/index'

const StyledManagementInfoChartControls = styled(ManagementInfoChartControls)`
  ${media.desktop`
    flex-direction: row;
  `}
`

const StyledManagementInfoChartLegend = styled(ManagementInfoChartLegend)`
  opacity: ${({ $loading }) => ($loading ? 0.25 : 1)};

  ${media.desktop`
    flex-shrink: 0;
    flex-grow: 1;
  `}
`

const ChartLoadingIndicator = styled(ManagementInfoChartLoadingIndicator)`
  opacity: ${({ $loading }) => ($loading ? 1 : 0)};
`

const StyledManagementInfoChart = styled(ManagementInfoChart)`
  transition: opacity ease-in 0.125s;
`

// Component constants
const QUARTER = 'quarter'
const WEEKS = 'weeks'
const SCALES = {
  NUMBER_OF_CARS: 'number-of-cars',
  NUMBER_OF_WEEKS: 'number-of-weeks',
  RATIO: 'ratio',
}

const ManagementInfoChartTradeInValuationRatio = ({ dealerNumbers }) => {
  // Hooks
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)

  // State
  const [selectedQuarterId, setSelectedQuarterId] = useState(
    DEFAULT_VALUE_QUARTER,
  )
  const [initialDataLoaded, setInitialDataLoaded] = useState(false)

  // Variables
  const quarters = []
  const dateToday = moment(new Date()).format(DATE_FORMAT)

  for (let i = 0; i < 6; i++) {
    const quarter = moment(dateToday).subtract(i, QUARTER)
    quarters.push({
      quarterId: i === 0 ? DEFAULT_VALUE_QUARTER : `${QUARTER}-${i}`,
      quarterNumber: quarter.quarter(),
      quarterYear: quarter.year(),
      quarterStartDate: quarter.startOf(QUARTER).format(DATE_FORMAT),
      quarterEndDate: quarter.endOf(QUARTER).format(DATE_FORMAT),
      quarterWeeks: moment(quarter.endOf(QUARTER)).diff(
        quarter.startOf(QUARTER),
        WEEKS,
      ),
    })
  }

  const selectedQuarter = quarters.find(
    (quarter) => quarter.quarterId === selectedQuarterId,
  )

  const [dataQuery, { data, loading }] = useLazyQuery(
    QUERY_MANAGEMENT_INFO_CHART_TRADE_IN_VALUATION_RATIO,
    {
      // This prevents Apollo to automatically re-query when the component re-renders
      nextFetchPolicy: 'standby',
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setInitialDataLoaded(true)
      },
    },
  )

  const chartDataRaw = data?.valuationByType?.valuations || []
  const chartData = {
    datasets: [
      // Data set for "Aantal taxaties"
      {
        id: SCALES.NUMBER_OF_CARS,
        label: t('managementInfo.charts.tradeInValuationRatio.legends.first'),
        data: chartDataRaw.map((day) => ({
          x: day.createdAt,
          y: day.valuationAmount,
        })),
        color: 'chartsComparisonGroup',
        steppedLine: false,
        cubicInterpolationMode: 'monotone',
        pointRadius: 2,
      },

      // Data set for "Weeknummer"
      {
        id: SCALES.NUMBER_OF_WEEKS,
        label: t('managementInfo.charts.tradeInValuationRatio.legends.second'),
        data: chartDataRaw.map((day) => ({
          x: day.createdAt,
          y: day.tradeInAmount,
        })),
        color: 'chartsMyself',
        steppedLine: false,
        cubicInterpolationMode: 'monotone',
        pointRadius: 2,
      },

      // Data set for "Inruil-taxatie ratio (%)"
      {
        id: SCALES.RATIO,
        label: t('managementInfo.charts.tradeInValuationRatio.legends.third'),
        type: 'bar',
        yAxisID: SCALES.RATIO,
        data: chartDataRaw.map((day) => ({
          x: day.createdAt,
          y: parseInt(day.ratio),
        })),
        backgroundColor: theme.colors.chartsTargetTransparent,
      },
    ],
  }

  const chartDataPoints = [
    ...chartData.datasets
      .filter((dataset) => dataset.id !== SCALES.RATIO)
      .map((dataset) => dataset.data),
  ].flat()
  const chartYMin = Math.floor(
    Number(
      chartDataPoints.reduce(
        (prev, curr) => (curr.y < prev ? curr.y : prev),
        Infinity,
      ),
    ) * LINE_CHART_Y_MARGIN_BOTTOM,
  )
  const chartYMax = Math.ceil(
    Number(
      chartDataPoints.reduce(
        (prev, curr) => (curr.y > prev ? curr.y : prev),
        LINE_CHART_Y_MIN,
      ),
    ) * LINE_CHART_Y_MARGIN_TOP,
  )

  const chartOptions = {
    animation: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label || ''
          switch (tooltipItem.datasetIndex) {
            case 2: {
              // Override tooltip for "percentate" tooltip, which is when datasetIndex is equal to 2
              return `${label}: ${tooltipItem.yLabel} ${t('managementInfo.charts.tradeInValuationRatio.legends.unitPercentage')}`
            }

            default: {
              // Return default tooltip for "aantal auto's"
              return `${label}: ${tooltipItem.yLabel} ${t('managementInfo.charts.tradeInValuationRatio.legends.unitCars')}`
            }
          }
        },
      },
    },
    scales: {
      yAxes: [
        {
          offset: true,
          id: SCALES.NUMBER_OF_CARS,
          position: 'left',
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: t(
              'managementInfo.charts.tradeInValuationRatio.legends.yAxisCars',
            ),
          },
          ticks: {
            max: chartYMax,
            min: chartYMin <= 10 ? 0 : chartYMin,
          },
        },
        {
          offset: true,
          id: SCALES.RATIO,
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: t(
              'managementInfo.charts.tradeInValuationRatio.legends.yAxisPercentage',
            ),
          },
          ticks: {
            max: 100, // Hard-coded, backend gives 0% to 100% back
            min: 0, // Hard-coded, backend gives 0% to 100% back
          },
        },
      ],
      xAxes: [
        {
          offset: true,
          type: 'time',
          distribution: 'series',
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: t(
              'managementInfo.charts.standtime123Last12Months.legends.xAxis',
            ),
          },
          time: {
            unit: 'week',
            displayFormats: {
              week: 'W',
            },
          },
        },
      ],
    },
  }

  const chartLegendItems = [
    // Legend for "Aantal taxaties"
    {
      label: t('managementInfo.charts.tradeInValuationRatio.legends.first'),
      color: 'chartsComparisonGroup',
      backgroundColor: 'fieldBackground',
      value: data?.valuationByType?.averageValuationAmount,
      unit: t('managementInfo.charts.tradeInValuationRatio.legends.unitCars'),
    },

    // Legend for "Aantal inruil"
    {
      label: t('managementInfo.charts.tradeInValuationRatio.legends.second'),
      color: 'chartsMyself',
      backgroundColor: 'fieldBackground',
      value: data?.valuationByType?.averageTradeInAmount,
      unit: t('managementInfo.charts.tradeInValuationRatio.legends.unitCars'),
    },

    // Legend for "Inruil-taxatie ratio"
    {
      label: t('managementInfo.charts.tradeInValuationRatio.legends.third'),
      color: 'chartsTarget',
      backgroundColor: 'fieldBackground',
      value: data?.valuationByType?.averageRatio,
      unit: t(
        'managementInfo.charts.tradeInValuationRatio.legends.unitPercentage',
      ),
    },
  ]

  useEffect(() => {
    if (!selectedQuarterId) {
      return
    }

    dataQuery({
      variables: {
        dealerNrs: dealerNumbers,
        fromDate: selectedQuarter.quarterStartDate,
        tillDate: selectedQuarter.quarterEndDate,
      },
    })
  }, [
    dealerNumbers,
    selectedQuarterId,
    selectedQuarter.quarterEndDate,
    selectedQuarter.quarterStartDate,
    dataQuery,
  ])

  return (
    <>
      <div>
        <ManagementInfoChartTitle>
          {t('managementInfo.charts.tradeInValuationRatio.title')}
          {loading && !initialDataLoaded && (
            <ManagementInfoChartTitleLoadingIndicator />
          )}
        </ManagementInfoChartTitle>
        <StyledManagementInfoChartControls>
          <ManagementInfoChartSelect
            items={quarters.map((quarter) => ({
              label: `${t('managementInfo.charts.quarterFilters.quarter')} ${quarter.quarterNumber}, ${quarter.quarterYear}`,
              value: quarter.quarterId,
            }))}
            onChange={setSelectedQuarterId}
            label={t('filters')}
            value={selectedQuarterId}
          />
          <StyledManagementInfoChartLegend
            $loading={loading}
            arrangeAs="row"
            items={chartLegendItems}
          />
        </StyledManagementInfoChartControls>
      </div>
      <ManagementInfoChartContainer>
        <ChartLoadingIndicator
          $loading={loading && initialDataLoaded}
          loadingText={t('managementInfo.charts.loadingData')}
        />
        <StyledManagementInfoChart
          $loading={loading && initialDataLoaded}
          options={chartOptions}
          data={chartData}
        />
      </ManagementInfoChartContainer>
    </>
  )
}

ManagementInfoChartTradeInValuationRatio.propTypes = {
  dealerNumbers: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string).isRequired,
  ]),
}

export default withApolloV4Provider(ManagementInfoChartTradeInValuationRatio)
