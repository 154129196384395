import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import LabeledCheckbox from 'components/atoms/labeled-checkbox'
import Button from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import LabeledText from 'components/atoms/labeled-text'
import Typography from 'components/molecules/typography'

/**
 * Deduces if the supplied color is a color id or a color value.
 * @param {object} theme
 * @param {String} suppliedColor
 */
function deduceColor(theme, suppliedColor, parentIsDarker) {
  if (theme.colors[suppliedColor]) {
    return theme.colors[suppliedColor]
  }
  if (suppliedColor === 'theSameAsBox') {
    return parentIsDarker
      ? theme.colors.defaultBackground
      : theme.colors.isolatedBackground
  }
  return suppliedColor
}

const LegendBoxContainer = styled.li`
  display: block; /* or.. not a list-item */
  padding: ${({ theme }) => theme.sizings.lvl2};
  padding-bottom: 0;
  border-top: solid ${({ theme }) => theme.sizings.lvl0}
    ${({ $legendColor, theme, parentIsDarker }) =>
      deduceColor(theme, $legendColor, parentIsDarker)};
  background-color: ${({ $legendBackgroundColor, parentIsDarker, theme }) =>
    $legendBackgroundColor
      ? theme.colors[$legendBackgroundColor]
      : parentIsDarker
        ? theme.colors.defaultBackground
        : theme.colors.isolatedBackground};
  box-shadow: ${({ parentIsDarker, theme }) =>
    parentIsDarker ? theme.shadows.bottom : 'none'};
`

const BoxBottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const DataLabel = styled.div`
  display: block;
  font-size: ${({ theme }) => theme.smallFontSize};
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;
  font-weight: 400;
  line-height: ${({ theme }) => theme.sizings.lvl2};
  position: relative;
`

const DataContainer = styled.div`
  color: ${({ theme }) => theme.colors.text};
  margin-top: ${({ theme }) => theme.sizings.lvl0};
`

const DataContainerValue = styled.span`
  margin-right: ${({ theme }) => theme.sizings.lvl0};
  font-size: ${({ theme }) => theme.sizeByFactor(2.5)};
  font-weight: bold;
`

const DataContainerUnit = styled(Typography)`
  margin-right: ${({ theme }) => theme.sizings.lvl0};
  font-size: ${({ theme }) => theme.baseFontSize};
`

const OptionalDataContainer = styled.div`
  height: ${({ theme }) => theme.sizings.lvl2};
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.font};
  font-size: 12px;
  font-weight: 500;
  line-height: ${({ theme }) => theme.sizings.lvl2};
  text-align: right;
`

const SubCategoryList = styled.ul`
  padding: 0 ${({ theme }) => theme.sizings.lvl2};
  overflow: hidden;
  position: relative;
  width: calc(100% + ${({ theme }) => theme.sizings.lvl4});
  left: -${({ theme }) => theme.sizings.lvl2};
  transition: max-height 0.2s;

  > li:first-of-type {
    margin-top: ${({ theme }) => theme.sizings.lvl2};
  }

  > li:last-of-type {
    margin-bottom: ${({ theme }) => theme.sizings.lvl2};
  }

  &[aria-expanded='true'] {
    border-top: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
    /* calculated value prevents box, seemingly, close in a 'delayed' fashion. */
    max-height: ${({ theme, $nSubCategories }) =>
      theme.baseSize * 5 * ($nSubCategories + 1)}px;
  }

  &[aria-expanded='false'] {
    max-height: 0px;
  }
`

const ToggleButton = styled(Button)`
  position: absolute;
  top: 0;
  right: -${({ theme }) => theme.sizings.lvl1};

  transition: transform 0.2s;
  transform: rotate(
    ${({ $areSubCategoriesShown }) =>
      $areSubCategoriesShown ? '0deg' : '45deg'}
  );
`

const LabelImage = styled.img`
  margin: auto;
  margin-left: 0;
  width: 100px;
  display: inline-block;
  vertical-align: middle;
`

const GenerateLabel = (label, labelImage) =>
  labelImage ? <LabelImage src={labelImage} alt={label} /> : label

/**
 * The LegendBox is a way to link a dataset or data-point/datum in a
 * data visualisation (through colour) to an item that explains it
 * and can give some more background information on it.
 *
 * Additionally it provides a way to manipulate the dataset
 * or data-point/datum for a visualisation through
 * the option to select and deselect a LegendBox.
 *
 * Another key feature is the ability to show that a dataset
 * or data-point/datum is still loading or needs to be loaded.
 *
 * LegendBoxes are displayed as part of the items of a chart's legend.
 * For this reason their root HTML element is a list item (`<li>`).
 * To preserve it's semantic meaning (in terms of HTML) make sure to use
 * the LegendBoxes component to lay out the LegendBox, because LegendBoxes
 * uses a unordered list element (`<ul>`) as its root element.
 *
 * In addition the LegendBoxes component provides the row and column layout too.
 */
function LegendBox({
  legendColor,
  legendBackgroundColor,
  parentIsDarker,
  label,
  labelImage,
  subLabel,
  optionalValue,
  value,
  valueIsLoading,
  unit,
  className,
  selected,
  onSelect,
  subCategories,
  onSelectSubCategories,
  noValueText,
  noValueOnClick,
}) {
  const [areSubCategoriesShown, showSubCategories] = React.useState(true)
  const { t } = useTranslation()

  function updateSubCategories(newValue, currentSubCategory) {
    const updatedSubCategories = subCategories.map((subCategoryToCopy) => {
      const updatedSubCategory = {
        ...subCategoryToCopy,
      }
      updatedSubCategory.selected =
        subCategoryToCopy.id === currentSubCategory.id
          ? newValue
          : subCategoryToCopy.selected
      return updatedSubCategory
    })
    onSelectSubCategories(updatedSubCategories)
  }

  function updateAllSubCategories(newValue) {
    const updatedSubCategories = subCategories.map((subCategoryToCopy) => {
      const updatedSubCategory = {
        ...subCategoryToCopy,
      }
      updatedSubCategory.selected = newValue
      return updatedSubCategory
    })
    onSelectSubCategories(updatedSubCategories)
  }

  return (
    <LegendBoxContainer
      className={className}
      $legendColor={legendColor}
      $legendBackgroundColor={legendBackgroundColor}
      parentIsDarker={parentIsDarker}
      data-element="legend-box-container"
    >
      <DataLabel data-element="legend-box-data-label">
        {typeof onSelect === 'function' ? (
          <LabeledCheckbox
            checked={selected}
            label={GenerateLabel(label, labelImage)}
            onChange={(checkState) => {
              if (subCategories.length) {
                updateAllSubCategories(checkState)
              }
              onSelect(checkState)
            }}
          />
        ) : (
          <>
            {GenerateLabel(label, labelImage)}
            {subLabel && (
              <>
                :<br />
                <span>{subLabel}</span>
              </>
            )}
          </>
        )}
        {/* Show toggle button for sub categories */}
        {subCategories.length && onSelectSubCategories ? (
          <ToggleButton
            $areSubCategoriesShown={areSubCategoriesShown}
            iconColor="text"
            iconSize="sm"
            icon="close"
            aria-label={
              areSubCategoriesShown ? 'show categories' : 'hide categories'
            }
            level="minimal"
            onClick={() => showSubCategories(!areSubCategoriesShown)}
          />
        ) : (
          ''
        )}
      </DataLabel>
      <BoxBottom>
        <DataContainer>
          {valueIsLoading && <LoadingIndicator />}
          {!valueIsLoading && (
            <>
              {value &&
                (Array.isArray(value) ? (
                  value.map((subItem, index) => (
                    <LabeledText
                      key={String(index)}
                      title={subItem.label}
                      value={subItem.value}
                    />
                  ))
                ) : (
                  <>
                    <DataContainerValue>{value}</DataContainerValue>
                    <DataContainerUnit type="InlineBodyText">
                      {unit}
                    </DataContainerUnit>
                  </>
                ))}

              {!value && value !== 0 && noValueOnClick && noValueText && (
                <Button
                  onClick={noValueOnClick}
                  text={noValueText}
                  level="option"
                  noPadding
                />
              )}
            </>
          )}
        </DataContainer>
        <OptionalDataContainer
          $legendColor={legendColor}
          $legendBackgroundColor={legendBackgroundColor}
        >
          {optionalValue}
        </OptionalDataContainer>
      </BoxBottom>

      {/* sub categories */}
      {subCategories.length && onSelectSubCategories ? (
        <>
          <SubCategoryList
            aria-expanded={String(areSubCategoriesShown)}
            $nSubCategories={subCategories.length}
          >
            {subCategories.map((subCategory) => (
              <li key={subCategories.id}>
                <LabeledCheckbox
                  id={subCategories.id}
                  checked={subCategory.selected}
                  label={subCategory.label}
                  onChange={(newValue) =>
                    updateSubCategories(newValue, subCategory)
                  }
                />
              </li>
            ))}
          </SubCategoryList>
          <Button
            level="option"
            noPadding
            disabled={!subCategories.some((cat) => !cat.selected)}
            onClick={() => updateAllSubCategories(true)}
          >
            {t('legendBox.resetChoices')}
          </Button>
        </>
      ) : (
        ''
      )}
    </LegendBoxContainer>
  )
}

LegendBox.propTypes = {
  className: PropTypes.string,
  /** For enabling the parentIsDarker mode */
  parentIsDarker: PropTypes.bool,
  /** Label text */
  label: PropTypes.string.isRequired,
  /** ImageUrl text */
  labelImage: PropTypes.string,
  /**
   * label that is shown within the context of the main label.
   */
  subLabel: PropTypes.string,
  /**
   * Displays a list of subCategories of which the item displayed in the LegendBox is comprised.
   * Each item is selectable too.
   *
   * Changes are communicated through onSelectSubCategories prop.
   */
  subCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    }),
  ),
  /**
   * An array mirroring subCategories is passed to the function.
   */
  onSelectSubCategories: PropTypes.func,
  /** Value of data */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
  ]),
  /** To communicate if a value is still being loaded */
  valueIsLoading: PropTypes.bool,
  /** Unit of the data */
  unit: PropTypes.string,
  /**
   * Will show a additional value alongside the main value,
   * with the goal to enhance the main value's meaning or
   * explain it in more detail.
   */
  optionalValue: PropTypes.string,
  /** The color of the top border */
  legendColor: PropTypes.string,
  legendBackgroundColor: PropTypes.string,
  selected: PropTypes.bool,
  /**
   * When set is shows a LabeledCheckbox, which allows the
   * LegendBox control to the visibility of other content by checking and unchecking it.
   *
   * Needs to be used with `selected`.
   */
  onSelect: PropTypes.func,
  /**
   * When provided, the noValueText will be rendered as an option button.
   */
  noValueOnClick: PropTypes.func,
  /**
   * The text to display when no value is provided.
   */
  noValueText: PropTypes.string,
}

LegendBox.defaultProps = {
  className: undefined,
  unit: '',
  labelImage: null,
  subLabel: null,
  optionalValue: null,
  value: undefined,
  valueIsLoading: false,
  legendColor: 'actionsStandard',
  legendBackgroundColor: 'isolatedBackground',
  parentIsDarker: false,
  selected: true,
  onSelect: null,
  subCategories: [],
  onSelectSubCategories: null,
  noValueOnClick: null,
  noValueText: null,
}

export default LegendBox
