import * as React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { CarFileStatusEnumUpperCase } from 'config/enums'

import Overlay from 'components/molecules/overlay'
import ChecklistForm from 'components/views/settings/checklists/checklist-form'

const ChecklistOverlay = ({
  locationId,
  status,
  checklistId,
  onAfterSave,
  onClose,
  cluster,
  ...restProps
}) => {
  const { t } = useTranslation()

  return (
    <Overlay
      title={t(
        `checklistsSettings.checklistOverlay.${checklistId ? 'titleEdit' : 'titleAdd'}`,
      )}
      icon="checklist"
      close={() => {
        typeof onClose === 'function' && onClose()
      }}
      {...restProps}
    >
      <ChecklistForm
        locationId={locationId}
        cluster={cluster}
        status={status}
        checklistId={checklistId}
        onAfterSave={onAfterSave}
      />
    </Overlay>
  )
}

ChecklistOverlay.propTypes = {
  locationId: PropTypes.string,
  cluster: PropTypes.object,
  status: PropTypes.oneOf(Object.values(CarFileStatusEnumUpperCase)).isRequired, // Required to show the right type of mileage.
  checklistId: PropTypes.string,
  onAfterSave: PropTypes.func,
  onClose: PropTypes.func,
}

ChecklistOverlay.defaultProps = {
  cluster: null,
  locationId: null,
}
export default ChecklistOverlay
