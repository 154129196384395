import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const Layout = styled.section`
  ${({ theme, $hasRestrictedWidth }) =>
    $hasRestrictedWidth && `max-width: ${theme.widths.maxFieldWidth};`}
  display: grid;
  gap: ${({ theme }) => theme.sizings.lvl4};
  grid-template-rows: auto;
`

/**
 * The vertical layout displays blocks of content, evenly
 * spaced in a simple vertical way.
 *
 * Optionally it's with can be restricted. This width is based
 * on the max field width.
 *
 * @todo: Add examples in documentation. Used on the
 * CalculationForm (hasRestrictedWidth=true) and BidsForm (hasRestrictedWidth=false) in
 * the taxation flow.
 */
const VerticalLayout = ({ className, children, hasRestrictedWidth }) => (
  <Layout $hasRestrictedWidth={hasRestrictedWidth} className={className}>
    {children}
  </Layout>
)

VerticalLayout.propTypes = {
  children: PropTypes.node,
  hasRestrictedWidth: PropTypes.bool,
  className: PropTypes.string,
}

VerticalLayout.defaultProps = {
  children: '',
  className: '',
  hasRestrictedWidth: false,
}

export default VerticalLayout
