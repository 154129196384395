import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { verifyAuthentication as verifyAuthenticationAction } from 'redux/actions/auth'
import { marketViewRoutes, cspRoutes } from 'config/data'
import LoadingIndicator from '../atoms/loading-indicator'

// TODO Rewrite this as a functional component
class PrivateRoute extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      verifying: true,
    }
  }

  componentDidMount() {
    const { verifyAuthentication } = this.props

    verifyAuthentication()
  }

  componentDidUpdate(prevProps) {
    const {
      auth: { verifyAuthStatus },
    } = this.props

    this.getDoneVerifying(verifyAuthStatus, prevProps.auth.verifyAuthStatus)
  }

  getDoneVerifying(verifyAuthStatus, prevVerifyAuthStatus) {
    if (verifyAuthStatus !== 'REQUEST' && prevVerifyAuthStatus === 'REQUEST') {
      this.setState({
        verifying: false,
      })
    }
  }

  render() {
    const { auth, component: Component, ...rest } = this.props
    const { verifying } = this.state
    const { userDetails, verifyAuthStatus } = auth

    const unauthorized = !verifying && verifyAuthStatus === 'FAILURE'
    const marktetViewRightsOnly =
      userDetails &&
      userDetails.marketview &&
      userDetails.pakket === false &&
      userDetails.csp === false
    const cspRightsOnly =
      userDetails &&
      userDetails.csp &&
      userDetails.pakket === false &&
      userDetails.marketview === false
    const allButUccRights =
      userDetails &&
      userDetails.pakket === false &&
      userDetails.marketview &&
      userDetails.csp
    const getIsMarketViewRoute = (props) =>
      props.location.pathname.match(`${marketViewRoutes.join('|')}`)
    const isCspRoute = (props) =>
      props.location.pathname.match(`${cspRoutes.join('|')}`)

    return (
      <Route
        {...rest}
        render={(props) => {
          if (unauthorized) {
            return (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location },
                }}
              />
            )
          }
          if (
            allButUccRights &&
            !getIsMarketViewRoute(props) &&
            !isCspRoute(props)
          ) {
            return (
              <Redirect
                to={{
                  pathname: '/car-service-plan',
                  state: { from: props.location },
                }}
              />
            )
          }
          if (marktetViewRightsOnly && !getIsMarketViewRoute(props)) {
            return (
              <Redirect
                to={{
                  pathname: '/marketview',
                  state: { from: props.location },
                }}
              />
            )
          }
          // only redirect when URL is not a CSP route:
          if (cspRightsOnly && !isCspRoute(props)) {
            return (
              <Redirect
                to={{
                  pathname: '/car-service-plan',
                  state: { from: props.location },
                }}
              />
            )
          }
          if (verifying) {
            return <LoadingIndicator />
          }
          return <Component {...rest} {...props} />
        }}
      />
    )
  }
}

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  verifyAuthentication: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  const { auth } = state

  return {
    auth,
  }
}

const mapDispatchToProps = {
  verifyAuthentication: verifyAuthenticationAction,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrivateRoute),
)
