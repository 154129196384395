import Checkbox from 'components/atoms/checkbox'
import CarfileCard from 'components/molecules/carfile-card'
import CarFileCardForList, {
  LayoutSizeTypes,
} from 'components/molecules/carfile-card-for-list'
import Paging from 'components/molecules/paging'
import { DISPLAY_MODE_DEFAULT } from 'config/consts'
import { DisplayModeEnum } from 'config/enums'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components/macro'
import { useMediaLayout } from 'use-media'
import { media } from 'utilities/styled'

const InnerContainer = styled.div`
  ${({ $displayMode, $layoutSizeType, theme }) =>
    $displayMode === 'grid'
      ? `
      display: grid;
      grid-gap: ${theme.sizings.lvl3};
    `
      : `
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    row-gap: ${$layoutSizeType === LayoutSizeTypes.SMALL ? theme.sizings.lvl1 : theme.sizings.lvl2};
  `}

  grid-template-columns: 1fr;

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `};

  ${media.tv`
    grid-template-columns: 1fr 1fr 1fr;
  `};

  ${media.hd`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`

const StyledPaging = styled(Paging)`
  margin: 40px auto;
`

function StockItems({
  items,
  itemsPerPage,
  onChangePage,
  onUpdate,
  page,
  status,
  salesView,
  hideSummary,
  totalItems,
  linkGenerator,
  displayMode,
  ...restProps
}) {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const isTvSize = useMediaLayout({ minWidth: theme.metrics.tv })
  const layoutSizeType = isTvSize
    ? LayoutSizeTypes.LARGE
    : LayoutSizeTypes.SMALL

  return (
    <div>
      <InnerContainer
        {...restProps}
        $displayMode={displayMode}
        $layoutSizeType={layoutSizeType}
      >
        {items.map((item, idx) => {
          const link = linkGenerator(item)

          return displayMode === DisplayModeEnum.grid ? (
            <CarfileCard
              key={idx.toString()}
              location={item.bedrijfsnaam}
              appraisal={item.taxatiewaarde}
              brand={item.merk}
              carId={String(item.id)}
              createdExternally={Number(item.extern)}
              expected={item.datum_verwacht}
              image={item.image}
              km={item.km || item.rdwkm}
              license={item.kenteken}
              vin={item.chassisnr}
              link={link}
              margin={item.marge}
              reportingCode={item.meldcode}
              model={item.model}
              month={item.maanddeel1 ? String(item.maanddeel1) : undefined}
              owner={item.eigenaar}
              part1={item.deel1}
              part2={item.deel2}
              salesView={salesView}
              hideSummary={hideSummary || !!item.is_carshare}
              sellingPrice={item.verkoopprijs}
              status={item.status || status}
              staytime={Number(item.sta)}
              type={item.type}
              year={item.jaardeel1}
              publishedOnline={Boolean(Number(item.verzonden))}
              cleaningOrderIsIssued={!!item.poets && item.poets !== 'geen'}
              hasRemarks={Boolean(Number(item.boodschap))}
              isCarShare={!!item.is_carshare}
              carShareBids={item?.carshare_bids}
              displayMode={displayMode}
              actions={
                onUpdate && (
                  <>
                    <Checkbox
                      name={`dontenrich_${idx}`}
                      onChange={(checked) =>
                        onUpdate({
                          ...item,
                          not_afgeleverd: !!Number(item.not_afgeleverd),
                          not_teverrijken: checked,
                          not_verkocht: !!Number(item.not_verkocht),
                        })
                      }
                      title={t('dontEnrich')}
                      checked={!!Number(item.not_teverrijken)}
                    />
                    <Checkbox
                      name={`notdelivered_${idx}`}
                      onChange={(checked) =>
                        onUpdate({
                          ...item,
                          // Unchanged 'booleans' need to be casted to an actual
                          // boolean otherwise they will be reset by the backend.
                          not_afgeleverd: checked,
                          not_teverrijken: !!Number(item.not_teverrijken),
                          not_verkocht: !!Number(item.not_verkocht),
                        })
                      }
                      title={t('notDelivered')}
                      checked={!!Number(item.not_afgeleverd)}
                    />
                  </>
                )
              }
              dealerId={item.vestiging_id}
              data-test-e2e="car-file-card"
            />
          ) : (
            <CarFileCardForList
              key={idx.toString()}
              location={item.bedrijfsnaam}
              appraisal={item.taxatiewaarde}
              brand={item.merk}
              carId={String(item.id)}
              createdExternally={Number(item.extern)}
              expected={item.datum_verwacht}
              image={item.image}
              km={item.km || item.rdwkm}
              license={item.kenteken}
              vin={item.chassisnr}
              link={link}
              margin={item.marge}
              reportingCode={item.meldcode}
              model={item.model}
              month={item.maanddeel1 ? String(item.maanddeel1) : undefined}
              owner={item.eigenaar}
              part1={item.deel1}
              part2={item.deel2}
              salesView={salesView}
              hideSummary={hideSummary || !!item.is_carshare}
              sellingPrice={item.verkoopprijs}
              status={item.status || status}
              staytime={Number(item.sta)}
              type={item.type}
              year={item.jaardeel1}
              publishedOnline={Boolean(Number(item.verzonden))}
              cleaningOrderIsIssued={item.poets && item.poets !== 'geen'}
              hasRemarks={Boolean(Number(item.boodschap))}
              isCarShare={!!item.is_carshare}
              carShareBids={item?.carshare_bids}
              displayMode={displayMode}
              layoutSizeType={layoutSizeType}
              actions={
                onUpdate && (
                  <>
                    <Checkbox
                      name={`dontenrich_${idx}`}
                      onChange={(checked) =>
                        onUpdate({
                          ...item,
                          not_afgeleverd: !!Number(item.not_afgeleverd),
                          not_teverrijken: checked,
                          not_verkocht: !!Number(item.not_verkocht),
                        })
                      }
                      title={t('dontEnrich')}
                      checked={!!Number(item.not_teverrijken)}
                    />
                    <Checkbox
                      name={`notdelivered_${idx}`}
                      onChange={(checked) =>
                        onUpdate({
                          ...item,
                          // Unchanged 'booleans' need to be casted to an actual
                          // boolean otherwise they will be reset by the backend.
                          not_afgeleverd: checked,
                          not_teverrijken: !!Number(item.not_teverrijken),
                          not_verkocht: !!Number(item.not_verkocht),
                        })
                      }
                      title={t('notDelivered')}
                      checked={!!Number(item.not_afgeleverd)}
                    />
                  </>
                )
              }
              dealerId={item.vestiging_id}
              data-test-e2e="car-file-card-for-list"
            />
          )
        })}
      </InnerContainer>
      {totalItems > itemsPerPage && (
        <StyledPaging
          activePage={page}
          onChangePage={onChangePage}
          perPage={itemsPerPage}
          total={totalItems}
          data-test-e2e="paging"
        />
      )}
    </div>
  )
}

StockItems.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  page: PropTypes.number.isRequired,
  status: PropTypes.string,
  salesView: PropTypes.bool,
  /**
   * optionally hide the summary block, on the items,
   * containing pricing info and standing/stay-time
   */
  hideSummary: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  /**
   * Generates the link for the item to where it should lead.
   */
  linkGenerator: PropTypes.func.isRequired,
  displayMode: PropTypes.oneOf(Object.values(DisplayModeEnum)).isRequired,
}

StockItems.defaultProps = {
  items: null,
  status: undefined,
  onUpdate: null,
  salesView: false,
  hideSummary: false,
  displayMode: DISPLAY_MODE_DEFAULT,
}

export default StockItems
