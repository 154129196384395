import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'

const StyledCard = styled(Card)`
  background-color: ${({ theme, color }) => theme.colors[color]};
  border-radius: 0;
  box-shadow: ${({ theme, shadow }) =>
    shadow ? theme.shadows[shadow] : 'none'};
`
const StyledCardContent = styled(CardContent)`
  padding: ${({ theme, $paddingLevel }) =>
    $paddingLevel === 0 ? 0 : theme.sizings[`lvl${$paddingLevel}`]};
  &:last-child {
    padding-bottom: ${({ theme, $paddingLevel }) =>
      $paddingLevel === 0 ? 0 : theme.sizings[`lvl${$paddingLevel}`]};
  }
`
const StyledCardActions = styled(CardActions)`
  padding: ${({ theme, $paddingLevel }) =>
    $paddingLevel === 0
      ? 0
      : `0 ${theme.sizings[`lvl${$paddingLevel}`]} ${theme.sizings[`lvl${$paddingLevel}`]} ${theme.sizings[`lvl${$paddingLevel}`]}`};
`

function EnhancedCard({
  shadow,
  children,
  paddingLevel,
  className,
  footer,
  media,
  ...restProps
}) {
  return (
    <StyledCard
      className={`${className} ${shadow && 'shadow'}`}
      shadow={shadow}
      {...restProps}
    >
      {media && <CardMedia>{media}</CardMedia>}

      {children && (
        <StyledCardContent $paddingLevel={paddingLevel}>
          {children}
        </StyledCardContent>
      )}

      {footer && (
        <StyledCardActions $paddingLevel={paddingLevel}>
          {footer}
        </StyledCardActions>
      )}
    </StyledCard>
  )
}

EnhancedCard.propTypes = {
  /** Shadow colour string eg bottom */
  shadow: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Padding level refers to the sizings in theme.js */
  paddingLevel: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  className: PropTypes.string,
  footer: PropTypes.node,
  /** Theme colour string eg brandGolf */
  color: PropTypes.string,
  /** Card header has no padding. Can be string or node */
  media: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

EnhancedCard.defaultProps = {
  children: undefined,
  shadow: 'bottom',
  paddingLevel: 3,
  className: null,
  color: 'defaultBackground',
  footer: undefined,
  media: undefined,
}

/**
 * @component
 */
export default EnhancedCard
