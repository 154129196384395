import { gql } from '@apollo/client'
import warrantyProviderFragment from '../fragments/warrantyProvider'
import dealerFragment from '../fragments/dealer'

export const GET_DEALERS = gql`
  query getDealers {
    dealers(first: 9999) {
      edges {
        node {
          ...dealerInfo
        }
      }
    }
  }
  ${dealerFragment}
`

export const GET_DEALERS_WITH_CSP = gql`
  query getDealers {
    dealers(first: 100) {
      edges {
        node {
          ...dealerInfo
          #email
          carServicePlan {
            id
            isDisabled
            #lastmodified
          }
        }
      }
    }
  }
  ${dealerFragment}
`

export const GET_DEALER_QUERY = gql`
  query getDealer($id: ID) {
    dealer(id: $id) {
      name
      carServicePlan {
        id
        mechanicCarRate
        invoiceFee
        isDisabled
        packages {
          id
          packageType
          name
          description
        }
        usedWarrantyPrograms {
          id
          warrantyProgram {
            id
            name
            ownRiskPeriod
            brands {
              name
            }
          }
          warrantyProvider {
            name
          }
        }
        usedWarrantyProviders {
          id
          dealerCode
          warrantyProvider {
            ...warrantyProviderInfo
          }
          extraSettings {
            key
            value
          }
        }
      }
    }
  }
  ${warrantyProviderFragment}
`

export const CSP_FOR_DEALER = gql`
  query getDealer($id: ID) {
    dealer(id: $id) {
      carServicePlan {
        id
        mechanicCarRate
        invoiceFee
        isDisabled
        packages {
          id
          packageType
          name
          description
          disabled
        }
        usedWarrantyPrograms {
          warrantyProgram {
            documentationUri
            documentationFullUri
            name
            warrantyProvider {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const UPDATE_DEALER_CODE = gql`
  mutation UpdateDealerCode(
    $linkedWarrantyProviderId: ID!
    $dealerCode: String!
    $extraSettings: [KeyValueInput]
  ) {
    updateLinkedWarrantyProvider(
      id: $linkedWarrantyProviderId
      dealerCode: $dealerCode
      extraSettings: $extraSettings
    ) {
      id
      dealerCode
      extraSettings {
        key
        value
      }
    }
  }
`

export const DELETE_DEALER = gql`
  mutation deleteDealer($id: ID!) {
    deleteDealer(id: $id) {
      id
    }
  }
`
