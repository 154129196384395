import { connect } from 'react-redux'
import { getSearchResults } from 'redux/actions/data'
import { toggleSalesView } from 'redux/actions/ui'
import SearchResultsView from '../views/search-results-view'

const mapStateToProps = ({ data, ui }) => ({
  salesView: ui.salesView,
  searchResults: data.searchResults,
})

const mapDispatchToProps = {
  getSearchResults,
  toggleSalesView,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsView)
