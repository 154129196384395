import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as routes from 'config/routes'
import {
  CarFileStatusEnumLowerCase,
  CarFileStatusEnumUpperCase,
} from 'config/enums'

import { media } from 'utilities/styled'
import { objectToQueryString } from 'utilities/utils'

import LoadingIndicator from 'components/atoms/loading-indicator'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import BarChart from 'components/atoms/bar-chart'
import DropdownMenu from 'components/molecules/new-dropdown-select'

function getDestinationItems(t) {
  const destinationItems = [
    {
      label: t('all'),
      value: 'alles',
    },
    {
      label: t('private'),
      value: 'particulier',
    },
    {
      label: t('trade'),
      value: 'handel',
    },
    {
      label: t('demo'),
      value: 'demo',
    },
    {
      label: t('rental'),
      value: 'huur',
    },
    {
      label: t('new'),
      value: 'nieuw',
    },
    {
      label: t('import'),
      value: 'import',
    },
    {
      label: t('oldtimer'),
      value: 'oldtimer',
    },
    {
      label: t('damagedcar'),
      value: 'schadeauto',
    },
  ]

  return destinationItems
}

const Container = styled.div`
  height: 100%;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  justify-content: space-between;

  ${media.tablet`
    flex-direction: row;
  `}
`

const HeaderTitleContainer = styled.div`
  align-items: center;
  display: flex;

  & > *:first-child {
    margin-right: 8px;
  }
`

const HeaderTitle = styled(Text)`
  text-transform: capitalize;
`

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 50%;
  margin: ${({ theme }) => theme.sizings.lvl1} 0;

  ${media.tablet`
    margin-top: 0;
    margin-bottom: 0;
  `}
`

const FooterContainer = styled.div`
  display: flex;
  margin-top: 8px;

  & > *:first-child {
    margin-right: 0.5em;
  }
`

function StayTime({
  className,
  getStayTime,
  history,
  selectedLocation,
  stayTime,
}) {
  const { t } = useTranslation()
  const destinationItems = getDestinationItems(t)
  const [destination, setDestination] = useState(destinationItems[0].value)

  useEffect(() => {
    getStayTime(selectedLocation, destination)
  }, [destination, getStayTime, selectedLocation])

  if (!stayTime || stayTime.loading) {
    return <LoadingIndicator />
  }

  const meanDays = stayTime?.data?.chart?.averageValue // TODO: BED needs to implement this (https://app.clickup.com/t/2501750/UCC-6685)
  const data = stayTime?.data?.series

  function handleBarClick(barName) {
    const filterStockDays = {
      '0-30': 1,
      '31-60': 2,
      '61-90': 3,
      '91-120': 4,
      '>120': 5,
    }[barName]
    const params = {
      status: CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.binnen],
      filter_stockdays: filterStockDays,
    }

    if (destination !== 'alles') {
      params.soort = destination
    }

    const queryString = objectToQueryString(params)

    history.push(`${routes.STOCK}?${queryString}`)
  }

  return (
    <Container className={className}>
      <Header>
        <HeaderTitleContainer>
          <Icon type="input" color="brandAlfa" height="28" />
          <HeaderTitle text={t('stayDays')} type="h3" />
        </HeaderTitleContainer>
        <StyledDropdownMenu
          items={destinationItems}
          onChange={setDestination}
          label={t('destination')}
          value={destination}
          selectionRequired
        />
      </Header>
      <BarChart data={data} onBarClick={handleBarClick} />
      {!!meanDays && (
        <FooterContainer>
          <Text text={t('mean')} />
          <Text text={`${meanDays} ${t('stayDays')}`} type="input" />
        </FooterContainer>
      )}
    </Container>
  )
}

StayTime.propTypes = {
  className: PropTypes.string,
  getStayTime: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  selectedLocation: PropTypes.string,
  stayTime: PropTypes.object,
}

StayTime.defaultProps = {
  className: null,
  selectedLocation: null,
  stayTime: null,
}

export default withRouter(StayTime)
