import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { getNapPillLevel, getNapPillText } from 'utilities/utils'
import Button from 'components/atoms/button'
import Pill from 'components/atoms/pill'

const Container = styled.div`
  min-width: ${({ theme }) => theme.sizings.lvl8};
  text-align: center;
`

const StyledButton = styled(Button)`
  display: inline;
  height: auto;
`

/**
 * Check and display NAP for a given license plate and mileage combination.
 *

 */
function NapCheck({
  className,
  getNap,
  hideExplanation,
  licensePlate,
  mileage,
  nap,
}) {
  const { t } = useTranslation()
  const napValue = nap && nap.data && nap.data.nap
  const text = hideExplanation ? 'NAP' : `NAP ${getNapPillText(napValue)}`

  return (
    <Container className={className}>
      {typeof napValue !== 'undefined' ? (
        nap &&
        !nap.loading && <Pill text={text} level={getNapPillLevel(napValue)} />
      ) : (
        <StyledButton
          level="option"
          text={t('NAP')}
          onClick={() => getNap({ mileage, licensePlate })}
        />
      )}
    </Container>
  )
}

NapCheck.propTypes = {
  className: PropTypes.string,
  getNap: PropTypes.func.isRequired,
  hideExplanation: PropTypes.bool,
  licensePlate: PropTypes.string,
  mileage: PropTypes.number,
  nap: PropTypes.object,
}

NapCheck.defaultProps = {
  className: undefined,
  hideExplanation: false,
  licensePlate: 'XX-XX-XX',
  mileage: null,
  nap: undefined,
}

export default NapCheck
