import { connect } from 'react-redux'
import {
  getAccessories,
  getAllAccessories,
  setAccessoriesHighlight,
} from 'redux/actions/data'
import EquipmentForm from '../molecules/equipment-form'

const mapStateToProps = ({ data }) => ({
  genericAccessories:
    data.accessories &&
    data.accessories.data &&
    data.accessories.data.accessoires,
  allAccessories: data.allAccessories,
  accessoriesHighlights: data.accessoriesHighlights,
  maxNumberOfHighlights: data.carfile?.data?.accessoires_highlight_limit,
})

const mapDispatchToProps = {
  getAccessories,
  getAllAccessories,
  setAccessoriesHighlight,
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentForm)
