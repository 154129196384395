import { useMutation, useQuery } from '@apollo/client'
import LoadingIndicator from 'components/atoms/loading-indicator'
import NewTextInput from 'components/atoms/new-text-input'
import Typography from 'components/molecules/typography'
import {
  APP_TOKENS,
  CREATE_APP_TOKEN,
} from 'config/graphql/csp/queries/appTokens'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * Shows the code snippet for the dealer site widget
 */

const createCodeSnippet = (token, dealerId) => `<!--

You can add or modify the following attributes to customize the widget:

 • data-width\t\t\t\t| Sets the custom width of the widget\t\t\t\t\t| defaults to 300 | optional |
 • data-height\t\t\t\t| Sets the custom height of the widget\t\t\t\t| defaults to 350 | optional |
 • data-target-url-action-type\t| Sets the type of redirect after a succesful submit\t\t| defaults to null | optional | required when "data-target-url" has a value
 • data-target-url\t\t\t| Sets the target URL to redirect to after succesful submit\t| defaults to null | optional | required when "data-target-url-action-type" is "background" or "redirect"

If you would like to redirect the user to a different website after a succesfull submit, add the following two data attributes:

 • data-target-url="https://website.com?utm_source=website&utm_medium=csp_widget"
 • data-target-url-action-type="redirect"

The two options for "data-target-url-action-type" are: 

 • "redirect"\t | a redirect triggered by navigating to a link (with JavaScript). The user will "hard" navigate to the data-target-url.
 • "background"\t | a redirect triggered in a hidden iframe. The user will stay in the widget, in the background an iframe will trigger.

After redirect, we will pass car data and previously set search parameters to the redirect url. In the above example, the final redirect URL will look like this:

• https://website.com/?utm_source=website&utm_medium=csp_widget&mileage=123+km&registrationYear=2020-02-06+00%3A00%3A00&licensePlate=H401HH&carBrand=Volkswagen&carModel=Caddy&yearlySavings=%E2%82%AC+35%2C04&monthlyPriceNetto=%E2%82%AC+19%2C34&monthlyPriceGross=%E2%82%AC+22%2C26

Car data we merge to the redirect URL is:

• mileage\t\t\t| 123 km
• registrationYear\t| 2020-02-06 00:00:00
• licensePlate\t\t| H401HH
• carBrand\t\t| Volkswagen
• carModel\t\t| Caddy
• yearlySavings\t\t| € 35,04
• monthlyPriceNetto\t| € 19,34
• monthlyPriceGross\t| € 22,26
-->

<script
  data-height=""
  data-width=""
  data-target-url=""
  data-target-url-action-type=""
  async
  data-api-key="${token}"
  data-dealer-id="${dealerId}"
  id="ucc-csp-widget-script-${dealerId}"
  src="${process.env.REACT_APP_WIDGETS_HOSTNAME}/js/csp-widget-loader.js"
></script>
<div style="display:none" id="ucc-csp-widget-placeholder-${dealerId}"></div>
`

/**
 * Used to pad the textarea showing the snippet, because deducing simply based on
 * newline characters is not sufficient to not show a scroll bar.
 */
const snippetPaddingRows = 4

function DealerSiteWidget({ dealerId }) {
  const [generatedCodeSnippet, setGeneratedCodeSnippet] = useState(null)
  const { t } = useTranslation()

  const {
    data: appTokensData,
    loading: loadingAppTokens,
    error: errorAppTokens,
  } = useQuery(APP_TOKENS, {
    variables: {
      dealerId,
    },
  })

  const [createAppToken] = useMutation(CREATE_APP_TOKEN, {
    variables: {
      dealerId,
    },
    onCompleted: (data) => {
      setGeneratedCodeSnippet(
        createCodeSnippet(data.createAppToken.token, dealerId),
      )
    },
  })

  const loadingData = loadingAppTokens
  const error = errorAppTokens

  useEffect(() => {
    if (
      !generatedCodeSnippet &&
      !loadingAppTokens &&
      Array.isArray(appTokensData?.appTokens)
    ) {
      if (appTokensData.appTokens.length === 0) {
        createAppToken()
      } else {
        setGeneratedCodeSnippet(
          createCodeSnippet(appTokensData.appTokens[0].token, dealerId),
        )
      }
    }
  }, [
    appTokensData,
    createAppToken,
    loadingAppTokens,
    generatedCodeSnippet,
    dealerId,
  ])

  return (
    <>
      {loadingData ? (
        <LoadingIndicator error={error} />
      ) : (
        typeof generatedCodeSnippet === 'string' && (
          <>
            <Typography type="ExplanationParagraph">
              {t(
                'carServicePlanDealerSiteWidgetTab.dealerSpecific.explanation',
              )}
            </Typography>
            <NewTextInput
              filled
              multiline
              value={generatedCodeSnippet}
              params={{
                inputProps: {
                  wrap: 'off',
                },
              }}
              rows={
                Array.from(generatedCodeSnippet.matchAll(/\n/g)).length +
                snippetPaddingRows
              }
            />
          </>
        )
      )}
    </>
  )
}

DealerSiteWidget.propTypes = {
  dealerId: PropTypes.string.isRequired,
}

export default DealerSiteWidget
