import React from 'react'
import PropTypes from 'prop-types'
import LegendBoxes from 'components/molecules/legend-boxes'
import Text from 'components/atoms/text'
import ValueComparisonChart from 'components/molecules/value-comparison-chart'
import styled from 'styled-components/macro'

const Container = styled.article`
  width: 100%;
  display: grid;
  grid-gap: ${({ theme }) => theme.sizings.lvl2};
  grid-template-columns: 1fr 240px;
  /* enough space for heading text in header area: */
  grid-template-rows: ${({ theme }) => theme.baseSize * 5} 1fr;
  grid-template-areas:
    'header header'
    'chart legends';
`

const StyledValueComparisonChart = styled(ValueComparisonChart)`
  grid-area: chart;
`
const StyledAside = styled.aside`
  grid-area: legends;
`

const StyledHeading = styled(Text)`
  grid-area: header;
`

const ExtraFieldsLegendBoxes = styled(LegendBoxes)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

/**
 * A Generic Chart to compare two
 */
const CompareTopicsChart = ({ heading, itemsToCompare, extraFields, unit }) => (
  <Container>
    <StyledHeading type="h2" text={heading} />
    <StyledValueComparisonChart itemsToCompare={itemsToCompare} unit={unit} />
    <StyledAside>
      {!!(extraFields && extraFields.length) && (
        <ExtraFieldsLegendBoxes
          items={extraFields}
          unit={extraFields[0].unit}
        />
      )}
      <LegendBoxes items={itemsToCompare} unit={unit} />
    </StyledAside>
  </Container>
)
CompareTopicsChart.propTypes = {
  /**
   * Displayed above the chart
   */
  heading: PropTypes.string.isRequired,
  /**
   * An array of items you want to display in the chart
   */
  itemsToCompare: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    }),
  ).isRequired,
  unit: PropTypes.string,
  /**
   * An array of fields that do calculation
   * that are not shown directly in the chart
   */
  extraFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      unit: PropTypes.string,
      fieldAction: PropTypes.func.isRequired,
    }),
  ),
}

CompareTopicsChart.defaultProps = {
  unit: '',
  extraFields: null,
}

export default CompareTopicsChart
