import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'
import CSPSalesFlow from '../views/car-service-plan/csp-sales'

const mapStateToProps = ({ auth }) => ({
  userDetails: auth.userDetails,
})

export default withRouter(connect(mapStateToProps)(CSPSalesFlow))
