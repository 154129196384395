/*!
 * numbro.js language configuration
 * language : German
 * locale: Germany
 * author : Marco Krage : https://github.com/sinky
 *
 * Generally useful in Germany, Austria, Luxembourg, Belgium
 */

export default {
  languageTag: 'de',
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'Mi',
    billion: 'Ma',
    trillion: 'Bi',
  },
  ordinal: function () {
    return '.'
  },
  currency: {
    symbol: '€',
    position: 'postfix',
    code: 'EUR',
  },
  currencyFormat: {
    totalLength: 4,
    thousandSeparated: true,
  },
}
