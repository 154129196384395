import React from 'react'
import PropTypes from 'prop-types'
import DropDownMenu from 'components/atoms/dropdown-menu'

/**
 * Allows a user to select a filter option from a list of prefined filter options
 * in order to filter the results in a related overview.
 */
function DropDownFilter({ filterOptions, onFilterUpdate, selectedFilter }) {
  const items = filterOptions.map((filter) => ({
    label: filter.label,
    value: filter.id,
  }))
  return (
    <>
      <DropDownMenu
        items={items}
        onChange={onFilterUpdate}
        selected={selectedFilter}
      />
    </>
  )
}

DropDownFilter.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  selectedFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

DropDownFilter.defaultProps = {
  selectedFilter: '',
}

export default DropDownFilter
