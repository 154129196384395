import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useMediaLayout } from 'use-media'
import 'react-dates/initialize'
import { SingleDatePicker } from 'react-dates'
import InputLabel from '@material-ui/core/InputLabel'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import styled, { ThemeContext } from 'styled-components'
import MonthYearPicker from 'components/atoms/month-year-picker'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  width: 100%;

  .DateInput_input {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.brandAlfa};
    margin-bottom: ${({ theme }) => theme.sizings.lvl1};
    text-align: left;
  }

  > label {
    color: ${({ theme }) => theme.colors.inactiveTextColor};
  }

  ${({ isInlined }) =>
    isInlined &&
    `
    display: block;
    > * {
      display: inline-block;
      vertical-align: baseline;
    }
  `}
`

const constants = {
  START_DATE: 'START_DATE',
  END_DATE: 'END_DATE',
}

/**
 * DateRangePicker is built using two pre-configured version's of AirBnB's date picker.
 *

 */
function DateRangePicker({
  className,
  onChange,
  startDate,
  endDate,
  isInlined,
}) {
  const { t } = useTranslation()
  const parsedStartDate = startDate && moment(startDate)
  const parsedEndDate = endDate && moment(endDate)
  const [internalStartDate, setInternalStartDate] = React.useState(null)
  const [internalEndDate, setInternalEndDate] = React.useState(null)
  // keep track of which one of the two inputs has the focus:
  // will store either START_DATE or END_DATE
  const [focusedInput, setFocusedInput] = React.useState()

  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })

  function handleChanges({ newStartDate, newEndDate }) {
    if (newStartDate !== parsedStartDate || newEndDate !== parsedEndDate) {
      const currentStartDate =
        newStartDate || internalStartDate || parsedStartDate
      const currentEndDate = newEndDate || internalEndDate || parsedEndDate
      if (currentStartDate < currentEndDate) {
        onChange({
          startDate: currentStartDate,
          endDate: currentEndDate,
        })
        setInternalStartDate(null)
        setInternalEndDate(null)
      } else if (newStartDate) {
        setInternalStartDate(newStartDate)
      } else if (newEndDate) {
        setInternalEndDate(newEndDate)
      }
    }
  }

  return (
    <Container className={className} isInlined={isInlined}>
      <InputLabel shrink>{t('from')}:</InputLabel>
      <SingleDatePicker
        date={internalStartDate || parsedStartDate}
        displayFormat="DD-MM-YYYY"
        onDateChange={(newStartDate) => {
          handleChanges({ newStartDate })
        }}
        onFocusChange={(values) =>
          setFocusedInput(values.focused ? constants.START_DATE : null)
        }
        focused={focusedInput === constants.START_DATE}
        withPortal={isMobile}
        renderMonthElement={MonthYearPicker}
        numberOfMonths={1}
        noBorder
        readOnly
        small
        isOutsideRange={() => false}
      />
      <InputLabel shrink>{t('till')}:</InputLabel>
      <SingleDatePicker
        date={internalEndDate || parsedEndDate}
        displayFormat="DD-MM-YYYY"
        numberOfMonths={1}
        onDateChange={(newEndDate) => {
          handleChanges({ newEndDate })
        }}
        onFocusChange={(values) =>
          setFocusedInput(values.focused ? constants.END_DATE : null)
        }
        focused={focusedInput === constants.END_DATE}
        withPortal={isMobile}
        renderMonthElement={MonthYearPicker}
        noBorder
        readOnly
        small
        isOutsideRange={() => false}
      />
    </Container>
  )
}

DateRangePicker.propTypes = {
  className: PropTypes.string,
  /* @param {Object} dateRange object with the props startDate and endDate */
  onChange: PropTypes.func,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  isInlined: PropTypes.bool,
}

DateRangePicker.defaultProps = {
  className: null,
  startDate: null,
  endDate: null,
  onChange: () => {},
  isInlined: false,
}

export default DateRangePicker
