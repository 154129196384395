import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useMediaLayout } from 'use-media'
import styled, { ThemeContext } from 'styled-components/macro'
import TextualDataCards from 'components/molecules/textual-data-cards'

const Container = styled.div`
  overflow-y: auto;
`

const StyledTable = styled.table`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.brandDelta};
`

const StyledTr = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.brandDelta};
`

const StyledTd = styled.td`
  min-height: ${({ theme }) => theme.sizings.lvl4};
  line-height: ${({ theme }) => theme.sizings.lvl4};
  padding: ${({ theme }) => `${theme.sizings.lvl1} ${theme.sizings.lvl2}`};
  text-align: left;

  &:first-child {
    padding-left: ${({ theme }) => theme.sizings.lvl2};
  }
  &:last-child {
    padding-right: ${({ theme }) => theme.sizings.lvl2};
  }

  > * {
    display: block;
  }
`

function Table({ actions, columns, rows, ...restProps }) {
  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })

  if (isMobile) {
    return (
      <TextualDataCards
        actions={actions}
        columns={columns}
        rows={rows}
        {...restProps}
      />
    )
  }

  return (
    <Container {...restProps}>
      <StyledTable>
        <thead>
          <StyledTr>
            {columns.map((column, index) => (
              <StyledTd key={index.toString()}>{column}</StyledTd>
            ))}
            {actions &&
              actions[0] &&
              actions[0].map((action, actionIndex) => (
                <StyledTd key={actionIndex.toString()}>&nbsp;</StyledTd>
              ))}
          </StyledTr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <StyledTr key={index.toString()}>
              {row.map((rowItem, rowIndex) => (
                <StyledTd key={rowIndex.toString()}>{rowItem}</StyledTd>
              ))}
              {actions &&
                actions[index] &&
                actions[index].map((action, actionIndex) => (
                  <StyledTd key={actionIndex.toString()}>{action}</StyledTd>
                ))}
            </StyledTr>
          ))}
        </tbody>
      </StyledTable>
    </Container>
  )
}

Table.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.element).isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.element)).isRequired,
}

Table.defaultProps = {
  actions: null,
}

export default Table
