import { connect } from 'react-redux'
import {
  getB2bTradePlatformFilters,
  getB2bTradePlatformResults,
  getB2bTradePlatformSortOptions,
} from 'redux/actions/data'
import b2bTradePlatform from 'components/views/b2b-trade-platform'

const mapStateToProps = ({ data, ui }) => ({
  salesView: ui.salesView,
  b2bTradePlatformFilters: data.b2bTradePlatformFilters,
  b2bTradePlatformResults: data.b2bTradePlatformResults,
  b2bTradePlatformSortOptions: data.b2bTradePlatformSortOptions,
})

const mapDispatchToProps = {
  getB2bTradePlatformFilters,
  getB2bTradePlatformSortOptions,
  getB2bTradePlatformResults,
}

export default connect(mapStateToProps, mapDispatchToProps)(b2bTradePlatform)
