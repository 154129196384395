import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { media } from 'utilities/styled'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import PageHeader from 'components/organisms/page-header'
import NewsFeed from 'components/organisms/news-feed'
import StayTime from 'components/molecules/stay-time'
import EnhancedCard from 'components/layouts/enhanced-card'
import TodosDashboardOverview from 'components/redux-containers/todos-dashboard-overview-container'
import ChecklistsDashboardWidget from 'components/views/checklists/checklists-dashboard-widget'
import { useSelector } from 'react-redux'
import useSelectedLocationId from 'hooks/use-selected-location-id'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.brandFoxtrot};
  padding: 0 20px;

  ${media.desktop`
    padding: 0 60px;
  `};
`

const Grid = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.sizings.lvl3};
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin-bottom: ${({ theme }) => theme.sizings.lvl4};

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
  `}
`

const TodosContainer = styled(EnhancedCard)`
  grid-column: 1;
  grid-row: 1;

  > div {
    height: 100%; // To make the card contents stretch to the full height of the card
  }
`

const Todos = styled(TodosDashboardOverview)`
  height: 100%;
`

const StayTimeContainer = styled(EnhancedCard)`
  grid-row: 2;
  grid-column: 1;

  ${media.tablet`
    grid-row: 1;
    grid-column: 2
  `}
`

const ChecklistsContainer = styled(EnhancedCard)`
  grid-column: 1;
  grid-row: 3;

  ${media.tablet`
    grid-column: 1 / 3;
    grid-row: 2;
  `}
`

const NewsContainer = styled(EnhancedCard)`
  grid-row: 4;
  grid-column: 1;

  ${media.tablet`
    grid-row: auto;
    grid-column: 1 / 3;
  `}
`

function DashboardView({
  auth,
  getLocations,
  getStayTime,
  locations,
  stayTime,
}) {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const [selectedLocationId, setSelectedLocationId] = useSelectedLocationId()
  const userHasAccessToChecklists = useSelector(
    (state) => state?.auth?.userDetails.flexflow,
  )

  // All locations should be indicated with a location_id of 0 for the todos
  // but the all locations data comes with an id which is an empty string
  // from the backend. Therefore the mapping here.
  const mappedLocations =
    locations &&
    locations.data &&
    locations.data.map((location) => ({
      ...location,
      id: location.id.length ? location.id : '0',
    }))

  useEffect(() => {
    getLocations()
  }, [getLocations])

  // If this component gets new `locations`, and the `selectedLocationId` is not part of that, reset
  // the selection to "All locations"
  useEffect(() => {
    if (locations?.data) {
      const ids = locations.data.map((location) => location.id)

      if (!ids.includes(selectedLocationId)) {
        setSelectedLocationId('0')
      }
    }
  }, [locations, selectedLocationId, setSelectedLocationId])

  return (
    <AppLayoutContainer pageTitle={t('dashboard')}>
      <Container>
        <PageHeader
          locations={mappedLocations}
          selectedLocation={selectedLocationId}
          setSelectedLocation={setSelectedLocationId}
          mainTitle={t('dashboard')}
          subTitle={`${t('welcomeBack')} ${auth.userDetails.firstname}`}
          showSearchbox
        />
        <Grid $userHasAccessToChecklists={userHasAccessToChecklists}>
          <TodosContainer data-test-e2e="todos-dashboard-overview-card">
            <Todos
              locationId={
                selectedLocationId !== '0' ? selectedLocationId : undefined
              }
            />
          </TodosContainer>
          <StayTimeContainer>
            <StayTime
              getStayTime={getStayTime}
              selectedLocation={selectedLocationId}
              stayTime={stayTime}
            />
          </StayTimeContainer>
          {userHasAccessToChecklists && (
            <ChecklistsContainer data-test-e2e="checklists-dashboard-widget-card">
              <ChecklistsDashboardWidget
                locationId={selectedLocationId}
                data-test-e2e="checklists-dashboard-widget"
              />
            </ChecklistsContainer>
          )}
          {theme.showNewsFeed && (
            <NewsContainer>
              <NewsFeed />
            </NewsContainer>
          )}
        </Grid>
      </Container>
    </AppLayoutContainer>
  )
}

DashboardView.propTypes = {
  auth: PropTypes.object.isRequired,
  getLocations: PropTypes.func.isRequired,
  getStayTime: PropTypes.func.isRequired,
  locations: PropTypes.object,
  stayTime: PropTypes.object,
}

DashboardView.defaultProps = {
  locations: null,
  locationTodos: null,
  stayTime: null,
}

export default DashboardView
