import React, { useState, useContext } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import toast from 'utilities/toast'
import { media } from 'utilities/styled'
import { formatMileage } from 'utilities/utils'
import styled from 'styled-components/macro'
import ContentSeparator from 'components/atoms/content-separator'
import FlexibleDialog from 'components/molecules/flexible-dialog'
import DealerNoCSPMessage from 'components/organisms/dealer-no-csp-message'

import { CSPAdminDealerSchema } from 'config/validation-schemas'
import {
  GET_DEALER_QUERY,
  GET_PRICE_MATRICES,
  UPDATE_CSP_AS_ADMIN,
  UPDATE_DEALER_CODE,
  UNLINK_WARRANTY_PROGRAM_CAR_SERVICE_PLAN,
  DELETE_PRICE_MATRIX,
} from 'config/graphql/csp'
import FiltersContainer from 'components/layouts/filters-options-bar'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Button from 'components/atoms/button'
import Typography from 'components/molecules/typography'
import {
  FormikTextInput,
  FormikLabeledCheckBox,
} from 'components/molecules/formik-field'
import InlinedItems from 'components/layouts/inlined-items'
import EnhancedTable from 'components/organisms/enhanced-table'
import Breadcrumbs from 'components/molecules/breadcrumbs'
import BodyTextWrapper from 'components/layouts/body-text-wrapper'
import TextLink from 'components/atoms/text-link'
import { DealerContext } from 'contexts/dealer'

const PostionedButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
`

const ActionsContainer = styled.div`
  display: inline-flex;
  position: relative;
  top: 2px;
  align-items: center;
`

const StyledSeparator = styled(ContentSeparator)`
  height: ${({ theme }) => theme.sizings.lvl3};
`

const LinkProgramLink = styled(TextLink)`
  display: block;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`

const DealerCodesWrapper = styled(BodyTextWrapper)`
  margin-top: ${({ theme }) => theme.sizings.lvl5};
`

const DealerCodesContainer = styled(FiltersContainer)`
  margin-bottom: 0;

  ${media.desktop`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.tv`
    grid-template-columns: repeat(4, 1fr);
  `}
`

const DealerCodeHeading = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const DealerCodeWarrantyProviderHeading = styled(Typography)`
  margin-top: 0;
`

const DealerCodeWarrantyProviderContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizings.lvl4};

  &:first-child {
    margin-top: 0;
  }
`

const FlexibleDialogBody = styled(Typography)`
  margin: 0;
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: ${({ theme }) => theme.sizings.lvl7};
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const InputFieldType = {
  PASSWORD: 'password',
  TEXT: 'text',
}

function CarServicePlanDetailsForDealer() {
  const { dealerId } = useParams()
  const { pathname } = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const [warrantyProgramToUnlink, setWarrantyProgramToUnlink] = useState()
  const [priceMatrixToDelete, setPriceMatrixToDelete] = useState()
  const { warrantyProgramMutating, priceMatrixMutating } =
    useContext(DealerContext)

  const getDealerVariables = {
    id: dealerId,
  }
  const {
    loading: dealerLoading,
    error: dealerError,
    data: dealerData,
  } = useQuery(GET_DEALER_QUERY, {
    variables: getDealerVariables,
  })
  const getPriceMatricesVariables = {
    carServicePlanId: dealerData?.dealer?.carServicePlan?.id || '',
  }
  const refetchQueries = {
    refetchQueries: [
      {
        query: GET_DEALER_QUERY,
        variables: getDealerVariables,
      },
      {
        query: GET_PRICE_MATRICES,
        variables: getPriceMatricesVariables,
      },
    ],
    awaitRefetchQueries: true,
  }
  const {
    loading: priceMatricesLoading,
    error: priceMatricesError,
    data: priceMatricesData,
  } = useQuery(GET_PRICE_MATRICES, {
    variables: getPriceMatricesVariables,
    skip:
      !dealerData || !dealerData.dealer || !dealerData.dealer.carServicePlan,
  })
  const [persistUpdatedCSP] = useMutation(UPDATE_CSP_AS_ADMIN, refetchQueries)
  const [persistUpdatedDealerCode] = useMutation(UPDATE_DEALER_CODE)
  const [unlinkWarrantyProgram, { loading: unlinkingWarrantyProgram }] =
    useMutation(UNLINK_WARRANTY_PROGRAM_CAR_SERVICE_PLAN, refetchQueries)
  const [deletePriceMatrix, { loading: deletingPriceMatrix }] = useMutation(
    DELETE_PRICE_MATRIX,
    refetchQueries,
  )

  if (
    dealerLoading ||
    priceMatricesLoading ||
    warrantyProgramMutating ||
    priceMatrixMutating
  ) {
    return <LoadingIndicator error={null} />
  }
  if (dealerError || priceMatricesError) {
    if (dealerError) {
      console.error(dealerError)
    }
    if (priceMatricesError) {
      console.error(priceMatricesError)
    }
    return (
      <>
        <Typography type="Level1Heading">
          {t('cspAdminDealerDetails.heading')}
        </Typography>
        <Typography type="Level2Heading">
          {t('problemsFoundHeading')}
        </Typography>
        <Typography type="ExplanationParagraph">
          {t('problemsWhenRetrievingData')}
        </Typography>
      </>
    )
  }

  if (dealerData && dealerData.dealer && !dealerData.dealer.carServicePlan) {
    // This dealer has no CSP. Show an error.
    return (
      <>
        <Typography type="Level1Heading">
          {t('CSPAdminDealerDetails.heading')}
        </Typography>
        <DealerNoCSPMessage />
      </>
    )
  }

  const handleUnlinkWarrantyProgram = () => {
    if (!warrantyProgramToUnlink) {
      return
    }

    unlinkWarrantyProgram({
      variables: {
        id: warrantyProgramToUnlink.id,
      },
    })
      .then(() => {
        toast.success(
          t(
            'cspAdminDealerDetails.linkingWarrantyPrograms.warrantyProgramUnlinked',
          ),
        )
        setWarrantyProgramToUnlink()
      })
      .catch((e) => {
        console.error(e)
        toast.error(
          t(
            'cspAdminDealerDetails.linkingWarrantyPrograms.warrantyProgramUnlinkError',
          ),
        )
      })
  }

  const handleDeletePriceMatrix = () => {
    if (!priceMatrixToDelete) {
      return
    }

    deletePriceMatrix({
      variables: {
        id: priceMatrixToDelete.id,
      },
    })
      .then(() => {
        toast.success(
          t('cspAdminDealerDetails.priceMatrices.priceMatrixDeleted'),
        )
        setPriceMatrixToDelete()
      })
      .catch((e) => {
        console.error(e)
        toast.error(
          t('cspAdminDealerDetails.priceMatrices.priceMatrixDeleteError'),
        )
      })
  }

  function onSubmitUpdates(values) {
    const { carServicePlan, warrantyProviders } = values

    const updates = []
    if (carServicePlan) {
      const updateCSP = persistUpdatedCSP({
        variables: {
          id: carServicePlan.id,
          invoiceFee: parseFloat(carServicePlan.invoiceFee),
          isDisabled: carServicePlan.isDisabled,
        },
      })
      updates.push(updateCSP)
    }

    warrantyProviders.forEach((warrantyProvider) => {
      const extraSettings = warrantyProvider.extraSettings
        ? Object.keys(warrantyProvider.extraSettings).map((key) => ({
            key,
            value: warrantyProvider.extraSettings[key],
          }))
        : []
      const updateDealerCode = persistUpdatedDealerCode({
        variables: {
          linkedWarrantyProviderId: warrantyProvider.id,
          dealerCode: warrantyProvider.dealerCode,
          extraSettings,
        },
      })
      updates.push(updateDealerCode)
    })

    Promise.all(updates)
      .then(() => {
        toast.success(t('formFeedback.settingsSaved'))
      })
      .catch(() => {
        toast.error(t('formFeedback.settingsSaveError'))
      })
  }

  const { carServicePlan } = dealerData.dealer
  const { usedWarrantyProviders } = carServicePlan
  const warrantyProviders = usedWarrantyProviders.map((uwp) => {
    /**
     * The extra settings are a set of configuration that can be set via the fields
     * provided in the inputFields config, both are provided in the `uwp`.
     */
    const extraSettings = {}
    if (Array.isArray(uwp.extraSettings)) {
      // preset extraSettings object based on the input fields:
      uwp.warrantyProvider.inputFields.forEach((inputField) => {
        extraSettings[inputField.name] = ''
      })
      // populate extraSettings object with any provided settings:
      uwp.extraSettings.forEach((setting) => {
        extraSettings[setting.key] = setting.value
      })
    }
    return {
      ...uwp,
      name: uwp.warrantyProvider.name,
      extraSettings,
    }
  })

  return (
    <>
      <Formik
        initialValues={{
          carServicePlan: {
            id: dealerData.dealer.carServicePlan.id,
            invoiceFee: dealerData.dealer.carServicePlan.invoiceFee,
            isDisabled: dealerData.dealer.carServicePlan.isDisabled,
          },
          warrantyProviders,
        }}
        validationSchema={CSPAdminDealerSchema(t)}
        onSubmit={onSubmitUpdates}
      >
        {() => (
          <Form>
            <BodyTextWrapper>
              <Breadcrumbs
                items={[
                  {
                    label: t('carServicePlanAdminDealersOverview.header'),
                    to: String(pathname)
                      .replace(/\/$/, '') // just in case it has a trailing slash
                      .replace(`details/${dealerId}`, ''),
                  },
                  {
                    label: dealerData.dealer.name,
                  },
                ]}
              />
            </BodyTextWrapper>
            <BodyTextWrapper style={{ position: 'relative' }}>
              <Typography type="Level1Heading">
                {t('CSPAdminDealerDetails.heading')}
              </Typography>
              <PostionedButton
                type="submit"
                level="cta"
                text={t('save')}
                inline
                filled
              />
              <Typography type="Level2Heading">
                {t('cspAdminDealerDetails.priceSettings.heading')}
              </Typography>
              <Typography type="ExplanationParagraph">
                {t('cspAdminDealerDetails.priceSettings.explanation')}
              </Typography>
              <InlinedItems>
                <Field
                  name="carServicePlan.invoiceFee"
                  label={t(
                    'cspAdminDealerDetails.priceSettings.fields.invoiceFee.label',
                  )}
                  component={FormikTextInput}
                  unit="currency_euro"
                  type="number"
                  filled
                />
                <Field
                  name="carServicePlan.isDisabled"
                  label={t('cspAdminDealerDetails.blockAccount')}
                  component={FormikLabeledCheckBox}
                />
              </InlinedItems>
              <Typography type="Level2Heading">
                {t('cspAdminDealerDetails.linkingWarrantyPrograms.heading')}
              </Typography>
              <EnhancedTable
                selectable={false}
                columns={[
                  {
                    id: 'warrantyProvider',
                    label: t(
                      'cspAdminDealerDetails.linkingWarrantyPrograms.tableHeadings.warrantyProvider',
                    ),
                  },
                  {
                    id: 'programName',
                    label: t(
                      'cspAdminDealerDetails.linkingWarrantyPrograms.tableHeadings.programName',
                    ),
                  },
                  {
                    id: 'brands',
                    label: t(
                      'cspAdminDealerDetails.linkingWarrantyPrograms.tableHeadings.brands',
                    ),
                  },
                  {
                    id: 'ownRiskPeriod',
                    label: t(
                      'cspAdminDealerDetails.linkingWarrantyPrograms.tableHeadings.ownRiskPeriod',
                    ),
                  },
                  {
                    id: 'actions',
                    label: t(
                      'cspAdminDealerDetails.linkingWarrantyPrograms.tableHeadings.actions',
                    ),
                    alignRight: true,
                  },
                ]}
                rows={dealerData.dealer.carServicePlan.usedWarrantyPrograms.map(
                  (usedWarrantyProgram) => {
                    const { warrantyProgram, warrantyProvider } =
                      usedWarrantyProgram
                    return {
                      warrantyProvider: {
                        component: warrantyProvider.name,
                      },
                      programName: {
                        component: warrantyProgram.name,
                      },
                      brands: {
                        component: Array.isArray(warrantyProgram.brands)
                          ? warrantyProgram.brands
                              .map((brand) => brand.name)
                              .join(', ')
                          : '[N/A]',
                      },
                      ownRiskPeriod: {
                        component: t(
                          warrantyProgram.ownRiskPeriod === 1
                            ? 'monthCountSingular'
                            : 'monthCount',
                          { count: warrantyProgram.ownRiskPeriod },
                        ),
                      },
                      actions: {
                        component: (
                          <ActionsContainer>
                            <Button
                              iconColor="actionsStandard"
                              level="option"
                              icon="forbidden"
                              onClick={() => {
                                setWarrantyProgramToUnlink(usedWarrantyProgram)
                              }}
                              disabled={Boolean(warrantyProgramToUnlink)}
                            />
                            <StyledSeparator
                              variant="vertical"
                              paddingLevel={1}
                            />
                            <Button
                              iconColor="actionsStandard"
                              level="option"
                              icon="edit"
                              to={`${pathname}${pathname.endsWith('/') ? '' : '/'}warranty-program/${warrantyProgram.id}`}
                            />
                          </ActionsContainer>
                        ),
                        data: 'action',
                        alignRight: true,
                      },
                    }
                  },
                )}
              />
              <LinkProgramLink
                onClick={() =>
                  history.push(
                    `${pathname}${pathname.endsWith('/') ? '' : '/'}warranty-program-link/`,
                  )
                }
                text={t(
                  'cspAdminDealerDetails.linkingWarrantyPrograms.linkWarrantyProgramAction',
                )}
              />

              <Typography type="Level2Heading">
                {t('cspAdminDealerDetails.priceMatrices.heading')}
              </Typography>
              <EnhancedTable
                selectable={false}
                columns={[
                  {
                    id: 'name',
                    label: t(
                      'cspAdminDealerDetails.priceMatrices.tableHeadings.name',
                    ),
                  },
                  {
                    id: 'brands',
                    label: t(
                      'cspAdminDealerDetails.priceMatrices.tableHeadings.maxMileagePerYear',
                    ),
                  },
                  {
                    id: 'actions',
                    label: t(
                      'cspAdminDealerDetails.priceMatrices.tableHeadings.actions',
                    ),
                    alignRight: true,
                  },
                ]}
                rows={priceMatricesData.priceMatrices.edges.map(
                  (priceMatrix) => ({
                    name: {
                      component: priceMatrix.node.name,
                    },
                    brands: {
                      component: priceMatrix.node.maxMileagePerYear
                        ? formatMileage(priceMatrix.node.maxMileagePerYear)
                        : '',
                    },
                    actions: {
                      component: (
                        <ActionsContainer>
                          <Button
                            iconColor="actionsStandard"
                            level="option"
                            icon="forbidden"
                            onClick={() => {
                              setPriceMatrixToDelete(priceMatrix.node)
                            }}
                            // disabled={Boolean(warrantyProgramToUnlink)}
                          />
                          <StyledSeparator
                            variant="vertical"
                            paddingLevel={1}
                          />
                          <Button
                            iconColor="actionsStandard"
                            level="option"
                            icon="edit"
                            to={`${pathname}${pathname.endsWith('/') ? '' : '/'}price-matrix/${priceMatrix.node.id}`}
                          />
                        </ActionsContainer>
                      ),
                    },
                  }),
                )}
              />
              <LinkProgramLink
                onClick={() =>
                  history.push(
                    `${pathname}${pathname.endsWith('/') ? '' : '/'}price-matrix/new`,
                  )
                }
                text={t('cspAdminDealerDetails.priceMatrices.addPriceMatrix')}
              />
            </BodyTextWrapper>

            <DealerCodesWrapper>
              <DealerCodeHeading type="Level2Heading">
                {t('cspAdminDealerDetails.dealerCodeManagement.heading')}
              </DealerCodeHeading>
              <div>
                {warrantyProviders.length > 0 ? (
                  warrantyProviders.map((warrantyProvider, index) => (
                    <DealerCodeWarrantyProviderContainer
                      key={warrantyProvider.id}
                    >
                      <DealerCodeWarrantyProviderHeading type="Level3Heading">
                        {warrantyProvider.name}
                      </DealerCodeWarrantyProviderHeading>
                      <DealerCodesContainer>
                        <Field
                          name={`warrantyProviders[${index}].dealerCode`}
                          label={t(
                            'cspAdminDealerDetails.dealerCodeManagement.fieldLabel',
                            { warrantyProviderName: warrantyProvider.name },
                          )}
                          component={FormikTextInput}
                          type="text"
                          filled
                        />
                        {/* TODO: It might be worthwhile to move this kind of dynamic field logic to a new component that works similar to ComplexForm */}
                        {Array.isArray(
                          warrantyProvider.warrantyProvider.inputFields,
                        ) &&
                          warrantyProvider.warrantyProvider.inputFields.map(
                            (inputField) => (
                              <Field
                                key={inputField.id}
                                // the name prop value will be mapped to a array of key, value pairs:
                                name={`warrantyProviders[${index}].extraSettings.${inputField.name}`}
                                label={`${inputField.label} ${warrantyProvider.name}`}
                                component={FormikTextInput}
                                type={InputFieldType[inputField.type]}
                                filled
                              />
                            ),
                          )}
                      </DealerCodesContainer>
                    </DealerCodeWarrantyProviderContainer>
                  ))
                ) : (
                  <Typography type="BodyParagraph">
                    {t(
                      'cspAdminDealerDetails.dealerCodeManagement.noWarrantyProviders',
                    )}
                  </Typography>
                )}
              </div>
            </DealerCodesWrapper>
          </Form>
        )}
      </Formik>
      {warrantyProgramToUnlink && (
        <FlexibleDialog
          content={
            <>
              <FlexibleDialogBody type="BodyParagraph">
                {t(
                  'cspAdminDealerDetails.confirmWarrantyProgramUnlinking.body',
                )}
              </FlexibleDialogBody>
              {unlinkingWarrantyProgram && (
                <StyledLoadingIndicator size="small" />
              )}
            </>
          }
          onCancel={
            !unlinkingWarrantyProgram
              ? () => {
                  setWarrantyProgramToUnlink()
                }
              : undefined
          }
          cancelText={t('cancel')}
          onSubmit={
            !unlinkingWarrantyProgram
              ? () => {
                  handleUnlinkWarrantyProgram()
                }
              : undefined
          }
          submitText={t(
            'cspAdminDealerDetails.confirmWarrantyProgramUnlinking.unlink',
          )}
          title={t(
            'cspAdminDealerDetails.confirmWarrantyProgramUnlinking.title',
            { warrantyProgram: warrantyProgramToUnlink.warrantyProgram.name },
          )}
          cancel={!unlinkingWarrantyProgram}
          open
        />
      )}
      {priceMatrixToDelete && (
        <FlexibleDialog
          content={
            <>
              <FlexibleDialogBody type="BodyParagraph">
                {t('cspAdminDealerDetails.confirmPriceMatrixDeletion.body')}
              </FlexibleDialogBody>
              {deletingPriceMatrix && <StyledLoadingIndicator size="small" />}
            </>
          }
          onCancel={
            !deletingPriceMatrix
              ? () => {
                  setPriceMatrixToDelete()
                }
              : undefined
          }
          cancelText={t('cancel')}
          onSubmit={
            !deletingPriceMatrix
              ? () => {
                  handleDeletePriceMatrix()
                }
              : undefined
          }
          submitText={t('delete')}
          title={t('cspAdminDealerDetails.confirmPriceMatrixDeletion.title', {
            priceMatrix: priceMatrixToDelete.name,
          })}
          cancel={!deletingPriceMatrix}
          open
        />
      )}
    </>
  )
}

export default CarServicePlanDetailsForDealer
