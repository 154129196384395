import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Text from 'components/atoms/text'
import numbro from 'numbro'

const StyledText = styled(Text)`
  margin-top: 5px;
  text-transform: none;
  white-space: nowrap;
`

function LabeledText({
  className,
  highlight,
  price,
  title,
  value,
  ...restProps
}) {
  let formattedValue = value
  if (price) {
    // https://github.com/BenjaminVanRyseghem/numbro/issues/265
    formattedValue = numbro(
      value === null ? Number(value) : value,
    ).formatCurrency({ thousandSeparated: true })
  }

  return (
    <div className={className} {...restProps}>
      <Text text={title} data-test-e2e="title" />
      <StyledText
        type={highlight ? 'h1' : 'h5'}
        tag="p"
        text={formattedValue}
        data-test-e2e="value"
      />
    </div>
  )
}

LabeledText.propTypes = {
  className: PropTypes.string,
  highlight: PropTypes.bool,
  price: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

LabeledText.defaultProps = {
  className: null,
  highlight: false,
  price: null,
}

export default LabeledText
