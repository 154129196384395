import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { GET_PROSPECTS, GET_DEALERS_WITH_CSP } from 'config/graphql/csp'
import { useQuery } from '@apollo/client'
import Typography from 'components/molecules/typography'
import LoadingIndicator from 'components/atoms/loading-indicator'
import StartServiceCalculationWithLicensePlate from './start-service-calculation-forms/with-license-plate'
import StartServiceCalculationWithoutLicensePlate from './start-service-calculation-forms/without-license-plate'

const StartServiceCalculation = ({ demo }) => {
  const { t } = useTranslation()
  const [showNoLicensePlateForm, setShowNoLicensePlateForm] = useState(false)

  const {
    loading: prospectsLoading,
    error: prospectsError,
    data: prospectsData,
  } = useQuery(GET_PROSPECTS)
  const {
    loading: dealersLoading,
    error: dealersError,
    data: dealersData,
  } = useQuery(GET_DEALERS_WITH_CSP)

  if (prospectsLoading || dealersLoading) {
    return <LoadingIndicator error={null} size="small" />
  }
  if (prospectsError || dealersError) {
    console.error(prospectsError, dealersError)
    return (
      <>
        <Typography type="Level2Heading">
          {t('problemsFoundHeading')}
        </Typography>
        <Typography type="ExplanationParagraph">
          {t('problemsWhenRetrievingData')}
        </Typography>
      </>
    )
  }

  const prospectTypes = prospectsData.prospects.map((prospectType) => ({
    label: prospectType.label,
    value: prospectType.key,
  }))

  const dealers = dealersData.dealers.edges.map((edge) => ({
    label: edge.node.name,
    value: edge.node.id,
    disabled: edge.node.carServicePlan.isDisabled,
  }))

  const switchForm = () => {
    setShowNoLicensePlateForm(!showNoLicensePlateForm)
  }

  return showNoLicensePlateForm ? (
    <StartServiceCalculationWithoutLicensePlate
      demo={demo}
      dealers={dealers}
      prospectTypes={prospectTypes}
      switchFormMode={switchForm}
    />
  ) : (
    <StartServiceCalculationWithLicensePlate
      dealers={dealers}
      prospectTypes={prospectTypes}
      switchFormMode={switchForm}
    />
  )
}

StartServiceCalculation.propTypes = {
  /** If true, show fields required to start a demo flow */
  demo: PropTypes.bool,
}

StartServiceCalculation.defaultProps = {
  demo: undefined,
}

export default StartServiceCalculation
