import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Text, { styles as textStyles } from 'components/atoms/text'
import DatePicker from 'components/atoms/date-picker'

const StyledInput = styled.input`
  ${textStyles.input}
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.brandAlpha};
  outline: none;
  padding: 5px 0;
  width: 100%;
`

const StyledDatePicker = styled(DatePicker)`
  ${textStyles.input}
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.brandAlpha};
`

const Title = styled(Text)`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  white-space: nowrap;
`

const Container = styled.div`
`

const ErrorContainer = styled.div`
  margin-top: 5px;
  height: 16px;
`

/**
 * Generates Text inputs with a label and error message handling.
 * maxNumber and minNumber are only applied as the bounding ranges if the type is 'number'.
 */
function TextInput ({
  className,
  error,
  name,
  onChange,
  readOnly,
  required,
  title,
  type,
  value,
  maxNumber,
  minNumber,
  clearable,
  disabled,
  ...restProps
}) {
  const [focussed, setFocussed ] = useState(false)
  const inputEl = useRef(null)

  function handleFocus () {
    setFocussed(true)
  }

  function handleBlur () {
    setFocussed(false)
  }

  const placeholder = focussed ? null : title
  const titleColor = 'brandAlfa'
  const constructedTitle = `${title}${required ? '*' : ''}`

  return (
    <div className={className} {...restProps}>
      <Container
        htmlFor={name}
        deselected={!focussed}
      >
        {title &&
        (
          <Title
            tag="label"
            htmlFor={name}
            type="floatingLabelLabel"
            text={constructedTitle}
            color={error ? 'stateNegative' : titleColor}
            visible={focussed || value}
            data-test-e2e="title"
          />
        )}
        {type === 'date'
          ? (
            <StyledDatePicker
              onChange={(newDate) => onChange({ target: { value: newDate } })}
              value={value}
              data-test-e2e="date-picker"
              clearable={clearable}
              disabled={disabled}
            />
          )
          : (
            <StyledInput
              autoCapitalize="none"
              data-test-e2e="text-input"
              placeholder={placeholder}
              name={name}
              id={name}
              onBlur={handleBlur}
              onChange={onChange}
              onFocus={(e) => handleFocus(e.target)}
              readOnly={readOnly}
              max={maxNumber !== null && type === 'number' ? maxNumber : undefined}
              min={minNumber !== null && type === 'number' ? minNumber : undefined}
              type={type}
              value={value}
              ref={inputEl}
            />
          )}
        {error &&
          (
            <ErrorContainer data-test-e2e="error-container">
              <Text id={name} name={name} type="menu" text={error} color="stateNegative" />
            </ErrorContainer>
          )}
      </Container>
    </div>
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool ]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  maxNumber: PropTypes.number,
  minNumber: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number ]),
  clearable: PropTypes.bool, // Only used when type === 'date'
  disabled: PropTypes.bool,
}

TextInput.defaultProps = {
  className: null,
  error: false,
  readOnly: false,
  required: false,
  title: null,
  type: null,
  value: '',
  maxNumber: null,
  minNumber: null,
  disabled: false,
}

export default TextInput
