import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const iconEdge = 16
const lineLength = iconEdge / 2
const offSet = (iconEdge - lineLength) / 2

const StyledButton = styled.button`
  border-radius: 4px;
  height: ${iconEdge}px;
  width: ${iconEdge}px;
  background-color: ${({ theme }) => theme.colors.actionsStandard};
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }

  > svg {
    line {
      stroke: ${({ theme }) => theme.colors.actionsStandardText};
      stroke-width: 1px;
    }
    line.ucc-expand-button--line-that-rotates {
      background-color: ${({ theme }) => theme.colors.actionsStandard};
      transition: ease-in 0.25s;
      transform-origin: center;
      transform: rotate(${({ expanded }) => (!expanded ? '90deg' : '180deg')});
    }
  }
`

function ExpandButton({ onClick, isExpanded, className, ...restProps }) {
  return (
    <StyledButton
      className={className}
      type="button"
      expanded={isExpanded}
      onClick={onClick}
      {...restProps}
    >
      <svg
        viewBox={`0 0 ${iconEdge} ${iconEdge}`}
        height={iconEdge}
        width={iconEdge}
      >
        <line
          x1={offSet}
          y1={iconEdge / 2}
          x2={iconEdge - offSet}
          y2={iconEdge / 2}
        />
        <line
          className="ucc-expand-button--line-that-rotates"
          x1={offSet}
          y1={iconEdge / 2}
          x2={iconEdge - offSet}
          y2={iconEdge / 2}
        />
      </svg>
    </StyledButton>
  )
}

ExpandButton.propTypes = {
  onClick: PropTypes.func,
  isExpanded: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

ExpandButton.defaultProps = {
  onClick: null,
  className: null,
}

export default ExpandButton
