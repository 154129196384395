import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import TextLink from 'components/atoms/text-link'
import ActionsMenu from 'components/molecules/actions-menu'
import Menu from 'components/organisms/menu'

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.brandBravo};
  width: 100%;
`

const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.brandCharlie};
  width: calc(100% - 48px);
  height: 1px;
  margin: 0 24px 16px;
`

const ButtonsContainer = styled.div`
  margin: 0 24px;
  margin-bottom: 16px;
`

const StyledMenu = styled(Menu)`
  margin-bottom: 16px;
`

const LogoutLink = styled(TextLink)`
  margin-top: 16px;
`

function MobileMenu({
  actionsMenuItems,
  className,
  closeMobileMenu,
  items,
  logout,
  ...restProps
}) {
  const { t } = useTranslation()

  return (
    <Container className={className} {...restProps}>
      <Line />
      {actionsMenuItems && actionsMenuItems.length ? (
        <>
          <ButtonsContainer>
            <ActionsMenu closeMenu={closeMobileMenu} items={actionsMenuItems} />
          </ButtonsContainer>
          <Line />
        </>
      ) : null}
      <StyledMenu
        closeMenu={closeMobileMenu}
        items={items.filter((item) => !item.hideMobile)}
        backgroundColor="brandBravo"
        color="brandGolf"
        hasMobileLayout
      />
      <Line />
      <ButtonsContainer>
        <LogoutLink
          onClick={logout}
          text={t('logout')}
          data-test-e2e="logout-link"
        />
      </ButtonsContainer>
    </Container>
  )
}

MobileMenu.propTypes = {
  actionsMenuItems: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
  closeMobileMenu: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  logout: PropTypes.func.isRequired,
}

export default MobileMenu
