import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { formatPrice } from 'utilities/format'
import Typography from './typography'
import PropTypes from 'prop-types'

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Price = styled(Typography)`
  margin: ${({ theme }) => theme.sizeByFactor(0.5)} 0;
  font-size: ${({ theme }) => theme.sizings.lvl4};
  font-weight: bold;
`

const FromText = styled(Typography)`
  padding-left: ${({ theme }) => theme.sizeByFactor(1)};
  margin-bottom: ${({ theme }) => theme.sizeByFactor(1)};
`

export const PeriodType = {
  MONTH: 'MONTH',
  WEEK: 'WEEK',
}

function PricingPerPeriod({
  price,
  periodType,
  showFromText = false,
  className,
  ...restProps
}) {
  const { t } = useTranslation()

  return (
    <PriceContainer className={className} {...restProps}>
      {showFromText && (
        <FromText type="InlineBodyText">{t('fromPrice')}</FromText>
      )}
      <span>
        <Typography type="InlineBodyText">€</Typography>
        &nbsp;
        <Price type="InlineBodyText">
          {formatPrice(price, true, false, true)}
        </Price>
        {periodType === PeriodType.MONTH && (
          <Typography type="InlineBodyText">/ {t('month')}</Typography>
        )}
        {periodType === PeriodType.WEEK && (
          <Typography type="InlineBodyText">/ {t('week')}</Typography>
        )}
      </span>
    </PriceContainer>
  )
}

PricingPerPeriod.propTypes = {
  price: PropTypes.number.isRequired,
  periodType: PropTypes.oneOf([PeriodType.MONTH, PeriodType.WEEK]),
  showFromText: PropTypes.bool, // Shows a label above the price displaying the text "Prijs vanaf"
  className: PropTypes.string,
}

PricingPerPeriod.defaultProps = {
  periodType: PeriodType.MONTH,
  showFromText: false,
  className: '',
}

export default PricingPerPeriod
