import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import styled from 'styled-components/macro'
import moment from 'moment'

import { CAR_FILE } from 'config/routes'
import {
  CarFileStatusEnumLowerCase,
  CarFileStatusEnumUpperCase,
} from 'config/enums'
import { CSPSalesFlowStep3ChangeVehicleStatus } from 'config/validation-schemas'

import { setCarStatus } from 'redux/actions/data'

import data from 'utilities/data'

import {
  FormikTextInput,
  FormikDatePicker,
} from 'components/molecules/formik-field'
import Button from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import TextLink from 'components/atoms/text-link'
import Typography from 'components/molecules/typography'
import Dialog from 'components/molecules/flexible-dialog'

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper,
  .MuiDialogContent-root {
    overflow: visible;
  }
`
const FormRow = styled.div`
  padding-top: ${({ theme }) => theme.sizings.lvl2};
  display: flex;
  justify-content: space-between;
  > * {
    width: 100%;
    padding-right: ${({ theme }) => theme.sizings.lvl3};
    &:last-child {
      padding-right: 0;
    }
  }
`
const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`
const CancelLink = styled(TextLink)`
  padding: ${({ theme }) => theme.sizings.lvl3} 0;
`
const LoadingIndicatorStyled = styled(LoadingIndicator)`
  margin-top: ${({ theme }) => theme.sizings.lvl4};
`

function CspSalesChangeVehicleStatusDialog({
  onClose,
  onSubmit,
  carFileId,
  lastName,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const instanceId = useSelector((state) => state.auth.instanceId)
  const [error, setError] = useState()

  const handleSetCarToSold = async (values) =>
    dispatch(
      setCarStatus({
        auto_id: carFileId,
        klant_achternaam: lastName,
        afleverdatum: values.afleverdatum,
        verkoopprijs_fin: values.verkoopprijs_fin,
        currentStatus:
          CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.binnen],
      }),
    ).then((response) => {
      if (response && response.data.success) {
        // @todo: update this to retrieve the carFile data through GraphQL:
        if (carFileId && instanceId) {
          const fetchCarFile = async () => {
            const carFileResponse = await data.getCarfile(instanceId, {
              carId: carFileId,
            })
            if (Object.keys(carFileResponse).length) {
              onSubmit({
                carFile: carFileResponse,
              })
              onClose()
            }
          }
          fetchCarFile()
        } else {
          onSubmit()
          onClose()
        }
      } else {
        // This error is caught in the try catch in csp-sales-changeVehicleStatusDialog
        throw new Error()
      }
    })

  return (
    <StyledDialog
      closeHandler={onClose}
      open
      title={t('cspSalesFlow.steps.changeVehicleStatusDialog.heading')}
      content={
        <div>
          <Typography type="ExplanationParagraph">
            {t('cspSalesFlow.steps.changeVehicleStatusDialog.body')}
          </Typography>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              verkoopprijs_fin: 0,
              afleverdatum: moment(),
            }}
            validationSchema={CSPSalesFlowStep3ChangeVehicleStatus(t)}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)
              try {
                await handleSetCarToSold(values)
              } catch (e) {
                console.error(e)
                setError(
                  t(
                    'cspSalesFlow.steps.changeVehicleStatusDialog.carStatusError',
                  ),
                )
              }
              setSubmitting(false)
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormRow>
                  <Field
                    name="verkoopprijs_fin"
                    unit="currency_euro"
                    label={t(
                      'cspSalesFlow.steps.changeVehicleStatusDialog.fields.salePrice',
                    )}
                    filled
                    component={FormikTextInput}
                  />
                  <Field
                    name="afleverdatum"
                    label={t(
                      'cspSalesFlow.steps.changeVehicleStatusDialog.fields.saleDate',
                    )}
                    filled
                    component={FormikDatePicker}
                  />
                </FormRow>

                {(error || isSubmitting) && (
                  <LoadingIndicatorStyled size="small" error={error} />
                )}
                {error && (
                  <TextLink
                    to={`${CAR_FILE}/${carFileId}`}
                    text={t(
                      'cspSalesFlow.steps.changeVehicleStatusDialog.changeCarStatus',
                    )}
                  />
                )}
                <Controls>
                  <CancelLink onClick={onClose} text={t('cancel')} />
                  <Button level="cta" disabled={isSubmitting} type="submit">
                    {t('cspSalesFlow.steps.changeVehicleStatusDialog.heading')}
                  </Button>
                </Controls>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  )
}

CspSalesChangeVehicleStatusDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  carFileId: PropTypes.number.isRequired,
  lastName: PropTypes.string.isRequired,
}

export default CspSalesChangeVehicleStatusDialog
