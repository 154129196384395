import { DocumentCategories, ImageCategories } from 'config/enums'
import i18next from 'i18next'
import dataApi from 'utilities/data'
import toast, { TOAST_AUTOCLOSE_SHORT_DURATION } from 'utilities/toast'

export const types = {
  ADD_NEW_CAR_DATA: 'ADD_NEW_CAR_DATA',
  REQUEST_DATA: 'REQUEST_DATA',
  POST_DATA: 'POST_DATA',
  RECEIVE_DATA: 'RECEIVE_DATA',
  CLEAR_DATA: 'CLEAR_DATA',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET_ACCESSORIES_HIGHLIGHT: 'SET_ACCESSORIES_HIGHLIGHT',
}

export const stateBranches = {
  CAR_FILE: 'carfile',
}

export function requestData(id, payload) {
  return {
    type: types.REQUEST_DATA,
    id,
    payload,
  }
}

function postData(id, payload) {
  return {
    type: types.POST_DATA,
    id,
    payload,
  }
}

export function receiveData(id, data, params) {
  return {
    type: types.RECEIVE_DATA,
    id,
    data,
    params,
  }
}

export function failRequest(id, error) {
  return {
    type: types.FAIL_REQUEST,
    id,
    error,
  }
}

export function addNewCarData(data) {
  return {
    type: types.ADD_NEW_CAR_DATA,
    data,
  }
}

export function clearData(id) {
  return {
    type: types.CLEAR_DATA,
    id,
  }
}

function getData(id, method, data) {
  return (dispatch, getState) => {
    const instance = getState().auth.instanceId

    dispatch(requestData(id, data))

    method(instance, data)
      .then((response) => {
        if (!response) {
          dispatch(failRequest(id), 'no response')
          toast.error('no response')
          return Promise.reject(new Error('no response'))
        }

        if (response.info) {
          toast.info(response.info)
        }

        dispatch(receiveData(id, response, data))

        return Promise.resolve()
      })
      .catch((error) => {
        dispatch(failRequest(id, error))
        toast.error(error)
      })
  }
}

function addData(id, apiToCall, data, carId, callback, onProgress) {
  return (dispatch, getState) => {
    const instance = getState().auth.instanceId

    dispatch(postData(id, data))

    // call the api endpoint to store the data remotely:
    return apiToCall(instance, data, carId, onProgress)
      .then((response) => {
        const responseData =
          typeof response === 'string' ? JSON.parse(response) : response

        if (responseData?.success === false) {
          return Promise.reject(
            responseData?.error?.msg || i18next.t('genericError'),
          )
        }

        dispatch(receiveData(id, responseData))

        if (callback) {
          callback(dispatch, response)
        }

        return Promise.resolve(response)
      })
      .catch((error) => {
        dispatch(failRequest(id, error))
        toast.error(error)
      })
  }
}

export function getCarfile(carId, locationId) {
  return getData(stateBranches.CAR_FILE, dataApi.getCarfile, {
    carId,
    locationId,
  })
}

export function getB2bCarFile(carId) {
  return getData('b2bCarFile', dataApi.getB2bCarFile, { carId })
}

export function getCarAssets(carId) {
  return getData('carAssets', dataApi.getCarAssets, { carId })
}

/**
 * @param {String} carId
 * @param {String} carImageType
 * @returns
 */
export function getCarMediaAssets(carFileId, carImageCategory, carImageType) {
  return getData('carMediaAssets', dataApi.getCarMediaAssets, {
    carFileId,
    carImageCategory,
    carImageType,
  })
}

export function getCarDocumentAssets(carFileId, documentCategory) {
  return getData('carDocumentAssets', dataApi.getCarDocumentAssets, {
    carFileId,
    documentCategory,
  })
}

export function getLicensePlate(data) {
  return getData('licensePlate', dataApi.getLicensePlate, data)
}

export function getAds(data) {
  return getData('ads', dataApi.getAds, data)
}

export function getAdsFilters() {
  return getData('adsFilters', dataApi.getAdsFilters)
}

export function getLocations() {
  return getData('locations', dataApi.getLocations)
}

export function getUserLocations() {
  return getData('userLocations', dataApi.getUserLocations)
}

export function getLocationsDetails() {
  return getData('locationsDetails', dataApi.getLocationsDetails)
}

export function getB2bRelations() {
  return getData('b2bRelations', dataApi.getB2bRelations)
}

export function getPortals(params) {
  return getData('portals', dataApi.getPortals, params)
}
export function getActivePortals(params) {
  return getData('activePortals', dataApi.getActivePortals, params)
}
export function getPortalPreaddConfirmation(params) {
  return getData(
    'portalPreaddConfirmation',
    dataApi.getPortalPreaddConfirmation,
    params,
  )
}
export function addPortal(data) {
  return addData('addPortal', dataApi.addPortal, data, null, (dispatch) => {
    dispatch(getActivePortals(data))
    dispatch(getPortals(data))
  })
}
export function removePortal(data) {
  return addData(
    'removePortal',
    dataApi.removePortal,
    data,
    null,
    (dispatch) => {
      dispatch(getActivePortals(data))
      dispatch(getPortals(data))
    },
  )
}
export function setPortalDefault(data, params) {
  return addData(
    'setPortalDefault',
    dataApi.setPortalDefault,
    data,
    params,
    (dispatch) => {
      dispatch(getActivePortals(params))
      dispatch(getPortals(params))
    },
  )
}
export function addAllVehiclesToPortal(data) {
  return addData(
    'addAllVehiclesToPortal',
    dataApi.addAllVehiclesToPortal,
    data,
    null,
    (dispatch) => {
      dispatch(getActivePortals(data))
      dispatch(getPortals(data))
    },
  )
}
export function removeAllVehiclesFromPortal(data) {
  return addData(
    'removeAllVehiclesFromPortal',
    dataApi.removeAllVehiclesFromPortal,
    data,
    null,
    (dispatch) => {
      dispatch(getActivePortals(data))
      dispatch(getPortals(data))
    },
  )
}

export function deleteB2bRelation(params) {
  return addData(
    'deleteB2bRelation',
    dataApi.deleteB2bRelation,
    { company_id: params.company_id },
    null,
    (dispatch) => dispatch(getB2bRelations()),
  )
}

export function getB2bInterests(params) {
  return getData('b2bInterests', dataApi.getB2bInterests, params)
}

export function deleteB2bInterests(params) {
  return addData(
    'deleteB2bInterests',
    dataApi.deleteB2bInterests,
    { interesse_id: params.interesse_id },
    null,
    (dispatch) =>
      dispatch(
        getB2bInterests({
          company_id: params.company_id,
        }),
      ),
  )
}

export function getB2bPurchaseInterests(params) {
  return getData(
    'b2bPurchaseInterests',
    dataApi.getB2bPurchaseInterests,
    params,
  )
}

export function deleteB2bPurchaseInterests(params) {
  return addData(
    'deleteB2bPurchaseInterests',
    dataApi.deleteB2bPurchaseInterests,
    { interesse_id: params.interesse_id },
    null,
    (dispatch) =>
      dispatch(
        getB2bPurchaseInterests({
          vestiging_id: params.vestiging_id,
        }),
      ),
  )
}

export function getEditions(data) {
  return getData('editions', dataApi.getEditions, data)
}

export function getEditionsForBpmDeclaration(data) {
  return getData(
    'editionsForBpmDeclaration',
    dataApi.getEditionsForBpmDeclaration,
    data,
  )
}

export function getFilteredEditionsForBpmDeclaration(data) {
  return getData(
    'editionsForBpmDeclaration',
    dataApi.getFilteredEditionsForBpmDeclaration,
    data,
  )
}

export function getEdition(data) {
  return getData('edition', dataApi.getEdition, data)
}

export function getTodos() {
  return getData('todos', dataApi.getTodos)
}

export function getLocationTodos(locationId) {
  return getData('locationTodos', dataApi.getLocationTodos, { locationId })
}

export function getSearchResults(data, page = 1, lines) {
  return getData('searchResults', dataApi.getSearchResults, {
    ...data,
    page,
    lines,
  })
}

export function getStockFilters(data) {
  return getData('stockFilters', dataApi.getStockFilters, data)
}

export function getStockSortOptions(data) {
  return getData('stockSortOptions', dataApi.getStockSortOptions, data)
}

export function getStockResults(data, page = 1, lines) {
  return getData('stockResults', dataApi.getStockResults, {
    ...data,
    page,
    lines,
  })
}

export function getB2bTradePlatformFilters(data) {
  return getData(
    'b2bTradePlatformFilters',
    dataApi.getB2bTradePlatformFilters,
    data,
  )
}

export function getB2bTradePlatformSortOptions(data) {
  return getData(
    'b2bTradePlatformSortOptions',
    dataApi.getB2bTradePlatformSortOptions,
    data,
  )
}

export function getB2bTradePlatformResults(data, page = 1, lines) {
  return getData(
    'b2bTradePlatformResults',
    dataApi.getB2bTradePlatformResults,
    {
      ...data,
      page,
      lines,
    },
  )
}

export function getB2bCarFileAssets(carFileId) {
  return getData('b2bCarFileAssets', dataApi.getB2bCarFileAssets, {
    carId: carFileId,
  })
}

/**
 * Place a interest request with a trader on the b2b trade platform for specific car file
 * @param {object} param0 parameters
 * @param {string} param0.id The carFileId of interest
 * @returns Promise
 */
export function placeB2bCarFileInterest({ id }) {
  return getData('b2bCarFileInterests', dataApi.placeB2bCarFileInterest, { id })
}

export function getRdwStockResults(data) {
  return getData('rdwStockResults', dataApi.getRdwStockResults, data)
}

export function getAccessories() {
  return getData('accessories', dataApi.getAccessories)
}

export function getAllAccessories(data) {
  return getData('allAccessories', dataApi.getAllAccessories, data)
}

export function getNews() {
  return getData('news', dataApi.getNews)
}

export function getStayTime(locationId, destination) {
  return getData('stayTime', dataApi.getStayTime, { locationId, destination })
}

export function getNap(data) {
  return getData('nap', dataApi.getNap, data)
}

export function getCarBrands(data) {
  return getData('carBrands', dataApi.getCarBrands, data)
}

export function getCarModels(data) {
  return getData('carModels', dataApi.getCarModels, data)
}

export function getCarTypes(data) {
  return getData('carTypes', dataApi.getCarTypes, data)
}

export function getMenus() {
  return getData('menus', dataApi.getMenus)
}

export function getCarComments(data) {
  return getData('carComments', dataApi.getCarComments, data)
}

export function getActionBarItems() {
  return getData('actionBarItems', dataApi.getActionBarItems)
}

export function getActionMenuItems() {
  return getData('actionMenuItems', dataApi.getActionMenuItems)
}

export function getDataProviders() {
  return getData('dataProviders', dataApi.getDataProviders)
}

// A generic action creator to call an endpoint that is dynamically
// passed to it. Brought into existance because of the action bar's
// backend data that needs to specify enpoints dynamically.
export function getDynamicEndpoint(data) {
  return getData('dynamicEndpoint', dataApi.getDynamicEndpoint, data)
}

export function addCarComment(data) {
  return addData(
    'addCarComment',
    dataApi.addCarComment,
    data,
    null,
    (dispatch) => dispatch(getCarComments(data)),
  )
}

export function getMarktplaats(data) {
  return getData('marktplaats', dataApi.getMarktplaats, data)
}

export function updateMarktplaatsFeatures(dataCollection, callback) {
  const id = 'marktplaatsUpdates'

  return (dispatch, getState) => {
    const instance = getState().auth.instanceId
    const data = getState().data[id]
    const loading = data && data.loading

    // Prevent triggering an update if one is already triggered
    if (loading) {
      return
    }

    const promises = dataCollection.map((row) =>
      dataApi
        .updateMarktplaatsFeature(instance, row)
        // Catch errors here so Promise.all won't stop executing
        // and simply returns the responses of the catched errors
        // to the then as well
        .catch((error) => error),
    )

    dispatch(postData(id, dataCollection))

    return Promise.all(promises)
      .then((responses) => {
        dispatch(receiveData(id, responses))

        const succesfullResponses = responses.filter(
          (response) => response.data && response.data.success,
        )
        const failedResponses = responses.filter(
          (response) => !(response.data && response.data.success),
        )

        const succeededLicensePlates = succesfullResponses
          .map((car) => car.data.data.kenteken)
          .join('\n')
        const failedLicensePlates = failedResponses
          .map((car) => car.kenteken)
          .join('\n')

        if (succeededLicensePlates) {
          toast.success(i18next.t('marktplaatsUpdateSucceeded'))
        }
        if (failedLicensePlates) {
          toast.error(
            `${i18next.t(
              'marktplaatsUpdateFailed',
            )}:\n\n${failedLicensePlates}`,
            {
              autoClose: false,
            },
          )
        }

        if (callback) {
          callback()
        }

        return Promise.resolve()
      })
      .catch((error) => {
        dispatch(failRequest(id, error))

        toast.error(error.kenteken)

        if (callback) {
          callback()
        }
      })
  }
}

export function addCarAssets(data, carId, successMessage) {
  const loadingToast = toast.info('0 %')

  return addData(
    'addCarAssets',
    dataApi.addCarAssets,
    data,
    carId,
    (dispatch) => {
      toast.update('success', loadingToast, successMessage, {
        autoClose: TOAST_AUTOCLOSE_SHORT_DURATION,
      })
      dispatch(getCarAssets(carId))
    },
    (event) =>
      toast.update(
        'info',
        loadingToast,
        `${Math.round((event.loaded / event.total) * 100)} %`,
      ),
  )
}

export function addCarShareStockData(data, successMessage) {
  const loadingToast = toast.info('0 %')

  return addData(
    'addCarShareStockData',
    dataApi.addCarShareStockData,
    data,
    undefined,
    () => {
      toast.update('success', loadingToast, successMessage, {
        autoClose: TOAST_AUTOCLOSE_SHORT_DURATION,
      })
    },
    (event) =>
      toast.update(
        'info',
        loadingToast,
        `${Math.round((event.loaded / event.total) * 100)} %`,
      ),
  )
}

export function addCarImageAssetsForCategory(
  data,
  carId,
  category,
  successMessage,
) {
  const loadingToast = toast.info('0 %')

  let api = dataApi.addCarAssets
  if (category) {
    if (category === ImageCategories.INTERNAL) {
      api = dataApi.addCarAssetsImageInternal
    } else if (category === ImageCategories.COMMERCIAL) {
      api = dataApi.addCarAssetsImageCommercial
    }
  }

  return addData(
    'addCarAssets',
    api,
    data,
    carId,
    () => {
      toast.update('success', loadingToast, successMessage, {
        autoClose: TOAST_AUTOCLOSE_SHORT_DURATION,
      })
    },
    (event) =>
      toast.update(
        'info',
        loadingToast,
        `${Math.round((event.loaded / event.total) * 100)} %`,
      ),
  )
}

export function addCarDocumentAssets(data, carId, category, successMessage) {
  const loadingToast = toast.info('0 %')

  let api = dataApi.addCarAssetsDocuments
  if (category) {
    if (category === DocumentCategories.INTERNAL) {
      api = dataApi.addCarAssetsDocumentsInternal
    } else if (category === DocumentCategories.COMMERCIAL) {
      api = dataApi.addCarAssetsDocumentsCommercial
    }
  }

  return addData(
    'addCarAssets',
    api,
    data,
    carId,
    () => {
      toast.update('success', loadingToast, successMessage, {
        autoClose: TOAST_AUTOCLOSE_SHORT_DURATION,
      })
    },
    (event) =>
      toast.update(
        'info',
        loadingToast,
        `${Math.round((event.loaded / event.total) * 100)} %`,
      ),
  )
}

export function setCarImageAssetCategory(assetId, category, callback) {
  return addData(
    'setCarImageAssetCategory',
    dataApi.setCarImageAssetCategory,
    { assetId, category },
    null,
    callback,
  )
}

export function setCarAssetCategory(assetId, category, callback) {
  return addData(
    'setCarAssetCategory',
    dataApi.setCarAssetCategory,
    { assetId, category },
    null,
    callback,
  )
}

// A generic action creator to post to an endpoint that is dynamically
// passed to it. Brought into existance because of the action bar's
// backend data that needs to specify enpoints dynamically.
export function postDynamicEndpoint(data) {
  return addData('dynamicEndpoint', dataApi.postDynamicEndpoint, data)
}

export function setCarKind({ data }) {
  return addData('carkind', dataApi.setCarkind, data, null, (dispatch) =>
    dispatch(getCarfile(data.auto_id)),
  )
}

export function setCarStatus(data, callback) {
  return addData(
    'carstatus',
    dataApi.setCarStatus,
    data,
    null,
    (dispatch, response) => {
      dispatch(getCarfile(data.auto_id))
      if (typeof callback === 'function') {
        callback(dispatch, response)
      }
    },
  )
}

export function deleteCarAssets(carId, assetIds, type) {
  return addData(
    'deleteCarAssets',
    dataApi.deleteCarAssets,
    { carId, assetIds, type },
    null,
    (dispatch) => dispatch(getCarAssets(carId)),
  )
}

export function sortCarAssets(carId, sortOrder, category) {
  return addData(
    'carAssetsSort',
    dataApi.sortCarAssets,
    { carId, sortOrder, category },
    null,
    (dispatch) => dispatch(getCarAssets(carId)),
  )
}

export function createCarfile(data) {
  return addData(
    'createCarfile',
    dataApi.createCarfile,
    data,
    null,
    (dispatch, response) => dispatch(addNewCarData(response.data.data)),
  )
}

export function createCarfileByEdition(data, callback) {
  const { valuation, ...otherData } = data

  return addData(
    'createdCarfileByEdition',
    valuation
      ? dataApi.createValuationCarfileByEdition
      : dataApi.createCarfileByEdition,
    otherData,
    null,
    (dispatch, response) => {
      dispatch(addNewCarData(response.data.data))
      if (callback) {
        callback(dispatch, response)
      }
    },
  )
}

export function updateCarfile(
  data,
  carId,
  locationId = null,
  reloadCarfile = true,
) {
  return addData(
    'updateCarfile',
    dataApi.updateCarfile,
    {
      ...data,
      id: carId,
    },
    null,
    (dispatch, response) => {
      dispatch(addNewCarData(response.data.data))

      if (reloadCarfile) {
        dispatch(getCarfile(carId, locationId))
      }
    },
  )
}

export function getSupportRequestDetails() {
  return getData('supportRequestDetails', dataApi.getSupportRequestDetails)
}

export function sendSupportRequest(type, data) {
  const apiMethod =
    type === 'marketview'
      ? dataApi.sendSupportRequestMarktview
      : dataApi.sendSupportRequest
  return addData('supportRequest', apiMethod, data)
}

export function sendPawnListRequest(data) {
  return addData('sendPawnListRequest', dataApi.sendPawnListRequest, data)
}

export function getUsersForLocation(data) {
  return getData('usersForLocation', dataApi.getUsersForLocation, data)
}

export function updateRdwItem(data) {
  return addData('rdwItemUpdate', dataApi.updateRdwItem, data)
}

export function getPdfPreview(data) {
  return addData('pdfPreview', dataApi.getPdfPreview, data)
}

export function getTransactions(data) {
  return getData('transactions', dataApi.getTransactions, data)
}

export function getTransactionTypes(data) {
  return getData('transactionTypes', dataApi.getTransactionTypes, data)
}

export function rotateImages(data) {
  return addData(
    'rotateImages',
    dataApi.rotateImages,
    data,
    data.auto_id,
    (dispatch) => dispatch(getCarAssets(data.auto_id)),
  )
}

export function getBids(data) {
  return getData('bids', dataApi.getBids, data)
}

export function getDamage(data) {
  return getData('damage', dataApi.getDamage, data)
}

export function getB2bCarFileDamage(carId) {
  return getData('b2bCarFileDamage', dataApi.getB2bCarFileDamage, {
    auto_id: carId,
  })
}

export function addDamage(technical, data) {
  const id = technical ? 'addTechnicalDamage' : 'addOpticalDamage'
  const dataMethod = technical
    ? dataApi.addTechnicalDamage
    : dataApi.addOpticalDamage

  return addData(id, dataMethod, data, data.auto_id, (dispatch) =>
    dispatch(getDamage({ auto_id: data.auto_id })),
  )
}

export function addDamageImages(data, params) {
  return addData(
    'addDamageImage',
    dataApi.addDamageImages,
    data,
    params,
    (dispatch) => dispatch(getDamage(params)),
  )
}

export function deleteDamageImages(data, params) {
  return addData(
    'deleteDamageImages',
    dataApi.deleteDamageImages,
    data,
    params,
    (dispatch) => dispatch(getDamage({ auto_id: params.auto_id })),
  )
}

export function deleteDamage(data, params) {
  return addData(
    'deleteDamage',
    dataApi.deleteDamage,
    data,
    params,
    (dispatch) => dispatch(getDamage({ auto_id: params.auto_id })),
  )
}

export function getPriceReport(data) {
  return getData('priceReport', dataApi.getPriceReport, data)
}

export function getPriceChartData(data) {
  return getData('priceChartData', dataApi.getPriceChartData, data)
}

export function requestPeriodicPriceCheck(data) {
  return addData(
    'requestPeriodicPriceCheck',
    dataApi.requestPeriodicPriceCheck,
    data,
    null,
    (dispatch) => {
      dispatch(getPriceReport({ auto_id: data.auto_id }))
      dispatch(getPriceChartData({ auto_id: data.auto_id }))
    },
  )
}

export function createCarFileUsingCarCollect({ licensePlate }) {
  return addData(
    'createCarFileUsingCarCollect',
    dataApi.createCarFileUsingCarCollect,
    { licensePlate },
  )
}

export function getRdwSentCars(data) {
  return getData('rdwSentCars', dataApi.getRdwSentCars, data)
}

export function getRdwReceivedCars(data) {
  return getData('rdwReceivedCars', dataApi.getRdwReceivedCars, data)
}

export function setAccessoriesHighlight(id, highlight) {
  return {
    type: types.SET_ACCESSORIES_HIGHLIGHT,
    payload: {
      id,
      highlight,
    },
  }
}

export function createMarktplaatsBulkAction(data, callback) {
  return addData(
    'marktplaatsBulkAction',
    dataApi.createMarktplaatsBulkAction,
    data,
    null,
    callback,
  )
}

export function getMarktplaatsBulkActionStatus(callback) {
  return getData(
    'marktplaatsBulkActionStatus',
    dataApi.getMarktplaatsBulkActionStatus,
    null,
    null,
    callback,
  )
}

export function getPriceValuation(id, data) {
  return addData(id, dataApi.getPriceValuation, data)
}
