import { types } from 'redux/actions/forms'
import forms from 'config/forms'

export const initialState = {
  ...forms,
}

export function reducer(state = initialState, action) {
  const { data, error, id, payload, type } = action

  switch (type) {
    case types.REQUEST_FORMS: {
      return {
        ...state,
        [id]: {
          ...state[id],
          error: null,
          loading: true,
          payload,
        },
      }
    }

    case types.RECEIVE_FORMS: {
      return {
        ...state,
        [id]: {
          ...state[id],
          loading: false,
          data,
        },
      }
    }

    case types.FAIL_REQUEST: {
      return {
        ...state,
        [id]: {
          ...state[id],
          loading: false,
          error,
        },
      }
    }

    default:
      return state
  }
}
