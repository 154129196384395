import { API_ERROR_MESSAGES } from 'utilities/api'
import marketviewData from 'utilities/marktview-data'

export const types = {
  REQUEST_MARKETVIEW_DATA: 'REQUEST_MARKETVIEW_DATA',
  POST_MARKETVIEW_DATA: 'POST_MARKETVIEW_DATA',
  RECEIVE_MARKETVIEW_DATA: 'RECEIVE_MARKETVIEW_DATA',
  FAIL_MARKETVIEW_DATA_REQUEST: 'FAIL_MARKETVIEW_DATA_REQUEST',
}

function requestMarketviewData(key) {
  return {
    type: types.REQUEST_MARKETVIEW_DATA,
    payload: {
      key,
    },
  }
}

function postMarketviewData(key, data) {
  return {
    type: types.POST_MARKETVIEW_DATA,
    payload: {
      key,
      data,
    },
  }
}

function receiveMarketviewData(key, data) {
  return {
    type: types.RECEIVE_MARKETVIEW_DATA,
    payload: {
      key,
      data,
    },
  }
}

function failMarketviewDataRequest(payload) {
  return {
    type: types.FAIL_MARKETVIEW_DATA_REQUEST,
    payload,
  }
}

export function getMarketviewData(type, currentParams, signal) {
  return (dispatch, getState) => {
    const instance = getState().auth.instanceId

    dispatch(requestMarketviewData(type))

    return marketviewData
      .getMarketviewData(type, currentParams, instance, signal)
      .then((response) => {
        if (!response) {
          dispatch(failMarketviewDataRequest('no response'))
          return Promise.reject(new Error('Could not get Marketview data'))
        }

        dispatch(receiveMarketviewData(type, response))
        return Promise.resolve(response)
      })
      .catch((error) => {
        if (error !== API_ERROR_MESSAGES.ABORT_CONTROLLER_CANCELLED_REQUEST) {
          dispatch(failMarketviewDataRequest(error))
        }
      })
  }
}

export function setMarketViewData(type, data) {
  return (dispatch, getState) => {
    const instance = getState().auth.instanceId

    dispatch(postMarketviewData(type, data))

    return marketviewData
      .postMarketviewData(type, data, instance)
      .then((response) => {
        if (!response) {
          dispatch(failMarketviewDataRequest('no response'))
          return Promise.reject(new Error('Could not set Marketview data'))
        }

        dispatch(receiveMarketviewData(type, response))
        return Promise.resolve()
      })
      .catch((error) => {
        if (error !== API_ERROR_MESSAGES.ABORT_CONTROLLER_CANCELLED_REQUEST) {
          dispatch(failMarketviewDataRequest(error))
        }
      })
  }
}
