import React from 'react'
import PropTypes from 'prop-types'

import * as Yup from 'yup'
import LicensePlateInput from 'components/atoms/license-plate-input'
import NewTextInput from 'components/atoms/new-text-input'
import NewDropDownSelect from 'components/molecules/new-dropdown-select'
import DatePicker from 'components/atoms/new-date-picker'
import Switch from 'components/atoms/switch'
import LabeledCheckbox from 'components/atoms/labeled-checkbox'
import LabeledCheckboxGroup from 'components/molecules/labeled-checkbox-group'
import NewOptionsSelect from 'components/atoms/new-options-select'
import styled from 'styled-components'

const StyledLabeledCheckboxComponent = styled(LabeledCheckbox)`
  > span {
    color: ${({ $error, theme }) =>
      $error ? `${theme.colors.errorText}` : 'currentColor'};
  }
`

const createFormikField = (Component) => {
  const WrappedComponent = ({
    field,
    form,
    onChange,
    validationSchema,
    required,
    ...props
  }) => {
    if (!form) {
      return <span>Formik field must be used within Formik context</span>
    }
    // eslint-disable-next-line no-undef
    const touched = form.touched[field.name]
    const submitted = form.submitCount > 0

    let error
    if (touched || submitted) {
      try {
        // Eval used to find errors with field.name referring to a fieldArray.
        // Wrapped in try catch to stop code stopping if eval fails
        // eslint-disable-next-line no-eval
        error = form.errors[field.name] || eval(`form.errors.${field.name}`)
      } catch (e) {
        // Error not caught because if eval fails, there shouldn't be an error for this field.
      }
    }

    /**
     * If required is set on the field explicitly as a prop
     * that will be used. Otherwise it will get it from the validationSchema,
     * when provided.
     */
    let derivedRequiredness = required
    if (typeof derivedRequiredness === 'undefined' && validationSchema) {
      /**
       * A better way would be to extract this info from the
       * [FormikBag](https://formik.org/docs/api/
       * withFormik#handlesubmit-values-values-formikbag-formikbag--void--promiseany)
       * (form parameter), but Formik does not support that right now.
       * There is a feature request for this: https://github.com/formium/formik/issues/797
       */
      try {
        derivedRequiredness = Yup.reach(validationSchema, field.name)
          .describe()
          .tests.some((rule) => rule.name === 'required')
      } catch (e) {
        derivedRequiredness = false
      }
    }

    const handleCheckboxGroupChange = (value, checked) => {
      const prevValue = field.value || []
      const newSetOfValues = checked
        ? [...prevValue, value]
        : prevValue.filter((val) => val !== value)
      form.setFieldValue(field.name, newSetOfValues)
      if (onChange) {
        onChange(newSetOfValues)
      }
    }

    const handleOptionsSelectChange = (values) => {
      const newValues = values.map((option) => option.value)
      form.setFieldValue(field.name, newValues, false)
      if (onChange) {
        onChange(newValues)
      }
    }

    const handleChange = (value) => {
      // Sometimes checkboxes are grouped using the same field name
      // The selected values need to be collected in an array for that field:
      if (Array.isArray(field.value)) {
        let newSetOfValues = []
        if (value) {
          newSetOfValues = [...field.value, props.value]
        } else {
          newSetOfValues = field.value.filter((item) => item !== props.value)
        }
        form.setFieldValue(field.name, newSetOfValues)
        if (onChange) {
          onChange(newSetOfValues)
        }
      } else {
        const valueToSet = value === null ? '' : value
        form.setFieldValue(field.name, valueToSet)
        if (onChange) {
          onChange(valueToSet)
        }
      }
    }
    const onBlur = () => form.setFieldTouched(field.name, true)

    if (Component === LabeledCheckbox) {
      let isChecked = field.value

      if (Array.isArray(field.value)) {
        isChecked = field.value.includes(props.value)
      }

      return (
        <StyledLabeledCheckboxComponent
          {...field}
          {...props}
          required={derivedRequiredness}
          id={props.value}
          checked={isChecked}
          onBlur={onBlur}
          error={error}
          onChange={handleChange}
          $error={!!error}
        />
      )
    } else if (Component === NewOptionsSelect) {
      return (
        <Component
          chipSize="medium"
          filterSelectedOptions
          disableAddOption
          {...field}
          {...props}
          onBlur={onBlur}
          error={error}
          required={derivedRequiredness}
          onChange={handleOptionsSelectChange}
        />
      )
    } else if (Component === Switch) {
      return (
        <Component
          {...field}
          {...props}
          required={derivedRequiredness}
          value={field.value}
          checked={field.value}
          onBlur={onBlur}
          error={error}
          onChange={handleChange}
        />
      )
    } else {
      return (
        <Component
          {...field}
          {...props}
          required={derivedRequiredness}
          value={field.value}
          checked={field.value}
          onBlur={onBlur}
          error={error}
          onChange={
            Component === LabeledCheckboxGroup
              ? handleCheckboxGroupChange
              : handleChange
          }
        />
      )
    }
  }

  WrappedComponent.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    /**
     * A Yup validation scheme, anything other will
     * be gracefully ignored.
     */
    validationSchema: PropTypes.any,
    /**
     * A way to set requiredness for a field
     * if a validationSchema is not provided. A validationSchema
     * will override the requiredness.
     */
    required: PropTypes.bool,
  }

  WrappedComponent.defaultProps = {
    onChange: undefined,
    validationSchema: undefined,
    required: undefined,
  }

  return WrappedComponent
}

export const FormikTextInput = createFormikField(NewTextInput)
export const FormikLabeledCheckBox = createFormikField(LabeledCheckbox)
export const FormikLabeledCheckBoxGroup =
  createFormikField(LabeledCheckboxGroup)
export const FormikSelectInput = createFormikField(NewDropDownSelect)
export const FormikDatePicker = createFormikField(DatePicker)
export const FormikSwitch = createFormikField(Switch)
export const FormikOptionsSelect = createFormikField(NewOptionsSelect)

const WrappedLicensePlateInput = ({ onChange, ...restProps }) => (
  <LicensePlateInput
    onChange={(event) => onChange(event.target.value)}
    {...restProps}
  />
)

WrappedLicensePlateInput.propTypes = {
  /** A change handler which will get the value of the input as its argument */
  onChange: PropTypes.func,
  /** Any Yup validation schema, used to determine if a field is required */
  validationSchema: PropTypes.any,
}

WrappedLicensePlateInput.defaultProps = {
  onChange: null,
  validationSchema: null,
}
export const FormikLicensePlateInput = createFormikField(
  WrappedLicensePlateInput,
)
