import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import LegendBoxes from 'components/molecules/legend-boxes'

const StyledLegendBoxes = styled(LegendBoxes)`
  flex-shrink: 1;
  transition: opacity ease-in 0.125s;

  & [data-element='legend-box-container'] {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    & span {
      white-space: nowrap;
    }
  }
`

/**
 * Note: Only use this component in the context of the management info page.
 * It's seperated into a molecule because it's being re-used in different charts,
 * yet it's not meant to be used outside of the management info page.
 */
function ManagementInfoChartLegend({ className, items, unit }) {
  return (
    <StyledLegendBoxes
      className={className}
      arrangeAs="row"
      items={items}
      unit={unit}
    />
  )
}

// These props are copied from the 'legend-boxes.js' component
ManagementInfoChartLegend.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      parentIsDarker: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
    }),
  ).isRequired,
  unit: PropTypes.string,
}

export default ManagementInfoChartLegend
