import { gql } from '@apollo/client'

export const BPM_DOCUMENTS = gql`
  query bpmDocuments($carFileId: ID!) {
    bpmDocuments(first: 1, carFileId: $carFileId) {
      edges {
        node {
          id
          uri
        }
      }
    }
  }
`

export const GENERATE_BPM_REPORT = gql`
  query generateBpmReport(
    $resultId: ID!
    $deprecrationType: BpmDeprecationEnum!
    $rsin: String
    $bsn: String
    $vin: String!
    $date: Date
  ) {
    generateBpmReport(
      resultId: $resultId
      deprecrationType: $deprecrationType
      rsin: $rsin
      bsn: $bsn
      vin: $vin
      date: $date
    ) {
      id
      uri
    }
  }
`

export const SEND_BPM_APPRAISAL_REQUEST = gql`
  query bpmSendAppraisalRequest($carId: ID!, $appraiserId: ID!) {
    bpmSendAppraisalRequest(carId: $carId, appraiserId: $appraiserId) {
      success
      appraiser {
        id
        name
      }
    }
  }
`

export const GET_BPM_APPRAISERS = gql`
  query bpmAppraisers($dealerId: ID!) {
    bpmAppraisers(dealerId: $dealerId) {
      id
      name
    }
  }
`

export const CALCULATE_GROSS_BPM_CO2_DIFFERENCE = gql`
  query calculateGrossBpmCo2Difference($carFileId: ID!) {
    calculateGrossBpmCo2Difference(carFileId: $carFileId) {
      originalMarketPrice
      co2BpmCorrection
      co2CorrectedMarketPrice
    }
  }
`

export const CALCULATE_BPM = gql`
  query calculateBpm($carFileId: ID!, $type: BpmCalculationEnum) {
    calculateBpm(carFileId: $carFileId, type: $type) {
      id
      grossResult {
        period
        deprecation {
          type
          factor
        }
        value
        value_gross
      }
      consideredResults {
        success
        message
        profitPotential
        period
        deprecation {
          type
          factor
        }
        value
        value_gross
        specificationLines {
          lines {
            label
            value
            styles
          }
        }
      }
    }
  }
`
