import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import Tag from 'components/atoms/tag'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.sizeByFactor(0.75)};
`

const TagsList = ({ className, tags = [] }) => {
  if (!tags.length) {
    return
  }

  return (
    <Container className={className}>
      {tags.map(({ label, ...tagProps }) => (
        <Tag key={label} label={label} size="small" {...tagProps} />
      ))}
    </Container>
  )
}

TagsList.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      className: PropTypes.string,
      size: PropTypes.oneOf(['small', 'medium']),
      icon: PropTypes.string,
      iconColor: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
}

TagsList.defaultProps = {
  className: null,
  tags: [],
}

export default TagsList
