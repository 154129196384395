import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Typography from 'components/molecules/typography'
import { media } from 'utilities/styled'
import ConfettiBackground from 'components/layouts/confetti-background'
import Button from 'components/atoms/button'
import InlinedItems from 'components/layouts/inlined-items'
import { CAR_FILE, CSP_CONTRACT_FILE } from 'config/routes'

const ConfettiWrapper = styled(ConfettiBackground)`
  text-align: center;
  overflow: hidden;
  padding: 0 ${({ theme }) => theme.sizings.lvl2}
    ${({ theme }) => theme.sizings.lvl3};

  ${media.tv`
    padding-bottom: ${({ theme }) => theme.sizings.lvl6};
  `}
`

const StyledTitle = styled(Typography)`
  padding: 0 10%;

  ${media.desktop`
    padding: 0
  `}
`

const StyledSubtitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl4};
`

const CspSalesSuccess = ({ payload }) => {
  const { t } = useTranslation()
  const { id: contractId, carFileId } = payload

  return (
    <ConfettiWrapper data-test-e2e="confetti">
      <StyledTitle type="Level1Heading">
        {t('cspSalesFlow.steps.success.title')}
      </StyledTitle>
      <StyledSubtitle type="Level2Heading">
        {t('cspSalesFlow.steps.success.subtitle')}
      </StyledSubtitle>
      <InlinedItems hasCenteredChildren>
        {carFileId && (
          <Button
            text={t('cspSalesFlow.steps.success.toCarFile')}
            to={`${CAR_FILE}/${carFileId}`}
            data-test-e2e="button-to-car-file"
          />
        )}
        <Button
          text={t('cspSalesFlow.steps.success.toContractFile')}
          to={`${CSP_CONTRACT_FILE}/${contractId}`}
          level="cta"
          data-test-e2e="button-to-contract"
        />
      </InlinedItems>
    </ConfettiWrapper>
  )
}

CspSalesSuccess.propTypes = {
  payload: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    carFileId: PropTypes.string,
  }).isRequired,
}

export default CspSalesSuccess
