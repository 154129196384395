// EXTERNAL
import React, { useRef, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

// INTERNAL
import { scrollTo } from 'utilities/utils'
import MediaForm from 'components/organisms/media-form'
import Text from 'components/atoms/text'
import { BPM_DOCUMENTS } from 'config/graphql/v4'
import LoadingIndicator from 'components/atoms/loading-indicator'
import GeneralDataError from 'components/organisms/general-data-error'
import Typography from 'components/molecules/typography'
import { withApolloV4Provider } from 'utilities/apollo'

const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const BpmDocuments = () => {
  const { t } = useTranslation()
  const resultDiv = useRef()
  const carFile = useSelector(
    (state) => state.data && state.data.carfile && state.data.carfile.data,
  )
  const carId = carFile.auto_id
  const { data, loading, error } = useQuery(BPM_DOCUMENTS, {
    variables: {
      carFileId: carId,
    },
  })
  const documents = data?.bpmDocuments?.edges

  useEffect(() => {
    if (!loading && resultDiv.current) {
      scrollTo(resultDiv.current)
    }
  }, [loading])

  if (loading) {
    return <LoadingIndicator />
  }
  if (error) {
    return <GeneralDataError error={error.message} />
  }

  return (
    <div ref={resultDiv}>
      <StyledText type="h2">{t('bpmDeclarationForm')}</StyledText>
      {Array.isArray(documents) && documents.length > 0 ? (
        <MediaForm
          level="standard"
          items={documents.map(({ node: document }) => ({
            name: document.id,
            src: document.uri,
            type: 'document',
          }))}
          type="document"
          single
        />
      ) : (
        <Typography type="ExplanationParagraph">{t('noResults')}</Typography>
      )}
    </div>
  )
}

export default withApolloV4Provider(BpmDocuments)
