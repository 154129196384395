import BodyHeading from 'components/atoms/body-heading'
import StaticValuesCollection from 'components/molecules/static-values-collection'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import styled from 'styled-components/macro'
// import { media } from 'utilities/styled'

const RenderCollection = ({ fieldset, values, level }) => {
  const { t } = useTranslation()
  /**
   * If `fielset.fields` contain no meaningful data don't
   * bother showing the entire section
   */
  const isMeaningless = fieldset.fields.every((field) => {
    return ['options_select', 'accessoires_select'].includes(field.type)
  })
  return (
    <>
      {!isMeaningless && (
        <>
          <BodyHeading level="2">{t(fieldset.title)}</BodyHeading>
          <StaticValuesCollection
            collection={fieldset.fields
              .map((field) => {
                switch (field.type) {
                  case 'options_select':
                  case 'hidden':
                  case 'accessoires_select': {
                    return null
                  }
                  case 'table':
                  case 'formTable':
                    return {
                      title: field.title ? t(field.title) : null,
                      value: field.data,
                      displayType: 'table',
                    }
                  case 'link':
                    return {
                      title: t(field.title),
                      value: values[field.name],
                      displayType: 'link',
                    }
                  case 'info':
                    return {
                      title: t(field.title),
                      value: field.value,
                      displayType: 'message',
                    }
                  case 'date':
                    return {
                      ...field,
                      title: t(field.title),
                      value: values[field.name],
                      displayType: 'date',
                    }
                  case 'checkbox':
                    if (field.price !== undefined) {
                      // even if it is null
                      return {
                        title: t(field.title),
                        value: values[field.name],
                        price: values[field.name]?.bedrag,
                        displayType: 'carAccessory',
                        hideAFalsyPrice: true,
                      }
                    } // if not fall through to default:
                  case 'package_select':
                  case 'select':
                  case 'textarea':
                  default:
                    // displayType defaults to simple dimensionless (no units) bit of text:
                    return {
                      ...field,
                      title: field.title ? t(field.title) : null,
                      value: values[field.name],
                    }
                }
              })
              .filter((item) => item != null)}
            columns={3}
          />
        </>
      )}
    </>
  )
}

RenderCollection.propTypes = {
  fieldset: PropTypes.shape({
    fields: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  values: PropTypes.object.isRequired,
  level: PropTypes.oneOf(['2', '3']).isRequired,
}

/**
 * This is the static counterpart of ComplexForm, as in
 * it takes the same configuration and displays that as static content instead
 */
const DynamicStaticValuesCollections = ({ formSetup, values }) => {
  return (
    <section>
      {formSetup.fieldsets.map((fieldset, index) => (
        <React.Fragment key={`${fieldset.title}-${index}`}>
          {fieldset.groupedFieldSet
            ? fieldset.fieldsets.map((fieldset, subIndex) => {
                return (
                  fieldset && (
                    <RenderCollection
                      key={`${fieldset.title}-${index}-${subIndex}`}
                      fieldset={fieldset}
                      values={values}
                      level="3"
                    />
                  )
                )
              })
            : fieldset && (
                <RenderCollection
                  fieldset={fieldset}
                  values={values}
                  level="2"
                />
              )}
        </React.Fragment>
      ))}
    </section>
  )
}

DynamicStaticValuesCollections.propTypes = {
  formSetup: PropTypes.shape({
    fieldsets: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.array,
        title: PropTypes.string,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  values: PropTypes.object.isRequired,
}

export default DynamicStaticValuesCollections
