import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from 'utilities/utils'
import Text, { styles as textStyles } from 'components/atoms/text'

const Container = styled.div`
  background: ${({ error, theme }) =>
    error ? theme.colors.actionsCtaTertiery : theme.colors.brandFoxtrot};
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 36px;
  padding: 0 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.brandDelta};
`

const Label = styled(Text)`
  align-self: center;
  color: ${({ theme }) => theme.colors.brandCharlie};
  display: flex;
  flex: 0 1 auto;
  margin-right: ${({ theme }) => theme.sizings.lvl1};
  white-space: nowrap;
`

const Select = styled(Text)`
  ${textStyles.floatingLabelInput};
  border: none;
  border-radius: 0;
  background-color: transparent;
  margin-left: auto;
  padding: 0 30px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-align-last: right;
  text-overflow: ellipsis;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20height%3D%225%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%2010%205%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23999%22%20stroke%3D%22%23999%22%20class%3D%22StyledSvg-sc-11vgbka-2%20ejApbw%22%3E%0A%20%20%20%20%3Cg%20stroke%3D%22none%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M0%200l5%205%205-5z%22%3E%3C%2Fpath%3E%0A%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E');
  background-position: right;
  background-repeat: no-repeat;
  direction: rtl;
`

const StyledOption = styled.option`
  ${textStyles.floatingLabelDropdownMenuOption};
  direction: ltr;
`

function DropdownMenuFloatingLabel({
  error,
  initialValue,
  items,
  name,
  label,
  onChange,
  value,
  className,
  required,
}) {
  const { t } = useTranslation()
  const constructedLabel = label && `${label}${required ? '*' : ''}:`
  const processedItems = items

  // When an inital value is given which is not part
  // of the available items it should be added to the
  // items
  if (initialValue && initialValue.length) {
    const itemValues = items.map((item) =>
      typeof item.value === 'string'
        ? item.value.toLowerCase()
        : item.value.toString().toLowerCase(),
    )
    const initialValueNotInItems =
      itemValues.indexOf(initialValue.toLowerCase()) === -1

    if (initialValueNotInItems) {
      processedItems.push({
        value: initialValue,
        label: initialValue,
      })
    }
  }

  return (
    <Container className={className} error={error}>
      {/*
        Capitalizing first letter using CSS here because
        using the ::first-letter pseudo selector isn't
        supported in flex elements which this label has
        to be to use it's available space optimally
      */}
      {constructedLabel && (
        <Label
          tag="label"
          htmlFor={name}
          type="menu"
          text={capitalizeFirstLetter(constructedLabel)}
        />
      )}
      <Select
        tag="select"
        type="input"
        id={name}
        name={name}
        selected={value}
        value={value}
        onChange={onChange}
      >
        {!value && <StyledOption value="">{t('select')}</StyledOption>}
        {processedItems.map((item, index) => (
          <StyledOption key={index.toString()} value={item.value || index}>
            {item.label}
          </StyledOption>
        ))}
      </Select>
    </Container>
  )
}

DropdownMenuFloatingLabel.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  initialValue: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

DropdownMenuFloatingLabel.defaultProps = {
  error: null,
  className: null,
  initialValue: null,
  label: null,
  required: false,
  value: undefined,
}

export default DropdownMenuFloatingLabel
