import { gql } from '@apollo/client'

import brandInfoFragment from './fragments/brand'
import modelInfoFragment from './fragments/model'

export * from './queries/approval'
export * from './queries/calculations'
export * from './queries/car-services'
export * from './queries/car-service-plans'
export * from './queries/cars'
export * from './queries/mileage'
export * from './queries/contracts'
export * from './queries/customers'
export * from './queries/dealers'
export * from './queries/documents'
export * from './queries/drivers'
export * from './queries/fuel-types'
export * from './queries/invoices'
export * from './queries/packages'
export * from './queries/price-matrices'
export * from './queries/prospects'
export * from './queries/warranty-programs'
export * from './queries/leads'

export const GET_DEALER_AND_GET_CAR_MODELS = gql`
  query GetDealerAndCarModels($dealerId: ID) {
    dealer(id: $dealerId) {
      id
      name
      carServicePlan {
        id
      }
    }
    brands {
      ...brandInfo
      models {
        ...modelInfo
      }
    }
  }
  ${brandInfoFragment}
  ${modelInfoFragment}
`

export const GET_WARRANTY_PROVIDERS_AND_CAR_MODELS_QUERY = gql`
  query {
    warrantyProviders(first: 9000) {
      edges {
        node {
          id
          name
        }
      }
    }
    brands {
      ...brandInfo
      models {
        ...modelInfo
      }
    }
  }
  ${brandInfoFragment}
  ${modelInfoFragment}
`

export const calculationSourceEnum = {
  carFile: 'CAR_FILE',
  b2b: 'B2B',
  flow: 'FLOW',
  other: 'OTHER',
}
