import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.isolatedBackground};
  padding: ${({ theme }) => theme.sizings.lvl3};
`

const Header = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: ${({ theme }) => theme.sizings.lvl3};

  > *:first-child {
    margin-right: ${({ theme }) => theme.sizings.lvl1};
  }

  > *:last-child {
    margin-left: auto;
  }
`

function Comment({ className, commenter, comment, date }) {
  return (
    <Container className={className}>
      <Header>
        <Icon color="brandCharlie" size="md" type="user" />
        <Text color="brandCharlie" type="menu" text={commenter} weight={500} />
        <Text color="brandCharlie" type="menu" text={date} weight={500} />
      </Header>
      {/* @TODO Sanitize HTML */}
      <Text dangerouslySetInnerHTML={{ __html: comment }} />
    </Container>
  )
}

Comment.propTypes = {
  className: PropTypes.string,
  commenter: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
}

Comment.defaultProps = {
  className: null,
}

export default Comment
