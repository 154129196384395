import TodosDashboardOverview from 'components/views/todos-dashboard-overview'
import { connect } from 'react-redux'
import { getLocations, getLocationTodos } from 'redux/actions/data'
import { openOverlay } from 'redux/actions/ui'

const mapStateToProps = ({ data }) => ({
  locationTodos: data.locationTodos,
})

const mapDispatchToProps = {
  getLocations,
  getLocationTodos,
  openOverlay,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TodosDashboardOverview)
