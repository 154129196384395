import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

const Component = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
`

const ScrollContainer = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const Table = styled.table`
  position: relative;
  z-index: 1;
`

const HeadCell = styled.th`
  ${({ $isStickyHorizontal }) => ($isStickyHorizontal ? 'left: 0;' : '')}
  ${({ $isStickyVertical }) => ($isStickyVertical ? 'top: 0;' : '')}
  position: ${({ $isStickyHorizontal, $isStickyVertical }) =>
    $isStickyHorizontal || $isStickyVertical ? 'sticky' : 'relative'};
  ${({ $width }) =>
    typeof $width !== 'undefined'
      ? `width: ${typeof $width === 'number' ? `${$width}%` : $width};`
      : ''}
  ${({ $isStickyHorizontal, $isStickyVertical }) =>
    $isStickyHorizontal || $isStickyVertical ? 'z-index: 3;' : ''}
  ${({ $bgColor, theme }) =>
    $bgColor ? `background-color: ${theme.colors[$bgColor] || $bgColor};` : ''}
  ${({ $isStickyHorizontal, $isStickyVertical }) =>
    $isStickyHorizontal || $isStickyVertical
      ? `
    &:first-child {
      z-index: 10;
    }
  `
      : ''}
`

const Cell = styled.td`
  left: 0;
  position: ${({ $isSticky }) => ($isSticky ? 'sticky' : 'relative')};
  ${({ $isSticky }) => ($isSticky ? 'z-index: 2;' : '')}
  padding: 0;
  text-align: left;
  ${({ $width }) =>
    typeof $width !== 'undefined'
      ? `width: ${typeof $width === 'number' ? `${$width}%` : $width};`
      : ''}
  ${({ $bgColor, theme }) =>
    $bgColor ? `background-color: ${theme.colors[$bgColor] || $bgColor};` : ''}
`

const ScrollableTable = ({
  columns,
  rows,
  stickyFirstRow,
  stickyFirstColumn,
  firstRowBgColor,
  firstColumnBgColor,
  className,
  ...restProps
}) => {
  /**
   * Cells can be either React nodes, or an object with the following structure:
   *
   * {
   *  content: ReactNode bgColor: string
   * }
   *
   * This function converts all cells that are React nodes into objects as described above. This
   * makes iterating over them easier.
   */
  const normalizedRows = React.useMemo(
    () =>
      rows.map((row) =>
        row.map((cell, i) =>
          typeof cell !== 'object' ||
          !Object.prototype.hasOwnProperty.call(cell, 'content')
            ? { content: cell, id: i }
            : {
                ...cell,
                id: typeof cell.id === 'string' ? cell.id : i,
              },
        ),
      ),
    [rows],
  )

  return (
    <Component className={className}>
      <ScrollContainer className="scroll-container">
        <Table className="table" {...restProps}>
          {Array.isArray(columns) &&
            columns.length > 0 &&
            columns.findIndex((column) => !!column.content) >= 0 && (
              <thead>
                <tr>
                  {columns.map((row, i) => (
                    <HeadCell
                      key={i}
                      $isStickyHorizontal={stickyFirstColumn && i === 0}
                      $isStickyVertical={stickyFirstRow}
                      $bgColor={row.bgColor || firstRowBgColor}
                      $width={row.width}
                      className={`headCell${i === 0 ? ' firstHeadCell' : ''}`}
                    >
                      {row.content}
                    </HeadCell>
                  ))}
                </tr>
              </thead>
            )}
          <tbody>
            {normalizedRows.map((cells, i) => (
              <tr key={i}>
                {cells.map((cell, k) => (
                  <Cell
                    key={k}
                    $isSticky={stickyFirstColumn && k === 0}
                    className={`cell${k === 0 ? ' firstCell' : ''}`}
                    $bgColor={cell.bgColor || (k === 0 && firstColumnBgColor)}
                  >
                    {cell.content}
                  </Cell>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollContainer>
    </Component>
  )
}

export const propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.node,
      /* The width of this column. This will be set in CSS as a value on the column.
       * This can be defined as a number, or a string. A numberic value will be
       * treated as a percentage, while strings will be used as-is. Examples:
       * 20: will be used as "width: 20%;"
       * "100px": will be used as: "width: 100px;"
       */
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      // The background color for this cell. This can be a name of a color from the theme (i.e.
      // "brandPrimary"), or a valid CSS string.
      bgColor: PropTypes.string,
    }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
      PropTypes.shape({
        content: PropTypes.node.isRequired,
        bgColor: PropTypes.string,
        id: PropTypes.string, // Used as the cells key, if set. Could be useful for re-rendering.
      }).isRequired,
    ]),
  ).isRequired,
  stickyFirstRow: PropTypes.bool,
  stickyFirstColumn: PropTypes.bool,
  /**
   * By default, all cells are transparent. That means that cells that are not sticky can be seen
   * behind the sticky first row/column. Set these two properties if you want to prevent this
   * effect.
   *
   * Note: this can be a name of a color from the theme (i.e. "brandPrimary"), or a valid CSS
   * string.
   */
  firstRowBgColor: PropTypes.string,
  firstColumnBgColor: PropTypes.string,
}

ScrollableTable.propTypes = {
  ...propTypes,
}

ScrollableTable.defaultProps = {
  rows: [],
}

export default ScrollableTable
