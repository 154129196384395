import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Text from 'components/atoms/text'

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 36px;
  background: ${({ theme }) => theme.colors.brandFoxtrot};
  padding: 0 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.brandDelta};
`

const Title = styled(Text)`
  align-self: center;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.brandCharlie};
  text-transform: none;

  &::first-letter {
    text-transform: uppercase;
  }
`

const StyledInput = styled.input`
  min-width: auto;
  display: flex;
  align-self: center;
`

function CheckboxFloatingLabel({
  checked,
  name,
  id,
  onChange,
  title,
  value,
  ...restProps
}) {
  return (
    <Container {...restProps}>
      {title && (
        <Title
          tag="label"
          htmlFor={id || name}
          type="menu"
          text={`${title}:`}
        />
      )}
      <StyledInput
        checked={checked}
        id={id || name}
        name={name}
        onChange={(e) => onChange(e.target.checked)}
        type="checkbox"
        value={value}
      />
    </Container>
  )
}

CheckboxFloatingLabel.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  value: PropTypes.string,
}

CheckboxFloatingLabel.defaultProps = {
  checked: false,
  id: null,
  title: null,
  value: '',
}

export default CheckboxFloatingLabel
