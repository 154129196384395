import { connect } from 'react-redux'
import { openOverlay } from 'redux/actions/ui'
import { getUsersForLocation, postDynamicEndpoint } from 'redux/actions/data'

import LocationUserSettings from '../organisms/location-user-settings'

const mapStateToProps = ({ data }) => ({
  usersForLocation: data.usersForLocation,
  dynamicEndpoint: data.dynamicEndpoint,
})

const mapDispatchToProps = {
  openOverlay,
  getUsersForLocation,
  postDynamicEndpoint,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationUserSettings)
