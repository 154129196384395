import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useQuery, useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import NewOptionsSelect from 'components/atoms/new-options-select'
import LoadingButton from 'components/atoms/loading-button'
import Button from 'components/atoms/button'
import Icon from 'components/atoms/icon'
import ExpandableTagsList from 'components/molecules/expandable-tags-list'
// import LoadingIndicator from 'components/atoms/loading-indicator'

import * as routes from 'config/routes'
import { READ_TAGS, TAG_CARFILE_SYNC } from 'config/graphql/v4/queries/tags'

import { withApolloV4Provider } from 'utilities/apollo'
import toast from 'utilities/toast'

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.sizeByFactor(1)};
`

const TagIcon = styled(Icon)`
  flex-shrink: 0;
  margin-top: ${({ theme }) => theme.sizeByFactor(0.625)};
`

const StyledExpandableTagsList = styled(ExpandableTagsList)`
  margin-top: ${({ theme }) => theme.sizeByFactor(0.25)};
  padding-left: ${({ theme }) => theme.sizeByFactor(0.375)};
  padding-top: ${({ theme }) => theme.sizeByFactor(0.125)};
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizeByFactor(0.5)};
  align-items: flex-start;
  flex-grow: 1;

  > div {
    width: 100%;
  }

  & .MuiInputBase-root {
    padding: 0 0
      ${({ theme, $inClickableTagsMode }) =>
        $inClickableTagsMode ? '0' : theme.sizeByFactor(1)}
      0;
  }

  & .MuiAutocomplete-endAdornment {
    display: ${({ $inClickableTagsMode }) =>
      $inClickableTagsMode ? 'none' : undefined};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.sizeByFactor(0.5)};
  margin-top: ${({ theme }) => theme.sizeByFactor(1.5)};
`

// Maps the selected tags for the tag list
const mapTagsForTagList = (
  t,
  history,
  tags,
  setIsEditingTags,
  setInitialValuesLoaded,
  readOnly,
) =>
  readOnly
    ? tags
    : [
        ...tags.map(({ id, label }) => ({
          label,
          onClick: () =>
            history.push(`${routes.STOCK}?status=binnen&tags=${id}`),
        })),
        {
          label: t('edit'),
          level: 'cta',
          icon: 'edit',
          isEditTag: true,
          onClick: () => {
            setIsEditingTags(true)
            setInitialValuesLoaded(true)
          },
        },
      ]

// Maps the selected tags for the 'new-option-select' input field
const mapTagsForSelect = (tags) =>
  tags.map(({ id, label }) => ({ label, value: id }))

const CarfileTagSelector = ({ className, readOnly }) => {
  const { t } = useTranslation()
  const history = useHistory()

  // Selectors that gets data from the V3 API
  const carFileId = useSelector((state) => state?.data?.carfile?.data?.auto_id)
  const carFileIsLoading = useSelector((state) => state?.data?.carfile?.loading)
  const carFileTagsRaw =
    useSelector((state) => state?.data?.carfile?.data?.tags) || []
  const carFileHasTags = !!carFileTagsRaw.length

  // Query that gets data from the V4 API
  const {
    data: allTagsRaw,
    loading: allTagsLoading,
    error: allTagsError,
  } = useQuery(READ_TAGS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  // States to show a difference in the UI when the user wants to attach / detach tags, or when the 'new-options-select' component is submitting
  const [isEditingTags, setIsEditingTags] = useState(false)

  // States for 'new-options-select' component
  const valuesForSelect = useMemo(
    () => mapTagsForSelect(carFileTagsRaw),
    [carFileTagsRaw],
  )
  const [initialValuesForSelect, setInitialValuesForSelect] = useState([]) // useState is needed to differ between initialValues after making a mutation and updating tags
  const [initialValuesLoaded, setInitialValuesLoaded] = useState(false) // useState is needed to differ between initialValues after making a mutation and updating tags
  const [selectedValuesForSelect, setSelectedValuesForSelect] = useState([])
  const optionsForSelect = useMemo(
    () => (allTagsRaw?.tags ? mapTagsForSelect(allTagsRaw.tags) : []),
    [allTagsRaw],
  )

  // States for the 'expandable-tags-list' component
  const [tagsListIsExpanded, setTagsListIsExpanded] = useState(false)
  const initialValuesForTagList = useMemo(
    () =>
      carFileTagsRaw
        ? mapTagsForTagList(
            t,
            history,
            carFileTagsRaw,
            setIsEditingTags,
            setInitialValuesLoaded,
            readOnly,
          )
        : [],
    [readOnly, carFileTagsRaw, history, t],
  )
  const [selectedValuesForTagList, setSelectedValuesForTagList] = useState([])

  // Mutation that attaches / detaches the tags to / from the carFile
  const [
    syncCarfileTagsMutation,
    { loading: syncCarfileTagsLoading, error: syncCarfileTagsError },
  ] = useMutation(TAG_CARFILE_SYNC, { awaitRefetchQueries: true })

  const hasModifiedTags = () =>
    JSON.stringify(initialValuesForSelect) !==
    JSON.stringify(selectedValuesForSelect)

  const handleOnChangeOptions = (values) => {
    setSelectedValuesForSelect(values)
  }

  const handleClickOnSaveTagsButton = () => {
    // Try the mutation
    syncCarfileTagsMutation({
      variables: {
        carFileId,
        tags: { sync: selectedValuesForSelect.map((tag) => tag.value) },
      },
    })
      .then(({ data }) => {
        const {
          tagCarSync: { tags },
        } = data

        // Update the tag values in the tags list
        const updatedTagsForTagList = mapTagsForTagList(
          t,
          history,
          tags,
          setIsEditingTags,
          setInitialValuesLoaded,
          readOnly,
        )
        setSelectedValuesForTagList(updatedTagsForTagList)

        // Update the initial values in the select component, this is needed so "hasModifiedTags()" will be re-evaluated if the user modifies tags, saves, and then modifies again
        const updatedTagsForSelect = mapTagsForSelect(tags)
        setInitialValuesForSelect(updatedTagsForSelect)
        setSelectedValuesForSelect(updatedTagsForSelect)

        // Show all the tags in the tags list
        setTagsListIsExpanded(true)

        // Reset the UI to match the UI for when the user is not modifying the tags
        setIsEditingTags(false)

        // Shows the success toast
        toast.success(t('tagsSettings.toast.updated'))
      })
      .catch((error) => {
        console.error(error)
        toast.error(t('tagsSettings.toast.error')) // This isn't mandatory, but it doest show a user friendly error message. The GraphQL error message is also shown.
      })
  }

  const handleOnClickCloseEditingTagsButton = () => {
    setIsEditingTags(false)
    setTagsListIsExpanded(true)

    setSelectedValuesForSelect(initialValuesForSelect)
  }

  const isLoading = allTagsLoading || carFileIsLoading
  const isError = allTagsError || syncCarfileTagsError

  useEffect(() => {
    if (initialValuesLoaded) {
      return
    }
    if (valuesForSelect.length === selectedValuesForSelect.length) {
      return
    }

    setInitialValuesForSelect(valuesForSelect)
    setSelectedValuesForSelect(valuesForSelect)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesForSelect, setInitialValuesLoaded])

  useEffect(() => {
    if (initialValuesLoaded) {
      return
    }
    if (initialValuesForTagList.length === selectedValuesForTagList.length) {
      return
    }

    setSelectedValuesForTagList(initialValuesForTagList)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValuesForTagList, setInitialValuesLoaded])

  // Show the loading indicator when the tags are loading
  if (isLoading || isError || (readOnly && !carFileHasTags)) {
    // Instead of showing a loading indicator, I didn't show anything while the API's are loading. We *can* uncomment the line below, and it'll show
    // Another loading spinner, but it's quite overkill on this window.
    return null
    // return <LoadingIndicator className={className} error={isError} size="small" />
  }

  return (
    <Container className={className}>
      <TagIcon type="tag" width="19" height="19" />

      {!isEditingTags && (
        <StyledExpandableTagsList
          tags={selectedValuesForTagList}
          maxTags={5}
          isInitiallyExpanded={tagsListIsExpanded}
        />
      )}

      {isEditingTags && (
        <InputContainer>
          <NewOptionsSelect
            label=""
            options={optionsForSelect}
            defaultValue={initialValuesForSelect}
            value={selectedValuesForSelect}
            onChange={handleOnChangeOptions}
            disabled={syncCarfileTagsLoading}
            disableAddOption
            disableClearable
          />
          <ButtonContainer>
            <Button
              disabled={syncCarfileTagsLoading}
              level="option"
              onClick={handleOnClickCloseEditingTagsButton}
            >
              {t('close')}
            </Button>
            <LoadingButton
              isLoading={syncCarfileTagsLoading}
              disabled={syncCarfileTagsLoading || !hasModifiedTags()}
              level="cta"
              type="button"
              onClick={handleClickOnSaveTagsButton}
            >
              {t('save')}
            </LoadingButton>
          </ButtonContainer>
        </InputContainer>
      )}
    </Container>
  )
}

CarfileTagSelector.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
}

CarfileTagSelector.defaultValue = {
  className: null,
  readOnly: true,
}

export default withApolloV4Provider(CarfileTagSelector)
