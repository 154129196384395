import React, { forwardRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { unitsOfMeasurement, unitTypes } from 'config/data'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from 'components/molecules/typography'
import Tooltip from 'components/atoms/new-tooltip'
import { formatDataTestE2eAttr } from 'utilities/format'

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
  }

  ${({ error, required, theme, value }) =>
    !error && !value && required
      ? `
  .MuiInput-underline:before,
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after,
  .MuiFilledInput-underline:hover:not(.Mui-disabled):before {
    border-color: ${theme.colors.actionsCta};
  }

  .MuiFilledInput-root {
    background-color: ${theme.colors.requiredInputBackground};
  }
  `
      : `
  .MuiFilledInput-root {
    background-color: ${theme.colors.fieldBackground};
    }
  `}
`

const ExplanationIcon = styled.i`
  display: inline-block;
  color: ${({ theme }) => theme.colors.inputPlaceholder};
  height: ${({ theme }) => theme.sizings.lvl2};
  margin-left: ${({ theme }) => theme.sizings.lvl1};
  width: ${({ theme }) => theme.sizings.lvl2};
  line-height: ${({ theme }) => theme.sizings.lvl2};
  border: 1px solid ${({ theme }) => theme.colors.inputPlaceholder};
  border-radius: 50%;
  text-align: center;
  font-size: 0.8em;

  &::before {
    content: 'i';
  }
`

const Error = styled(Typography)`
  display: block;
  margin-left: 12px;
  margin-top: ${({ theme }) => theme.sizings.lvl0};
`

/**
 * The new TextInput component that should eventually replace all text inputs
 * troughout the app. (This component should be renamed to TextInput and its
 * filename and the filename of the docs example should be renamed to
 * text-input.* at that time.)
 * This TextInput component is our wrapper around the
 * [Material UI TextField component](https://material-ui.com/components/text-fields/)
 *

 */
const NewTextInput = forwardRef(
  (
    {
      className,
      disabled,
      error,
      explanation,
      filled,
      multiline,
      rows,
      icon,
      id,
      label,
      onBlur,
      onChange,
      placeholder,
      readOnly,
      required,
      type,
      unit,
      fullWidth,
      value,
      params,
      inputProps,
      ...restProps
    },
    ref,
  ) => {
    let processedType = unitTypes[unit] || type

    if (['float', 'integer'].includes(type)) {
      processedType = 'number'
    }
    const processedUnit = unitsOfMeasurement[unit] || unit

    const onChangeHandler = (event) => {
      if (onChange) {
        // numbers not defined as float will behave like integer fields:
        if (type === 'integer') {
          if (event.target.value && event.target.value.length) {
            // Only pass back what is in front of the comma.
            // doing parseInt returns actual Numbers formatted like 3.454+e20,
            // which make no sense to the user.
            onChange(event.target.value.split('.')[0])
          } else {
            onChange(event.target.value)
          }
        } else if (
          typeof event.target.value === 'string' &&
          type !== 'textarea'
        ) {
          onChange(event.target.value.trimLeft())
        } else {
          onChange(event.target.value)
        }
      }
      return undefined
    }

    return (
      <div className={className} {...restProps}>
        <StyledTextField
          inputRef={ref}
          disabled={disabled || readOnly}
          error={!!error}
          filled={filled ? 1 : 0}
          id={id}
          InputProps={{
            ...inputProps,
            endAdornment:
              unit || icon || explanation ? (
                <>
                  {unit && (
                    <InputAdornment position="end">
                      {processedUnit}
                    </InputAdornment>
                  )}
                  {explanation && (
                    <Tooltip title={explanation}>
                      <ExplanationIcon />
                    </Tooltip>
                  )}
                  {icon}
                </>
              ) : null,
          }}
          inputProps={{
            ...params?.inputProps,
            'data-test-e2e': formatDataTestE2eAttr(id, 'input'),
          }}
          label={label}
          multiline={multiline}
          onBlur={onBlur}
          onChange={onChangeHandler}
          placeholder={placeholder}
          required={required}
          rows={rows}
          type={processedType}
          value={value}
          fullWidth={fullWidth}
          variant={filled ? 'filled' : undefined}
          data-test-e2e={formatDataTestE2eAttr(id)}
          {...params}
        />
        {typeof error === 'string' && <Error type="ErrorText">{error}</Error>}
      </div>
    )
  },
)

export const propTypes = {
  className: PropTypes.string,
  /** Boolean to indicate if the TextInput should be disabled */
  disabled: PropTypes.bool,
  /** Boolean to if the input has an error */
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Text to show in a tooltip on hover of a 'i' icon */
  explanation: PropTypes.string,
  /** Boolean to indicate if the TextInput should be rendered as the filled
   * variant */
  filled: PropTypes.bool,
  /** A generic HTML (unique) id */
  id: PropTypes.string,
  /** Icon  component  */
  icon: PropTypes.element,
  /** A label / title */
  label: PropTypes.string.isRequired,
  /** Transform the field into a textarea */
  multiline: PropTypes.bool,
  /** If multiline = true, how many rows should the textarea have? */
  rows: PropTypes.number,
  onBlur: PropTypes.func,
  /** A change handler which will get the value of the input as its argument */
  onChange: PropTypes.func,
  /** An optional placeholder which will become visible when the input has
   * focus */
  placeholder: PropTypes.string,
  /** Boolean to indicate if the TextInput should be read only */
  readOnly: PropTypes.bool,
  /** Boolean to indicate if the TextInput should be required */
  required: PropTypes.bool,
  /**
   * An input type (on eof the regular HTML input types or 'integer' and 'float'
   * Please note that 'number' fields are cast to behave as integer (whole numbers) fields
   * the type 'password' is also supported.
   */
  type: PropTypes.string,
  /** A unit to be shown at the right end of the text input */
  unit: PropTypes.string,
  /** The value of the input */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  /** Used to attach more props to the <input> element */
  params: PropTypes.object,
  /** Used to attach more props to the <input inputProps> attribute */
  inputProps: PropTypes.object,
}

NewTextInput.propTypes = propTypes

export const defaultProps = {
  className: undefined,
  disabled: false,
  error: false,
  explanation: null,
  filled: undefined,
  id: undefined,
  icon: undefined,
  multiline: false,
  rows: 6,
  onBlur: null,
  onChange: null,
  placeholder: null,
  readOnly: false,
  required: false,
  type: null,
  unit: null,
  value: '',
  fullWidth: true,
  params: undefined,
  inputProps: undefined,
}

NewTextInput.defaultProps = defaultProps

export default NewTextInput
