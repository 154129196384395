/* eslint-disable camelcase */

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import PageHeader from 'components/organisms/page-header'
import EnhancedCard from 'components/layouts/enhanced-card'
import ExplanationParagraph from 'components/atoms/explanation-paragraph'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import Button from 'components/atoms/button'
import { Field, Form, Formik } from 'formik'
import { CarFileViaCarCollectFormSchema } from 'config/validation-schemas'
import { FormikLicensePlateInput } from 'components/molecules/formik-field'
import { clearData, createCarFileUsingCarCollect } from 'redux/actions/data'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import * as ROUTES from 'config/routes'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.background};
  padding: 0 ${({ theme }) => theme.sizings.lvl3};

  ${media.desktop`
    padding: 0 60px;
  `};
`
// This negative margin makes the content full width in mobile
const StyledEnhancedCard = styled(EnhancedCard)`
  margin: 0 -${({ theme }) => theme.sizings.lvl3};
  ${media.tablet`
    margin: 0;
  `};
`

const FormRow = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.sizings.lvl3};
  align-items: center;

  > * {
    width: 100%;
  }

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `}

  ${media.desktop`
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

const FormFieldWrapper = styled.div`
  > * {
    // fill out the field across the available width:
    width: 100%;
  }
`

function CarFileViaCarCollect() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const newCarFileId = useSelector(
    (state) => state?.data?.createCarFileUsingCarCollect?.data?.carFileId,
  )
  const history = useHistory()

  const getFormikEmptyFormSchema = () => ({
    licensePlate: '',
  })

  useEffect(
    () =>
      // clean up redux state on component destruction:
      () => {
        dispatch(clearData('createCarFileUsingCarCollect'))
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (typeof newCarFileId === 'string') {
      history.push(`${ROUTES.CAR_FILE}/${newCarFileId}`)
    }
  }, [newCarFileId, history])

  const validationSchema = CarFileViaCarCollectFormSchema(t)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    if (values.licensePlate) {
      await dispatch(createCarFileUsingCarCollect(values))
      setSubmitting(false)
    } else {
      setSubmitting(false)
    }
  }

  return (
    <AppLayoutContainer pageTitle={t('carEntry')}>
      <Container>
        <PageHeader
          mainTitle={t('carFileViaCarCollect.mainTitle')}
          status={t('carFileViaCarCollect.status')}
          subTitle={t('carFileViaCarCollect.subTitle')}
        />
        <StyledEnhancedCard>
          <ExplanationParagraph>
            {t('carFileViaCarCollect.explanation')}
          </ExplanationParagraph>

          <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={getFormikEmptyFormSchema()}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormRow>
                  <FormFieldWrapper>
                    <Field
                      name="licensePlate"
                      validationSchema={validationSchema}
                      label={t(
                        'carServicePlanAdminProgramsNewProgramOverlay.inputLabels.name',
                      )}
                      filled
                      component={FormikLicensePlateInput}
                      disabled={isSubmitting}
                    />
                  </FormFieldWrapper>
                  <Button
                    type="submit"
                    level="cta"
                    disabled={isSubmitting}
                    text={t('carFileViaCarCollect.submitButtonText')}
                  />
                </FormRow>
              </Form>
            )}
          </Formik>
        </StyledEnhancedCard>
      </Container>
    </AppLayoutContainer>
  )
}

export default CarFileViaCarCollect
