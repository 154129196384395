import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import initStore from 'redux/store'
import { ThemeProvider } from 'styled-components'

import {
  ThemeProvider as MaterialThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles'

import { themes } from 'config'
import { AppTypes, CarFileStatusEnumLowerCase } from 'config/enums'
import * as ROUTES from 'config/routes'

import ReactDatesStyles from 'styles/react-dates-overrides'
import ResetStyles from 'styles/reset'

import { getMuiTheme } from 'utilities/styled'

import LoadingTranslations from 'components/atoms/loading-translations'
import AdsContainer from 'components/redux-containers/ads-container'
import B2bCarFileContainer from 'components/redux-containers/b2b-car-file-container'
import CarShareCarFileContainer from 'components/redux-containers/car-share-car-file-container'
import B2bTradePlatformContainer from 'components/redux-containers/b2b-trade-platform-container'
import CarBpmDeclarationViewContainer from 'components/redux-containers/car-bpm-declaration-view-container'
import CarEnrichmentContainer from 'components/redux-containers/car-enrichment-container'
import CarEntryViewContainer from 'components/redux-containers/car-entry-view-container'
import CarFileContainer from 'components/redux-containers/car-file-container'
import CarValuationViewContainer from 'components/redux-containers/car-valuation-view-container'
import DashboardViewContainer from 'components/redux-containers/dashboard-view-container'
import ForgotPasswordContainer from 'components/redux-containers/forgot-password-container'
import LoginContainer from 'components/redux-containers/login-container'
import PrivateRoute from 'components/redux-containers/private-route'
import RdwStockViewContainer from 'components/redux-containers/rdw-stock-view-container'
import ResetPasswordContainer from 'components/redux-containers/reset-password-container'
import SearchResultsViewContainer from 'components/redux-containers/search-results-view-container'
import SettingsViewContainer from 'components/redux-containers/settings-view-container'
import StockContainer from 'components/redux-containers/stock-container'
import AdministrationView from 'components/views/administration'
import CameraView from 'components/views/camera-view'
import CarFileViaCarCollect from 'components/views/car-file-via-car-collect'
import CarServicePlanView from 'components/views/car-service-plan'
import SingleChecklistOverlay from 'components/views/checklists/single-checklist-overlay'
import CspDealerSiteWidgetCta from 'components/views/csp-widget/csp-dealer-site-widget-cta'
import CspDealerSiteWidgetForm from 'components/views/csp-widget/csp-widget-site-widget-form'
import ManagementInfoContainer from 'components/redux-containers/management-info-container'
import MarktviewView from 'components/views/market-view'
import RdwCarTransfer from 'components/views/rdw-car-transfer/index'
import RdwRequestDocumentNumber from 'components/views/rdw-request-document-number'
import { getAppTheme } from 'utilities/utils'

const { store, persistor } = initStore()

function App() {
  let { theme } = getAppTheme()

  /**
   * using appType as a way to see which logic/content needs to be exposed for which environment:
   */
  const appType = process.env.REACT_APP_WIDGETS
    ? AppTypes.WIDGETS
    : AppTypes.UCC

  if (appType === AppTypes.WIDGETS) {
    theme = themes.widgets
  }

  const muiTheme = getMuiTheme(theme)

  return (
    <Suspense fallback={<LoadingTranslations />}>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <MaterialThemeProvider theme={muiTheme}>
            <Provider store={store}>
              <Helmet>
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href={`${theme.faviconsFolder}/apple-touch-icon.png`}
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href={`${theme.faviconsFolder}/favicon-32x32.png`}
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href={`${theme.faviconsFolder}/favicon-16x16.png`}
                />
                <link
                  rel="manifest"
                  href={`${theme.faviconsFolder}/site.webmanifest`}
                />
                <link
                  rel="mask-icon"
                  href={`${theme.faviconsFolder}/safari-pinned-tab.svg`}
                />
                <link
                  rel="shortcut icon"
                  href={`${theme.faviconsFolder}/favicon.ico`}
                />
                <meta
                  name="msapplication-config"
                  content={`${theme.faviconsFolder}/browserconfig.xml`}
                />
                {/* Why not using react fragments? Because Helmet considers them invalid elements...  */}
                {appType === AppTypes.WIDGETS && (
                  <link rel="preconnect" href="https://fonts.googleapis.com" />
                )}
                {appType === AppTypes.WIDGETS && (
                  <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossOrigin
                  />
                )}
                {appType === AppTypes.WIDGETS && (
                  <link
                    href={`https://fonts.googleapis.com/css2?family=${theme.font.replace(/\s/g, '+')}&display=swap`}
                    rel="stylesheet"
                  />
                )}
              </Helmet>
              <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                  {appType === AppTypes.UCC ? (
                    <Switch>
                      <PrivateRoute
                        path={[
                          ROUTES.DASHBOARD,
                          ...Object.values(CarFileStatusEnumLowerCase).map(
                            (url) =>
                              `${ROUTES.DASHBOARD.replace(/\/$/, '')}/${url}`,
                          ), // To accomodate for the tabs used in the dashboard widget for flexflows
                        ]}
                        component={DashboardViewContainer}
                        exact
                      />
                      <PrivateRoute
                        path={ROUTES.ADS}
                        component={AdsContainer}
                      />
                      <PrivateRoute
                        path={`${ROUTES.CAR_FILE}/:id`}
                        component={CarFileContainer}
                      />
                      <PrivateRoute
                        path={`${ROUTES.B2B_CAR_FILE}/:id`}
                        component={B2bCarFileContainer}
                      />
                      <PrivateRoute
                        path={`${ROUTES.CAR_SHARE_CAR_FILE}/:id/:carShareLocationId`}
                        component={CarShareCarFileContainer}
                      />
                      <PrivateRoute
                        path={ROUTES.CSP}
                        component={CarServicePlanView}
                      />
                      <PrivateRoute
                        path={ROUTES.STOCK}
                        component={StockContainer}
                        exact
                      />
                      <PrivateRoute
                        path={ROUTES.B2B_TRADE_PLATFORM}
                        component={B2bTradePlatformContainer}
                        exact
                      />
                      <PrivateRoute
                        path={ROUTES.RDW_STOCK}
                        component={RdwStockViewContainer}
                        exact
                      />
                      <PrivateRoute
                        path={ROUTES.RDW_CAR_TRANSFER}
                        component={RdwCarTransfer}
                      />
                      <PrivateRoute
                        path={ROUTES.RDW_REQUEST_DOCUMENT_NUMBER}
                        component={RdwRequestDocumentNumber}
                      />
                      <PrivateRoute
                        path={ROUTES.SEARCH_RESULTS}
                        component={SearchResultsViewContainer}
                      />
                      <PrivateRoute
                        path={`${ROUTES.CAR_ENTRY}/:step`}
                        component={CarEntryViewContainer}
                      />
                      <PrivateRoute
                        path={`${ROUTES.CAR_ENRICHMENT}/:carFileId/:step`}
                        component={CarEnrichmentContainer}
                      />
                      <PrivateRoute
                        path={`${ROUTES.VALUATION}/:step`}
                        component={CarValuationViewContainer}
                      />
                      <PrivateRoute
                        path={`${ROUTES.CAR_BPM_DECLARATION}/:step`}
                        component={CarBpmDeclarationViewContainer}
                      />
                      <PrivateRoute
                        path={ROUTES.SETTINGS}
                        component={SettingsViewContainer}
                      />
                      <PrivateRoute
                        path={ROUTES.ADMINISTRATION}
                        component={AdministrationView}
                      />
                      <PrivateRoute
                        path={ROUTES.LOG}
                        component={AdministrationView}
                      />
                      <PrivateRoute
                        path={ROUTES.CAMERA}
                        component={CameraView}
                      />
                      <PrivateRoute
                        path={ROUTES.MARKTVIEW}
                        component={MarktviewView}
                      />
                      <PrivateRoute
                        path={ROUTES.NEW_CAR_FILE_VIA_CAR_COLLECT}
                        component={CarFileViaCarCollect}
                      />
                      <PrivateRoute
                        path={ROUTES.MANAGEMENT_INFO}
                        component={ManagementInfoContainer}
                      />
                      {/* <PrivateRoute
                      path={`${ROUTES.CHECKLISTS}/:locationId?/:status?/:id?`}
                      component={ChecklistsOverlay}
                    /> */}
                      <PrivateRoute
                        path={`${ROUTES.CHECKLISTS}/:checklistId/:checklistItemId?`}
                        component={SingleChecklistOverlay}
                      />
                      <Route
                        path={ROUTES.LOGIN}
                        component={LoginContainer}
                        exact
                      />
                      <Route
                        path={ROUTES.FORGOT_PASSWORD}
                        component={ForgotPasswordContainer}
                        exact
                      />
                      <Route
                        path={ROUTES.RESET_PASSWORD}
                        component={ResetPasswordContainer}
                        exact
                      />
                    </Switch>
                  ) : (
                    // The widgets environment cannot have private routes, because
                    // the routes needs to be publically accessible in order to
                    // open them in an iframes.
                    <Switch>
                      <Route
                        path={ROUTES.CSP_DEALER_SITE_WIDGET_CTA}
                        component={CspDealerSiteWidgetCta}
                        exact
                      />
                      <Route
                        path={ROUTES.CSP_DEALER_SITE_WIDGET_FORM}
                        component={CspDealerSiteWidgetForm}
                        exact
                      />
                    </Switch>
                  )}
                </BrowserRouter>
                <ToastContainer
                  autoClose={3000}
                  closeButton={false}
                  hideProgressBar
                />
                <ResetStyles />
                <ReactDatesStyles />
              </PersistGate>
            </Provider>
          </MaterialThemeProvider>
        </StylesProvider>
      </ThemeProvider>
    </Suspense>
  )
}

export default App
