/*
 * All the css props that specify a default for a certain textStyle should which
 * can should also be able to be overwritten with a prop should be defined before
 * the defaultStyle so that can still manage the overwriting.
 */

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'

const defaultStyle = css`
  color: ${({ $color, theme }) => theme.colors[$color] || ''};
  font-family: ${({ theme }) => theme.font};
  font-size: 14px;
  font-weight: ${({ weight }) => weight || 300};
  line-height: 18px;
`

const displayStyle = css`
  color: ${({ theme }) => theme.colors.brandAlfa};
  ${defaultStyle};
  font-size: 80px;
  font-weight: 500;
  line-height: 68px;
  text-transform: uppercase;
`

const h1Style = css`
  color: ${({ theme }) => theme.colors.brandAlfa};
  ${defaultStyle};
  font-size: 32px;
  font-weight: 500;
  line-height: 34px;
  text-transform: uppercase;
`

const h1bStyle = css`
  ${h1Style};
  font-weight: 300;
`

const h2Style = css`
  color: ${({ theme }) => theme.colors.brandAlfa};
  ${defaultStyle};
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`

const h2bStyle = css`
  ${h2Style};
  font-weight: 300;
  text-transform: uppercase;
`

const h3Style = css`
  color: ${({ theme }) => theme.colors.brandAlfa};
  ${defaultStyle};
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`

const h3bStyle = css`
  ${h3Style};
  font-weight: 300;
`

const h4Style = css`
  color: ${({ theme }) => theme.colors.brandAlfa};
  ${defaultStyle};
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
`

const h4bStyle = css`
  ${h4Style};
  font-weight: 300;
`

const h5Style = css`
  color: ${({ theme }) => theme.colors.brandAlfa};
  ${defaultStyle};
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
`

const h5bStyle = css`
  ${h5Style};
  font-weight: 300;
`

const numberStyle = css`
  ${h2Style};
  text-transform: uppercase;
`

const tabStyle = css`
  color: ${({ theme }) => theme.colors.brandAlfa};
  ${defaultStyle};
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
`

const tabDeselectedStyle = css`
  color: ${({ theme }) => theme.colors.actionsStandard};
  ${tabStyle};
  font-weight: 400;
`

const tabSmallStyle = css`
  color: ${({ theme }) => theme.colors.brandAlfa};
  ${defaultStyle};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`

const tabSmallDeselectedStyle = css`
  color: ${({ theme }) => theme.colors.actionsStandard};
  ${tabSmallStyle};
  font-weight: 400;
`

const bodyStyle = css`
  color: ${({ theme }) => theme.colors.brandAlfa};
  ${defaultStyle};
`

const bodyLargeStyle = css`
  ${bodyStyle};
  font-size: 16px;
  line-height: 18px;
`

const bodyLightStyle = css`
  ${bodyStyle};
  color: ${({ theme }) => theme.colors.brandDelta};
`

const menuStyle = css`
  color: ${({ theme }) => theme.colors.brandGolf};
  font-weight: 400;
  ${defaultStyle};
  font-size: 12px;
  line-height: 16px;
`

const inputStyle = css`
  &,
  &::placeholder {
    color: ${({ theme }) => theme.colors.brandAlfa};
    ${defaultStyle};
    font-weight: 400;
  }

  &[for] {
    cursor: pointer;
  }
`

const floatingLabelInputStyle = css`
  color: ${({ theme }) => theme.colors.brandAlfa};
  ${defaultStyle};
  font-size: ${({ theme }) => theme.baseFontSize};
  font-weight: 500;
  line-height: 16px;
`

const floatingLabelDropdownMenuOptionStyle = css`
  color: ${({ theme }) => theme.colors.brandAlfa};
  ${defaultStyle};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`

const buttonStyle = css`
  color: ${({ theme }) => theme.colors.brandGolf};
  ${defaultStyle};
  font-weight: 500;
  text-transform: uppercase;
`

const buttonLargeStyle = css`
  ${buttonStyle};
  font-size: 18px;
  line-height: 22px;
`

const countStyle = css`
  color: ${({ theme }) => theme.colors.brandGolf};
  ${defaultStyle};
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`

const actionStyle = css`
  color: ${({ theme }) => theme.colors.actionsStandard};
  ${defaultStyle};
  font-size: 16px;
`

const actionSelectedStyle = css`
  ${actionStyle};
  color: ${({ theme }) => theme.colors.brandAlfa};
  font-weight: 700;
`

const actionDisabledStyle = css`
  ${actionStyle};
  color: ${({ theme }) => theme.colors.brandDelta};
`

const boldLabelStyle = css`
  &,
  a {
    color: ${({ theme }) => theme.colors.brandAlfa};
    ${defaultStyle};
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
`

const textareaStyle = css`
  color: ${({ theme }) => theme.colors.brandAlfa};
  ${defaultStyle};
  font-size: 14px;
  line-height: 20px;
`

const floatingLabelLabelStyle = css`
  color: ${({ theme }) => theme.colors.textTonedDown};
  ${defaultStyle};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`

export const styles = {
  display: displayStyle,
  h1: h1Style,
  h1b: h1bStyle,
  h2: h2Style,
  h2b: h2bStyle,
  h3: h3Style,
  h3b: h3bStyle,
  h4: h4Style,
  h4b: h4bStyle,
  h5: h5Style,
  h5b: h5bStyle,
  number: numberStyle,
  tab: tabStyle,
  tabDeselected: tabDeselectedStyle,
  tabSmall: tabSmallStyle,
  tabSmallDeselected: tabSmallDeselectedStyle,
  body: bodyStyle,
  bodyLarge: bodyLargeStyle,
  bodyLight: bodyLightStyle,
  menu: menuStyle,
  input: inputStyle,
  floatingLabelInput: floatingLabelInputStyle,
  floatingLabelDropdownMenuOption: floatingLabelDropdownMenuOptionStyle,
  button: buttonStyle,
  buttonLarge: buttonLargeStyle,
  count: countStyle,
  action: actionStyle,
  actionSelected: actionSelectedStyle,
  actionDisabled: actionDisabledStyle,
  boldLabel: boldLabelStyle,
  textarea: textareaStyle,
  floatingLabelLabel: floatingLabelLabelStyle,
}

export const styledComponents = {
  hint: styled.span`
    color: ${({ theme }) => theme.colors.brandAlfa};
    ${defaultStyle};
    font-size: 14px;
    line-height: 16px;
  `,
  display: styled.p`
    ${displayStyle};
  `,
  h1: styled.h1`
    ${h1Style};
  `,
  h1b: styled.h2`
    ${h1bStyle};
  `,
  h2: styled.h2`
    ${h2Style};
  `,
  h2b: styled.h2`
    ${h2bStyle};
  `,
  h3: styled.h3`
    ${h3Style};
  `,
  h3b: styled.h3`
    ${h3bStyle};
  `,
  h4: styled.h4`
    ${h4Style};
  `,
  h4b: styled.h4`
    ${h4bStyle};
  `,
  h5: styled.h5`
    ${h5Style};
  `,
  h5b: styled.h5`
    ${h5bStyle};
  `,
  number: styled.p`
    ${numberStyle};
  `,
  tab: styled.p`
    ${tabStyle};
  `,
  tabDeselected: styled.p`
    ${tabDeselectedStyle};
  `,
  tabSmall: styled.p`
    ${tabSmallStyle};
  `,
  tabSmallDeselected: styled.p`
    ${tabSmallDeselectedStyle};
  `,
  body: styled.p`
    ${bodyStyle};
  `,
  bodyLarge: styled.p`
    ${bodyLargeStyle};
  `,
  bodyLight: styled.p`
    ${bodyLightStyle};
  `,
  menu: styled.p`
    ${menuStyle};
  `,
  input: styled.span`
    ${inputStyle};
  `,
  button: styled.span`
    ${buttonStyle};
  `,
  buttonLarge: styled.span`
    ${buttonLargeStyle};
  `,
  count: styled.span`
    ${countStyle};
  `,
  action: styled.span`
    ${actionStyle};
  `,
  actionSelected: styled.span`
    ${actionSelectedStyle};
  `,
  actionDisabled: styled.span`
    ${actionDisabledStyle};
  `,
  boldLabel: styled.label`
    ${boldLabelStyle};
    cursor: pointer;
  `,
  textarea: styled.textarea`
    ${textareaStyle};
  `,
  floatingLabelLabel: styled.span`
    ${floatingLabelLabelStyle};
  `,
  floatingLabelInput: styled.span`
    ${floatingLabelInputStyle};
  `,
}

/**
 * A component to be used for all text / typography throughout the
 * app / component library.
 * @deprecated Please use [Typography](/#/Molecules/Typography) when possible.
 */

const Text = forwardRef(({ children, tag, text, type, ...restProps }, ref) => {
  const StyledText = styledComponents[type]

  if (!StyledText) {
    // eslint-disable-next-line no-console
    console.error(`Text type '${type}' does not exist`)
  }

  const { color, ...restOfRestProps } = restProps

  return (
    <StyledText as={tag} ref={ref} $color={color} {...restOfRestProps}>
      {text || children}
    </StyledText>
  )
})

Text.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  weight: PropTypes.number,
}

Text.defaultProps = {
  children: null,
  tag: null,
  text: null,
  type: 'body',
  weight: null,
}

export default Text
