/**
 * Module to abstract the usage of localStorage and sessionStorage.
 * Basically a [Facade](https://en.wikipedia.org/wiki/Facade_pattern) for
 * local browser storage.
 */
function getPermanent(key) {
  return localStorage.getItem(key)
}

function setPermanent(key, value) {
  const event = new Event('setLocalStorage')

  event.newValue = value
  event.oldValue = localStorage.getItem(key)
  event.key = key

  if (event.newValue !== event.oldValue) {
    window.dispatchEvent(event)
  }
  return localStorage.setItem(key, value)
}

function removePermanent(key) {
  return localStorage.removeItem(key)
}

function getSession(key) {
  return sessionStorage.getItem(key)
}

function setSession(key, value) {
  const event = new Event('setSessionStorage')

  event.newValue = value
  event.oldValue = sessionStorage.getItem(key)
  event.key = key

  if (event.newValue !== event.oldValue) {
    window.dispatchEvent(event)
  }
  return sessionStorage.setItem(key, value)
}

function removeSession(key) {
  return sessionStorage.removeItem(key)
}

function getValue(key, permanentStorageFirst = true) {
  const sessionValue = getSession(key)
  const permanentValue = getPermanent(key)
  return permanentStorageFirst
    ? permanentValue || sessionValue
    : sessionValue || permanentValue
}

function getAllPermanentValues() {
  return localStorage
}

export default {
  getPermanent,
  setPermanent,
  removePermanent,
  getSession,
  setSession,
  removeSession,
  getValue,
  getAllPermanentValues,
}
