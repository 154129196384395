import { gql } from '@apollo/client'

export const AD_EVENT_PORTALS = gql`
  query adEventPortals($dealerId: ID!, $carFileId: ID!) {
    adEventPortals(dealerId: $dealerId, carFileId: $carFileId) {
      id
      label
    }
  }
`

export const AD_EVENTS = gql`
  query adEventDataPoints($dealerId: ID!, $carFileId: ID!, $portals: [ID]) {
    adEventDataPoints(
      dealerId: $dealerId
      carFileId: $carFileId
      portals: $portals
    ) {
      type
      date
      interaction
      amount
      portal
    }
  }
`
