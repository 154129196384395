import React from 'react'
import PropTypes from 'prop-types'
import DropDownMenu from 'components/atoms/dropdown-menu'

/**
 * @deprecated Use DropDownFilter instead
 */
function BranchLocationPicker({
  locations,
  onLocationChange,
  selectedLocation,
}) {
  const items = locations.map((location) => ({
    label: location.label,
    value: location.id || location.value,
  }))

  return (
    <>
      <DropDownMenu
        items={items}
        onChange={onLocationChange}
        selected={selectedLocation}
      />
    </>
  )
}

BranchLocationPicker.propTypes = {
  locations: PropTypes.array.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  selectedLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

BranchLocationPicker.defaultProps = {
  selectedLocation: '',
}

export default BranchLocationPicker
