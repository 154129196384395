import React from 'react'
import PropTypes from 'prop-types'
import SimpleTable from 'components/layouts/simple-table'
import OptionsBar from 'components/layouts/options-bar'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'

/**
 * Shows a heading coupled with some tabular results. optionally possible to
 * supply actions to act on the row data in some way.
 *
 * Through the `ordering` and `dataMapping` props you can supply this component
 * with a custom way of displaying any kind of data. the raw data is provided
 * via the `data` prop.
 */
function TabularResults({
  title,
  data,
  dataMapping,
  ordering,
  actions,
  addNewOption,
  noDataMessage,
}) {
  return (
    <section>
      <OptionsBar
        leftAligned={[
          data.length ? (
            <Text type="h2" text={title} />
          ) : (
            <Text type="h2" text={noDataMessage} />
          ),
        ]}
        rightAligned={
          addNewOption
            ? [
                <Button
                  key={addNewOption.label}
                  text={addNewOption.label}
                  onClick={() => addNewOption.method()}
                />,
              ]
            : []
        }
      />
      {!!data.length && (
        <SimpleTable
          columns={ordering.map((id) => (
            <Text
              key={id}
              type="floatingLabelLabel"
              text={dataMapping[id].label}
            />
          ))}
          rows={data.map((row) =>
            ordering.map((id) => (
              <Text
                key={`row-${id}`}
                type="boldLabel"
                text={dataMapping[id].getter(row)}
              />
            )),
          )}
          actions={data.map((row) =>
            actions.map((action) => (
              <Button
                key={action.label}
                level="option"
                text={action.label}
                onClick={() => action.method(row)}
                noPadding
              />
            )),
          )}
        />
      )}
    </section>
  )
}

TabularResults.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  ordering: PropTypes.array.isRequired,
  dataMapping: PropTypes.object.isRequired,
  actions: PropTypes.array,
  addNewOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    method: PropTypes.func.isRequired,
  }),
  noDataMessage: PropTypes.string.isRequired,
}

TabularResults.defaultProps = {
  addNewOption: null,
  actions: [],
}

export default TabularResults
