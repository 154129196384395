import { DisplayModeEnum } from './enums'

// Car service plan
export const MINIMUM_WEEKS_TO_ENABLE_CONTRACT_MODIFICATION = 6

// Management info
export const DATE_FORMAT = 'YYYY-MM-DD'
export const LINE_CHART_Y_MIN = 0
export const LINE_CHART_Y_MARGIN_BOTTOM = 0.75
export const LINE_CHART_Y_MARGIN_TOP = 1.25
export const DISPLAY_MODE_DEFAULT = DisplayModeEnum.grid
export const VIN_LENGTH = 17
