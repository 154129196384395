import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { media } from 'utilities/styled'

import AppLayout from 'components/redux-containers/app-layout-container'
import PageHeader from 'components/organisms/page-header'

const Content = styled.main`
  padding: 0 20px;

  ${media.desktop`
    padding: 0 60px;
  `};
`
/**
 * Generic layout to display search results.
 */
function SearchResultsOverview({
  mainTitle,
  pageTitle,
  statusTitle,
  subTitle,
  showSearchbox,
  pageHeaderOptionsSlot,
  children,
}) {
  return (
    <AppLayout pageTitle={pageTitle}>
      <Content>
        <PageHeader
          mainTitle={mainTitle}
          subTitle={subTitle}
          status={statusTitle}
          showSearchbox={showSearchbox}
          optionsSlot={pageHeaderOptionsSlot}
        />
        <section>{children}</section>
      </Content>
    </AppLayout>
  )
}

SearchResultsOverview.propTypes = {
  mainTitle: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  statusTitle: PropTypes.string,
  subTitle: PropTypes.string.isRequired,
  showSearchbox: PropTypes.bool,
  children: PropTypes.node.isRequired,
  pageHeaderOptionsSlot: PropTypes.node,
}

SearchResultsOverview.defaultProps = {
  showSearchbox: false,
  statusTitle: null,
  pageHeaderOptionsSlot: null,
}

export default SearchResultsOverview
