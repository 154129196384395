export const newsItemWordPressTagOpenOnLoad = 'Aandacht'
export const newsItemWordPressTagShowInNavigation = 'WebApp'
export const newsItemWordPressTagLanguage = 'WebApp taal' // "WebApp taal FR" or "WebApp taal EN" would be the tag in WordPress
export const newsItemWordPressTagLocation = 'WebApp locatie' // "WebApp locatie DE" or "WebApp locatie NL" would be the tag in WordPress

export const userLicensesMap = {
  ucc: 'UCC',
  marketview: 'MV',
  carserviceplan: 'CSP',
  flexflow: 'FF',
}

export const userLanguagesAndLocationsMap = {
  nl: 'NL', // Used for Netherlands as location and Dutch as language
  fr: 'FR', // Used for France as location and French as language
  de: 'DE', // Used for Germany as location and German as language
  en: 'EN', // User for United Kingdom as location and English as language
}

/**
 *
 *
 * WordPress tags overzicht:
 *
 * "Aandacht"           - toont niet een "1" in het notificatie-bolletje, maar triggert een pop-up zodra de app wordt geopend. Het gebruiken van deze tag zorgt voor een pop-up ongeacht licentie(!)
 *
 * "WebApp"             - toont een "1" het notificatie-bolletje in de navigatie in de web-app voor alle gebruikers, ongeacht licentie, triggert géén pop-up
 * "WebApp UCC"         - toont een "1" het notificatie-bolletje in de navigatie in de web-app voor alle gebruikers met Used Car Controller licentie, triggert géén pop-up
 * "WebApp CSP"         - toont een "1" het notificatie-bolletje in de navigatie in de web-app voor alle gebruikers met Car Service Plan licentie, triggert géén pop-up
 * "WebApp MV"          - toont een "1" het notificatie-bolletje in de navigatie in de web-app voor alle gebruikers met Market View licentie, triggert géén pop-up
 * "WebApp FF"          - toont een "1" het notificatie-bolletje in de navigatie in de web-app voor alle gebruikers met Flex Flow licentie, triggert géén pop-up
 *
 * "WebApp locatie NL"  - triggert de pop-up alleen voor locaties waarbij de "country_code" gelijk is aan "NL"
 * "WebApp locatie BE"  - triggert de pop-up alleen voor locaties waarbij de "country_code" gelijk is aan "BE"
 * "WebApp locatie DE"  - triggert de pop-up alleen voor locaties waarbij de "country_code" gelijk is aan "DE"
 * "WebApp locatie FR"  - triggert de pop-up alleen voor locaties waarbij de "country_code" gelijk is aan "FR"
 * "WebApp locatie EN"  - triggert de pop-up alleen voor locaties waarbij de "country_code" gelijk is aan "EN"
 *
 * "WebApp taal NL"     - triggert de pop-up alleen voor locaties waarbij de huidige taal (ingesteld in het profiel) gelijk is aan Nederlands
 * "WebApp taal FR"     - triggert de pop-up alleen voor locaties waarbij de huidige taal (ingesteld in het profiel) gelijk is aan Frans
 * "WebApp taal EN"     - triggert de pop-up alleen voor locaties waarbij de huidige taal (ingesteld in het profiel) gelijk is aan Engels
 *
 *
 * Verplichte tags:
 *
 * "WebApp" of "Aandacht" zijn verplicht  - de UCC applicatie moet weten of je een pop-up wilt tonen of een "1" in het notificatie-bolletje
 * "WebApp locatie" is verplicht          - Bijvoorbeeld "WebApp locatie NL"
 * "WebApp taal" is verplicht             - Bijvoorbeeld "WebApp taal NL"
 *
 *
 * Voorbeelden:
 *
 * - Situatie: Een Franstalig WordPress bericht moet door middel van een pop-up getoond worden aan alle Franstalige gebruikers van UCC, in Frankrijk en België
 * - "Aandacht"             - We willen dit WordPress bericht in een pop-up tonen
 * - "WebApp locatie FR"    - We willen dit WordPress bericht tonen aan gebruikers in Frankrijk
 * - "WebApp locatie BE"    - We willen dit WordPress bericht tonen aan gebruikers in België
 * - "WebApp taal FR"       - We willen dit WordPress bericht alleen tonen aan gebruikers die de Franse taal hebben geselecteerd
 *
 * - Situatie: Een Engelstalig WordPress bericht moet door middel van een pop-up getoond worden aan alle gebruikers, ongeacht land, taal of licentie
 * - "Aandacht"             - We willen dit WordPress bericht in een pop-up tonen
 * - "WebApp locatie NL"    - We willen dit WordPress bericht tonen aan locaties die gevestigd zijn in Nederland
 * - "WebApp locatie BE"    - We willen dit WordPress bericht tonen aan locaties die gevestigd zijn in Belgie
 * - "WebApp locatie DE"    - We willen dit WordPress bericht tonen aan locaties die gevestigd zijn in Duitsland
 * - "WebApp locatie FR"    - We willen dit WordPress bericht tonen aan locaties die gevestigd zijn in Frankrijk
 * - "WebApp locatie EN"    - We willen dit WordPress bericht tonen aan locaties die gevestigd zijn in Engeland
 * - "WebApp taal NL"       - We willen dit WordPress bericht tonen aan gebruikers die de Nederlandse taal hebben geselecteerd
 * - "WebApp taal FR"       - We willen dit WordPress bericht tonen aan gebruikers die de Franse taal hebben geselecteerd
 * - "WebApp taal EN"       - We willen dit WordPress bericht tonen aan gebruikers die de Engelse taal hebben geselecteerd
 *
 * - Situatie: Een Nederlandstalig WordPress bericht moet door middel van het notificatie-bolletje getoond worden aan alle UCC gebruikers, ongeacht licentie, in België en Nederland
 * - "WebApp"               - We willen dit WordPress bericht in een notificatie-bolletje tonen (niet in een pop-up)
 * - "WebApp locatie BE"    - We willen dit WordPress bericht tonen aan gebruikers in België
 * - "WebApp locatie NL"    - We willen dit WordPress bericht tonen aan gebruikers in Nederland
 * - "WebApp taal NL"       - We willen dit WordPress bericht alleen tonen aan gebruikers die de Franse taal hebben geselecteerd
 *
 * - Situatie: Een Franstalig WordPress bericht moet door middel van het notificatie-bolletje getoond worden aan alle UCC gebruikers, alleen met Marketview licentie, in België en Frankrijk
 * - "WebApp MV"            - We willen dit WordPress bericht in een notificatie-bolletje tonen (niet in een pop-up), alleen aan MarketView gebruikers
 * - "WebApp locatie BE"    - We willen dit WordPress bericht tonen aan gebruikers in België
 * - "WebApp locatie FR"    - We willen dit WordPress bericht tonen aan gebruikers in Nederland
 * - "WebApp taal FR"       - We willen dit WordPress bericht alleen tonen aan gebruikers die de Franse taal hebben geselecteerd
 */
