import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import TagsList from 'components/molecules/tags-list'

const ExpandableTagsList = ({
  className,
  tags,
  maxTags,
  isInitiallyExpanded,
}) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded)

  const handleOnExpandTagsClick = () => setIsExpanded(true)

  const modifiedTags = useMemo(() => {
    // Return unmodified tags when list is expanded
    if (isExpanded) {
      return tags
    }

    // Check if the tag array contains a tag with "isEditTag: true"
    const editTag = tags.find((tag) => tag?.isEditTag === true)

    // Check the tags length. We have to substract one if there's a tag with the property "isEditTag: true"
    const tagsLength = editTag ? tags.length - 1 : tags.length

    // Return unmodified tags when the total number of tags is smaller then or equal to the maximum allowed tags
    if (tagsLength <= maxTags) {
      return tags
    }

    // Clone the tags
    const tagsCopy = [...tags]

    // Remove all tags, up until the maxTags number
    tagsCopy.splice(maxTags)

    // If the tags array has a tag with "isEditTag: true", move this to the end of the shortened array
    if (editTag) {
      tagsCopy.push(editTag)
    }

    // Create new array with the "show all" tag
    const tagsWithShowAllTag = [
      ...tagsCopy,
      {
        label: t('showAll'),
        level: 'option',
        onClick: () => handleOnExpandTagsClick(),
      },
    ]

    // Add the "show all" tag to the list when the user should be able to expand to see all tags
    return tagsWithShowAllTag
  }, [t, tags, isExpanded, maxTags])

  // Don't show the component when there's no tags to show
  if (!modifiedTags.length) {
    return null
  }

  return <TagsList className={className} tags={modifiedTags} />
}

ExpandableTagsList.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      label: PropTypes.string.isRequired,
      size: PropTypes.oneOf(['small', 'medium']),
      icon: PropTypes.string,
      iconColor: PropTypes.string,
      isEditTag: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  maxTags: PropTypes.number,
  isInitiallyExpanded: PropTypes.bool,
}

ExpandableTagsList.defaultProps = {
  className: null,
  tags: [],
  maxTags: 5,
  isExpanded: false,
}

export default ExpandableTagsList
