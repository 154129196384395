import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import WarrantyProgramDetails from './warranty-program-details'
import WarrantyProgramNew from './warranty-program-new'
import WarrantyProgramsOverview from './warranty-programs-overview'

function CarServicePlanAdminWarrantyPrograms({ basePath }) {
  return (
    <>
      <Switch>
        <Route
          path={`${basePath}`}
          exact
          component={WarrantyProgramsOverview}
        />
        <Route path={`${basePath}/new/`} exact component={WarrantyProgramNew} />
        <Route
          path={`${basePath}/details/:warrantyProgramId`}
          exact
          component={() => <WarrantyProgramDetails initiallyDisabled />}
        />
      </Switch>
    </>
  )
}

CarServicePlanAdminWarrantyPrograms.propTypes = {
  basePath: PropTypes.string.isRequired,
}

export default CarServicePlanAdminWarrantyPrograms
