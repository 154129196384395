import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import LoadingIndicator from 'components/atoms/loading-indicator'

const StyledLoadingIndicator = styled(LoadingIndicator)`
  display: inline;

  span {
    margin: 1px;
  }
`

/**
 * Note: Only use this component in the context of the management info page.
 * It's seperated into a molecule because it's being re-used in different charts,
 * yet it's not meant to be used outside of the management info page.
 */
function ManagementInfoChartTitleLoadingIndicator({ className }) {
  return <StyledLoadingIndicator className={className} size="small" />
}

ManagementInfoChartTitleLoadingIndicator.propTypes = {
  className: PropTypes.string,
}

export default ManagementInfoChartTitleLoadingIndicator
