import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { CarFileStatusEnumUpperCase } from 'config/enums'

import { formatPercentage } from 'utilities/format'

import ExpandableBars from 'components/layouts/expandable-bars'
import SummaryLine from 'components/molecules/summary-line'
import Text from 'components/atoms/text'
import Progress from 'components/atoms/progress'
import ChecklistsOverview from 'components/views/car-file/tabs/checklists/checklists-overview'

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.sizings.lvl1};
`

const ColoredText = styled(Text)`
  color: ${({ theme, $color }) => `${theme.colors[$color]}`};
`

const StyledProgress = styled(Progress)`
  width: ${({ theme }) => theme.sizeByFactor(12)};
`
const ProgressText = styled(Text)`
  margin-right: ${({ theme }) => theme.sizings.lvl1};
`

const Container = styled.div`
  display: flex !important;
  align-items: center;
`

const CarFileChecklistStatussesTable = ({
  carFileId,
  checklistStatusses,
  initialExpandedStatus,
  onRowClick,
  onChecklistSubmit,
  checklistIsSubmitting,
  ...restProps
}) => {
  const { t } = useTranslation()

  return (
    <ExpandableBars
      rows={checklistStatusses.map(
        ({
          status,
          statusOrder,
          totalProgress,
          totalBlockedProgress,
          startedAt,
          finishedAt,
          checklists,
        }) => {
          const progress = (totalProgress.checked / totalProgress.total) * 100
          const blockedItemsAreComplete =
            totalBlockedProgress.total === 0 ||
            totalBlockedProgress.checked === totalBlockedProgress.total

          let textColor = 'statusIncomplete'
          if (statusOrder < 0) {
            textColor = 'statusComplete'
          } else if (statusOrder > 0) {
            textColor = 'inactiveTextColor'
          }

          const summaryLineDetails = [
            {
              value: t('carFileChecklists.tab.progress', {
                finished: totalProgress.checked,
                total: totalProgress.total,
              }),
            },
            {
              value: (
                <Container>
                  <ProgressText text={formatPercentage(progress, 2)} />
                  <StyledProgress
                    value={progress}
                    color={
                      blockedItemsAreComplete ? 'statusComplete' : undefined
                    }
                  />
                </Container>
              ),
            },
          ]

          if (startedAt && statusOrder <= 0) {
            // Push the time based stuff
            const startDate = moment(startedAt).format('DD-MM-YYYY')
            const endDate = moment(finishedAt || new Date()).format(
              'DD-MM-YYYY',
            )
            const timeText = finishedAt
              ? 'carFileChecklists.tab.finishedOnDate'
              : 'carFileChecklists.tab.activeSinceDate'
            const dateText = finishedAt ? endDate : startDate
            summaryLineDetails.unshift({
              value: t(timeText, { date: dateText }),
            })
          }

          return {
            id: status,
            header: (
              <SummaryLine
                name={
                  <Header>
                    <ColoredText
                      type="tabSmall"
                      text={t(`carFileStatusses.${status}`)}
                      $color={textColor}
                    />
                  </Header>
                }
                details={summaryLineDetails}
              />
            ),
            details: (
              <ChecklistsOverview
                checklists={checklists}
                status={status}
                carFileId={carFileId}
                onChecklistSubmit={onChecklistSubmit}
                checklistIsSubmitting={checklistIsSubmitting}
                fullScreenHeightOnMobile={false}
              />
            ),
            onRowClick: ({ isExpanded: isCurrentlyExpanded }) => {
              typeof onRowClick === 'function' &&
                onRowClick(status, isCurrentlyExpanded)
            },
          }
        },
      )}
      initialExpandedRowId={initialExpandedStatus}
      {...restProps}
    />
  )
}

CarFileChecklistStatussesTable.propTypes = {
  carFileId: PropTypes.string.isRequired,
  checklistStatusses: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(Object.values(CarFileStatusEnumUpperCase))
        .isRequired,
      statusOrder: PropTypes.number.isRequired,
      averageDays: PropTypes.number,
      uniqueCarCount: PropTypes.number,
      inactive: PropTypes.bool,
      startedAt: PropTypes.string,
      finishedAt: PropTypes.string,
      checklists: ChecklistsOverview.propTypes.checklists,
      totalProgress: PropTypes.shape({
        checked: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
      }),
      totalBlockedProgress: PropTypes.shape({
        checked: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
      }),
    }),
  ),
  initialExpandedStatus: PropTypes.oneOf(
    Object.values(CarFileStatusEnumUpperCase),
  ),
  onRowClick: PropTypes.func,
  onChecklistSubmit: PropTypes.func,
  checklistIsSubmitting: PropTypes.bool,
}

export default CarFileChecklistStatussesTable
