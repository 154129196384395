/*
 * @TODO This view and all its children are preferably refactored in line with, /
 * using the components used in, the RdwStockView. The RdwStockView is pretty
 * stateless: deriving props from the querystring in the url, and setting
 * state by updating the url. That's a much easier solution than all the state
 * used in this view and it's children and therefore should be implemented /
 * reused in here to (since the two views are practically the same with some
 * small differences
 */

import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { media } from 'utilities/styled'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import {
  getObjectFromQueryString,
  handleChangeQuery,
  scrollToTop,
} from 'utilities/utils'
import * as routes from 'config/routes'
import SearchResultsOverview from 'components/layouts/search-results-overview'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Filters from 'components/molecules/filters'
import StockItems from 'components/organisms/stock-items'
import StockHeader from 'components/organisms/stock-header'
import Overlay from 'components/molecules/overlay'

const ResultsContainer = styled.div`
  align-items: start;
  display: grid;
  column-gap: ${({ theme }) => theme.widths.gutter};
  margin-bottom: 40px;

  ${media.desktop`
    grid-template-areas: "spacing header"
                   "filters results";
    grid-template-rows: auto auto;
    grid-template-columns: ${({ theme }) => theme.widths.navigationBarWidth} 1fr;
  `};
`

const StyledStockHeader = styled(StockHeader)`
  margin-bottom: 20px;

  ${media.desktop`
    grid-area: header;
    margin-left: calc(${({ theme }) => `${theme.widths.navigationBarWidth} + ${theme.widths.gutter}`});
  `};
`
//
// @TODO Refactor RdwFiklters component to regular Filters component when
// it is reusable for both views
const StyledFilters = styled(Filters)`
  display: ${({ filtersVisibleOnMobile }) =>
    filtersVisibleOnMobile ? 'block' : 'none'};
  bottom: 0;
  position: ${({ filtersVisibleOnMobile }) =>
    filtersVisibleOnMobile ? 'fixed' : 'static'};
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;

  ${media.desktop`
    bottom: auto;
    display: block;
    grid-area: filters;
    left: auto;
    position: static;
    right: auto;
    top: auto;
    z-index: auto;
  `};
`

const MobileFilters = styled(Filters)`
  width: 100%;
`

const StockResultsContainer = styled.div`
  ${media.desktop`
    grid-area: results;
  `};
`

function B2bTradePlatform({
  getB2bTradePlatformSortOptions,
  getB2bTradePlatformFilters,
  getB2bTradePlatformResults,
  history,
  location,
  b2bTradePlatformFilters,
  b2bTradePlatformResults,
  b2bTradePlatformSortOptions,
}) {
  const { t } = useTranslation()
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

  // Sorting
  const parseSortOptionFromQuery = (query) => {
    const params = new URLSearchParams(query)
    return {
      sort: params.get('sort'),
      dir: params.get('dir'),
    }
  }
  const getSortOptionValuesFromQuery = useCallback(() => {
    return parseSortOptionFromQuery(location.search)
  }, [location.search])

  const sortOptions =
    b2bTradePlatformSortOptions &&
    b2bTradePlatformSortOptions.data &&
    b2bTradePlatformSortOptions.data.length &&
    b2bTradePlatformSortOptions.data.map((item) => ({
      ...item,
      label: `${item.label} (${t(item.dir)})`,
      value: `sort=${item.field}&dir=${item.dir}`,
    }))
  const defaultSelectedSortOption =
    sortOptions && sortOptions.find((option) => option.selected)

  const sortOptionValuesFromQuery = getSortOptionValuesFromQuery()
  const selectedSortOption =
    sortOptions &&
    sortOptions.find(
      (option) =>
        option.field === sortOptionValuesFromQuery.sort &&
        option.dir === sortOptionValuesFromQuery.dir,
    )
  const sortOption = selectedSortOption || defaultSelectedSortOption
  const sortOptionQuery =
    sortOption && `sort=${sortOption.field}&dir=${sortOption.dir}`
  // /Sorting

  const queryFilters = getObjectFromQueryString(location.search) || {}

  const loading = !b2bTradePlatformFilters || b2bTradePlatformFilters.loading
  const b2bTradePlatformResultsTotal = Number(
    b2bTradePlatformResults &&
      b2bTradePlatformResults.data &&
      b2bTradePlatformResults.data.total,
  )
  const items =
    b2bTradePlatformResults &&
    b2bTradePlatformResults.data &&
    b2bTradePlatformResults.data.items
  // determine which page we are one from query string:
  const page = queryFilters && (queryFilters.page || 1)
  const resultsPerPage = 36

  function handleResetFilters() {
    history.push(routes.B2B_TRADE_PLATFORM)
    setMobileFiltersOpen(false)
  }

  function handleFilterChange(filter, value) {
    handleChangeQuery(location, history, filter, value, filter !== 'page')
  }

  function handleSortOptionChange(value) {
    const values = parseSortOptionFromQuery(value)
    const newQuery = new URLSearchParams(location.search)
    newQuery.set('sort', values.sort)
    newQuery.set('dir', values.dir)
    history.push({
      pathname: location.pathname,
      search: newQuery.toString(),
    })
  }

  function handleToggleMobileFilters() {
    setMobileFiltersOpen(!mobileFiltersOpen)
  }

  function handleChangePage(newPage) {
    handleFilterChange('page', newPage)
    // scroll to top because that's were the new results will be:
    scrollToTop()
  }

  useEffect(() => {
    getB2bTradePlatformSortOptions()
  }, [getB2bTradePlatformSortOptions])

  useDeepCompareEffect(() => {
    const filters = {
      ...queryFilters,
      dir: sortOption?.dir,
      sort: sortOption?.field,
    }
    getB2bTradePlatformFilters(filters)
    getB2bTradePlatformResults(filters, page, resultsPerPage)
  }, [
    queryFilters,
    getB2bTradePlatformResults,
    page,
    resultsPerPage,
    sortOption,
  ])

  const linkGenerator = (item) => {
    return `${routes.B2B_CAR_FILE}/${item.id || item.auto_id}`
  }

  return (
    <>
      <SearchResultsOverview
        pageTitle={`${t('b2bTradePlatform.overview.heading.prefix')}${t('b2bTradePlatform.overview.heading.suffix')}`}
        subTitle={t('overview')}
        mainTitle={t('b2bTradePlatform.overview.heading.prefix')}
        statusTitle={t('b2bTradePlatform.overview.heading.suffix')}
      >
        {loading && <LoadingIndicator />}
        {!loading && b2bTradePlatformResults && items && (
          <>
            <StyledStockHeader
              onSortOptionChange={handleSortOptionChange}
              selectedSortOption={sortOptionQuery}
              sortOptions={sortOptions}
              toggleMobileFilters={handleToggleMobileFilters}
              total={b2bTradePlatformResultsTotal}
            />
            <ResultsContainer>
              <StyledFilters
                onUpdate={handleFilterChange}
                selectedFilters={queryFilters}
                filters={b2bTradePlatformFilters.data}
                onResetFilters={handleResetFilters}
              />
              <StockResultsContainer>
                <StockItems
                  linkGenerator={linkGenerator}
                  items={items}
                  onChangePage={handleChangePage}
                  page={Number(page)}
                  itemsPerPage={resultsPerPage}
                  hideSummary
                  status={`${t('b2b')} ${t('car')}`}
                  totalItems={b2bTradePlatformResultsTotal}
                />
              </StockResultsContainer>
            </ResultsContainer>
          </>
        )}
      </SearchResultsOverview>
      {mobileFiltersOpen && (
        <Overlay close={handleToggleMobileFilters} title={t('filters')}>
          <MobileFilters
            onUpdate={handleFilterChange}
            selectedFilters={queryFilters}
            filters={b2bTradePlatformFilters.data}
            onResetFilters={handleResetFilters}
          />
        </Overlay>
      )}
    </>
  )
}

B2bTradePlatform.propTypes = {
  getB2bTradePlatformFilters: PropTypes.func.isRequired,
  getB2bTradePlatformSortOptions: PropTypes.func.isRequired,
  getB2bTradePlatformResults: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  b2bTradePlatformFilters: PropTypes.object,
  b2bTradePlatformResults: PropTypes.object,
  b2bTradePlatformSortOptions: PropTypes.object,
}

B2bTradePlatform.defaultProps = {
  b2bTradePlatformFilters: null,
  b2bTradePlatformResults: null,
  b2bTradePlatformSortOptions: null,
}

export default withRouter(B2bTradePlatform)
