import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Icon from 'components/atoms/icon'
import Typography from 'components/molecules/typography'
import Counter from 'components/atoms/counter'

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`

const StyledCounter = styled(Counter)`
  margin-left: 8px;
`

const Container = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const StyledHeading = styled(Typography)`
  margin-top: 0;
  margin-bottom: 0;
`

const IconHeading = ({ iconType, badgeCount, headingType, children }) => (
  <Container>
    <StyledIcon type={iconType} color="brandAlfa" height="28" />
    <StyledHeading type={headingType}>{children}</StyledHeading>
    {!!badgeCount && <StyledCounter count={badgeCount} />}
  </Container>
)

IconHeading.propTypes = {
  children: PropTypes.node.isRequired,
  iconType: PropTypes.string.isRequired,
  badgeCount: PropTypes.number,
  headingType: Typography.propTypes.type,
}

IconHeading.defaultProps = {
  badgeCount: 0,
  headingType: 'Level1Heading',
}

export default IconHeading
