import * as React from 'react'
import Tabs from 'components/layouts/tabs'
import ContractExtensionRequests from 'components/views/car-service-plan/contract-extension-requests'
import ContractsOverview from 'components/views/car-service-plan/contracts-overview'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import {
  CSP_CONTRACT_TABS_OVERVIEW,
  CSP_CONTRACT_TABS_EXTENSION_REQUESTS,
} from 'config/routes'

const ContractTabs = (props) => {
  const { t } = useTranslation()
  const match = useRouteMatch()

  const items = [
    {
      label: t('carServicePlanDashboard.contractOverview'),
      to: `${match.url}${CSP_CONTRACT_TABS_OVERVIEW}`, // Using match.url in stead of hard-coding it to the CSP dashboard url so this component can be used on any url
      component: (props) => <ContractsOverview {...props} />,
    },
    {
      label: t('carServicePlanDashboard.contractExtensionRequests'),
      to: `${match.url}${CSP_CONTRACT_TABS_EXTENSION_REQUESTS}`, // Using match.url in stead of hard-coding it to the CSP dashboard url so this component can be used on any url
      component: (props) => <ContractExtensionRequests {...props} />,
    },
  ]

  return (
    <Tabs items={items} {...props}>
      {items.map((item, index) => (
        <item.component key={index.toString()} />
      ))}
    </Tabs>
  )
}

ContractTabs.propTypes = {
  // match: PropTypes.object.isRequired,
  // /** A history object to use for navigation from react-router. [Documentation](https://reacttraining.com/react-router/web/api/Router/history-object)  */
  // history: PropTypes.object.isRequired,
  // /** A location object to use for navigation from react-router. [Documentation](https://reacttraining.com/react-router/web/api/NavLink/location-object) */
  // location: PropTypes.object.isRequired,
}

export default ContractTabs
