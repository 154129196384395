import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Text from 'components/atoms/text'
import OptionsSelect from 'components/atoms/options-select'

const Instruction = styled(Text)`
  display: block;
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

function DeliveryAccessoriesSettings({
  deliveryAccessories,
  addDeliveryAccessories,
  getDeliveryAccessories,
  locationId,
}) {
  const { t } = useTranslation()
  const accessories =
    deliveryAccessories &&
    deliveryAccessories[locationId] &&
    deliveryAccessories[locationId].data &&
    deliveryAccessories[locationId]
  const loading = accessories && accessories.loading
  const accessoriesData = accessories && accessories.data
  const defaultAccessories = accessoriesData
    ? accessoriesData.map(({ omschrijving }) => ({
        label: omschrijving,
        value: omschrijving,
      }))
    : []

  function handleChangeSelectedAccessories(newAccessories) {
    addDeliveryAccessories({
      id: locationId,
      accessories: newAccessories,
    })
  }

  useEffect(() => {
    getDeliveryAccessories({
      id: locationId,
    })
  }, [getDeliveryAccessories, locationId])

  return (
    <>
      <Instruction
        text={t('deliveryAccessoriesInstruction')}
        type="floatingLabelLabel"
      />
      {!accessories || loading ? (
        <LoadingIndicator />
      ) : (
        <OptionsSelect
          name="deliveryPackagesAccessories"
          defaultValue={defaultAccessories}
          items={defaultAccessories}
          onChange={handleChangeSelectedAccessories}
          placeholder={t('addAccessories')}
          hideClearIndicator
        />
      )}
    </>
  )
}

DeliveryAccessoriesSettings.propTypes = {
  addDeliveryAccessories: PropTypes.func.isRequired,
  deliveryAccessories: PropTypes.object,
  getDeliveryAccessories: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
}

DeliveryAccessoriesSettings.defaultProps = {
  deliveryAccessories: null,
}

export default DeliveryAccessoriesSettings
