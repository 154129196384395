import React from 'react'
import styled from 'styled-components'

import NewDropdownSelect, {
  propTypes,
} from 'components/molecules/new-dropdown-select'

const StyledNewDropdownSelect = styled(NewDropdownSelect)`
  flex-shrink: 0;
  width: ${({ theme }) => theme.sizeByFactor(30)};
`

/**
 * Note: Only use this component in the context of the management info page.
 * It's seperated into a molecule because it's being re-used in different charts,
 * yet it's not meant to be used outside of the management info page.
 */
function ManagementInfoChartSelect({
  className,
  items,
  onChange,
  label,
  value,
}) {
  return (
    <StyledNewDropdownSelect
      className={className}
      items={items}
      onChange={onChange}
      label={label}
      value={value}
      filled
      selectionRequired
    />
  )
}

ManagementInfoChartSelect.propTypes = propTypes

export default ManagementInfoChartSelect
