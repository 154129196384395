import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { submit } from 'redux-form'

import { getCarfile, updateCarfile } from 'redux/actions/data'
import { getForms } from 'redux/actions/forms'

import { mapCarFileType } from 'utilities/mapping'
import { getObjectFromQueryString, scrollToTop } from 'utilities/utils'
import { media } from 'utilities/styled'

import PageHeader from 'components/organisms/page-header'
import CarEntryHeader from 'components/molecules/car-entry-header'
import FullWidthTopBarLayout from 'components/layouts/full-width-top-bar-layout'
import FlowStepper from 'components/molecules/flow-stepper'

import CarBpmDeclarationStep2Tabs from './car-bpm-declaration-step-2-tabs'

const Container = styled.div`
  padding: ${({ theme }) => theme.sizings.lvl2} 20px;
  ${media.desktop`
      padding: ${({ theme }) => theme.sizings.lvl2} 60px;
  `}
`

const DesktopPageHeader = styled(PageHeader)`
  display: none;
  ${media.tablet`
    display: block;
  `};
`

const StyledCarEntryHeader = styled(CarEntryHeader)`
  border-bottom: ${({ theme }) =>
    `${theme.colors.darkOnLightBorder} 1px solid`};
`

const CarBpmDeclarationStep2 = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const queryParams = getObjectFromQueryString(location.search)
  const carId = queryParams && queryParams.auto_id
  const carFile = useSelector(
    (state) => state.data && state.data.carfile && state.data.carfile.data,
  )
  const forms = useSelector((state) => state.forms)
  const loading = useSelector((state) => {
    const { data: stateData } = state
    return stateData.updateCarfile && stateData.updateCarfile.loading
  })
  const formId = 'bpmDeclaration'

  const [done, setDone] = useState(false)

  const paths = {
    basic: `${match.url}/basic`,
    equipment: `${match.url}/equipment`,
    bpmDeclaration: `${match.url}/bpm-determation`,
  }

  const stepperItems = [
    {
      active: location.pathname === paths.basic,
      label: t('basicData'),
      link: `${paths.basic}${location.search}`,
    },
    {
      active: location.pathname === paths.equipment,
      label: t('equipment'),
      link: `${paths.equipment}${location.search}`,
    },
    {
      active: location.pathname === paths.bpmDeclaration,
      label: t('bpmDetermation'),
      link: `${paths.bpmDeclaration}${location.search}`,
    },
  ]

  const car = {
    ...carFile,
  }

  const handleGetCarFile = useCallback(() => {
    dispatch(getCarfile(carId))
  }, [dispatch, carId])

  const goToCar = useCallback(() => {
    history.push(`/car/${carId}/bpm/data`)
  }, [carId, history])

  useEffect(() => {
    if (done) {
      if (!carId) {
        // Show an alert when trying to close
        // the tabs before having saved a car
        setDone(false)
        alert(t('enterDetailsAndSaveFirst'))
      } else {
        // go to the advertisements tab:
        goToCar()
      }
    }
  }, [done, carId, t, history, goToCar])

  const handleChangeStep = useCallback(
    async (newIndex, oldIndex, actionType) => {
      if (actionType === 'flow-stepper') {
        dispatch(submit(formId))
      }
      await handleGetCarFile(carId)
      await dispatch(
        getForms('bpmDeclaration', {
          kenteken: car.kenteken,
          auto_id: carId,
        }),
      )
      return true
    },
    [car.kenteken, carId, dispatch, handleGetCarFile],
  )

  const handleSubmit = useCallback(
    async (updatedData, finish, justUpdate) => {
      if (!justUpdate) {
        // eslint-disable-next-line no-use-before-define
        const noErrors = handleChangeStep ? await handleChangeStep() : true
        if (!noErrors) {
          return false
        }
      }

      if (loading) {
        return false
      }

      if (carId) {
        // This saves the new input values after submitting the form (when redirecting after "onClose" in ComplexForm) AND after the flow-stepper steps
        dispatch(
          updateCarfile(
            {
              ...car,
              ...updatedData,
            },
            carId,
          ),
        )
      }

      if (finish) {
        setDone(true)
        goToCar()
      }

      return true
      // eslint-disable-next-line no-use-before-define
    },
    [loading, car, carId, goToCar, handleChangeStep, dispatch],
  )

  useEffect(() => {
    if (carId && !carFile) {
      handleGetCarFile(carId)
    }
  }, [carFile, carId, dispatch, handleGetCarFile])

  useEffect(() => {
    scrollToTop()
    dispatch(
      getForms('bpmDeclaration', { kenteken: car.kenteken, auto_id: carId }),
    )
  }, [dispatch, carId, car.kenteken])

  useEffect(() => {
    if (!forms) {
      dispatch(
        getForms('bpmDeclaration', { kenteken: car.kenteken, auto_id: carId }),
      )
    }
  }, [car.kenteken, carId, forms, dispatch])

  return (
    <Container>
      <DesktopPageHeader
        mainTitle={mapCarFileType(t, car?.voertuigsoort_mapped)}
        status={t('bpmDeclaration')}
      />
      <FullWidthTopBarLayout
        paddingLevelTopBar={2}
        paddingLevelMain={0}
        topBarNoPaddingSlot={
          <StyledCarEntryHeader
            brand={car.merk}
            model={car.model}
            type={car.type}
            licensePlate={car.kenteken}
            mileage={car.km_binnen}
            buttonIcon="pen"
            buttonTo="/bpm/1"
          />
        }
        topBarSlot={
          <FlowStepper items={stepperItems} onChange={handleChangeStep} />
        }
        mainSlot={
          <CarBpmDeclarationStep2Tabs
            handleChangeStep={handleChangeStep}
            handleSubmit={handleSubmit}
          />
        }
      />
    </Container>
  )
}

export default CarBpmDeclarationStep2
