import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { media } from 'utilities/styled'

import { postDynamicEndpoint } from 'redux/actions/data'

import FlexibleDialog from 'components/molecules/flexible-dialog'
import CarEntryHeader from 'components/molecules/car-entry-header'
import Typography from 'components/molecules/typography'
import ComplexForm from 'components/molecules/complex-form'

const StyledCarEntryHeader = styled(CarEntryHeader)`
  background-color: ${({ theme }) => theme.colors.sectionBackground};
`

const StyledFlexibleDialog = styled(FlexibleDialog)`
  .MuiPaper-root {
    overflow: hidden auto;
  }

  ${media.tablet`
    .MuiPaper-root {
      min-width: ${({ theme }) => `${theme.metrics.phone}px`};
    }
  `}
`

const StyledTypography = styled(Typography)`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const CarFileCarShareAddBidDialog = ({ className, open, onCancel }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const carFile = useSelector((state) => state?.data?.carfile)
  const dynamicEndpoint = useSelector((state) => state?.data?.dynamicEndpoint)
  const topdownSharedBidForm = useSelector(
    (state) => state?.forms?.topdownSharedBidForm,
  )
  const carAssets = useSelector((state) => state?.data?.carAssets)

  const carFileData = carFile?.data
  const carPicture =
    carAssets?.data?.find(
      (picture) =>
        picture?.position === 1 && picture?.category === 'commercieel',
    )?.src || undefined

  const handleSubmitAddBidConfirmation = async (formValues) =>
    dispatch(
      postDynamicEndpoint({
        endpoint: topdownSharedBidForm?.data?.endpoint,
        ...formValues,
      }),
    )

  return (
    <StyledFlexibleDialog
      className={className}
      title={t('confirmYourBid')}
      open={open}
      content={
        <>
          <StyledTypography type="ExplanationParagraph">
            {t('carShare.confirmBidDialogExplanation')}
          </StyledTypography>
          <StyledCarEntryHeader
            imagePath={carPicture}
            brand={carFileData?.brand}
            model={carFileData?.model}
            type={carFileData?.type}
            mileage={carFileData?.mileage}
            year={carFileData?.jaardeel1}
          />
          <ComplexForm
            formId="topdownSharedBidForm"
            data={carFile}
            level="cta"
            fieldsets={topdownSharedBidForm?.data?.fieldsets}
            onSubmit={handleSubmitAddBidConfirmation}
            submitText={t('confirmBid')}
            onCancel={onCancel}
            cancelText={t('cancel')}
            isLoading={dynamicEndpoint?.loading === true}
            submitting={dynamicEndpoint?.loading === true}
            enableReinitialize
          />
        </>
      }
    />
  )
}

CarFileCarShareAddBidDialog.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

CarFileCarShareAddBidDialog.defaultProps = {
  className: null,
}

export default CarFileCarShareAddBidDialog
