import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Text from 'components/atoms/text'

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.actionsCta};
  border-radius: 9999px;
  display: flex;
  height: 18px;
  min-width: 18px;
  justify-content: center;
  text-align: center;
  z-index: 1;
  padding-left: 3px;
  padding-right: 3px;
`

function Counter({ className, count, ...restProps }) {
  return (
    <Container className={className} {...restProps}>
      <Text type="count" text={count} />
    </Container>
  )
}

Counter.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
}

Counter.defaultProps = {
  className: null,
}

export default Counter
