import React, { useCallback, useEffect, useState, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaLayout } from 'use-media'
import { useTranslation } from 'react-i18next'
import { ClickAwayListener } from '@material-ui/core'

import {
  addDamage,
  addDamageImages,
  deleteDamage,
  getDamage,
} from 'redux/actions/data'
import { openOverlay } from 'redux/actions/ui'

import PopupMenu from 'components/molecules/popup-menu'
import Button from 'components/atoms/button'
import Typography from 'components/molecules/typography'
import NextPrevCloseButtons, {
  rightIconWithChevronProps,
} from 'components/molecules/next-prev-close-buttons'
import SelectModal from 'components/molecules/select-modal'
import DamageCards from 'components/molecules/damage-cards'

import { media } from 'utilities/styled'
import { scrollTo } from 'utilities/utils'

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

const StyledButton = styled(Button)`
  ${media.tablet`
    margin-top: -12px;
  `}
`

const DamagesContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.sizings.lvl4};
`

const StyledPopupMenu = styled(PopupMenu)`
  .actual-menu {
    top: -12px;
    right: 0;
  }
`

function DamageForm({
  className,
  carId,
  closeText,
  next,
  nextTab,
  onClose,
  hasStickyNav,
  optionButtonText,
  onOptionButtonClick,
  hasChevronInNextPrevCloseButtons,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [damageSelectorSelectModalOpen, setDamageSelectorSelectModalOpen] =
    useState(false)
  const [triggerAfterAdding, setTriggerAfterAdding] = useState(false)
  const damage = useSelector((state) => state.data.damage)
  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })

  const handleSubmit = useCallback(
    async (technical, values, formDataList, scrollAfterSubmit = true) => {
      const submittedDamage = await dispatch(
        addDamage(technical, {
          ...values,
          auto_id: carId,
        }),
      )

      const imagePromises = formDataList.map((formData) =>
        dispatch(
          addDamageImages(formData, {
            auto_id: carId,
            damage_id: values.damageId || submittedDamage.id,
          }),
        ),
      )

      return Promise.all(imagePromises).then(() => {
        setTriggerAfterAdding(scrollAfterSubmit)
      })
    },
    [carId, dispatch],
  )

  const handleDeleteDamage = useCallback(
    (data) => {
      dispatch(
        deleteDamage(data, {
          auto_id: carId,
          id: data.id,
        }),
      )
    },
    [carId, dispatch],
  )

  const handleOpenDamageModal = () => {
    setDamageSelectorSelectModalOpen(true)
  }

  const handleCloseDamageModal = () => {
    setDamageSelectorSelectModalOpen(false)
  }

  const handleOpenOpticalDamage = useCallback(
    (data, noSteps, scrollAfterSubmit) => {
      dispatch(
        openOverlay('opticalDamage', {
          data,
          noSteps,
          onSubmit: (values, formDataList) =>
            handleSubmit(false, values, formDataList, scrollAfterSubmit),
        }),
      )
    },
    [dispatch, handleSubmit],
  )

  const handleOpenTechnicalDamage = useCallback(
    (data, noSteps, scrollAfterSubmit) => {
      dispatch(
        openOverlay('technicalDamage', {
          data,
          noSteps,
          onSubmit: (values, formDataList) =>
            handleSubmit(true, values, formDataList, scrollAfterSubmit),
        }),
      )
    },
    [dispatch, handleSubmit],
  )

  useEffect(() => {
    dispatch(getDamage({ auto_id: carId }))
  }, [carId, dispatch])

  useEffect(() => {
    if (triggerAfterAdding) {
      setTriggerAfterAdding(false)

      if (Array.isArray(damage?.data) && damage?.data?.length > 0) {
        const newestDamageItemId = damage.data.reduce(
          (prev, curr) =>
            Number(prev.id) > Number(curr.id)
              ? Number(prev.id)
              : Number(curr.id),
          [0],
        )
        const newestDamageElement = document.querySelector(
          `[data-damage-id="${newestDamageItemId}"]`,
        )

        scrollTo(newestDamageElement)
      }
    }
  }, [damage, triggerAfterAdding])

  return (
    <div className={className}>
      <HeaderContainer>
        <Typography type="Level4Heading">{t('addDamage')}</Typography>

        <ClickAwayListener
          onClickAway={!isMobile ? handleCloseDamageModal : () => null}
        >
          <div>
            <StyledPopupMenu
              items={[
                {
                  icon: 'settings',
                  label: t('opticalDamage'),
                  onClick: () => handleOpenOpticalDamage(),
                },
                {
                  icon: 'settings',
                  label: t('technicalDamage'),
                  onClick: () => handleOpenTechnicalDamage(),
                },
              ]}
              positionMenu={{ toLeft: true }}
              isMenuActive={!isMobile && damageSelectorSelectModalOpen}
            >
              <StyledButton
                background="actionsStandard"
                icon="add"
                onClick={handleOpenDamageModal}
                level="minimal"
                data-test-e2e="add-damage-button"
              />
            </StyledPopupMenu>
          </div>
        </ClickAwayListener>
      </HeaderContainer>

      <DamagesContainer>
        {!damage || !damage.data || !damage.data.length ? (
          <>
            <Typography type="ExplanationParagraph">
              {t('noDamageYet')}
            </Typography>
            <Typography type="ExplanationParagraph">
              {t('clickButtonToAddDamage')}
            </Typography>
          </>
        ) : (
          <DamageCards
            damages={damage.data}
            onDeleteDamage={handleDeleteDamage}
            onSelectOpticalDamage={(data) =>
              handleOpenOpticalDamage(data, true, false)
            }
            onSelectTechnicalDamage={(data) =>
              handleOpenTechnicalDamage(data, true, false)
            }
          />
        )}
      </DamagesContainer>

      {next || onClose ? (
        <NextPrevCloseButtons
          closeText={closeText}
          optionButtonText={optionButtonText}
          onOptionButtonClick={onOptionButtonClick}
          nextTab={next && nextTab}
          onClose={onClose}
          hasStickyNav={hasStickyNav}
          {...(hasChevronInNextPrevCloseButtons && rightIconWithChevronProps)}
        />
      ) : null}

      {isMobile && damageSelectorSelectModalOpen && (
        <SelectModal
          title={t('addDamage')}
          showCloseButton
          open={damageSelectorSelectModalOpen}
          closeHandler={() => setDamageSelectorSelectModalOpen(false)}
          options={[
            {
              icon: 'settings',
              label: t('opticalDamage'),
              handler: handleOpenOpticalDamage,
              testId: 'button-optical-damage',
            },
            {
              icon: 'settings',
              label: t('technicalDamage'),
              handler: handleOpenTechnicalDamage,
              testId: 'button-technical-damage',
            },
          ]}
          data-test-e2e="damage-selector-modal"
        />
      )}
    </div>
  )
}

DamageForm.propTypes = {
  className: PropTypes.object,
  carId: PropTypes.string.isRequired,
  closeText: PropTypes.string,
  next: PropTypes.bool,
  nextTab: PropTypes.func,
  onClose: PropTypes.func,
  optionButtonText: PropTypes.string,
  onOptionButtonClick: PropTypes.func,
  hasStickyNav: PropTypes.bool,
  hasChevronInNextPrevCloseButtons: PropTypes.bool,
}

DamageForm.defaultProps = {
  className: null,
  closeText: null,
  onClose: null,
  next: null,
  nextTab: null,
  optionButtonText: null,
  onOptionButtonClick: null,
  hasStickyNav: false,
  hasChevronInNextPrevCloseButtons: false,
}

export default DamageForm
