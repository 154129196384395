import LoadingIndicator from 'components/atoms/loading-indicator'
import ComplexForm from 'components/molecules/complex-form'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { clearData, postDynamicEndpoint } from 'redux/actions/data'
import { getForms } from 'redux/actions/forms'
import { translateForm } from 'utilities/form-utils'
import { useQueryParam } from 'utilities/hooks'
import PropTypes from 'prop-types'
import toast from 'utilities/toast'
import { reset } from 'redux-form'

const formId = 'rdwRetractCar'

/**
 * Retracts a car from RDW as being available for ownership transfer.
 * Effectively undoes what ./rdw-check-car-form.js does.
 */
const RdwRetractCarForm = ({ carFileId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const form = useSelector((state) => state?.forms?.rdwRetractCar?.data?.form)
  const licensePlate = useQueryParam('licenseplate', true) || ''
  const [formValues, setFormValues] = React.useState({
    kenteken: licensePlate,
  })
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  useEffect(() => {
    if (!form) {
      dispatch(getForms(formId, { auto_id: carFileId }))
    }
  }, [dispatch, form, carFileId])

  const handleFormChange = (values) => {
    setFormValues(values)
  }

  const onSubmit = () => {
    setIsSubmitting(true)
    return dispatch(
      postDynamicEndpoint({
        endpoint: form.endpoint,
        ...formValues,
      }),
    )
      .then((response) => {
        if (response?.success) {
          toast.success(response.data.message, { autoClose: 10000 })
          dispatch(clearData('rdwSentCars'))
          dispatch(reset(formId))
          setFormValues({})
        }
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  if (form) {
    const translatedForm = translateForm(form.fieldsets, t)

    return (
      <ComplexForm
        level="cta"
        data={formValues}
        fieldsets={translatedForm}
        formId="rdwRetractCar"
        onChange={handleFormChange}
        onSubmit={onSubmit}
        submitText={t('rdwCarTransfer.retractCarTab.submitButtonText')}
        enableReinitialize
        isLoading={isSubmitting}
      />
    )
  } else {
    return <LoadingIndicator />
  }
}

RdwRetractCarForm.propTypes = {
  carFileId: PropTypes.string,
}

RdwRetractCarForm.defaultProps = {
  carFileId: null,
}

export default RdwRetractCarForm
