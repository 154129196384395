import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useMediaLayout } from 'use-media'
import { useTranslation } from 'react-i18next'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import styled, { ThemeContext } from 'styled-components'
import MonthYearPicker from 'components/atoms/month-year-picker'

const Container = styled.div`
  width: 100%;
`
function DatePicker ({
  className,
  onChange,
  value,
  clearable,
  disabled,
}) {
  const date = value && moment(value)

  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })
  const { t } = useTranslation()
  const [focused, setFocused ] = useState()

  function handleDateChange (newDate) {
    onChange(newDate)
  }

  function handleFocusChange (values) {
    setFocused(values.focused)
  }

  return (
    <Container
      className={className}
    >
      <SingleDatePicker
        date={date}
        displayFormat="DD/MM/YYYY"
        focused={focused}
        isOutsideRange={() => false}
        numberOfMonths={1}
        onDateChange={handleDateChange}
        onFocusChange={handleFocusChange}
        placeholder={t('pickDate')}
        withPortal={isMobile}
        renderMonthElement={MonthYearPicker}
        showClearDate={clearable}
        disabled={disabled}
        noBorder
        readOnly
        small
      />
    </Container>
  )
}

DatePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
}

DatePicker.defaultProps = {
  className: null,
  value: null,
  disabled: false,
}

export default DatePicker
