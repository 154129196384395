import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const StyledP = styled.p`
  color: ${({ theme, $color }) => theme.colors[$color] || theme.colors.text};
  font-family: ${({ theme }) => theme.font};
  font-size: ${({ theme }) => theme.baseFontSize};
  font-weight: ${({ theme, $weight }) => $weight || theme.baseFontWeight};
  line-height: ${({ theme }) => theme.baseLineHeight};
  margin: 1em 0;
  strong {
    font-weight: 600;
  }
`

const BodyParagraph = ({
  className,
  children,
  color,
  weight,
  ...restProps
}) => (
  <StyledP $color={color} $weight={weight} className={className} {...restProps}>
    {children}
  </StyledP>
)

BodyParagraph.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  /** One of the theme color names */
  color: PropTypes.string,
  weight: PropTypes.number,
}

BodyParagraph.defaultProps = {
  className: null,
  children: null,
  color: 'text',
  weight: null,
}

export default BodyParagraph
