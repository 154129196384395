import { useQuery } from '@apollo/client'
import Link from 'components/atoms/link'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Typography from 'components/molecules/typography'
import EnhancedTable from 'components/organisms/enhanced-table'
import { CHECKLIST_WIDGET_DETAILS } from 'config/graphql/v4'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { formatPercentage } from 'utilities/format'
import { media } from 'utilities/styled'

const Table = styled(EnhancedTable)`
  position: relative;

  th {
    border-top: none;
  }

  ${media.tv`
    > .MuiTableContainer-root {
      overflow: visible;
    }
  `}
`

const RowText = styled(Typography)`
  margin-top: 0;
  margin-bottom: 0;
`

const Strong = styled.strong`
  font-weight: 500;
`

const StyledLink = styled(Link)`
  display: inline-block;

  & > * {
    color: ${({ $color, theme }) =>
      theme.colors[$color] || theme.colors.actionsStandard};
  }
`

const ChecklistDetails = ({
  id,
  onTotalCarsClick,
  onDelayedCarsClick,
  ...restProps
}) => {
  const { t } = useTranslation()

  const { data, loading, error } = useQuery(CHECKLIST_WIDGET_DETAILS, {
    variables: {
      checklistId: id,
    },
  })

  if (loading || error) {
    return <LoadingIndicator error={error ? t('genericError') : undefined} />
  }

  const columns = [
    { id: 'name', label: t('checklistDetails.tableHeadings.name') },
    { id: 'total', label: t('checklistDetails.tableHeadings.total') },
    { id: 'delayed', label: t('checklistDetails.tableHeadings.delayed') },
    {
      id: 'averageDuration',
      label: t('checklistDetails.tableHeadings.averageDuration'),
    },
  ]

  const results = data?.flexFlowWidgetByChecklist || []

  const rows = results.map((result) => {
    const totalCars = (
      <RowText type="BodyParagraph">
        <Strong>{result.total}</Strong>{' '}
        {t(result.total === 1 ? 'car' : 'cars').toLowerCase()}
      </RowText>
    )
    const delayedCars = (
      <RowText type="BodyParagraph">
        <Strong>{result.delayed}</Strong>{' '}
        {t(
          result.delayed === 1 ? 'carWithDelay' : 'carsWithDelay',
        ).toLowerCase()}{' '}
        ({formatPercentage(result.delayedPercentage, 1, true)})
      </RowText>
    )

    return {
      _attrs: {
        'data-test-e2e': 'checklist-item-row',
        'data-test-e2e-item-id': result.checklistItem.id,
      },
      name: {
        data: result.checklistItem.name,
        component: (
          <RowText type="BodyParagraph">{result.checklistItem.name}</RowText>
        ),
      },
      total: {
        data: result.total,
        component:
          typeof onTotalCarsClick === 'function' ? (
            <StyledLink
              onClick={(e) => {
                onTotalCarsClick(result, e)
              }}
            >
              {totalCars}
            </StyledLink>
          ) : (
            totalCars
          ),
      },
      delayed: {
        data: result.delayed,
        component:
          typeof onDelayedCarsClick === 'function' ? (
            <StyledLink
              onClick={(e) => {
                onDelayedCarsClick(result, e)
              }}
            >
              {delayedCars}
            </StyledLink>
          ) : (
            delayedCars
          ),
      },
      averageDuration: {
        data: result.averageDuration,
        component: (
          <RowText type="BodyParagraph">
            {result.averageDuration}{' '}
            {t(result.averageDuration === 1 ? 'day' : 'days').toLowerCase()}
          </RowText>
        ),
      },
    }
  })

  return (
    <Table columns={columns} rows={rows} selectable={false} {...restProps} />
  )
}

ChecklistDetails.propTypes = {
  id: PropTypes.string.isRequired,
  onTotalCarsClick: PropTypes.func,
  onDelayedCarsClick: PropTypes.func,
}

export default ChecklistDetails
