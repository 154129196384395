import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import LoadingIndicator from 'components/atoms/loading-indicator'
import ExpandableBars from 'components/layouts/expandable-bars'
import SummaryLine from 'components/molecules/summary-line'
import Tabs from 'components/layouts/tabs'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import BodyHeading from 'components/atoms/body-heading'

/**
 * Layout for all settings tab which shows an overview all locations.
 */
function LocationsSettings({
  getLocationsDetails,
  locationsDetails,
  title,
  tabItems,
  appFeature,
  appFeatureNotAllowedMessageId,
}) {
  const { t } = useTranslation()

  const rightsPerDealer = useSelector((state) => {
    const lookUp = new Map()
    if (Array.isArray(state?.auth?.userDetails?.dealers) && appFeature) {
      for (const dealerRights of state.auth.userDetails.dealers) {
        if (typeof dealerRights.rechten[appFeature] === 'boolean') {
          lookUp.set(dealerRights.dealernr, dealerRights.rechten[appFeature])
        }
      }
    }
    return lookUp
  })

  const hasRightsPerDealer =
    rightsPerDealer.size > 0 &&
    Array.from(rightsPerDealer.values()).every(
      (right) => typeof right === 'boolean',
    )

  useEffect(() => {
    getLocationsDetails()
  }, [getLocationsDetails])

  return (
    <>
      <BodyHeading level="2">{title} </BodyHeading>
      {locationsDetails && locationsDetails.data ? (
        <ExpandableBars
          data-test-e2e="locations-settings-bars"
          rows={locationsDetails.data.map((location) => ({
            id: location.id,
            header: (
              <SummaryLine
                key={location.dealernr}
                name={location.label}
                details={[
                  {
                    label: t('clientNumber'),
                    value: location.dealernr,
                  },
                  {
                    value: `${location.straat} ${location.postcode} ${location.plaats}`,
                  },
                  {
                    iconLabel: {
                      label: t('lastModifiedOn'),
                      icon: 'create',
                    },
                    value: location.updated.split(' ')[0],
                  },
                ]}
              />
            ),
            isDisabled:
              hasRightsPerDealer && !rightsPerDealer.get(location.dealernr),
            // isDisabledMessage: (
            //   (hasRightsPerDealer && !rightsPerDealer.get(location.dealernr)) &&
            //   !rightsPerDealer.get(location.dealernr)
            // )
            //   ? t(appFeatureNotAllowedMessageId, {
            //     dealerName: location.label,
            //   })
            //   : undefined,
            onRowClick: () => {},
            details:
              // if it has no dealer specific rights associated, just show the tabs,
              // because this is the default behaviour.
              !hasRightsPerDealer ||
              (hasRightsPerDealer && rightsPerDealer.get(location.dealernr)) ? (
                <Tabs items={tabItems} small fullScreenHeightOnMobile={false}>
                  {tabItems.map((tabItem, subIdx) => (
                    <tabItem.component
                      key={subIdx.toString()}
                      params={{
                        vestiging_id: location.id,
                      }}
                      dealerId={location.dealernr}
                      locationId={location.id}
                      onUpdateSuccess={getLocationsDetails}
                    />
                  ))}
                </Tabs>
              ) : (
                <Typography type="ExplanationParagraph">
                  {t(appFeatureNotAllowedMessageId, {
                    dealerName: location.label,
                  })}
                </Typography>
              ),
          }))}
        />
      ) : (
        <LoadingIndicator />
      )}
    </>
  )
}

LocationsSettings.propTypes = {
  getLocationsDetails: PropTypes.func.isRequired,
  tabItems: PropTypes.array.isRequired,
  locationsDetails: PropTypes.object,
  title: PropTypes.string.isRequired,
  /**
   * What the settings are about. Is used to check if a dealer has access to that feature.
   * Currently only flexflow is supported.
   */
  appFeature: PropTypes.string,
  appFeatureNotAllowedMessageId: PropTypes.string,
}

LocationsSettings.defaultProps = {
  locationsDetails: null,
  appFeature: null,
  appFeatureNotAllowedMessageId: null,
}

export default LocationsSettings
