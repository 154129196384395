export const types = {
  CLOSE_IMAGE_OVERLAY: 'CLOSE_IMAGE_OVERLAY',
  CLOSE_OVERLAY: 'CLOSE_OVERLAY',
  OPEN_OVERLAY: 'OPEN_OVERLAY',
  CLOSE_SEARCHBOX: 'CLOSE_SEARCHBOX',
  OPEN_IMAGE_OVERLAY: 'OPEN_IMAGE_OVERLAY',
  OPEN_SEARCHBOX: 'OPEN_SEARCHBOX',
  TOGGLE_SALES_VIEW: 'TOGGLE_SALES_VIEW',
  CAR_FILE_CHECKLIST_PROGRESS_NEEDS_UPDATE:
    'CAR_FILE_CHECKLIST_PROGRESS_NEEDS_UPDATE',
  SET_SELECTED_LOCATION_ID: 'SET_SELECTED_LOCATION_ID',
}

export function openImageOverlay(imageIdx, imagesSetType) {
  return {
    type: types.OPEN_IMAGE_OVERLAY,
    payload: {
      imageIdx,
      imagesSetType,
    },
  }
}

export function closeImageOverlay() {
  return {
    type: types.CLOSE_IMAGE_OVERLAY,
  }
}

export function toggleSalesView() {
  return {
    type: types.TOGGLE_SALES_VIEW,
  }
}

export function closeSearchbox() {
  return {
    type: types.CLOSE_SEARCHBOX,
  }
}

export function openSearchbox() {
  return {
    type: types.OPEN_SEARCHBOX,
  }
}

export function openOverlay(type, props) {
  return {
    type: types.OPEN_OVERLAY,
    payload: {
      type,
      props,
    },
  }
}

export function closeOverlay(type) {
  return {
    type: types.CLOSE_OVERLAY,
    payload: {
      type,
    },
  }
}

// boolean and a carFileId
export function carFileChecklistProgressNeedsUpdate(update, carFileId) {
  return {
    type: types.CAR_FILE_CHECKLIST_PROGRESS_NEEDS_UPDATE,
    payload: {
      update,
      carFileId,
    },
  }
}

export function setSelectedLocationId(id) {
  return {
    type: types.SET_SELECTED_LOCATION_ID,
    payload: {
      id,
    },
  }
}
