/* eslint-disable camelcase */

import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import { carFileChecklistProgressNeedsUpdate } from 'redux/actions/ui'

import { getCarFileStatusFromV3Value, CarFileStatusEnumLowerCase, CarFileStatusEnumUpperCase } from 'config/enums'
import { CAR_FILE_CHECKLIST_PROGRESS } from 'config/graphql/v4'

import { media } from 'utilities/styled'
import { withApolloV4Provider } from 'utilities/apollo'

import Button from 'components/atoms/button'
import Text from 'components/atoms/text'
import TextInput from 'components/atoms/text-input'
import LoadingIndicator from 'components/atoms/loading-indicator'
import NewTooltip from 'components/atoms/new-tooltip'
import Progress from 'components/atoms/progress'
import TextLink from 'components/atoms/text-link'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.brandDelta};
  align-items: flex-start;
  justify-content: space-between;
  gap: ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  `};
`

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  padding-right: 20px;

  ${media.tablet`
    flex: 0 1 auto;
  `};
`

const StyledButton = styled(Button)`
  display: block;
  text-align: center;

  ${media.tablet`
    flex: 0 0 auto;
  `};
`

const ButtonContainer = styled.div`
  display: inline-block;
  position: relative;

  ${media.tablet`
    display: block;
    flex: 0 0 auto;
  `};
`

const Cover = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  > * {
    width: 100%;
    height: 100%;
  }
`

const TooltipHitArea = styled.div`
  width: 100%;
  min-height: 100%;
`

const StyledTooltip = styled(NewTooltip)`
  display: block !important;
`

const StyledTextInput = styled(TextInput)`
  margin-left: 10px;
  width: 6em;
`

const ProgressBarContainer = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl1};

  ${media.tablet`
    min-width: ${({ theme }) => theme.sizeByFactor(30)};
  `}
`

function CarfileStatus ({
  carfile,
  carId,
  carStatus,
  mileage,
  openOverlay,
  setCarStatus,
  updateCarfile,
  deliveryDate,
  salesView,
  onProgressClick,
  onStatusChange,
}) {
  const {
    datum_verwacht,
    datum_binnen,
    datum_verkocht,
  } = carfile

  const date = {
    lopend: datum_verwacht,
    verwacht: datum_verwacht,
    binnen: datum_binnen,
    verkocht: datum_verkocht,
    afgeleverd: datum_verkocht,
  }[carStatus]

  const { t } = useTranslation()

  function handleSubmit (e) {
    e.preventDefault()

    if (carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.lopend]) {
      return setCarStatus({
        auto_id: carId,
        datum_verwacht: date,
        currentStatus: carStatus,
      }, onStatusChange)
    }

    if (carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verwacht]) {
      return setCarStatus({
        auto_id: carId,
        datum_binnen: date,
        km_binnen: mileage,
        currentStatus: carStatus,
      }, onStatusChange)
    }

    if (carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.binnen]) {
      return openOverlay('form', {
        formCategory: 'carfile',
        formId: 'client_form_sold',
        storeFormId: 'reportSold',
        icon: 'preCalculation',
        onSubmit: (clientData) => {
          setCarStatus({
            auto_id: carId,
            currentStatus: carStatus,
            ...clientData,
          }, onStatusChange)
        },
        submitText: t('reportSold'),
        title: t('client'),
        enableReinitialize: true,
      })
    }

    if (carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verkocht]) {
      return setCarStatus({
        currentStatus: carStatus,
        auto_id: carId,
        afleverdatum: deliveryDate,
      }, onStatusChange)
    }

    return false
  }

  function handleDateChange (event) {
    const newCarfileData = {}
    const newDate = event.target.value

    if (carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.lopend]) {
      newCarfileData.datum_verwacht = newDate
    }

    if (carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verwacht]) {
      newCarfileData.datum_verwacht = newDate
    }

    if (carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.binnen]) {
      newCarfileData.datum_binnen = newDate
    }

    if (carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verkocht]) {
      newCarfileData.datum_verkocht = newDate
    }

    updateCarfile(newCarfileData, carId)
  }

  function handleDeliveryDateChange (event) {
    updateCarfile({
      afleverdatum: event.target.value,
    }, carId)
  }

  const dateText = {
    lopend: 'inStockExpected',
    verwacht: 'inStockExpected',
    binnen: 'inStockPer',
    verkocht: 'soldPer',
    afgeleverd: 'soldPer',
  }[carStatus]

  const statusText = {
    lopend: 'reportExpected',
    verwacht: 'reportInStock',
    binnen: 'reportSold',
    verkocht: 'reportDelivered',
  }[carStatus]

  const variables = {
    carFileId: carfile.id,
  }

  /**
   * ** Checklists progress **
   *
   * Checklist items can be checked from the "Flexflows" carfile tab. When that happens, the
   * number of finished items in this components should be updated to reflect the progress.
   *
   * However, the carfile tab and this component do not share the same Apollo provider and thus
   * do not share the same cache. So, data has to be manually refetched when the user checks a
   * checklist item.
   *
   * This is done by setting a variable in the top level redux store, called
   * "carFileChecklistProgressNeedsUpdate". This variable gets sets when the user checks an item in
   * a checklist (see the ChecklistTab component in
   * '/components/views/car-file/tabs/checklists/checklists-tab.js' for that). This redux store
   * variable is then read here in this component, and the data is be refetched if needed. After
   * the the data has been refetched, the variable in the redux store will be reset to "false".
   **/
  const dispatch = useDispatch()
  const isCurrentlyRefetching = useRef(false)
  const shouldRefetchProgress = useSelector(({ ui }) => ui?.carFileChecklistProgressNeedsUpdate || false)
  const dealerIsAllowedToUseChecklist = useSelector(state =>
    Array.isArray(state?.auth?.userDetails?.dealers) && carfile
      ? state.auth.userDetails.dealers.some(dealer => dealer.dealernr === carfile.dealernr && dealer.rechten.flexflow)
      : false,
  )

  const userIsAllowedToUseChecklist = useSelector(state => !!(state?.auth?.userDetails?.flexflow))

  const { data, loading, error, refetch, previousData } = useQuery(
    CAR_FILE_CHECKLIST_PROGRESS,
    {
      variables,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        dispatch(carFileChecklistProgressNeedsUpdate(false))
        isCurrentlyRefetching.current = false
      },
      onError: () => {
        dispatch(carFileChecklistProgressNeedsUpdate(false))
        isCurrentlyRefetching.current = false
      },
    },
  )

  useEffect(() => {
    if (!isCurrentlyRefetching.current && shouldRefetchProgress) {
      isCurrentlyRefetching.current = true
      refetch(variables)
    }
  }, [shouldRefetchProgress, variables, refetch])
  // ** End of checklists progress data fetching **

  // ** Prepare progress data for component **
  const statusEnumValue = getCarFileStatusFromV3Value(carStatus)

  if (!statusEnumValue) {
    console.error('Unknown car status')
    return <LoadingIndicator error={t('genericError')} />
  }
  const statusProgressData = data?.carFileChecklistSummary?.statuses || []
  const currentStatusProgress = statusProgressData.find(statusProgress => statusProgress.status === statusEnumValue)
  // Either there are no checklists that need to be finished for the current status of the car, or all blocking checklist items have been finished
  const canChangeStatus = !currentStatusProgress || currentStatusProgress.totalProgress.total === 0 || currentStatusProgress.totalBlockedProgress.checked === currentStatusProgress.totalBlockedProgress.total

  let progress = 100
  if (currentStatusProgress && currentStatusProgress.totalProgress.total > 0) {
    // Mark progress as completed if this car has no checklists (if progress.total === 0)
    progress = currentStatusProgress.totalProgress.checked / currentStatusProgress.totalProgress.total * 100
  }
  progress = Math.min(Math.max(progress, 0), 100)
  const progressFinished = currentStatusProgress?.totalProgress.checked || 0
  const progressTotal = currentStatusProgress?.totalProgress.total || 0
  // ** End of preparing progress data for component **

  const showChecklistProgress = !canChangeStatus && dealerIsAllowedToUseChecklist && userIsAllowedToUseChecklist

  return (
    <form onSubmit={handleSubmit}>
      {(loading && !previousData) || error ? <LoadingIndicator error={error?.message} size="small" /> : (
        <>
          {!salesView && (
            <Wrapper>
              <DateContainer>
                <Text text={t(dateText)} />
                <StyledTextInput
                  name="date"
                  onChange={handleDateChange}
                  type="date"
                  value={date}
                  disabled={carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verkocht] || carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.afgeleverd]}
                />
              </DateContainer>
              {((carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verkocht] || carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.afgeleverd]) && deliveryDate) && (
                <DateContainer>
                  <Text text={t('deliveredOn')} />
                  <StyledTextInput
                    name="date"
                    onChange={handleDeliveryDateChange}
                    type="date"
                    value={deliveryDate}
                    disabled={carStatus === CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.afgeleverd]}
                  />
                </DateContainer>
              )}
            </Wrapper>
          )}
          {(statusText || showChecklistProgress) && (
            <Wrapper>
              {!salesView && showChecklistProgress && (
                <ProgressBarContainer>
                  {onProgressClick &&
                    <TextLink text={t('carFileChecklists.progress.summary', { finished: progressFinished, total: progressTotal })} onClick={onProgressClick} />}
                  <Progress value={progress} color={canChangeStatus ? 'statePositive' : undefined} />
                </ProgressBarContainer>
              )}
              {statusText && (
                canChangeStatus ? (
                  <StyledButton level="cta" type="submit" data-test-e2e="button-change-status">{t(statusText)}</StyledButton>
                ) : (
                  <ButtonContainer>
                    <Cover>
                      <StyledTooltip title={t('carFileChecklists.progress.checklistsNotCompleted', { status: t(`carFileStatussesV3.${carStatus}`).toLowerCase() })} arrow placement="bottom-end">
                        <TooltipHitArea />
                      </StyledTooltip>
                    </Cover>
                    <Button level="cta" type="submit" data-test-e2e="button-change-status" disabled>{t(statusText)}</Button>
                  </ButtonContainer>
                ))}
            </Wrapper>
          )}
        </>
      )}
    </form>
  )
}

CarfileStatus.propTypes = {
  carfile: PropTypes.object.isRequired,
  carId: PropTypes.string.isRequired,
  carStatus: PropTypes.oneOf(Object.values(CarFileStatusEnumLowerCase)),
  mileage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  openOverlay: PropTypes.func.isRequired,
  setCarStatus: PropTypes.func.isRequired,
  updateCarfile: PropTypes.func.isRequired,
  deliveryDate: PropTypes.string,
  salesView: PropTypes.bool,
  onProgressClick: PropTypes.func,
  onStatusChange: PropTypes.func, // Receives dispatch and result of status change call as params
}

CarfileStatus.defaultProps = {
  carStatus: null,
  deliveryDate: null,
  salesView: false,
}

export default withApolloV4Provider(CarfileStatus)
