import { gql } from '@apollo/client'

import {
  stockDaysByTypeFragment,
  stockTurnByTypeFragment,
  stockDaysFragment,
  stockDaysFragmentMyAveragesAndComparisonGroupStockDays1,
  stockDaysFragmentMyAveragesAndComparisonGroupStockDays2,
  stockDaysFragmentMyAveragesAndComparisonGroupStockDays3,
} from '../fragments/management-info'

export const QUERY_MANAGEMENT_INFO_CHART_AVERAGE_STAND_TIME = gql`
  query stockDaysByType(
    $dealerNrs: [ID!]!
    $type: [ManagementInfoCarFileSoortEnum]
    $fromDate: Date!
    $tillDate: Date!
    $interval: ManagementInfoCalculationIntervalEnum!
  ) {
    first: stockDaysByType(
      comparisonGroup: MY_AVERAGES
      dealerNrs: $dealerNrs
      type: $type
      fromDate: $fromDate
      tillDate: $tillDate
      interval: $interval
    ) {
      ...stockDaysByTypeFragment
    }
    second: stockDaysByType(
      comparisonGroup: COMPARISON_GROUP
      dealerNrs: $dealerNrs
      type: $type
      fromDate: $fromDate
      tillDate: $tillDate
      interval: $interval
    ) {
      ...stockDaysByTypeFragment
    }
    third: stockDaysByType(
      comparisonGroup: BEST_IN_CLASS
      dealerNrs: $dealerNrs
      type: $type
      fromDate: $fromDate
      tillDate: $tillDate
      interval: $interval
    ) {
      ...stockDaysByTypeFragment
    }
  }
  ${stockDaysByTypeFragment}
`

export const QUERY_MANAGEMENT_INFO_CHART_TURNOVER_RATE = gql`
  query stockTurnByType(
    $dealerNrs: [ID!]!
    $type: [ManagementInfoCarFileSoortEnum]
    $fromDate: Date!
    $tillDate: Date!
  ) {
    first: stockTurnByType(
      comparisonGroup: MY_AVERAGES
      dealerNrs: $dealerNrs
      type: $type
      fromDate: $fromDate
      tillDate: $tillDate
    ) {
      ...stockTurnByTypeFragment
    }
    second: stockTurnByType(
      comparisonGroup: COMPARISON_GROUP
      dealerNrs: $dealerNrs
      type: $type
      fromDate: $fromDate
      tillDate: $tillDate
    ) {
      ...stockTurnByTypeFragment
    }
    third: stockTurnByType(
      comparisonGroup: BEST_IN_CLASS
      dealerNrs: $dealerNrs
      type: $type
      fromDate: $fromDate
      tillDate: $tillDate
    ) {
      ...stockTurnByTypeFragment
    }
  }
  ${stockTurnByTypeFragment}
`

export const QUERY_MANAGEMENT_INFO_CHART_123 = gql`
  query stockDays123ByType(
    $dealerNrs: [ID!]!
    $fromDate: Date!
    $tillDate: Date!
  ) {
    allStandtimes: stockDays123ByType(
      dealerNrs: $dealerNrs
      fromDate: $fromDate
      tillDate: $tillDate
      interval: WEEKLY
    ) {
      ...stockDaysFragment
    }
  }
  ${stockDaysFragment}
`

export const QUERY_MANAGEMENT_INFO_CHART_123_WITH_STOCK_DAY_SELECTED = gql`
  query stockDays123ByTypeFiltered(
    $dealerNrs: [ID!]!
    $fromDate: Date!
    $tillDate: Date!
    $stockDaysType: ManagementInfoStockDays123Enum
    $includeStockDays1: Boolean!
    $includeStockDays2: Boolean!
    $includeStockDays3: Boolean!
  ) {
    first: stockDays123ByType(
      comparisonGroup: MY_AVERAGES
      dealerNrs: $dealerNrs
      fromDate: $fromDate
      tillDate: $tillDate
      interval: WEEKLY
    ) {
      ...stockDaysFragmentMyAveragesAndComparisonGroupStockDays1
        @include(if: $includeStockDays1)
      ...stockDaysFragmentMyAveragesAndComparisonGroupStockDays2
        @include(if: $includeStockDays2)
      ...stockDaysFragmentMyAveragesAndComparisonGroupStockDays3
        @include(if: $includeStockDays3)
    }
    second: stockDays123ByType(
      comparisonGroup: COMPARISON_GROUP
      dealerNrs: $dealerNrs
      fromDate: $fromDate
      tillDate: $tillDate
      interval: WEEKLY
    ) {
      ...stockDaysFragmentMyAveragesAndComparisonGroupStockDays1
        @include(if: $includeStockDays1)
      ...stockDaysFragmentMyAveragesAndComparisonGroupStockDays2
        @include(if: $includeStockDays2)
      ...stockDaysFragmentMyAveragesAndComparisonGroupStockDays3
        @include(if: $includeStockDays3)
    }
    third: stockDays123ByType(
      comparisonGroup: BEST_IN_CLASS
      dealerNrs: $dealerNrs
      fromDate: $fromDate
      tillDate: $tillDate
      interval: WEEKLY
      stockDaysType: $stockDaysType
    ) {
      ...stockDaysFragment
    }
  }
  ${stockDaysFragmentMyAveragesAndComparisonGroupStockDays1}
  ${stockDaysFragmentMyAveragesAndComparisonGroupStockDays2}
  ${stockDaysFragmentMyAveragesAndComparisonGroupStockDays3}
  ${stockDaysFragment}
`

export const QUERY_MANAGEMENT_INFO_CHART_TRADE_IN_VALUATION_RATIO = gql`
  query valuationByType(
    $dealerNrs: [ID!]!
    $fromDate: Date!
    $tillDate: Date!
  ) {
    valuationByType(
      dealerNrs: $dealerNrs
      fromDate: $fromDate
      tillDate: $tillDate
      interval: WEEKLY
    ) {
      averageValuationAmount
      averageTradeInAmount
      averageRatio
      valuations {
        valuationAmount
        tradeInAmount
        ratio
        createdAt
      }
    }
  }
`
