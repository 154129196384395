import { convertToCamelCase } from 'utilities/utils'
import { passwordRequirementsRegex } from 'config/data'

function getFormTableDefaultValues(field) {
  const { rows } = field.data

  return rows.reduce((values, row) => {
    let newValues

    row.forEach((rowItem) => {
      newValues = {
        ...newValues,
        [rowItem.name]: rowItem.value,
      }
    })

    return {
      ...values,
      ...newValues,
    }
  }, {})
}

function getCheckboxValue(value) {
  if (value === '0') {
    return false
  }
  if (value === '1' || value) {
    return true
  }
  return false
}

function getDefaultValue(field) {
  switch (field.type) {
    case 'checkbox':
      return false
    case 'package_select': {
      // Package select fields use an array of strings as their value. It represents
      // the sub-checkboxes of the field that are selected. So, an empty array means
      // no sub-checkboxes were selected.
      const { items } = field
      return items.filter((item) => !!item.value).map((item) => item.name)
    }
    case 'select':
      return field.value || ''
    case 'formTable':
      return getFormTableDefaultValues(field)
    default:
      return field.value || ''
  }
}

export function getValue(data, field) {
  const value = data && data[field.name]

  switch (field.type) {
    case 'options_select':
      return field.defaultValue.map((val) => val.value)
    case 'checkbox':
      return typeof field.value !== 'undefined'
        ? getCheckboxValue(field.value)
        : getCheckboxValue(value)
    default:
      if (value === null || typeof value === 'undefined') {
        return getDefaultValue(field)
      }
      return value
  }
}

function getLabel(field, value) {
  const { options } = field

  if (!options) {
    return null
  }

  const option = options.filter((opt) => opt.value === value)[0]

  return option && option.label
}

const colonPattern = /:/

function translateField(field, translate) {
  const translatedField = {
    ...field,
    explanation: translate(field.explanation),
    legend: translate(field.legend),
    title: translate(field.title),
    placeholder: translate(field.placeholder),
  }

  if (field.options) {
    translatedField.options = field.options.map((option) => ({
      ...option,
      // do not translate things that contain a ':',
      // i18n interprets this as way to specify namespaces:
      label: colonPattern.test(option.label)
        ? option.label
        : translate(option.label),
    }))
  }

  return translatedField
}

function translateFieldSet(fieldSet, translate) {
  const translatedFieldSet = {
    ...fieldSet,
    title: translate(fieldSet.title),
  }
  if (fieldSet.fields) {
    translatedFieldSet.fields = fieldSet.fields.map((field) =>
      translateField(field, translate),
    )
  }
  if (fieldSet.fieldsets) {
    translatedFieldSet.fieldsets = fieldSet.fieldsets.map((nestedFieldSet) =>
      translateFieldSet(nestedFieldSet, translate),
    )
  }

  return translatedFieldSet
}

export function translateForm(fieldsets, translate) {
  return fieldsets.map((fieldset) => translateFieldSet(fieldset, translate))
}

export function getFormData(values, fields) {
  const formData = {
    labels: {},
    values: {},
  }

  values.forEach((value, index) => {
    const label = getLabel(fields[index], value)

    formData.labels[fields[index].name] = label
    formData.values[fields[index].name] = value
  })

  return formData
}

export function getFlatFields(fieldsets) {
  return fieldsets.flatMap((fieldset) =>
    fieldset.fieldsets
      ? fieldset.fieldsets.flatMap((nestedFieldSet) => nestedFieldSet.fields)
      : fieldset.fields,
  )
}

export function getFlatFieldSet(fieldset) {
  return fieldset.flatMap((i) => i)[0]
}

export function getFlatValues(fieldsets, data) {
  const fields = getFlatFields(fieldsets)
  return fields.map((field) => getValue(data, field))
}

export function getTranslatedForms(t, forms) {
  const translatedForms = Object.entries(forms).reduce(
    (newForms, [key, value]) => {
      if (!value) {
        return newForms
      }

      const fieldsets = value.fieldsets || value
      return {
        ...newForms,
        [convertToCamelCase(key)]: translateForm(fieldsets, t),
      }
    },
    {},
  )

  return translatedForms
}

const PASSWORD_REQUIREMENTS_REGEX = passwordRequirementsRegex
function setPasswordErrors(fields, values, errs, t) {
  // Check if all fields of the type password in the form are equal
  const passwordFields = fields.reduce((result, field) => {
    if (field.type === 'password') {
      return [
        ...result,
        {
          name: field.name,
          value: values[field.name],
        },
      ]
    }
    return result
  }, [])

  const passwordFieldsAreEqual = passwordFields.every(
    (field, index, source) => field.value === source[0].value,
  )

  const passwordsMeetRequirements = passwordFields.every(({ value }) => {
    // Don't check if the password meets requirements if it is empty
    if (!value || value.length === 0) {
      return true
    }
    const requirementsMatch = value.match(PASSWORD_REQUIREMENTS_REGEX)
    const requirementsMet = requirementsMatch && requirementsMatch[0] === value

    return requirementsMet
  })

  if (passwordFieldsAreEqual && passwordsMeetRequirements) {
    return errs
  }

  let newErrors = errs

  if (!passwordsMeetRequirements) {
    newErrors = passwordFields.reduce((result, field) => ({
      ...result,
      [field.name]: t('passwordsDontMeetRequirements'),
    }))

    return newErrors
  }

  if (!passwordFieldsAreEqual) {
    newErrors = passwordFields.reduce((result, field) => ({
      ...result,
      [field.name]: t('passwordsDontMatch'),
    }))
  }

  return newErrors
}

export function isFieldRequired(currentField, values) {
  if (Array.isArray(currentField.required)) {
    const controllingFields = currentField.required
    const isCurrentlyRequired = controllingFields.some((config) => {
      if (
        config.field &&
        config.operator &&
        config.operator === 'equals' &&
        config.values
      ) {
        if (values[config.field]) {
          return config.values.some((value) => value === values[config.field])
        }
        return false
      }
      console.error(`the required configuration for ${currentField.name}
          is missing props. (supports field, operator, values)`)
      return false
    })
    return isCurrentlyRequired
  }
  return currentField.required
}

export function validateFields(values, fields) {
  let errors = fields.reduce((result, field) => {
    if (isFieldRequired(field, values) && !values[field.name]) {
      return {
        ...result,
        [field.name]: true,
      }
    }
    return result
  }, {})

  // Check if all fields of the type password in the form are equal
  // TODO replace translation function
  errors = setPasswordErrors(fields, values, errors, (input) => input)
  return errors
}
