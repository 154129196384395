/**
 * Taken from: https://github.com/BenjaminVanRyseghem/numbro/blob/develop/languages/nl-NL.js
 */
export default {
  languageTag: 'nl',
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'mln',
    billion: 'mrd',
    trillion: 'bln',
  },
  ordinal: (number) => {
    const remainder = number % 100
    return (number !== 0 && remainder <= 1) ||
      remainder === 8 ||
      remainder >= 20
      ? 'ste'
      : 'de'
  },
  currency: {
    symbol: '€ ',
    position: 'prefix',
    code: 'EUR',
  },
  currencyFormat: {
    thousandSeparated: true,
    totalLength: 4,
    spaceSeparated: true,
    average: false,
  },
}
