import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import useDynamicEndpointDone from 'hooks/use-dynamic-endpoint-done'

import LoadingIndicator from 'components/atoms/loading-indicator'
import ComplexForm from 'components/redux-containers/complex-form-container'

import toast from 'utilities/toast'

function SettingsForm({
  className,
  dynamicEndpoint,
  forms,
  formId,
  getForms,
  params,
  postDynamicEndpoint,
  onUpdateSuccess,
  subId,
}) {
  const { t } = useTranslation()

  // storing it to keep track data being loaded:
  const form = subId ? forms[`${formId}_${subId}`] : forms[formId]
  const data = form && form.data
  const loading = form && form.loading
  const endpoint = form && form.data && form.data.endpoint

  useEffect(() => {
    getForms(formId, params, subId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useDynamicEndpointDone(
    dynamicEndpoint,
    () => {
      if (onUpdateSuccess) {
        onUpdateSuccess()
      }
      toast.success(t('formFeedback.successfullySaved'))
    },
    [onUpdateSuccess, dynamicEndpoint],
  )

  if (!data || loading) {
    return <LoadingIndicator />
  }

  function handleSubmit(formState) {
    postDynamicEndpoint({
      endpoint,
      ...formState,
      // Add "vestiging_id" conditionally. This was added for the topdown config and was broken in the Marktplaats settings:
      // - https://app.clickup.com/t/2501750/UCC-6656 (topdown config)
      // - https://app.clickup.com/t/2501750/UCC-7176 (marktplaats bug)
      ...(params?.vestiging_id && { vestiging_id: params?.vestiging_id }),
    })
  }

  return (
    <div className={className}>
      <ComplexForm
        fieldsets={data.fieldsets}
        formId={`${formId}Settings`}
        level="cta"
        onSubmit={handleSubmit}
        submitText={t('saveChanges')}
        enableReinitialize
      />
    </div>
  )
}

export const propTypes = {
  className: PropTypes.object,
  dynamicEndpoint: PropTypes.object,
  formId: PropTypes.string.isRequired,
  forms: PropTypes.object,
  getForms: PropTypes.func.isRequired,
  onUpdateSuccess: PropTypes.func,
  params: PropTypes.object,
  postDynamicEndpoint: PropTypes.func.isRequired,
  subId: PropTypes.string,
}

SettingsForm.propTypes = propTypes

export const defaultProps = {
  className: null,
  dynamicEndpoint: null,
  forms: null,
  onUpdateSuccess: null,
  params: null,
  subId: null,
}

SettingsForm.defaultProps = defaultProps

export default SettingsForm
