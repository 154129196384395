import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { getYearOptions } from 'utilities/utils'
import DropdownMenu from 'components/atoms/dropdown-menu'
import FilterCollapser from 'components/molecules/filter-collapser'
import TextLink from 'components/atoms/text-link'

function getMonthItems() {
  return moment.months().map((label, value) => ({
    label,
    value: moment(value + 1, 'M').format('MM'),
  }))
}

function getYear(date) {
  return moment(date, 'YYYYMM').format('YYYY')
}

function getMonth(date) {
  return moment(date, 'YYYYMM').format('MM')
}

const StyledDropdownMenu = styled(DropdownMenu)`
  margin: ${({ theme }) => theme.sizings.lvl2} 0;
`

const ClearLink = styled(TextLink)`
  margin: ${({ theme }) => theme.sizings.lvl2} 0;
`

function DateFilter({ initallyOpen, name, onFilterUpdate, title, value }) {
  const yearProp = value && getYear(value)
  const monthProp = value && getMonth(value)
  const [year, setYear] = useState(yearProp)
  const [month, setMonth] = useState(monthProp)
  const { t } = useTranslation()
  const currentYear = moment().year()
  const yearItems = getYearOptions(currentYear - 100, currentYear + 2)
  const monthItems = getMonthItems()

  const handleChange = useCallback(
    (yr, mnth) => {
      onFilterUpdate(name, `${yr}${mnth}`)
    },
    [name, onFilterUpdate],
  )

  function handleClear() {
    onFilterUpdate(name, '')
  }

  useEffect(() => {
    if (year && month && (year !== yearProp || month !== monthProp)) {
      handleChange(year, month)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month])

  return (
    <FilterCollapser initallyOpen={initallyOpen} title={title}>
      <StyledDropdownMenu
        items={yearItems}
        onChange={setYear}
        placeholder={t('selectYear')}
        selected={year}
      />
      <StyledDropdownMenu
        items={monthItems}
        onChange={setMonth}
        placeholder={t('selectMonth')}
        selected={month}
      />
      <ClearLink onClick={handleClear} text={t('reset')} />
    </FilterCollapser>
  )
}

DateFilter.propTypes = {
  initallyOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  value: PropTypes.string,
  title: PropTypes.string.isRequired,
}

DateFilter.defaultProps = {
  initallyOpen: null,
  value: null,
}

export default DateFilter
