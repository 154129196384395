import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'

import {
  CarFileStatusEnumUpperCase,
  CarFileStatusEnumLowerCase,
} from 'config/enums'

import Tabs from 'components/layouts/tabs'
import ChecklistForm, {
  propTypes as checklistFormPropTypes,
} from 'components/views/car-file/tabs/checklists/checklist-form'

const StyledTabs = styled(Tabs)`
  & .TabList > div {
    padding-left: 0;
  }

  & .TabList > div > div > div {
    margin-left: 0;
    margin-right: 0;
    padding-right: 0;
  }

  .TabChildContainer {
    padding-left: 0;
    padding-right: 0;
  }
`

const ChecklistsOverview = ({
  carFileId,
  checklists,
  status,
  match,
  onChecklistSubmit,
  checklistIsSubmitting,
  ...restProps
}) => {
  const tabItems = checklists.map((checklist) => ({
    label: `${checklist.name} (${checklist.totalProgress.checked}/${checklist.totalProgress.total})`,
    to: `${match.url.replace(/\/$/, '')}/${CarFileStatusEnumLowerCase[status]}/${checklist.id}`,
  }))

  return (
    <StyledTabs items={tabItems} small {...restProps}>
      {checklists.map((checklist) => (
        <ChecklistForm
          key={checklist.id}
          carFileId={carFileId}
          onSubmit={(checkedItemIds) => {
            typeof onChecklistSubmit === 'function' &&
              onChecklistSubmit(checklist, checkedItemIds)
          }}
          isSubmitting={checklistIsSubmitting}
          {...checklist}
        />
      ))}
    </StyledTabs>
  )
}

// Only take the props that we need for the checklists
const { carFileId, onSubmit, isSubmitting, ...checklistsProps } =
  checklistFormPropTypes

ChecklistsOverview.propTypes = {
  carFileId: PropTypes.string.isRequired,
  checklists: PropTypes.arrayOf(PropTypes.shape(checklistsProps)).isRequired,
  status: PropTypes.oneOf(Object.values(CarFileStatusEnumUpperCase)).isRequired,
  match: PropTypes.object.isRequired,
  onChecklistSubmit: PropTypes.func,
  checklistIsSubmitting: PropTypes.bool,
}

export default withRouter(ChecklistsOverview)
