import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types';
// import styled from 'styled-components/macro';
import moment from 'moment'
import { ApolloProvider, useQuery } from '@apollo/client'
import { CSPapolloClient } from 'config/apollo'
import { GET_CSP_TRANSACTION_LOGS } from 'config/graphql/csp'
import { useLocation, useHistory } from 'react-router-dom'
import SimpleSearchResults from 'components/organisms/simple-search-results'
import { useTranslation } from 'react-i18next'
import {
  getObjectFromQueryString,
  getQueryStringFromObject,
} from 'utilities/utils'
import LoadingIndicator from 'components/atoms/loading-indicator'
import GeneralDataError from 'components/organisms/general-data-error'
import { formatLicenseplate, formatDate } from 'utilities/format'
import { transactionLogTypes, getTransactionLogTypeLabels } from 'config/data'
import NewDropdownSelect from 'components/molecules/new-dropdown-select'
import useDeepCompareEffect from 'use-deep-compare-effect'

const CSPTransactions = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const defaultFilterValues = {
    page: 1,
    type: '',
    month: '',
    year: '',
  }
  const [filters, setFilters] = useState({})

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1
  // The amount of years in the past that will be shown for the year filter
  const yearsInThePast = 20

  // Setup the intial filter values from the url's query string
  useEffect(() => {
    // Parse the query string and convert it to an object
    const queryParams = getObjectFromQueryString(location.search)

    // Get all the parameters to set the initial filter values
    const page = parseInt(queryParams.page, 10)
    const { type } = queryParams
    const year = parseInt(queryParams.year, 10)
    const month = parseInt(queryParams.month, 10)

    // Validate all the parameters and clean values if needed

    // Splitting validatedYear out, because we need it later
    const validatedFilters = {
      page: !Number.isNaN(page) && page > 0 ? page : defaultFilterValues.page,
      type: Object.values(transactionLogTypes).includes(type)
        ? type
        : defaultFilterValues.type,
      year:
        !Number.isNaN(year) && year > 0 && year <= currentYear
          ? year
          : defaultFilterValues.year,
      month:
        !Number.isNaN(month) && month > 0 && month <= 12
          ? month
          : defaultFilterValues.month,
    }

    // Make sure the year is not too far in the past
    if (
      validatedFilters.year &&
      validatedFilters.year <= currentYear - yearsInThePast
    ) {
      validatedFilters.year = ''
    }

    // Make sure a month cannot be set if no year has been selected
    // And make sure month cannot be in the future of the current year
    if (
      !validatedFilters.year ||
      (validatedFilters.year === currentYear &&
        validatedFilters.month &&
        validatedFilters.month > currentMonth)
    ) {
      validatedFilters.month = ''
    }

    // Set the filters
    setFilters({
      ...filters,
      ...validatedFilters,
    })

    // This hook should only run once, when the component mounts.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * This effect updates the url's query string if the filter values change.
   * Using deep compare here to make sure this effect only triggers when one
   * of the dependancy objects itself changes. A normal effect (shallow compare)
   * would cause a trigger even if none of the object's values did not change.
   */
  useDeepCompareEffect(() => {
    // Check if filters is empty
    if (!Object.keys(filters).length) {
      return
    }

    const queryObject = {}
    // Make sure only non-default values are added to the query string
    Object.keys(defaultFilterValues).forEach((key) => {
      const defaultValue = defaultFilterValues[key]
      const currentValue = filters[key]

      if (currentValue !== defaultValue) {
        queryObject[key] = currentValue
      }
    })
    const queryString = getQueryStringFromObject(queryObject)

    history.push({
      pathname: location.pathname,
      search: queryString,
    })
  }, [defaultFilterValues, filters])

  // Construct the GraphQL query.
  // It's variables will be set using the filters state object.
  const itemsPerPage = 30
  const variables = {
    first: itemsPerPage,
    // The "after" value needs to be base64 encoded for the back-end
    after: btoa((filters.page - 1) * itemsPerPage),
  }

  if (filters.type) {
    variables.transactionType = filters.type
  }

  if (filters.year) {
    const filterDate = moment()
    filterDate.year(filters.year)

    variables.createdAt = {
      from: filterDate.startOf('year').format('YYYY-MM-DD'),
      to: filterDate.endOf('year').format('YYYY-MM-DD'),
    }

    if (filters.month) {
      filterDate.month(filters.month - 1)
      variables.createdAt = {
        from: filterDate.startOf('month').format('YYYY-MM-DD'),
        to: filterDate.endOf('month').format('YYYY-MM-DD'),
      }
    }
  }
  const { loading, error, data } = useQuery(GET_CSP_TRANSACTION_LOGS, {
    variables,
  })

  if (loading) {
    return <LoadingIndicator />
  }
  if (error) {
    console.error(error)
    return <GeneralDataError />
  }

  const formattedData = {
    itemsPerPage,
    totalResults: data.transactionLogs.pageInfo.total,
    rows: data.transactionLogs.edges.map(({ node }) => ({
      licensePlate: formatLicenseplate(node.licensePlate),
      type: node.type,
      employee:
        node.employee && node.employee.firstname && node.employee.lastname
          ? `${node.employee.firstname} ${node.employee.lastname}`
          : node.employee.username || '',
      description: node.message,
      date: formatDate(
        node.createdAt,
        'YYYY-MM-DD HH:mm:ss',
        'DD-MM-YYYY HH:mm:ss',
      ),
    })),
  }

  const handleFilterChange = (name, newValue) => {
    let cleanedValue = newValue

    // Make sure months and years are a integers
    if (newValue && ['year', 'month'].includes(name)) {
      cleanedValue = parseInt(newValue, 10)
    }

    const newFilters = {
      [name]: cleanedValue || '',
    }

    // Reset the month if the year is reset
    if (name === 'year' && !newValue) {
      newFilters.month = ''
    }

    // Reset the year is set to the current year, and the month is in the future
    if (
      name === 'year' &&
      newValue === currentYear &&
      filters.month > currentMonth
    ) {
      newFilters.month = ''
    }

    // Reset the page if neccessary
    if (name !== 'page') {
      newFilters.page = 1
    }

    setFilters({
      ...filters,
      ...newFilters,
    })
  }

  const transactionLogTypeLabels = getTransactionLogTypeLabels(t)
  const typeItems = Object.values(transactionLogTypes).map((type) => ({
    label: transactionLogTypeLabels[type],
    value: type,
  }))

  const yearItems = []
  for (let year = currentYear; year > currentYear - yearsInThePast; year -= 1) {
    yearItems.push({
      label: String(year),
      value: year,
    })
  }

  const monthItems = Array.from(Array(12), (_, i) => i + 1) // Generates array from 1 through 12
    .filter((month) => {
      // Filter out the months (in the current year) that are in the future
      if (filters.year && filters.year === currentYear) {
        return currentMonth >= month
      }
      return true
    })
    .map((month) => ({
      label: t(`months.${month}`),
      value: month,
    }))

  const filterComponents = [
    <NewDropdownSelect
      key="type-dropdown-select"
      label={t('type')}
      items={typeItems}
      value={filters.type}
      onChange={(value) => {
        handleFilterChange('type', value)
      }}
      filled
    />,
    <NewDropdownSelect
      key="year-dropdown-select"
      label={t('year')}
      items={yearItems}
      value={filters.year}
      onChange={(value) => {
        handleFilterChange('year', value)
      }}
      filled
    />,
  ]

  // Only show the month filter if the year is set
  if (filters.year) {
    filterComponents.push(
      <NewDropdownSelect
        label={t('month')}
        items={monthItems}
        value={filters.month}
        onChange={(value) => {
          handleFilterChange('month', value)
        }}
        filled
      />,
    )
  }

  return (
    <div>
      <SimpleSearchResults
        filtering={filterComponents}
        data={formattedData.rows}
        noDataMessage={t('noTransactionsFound')}
        dataMapping={{
          licensePlate: {
            label: t('licensePlate'),
            getter: (row) => row.licensePlate,
          },
          employee: {
            label: t('employee'),
            getter: (row) => row.employee,
          },
          type: {
            label: t('type'),
            getter: (row) => transactionLogTypeLabels[row.type],
          },
          description: {
            label: t('description'),
            getter: (row) => row.description,
          },
          date: {
            label: t('date'),
            getter: (row) => row.date,
          },
        }}
        ordering={['licensePlate', 'employee', 'type', 'description', 'date']}
        onChangePage={(newPage) => handleFilterChange('page', newPage)}
        page={parseInt(filters.page, 10)}
        itemsPerPage={formattedData.itemsPerPage}
        totalItems={formattedData.totalResults}
      />
    </div>
  )
}

CSPTransactions.propTypes = {}

CSPTransactions.defaultProps = {}

const WrappedCSPTransactions = (props) => {
  const cspApolloClient = React.useMemo(() => CSPapolloClient(), [])

  return (
    <ApolloProvider client={cspApolloClient}>
      <CSPTransactions {...props} />
    </ApolloProvider>
  )
}

export default WrappedCSPTransactions
