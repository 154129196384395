import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Svg = styled.svg`
  /* Restrict height, otherwise it expands to huge sizes */
  max-height: 575px;
  path {
    fill: none; /* makes it by default non clickable */
  }

  [aria-label$='wiel'] {
    /* Needs to be filled, but it non-clickable */
    fill: ${({ theme }) => theme.colors.alternativeBackground};
  }

  [role='radio'] {
    /* all these should be clickable: */
    cursor: pointer;
    fill: ${({ theme }) =>
      theme.colors.defaultBackground}; /* makes it clickable */
    &:hover {
      fill: #fbb48b; /* lightened primary colour */
    }
    &[aria-checked='true'] {
      fill: ${({ theme }) => theme.colors.actionsCtaTransparent};
    }
  }

  /* Styles derived directly from source SVG: */
  .st0 {
    stroke: ${({ theme }) => theme.colors.text};
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .st1 {
    stroke: ${({ theme }) => theme.colors.text};
    stroke-width: 2;
    stroke-linejoin: round;
  }
  .st2 {
    stroke: ${({ theme }) => theme.colors.text};
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .st3 {
    stroke: ${({ theme }) => theme.colors.text};
    stroke-width: 1.8528;
    stroke-linejoin: round;
  }
  .st4 {
    stroke: ${({ theme }) => theme.colors.defaultBackground};
    stroke-width: 0.5;
    stroke-miterlimit: 10;
  }
  .st5 {
    stroke: ${({ theme }) => theme.colors.defaultBackground};
    stroke-width: 3;
    stroke-linejoin: round;
  }
  .st6 {
    stroke: ${({ theme }) => theme.colors.text};
    stroke-width: 3;
    stroke-linejoin: round;
  }
  .st7 {
    fill: ${({ theme }) => theme.colors.brandSupportTonedDown};
    stroke: ${({ theme }) => theme.colors.text};
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .st8 {
    fill: ${({ theme }) => theme.colors.defaultBackground};
    stroke: ${({ theme }) => theme.colors.text};
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .st9 {
    stroke: ${({ theme }) => theme.colors.text};
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st10 {
    fill: ${({ theme }) => theme.colors.brandSupportTonedDown};
    stroke: ${({ theme }) => theme.colors.text};
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
`

const initialData = {
  Achterbumper: {
    value: '1',
    label: 'Achterbumper',
  },
  Achterklep: {
    value: '2',
    label: 'Achterklep/deksel',
  },
  Achterruit: {
    value: '3',
    label: 'Achterruit',
  },
  Dak: {
    value: '4',
    label: 'Dak',
  },
  Grille: {
    value: '5',
    label: 'Grille',
  },
  LA_portier: {
    value: '6',
    label: 'LA portier',
  },
  LA_zijruit: {
    value: '7',
    label: 'LA zijruit',
  },
  LA_scherm: {
    value: '8',
    label: 'LA scherm',
  },
  LV_portier: {
    value: '9',
    label: 'LV portier',
  },
  LV_zijruit: {
    value: '10',
    label: 'LV zijruit',
  },
  LV_scherm: {
    value: '11',
    label: 'LV scherm',
  },
  Motorkap: {
    value: '12',
    label: 'Motorkap',
  },
  RA_portier: {
    value: '13',
    label: 'RA portier',
  },
  RA_zijruit: {
    value: '14',
    label: 'RA zijruit',
  },
  RA_scherm: {
    value: '15',
    label: 'RA scherm',
  },
  RV_portier: {
    value: '16',
    label: 'RV portier',
  },
  RV_zijruit: {
    value: '17',
    label: 'RV zijruit',
  },
  RV_scherm: {
    value: '18',
    label: 'RV scherm',
  },
  Voorbumper: {
    value: '19',
    label: 'Voorbumper',
  },
  Voorruit: {
    value: '20',
    label: 'Voorruit',
  },
  other: {
    value: '21',
    label: 'Anders...',
  },

  L_achterscherm: {
    value: '22',
    label: 'L achterscherm',
  },
  R_achterscherm: {
    value: '23',
    label: 'R achterscherm',
  },
  L_dorpel: {
    value: '24',
    label: 'L dorpel',
  },
  R_dorpel: {
    value: '25',
    label: 'R dorpel',
  },
  L_spiegel: {
    value: '26',
    label: 'L spiegel',
  },
  R_spiegel: {
    value: '27',
    label: 'R spiegel',
  },
  LA_velg: {
    value: '28',
    label: 'LA velg',
  },
  LV_velg: {
    value: '29',
    label: 'LV velg',
  },
  RA_velg: {
    value: '30',
    label: 'RA velg',
  },
  RV_velg: {
    value: '31',
    label: 'RV velg',
  },
}

/**
 * A visual way to point out where a car is damaged.
 * This labeled field component does not have the usual data prop,
 * because it's data is hardcoded in the form of a external car panels.
 */
function OpticalCarDamagePicker({ onChange, selected }) {
  const initData = initialData

  function handleClick(id) {
    if (initData[id]) {
      onChange(initData[id].value)
    }
  }

  function setChecked(id) {
    return initData[id] && initData[id].value === selected
  }

  return (
    <fieldset>
      <Svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 779.161 986.195"
        style={{ enableBackground: 'new 0 0 779.161 986.195' }}
        xmlSpace="preserve"
        role="radiogroup"
      >
        <path
          aria-label="Motorkap"
          onClick={() => handleClick('Motorkap')}
          role="radio"
          aria-checked={setChecked('Motorkap')}
          className="st0"
          d="M390.073,279.068c44.135-0.696,90.367,2.56,126.243,30.344
          c-0.342-34.259-2.956-72.475-9.443-113.667c-0.909-5.772-1.871-11.453-2.879-17.041c-3.222-17.86-17.742-31.09-35.114-32.123
          c0,0-58.809-4.669-78.454-4.665c-19.538,0.004-58.518,3.502-78.024,4.665c-17.371,1.036-31.892,14.263-35.114,32.123
          c-1.008,5.588-1.97,11.269-2.879,17.041c-6.487,41.192-9.101,79.408-9.443,113.667C301.929,280.698,343.51,279.068,390.073,279.068"
        />
        <path
          aria-label="Voorbumper"
          onClick={() => handleClick('Voorbumper')}
          role="radio"
          aria-checked={setChecked('Voorbumper')}
          className="st1"
          d="M397.622,112.033c22.327,0,44.654,0,66.982,0l-8.237-14.453
          c-2.506-4.397,0.527-9.971,5.427-9.971h65.765c-0.172-9.036-1.183-20.25-4.197-32.752c-1.418-5.882-3.087-11.248-4.837-16.068
          c-1.243-3.424-4.408-5.665-7.914-5.665H390.427h0.43H270.673c-3.506,0-6.671,2.242-7.914,5.665
          c-1.75,4.819-3.419,10.185-4.837,16.068c-3.014,12.502-4.024,23.716-4.197,32.752h65.765c4.899,0,7.933,5.574,5.427,9.971
          l-8.237,14.453C339.007,112.033,375.294,112.033,397.622,112.033"
        />
        <path
          aria-label="Bumper_bar"
          className="st2"
          d="M488.157,50.887v-0.202c0-2.538-2.058-4.596-4.596-4.596H296.768
          c-2.538,0-4.596,2.058-4.596,4.596v0.202c0,2.538,2.058,4.596,4.596,4.596h186.792C486.099,55.483,488.157,53.425,488.157,50.887z"
        />
        <g aria-label="LV_wiel">
          <ellipse
            className="st1"
            cx="75.633"
            cy="255.147"
            rx="44.155"
            ry="45.237"
          />
          <ellipse
            aria-label="LV_velg"
            onClick={() => handleClick('LV_velg')}
            role="radio"
            aria-checked={setChecked('LV_velg')}
            className="st3"
            cx="75.633"
            cy="255.147"
            rx="33.525"
            ry="34.347"
          />
        </g>
        <g aria-label="LA_wiel">
          <ellipse
            className="st1"
            cx="75.503"
            cy="654.974"
            rx="44.155"
            ry="45.237"
          />
          <ellipse
            aria-label="LA_velg"
            onClick={() => handleClick('LA_velg')}
            role="radio"
            aria-checked={setChecked('LA_velg')}
            className="st3"
            cx="75.503"
            cy="654.974"
            rx="33.525"
            ry="34.347"
          />
        </g>
        <path
          aria-label="LV_scherm"
          onClick={() => handleClick('LV_scherm')}
          role="radio"
          aria-checked={setChecked('LV_scherm')}
          className="st4"
          d="M120.45,165.943l24.444,10.1l13.959,74.373c2.462,15.823,4.544,31.26,6.293,46.278
          c0.271,1.609,0.737,3.575,1.562,5.733c1.017,2.66,2.15,4.477,2.659,5.309c1.494,2.446,3.668,6.334,6.538,12.554l-121.029,7.096
          l0.021-18.5c29.376,5.87,57.789-7.024,69.758-30.232c0.548-1.063,12.683-25.583,0.376-50.313c-1.735-3.487-3.842-6.749-6.269-9.755
          C118.761,218.586,120.467,165.411,120.45,165.943"
        />
        <path
          fill="yellow"
          aria-label="LA_scherm"
          onClick={() => handleClick('LA_scherm')}
          role="radio"
          aria-checked={setChecked('LA_scherm')}
          className="st5"
          d="M65.841,735.995c-0.446-7.677-0.891-20.331-1.337-28.008c4.761,0.99,17.725,3.025,32.253-3.131
          c4.566-1.935,17.272-8.063,25.598-22.545c1.762-3.065,6.986-12.865,7.045-26.538c0.024-5.499-0.754-19.206-10.333-32.174
          c-1.183-1.601-9.141-12.102-23.25-17.849c-7.141-2.908-14.072-3.871-26.773-5.636c-5.238-0.728-9.624-1.154-12.703-1.409l0-14.917
          c0,0,26.674,0.388,32.327,1.278c13.705,2.156,21.184,6.384,28.889,9.936c15.225,7.019,27.812,21.87,29.088,23.826
          c10.336,15.841,9.176,33.585,9.15,40.302c-0.064,16.702-5.701,28.673-7.602,32.417c-8.985,17.69-20.505,26.101-30.622,32.517
          C94.293,735.948,65.841,735.995,65.841,735.995z"
        />
        <path
          aria-label="L_dorpel"
          onClick={() => handleClick('L_dorpel')}
          role="radio"
          aria-checked={setChecked('L_dorpel')}
          className="st4"
          d="M72,584.1V326.4l-13.8,0.8v256.6C61,583.9,66.4,584,72,584.1z"
        />
        <path
          aria-label="L_achterscherm"
          onClick={() => handleClick('L_achterscherm')}
          role="radio"
          aria-checked={setChecked('L_achterscherm')}
          className="st4"
          d="M179.4,634.6c-5.1,1.1-8,2-15.4,2c-3.8,0-7.3-0.2-10.5-0.5c2.3,9.7,1.3,18.8,1.3,23.1
          c0.1,10.3-5.1,28.7-7,32.4c-6.7,13.3-14.9,21.3-22.8,27.2l2.8,67.1c8.8,0.8,18.4,1.2,28.6,1.2c5.9,0,11.6-0.2,17-0.5
          c5.8-0.3,10.4-5,10.5-10.8c0.5-16.9,1-33.8,1.4-50.7c0.3-11.5,6.4-22.7,10.9-29c7.3-10.2,14.8-17.7,29.7-40.6
          c10.5-16.2,14.4-26.4,15.5-29.4c2-5.4,5.3-13.4,6.4-17.8l-65.3,23L179.4,634.6z M162.3,786.3c-6.7,0.1-13.1-0.1-19.1-0.4l2.6-19.5
          c0.3-2.2,2.1-4,4.3-4.2c3.5-0.3,7.3-0.6,11.2-0.6c2.8,0,5.5,0,8,0.1c2.5,0.1,4.6,2.1,4.7,4.7l0.6,19.5
          C170.7,786.1,166.5,786.2,162.3,786.3z"
        />
        <path
          className="st6"
          d="M56.342,200.118v-55.424c6.097-3.549,16.267-8.476,29.768-10.676
          c13.383-2.181,24.504-0.78,31.391,0.626c4.248,1.661,8.992,3.802,14.013,6.576c2.911,1.608,5.593,3.251,8.048,4.877
          c3.25,2.152,5.655,5.361,6.773,9.094c4.504,15.031,8.831,30.734,12.891,47.09c7.451,30.018,13.103,58.626,17.379,85.485
          c7.429,12.281,17.726,29.926,29.121,51.667c15.813,30.171,26.394,49.998,33.818,73.273c7.139,22.38,11.348,71.215,11.899,95.192
          c0.446,19.421-0.939,54.798-2.975,91.278c-0.365,6.545-2.504,15.716-6.576,26.773c-1.102,2.994-5.002,13.221-15.5,29.434
          c-14.829,22.902-22.35,30.372-29.658,40.599c-4.509,6.31-10.588,17.531-10.914,29.039c-0.479,16.9-0.957,33.799-1.435,50.699
          c-0.164,5.806-4.742,10.523-10.541,10.845c-5.411,0.3-11.073,0.476-16.967,0.489c-10.29,0.023-19.882-0.452-28.672-1.223
          c-1.164,2.36-3.283,5.819-7.025,8.582c-5.798,4.281-12.295,4.165-25.05,3.758c-17.039-0.544-21.097-3.94-22.546-5.323
          c-2.473-2.363-3.811-5.044-4.561-7.016c-1.507-25.948-3.013-51.895-4.52-77.842c4.761,0.99,17.725,3.025,32.252-3.131
          c4.566-1.935,17.272-8.063,25.599-22.545c1.762-3.065,6.986-12.865,7.045-26.538c0.024-5.499-0.754-19.206-10.333-32.174
          c-1.183-1.601-9.141-12.102-23.25-17.849c-7.14-2.908-14.072-3.871-26.773-5.636c-5.238-0.728-9.624-1.154-12.703-1.409l0-289.617
          c28.775,5.768,56.587-6.799,68.283-29.464c0.536-1.038,12.392-24.984,0.313-49.162C113.464,207.496,85.49,194.523,56.342,200.118z"
        />
        <path
          className="st3"
          d="M124.986,717.641c1.073,22.729,2.146,45.459,3.219,68.188"
        />
        <path
          aria-label="RA_scherm"
          onClick={() => handleClick('RA_scherm')}
          role="radio"
          aria-checked={setChecked('RA_scherm')}
          className="st5"
          d="M713.32,735.995c0.446-7.677,0.891-20.331,1.337-28.008c-4.761,0.99-17.725,3.025-32.253-3.131
          c-4.566-1.935-17.272-8.063-25.599-22.545c-1.762-3.065-6.986-12.865-7.045-26.538c-0.024-5.499,0.754-19.206,10.333-32.174
          c1.183-1.601,9.141-12.102,23.25-17.849c7.141-2.908,14.072-3.871,26.773-5.636c5.238-0.728,9.624-1.154,12.703-1.409v-14.917
          c0,0-26.674,0.388-32.327,1.278c-13.705,2.156-21.184,6.384-28.889,9.936c-15.225,7.019-27.812,21.87-29.088,23.826
          c-10.336,15.841-9.176,33.585-9.15,40.302c0.064,16.702,5.701,28.673,7.602,32.417c8.985,17.69,20.505,26.101,30.622,32.517
          C684.868,735.948,713.32,735.995,713.32,735.995z"
        />
        <path
          aria-label="R_dorpel"
          onClick={() => handleClick('R_dorpel')}
          role="radio"
          aria-checked={setChecked('R_dorpel')}
          className="st4"
          d="M707.8,584.1V326.4l13.8,0.8v256.6C718.8,583.9,713.4,584,707.8,584.1z"
        />
        <path
          aria-label="R_achterscherm"
          onClick={() => handleClick('R_achterscherm')}
          role="radio"
          aria-checked={setChecked('R_achterscherm')}
          className="st4"
          d="M596,629.9l-65.3-23
          c1.1,4.4,4.4,12.5,6.4,17.8c1.1,3,5,13.2,15.5,29.4c14.8,22.9,22.3,30.4,29.7,40.6c4.5,6.3,10.6,17.5,10.9,29
          c0.5,16.9,1,33.8,1.4,50.7c0.2,5.8,4.7,10.5,10.5,10.8c5.4,0.3,11.1,0.5,17,0.5c10.3,0,19.9-0.5,28.6-1.2l2.8-67.1
          c-7.9-5.9-16.1-13.9-22.8-27.2c-1.9-3.7-7.1-22.1-7-32.4c0-4.3-1-13.4,1.3-23.1c-3.2,0.3-6.7,0.6-10.5,0.5c-7.4,0-10.4-0.9-15.4-2
          L596,629.9z M603.9,784.6l0.6-19.5c0.1-2.5,2.1-4.6,4.7-4.7c2.6-0.1,5.2-0.1,8-0.1c4,0.1,7.7,0.3,11.2,0.6c2.2,0.2,4,1.9,4.3,4.2
          l2.6,19.5c-6,0.4-12.4,0.5-19.1,0.4C612,785,607.9,784.8,603.9,784.6z"
        />
        <g data-name="door_LV">
          <path
            aria-label="LV_portier"
            onClick={() => handleClick('LV_portier')}
            role="radio"
            aria-checked={setChecked('LV_portier')}
            className="st2"
            d="M242.148,501.663c0.002-4.546-0.112-8.77-0.359-11.852
            c-2.137-26.686-4.083-50.979-31.123-102.157c-1.806-3.417-4.002-7.315-6.054-10.96c-10.17-18.053-15.367-27.183-20.876-32.228
            c-3.988-3.653-10.842-8.612-22.031-11.424H81.91c-5.807,0-10.515,4.87-10.515,10.877c0,0,0,134.78,0,144.919
            C124.7,484.818,145.63,481.49,242.148,501.663z"
          />
          <path
            aria-label="LV_zijruit"
            onClick={() => handleClick('LV_zijruit')}
            role="radio"
            aria-checked={setChecked('LV_zijruit')}
            className="st7"
            d="M236.108,493.492l-60.524-11.538l-5.031-138.27c2.518,1.429,4.827,3.074,6.917,4.924
            c4.954,4.386,10.157,13.055,20.523,30.844c2.14,3.672,4.353,7.431,6.191,10.794c27.429,50.187,29.343,73.271,31.56,100.021
            C235.855,491.606,236.108,493.492,236.108,493.492"
          />
          <path
            aria-label="LV_lamp"
            className="st7"
            d="M117.501,134.644l3.366,33.192c3.374,0.66,7.521,1.765,12.055,3.679
            c4.727,1.995,8.539,4.306,11.429,6.341c-0.917-10.056-1.834-20.111-2.751-30.167c-2.79-1.986-5.915-4.018-9.382-6
            C126.976,138.693,121.989,136.405,117.501,134.644"
          />
        </g>
        <g data-name="door_LA">
          <path
            aria-label="LA_portier"
            onClick={() => handleClick('LA_portier')}
            role="radio"
            aria-checked={setChecked('LA_portier')}
            className="st2"
            d="M71.395,488.745c0,12.419,0,96.005,0,96.005c0,3.162,2.506,5.685,5.563,5.654
            c2.913-0.029,5.53,0.48,9.896,1.542c5.763,1.402,18.001,5.298,30.272,13.333c11.648,7.628,18.402,17.764,22.059,24.786
            c1.544,2.965,4.33,5.034,7.55,5.531c3.926,0.606,8.419,1.018,13.377,0.996c7.062-0.032,13.16-0.939,17.992-2.006l4.097-3.474
            l57.204-20.197c1.745-28.233,2.766-58.248,2.745-89.869c-0.005-8.226,0-19.385,0-19.385
            C180.704,487.927,132.332,480.327,71.395,488.745z"
          />
          <path
            aria-label="LA_zijruit"
            onClick={() => handleClick('LA_zijruit')}
            role="radio"
            aria-checked={setChecked('LA_zijruit')}
            className="st7"
            d="M235.197,506.648l0.445,15.442c0,28.235-1.559,56.777-3.289,84.983l-57.394,19.313V496.839
            L235.197,506.648z"
          />
        </g>
        <g data-name="door_RV">
          <path
            aria-label="RV_portier"
            onClick={() => handleClick('RV_portier')}
            role="radio"
            aria-checked={setChecked('RV_portier')}
            className="st2"
            d="M537.013,501.663c-0.002-4.546,0.112-8.77,0.359-11.852
            c2.137-26.686,4.083-50.979,31.124-102.157c1.805-3.417,4.001-7.315,6.054-10.96c10.17-18.053,15.367-27.183,20.876-32.228
            c3.988-3.653,10.842-8.612,22.031-11.424h79.794c5.807,0,10.515,4.87,10.515,10.877c0,0,0,134.78,0,144.919
            C654.462,484.818,633.531,481.49,537.013,501.663z"
          />
          <path
            aria-label="RV_zijruit"
            onClick={() => handleClick('RV_zijruit')}
            role="radio"
            aria-checked={setChecked('RV_zijruit')}
            className="st7"
            d="M543.053,493.492l60.524-11.538l5.031-138.27c-2.518,1.429-4.827,3.074-6.917,4.924
            c-4.954,4.386-10.157,13.055-20.523,30.844c-2.14,3.672-4.353,7.431-6.191,10.794c-27.429,50.187-29.343,73.271-31.56,100.021
            C543.306,491.606,543.053,493.492,543.053,493.492"
          />
        </g>
        <g data-name="door_RA">
          <path
            aria-label="RA_portier"
            onClick={() => handleClick('RA_portier')}
            role="radio"
            aria-checked={setChecked('RA_portier')}
            className="st2"
            d="M707.767,488.745c0,12.419,0,96.005,0,96.005c0,3.162-2.506,5.685-5.563,5.654
            c-2.914-0.029-5.53,0.48-9.896,1.542c-5.763,1.402-18.001,5.298-30.272,13.333c-11.648,7.628-18.402,17.764-22.059,24.786
            c-1.544,2.965-4.33,5.034-7.55,5.531c-3.926,0.606-8.419,1.018-13.377,0.996c-7.062-0.032-13.16-0.939-17.992-2.006l-4.097-3.474
            l-57.204-20.197c-1.745-28.233-2.766-58.248-2.745-89.869c0.005-8.226,0-19.385,0-19.385
            C598.457,487.927,646.829,480.327,707.767,488.745z"
          />
          <path
            aria-label="RA_zijruit"
            onClick={() => handleClick('RA_zijruit')}
            role="radio"
            aria-checked={setChecked('RA_zijruit')}
            className="st7"
            d="M543.964,506.648l-0.445,15.442c0,28.235,1.559,56.777,3.289,84.983l57.394,19.313V496.839
            L543.964,506.648z"
          />
        </g>
        <path
          aria-label="Voorruit_1_"
          onClick={() => handleClick('Voorruit_1_')}
          role="radio"
          aria-checked={setChecked('Voorruit_1_')}
          className="st7"
          d="M236.879,405.153c-1.879,3.236-3.648,6.646-5.527,9.882
          c-7.685-15.908-17.227-32.612-27.344-50.013c-9.395-16.158-20.048-30.983-29.048-44.661v-33.578c8,14.521,17.559,29.7,25.888,45.54
          C214.023,357.377,226.449,381.733,236.879,405.153z"
        />
        <path
          className="st2"
          d="M121.006,167.928c-0.548,17.849-1.096,35.697-1.644,53.545"
        />
        <path
          aria-label="L_spiegel"
          onClick={() => handleClick('L_spiegel')}
          role="radio"
          aria-checked={setChecked('L_spiegel')}
          className="st8"
          d="M178.679,349.708l16.929,25.706c1.289,1.958,0.778,4.572-1.137,5.924
          c-1.805,1.274-4.241,2.579-7.305,3.295c-5.029,1.175-9.366,0.205-12.11-0.793c-1.636-0.595-2.757-2.102-2.848-3.84
          c-0.484-9.229-0.968-18.459-1.452-27.688c-0.109-2.087,1.291-3.954,3.326-4.432h0C175.846,347.464,177.682,348.195,178.679,349.708z
          "
        />
        <path
          aria-label="LA_lamp"
          className="st7"
          d="M175.011,785.83l-0.615-19.53c-0.08-2.546-2.117-4.588-4.663-4.684
          c-2.553-0.096-5.224-0.13-8.003-0.083c-3.968,0.068-7.719,0.294-11.227,0.624c-2.23,0.21-4.011,1.948-4.31,4.168l-2.625,19.504
          c6.011,0.353,12.39,0.525,19.101,0.44C166.931,786.216,171.047,786.063,175.011,785.83z"
        />
        <path
          className="st2"
          d="M144.352,177.856c5.221,23.403,10,48.165,14.091,74.212c2.429,15.464,4.484,30.55,6.214,45.226
          c0.267,1.573,0.726,3.495,1.536,5.604c0.999,2.6,2.111,4.376,2.61,5.191c1.466,2.391,3.6,6.193,6.416,12.274"
        />
        <path
          className="st3"
          d="M65.139,735.716c71.535-4.977,93.151-41.814,88.632-98.232"
        />
        <g aria-label="RV_wiel">
          <ellipse
            className="st1"
            cx="703.528"
            cy="255.147"
            rx="44.155"
            ry="45.237"
          />
          <ellipse
            aria-label="RV_velg"
            onClick={() => handleClick('RV_velg')}
            role="radio"
            aria-checked={setChecked('RV_velg')}
            className="st3"
            cx="703.528"
            cy="255.147"
            rx="33.525"
            ry="34.347"
          />
        </g>
        <g aria-label="RA_wiel">
          <ellipse
            className="st1"
            cx="703.658"
            cy="654.974"
            rx="44.155"
            ry="45.237"
          />
          <ellipse
            aria-label="RA_velg"
            onClick={() => handleClick('RA_velg')}
            role="radio"
            aria-checked={setChecked('RA_velg')}
            className="st3"
            cx="703.658"
            cy="654.974"
            rx="33.525"
            ry="34.347"
          />
        </g>
        <path
          aria-label="RV_scherm"
          onClick={() => handleClick('RV_scherm')}
          role="radio"
          aria-checked={setChecked('RV_scherm')}
          className="st4"
          d="M658.711,165.943l-24.444,10.1l-13.959,74.373c-2.462,15.823-4.544,31.26-6.293,46.278
          c-0.271,1.609-0.737,3.575-1.562,5.733c-1.017,2.66-2.15,4.477-2.659,5.309c-1.494,2.446-3.668,6.334-6.538,12.554l121.029,7.096
          l-0.021-18.5c-29.376,5.87-57.789-7.024-69.758-30.232c-0.548-1.063-12.683-25.583-0.376-50.313
          c1.735-3.487,3.842-6.749,6.269-9.755C660.4,218.586,658.694,165.411,658.711,165.943"
        />
        <path
          className="st6"
          d="M722.819,200.118v-55.424c-6.097-3.549-16.267-8.476-29.768-10.676
          c-13.383-2.181-24.504-0.78-31.391,0.626c-4.248,1.661-8.992,3.802-14.013,6.576c-2.911,1.608-5.593,3.251-8.048,4.877
          c-3.25,2.152-5.655,5.361-6.773,9.094c-4.504,15.031-8.831,30.734-12.891,47.09c-7.451,30.018-13.103,58.626-17.379,85.485
          c-7.429,12.281-17.726,29.926-29.121,51.667c-15.813,30.171-26.394,49.998-33.818,73.273c-7.139,22.38-11.348,71.215-11.899,95.192
          c-0.446,19.421,0.939,54.798,2.975,91.278c0.365,6.545,2.504,15.716,6.576,26.773c1.102,2.994,5.002,13.221,15.5,29.434
          c14.829,22.902,22.35,30.372,29.658,40.599c4.509,6.31,10.588,17.531,10.914,29.039c0.479,16.9,0.957,33.799,1.435,50.699
          c0.164,5.806,4.742,10.523,10.541,10.845c5.411,0.3,11.073,0.476,16.967,0.489c10.29,0.023,19.882-0.452,28.672-1.223
          c1.164,2.36,3.283,5.819,7.025,8.582c5.798,4.281,12.295,4.165,25.05,3.758c17.039-0.544,21.097-3.94,22.545-5.323
          c2.473-2.363,3.811-5.044,4.561-7.016c1.506-25.948,3.013-51.895,4.52-77.842c-4.761,0.99-17.725,3.025-32.253-3.131
          c-4.566-1.935-17.272-8.063-25.599-22.545c-1.762-3.065-6.986-12.865-7.045-26.538c-0.024-5.499,0.754-19.206,10.333-32.174
          c1.183-1.601,9.141-12.102,23.25-17.849c7.141-2.908,14.072-3.871,26.773-5.636c5.238-0.728,9.624-1.154,12.703-1.409l0-289.617
          c-28.775,5.768-56.587-6.799-68.283-29.464c-0.536-1.038-12.393-24.984-0.313-49.162
          C665.697,207.496,693.671,194.523,722.819,200.118z"
        />
        <path
          className="st3"
          d="M654.175,717.641c-1.073,22.729-2.146,45.459-3.219,68.188"
        />
        <path
          aria-label="Voorruit_3_"
          onClick={() => handleClick('Voorruit_3_')}
          role="radio"
          aria-checked={setChecked('Voorruit_3_')}
          className="st7"
          d="M542.282,405.153c1.879,3.236,3.648,6.646,5.527,9.882
          c7.685-15.908,17.227-32.612,27.344-50.013c9.395-16.158,20.048-30.983,29.048-44.661v-33.578c-8,14.521-17.559,29.7-25.888,45.54
          C565.138,357.377,552.713,381.733,542.282,405.153z"
        />
        <path
          aria-label="RV_lamp_1_"
          className="st7"
          d="M661.66,134.644l-3.366,33.192c-3.374,0.66-7.521,1.765-12.055,3.679
          c-4.727,1.995-8.539,4.306-11.429,6.341c0.917-10.056,1.834-20.111,2.751-30.167c2.79-1.986,5.915-4.018,9.382-6
          C652.185,138.693,657.172,136.405,661.66,134.644"
        />
        <path
          className="st2"
          d="M658.155,167.928c0.548,17.849,1.096,35.697,1.644,53.545"
        />
        <path
          aria-label="R_spiegel"
          onClick={() => handleClick('R_spiegel')}
          role="radio"
          aria-checked={setChecked('R_spiegel')}
          className="st8"
          d="M600.483,349.708l-16.929,25.706c-1.289,1.958-0.778,4.572,1.137,5.924
          c1.805,1.274,4.241,2.579,7.305,3.295c5.029,1.175,9.366,0.205,12.11-0.793c1.636-0.595,2.757-2.102,2.848-3.84
          c0.484-9.229,0.968-18.459,1.452-27.688c0.109-2.087-1.291-3.954-3.326-4.432h0C603.315,347.464,601.479,348.195,600.483,349.708z"
        />
        <path
          aria-label="RA_lamp"
          className="st7"
          d="M604.15,785.83l0.615-19.53c0.08-2.546,2.117-4.588,4.662-4.684
          c2.553-0.096,5.224-0.13,8.003-0.083c3.968,0.068,7.719,0.294,11.227,0.624c2.23,0.21,4.011,1.948,4.31,4.168l2.625,19.504
          c-6.011,0.353-12.39,0.525-19.101,0.44C612.231,786.216,608.114,786.063,604.15,785.83z"
        />
        <path
          className="st2"
          d="M634.809,177.856c-5.221,23.403-10,48.165-14.091,74.212c-2.429,15.464-4.484,30.55-6.214,45.226
          c-0.267,1.573-0.726,3.495-1.536,5.604c-0.999,2.6-2.111,4.376-2.61,5.191c-1.466,2.391-3.599,6.193-6.416,12.274"
        />
        <path
          className="st3"
          d="M714.022,735.716c-71.535-4.977-93.151-41.814-88.632-98.232"
        />
        <path
          aria-label="RV_lamp"
          className="st7"
          d="M262.864,87.608c-0.47,0-4.452,24.424-4.452,24.424h58.691l8.145-15.66
          c2.076-3.992-0.82-8.764-5.32-8.764H262.864z"
        />
        <path
          aria-label="LV_lamp_1_"
          className="st7"
          d="M518.783,87.608c0.47,0,4.452,24.424,4.452,24.424h-58.691l-8.145-15.66
          c-2.076-3.992,0.82-8.764,5.32-8.764H518.783z"
        />
        <path
          aria-label="Grille"
          onClick={() => handleClick('Grille')}
          role="radio"
          aria-checked={setChecked('Grille')}
          className="st7"
          d="M443.997,96.121l-1.28-3.089c-6.761-16.323-16.781-26.333-27.553-27.349
          c-0.059-0.006-0.117-0.011-0.176-0.017c-8.259-0.776-16.305-1.239-24.133-1.442V64.21c-0.071,0.002-0.144,0.005-0.215,0.007
          c-0.071-0.002-0.144-0.005-0.215-0.007v0.014c-7.827,0.203-15.874,0.666-24.133,1.442c-0.059,0.006-0.117,0.011-0.176,0.017
          c-10.772,1.017-20.792,11.027-27.553,27.349l-1.28,3.089c-1.242,2.999-0.143,7.457,1.838,7.457h51.303h0.43h51.303
          C444.141,103.578,445.24,99.12,443.997,96.121z"
        />
        <g>
          <path
            aria-checked={setChecked('Achterbumper')}
            className="st1"
            onClick={() => handleClick('Achterbumper')}
            role="radio"
            aria-label="Achterbumper"
            d="M506.1,943.595c1.883,0.365,3.701,0.514,3.701,0.514c2.188,0,3.683,0.069,4.76-0.726
            c1.12-0.826,1.432-2.083,1.555-2.581c1.615-4.324,3.199-8.939,4.701-13.837c1.284-4.185,2.404-8.234,3.383-12.125
            c0.9-2.891,1.359-5.912,1.359-8.952v-11.229c0-2.938-2.28-5.319-5.092-5.319h-1.686c-1.223,0-2.215-1.036-2.215-2.314v-54.528
            l-125.46-0.462h0.43l-125.46,0.462v54.528c0,1.278-0.992,2.314-2.215,2.314h-1.686c-2.812,0-5.092,2.381-5.092,5.319v11.229
            c0,3.04,0.459,6.061,1.359,8.952c0.979,3.891,2.099,7.94,3.383,12.125c1.503,4.898,3.087,9.513,4.701,13.837
            c0.123,0.498,0.435,1.755,1.555,2.581c1.078,0.795,2.572,0.726,4.76,0.726c0,0,1.818-0.149,3.701-0.514
            c4.918-0.952,9.925-1.365,14.934-1.365h199.689C496.175,942.229,501.182,942.642,506.1,943.595z"
          />
          <path
            className="st2"
            d="M480.124,867.776l-5.826,10.711c-2.141,3.936-6.263,6.387-10.744,6.387h-73.266h0.354h-72.004
            c-4.404,0-8.455-2.45-10.559-6.387l-5.726-10.711"
          />
        </g>
        <path
          aria-label="LA_lamp_1_"
          className="st7"
          d="M266.039,841.268c0.082,11.535,0.164,23.071,0.246,34.606
          c10.749-0.049,21.497-0.099,32.246-0.148c3.741,0,6.496-2.585,5.303-4.976l-12.492-25.056c-1.261-2.529-4.694-4.193-8.65-4.193
          C277.141,841.422,271.59,841.345,266.039,841.268z"
        />
        <path
          aria-label="RA_lamp_1_"
          className="st7"
          d="M517.074,841.268c-0.083,11.535-0.167,23.071-0.25,34.606
          c-10.937-0.049-21.874-0.099-32.812-0.148c-3.807,0-6.61-2.585-5.396-4.976l12.711-25.056c1.283-2.529,4.776-4.193,8.802-4.193
          C505.777,841.422,511.426,841.345,517.074,841.268z"
        />
        <path
          aria-label="Achterklep"
          onClick={() => handleClick('Achterklep')}
          role="radio"
          aria-checked={setChecked('Achterklep')}
          className="st9"
          d="M442.713,716.272c-18.94,1.706-39.011,1.087-51.977,1.416
          c-15.837,0.402-33.527,0.316-52.468-1.389c-25.439-2.291-48.546-6.425-68.071-11.33c0.324,22.396,0.649,44.791,0.973,67.187
          c0.123,8.529,6.117,15.563,13.831,16.192c17.573,1.433,35.625,2.602,54.137,3.458c17.461,0.807,34.527,1.298,51.178,1.515
          c16.651-0.217,33.717-0.708,51.178-1.515c18.512-0.856,36.565-2.025,54.137-3.458c7.714-0.629,13.708-7.663,13.831-16.192
          c0.325-22.434,0.65-44.867,0.975-67.301C490.918,709.761,468.152,713.981,442.713,716.272z"
        />
        <path
          aria-label="Dak"
          onClick={() => handleClick('Dak')}
          role="radio"
          aria-checked={setChecked('Dak')}
          className="st9"
          d="M491.073,643.502c-42.251,16.452-132.043,18.526-203.623-1.421
          c15.716-70.431,26.135-129.221,4.421-237.357c41.369-11.235,149.413-13.426,197.312-0.494
          C470.235,500.209,475.817,583.93,491.073,643.502z"
        />
        <path
          aria-label="Voorruit"
          onClick={() => handleClick('Voorruit')}
          role="radio"
          aria-checked={setChecked('Voorruit')}
          className="st7"
          d="M390.505,395.29c18.639,0.045,38.547,0.483,59.703,2.43c28.279,3.349,34.605,5.209,38.975,6.51
          c9.143-32.096,17.99-62.723,27.133-94.819l-0.333-0.272c-21.403-17.522-55.026-25.933-74.778-28.371
          c-12.507-1.544-37.755-1.698-50.349-1.701c-12.701-0.003-38.165,0.146-50.779,1.701c-19.752,2.435-53.375,10.849-74.778,28.371
          l-0.333,0.272c9.143,32.096,26.904,95.313,26.904,95.313s13.221-3.841,39.267-6.26c21.156-1.948,41.158-3.129,59.796-3.174"
        />
        <path
          aria-label="Achterruit"
          onClick={() => handleClick('Achterruit')}
          role="radio"
          aria-checked={setChecked('Achterruit')}
          className="st10"
          d="M287.45,642.081l-17.636,62.505c19.605,4.947,42.851,9.119,68.455,11.425
          c18.94,1.706,36.63,1.791,52.467,1.389c12.966-0.329,33.037,0.289,51.977-1.416c25.604-2.306,48.504-6.564,68.108-11.511
          l-19.749-60.971c-6.432,3.625-42.074,13.66-100.923,12.94C340.999,656.442,287.45,642.081,287.45,642.081z"
        />
      </Svg>
    </fieldset>
  )
}

OpticalCarDamagePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOf(
    Object.keys(initialData).map((key) => initialData[key].value),
  ),
}

OpticalCarDamagePicker.defaultProps = {
  selected: null,
}

export default OpticalCarDamagePicker
