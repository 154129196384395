import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer } from '@material-ui/core'

import styled, { ThemeContext } from 'styled-components/macro'
import { useQuery, useMutation } from '@apollo/client'
import { useMediaLayout } from 'use-media'

import ActionIconButton from 'components/atoms/action-icon-button'
import EnhancedTable from 'components/organisms/enhanced-table'
import PopupMenu from 'components/molecules/popup-menu'
import Button from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import BodyHeading from 'components/atoms/body-heading'
import Typography from 'components/molecules/typography'
import Menu from 'components/organisms/menu'

import {
  GET_USER_INPUT_ACCESSORIES,
  CREATE_USER_INPUT_ACCESSORY,
  UPDATE_USER_INPUT_ACCESSORY,
  DELETE_USER_INPUT_ACCESSORY,
  UPDATE_USER_INPUT_ACCESSORY_LOCALIZATION,
} from 'config/graphql/v4/queries/accessories'

import { media } from 'utilities/styled'
import toast from 'utilities/toast'
import { withApolloV4Provider } from 'utilities/apollo'

import AccessoryOverlay from './accessories/accessory-overlay'
import { ACCESSORY_FORM_SWITCH_GROUP_SETTINGS } from './accessories/accessory-form'

const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl0};
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.sizeByFactor(1)};
`

const StyledBodyHeading = styled(BodyHeading)`
  margin: 0;
`

const RowText = styled(Typography)`
  margin-top: 0;
  margin-bottom: 0;

  ${({ theme, $textTonedDown }) =>
    $textTonedDown && `color: ${theme.colors.textTonedDown}`};
`

const StyledPopupMenu = styled(PopupMenu)`
  text-align: left;

  > .actual-menu {
    right: 0;
    min-width: 230px;
  }
`

const Table = styled(EnhancedTable)`
  position: relative;

  ${media.tv`
    > .MuiTableContainer-root {
      overflow: visible;
    }
  `}
`

const CloseMenuOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  z-index: 100;

  ${media.tv`
    position: fixed;
    width: 100%;
    height: 100%;
  `}
`

const DrawerHeader = styled.header`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.sizings.lvl2}
    ${({ theme }) => theme.sizings.lvl2} ${({ theme }) => theme.sizings.lvl2}
    ${({ theme }) => theme.sizings.lvl4};
`

const DrawerHeading = styled(BodyHeading)`
  flex: 1 0 auto;
  margin: 0;
`

const CloseButton = styled(Button)`
  flex: 0 0 auto;
`

const StyledTd = styled.td`
  border: none !important;
  padding: ${({ theme }) => theme.sizeByFactor(0.125)}
    ${({ theme }) => theme.sizings.lvl1}
    ${({ theme }) => theme.sizeByFactor(0.125)} 0;
`

const NoAccessoiresFound = styled(Typography)`
  margin-bottom: 0;
`

const StyledMenu = styled(Menu)`
  padding-bottom: ${({ theme }) => theme.sizeByFactor(2)};
`

const StyledRowText = styled(RowText)`
  text-transform: capitalize;
`

export const NEW_ACCESSORY_ID = 'NEW_ACCESSORY'
export const ACCESSORY_LANGUAGES = {
  DE: 'DE',
  FR: 'FR',
  EN: 'EN',
}

const LocationsAccessoriesSettings = () => {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const [activeMenuItemId, setActiveMenuItemId] = useState(null)
  const [addEditAccessoryId, setAddEditAccessoryId] = useState(null)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tv - 1 })
  const isNewAccessory = addEditAccessoryId === NEW_ACCESSORY_ID
  const {
    data: getAccessoriesData,
    loading: getAccessoriesLoading,
    error: getAccessoriesError,
  } = useQuery(GET_USER_INPUT_ACCESSORIES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [createAccessoryMutation, { loading: createAccessoryLoading }] =
    useMutation(CREATE_USER_INPUT_ACCESSORY, { awaitRefetchQueries: true })

  const [updateAccessoryMutation, { loading: updateAccessoryLoading }] =
    useMutation(UPDATE_USER_INPUT_ACCESSORY, { awaitRefetchQueries: true })

  const [
    deleteAccessoryMutation,
    { loading: deleteAccessoryLoading, error: deleteAccessoryError },
  ] = useMutation(DELETE_USER_INPUT_ACCESSORY, { awaitRefetchQueries: true })

  const [
    updateLocalizationMutation,
    { loading: updateLocalizationLoading, error: updateLocalizationError },
  ] = useMutation(UPDATE_USER_INPUT_ACCESSORY_LOCALIZATION, {
    awaitRefetchQueries: true,
  })

  const isLoading =
    getAccessoriesLoading || deleteAccessoryLoading || updateLocalizationLoading
  const error =
    getAccessoriesError || deleteAccessoryError || updateLocalizationError

  if (isLoading || error) {
    return <LoadingIndicator error={error} />
  }

  const handleOpenAddEditAccessoryOverlay = (
    accessoryId = NEW_ACCESSORY_ID,
  ) => {
    setAddEditAccessoryId(accessoryId)
  }

  const handleCloseAddEditAccessoryOverlay = () => setAddEditAccessoryId(null)

  const handleCreateEditAccessorySubmit = (values) => {
    const { omschrijving, disabled, vehicleTypes } = values
    const createOrUpdateMutation = isNewAccessory
      ? createAccessoryMutation
      : updateAccessoryMutation

    const variables = isNewAccessory
      ? {
          omschrijving,
          vehicleTypes,
        }
      : {
          id: addEditAccessoryId,
          omschrijving,
          disabled:
            disabled === ACCESSORY_FORM_SWITCH_GROUP_SETTINGS.deactivated,
          vehicleTypes,
        }

    createOrUpdateMutation({
      variables,
      refetchQueries: () => [
        {
          query: GET_USER_INPUT_ACCESSORIES,
        },
      ],
    })
      .then((data) => {
        // We're using the data from the response (create or update) to update the translations
        const translations =
          data?.data?.updateUserInputAccessory?.translations ||
          data?.data?.createUserInputAccessory?.translations

        if (Array.isArray(translations) && translations.length > 0) {
          translations.forEach((translation) => {
            // When the translation is either:
            // - not entered on creation of the accessory
            // - not updated when updating the accessory
            // Then don't fire the update tranlsation mutation
            const updatedTranslation =
              values[`translation${translation.language}`]
            if (
              updatedTranslation === translation.translation ||
              !updatedTranslation
            ) {
              return
            }

            const variables = {
              id: translation.localizationId,
              language: translation.language,
              text: values[`translation${translation.language}`],
            }

            updateLocalizationMutation({
              variables,
              refetchQueries: () => [
                {
                  query: GET_USER_INPUT_ACCESSORIES,
                },
              ],
            })
          })
        }

        setAddEditAccessoryId(null)
        toast.success(
          t(
            `accessoriesSettings.toast.${isNewAccessory ? 'created' : 'updated'}`,
          ),
        )
      })
      .catch((error) => {
        console.error(error)
        toast.error(t('accessoriesSettings.toast.error'))
      })
  }

  const handleDeleteAccessory = (accessoryId) => {
    if (window.confirm(t('accessoriesSettings.tab.confirmDeleteAccessory'))) {
      deleteAccessoryMutation({
        variables: { id: accessoryId },
        refetchQueries: () => [
          {
            query: GET_USER_INPUT_ACCESSORIES,
          },
        ],
      })
        .then(() => {
          toast.success(t('accessoriesSettings.toast.deleted'))
        })
        .catch((error) => {
          console.error(error)
          toast.error(t('accessoriesSettings.toast.error'))
        })
    }
  }

  const closeDrawer = () => setActiveMenuItemId(null)

  const data = getAccessoriesData?.userInputAccessories?.edges

  const generateMenuItems = (accessory) => {
    return [
      {
        icon: 'edit',
        label: t('edit'),
        onClick: () => {
          handleOpenAddEditAccessoryOverlay(accessory.id)
          setActiveMenuItemId(null)
        },
      },
      {
        icon: 'delete',
        label: t('delete'),
        onClick: () => {
          handleDeleteAccessory(accessory.id)
          setActiveMenuItemId(null)
        },
      },
    ]
  }

  const columns = [
    {
      id: 'description',
      label: t('accessoriesSettings.tableHeadings.description'),
    },
    {
      id: 'translations',
      label: t('accessoriesSettings.tableHeadings.translation'),
    },
    {
      id: 'vehicleTypes',
      label: t('accessoriesSettings.tableHeadings.vehicleTypes'),
    },
    { id: 'disabled', label: t('accessoriesSettings.tableHeadings.disabled') },
    { id: 'used', label: t('accessoriesSettings.tableHeadings.used') },
    {
      id: 'actions',
      label: t('accessoriesSettings.tableHeadings.actions'),
      alignRight: true,
    },
  ]

  const rows = data.map((item) => {
    const accessory = item.node

    const translationDe = accessory.translations.find(
      (translation) => translation.language === ACCESSORY_LANGUAGES.DE,
    )?.translation
    const translationFr = accessory.translations.find(
      (translation) => translation.language === ACCESSORY_LANGUAGES.FR,
    )?.translation
    const translationEn = accessory.translations.find(
      (translation) => translation.language === ACCESSORY_LANGUAGES.EN,
    )?.translation

    return {
      description: {
        component: (
          <RowText type="InlineBodyText">{accessory.omschrijving}</RowText>
        ),
      },
      translations: {
        component: (
          <table>
            <tbody>
              <tr>
                <StyledTd>
                  <RowText
                    type="InlineBodyText"
                    $textTonedDown={!translationEn}
                  >
                    {t('accessoriesSettings.tableRows.languages.EN')}:
                  </RowText>
                </StyledTd>
                <StyledTd>
                  <RowText
                    type="InlineBodyText"
                    $textTonedDown={!translationEn}
                  >
                    {translationEn ||
                      t('accessoriesSettings.tableRows.notYetTranslated')}
                  </RowText>
                </StyledTd>
              </tr>
              <tr>
                <StyledTd>
                  <RowText
                    type="InlineBodyText"
                    $textTonedDown={!translationDe}
                  >
                    {t('accessoriesSettings.tableRows.languages.DE')}:
                  </RowText>
                </StyledTd>
                <StyledTd>
                  <RowText
                    type="InlineBodyText"
                    $textTonedDown={!translationDe}
                  >
                    {translationDe ||
                      t('accessoriesSettings.tableRows.notYetTranslated')}
                  </RowText>
                </StyledTd>
              </tr>
              <tr>
                <StyledTd>
                  <RowText
                    type="InlineBodyText"
                    $textTonedDown={!translationFr}
                  >
                    {t('accessoriesSettings.tableRows.languages.FR')}:
                  </RowText>
                </StyledTd>
                <StyledTd>
                  <RowText
                    type="InlineBodyText"
                    $textTonedDown={!translationFr}
                  >
                    {translationFr ||
                      t('accessoriesSettings.tableRows.notYetTranslated')}
                  </RowText>
                </StyledTd>
              </tr>
            </tbody>
          </table>
        ),
      },
      vehicleTypes: {
        component:
          accessory.vehicleTypes.length > 0 ? (
            <table>
              <tbody>
                {accessory.vehicleTypes.map((type, key) => (
                  <tr key={`accessory-${accessory.id}-vehicle-type-${key}`}>
                    <StyledTd>
                      <StyledRowText type="InlineBodyText">
                        {type}
                      </StyledRowText>
                    </StyledTd>
                  </tr>
                ))}
              </tbody>
              {/* eslint-disable indent */}
              {/* eslint-disable-next-line react/jsx-indent */}
            </table>
          ) : (
            <RowText $textTonedDown type="InlineBodyText">
              {t('accessoriesSettings.tableRows.noVehicleTypesSelected')}
            </RowText>
          ),
      },
      used: {
        component: (
          <RowText type="InlineBodyText">{`${accessory.gebruikt} ${t('accessoriesSettings.tableRows.times')}`}</RowText>
        ),
      },
      disabled: {
        component: (
          <RowText type="InlineBodyText">
            {accessory.disabled ? t('no') : t('yes')}
          </RowText>
        ),
      },
      actions: {
        component: (
          <>
            {activeMenuItemId === accessory.id && (
              <CloseMenuOverlay
                onClick={() => {
                  setActiveMenuItemId(undefined)
                }}
                data-test-e2e="checklist-actions-menu-close-overlay"
              />
            )}
            {isMobile ? (
              <>
                <ActionIconButton
                  icon="dots"
                  width="24"
                  height="24"
                  onClick={() => {
                    setActiveMenuItemId((id) =>
                      id === accessory.id ? undefined : accessory.id,
                    )
                  }}
                  disabled={accessory.id === '124' || accessory.id === '123'} // TODO: Temporary for UI testing - delete later
                />
                <Drawer
                  anchor="bottom"
                  open={activeMenuItemId === accessory.id}
                  onClose={closeDrawer}
                >
                  <DrawerHeader>
                    <DrawerHeading level="5">
                      {t('accessoriesSettings.tableRowOptionsHeading')}
                    </DrawerHeading>
                    <CloseButton
                      iconColor="text"
                      level="minimal"
                      icon="close"
                      onClick={closeDrawer}
                      $isAbsolute
                    />
                  </DrawerHeader>
                  <StyledMenu items={generateMenuItems(accessory)} />
                </Drawer>
              </>
            ) : (
              <StyledPopupMenu
                items={generateMenuItems(accessory)}
                positionMenu={{ toLeft: true }}
                isMenuActive={activeMenuItemId === accessory.id}
              >
                <ActionIconButton
                  icon="dots"
                  width="24"
                  height="24"
                  onClick={() => {
                    setActiveMenuItemId((id) =>
                      id === accessory.id ? null : accessory.id,
                    )
                  }}
                  disabled={accessory.id === '124' || accessory.id === '123'} // TODO: Temporary for UI testing - delete later
                />
              </StyledPopupMenu>
            )}
          </>
        ),
      },
    }
  })

  return (
    <>
      <StyledArticle>
        <StyledHeader>
          <StyledBodyHeading level="2">{t('accessories')}</StyledBodyHeading>
          <Button
            level="option"
            onClick={() => handleOpenAddEditAccessoryOverlay()}
            noPadding
          >
            + {t('accessoriesSettings.tab.addAccessoire')}
          </Button>
        </StyledHeader>
        {!data.length ? (
          <NoAccessoiresFound type="BodyParagraph">
            {t('noAccessoiresFound')}
          </NoAccessoiresFound>
        ) : (
          <Table columns={columns} rows={rows} selectable={false} />
        )}
      </StyledArticle>
      {(addEditAccessoryId ||
        createAccessoryLoading ||
        updateAccessoryLoading) && (
        <AccessoryOverlay
          accessoryId={addEditAccessoryId}
          isNewAccessory={isNewAccessory}
          getAccessoriesData={data}
          createAccessoryLoading={createAccessoryLoading}
          updateAccessoryLoading={updateAccessoryLoading}
          handleCloseAddEditAccessoryOverlay={
            handleCloseAddEditAccessoryOverlay
          }
          handleCreateEditAccessorySubmit={handleCreateEditAccessorySubmit}
        />
      )}
    </>
  )
}

export default withApolloV4Provider(LocationsAccessoriesSettings)
