import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Creatable from 'react-select/creatable'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'

function getSelectStyles(theme) {
  return {
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      margin: 0,
      boxShadow: '0 4px 11px hsla(0, 0%, 0%, 0.1)',
    }),
    option: (provided) => ({
      ...provided,
      color: theme.colors.brandAlfa,
      backgroundColor: 'transparent',
      padding: 12,
      fontSize: 14,
      lineHeight: '16px',
      fontFamily: 'work-sans',
      textAlign: 'left',
      cursor: 'pointer',

      ':hover, :focus, :active': {
        backgroundColor: theme.colors.brandFoxtrot,
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      padding: 12,
      fontSize: 14,
      lineHeight: '16px',
      fontFamily: 'work-sans',
    }),
    input: (provided) => ({
      ...provided,
      paddingLeft: 12,
      '&, *': {
        color: theme.colors.brandAlfa,
        fontSize: 14,
        fontFamily: 'work-sans',
      },
    }),
    container: (base) => ({
      ...base,
      width: '100%',
    }),
    control: (base, state) => ({
      ...base,
      fontSize: 16,
      borderRadius: 0,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: state.isFocused
        ? theme.colors.brandAlfa
        : theme.colors.brandDelta,
      boxShadow: 'none',
      padding: 0,
      width: '100%',
      textAlign: 'left',
      cursor: 'pointer',

      ':hover, :focus, :active': {
        borderColor: state.isFocused
          ? theme.colors.brandAlfa
          : theme.colors.brandDelta,
      },
    }),
    clearIndicator: (base, state) => ({
      ...base,
      color: state.isFocused ? theme.colors.brandAlfa : theme.colors.brandDelta,

      ':hover, :focus, :active': {
        color: state.isFocused
          ? theme.colors.brandAlfa
          : theme.colors.brandDelta,
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: theme.colors.brandDelta,

      ':hover, :focus, :active': {
        backgroundColor: theme.colors.brandDelta,
      },
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused ? theme.colors.brandAlfa : theme.colors.brandDelta,

      ':hover, :focus, :active': {
        color: theme.colors.brandAlfa,
      },
    }),
    multiValue: (base) => ({
      ...base,
      borderRadius: 0,

      backgroundColor: theme.colors.brandFoxtrot,
      padding: '10px 12px',
      ':hover, :focus, :active': {
        color: theme.colors.brandAlfa,
      },
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: theme.colors.brandAlfa,
      padding: 0,
      paddingLeft: 0,
      fontWeight: 500,
      fontSize: 14,
      fontFamily: 'work-sans',
      lineHeight: '16px',
    }),
    multiValueRemove: (base) => ({
      ...base,
      padding: 0,
      margin: '0 0 0 12px',
      color: theme.colors.brandDelta,
      backgroundColor: 'none',
      ':hover, :focus, :active': {
        color: theme.colors.brandAlfa,
        backgroundColor: 'transparent',
      },
    }),
    valueContainer: (base) => ({
      ...base,
    }),
  }
}

function OptionsSelect({
  defaultValue,
  hideClearIndicator,
  items,
  name,
  newIndicator,
  onChange,
  placeholder,
  ...restProps
}) {
  const loading = !items
  const theme = useContext(ThemeContext)
  const [value, setValue] = useState(defaultValue)
  const { t } = useTranslation()
  const selectStyles = getSelectStyles(theme)

  function handleChange(selected) {
    const selectedArray = selected.map((option) => option.value)
    setValue(selected)
    onChange(selectedArray)
  }

  // New item is prefixed with the letter 'n' when newIndicator prop
  // is true so backend knows it is a new item, as requested by the backend team.
  function handleCreate(item) {
    const newValue = []
    // value can sometimes not be set (null or undefined):
    if (value) {
      newValue.push(...value)
    }
    newValue.push({
      label: item,
      value: newIndicator ? `n${item}` : item,
    })
    setValue(newValue)
    handleChange(newValue)
  }

  const customComponents = {}

  if (hideClearIndicator) {
    customComponents.ClearIndicator = null
  }

  return (
    <div {...restProps}>
      <Creatable
        components={customComponents}
        defaultValue={defaultValue}
        isLoading={loading}
        name={name}
        onChange={handleChange}
        options={loading ? [] : items}
        styles={selectStyles}
        onCreateOption={handleCreate}
        placeholder={placeholder}
        formatCreateLabel={(option) => `${t('addNew')}: ${option}`}
        noOptionsMessage={() => `${t('noOptions')}`}
        value={value}
        isMulti
      />
    </div>
  )
}

OptionsSelect.propTypes = {
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ),
  hideClearIndicator: PropTypes.bool,
  name: PropTypes.string.isRequired,
  newIndicator: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

OptionsSelect.defaultProps = {
  defaultValue: null,
  hideClearIndicator: null,
  items: null,
  newIndicator: false,
  placeholder: null,
}

export default OptionsSelect
