import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import LoadingIndicator from 'components/atoms/loading-indicator'
import ExpandableBars from 'components/layouts/expandable-bars'
import SummaryLine from 'components/molecules/summary-line'
import Tabs from 'components/layouts/tabs'
import TabularResults from 'components/organisms/tabular-results'
import OptionsBar from 'components/layouts/options-bar'
import Text from 'components/atoms/text'

function B2bPurchasingSettings({
  clearData,
  locationsDetails,
  getLocationsDetails,
  getB2bPurchaseInterests,
  b2bPurchaseInterests,
  deleteB2bPurchaseInterests,
  openOverlay,
  postDynamicEndpoint,
  match,
}) {
  const [currentLocation, setCurrentLocation] = useState(null)
  const b2bInterestsData =
    b2bPurchaseInterests &&
    b2bPurchaseInterests.data &&
    b2bPurchaseInterests.data.data

  const { t } = useTranslation()

  useEffect(() => {
    getLocationsDetails()
  }, [clearData, getLocationsDetails])

  const updateInterests = () =>
    getB2bPurchaseInterests({
      vestiging_id: currentLocation,
    })

  useEffect(() => {
    if (currentLocation !== null) {
      clearData('b2bPurchaseInterests')
      getB2bPurchaseInterests({
        vestiging_id: currentLocation,
      })
    }
  }, [clearData, currentLocation, getB2bPurchaseInterests])

  function handleDeleteB2bPurchaseInterest(interestId, locationId) {
    if (window.confirm(t('areYouSure'))) {
      deleteB2bPurchaseInterests({
        interesse_id: interestId,
        vestiging_id: locationId,
      })
    }
  }

  const tabItems = [
    {
      label: t('interests'),
      to: `${match.url}/b2b-purchasing-profile/interests/`,
      component: () => (
        <>
          {!b2bPurchaseInterests || b2bPurchaseInterests.loading ? (
            <LoadingIndicator />
          ) : (
            <TabularResults
              title={t('interests')}
              data={Array.isArray(b2bInterestsData) ? b2bInterestsData : []}
              ordering={[
                'brand',
                'model',
                'buildYearMin',
                'buildYearMax',
                'distanceDrivenMin',
                'distanceDrivenMax',
                'carType',
              ]}
              dataMapping={{
                brand: {
                  label: t('brand'),
                  getter: (row) => row.merk,
                },
                model: {
                  label: t('model'),
                  getter: (row) => row.modelserie,
                },
                buildYearMin: {
                  label: t('buildYearMin'),
                  getter: (row) => row.jaar_min,
                },
                buildYearMax: {
                  label: t('buildYearMax'),
                  getter: (row) => row.jaar_max,
                },
                distanceDrivenMin: {
                  label: t('distanceDrivenMin'),
                  getter: (row) => row.km_min,
                },
                distanceDrivenMax: {
                  label: t('distanceDrivenMax'),
                  getter: (row) => row.km_max,
                },
                carType: {
                  label: t('carType'),
                  getter: (row) => row.soort,
                },
              }}
              actions={[
                {
                  label: t('modify'),
                  method: (row) =>
                    openOverlay('dynamicForm', {
                      formId: 'editB2bSalesInterest',
                      formParams: {
                        interesse_id: row.id,
                      },
                      onSubmit: (values, endpoint) => {
                        postDynamicEndpoint({
                          endpoint,
                          ...values,
                        })
                      },
                      onUpdateSuccess: updateInterests,
                      subId: currentLocation,
                      icon: 'preCalculation',
                      submitText: t('saveChanges'),
                      title: t('modifyB2bSalesInterest'),
                    }),
                },
                {
                  label: t('delete'),
                  method: (row) =>
                    handleDeleteB2bPurchaseInterest(row.id, currentLocation),
                },
              ]}
              addNewOption={{
                label: t('addInterest'),
                method: () =>
                  openOverlay('dynamicForm', {
                    formId: 'addB2bPurchasingInterest',
                    formParams: {
                      vestiging_id: currentLocation,
                    },
                    onSubmit: (values, endpoint) => {
                      postDynamicEndpoint({
                        endpoint,
                        ...values,
                      })
                    },
                    onUpdateSuccess: updateInterests,
                    subId: currentLocation,
                    icon: 'preCalculation',
                    submitText: t('addB2bSalesInterest'),
                    title: t('addingB2bSalesInterest'),
                  }),
              }}
              noDataMessage={t('noInterestsYet')}
            />
          )}
        </>
      ),
    },
  ]

  return (
    <>
      <OptionsBar
        leftAligned={[
          <Text type="h2" text={t('locationsOverview')} key="title" />,
        ]}
      />
      {locationsDetails && locationsDetails.data ? (
        <ExpandableBars
          rows={locationsDetails.data.map((location) => ({
            id: location.id,
            header: (
              <SummaryLine
                key={location.dealernr}
                name={location.label}
                details={[]}
              />
            ),
            onRowClick: () => setCurrentLocation(location.id),
            details: (
              <Tabs items={tabItems} small>
                {tabItems.map((tabItem, subIdx) => (
                  <tabItem.component
                    key={subIdx.toString()}
                    location={location}
                  />
                ))}
              </Tabs>
            ),
          }))}
        />
      ) : (
        <LoadingIndicator />
      )}
    </>
  )
}

B2bPurchasingSettings.propTypes = {
  clearData: PropTypes.func.isRequired,
  locationsDetails: PropTypes.object,
  getLocationsDetails: PropTypes.func.isRequired,
  match: PropTypes.object,
  getB2bPurchaseInterests: PropTypes.func.isRequired,
  deleteB2bPurchaseInterests: PropTypes.func.isRequired,
  openOverlay: PropTypes.func.isRequired,
  b2bPurchaseInterests: PropTypes.object,
  postDynamicEndpoint: PropTypes.func.isRequired,
}

B2bPurchasingSettings.defaultProps = {
  match: null,
  locationsDetails: null,
  b2bPurchaseInterests: null,
}

export default B2bPurchasingSettings
