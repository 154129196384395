import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import { ADD_CUSTOMER, UPDATE_CUSTOMER } from '../../../../config/graphql/csp'
import { CSPSalesFlowCustomerMinimumSchema } from '../../../../config/validation-schemas'
import toast from '../../../../utilities/toast'

import {
  FormikSelectInput,
  FormikTextInput,
} from '../../../molecules/formik-field'
import LoadingIndicator from '../../../atoms/loading-indicator'
import CustomerSearchInput from '../../../molecules/customer-search-input'
import Button from '../../../atoms/button'
import Typography from '../../../molecules/typography'

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
  > *:first-child {
    padding-right: ${({ theme }) => theme.sizings.lvl2};
  }
`
const StyledDropDownSelect = styled(FormikSelectInput)`
  min-width: 120px;
  max-width: 50%;
`
const StyledTextInput = styled(FormikTextInput)`
  flex: 1;
  max-width: 50%;
`
const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.sizings.lvl3};
`
const StyledCustomerSearchInput = styled(CustomerSearchInput)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const customerFields = {
  title: 'subheadingCustomer',
  rows: [
    {
      id: 'basic',
      fields: [
        { id: 'firstName', label: 'firstName', type: 'text' },
        { id: 'lastName', label: 'lastName', type: 'text' },
      ],
    },
    {
      id: 'contact',
      fields: [
        { id: 'zipCode', label: 'postalcode', type: 'text' },
        { id: 'email', label: 'email', type: 'text' },
      ],
    },
  ],
}

const CspSalesCustomerForm = ({
  onSubmit,
  primaryActionLabel,
  initialCustomer,
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const dealerId = new URLSearchParams(location.search).get('dealerId')
  const [activeCustomer, setActiveCustomer] = useState(initialCustomer)
  const [addCustomer] = useMutation(ADD_CUSTOMER)
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER)

  useEffect(() => {
    setActiveCustomer(initialCustomer)
  }, [initialCustomer])

  const initialValues = {}
  useEffect(() => {
    if (!activeCustomer) {
      initialValues.country = 'Nederland'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** This loop builds the initialValues by looping over the schema,
   * and filling from relevant initial objects, depending on whether its a company customer or not
   * */
  customerFields.rows.forEach((row) =>
    row.fields.forEach((field) => {
      initialValues[field.id] =
        activeCustomer && activeCustomer[field.id]
          ? activeCustomer[field.id]
          : ''
    }),
  )

  const handleAddCustomer = async (payload) => {
    const modifiedCustomer = await addCustomer({
      variables: payload,
    })
    if (
      modifiedCustomer &&
      modifiedCustomer.data &&
      modifiedCustomer.data.createCustomer
    ) {
      return modifiedCustomer.data.createCustomer
    }
    throw new Error('Error from adding customer')
  }
  const handleUpdateCustomer = async (payload) => {
    const modifiedCustomer = await updateCustomer({
      variables: {
        id: activeCustomer.id,
        ...payload,
      },
    })
    if (
      modifiedCustomer &&
      modifiedCustomer.data &&
      modifiedCustomer.data.updateCustomer
    ) {
      return modifiedCustomer.data.updateCustomer
    }
    throw new Error('Error from adding customer')
  }

  const handleSubmitForm = async (payload) => {
    const customerPayload = {
      dealerId,
      ...payload,
    }

    const customerResponse = activeCustomer
      ? await handleUpdateCustomer(customerPayload)
      : await handleAddCustomer(customerPayload)

    return onSubmit({
      customerId: customerResponse.id,
      customer: customerResponse,
      lastName: payload.lastName,
    })
  }

  const requiredFields = ['lastName']

  return (
    <>
      {!initialCustomer && (
        <StyledCustomerSearchInput
          onChange={(val) => setActiveCustomer(val)}
          data-test-e2e="customer-search"
        />
      )}
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={CSPSalesFlowCustomerMinimumSchema(t)}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          try {
            await handleSubmitForm(values)
          } catch (e) {
            console.error(e)
            toast.error(t('cspSalesFlow.errorSubmittingCustomer'))
          }
          setSubmitting(false)
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form data-test-e2e="customer-form">
            <div key={`formSection_${customerFields.title}`}>
              <Typography type="Level2Heading">
                {t(`cspSalesFlow.steps.customerData.${customerFields.title}`)}
              </Typography>
              {customerFields.rows.map((sectionRow) => (
                <FormRow
                  key={`formSection_${customerFields.title}_row_${sectionRow.id}`}
                >
                  {sectionRow.fields.map((field) => (
                    <Field
                      disabled={isSubmitting}
                      key={`formSection_${customerFields.title}_row_${sectionRow.id}_field_${field.id}`}
                      name={field.id}
                      id={field.id}
                      label={t(field.label)}
                      filled
                      items={field.items}
                      component={
                        field.type === 'text'
                          ? StyledTextInput
                          : StyledDropDownSelect
                      }
                      required={requiredFields.includes(field.id)}
                    />
                  ))}
                </FormRow>
              ))}
            </div>

            {onSubmit && (
              <ActionsContainer>
                {isSubmitting && <LoadingIndicator size="small" />}
                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  level="cta"
                  type="submit"
                  data-test-e2e="button-primary-action"
                >
                  {primaryActionLabel ||
                    t('cspSalesFlow.steps.basicData.nextButtonLabel')}
                </Button>
              </ActionsContainer>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}

CspSalesCustomerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  primaryActionLabel: PropTypes.string,
  initialCustomer: PropTypes.object,
}

CspSalesCustomerForm.defaultProps = {
  primaryActionLabel: undefined,
  initialCustomer: undefined,
}

export default CspSalesCustomerForm
