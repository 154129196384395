import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'

const HeaderContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`

const StyledIcon = styled(Icon)`
  margin-left: auto;
`

function FilterCollapser({ children, initallyOpen, title }) {
  const [open, setOpen] = useState(initallyOpen)

  return (
    <>
      <HeaderContainer onClick={() => setOpen(!open)}>
        <Text text={title} type="tab" />
        <StyledIcon color="brandAlfa" size="sm" type={open ? 'close' : 'add'} />
      </HeaderContainer>
      {open && children}
    </>
  )
}

FilterCollapser.propTypes = {
  children: PropTypes.node.isRequired,
  initallyOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

FilterCollapser.defaultProps = {
  initallyOpen: null,
}

export default FilterCollapser
