import { useRouteMatch } from 'react-router-dom'

import { B2B_CAR_FILE, CAR_FILE, CAR_SHARE_CAR_FILE } from 'config/routes'

export const useCarFileType = () => {
  const match = useRouteMatch()

  const path = `/${match.url.split('/').filter(Boolean)[0]}`

  const isRegularCarFile = path === CAR_FILE
  const isB2bCarFile = path === B2B_CAR_FILE
  const isCarShareCarFile = path === CAR_SHARE_CAR_FILE

  const isCarFile = isRegularCarFile || isB2bCarFile || isCarShareCarFile

  return {
    isCarFile,
    isRegularCarFile,
    isB2bCarFile,
    isCarShareCarFile,
  }
}
