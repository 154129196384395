import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'components/atoms/new-tooltip'
import Icon from 'components/atoms/icon'
import { useTranslation } from 'react-i18next'

const FieldNotMappedTooltip = ({
  fieldName,
  iconType,
  iconSize,
  iconColor,
  children,
  ...restProps
}) => {
  const { t } = useTranslation()
  return (
    <Tooltip
      placement="top-end"
      arrow
      title={t('carFileFieldNotKnown', { fieldName })}
      width={350}
      {...restProps}
    >
      <Icon type={iconType} size={iconSize} color={iconColor} />
      {children}
    </Tooltip>
  )
}

FieldNotMappedTooltip.propTypes = {
  fieldName: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

FieldNotMappedTooltip.defaultProps = {
  iconType: 'info',
  iconColor: 'stateNegative',
  iconSize: 'md',
}

export default FieldNotMappedTooltip
