import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components/macro'
import { icons } from '../../config'

const Container = styled.span`
  font-size: 0;
  line-height: 1;
  height: ${({ height, width }) => height || width}px;
  width: ${({ width }) => width}px;
  display: ${({ $isInlined }) => ($isInlined ? 'inline-block' : 'block')};
  ${({ $isInlined }) =>
    $isInlined &&
    `
    vertical-align: middle;
    position: relative;
    top: -1px;
  `}
`

const StyledPlaceholder = styled.div`
  background-color: pink;
  border: 1px solid red;
  height: ${({ height, width }) => height || width}px;
  width: ${({ width }) => width}px;
`

const StyledSvg = styled.svg`
  pointer-events: none;
`

/**
 * Displays all custom designed icons for the UCC Design System
 */
function Icon({
  color,
  className,
  height,
  size,
  title,
  type,
  width,
  isInlined,
}) {
  const theme = useContext(ThemeContext)
  let tshirtSize

  if (size) {
    tshirtSize = {
      sm: '10',
      md: '16',
      lg: '24',
    }[size]
  }

  if (!icons[type]) {
    return (
      <StyledPlaceholder
        className={className}
        height={height || tshirtSize}
        width={width || tshirtSize}
      />
    )
  }

  return (
    <Container
      $isInlined={isInlined}
      className={className}
      height={height || tshirtSize}
      width={width || tshirtSize}
    >
      <StyledSvg
        aria-label={title}
        height={height || tshirtSize}
        preserveAspectRatio="xMidYMid meet"
        viewBox={icons[type].viewbox}
        width={width || tshirtSize}
        xmlns="http://www.w3.org/2000/svg"
        color={!icons[type].usePredefinedFills && theme.colors[color]}
        fill={!icons[type].usePredefinedFills && theme.colors[color]}
        stroke={!icons[type].usePredefinedFills && theme.colors[color]}
        dangerouslySetInnerHTML={{ __html: icons[type].content }}
      />
    </Container>
  )
}

export const propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  width: PropTypes.string,
  isInlined: PropTypes.bool,
}

Icon.propTypes = propTypes

Icon.defaultProps = {
  className: null,
  color: 'brandAlfa',
  height: '100%',
  size: 'lg',
  title: null,
  width: null,
  isInlined: false,
}

export default Icon
