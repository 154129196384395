import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { ThemeContext } from 'styled-components'

/**
 * This component allows a user to easily compare a set of items.
 * It uses a bar chart to achieve this. This is also why all the values have the same unit.
 *
 */
const ValueComparisonChart = ({ className, itemsToCompare, noOfTicks }) => {
  const [convertedData, setConvertedData] = useState({})
  const [maxValue, setMaxValue] = useState(0)
  const themeContext = useContext(ThemeContext)
  const barThickness = themeContext.baseSize * 6
  const borderWidth = 0

  useEffect(() => {
    const colors = itemsToCompare.map((item) => themeContext.colors[item.color])
    setConvertedData({
      labels: itemsToCompare.map((item) => item.label.split('\n')),
      datasets: [
        {
          barThickness,
          borderWidth,
          backgroundColor: colors,
          // convert hex colour to a semi-transparent version of it.
          hoverBackgroundColor: colors.map((hex) => `${hex}aa`),
          data: itemsToCompare.map((item) => item.value),
        },
      ],
    })
    setMaxValue(Math.max(...itemsToCompare.map((item) => item.value)))
  }, [barThickness, itemsToCompare, themeContext.colors])

  return (
    <section className={className}>
      <Bar
        data={convertedData}
        options={{
          responsive: true,
          aspectRatio: 1,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          plugins: {
            datalabels: {
              display: false, // By default hide the data labels
            },
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  beginAtZero: true,
                  // reduce the amount of ticks, making the chart look cleaner
                  // + 10% extra breathing room (maxValue / 10)
                  stepSize: Math.round((maxValue + maxValue / 10) / noOfTicks),
                },
              },
            ],
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        }}
      />
    </section>
  )
}
ValueComparisonChart.propTypes = {
  /**
   * An array of items you want to display in the chart
   */
  itemsToCompare: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    }),
  ).isRequired,
  /**
   * Number of ticks (horizontal lines) you want to shown in the chart
   */
  noOfTicks: PropTypes.number,
  className: PropTypes.string,
}

ValueComparisonChart.defaultProps = {
  noOfTicks: 5,
  className: null,
}

export default ValueComparisonChart
