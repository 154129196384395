import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { formatPercentage } from 'utilities/format'
import { useLocation } from 'react-router-dom'

import ContentSeparator from 'components/atoms/content-separator'
import Link from 'components/atoms/link'
import Icon from 'components/atoms/icon'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Text from 'components/atoms/text'
import ExpandableBars from 'components/layouts/expandable-bars'
import Labels from 'components/molecules/labels'
import Typography from 'components/molecules/typography'
import ChecklistDetails from 'components/views/checklists/checklist-details'

import { CarFileStatusEnumUpperCase } from 'config/enums'
import { CHECKLISTS_FOR_STATUS } from 'config/graphql/v4'

import { ROW_INDEX_PARAM } from './checklists-dashboard-widget'

const StyledExpandableBarsContainer = styled.div`
  padding-top: ${({ theme }) => theme.sizings.lvl2};

  li:first-of-type {
    border-top: solid 1px ${({ theme }) => theme.colors.darkOnLightBorder};
  }

  li {
    width: calc(100% - ${({ theme }) => theme.sizings.lvl4});
    margin-left: auto;
  }

  header {
    padding-left: 0;
  }
`

const StyledExpandableBars = styled(ExpandableBars)`
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.brandDelta};

  :last-child {
    border-bottom: 0 none;
  }
`

const ChecklistHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > * {
    flex-shrink: 0;
  }
`

const Name = styled(Text)`
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};
  margin-right: ${({ theme }) => theme.sizings.lvl2};
`

const StatsContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const HeaderText = styled(Typography)`
  margin-top: 0;
  margin-bottom: 0;
`

const HeaderSeparator = styled(ContentSeparator)`
  align-self: stretch;
  height: auto;
`

const StyledLink = styled(Link)`
  & > * {
    color: ${({ $color, theme }) =>
      theme.colors[$color] || theme.colors.actionsStandard};
  }
`

const ChecklistRow = styled.div`
  padding: ${({ theme }) => theme.sizings.lvl2} 0
    ${({ theme, $isExpanded }) => ($isExpanded ? 0 : theme.sizings.lvl2)} 0;
  border-top: solid 1px ${({ theme }) => theme.colors.darkOnLightBorder};

  &:last-of-type:not(:last-child) {
    border-bottom: solid 1px ${({ theme }) => theme.colors.darkOnLightBorder};
  }
`

const ChecklistsHeader = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > * {
    flex-shrink: 0;
  }
`

const DropdownIconWrapper = styled.div`
  flex-shrink: 0;
  align-self: center;
  cursor: pointer;
  padding-right: ${({ theme }) => theme.sizings.lvl0};
`

const DropdownIcon = styled(Icon)`
  transform: ${({ $isExpanded }) => `rotate(${$isExpanded ? '180' : '0'}deg)`};
`

const ChecklistsAccordion = ({
  status,
  locationId,
  onNameClick,
  onRowExpandCollapseClick,
  onTotalCarsClick,
  onDelayedCarsClick,
  onItemTotalCarsClick,
  onItemDelayedCarsClick,
  expandedChecklistRowIndex,
  setExpandedChecklistRowIndex,
  onExpandableChecklistRowClick,
  ...restProps
}) => {
  const location = useLocation()
  const { t } = useTranslation()
  const { data, loading, error } = useQuery(CHECKLISTS_FOR_STATUS, {
    variables: {
      status,
      locationId,
    },
  })

  // Reset the expanded state when the component is mounted and there's no URL parameter, this happens when changing tabs
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search.replace(/^\?/, ''))

    if (!searchParams.get(ROW_INDEX_PARAM)) {
      setExpandedChecklistRowIndex(null)
    }
  }, [location.search, setExpandedChecklistRowIndex])

  if (loading || error) {
    return <LoadingIndicator error={error ? t('genericError') : undefined} />
  }

  const isLocationOverview = locationId === '0'
  const duplicateRows = []
  const widgetDataIsArray = Array.isArray(data.flexFlowWidgetByStatus)
  const widgetData = data?.flexFlowWidgetByStatus || []

  if (isLocationOverview && widgetDataIsArray) {
    for (const item of widgetData) {
      if (item.checklist.parent === null) {
        continue
      }

      const isDuplicate =
        widgetData
          .filter((obj) => obj.checklist.parent !== null)
          .filter((obj) => obj.checklist.parent.id === item.checklist.parent.id)
          ?.length > 1

      if (isDuplicate) {
        duplicateRows.push(item)
      }
    }
  }

  const mapExpandableRow = (item, showCompanyName) => {
    const { checklist, delayed, delayedPercentage, total } = item
    const totalCars = (
      <HeaderText key="total" type="BodyParagraph">
        <strong>{total}</strong> {t(total === 1 ? 'car' : 'cars').toLowerCase()}
      </HeaderText>
    )
    const delayedCars = (
      <HeaderText type="BodyParagraph">
        <strong>{delayed}</strong>{' '}
        {t(delayed === 1 ? 'carWithDelay' : 'carsWithDelay').toLowerCase()} (
        {formatPercentage(delayedPercentage, 1, true)})
      </HeaderText>
    )

    return {
      header: (
        <ChecklistHeader>
          <Name
            type="tabSmall"
            data-test-e2e="checklist-name"
            onClick={(e) => {
              onNameClick && onNameClick(item, e)
            }}
            $isClickable={typeof onNameClick === 'function'}
          >
            {checklist.name}
          </Name>
          <Labels
            texts={checklist.soort.map((soort) => t(`carKinds.${soort.soort}`))}
            includeWrapper={false}
            gapLevel={0}
          />
          <StatsContainer>
            {showCompanyName && (
              <>
                <HeaderText color="inactiveTextColor" type="BodyParagraph">
                  {checklist.company.name}
                </HeaderText>
                <HeaderSeparator variant="vertical" paddingLevel={2} />
              </>
            )}
            {typeof onTotalCarsClick === 'function' ? (
              <StyledLink
                onClick={(e) => {
                  onTotalCarsClick(item, e)
                }}
              >
                {totalCars}
              </StyledLink>
            ) : (
              totalCars
            )}
            <HeaderSeparator variant="vertical" paddingLevel={2} />
            {typeof onDelayedCarsClick === 'function' ? (
              <StyledLink
                onClick={(e) => {
                  onDelayedCarsClick(item, e)
                }}
              >
                {delayedCars}
              </StyledLink>
            ) : (
              delayedCars
            )}
            <HeaderSeparator variant="vertical" paddingLevel={2} />
          </StatsContainer>
        </ChecklistHeader>
      ),
      id: checklist.id,
      details: (
        <ChecklistDetails
          id={checklist.id}
          onTotalCarsClick={onItemTotalCarsClick}
          onDelayedCarsClick={onItemDelayedCarsClick}
        />
      ),
      onRowClick: (data) => {
        typeof onRowExpandCollapseClick === 'function' &&
          onRowExpandCollapseClick({ id: checklist.id, ...data })
      },
    }
  }

  const rows = widgetDataIsArray
    ? widgetData.map((item) => mapExpandableRow(item))
    : []

  // When it is the "all locations" overview and there are rows with duplicate names, wrap the duplicate ones in a dropdown
  if (isLocationOverview && duplicateRows.length > 0) {
    const mergedComponents = []
    const mergedChecklistNames = []

    // Loop over all items, but filter duplicate ones
    for (const item of widgetData) {
      const rowIndex = Number(item.checklist.id)

      // If this item doesn't have a parent, add it to the list
      if (item.checklist.parent === null) {
        mergedComponents.push(
          <StyledExpandableBars
            {...restProps}
            key={`checklist-row-${rowIndex}`}
            rows={[mapExpandableRow(item)]}
            fullRowIsClickable={false}
          />,
        )

        // Continue with the next item
        continue
      }

      // If this item was already merged, continue the loop
      if (mergedChecklistNames.includes(item.checklist.parent.id)) {
        continue
      }

      // Check if this item is in the duplicateRows array
      const isInDuplicateRowsArray = duplicateRows.find(
        (obj) => obj.checklist.parent.id === item.checklist.parent.id,
      )

      // If it is in the duplicateRows array, collect all the rows with this checklist.parent.id and add a wrapper around them
      if (isInDuplicateRowsArray && item.checklist.parent !== null) {
        const filteredRows = widgetData
          .filter((obj) => obj.checklist.parent !== null)
          .filter(
            (itemToMerge) =>
              itemToMerge.checklist.parent.id === item.checklist.parent.id,
          )
        const mergedRows = filteredRows.map((itemToMap) =>
          mapExpandableRow(itemToMap, true),
        )

        // Push this items' checklist name so we don't go over these items again
        mergedChecklistNames.push(item.checklist.parent.id)

        // Calculate the totals
        const totalCars = filteredRows.reduce(
          (prev, current) => prev + current.total,
          0,
        )
        const totalCarsWithDelay = filteredRows.reduce(
          (prev, current) => prev + current.delayed,
          0,
        )
        const totalPercentage =
          filteredRows.reduce(
            (prev, current) => prev + current.delayedPercentage,
            0,
          ) / filteredRows.length
        const mergedLabels = [
          ...new Set(
            filteredRows.reduce(
              (prev, current) => [
                ...prev,
                ...current.checklist.soort.map((soort) =>
                  t(`carKinds.${soort.soort}`),
                ),
              ],
              [],
            ),
          ),
        ]

        // Add the filtered items to the merged components array
        mergedComponents.push(
          <ChecklistRow
            key={`checklist-row-${rowIndex}`}
            $isExpanded={expandedChecklistRowIndex === rowIndex}
          >
            <ChecklistsHeader>
              <DropdownIconWrapper
                onClick={(e) => {
                  e.preventDefault()
                  onExpandableChecklistRowClick(rowIndex)
                }}
              >
                <DropdownIcon
                  size="md"
                  type="arrowDropDown"
                  $isExpanded={expandedChecklistRowIndex === rowIndex}
                />
              </DropdownIconWrapper>
              <Name
                type="tabSmall"
                onClick={(e) => {
                  e.preventDefault()
                  onExpandableChecklistRowClick(rowIndex)
                }}
              >
                {item.checklist.parent.name}
              </Name>
              <Labels
                texts={mergedLabels}
                includeWrapper={false}
                gapLevel={0}
              />
              <StatsContainer>
                <HeaderText color="inactiveTextColor" type="BodyParagraph">
                  <strong>{totalCars}</strong>{' '}
                  {t(totalCars === 1 ? 'car' : 'cars').toLowerCase()}
                </HeaderText>
                <HeaderSeparator variant="vertical" paddingLevel={2} />
                <HeaderText color="inactiveTextColor" type="BodyParagraph">
                  <strong>{totalCarsWithDelay}</strong>{' '}
                  {t(
                    totalCarsWithDelay === 1 ? 'carWithDelay' : 'carsWithDelay',
                  ).toLowerCase()}{' '}
                  ({formatPercentage(totalPercentage, 1, true)})
                </HeaderText>
              </StatsContainer>
            </ChecklistsHeader>
            {expandedChecklistRowIndex === rowIndex && (
              <StyledExpandableBarsContainer>
                <StyledExpandableBars
                  {...restProps}
                  rows={mergedRows}
                  fullRowIsClickable={false}
                />
              </StyledExpandableBarsContainer>
            )}
          </ChecklistRow>,
        )
      }

      // If it is not in the duplicateRows array, display the StyledExpandableBars
      if (!isInDuplicateRowsArray) {
        mergedComponents.push(
          <StyledExpandableBars
            {...restProps}
            key={`checklist-row-${rowIndex}`}
            rows={[mapExpandableRow(item)]}
            fullRowIsClickable={false}
          />,
        )
      }
    }

    return mergedComponents || null
  }

  // When it is not the "all locations" overview, or when it is the "all locations" overview but there's no duplicate rows, display the regular expandable bars
  if (
    !isLocationOverview ||
    (isLocationOverview && duplicateRows.length === 0)
  ) {
    return (
      <StyledExpandableBars
        {...restProps}
        rows={rows}
        fullRowIsClickable={false}
      />
    )
  }
}

ChecklistsAccordion.propTypes = {
  status: PropTypes.oneOf(Object.values(CarFileStatusEnumUpperCase)).isRequired,
  locationId: PropTypes.string,
  onNameClick: PropTypes.func, // When the name of a checklist is clicked
  onRowExpandCollapseClick: PropTypes.func, // When a row opens/closes
  onTotalCarsClick: PropTypes.func, // What happens if a user clicks the total number of cars on the flexflow level? Function recieves 2 arguments: onTotalCarsClick(result: WidgetByStatusResult, e: MouseEvent)
  onDelayedCarsClick: PropTypes.func, // What happens if a user clicks the number of delayed cars on the flexflow level? Function recieves 2 arguments: onDelayedCarsClick(result: WidgetByStatusResult, e: MouseEvent)
  onItemTotalCarsClick: PropTypes.func, // What happens if a user clicks the total number of cars on the flexflow item level (this prop will be passed to the ChecklistDetails component)? Function recieves 2 arguments: onItemTotalCarsClick(result: WidgetByChecklistResult, e: MouseEvent)
  onItemDelayedCarsClick: PropTypes.func, // What happens if a user clicks the number of delated cars on the flexflow item level (this prop will be passed to the ChecklistDetails component)? Function recieves 2 arguments: onItemDelayedCarsClick(result: WidgetByChecklistResult, e: MouseEvent)
  expandedChecklistRowIndex: PropTypes.number, // The index of the expanded row in the accordion on the dashboard when "alle bedrijven" is selected
  setExpandedChecklistRowIndex: PropTypes.func, // The state setter for expandedChecklistRowIndex
  onExpandableChecklistRowClick: PropTypes.func, // What happens if a user clicks the chevron in the accordion on the dashboard when "alle bedrijven" is selected
}

export default ChecklistsAccordion
