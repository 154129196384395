import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Text from 'components/atoms/text'
// eslint-disable-next-line import/no-cycle
import MenuItem from 'components/molecules/menu-item'
import { formatDataTestE2eAttr } from 'utilities/format'

const Container = styled.div`
  background-color: ${({ $backgroundColor, theme }) =>
    theme.colors[$backgroundColor]};
`

const Title = styled(Text)`
  padding: ${({ theme }) => theme.sizeByFactor(2)}
    ${({ theme }) => theme.sizeByFactor(4)};
  text-transform: uppercase;
`

function Menu({
  backgroundColor,
  closeMenu,
  color,
  items,
  title,
  variant,
  hasMobileLayout,
  ...restProps
}) {
  return (
    <Container
      $backgroundColor={backgroundColor}
      variant={variant}
      {...restProps}
    >
      {title && <Title type="bodyLarge" text={title} color={color} />}
      {items.map((item, key) => (
        <MenuItem
          key={key.toString()}
          backgroundColor={backgroundColor}
          closeMenu={closeMenu}
          color={color}
          {...item}
          variant={variant}
          hasMobileLayout={hasMobileLayout}
          data-test-e2e={formatDataTestE2eAttr('menu-item', item.label)}
        />
      ))}
    </Container>
  )
}

Menu.propTypes = {
  backgroundColor: PropTypes.string,
  closeMenu: PropTypes.func,
  color: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
      to: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.node,
  variant: PropTypes.oneOf(['popup', 'main']),
  hasMobileLayout: PropTypes.bool,
}

Menu.defaultProps = {
  backgroundColor: 'brandGolf',
  closeMenu: null,
  color: undefined,
  title: null,
  variant: 'main',
  hasMobileLayout: false,
}

export default Menu
