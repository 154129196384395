import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SlickCarousel from 'react-slick'
import styled from 'styled-components/macro'
import Button from 'components/atoms/button'

const MAX_DOTS_COUNT = 5
const HALF_MAX_DOTS_COUNT = Math.floor(MAX_DOTS_COUNT / 2)

const StyledCarousel = styled(SlickCarousel)`
  width: 100%;

  .slick-dots {
    bottom: 2em;

    li {
      display: inline-flex;
    }

    li:empty {
      display: none;
    }

    div {
      margin: auto;
    }

    .slick-active div {
      background-color: ${({ theme }) => theme.colors.actionsStandard};
      opacity: 1;
    }
  }

  .slick-list,
  .slick-track {
    &,
    & div {
      height: ${({ fullHeight }) => (fullHeight ? '100%' : '')};
    }
  }
`

const StyledArrow = styled(Button)`
  margin: 0 16px;
  position: absolute;
  right: ${({ right }) => (right ? '0px' : '')};
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.defaultBackground};
`

function Arrow({ onClick, right }) {
  return (
    <StyledArrow
      icon={right ? 'toRight' : 'toLeft'}
      level="minimal"
      onClick={onClick}
      right={right}
    />
  )
}

Arrow.propTypes = {
  onClick: PropTypes.func,
  right: PropTypes.bool,
}

Arrow.defaultProps = {
  onClick: null,
  right: false,
}

const StyledDot = styled.div`
  background-color: ${({ theme }) => theme.colors.brandGolf};
  border-radius: 8px;
  height: 8px;
  opacity: 1;
  width: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.actionsStandard};
  }
`

function Dot({ currentSlideIndex, index, onClick, totalDots }) {
  const minVisibleIndex = currentSlideIndex - HALF_MAX_DOTS_COUNT
  const maxVisibleIndex = currentSlideIndex + HALF_MAX_DOTS_COUNT
  const minOffset =
    maxVisibleIndex >= totalDots ? maxVisibleIndex - (totalDots - 1) : 0
  const maxOffset = minVisibleIndex < 0 ? minVisibleIndex * -1 : 0
  const hide =
    index < minVisibleIndex - minOffset || index > maxVisibleIndex + maxOffset

  if (hide) {
    return null
  }

  return <StyledDot index={index} onClick={onClick} />
}

Dot.propTypes = {
  currentSlideIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  totalDots: PropTypes.number.isRequired,
}

Dot.defaultProps = {
  onClick: null,
}

function Carousel({ className, children, ...restProps }) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const defaultSettings = {
    adaptiveHeight: true,
    customPaging: (index) => (
      <Dot
        currentSlideIndex={currentSlideIndex}
        index={index}
        // eslint-disable-next-line react/prop-types
        totalDots={children ? children.length : 0}
      />
    ),
    arrows: false,
    dots: true,
    dotsClass: 'slick-dots',
    infinite: true,
    nextArrow: <Arrow right />,
    prevArrow: <Arrow />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <StyledCarousel
      className={className}
      beforeChange={(oldIndex, newIndex) => setCurrentSlideIndex(newIndex)}
      {...defaultSettings}
      {...restProps}
    >
      {children}
    </StyledCarousel>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Carousel.defaultProps = {
  className: null,
}

export default Carousel
