import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { arrayMove } from 'react-sortable-hoc'
import styled, { ThemeContext } from 'styled-components/macro'
import { useMediaLayout } from 'use-media'
import { useHistory } from 'react-router-dom'

import { ImageCategories } from 'config/enums'
import * as routes from 'config/routes'

import { toKebabCase } from 'utilities/format'
import { media } from 'utilities/styled'

import BodyHeading from 'components/atoms/body-heading'
import Button from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import NewDropdownSelect, {
  propTypes as newDropdownSelectPropTypes,
} from 'components/molecules/new-dropdown-select'
import SelectFileButton from 'components/molecules/select-file-button'
import { CameraSearchParamsEnum } from 'components/views/camera-view'

import { SortableGrid } from './media-form/grid'

function sortItems(items, newSortOrder) {
  // If the number length of items is not the same as the of newSortOrder
  // this function has been called after uploading a new asset and the
  // the items are freshly served from the backend with a correct sort
  // order so the newSortorder can be ignored
  if (!newSortOrder || items.length !== newSortOrder.length) {
    return items.sort((first, second) => first.position - second.position)
  }
  return items.sort((first, second) => {
    const firstPosition = newSortOrder.find(
      (item) => item.id === first.id,
    ).position
    const secondPosition = newSortOrder.find(
      (item) => item.id === second.id,
    ).position

    return firstPosition - secondPosition
  })
}

const Header = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 32px;

  ${media.tablet`
    display:grid;
    grid-template-areas: "header header"
    "left-aligned right-aligned";
  `}
`

const MainHeading = styled(BodyHeading)`
  grid-area: header;
`

const ActionsContainer = styled.div`
  grid-area: right-aligned;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 8px 16px;
  flex-wrap: wrap;

  ${media.tablet`
    flex-direction: row;
    margin-top: 0;
    width: auto;
  `}
`

const LeftAligned = styled.div`
  grid-area: left-aligned;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: start;
  margin-top: 24px;
  width: 100%;

  ${media.tablet`
  flex-direction: row;
  margin-top: 0;
  width: auto;
`}
`

const StyledNewDropdownSelect = styled(NewDropdownSelect)`
  width: ${(props) => props.theme.sizeByFactor(32)};
`

const DesktopAddButton = styled(SelectFileButton)`
  display: flex;
  margin-left: 0;

  ${media.tablet`
    margin-left: ${({ theme }) => theme.sizings.lvl2};
  `}
`
const MobileAddButton = styled(SelectFileButton)`
  display: flex;

  & label:active * {
    fill: ${({ theme }) => theme.colors.defaultBackground} !important;
  }
`

const RotateButton = styled(Button)`
  padding: 4px;
  > * {
    font-size: 30px;
  }
`

const SelectButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.sizings.lvl4};
`

const StyledActionBarButton = styled(Button)`
  padding-left: ${({ theme }) => theme.sizings.lvl1};
  padding-right: ${({ theme }) => theme.sizings.lvl1};
`

export const IMAGE_FILE_EXTENSIONS = [
  '.jpg',
  '.JPG',
  '.jpeg',
  '.JPEG',
  '.png',
  '.PNG',
]
export const DOCUMENT_FILE_EXTENSIONS = [
  '.doc',
  '.docx',
  '.pdf',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
]

/**
 * The MediaForm is a multfunctional tool to help upload and manage media files.
 */
function MediaForm({
  carAssetsSort,
  className,
  carId,
  disabled,
  noCustomCamera,
  onUpload,
  onChange,
  onSelect,
  onDelete,
  onSort,
  items,
  level,
  onRotate,
  single,
  title,
  type,
  allowImageAsDocumentUpload,
  showLabelsOnImages,
  uploadButtonText,
  exclusiveSelect,
  itemsFilter,
  itemsFilterValue,
  customOptions,
  enableReinitialize,
  readOnly,
  isValuation,
  ...restProps
}) {
  const history = useHistory()
  const newSortOrder =
    carAssetsSort && carAssetsSort.payload && carAssetsSort.payload.sortOrder
  const previousItems = useRef(items)
  const [itemsList, setItemsList] = useState(
    items ? sortItems(items, newSortOrder) : [],
  )
  const { t } = useTranslation()
  const [selectedImageIds, setSelectedImageIds] = useState(
    items && items.length
      ? items.filter((item) => item.selected).map((item) => item.id)
      : [],
  )
  const [sortingImageId, setSortingImageId] = useState(null)
  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.desktop })
  const getUserMediaSupported = window.Modernizr.getusermedia
  const customCamera =
    isMobile && getUserMediaSupported && type !== 'documents' && !noCustomCamera

  const allowedFileExtensions =
    type === 'images'
      ? IMAGE_FILE_EXTENSIONS // Only image uploads
      : allowImageAsDocumentUpload
        ? [...IMAGE_FILE_EXTENSIONS, ...DOCUMENT_FILE_EXTENSIONS] // Allow photos as document uploads
        : DOCUMENT_FILE_EXTENSIONS // Only documents

  useEffect(() => {
    if (
      items &&
      (previousItems.current.length !== items.length || // if items have been added
        previousItems.current.some((previousItem, prevIndex) => {
          // if items are different
          return previousItem.src !== items[prevIndex].src
        }))
    ) {
      previousItems.current = items
      setItemsList(sortItems(items))
      if (enableReinitialize) {
        setSelectedImageIds([])
      }
    }
  }, [items, enableReinitialize])

  useEffect(() => {
    if (onChange) {
      onChange(selectedImageIds)
    }
  }, [selectedImageIds, onChange])

  const buttonTitle =
    uploadButtonText || (type === 'images' ? t('addFotos') : t('addDocuments'))

  function handleSelectAll() {
    setSelectedImageIds(items.map((item) => item.id))
  }

  function handleDeselectAll() {
    setSelectedImageIds([])
  }

  function toggleSelection(index) {
    if (exclusiveSelect) {
      setSelectedImageIds([index])
      return
    }

    if (selectedImageIds.indexOf(index) === -1) {
      const newSelectedImageIds = [...selectedImageIds, index]
      setSelectedImageIds(newSelectedImageIds)
    } else {
      const newSelectedImageIds = selectedImageIds.filter(
        (selectedIndex) => selectedIndex !== index,
      )
      setSelectedImageIds(newSelectedImageIds)
    }
  }

  function onSortEnd({ oldIndex, newIndex }) {
    setSortingImageId(null)

    if (oldIndex === newIndex) {
      return
    }
    const newItemsList = arrayMove(itemsList, oldIndex, newIndex)
    const newSortOrderData = newItemsList.map((item, index) => ({
      id: item.id,
      position: index,
    }))
    setItemsList(newItemsList)
    onSort(newSortOrderData)
  }
  const addButtonId = type === 'images' ? 'addFotos' : 'addDocs'

  function handleSortStart({ index }) {
    setSortingImageId(index)
  }

  function handleDelete() {
    onDelete(selectedImageIds)
    setSelectedImageIds([])
  }

  function handleSelect() {
    onSelect(selectedImageIds)
    setSelectedImageIds([])
  }

  function handleView(url) {
    window.open(url, '_blank')
  }

  function handleChange(formData) {
    if (!formData || !onUpload) {
      return
    }

    onUpload(formData)
  }

  function handleRotate(degrees) {
    onRotate(selectedImageIds, degrees)
  }

  function handleCameraButtonClick() {
    let searchParamsObj = {
      [CameraSearchParamsEnum.CAR_ID]: carId,
      [CameraSearchParamsEnum.ASSETS_COUNT]: items ? items.length : 0,
      [CameraSearchParamsEnum.REFERRER_URL]: `${history.location.pathname}${history.location.search}${window.location.hash}`,
      [CameraSearchParamsEnum.REFERRER_IMAGE_CATEGORY]:
        window.location.hash.replace('#', ''), // Should be "original" or "intern"
    }

    if (isValuation) {
      searchParamsObj = {
        ...searchParamsObj,
        [CameraSearchParamsEnum.REFERRER_IMAGE_CATEGORY]:
          ImageCategories.INTERNAL, // Used in the Camera component
      }
    }

    const searchParams = new URLSearchParams(searchParamsObj)

    history.push({
      pathname: routes.CAMERA,
      search: searchParams.toString(),
    })
  }

  if (!itemsList) {
    return <LoadingIndicator />
  }
  return (
    <div className={className} data-test-e2e="media-form" {...restProps}>
      {(title || !single || onDelete || onUpload) && (
        <Header data-test-e2e="header">
          <MainHeading level="2" styledAs="oldheading" data-test-e2e="heading">
            {title && title}

            {!single &&
              `${itemsList.length} ${t('files', {
                count: itemsList.length,
              })}`}
          </MainHeading>
          <LeftAligned>
            {itemsFilter && itemsFilterValue && (
              <StyledNewDropdownSelect
                disabled={itemsFilter?.disabled}
                label={itemsFilter.label}
                onChange={(value) => {
                  setSelectedImageIds([])
                  itemsFilter.onChange(value)
                }}
                items={itemsFilter.filterOptions}
                value={itemsFilterValue}
                selectionRequired
                id="items-filter"
                data-test-e2e="items-filter"
              />
            )}
          </LeftAligned>

          {!readOnly && (
            <ActionsContainer data-test-e2e="actions-container">
              {customOptions.length > 0 &&
                customOptions.map((customAction, index) => (
                  <StyledActionBarButton
                    key={index.toString()}
                    onClick={customAction.handler}
                    level="option"
                    disabled={
                      !selectedImageIds || selectedImageIds.length === 0
                    }
                    data-test-e2e={`button-custom-${toKebabCase(customAction.buttonText)}`}
                  >
                    {customAction.buttonText}
                  </StyledActionBarButton>
                ))}
              {onSelect && (
                <Button
                  disabled={
                    !selectedImageIds ||
                    selectedImageIds.length === 0 ||
                    disabled
                  }
                  onClick={handleSelect}
                >
                  {uploadButtonText}
                </Button>
              )}
              {(onDelete || onUpload) && (
                <>
                  {onDelete &&
                    selectedImageIds &&
                    selectedImageIds.length > 0 && (
                      <>
                        <StyledActionBarButton
                          level="option"
                          onClick={handleDelete}
                          disabled={disabled}
                          data-test-e2e="button-delete"
                        >
                          {t('delete')}
                        </StyledActionBarButton>
                        {type === 'images' && onRotate && (
                          // <RotateButtonsContainer>
                          <>
                            <RotateButton
                              text="&#10226;"
                              level="option"
                              disabled={disabled}
                              onClick={() => handleRotate('L90')}
                              data-test-e2e="button-rotate-left"
                            />
                            <RotateButton
                              text="&#10227;"
                              level="option"
                              iconSize="lg"
                              disabled={disabled}
                              onClick={() => handleRotate('R90')}
                              data-test-e2e="button-rotate-right"
                            />
                          </>
                          // </RotateButtonsContainer>
                        )}
                      </>
                    )}

                  {onUpload && (
                    <>
                      {isMobile ? (
                        <>
                          <MobileAddButton
                            allowedFileExtensions={allowedFileExtensions}
                            disabled={disabled}
                            existingAssetsCount={items ? items.length : 0}
                            icon="mediaFile"
                            id={addButtonId}
                            level="standard"
                            multiple={!single}
                            onChange={handleChange}
                            text={customCamera ? t('fromFile') : buttonTitle}
                            data-test-e2e="button-add-photo-mobile"
                          />
                          {customCamera && (
                            <Button
                              icon="mediaPhoto"
                              text={t('bulkPhotos')}
                              onClick={handleCameraButtonClick}
                              data-test-e2e="button-bulk-photos-mobile"
                            />
                          )}
                        </>
                      ) : (
                        <DesktopAddButton
                          allowedFileExtensions={allowedFileExtensions}
                          disabled={disabled}
                          existingAssetsCount={items ? items.length : 0}
                          id={addButtonId}
                          onChange={handleChange}
                          level={level}
                          multiple={!single}
                          text={buttonTitle}
                          data-test-e2e="button-add-photo"
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </ActionsContainer>
          )}
        </Header>
      )}

      {items &&
        !!items.length &&
        (onDelete || onRotate || onSelect || onChange) &&
        !exclusiveSelect &&
        !readOnly && (
          <SelectButtonsContainer>
            <Button
              level="option"
              noPadding
              onClick={handleSelectAll}
              disabled={selectedImageIds.length === items.length || disabled}
              text={t('selectAll')}
            />
            <Button
              level="option"
              noPadding
              onClick={handleDeselectAll}
              disabled={!selectedImageIds.length || disabled}
              text={t('deselectAll')}
            />
          </SelectButtonsContainer>
        )}

      <SortableGrid
        axis="xy"
        disabled={!onSort || type === 'documents' || disabled}
        clickDisabled={disabled}
        items={itemsList}
        showLabelsOnImages={showLabelsOnImages}
        onSelect={onDelete || onSelect || onChange}
        updateBeforeSortStart={handleSortStart}
        onSortEnd={onSortEnd}
        onView={handleView}
        pressDelay={isMobile ? 200 : 0}
        selectedImageIds={selectedImageIds}
        sortingImageId={sortingImageId}
        t={t}
        toggleSelection={toggleSelection}
        exclusiveSelect={exclusiveSelect}
        data-test-e2e="sortable-grid"
      />
    </div>
  )
}

export const propTypes = {
  carAssetsSort: PropTypes.object,
  className: PropTypes.string,
  carId: PropTypes.string,
  disabled: PropTypes.bool,
  /** Level of submit button */
  level: PropTypes.string,
  noCustomCamera: PropTypes.bool,
  /** Allows callback to when an image is selected */
  onChange: PropTypes.func,
  /** Allows callback to upload new images */
  onUpload: PropTypes.func,
  /** Allows deletion callback of current images */
  onDelete: PropTypes.func,
  /** Allows selection callback of images when images selected and confirm button pressed */
  onSelect: PropTypes.func,
  /** Allows dragging and sorting of files in files array */
  onSort: PropTypes.func,
  items: PropTypes.array,
  onRotate: PropTypes.func,
  /**
   * Set this to true is you only want to upload a single file per upload:
   */
  single: PropTypes.bool,
  title: PropTypes.string,
  /**
   * Labels on images only need to be shown sometimes, not every time.
   */
  showLabelsOnImages: PropTypes.bool,
  type: PropTypes.oneOf(['images', 'documents']).isRequired,
  allowImageAsDocumentUpload: PropTypes.bool,
  uploadButtonText: PropTypes.string,
  /** Only allows one media element to be selected at a time */
  exclusiveSelect: PropTypes.bool,
  /**
   * collated set of itemsFilter parameters:
   * NOTE: is dependent on itemsFilterValue to be used too.
   */
  itemsFilter: PropTypes.shape({
    onChange: newDropdownSelectPropTypes.onChange,
    label: newDropdownSelectPropTypes.label,
    filterOptions: newDropdownSelectPropTypes.items,
    disabled: newDropdownSelectPropTypes.disabled,
  }),
  /**
   * Kept itemsFilterValue out of itemsFilter, because it updates all the time:
   * NOTE: is dependent on itemsFilter to be set:
   */
  itemsFilterValue: PropTypes.string,
  customOptions: PropTypes.arrayOf(
    PropTypes.shape({
      handler: PropTypes.func.isRequired,
      buttonText: PropTypes.string.isRequired,
    }).isRequired,
  ),
  enableReinitialize: PropTypes.bool, // Reset the selection of images if the array of images changes?
  readOnly: PropTypes.bool,
  isValuation: PropTypes.bool,
}

MediaForm.propTypes = propTypes

MediaForm.defaultProps = {
  carId: null,
  carAssetsSort: null,
  className: null,
  disabled: false,
  level: 'cta',
  noCustomCamera: false,
  onUpload: undefined,
  onChange: null,
  onDelete: null,
  onSelect: null,
  onRotate: null,
  onSort: null,
  items: null,
  single: false,
  title: null,
  showLabelsOnImages: false,
  allowImageAsDocumentUpload: false,
  uploadButtonText: null,
  exclusiveSelect: false,
  itemsFilter: null,
  itemsFilterValue: null,
  customOptions: [],
  enableReinitialize: false,
  readOnly: false,
  isValuation: false,
}

export default MediaForm
