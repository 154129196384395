import { gql } from '@apollo/client'

export const APP_TOKENS = gql`
  query appTokens($dealerId: ID!) {
    appTokens(dealerId: $dealerId) {
      id
      token
    }
  }
`

export const CREATE_APP_TOKEN = gql`
  mutation createAppToken($dealerId: ID!) {
    createAppToken(dealerId: $dealerId) {
      token
      id
    }
  }
`
