/* eslint-disable camelcase */

import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getObjectFromQueryString } from 'utilities/utils'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import CarEntryStep1 from 'components/organisms/car-entry-step1'
import CarEntryStep1NoLicense from 'components/organisms/car-entry-step1-no-license'
import CarEntryStep1Manual from 'components/organisms/car-entry-step1-manual'
import CarEntryStep2 from 'components/organisms/car-entry-step2'

function CarEntryView({
  createCarfile,
  location,
  match,
  newCar,
  updateCarfile,
  ...restProps
}) {
  const { t } = useTranslation()
  const { step } = match.params
  const query = getObjectFromQueryString(location.search)
  const { kenteken, mileage } = query

  const prefilledLicensePlate = kenteken && kenteken !== 'null' ? kenteken : ''
  const prefilledMileage =
    mileage && mileage !== 'null' ? Number(mileage) : null

  return (
    <AppLayoutContainer pageTitle={t('carEntry')}>
      {
        {
          1: (
            <CarEntryStep1
              newCar={newCar}
              prefilledLicensePlate={prefilledLicensePlate}
              prefilledMileage={prefilledMileage}
              {...restProps}
            />
          ),
          2: (
            <CarEntryStep2
              createCarfile={createCarfile}
              newCar={newCar && newCar.data}
              updateCarfile={updateCarfile}
              {...restProps}
            />
          ),
          nolicenseplate: (
            <CarEntryStep1NoLicense
              car={newCar && newCar.data}
              newCar={newCar}
              {...restProps}
            />
          ),
          manual: (
            <CarEntryStep1Manual
              createCarfile={createCarfile}
              newCar={newCar && newCar.data}
              updateCarfile={updateCarfile}
              {...restProps}
            />
          ),
        }[step]
      }
    </AppLayoutContainer>
  )
}

CarEntryView.propTypes = {
  createCarfile: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  newCar: PropTypes.object,
  updateCarfile: PropTypes.func.isRequired,
}

CarEntryView.defaultProps = {
  newCar: null,
}

export default CarEntryView
