import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import Text from 'components/atoms/text'
import Typography from 'components/molecules/typography'

const Input = styled(Text)`
  display: block;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.licensePlate};
  border: none;
  outline: none;
  padding: 8px 0;
  text-align: center;
  ::placeholder {
    color: ${({ theme }) => theme.colors.licensePlatePlaceholder};
  }
`

const Error = styled(Typography)`
  display: block;
  margin-left: 12px;
  margin-top: ${({ theme }) => theme.sizings.lvl0};
`
/**
 * TODO: LicensePlate internally uses the deprecated Text component (which renders an input element).
 * Replace with a proper field component instead.
 */
function LicensePlateInput({ placeholder, error, ...restProps }) {
  const { t } = useTranslation()
  return (
    // The div wraps the error message and the field (like NewTextInput)
    // so the spacing between them cannot be affected by
    // external layout logic, which will mess up its internal layout.
    <div>
      <Input
        color="text"
        tag="input"
        placeholder={placeholder || t('licensePlate')}
        data-test-e2e="license-plate-input"
        {...restProps}
        type="h1" // type can be fed down from ComplexForm. This ensures that it will not be overruled.
      />
      {typeof error === 'string' && <Error type="ErrorText">{error}</Error>}
    </div>
  )
}

LicensePlateInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

LicensePlateInput.defaultProps = {
  className: null,
  disabled: null,
  name: null,
  onChange: null,
  onBlur: null,
  readOnly: false,
  value: '',
  placeholder: null,
  error: false,
}

export default LicensePlateInput
