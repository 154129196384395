import { connect } from 'react-redux'
import {
  getMarktplaats,
  getLocations,
  updateMarktplaatsFeatures,
  getMarktplaatsBulkActionStatus,
} from 'redux/actions/data'
import MarktplaatsForm from '../organisms/marktplaats-form'

const mapStateToProps = ({ data }) => ({
  marktplaatsData: data.marktplaats,
  locations: data.locations,
  marktplaatsBulkActionStatus: data.marktplaatsBulkActionStatus,
})

const mapDispatchToProps = {
  getMarktplaats,
  getLocations,
  updateMarktplaatsFeatures,
  getMarktplaatsBulkActionStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(MarktplaatsForm)
