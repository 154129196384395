import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { media } from 'utilities/styled'

import Icon from '../atoms/icon'
import ContentSeperator from '../atoms/content-separator'
import Typography from '../molecules/typography'
import Label from '../atoms/label'
import Button from '../atoms/button'
import { formatPrice } from 'utilities/format'
import PricingPerPeriod from 'components/molecules/pricing-per-period'

const PlanContainer = styled.section`
  position: relative;
  flex-basis: 100%;
  flex: 1;
  text-align: center;
  border: 1px solid
    ${({ theme, highlighted }) =>
      highlighted
        ? theme.colors.errorBorderActive
        : theme.colors.darkOnLightBorder};
  background-color: ${({ theme, highlighted }) =>
    highlighted ? 'transparent' : theme.colors.alternativeBackground};
  padding: ${({ theme }) => theme.sizings.lvl4};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media print {
    padding: ${({ theme }) => theme.sizings.lvl2};
    background-color: rgba(0, 0, 0, 0);
  }
`

const MostChosenLabel = styled(Label)`
  position: absolute;
  top: -${({ theme }) => theme.sizings.lvl2};
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 0;
  text-transform: uppercase;
`

const StyledIcon = styled(Icon)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
  .text {
    stroke: transparent;
    color: ${({ theme, $highlighted }) =>
      $highlighted
        ? theme.colors.errorBorderActive
        : theme.colors.actionsStandard};
    fill: ${({ theme, $highlighted }) =>
      $highlighted
        ? theme.colors.errorBorderActive
        : theme.colors.actionsStandard};
  }
`

const StyledPricingPerPeriod = styled(PricingPerPeriod)`
  display: flex;
  margin-top: 12px;
`
const OriginalPrice = styled(Typography)`
  text-decoration: line-through;
  margin: 0;
`
const Description = styled.div`
  ${media.phone`
    margin-bottom: 0;
  `}
  ${media.tablet`
    margin: 0 0 ${({ theme }) => theme.sizings.lvl3} 0;
  `}
  width: 100%;
  height: ${({ $fullBodyHeight }) => ($fullBodyHeight ? '100%' : 'initial')};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textLighter};
`

const Title = styled(Typography)`
  margin-top: 0;
`

const SavingsLabel = styled(Label)`
  p {
    font-size: 12px;
  }

  margin-top: ${({ theme }) => theme.sizings.lvl3};
  @media print {
    margin-bottom: 0;
  }
`

const ConfirmButton = styled(Button)`
  width: 100%;
  margin-top: ${({ theme }) => theme.sizings.lvl5};

  @media print {
    display: none;
  }
`

const PlanChoice = ({
  className,
  highlighted,
  highlightedText,
  id,
  children,
  icon,
  iconWidth,
  heading,
  body,
  fullBodyHeight,
  price,
  priceDiscounted,
  onSubmit,
  submitText,
  singular,
  disabled,
  ...restProps
}) => {
  const { t } = useTranslation()

  return (
    <PlanContainer
      highlighted={highlighted}
      className={className}
      {...restProps}
    >
      {highlighted && !singular && (
        <MostChosenLabel
          text={
            highlightedText || t('cspSalesFlow.steps.warrantyPlan.mostChosen')
          }
          backgroundColor="errorBorderActive"
        />
      )}

      {icon && (
        <StyledIcon type={icon} width={iconWidth} $highlighted={highlighted} />
      )}

      {heading && <Title type="Level1Heading">{heading}</Title>}

      {body && (
        <Description
          type="ExplanationParagraph"
          $fullBodyHeight={fullBodyHeight}
        >
          {body}
        </Description>
      )}

      {price && price > priceDiscounted && (
        <OriginalPrice type="ExplanationParagraph">
          {formatPrice(price, true, true)}
        </OriginalPrice>
      )}

      {priceDiscounted && <StyledPricingPerPeriod price={priceDiscounted} />}

      {price && priceDiscounted && price > priceDiscounted && (
        <SavingsLabel
          text={`${t(
            'cspSalesFlow.steps.warrantyPlan.youSaveEveryYear',
          )} ${formatPrice((price - priceDiscounted) * 12, true, false, true)}`}
          backgroundColor={highlighted ? 'chartsIsOk' : 'darkestBackground'}
        />
      )}
      <div>
        {onSubmit && (
          <ConfirmButton
            disabled={disabled}
            level={highlighted ? 'cta' : 'standard'}
            onClick={() => onSubmit({ packageCalculationResultId: id })}
            data-test-e2e="button-choose-plan"
          >
            {submitText || t('cspSalesFlow.steps.warrantyPlan.confirm')}
          </ConfirmButton>
        )}
        {children && <ContentSeperator paddingLevel={3} />}
        {children}
      </div>
    </PlanContainer>
  )
}

PlanChoice.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  highlighted: PropTypes.bool,
  highlightedText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  iconWidth: PropTypes.string,
  heading: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fullBodyHeight: PropTypes.bool,
  price: PropTypes.number,
  priceDiscounted: PropTypes.number,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
  /** True if this is the only plan to choose */
  singular: PropTypes.bool,
  disabled: PropTypes.bool,
}
PlanChoice.defaultProps = {
  id: null,
  className: null,
  highlighted: false,
  highlightedText: null,
  icon: undefined,
  iconWidth: '70',
  heading: undefined,
  body: undefined,
  fullBodyHeight: false,
  children: undefined,
  price: undefined,
  priceDiscounted: undefined,
  onSubmit: undefined,
  submitText: null,
  singular: false,
  disabled: false,
}
export default PlanChoice
