import { useRef, useEffect } from 'react'

const useAbortController = () => {
  const ref = useRef(null)

  const abort = () => {
    if (ref.current instanceof AbortController && !ref.current.signal.aborted) {
      ref.current.abort()
    }

    ref.current = new AbortController()
  }

  useEffect(() => {
    return () => {
      if (
        ref.current instanceof AbortController &&
        !ref.current.signal.aborted
      ) {
        ref.current.abort()
      }
    }
  }, [])

  return { ref, abort }
}

export default useAbortController
