import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'

import { vinSearchFormValidation } from 'config/validation-schemas'

import Button from 'components/atoms/button'
import ContentSeparator from 'components/atoms/content-separator'
import {
  FormikSelectInput,
  FormikTextInput,
} from 'components/molecules/formik-field'
import { useDispatch, useSelector } from 'react-redux'
import { addNewCarData, getDataProviders } from 'redux/actions/data'

const InputRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.sizings.lvl1};

  ${media.tablet`
    gap: ${({ theme }) => theme.sizeByFactor(0.5)};
    grid-template-columns: 1fr min-content 1fr min-content  1fr min-content 1fr;
    align-items: top;
  `}
`
const StyledSeparator = styled(ContentSeparator)`
  ${media.tablet`
    height: ${({ theme }) => theme.sizeByFactor(6)};
  `}
`

const InputCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl1};
  width: 100%;
`

const SubmitButton = styled(Button)`
  width: 100%;

  ${media.tablet`
    margin: ${({ theme }) => theme.sizings.lvl1} 0;
  `}
`

// The values in these fields (in Dutch) are inline with what the backend expects
// and what's added to the Redux store.
export const VIN_SEARCH_FORM_FIELDS = {
  MILEAGE: 'mileage',
  VIN: 'vin',
  PROVIDER: 'provider',
}

const VinSearch = ({
  handleGetLicensePlate,
  prefilledMileage,
  prefilledVin,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const separator = <StyledSeparator variant="vertical" paddingLevel={2} />

  const dataProviders = useSelector((state) => state.data?.dataProviders)
  const dataProviderItems = dataProviders?.data
    ?.filter((provider) => !!provider.key)
    .map(({ key, label }) => ({ value: key, label })) || [
    { label: '', value: '' },
  ]
  const validationSchema = vinSearchFormValidation(t)

  const initialValues = {
    [VIN_SEARCH_FORM_FIELDS.VIN]: prefilledVin || '',
    [VIN_SEARCH_FORM_FIELDS.PROVIDER]:
      dataProviderItems.length > 0 ? dataProviderItems[0].value : '',
    [VIN_SEARCH_FORM_FIELDS.MILEAGE]: prefilledMileage || '',
  }

  const onSubmit = async (values) => {
    // Adds the car data to the Redux store
    dispatch(
      addNewCarData({
        [VIN_SEARCH_FORM_FIELDS.MILEAGE]:
          values[VIN_SEARCH_FORM_FIELDS.MILEAGE],
        chassisnr: values[VIN_SEARCH_FORM_FIELDS.VIN],
        [VIN_SEARCH_FORM_FIELDS.PROVIDER]:
          values[VIN_SEARCH_FORM_FIELDS.PROVIDER],
      }),
    )

    // Fetches the license plate data
    await handleGetLicensePlate({
      [VIN_SEARCH_FORM_FIELDS.VIN]: values[VIN_SEARCH_FORM_FIELDS.VIN],
      [VIN_SEARCH_FORM_FIELDS.PROVIDER]:
        values[VIN_SEARCH_FORM_FIELDS.PROVIDER],
    })
  }

  useEffect(() => {
    // If the data providers already have been loaded, do not do it again
    if (dataProviders?.data || dataProviders?.loading) {
      return
    }
    // Fetch the data providers needed for the VIN submit
    dispatch(getDataProviders())
  }, [dataProviders, dispatch])

  return (
    <Formik
      key="form-vin"
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange
      validateOnBlur
      validateOnMount
    >
      {({ values, isValid, isSubmitting }) => (
        <Form noValidate>
          <InputRow>
            <InputCell>
              <Field
                name={VIN_SEARCH_FORM_FIELDS.VIN}
                label={t('vin')}
                type="text"
                component={FormikTextInput}
                required
                filled
              />
            </InputCell>
            {separator}
            <InputCell>
              <Field
                name={VIN_SEARCH_FORM_FIELDS.PROVIDER}
                label={t('dataProvider')}
                type="text"
                items={dataProviderItems}
                component={FormikSelectInput}
                value={values[VIN_SEARCH_FORM_FIELDS.PROVIDER]}
                required
                filled
              />
            </InputCell>
            {separator}
            <InputCell>
              <Field
                name={VIN_SEARCH_FORM_FIELDS.MILEAGE}
                label={t('mileage')}
                type="text"
                component={FormikTextInput}
                required
                filled
                data-test-e2e="input-mileage"
              />
            </InputCell>
            {separator}
            <InputCell>
              <SubmitButton
                data-test-e2e="button-search-version"
                disabled={!isValid || isSubmitting}
                text={t('searchVersion')}
                level="cta"
                type="submit"
              />
            </InputCell>
          </InputRow>
        </Form>
      )}
    </Formik>
  )
}

VinSearch.propTypes = {
  handleGetLicensePlate: PropTypes.func.isRequired,
  prefilledMileage: PropTypes.number,
  prefilledVin: PropTypes.string,
}

VinSearch.defaultProps = {
  prefilledMileage: null,
  prefilledVin: '',
}

export default VinSearch
