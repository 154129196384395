import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Icon from 'components/atoms/icon'
import { formatDataTestE2eAttr } from 'utilities/format'

const Container = styled.div`
  background-color: ${({ theme, $style }) => theme.colors[$style.background]};
  display: flex;
  height: 20px;
  justify-content: center;
  justify-items: center;
  width: 20px;
  border: 1px solid ${({ theme, $style }) => theme.colors[$style.border]};
`

const StyledIcon = styled(Icon)`
  * {
    line-height: 1;
  }
`

function AdStatusIcon({ status }) {
  const styleMap = {
    ok: {
      background: 'statePositive',
      border: 'statePositive',
      icon: 'checkCircleOutline',
    },
    update: {
      background: 'actionsStandard',
      border: 'actionsStandard',
      icon: 'accessTime',
    },
    error: {
      background: 'stateNegative',
      border: 'stateNegative',
      icon: 'errorOutline',
    },
    none: {
      background: 'brandGolf',
      border: 'brandDelta',
    },
  }

  const style = styleMap[status] || styleMap.none

  return (
    <Container
      $style={style}
      data-test-e2e={formatDataTestE2eAttr('ad-status-icon', style?.icon)}
    >
      {style && style.icon && (
        <StyledIcon type={style.icon} color="brandGolf" width="16" />
      )}
    </Container>
  )
}

AdStatusIcon.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

AdStatusIcon.defaultProps = {
  status: 'none',
}

export default AdStatusIcon
