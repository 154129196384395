import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components/macro'
import Text from 'components/atoms/text'
import { useTranslation } from 'react-i18next'

const RING_WIDTHS = { extraSmall: '2px', small: '3px', medium: '6px' }
const RING_SIZES = { extraSmall: '16px', small: '25px', medium: '51px' }

const ringAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Container = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: ${({ size }) => RING_SIZES[size]};
  min-width: ${({ size }) => RING_SIZES[size]};
  position: relative;
  width: 100%;
  height: ${({ height }) => height || '100%'};
`

const ErrorContainer = styled.span`
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  > *:first-child {
    margin-right: ${({ theme }) => theme.sizings.lvl1};
  }
`

const Ring = styled.span`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: ${({ size }) => RING_SIZES[size]};
  height: ${({ size }) => RING_SIZES[size]};
  margin: 6px;
  border: ${({ size }) => RING_WIDTHS[size]} solid
    ${({ theme }) => theme.colors.brandAlfa};
  border-radius: 50%;
  animation: ${ringAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({ theme }) => theme.colors.brandAlfa} transparent transparent
    transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }
  &:nth-child(2) {
    animation-delay: -0.3s;
  }
  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`

/**
 * Spinner to show loading state and possibly an error.
 *
 */
function LoadingIndicator({ error, size, ...restProps }) {
  const { t } = useTranslation()
  let formattedError = error
  if (error && typeof error !== 'string') {
    formattedError = error.description || t('genericError')
  }
  return (
    <Container data-test-e2e="loading-indicator" {...restProps} size={size}>
      {formattedError ? (
        <ErrorContainer>
          <Text color="stateNegative" text="Error" type="h5" />
          <Text color="stateNegative" text={formattedError} />
        </ErrorContainer>
      ) : (
        <>
          <Ring size={size} />
          <Ring size={size} />
          <Ring size={size} />
          <Ring size={size} />
        </>
      )}
    </Container>
  )
}

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium']),
}

LoadingIndicator.defaultProps = {
  className: undefined,
  error: undefined,
  size: 'medium',
}

export default LoadingIndicator
