import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { withApolloV4Provider } from 'utilities/apollo'

import Icon from 'components/atoms/icon'
import Counter from 'components/atoms/counter'
import Todos from 'components/molecules/todos'
import TextLink from 'components/atoms/text-link'
import Text from 'components/atoms/text'
import LoadingIndicator from 'components/atoms/loading-indicator'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderTitleContainer = styled.header`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.sizings.lvl1};
  margin-bottom: ${({ theme }) => theme.sizings.lvl3}};
`

const ShowAllLink = styled(TextLink)`
  margin-top: auto;
`

const TodosDashboardOverview = ({
  locationId,
  openOverlay,
  getLocationTodos,
  locationTodos,
  ...restProps
}) => {
  const { t } = useTranslation()

  const loading = locationTodos?.loading
  const error = locationTodos?.error
  const badgeCount = locationTodos?.data?.count || 0
  const items = locationTodos?.data?.items || []

  React.useEffect(() => {
    getLocationTodos(locationId)
  }, [getLocationTodos, locationId])

  return (
    <Container {...restProps}>
      {loading || error ? (
        <LoadingIndicator error={error} />
      ) : (
        <>
          <HeaderTitleContainer>
            <Icon type="todo" color="brandAlfa" height="28" />
            <Text text={t('toDos')} type="h3" />
            {badgeCount > 0 && <Counter count={badgeCount} />}
          </HeaderTitleContainer>
          <Todos columns={1} items={items} loading={false} />
          <ShowAllLink
            onClick={() => openOverlay('todos', { locationId })}
            text={t('showAllTodos')}
          />
        </>
      )}
    </Container>
  )
}

TodosDashboardOverview.propTypes = {
  locationId: PropTypes.string,
  openOverlay: PropTypes.func.isRequired,
  getLocationTodos: PropTypes.func.isRequired,
  locationTodos: PropTypes.object,
}

export default withApolloV4Provider(TodosDashboardOverview)
