import * as routes from 'config/routes'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { submit } from 'redux-form'
import styled from 'styled-components/macro'

import { getCarfile } from 'redux/actions/data'

import { mapCarFileType } from 'utilities/mapping'
import { media } from 'utilities/styled'
import {
  getBackendNapValue,
  getObjectFromQueryString,
  scrollToTop,
} from 'utilities/utils'

import LoadingIndicator from 'components/atoms/loading-indicator'
import FullWidthTopBarLayout from 'components/layouts/full-width-top-bar-layout'
import CarEntryHeader from 'components/molecules/car-entry-header'
import PageHeader from 'components/organisms/page-header'

import CarEntryValuationStepper from './car-entry-valuation-stepper'
import CarEntryValuationTabs from './car-entry-valuation-tabs'

const Container = styled.div`
  padding: ${({ theme }) => theme.sizings.lvl2} 20px;
  ${media.desktop`
      padding: ${({ theme }) => theme.sizings.lvl2} 60px;
  `}
`

const DesktopPageHeader = styled(PageHeader)`
  display: none;
  ${media.tablet`
    display: block;
  `};
`

const StyledCarEntryHeader = styled(CarEntryHeader)`
  border-bottom: ${({ theme }) =>
    `${theme.colors.darkOnLightBorder} 1px solid`};
`

function CarEntryValuationStep2({
  createCarfile,
  forms,
  getForms,
  getBids,
  getNap,
  nap,
  newCar,
  updateCarfile,
  deleteCarAssets,
  sortCarAssets,
  rotateImages,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const queryParams = getObjectFromQueryString(location.search)
  const carId = queryParams && queryParams.auto_id

  const carFile = useSelector(
    (state) => state.data && state.data.carfile && state.data.carfile.data,
  )

  const loading = useSelector((state) => {
    const { data } = state
    return (
      (data.createCarfile && data.createCarfile.loading) ||
      (data.updateCarfile && data.updateCarfile.loading) ||
      (data.carfile && data.carfile.loading)
    )
  })

  const formId = 'carValuation'
  const formTopId = `${formId}-top`
  const formTop = useSelector(
    (state) =>
      state.form && state.form[formTopId] && state.form[formTopId].values,
  )

  const [done, setDone] = useState(false)

  const napValue = nap && nap.data && nap.data.nap
  const napBackendValue = getBackendNapValue(napValue)
  const car = {
    ...newCar,
    ...carFile,
    nap: napBackendValue,
  }

  const handleGetCarFile = useCallback(() => {
    dispatch(getCarfile(carId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carId])

  const goToCar = useCallback(() => {
    history.push(`/car/${carId}/media#published`)
  }, [carId, history])

  useEffect(() => {
    if (done) {
      if (!carId) {
        // Show an alert when trying to close
        // the tabs before having saved a car
        setDone(false)
        alert(t('enterDetailsAndSaveFirst'))
      } else {
        // go to the advertisements tab:
        goToCar()
      }
    }
  }, [done, carId, t, history, goToCar])

  /**
   * Don't remove unsused variables newIndex and oldIndex,
   * it'll result the actionType to not be sent
   * See "handleChange" in flow-stepper.js
   */
  const handleChangeStep = useCallback(
    async (newIndex, oldIndex, actionType, oldKey) => {
      // Quite a hacky solution. Top Down / Calculation Form is not made with Redux Form so it's hard to submit from another location
      // This fetches the data of the calculation form when the user navigates to a previous tab and updates the carfile.
      // Similar to the result of dispatch(submit(formId))
      if (carId && oldKey === 'calculation') {
        // Get the top down calculation form
        const formsContainer = document.getElementById(
          'valuation-top-down-calculation-form',
        )

        // Create an object to store the input values
        let dataFromInputs = {}

        // Loop over all the input fields
        formsContainer.querySelectorAll('input').forEach((input) => {
          dataFromInputs = {
            ...dataFromInputs,
            ...((!!input.id || !!input.name) && {
              [input.id || input.name]:
                input.type === 'number' ? Number(input.value) : input.value,
            }),
          }
        })

        // Merge the data
        const mergedCarFileData = {
          ...car,
          ...dataFromInputs,
        }

        // Update the carfile
        updateCarfile(mergedCarFileData, carId, null, false)

        return true
      }

      if (actionType === 'flow-stepper') {
        await dispatch(submit(formId))
      }

      await handleGetCarFile(carId)
      await getForms('valuation', { kenteken: car.kenteken, auto_id: carId })

      return true
    },
    [car, carId, getForms, handleGetCarFile, dispatch, updateCarfile],
  )

  const handleSubmit = useCallback(
    async (updatedData, finish, justUpdate) => {
      const carfileData = {
        kenteken: car.kenteken,
        ...updatedData,
        ...formTop,
      }

      if (carId) {
        await updateCarfile(
          {
            ...car,
            ...carfileData,
          },
          carId,
          null,
          false,
        )
      } else {
        await createCarfile({
          ...car,
          ...carfileData,
        })
      }

      if (!justUpdate) {
        const noErrors = handleChangeStep ? await handleChangeStep() : true
        if (!noErrors) {
          return false
        }
      }

      if (loading) {
        return false
      }

      if (finish === true) {
        setDone(true)
        goToCar()
      }

      return true
    },
    [
      loading,
      car,
      carId,
      createCarfile,
      formTop,
      goToCar,
      handleChangeStep,
      updateCarfile,
    ],
  )

  useEffect(() => {
    if (carId && !carFile) {
      handleGetCarFile(carId)
    }
  }, [carFile, carId, handleGetCarFile])

  useEffect(() => {
    getForms('valuation', { kenteken: car.kenteken, auto_id: carId })
    getForms('bids', { auto_id: carId })
    getBids({ auto_id: carId })
    // has no dependencies, so it will only run once on componentDidMount and
    // on at that time. This ensures that a clean version of the forms config
    // is retrieved for car entry, otherwise that forms for the last visited
    // carFile are used.
    // documented in: https://app.clickup.com/t/6unbvp
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!forms) {
      getForms('valuation', { kenteken: car.kenteken, auto_id: carId })
    }
  }, [car.kenteken, carId, forms, getForms])

  useEffect(() => {
    scrollToTop()
  }, [])

  const valuationProcessStartLink = useSelector((state) => {
    const actions = state?.data?.menus?.data?.actions
    if (Array.isArray(actions)) {
      const link = actions.find((action) => action.id === 'valuation')?.link
      if (link) {
        return link
      }
    }
    return `${routes.VALUATION}/1`
  })

  return (
    <Container>
      <DesktopPageHeader
        mainTitle={mapCarFileType(t, car?.voertuigsoort_mapped)}
        status={t('valuation')}
        subtitle={t('step22AddData')}
      />
      <FullWidthTopBarLayout
        paddingLevelTopBar={2}
        paddingLevelMain={0}
        topBarNoPaddingSlot={
          <StyledCarEntryHeader
            brand={car.merk}
            model={car.model}
            type={car.type}
            licensePlate={car.kenteken}
            mileage={car.km_binnen}
            getNap={getNap}
            nap={nap}
            buttonIcon="pen"
            buttonTo={valuationProcessStartLink}
          />
        }
        topBarSlot={
          <CarEntryValuationStepper
            formId={formId}
            onChange={handleChangeStep}
            carFile={carFile}
          />
        }
        mainSlot={
          !forms || forms.loading || loading ? (
            <LoadingIndicator />
          ) : (
            <CarEntryValuationTabs
              data-test-e2e="car-entry-valuation-tabs"
              car={car}
              createCarfile={createCarfile}
              formId={formId}
              forms={forms.data}
              onChange={handleChangeStep}
              handleSubmit={handleSubmit}
              valuation
              updateCarfile={updateCarfile}
              deleteCarAssets={deleteCarAssets}
              sortCarAssets={sortCarAssets}
              rotateImages={rotateImages}
            />
          )
        }
      />
    </Container>
  )
}

CarEntryValuationStep2.propTypes = {
  createCarfile: PropTypes.func.isRequired,
  forms: PropTypes.object,
  getForms: PropTypes.func.isRequired,
  getBids: PropTypes.func.isRequired,
  getNap: PropTypes.func.isRequired,
  nap: PropTypes.object,
  newCar: PropTypes.object,
  updateCarfile: PropTypes.func.isRequired,
  deleteCarAssets: PropTypes.func.isRequired,
  sortCarAssets: PropTypes.func.isRequired,
  rotateImages: PropTypes.func.isRequired,
}
CarEntryValuationStep2.defaultProps = {
  forms: null,
  nap: undefined,
  newCar: null,
}

// const CarEntryValuationStep2a = compose(
//   connect((state) => ({
//     formValues: getFormValues('carValuation')(state),
//   })),
// )(CarEntryValuationStep2);

export default withRouter(CarEntryValuationStep2)
