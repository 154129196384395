import React from 'react'
import PropTypes from 'prop-types'
import FilterCollapser from 'components/molecules/filter-collapser'
import DateRangePicker from 'components/atoms/date-range-picker'
import moment from 'moment'
import styled from 'styled-components/macro'
/**
 * Filter component that renders two dropdowns with selection
 * options.
 *

 */

const StyledDateRangePicker = styled(DateRangePicker)`
  margin: ${({ theme }) => theme.sizings.lvl2} 0;
`

function DateRangeFilter({
  initiallyOpen,
  id,
  fromId,
  onFilterUpdate,
  title,
  toId,
  startDate,
  endDate,
}) {
  const handleUpdate = (values) => {
    if (
      values.startDate &&
      values.startDate.isValid() &&
      values.endDate &&
      values.endDate.isValid()
    ) {
      onFilterUpdate(id, {
        [fromId]: moment(values.startDate).format('YYYYMMDD'),
        [toId]: moment(values.endDate).format('YYYYMMDD'),
      })
    }
  }

  return (
    <FilterCollapser initallyOpen={initiallyOpen} title={title}>
      <StyledDateRangePicker
        onChange={handleUpdate}
        startDate={startDate}
        startDateId={fromId}
        endDate={endDate}
        endDateId={toId}
      />
    </FilterCollapser>
  )
}

DateRangeFilter.propTypes = {
  /** Control whether the filter is initially opened or collapsed */
  initiallyOpen: PropTypes.bool,
  id: PropTypes.string.isRequired,
  /** The from id for the filter that will be passed as the first argument
   * of the onChange handler */
  fromId: PropTypes.string.isRequired,
  /** The onChange handler */
  onFilterUpdate: PropTypes.func.isRequired,
  /** The from id for the filter that will be passed as the first argument
   * of the onChange handler */
  toId: PropTypes.string.isRequired,
  /** The title of the filter */
  title: PropTypes.string.isRequired,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
}

DateRangeFilter.defaultProps = {
  initiallyOpen: true,
  startDate: null,
  endDate: null,
}

export default DateRangeFilter
