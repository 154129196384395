import { connect } from 'react-redux'
import { getBids, postDynamicEndpoint } from 'redux/actions/data'
import { openOverlay } from 'redux/actions/ui'
import { getForms } from 'redux/actions/forms'
import BidsForm from '../organisms/bids-form'

const mapStateToProps = ({ data, forms }) => ({
  bids: data.bids,
  dynamicEndpoint: data.dynamicEndpoint,
  forms,
})

const mapDispatchToProps = {
  getBids,
  getForms,
  openOverlay,
  postDynamicEndpoint,
}

export default connect(mapStateToProps, mapDispatchToProps)(BidsForm)
