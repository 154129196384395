import React from 'react'
import PropTypes from 'prop-types'
import Text from 'components/atoms/text'
import SimpleTable from 'components/layouts/simple-table'

function TextTable({ className, columns, rows }) {
  return (
    <SimpleTable
      className={className}
      columns={columns.map((column, index) => (
        <Text key={index.toString()} text={column} type="floatingLabelLabel" />
      ))}
      rows={rows.map((row) =>
        row.map((column, index) => (
          <Text key={index.toString()} text={column} type="boldLabel" />
        )),
      )}
    />
  )
}

TextTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
}

TextTable.defaultProps = {
  className: null,
}

export default TextTable
