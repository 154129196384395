/* eslint-disable camelcase */

import AuthLayout from 'components/layouts/auth-layout'
import LoginForm from 'components/organisms/login-form'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getObjectFromQueryString } from 'utilities/utils'

function Login({ auth, history, location, login, resetStore }) {
  const { t } = useTranslation()
  const queryParams = getObjectFromQueryString(location.search)
  const { signon_token } = queryParams

  const errorTexts = {
    invalid_login_data: t('loginDataInvalid'),
    api_endpoint_not_found: t('apiEndPointNotFound'),
    could_not_reach_api_endpoint: t('couldNotReachApiEndpoint'),
    unknown_error: t('wrongLoginCredentials'), // Assuming the user is using the wrong login credentials
  }

  const { error, loginStatus } = auth

  const renderedOnce = useRef()
  const attemptedLogin = useRef()
  const errorText = errorTexts[error]

  useEffect(() => {
    if (!renderedOnce.current) {
      resetStore()
      renderedOnce.current = true
    } else if (attemptedLogin.current && loginStatus === 'SUCCESS') {
      const { from } = location.state || { from: { pathname: '/' } }
      history.replace(from)
    }
  }, [history, location.state, loginStatus, resetStore])

  useEffect(() => {
    if (signon_token) {
      attemptedLogin.current = true
      login({ signon_token })
    }
  }, [login, signon_token])

  function handleLogin(formData) {
    attemptedLogin.current = true
    login(formData)
  }

  return (
    <AuthLayout pageTitle={t('login')}>
      <LoginForm auth={auth} error={errorText} onSubmit={handleLogin} />
    </AuthLayout>
  )
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  resetStore: PropTypes.func.isRequired,
}

export default Login
