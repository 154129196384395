import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from 'components/atoms/text'
import { media } from 'utilities/styled'
import TextLink from 'components/atoms/text-link'
import Icon from '../atoms/icon'

const Line = styled.div`
  display: flex;
  align-items: center;
  display: flex;

  > header {
    flex: 0 0 auto;
    display: inline-block;
  }

  > dl {
    display: none;

    ${media.tablet`
      flex: 1 1 auto;
      display: block;
      /* display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end; */
      text-align: right;
    `}

    > div {
      padding: 0 ${({ theme }) => theme.sizings.lvl2} 0 0;
      display: inline-block;
      border-right: 1px solid ${({ theme }) => theme.colors.brandDelta};
      margin-right: ${({ theme }) => theme.sizings.lvl2};
      & > * {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  footer {
    margin-right: 16px;

    & > * {
      padding: 0 0 0 16px;
    }
    & > *:first-child {
      padding: 0;
    }
  }
`

/**
 * Display a summary of an entity (like a location, relation or a car).
 * Also allow the optional adding of of an extra set of actions associated with
 * this entity.
 */
function SummaryLine({ name, details, actions }) {
  return (
    <Line>
      <header>
        {typeof name === 'string' ? <Text type="tabSmall" text={name} /> : name}
      </header>
      <dl>
        {details.map((detail, index) => (
          <div key={index.toString()}>
            {detail.iconLabel && (
              <>
                <Icon
                  title={detail.iconLabel.label}
                  type={detail.iconLabel.icon}
                  size="md"
                />
                &nbsp;
              </>
            )}
            {detail.label && (
              <>
                <Text color="brandCharlie" text={`${detail.label}:`} />
                &nbsp;
              </>
            )}
            {typeof detail.value === 'string' ||
            typeof detail.value === 'number' ? (
              <Text text={detail.value} />
            ) : (
              detail.value
            )}
          </div>
        ))}
      </dl>
      {actions.length > 0 && (
        <footer>
          {actions.map((action, index) => (
            <TextLink
              key={index.toString()}
              onClick={action.action}
              text={action.text}
            />
          ))}
        </footer>
      )}
    </Line>
  )
}

SummaryLine.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]).isRequired,
    }),
  ).isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

SummaryLine.defaultProps = {
  actions: [],
}

export default SummaryLine
