import React, { useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { termsDocumentAllowedFileExtensions } from 'config/data'
import { useTranslation } from 'react-i18next'
import { media } from 'utilities/styled'
import Typography from 'components/molecules/typography'
import SelectFileButton from 'components/molecules/select-file-button'
import {
  ADD_WARRANTY_PROGRAM_QUERY,
  ADD_PRICE_RULE_QUERY,
  GET_WARRANTY_PROVIDERS_AND_CAR_MODELS_QUERY,
  GET_WARRANTY_PROGRAMS_FOR_OVERVIEW_QUERY,
} from 'config/graphql/csp'
import Overlay from 'components/molecules/overlay'
import WarrantyProgramTableForm from 'components/organisms/car-service-plan/warranty-program-table-form'
import { DealerContext } from 'contexts/dealer'

const LayoutContainer = styled.div`
  flex: 1;
`

const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.sizings.lvl2};

  > * {
    margin: ${({ theme }) =>
      `${theme.sizings.lvl0} ${theme.sizings.lvl2} ${theme.sizings.lvl0} 0`};

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  ${media.tablet`
    flex-wrap: nowrap;
    
    > * {
      margin: 0 ${({ theme }) => theme.sizings.lvl2} 0 0;
    }
  `}

  ${media.desktop`
    flex-direction: row-reverse;
    margin-top: 0;

    > * {
      margin-right: 0;
      margin-left: ${({ theme }) => theme.sizings.lvl2};

      &:last-child {
        margin-left: 0;
      }
    }
  `}
`

const UploadTermsButton = styled(SelectFileButton)`
  text-align: center;

  // I am absolutely aware that !important is the devil, but I cannot
  // make this work properly without refactoring the SelectFileButton
  // component. In the interest of time, I am using !important.

  label *,
  label:hover *,
  label:focus * {
    fill: ${({ theme }) => theme.colors.actionsStandardText} !important;
  }

  ${media.desktop`
    flex-shrink: 0;
  `}
`

function WarrantyProgramDetails() {
  const { t } = useTranslation()
  const history = useHistory()

  const warrantyProvidersAndCarModelsQuery = useQuery(
    GET_WARRANTY_PROVIDERS_AND_CAR_MODELS_QUERY,
  )

  const [addWarrantyProgram] = useMutation(ADD_WARRANTY_PROGRAM_QUERY)
  const [addWarrantyPriceRule] = useMutation(ADD_PRICE_RULE_QUERY, {
    refetchQueries: [{ query: GET_WARRANTY_PROGRAMS_FOR_OVERVIEW_QUERY }],
    awaitRefetchQueries: true,
  })

  const { setWarrantyProgramMutating } = useContext(DealerContext)
  const handleAddWarrantyProgram = async ({
    warrantyProviderId,
    name,
    ownRiskPeriod,
    warrantyPriceRules,
    manufacturerWarranty,
    priceCalculation,
    maxMileage,
    externalId,
  }) => {
    setWarrantyProgramMutating(true)
    const addWarrantyProgramPayload = {
      variables: {
        warrantyProviderId,
        name,
        ownRiskPeriod,
        manufacturerWarranty,
        priceCalculation,
        maxMileage,
        externalId,
      },
    }
    try {
      const warrantyProgram = await addWarrantyProgram(
        addWarrantyProgramPayload,
      )
      if (
        warrantyProgram.data &&
        warrantyProgram.data.createWarrantyProgram.id
      ) {
        return Promise.all(
          warrantyPriceRules.map((rule) => {
            const payload = rule
            delete payload.actions
            return addWarrantyPriceRule({
              variables: {
                ...payload,
                warrantyProgramId:
                  warrantyProgram.data.createWarrantyProgram.id,
              },
            })
          }),
        ).then((_) => {
          setWarrantyProgramMutating(false)
          history.goBack()
        })
      }

      setWarrantyProgramMutating(false)
      history.goBack()
      throw new Error('Error submitting form')
    } catch (e) {
      setWarrantyProgramMutating(false)
      history.goBack()
      throw new Error(e)
    }
  }

  return (
    <Overlay
      close={() => history.goBack()}
      title={t('carServicePlanAdminProgramsNewProgramOverlay.addHeading')}
      icon="cleaning"
      headerRightSlot={
        <TermsContainer>
          <UploadTermsButton
            id="uploadTerms"
            icon="add"
            iconSize="md"
            text={t('carServicePlanAdminProgramsNewProgramOverlay.uploadTerms')}
            allowedFileExtensions={termsDocumentAllowedFileExtensions}
            onChange={() => {}}
            disabled
          />
          <Typography type="ExplanationParagraph">
            {t(
              'carServicePlanAdminProgramsNewProgramOverlay.possibleAfterSave',
            )}
          </Typography>
        </TermsContainer>
      }
    >
      <LayoutContainer>
        <WarrantyProgramTableForm
          onSubmit={handleAddWarrantyProgram}
          carBrandsAndModels={
            warrantyProvidersAndCarModelsQuery.data
              ? warrantyProvidersAndCarModelsQuery.data.brands
              : []
          }
          warrantyProviders={
            warrantyProvidersAndCarModelsQuery.data
              ? warrantyProvidersAndCarModelsQuery.data.warrantyProviders.edges
              : []
          }
        />
      </LayoutContainer>
    </Overlay>
  )
}

export default WarrantyProgramDetails
