import * as Iban from 'iban'
import * as Yup from 'yup'

import { LICENSE_PLATE_SEARCH_FORM_FIELDS } from 'components/organisms/license-plate-search'
import { VIN_SEARCH_FORM_FIELDS } from 'components/organisms/vin-search'
import { ACCESSORY_FORM_SWITCH_GROUP_SETTINGS } from 'components/views/settings/accessories/accessory-form'
import { VIN_LENGTH } from 'config/consts'
import { cspPackageTypes } from 'config/data'

// The `t` in validation schemas should be the t function from 'react-i18next';

const commonRules = (t) => ({
  mustBeIban: Yup.string().test(
    'iban',
    t('validationRules.mustBeAValidIban'),
    (value) => Iban.isValid(value),
  ),
  mustBeText: Yup.string().typeError(t('validationRules.mustBeText')),
  mustBeNumber: Yup.number()
    .typeError(t('validationRules.mustBeNumber'))
    .nullable(),
  mustBeBoolean: Yup.bool().typeError(t('validationRules.mustBeBoolean')),
  mustBeAPrice: Yup.number()
    .positive(t('validationRules.mustBeAboveNumber', { number: 0 }))
    .typeError(t('validationRules.mustBeAPrice')),
  mustBeAMonthlyRate: Yup.number().typeError(
    t('validationRules.mustBeAMonthlyRate'),
  ),
  optionMustBeSelected: Yup.string()
    .typeError(t('validationRules.optionMustBeSelected'))
    .min(1, t('validationRules.optionMustBeSelected')),
  mustBeAnEmail: Yup.string()
    .typeError(t('validationRules.mustBeText'))
    .matches(
      // taken from: https://www.emailregex.com/
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      t('validationRules.mustBeAnEmail'),
    ),
  mustBeALicensePlate: Yup.string()
    .typeError(t('validationRules.mustBeText'))
    .transform((value) => value.split('-').join('').toUpperCase())
    .min(6, t('validLicenseplateRequired'))
    .max(6, t('validLicenseplateRequired'))
    .matches(/[A-Z0-9]{6}/, t('validLicenseplateRequired')),
  mustBeMinNumber: (number) =>
    Yup.number()
      .typeError(t('validationRules.mustBeNumber'))
      .min(number, t('validationRules.mustBeMinNumber', { number }))
      .nullable(),
  mustBeMaxNumber: (number) =>
    Yup.number()
      .typeError(t('validationRules.mustBeNumber'))
      .max(number, t('validationRules.mustBeMaxNumber', { number }))
      .nullable(),
  mustBeAboveNumber: (number) =>
    Yup.number()
      .typeError(t('validationRules.mustBeNumber'))
      .moreThan(number, t('validationRules.mustBeAboveNumber', { number }))
      .nullable(),
  mustBeBelowNumber: (number) =>
    Yup.number()
      .typeError(t('validationRules.mustBeNumber'))
      .lessThan(number, t('validationRules.mustBeBelowNumber', { number }))
      .nullable(),
  mustBeMinCharacters: (nCharacters) =>
    Yup.string()
      .typeError(t('validationRules.mustBeText'))
      .min(
        nCharacters,
        t('validationRules.mustBeBelowCharactersLength', { nCharacters }),
      ),
  mustBeExactlyNCharacters: (nCharacters) =>
    Yup.string()
      .typeError(t('validationRules.mustBeText'))
      .test(
        'len',
        t('validationRules.mustExactlyCharactersLength', { nCharacters }),
        (val) => val?.length === nCharacters,
      ),
  mustBeMaxCharacters: (nCharacters) =>
    Yup.string()
      .typeError(t('validationRules.mustBeText'))
      .max(
        nCharacters,
        t('validationRules.mustBeBelowCharactersLength', { nCharacters }),
      ),
  requiredNumber: (fieldName) =>
    Yup.number()
      .typeError(t('validationRules.mustBeNumber'))
      .required(t('fieldIsRequired', { fieldName })),
  requiredText: (fieldName) =>
    Yup.string()
      .typeError(t('validationRules.mustBeText'))
      .required(t('fieldIsRequired', { fieldName })),
  requiredBoolean: (fieldName) =>
    Yup.bool()
      .typeError(t('validationRules.mustBeBoolean'))
      .required(t('fieldIsRequired', { fieldName })),
})

export const CSPGeneralSchema = (t) => {
  const { mustBeText, requiredNumber } = commonRules(t)
  return Yup.object().shape({
    carServicePlan: Yup.object().shape({
      mechanicCarRate: requiredNumber('Uurtarief'),
    }),
    servicePackages: Yup.array().of(
      Yup.object().shape({
        description: mustBeText,
      }),
    ),
  })
}

export const CSPAdminDealerSchema = (t) => {
  const { mustBeAPrice, requiredText } = commonRules(t)
  return Yup.object().shape({
    carServicePlan: Yup.object().shape({
      invoiceFee: mustBeAPrice.required(
        t('cspAdminDealerDetails.priceSettings.fields.invoiceFee.labelShort'),
      ),
    }),
    warrantyProviders: Yup.array().of(
      Yup.object().shape({
        id: requiredText('Garantieprovider id'),
        dealerCode: requiredText('Dealercode'),
      }),
    ),
  })
}

export const CSPSalesFlowStep1Schema = (t) => {
  const { requiredText, requiredNumber, mustBeText, mustBeBoolean } =
    commonRules(t)
  return Yup.object().shape({
    mileagePerYear: requiredNumber(
      t('cspSalesFlow.steps.basicData.mileagePerYear'),
    ),
    activationDate: requiredText(
      t('cspSalesFlow.steps.basicData.activationDate'),
    ),
    warrantyProgramId: mustBeText,
    noFactoryWarranty: mustBeBoolean,
  })
}
export const CSPSalesFlowStep1WorkshopSchema = (t) => {
  const { requiredText, requiredNumber } = commonRules(t)
  return Yup.object().shape({
    mileagePerYear: requiredNumber(
      t('cspSalesFlow.steps.basicData.mileagePerYear'),
    ),
    activationDate: requiredText(
      t('cspSalesFlow.steps.basicData.startingDate'),
    ),
  })
}
export const CSPSalesFlowStep1UpdateVehicle = (t) => {
  const { mustBeText } = commonRules(t)
  return Yup.object().shape({
    modelId: mustBeText.required(
      t('fieldIsRequired', {
        fieldName: t('cspSalesFlow.steps.updateVehicleModal.fields.modelId'),
      }),
    ),
  })
}

export const CSPSalesFlowCustomerMinimumSchema = (t) => {
  const { mustBeText } = commonRules(t)
  return Yup.object().shape({
    firstName: mustBeText,
    lastName: mustBeText.required(
      t('fieldIsRequired', { fieldName: 'Achternaam' }),
    ),
    zipCode: mustBeText,
    email: mustBeText,
  })
}
export const CSPSalesFlowStep3CustomerPrivateSchema = (
  t,
  withVinFieldRequired,
  withLicensePlateField,
) => {
  const { mustBeText, mustBeIban, mustBeALicensePlate, requiredText } =
    commonRules(t)
  const fieldsConfig = {
    bankName: mustBeText.required(t('fieldIsRequired', { fieldName: 'Bank' })),
    iban: mustBeIban.required(t('fieldIsRequired', { fieldName: 'IBAN' })),
    vin: mustBeText,
    title: mustBeText.required(t('fieldIsRequired', { fieldName: 'Aanhef' })),
    firstName: mustBeText,
    lastName: mustBeText,
    street: mustBeText.required(t('fieldIsRequired', { fieldName: 'Straat' })),
    streetNr: mustBeText.required(
      t('fieldIsRequired', { fieldName: 'Huisnummer' }),
    ),
    zipCode: mustBeText.required(
      t('fieldIsRequired', { fieldName: 'Postcode' }),
    ),
    city: mustBeText.required(t('fieldIsRequired', { fieldName: 'Plaats' })),
    telephone: mustBeText.required(
      t('fieldIsRequired', { fieldName: 'Telefoonnummer' }),
    ),
    email: mustBeText.required(
      t('fieldIsRequired', { fieldName: 'E-mailadres' }),
    ),
  }
  if (withVinFieldRequired) {
    fieldsConfig.vin = mustBeText.required(
      t('fieldIsRequired', { fieldName: t('chassisNumber') }),
    )
  }
  if (withLicensePlateField) {
    fieldsConfig.licensePlate = mustBeALicensePlate.concat(
      requiredText(t('licensePlate')),
    )
  }
  return Yup.object().shape(fieldsConfig)
}

export const CSPSalesFlowStep3CustomerCompanySchema = (
  t,
  withVinFieldRequired,
  withLicensePlateField,
) => {
  const { mustBeText, mustBeIban, mustBeALicensePlate, requiredText } =
    commonRules(t)
  const fieldsConfig = {
    bankName: mustBeText.required(t('fieldIsRequired', { fieldName: 'Bank' })),
    iban: mustBeIban.required(t('fieldIsRequired', { fieldName: 'IBAN' })),
    vin: mustBeText,
    title: mustBeText.required(t('fieldIsRequired', { fieldName: 'Aanhef' })),
    firstName: mustBeText.required(
      t('fieldIsRequired', { fieldName: 'Voornaam' }),
    ),
    lastName: mustBeText.required(
      t('fieldIsRequired', { fieldName: 'Achternaam' }),
    ),
    street: mustBeText.required(t('fieldIsRequired', { fieldName: 'Straat' })),
    streetNr: mustBeText.required(
      t('fieldIsRequired', { fieldName: 'Huisnummer' }),
    ),
    zipCode: mustBeText.required(
      t('fieldIsRequired', { fieldName: 'Postcode' }),
    ),
    city: mustBeText.required(t('fieldIsRequired', { fieldName: 'Plaats' })),
    telephone: mustBeText.required(
      t('fieldIsRequired', { fieldName: 'Telefoon' }),
    ),
    email: mustBeText.required(
      t('fieldIsRequired', { fieldName: 'E-mailadres' }),
    ),
    companyName: mustBeText,
    streetCompany: mustBeText,
    streetNrCompany: mustBeText,
    zipCodeCompany: mustBeText.required(
      t('fieldIsRequired', { fieldName: 'Postcode' }),
    ),
    cityCompany: mustBeText,
    telephoneCompany: mustBeText,
    emailCompany: mustBeText.required(
      t('fieldIsRequired', { fieldName: 'E-mailadres' }),
    ),
  }
  if (withVinFieldRequired) {
    fieldsConfig.vin = mustBeText.required(
      t('fieldIsRequired', { fieldName: t('chassisNumber') }),
    )
  }
  if (withLicensePlateField) {
    fieldsConfig.licensePlate = mustBeALicensePlate.concat(
      requiredText(t('licensePlate')),
    )
  }
  return Yup.object().shape(fieldsConfig)
}

export const CSPSalesFlowStep3ChangeVehicleStatus = (t) => {
  const { mustBeNumber, mustBeText } = commonRules(t)
  return Yup.object().shape({
    verkoopprijs_fin: mustBeNumber.required(
      t('fieldIsRequired', {
        fieldName: t(
          'cspSalesFlow.steps.changeVehicleStatusDialog.fields.salePrice',
        ),
      }),
    ),
    afleverdatum: mustBeText.required(
      t('fieldIsRequired', {
        fieldName: t(
          'cspSalesFlow.steps.changeVehicleStatusDialog.fields.saleDate',
        ),
      }),
    ),
  })
}

export const CSPWarrantyProgramFormSchema = (t) => {
  const {
    mustBeText,
    mustBeNumber,
    mustBeAPrice,
    mustBeAboveNumber,
    mustBeMinCharacters,
    mustBeMaxCharacters,
    requiredText,
    requiredNumber,
    requiredBoolean,
    optionMustBeSelected,
  } = commonRules(t)
  return Yup.object().shape({
    warrantyProviderId: requiredText('Garantieprovider'),
    name: mustBeMinCharacters(2)
      .concat(mustBeMaxCharacters(50))
      .concat(requiredText('Naam')),
    ownRiskPeriod: requiredText('Eigen risico'),
    manufacturerWarranty: requiredBoolean('Fabrieksgarantie'),
    priceCalculation: requiredText('Prijsberekening'),
    maxMileage: requiredNumber('Max. km-stand eind'),
    externalId: requiredText('externalId'),
    warrantyPriceRules: Yup.array().of(
      Yup.object().shape({
        brandId: mustBeText,
        modelId: mustBeText,
        age: Yup.object().shape({
          from: mustBeNumber,
          to: mustBeAboveNumber(0),
        }),
        mileage: Yup.object().shape({
          from: mustBeNumber,
          to: mustBeAboveNumber(0),
        }),
        duration: optionMustBeSelected.required(
          t('fieldIsRequired', {
            fieldName: t(
              'carServicePlanAdminProgramsNewProgramOverlay.addPlansTableHeadings.duration',
            ),
          }),
        ),
        priceForDuration: mustBeAPrice.required(
          t('fieldIsRequired', {
            fieldName: t(
              'carServicePlanAdminProgramsNewProgramOverlay.addPlansTableHeadings.priceForDuration',
            ),
          }),
        ),
        engineCapacity: Yup.object().shape({
          from: mustBeNumber,
          to: mustBeAboveNumber(0),
        }),
        kilowatt: Yup.object().shape({
          from: mustBeNumber,
          to: mustBeAboveNumber(0),
        }),
      }),
    ),
  })
}

export const CSPPriceMatrixFormSchema = (t) => {
  const {
    mustBeText,
    mustBeNumber,
    mustBeAPrice,
    mustBeAboveNumber,
    mustBeMaxNumber,
    mustBeMinCharacters,
    mustBeMaxCharacters,
    requiredText,
  } = commonRules(t)

  return Yup.object().shape({
    carServicePlanId: requiredText('Car service plan'),
    name: mustBeMinCharacters(2)
      .concat(mustBeMaxCharacters(50))
      .concat(requiredText('Naam')),
    maxMileagePerYear: Yup.number(),
    priceMatrixRules: Yup.array().of(
      Yup.object().shape({
        brandId: mustBeText,
        modelId: mustBeText,
        age: Yup.object().shape({
          from: mustBeNumber,
          to: mustBeAboveNumber(0),
        }),
        mileage: Yup.object().shape({
          from: mustBeNumber,
          to: mustBeAboveNumber(0),
        }),
        pricePerKm: mustBeMaxNumber(1000000).concat(
          mustBeAPrice.required(
            t('fieldIsRequired', {
              fieldName: t(
                'carServicePlanAdminPriceMatrixOverlay.tableHeadings.pricePerKm',
              ),
            }),
          ),
        ),
      }),
    ),
  })
}

export const CSPEditCarServicesFormSchema = (t) => {
  const { mustBeText, mustBeAPrice, mustBeNumber, mustBeMinNumber } =
    commonRules(t)

  // Creates the expected shape for the cspPackages
  // {
  //   "LOYAL": Yup.bool()
  //   ...
  // }
  const packageTypesShape = Object.fromEntries(
    Object.entries(cspPackageTypes).map(([key]) => [key, Yup.bool()]),
  )

  return Yup.object().shape({
    carServices: Yup.array().of(
      Yup.object().shape({
        commercialName: mustBeText.required(
          t('fieldIsRequired', {
            fieldName: t('editCarServices.tableHeadings.commercialName'),
          }),
        ),
        clientTariff: mustBeAPrice.concat(mustBeMinNumber(0)).required(
          t('fieldIsRequired', {
            fieldName: t('editCarServices.tableHeadings.clientTariff'),
          }),
        ),
        costPerYear: mustBeNumber.concat(mustBeMinNumber(0)).required(
          t('fieldIsRequired', {
            fieldName: t('editCarServices.tableHeadings.costPerYear'),
          }),
        ),
        packageTypes: Yup.object()
          .shape(packageTypesShape)
          .test(function (selectedPackages) {
            const somePackagesSelected = Object.values(selectedPackages).some(
              (selectedPackage) => !!selectedPackage,
            )

            // User has selected at least one of the packages, no error
            if (somePackagesSelected) {
              return true
            }

            // User has selected no packages (at least one is required), map over the fields and create errors for them
            const errors = Object.keys(selectedPackages).map(
              (key) =>
                new Yup.ValidationError(
                  'At least one package is required', // Not visible in the frontend, the checkboxes turn red
                  `${this.path}.${key}`,
                  `${this.path}.${key}`,
                ),
            )

            // Return the validation errors and show them in the UI
            return new Yup.ValidationError(errors)
          }),
      }),
    ),
  })
}

export const CSPSalesFlowStartCalculationSchema = (t) => {
  const {
    mustBeALicensePlate,
    mustBeAboveNumber,
    requiredText,
    requiredNumber,
  } = commonRules(t)
  return Yup.object().shape({
    licensePlate: mustBeALicensePlate.concat(requiredText(t('licensePlate'))),
    mileage: mustBeAboveNumber(0).concat(requiredNumber(t('mileage'))),
    prospectType: requiredText('Prospect soort'),
    dealer: requiredText('Dealer'),
  })
}
export const CSPSalesFlowStartCalculationDemoSchema = (t) => {
  const {
    mustBeALicensePlate,
    mustBeAboveNumber,
    requiredText,
    requiredNumber,
  } = commonRules(t)
  return Yup.object().shape({
    licensePlate: mustBeALicensePlate.concat(requiredText(t('licensePlate'))),
    mileage: mustBeAboveNumber(0).concat(requiredNumber(t('mileage'))),
    mileagePerYear: mustBeAboveNumber(0).concat(
      requiredNumber(t('cspSalesFlow.steps.basicData.mileagePerYear')),
    ),
  })
}

export const CSPSalesFlowStartFindCarTypeSchema = (t) => {
  const { mustBeAboveNumber, requiredText, requiredNumber } = commonRules(t)
  return Yup.object().shape({
    carBrand: requiredText('Merk'),
    carModel: requiredText('Merk'),
    carBuildYear: requiredNumber('Bouwjaar'),
    carFuelType: requiredText('Brandstof'),
    currentMileage: mustBeAboveNumber(0).concat(requiredNumber(t('mileage'))),
    prospect: requiredText('Prospect soort'),
    dealerId: requiredText('Dealer'),
  })
}

export const CSPStopContract = (t) => {
  const { mustBeText, requiredText } = commonRules(t)
  return Yup.object().shape({
    stopDate: requiredText(
      t('carServicePlanContractDetails.packageInfoTable.contractEndDate'),
    ),
    reasonId: requiredText(
      t('carServicePlanContractDetails.packageInfoTable.deactivationReason'),
    ),
    explanation: mustBeText,
  })
}
export const CSPCancelContract = (t) => {
  const { mustBeText, requiredText } = commonRules(t)
  return Yup.object().shape({
    reasonId: requiredText(
      t('carServicePlanContractDetails.packageInfoTable.deactivationReason'),
    ),
    explanation: mustBeText,
  })
}

export const topdownBpmCalculationFormSchema = (t) => {
  const { requiredNumber } = commonRules(t)
  return Yup.object().shape({
    visualReconditioningCosts: requiredNumber(
      t('topdownBpmCalculationForm.visualReconditioningCosts'),
    ),
    technicalReconditioningCosts: requiredNumber(
      t('topdownBpmCalculationForm.technicalReconditioningCosts'),
    ),
    totalReconditioningCosts: requiredNumber(
      t('topdownBpmCalculationForm.totalReconditioningCosts'),
    ),
    purchasingValue: requiredNumber(
      t('topdownBpmCalculationForm.purchasingValue'),
    ),
    totalPurchasingValueInclRestBpm: requiredNumber(
      t('topdownBpmCalculationForm.totalPurchasingValueInclRestBpm'),
    ),
    grossProfitPotentialTotals: requiredNumber(
      t('topdownBpmCalculationForm.grossProfitPotentialTotals'),
    ),
  })
}

export const CarFileViaCarCollectFormSchema = (t) => {
  const { mustBeALicensePlate, requiredText } = commonRules(t)
  return Yup.object().shape({
    licensePlate: mustBeALicensePlate.concat(requiredText(t('licensePlate'))),
  })
}

export const GenerateBpmFormSchema = (t) => {
  const { requiredText, mustBeText } = commonRules(t)
  return Yup.object().shape({
    bsn: mustBeText(t('topdownBpmCalculationForm.visualReconditioningCosts')),
    vin: requiredText(
      t('topdownBpmCalculationForm.technicalReconditioningCosts'),
    ),
    rsin: mustBeText(t('topdownBpmCalculationForm.totalReconditioningCosts')),
    firstRegistrationDate: mustBeText(
      t('topdownBpmCalculationForm.purchasingValue'),
    ),
  })
}

export const MarktplaatsFormSchema = (t) => {
  const { requiredText, mustBeAPrice } = commonRules(t)
  const mapRules = (map, rule) =>
    Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {})

  return Yup.object().shape({
    marktplaatsFeatures: Yup.lazy((map) =>
      Yup.object(
        mapRules(
          map,
          Yup.object({
            prijstype: requiredText(t('marktplaatsForm.formLabels.priceType')),
            minimaal_bod: mustBeAPrice.when('prijstype', {
              is: '8',
              then: mustBeAPrice.required(
                t('fieldIsRequired', {
                  fieldName: t('marktplaatsForm.formLabels.offerFrom'),
                }),
              ),
            }),
          }),
        ),
      ),
    ),
  })
}

export const ChecklistForm = (t) => {
  const { requiredText } = commonRules(t)
  return Yup.object().shape({
    name: requiredText(t('name')),
    kinds: Yup.array()
      .of(Yup.string())
      .min(1, t('validationRules.atLeastOneOptionMustBeSelected')),
  })
}

export const ChecklistFormWithClusters = (t) => {
  const { requiredText } = commonRules(t)
  return Yup.object().shape({
    name: requiredText(t('name')),
    kinds: Yup.array()
      .of(Yup.string())
      .min(1, t('validationRules.atLeastOneOptionMustBeSelected')),
    clusterIds: Yup.array()
      .of(Yup.string())
      .min(1, t('validationRules.atLeastOneOptionMustBeSelected')),
  })
}

export const ClusterForm = (t) => {
  const { requiredText } = commonRules(t)
  return Yup.object().shape({
    name: requiredText(t('name')),
    dealers: Yup.array()
      .of(Yup.string())
      .min(1, t('validationRules.atLeastOneOptionMustBeSelected')),
  })
}

export const ChecklistItemForm = (t) => {
  const {
    requiredText,
    mustBeMaxCharacters,
    mustBeBoolean,
    requiredNumber,
    mustBeMinNumber,
  } = commonRules(t)
  return Yup.object().shape({
    name: requiredText(
      t('checklistsSettings.checklistItemForm.checklistItemName'),
    ),
    description: mustBeMaxCharacters(150),
    notificationUsers: Yup.array().of(Yup.string()),
    // Duration limit conditional validation
    limitDuration: mustBeBoolean,
    maxDuration: Yup.number().when('limitDuration', {
      is: true,
      then: requiredNumber(
        t('checklistsSettings.checklistItemForm.maxDuration'),
      ).concat(mustBeMinNumber(0)),
    }),
    alertMessage: Yup.string().when('limitDuration', {
      is: true,
      then: requiredText(
        t('checklistsSettings.checklistItemForm.alertMessage'),
      ),
    }),
    triggerChecklistItemId: Yup.string().when('limitDuration', {
      is: true,
      then: requiredText(
        t('checklistsSettings.checklistItemForm.durationStart'),
      ),
    }),
    // Automation conditional validation
    checkAutomatically: mustBeBoolean,
    automationTriggerIds: Yup.array().when('checkAutomatically', {
      is: true,
      then: Yup.array()
        .of(Yup.string())
        .min(1, t('validationRules.atLeastOneOptionMustBeSelected')),
    }),
    sendReminder: mustBeBoolean,
    reminderAfterDays: Yup.number().when('sendReminder', {
      is: true,
      then: requiredNumber(
        t('checklistsSettings.checklistItemForm.numberOfDays'),
      ).concat(mustBeMinNumber(0)),
    }),
    reminderUserIds: Yup.array().when('sendReminder', {
      is: true,
      then: Yup.array()
        .of(Yup.string())
        .min(
          1,
          t(
            'checklistsSettings.checklistItemForm.atLeastOneUserMustBeSelected',
          ),
        ),
    }),
  })
}

export const CspDealerSiteWidgetCta = (t) => {
  const {
    mustBeALicensePlate,
    requiredNumber,
    requiredText,
    mustBeAboveNumber,
  } = commonRules(t)
  return Yup.object().shape({
    licensePlate: mustBeALicensePlate.concat(
      requiredText(t('cspDealerSiteWidgetCta.form.licensePlate.label')),
    ),
    currentMileage: mustBeAboveNumber(0).concat(
      requiredNumber(t('cspDealerSiteWidgetCta.form.currentMileage.label')),
    ),
  })
}

export const CspDealerSiteWidgetForm = (t) => {
  const { mustBeText, mustBeAnEmail } = commonRules(t)
  return Yup.object().shape({
    firstName: mustBeText,
    lastName: mustBeText,
    email: mustBeAnEmail.required(
      t('fieldIsRequired', {
        fieldName: t(
          'cspDealerSiteWidgetForm.form.personalia.fields.email.label',
        ),
      }),
    ),
    phone: Yup.number().typeError(t('validationRules.onlyNumbersAllowed')),
  })
}

export const RdwCertificateForm = (t) => {
  const { requiredText } = commonRules(t)

  return Yup.object().shape({
    password: requiredText(t('password')),
  })
}

export const AccessoryForm = (t) => {
  const { mustBeText, requiredText } = commonRules(t)

  return Yup.object().shape({
    omschrijving: requiredText(
      t('checklistsSettings.checklistItemForm.checklistItemName'),
    ),
    disabled: Yup.mixed().oneOf(
      Object.values(ACCESSORY_FORM_SWITCH_GROUP_SETTINGS),
    ),
    // In the BE this is saved as an array in translations. In the FE we're asking seperate input fields from the users
    translationDe: mustBeText,
    translationFr: mustBeText,
    translationEn: mustBeText,
  })
}

export const LocationTagForm = (t) => {
  const { mustBeText } = commonRules(t)

  return Yup.object().shape({
    tags: Yup.array().of(
      Yup.object().shape({
        id: mustBeText.required(),
        label: Yup.string()
          .required()
          .typeError(t('validationRules.mustBeText'))
          .matches(
            // Created with ChatGPT. Yep.
            // Invalid: "Déshydrogénénotyrosinémique" (too long)
            // Invalid: "Test tag 123$" ($ character)
            // Valid: "Täst tag 123"
            // Valid: "Täst-tag-123"
            // Valid: "Second-hand"
            /^[a-zA-Z0-9À-ÿ\s'’\-()]{1,25}$/u,
          ),
      }),
    ),
  })
}

export const addVinNumberDialogFormValidation = (t) => {
  const { mustBeExactlyNCharacters, requiredText } = commonRules(t)

  return Yup.object().shape({
    vin: mustBeExactlyNCharacters(VIN_LENGTH).concat(
      requiredText(t('carFileSummary.addVinNumberDialog.form.vin.label')),
    ),
  })
}

export const addLicensePlateNumberDialogFormValidation = (t) => {
  const { requiredText, mustBeALicensePlate } = commonRules(t)

  return Yup.object().shape({
    licensePlate: mustBeALicensePlate.concat(requiredText(t('licensePlate'))),
  })
}

export const carShareUploadDialogFormValidation = (t) => {
  const { requiredText } = commonRules(t)

  return Yup.object().shape({
    fileName: Yup.mixed()
      // Tests if the fileName is set, derived from the file that has been set
      .test(
        'is-file-set',
        t('fieldIsRequired', {
          fieldName: t('carShare.uploadDialog.form.file.label'),
        }),
        function (fileName) {
          return !!fileName
        },
      )
      // Tests if the file that has been set isn't greater than 1MB
      .test(
        'is-file-too-big',
        t('carShare.uploadDialog.form.file.fileIsTooBig'),
        function () {
          const fileSize = this.parent?.file?.size
          return !!fileSize && fileSize < 1000000
        },
      ),
    status: requiredText(t('carShare.uploadDialog.form.status.label')),
    enrichment: requiredText(t('carShare.uploadDialog.form.enrichment.label')),
    purchasingCompany: requiredText(
      t('carShare.uploadDialog.form.purchasingCompany.label'),
    ),
    type: requiredText(t('carShare.uploadDialog.form.type.label')),
  })
}

export const licensePlateSearchFormValidation = (t) => {
  const {
    requiredText,
    requiredNumber,
    mustBeAboveNumber,
    mustBeALicensePlate,
  } = commonRules(t)

  return Yup.object().shape({
    [LICENSE_PLATE_SEARCH_FORM_FIELDS.LICENSE_PLATE]:
      mustBeALicensePlate.concat(requiredText(t('licensePlate'))),
    [LICENSE_PLATE_SEARCH_FORM_FIELDS.MILEAGE]: mustBeAboveNumber(0).concat(
      requiredNumber(t('mileage')),
    ),
  })
}

export const vinSearchFormValidation = (t) => {
  const {
    requiredText,
    requiredNumber,
    mustBeAboveNumber,
    mustBeExactlyNCharacters,
  } = commonRules(t)

  return Yup.object().shape({
    [VIN_SEARCH_FORM_FIELDS.VIN]: mustBeExactlyNCharacters(VIN_LENGTH).concat(
      requiredText(t('vin')),
    ),
    [VIN_SEARCH_FORM_FIELDS.PROVIDER]: requiredText(t('dataProvider')),
    [VIN_SEARCH_FORM_FIELDS.MILEAGE]: mustBeAboveNumber(0).concat(
      requiredNumber(t('mileage')),
    ),
  })
}
