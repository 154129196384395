import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GET_WARRANTY_PROGRAMS_FOR_OVERVIEW_QUERY } from '../../../../../../config/graphql/csp'
import BodyHeading from '../../../../../atoms/body-heading'
import Text from '../../../../../atoms/text'
import Icon from '../../../../../atoms/icon'
import Button from '../../../../../atoms/button'
import TextLink from '../../../../../atoms/text-link'
import LoadingIndicator from '../../../../../atoms/loading-indicator'
import NewTextInput from '../../../../../atoms/new-text-input'
import EnhancedTable from '../../../../../organisms/enhanced-table'

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.sizings.lvl2};
`
const AddProgramTextLink = styled(TextLink)`
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`
const StyledActionButton = styled(Button)`
  float: right;
`
const TableDataText = styled.span`
  color: ${({ theme, color }) =>
    color === 'errorText' ? theme.colors.errorText : theme.colors.text};
  font-family: ${({ theme }) => theme.font};
  font-size: ${({ theme }) => theme.baseFontSize};
  font-weight: 500;
  display: inline-block;
  line-height: 1.5em;
`
const SearchField = styled(NewTextInput)`
  min-width: 300px;
`

function WarrantyProgramsOverview() {
  const { t } = useTranslation()
  const history = useHistory()
  const { pathname } = useLocation()
  const { data, error, loading } = useQuery(
    GET_WARRANTY_PROGRAMS_FOR_OVERVIEW_QUERY,
  )

  const [searchValue, setSearchValue] = useState()
  const [tableRows, setTableRows] = useState([])

  const tableColumns = [
    {
      id: 'warrantyProvider',
      label: t(
        'carServicePlanAdminWarrantyProgramsOverview.tableHeadings.warrantyProvider',
      ),
    },
    {
      id: 'name',
      label: t(
        'carServicePlanAdminWarrantyProgramsOverview.tableHeadings.name',
      ),
    },
    {
      id: 'brands',
      label: t(
        'carServicePlanAdminWarrantyProgramsOverview.tableHeadings.brands',
      ),
    },
    {
      id: 'warrantyPriceRules',
      label: t(
        'carServicePlanAdminWarrantyProgramsOverview.tableHeadings.warrantyPriceRules',
      ),
    },
    {
      id: 'ownRiskPeriod',
      label: t(
        'carServicePlanAdminWarrantyProgramsOverview.tableHeadings.ownRiskPeriod',
      ),
    },
    {
      id: 'actions',
      label: t(
        'carServicePlanAdminWarrantyProgramsOverview.tableHeadings.actions',
      ),
      alignRight: true,
    },
  ]
  const createTableRow = useCallback(
    (warrantyProgram) => {
      const {
        name,
        brands,
        warrantyProvider,
        warrantyPriceRules,
        ownRiskPeriod,
        id,
      } = warrantyProgram
      const brandNames = brands.map((brand) => brand.name)
      return {
        warrantyProvider: {
          component: warrantyProvider.name,
          data: warrantyProvider.name,
        },
        name: {
          component: name,
          data: name,
        },
        brands: {
          component: brandNames.join(', '),
          data: brandNames.join(', '),
        },
        warrantyPriceRules: {
          component: (
            <TableDataText
              color={warrantyPriceRules.length === 0 ? 'errorText' : undefined}
            >
              {warrantyPriceRules.length || '0'}
            </TableDataText>
          ),
          data: warrantyPriceRules.length,
        },
        ownRiskPeriod: {
          component: t(
            ownRiskPeriod === 1 ? 'monthCountSingular' : 'monthCount',
            { count: ownRiskPeriod },
          ),
          data: ownRiskPeriod,
        },
        actions: {
          component: (
            <StyledActionButton
              iconColor="actionsStandard"
              type="submit"
              level="option"
              icon="edit"
              to={`${pathname}${pathname.endsWith('/') ? '' : '/'}details/${id}/`}
            />
          ),
          data: 'action',
          alignRight: true,
        },
      }
    },
    [pathname, t],
  )

  useEffect(() => {
    if (!error) {
      const rows = data
        ? data.warrantyPrograms.edges.map((edge) => createTableRow(edge.node))
        : []
      setTableRows(rows)
    }
  }, [createTableRow, data, error])

  const filteredTableRows = () => {
    const searchColumns = ['warrantyProvider', 'name', 'brands']
    // This next line case insensitively filters data
    // The search keys are the columns it will look in.
    return searchValue
      ? tableRows.filter(
          (row) =>
            searchColumns.filter((rowKey) => {
              let searchData = ''
              if (typeof row[rowKey].data === 'string') {
                searchData = row[rowKey].data
              }
              return (
                searchData.toLowerCase().indexOf(searchValue.toLowerCase()) !==
                -1
              )
            }).length > 0,
        )
      : tableRows
  }

  return error || loading ? (
    <LoadingIndicator
      error={
        error &&
        t(
          'carServicePlanAdminWarrantyProgramsOverview.errorLoadingWarrantyPrograms',
        )
      }
    />
  ) : (
    <>
      <FlexContainer>
        <div>
          <BodyHeading>
            {t('carServicePlanAdminWarrantyProgramsOverview.header')}
          </BodyHeading>
          <Text text={t('carServicePlanAdminWarrantyProgramsOverview.body')} />
        </div>
        <SearchField
          filled
          onChange={(val) => setSearchValue(val)}
          value={searchValue}
          label={t('carServicePlanAdminWarrantyProgramsOverview.searchLabel')}
          icon={<Icon type="search" color="brandCharlie" />}
        />
      </FlexContainer>
      <EnhancedTable
        selectable={false}
        columns={tableColumns}
        rows={filteredTableRows()}
      />
      <AddProgramTextLink
        text={t('carServicePlanAdminWarrantyProgramsOverview.addProvider')}
        onClick={() =>
          history.push(`${pathname}${pathname.endsWith('/') ? '' : '/'}new/`)
        }
      />
    </>
  )
}

export default WarrantyProgramsOverview
