import React from 'react'
import PropTypes from 'prop-types'
import DropDownMenu from 'components/atoms/dropdown-menu'

/**
 * @deprecated Use DropDownFilter instead
 */
function AdsOverviewFilter({ filters, onFilterChange, selectedFilter }) {
  const items = filters.map((filter) => ({
    label: filter.label,
    value: filter.id,
  }))
  return (
    <>
      <DropDownMenu
        items={items}
        onChange={onFilterChange}
        selected={selectedFilter}
      />
    </>
  )
}

AdsOverviewFilter.propTypes = {
  filters: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  selectedFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}

export default AdsOverviewFilter
