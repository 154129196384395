import React from 'react'
import PropTypes from 'prop-types'
import { media } from 'utilities/styled'
import CompareGroupedValuesChart from 'components/molecules/compare-grouped-values-chart'
import LegendBoxes from 'components/molecules/legend-boxes'
import Text from 'components/atoms/text'
import styled from 'styled-components/macro'

const Container = styled.article`
  display: grid;
  grid-gap: ${({ theme }) => theme.sizings.lvl2};
  grid-template-columns: 1fr;
  /* enough space for heading text in header area: */
  grid-template-rows: ${({ theme }) => theme.baseSize * 5} 2fr 1fr;
  grid-template-areas:
    'header'
    'chart'
    'legends';
`

const StyledCompareGroupedValuesChart = styled(CompareGroupedValuesChart)`
  grid-area: chart;
`
const StyledAside = styled.aside`
  grid-area: legends;
  display: grid;
  grid-row: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.sizings.lvl2};
`

const StyledHeading = styled(Text)`
  grid-area: header;
`

// The unconventional styling here is used to make sure
// the optional percentage in the LegendBox-es fits
const StyledLegendBoxes = styled(LegendBoxes)`
  > * > *:last-child > *:last-child {
    flex-direction: column;

    > *:last-child {
      margin-top: ${({ theme }) => theme.sizings.lvl1};
    }
  }

  ${media.tv`
    > * > *:last-child > *:last-child {
      flex-direction: row;

      > *:last-child {
        margin-top: ${({ theme }) => theme.sizings.lvl1};
      }
    }
  `}
`

/**
 *
 */
const SegmentedRangeChart = ({ heading, segments, unit }) => {
  const groupedLegendItems = segments.map((segment) =>
    segment.items.map((item) => ({
      ...item,
      subLabel: segment.label,
      unit,
    })),
  )
  return (
    <Container>
      <StyledHeading type="h2" text={heading} />
      <StyledCompareGroupedValuesChart groupedItems={segments} unit={unit} />
      <StyledAside>
        {groupedLegendItems.map((legendItems, index) => (
          <StyledLegendBoxes
            key={index.toString()}
            items={legendItems}
            unit={unit}
          />
        ))}
      </StyledAside>
    </Container>
  )
}

SegmentedRangeChart.propTypes = {
  /**
   * Displayed above the chart
   */
  heading: PropTypes.string.isRequired,
  /**
   * An array of items you want to display in the chart
   */
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  unit: PropTypes.string,
}

SegmentedRangeChart.defaultProps = {
  unit: '',
}

export default SegmentedRangeChart
