import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const HorizontalRule = styled.hr`
  margin: ${({ theme, paddingLevel }) =>
      paddingLevel === 0 ? 0 : theme.sizings[`lvl${paddingLevel}`]}
    0;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.darkOnLightBorder};
  border: 0 none;
  display: block;
  width: 100%;
  &.horizontal {
    color: red;
  }
`

const VerticalRule = styled.span`
  display: inline-block;
  margin: 0
    ${({ theme, paddingLevel }) =>
      paddingLevel === 0 ? 0 : theme.sizings[`lvl${paddingLevel}`]};
  border-left: ${({ theme }) => theme.colors.darkOnLightBorder} 1px solid;
  height: 100%;
`

/**
 * Simple horizontal rule for visually separating to blocks of content from each other.
 *
 */
const ContentSeparator = ({ variant, paddingLevel, className }) =>
  variant === 'horizontal' ? (
    <HorizontalRule paddingLevel={paddingLevel} className={className} />
  ) : (
    <VerticalRule
      paddingLevel={paddingLevel}
      className={className}
      aria-hidden="true"
    />
  )

ContentSeparator.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['horizontal', 'vertical']),
  /** Padding level refers to the sizings in theme.js */
  paddingLevel: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
}

ContentSeparator.defaultProps = {
  className: null,
  variant: 'horizontal',
  paddingLevel: 4,
}

export default ContentSeparator
