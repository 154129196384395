import LoadingIndicator from 'components/atoms/loading-indicator'
import TextLink from 'components/atoms/text-link'
import EnhancedTable from 'components/organisms/enhanced-table'
import { RDW_TRANSFERS_RETRACT_CAR } from 'config/routes'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getRdwSentCars } from 'redux/actions/data'

/**
 * This shows a form that allows the user to check within RDW
 * the ownership of that car.
 *
 * It allow the user to check if a car ownership has been processed by RDW.
 */
const RdwSentCarsOverview = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const rdwSentCars = useSelector((state) => state?.data?.rdwSentCars)

  React.useEffect(() => {
    /**
     * There is a chance that `rdwSentCars` is actually set, but it contains no data, loading or
     * error props. This happens when you dispatch a `clearData` action. So, we have to do some
     * additional checks to make sure the data is fetched if it is not in the store yet.
     */
    if (
      !rdwSentCars ||
      (!rdwSentCars.data && !rdwSentCars.loading && !rdwSentCars.error)
    ) {
      dispatch(getRdwSentCars())
    }
  }, [dispatch, rdwSentCars])

  const rows = Array.isArray(rdwSentCars?.data)
    ? rdwSentCars.data.map((rowData) => ({
        registrationDate: {
          data: rowData.registrationDate,
          component: rowData.registrationDate,
        },
        licensePlate: {
          data: rowData.licensePlate,
          component: rowData.licensePlate,
        },
        buyer: {
          data: rowData.buyer,
          component: rowData.buyer,
        },
        legalEntity: {
          data: rowData.legalEntity,
          component: rowData.legalEntity,
        },
        expirationDate: {
          data: rowData.expireDate,
          component: rowData.expireDate,
        },
        options: {
          data: '',
          component: (
            <TextLink
              to={`${RDW_TRANSFERS_RETRACT_CAR}?licenseplate=${rowData.licensePlate}`}
              text={t('rdwSentCarsOverview.retractLinkLabel')}
            />
          ),
        },
      }))
    : []

  return (
    <article>
      {!!rdwSentCars && (rdwSentCars.loading || rdwSentCars.error) && (
        <LoadingIndicator error={rdwSentCars.error} />
      )}
      {!!rdwSentCars && Array.isArray(rdwSentCars.data) && (
        <EnhancedTable
          selectable={false}
          columns={[
            {
              id: 'registrationDate',
              label: t('rdwSentCarsOverview.tableHeadings.registrationDate'),
            },
            {
              id: 'licensePlate',
              label: t('licensePlate'),
            },
            {
              id: 'buyer',
              label: t('rdwSentCarsOverview.tableHeadings.buyer'),
            },
            {
              id: 'legalEntity',
              label: t('rdwSentCarsOverview.tableHeadings.legalEntity'),
            },
            {
              id: 'expirationDate',
              label: t('rdwSentCarsOverview.tableHeadings.expirationDate'),
            },
            {
              id: 'options',
              label: '',
            },
          ]}
          rows={rows}
          noDataMessage={t('rdwSentCarsOverview.noDataMessage')}
        />
      )}
    </article>
  )
}

export default RdwSentCarsOverview
