import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ComparisonDonutCharts from 'components/organisms/comparison-donut-charts'

/*
 * Wrapper for the ComparisonDonutCharts that show the competitive position in
 * MarketView so they can be easily conditionally rendered.
 */

function MarketviewCompetitivePosition({ data }) {
  const { t } = useTranslation()
  const competitivePosition = data.competitivePosition[0]
  const competitivePositionComparison =
    data.competitivePositionComparisongroup[0]

  return (
    <article style={{ width: '100%' }}>
      <ComparisonDonutCharts
        unit={t('cars')}
        mainTitleLeft={t('myCompetitivePosition')}
        legendColorLeft="actionsStandard"
        percentageLeft={competitivePosition.marketPercentageOwn}
        leftTitleLeft={t('mySales')}
        leftValueLeft={competitivePosition.own}
        rightTitleLeft={t('salesCompleteMarket')}
        rightValueLeft={competitivePosition.market}
        mainTitleRight={t('competitivePositionComparisonGroup')}
        legendColorRight="brandAlfa"
        percentageRight={
          competitivePositionComparison.marketPercentageComparisonGroup
        }
        leftTitleRight={t('salesComparisonGroup')}
        leftValueRight={competitivePositionComparison.comparisongroup}
        rightTitleRight={t('salesCompleteMarket')}
        rightValueRight={competitivePositionComparison.market}
      />
    </article>
  )
}

MarketviewCompetitivePosition.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MarketviewCompetitivePosition
