import { connect } from 'react-redux'
import { closeImageOverlay, closeSearchbox } from 'redux/actions/ui'
import LayoutOverlays from '../organisms/layout-overlays'

const mapStateToProps = ({ data, ui }) => ({
  carAssets: data.carAssets,
  ui,
})

const mapDispatchToProps = {
  closeImageOverlay,
  closeSearchbox,
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutOverlays)
