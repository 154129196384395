import { DocumentCategories, ImageCategories } from 'config/enums'
import api from 'utilities/api'
import { createFormData, cutoffText } from 'utilities/utils'

const napMap = {
  ja: true,
  nee: false,
}

function mapTodos({
  filters,
  number,
  soort,
  target,
  target_url, // eslint-disable-line camelcase
  title,
}) {
  return {
    filters,
    count: Number(number),
    target,
    title,
    type: soort,
    url: target_url,
  }
}

// This function takes ComplexForm fieldsets, and enriches the fields within with carfile data. This
// is required to make certain ComplexForm fields work (such as the car advertisement text field).
const injectCarFileDataInComplexFormFieldsets = (fieldsets, carFileId) =>
  fieldsets.map((fieldset) =>
    Array.isArray(fieldset.fields) &&
    fieldset.fields.findIndex(
      (field) => field.type === 'advertisementtextareagpt',
    ) >= 0
      ? {
          ...fieldset,
          fields: fieldset.fields.map((field) =>
            field.type === 'advertisementtextareagpt'
              ? {
                  ...field,
                  data: {
                    ...field.data,
                    carFileId,
                  },
                }
              : field,
          ),
        }
      : fieldset,
  )

function getCarfile(instance, { carId, locationId }) {
  return api
    .post(
      '/cl/v3/carfile/databyid',
      {
        auto_id: carId,
        // "vestiging_id" is used in the car-share-car-file, for the CarShare flows
        ...(!!locationId && { vestiging_id: locationId }),
      },
      instance,
    )
    .then((response) => {
      // TODO: Enable these commented out lines when the client decides it's time to do so. (Ticket https://app.clickup.com/t/web0uk)
      const transformedData = {
        ...response.data.data,
        body: response.data.data.carrosserie,
        // bodyIsNotMapped: response.data.data.svid_carrosserie === '0',
        bodyIsNotMapped: false,
        brand: response.data.data.merk,
        // brandIsNotMapped: response.data.data.svid_merk === '0',
        brandIsNotMapped: false,
        buildyear: response.data.data.jaardeel1,
        color: response.data.data.kleur,
        created: response.data.data.datum,
        createdBy: response.data.data.adviseur,
        dateSold: response.data.data.datum_verkocht,
        fuel: response.data.data.brandstof,
        // fuelIsNotMapped: response.data.data.svid_brandstof === '0',
        fuelIsNotMapped: false,
        kind: response.data.data.soort,
        license: response.data.data.kenteken,
        model: response.data.data.modelserie,
        // modelIsNotMapped: response.data.data.svid_modelserie === '0',
        modelIsNotMapped: false,
        mileage: response.data.data.km_binnen,
        nap: napMap[response.data.data.nap],
        powerHP: response.data.data.vermogenpk,
        powerKW: response.data.data.vermogenkw,
        purchaseprice: Number(response.data.data.inkoopprijs),
        sellingprice: response.data.data.verkoopprijs,
        staytime: Number(response.data.data.stadagen),
        taxMargin: response.data.data.btw,
        type: response.data.data.type,
        transmission: response.data.data.schakeling,
        status: response.data.data.status,
        location: response.data.data.lokatie,
        id: response.data.data.auto_id,
        catalogValueInclOptions:
          response.data.data.teruggerekendecatalogusprijs,
        privateCarTaxInclOptions:
          response.data.data.teruggerekendebpmnieuwprijs,
        salesTaxCatValInclOpt: response.data.data.teruggerekendebtwnieuwprijs,
        netCatInclOptions: response.data.data.teruggerekendenettocatalogusprijs,
        restPrivateCarTaxValuation: response.data.data.merk,
        restPrivateCarTaxEntry: response.data.data.merk,
        restPrivateCarTaxSales: response.data.data.merk,
        consumerPrice: response.data.data.consumentenprijs,
        privateCarTax: response.data.data.bpm,
        salesTaxvalue: response.data.data.btwwaarde,
        netPrice: response.data.data.nettocatalogusprijs,
        cylinders: response.data.data.cylinder,
        cylindersContent: response.data.data.cylinderinhoud,
        wheelbaseNap: response.data.data.wielbasis,
        topSpeed: response.data.data.topsnelheid,
        taxAddition: response.data.data.bijtelling,
        chassisNumber: response.data.data.chassisnr,
        keyNumber: response.data.data.sleutelnr,
        keyQuantity: response.data.data.sleutels,
        minimumRoadTaxes: response.data.data.wegenbelastingmin,
        maximumRoadTaxes: response.data.data.wegenbelastingmax,
        weight: response.data.data.gewicht,
        massRoadworthy: response.data.data.massarijklaar,
        allowedMaxMass: response.data.data.maxiummassa,
        loadCapacity: response.data.data.laadvermogen,
        unrestrained: response.data.data.maxmassaongeremd,
        restrained: response.data.data.maxmassageremd,
        trailerRestrained: response.data.data.maxmassaopleggergeremd,
        autonomouslyRestrained: response.data.data.maxmassaautonoomgeremd,
        centerAxisRestrained: response.data.data.maxmassamiddenasgeremd,
        g3Installation: response.data.data.g3installatie,
        dieselParticulateFilter: response.data.data.roetfilter,
        dieselParticulates: response.data.data.roetdeeltjes,
        co2Emission: response.data.data.co2uitstoot,
        emissionCode: response.data.data.emissiecode,
        euroClass: response.data.data.euroklasse,
        energyLabel: response.data.data.euroklasse,
        fuelCity: response.data.data.brandstofstad,
        fuelRural: response.data.data.brandstofbuitenweg,
        fuelCombi: response.data.data.brandstofcombi,
        dateApk: response.data.data.apk,
        dateFirstAccepted: response.data.data.toelating,
        datePartOne: response.data.data.deel1,
      }
      return transformedData
    })
}

function getB2bCarFile(instance, { carId }) {
  return api
    .post('/cl/v3/carfile/databyidb2b', { auto_id: carId }, instance)
    .then((response) => {
      const transformedData = {
        ...response.data.data,
        body: response.data.data.carrosserie,
        brand: response.data.data.merk,
        buildyear: response.data.data.jaardeel1,
        color: response.data.data.kleur,
        created: response.data.data.datum,
        createdBy: response.data.data.adviseur,
        dateSold: response.data.data.datum_verkocht,
        fuel: response.data.data.brandstof,
        kind: response.data.data.soort,
        license: response.data.data.kenteken,
        model: response.data.data.modelserie,
        mileage: response.data.data.km_binnen,
        nap: napMap[response.data.data.nap],
        powerHP: response.data.data.vermogenpk,
        powerKW: response.data.data.vermogenkw,
        purchaseprice: Number(response.data.data.inkoopprijs),
        sellingprice: response.data.data.verkoopprijs,
        staytime: Number(response.data.data.stadagen),
        taxMargin: response.data.data.btw,
        type: response.data.data.type,
        transmission: response.data.data.schakeling,
        status: response.data.data.status,
        location: response.data.data.lokatie,
        id: response.data.data.auto_id,
        catalogValueInclOptions:
          response.data.data.teruggerekendecatalogusprijs,
        privateCarTaxInclOptions:
          response.data.data.teruggerekendebpmnieuwprijs,
        salesTaxCatValInclOpt: response.data.data.teruggerekendebtwnieuwprijs,
        netCatInclOptions: response.data.data.teruggerekendenettocatalogusprijs,
        restPrivateCarTaxValuation: response.data.data.merk,
        restPrivateCarTaxEntry: response.data.data.merk,
        restPrivateCarTaxSales: response.data.data.merk,
        consumerPrice: response.data.data.consumentenprijs,
        privateCarTax: response.data.data.bpm,
        salesTaxvalue: response.data.data.btwwaarde,
        netPrice: response.data.data.nettocatalogusprijs,
        cylinders: response.data.data.cylinder,
        cylindersContent: response.data.data.cylinderinhoud,
        wheelbaseNap: response.data.data.wielbasis,
        topSpeed: response.data.data.topsnelheid,
        taxAddition: response.data.data.bijtelling,
        chassisNumber: response.data.data.chassisnr,
        keyNumber: response.data.data.sleutelnr,
        keyQuantity: response.data.data.sleutels,
        minimumRoadTaxes: response.data.data.wegenbelastingmin,
        maximumRoadTaxes: response.data.data.wegenbelastingmax,
        weight: response.data.data.gewicht,
        massRoadworthy: response.data.data.massarijklaar,
        allowedMaxMass: response.data.data.maxiummassa,
        loadCapacity: response.data.data.laadvermogen,
        unrestrained: response.data.data.maxmassaongeremd,
        restrained: response.data.data.maxmassageremd,
        trailerRestrained: response.data.data.maxmassaopleggergeremd,
        autonomouslyRestrained: response.data.data.maxmassaautonoomgeremd,
        centerAxisRestrained: response.data.data.maxmassamiddenasgeremd,
        g3Installation: response.data.data.g3installatie,
        dieselParticulateFilter: response.data.data.roetfilter,
        dieselParticulates: response.data.data.roetdeeltjes,
        co2Emission: response.data.data.co2uitstoot,
        emissionCode: response.data.data.emissiecode,
        euroClass: response.data.data.euroklasse,
        energyLabel: response.data.data.euroklasse,
        fuelCity: response.data.data.brandstofstad,
        fuelRural: response.data.data.brandstofbuitenweg,
        fuelCombi: response.data.data.brandstofcombi,
        dateApk: response.data.data.apk,
        dateFirstAccepted: response.data.data.toelating,
        datePartOne: response.data.data.deel1,
      }
      return transformedData
    })
}

function getLicensePlate(instance, data) {
  const formData = new FormData()

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value)
  })

  return api
    .post('/cl/carfile/databylicenseplate', formData, instance)
    .then((response) => response.data.data)
}

function setCarkind(instance, data) {
  return api
    .post('/cl/v3/carfile/updatesoort', data, instance)
    .then((response) => response.data.data)
}

function getCarAssets(instance, { carId }) {
  return api
    .get('/cl/v3/carimages/list', instance, { auto_id: carId })
    .then((response) => response.data.data)
}

/**
 *
 * @param {Object} instance
 * @param {Object} parameters
 * @param {String} parameters.carId
 * @param {String} parameters.carImageType
 * @returns
 */
function getCarMediaAssets(
  instance,
  { carFileId, carImageCategory, carImageType },
) {
  const options = {
    auto_id: carFileId,
  }
  if (carImageCategory) {
    options.category = carImageCategory
  }
  if (carImageType) {
    options.carimagetype = carImageType
  }
  return api
    .get('/cl/v3/carimages/list', instance, options)
    .then((response) => response.data.data)
}

function getCarDocumentAssets(instance, { carFileId, documentCategory }) {
  return api
    .get('/cl/v3/carimages/list', instance, {
      auto_id: carFileId,
      category: documentCategory,
    })
    .then((response) => response.data.data)
}

function setCarAssetCategory(instance, { assetId, category }) {
  return api
    .get('/cl/v3/carimages/updatecategory', instance, { id: assetId, category })
    .then((response) => response.data.data)
}

function getTodos(instance) {
  return api
    .get('/cl/v3/dashboard/notifications', instance)
    .then((response) => ({
      count: response.data.total,
      items: response.data.data.map(mapTodos),
    }))
}

function getLocationTodos(instance, { locationId }) {
  return api
    .get('/cl/v3/dashboard/notifications', instance, {
      vestiging_id: locationId,
    })
    .then((response) => ({
      count: response.data.total,
      items: response.data.data.map(mapTodos),
    }))
}

function setCarStatus(instance, data) {
  const { currentStatus, ...otherData } = data
  const endpoint = {
    lopend: 'statusverwacht',
    verwacht: 'statusbinnen',
    binnen: 'statusverkocht',
    verkocht: 'statusafgeleverd',
  }[currentStatus]

  return api.post(`/cl/v3/carfile/${endpoint}`, otherData, instance)
}

function getStockFilters(instance, params) {
  return api
    .get('/cl/v3/overviews/filters', instance, params)
    .then((response) => response.data.data)
}

function getStockSortOptions(instance, params) {
  return api
    .get('/cl/v3/overviews/sorttypes', instance, params)
    .then((response) => response.data.data)
}

function getStockResults(instance, params) {
  return api
    .get('/cl/v3/overviews/overview', instance, params)
    .then((response) => ({
      total: Number(response.data.total),
      items: response.data.data.map((item) => ({
        ...item,
        model: item.modelserie,
        sta: item.stadagen || 0,
      })),
    }))
}

function getB2bTradePlatformFilters(instance, params) {
  return api
    .get('/cl/v3/overviews/overviewb2bfilters', instance, params)
    .then((response) => response.data.data)
}

function getB2bTradePlatformSortOptions(instance, params) {
  return api
    .get('/cl/v3/overviews/sorttypesb2b', instance, params)
    .then((response) => response.data.data)
}

function placeB2bCarFileInterest(instance, params) {
  return api
    .get('/cl/v3/b2b/interesse', instance, params)
    .then((response) => response.data)
}

function getB2bTradePlatformResults(instance, params) {
  return api
    .get('/cl/v3/overviews/overviewb2b', instance, params)
    .then((response) => ({
      total: Number(response.data.total),
      items: response.data.data.map((item) => ({
        ...item,
        model: item.modelserie,
        sta: item.stadagen || 0,
      })),
    }))
}

function getB2bCarFileAssets(instance, { carId }) {
  return api
    .get('/cl/v3/carimages/listb2b', instance, { auto_id: carId })
    .then((response) => response.data.data)
}

function getB2bCarFileDamage(instance, data) {
  return api
    .get('/cl/v3/damage/listb2b', instance, data)
    .then((response) => response.data)
}

function getRdwStockResults(instance, params) {
  return api
    .get('/cl/v3/overviews/overviewrdw', instance, {
      page: 1,
      sort: 'sta',
      ...params,
    })
    .then((response) => response.data)
}

function getSearchResults(instance, params) {
  return api
    .get('/cl/v3/overviews/search', instance, params)
    .then((response) => ({
      total: Number(response.data.total),
      items: response.data.data.map((item) => ({
        // include original data too, makes development easier:
        ...item,
        appraisal: item.taxatiewaarde,
        brand: item.merk,
        expected: item.datum_verwacht,
        image: item.image,
        id: item.id,
        mileage: Number(item.km),
        license: item.kenteken,
        margin: item.marge,
        model: item.modelserie,
        month: item.maanddeel1,
        sellingPrice: item.verkoopprijs,
        staytime: item.stadagen,
        status: item.status,
        type: item.type,
        year: item.jaardeel1,
      })),
    }))
}

function addCarAssets(
  instance,
  data,
  carId,
  onProgress,
  additionalBody = null,
) {
  return api.upload(
    '/cl/v3/carimages/create',
    data,
    instance,
    { auto_id: carId, category: 'internet', ...additionalBody },
    onProgress,
  )
}

function addCarShareStockData(
  instance,
  data,
  _, // In other functions, this is the carId. We're not using it in this case, but the positioning of the arguments should remain the same.
  onProgress,
) {
  return api.upload(
    '/cl/v3/carshare/importupdate',
    data,
    instance,
    undefined,
    onProgress,
  )
}

function addCarAssetsImageInternal(instance, data, carId, onProgress) {
  return addCarAssets(instance, data, carId, onProgress, {
    category: ImageCategories.INTERNAL,
  })
}

function addCarAssetsImageCommercial(instance, data, carId, onProgress) {
  return addCarAssets(instance, data, carId, onProgress, {
    category: ImageCategories.COMMERCIAL,
  })
}

// This function uploads a document without a category attached to it. You will probably never have to use this but it is here just in case.
function addCarAssetsDocuments(instance, data, carId, onProgress) {
  return addCarAssets(instance, data, carId, onProgress, { asdocument: 'true' })
}

// Add a document in the "internal" category
function addCarAssetsDocumentsInternal(instance, data, carId, onProgress) {
  return addCarAssets(instance, data, carId, onProgress, {
    asdocument: 'true',
    category: DocumentCategories.INTERNAL,
  })
}

// Add a document in the "commercial" category
function addCarAssetsDocumentsCommercial(instance, data, carId, onProgress) {
  return addCarAssets(instance, data, carId, onProgress, {
    asdocument: 'true',
    category: DocumentCategories.COMMERCIAL,
  })
}

function deleteCarAssets(instance, { carId, assetIds, type }) {
  const endpoint = type === 'images' ? 'deleteimages' : 'deletedocs'

  return api.post(`/cl/v3/carimages/${endpoint}`, assetIds, instance, {
    auto_id: carId,
  })
}

function sortCarAssets(instance, { carId, sortOrder, category }) {
  return api.post('/cl/v3/carimages/update', sortOrder, instance, {
    auto_id: carId,
    category,
  })
}

function getAds(instance, params) {
  return api
    .get('/cl/v3/portalsfeedback/data', instance, {
      filter_id: params.filterId,
      search: params.search,
      vestiging_id: params.locationId,
      lines: params.lines,
      page: params.page,
    })
    .then((response) => ({
      ...response.data.data,
      total: Number(response.data.total),
    }))
}

function getAdsFilters(instance) {
  return api
    .get('/cl/v3/portalsfeedback/filters', instance)
    .then((response) => response.data.data)
}

function getLocations(instance) {
  return api
    .get('/cl/v3/company/list', instance)
    .then((response) => response.data.data)
}

function getUserLocations(instance) {
  return api
    .get('/cl/v3/company/userlocations', instance)
    .then((response) => response.data.data)
}

function getLocationsDetails(instance) {
  return api
    .get('/cl/v3/company/overview', instance)
    .then((response) => response.data.data)
}

function deleteB2bRelation(instance, params) {
  return api
    .get('/cl/v3/b2bprospect/deletecompany', instance, params)
    .then((response) => response.data.data)
}

function getB2bRelations(instance) {
  return api
    .get('/cl/v3/b2bprospect/list', instance)
    .then((response) => response.data.data)
}

function getB2bInterests(instance, params) {
  return api
    .get('/cl/v3/b2bprospect/interesse', instance, params)
    .then((response) => response.data)
}

function getB2bPurchaseInterests(instance, params) {
  return api
    .get('/cl/v3/b2bprospect/interessevestiging', instance, params)
    .then((response) => response.data)
}

function getPortals(instance, params) {
  return api
    .get('/cl/v3/portals/list', instance, params)
    .then((response) => response.data.data)
}
function getActivePortals(instance, params) {
  return api
    .get('/cl/v3/portals/listcompany', instance, params)
    .then((response) => response.data.data)
}
function getPortalPreaddConfirmation(instance, params) {
  return api
    .get('/cl/v3/portals/preadd', instance, params)
    .then((response) => response.data.data)
}
function addPortal(instance, data) {
  return api
    .post('/cl/v3/portals/addportal', data, instance, data)
    .then((response) => response.data.data)
}
function removePortal(instance, data) {
  return api
    .post('/cl/v3/portals/deleteportal', data, instance, data)
    .then((response) => response.data.data)
}
function addAllVehiclesToPortal(instance, data) {
  return api
    .post('/cl/v3/portals/bulkadd', data, instance, data)
    .then((response) => response.data.data)
}
function removeAllVehiclesFromPortal(instance, data) {
  return api
    .post('/cl/v3/portals/bulkdelete', data, instance, data)
    .then((response) => response.data.data)
}
function setPortalDefault(instance, data, params) {
  return api
    .post('/cl/v3/portals/setdefault', data, instance, params)
    .then((response) => response.data.data)
}

function deleteB2bInterests(instance, params) {
  return api
    .get('/cl/v3/b2bprospect/deleteinteresse', instance, params)
    .then((response) => response.data.data)
}

function deleteB2bPurchaseInterests(instance, params) {
  return api
    .get('/cl/v3/b2bprospect/deleteinteresse', instance, params)
    .then((response) => response.data.data)
}

function getEditions(instance, data) {
  return api
    .post('/cl/v3/carfile/jato_editions', createFormData(data), instance)
    .then((response) => response.data.data)
}

function getEditionsForBpmDeclaration(instance, params) {
  return api
    .get('/cl/v3/carfileimport/createselection', instance, params)
    .then((response) => response.data.data)
}

function getFilteredEditionsForBpmDeclaration(instance, params) {
  return api
    .get('/cl/v3/carfileimport/readselection', instance, params)
    .then((response) => response.data.data)
}

function createCarfile(instance, data) {
  return api.post('/cl/v3/carfile/create', data, instance)
}

function createCarfileByEdition(instance, data) {
  return api.post('/cl/v3/carfile/createbyedition', data, instance)
}

function createCarfileByNat(instance, data) {
  return api.post('/cl/v3/carfile/createbynat', data, instance)
}

function createValuationCarfileByEdition(instance, data) {
  return api.post('/cl/v3/carfile/createtaxbyedition', data, instance)
}

function updateCarfile(instance, data) {
  return api.post('/cl/v3/carfile/update', data, instance)
}

function getAccessories(instance) {
  return api
    .post('/cl/v3/carfile/accessoires', null, instance, {
      dir: 'ASC',
      limit: 5000,
      page: 1,
      sort: 'omschrijving',
      start: 0,
    })
    .then((response) => response.data.data)
}

function getAllAccessories(instance, data) {
  return api
    .post('/cl/v3/carfile/allaccessoires', data, instance, {
      dir: 'ASC',
      limit: 5000,
      page: 1,
      sort: 'omschrijving',
      start: 0,
    })
    .then((response) => response.data.data)
}

function getStayTime(instance, { locationId, destination }) {
  return api
    .post(
      '/cl/dashboard/stadagen',
      createFormData({ vestiging_id: locationId, 'soort[]': destination }),
      instance,
    )
    .then((response) => response.data.data)
    .then((data) => {
      const processedData = data.dataset[1].data.map((item, index) => ({
        name: data.categories.category[index].label,
        value: item.value,
      }))

      return {
        series: processedData,
        chart: data?.chart,
      }
    })
}

function getNews() {
  return new Promise((resolve) => {
    api
      .getStatic(`https://go-remarketing.com/feed/json?ts=${Date.now()}`)
      .then((response) => {
        const { items } = response
        const parsedItems = items.map((item) => {
          const content = item.content_text.replace(item.title, '')

          return {
            id: item.id.slice(item.id.indexOf('=') + 1), // Gets the ID of the WordPress post
            date_published: item.date_published,
            intro: cutoffText(content, 300),
            img: item.image,
            link: item.url,
            title: item.title,
            tags: item.tags,
          }
        })
        return resolve(parsedItems)
      })
  })
}

function getSupportRequestDetails(instance) {
  return api
    .get('/cl/v3/help/maillink', instance)
    .then((response) => response.data.data)
}

function sendSupportRequest(instance, data) {
  return api
    .post('/cl/v3/help/send', data, instance)
    .then((response) => response.data.data)
}

function sendSupportRequestMarktview(instance, data) {
  return api
    .post('/cl/v3/help/sendmarketview', data, instance)
    .then((response) => response.data.data)
}

function sendPawnListRequest(instance, data) {
  return api
    .post('/cl/v3/company/pandlijst', data, instance)
    .then((response) => response.data.data)
}

function getNap(instance, { licensePlate, mileage }) {
  return api
    .post(
      '/cl/nap/addmilage',
      createFormData({
        auto_id: '',
        license: licensePlate,
        milage: Number(mileage),
      }),
      instance,
    )
    .then((response) => response.data.data)
}

function getCarBrands(instance, data) {
  return api
    .get('/cl/v3/carfile/jato_brand', instance, data)
    .then((response) => response.data.data)
}

function getCarAccessoiresForBpmDeclaration(instance, data) {
  return api
    .get('/cl/v3/carfileimport/accessoires', instance, data)
    .then((response) => response.data.data)
}

function getEurotaxValue(instance, data) {
  return api
    .get('/cl/v3/carfileimport/eurotaxwaarde', instance, data)
    .then((response) => response.data.data)
}

function addCarAccessoiresInBpmDeclaration(instance, data) {
  return api
    .post('/cl/v3/carfile/addnewacc', data, instance)
    .then((response) => response.data.data)
}

function getCarBrandsForBpmDeclaration(instance, data) {
  return api
    .get('/cl/v3/carfileimport/merken', instance, data)
    .then((response) => response.data.data)
}

function getMarktplaats(instance, data) {
  return api
    .get('/cl/v3/marktplaats/formdata', instance, data)
    .then((response) => response.data.data)
}

function updateMarktplaatsFeature(instance, data) {
  return api.post('/cl/v3/marktplaats/features', data, instance)
}

function getCarModels(instance, data) {
  return api
    .get('/cl/v3/carfile/jato_model', instance, data)
    .then((response) => response.data.data)
}

function getCarModelsForBpmDeclaration(instance, data) {
  return api
    .get('/cl/v3/carfileimport/modellen', instance, data)
    .then((response) => response.data.data)
}

function getCarTypes(instance, data) {
  return api
    .get('/cl/v3/carfile/voertuigsoorten', instance, data)
    .then((response) => response.data.data)
}

function getCarTypesForBpmDeclaration(instance, data) {
  return api
    .get('/cl/v3/carfileimport/voertuigsoorten', instance, data)
    .then((response) => response.data.data)
}

function getMenus(instance) {
  return api
    .get('/cl/v3/navigation/menus', instance)
    .then((response) => response.data.data)
}

function getActionBarItems(instance) {
  return api
    .get('/cl/v3/navigation/actionmenu', instance)
    .then((response) => response.data.data)
}

// "Action Menu" is the menu with the three dots in the stock overview page
function getActionMenuItems(instance) {
  return api
    .get('/cl/v3/navigation/overviewmenu', instance)
    .then((response) => response.data.data)
}

// "Data providers" are the VIN data providers (and more) used in e.g. the TmeUpload flow
function getDataProviders(instance) {
  return api
    .get('/cl/v3/carfile/dataproviders', instance)
    .then((response) => response.data.data)
}

function getCarComments(instance, data) {
  return api
    .get('/cl/v3/automessages/getbyautoid', instance, data)
    .then((response) => response.data.data)
}

function addCarComment(instance, data) {
  return api
    .post('/cl/v3/automessages/add', data, instance)
    .then((response) => response.data.data)
}

function updateRdwItem(instance, data) {
  return api
    .post('/cl/v3/overviews/updaterdw', data, instance)
    .then((response) => response.data.data)
}

// A generic data function to call an endpoint that is dynamically
// passed to this function. Brought into existance because of the
// action bar's backend data that needs to specify enpoints dynamically.
function getDynamicEndpoint(instance, { endpoint }) {
  return api.get(endpoint, instance).then((response) => response.data)
}

function getUsersForLocation(instance, data) {
  return api
    .get('/cl/v3/account/read', instance, data)
    .then((response) => response.data)
}

function getPdfPreview(instance, data) {
  return api
    .post('/cl/v3/pdfconfig/preview', data, instance)
    .then((response) => response.data.data)
}

// A generic data function to post to an endpoint that is dynamically
// passed to this function. Brought into existance because of the
// action bar's backend data that needs to specify enpoints dynamically.
function postDynamicEndpoint(instance, { endpoint, ...otherData }) {
  // Log data if endpoint was not given since a lot of dynamic enpoints
  // from the backend are still missing and we can verify the frontend
  // should be working this way
  if (!endpoint) {
    console.warn(
      'Endpoint was not provided, this is the data that would be posted:\n',
      otherData,
    )
    return Promise.resolve()
  }
  return api
    .post(endpoint, otherData, instance)
    .then((response) => response.data)
}

function getTransactions(instance, params) {
  return api
    .get('/cl/v3/transacties/overzicht', instance, {
      page: 1,
      ...params,
    })
    .then((response) => response.data)
}

function getTransactionTypes(instance) {
  return (
    api
      .get('/cl/v3/transacties/soorten', instance)
      // after retrieval the data is formatted in
      // an array like structure to make it easier to consume:
      .then((response) =>
        Object.entries(response.data.data)
          .sort((a, b) => b[0].localeCompare(a[0]))
          .map(([key, value]) => ({
            label: value,
            id: key,
          })),
      )
  )
}

function rotateImages(instance, data) {
  const requests = data.imageIds.map((id) =>
    api.get('/cl/v3/carimages/rotate', instance, {
      ...data,
      image_id: id,
    }),
  )
  return Promise.all(requests).then((responses) =>
    responses.map((response) => response.data.data),
  )
}

function getBids(instance, data) {
  return api
    .get('/cl/v3/bidservice/carfile', instance, data)
    .then((response) => response.data.data)
}

function addOpticalDamage(instance, data) {
  return api
    .post('/cl/v3/damage/create', data, instance)
    .then((response) => response.data.data)
}

function addTechnicalDamage(instance, data) {
  return api
    .post('/cl/v3/damage/createtechnisch', data, instance)
    .then((response) => response.data.data)
}

function getDamage(instance, data) {
  return api
    .get('/cl/v3/damage/list', instance, data)
    .then((response) => response.data.data)
}

function addDamageImages(instance, data, params) {
  return api
    .post('/cl/v3/carimages/createdamage', data, instance, params)
    .then((response) => response.data.data)
}

function deleteDamageImages(instance, data, params) {
  return api
    .post('/cl/v3/carimages/deleteimages', data, instance, params)
    .then((response) => response.data.data)
}

function deleteDamage(instance, data, params) {
  return api
    .post('/cl/v3/damage/delete', data, instance, params)
    .then((response) => response.data.data)
}

function getPriceReport(instance, data) {
  return api
    .get('/cl/v3/rapportage/events', instance, data)
    .then((response) => response.data.data)
}

function getPriceChartData(instance, data) {
  return api
    .get('/cl/v3/rapportage/graphdata', instance, data)
    .then((response) => response.data.data)
}

function requestPeriodicPriceCheck(instance, data) {
  return api
    .post('/cl/v3/rapportage/startpricecheck', data, instance)
    .then((response) => response.data.data)
}

function createCarFileUsingCarCollect(instance, { licensePlate }) {
  return api
    .get('/cl/v3/carcollect/ophalen', instance, {
      kenteken: licensePlate,
    })
    .then((response) => response.data.data)
}

function getRdwSentCars(instance, data) {
  return api
    .get('/cl/v3/vrijwaren/b2bout', instance, data)
    .then((response) => response.data.data)
}

function getRdwReceivedCars(instance, data) {
  return api
    .get('/cl/v3/vrijwaren/b2bin', instance, data)
    .then((response) => response.data.data)
}

function createMarktplaatsBulkAction(instance, data) {
  return api
    .post('/cl/v3/marktplaats/bulkaction', data, instance)
    .then((response) => response.data)
}

function getMarktplaatsBulkActionStatus(instance) {
  return api
    .get('/cl/v3/marktplaats/bulkactionstatus', instance)
    .then((response) => response.data.data)
}

function getPriceValuation(instance, data) {
  return api
    .post('/cl/v3/bidservice/create', data, instance)
    .then((response) => response.data.data)
}

export default {
  addAllVehiclesToPortal,
  addCarAccessoiresInBpmDeclaration,
  addCarAssets,
  addCarAssetsDocuments,
  addCarAssetsDocumentsCommercial,
  addCarAssetsDocumentsInternal,
  addCarAssetsImageCommercial,
  addCarAssetsImageInternal,
  addCarComment,
  addCarShareStockData,
  addDamageImages,
  addOpticalDamage,
  addPortal,
  addTechnicalDamage,
  createCarFileUsingCarCollect,
  createCarfile,
  createCarfileByEdition,
  createCarfileByNat,
  createMarktplaatsBulkAction,
  createValuationCarfileByEdition,
  deleteB2bInterests,
  deleteB2bPurchaseInterests,
  deleteB2bRelation,
  deleteCarAssets,
  deleteDamage,
  deleteDamageImages,
  getAccessories,
  getActionBarItems,
  getActionMenuItems,
  getActivePortals,
  getAds,
  getAdsFilters,
  getAllAccessories,
  getB2bCarFile,
  getB2bCarFileAssets,
  getB2bCarFileDamage,
  getB2bInterests,
  getB2bPurchaseInterests,
  getB2bRelations,
  getB2bTradePlatformFilters,
  getB2bTradePlatformResults,
  getB2bTradePlatformSortOptions,
  getBids,
  getCarAccessoiresForBpmDeclaration,
  getCarAssets,
  getCarBrands,
  getCarBrandsForBpmDeclaration,
  getCarComments,
  getCarDocumentAssets,
  getCarMediaAssets,
  getCarModels,
  getCarModelsForBpmDeclaration,
  getCarTypes,
  getCarTypesForBpmDeclaration,
  getCarfile,
  getDamage,
  getDataProviders,
  getDynamicEndpoint,
  getEditions,
  getEditionsForBpmDeclaration,
  getEurotaxValue,
  getFilteredEditionsForBpmDeclaration,
  getLicensePlate,
  getLocationTodos,
  getLocations,
  getLocationsDetails,
  getMarktplaats,
  getMarktplaatsBulkActionStatus,
  getMenus,
  getNap,
  getNews,
  getPdfPreview,
  getPortalPreaddConfirmation,
  getPortals,
  getPriceChartData,
  getPriceReport,
  getPriceValuation,
  getRdwReceivedCars,
  getRdwSentCars,
  getRdwStockResults,
  getSearchResults,
  getStayTime,
  getStockFilters,
  getStockResults,
  getStockSortOptions,
  getSupportRequestDetails,
  getTodos,
  getTransactionTypes,
  getTransactions,
  getUserLocations,
  getUsersForLocation,
  injectCarFileDataInComplexFormFieldsets,
  placeB2bCarFileInterest,
  postDynamicEndpoint,
  removeAllVehiclesFromPortal,
  removePortal,
  requestPeriodicPriceCheck,
  rotateImages,
  sendPawnListRequest,
  sendSupportRequest,
  sendSupportRequestMarktview,
  setCarAssetCategory,
  setCarStatus,
  setCarkind,
  setPortalDefault,
  sortCarAssets,
  updateCarfile,
  updateMarktplaatsFeature,
  updateRdwItem,
}
