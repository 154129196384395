import React from 'react'
import SettingsForm from 'components/redux-containers/settings-form-container'
import { useDispatch } from 'react-redux'
import { verifyAuthentication } from 'redux/actions/auth'
import { getForms } from 'redux/actions/forms'
import { getMenus, getActionBarItems } from 'redux/actions/data'

function MyDataSettings() {
  const dispatch = useDispatch()

  return (
    <SettingsForm
      formId="myData"
      onUpdateSuccess={() => {
        dispatch(
          verifyAuthentication((_) => {
            dispatch(getForms('general'))
            dispatch(getForms('myData'))
            dispatch(getMenus())
            dispatch(getActionBarItems())
          }),
        )
      }}
    />
  )
}

MyDataSettings.propTypes = {}

MyDataSettings.defaultProps = {}

export default MyDataSettings
