import { CarIdentityPlateSizes } from 'config/enums'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.brandAlfa};
  font-size: ${({ theme }) => theme.smallFontSize};
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.font};
  white-space: nowrap;
  padding: ${({ theme }) => theme.sizeByFactor(0.625)}
    ${({ theme }) => theme.sizings.lvl1};
  border-radius: ${({ theme }) => theme.sizings.lvl0};
  border: solid 1px ${({ theme }) => theme.colors.brandAlfa};
  text-align: center;
`

const CarFileIdTag = ({ className, carFileId, size }) => {
  const { t } = useTranslation()

  return (
    <StyledSpan className={className}>
      {size === CarIdentityPlateSizes.SMALL && t('dossier')} {carFileId}
    </StyledSpan>
  )
}

CarFileIdTag.propTypes = {
  className: PropTypes.string,
  carFileId: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(CarIdentityPlateSizes)),
}

CarFileIdTag.defaultProps = {
  className: null,
  size: CarIdentityPlateSizes.SMALL,
}

export default CarFileIdTag
