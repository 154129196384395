import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import PageHeader from 'components/organisms/page-header'
import EnhancedCard from 'components/layouts/enhanced-card'
import { useDispatch, useSelector } from 'react-redux'
import { getForms } from 'redux/actions/forms'
import { postDynamicEndpoint } from 'redux/actions/data'
import { translateForm } from 'utilities/form-utils'
import ComplexForm from 'components/molecules/complex-form'
import Button from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import StatusMessage from 'components/molecules/status-message'
import { useLocation } from 'react-router'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.alternativeBackground};
  padding: 0 ${({ theme }) => theme.sizeByFactor(2.5)}; // based on the 20px throughout the app

  ${media.desktop`
    padding: 0 ${({ theme }) => theme.sizeByFactor(7.5)}; // based on the 60px throughout the app
  `}
`

const StyledButtonLink = styled(Button)`
  margin-top: ${({ theme }) => theme.sizings.lvl2};
  width: max-content;
`

/**
 * Allows the dealer to request a rdw license plate document number for
 * an indemnified ('vrijgewaard' in Dutch) car. With it, it also sends
 * along the TNS1 and TNS2 code.
 *
 * Please note that the entered license plate needs a 'meldcode' which is
 * linked to the car (last 4 number of the VIN).
 *
 * Please note, the submit endpoint (part of the form config) always replies with a
 * `success: true` in its response. This is done to evade the standard toast message
 * that is shown when there is an error.
 * Instead errors are communicated via the `melding` property. If set, there was a problem
 * with the request. This solution might not be ideal, but reworking the entire error
 * handling system is too much to ask for a single use-case like this.
 */
const RdwRequestDocumentNumber = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const form = useSelector(
    (state) => state?.forms?.rdwIndemnifyCar?.data?.form_documentnummer,
  )
  // because this form is part of a multi form config request (1 response with 1 or more form config in it)
  // We need to look at the higher up object to read .loading and .error
  const formRequest = useSelector((state) => state?.forms?.rdwIndemnifyCar)
  const [formValues, setFormValues] = React.useState({})
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [formResponse, setFormResponse] = React.useState(null)
  const [formResponseError, setFormResponseError] = React.useState(null)

  React.useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search)
      // These 2 parameters differ between the form fields and the search parameters
      const initialFormValues = {
        kenteken: searchParams.get('licenseplate') ?? '',
        meldcode: searchParams.get('code') ?? '',
      }
      // The rest can be mapped 1 to 1.
      for (const searchParam of searchParams) {
        initialFormValues[searchParam[0]] = searchParam[1]
      }
      setFormValues(initialFormValues)
    }
  }, [location])

  React.useEffect(() => {
    if (!form) {
      dispatch(getForms('rdwIndemnifyCar'))
    }
  }, [dispatch, form])

  const handleFormChange = (values) => {
    setFormValues(values)
  }

  const onSubmit = () => {
    setIsSubmitting(true)
    setFormResponse(null)
    setFormResponseError(null)
    return dispatch(
      postDynamicEndpoint({
        endpoint: form.endpoint,
        ...formValues,
      }),
    )
      .then((response) => {
        setFormResponse(response)
      })
      .catch((error) => {
        setFormResponseError(error)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const translatedForm = translateForm(form?.fieldsets || [], t)

  return (
    <AppLayoutContainer pageTitle={t('rdwRequestDocumentNumber.pageTitle')}>
      <Container>
        <PageHeader
          mainTitle={t('rdwRequestDocumentNumber.mainTitle')}
          hasBorderBottom={false}
        />
        <EnhancedCard component="main" shadow="bottomSoft" columns={2}>
          {formResponse || formResponseError ? (
            formResponse.data.kentekendocumentnummer ? (
              <StatusMessage
                type="success"
                heading={t('rdwRequestDocumentNumber.response.success.heading')}
              >
                {t(
                  'rdwRequestDocumentNumber.response.success.labels.licensePlateDocNumber',
                )}
                :&nbsp;
                {formResponse.data.kentekendocumentnummer}
                <br />
                {t('rdwRequestDocumentNumber.response.success.labels.tns1')}
                :&nbsp;
                {formResponse.data.tns1}
                <br />
                {t('rdwRequestDocumentNumber.response.success.labels.tns2')}
                :&nbsp;
                {formResponse.data.tns2}
              </StatusMessage>
            ) : (
              // Using type warning, because requesting this document number can easily go
              // wrong. So need to scream 'error', because most of the time it is
              // an easy mistake to make.
              <StatusMessage
                type="warning"
                heading={t('rdwRequestDocumentNumber.response.error.heading')}
              >
                {t('rdwRequestDocumentNumber.response.error.introMessage')}
                <br />
                {/* contains feedback from a 3rd party system about the problem: */}
                {formResponseError || formResponse.data.melding}
              </StatusMessage>
            )
          ) : !formRequest || formRequest.loading || formRequest.error ? (
            <LoadingIndicator error={formRequest?.error} />
          ) : (
            <ComplexForm
              level="cta"
              data={formValues}
              fieldsets={translatedForm}
              formId="rdwRequestDocumentNumber"
              onChange={handleFormChange}
              onSubmit={onSubmit}
              submitText={t('rdwRequestDocumentNumber.form.submitButtonText')}
              enableReinitialize
              isLoading={isSubmitting}
            />
          )}
          {formResponse?.data?.pdf && (
            <StyledButtonLink
              href={formResponse.data.pdf}
              text={t('rdwRequestDocumentNumber.response.success.downloadData')}
            />
          )}
        </EnhancedCard>
      </Container>
    </AppLayoutContainer>
  )
}

export default RdwRequestDocumentNumber
