import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ApolloProvider, useQuery } from '@apollo/client'

import { ApiV4ApolloClient } from 'config/apollo'
import { unitsOfMeasurement } from 'config/units'
import { BidsProviderIds } from 'config/enums'

import {
  getPriceReport,
  getPriceChartData,
  requestPeriodicPriceCheck,
} from 'redux/actions/data'
import storage from 'utilities/storage'
import usePriceManagementStoragePrefix from 'hooks/use-price-management-storage-prefix'
import Text from 'components/atoms/text'
import FlexibleDialog from 'components/molecules/flexible-dialog'
import { AD_EVENT_PORTALS, AD_EVENTS } from 'config/graphql/v4'
import TimelineInPeriodsChart from 'components/organisms/timeline-in-periods-chart'
import LoadingIndicator from 'components/atoms/loading-indicator'
import LegendBox from 'components/molecules/legend-box'

function RequestModal({ carFileId, onSubmit, type, ...restProps }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const title = {
    indicata: t('requestIndicataValue'),
    restwaarde: t('requestResidualValue'),
  }[type]

  const text = {
    indicata: t('requestIndicataValueModalText'),
    restwaarde: t('requestResidualValueModalText'),
  }[type]

  const storageId = usePriceManagementStoragePrefix(type)

  function handleSubmit() {
    dispatch(
      requestPeriodicPriceCheck({
        auto_id: carFileId,
        service: type,
      }),
    )
    storage.setPermanent(storageId, true)
    onSubmit()
  }

  return (
    <FlexibleDialog
      title={title}
      content={<Text color="inputLabel" text={text} />}
      submitText={t('agree')}
      buttonText={t('agree')}
      onSubmit={handleSubmit}
      cancel
      {...restProps}
    />
  )
}

RequestModal.propTypes = {
  carFileId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.oneOf([BidsProviderIds.INDICATA, BidsProviderIds.AUTOTELEX])
    .isRequired,
}

function getLatestPrice(rows, type) {
  return rows.reduce((result, data) => {
    if (data.type !== type) {
      return result
    }
    return data.rows[data.rows.length - 1]
  }, 0)
}

function getLatestPriceValue(rows, type) {
  const latestPrice = getLatestPrice(rows, type)
  const value = latestPrice && latestPrice.value
  return value
}

function getLatestPriceDate(rows, type) {
  const latestPrice = getLatestPrice(rows, type)
  const date = latestPrice && latestPrice.date
  return moment(date).format('DD-MM-YYYY')
}

function getFirstEventDate(events) {
  const now = moment()
  return Object.values(events).reduce((result, event) => {
    if (Array.isArray(event)) {
      const first = event.reduce((subResult, subEvent) => {
        const subEventDate = moment(subEvent.datum)

        return subEventDate.isBefore(subResult) ? subEventDate : subResult
      }, now)

      return first.isBefore(result) ? first : result
    }

    return moment(event).isBefore(result) ? event : result
  }, now)
}

/**
 * Wraps the TimelineInPeriodsChart component and provides data to it.
 */
function ManagementInfo({ carFile }) {
  const { t } = useTranslation()
  const [selectedPortals, setSelectedPortals] = useState([])
  const [filteredAdEvents, setFilteredAdEvents] = useState(null)

  const { dealernr: dealerId, id: carFileId } = carFile

  const adEventsPortals = useQuery(AD_EVENT_PORTALS, {
    variables: {
      dealerId,
      carFileId,
    },
  })

  const adEvents = useQuery(AD_EVENTS, {
    variables: {
      dealerId,
      carFileId,
      portals: adEventsPortals.data
        ? adEventsPortals.data.adEventPortals.map((portal) => portal.id)
        : [],
    },
  })

  useEffect(() => {
    if (
      adEventsPortals.data &&
      Array.isArray(adEventsPortals.data.adEventPortals)
    ) {
      setSelectedPortals(
        adEventsPortals.data.adEventPortals.map((portal) => ({
          ...portal,
          selected: true,
        })),
      )
    }
  }, [setSelectedPortals, adEventsPortals.data])

  useEffect(() => {
    if (adEvents.data && adEvents.data.adEventDataPoints) {
      const portalIds = selectedPortals
        .filter((portal) => portal.selected)
        .map((portal) => portal.id)
      if (portalIds.length > 0) {
        setFilteredAdEvents(
          adEvents.data.adEventDataPoints.filter(
            (adEvent) => portalIds.indexOf(adEvent.portal) > -1,
          ),
        )
      } else if (portalIds.length === 0) {
        setFilteredAdEvents([])
      } else {
        setFilteredAdEvents(adEvents.data.adEventDataPoints)
      }
    }
  }, [adEvents.data, selectedPortals])

  const dispatch = useDispatch()
  const purchasePrice = carFile && carFile.inkoopprijs
  const priceChartData = useSelector(
    (state) => state.data.priceChartData && state.data.priceChartData.data,
  )
  const priceChartDataRows = priceChartData && priceChartData.rows
  const priceReport = useSelector(
    (state) => state.data.priceReport && state.data.priceReport.data,
  )
  const [modal, setModal] = useState(false)
  const indicataStorageId = usePriceManagementStoragePrefix(
    BidsProviderIds.INDICATA,
  )
  const restwaardeStorageId = usePriceManagementStoragePrefix(
    BidsProviderIds.AUTOTELEX,
  )
  const storageIds = {
    indicata: indicataStorageId,
    restwaarde: restwaardeStorageId,
  }

  useEffect(() => {
    dispatch(getPriceReport({ auto_id: carFileId }))
    dispatch(getPriceChartData({ auto_id: carFileId }))
  }, [carFileId, dispatch])

  if (adEventsPortals.loading || adEvents.loading) {
    return <LoadingIndicator />
  }

  if (!(priceChartData && priceReport)) {
    return <LoadingIndicator />
  }

  const startDate = getFirstEventDate(priceReport)
  const endDate = moment().format('YYYY-MM-DD HH:mm:ss')
  function getPeriodicPriceChecksActive(service) {
    const periodicPriceCheck =
      priceChartData && priceChartData.periodicPriceCheck

    if (!periodicPriceCheck) {
      return false
    }

    return (
      Array.isArray(periodicPriceCheck) &&
      periodicPriceCheck.indexOf(service) !== -1
    )
  }

  function handleClickLegendLink(type) {
    if (storage.getValue(storageIds[type])) {
      dispatch(
        requestPeriodicPriceCheck({
          auto_id: carFileId,
          service: type,
        }),
      )
      return
    }
    setModal(type)
  }

  /**
   * Converts adEvent into chartJs data point
   * @param {Object} data
   */
  const priceChangeToChartDataPoint = (data) => ({
    x: data.date,
    y: data.value || data.amount,
  })

  /**
   * BuildBarSegmentDataSet accepts a array of adEvents and outputs a ChartJs "segment"
   * BuildBarSegment groups values by week
   * @param {Object} data
   */
  const buildBarSegmentDataSet = (data) => {
    const uniqueAdEvents = [...new Set(data.map((lead) => lead.type))].sort()
    return uniqueAdEvents.map((type) => {
      const filteredData = data
        .filter(
          (adEvent) =>
            adEvent.type === type &&
            moment(adEvent.date).isBefore(moment(endDate)),
        )
        .map((adEvent) => ({
          ...adEvent,
          date: moment(adEvent.date).startOf('week').format('YYYY-MM-DD'),
        }))
      const uniqueStartOfWeekDates = [
        ...new Set(filteredData.map((adEvent) => adEvent.date)),
      ]
      return {
        label: t(`timeLineInPeriodValues.${type}`),
        data: uniqueStartOfWeekDates.map((startOfWeekDate) => {
          const wholeWeekValue = filteredData
            .filter((adEvent) => adEvent.date === startOfWeekDate)
            .map((adEvent) => adEvent.amount)
            .reduce((a, b) => a + b)

          return { x: startOfWeekDate, y: wholeWeekValue }
        }),
      }
    })
  }

  if (Array.isArray(filteredAdEvents)) {
    const sellPriceChanges = priceChartData.rows
      .filter((row) => row.type === 'verkoopprijs')[0]
      .rows.map((item) => priceChangeToChartDataPoint(item))

    /**
     * price changes for autoTelex are provided to the front-end
     * as 'restwaarde':
     */
    const autoTelexPriceChanges = priceChartData.rows
      .filter((row) => row.type === BidsProviderIds.AUTOTELEX)[0]
      .rows.map((item) => priceChangeToChartDataPoint(item))

    // Map leads into the required format:
    const leadAdEvents = filteredAdEvents.filter(
      (adEvent) => adEvent.interaction === 'LEAD',
    )
    const leadSegments = buildBarSegmentDataSet(leadAdEvents)

    // Map impressions into the required format:
    const impressionAdEvents = filteredAdEvents.filter(
      (adEvent) => adEvent.interaction === 'IMPRESSION',
    )
    const impressionSegments = buildBarSegmentDataSet(impressionAdEvents)

    const datasets = [
      {
        label: t('mySalesPrice'),
        legend: {
          value: `€ ${sellPriceChanges[sellPriceChanges.length - 1].y}`,
          optionalValue: t('since', {
            date: moment(
              sellPriceChanges[sellPriceChanges.length - 1].x,
            ).format('DD-MM-YYYY'),
          }),
        },
        displayedAs: 'LINE',
        unit: unitsOfMeasurement.currency_euro,
        color: 'lightBlue',
        data:
          sellPriceChanges.length > 0
            ? [
                ...sellPriceChanges,
                {
                  x: endDate,
                  y: sellPriceChanges[sellPriceChanges.length - 1].y,
                },
              ]
            : [],
      },
      {
        label: t('myPurchasePrice'),
        displayedAs: 'LINE',
        unit: unitsOfMeasurement.currency_euro,
        color: 'softRed',
        legend: {
          optionalValue: t('since', {
            date: moment(startDate).format('DD-MM-YYYY'),
          }),
          value: purchasePrice && `€ ${purchasePrice}`,
        },
        data: [
          {
            x: startDate,
            y: purchasePrice,
          },
          {
            x: endDate,
            y: purchasePrice,
          },
        ],
      },
      {
        label: 'AUTOTELEX',
        displayedAs: 'LINE',
        unit: unitsOfMeasurement.currency_euro,
        color: 'plainYellow',
        data:
          autoTelexPriceChanges.length > 0
            ? [
                ...autoTelexPriceChanges,
                {
                  x: endDate,
                  y: autoTelexPriceChanges[autoTelexPriceChanges.length - 1].y,
                },
              ]
            : [],
        legend: {
          optionalValue:
            priceChartData &&
            t('since', {
              date: getLatestPriceDate(
                priceChartDataRows,
                BidsProviderIds.AUTOTELEX,
              ),
            }),
          label: BidsProviderIds.AUTOTELEX,
          noValueText: t('requestResidualValue'),
          noValueOnClick: () =>
            handleClickLegendLink(BidsProviderIds.AUTOTELEX),
          labelImage: '/images/autotelex.png',
          value:
            getPeriodicPriceChecksActive(BidsProviderIds.AUTOTELEX) &&
            `€ ${getLatestPriceValue(priceChartDataRows, BidsProviderIds.AUTOTELEX)}`,
        },
      },
      {
        label: 'Leads',
        displayedAs: 'BAR',
        color: 'grassyGreen',
        segments: leadSegments,
      },
      {
        label: 'Impressies',
        displayedAs: 'BAR',
        color: 'intensePurple',
        segments: impressionSegments,
      },
    ]

    /**
     * Deduce and compile key events shown in the timeline below the graph.
     */
    const today = moment()
    const releaseDate = moment(priceReport.vrijwaren_datum)
    const standingTimeInDays = parseInt(
      moment.duration(today.diff(releaseDate)).as('days'),
      10,
    )

    const keyEvents = [
      {
        x: priceReport.vrijwaren_datum,
        icon: 'lock',
        label: t('indemnify'),
      },
      // @todo: this below also seems to be stored in priceReport.verkoopprijs_wijzigingen
      // somehow... check with Roel.
      ...sellPriceChanges
        .sort((a, b) => new Date(a.x) - new Date(b.x))
        .map((priceChange, index) => {
          if (index === 0) {
            // Skip the first price change, no icon is needed since there is no previous price to compare this price to
            return undefined
          }
          const previousPriceChange = sellPriceChanges[index - 1]
          const priceDecrease =
            Number(priceChange.y) < Number(previousPriceChange.y)
          const periodInDays = parseInt(
            moment.duration(moment(priceChange.x).diff(releaseDate)).as('days'),
            10,
          )
          const icon = priceDecrease ? 'priceDecrease' : 'priceIncrease'
          const labelText =
            periodInDays < 0
              ? 'managementInfoComp.eventBeforeNDays'
              : 'managementInfoComp.eventAfterNDays'
          const eventText = priceDecrease ? 'priceDecrease' : 'priceIncrease'
          const label = t(labelText, {
            event: t(eventText),
            count: Math.abs(periodInDays),
          })

          return {
            ...priceChange,
            icon,
            label,
          }
        })
        .filter((value) => value !== undefined),
    ]

    if (priceReport.internet_datum) {
      const periodInDays = parseInt(
        moment
          .duration(moment(priceReport.internet_datum).diff(releaseDate))
          .as('days'),
        10,
      )
      const labelText =
        periodInDays < 0
          ? 'managementInfoComp.eventBeforeNDays'
          : 'managementInfoComp.eventAfterNDays'
      keyEvents.push({
        x: priceReport.internet_datum,
        icon: 'publication',
        label: t(labelText, {
          event: t('published'),
          count: Math.abs(periodInDays),
        }),
      })
    }
    if (priceReport.verkocht_datum) {
      const periodInDays = parseInt(
        moment
          .duration(moment(priceReport.verkocht_datum).diff(releaseDate))
          .as('days'),
        10,
      )
      const labelText =
        periodInDays < 0
          ? 'managementInfoComp.eventBeforeNDays'
          : 'managementInfoComp.eventAfterNDays'
      keyEvents.push({
        x: priceReport.verkocht_datum,
        icon: 'ascription',
        label: t(labelText, {
          event: t('sold'),
          count: Math.abs(periodInDays),
        }),
      })
    }

    return (
      <>
        {modal && (
          <RequestModal
            carFileId={carFileId}
            type={modal}
            closeHandler={() => setModal(false)}
            onSubmit={() => setModal(false)}
            submitText={t('agree')}
            open
          />
        )}
        <TimelineInPeriodsChart
          startTicksAtTime={startDate}
          mainHeading="Instellingen"
          linesHeading="Instellingen prijzen"
          barsHeading="Instellingen advertentie interacties"
          datasets={datasets}
          keyEvents={keyEvents}
          showPeriodFilter
          barLegendsSlot={
            <LegendBox
              legendColor="theSameAsBox"
              label="portal"
              selected={
                selectedPortals.length > 0
                  ? selectedPortals.every((portal) => portal.selected)
                  : false
              }
              onSelect={(value) => {
                setSelectedPortals(
                  selectedPortals.map((portal) => ({
                    ...portal,
                    selected: value,
                  })),
                )
              }}
              subCategories={selectedPortals}
              onSelectSubCategories={setSelectedPortals}
            />
          }
          lowerCornerMessage={`${t('standingTimeInDays', { days: standingTimeInDays })}`}
        />
      </>
    )
  }
  return <LoadingIndicator />
}

ManagementInfo.propTypes = {
  carFile: PropTypes.object.isRequired,
}

/**
 * A Sandwich, cuz it is a Provider with a yummy component sandwiched in it:
 */
const ManagementInfoProviderSandwich = (props) => {
  const MemoedApolloClient = React.useMemo(() => ApiV4ApolloClient(), [])

  return (
    <ApolloProvider client={MemoedApolloClient}>
      <ManagementInfo {...props} />
    </ApolloProvider>
  )
}

export default ManagementInfoProviderSandwich
