/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { translateForm } from 'utilities/form-utils'

import configForms from 'config/forms'

import Tabs from 'components/layouts/tabs'
import CarDataForm from 'components/molecules/cardata-form'
import EquipmentForm from 'components/redux-containers/equipment-form-container'

function CarEntryTabs({
  car,
  createCarfile,
  formId,
  forms,
  getForms,
  history,
  manual,
  match,
  onSubmit,
  updateCarfile,
}) {
  const { t } = useTranslation()
  const carVersionFieldsets = manual
    ? configForms.licensePlate.data.entry.fieldsets
    : []
  const basicsForm = translateForm(forms.basics_form.fieldsets, t)
  const specificationsGeneralForm = translateForm(
    forms.specifications_general_form.fieldsets,
    t,
  )
  const specificationsHistoricalForm = translateForm(
    forms.specifications_historical_form.fieldsets,
    t,
  )
  const carId = car.auto_id
  const carLicensePlate = car.kenteken

  const loading = useSelector((state) => {
    const { data } = state
    return (
      (data.createCarfile && data.createCarfile.loading) ||
      (data.updateCarfile && data.updateCarfile.loading)
    )
  })
  const formTopId = `${formId}-top`
  const formTop =
    useSelector(
      (state) =>
        state.form && state.form[formTopId] && state.form[formTopId].values,
    ) || {}

  async function handleSubmit(data, finish) {
    const noErrors = onSubmit ? onSubmit() : true

    if (loading) {
      return false
    }

    if (!noErrors) {
      return false
    }

    const carfileData = {
      kenteken: car.kenteken,
      ...data,
      ...formTop,
    }

    if (carId) {
      await updateCarfile(
        {
          ...car,
          ...carfileData,
        },
        carId,
      )

      await getForms('carfile', { kenteken: carLicensePlate, auto_id: carId })
    } else {
      await createCarfile({
        ...car,
        ...carfileData,
      })
    }

    if (finish) {
      if (!carId) {
        alert(t('enterDetailsAndSaveFirst'))
        return false
      } else {
        history.push(`/car/${carId}/media#published`)
      }
    }

    return true
  }

  const tabItems = [
    {
      label: t('basicData'),
      to: `${match.url}/basic`,
      component: (props) => (
        <CarDataForm
          carFile={car}
          fieldsets={[...carVersionFieldsets, ...basicsForm]}
          formId={formId}
          level="cta"
          onSubmit={handleSubmit}
          manual={manual}
          {...props}
          next
          scrollToTopOnError
        />
      ),
    },
    {
      disabled: !carId,
      label: t('specifications'),
      to: `${match.url}/specifications`,
      component: (props) => (
        <CarDataForm
          carFile={car}
          fieldsets={specificationsGeneralForm}
          formId={formId}
          level="cta"
          onSubmit={handleSubmit}
          manual={manual}
          {...props}
          next
          previous
          scrollToTopOnError
        />
      ),
    },
    {
      disabled: !carId,
      label: t('history'),
      to: `${match.url}/history`,
      component: (props) => (
        <CarDataForm
          carFile={car}
          fieldsets={specificationsHistoricalForm}
          formId={formId}
          level="cta"
          onSubmit={handleSubmit}
          manual={manual}
          {...props}
          next
          previous
          scrollToTopOnError
        />
      ),
    },
    {
      disabled: !carId,
      label: t('equipment'),
      to: `${match.url}/equipment`,
      component: (props) => (
        <EquipmentForm
          {...props}
          extraData={{
            ...car,
            // eslint-disable-next-line react/prop-types
            accessoires_data_edition: car.accessoires_data_edition,
            // eslint-disable-next-line react/prop-types
            accessoires_data_licenseplate: car.accessoires_data_licenseplate,
            // eslint-disable-next-line react/prop-types
            altcode: car.altcode,
          }}
          // eslint-disable-next-line react/prop-types
          carAccessories={car.accessoires}
          closeText={t('goToCarFile')}
          formId={formId}
          onClose={() => null} // TODO: this needs refactoring. Without this prop the next / prev buttons aren't visible. Added now due to hotfix for https://app.clickup.com/t/2501750/UCC-6005
          onSubmit={handleSubmit}
          manual={manual}
          previous
          finish
        />
      ),
    },
  ]

  return (
    <Tabs items={tabItems} hideNavigation>
      {tabItems.map((item, index) => (
        <item.component key={index.toString()} />
      ))}
    </Tabs>
  )
}

CarEntryTabs.propTypes = {
  car: PropTypes.object.isRequired,
  createCarfile: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  forms: PropTypes.object,
  getForms: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  manual: PropTypes.bool,
  onSubmit: PropTypes.func,
  updateCarfile: PropTypes.func.isRequired,
}

CarEntryTabs.defaultProps = {
  forms: null,
  manual: false,
  onSubmit: null,
}

export default withRouter(CarEntryTabs)
