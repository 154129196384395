import React, { useContext, useState } from 'react'
import numbro from 'numbro'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import debounce from 'lodash/debounce'

import storage from 'utilities/storage'
import marketviewData from 'utilities/marktview-data'
import { formatDate } from 'utilities/format'
import { handleChangeQuery, getObjectFromQueryString } from 'utilities/utils'

import { InputParamsContext } from 'contexts/marketview'

import {
  getMarketviewData,
  setMarketViewData,
} from 'redux/actions/marketview-data'

import { marketViewFilterIds } from 'config/data'

import LoadingIndicator from 'components/atoms/loading-indicator'
import EnhancedTable from 'components/organisms/enhanced-table'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import LegendBox from 'components/molecules/legend-box'
import ToolTip from 'components/atoms/tooltip'
import Icon from 'components/atoms/icon'
import CompareTopicsChart from 'components/organisms/compare-topics-chart'
import ContentSeparator from 'components/atoms/content-separator'
import MarketviewTableFilters from 'components/molecules/marketview-table-filters'

import useMarketviewStoragePrefix from 'hooks/use-marketview-storage-prefix'
import useAbortController from 'hooks/use-abort-controller'

const DEFAULT_CONVERSION_RATE = 6

const Title = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const InfoContainer = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const InfoTextContainer = styled.div`
  margin-left: ${({ theme }) => theme.sizings.lvl2};
  max-width: 70%;
`

const InfoTitle = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

const ExplanationIcon = styled.i`
  display: inline-block;
  color: ${({ theme }) => theme.colors.darkOnLightBorder};
  height: ${({ theme }) => theme.sizings.lvl2};
  width: ${({ theme }) => theme.sizings.lvl2};
  line-height: ${({ theme }) => theme.sizings.lvl2};
  border: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
  border-radius: 50%;
  margin-left: ${({ theme }) => theme.sizings.lvl1};
  text-align: center;
  font-size: 0.8em;

  &::before {
    content: 'i';
  }
`

const StyledTableFilters = styled(MarketviewTableFilters)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

function getInfoTitle(targetAmount, translate) {
  if (targetAmount > 0) {
    return translate('buyingAdvice')
  }
  if (targetAmount < 0) {
    return translate('sellingAdvice')
  }
  if (targetAmount === 0) {
    return translate('buyingAdvice')
  }
  return null
}

function getInfoText(targetAmount, translate) {
  if (targetAmount > 0) {
    return translate('stockInfoBuyingText')
  }
  if (targetAmount < 0) {
    return translate('stockInfoSellingText')
  }
  if (targetAmount === 0) {
    return translate('stockInfoGoalReachedText')
  }
  return null
}

const debouncedTargetConversionRateUpdate = debounce(
  (update, targetConversionRateStorageId, newConversionRate) => {
    update(newConversionRate)
    storage.setPermanent(targetConversionRateStorageId, newConversionRate)
  },
  200,
)

const B2bIcon = styled(Icon)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.sizings.lvl0};
  width: ${({ theme }) => theme.sizings.lvl5};
`

/**
 * Component to fetch and show missed sales outside disctrict
 * data.
 *

 */

function MarketviewStock() {
  const history = useHistory()
  const location = useLocation()
  const inputParams = useContext(InputParamsContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const token = useSelector(({ auth }) => auth?.instanceId)
  const [page, setPage] = useState(1)
  const resultsPerPage = 24
  const [orderBy, setOrderBy] = useState('make')
  const [orderDirection, setOrderDirection] = useState('asc')
  const {
    ref: stockBuyTheseAllExportAbortControllerRef,
    abort: abortStockBuyTheseAllExportRequest,
  } = useAbortController()
  const {
    ref: stockBuyTheseAllAbortControllerRef,
    abort: abortStockBuyTheseAllRequest,
  } = useAbortController()
  const {
    ref: stockBuyTheseAbortControllerRef,
    abort: abortStockBuyTheseRequest,
  } = useAbortController()
  const { ref: stockAbortControllerRef, abort: abortStockRequest } =
    useAbortController()

  // data set up:
  const carsToBuy = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.data &&
      state.marketviewData.data.stockBuyThese &&
      (state.marketviewData.data.stockBuyThese.recordSet === null
        ? []
        : state.marketviewData.data.stockBuyThese.recordSet),
  )
  const totalCarsToBuy = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.data &&
      state.marketviewData.data.stockBuyThese &&
      state.marketviewData.data.stockBuyThese.totalRecords &&
      state.marketviewData.data.stockBuyThese.totalRecords[0].total,
  )
  const stock = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.data &&
      state.marketviewData.data.stock,
  )
  const error = useSelector(
    (state) =>
      state.marketviewData &&
      state.marketviewData.error &&
      state.marketviewData.error.stockBuyThese,
  )
  const userHasUccLicense = useSelector(
    (state) =>
      state.auth && state.auth.userDetails && !!state.auth.userDetails.pakket,
  )
  const storagePrefix = useMarketviewStoragePrefix()
  const targetConversionRateStorageId = `${storagePrefix}${marketViewFilterIds.stockTargetConversionRate}`

  const stocktargetconverstionrate =
    getObjectFromQueryString(location.search).stocktargetconverstionrate ||
    storage.getValue(targetConversionRateStorageId)
  const stockTargetConversionRate =
    stocktargetconverstionrate || DEFAULT_CONVERSION_RATE
  const [tempTargetConversionRate, setTempTargetConversionRate] = useState(
    stockTargetConversionRate,
  )

  function updateStockTargetConversionRate(newTarget) {
    setTempTargetConversionRate(newTarget)
    debouncedTargetConversionRateUpdate(
      (newRate) =>
        handleChangeQuery(
          location,
          history,
          marketViewFilterIds.stockTargetConversionRate,
          newRate,
        ),
      targetConversionRateStorageId,
      newTarget,
    )
  }

  const toPercentage = (value) =>
    `${numbro(value).format({
      forceSign: true,
      mantissa: 2, // two decimals
    })}%`

  function handleOrder(direction, by) {
    setOrderDirection(direction)
    setOrderBy(by)
  }

  function getParams(all) {
    let params = [
      ...inputParams,
      {
        key: 'orderby',
        value: orderBy,
      },
      {
        key: 'orderdirection',
        value: orderDirection,
      },
    ]

    if (all) {
      params = [
        ...params,
        {
          key: 'rowstart',
          value: '0',
        },
      ]
    } else {
      params = [
        ...params,
        {
          key: 'rows',
          value: resultsPerPage.toString(),
        },
        {
          key: 'rowstart',
          value: ((page - 1) * resultsPerPage).toString(),
        },
      ]
    }

    return params
  }

  function fetchTableData(all) {
    const fetchId = all ? 'stockBuyTheseAll' : 'stockBuyThese'

    if (!inputParams) {
      return Promise.resolve()
    }

    const params = getParams(all)

    let signal

    if (fetchId === 'stockBuyThese') {
      abortStockBuyTheseRequest()
      signal = stockBuyTheseAbortControllerRef.current.signal
    }

    if (fetchId === 'stockBuyTheseAll') {
      abortStockBuyTheseAllRequest()
      signal = stockBuyTheseAllAbortControllerRef.current.signal
    }

    return dispatch(getMarketviewData(fetchId, params, signal))
  }

  useDeepCompareEffect(() => {
    fetchTableData()
  }, [dispatch, inputParams, page, orderBy, orderDirection])

  useDeepCompareEffect(() => {
    if (!inputParams) {
      return
    }

    abortStockRequest()

    dispatch(
      getMarketviewData(
        'stock',
        [
          ...inputParams,
          {
            key: 'targetTurnoverRate',
            value: stockTargetConversionRate.toString(),
          },
        ],
        stockAbortControllerRef.current.signal,
      ),
    )
  }, [dispatch, inputParams, stockTargetConversionRate])

  if (!Array.isArray(carsToBuy) || !stock) {
    return <LoadingIndicator error={error} />
  }

  // map data into the desired structure for itemsToCompare prop of CompareTopicsChart
  const requiredStockData = [
    {
      label: t('myStock'),
      extendedLabel: t('myStock'),
      color: 'chartsMyself',
      value: stock.companyInventory,
    },
    {
      label: t('comparisonGroupLabel'),
      extendedLabel: t('requiredStockBasedOnComparisonGroup'),
      color: 'chartsComparisonGroup',
      value: stock.requiredInventoryBasedOnComparisongroupAbsoluteValue,
      additionalValue: toPercentage(
        stock.requiredInventoryBasedOnComparisongroupPercentage,
      ),
    },
    {
      label: t('targetLabel'),
      extendedLabel: t('requiredStockBasedOnTargetConversionRate'),
      color: 'chartsTarget',
      additionalValue: toPercentage(
        stock.requiredInventoryBasedOnTargetTurnoverRatePercentage,
      ),
      value: stock.requiredInventoryBasedOnTargetTurnoverRateAbsoluteValue,
    },
  ]

  const requiredStockFields = [
    {
      label: t('targetConversionRate'),
      value: Number(tempTargetConversionRate),
      unit: 'x',
      fieldAction: (value) => updateStockTargetConversionRate(value),
    },
  ]

  const infoTitle = getInfoTitle(stock.targetAmount, t)
  const infoText = getInfoText(stock.targetAmount, t)

  const columns = [
    {
      id: 'brand',
      orderId: 'brand',
      label: t('brand'),
    },
    {
      id: 'model',
      orderId: 'model',
      label: t('model'),
    },
    {
      id: 'type',
      orderId: 'type',
      label: t('type'),
    },
    {
      id: 'transmission',
      orderId: 'transmission',
      label: t('transmission'),
    },
    {
      id: 'fuel',
      orderId: 'fuel',
      label: t('fuel'),
    },
    {
      id: 'yearOfBuild',
      orderId: 'buildYear',
      label: t('yearOfBuild'),
    },
    {
      id: 'sellDate',
      orderId: 'sellDate',
      label: t('sellDate'),
    },
    {
      id: 'b2bAlert',
      exportId: 'hasB2b',
      label: t('b2bAlert'),
      component: (
        <>
          {t('b2bAlert')}
          {!userHasUccLicense && (
            <ToolTip message={t('onlyAvailableToUccUsers')} position="left">
              <ExplanationIcon />
            </ToolTip>
          )}
        </>
      ),
    },
  ]

  function handleClickB2bAlert(make, model, buildYear) {
    dispatch(setMarketViewData('b2bAlert', { make, model, buildYear })).then(
      () => {
        fetchTableData()
      },
    )
  }

  function handleDownloadAll() {
    const params = getParams(true)

    abortStockBuyTheseAllExportRequest()

    marketviewData.getMarketviewExport({
      type: 'stockBuyTheseAll',
      token,
      body: params,
      queryParams: {
        source: 'stock',
      },
      signal: stockBuyTheseAllExportAbortControllerRef.current.signal,
    })
  }

  return (
    <>
      <CompareTopicsChart
        heading={t('myStock')}
        itemsToCompare={requiredStockData}
        extraFields={requiredStockFields}
        unit={t('unitLabelCars')}
      />
      <ContentSeparator />
      <Title text={t('missedSalesInYourDistrict')} type="h2" />
      <InfoContainer>
        <LegendBox
          label={t('toBuyOboGoal')}
          legendColor={
            stock.targetAmount === 0 ? 'inactiveBackground' : 'stateNegative'
          }
          optionalValue={toPercentage(
            stock.toBePurchasedBasedOnTargetPercentage,
          )}
          unit={t('cars')}
          value={stock.toBePurchasedBasedOnTargetAbsoluteValue}
        />
        <InfoTextContainer>
          <InfoTitle text={infoTitle} type="h3b" />
          <Text text={infoText} type="floatingLabelLabel" />
        </InfoTextContainer>
      </InfoContainer>
      <StyledTableFilters />
      <EnhancedTable
        columns={columns}
        rows={carsToBuy.map((carToBuy) => ({
          brand: {
            component: <Text text={carToBuy.make} />,
            data: carToBuy.make,
          },
          model: {
            component: <Text text={carToBuy.model} />,
            data: carToBuy.model,
          },
          type: {
            component: <Text text={carToBuy.type} />,
            data: carToBuy.type,
          },
          transmission: {
            component: <Text text={carToBuy.transmission} />,
            data: carToBuy.transmission,
          },
          fuel: {
            component: <Text text={carToBuy.fuel} />,
            data: carToBuy.fuel,
          },
          yearOfBuild: {
            component: <Text text={carToBuy.buildYear} />,
            data: carToBuy.buildYear,
          },
          sellDate: {
            component: <Text text={formatDate(carToBuy.sellDate)} />,
            data: formatDate(carToBuy.sellDate),
          },
          b2bAlert: {
            component: carToBuy.hasB2b ? (
              <ToolTip
                message={`${t('activeAlertTooltip')} <a href="/settings/relations-management/b2b-purchasing-profile">${t('settings')}</>`}
                position="left"
              >
                <B2bIcon type="b2b" color="statePositive" />
              </ToolTip>
            ) : (
              <ToolTip
                message={userHasUccLicense ? null : t('interestedInB2b')}
                position="left"
              >
                <Button
                  icon="b2b"
                  iconColor="inactiveTextColor"
                  level="minimal"
                  onClick={
                    userHasUccLicense
                      ? () =>
                          handleClickB2bAlert(
                            carToBuy.make,
                            carToBuy.model,
                            carToBuy.buildYear,
                          )
                      : null
                  }
                />
              </ToolTip>
            ),
            data: carToBuy.hasB2b ? t('yes') : t('no'),
          },
        }))}
        selectedText={t('carsSelected')}
        onChangePage={setPage}
        onDownloadAll={handleDownloadAll}
        onOrder={handleOrder}
        orderBy={orderBy}
        orderDirection={orderDirection}
        page={page}
        resultsPerPage={resultsPerPage}
        totalResults={totalCarsToBuy}
        noDataMessage={t('noCarFoundBasedOnFilters')}
      />
    </>
  )
}

MarketviewStock.propTypes = {}

MarketviewStock.defaultProps = {}

export default MarketviewStock
