import { data as dataConfig } from 'config'
import language from 'utilities/language'

const DEFAULT_HEADERS = {
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'include',
  redirect: 'follow',
  referrer: 'no-referrer',
}

export const API_ERROR_MESSAGES = {
  UNAUTHORIZED: 'unauthorized',
  INVALID_LOGIN_DATA: 'invalid_login_data',
  API_ENDPOINT_NOT_FOUND: 'api_endpoint_not_found',
  COULD_NOT_REACH_API_ENDPOINT: 'could_not_reach_api_endpoint',
  ABORT_CONTROLLER_CANCELLED_REQUEST: 'abort_controller_cancelled_request',
  UNKNOWN_ERROR: 'unknown_error',
}

function handleSuccess(response) {
  if (typeof response.success === 'undefined') {
    return Promise.resolve(response)
  }

  if (response.success === true) {
    // @TODO this wrap of the repsonse in an object
    // with a data field is a relic of the old implementation
    // of this module using axios
    return Promise.resolve({ data: response })
  }

  return Promise.reject(response)
}

function handleFailure(response) {
  /**
   * When the response "name" is equal to "AbortError" the request was consiously cancelled because another request is firing
   * See more: https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort#parameters
   */
  if (response?.name === 'AbortError') {
    return Promise.reject(API_ERROR_MESSAGES.ABORT_CONTROLLER_CANCELLED_REQUEST)
  }

  let errorMessage = response.error
    ? response.error.msg
    : API_ERROR_MESSAGES.UNKNOWN_ERROR

  if (!response.status) {
    return Promise.reject(errorMessage)
  }

  switch (response && response.status) {
    case 401:
      errorMessage = API_ERROR_MESSAGES.INVALID_LOGIN_DATA
      break
    case 403:
      errorMessage = API_ERROR_MESSAGES.UNAUTHORIZED
      break
    case 404:
      errorMessage = API_ERROR_MESSAGES.API_ENDPOINT_NOT_FOUND
      break
    case 500:
      errorMessage = API_ERROR_MESSAGES.COULD_NOT_REACH_API_ENDPOINT
      break
    default:
      errorMessage = API_ERROR_MESSAGES.UNKNOWN_ERROR
  }

  return Promise.reject(errorMessage)
}

function formatData(data) {
  if (data instanceof FormData) {
    return data
  }
  return JSON.stringify(data)
}

function constructUrl(url, instance, params) {
  const parsedUrl = new URL(dataConfig.apiUrl)
  if (url) {
    // Split out the provided url's query string params
    const parts = url.split('?')
    let urlFormatted = parts[0]

    // Force leading slash on provided url
    if (urlFormatted.slice(0, 1) !== '/') {
      urlFormatted = `/${urlFormatted}`
    }

    // Trim trailing slash on API pathname, if there is one
    const base =
      parsedUrl.pathname.slice(-1) === '/'
        ? parsedUrl.pathname.slice(0, -1)
        : parsedUrl.pathname

    // Construct the pathname
    parsedUrl.pathname = `${base}${urlFormatted}`

    // Add the search params that were provided in the url, if there are any
    if (parts.length > 0) {
      const searchParams = new URLSearchParams(parts[1])
      for (const [key, value] of searchParams.entries()) {
        parsedUrl.searchParams.set(key, value)
      }
    }
  }

  // Add the token, if there is one
  if (instance) {
    parsedUrl.searchParams.set('token', instance)
  }

  // Add any query string params, if there are any
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        const processedValue = Array.isArray(value) ? value.join(',') : value
        parsedUrl.searchParams.set(key, processedValue)
      }
    })
  }

  return parsedUrl.toString()
}

function request(method, url, data, instance, params, signal) {
  const constructedUrl = constructUrl(url, instance, params)
  const options = {
    headers: {
      ...DEFAULT_HEADERS,
      ...{ 'accept-language': language.getLanguage() },
    },
    method,
    body: method === 'POST' ? formatData(data) : undefined,
    signal,
  }

  return fetch(constructedUrl, options)
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response)
      }
      return response
    })
    .then((response) => response.json())
    .then(handleSuccess)
    .catch(handleFailure)
}

function get(url, instance, params, signal) {
  return request('GET', url, null, instance, params, signal)
}

function post(url, data, instance, params, signal) {
  return request('POST', url, data, instance, params, signal)
}

function getStatic(url) {
  return fetch(url).then((response) => response.json())
}

function upload(url, data, instance, params, onProgress) {
  const constructedUrl = constructUrl(url, instance, params)

  const options = {
    headers: {
      ...DEFAULT_HEADERS,
      ...{ 'accept-language': language.getLanguage() },
    },
    body: formatData(data),
  }

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open('post', constructedUrl)

    Object.entries(([key, value]) => {
      xhr.setRequestHeader(key, value)
    })

    xhr.onload = (e) => {
      const { target } = e
      if (target.status === 200) {
        return resolve(target.responseText)
      }
      return reject(target.statusText)
    }

    xhr.onerror = reject

    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = onProgress
    }

    xhr.send(options.body)
  })
}

export default {
  get,
  getStatic,
  post,
  upload,
}
