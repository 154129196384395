import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import * as ROUTES from 'config/routes'
import styled from 'styled-components'
import SearchInput from 'components/molecules/search-input'

const Container = styled.div`
  // background-color: ${({ theme }) => theme.colors.defaultBackground};
  padding: 10px;
`

function Searchbox({ history, onSubmit }) {
  const [searchQuery, setSearchQuery] = useState('')

  function handleSetSearchQuery(query) {
    setSearchQuery(query)
  }

  function handleSubmit(e) {
    e.preventDefault()
    onSubmit()
    history.push(`${ROUTES.SEARCH_RESULTS}?q=${searchQuery}`)
  }

  return (
    <Container>
      <SearchInput
        onChange={handleSetSearchQuery}
        onSubmit={handleSubmit}
        value={searchQuery}
      />
    </Container>
  )
}

Searchbox.propTypes = {
  history: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
}

Searchbox.defaultProps = {
  onSubmit: () => {},
}

export default withRouter(Searchbox)
