import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { CarKindEnum } from 'config/enums'
import Typography from 'components/molecules/typography'
import Label from 'components/atoms/label'
import Text from 'components/atoms/text'
import Checkbox from 'components/atoms/new-checkbox'
import { media } from 'utilities/styled'

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.sizings.lvl1};
  flex-wrap: wrap;
`

const Heading = styled(Typography)`
  margin-bottom: 0;
`

const ChecklistList = styled.ul`
  margin: ${({ theme }) => theme.sizings.lvl2} 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl1};
`

const ChecklistItemContainer = styled.label`
  display: inline-flex;
  align-items: baseline;
  gap: ${({ theme }) => theme.sizeByFactor(1.5)};
  cursor: pointer;
`

const LabelTagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: ${({ theme }) => theme.sizings.lvl0};
  column-gap: ${({ theme }) => theme.sizings.lvl1};

  ${media.tablet`
    min-height: 20px;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  `}
`

const Name = styled(Text)`
  margin-bottom: 0;
  ${({ $lineThrough }) => !!$lineThrough && 'text-decoration: line-through;'}
`

const CheckboxContainer = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
`

const Description = styled(Typography)`
  display: block;
  margin-top: ${({ theme }) => theme.sizings.lvl0};
  margin-bottom: 0;
  // Apparently, this is a completely new text style
  font-size: 12px;
  max-width: ${({ theme }) => theme.sizeByFactor(50)};
`

const ChecklistItem = ({
  id,
  name,
  description,
  tag,
  tagIsMuted,
  isChecked,
  onChange,
  ...restProps
}) => (
  <ChecklistItemContainer {...restProps}>
    <CheckboxContainer>
      <Checkbox
        id={id}
        value={id}
        checked={isChecked}
        onChange={(checked) => {
          typeof onChange === 'function' && onChange(checked)
        }}
      />
    </CheckboxContainer>
    <div>
      <LabelTagWrapper>
        <Name type="tabSmall" $lineThrough={isChecked}>
          {name}
        </Name>
        {tag && (
          <Label
            backgroundColor={tagIsMuted ? 'brandFoxtrot' : 'inactiveBackground'}
            textColor={tagIsMuted ? 'brandCharlie' : 'text'}
            text={tag}
            size="small"
          />
        )}
      </LabelTagWrapper>
      {description && (
        <Description type="InlineBodyText" color="brandCharlie">
          {description}
        </Description>
      )}
    </div>
  </ChecklistItemContainer>
)

ChecklistItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  tag: PropTypes.string,
  tagIsMuted: PropTypes.bool,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
}

const Checklist = ({
  name,
  types,
  onItemChange,
  checklistItems,
  ...restProps
}) => {
  const { t } = useTranslation()

  return (
    <div {...restProps}>
      <HeaderContainer>
        <Heading type="Level2Heading">{name}</Heading>
        {[...new Set(types)].map((type) => (
          <Label
            key={type}
            backgroundColor="inactiveBackground"
            text={t(`carKinds.${type}`)}
            textColor="text"
            size="small"
          />
        ))}
      </HeaderContainer>
      <ChecklistList>
        {checklistItems.map((item) => (
          <li key={item.id}>
            <ChecklistItem
              {...item}
              onChange={(isChecked) => {
                typeof onItemChange === 'function' &&
                  onItemChange(item, isChecked)
              }}
            />
          </li>
        ))}
      </ChecklistList>
    </div>
  )
}

Checklist.propTypes = {
  name: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.oneOf(Object.values(CarKindEnum))),
  checklistItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      description: PropTypes.string,
      tag: PropTypes.string,
      tagIsMuted: PropTypes.bool,
      isChecked: PropTypes.bool,
    }),
  ),
  onItemChange: PropTypes.func,
}

export default Checklist
