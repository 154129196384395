import { connect } from 'react-redux'
import { getCarComments } from 'redux/actions/data'
import CarComments from '../molecules/car-comments'

const mapStateToProps = ({ data }) => ({
  comments: data.carComments,
})

const mapDispatchToProps = {
  getComments: getCarComments,
}

export default connect(mapStateToProps, mapDispatchToProps)(CarComments)
