import React from 'react'
import PdfSettingsForm from 'components/redux-containers/pdf-settings-form-container'

function PdfSettings() {
  return <PdfSettingsForm />
}

PdfSettings.propTypes = {}

PdfSettings.defaultProps = {}

export default PdfSettings
