import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SettingsTabs from '../views/settings/settings-tabs'

const mapStateToProps = ({ auth }) => ({
  userDetails: auth.userDetails,
})

const mapDispatchToProps = {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettingsTabs),
)
