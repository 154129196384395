import i18n from 'i18next'

import numbro from 'numbro'
import moment from 'moment'
import 'moment/locale/nl'
import 'moment/locale/de'
import 'moment/locale/fr'

import storage from 'utilities/storage'

import numberFormats from 'config/numberFormats'
import { storageKeys } from 'config/storage'

const defaultLanguage = 'nl'
const allowedLanguages = ['nl-NL', 'nl', 'fr-FR', 'fr']

function getLanguage() {
  const preferredLanguage = storage.getPermanent(
    storageKeys.UCC_PREFERRED_LANGUAGE,
  )

  // First priority: preferred language from local storage
  if (
    preferredLanguage !== null &&
    allowedLanguages.includes(preferredLanguage)
  ) {
    return preferredLanguage
  }

  // Second priority: browser language (when it includes an allowed langauge)
  if (allowedLanguages.includes(navigator.language)) {
    return navigator.language.slice(0, 2).toLowerCase()
  }

  // Last priority: default language ('nl)
  return defaultLanguage
}

async function setLanguage(language) {
  if (!language || i18n.language === language) {
    return
  }

  // Changes language for i18next
  i18n.changeLanguage(language)

  // Configures numbro with new language properties (e.g. NL displays €1.000.000, FR displays 1.000.000 €)
  numbro.registerLanguage(numberFormats[language])
  numbro.setLanguage(language)

  // Configures moment with new language properties (for translating dates, times, etc.)
  moment.locale(language)

  // Saves language to local storage
  storage.setPermanent(storageKeys.UCC_PREFERRED_LANGUAGE, language)
}

export default {
  defaultLanguage,
  getLanguage,
  setLanguage,
}
