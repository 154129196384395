import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { media } from 'utilities/styled'
import toast from 'utilities/toast'
import { formatUnit, formatFromToLabel } from 'utilities/format'
import { CSPPriceMatrixFormSchema } from 'config/validation-schemas'
import Typography from 'components/molecules/typography'
import Button from 'components/atoms/button'
import LoadingButton from 'components/atoms/loading-button'
import ContentSeperator from 'components/atoms/content-separator'
import TextLink from 'components/atoms/text-link'
import {
  FormikTextInput,
  FormikSelectInput,
} from 'components/molecules/formik-field'
import EnhancedTable from 'components/organisms/enhanced-table'
import FullWidthTopBarLayout from 'components/layouts/full-width-top-bar-layout'
import numbro from 'numbro'

const TopBarContainer = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  > * {
    margin: ${({ theme }) => theme.sizings.lvl1} 0;
  }
`
const StyledDropDownSelect = styled(FormikSelectInput)`
  flex: 1;
  min-width: 180px;
  flex-basis: 25%;
  ${media.tablet`
    flex: 0;
  `}
`
const StyledTextInput = styled(FormikTextInput)`
  flex: 1;
  flex-basis: 25%;
  min-width: 180px;
  ${media.tablet`
    flex: 0;
  `}
`
const StyledCellTextInput = styled(FormikTextInput)`
  min-width: 120px;
`
const TextInputSeperator = styled.span`
  padding: 0 ${({ theme }) => theme.sizings.lvl1};
  &:before {
    content: '-';
  }
`
const ActionsContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizings.lvl2};
  display: flex;
  justify-content: space-between;
`
const VerticallyAlignedInlineContainer = styled.div`
  display: flex;
  align-items: center;
`
const ProviderNameContainer = styled.div`
  align-self: center;
`
const ProviderNameLabel = styled(Typography)`
  margin: 0 0 ${({ theme }) => theme.sizings.lvl1} 0;
  color: ${(props) => props.theme.colors.text};
  font-weight: normal;
`
const ProviderName = styled(Typography)`
  font-weight: bold;
  margin: 0;
  color: ${(props) => props.theme.colors.text};
`
const ActionsButton = styled(Button)`
  &[disabled] {
    opacity: 0.4;
    * {
      fill: ${({ theme }) => theme.colors.actionsStandard};
    }

    &:hover,
    &:focus {
      & * {
        fill: ${({ theme }) => theme.colors.actionsStandard};
      }
    }
  }
`

/**
 * Noramlly the `formatPrice` function from `config/data.js` would be used to format prices. Here
 * we need to rounds off the prices to 8 decimals. For now, this PriceMatrixTableForm is the only
 * place we need to do this. However, if this precise way of formatting a price ends up being used
 * in other places as well, the `formatPrice` function needs to be refactored so the number of
 * decimals can be configured there.
 **/

const formatPricePrecise = (value) => {
  let floatValue = parseFloat(value)
  if (Number.isNaN(floatValue)) {
    floatValue = 0
  }

  const numbroValue = numbro(floatValue)
  const formatSettings = {
    thousandSeparated: false,
    mantissa: 8,
    trimMantissa: true,
  }

  return numbroValue.formatCurrency(formatSettings)
}

function PriceMatrixTableForm({
  dealerName,
  carServicePlanId,
  carBrandsAndModels,
  fuelTypes,
  priceMatrix,
  onSubmit,
  initiallyDisabled,
  enableReinitializeForm,
}) {
  const { t } = useTranslation()

  const brandOptions = carBrandsAndModels
    .map((brand) => ({ value: brand.id, label: brand.name }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const modelOptions = (brandId) => {
    const selectedBrand = carBrandsAndModels.find(
      (brand) => brand.id === brandId,
    )
    if (selectedBrand) {
      return selectedBrand.models
        .map((model) => ({ value: model.id, label: model.name }))
        .sort((a, b) => a.label.localeCompare(b.label))
    }
    return []
  }

  const fuelTypeOptions = fuelTypes.map((fuelType) => ({
    value: fuelType.value,
    label: fuelType.label,
  }))

  const tableColumns = [
    {
      id: 'brandId',
      orderId: 'brandId',
      label: t('carServicePlanAdminPriceMatrixOverlay.tableHeadings.brandId'),
      width: 11.5,
    },
    {
      id: 'modelId',
      orderId: 'modelId',
      label: t('carServicePlanAdminPriceMatrixOverlay.tableHeadings.modelId'),
      width: 11.5,
    },
    {
      id: 'age',
      orderId: 'age',
      label: t('carServicePlanAdminPriceMatrixOverlay.tableHeadings.age'),
      width: 18,
    },
    {
      id: 'mileage',
      orderId: 'mileage',
      label: t('carServicePlanAdminPriceMatrixOverlay.tableHeadings.mileage'),
      width: 18,
    },
    {
      id: 'kw',
      orderId: 'kw',
      label: t('carServicePlanAdminPriceMatrixOverlay.tableHeadings.kilowatt'),
      width: 18,
    },
    {
      id: 'fuelType',
      orderId: 'fuelType',
      label: t('carServicePlanAdminPriceMatrixOverlay.tableHeadings.fuelType'),
      width: 11.5,
    },
    {
      id: 'pricePerKm',
      orderId: 'pricePerKm',
      label: t(
        'carServicePlanAdminPriceMatrixOverlay.tableHeadings.pricePerKm',
      ),
      width: 11.5,
    },
    {
      id: 'actions',
      label: t('carServicePlanAdminPriceMatrixOverlay.tableHeadings.actions'),
      width: '90px',
    },
  ]

  const rowSchema = {
    brandId: {
      type: 'select',
      items: brandOptions,
      data: '',
    },
    modelId: {
      type: 'contextualSelect',
      context: 'brandId',
      items: modelOptions,
      data: '',
    },
    age: {
      type: 'fromToNumber',
      to: '',
      from: '',
    },
    mileage: {
      type: 'fromToNumber',
      unit: 'km',
      to: '',
      from: '',
    },
    kw: {
      type: 'fromToNumber',
      unit: 'power_kw',
      to: '',
      from: '',
    },
    fuelType: {
      type: 'select',
      items: fuelTypeOptions,
      data: '',
    },
    pricePerKm: {
      type: 'number',
      unit: 'currency_euro',
      data: '',
    },
    actions: {
      type: 'editAndDelete',
    },
  }

  // Add the "resetOnChange" property to all fields which have a contextualSelect
  Object.entries(rowSchema)
    .filter(([_, object]) => object.type === 'contextualSelect')
    .forEach(([id, object]) => {
      rowSchema[object.context].resetOnChange = id
    })

  // Convenience function to generate an empty price rule
  const getFormikEmptyPriceRuleRow = () => {
    const emptyRow = {}
    Object.keys(rowSchema).forEach((key) => {
      emptyRow[key] =
        rowSchema[key].type === 'fromToText' ||
        rowSchema[key].type === 'fromToNumber'
          ? { from: '', to: '' }
          : ''
    })

    return emptyRow
  }

  // Convenience function to generate an empty form
  const getFormikEmptyFormSchema = () => ({
    name: '',
    carServicePlanId,
    priceMatrixRules: [getFormikEmptyPriceRuleRow()],
  })

  // Convert formik's values to the payload that will be supplied to the onSubmit handler
  const formikToPayload = (formik) => {
    const copiedFormik = JSON.parse(JSON.stringify(formik))
    const payloadPriceRules = copiedFormik.priceMatrixRules.map((priceRule) => {
      const payloadPriceRule = priceRule
      Object.keys(payloadPriceRule).forEach((key) => {
        if (
          typeof payloadPriceRule[key] === 'object' &&
          payloadPriceRule[key] !== null
        ) {
          payloadPriceRule[`${key}From`] = payloadPriceRule[key].from
          payloadPriceRule[`${key}To`] = payloadPriceRule[key].to
          delete payloadPriceRule[key]
        }
      })
      delete payloadPriceRule.actions
      delete payloadPriceRule.brandLabel
      delete payloadPriceRule.modelLabel

      // Remove all empty fields and parse all number fields
      Object.keys(payloadPriceRule).forEach((key) => {
        if (
          payloadPriceRule[key] === '' ||
          payloadPriceRule[key] === null ||
          payloadPriceRule[key] === undefined
        ) {
          payloadPriceRule[key] = null
          return
        }

        const match = key.match(/(From|To)$/)
        const schemaKey = match ? key.slice(0, match.index) : key
        if (!rowSchema[schemaKey]) {
          return
        }

        if (rowSchema[schemaKey].unit === 'currency_euro') {
          payloadPriceRule[key] = parseFloat(payloadPriceRule[key])
        } else if (
          ['number', 'fromToNumber'].includes(rowSchema[schemaKey].type)
        ) {
          payloadPriceRule[key] = parseInt(payloadPriceRule[key])
        }
      })

      return payloadPriceRule
    })

    Object.keys(copiedFormik)
      .filter(
        (key) =>
          copiedFormik[key] === '' ||
          copiedFormik[key] === null ||
          copiedFormik[key] === undefined,
      )
      .forEach((key) => {
        copiedFormik[key] = null
      })

    return {
      ...copiedFormik,
      maxMileagePerYear:
        copiedFormik.maxMileagePerYear !== null
          ? parseInt(copiedFormik.maxMileagePerYear)
          : copiedFormik.maxMileagePerYear,
      priceMatrixRules: payloadPriceRules,
    }
  }

  const payloadToFormik = (payload) => {
    const copiedPayload = JSON.parse(JSON.stringify(payload))
    const priceMatrixRules = payload.priceMatrixRules || []
    const formikPriceRules = priceMatrixRules.map((priceRule) => {
      const formikPriceRule = JSON.parse(JSON.stringify(priceRule))
      Object.keys(priceRule).forEach((key) => {
        const fromSplit = key.split('From')
        if (fromSplit.length > 1) {
          const valueFrom =
            priceRule[key] === null || priceRule[key] === undefined
              ? ''
              : priceRule[key]
          const valueTo =
            priceRule[`${fromSplit[0]}To`] === null ||
            priceRule[`${fromSplit[0]}To`] === undefined
              ? ''
              : priceRule[`${fromSplit[0]}To`]
          formikPriceRule[fromSplit[0]] = { from: valueFrom, to: valueTo }
          delete formikPriceRule[key]
          delete formikPriceRule[`${fromSplit[0]}To`]
        } else if (priceRule[key] === null || priceRule[key] === undefined) {
          formikPriceRule[key] = ''
        }
      })
      formikPriceRule.brandId = formikPriceRule.brand
        ? formikPriceRule.brand.id
        : ''
      formikPriceRule.modelId = formikPriceRule.model
        ? formikPriceRule.model.id
        : ''
      return formikPriceRule
    })
    Object.keys(copiedPayload).forEach((key) => {
      if (copiedPayload[key] === undefined || copiedPayload[key] === null) {
        copiedPayload[key] = ''
      }
    })
    return {
      ...copiedPayload,
      priceMatrixRules: formikPriceRules,
    }
  }

  const TopBarSeperator = (
    <div>
      <ContentSeperator variant="vertical" paddingLevel={2} />
    </div>
  )

  const validationSchema = CSPPriceMatrixFormSchema(t)

  // Ordering

  /**
   * The `rowOrderSchema` dictates how rows are sorted when a user clicks on a column head. The
   * `orderId` values given in the `tableColumns` array (a bit further above) should be the keys in
   * this object. The values should be functions which accept `row` as an argument. The return
   * value of this function can either be a number, a string, or an array of strings/numbers. If
   * you return a string or number, those will be used as values to compare rows when ordering. If
   * an array is returned, the values will be used in sequence to compare the rows if the previous
   * value in the array is equal. This means you are returning more than one columns to order the
   * results on. This can be useful when you have a column that has the same value in it.
   *
   * For instance: when you try to sort the "brand" column. Most tables will use the same brand for
   * all the rows in that column. If you provide an array which contains `[brandId, id]`, the rows
   * will be sorted on the `id` property of the row, if the `brandId` is the same for any rows that
   * are being compared when ordering.
   */

  const formatFromToValuesForOrdering = (prop) => [prop.from || 0, prop.to || 0]
  const fallbackToRowIdForOrdering = (value, row) => {
    const rowId = row.id ? parseInt(row.id) : ''
    return Array.isArray(value) ? [...value, rowId] : [value, rowId]
  }

  const rowOrderSchema = {
    brandId: (row) =>
      fallbackToRowIdForOrdering(
        row.brandId
          ? rowSchema.brandId.items.find((item) => item.value === row.brandId)
              .label
          : '',
        row,
      ),
    modelId: (row) =>
      fallbackToRowIdForOrdering(
        [
          row.brandId && row.modelId
            ? rowSchema.modelId
                .items(row.brandId)
                .find((item) => item.value === row.modelId).label
            : '',
          row.brandId
            ? rowSchema.brandId.items.find((item) => item.value === row.brandId)
                .label
            : '',
        ],
        row,
      ),
    age: (row) =>
      fallbackToRowIdForOrdering(formatFromToValuesForOrdering(row.age), row),
    mileage: (row) =>
      fallbackToRowIdForOrdering(
        formatFromToValuesForOrdering(row.mileage),
        row,
      ),
    kw: (row) =>
      fallbackToRowIdForOrdering(formatFromToValuesForOrdering(row.kw), row),
    fuelType: (row) =>
      fallbackToRowIdForOrdering(
        row.fuelType
          ? rowSchema.fuelType.items.find((item) => item.value === row.fuelType)
              .label
          : '',
        row,
      ),
    pricePerKm: (row) => fallbackToRowIdForOrdering(row.pricePerKm, row),
  }

  const [orderDirection, setOrderDirection] = useState('asc')
  const [orderBy, setOrderBy] = useState(null)

  const compare = (a, b) => {
    if (Number.isFinite(a) && Number.isFinite(b)) {
      return a - b
    } else {
      return `${a}`.localeCompare(`${b}`)
    }
  }

  const orderRows = (rows, direction, by) => {
    if (rows.length === 0) {
      return
    }

    rows.sort((a, b) => {
      const valueA = Object.prototype.hasOwnProperty.call(rowOrderSchema, by)
        ? rowOrderSchema[by](a)
        : a[by]
      const valueB = Object.prototype.hasOwnProperty.call(rowOrderSchema, by)
        ? rowOrderSchema[by](b)
        : b[by]
      if (valueA === undefined || valueB === undefined) {
        console.warn(`Tried to sort row on non-existent property "${by}"`)
        return 0
      }
      if (Array.isArray(valueA) && Array.isArray(valueB)) {
        // Multiple values to compare
        let result = 0
        let i = 0
        const length = valueA.length
        while (i < length && result === 0) {
          result = compare(valueA[i], valueB[i])
          i++
        }
        return result
      } else {
        return compare(valueA, valueB)
      }
    })

    if (direction === 'desc') {
      rows.reverse()
    }

    // Update the sorting values in the state
    setOrderDirection(direction)
    setOrderBy(by)
    return rows
  }

  const initialValues = priceMatrix
    ? payloadToFormik({ carServicePlanId, ...priceMatrix })
    : getFormikEmptyFormSchema()

  const [rowsInEditMode, setRowsInEditMode] = useState(
    initialValues && !initiallyDisabled
      ? initialValues.priceMatrixRules.map((row) => row.id)
      : [],
  )

  // A function which returns an element which will represent one cell in a row.
  // This will mostly return input fields, or action buttons.
  const getRowCellComponent = useCallback(
    (
      index,
      id,
      { unit, items, type, context, resetOnChange },
      values,
      setValues,
      setFieldValue,
      validationSchema,
    ) => {
      const rowName = `priceMatrixRules[${index}]`
      const name = `${rowName}.${id}`
      const row = values.priceMatrixRules[index]
      const rowIsEditable = !row.id || rowsInEditMode.includes(row.id)
      const fieldValue = row[id]
      switch (type) {
        case 'select': {
          const onChange = (value) => {
            setFieldValue(name, value)
            if (resetOnChange) {
              setFieldValue(`${rowName}.${resetOnChange}`, '')
            }
          }
          const selectedLabel = (items || []).find(
            (item) => item.value === fieldValue,
          )?.label
          const label = selectedLabel || fieldValue
          return rowIsEditable ? (
            <Field
              name={name}
              validationSchema={validationSchema}
              label={t(
                `carServicePlanAdminPriceMatrixOverlay.inputLabels.${id}`,
              )}
              items={items || []}
              filled
              component={StyledDropDownSelect}
              // disabled={formDisabled}
              onChange={onChange}
            />
          ) : (
            label
          )
        }
        case 'contextualSelect': {
          const itemsFormatted = items(row[context])
          const selectedLabel = (itemsFormatted || []).find(
            (item) => item.value === fieldValue,
          )?.label
          const label = selectedLabel || fieldValue

          return rowIsEditable ? (
            <Field
              name={name}
              validationSchema={validationSchema}
              label={t(
                `carServicePlanAdminPriceMatrixOverlay.inputLabels.${id}`,
              )}
              items={itemsFormatted}
              filled
              component={StyledDropDownSelect}
            />
          ) : (
            label
          )
        }
        case 'number':
        case 'text':
          return rowIsEditable ? (
            <Field
              name={name}
              validationSchema={validationSchema}
              label={t(
                `carServicePlanAdminPriceMatrixOverlay.inputLabels.${id}`,
              )}
              unit={unit}
              type={type === 'number' && 'number'}
              filled
              component={StyledCellTextInput}
            />
          ) : unit === 'currency_euro' ? (
            formatPricePrecise(fieldValue)
          ) : (
            `${fieldValue}${unit && ' ' + formatUnit(unit)}`
          )
        case 'fromToNumber':
        case 'fromToText':
          return rowIsEditable ? (
            <VerticallyAlignedInlineContainer>
              <Field
                name={`${name}.from`}
                validationSchema={validationSchema}
                label={t(
                  'carServicePlanAdminPriceMatrixOverlay.inputLabels.from',
                )}
                unit={unit}
                type={type === 'fromToNumber' ? 'number' : undefined}
                filled
                component={StyledCellTextInput}
              />
              <TextInputSeperator />
              <Field
                name={`${name}.to`}
                validationSchema={validationSchema}
                label={t(
                  'carServicePlanAdminPriceMatrixOverlay.inputLabels.to',
                )}
                unit={unit}
                type={type === 'fromToNumber' && 'number'}
                filled
                component={StyledCellTextInput}
              />
            </VerticallyAlignedInlineContainer>
          ) : (
            formatFromToLabel(fieldValue.from, fieldValue.to, type, unit)
          )
        case 'editAndDelete':
          return rowIsEditable ? (
            <ActionsButton
              level="option"
              icon="deleteCarfile"
              onClick={() => {
                const newValues = JSON.parse(JSON.stringify(values))
                newValues.priceMatrixRules.splice(index, 1)
                return setValues(newValues)
              }}
            />
          ) : (
            <ActionsButton
              level="option"
              icon="edit"
              onClick={() => {
                row.id && setRowsInEditMode((rowIds) => [...rowIds, row.id])
              }}
            />
          )
        default:
          return <></>
      }
    },
    [rowsInEditMode, t],
  )

  return (
    <Formik
      enableReinitialize={enableReinitializeForm}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        try {
          toast.info(t('carServicePlanAdminPriceMatrixOverlay.savingChanges'), {
            autoClose: 10000, // some time to read the message.
          })

          await onSubmit(formikToPayload(values))
          setSubmitting(false)
        } catch (e) {
          console.error(e)
          toast.error(
            t('carServicePlanAdminPriceMatrixOverlay.errorSubmittingForm'),
          )
          setSubmitting(false)
        }
      }}
    >
      {({ values, setValues, isSubmitting, setFieldValue }) => (
        <Form>
          <FullWidthTopBarLayout
            paddingLevelMain={2}
            topBarSlot={
              <TopBarContainer>
                {dealerName && (
                  <>
                    <ProviderNameContainer>
                      <ProviderNameLabel type="Level3Heading">
                        {t(
                          'carServicePlanAdminPriceMatrixOverlay.dealer',
                        ).toUpperCase()}
                      </ProviderNameLabel>
                      <ProviderName type="Level2Heading">
                        {dealerName.toUpperCase()}
                      </ProviderName>
                    </ProviderNameContainer>
                    {TopBarSeperator}
                  </>
                )}
                <Field
                  name="name"
                  validationSchema={validationSchema}
                  label={t(
                    'carServicePlanAdminPriceMatrixOverlay.inputLabels.name',
                  )}
                  filled
                  component={StyledTextInput}
                />
                {TopBarSeperator}
                <Field
                  name="maxMileagePerYear"
                  validationSchema={validationSchema}
                  unit="km"
                  label={t(
                    'carServicePlanAdminPriceMatrixOverlay.inputLabels.maxMileagePerYear',
                  )}
                  filled
                  component={StyledTextInput}
                />
                {/* {formDisabled && (
                  <>
                    {TopBarSeperator}
                    <TopBarEditButton
                      iconColor="actionsStandard"
                      type="submit"
                      level="option"
                      icon="edit"
                      text={t('carServicePlanAdminPriceMatrixOverlay.editPriceMatrix')}
                      onClick={() => setFormDisabled(false)}
                    />
                  </>
                )} */}
              </TopBarContainer>
            }
            mainSlot={
              <section>
                <EnhancedTable
                  onOrder={(direction, by) => {
                    const orderedRows = orderRows(
                      JSON.parse(JSON.stringify(values.priceMatrixRules)),
                      direction,
                      by,
                    )
                    setValues({
                      ...values,
                      priceMatrixRules: orderedRows,
                    })
                  }}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  selectable={false}
                  verticalCellBorders
                  columns={tableColumns}
                  noDataMessage={t(
                    'carServicePlanAdminPriceMatrixOverlay.noPriceRules',
                  )}
                  rows={values.priceMatrixRules.map((_, index) => {
                    const enhancedTableRow = {}
                    Object.keys(rowSchema).forEach((key) => {
                      enhancedTableRow[key] = {}
                      enhancedTableRow[key].component = getRowCellComponent(
                        index,
                        key,
                        { ...rowSchema[key] },
                        values,
                        setValues,
                        setFieldValue,
                        validationSchema,
                      )
                    })
                    return enhancedTableRow
                  })}
                />
                <ActionsContainer>
                  <TextLink
                    onClick={() => {
                      const newValues = JSON.parse(JSON.stringify(values))
                      newValues.priceMatrixRules.push(
                        getFormikEmptyPriceRuleRow(),
                      )
                      setValues(newValues)
                    }}
                    text={t(
                      'carServicePlanAdminPriceMatrixOverlay.addNewPriceRule',
                    )}
                  />
                  <LoadingButton
                    level="cta"
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    {t('save')}
                  </LoadingButton>
                </ActionsContainer>
              </section>
            }
          />
        </Form>
      )}
    </Formik>
  )
}

PriceMatrixTableForm.propTypes = {
  dealerName: PropTypes.string.isRequired,
  carServicePlanId: PropTypes.string.isRequired,
  // If a price matrix is provided, then the table form will be used for editing
  priceMatrix: PropTypes.object,
  carBrandsAndModels: PropTypes.array.isRequired,
  fuelTypes: PropTypes.array.isRequired,
  /* If true, all form fields are initially disabled
  and a link is shown to edit the warranty program. */
  initiallyDisabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  enableReinitializeForm: PropTypes.bool,
}

PriceMatrixTableForm.defaultProps = {
  warrantyProgram: undefined,
  warrantyPrograms: undefined,
  initiallyDisabled: false,
  enableReinitializeForm: true,
}

export default PriceMatrixTableForm
