import LoadingIndicator from 'components/atoms/loading-indicator'
import TextLink from 'components/atoms/text-link'
import EnhancedTable from 'components/organisms/enhanced-table'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearData,
  getRdwReceivedCars,
  postDynamicEndpoint,
} from 'redux/actions/data'
import { getForms } from 'redux/actions/forms'
import { openOverlay } from 'redux/actions/ui'
import toast from 'utilities/toast'

/**
 * This overview shows all cars that have their ownership transferred
 * through RDW to the user's location.
 */
const RdwReceivedCarsOverview = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const rdwReceivedCars = useSelector((state) => state?.data?.rdwReceivedCars)
  const rdwForm = useSelector((state) => state?.forms?.rdw)
  React.useEffect(() => {
    /**
     * There is a chance that `rdwReceivedCars` is actually set, but it contains no data, loading or
     * error props. This happens when you dispatch a `clearData` action. So, we have to do some
     * additional checks to make sure the data is fetched if it is not in the store yet.
     */
    if (
      !rdwReceivedCars ||
      (!rdwReceivedCars.data &&
        !rdwReceivedCars.loading &&
        !rdwReceivedCars.error)
    ) {
      dispatch(getRdwReceivedCars())
    }
  }, [dispatch, rdwReceivedCars])

  React.useEffect(() => {
    if (!rdwForm) {
      dispatch(getForms('rdw'))
    }
  }, [dispatch, rdwForm])

  const openIndemnifyOverlay = (data) => {
    // HACK: loads a form config in a ComplexForm that is provided by the API, in an overlay. This
    // means that FE does not have full control if rwdIdemnifyForm is available (it should be
    // though).
    const rdwFormObject = rdwForm.data?.form
    const formCategory = 'rdw'
    const formId = 'form'
    if (rdwFormObject) {
      dispatch(
        openOverlay('form', {
          icon: 'rdwGeneral',
          formCategory,
          formId,
          storeFormId: 'vrijwaren',
          submitText: t(`overlaySubmitButtonTexts.${formCategory}.${formId}`),
          title: t('rdwVrijwaren'),
          enableReinitialize: true,
          data: {
            typevw: 'OD',
            ...data,
          },
          onSubmit: (formState) => {
            const { endpoint, reload, redirect } = rdwFormObject
            return dispatch(
              postDynamicEndpoint({
                endpoint,
                reload,
                redirect,
                ...formState,
              }),
            ).then((response) => {
              if (response?.success) {
                dispatch(clearData('rdwReceivedCars'))
              }
              return response
            })
          },
        }),
      )
    } else {
      toast.error(t('rdwReceivedCarsOverview.indemnifyCarFormCouldNotBeLoaded'))
    }
  }

  const rows = Array.isArray(rdwReceivedCars?.data)
    ? rdwReceivedCars.data.map((rowData) => ({
        registrationDate: {
          data: rowData.registrationDate,
          component: rowData.registrationDate,
        },
        licensePlate: {
          data: rowData.licensePlate,
          component: rowData.licensePlate,
        },
        seller: {
          data: rowData.seller,
          component: rowData.seller,
        },
        legalEntity: {
          data: rowData.legalEntity,
          component: rowData.legalEntity,
        },
        expirationDate: {
          data: rowData.expireDate,
          component: rowData.expireDate,
        },
        options: {
          data: '',
          component: (
            <TextLink
              onClick={() =>
                openIndemnifyOverlay({
                  kenteken: rowData.licensePlate,
                  teller: rowData.mileage,
                  teller_eenheid: rowData.mileageUnit,
                })
              }
              text={t('rdwReceivedCarsOverview.indemnifyCarLinkLabel')}
            />
          ),
        },
      }))
    : []

  return (
    <article>
      {!rdwReceivedCars ||
      rdwReceivedCars.loading ||
      rdwReceivedCars.error ||
      !rdwForm ||
      rdwForm.loading ||
      rdwForm.error ? (
        // eslint-disable-next-line react/jsx-indent
        <LoadingIndicator error={rdwReceivedCars?.error || rdwForm?.error} />
      ) : (
        <EnhancedTable
          selectable={false}
          columns={[
            {
              id: 'registrationDate',
              label: t(
                'rdwReceivedCarsOverview.tableHeadings.registrationDate',
              ),
            },
            {
              id: 'licensePlate',
              label: t('licensePlate'),
            },
            {
              id: 'seller',
              label: t('rdwReceivedCarsOverview.tableHeadings.seller'),
            },
            {
              id: 'legalEntity',
              label: t('rdwReceivedCarsOverview.tableHeadings.legalEntity'),
            },
            {
              id: 'expirationDate',
              label: t('rdwReceivedCarsOverview.tableHeadings.expirationDate'),
            },
            {
              id: 'options',
              label: '',
            },
          ]}
          rows={rows}
          noDataMessage={t('rdwReceivedCarsOverview.noDataMessage')}
        />
      )}
    </article>
  )
}

export default RdwReceivedCarsOverview
