import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import IconHeading from 'components/molecules/icon-heading'
import Todos from 'components/molecules/todos'

const StyledTodos = styled(Todos)`
  & > *:last-child {
    margin-bottom: 0;
  }
`

const TodosWithHeader = ({ items }) => {
  const { t } = useTranslation()
  const badgeCount = items.reduce((a, b) => a + b.count, 0)
  return (
    <>
      <IconHeading iconType="todo" badgeCount={badgeCount}>
        {t('toDos')}
      </IconHeading>
      <StyledTodos items={items} loading={false} columns={1} />
    </>
  )
}

TodosWithHeader.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

// TodosWithHeader.defaultProps = {
//   items: [],
// };

export default TodosWithHeader
