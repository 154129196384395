import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { B2B_TRADE_PLATFORM } from 'config/routes'

import { media } from 'utilities/styled'
import { scrollTo } from 'utilities/utils'

import {
  clearData,
  getB2bCarFileAssets,
  getB2bCarFileDamage,
} from 'redux/actions/data'

import LoadingIndicator from 'components/atoms/loading-indicator'
import Tabs from 'components/layouts/tabs'
import Breadcrumbs from 'components/molecules/breadcrumbs'
import CarfileMetaData from 'components/molecules/carfile-meta-data'
import DamageCards from 'components/molecules/damage-cards'
import EquipmentForm from 'components/molecules/equipment-form'
import Typography from 'components/molecules/typography'
import CarfileSummary from 'components/organisms/carfile-summary'
import DynamicStaticValuesCollections from 'components/organisms/dynamic-static-values-collections'
import GeneralDataError from 'components/organisms/general-data-error'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'

const Container = styled.article`
  flex-direction: column;

  ${media.desktop`
    align-items: stretch;
    display: flex;
    padding: 0 60px;
    gap: ${({ theme }) => theme.sizings.lvl6};
  `}
`

const TopBar = styled.div`
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: none;

  ${media.desktop`
    display: flex;
  `}
`

function B2bCarFile({
  carAssets,
  b2bCarFile,
  carKind,
  forms,
  getB2bCarFile,
  getForms,
  history,
  match,
  openImageOverlay,
  salesView,
}) {
  const { t } = useTranslation()
  const carStatus = b2bCarFile.data && b2bCarFile.data.status
  const carId = match.params.id
  const dispatch = useDispatch()
  clearData('carComments')

  useEffect(() => {
    clearData('carComments')
    getB2bCarFile(carId)
    dispatch(getB2bCarFileAssets(carId))
    getForms('b2bCarFile', { auto_id: carId })
    dispatch(getB2bCarFileDamage(carId))
  }, [carId, getB2bCarFile, getForms, match.params.id, dispatch])

  useEffect(() => {
    // When carStatus changes, other assets can come available,
    // like the default 'verwacht' (expected) foto. when a car
    // changes from 'lopend' (lead) to 'verwacht' (expected).
    dispatch(getB2bCarFileAssets(carId))
  }, [carStatus, carId, dispatch])

  useEffect(() => {
    const { hash } = history.location
    if (hash && forms && !forms.loading && b2bCarFile && !b2bCarFile.loading) {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        setTimeout(() => {
          scrollTo(element)
        }, 100)
      }
    }
  }, [history, forms, b2bCarFile])

  const carImages =
    carAssets &&
    carAssets.data &&
    carAssets.data.filter(({ type }) => type === 'image')
  // const carDocuments = carAssets && carAssets.data && carAssets.data.filter(({ type }) => type === 'document')

  const specificationsFormSetup = useSelector(
    (state) => state?.forms?.b2bCarFile?.data?.specifications_general_form,
  )
  const specificationHistoryFormSetup = useSelector(
    (state) => state?.forms?.b2bCarFile?.data?.specifications_historical_form,
  )
  const damage = useSelector((state) => state?.data?.b2bCarFileDamage)
  const tabItems = []

  tabItems.push({
    label: t('equipment'),
    to: `${match.url}/equipment`,
    component: () => (
      <>
        {b2bCarFile.data && (
          <EquipmentForm
            carAccessories={b2bCarFile.data.accessoires}
            allAccessories={{}}
            dataIsStatic
          />
        )}
      </>
    ),
  })

  tabItems.push({
    label: t('history'),
    to: `${match.url}/history`,
    component: () => (
      <>
        {specificationHistoryFormSetup && !b2bCarFile.loading && (
          <DynamicStaticValuesCollections
            formSetup={specificationHistoryFormSetup}
            values={b2bCarFile.data}
          />
        )}
      </>
    ),
  })

  tabItems.push({
    label: t('damage'),
    to: `${match.url}/damage`,
    component: (props) =>
      damage.loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {!Array.isArray(damage.data.data) ||
          (Array.isArray(damage.data.data) && damage.data.data.length === 0) ? (
            <Typography type="ExplanationParagraph">
              {t('noB2bDamage')}
            </Typography>
          ) : (
            <DamageCards hideCosts damages={damage.data.data} />
          )}
        </>
      ),
  })

  tabItems.push({
    label: t('specifications'),
    to: `${match.url}/specifications`,
    component: () => (
      <>
        {specificationsFormSetup && !b2bCarFile.loading && (
          <DynamicStaticValuesCollections
            formSetup={specificationsFormSetup}
            values={b2bCarFile.data}
          />
        )}
      </>
    ),
  })

  if (!b2bCarFile.data && !b2bCarFile.loading) {
    return <GeneralDataError error={t('errorRetrievingCar')} />
  }

  return (
    <AppLayoutContainer pageTitle={t('carFile')}>
      {!b2bCarFile.loading && (
        <Container>
          <header>
            <TopBar>
              <Breadcrumbs
                items={[
                  {
                    label: t('b2bTradePlatform.carFile.breadcrumbs.overview'),
                    to: B2B_TRADE_PLATFORM,
                  },
                  { label: `${t('carFile')} ${b2bCarFile.data.id}` },
                ]}
              />
              <CarfileMetaData created={b2bCarFile.data.created} />
            </TopBar>
            <CarfileSummary
              imagesSetType="b2b-car-file"
              carImageLabelText={t('b2bTradePlatform.carFile.statusLabel')}
              carfile={b2bCarFile.data}
              carfileLoading={b2bCarFile.loading}
              carId={match.params.id}
              images={carImages}
              imagesLoading={carAssets ? carAssets.loading : true}
              openImageOverlay={openImageOverlay}
              salesView={salesView}
              carStatus={carStatus}
              showInterestedButton
              showCspCalculationButton={false}
              showEditMasterDataButton={false}
              showRequestBmsDataButton={false}
            />
          </header>
          <Tabs items={tabItems}>
            {tabItems.map((item, index) =>
              item.subItems ? (
                <Tabs items={item.subItems} key={index.toString()} small>
                  {item.subItems.map((subItem, subIdx) => (
                    <subItem.component key={subIdx.toString()} />
                  ))}
                </Tabs>
              ) : (
                <item.component key={index.toString()} />
              ),
            )}
          </Tabs>
        </Container>
      )}
    </AppLayoutContainer>
  )
}

B2bCarFile.propTypes = {
  carAssets: PropTypes.object,
  b2bCarFile: PropTypes.object,
  carKind: PropTypes.object,
  forms: PropTypes.object,
  getB2bCarFile: PropTypes.func.isRequired,
  getForms: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  openImageOverlay: PropTypes.func.isRequired,
  salesView: PropTypes.bool.isRequired,
}

B2bCarFile.defaultProps = {
  carAssets: null,
  b2bCarFile: {},
  carKind: {},
  forms: null,
}

export default B2bCarFile
