import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useDynamicEndpointDone from 'hooks/use-dynamic-endpoint-done'
import OptionsBar from 'components/layouts/options-bar'
import Text from 'components/atoms/text'
import TextLink from 'components/atoms/text-link'
import Button from 'components/atoms/button'
import SimpleTable from 'components/layouts/simple-table'

function DeliverySegmentsSettings({
  deleteDeliverySegment,
  deliverySegments,
  dynamicEndpoint,
  getDeliverySegments,
  getForms,
  locationId,
  openOverlay,
  postDynamicEndpoint,
}) {
  const { t } = useTranslation()
  const segments = deliverySegments && deliverySegments[locationId]
  const segmentsData = segments && segments.data && segments.data.rows
  const loading = segments && segments.loading
  const hasSegments = segmentsData && segmentsData.length > 0

  const columns = [t('description'), t('mileage'), t('yearOfBuild'), t('brand')]

  useEffect(() => {
    getDeliverySegments({
      id: locationId,
    })
  }, [getDeliverySegments, getForms, locationId])

  useDynamicEndpointDone(
    dynamicEndpoint,
    () => {
      getDeliverySegments({
        id: locationId,
      })
    },
    [dynamicEndpoint, getDeliverySegments, locationId],
  )

  function openSegmentsOverlay(formParams) {
    openOverlay('dynamicForm', {
      formId: 'deliverySegments',
      formParams,
      icon: 'administration',
      onSubmit: (values, endpoint) => {
        postDynamicEndpoint({
          endpoint,
          ...values,
          vestiging_id: locationId,
        })
      },
      subId: formParams && formParams.groep_id,
      submitText: t('saveSegment'),
      title: t('newSegment'),
    })
  }

  function handleClickNewSegment() {
    openSegmentsOverlay()
  }

  function handleClickModify(segment) {
    openSegmentsOverlay({ groep_id: segment.id })
  }

  function handleClickDelete(segment) {
    if (window.confirm(`${t('areYouSureYouWantToDeleteThisSegment')}?`)) {
      deleteDeliverySegment({ groep_id: segment.id, vestiging_id: locationId })
    }
  }

  return (
    <>
      <OptionsBar
        leftAligned={[
          <React.Fragment key="left-aligned-options">
            {!loading && !hasSegments && (
              <Text
                text={t('noDeliverySegmentsYet')}
                type="floatingLabelLabel"
              />
            )}
            {hasSegments && <Text text={t('segments')} type="h5" />}
          </React.Fragment>,
        ]}
        rightAligned={[
          <Button
            key="right-aligned-button"
            level="cta"
            onClick={handleClickNewSegment}
            text={t('newSegment')}
          />,
        ]}
      />
      {hasSegments && (
        <SimpleTable
          columns={columns.map((column, columnIndex) => (
            <Text
              key={`column-text-${columnIndex}`}
              text={column}
              type="floatingLabelLabel"
            />
          ))}
          rows={segmentsData.map((segment, segmentIndex) => [
            <Text
              key={`row-description-${segmentIndex}`}
              text={segment.omschrijving}
              type="boldLabel"
            />,
            <Text
              key={`row-mileage-${segmentIndex}`}
              text={segment.km}
              type="boldLabel"
            />,
            <Text
              key={`row-year-built-${segmentIndex}`}
              text={segment.bouwjaar}
              type="boldLabel"
            />,
            <Text
              key={`row-brand-${segmentIndex}`}
              text={segment.merk}
              type="boldLabel"
            />,
          ])}
          actions={segmentsData.map((segment, segmentIndex) => [
            <TextLink
              key={`action-modify-${segmentIndex}`}
              text={t('modify')}
              onClick={() => handleClickModify(segment)}
            />,
            <TextLink
              key={`action-delete-${segmentIndex}`}
              text={t('delete')}
              onClick={() => handleClickDelete(segment)}
            />,
          ])}
        />
      )}
    </>
  )
}

DeliverySegmentsSettings.propTypes = {
  deliverySegments: PropTypes.object,
  deleteDeliverySegment: PropTypes.func.isRequired,
  dynamicEndpoint: PropTypes.object,
  getDeliverySegments: PropTypes.func.isRequired,
  getForms: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
  openOverlay: PropTypes.func.isRequired,
  postDynamicEndpoint: PropTypes.func.isRequired,
}

DeliverySegmentsSettings.defaultProps = {
  deliverySegments: null,
  dynamicEndpoint: null,
}

export default DeliverySegmentsSettings
