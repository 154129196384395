import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import EnhancedTable from 'components/organisms/enhanced-table'
import Typography from 'components/molecules/typography'
import FramedImage from 'components/atoms/framed-image'
import Button from 'components/atoms/button'

const Header = styled.header`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
  justify-content: space-between;
`

const StyledTable = styled(EnhancedTable)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const TableCellActionButton = styled(Button)`
  padding: 0;
  margin: 0;
  line-height: inherit;
  height: auto;
`

function BidsOverview({ className, bids, logo, title, handleOpenBid }) {
  const { t } = useTranslation()

  // Formatting the bids to rows enhanced table can understand
  const rows = bids
    ? bids.map((bid) => {
        const newRow = {}
        Object.keys(bid).forEach((key) => {
          newRow[key] = {
            component:
              key === 'buttons' ? (
                <>
                  {' '}
                  {bid[key].map((btn, index) => (
                    <TableCellActionButton
                      key={index.toString()}
                      level="option"
                      onClick={() => handleOpenBid(btn, bid)}
                      text={btn.label}
                    />
                  ))}
                </>
              ) : (
                bid[key]
              ),
            data: bid[key],
          }
        })
        return newRow
      })
    : []
  const hiddenColumns = ['id']
  // Get row object that enhacned table can understand, based from rows
  const columns = []
  rows.forEach((row) =>
    Object.keys(row).forEach((key) => {
      if (
        !hiddenColumns.includes(key) &&
        !columns.some((column) => column.id === key)
      ) {
        columns.push({ label: t(key), id: key })
      }
    }),
  )

  return (
    <section className={className}>
      <Header>
        <Typography type="Level2Heading">{title}</Typography>
        {logo && <FramedImage alt={`logo ${title}`} src={logo} />}
      </Header>
      {bids && bids.length && (
        <StyledTable
          onMobileSwitchToValueCollection
          selectable={false}
          columns={columns}
          rows={rows}
        />
      )}
    </section>
  )
}

BidsOverview.propTypes = {
  className: PropTypes.object,
  bids: PropTypes.array,
  logo: PropTypes.string,
  handleOpenBid: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

BidsOverview.defaultProps = {
  className: null,
  bids: null,
  logo: null,
}

export default BidsOverview
