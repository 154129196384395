/* eslint-disable camelcase */

/**
 * The extraData prop is added in order to be able to send extra fields along with
 * the selected equipment to the back-end. The back-end expects two fields containing strings
 * with equipment ids which are fetched when entering a car to be added to the create car
 * file calll whenever a call is created with equipment selected.
 */

import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useMediaLayout } from 'use-media'
import { ThemeContext } from 'styled-components'

import { withApolloV4Provider } from 'utilities/apollo'

import { SET_ACCESSORIES_HIGHLIGHT } from 'config/graphql/v4'

import CarDataForm from 'components/molecules/cardata-form'
import LoadingIndicator from 'components/atoms/loading-indicator'
import DynamicStaticValuesCollections from 'components/organisms/dynamic-static-values-collections'

const OTHER_TITLE = 'Accessoires'
const STANDARD_TITLE = 'Standaard opties'
const FACTORY_TITLE = 'Fabriek opties'

const DEFAULT_MAX_NUMBER_OF_HIGHLIGHTS = 10 // This value is used as the maximum allowed number of highlights if the back-end does not provide a value

function handleSubmit(extraData, values, onSubmit, finish) {
  const formData = Object.entries(values).reduce((data, [key, value]) => {
    if (extraData[key]) {
      return data
    }
    if (key === 'otherAccessories' && typeof value === 'object') {
      const formattedValues = value.map((val) => val.value)
      return [...data, ...formattedValues]
    }
    if (Array.isArray(value)) {
      return [...data, ...value]
    }
    if (value) {
      return [...data, key]
    }
    return data
  }, [])

  onSubmit(
    {
      ...extraData,
      accessoires_selected: formData,
    },
    finish,
  )

  return true
}

function getFieldsFromAccessories(accessories) {
  return accessories.map(
    ({ acc_id, omschrijving, omschrijving_lang, selected, bedrag }) => ({
      name: acc_id,
      title: omschrijving_lang || omschrijving,
      value: !!Number(selected),
      type: 'checkbox',
      variety: 'withHighlight',
      price: bedrag,
    }),
  )
}

function getAccessoriesGroup(accessories, title) {
  return accessories.filter((accessory) => accessory.groep === title)
}

function EquipmentForm({
  allAccessories,
  carAccessories,
  closeText,
  extraData,
  formId,
  genericAccessories,
  getAccessories,
  getAllAccessories,
  accessoriesHighlights,
  setAccessoriesHighlight,
  manual,
  next,
  nextTab,
  previous,
  previousTab,
  onClose,
  onSubmit,
  submitText,
  valuation,
  addAccessoiresWithCosts,
  dataIsStatic,
  onlyExpandFirstInitially,
  maxNumberOfHighlights,
  finish,
  hasStickyNav,
  optionButtonText,
  hasChevronInNextPrevCloseButtons,
}) {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const isBiggerThanMobile = useMediaLayout({ minWidth: theme.metrics.phone })
  const carId = useSelector((state) => state.data?.carfile?.data?.auto_id)
  const [requestDone, setRequestDone] = useState(false)

  useEffect(() => {
    // @TODO Seems unnecessary to check whether allAccessories is
    // falsy but if removed the component endlessly keeps
    // rerendering. Tried to reproduce here but without any
    // luck (yet) https://codesandbox.io/s/xv9k7wlqkz

    // Update to this code, used a simple state
    // to lock the request to happen once.
    // Root of this bug might be related to the mounting and unmounting (and re-mounting)
    // of this component, because of Tabs.js
    if (!requestDone) {
      getAllAccessories({ auto_id: carId })
      setRequestDone(true)
    }
  }, [allAccessories, getAllAccessories, carId, requestDone])

  useEffect(() => {
    // When carAccessoreis are not passed from the parent component
    // we use redux to fetch a generic set of accessories
    if (!carAccessories && !genericAccessories) {
      getAccessories()
    }
  }, [carAccessories, genericAccessories, getAccessories])

  const accessories = carAccessories || genericAccessories

  /**
   * DISCLAIMER
   *
   * So, this form requires some "special" handling. The accessories checkboxes are of a special
   * variety called 'withHighlight'. They get a special "star" button next to their labels, which
   * can be clicked to mark an accessory as a highlight. These fields are currently only used in
   * this EquipmentForm component. Ultimately, this component renders a ComplexForm, which is fed
   * data that comes from the V3 API. Because ComplexForm is a reusable form solution, which needs
   * to be kept clean from special edge case handling, there is a bunch of "glue" code in this
   * component that makes sure that the marking of favorites is handled properly. The performance
   * of this is absolutely horrible, but that's the price we have to pay for having to tie an old
   * API to a new API (highlights data is read from the V3 API and stored using the V4 API) and
   * having to keep state in sync across multiple places in the front-end (Redux and local state).
   * In short: this is a very hacky tacky way of doing things, but it does work.
   *
   * /DISCLAIMER
   */

  // To keep track of which items are loading, we have to implement some local state
  const [highlightsLoading, setHighlightsLoading] = React.useState([])
  // The V4 GraphQL API is used to mark accessories as highlights, so let's create a mutation for
  // that.
  const [setAccessoriesHighlightMutation] = useMutation(
    SET_ACCESSORIES_HIGHLIGHT,
  )

  /**
   * The user is only allowed to mark a certain number of accessories as highlights. To get the
   * current number of highlights, we have to take into account what accessories are currently
   * checked. The current state of the form and the accessories that have been marked as a
   * highlight live in different parts of the state. So, we have to jump through some hoops to
   * get the information we want. Don't ask, it's just the way it is.
   */

  // Take the current form values from the Redux state
  const formValues = useSelector((state) => state.form[formId]?.values)
  // Calculate the current number of highlights, taking into account only the accessories that are
  // checked.
  let numberOfHighlights = accessoriesHighlights?.length || 0
  if (formValues && accessoriesHighlights) {
    const selectedValues = Object.entries(formValues).reduce((values, pair) => {
      if (pair[1] === true) {
        // A plain checkbox
        return [...values, pair[0]]
      } else if (Array.isArray(pair[1])) {
        // A package with sub-checkboxes. It's value is an array.
        return [...values, ...pair[1]]
      }
      return values
    }, [])
    numberOfHighlights = accessoriesHighlights.filter((highlight) =>
      selectedValues.includes(highlight),
    ).length
  }

  // This function adds the neccessary properties to a fieldset's fields to make the "mark as
  // highlight" stuff work. This function is defined inside of this component because it uses
  // local state variables, which are not accessible outside of this component (obviously).
  const enrichFieldsWithHighlights = React.useCallback(
    (fields) =>
      fields.map((field) => {
        const isHighlight = accessoriesHighlights?.includes(field.name) || false
        const maxAmountReached =
          numberOfHighlights >= maxNumberOfHighlights ||
          (numberOfHighlights === maxNumberOfHighlights - 1 &&
            highlightsLoading.length > 0)
        const isReadOnly =
          highlightsLoading.includes(field.name) ||
          (maxAmountReached && !isHighlight)
        let highlightTooltip = t('accessoriesHighlights.removeHighlight')
        if (!isHighlight) {
          highlightTooltip = maxAmountReached
            ? t('accessoriesHighlights.maximumHighlightsReached', {
                amount: maxNumberOfHighlights,
              })
            : t('accessoriesHighlights.setAsHighlight')
        }

        return {
          ...field,
          variety: 'withHighlight',
          isHighlight,
          highlightReadOnly: isReadOnly,
          highlightTooltip,
          onHighlightChange: (checked, name) => {
            if (setAccessoriesHighlight) {
              setHighlightsLoading((ids) => [...ids, name])
              setAccessoriesHighlightMutation({
                variables: {
                  id: field.name,
                  highlight: checked,
                },
              })
                .then((response) => {
                  setHighlightsLoading((ids) => ids.filter((id) => id !== name))
                  if (response?.data?.setAccessoriesHighlight) {
                    setAccessoriesHighlight(name, checked)
                  } else {
                    throw new Error('Invalid or no response from back-end.')
                  }
                })
                .catch((err) => {
                  console.error(err)
                })
            }
          },
        }
      }),
    [
      accessoriesHighlights,
      highlightsLoading,
      numberOfHighlights,
      maxNumberOfHighlights,
      setAccessoriesHighlight,
      setAccessoriesHighlightMutation,
      t,
    ],
  )

  if (!accessories) {
    return <LoadingIndicator height="100px" />
  }

  const carSpecificAccessoryGroupTitlesAndPrices = accessories.reduce(
    (groups, accessory) => {
      const groupTitle = accessory.groep

      if (
        groupTitle !== STANDARD_TITLE &&
        groupTitle !== FACTORY_TITLE &&
        groupTitle !== OTHER_TITLE &&
        !groups.some((group) => group.title === groupTitle)
      ) {
        return [...groups, { title: groupTitle, price: accessory.pakketprijs }]
      }
      return groups
    },
    [],
  )

  const standardAccessories = getAccessoriesGroup(accessories, STANDARD_TITLE)
  const factoryAccessories = getAccessoriesGroup(accessories, FACTORY_TITLE)
  const otherAccessories = getAccessoriesGroup(accessories, OTHER_TITLE)

  const otherAccessoryLabels = otherAccessories.map(
    (accessory) => accessory.omschrijving,
  )

  const mappedAllAccessories =
    allAccessories &&
    allAccessories.data &&
    allAccessories.data
      .map(({ acc_id, omschrijving }) => ({
        value: acc_id,
        label: omschrijving,
      }))
      .filter(
        (accessory) => otherAccessoryLabels.indexOf(accessory.label) === -1,
      )

  const carSpecificAccessories = carSpecificAccessoryGroupTitlesAndPrices.map(
    (group) => ({
      title: group.title,
      price: group.price,
      items: getAccessoriesGroup(accessories, group.title),
    }),
  )

  const data = accessories.reduce(
    (dataObject, accessory) => ({
      ...dataObject,
      [accessory.acc_id]: accessory,
    }),
    {},
  )

  const fieldsets = [
    {
      title: t('addAccessories'),
      maxColumns: 1,
      fields: [
        {
          defaultValue: [],
          name: 'otherAccessories',
          options: mappedAllAccessories,
          title: 'searchOrAddAccessory',
          type: addAccessoiresWithCosts
            ? 'accessoires_select'
            : 'options_select',
          variety: 'boldLabel',
        },
      ],
    },
  ]

  if (otherAccessories.length) {
    fieldsets.push({
      title: t('optionsOther'),
      fields: enrichFieldsWithHighlights(
        getFieldsFromAccessories(otherAccessories),
      ),
      expandable: true,
      isInitiallyExpanded: onlyExpandFirstInitially
        ? false
        : isBiggerThanMobile,
      maxColumns: 3,
    })
  }

  if (standardAccessories.length) {
    fieldsets.push({
      title: t('optionsStandard'),
      fields: enrichFieldsWithHighlights(
        getFieldsFromAccessories(standardAccessories),
      ),
      expandable: true,
      isInitiallyExpanded: onlyExpandFirstInitially
        ? false
        : isBiggerThanMobile,
      maxColumns: 3,
    })
  }

  if (factoryAccessories.length) {
    fieldsets.push({
      title: t('optionsFactory'),
      fields: enrichFieldsWithHighlights(
        getFieldsFromAccessories(factoryAccessories),
      ),
      expandable: true,
      isInitiallyExpanded: onlyExpandFirstInitially
        ? false
        : isBiggerThanMobile,
      maxColumns: 3,
    })
  }

  if (carSpecificAccessories.length) {
    fieldsets.push({
      title: t('optionsPackages'),
      groupedFieldSet: true,
      fieldsets: carSpecificAccessories.map(({ items, title, price }) => ({
        maxColumns: 1,
        price,
        fields: [
          {
            items: enrichFieldsWithHighlights(getFieldsFromAccessories(items)),
            name: title,
            title,
            type: 'package_select',
          },
        ],
      })),
      isInitiallyExpanded: onlyExpandFirstInitially
        ? false
        : isBiggerThanMobile,
      expandable: true,
    })
  }

  // Only expand the first expandable fieldset
  if (onlyExpandFirstInitially) {
    const firstFieldset = fieldsets
      .filter((fieldset) => fieldset.expandable)
      .shift()
    if (firstFieldset) {
      firstFieldset.isInitiallyExpanded = true
    }
  }

  return (
    <>
      {dataIsStatic ? (
        <DynamicStaticValuesCollections
          columns={3}
          values={{
            ...data,
            ...extraData,
          }}
          formSetup={{ fieldsets }}
        />
      ) : (
        <CarDataForm
          closeText={closeText}
          data={{
            ...data,
            ...extraData,
          }}
          fieldsets={fieldsets}
          formId={formId}
          level="cta"
          next={next}
          nextTab={nextTab}
          onClose={onClose}
          onSubmit={(values) =>
            handleSubmit(extraData, values, onSubmit, finish)
          }
          optionButtonText={optionButtonText}
          onOptionButtonClick={
            optionButtonText
              ? () => handleSubmit({}, formValues, onSubmit, finish)
              : null
          }
          previous={previous}
          previousTab={previousTab}
          submitText={submitText}
          error=""
          manual={manual}
          valuation={valuation}
          finish={finish}
          hasStickyNav={hasStickyNav}
          hasChevronInNextPrevCloseButtons={hasChevronInNextPrevCloseButtons}
          dontSubmitOnPrevious
        />
      )}
    </>
  )
}

EquipmentForm.propTypes = {
  carAccessories: PropTypes.array,
  closeText: PropTypes.string,
  extraData: PropTypes.object,
  formId: PropTypes.string,
  allAccessories: PropTypes.object,
  genericAccessories: PropTypes.array,
  getAccessories: PropTypes.func,
  getAllAccessories: PropTypes.func,
  accessoriesHighlights: PropTypes.array,
  setAccessoriesHighlight: PropTypes.func, // Redux action to update the highlights the the store
  maxNumberOfHighlights: PropTypes.number,
  manual: PropTypes.bool,
  next: PropTypes.bool,
  nextTab: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  previous: PropTypes.bool,
  previousTab: PropTypes.func,
  submitText: PropTypes.string,
  valuation: PropTypes.bool,
  addAccessoiresWithCosts: PropTypes.bool,
  dataIsStatic: PropTypes.bool,
  onlyExpandFirstInitially: PropTypes.bool,
  finish: PropTypes.bool,
  hasStickyNav: PropTypes.bool,
  optionButtonText: PropTypes.string,
  hasChevronInNextPrevCloseButtons: PropTypes.bool,
}

EquipmentForm.defaultProps = {
  allAccessories: null,
  carAccessories: null,
  closeText: null,
  extraData: {},
  genericAccessories: null,
  maxNumberOfHighlights: DEFAULT_MAX_NUMBER_OF_HIGHLIGHTS,
  manual: false,
  next: null,
  nextTab: null,
  onClose: null,
  previous: null,
  previousTab: null,
  submitText: null,
  valuation: false,
  addAccessoiresWithCosts: false,
  dataIsStatic: false,
  onSubmit: () => {},
  formId: '',
  getAccessories: () => {},
  getAllAccessories: () => {},
  hasStickyNav: false,
  optionButtonText: null,
  hasChevronInNextPrevCloseButtons: false,
}

export default withApolloV4Provider(EquipmentForm)
