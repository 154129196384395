import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import LabeledText from 'components/atoms/labeled-text'
import Link from 'components/atoms/link'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Typography from 'components/molecules/typography'

import { media } from 'utilities/styled'

const ProviderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: ${({ theme }) => theme.sizings.lvl2};
  text-align: right;
`

const StyledTypography = styled(Typography)`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.sizings.lvl0};
`

const StyledLabeledText = styled(LabeledText)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  ${media.tablet`
    margin-right: ${({ theme }) => theme.sizeByFactor(2)};
  `}
`

function CarFileQuickPriceValuationContent({
  className,
  isLoading,
  handlePriceCheck,
  tradingPrice,
  sellPrice,
}) {
  const { t } = useTranslation()

  return (
    <ProviderContent className={className}>
      {isLoading && <StyledLoadingIndicator size="small" />}

      {!isLoading && !tradingPrice && !sellPrice && (
        <>
          <StyledTypography type="BodyParagraph">
            {t('valueIndication')}
          </StyledTypography>
          <Link onClick={() => handlePriceCheck()}>{t('requestValue')}</Link>
        </>
      )}

      {!isLoading && tradingPrice && sellPrice && (
        <>
          <StyledLabeledText
            title={t('tradingValue')}
            price
            value={tradingPrice}
          />
          <StyledLabeledText title={t('sellValue')} price value={sellPrice} />
        </>
      )}
    </ProviderContent>
  )
}

CarFileQuickPriceValuationContent.propTypes = {
  className: PropTypes.string,
  handlePriceCheck: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  tradingPrice: PropTypes.string,
  sellPrice: PropTypes.string,
}

CarFileQuickPriceValuationContent.defaultProps = {
  className: null,
  tradingPrice: '',
  sellPrice: '',
}

export default CarFileQuickPriceValuationContent
