import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { getDotPathValue } from 'utilities/utils'

const StyledProgress = styled.div`
  background-color: ${({ theme }) => theme.colors.brandDelta};
  border-radius: 9999px;
  display: flex;
  height: ${({ $isSmall }) => ($isSmall ? '3px' : '6px')};
  ${({ $isSmall }) => !$isSmall && 'padding: 2px;'}
`

const StyledValue = styled.div`
  overflow: hidden;
  position: relative;
  width: ${({ $value }) => $value}%;
  border-radius: 9999px;

  &:before {
    content: '';
    background: ${({ theme, $color }) => getDotPathValue($color, theme)};
    width: ${({ $value }) => ($value === 0 ? '0' : (100 / $value) * 100)}%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    display: block;
  }
`

function Progress({ value, color, isSmall, ...restProps }) {
  let colorName =
    color || (value >= 100 ? 'colors.statePositive' : 'colors.stateNegative')
  if (colorName.indexOf('.') < 0) {
    colorName = `colors.${colorName}`
  }
  return (
    <StyledProgress $isSmall={isSmall} {...restProps}>
      <StyledValue $color={colorName} $value={value} />
    </StyledProgress>
  )
}

Progress.propTypes = {
  value: PropTypes.number,
  /**
   * A color or gradient token from the theme. Examples: colors.brandAlfa,
   * gradient.positiveToNegative. You can provide a color name without the "colors." prefix as
   * well. So, this is valid as well: "brandAlpha". Gradients do require the "gradients." prefix.
   **/
  color: PropTypes.string,
  isSmall: PropTypes.bool, // Removed the padding around the progress bar
}

Progress.defaultProps = {
  value: 0,
}

export default Progress
