import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

import Icon from 'components/atoms/icon'
import Toggle from 'components/atoms/toggle'
import Typography from 'components/molecules/typography'
import CarfileKindSwitch from 'components/molecules/carfile-kind-switch'

import { media } from 'utilities/styled'

import useDealerLocationName from 'hooks/use-location-dealer-name'

import CarBrandAndModel from './car-brand-and-model'
import CarIdentityPlate from './car-identity-plate'

const StyledCarfileSummaryHeader = styled.div`
  position: relative;

  ${media.desktop`
    display: flex;
  `};
`

const StyledSideContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 20px;

  ${media.desktop`
    align-items: flex-end;
    flex-direction: column;
    margin-left: auto;
    margin-top: 0;
    padding-left: 20px;
  `};
`

const StyledCarfileKindSwitch = styled(CarfileKindSwitch)`
  margin-left: auto;
  padding-left: 20px;

  ${media.tv`
    padding-left: 0;
    margin-top: 10px;
  `}
`

const StyledToggle = styled(Toggle)`
  position: absolute;
  top: 0;
  right: 0;

  ${media.desktop`
    display: none;
  `}
`

const CarLocation = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
  gap: ${({ theme }) => theme.sizings.lvl0};
`

const CarLocationTypography = styled(Typography)`
  font-size: ${({ theme }) => theme.sizeByFactor(2)};
  font-weight: 500;
`

function CarfileSummaryHeader ({
  carId,
  brand,
  brandIsNotMapped,
  model,
  modelIsNotMapped,
  kind,
  license,
  setCarKind,
  type,
  salesView,
  toggleSalesView,
  vin,
}) {
  const { dealerName: carLocation } = useDealerLocationName()

  return (
    <>
      <StyledCarfileSummaryHeader>
        <CarBrandAndModel
          carBrand={brand}
          carBrandIsNotMapped={brandIsNotMapped}
          carModel={model}
          carModelIsNotMapped={modelIsNotMapped}
          carType={type}
          size="large"
          data-test-e2e="car-brand-and-model"
        />
        {toggleSalesView && (
          <StyledToggle
            name="salesViewToggleMobile"
            checked={salesView}
            onChange={toggleSalesView}
            title="Sales"
          />
        )}
        <StyledSideContainer>
          <CarIdentityPlate
            carFileId={carId}
            licensePlate={license}
            vin={vin}
          />
          <StyledCarfileKindSwitch
            carId={carId}
            selected={kind}
            setCarKind={setCarKind}
          />
        </StyledSideContainer>
      </StyledCarfileSummaryHeader>
      {carLocation && (
        <CarLocation>
          <Icon type="location" size="lg" />
          <CarLocationTypography type="InlineBodyText">
            {carLocation}
          </CarLocationTypography>
        </CarLocation>
      )}
    </>
  )
}

CarfileSummaryHeader.propTypes = {
  brand: PropTypes.string,
  brandIsNotMapped: PropTypes.bool,
  carId: PropTypes.string.isRequired,
  kind: PropTypes.string,
  license: PropTypes.string,
  model: PropTypes.string,
  modelIsNotMapped: PropTypes.bool,
  setCarKind: PropTypes.func,
  type: PropTypes.string,
  salesView: PropTypes.bool.isRequired,
  toggleSalesView: PropTypes.func,
  vin: PropTypes.string,
}

CarfileSummaryHeader.defaultProps = {
  brand: '-',
  kind: null,
  license: '-',
  model: '-',
  type: '-',
  toggleSalesView: null,
  setCarKind: null,
  vin: null,
}

export default CarfileSummaryHeader
