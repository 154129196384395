import { gql } from '@apollo/client'

export const CREATE_CARSERVICE_INCLUDED = gql`
  mutation CreateCarServiceIncluded(
    $carServicePlanId: ID!
    $commercialName: [String]!
    $clientTariff: Float
    $costPerYear: Float
    $packageTypes: [packageVariantType!]
  ) {
    createCarServiceIncluded(
      carServicePlanId: $carServicePlanId
      commercialName: $commercialName
      clientTariff: $clientTariff
      costPerYear: $costPerYear
      packageTypes: $packageTypes
    ) {
      id
      commercialName
      clientTariff
      costPerYear
      packageTypes
    }
  }
`

export const READ_CARSERVICE_INCLUDED = gql`
  query ReadCarServiceIncluded($carServicePlanId: ID!) {
    carServiceIncludes(carServicePlanId: $carServicePlanId) {
      id
      commercialName
      clientTariff
      costPerYear
      packageTypes
      deletable
      editableCostPerYear
      editablePackageTypes
      multiLine
      carService {
        id
        name
        defaultClientTariff
        defaultCostPerYear
        isExcluded
      }
      carServicePlan {
        id
      }
    }
  }
`

export const UPDATE_CARSERVICE_INCLUDED = gql`
  mutation UpdateCarServiceIncluded(
    $id: ID!
    $commercialName: [String]!
    $clientTariff: Float
    $costPerYear: Float
    $packageTypes: [packageVariantType!]
  ) {
    updateCarServiceIncluded(
      id: $id
      commercialName: $commercialName
      clientTariff: $clientTariff
      costPerYear: $costPerYear
      packageTypes: $packageTypes
    ) {
      id
      commercialName
      clientTariff
      costPerYear
      packageTypes
    }
  }
`

export const DELETE_CARSERVICE_INCLUDED = gql`
  mutation DeleteCarServiceIncluded($carServiceId: ID!) {
    deleteCarServiceIncluded(id: $carServiceId) {
      id
      commercialName
      clientTariff
      costPerYear
      packageTypes
    }
  }
`
