/*
 * Due to the filters in the stock view not having a way
 * to submit but are designed to be submitted whenever they
 * changed this filter would call the update handler on
 * on every keystroke. To overcome this local state of the
 * filter is being administrated and the update to the
 * actual filter is being debounced.
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import FilterCollapser from 'components/molecules/filter-collapser'
import NumberRangeSelector from 'components/molecules/number-range-selector'

const debouncedMinUpdate = debounce((onFilterUpdate, mId, mV) => {
  onFilterUpdate(mId, mV)
}, 1000)

const debouncedMaxUpdate = debounce((onFilterUpdate, xId, xV) => {
  onFilterUpdate(xId, xV)
}, 1000)

function PriceFilter({
  initiallyOpen,
  minMaxIds,
  onFilterUpdate,
  selectedFilters,
  title,
}) {
  const [firstRender, setFirstRender] = useState(true)
  const minId = minMaxIds[0]
  const maxId = minMaxIds[1]
  const minValue = selectedFilters[minId]
  const maxValue = selectedFilters[maxId]
  const [localMinValue, setLocalMinValue] = useState(minValue)
  const [localMaxValue, setLocalMaxValue] = useState(maxValue)

  function handleUpdate(filter, value) {
    if (filter === minId) {
      setLocalMinValue(value)
    }
    if (filter === maxId) {
      setLocalMaxValue(value)
    }
  }

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
    }
  }, [firstRender, setFirstRender])

  useEffect(() => {
    if (firstRender) {
      return
    }
    debouncedMinUpdate(onFilterUpdate, minId, localMinValue)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localMinValue])

  useEffect(() => {
    if (firstRender) {
      return
    }
    debouncedMaxUpdate(onFilterUpdate, maxId, localMaxValue)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localMaxValue])

  // Whenver value is reset to an empty string set localValue to it
  useEffect(() => {
    if (
      typeof minValue === 'undefined' &&
      typeof localMinValue !== 'undefined'
    ) {
      handleUpdate(minId, minValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minId, minValue])

  // Whenver value is reset to an empty string set localValue to it
  useEffect(() => {
    if (
      typeof maxValue === 'undefined' &&
      typeof localMaxValue !== 'undefined'
    ) {
      handleUpdate(maxId, maxValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxId, maxValue])

  return (
    <FilterCollapser initallyOpen={initiallyOpen} title={title}>
      <NumberRangeSelector
        minValue={localMinValue}
        maxValue={localMaxValue}
        minValueId={minId}
        maxValueId={maxId}
        onUpdate={handleUpdate}
      />
    </FilterCollapser>
  )
}

PriceFilter.propTypes = {
  initiallyOpen: PropTypes.bool,
  minMaxIds: PropTypes.array.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object,
  title: PropTypes.string.isRequired,
}

PriceFilter.defaultProps = {
  initiallyOpen: null,
  selectedFilters: null,
}

export default PriceFilter
