import { API_ERROR_MESSAGES } from 'utilities/api'
import marketviewData from 'utilities/marktview-data'

export const types = {
  REQUEST_MARKETVIEW_PARAMS: 'REQUEST_MARKETVIEW_PARAMS',
  RECEIVE_MARKETVIEW_PARAMS: 'RECEIVE_MARKETVIEW_PARAMS',
  FAIL_MARKETVIEW_PARAMS_REQUEST: 'FAIL_MARKETVIEW_PARAMS_REQUEST',
  SET_SELECTED_MARKETVIEW_PARAMS: 'SET_SELECTED_MARKETVIEW_PARAMS',
}

function requestMarketviewParams() {
  return {
    type: types.REQUEST_MARKETVIEW_PARAMS,
  }
}

function receiveMarketviewParams(data) {
  return {
    type: types.RECEIVE_MARKETVIEW_PARAMS,
    data,
  }
}

function failMarketviewParamsRequest(payload) {
  return {
    type: types.FAIL_MARKETVIEW_PARAMS_REQUEST,
    payload,
  }
}

export function getMarketviewParams(currentParams, signal) {
  return (dispatch, getState) => {
    const instance = getState().auth.instanceId

    dispatch(requestMarketviewParams())

    marketviewData
      .getMarktviewParams(currentParams, instance, signal)
      .then((response) => {
        if (!response) {
          dispatch(failMarketviewParamsRequest('no response'))
          return Promise.reject(new Error('Could not get Marketview params'))
        }

        dispatch(receiveMarketviewParams(response))
        return Promise.resolve()
      })
      .catch((error) => {
        if (error !== API_ERROR_MESSAGES.ABORT_CONTROLLER_CANCELLED_REQUEST) {
          dispatch(failMarketviewParamsRequest(error))
        }
      })
  }
}
