import { gql } from '@apollo/client'

export default gql`
  fragment dealerInfo on Dealer {
    id
    name
    address
    city
    uccId
  }
`
