import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { reducer as formReducer } from 'redux-form'
import { persistReducer as persistReduce, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunkMiddleware from 'redux-thunk'
import {
  initialState as authInitialState,
  reducer as authReducer,
} from 'redux/reducers/auth'
import {
  initialState as dataInitialState,
  reducer as dataReducer,
} from 'redux/reducers/data'
import {
  initialState as formsInitialState,
  reducer as formsReducer,
} from 'redux/reducers/forms'
import {
  initialState as settingsInitialState,
  reducer as settingsReducer,
} from 'redux/reducers/settings'
import {
  initialState as uiInitialState,
  reducer as uiReducer,
} from 'redux/reducers/ui'

import {
  initialState as marketviewDataInitialState,
  reducer as marketviewDataReducer,
} from 'redux/reducers/marketview-data'
import {
  initialState as marketviewParamsInitialState,
  reducer as marketviewParamsReducer,
} from 'redux/reducers/marktview-params'

const defaultState = {
  auth: authInitialState,
  data: dataInitialState,
  forms: formsInitialState,
  settings: settingsInitialState,
  ui: uiInitialState,
  marketviewParams: marketviewParamsInitialState,
  marketviewData: marketviewDataInitialState,
}

const appReducer = combineReducers({
  auth: authReducer,
  data: dataReducer,
  form: formReducer,
  forms: formsReducer,
  settings: settingsReducer,
  ui: uiReducer,
  marketviewParams: marketviewParamsReducer,
  marketviewData: marketviewDataReducer,
})

const RESET_STORE = 'RESET_STORE'

const rootReducer = (state, action) => {
  // Clear the store (reset everything to the default values)
  // after a RESET_STORE action, a successfull logout or a failed
  // authentication verification
  if (
    action.type === RESET_STORE ||
    (action.type === 'SET_LOGOUT' && action.status === 'SUCCESS') ||
    (action.type === 'SET_VERIFY_AUTH' && action.status === 'FAILURE')
  ) {
    return appReducer(defaultState, action)
  }

  return appReducer(state, action)
}

const persistConfig = {
  key: 'root',
  storage,
}

if (process.env.REACT_APP_PERSIST_REDUX !== 'true') {
  persistConfig.whitelist = []
}

const persistReducer = persistReduce(persistConfig, rootReducer)

export function resetStore() {
  return {
    type: RESET_STORE,
  }
}

export default function initStore(initialState = defaultState) {
  const preloadedState = initialState

  // TODO maybe make redux-devtools-extension a devDependency and only use it
  // in development, although there are benefits of having it in production
  // https://medium.com/@zalmoxis/using-redux-devtools-in-production-4c5b56c5600f
  const composeEnhancers = composeWithDevTools({ trace: true })

  const store = createStore(
    persistReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunkMiddleware)),
  )
  const persistor = persistStore(store)

  return { store, persistor }
}
