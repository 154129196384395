import { useEffect, useRef } from 'react'

export default function useDynamicEndpointDone(dynamicEndpoint, onDone) {
  const previousLoading = useRef(false)

  useEffect(() => {
    if (
      dynamicEndpoint &&
      previousLoading.current &&
      !dynamicEndpoint.loading
    ) {
      onDone()
    }
    previousLoading.current = dynamicEndpoint ? dynamicEndpoint.loading : false
  }, [onDone, dynamicEndpoint])
}
