import React from 'react'
import styled from 'styled-components/macro'

import { media } from 'utilities/styled'

import Dialog, {
  propTypes as dialogPropTypes,
} from 'components/molecules/flexible-dialog'
import CarShareUploadDialogForm from 'components/molecules/car-share-upload-dialog-form'
import Typography from 'components/molecules/typography'

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    overflow: hidden auto;
  }

  ${media.tablet`
    .MuiPaper-root {
      min-width: ${({ theme }) => `${theme.metrics.phone}px`};
    }
  `}
`

const StyledTypography = styled(Typography)`
  margin: 0;
`

const CarShareUploadDialog = ({ title, description, open, closeHandler }) => (
  <StyledDialog
    overflowVisible
    title={title}
    open={open}
    closeHandler={closeHandler}
    content={
      <>
        <StyledTypography type="BodyParagraph">{description}</StyledTypography>
        <CarShareUploadDialogForm open={open} onClose={closeHandler} />
      </>
    }
  />
)

CarShareUploadDialog.propTypes = {
  ...dialogPropTypes,
}

export default CarShareUploadDialog
