import React from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import LoadingIndicator from 'components/atoms/loading-indicator'
import Typography from 'components/molecules/typography'
import SummaryLine from 'components/molecules/summary-line'
import ExpandableBars from 'components/layouts/expandable-bars'
import GeneralDataError from 'components/organisms/general-data-error'
import ContractLayoutSettings from 'components/views/settings/car-service-plan/dealer-specific/contract-layout-settings'
import { GET_DEALERS_WITH_CSP } from 'config/graphql/csp'
import DealerNoCSPMessage from 'components/organisms/dealer-no-csp-message'

function CarServicePlanContractLayout() {
  const { loading, error, data } = useQuery(GET_DEALERS_WITH_CSP)
  const { t } = useTranslation()

  if (loading) {
    return <LoadingIndicator error={null} />
  }
  if (error) {
    console.error(error)
    return (
      <>
        <Typography type="Level1Heading">
          {t('carServicePlanContractLayoutTab.heading')}
        </Typography>
        <GeneralDataError />
      </>
    )
  }

  return data.dealers &&
    Array.isArray(data.dealers.edges) &&
    data.dealers.edges.length ? (
    <>
      <Typography type="Level1Heading">
        {t('carServicePlanContractLayoutTab.heading')}
      </Typography>
      <ExpandableBars
        rows={data.dealers.edges.map((edge) => ({
          id: edge.node.id,
          header: (
            <SummaryLine
              name={edge.node.name}
              details={[
                { label: t('clientNumber'), value: edge.node.uccId },
                { value: `${edge.node.address} ${edge.node.city}` },
                {
                  value: edge.node.email ? edge.node.email : t('emailUnknown'),
                },
                {
                  iconLabel: {
                    icon: 'modificationAndDate',
                    label: t('lastModifiedOn'),
                  },
                  value:
                    edge.node.carServicePlan &&
                    edge.node.carServicePlan.lastModified
                      ? edge.node.carServicePlan.lastModified
                      : t('unknown'),
                },
              ]}
            />
          ),
          details: edge.node.carServicePlan ? (
            <ContractLayoutSettings dealerId={edge.node.id} />
          ) : (
            <DealerNoCSPMessage />
          ),
        }))}
      />
    </>
  ) : (
    <Typography type="ExplanationParagraph">{t('noDealersFound')}</Typography>
  )
}

CarServicePlanContractLayout.propTypes = {}

CarServicePlanContractLayout.defaultProps = {}

export default CarServicePlanContractLayout
