import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FilterCollapser from 'components/molecules/filter-collapser'
import DropdownMenu from 'components/molecules/new-dropdown-select'

const StyledDropdownMenu = styled(DropdownMenu)`
  margin: ${({ theme }) => theme.sizings.lvl2} 0;
`

function DropdownFilter({
  initallyOpen,
  name,
  onFilterUpdate,
  title,
  value,
  items,
}) {
  function handleChange(val) {
    onFilterUpdate(name, val)
  }

  return (
    <FilterCollapser initallyOpen={initallyOpen} title={title}>
      <StyledDropdownMenu
        items={items}
        onChange={handleChange}
        label={title}
        value={value}
      />
    </FilterCollapser>
  )
}

DropdownFilter.propTypes = {
  initallyOpen: PropTypes.bool,
  items: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  value: PropTypes.string,
  title: PropTypes.string.isRequired,
}

DropdownFilter.defaultProps = {
  initallyOpen: null,
  value: '',
}

export default DropdownFilter
