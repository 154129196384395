import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import styled from 'styled-components/macro'
import toast from 'utilities/toast'
import { media } from 'utilities/styled'
import { CSP } from '../../../../config/routes'
import {
  ADD_INCOMPLETE_CONTRACT,
  UPDATE_CONTRACT,
  GET_CONTRACTS,
  REQUEST_APPROVAL,
  HANDLE_APPROVAL,
} from '../../../../config/graphql/csp'
import Button from '../../../atoms/button'
import LoadingIndicator from '../../../atoms/loading-indicator'
import TextLink from '../../../atoms/text-link'
import LabeledCheckbox from '../../../atoms/labeled-checkbox'
import Typography from '../../../molecules/typography'
import Dialog from '../../../molecules/flexible-dialog'
import CspSalesCustomerForm from './csp-sales-customer-form'
import { cspPackageTypes } from 'config/data'

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
  flex-direction: column;
  > * {
    margin-bottom: ${({ theme }) => theme.sizings.lvl1};
  }
  ${media.tablet`
    flex-direction: row;
    > * {
      margin-bottom: 0;
    }
  `}
`
const CancelLink = styled(TextLink)`
  padding: ${({ theme }) => theme.sizings.lvl2} 0px;
`
const CancelLinkCenter = styled(CancelLink)`
  text-align: center;
`
const CancelLinkLeft = styled(CancelLink)`
  position: absolute;
  bottom: ${({ theme }) => theme.sizings.lvl2};
`
const Step2Container = styled.div`
  padding-bottom: ${({ theme }) => theme.sizings.lvl2};
`

function CspSalesSaveAndExit({
  onClose,
  onContinue,
  payload,
  initialStep,
  ...restProps
}) {
  const {
    id,
    dealerId,
    carId,
    servicePlan,
    customer,
    activationDate,
    warrantyProviderId,
    warrantyProviderName,
    calculationId,
  } = payload
  const packageType = servicePlan?.package?.packageType || cspPackageTypes.BASIC
  const { t } = useTranslation()
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(initialStep)
  const [approvalGranted, setApprovalGranted] = useState(false)
  const mutationOptions = { refetchQueries: [{ query: GET_CONTRACTS }] }
  const approval = useQuery(REQUEST_APPROVAL, {
    skip: !warrantyProviderId || packageType === cspPackageTypes.BASIC,
    variables: {
      warrantyProviderId,
    },
  })
  const [handleApproval, handleApprovalResult] = useMutation(HANDLE_APPROVAL)
  const [addIncompleteContract, addIncompleteContractResult] = useMutation(
    ADD_INCOMPLETE_CONTRACT,
    mutationOptions,
  )
  const [updateContract, updateContractResult] = useMutation(
    UPDATE_CONTRACT,
    mutationOptions,
  )

  if (warrantyProviderId && approval.loading) {
    return <LoadingIndicator />
  }
  if (approval.error) {
    toast.error(t('cspSalesFlow.saveAndCloseModel.step1.errorHandlingApproval'))
  }
  if (handleApprovalResult.error) {
    toast.error(t('cspSalesFlow.saveAndCloseModel.step1.errorHandlingApproval'))
  }
  if (addIncompleteContractResult.error || updateContractResult.error) {
    toast.error(t('cspSalesFlow.errorSubmittingContract'))
  }

  const approvalRequired =
    // Only show the approval required modal when the package is Plus 24 or Plus 36
    (packageType === cspPackageTypes.PLUS_24_MONTHS ||
      packageType === cspPackageTypes.PLUS_36_MONTHS) &&
    approval &&
    approval.data &&
    approval.data.requestApproval.approvalRequired
      ? approval.data.requestApproval.approvalRequired
      : false

  const onSubmit = async (values) => {
    const newPayload = {
      dealerId,
      carId,
      activationDate,
      customerId: values.customerId,
      packageCalculationResultId: servicePlan.id,
      driverId: values.driverId,
    }
    if (id) {
      await updateContract({ variables: { ...newPayload, id } })
    } else {
      await addIncompleteContract({ variables: newPayload })
    }
    history.push(CSP)
  }

  const onSubmitApprovalRequired = async (values, shouldContinue) => {
    if (!approvalGranted) {
      toast.error('cspSalesFlow.saveAndCloseModel.step1.approvalRequired')
      return
    }
    try {
      await handleApproval({ variables: { dealerId, calculationId } })
    } catch (e) {
      return
    }

    if (shouldContinue) {
      onContinue()
    } else if (activeStep === 0) {
      setActiveStep((oldStep) => oldStep + 1)
    } else {
      await onSubmit(values)
    }
  }

  const steps = [
    {
      id: 'saveAndCloseOrContinue',
      title: t('cspSalesFlow.steps.saveAndCloseModal.step1.heading'),
      content: (
        <div>
          <Typography type="ExplanationParagraph">
            {t('cspSalesFlow.steps.saveAndCloseModal.step1.body')}
          </Typography>
          {approvalRequired && (
            <>
              <Typography type="ExplanationParagraph">
                <Trans>
                  {t(
                    'cspSalesFlow.steps.saveAndCloseModal.step1.approvalWarning',
                    { provider: warrantyProviderName },
                  )}
                </Trans>
              </Typography>
              <LabeledCheckbox
                id="approval"
                label={t('approve')}
                checked={approvalGranted}
                onChange={setApprovalGranted}
              />
            </>
          )}
          <Controls>
            <Button
              onClick={
                approvalRequired
                  ? onSubmitApprovalRequired
                  : () => setActiveStep((oldStep) => oldStep + 1)
              }
              disabled={approvalRequired && !approvalGranted}
              data-test-e2e="button-save-and-close"
            >
              {t('saveAndClose')}
            </Button>
            <Button
              onClick={
                approvalRequired
                  ? () => onSubmitApprovalRequired({}, true)
                  : () => onContinue()
              }
              level="cta"
              disabled={approvalRequired && !approvalGranted}
              data-test-e2e="button-create-contract"
            >
              {t('cspSalesFlow.steps.saveAndCloseModal.step1.makeContract')}
            </Button>
          </Controls>
          <CancelLinkCenter
            onClick={onClose}
            text={t('cancel')}
            data-test-e2e="link-cancel"
          />
        </div>
      ),
    },
    {
      id: '',
      title: t('cspSalesFlow.steps.saveAndCloseModal.step2.heading'),
      content: (
        <Step2Container>
          <Typography type="ExplanationParagraph">
            {t('cspSalesFlow.steps.saveAndCloseModal.step2.body')}
          </Typography>
          {addIncompleteContractResult.loading ||
          updateContractResult.loading ? (
            <LoadingIndicator />
          ) : (
            <CspSalesCustomerForm
              onSubmit={onSubmit}
              initialCustomer={customer}
              primaryActionLabel={t(
                'cspSalesFlow.steps.saveAndCloseModal.step2.saveQuotation',
              )}
              data-test-e2e="customer-form"
            />
          )}
          <CancelLinkLeft
            onClick={onClose}
            text={t('cancel')}
            data-test-e2e="link-cancel"
          />
        </Step2Container>
      ),
    },
  ]

  return (
    <Dialog
      closeHandler={onClose}
      open
      title={steps[activeStep].title}
      content={steps[activeStep].content}
      {...restProps}
    />
  )
}

CspSalesSaveAndExit.propTypes = {
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    dealerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    carId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    servicePlan: PropTypes.object.isRequired,
    mileagePerYear: PropTypes.number.isRequired,
    activationDate: PropTypes.string.isRequired,
    driver: PropTypes.object,
    customer: PropTypes.object,
    warrantyProviderId: PropTypes.string,
    warrantyProviderName: PropTypes.string,
    calculationId: PropTypes.string,
  }).isRequired,
  initialStep: PropTypes.number,
}

CspSalesSaveAndExit.defaultProps = {
  initialStep: 0,
}

export default CspSalesSaveAndExit
