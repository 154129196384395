// EXTERNAL
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'

// INTERNAL
import DatePicker from 'components/atoms/new-date-picker'
import FlexibleDialog from 'components/molecules/flexible-dialog'
import Typography from 'components/molecules/typography'
import ContentSeperator from 'components/atoms/content-separator'
import NewTextInput from 'components/atoms/new-text-input'
import LoadingIndicator from 'components/atoms/loading-indicator'

const StyledTypography = styled(Typography)`
  text-align: center;
  margin-top: 18px;
`

const BpmCalculationResultConfirmationDialogForm = ({
  dialogFormData,
  setDialogFormData,
  isLoading,
}) => {
  const { t } = useTranslation()

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      <Typography type="ExplanationParagraph">
        {t('bpmCalculation.dialog.explanation')}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={5}>
          <NewTextInput
            id="rsin-text-input"
            label={t('bpmCalculation.dialog.rsin')}
            onChange={(value) => {
              setDialogFormData({ ...dialogFormData, rsin: value })
            }}
            value={dialogFormData.rsin}
            fullWidth
            filled
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <StyledTypography type="ExplanationParagraph">
            {t('or')}
          </StyledTypography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <NewTextInput
            id="rsin-text-input"
            label={t('bpmCalculation.dialog.bsn')}
            onChange={(value) => {
              setDialogFormData({ ...dialogFormData, bsn: value })
            }}
            value={dialogFormData.bsn}
            fullWidth
            filled
          />
        </Grid>
      </Grid>
      <ContentSeperator paddingLevel={1} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <NewTextInput
            required
            id="vin-text-input"
            label={t('chassisNumber')}
            onChange={(value) => {
              setDialogFormData({ ...dialogFormData, vin: value })
            }}
            value={dialogFormData.vin}
            fullWidth
            filled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            filled
            label={t('bpmCalculation.dialog.firstRegistrationDate')}
            name="registration-date-picker"
            onChange={(value) => {
              setDialogFormData({ ...dialogFormData, date: value })
            }}
            value={dialogFormData.date}
          />
        </Grid>
      </Grid>
    </>
  )
}

BpmCalculationResultConfirmationDialogForm.propTypes = {
  dialogFormData: PropTypes.object.isRequired,
  setDialogFormData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

BpmCalculationResultConfirmationDialogForm.defaultProps = {
  isLoading: false,
}

const BpmCalculationResultConfirmationDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  handleSubmitDialog,
  dialogFormData,
  setDialogFormData,
  isLoading,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <FlexibleDialog
        closeHandler={() => {
          setIsDialogOpen(false)
        }}
        content={
          <BpmCalculationResultConfirmationDialogForm
            isLoading={isLoading}
            dialogFormData={dialogFormData}
            setDialogFormData={setDialogFormData}
          />
        }
        onSubmit={handleSubmitDialog}
        open={isDialogOpen}
        title={t('bpmCalculation.dialog.title')}
        submitText={t('bpmCalculation.dialog.submitButton')}
        disabled={isLoading || !dialogFormData.vin}
        cancel
        overflowVisible
      />
    </>
  )
}

BpmCalculationResultConfirmationDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  setIsDialogOpen: PropTypes.func.isRequired,
  handleSubmitDialog: PropTypes.func.isRequired,
  dialogFormData: PropTypes.object.isRequired,
  setDialogFormData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

BpmCalculationResultConfirmationDialog.defaultProps = {
  isDialogOpen: false,
  isLoading: false,
}

export default BpmCalculationResultConfirmationDialog
