import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import MediaImage from 'components/molecules/media-image'
import { getFileExtension, getFileName } from 'utilities/utils'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl1};
`

const Title = styled(Text)`
  margin-top: ${({ theme }) => theme.sizings.lvl0};
  word-break: break-word;
  font-weight: 400;
`

const Actions = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

const StyledMediaImage = styled(MediaImage)`
  cursor: ${({ onSelect }) => (onSelect !== null ? 'pointer' : 'default')};
`

const DOCUMENT_ICON_TYPES = ['pdf', 'doc', 'ppt', 'pfx']

const getDocImgSrc = (fileName) => {
  let fileExtension = getFileExtension(fileName)
  if (!DOCUMENT_ICON_TYPES.includes(fileExtension)) {
    fileExtension = 'doc'
  }
  return `/images/file-${fileExtension}.svg`
}

const DocumentContainer = ({
  className,
  uri,
  title,
  useFilenameFromUriAsTitle,
  documentIcon,
  selected,
  onSelect,
  onAction1Click,
  action1Label,
  onAction2Click,
  action2Label,
  ...restProps
}) => {
  const { t } = useTranslation()
  const fileName = getFileName(uri)
  return (
    <Container className={className} {...restProps}>
      <StyledMediaImage
        src={documentIcon ? getDocImgSrc(documentIcon) : getDocImgSrc(fileName)}
        ratio={4 / 3}
        alt={title || fileName}
        selected={selected}
        onSelect={onSelect}
      />
      {(title || useFilenameFromUriAsTitle) && (
        <Title>{title || fileName}</Title>
      )}
      {(onAction1Click || onAction2Click) && (
        <Actions>
          {onAction1Click && (
            <Button
              level="option"
              noPadding
              text={action1Label || t('download')}
              onClick={onAction1Click}
            />
          )}
          {onAction2Click && (
            <Button
              level="option"
              noPadding
              text={action2Label || t('delete')}
              onClick={onAction2Click}
            />
          )}
        </Actions>
      )}
    </Container>
  )
}

DocumentContainer.propTypes = {
  className: PropTypes.string,
  uri: PropTypes.string.isRequired,
  title: PropTypes.string,
  useFilenameFromUriAsTitle: PropTypes.bool,
  documentIcon: PropTypes.oneOf(DOCUMENT_ICON_TYPES),
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  onAction1Click: PropTypes.func,
  action1Label: PropTypes.string,
  onAction2Click: PropTypes.func,
  action2Label: PropTypes.string,
}

DocumentContainer.defaultProps = {
  className: undefined,
  selected: false,
  onSelect: null,
  useFilenameFromUriAsTitle: true,
  title: null,
  documentIcon: null,
  onAction1Click: null,
  action1Label: null,
  onAction2Click: null,
  action2Label: null,
}

export default DocumentContainer
