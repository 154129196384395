import { useSelector } from 'react-redux'

const useDealerLocationName = () => {
  const dealers = useSelector((state) => state.auth?.userDetails?.dealers)
  const locationId = useSelector((state) => state.auth?.userDetails?.location)

  const locationDealerName = dealers.find(
    (dealer) => dealer.id === locationId,
  )?.bedrijfsnaam

  return {
    dealerName: locationDealerName,
  }
}

export default useDealerLocationName
