import React from 'react'
import { ApolloProvider, useQuery } from '@apollo/client'
import { CSPapolloClient } from 'config/apollo'
import { useTranslation } from 'react-i18next'
import { GET_DEALERS_WITH_CSP } from 'config/graphql/csp'
import LoadingIndicator from 'components/atoms/loading-indicator'
import SummaryLine from 'components/molecules/summary-line'
import ExpandableBars from 'components/layouts/expandable-bars'
import Typography from 'components/molecules/typography'
import GeneralDataError from 'components/organisms/general-data-error'
import DirectDebitDealerDetails from 'components/views/direct-debit-dealer-details'

const DirectDebitFiles = () => {
  const { loading, error, data } = useQuery(GET_DEALERS_WITH_CSP)
  const { t } = useTranslation()

  if (loading) {
    return <LoadingIndicator error={null} />
  }
  if (error) {
    console.error(error)
    return (
      <>
        <Typography type="Level1Heading">
          {t('directDebitFiles.heading')}
        </Typography>
        <GeneralDataError />
      </>
    )
  }

  return (
    <>
      <Typography type="Level1Heading">
        {t('DirectDebitFiles.heading')}
      </Typography>
      {data.dealers &&
      Array.isArray(data.dealers.edges) &&
      data.dealers.edges.length ? (
        <ExpandableBars
          rows={data.dealers.edges.map((edge) => ({
            id: edge.node.id,
            header: (
              <SummaryLine
                name={edge.node.name}
                details={[
                  { label: t('clientNumber'), value: edge.node.uccId },
                  { value: `${edge.node.address} ${edge.node.city}` },
                  {
                    value: edge.node.email
                      ? edge.node.email
                      : t('emailUnknown'),
                  },
                  {
                    iconLabel: {
                      icon: 'modificationAndDate',
                      label: t('lastModifiedOn'),
                    },
                    value:
                      edge.node.carServicePlan &&
                      edge.node.carServicePlan.lastModified
                        ? edge.node.carServicePlan.lastModified
                        : t('unknown'),
                  },
                ]}
              />
            ),
            details: <DirectDebitDealerDetails dealerId={edge.node.id} />,
          }))}
        />
      ) : (
        <Typography type="ExplanationParagraph">
          {t('noDealersFound')}
        </Typography>
      )}
    </>
  )
}

const WrappedDirectDebitFiles = (props) => {
  const cspApolloClient = React.useMemo(() => CSPapolloClient(), [])

  return (
    <ApolloProvider client={cspApolloClient}>
      <DirectDebitFiles {...props} />
    </ApolloProvider>
  )
}

export default WrappedDirectDebitFiles
