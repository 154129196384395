import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Button from 'components/atoms/button'
import Text from 'components/atoms/text'
import Portal from 'components/molecules/portal'

const Container = styled.div`
  margin-bottom: 40px;
  min-height: 75vh;
`

const PortalsContainer = styled.div`
  margin-bottom: 40px;
`

const PortalSection = styled.div`
  margin-bottom: 40px;
`

const Title = styled(Text)`
  margin-bottom: 16px;
  text-transform: capitalize;
`

function PortalsForm({ onSubmit, portals, published }) {
  const { t } = useTranslation()
  const [selectedPortals, setSelectedPortals] = useState()
  const recommendedPortals = portals.filter(
    (portal) => portal.type === 'recommended',
  )
  const businessPortals = portals.filter((portal) => portal.type === 'business')
  const otherPortals = portals.filter((portal) => portal.type === 'other')
  const defaultSelectedPortals = portals.reduce(
    (accumulator, { id, selected }) => {
      if (!selected) {
        return accumulator
      }
      return [...accumulator, id]
    },
    [],
  )

  const portalSections = [recommendedPortals, businessPortals, otherPortals]
    .filter(
      (section) => section && Array.isArray(section) && section.length > 0,
    )
    .map((section) => ({
      title: t(section[0].type),
      portals: section,
    }))
  const submitText = published ? t('saveChanges') : t('publishOnInternet')

  useEffect(() => {
    if (
      defaultSelectedPortals &&
      defaultSelectedPortals.length &&
      !selectedPortals
    ) {
      setSelectedPortals(defaultSelectedPortals)
    }
  }, [defaultSelectedPortals, selectedPortals, setSelectedPortals])

  function handleChange(selected, id) {
    if (selected) {
      setSelectedPortals([...(selectedPortals || []), id])
    } else {
      setSelectedPortals([...selectedPortals.filter((portal) => portal !== id)])
    }
  }

  function handleSubmit() {
    onSubmit({
      portals_selected: selectedPortals,
    })
  }

  return (
    <Container>
      {!portals || portals.loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <PortalsContainer>
            {portalSections.length &&
              portalSections.map((section, index) => (
                <PortalSection key={index.toString()}>
                  <Title type="h2" text={section.title} />
                  <div>
                    {section.portals.map((portal, idx) => {
                      const date = portal.updated
                        ? portal.updated.replace(/\s.*/, '')
                        : t('unknown')
                      return (
                        <Portal
                          key={idx.toString()}
                          date={date}
                          log={portal.statusLog}
                          selected={
                            selectedPortals &&
                            selectedPortals.indexOf(portal.id) !== -1
                          }
                          onChange={(selected) =>
                            handleChange(selected, portal.id)
                          }
                          status={portal.status}
                          title={portal.omschrijving}
                        />
                      )
                    })}
                  </div>
                </PortalSection>
              ))}
          </PortalsContainer>
          <Button
            level="cta"
            onClick={handleSubmit}
            text={submitText}
            type="submit"
            data-test-e2e="button-submit-portals"
          />
        </>
      )}
    </Container>
  )
}

PortalsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  portals: PropTypes.array,
  published: PropTypes.number.isRequired,
}

PortalsForm.defaultProps = {
  portals: null,
}

export default PortalsForm
