/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { reset } from 'redux-form'
import styled from 'styled-components/macro'
import Overlay from 'components/molecules/overlay'
import { translateForm } from 'utilities/form-utils'
import ComplexForm from 'components/molecules/complex-form'
import { useHistory } from 'react-router-dom'
import toast from 'utilities/toast'
import Typography from 'components/molecules/typography'
import Icon from 'components/atoms/icon'
import Link from 'components/atoms/link'
import { toKebabCase } from 'utilities/format'
import ExplanationParagraph from 'components/atoms/explanation-paragraph'

const StyledIcon = styled(Icon)`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;

  > svg {
    fill: currentColor;
  }
`

const FileList = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl2};
  > li {
    display: block;
  }
`

function FormOverlay({
  closeOverlay,
  data,
  formCategory,
  formId,
  forms,
  icon,
  onSubmit,
  doNotResetOnClose,
  resetOnsubmit,
  storeFormId,
  submitText,
  text,
  title,
  enableReinitialize,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const overlayId = 'form'
  const categoryForms = forms[formCategory].data || {}
  const form = formId ? categoryForms[formId] : categoryForms
  const translatedForm = translateForm(form?.fieldsets || [], t)
  const [filesSets, setFilesSets] = useState(null) // initially unset/null
  const [submitted, setSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const timeoutId = useRef(null)
  const mounted = useRef(false)

  // There's an async request and mounting happening in this component,
  // we only want to display components or trigger actions when the component is still mounted
  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false

      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
    }
  }, [])

  useEffect(() => {
    if (submitted && form.reload && !form.generatesDownloadableFiles) {
      history.push(form.redirect)
      window.location.reload()
    }
  }, [submitted, form, history])

  function handleCloseOverlay() {
    if (doNotResetOnClose) {
      dispatch(reset(storeFormId))
    }
    closeOverlay(overlayId)
  }

  function handleSubmit(values) {
    setIsSubmitting(true)

    const request = onSubmit && onSubmit(values, form.endpoint)

    if (request) {
      request
        .then((response) => {
          if (mounted.current) {
            setSubmitted(true)
          }

          /**
           * form.generatesDownloadableFiles is part of
           * a form config sent from the backend API.
           * This way the backend can control this user-flow.
           */
          if (response?.success) {
            toast.success(t('formFeedback.successfullySaved'))
          }

          if (form.reload && mounted.current) {
            // some time to read the message:
            timeoutId.current = setTimeout(() => {
              window.location.reload()
            }, 2000)
          }

          if (form.generatesDownloadableFiles && mounted.current) {
            setFilesSets(response?.data?.downloadableFileSets || [])
          }
        })
        .finally(() => {
          if (mounted.current) {
            setIsSubmitting(false)
          }
        })
    } else {
      if (mounted.current) {
        setIsSubmitting(false)
      }
    }

    if (resetOnsubmit && mounted.current) {
      dispatch(reset(storeFormId))
    }

    if (!form.generatesDownloadableFiles && mounted.current) {
      handleCloseOverlay()
    }

    if (form.redirect && mounted.current) {
      history.push(form.redirect)
    }
  }

  return (
    <Overlay
      close={handleCloseOverlay}
      icon={icon}
      text={text}
      title={title}
      data-test-e2e={`overlay-${toKebabCase(storeFormId)}`}
    >
      {!submitted && (
        <ComplexForm
          data={data}
          fieldsets={translatedForm}
          formId={storeFormId}
          onSubmit={handleSubmit}
          submitText={submitText}
          level="cta"
          enableReinitialize={enableReinitialize}
          isLoading={isSubmitting}
        />
      )}

      {Array.isArray(filesSets) && filesSets.length > 0 && (
        <>
          {/* @todo: move this to its own component */}
          {filesSets.map((fileSet, fileSetIndex) => (
            <section key={`fileset-${fileSetIndex}`}>
              {fileSet.description && (
                <Typography type="ExplanationParagraph">
                  {fileSet.description}
                </Typography>
              )}
              {Array.isArray(fileSet.files) && (
                <FileList>
                  {fileSet.files.map((file, fileIndex) => (
                    <li key={`file-${fileIndex}`}>
                      <Link href={file.path}>
                        {/* @todo: generalise this icon */}
                        <StyledIcon type="printPricetag" />
                        &nbsp;
                        {file.name}
                      </Link>
                    </li>
                  ))}
                </FileList>
              )}
            </section>
          ))}
        </>
      )}

      {Array.isArray(filesSets) && filesSets.length === 0 && (
        <ExplanationParagraph>
          {t('formOverlay.noFilesInFileSet')}
        </ExplanationParagraph>
      )}
    </Overlay>
  )
}

FormOverlay.propTypes = {
  closeOverlay: PropTypes.func.isRequired,
  data: PropTypes.object,
  enableReinitialize: PropTypes.bool,
  formCategory: PropTypes.string.isRequired,
  formId: PropTypes.string,
  forms: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  resetOnsubmit: PropTypes.bool,
  submitText: PropTypes.string,
  storeFormId: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      type: PropTypes.oneOf([Trans]),
    }),
  ]),
  title: PropTypes.string.isRequired,
  /**
   * forms in overlays should (in general) reset any changes
   * to the initial values when not saved, but there are some exceptions.
   */
  doNotResetOnClose: PropTypes.bool,
}

FormOverlay.defaultProps = {
  data: null,
  enableReinitialize: false,
  forms: null,
  formId: null,
  resetOnsubmit: false,
  text: null,
  doNotResetOnClose: true,
}

export default FormOverlay
