import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import toast from 'utilities/toast'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { termsDocumentAllowedFileExtensions } from 'config/data'
import { useTranslation } from 'react-i18next'
import Button from 'components/atoms/button'
import SelectFileButton from 'components/molecules/select-file-button'
import { media } from 'utilities/styled'
import {
  UPDATE_WARRANTY_PROGRAM_QUERY,
  UPDATE_PRICE_RULE_QUERY,
  ADD_PRICE_RULE_QUERY,
  DELETE_PRICE_RULE_QUERY,
  GET_WARRANTY_PROGRAM_QUERY,
  GET_WARRANTY_PROVIDERS_AND_CAR_MODELS_QUERY,
  UPLOAD_WARRANTY_PROGRAM_DOCUMENT,
} from 'config/graphql/csp'
import Overlay from 'components/molecules/overlay'
import LoadingIndicator from 'components/atoms/loading-indicator'
import WarrantyProgramTableForm from 'components/organisms/car-service-plan/warranty-program-table-form'
import { DealerContext } from 'contexts/dealer'

const LayoutContainer = styled.div`
  flex: 1;
`

const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.sizings.lvl2};

  > * {
    margin: ${({ theme }) =>
      `${theme.sizings.lvl0} ${theme.sizings.lvl2} ${theme.sizings.lvl0} 0`};

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  ${media.tablet`
    flex-wrap: nowrap;
    
    > * {
      margin: 0 ${({ theme }) => theme.sizings.lvl2} 0 0;
    }
  `}

  ${media.desktop`
    flex-direction: row-reverse;
    margin-top: 0;

    > * {
      margin-right: 0;
      margin-left: ${({ theme }) => theme.sizings.lvl2};

      &:last-child {
        margin-left: 0;
      }
    }
  `}
`

const UploadTermsButton = styled(SelectFileButton)`
  text-align: center;

  // I am absolutely aware that !important is the devil, but I cannot
  // make this work properly without refactoring the SelectFileButton
  // component. In the interest of time, I am using !important.

  label *,
  label:hover *,
  label:focus * {
    fill: ${({ theme }) => theme.colors.actionsStandardText} !important;
  }

  ${media.desktop`
    flex-shrink: 0;
  `}
`

const UploadLoadingIndicator = styled(LoadingIndicator)`
  width: auto;
`

function WarrantyProgramDetails({ initiallyDisabled }) {
  const { t } = useTranslation()
  const { warrantyProgramId } = useParams()
  const history = useHistory()
  const [uploadingTerms, setUploadingTerms] = useState(false)
  const {
    data: warrantyProgramData,
    error: warrantyProgramError,
    client,
  } = useQuery(GET_WARRANTY_PROGRAM_QUERY, {
    variables: {
      id: warrantyProgramId,
    },
  })
  const warrantyProvidersAndCarModelsQuery = useQuery(
    GET_WARRANTY_PROVIDERS_AND_CAR_MODELS_QUERY,
  )

  const [updateWarrantyProgram] = useMutation(UPDATE_WARRANTY_PROGRAM_QUERY)
  const [updateWarrantyPriceRule] = useMutation(UPDATE_PRICE_RULE_QUERY)
  const [removeWarrantyPriceRule] = useMutation(DELETE_PRICE_RULE_QUERY)
  const [addWarrantyPriceRule] = useMutation(ADD_PRICE_RULE_QUERY)
  const [uploadWarrantyProgramDocument] = useMutation(
    UPLOAD_WARRANTY_PROGRAM_DOCUMENT,
  )

  const { setWarrantyProgramMutating } = useContext(DealerContext)

  const handleUpdateWarrantyProgram = async ({
    id,
    name,
    ownRiskPeriod,
    warrantyPriceRules,
    manufacturerWarranty,
    priceCalculation,
    maxMileage,
    externalId,
  }) => {
    setWarrantyProgramMutating(true)
    const editWarrantyProgramPayload = {
      variables: {
        id,
        name,
        ownRiskPeriod,
        manufacturerWarranty,
        priceCalculation,
        maxMileage,
        externalId,
      },
    }
    // Compares the payload price rules with old price rule to find deleted IDs
    const deletedWarrantyPriceRuleIds =
      warrantyProgramData.warrantyProgram.warrantyPriceRules
        .map((oldPriceRule) => oldPriceRule.id)
        .filter(
          (oldPriceRuleId) =>
            !warrantyPriceRules.find(
              (currentPriceRule) => currentPriceRule.id === oldPriceRuleId,
            ),
        )

    try {
      const mutations = [updateWarrantyProgram(editWarrantyProgramPayload)]
      if (
        Array.isArray(deletedWarrantyPriceRuleIds) &&
        deletedWarrantyPriceRuleIds.length > 0
      ) {
        deletedWarrantyPriceRuleIds.forEach((priceRuleId) => {
          mutations.push(
            removeWarrantyPriceRule({ variables: { id: priceRuleId } }),
          )
        })
      }
      if (Array.isArray(warrantyPriceRules) && warrantyPriceRules.length > 0) {
        warrantyPriceRules.forEach((rule) => {
          const payload = rule
          delete payload.actions
          const mutation = payload.id
            ? updateWarrantyPriceRule({
                variables: {
                  ...payload,
                },
              })
            : addWarrantyPriceRule({
                variables: {
                  ...payload,
                  warrantyProgramId: id,
                },
              })
          mutations.push(mutation)
        })
      }

      return Promise.all(mutations).then((_) => {
        const { warrantyProgram } = warrantyProgramData
        if (warrantyProgram) {
          /**
           * HACK: Clear out all warranty program from local cache
           * so they no longer show up in the UI. This prevents
           * a user from updating a already updated program, creating a
           * race condition.
           */
          client.cache.evict({
            id: client.cache.identify(warrantyProgram),
          })
          // clean up loose end, recommended by Apollo:
          client.cache.gc()
        }
        setWarrantyProgramMutating(false)
        history.goBack()
      })
    } catch (e) {
      setWarrantyProgramMutating(false)
      history.goBack()
      throw new Error(e)
    }
  }

  const termsDocumentUrl =
    warrantyProgramData &&
    warrantyProgramData.warrantyProgram &&
    warrantyProgramData.warrantyProgram.documentationFullUri
      ? warrantyProgramData.warrantyProgram.documentationFullUri
      : null

  const handleTermsUpload = (file) => {
    setUploadingTerms(true)
    uploadWarrantyProgramDocument({
      variables: {
        warrantyProgramId,
        file,
      },
      context: {
        hasUploads: true,
      },
    })
      .then(() => {
        toast.success(
          t('formFeedback.uploadSuccess', {
            part: t('carServicePlanAdminProgramsNewProgramOverlay.terms'),
          }),
        )
      })
      .catch(() => {
        toast.error(
          t('formFeedback.uploadError', {
            part: t(
              'carServicePlanAdminProgramsNewProgramOverlay.terms',
            ).toLowerCase(),
          }),
        )
      })
      .finally(() => {
        setUploadingTerms(false)
      })
  }

  return (
    <Overlay
      close={() => history.goBack()}
      title={t('carServicePlanAdminProgramsNewProgramOverlay.detailsHeading')}
      icon="cleaning"
      headerRightSlot={
        warrantyProgramData ? (
          <TermsContainer>
            <UploadTermsButton
              id="uploadTerms"
              icon="add"
              iconSize="md"
              text={t(
                'carServicePlanAdminProgramsNewProgramOverlay.uploadTerms',
              )}
              allowedFileExtensions={termsDocumentAllowedFileExtensions}
              onChange={(formData) => {
                handleTermsUpload(formData.get('file'))
              }}
              disabled={uploadingTerms}
            />
            {termsDocumentUrl && (
              <Button
                onClick={() => {
                  window.open(termsDocumentUrl, '_blank')
                }}
                icon="pdf"
                iconSize="lg"
                level="option"
                text={t(
                  'carServicePlanAdminProgramsNewProgramOverlay.downloadTerms',
                )}
              />
            )}
            {uploadingTerms && <UploadLoadingIndicator size="small" />}
          </TermsContainer>
        ) : null
      }
    >
      <LayoutContainer>
        {warrantyProgramData ? (
          <WarrantyProgramTableForm
            onSubmit={handleUpdateWarrantyProgram}
            warrantyProgram={warrantyProgramData.warrantyProgram}
            carBrandsAndModels={
              warrantyProvidersAndCarModelsQuery.data
                ? warrantyProvidersAndCarModelsQuery.data.brands
                : []
            }
            warrantyProviders={
              warrantyProvidersAndCarModelsQuery.data
                ? warrantyProvidersAndCarModelsQuery.data.warrantyProviders
                    .edges
                : []
            }
            initiallyDisabled={initiallyDisabled}
            enableReinitializeForm={false}
          />
        ) : (
          <LoadingIndicator error={warrantyProgramError} />
        )}
      </LayoutContainer>
    </Overlay>
  )
}

WarrantyProgramDetails.propTypes = {
  initiallyDisabled: PropTypes.bool,
}

WarrantyProgramDetails.defaultProps = {
  initiallyDisabled: false,
}

export default WarrantyProgramDetails
