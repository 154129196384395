import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import LegendBoxContainer from 'components/atoms/legend-box-container'
import TextInput from 'components/atoms/new-text-input'

const StyledLegendBoxContainer = styled(LegendBoxContainer)`
  /* work around to force the field to full width. Some this does
  not work on the field itself */
  > * {
    width: 100%;
  }
`

/**
 * Legend input box

 */

function LegendInputBox({
  label,
  value,
  unit,
  legendColor,
  legendBackgroundColor,
  parentIsDarker,
  onChange,
}) {
  return (
    <StyledLegendBoxContainer
      legendColor={legendColor}
      legendBackgroundColor={legendBackgroundColor}
      parentIsDarker={parentIsDarker}
    >
      <TextInput label={label} unit={unit} onChange={onChange} value={value} />
    </StyledLegendBoxContainer>
  )
}

LegendInputBox.propTypes = {
  /** Label text */
  label: PropTypes.string.isRequired,
  /** LegendColor, one of the theme color names */
  legendColor: PropTypes.string,
  legendBackgroundColor: PropTypes.string,
  /** Onchange handler */
  onChange: PropTypes.func.isRequired,
  /** For enabling the parent is darker mode */
  parentIsDarker: PropTypes.bool,
  /** Unit label right */
  unit: PropTypes.string,
  /** Value of data */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

LegendInputBox.defaultProps = {
  legendColor: 'darkOnLightBorder',
  legendBackgroundColor: 'isolatedBackground',
  parentIsDarker: false,
  unit: undefined,
  value: undefined,
}

export default LegendInputBox
