import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import Text from 'components/atoms/text'

const Container = styled.div`
  display: inline-block;
  position: relative;
`

const topStyles = css`
  bottom: calc(100% + 9px);
  left: 50%;
  transform: translateX(-50%);

  &:after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid
      ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
  }
`

const rightStyles = css`
  left: calc(100% + 9px);
  top: 50%;
  transform: translateY(-50%);

  &:after {
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 9px solid
      ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
    left: -9px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const rightBottomStyles = css`
  left: calc(100% + 9px);
  top: 80%;
  transform: translateY(-80%);

  &:after {
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 9px solid
      ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
    left: -9px;
    top: 80%;
    transform: translateY(-80%);
  }
`

const rightTopStyles = css`
  left: calc(100% + 9px);
  top: 20%;
  transform: translateY(-20%);

  &:after {
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 9px solid
      ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
    left: -9px;
    top: 20%;
    transform: translateY(-20%);
  }
`

const bottomStyles = css`
  top: calc(100% + 9px);
  left: 50%;
  transform: translateX(-50%);

  &:after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid
      ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
    top: -9px;
    left: 50%;
    transform: translateX(-50%);
  }
`

const leftStyles = css`
  right: calc(100% + 9px);
  top: 50%;
  transform: translateY(-50%);

  &:after {
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 9px solid
      ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
    right: -9px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const leftTopStyles = css`
  right: calc(100% + 9px);
  top: 20%;
  transform: translateY(-20%);

  &:after {
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 9px solid
      ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
    right: -9px;
    top: 20%;
    transform: translateY(-20%);
  }
`

const leftBottomStyles = css`
  right: calc(100% + 9px);
  top: 80%;
  transform: translateY(-80%);

  &:after {
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 9px solid
      ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
    right: -9px;
    top: 80%;
    transform: translateY(-80%);
  }
`

const StyledBubble = styled.div`
  background-color: ${({ backgroundColor, theme }) =>
    theme.colors[backgroundColor]};
  border-radius: 10px;
  max-width: 210px;
  width: 180px; // a width that works for most text in terms of scan-ability and readability
  min-width: 120px;
  padding: 10px;
  position: absolute;
  z-index: 100;
  box-shadow: ${({ theme }) => theme.shadows.tooltip};

  &:after {
    content: '';
    position: absolute;
  }

  ${({ position }) => {
    switch (position) {
      case 'top':
        return topStyles
      case 'bottom':
        return bottomStyles
      case 'right':
        return rightStyles
      case 'right-top':
        return rightTopStyles
      case 'right-bottom':
        return rightBottomStyles
      case 'left':
        return leftStyles
      case 'left-top':
        return leftTopStyles
      case 'left-bottom':
        return leftBottomStyles
      default:
        return ''
    }
  }}
`

const StyledText = styled(Text)`
  color: ${({ textColor, theme }) => theme.colors[textColor]};

  /* The texts may contain links: */
  > a[href] {
    color: currentColor; /* adopt the colour of the surrounding text */
  }
`

/**
 * @deprecated Please use [NewToolTip](/#/molecules/new-tooltip) instead. This has the newer preferred styling.
 */
function Tooltip({
  backgroundColor,
  children,
  message,
  position,
  textColor,
  ...restProps
}) {
  const [showTooltip, setShowTooltip] = useState(false)

  function handleShowTooltip() {
    setShowTooltip(true)
  }

  function handleHideTooltip() {
    setShowTooltip(false)
  }

  return (
    <Container onMouseLeave={handleHideTooltip} {...restProps}>
      {showTooltip && message && (
        <StyledBubble
          backgroundColor={backgroundColor}
          position={position}
          textColor={textColor}
        >
          <StyledText
            tag="div"
            type="count"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
        </StyledBubble>
      )}
      <div onMouseOver={handleShowTooltip} onFocus={() => {}}>
        {children}
      </div>
    </Container>
  )
}

Tooltip.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.object.isRequired,
  message: PropTypes.string,
  position: PropTypes.string,
  textColor: PropTypes.string,
}

Tooltip.defaultProps = {
  backgroundColor: 'actionsStandard',
  message: null,
  position: 'top',
  textColor: 'brandGolf',
}

export default Tooltip
