import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import { media } from 'utilities/styled'

import FlexibleDialog from 'components/molecules/flexible-dialog'
import Typography from 'components/molecules/typography'

const StyledFlexibleDialog = styled(FlexibleDialog)`
  ${media.tablet`
    .MuiDialogActions-root button:last-of-type {
      margin-left: auto;
    }
  `}
`

const CarfileEnrichmentDialog = ({ title, message, action, closable }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)

  const handleSubmitClick = useCallback(() => {
    if (!action) {
      return null
    }

    return history.push(action)
  }, [history, action])

  const handleCancelClick = () => setOpen(false)

  return (
    <StyledFlexibleDialog
      open={open}
      title={title}
      content={<Typography type="BodyParagraph">{message}</Typography>}
      submitText={t('continue')}
      onSubmit={handleSubmitClick}
      cancelText={t('close')}
      onCancel={handleCancelClick}
      cancel={!!closable}
    />
  )
}

CarfileEnrichmentDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  action: PropTypes.string,
  closable: PropTypes.bool,
}

CarfileEnrichmentDialog.defaultProps = {
  title: '',
  message: '',
  action: '',
  closable: false,
}

export default CarfileEnrichmentDialog
