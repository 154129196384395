import Typography from 'components/molecules/typography'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

const Badge = styled.div`
  width: auto;
  min-width: ${({ theme }) => theme.sizeByFactor(2.75)};
  min-height: ${({ theme }) => theme.sizeByFactor(2.75)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.sizeByFactor(0.25)};
  ${({ $statusColor, theme }) =>
    $statusColor
      ? `background-color: ${theme.colors[$statusColor] || $statusColor};`
      : ''}
`

const Content = styled(Typography)`
  line-height: 1;
  ${({ $textColor, theme }) =>
    $textColor ? `color: ${theme.colors[$textColor] || $textColor};` : ''}
`

const StatusBadge = ({
  statusColor,
  textColor,
  content,
  children,
  ...restProps
}) => {
  return (
    <Badge {...restProps} $statusColor={statusColor}>
      {(typeof content === 'string' || typeof content === 'number') && (
        <Content $textColor={textColor} type="InlineBodyText">
          {content}
        </Content>
      )}
      {children}
    </Badge>
  )
}

StatusBadge.propTypes = {
  statusColor: PropTypes.string, // Either a color from the theme's tokens, or a valid CSS value.
  textColor: PropTypes.string, // Either a color from the theme's tokens, or a valid CSS value.
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
}

export default StatusBadge
