import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import Typography from 'components/molecules/typography'
import Dialog from 'components/molecules/flexible-dialog'
import TextLink from 'components/atoms/text-link'
import Button from 'components/atoms/button'

const ResetModalBody = styled(Typography)`
  margin: 0;
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`

const CancelLink = styled(TextLink)`
  padding: ${({ theme }) => theme.sizings.lvl3} 0;
`

const CspSalesResetDialog = ({ onReset, onCancel }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      closeHandler={onCancel}
      open
      title={t('areYouSureFormal')}
      content={
        <>
          <ResetModalBody type="BodyParagraph">
            {t('cspSalesFlowResetBody')}
          </ResetModalBody>
          <Controls>
            <CancelLink onClick={onCancel} text={t('cancel')} />
            <Button onClick={onReset} level="cta">
              {t('cspSalesFlowResetButtonLabel')}
            </Button>
          </Controls>
        </>
      }
    />
  )
}

CspSalesResetDialog.propTypes = {
  onReset: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CspSalesResetDialog
