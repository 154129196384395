import numbro from 'numbro'
import moment from 'moment'
import i18next from 'i18next'

import { unitsOfMeasurement } from 'config/data'
import { CHECKLISTS } from 'config/routes'
/**
 * Contains all formatters for UCC.
 */

/**
 * Can be useful, but try to use css' ::first-letter selector instead,
 * since it is way more performant.
 * @param {String} text
 */
export function capitalizeFirstLetter(text) {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`
}

/**
 * Internal function used to match special characters that can be converted to kebab or snake case
 * @param {String} text
 */
const prepareForKebabAndCamelCase = (text) =>
  text
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())

/**
 * Convert a string to a snake_case_string.
 * @param {String} text
 */
export const toSnakeCase = (text) => prepareForKebabAndCamelCase(text).join('_')

/**
 * Convert a string to a snake_case_string.
 * @param {String} text
 */
export const toKebabCase = (text) => prepareForKebabAndCamelCase(text).join('-')

/**
 * @param {String|Number} value
 * @param {?String} unit The unit to display at the end. When it is null no unit is shown
 */
export function formatMileage(value, unit = 'km') {
  let numericalValue = parseInt(value, 10)
  if (Number.isNaN(numericalValue)) {
    numericalValue = 0
  }

  return `${numbro(numericalValue).format({ thousandSeparated: true })}${unit ? ' ' + unit : ''}`
}

/**
 * Formats a price, in the local currency set in numbro.
 * For more info, see {@link https://numbrojs.com/format.html#currency|Numbro's docs}
 * @param {*} value - Will be converted to a price. Should be parsable to a float.
 * @param {boolean} [includeDecimals=true] - Include the decimals?
 * @param {boolean} [includeCurrency=true] - Include the currency (€/$/etc.) symbol?
 * @param {boolean} [includeThousandSeparator=false] - Include the thousand separator?
 */
export function formatPrice(
  value,
  includeDecimals = true,
  includeCurrency = true,
  includeThousandSeparator = false,
) {
  let floatValue = parseFloat(value)
  if (Number.isNaN(floatValue)) {
    floatValue = 0
  }

  const numbroValue = numbro(floatValue)
  const formatSettings = {
    thousandSeparated: includeThousandSeparator,
    mantissa: includeDecimals ? 2 : 0,
  }

  return includeCurrency
    ? numbroValue.formatCurrency(formatSettings)
    : numbroValue.format(formatSettings)
}

// https://github.com/webeau-nl/overheid-io/issues/1
export function formatLicenseplate(str) {
  if (typeof str !== 'string') {
    return ''
  }
  // If a licenseplate is a duplicate of another carfile this
  // is indicated on the backend by sending a license plate
  // prepended with a hyphen, we detect that here and prepend
  // the formatted license plate with 'D: ' at the end of this
  // function if so.
  const isDuplicate = !!str.match(/^-/)
  const licencePlate = str.replace(/-/g, '').toUpperCase()

  // licencePlates for diplomates should not be formatted
  const diplomateLicencePlateRegex = /^CD[ABFJNST]\d{1,3}$/ // for example: CDB1 of CDJ45
  if (licencePlate.match(diplomateLicencePlateRegex)) {
    return licencePlate
  }

  const licencePlateRegexes = [
    /^([A-Z]{2})(\d{2})(\d{2})$/, // 1) XX-99-99 (since 1951)
    /^(\d{2})(\d{2})([A-Z]{2})$/, // 2)) 99-99-XX (since 1965)
    /^(\d{2})([A-Z]{2})(\d{2})$/, // 3) 99-XX-99 (since 1973)
    /^([A-Z]{2})(\d{2})([A-Z]{2})$/, // 4) XX-99-XX (since 1978)
    /^([A-Z]{2})([A-Z]{2})(\d{2})$/, // 5) XX-XX-99 (since 1991)
    /^(\d{2})([A-Z]{2})([A-Z]{2})$/, // 6) 99-XX-XX (since 1999)
    /^(\d{2})([A-Z]{3})(\d{1})$/, // 7) 99-XXX-9 (since 2005)
    /^(\d{1})([A-Z]{3})(\d{2})$/, // 8) 9-XXX-99 (since 2009)
    /^([A-Z]{2})(\d{3})([A-Z]{1})$/, // 9) XX-999-X (since 2006)
    /^([A-Z]{1})(\d{3})([A-Z]{2})$/, // 10) X-999-XX (since 2008)
    /^([A-Z]{3})(\d{2})([A-Z]{1})$/, // 11) XXX-99-X (since 2015)
    /^([A-Z]{1})(\d{2})([A-Z]{3})$/, // 12) X-99-XXX
    /^(\d{1})([A-Z]{2})(\d{3})$/, // 13) 9-XX-999
    /^(\d{3})([A-Z]{2})(\d{1})$/, // 14) 999-XX-9
    /^(\d{3})(\d{2})([A-Z]{1})$/, // 15) 999-99-X
    /^([A-Z]{3})(\d{2})(\d{1})$/, // 16) XXX-99-9
    /^([A-Z]{3})([A-Z]{2})(\d{1})$/, // 17) XXX-XX-9
  ]

  let formattedLicensePlate = licencePlate

  licencePlateRegexes.forEach((value) => {
    if (licencePlate.match(value)) {
      formattedLicensePlate = licencePlate.replace(value, '$1-$2-$3')
    }
  })

  if (isDuplicate) {
    formattedLicensePlate = `D: ${formattedLicensePlate}`
  }

  return formattedLicensePlate
}

/**
 * Formats phone number, the minimum length of the phone number is assumed to be 10!
 * @param {String} phoneNumber just like 02079250918
 * @param {String} usageHint an optional text to display after the number.
 */
export function formatPhoneNumber(phoneNumber, usageHint) {
  // TODO: add country code for French / English customers (so they know to add +31)
  const divisibleInPartsOfThrees = phoneNumber.length % 3 === 0
  let formattedDigits = ''
  if (divisibleInPartsOfThrees) {
    for (let i = 0; i < phoneNumber.length; i += 3) {
      formattedDigits += `${phoneNumber.substring(i, i + 3)} `
    }
  } else {
    const positionLastFour = phoneNumber.length - 1 - 4
    // First chop up in bits of threes, until last 4:
    for (let i = 0; i < positionLastFour; i += 3) {
      formattedDigits += `${phoneNumber.substring(i, i + 3)} `
    }
    // then divide last four up in two bits of two:
    formattedDigits += `
      ${phoneNumber.substring(positionLastFour + 1, positionLastFour + 3)}
      ${phoneNumber.substring(positionLastFour + 3, positionLastFour + 5)}
      `
  }

  return `${formattedDigits.trim()}${usageHint ? ` (${usageHint})` : ''}`
}

export const DateFormats = {
  YEAR_MONTH_DAY: 'DD-MM-YYYY',
  YEAR_MONTH: 'MM-YYYY',
}

/**
 * Formats date with moment. Default output is DD-MM-YYYY, but can be changed.
 * For formatting options, see {@link https://momentjs.com/docs/#/displaying/format/|Moment.js docs}.
 * @param {String} date
 * @param {String} [inputFormat='YYYY-MM-DD'] Date format you provide the date in
 * @param {String} [outputFormat='DD-MM-YYYY'] What do you want the date to be in the end?
 * Please use the predefined DateFormats constants to ensure consistent date formats throughout the application.
 */
export function formatDate(
  date,
  inputFormat = 'YYYY-MM-DD',
  outputFormat = DateFormats.YEAR_MONTH_DAY,
) {
  // If date hasn't been set, just return "-"
  if (!date) {
    return '-'
  }

  const momentDate = moment(date, inputFormat)

  // If date is set and it's a valid date (according to Moment's API), return the formatted date
  if (momentDate.isValid()) {
    return momentDate.format(outputFormat)
  }

  // If date is set and it's NOT a valid date (according to Moment's API), return a translateable error message
  return i18next.t('invalidDate')
}

/**
 * Formats dates with the @see formatDate() function.
 * @param {String} startDate
 * @param {String} endDate
 * @param {String} [inputFormat='YYYY-MM-DD'] Date format you provide the date in
 * @param {String} [outputFormat='DD-MM-YYYY'] What do you want the date to be in the end?
 * Please use the predefined DateFormats constants to ensure consistent date formats throughout the application.
 * @returns {String} formatted date range using an en-dash to denote a range.
 */
export function formatDateRange(
  startDate,
  endDate,
  inputFormat = 'YYYY-MM-DD',
  outputFormat = DateFormats.YEAR_MONTH_DAY,
) {
  return `${formatDate(startDate, inputFormat, outputFormat)} – ${formatDate(endDate, inputFormat, outputFormat)}`
}

// A convenience function to format a unit in an enhanced table
export const formatUnit = (unit) => unitsOfMeasurement[unit] || unit

export const formatPercentage = (
  value,
  numberOfDecimals = 1,
  includeUnit = true,
) => {
  let floatValue = parseFloat(value)
  if (Number.isNaN(floatValue)) {
    floatValue = 0
  }

  const numbroValue = numbro(floatValue).format({
    mantissa: numberOfDecimals,
    optionalMantissa: true,
  })
  return includeUnit ? `${numbroValue}%` : numbroValue
}

// Enhanced table from/to values can be formatted into a simple text label using this function
export const formatFromToLabel = (from, to, type, unit) => {
  if (
    (typeof from === 'undefined' || !from) &&
    (typeof to === 'undefined' || !to)
  ) {
    return ''
  }
  const isCurrency = unit === 'currency_euro'
  let fromFormatted = from
  let toFormatted = to
  const unitFormatted = isCurrency ? '' : formatUnit(unit)
  if (type === 'fromToNumber') {
    if (!from) {
      fromFormatted = 0
    }
    if (!to) {
      toFormatted = 0
    }
    if (isCurrency) {
      fromFormatted = formatPrice(fromFormatted)
      toFormatted = formatPrice(toFormatted)
    }
  } else {
    if (typeof from === 'undefined') {
      fromFormatted = '?'
    }
    if (typeof to === 'undefined') {
      toFormatted = '?'
    }
  }

  return `${fromFormatted}${unitFormatted ? ' ' + unitFormatted : ''} - ${toFormatted}${unitFormatted ? ' ' + unitFormatted : ''}`
}

export const formatDataTestE2eAttr = (name, suffix) => {
  if (!name) {
    return undefined
  }
  const nameFormatted = toKebabCase(name)
  return typeof suffix === 'string'
    ? `${nameFormatted}-${toKebabCase(suffix)}`
    : nameFormatted
}

export const truncate = (s, maxChars, wholeWordsOnly = false, suffix) => {
  // Check if truncation is needed
  if (s.length < maxChars) {
    return s
  }

  // Calculate the absolute maximum number of characters, excluding the suffix, if one is set
  const max = suffix ? maxChars - suffix.length : maxChars

  // Truncate the string, based on the maximum number of characters
  const truncatedS = wholeWordsOnly
    ? s.substr(0, s.substr(0, max + 1).lastIndexOf(' ')) // Truncate the string up until the last whole word
    : s.substr(0, max) // Just truncate the string, regardless of whole words

  // Add the suffix if one is set, and return the truncated string
  return `${truncatedS}${suffix || ''}`
}

// All params are optional
export const formatChecklistsOverlayUrl = (
  locationId,
  status,
  checklistId,
  filters = null,
) => {
  const parts = [CHECKLISTS]
  if (locationId) {
    parts.push(locationId)

    if (status) {
      parts.push(status)

      if (checklistId) {
        parts.push(checklistId)
      }
    }
  }

  let path = parts.join('/')

  if (typeof filters === 'string') {
    path += filters
  } else if (filters) {
    const params = new URLSearchParams(filters)
    path += `?${params.toString()}`
  }

  return path
}

export const formatSingleChecklistsOverlayUrl = (
  checklistId,
  checklistItemId,
  filters,
) => {
  const parts = [CHECKLISTS, checklistId]

  if (typeof checklistItemId !== 'undefined') {
    parts.push(checklistItemId)
  }

  let path = parts.join('/')

  if (filters) {
    const formattedFilters = Object.fromEntries(
      Object.entries(filters).filter(
        (entry) => entry[1] !== undefined && entry[1] !== null,
      ),
    )
    const params = new URLSearchParams(formattedFilters)
    path += `?${params.toString()}`
  }

  return path
}
