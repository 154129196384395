/* eslint-disable camelcase */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getCarfile } from 'redux/actions/data'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import CarEnrichmentStep1 from 'components/views/car-enrichment/steps/car-enrichment-step1'
import CarEntryStep1NoLicense from 'components/views/car-enrichment/steps/car-entry-step1-no-license'
import CarEntryStep1Manual from 'components/views/car-enrichment/steps/car-entry-step1-manual'
import CarEntryStep2 from 'components/views/car-enrichment/steps/car-entry-step2'

function CarEnrichment({
  createCarfile,
  location,
  match,
  newCar,
  updateCarfile,
  ...restProps
}) {
  const { t } = useTranslation()
  const { step, carFileId } = match.params
  const dispatch = useDispatch()

  const carFile = useSelector(
    (state) => state.data && state.data.carfile && state.data.carfile.data,
  )

  useEffect(() => {
    if (carFileId) {
      dispatch(getCarfile(carFileId))
    }
  }, [carFileId, step, dispatch])

  const prefilledLicensePlate = carFile?.kenteken || ''
  const prefilledVin = carFile?.chassisnr || ''
  const prefilledMileage = carFile?.mileage || null

  return (
    <AppLayoutContainer pageTitle={t('carFileEnrichment.pageTitle')}>
      {
        {
          1: (
            <CarEnrichmentStep1
              newCar={newCar}
              carFileId={carFileId}
              prefilledLicensePlate={prefilledLicensePlate}
              prefilledMileage={prefilledMileage}
              prefilledVin={prefilledVin}
              {...restProps}
            />
          ),
          2: (
            <CarEntryStep2
              createCarfile={createCarfile}
              updateCarfile={updateCarfile}
              carFileId={carFileId}
              carFile={(newCar && newCar.data) || carFile}
              {...restProps}
            />
          ),
          nolicenseplate: (
            <CarEntryStep1NoLicense
              car={newCar && newCar.data}
              newCar={newCar}
              carFileId={carFileId}
              {...restProps}
            />
          ),
          manual: (
            <CarEntryStep1Manual
              createCarfile={createCarfile}
              newCar={newCar && newCar.data}
              updateCarfile={updateCarfile}
              carFileId={carFileId}
              {...restProps}
            />
          ),
        }[step]
      }
    </AppLayoutContainer>
  )
}

CarEnrichment.propTypes = {
  createCarfile: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  newCar: PropTypes.object,
  updateCarfile: PropTypes.func.isRequired,
}

CarEnrichment.defaultProps = {
  newCar: null,
}

export default CarEnrichment
