import React from 'react'
import PropTypes from 'prop-types'
import { media } from 'utilities/styled'
import styled from 'styled-components/macro'
import Button from 'components/atoms/button'
import Counter from 'components/atoms/counter'
import Typography from 'components/molecules/typography'
import Icon from 'components/atoms/icon'
import { Trans } from 'react-i18next'

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.brandFoxtrot};
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: fixed;
  left: 0;
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 200;

  /*
  Purposely re-creating the bottom padding using
  pseudo-elements, because otherwise overflow: auto
  ignores the bottom  padding as part of the content
  in FireFox.
  background: https://stackoverflow.com/questions/38993170/last-margin-padding-collapsing-in-flexbox-grid-layout
  */
  padding: ${({ padding }) => (padding ? '24px' : '')};
  padding-bottom: 0;
  &::after {
    content: '';
    height: ${({ padding }) => (padding ? '24px' : '')};
    flex: 0 0 ${({ padding }) => (padding ? '24px' : 0)};
  }
`

const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.sizings.lvl0};
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  flex-wrap: nowrap;

  ${media.desktop`
    margin-bottom: ${({ theme }) => theme.sizings.lvl2};
  `};
`

const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
`

const HeaderTitle = styled(Typography)`
  margin: 0;
  line-height: 1.2em; // gives the text the space to vertically align with other adjacent inlined items.
`

const HeaderRightSlotContainer = styled.div`
  display: flex;
  align-items: center;
`

const Body = styled.div`
  flex: 0 0 auto;
  margin-bottom: ${({ theme }) => theme.sizeByFactor(1)};

  ${media.desktop`
    margin: 0 32px 32px;
    padding-right: 50%;
  `};
`

const BodyText = styled(Typography)`
  font-size: 16px;
`

const ChildContainer = styled.div`
  flex: 1 0 auto; // let the content stretch out to take all available space.
  display: flex;

  ${media.desktop`
    margin: ${({ margin, theme }) => (margin ? `0 ${theme.sizeByFactor(8)} 0 ${theme.sizeByFactor(4)}` : '')};
  `};
`

const CloseButton = styled(Button)`
  margin-left: ${(props) => props.theme.sizings.lvl1};
  flex: 0 0 auto;
  background-color: ${({ theme }) => theme.colors.defaultBackground};

  ${media.tablet`
    flex-wrap: nowrap;
    display: flex;
    position: ${(props) => (props.$isAbsolute ? 'absolute' : 'static')};
  `}

  ${(props) =>
    props.$isAbsolute &&
    `
      position: absolute;
      top: ${props.theme.sizings.lvl2};
      right: ${props.theme.sizings.lvl2};
      z-index: 100; // raise it up above the other content, so it is clickable.
  `}
`

const StyledIcon = styled(Icon)`
  display: none;
  margin-right: ${(props) => props.theme.sizings.lvl1};

  ${media.tablet`
    display: block;
  `}
`

const StyledCounter = styled(Counter)`
  margin-left: ${(props) => props.theme.sizings.lvl1};
`

function Overlay({
  className,
  children,
  close,
  count,
  icon,
  text,
  title,
  headerRightSlot,
  ...restProps
}) {
  return (
    <Container
      className={className}
      id="overlay"
      padding={title}
      {...restProps}
    >
      {(title || headerRightSlot) && (
        <Header className="header">
          {title && (
            <HeaderTitleContainer className="header-left-slot">
              {icon && <StyledIcon type={icon} color="text" height="28" />}
              <HeaderTitle type="Level1Heading">{title}</HeaderTitle>
              {!!count && <StyledCounter count={count} />}
            </HeaderTitleContainer>
          )}
          <HeaderRightSlotContainer className="header-right-slot">
            {headerRightSlot}
            <CloseButton
              ariaLabel="Close button"
              iconColor="text"
              level="minimal"
              icon="close"
              onClick={close}
              $isAbsolute={!title}
              data-test-e2e="button-close"
            />
          </HeaderRightSlotContainer>
        </Header>
      )}
      {!title && !headerRightSlot && (
        <CloseButton
          iconColor="text"
          level="minimal"
          icon="close"
          onClick={close}
          $isAbsolute
        />
      )}
      {text && (
        <Body>
          <BodyText type="ExplanationParagraph">{text}</BodyText>
        </Body>
      )}
      <ChildContainer margin={title} className="child-container">
        {children}
      </ChildContainer>
    </Container>
  )
}

Overlay.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  count: PropTypes.number,
  close: PropTypes.func.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      type: PropTypes.oneOf([Trans]),
    }),
  ]),
  headerRightSlot: PropTypes.node,
}

Overlay.defaultProps = {
  className: null,
  count: null,
  icon: null,
  text: null,
  title: null,
  headerRightSlot: null,
}

export default Overlay
