import { ApolloProvider } from '@apollo/client'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import { CSPapolloClient } from 'config/apollo'
import {
  CSP,
  CSP_CONTRACT_FILE,
  CSP_DEMO_SALES_FLOW,
  CSP_NO_LICENSE_PLATE,
  CSP_SALES_FLOW,
} from 'config/routes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch } from 'react-router-dom'

import CarServicePlanSales from '../../redux-containers/csp-sales-container'
import CarServicePlanContractDetails from './contract-details'
import CarServicePlanDashboard from './csp-dashboard'
import CspNoLicensePlateView from './csp-no-license-plate'
import CarServicePlanSalesFlowDemo from './sales-flow-demo'

// This component expects to be rendered as the child of a React <Router> component.
function CarServicePlan() {
  const { t } = useTranslation()

  // Once it is loaded there is no need to load it again on each re-render:
  const cspApolloClient = React.useMemo(() => CSPapolloClient(), [])

  return (
    <ApolloProvider client={cspApolloClient}>
      <AppLayoutContainer pageTitle={t('carServicePlan')}>
        <Switch>
          <Route
            path={`${CSP_SALES_FLOW}`}
            exact
            component={CarServicePlanSales}
          />
          <Route
            path={`${CSP_SALES_FLOW}/:stepId`}
            component={CarServicePlanSales}
          />
          <Route
            path={`${CSP_CONTRACT_FILE}/:id`}
            component={CarServicePlanContractDetails}
          />
          <Route
            path={CSP_DEMO_SALES_FLOW}
            exact
            component={CarServicePlanSalesFlowDemo}
          />
          <Route
            path={CSP_NO_LICENSE_PLATE}
            component={CspNoLicensePlateView}
          />
          <Route path={CSP} component={CarServicePlanDashboard} />
        </Switch>
      </AppLayoutContainer>
    </ApolloProvider>
  )
}

export default CarServicePlan
