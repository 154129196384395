import { useQuery } from '@apollo/client'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Typography from 'components/molecules/typography'
import ContractExtensionRequestsTable from 'components/views/car-service-plan/contract-extension-requests-table'
import { EnhancedToolbarLink } from 'components/organisms/enhanced-table'
import { GET_CONTRACT_EXTENSION_REQUESTS } from 'config/graphql/csp'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import Button from 'components/atoms/button'
import NewTextInput from 'components/atoms/new-text-input'
import Icon from 'components/atoms/icon'
import { formatDate, formatPrice } from 'utilities/format'
import ActionIconButton from 'components/atoms/action-icon-button'
import { CAR_FILE } from 'config/routes'
import storage from 'utilities/storage'
import { carServicePlanApi } from 'config/data'
import { JWT_STORAGEID } from 'redux/reducers/auth'
import NewTooltip from 'components/atoms/new-tooltip'
import RateExtensionRequestsDialog from 'components/views/car-service-plan/dialogs/rate-extension-requests-dialog'

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};

  ${media.tv`
    flex-wrap: nowrap;
  `}
`

const HeadingContainer = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};

  ${media.tv`
    width: auto;
    margin-right: auto;
    margin-bottom: 0;
  `}
`

const Heading = styled(Typography)`
  margin-top: 0;
  margin-right: 0;
  width: 100%;
`

const FiltersContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
  gap: ${({ theme }) => theme.sizings.lvl1};

  ${media.tablet`
    align-items: center;
  `}

  ${media.desktop`
    flex-wrap: nowrap;
    gap: ${({ theme }) => theme.sizings.lvl2};
  `}

  ${media.tv`
    width: auto;
  `}
`

const ExplanationParagraph = styled(Typography)`
  margin-bottom: 0;
`

const SearchQueryInput = styled(NewTextInput)`
  width: 100%;

  ${media.tablet`
    width: auto;
    min-width: 225px;
  `}

  ${media.hd`
    min-width: 300px;
  `}
`

const ExportButton = styled(Button)`
  ${media.tablet`
    margin-left: auto;
  `}

  ${media.tv`
    order: -1;
  `}
`

const Line = styled.span`
  display: block;
  width: 1px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.brandDelta};
  margin: 0 ${({ theme }) => theme.sizeByFactor(1.5)};
`

const RateRequestButton = styled(ActionIconButton)`
  html &,
  html &:hover {
    svg * {
      fill: ${({ $declined, theme }) =>
        $declined ? theme.colors.statusErrorLvl1 : 'inherit'} !important;
    }
  }
`

const ActionIconButtonTooltip = styled(NewTooltip)`
  display: block !important;

  > a,
  > button {
    display: block;
  }
`

const ContractExtensionRequests = () => {
  const { t } = useTranslation()
  const userIsAdmin = useSelector((state) => !!state.auth?.userDetails?.admin)
  const jwt = storage.getValue(JWT_STORAGEID, undefined)
  const exportUrl =
    carServicePlanApi && jwt
      ? `${new URL(carServicePlanApi).origin}/export/extension-requests?token=${jwt}`
      : undefined

  const [searchQuery, setSearchQuery] = React.useState('')
  const [rateExtensionRequestIds, setRateExtensionRequestIds] = React.useState(
    [],
  )
  const [selectedExtensionRequestIds, setSelectedExtensionRequestIds] =
    React.useState([])

  const { loading, error, data } = useQuery(GET_CONTRACT_EXTENSION_REQUESTS)

  const rows = React.useMemo(
    () =>
      !data?.extensionRequests
        ? []
        : data.extensionRequests
            .map((request) => ({
              id: request.id,
              contractId: request.contract.id,
              licensePlate: request.contract.car.licensePlate,
              carFileId: request.contract.car.carFileId,
              clientLastName: request.contract.customer.lastName,
              postalcode: request.contract.customer.zipCode,
              package: request.contract.package.name,
              endDate: request.contract.expirationDate,
              duration: request.contract.period,
              oldPrice: request.contract.packageCalculationResult?.resultNet,
              newPrice: request.packageCalculationResult.resultNet,
              documentUrl: request.document?.fullUri,
              status: request.status,
            }))
            // Filter already accepted rows
            .filter((row) => row.status !== 'APPROVED')
            // Filter by search query
            .filter((row) => {
              if (!searchQuery) {
                return true
              } // No search query set, no filtering required
              // Look at every column's data prop and see if the search query occurs there
              const lowerCaseQuery = searchQuery.toLowerCase()
              return Object.keys(row).filter((column) => {
                // Try to convert the data to a string so the query can be checked
                try {
                  const stringValue = row[column].toString()
                  // Check to see if the search query occurs in the data value
                  return stringValue.toLowerCase().indexOf(lowerCaseQuery) >= 0
                } catch (e) {
                  // Not convertible to a string, so not searchable
                  return false
                }
              }).length
            })
            .map((request) => {
              const {
                status,
                duration,
                endDate,
                carFileId,
                oldPrice,
                newPrice,
                contractId,
                documentUrl,
                ...restProps
              } = request

              const declined = status === 'DECLINED'
              const tooltipMessage = declined
                ? 'carServicePlanContractExtensionRequestsTable.stillApproveExtensionRequest'
                : 'carServicePlanContractExtensionRequestsTable.rateExtensionRequest'

              const endDateFormatted = endDate ? formatDate(endDate) : ''

              const actions = (
                <>
                  {documentUrl && (
                    <>
                      <ActionIconButtonTooltip
                        title={t(
                          'carServicePlanContractExtensionRequestsTable.downloadExampleDocuments',
                        )}
                      >
                        <ActionIconButton
                          icon="doc"
                          to={documentUrl}
                          href={documentUrl}
                          data-test-e2e="button-download-document"
                        />
                      </ActionIconButtonTooltip>
                      <Line />
                    </>
                  )}
                  <ActionIconButtonTooltip title={t(tooltipMessage)}>
                    <RateRequestButton
                      icon="checkbox"
                      onClick={() => {
                        setRateExtensionRequestIds([request.id])
                      }}
                      $declined={declined}
                      data-test-e2e="button-edit-contract"
                    />
                  </ActionIconButtonTooltip>
                </>
              )
              return {
                duration: t(
                  'carServicePlanContractExtensionRequestsTable.durationMonths',
                  { months: duration },
                ),
                endDate: endDateFormatted,
                actions,
                licensePlateLink: carFileId ? `${CAR_FILE}/${carFileId}` : null,
                oldPrice: oldPrice ? formatPrice(oldPrice) : '',
                newPrice: newPrice ? formatPrice(newPrice) : '',
                ...restProps,
              }
            }),
    [data, searchQuery, t],
  )

  const handleBulkDownloadExampleDocuments = () => {
    const ids = !data?.extensionRequests
      ? []
      : data.extensionRequests
          .filter(
            (request) =>
              selectedExtensionRequestIds.includes(request.id) &&
              request.document?.fullUri,
          )
          .map((request) => request.id)

    if (ids.length === 0 || !carServicePlanApi || !jwt) {
      return
    }
    const idsParam = ids.map((id) => `ids[]=${id}`).join('&')
    const bulkDownloadUrl =
      carServicePlanApi && jwt
        ? `${new URL(carServicePlanApi).origin}/export/extension-requests/zip?${idsParam}&token=${jwt}`
        : undefined

    window.open(bulkDownloadUrl, '_blank')
    setRateExtensionRequestIds([])
  }
  const handleBulkRateExtensionRequests = () => {
    setRateExtensionRequestIds(selectedExtensionRequestIds)
  }

  if (loading) {
    return <LoadingIndicator error={null} size="small" />
  }
  if (error) {
    console.error(error)
    return (
      <>
        <Typography type="Level2Heading">
          {t('problemsFoundHeading')}
        </Typography>
        <Typography type="ExplanationParagraph">
          {t('problemsWhenRetrievingData')}
        </Typography>
      </>
    )
  }

  return (
    <div>
      <Header>
        <HeadingContainer>
          <Heading type="Level1Heading">
            {t('carServicePlanDashboard.contractExtensionRequests')}
          </Heading>
          <ExplanationParagraph type="ExplanationParagraph">
            {t(
              'carServicePlanContractExtensionRequestsTable.clickOnExtensionRequestForInfo',
            )}
          </ExplanationParagraph>
        </HeadingContainer>
        <FiltersContainer data-test-e2e="contracts-table-filters">
          <SearchQueryInput
            label={t(
              'carServicePlanContractExtensionRequestsTable.searchForExtensionRequests',
            )}
            filled
            icon={<Icon type="search" />}
            onChange={setSearchQuery}
            value={searchQuery}
            id="search"
          />
          {userIsAdmin && exportUrl && (
            <ExportButton
              level="option"
              icon="download"
              href={exportUrl}
              to={exportUrl}
            >
              {t(
                'carServicePlanContractExtensionRequestsTable.exportExtensionRequests',
              )}
            </ExportButton>
          )}
        </FiltersContainer>
      </Header>
      <ContractExtensionRequestsTable
        rows={rows}
        selectable
        onSelect={(rowIndexes) => {
          const ids = rowIndexes.map((index) => rows[index].id)
          setSelectedExtensionRequestIds(ids)
        }}
        selectedToolbarSlot={
          <>
            <EnhancedToolbarLink
              text={t(
                'carServicePlanContractExtensionRequestsTable.downloadExampleDocumentsBulk',
              )}
              onClick={handleBulkDownloadExampleDocuments}
            />
            <EnhancedToolbarLink
              text={t(
                'carServicePlanContractExtensionRequestsTable.rateExtensionRequestsBulk',
              )}
              onClick={handleBulkRateExtensionRequests}
            />
          </>
        }
        noDataMessage={t(
          'carServicePlanContractExtensionRequestsTable.noResults',
        )}
      />
      {rateExtensionRequestIds && rateExtensionRequestIds.length > 0 && (
        <RateExtensionRequestsDialog
          extensionRequestIds={rateExtensionRequestIds}
          onClose={() => setRateExtensionRequestIds([])}
        />
      )}
    </div>
  )
}

export default ContractExtensionRequests
