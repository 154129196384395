import BodyHeading from 'components/atoms/body-heading'
import LabeledText from 'components/atoms/labeled-text'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatMileage } from 'utilities/format'
import styled from 'styled-components/macro'
import FramedImage from 'components/atoms/framed-image'
import LicensePlate from 'components/atoms/license-plate'
import moment from 'moment'
import { media } from 'utilities/styled'

const StyledHeader = styled.header`
  display: flex;
`

const StyledBodyHeading = styled(BodyHeading)`
  margin: 0 0 ${({ theme }) => theme.sizeByFactor(1)} 0;
  // Needs to be the same as the body text colour,
  // so it does not draw the user's focus to much.
  color: ${({ theme }) => theme.colors.text} !important;
  font-weight: bold;
  font-size: ${({ theme }) => theme.sizeByFactor(3)};
`

const LeftSection = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.baseSize}px;
  justify-content: flex-end;
`

const RightSection = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.baseSize / 2}px;
`

const LabeledProperties = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.baseSize * 2}px;

  ${media.phone`
    gap: ${({ theme }) => theme.baseSize * 10}px;
    flex-direction: row;
  `}

  * {
    font-size: ${({ theme }) => theme.baseFontSize};
    color: ${({ theme }) => theme.colors.text};
  }
`
const StyledLicensePlate = styled(LicensePlate)`
  > * {
    font-weight: 600;
  }
`

function CarFileHeaderForWidget({ car, customMileage }) {
  const { t } = useTranslation()

  const carPictureUrl =
    Array.isArray(car.carPictures) && car.carPictures.length
      ? car.carPictures.find((pic) => pic.type === 'EXTERIOR' && pic.url)
          ?.url || car.carPictures[0].url
      : null

  return (
    <StyledHeader>
      {car ? (
        <>
          <LeftSection>
            <StyledBodyHeading
              level="2"
              styledAs="mainheading"
              data-test-e2e="CarFileHeaderForWidget-brand-model"
            >
              {car.brand.name} {car.model.name}
            </StyledBodyHeading>
            <LabeledProperties>
              <LabeledText
                title={t('yearOfBuild')}
                value={moment(car.registrationDate).year()}
              />
              <LabeledText
                title={t('mileageAbbreviation')}
                value={formatMileage(customMileage || car.mileage)}
              />
            </LabeledProperties>
          </LeftSection>
          <RightSection>
            <FramedImage
              width={91}
              src={carPictureUrl}
              alt="foto van auto"
              errorMessage="Geen afbeelding beschikbaar"
            />
            <StyledLicensePlate
              size="small"
              value={car.licensePlate}
              data-test-e2e="CarFileHeaderForWidget-licenseplate"
            />
          </RightSection>
        </>
      ) : (
        'nothing'
      )}
    </StyledHeader>
  )
}

CarFileHeaderForWidget.propTypes = {
  car: PropTypes.shape({
    licensePlate: PropTypes.string.isRequired,
    mileage: PropTypes.number.isRequired,
    registrationDate: PropTypes.string.isRequired,
    brand: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    model: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    carPictures: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
  customMileage: PropTypes.number,
}

CarFileHeaderForWidget.defaultProps = {
  car: null,
  customMileage: null,
}

export default CarFileHeaderForWidget
