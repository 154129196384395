// EXTERNAL
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// INTERNAL
import BpmCalculationResultLines from './bpm-calculation-result-lines'

const Group = styled.div`
  border-bottom: ${({ theme }) => theme.colors.darkOnLightBorder} 1px solid;
`

const Pusher = styled.div`
  flex-grow: 1;
`

const BpmCalculationResultGroups = ({ groups }) =>
  Array.isArray(groups) ? (
    <>
      {groups.map((group, index) => (
        <Group key={`group-${index.toString()}`}>
          <BpmCalculationResultLines lines={group.lines} />
        </Group>
      ))}
      <Pusher />
    </>
  ) : null

BpmCalculationResultGroups.propTypes = {
  groups: PropTypes.array.isRequired,
}

export default BpmCalculationResultGroups
