import { connect } from 'react-redux'
import { login } from 'redux/actions/auth'
import { resetStore } from 'redux/store'
import Login from '../views/login'

const mapStateToProps = ({ auth }) => ({ auth })

const mapDispatchToProps = {
  login,
  resetStore,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
