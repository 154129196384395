import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useQuery, useMutation } from '@apollo/client'
import { Field, Form, Formik } from 'formik'

import { FormikTextInput } from 'components/molecules/formik-field'
import EnhancedTable from 'components/organisms/enhanced-table'
import Button from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import BodyHeading from 'components/atoms/body-heading'
import LoadingButton from 'components/atoms/loading-button'

import { READ_TAGS, UPDATE_TAGS } from 'config/graphql/v4/queries/tags'

import { media } from 'utilities/styled'
import toast from 'utilities/toast'
import { withApolloV4Provider } from 'utilities/apollo'

import { LocationTagForm as LocationTagFormSchema } from 'config/validation-schemas'

const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl0};
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.sizeByFactor(1)};
`

const StyledBodyHeading = styled(BodyHeading)`
  margin: 0;
`

const Table = styled(EnhancedTable)`
  position: relative;

  ${media.tv`
    > .MuiTableContainer-root {
      overflow: visible;
    }
  `}
`

const StyledForm = styled(Form)`
  width: 100%;

  ${media.tablet`
    width: auto;
    min-width: ${({ theme }) => theme.widths.maxFieldWidth};
  `}
`

const ButtonGroup = styled.div`
  margin-top: ${({ theme }) => theme.sizings.lvl3};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: ${({ theme }) => theme.sizings.lvl1};
  text-align: center;

  ${media.tablet`
    flex-direction: row;
    align-items: center;
  `}
`

const StyledLoadingButton = styled(LoadingButton)`
  width: 100%;

  ${media.tablet`
    width: auto;
  `}
`

const StyledIconButton = styled(Button)`
  background-color: transparent;
  margin-left: auto;
  min-width: ${({ theme }) => theme.sizeByFactor(4)};
  padding: 0;

  &:hover {
    background-color: transparent;
  }

  & > span {
    margin-right: 0;
  }

  & svg,
  & svg * {
    fill: ${({ theme }) => theme.colors.actionsStandard} !important;
  }
`

const StyledHiddenInputField = styled(FormikTextInput)`
  display: none;
`

const StyledField = styled(FormikTextInput)`
  max-width: ${({ theme }) => theme.sizeByFactor(40)};
`

const StyledTable = styled(Table)`
  table {
    min-width: ${({ theme }) => theme.sizeByFactor(40)};
  }
`

const NEW_TAG_ID = 'NEW_TAG'

const LocationTagsForm = () => {
  const { t } = useTranslation()
  const [initialValues, setInitialValues] = useState({ tags: [] })
  const [actionCount, setActionCount] = useState(0)

  const bottomElementRef = React.useRef(null)

  const { loading: readTagsLoading, error: readTagsError } = useQuery(
    READ_TAGS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ tags }) => {
        setInitialValues({ tags })
      },
    },
  )

  const [
    updateDealerTagsMutation,
    { loading: updateDealerTagsLoading, error: updateDealerTagsError },
  ] = useMutation(UPDATE_TAGS, { awaitRefetchQueries: true })

  const isLoading = readTagsLoading || updateDealerTagsLoading
  const isError = readTagsError || updateDealerTagsError

  if (isLoading || isError) {
    return <LoadingIndicator error={isError} />
  }

  const handleUpdateTagsSubmit = ({ tags }) => {
    updateDealerTagsMutation({
      variables: {
        tags: tags.map((tag) => ({ id: tag.id, label: tag.label })),
      },
      refetchQueries: () => [{ query: READ_TAGS }],
    })
      .then(() => {
        toast.success(t('tagsSettings.toast.updated'))
      })
      .catch((error) => {
        console.error(error)
        toast.error(t('tagsSettings.toast.error')) // This isn't mandatory, but it doest show a user friendly error message. The GraphQL error message is also shown.
      })
  }

  const columns = [
    { id: 'description', label: t('tagsSettings.tableHeadings.name') },
    {
      id: 'actions',
      label: t('tagsSettings.tableHeadings.actions'),
      alignRight: true,
    },
  ]

  return (
    <StyledArticle>
      <Formik
        validateOnChange
        validateOnBlur
        validateOnMount
        initialValues={initialValues}
        enableReinitialize
        validationSchema={LocationTagFormSchema(t)}
        onSubmit={handleUpdateTagsSubmit}
      >
        {({ handleSubmit, values, isValid, setFieldValue, errors }) => {
          // Handles deleting a tag
          const handleDeleteTagClick = (tagId) => {
            // Updates the Formik state with the selected tag removed
            setFieldValue(
              'tags',
              values.tags.filter((tag) => tag.id !== tagId),
            )
          }

          // Handles adding a new tag
          const handleAddNewTagClick = () => {
            // Updates the Formik state with a new, empty tag
            setFieldValue('tags', [
              ...values.tags,
              { id: `${NEW_TAG_ID}_${actionCount}`, label: '' },
            ])

            // Keep action count, in case we add/delete tags without an existing ID
            setActionCount((previousActionCount) => previousActionCount + 1)
          }

          // When initialValues are the same as the current values, disable the submit
          const isClean =
            JSON.stringify(initialValues) === JSON.stringify(values)

          // When the user deletes all the tags, they still need to save the table
          const hasDeletedAllTags = !isClean && values.tags.length === 0

          // Disables the submit button
          const isDisabled = hasDeletedAllTags
            ? false // Enables the submit button when the user has deleted all the tags in the frontend table
            : isClean || !isValid // When the form is clean, disable the submit button (no changes are made), if the form is not valid, disable the submit button

          return (
            <>
              <StyledHeader>
                <StyledBodyHeading level="2">{t('tags')}</StyledBodyHeading>
                <Button
                  level="option"
                  onClick={() => {
                    handleAddNewTagClick(actionCount)
                    if (bottomElementRef && bottomElementRef.current) {
                      bottomElementRef.current.scrollIntoView({
                        behavior: 'smooth',
                      })
                    }
                  }}
                  noPadding
                >
                  + {t('tagsSettings.form.addTag')}
                </Button>
              </StyledHeader>
              <StyledForm>
                <StyledTable
                  noDataMessage={
                    hasDeletedAllTags
                      ? t('tagsSettings.form.allTagsDeleted')
                      : t('tagsSettings.form.noTagsFound')
                  }
                  alignCellContent="middle"
                  columns={columns}
                  rows={values.tags.map((tag, index) => ({
                    description: {
                      component: (
                        <>
                          <Field
                            label=""
                            name={`tags.${index}.id`}
                            component={StyledHiddenInputField}
                            type="hidden"
                            required
                          />
                          <Field
                            label={
                              tag.systemTag
                                ? t('tagsSettings.form.inputs.systemTag')
                                : t('tagsSettings.form.inputs.label')
                            }
                            name={`tags.${index}.label`}
                            component={StyledField}
                            disabled={isLoading || tag.systemTag}
                            type="text"
                            required={!tag.systemTag}
                            filled
                          />
                        </>
                      ),
                    },
                    actions: {
                      component: !tag.systemTag && (
                        <StyledIconButton
                          icon="trashCan"
                          tabIndex={-1} // TODO: optimize the accessibility of this button, for now it skips to the next tag so the user can quickly fill in data
                          onClick={() => {
                            handleDeleteTagClick(tag.id)
                          }}
                        />
                      ),
                    },
                  }))}
                  selectable={false}
                />
                <ButtonGroup ref={bottomElementRef}>
                  <Button
                    level="option"
                    onClick={() => handleAddNewTagClick()}
                    noPadding
                  >
                    + {t('tagsSettings.form.addTag')}
                  </Button>
                  <StyledLoadingButton
                    type="submit"
                    level="cta"
                    disabled={isDisabled}
                    isLoading={isLoading}
                    onClick={handleSubmit}
                  >
                    {t('saveChanges')}
                  </StyledLoadingButton>
                </ButtonGroup>
              </StyledForm>
            </>
          )
        }}
      </Formik>
    </StyledArticle>
  )
}

export default withApolloV4Provider(LocationTagsForm)
