import { gql } from '@apollo/client'

/**
 * Lead is defined as:
 * type Lead {
    firstName: String!
    lastName: String!
    email: String!
    phone: String
  }
 */
export const REQUEST_MAINTENANCE_OFFER = gql`
  query requestMaintenanceOffer(
    $lead: Lead!
    $licensePlate: String!
    $currentMileage: Int!
    $mileagePerYear: Int!
  ) {
    requestMaintenanceOffer(
      lead: $lead
      licensePlate: $licensePlate
      currentMileage: $currentMileage
      mileagePerYear: $mileagePerYear
    ) {
      id
      licensePlate
      currentMileage
      mileagePerYear
      dealer {
        id
        city
        address
      }
      warrantyProgram {
        name
      }
      saleDate
      results {
        id
        calculation {
          licensePlate
          currentMileage
          mileagePerYear
        }
        package {
          name
          packageType
          description
        }
        resultNet
        resultGross
      }
    }
  }
`
