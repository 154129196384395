import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import EnhancedCard from 'components/layouts/enhanced-card'

const StyledCard = styled(EnhancedCard)`
  ${media.tablet`
    grid-column: span ${({ $columnSpan }) => $columnSpan};
  `}
`

/**
 * A card that lives in a dashboard grid.
 * This component is intended to be used inside of a `DashboardGrid` component.
 * Examples on how to use it are found there.
 */
const DashboardGridCard = ({ columnSpan, children }) => (
  <StyledCard $columnSpan={columnSpan} paddingLevel={3} shadow="bottomSoft">
    {children}
  </StyledCard>
)

DashboardGridCard.propTypes = {
  /** The amount of columns this card spans.
   * This value shouldn't exceed the value of the `columns` prop
   * in the parent `DashboardGrid` component. */
  columnSpan: PropTypes.number.isRequired,
  children: PropTypes.node,
}

DashboardGridCard.defaultProps = {
  children: null,
}

export default DashboardGridCard
