import { DisplayModeEnum } from 'config/enums'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import Button from '../atoms/button'

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.sizings.lvl1};
`

const DisplayModeToggle = ({ displayMode, setDisplayMode, ...restProps }) => {
  return (
    <Container {...restProps}>
      <Button
        level="option"
        onClick={() => setDisplayMode('grid')}
        disabled={displayMode === 'grid'}
        iconColor={displayMode === 'grid' ? 'text' : 'actionsStandard'}
        icon="display-mode-grid"
      />
      <Button
        level="option"
        onClick={() => setDisplayMode('list')}
        disabled={displayMode === 'list'}
        icon="display-mode-list"
        iconColor={displayMode === 'list' ? 'text' : 'actionsStandard'}
      />
    </Container>
  )
}

DisplayModeToggle.propTypes = {
  className: PropTypes.string,
  displayMode: PropTypes.oneOf(Object.values(DisplayModeEnum)).isRequired,
  setDisplayMode: PropTypes.func.isRequired,
}

export default DisplayModeToggle
