import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@apollo/client'
import styled from 'styled-components/macro'
import { Formik, Form, Field } from 'formik'
import moment from 'moment'
import toast from 'utilities/toast'
import {
  targetContractStatuses,
  contractStatuses,
} from '../../../../config/data'
import {
  GET_CONTRACT,
  GET_REASONS,
  CANCEL_CONTRACT,
  STOP_CONTRACT,
} from '../../../../config/graphql/csp'
import {
  CSPStopContract,
  CSPCancelContract,
} from '../../../../config/validation-schemas'

import Button from '../../../atoms/button'
import LoadingIndicator from '../../../atoms/loading-indicator'
import Typography from '../../../molecules/typography'
import Dialog from '../../../molecules/flexible-dialog'
import {
  FormikSelectInput,
  FormikDatePicker,
  FormikTextInput,
} from '../../../molecules/formik-field'

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    padding-right: ${({ theme }) => theme.sizings.lvl3};
    &:last-child {
      padding-right: 0;
    }
  }
`
const BodyTypography = styled(Typography)`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`
const StyledDropDownSelect = styled(FormikSelectInput)`
  min-width: 120px;
`
const StyledFormikTextAreaInput = styled(FormikTextInput)`
  margin-top: ${({ theme }) => theme.sizings.lvl3};
`
const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`

const { SIGNED, STOPPED } = contractStatuses

function ChangeContractStatusDialog({ onClose, contract }) {
  const { t } = useTranslation()
  const [targetStatus] = useState(targetContractStatuses[contract.status])

  /**
   * SIGNED contracts should be set to CANCELLED, but the reasons for cancellation
   * should be identical to those when you set a contract to STOPPED. So, making
   * an exception for that special case here.
   */

  const isSignedContract = contract.status === SIGNED
  const reasonsStatus = isSignedContract ? STOPPED : targetStatus
  const reasons = useQuery(GET_REASONS, {
    variables: { status: reasonsStatus },
  })
  const mutationOptions = {
    refetchQueries: [{ query: GET_CONTRACT, variables: { id: contract.id } }],
    awaitRefetchQueries: true,
  }
  const [cancelContract] = useMutation(CANCEL_CONTRACT, mutationOptions)
  const [stopContract] = useMutation(STOP_CONTRACT, mutationOptions)

  const handleCancelContract = async (values) => {
    try {
      await cancelContract({
        variables: {
          ...values,
          id: contract.id,
        },
      })
      onClose()
    } catch (e) {
      throw new Error(
        t('carServicePlanContractDetails.errorCancellingContract'),
      )
    }
  }
  const handleStopContract = async (values) => {
    try {
      await stopContract({
        variables: {
          ...values,
          stopDate: moment(values.stopDate).format('YYYY-MM-DD'),
          id: contract.id,
        },
      })
      onClose()
    } catch (e) {
      throw new Error(t('carServicePlanContractDetails.errorStoppingContract'))
    }
  }

  const handleFormSubmit = {
    STOPPED: handleStopContract,
    CANCELED: handleCancelContract,
  }[targetStatus]
  const validationSchema = {
    STOPPED: CSPStopContract(t),
    CANCELED: CSPCancelContract(t),
  }[targetStatus]

  /**
   * Again, SIGNED contracts should be set to CANCELLED, but the text for
   * it is a bit different from the normal cancellation text. Making an exception
   * for that special case here.
   */
  const textTargetStatus = isSignedContract ? 'signedContract' : targetStatus

  return (
    <Dialog
      closeHandler={onClose}
      open
      title={t(`carServicePlanContractDetails.${textTargetStatus}.heading`)}
      overflowVisible
      content={
        <>
          <BodyTypography type="ExplanationParagraph">
            {t(`carServicePlanContractDetails.${textTargetStatus}.body`)}
          </BodyTypography>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              stopDate: moment(),
              reasonId: '',
              explanation: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)
              try {
                await handleFormSubmit(values)
              } catch (e) {
                toast.error(e.toString())
              }
              setSubmitting(false)
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <FormRow>
                  {targetStatus === STOPPED && (
                    <Field
                      name="stopDate"
                      label={t(
                        'carServicePlanContractDetails.packageInfoTable.contractEndDate',
                      )}
                      filled
                      component={FormikDatePicker}
                    />
                  )}

                  {reasons.data ? (
                    <Field
                      name="reasonId"
                      items={reasons.data.reasonsByStatus.edges.map(
                        ({ node }) => ({ label: node.message, value: node.id }),
                      )}
                      label={t(
                        'carServicePlanContractDetails.packageInfoTable.deactivationReason',
                      )}
                      filled
                      component={StyledDropDownSelect}
                    />
                  ) : (
                    <LoadingIndicator size="small" error={reasons.error} />
                  )}
                </FormRow>
                {reasons.data &&
                  values.reasonId &&
                  reasons.data.reasonsByStatus.edges.find(
                    (edge) => edge.node.id === values.reasonId,
                  ) &&
                  reasons.data.reasonsByStatus.edges.find(
                    (edge) => edge.node.id === values.reasonId,
                  ).node.explanationRequired && (
                    <Field
                      name="explanation"
                      label={t(
                        'carServicePlanContractDetails.packageInfoTable.explanation',
                      )}
                      filled
                      multiline
                      component={StyledFormikTextAreaInput}
                    />
                  )}
                <Controls>
                  <Button
                    level="option"
                    onClick={onClose}
                    text={t('cancel')}
                    noPadding
                  />
                  {isSubmitting && <LoadingIndicator size="small" />}
                  <Button level="cta" disabled={isSubmitting} type="submit">
                    {t(
                      `carServicePlanContractDetails.${textTargetStatus}.primaryActionLabel`,
                    )}
                  </Button>
                </Controls>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  )
}

ChangeContractStatusDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  contract: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
}

export default ChangeContractStatusDialog
