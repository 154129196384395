import { gql } from '@apollo/client'
import brandInfoFragment from '../fragments/brand'
import modelInfoFragment from '../fragments/model'

export const GET_CAR_FROM_LICENSE_PLATE_QUERY = gql`
  query getCarFromLicensePlateQuery($licensePlate: String!, $dealerId: ID) {
    carByLicensePlate(licensePlate: $licensePlate, dealerId: $dealerId) {
      id
      dealerId
      carFileId
      brand {
        ...brandInfo
        models {
          ...modelInfo
        }
      }
      additionalInputOptions {
        id
        label
        choices {
          id
          label
        }
      }
      dealer {
        carServicePlan {
          isDisabled
          packages {
            carServicePlanIncludes {
              id
              carService {
                id
                name
                isExcluded
              }
              commercialName
            }
            packageType
            description
            name
          }
        }
      }
      model {
        ...modelInfo
      }
      mileage
      licensePlate
      engineCapacity
      registrationDate
      carPictures {
        type
        url
      }
    }
  }
  ${brandInfoFragment}
  ${modelInfoFragment}
`

export const UPDATE_CAR = gql`
  mutation updateCar(
    $id: ID!
    $licensePlate: String
    $brandId: ID
    $modelId: ID
    $vin: String
    $mileage: Int
    $fuelType: String
    $engineCapacity: Int
    $registrationDate: DateTime
  ) {
    updateCar(
      id: $id
      licensePlate: $licensePlate
      brandId: $brandId
      modelId: $modelId
      vin: $vin
      mileage: $mileage
      fuelType: $fuelType
      engineCapacity: $engineCapacity
      registrationDate: $registrationDate
    ) {
      id
      licensePlate
      registrationDate
    }
  }
`
export const GET_CAR_BRANDS = gql`
  query brands {
    brands {
      id
      name
      # can retrieve models too, but that is not needed here.
    }
  }
`

export const GET_CAR_MODELS = gql`
  query models($brandId: ID!) {
    models(brandId: $brandId) {
      id
      name
    }
  }
`
export const GET_CAR_MODEL_TYPES = gql`
  query types(
    $brandId: ID!
    $modelId: ID!
    $fuelType: String!
    $registrationDate: Date!
    $kwFrom: Int
    $kwTo: Int
    $engineCapacityFrom: Int
    $engineCapacityTo: Int
  ) {
    types(
      brandId: $brandId
      modelId: $modelId
      fuelType: $fuelType
      registrationDate: $registrationDate
      kwFrom: $kwFrom
      kwTo: $kwTo
      engineCapacityFrom: $engineCapacityFrom
      engineCapacityTo: $engineCapacityTo
    ) {
      id
      name
      fuelType
      fuelTypeSecondary
      kilowatt
      engineCapacity
      transmission # transmission type (manual, automatic, etc.?)
      from # produced from...
      till # produced till.
      tcoId
      brand {
        name
      }
      model {
        name
      }
    }
  }
`
export const GET_CAR_BY_TCO = gql`
  query carByTcoId($dealerId: ID!, $tcoId: ID!, $registrationDate: Date!) {
    carByTcoId(
      dealerId: $dealerId
      tcoId: $tcoId
      registrationDate: $registrationDate
    ) {
      id
      dealerId
      carFileId
      brand {
        ...brandInfo
        models {
          ...modelInfo
        }
      }
      additionalInputOptions {
        id
        label
        choices {
          id
          label
        }
      }
      dealer {
        carServicePlan {
          isDisabled
          packages {
            carServicePlan {
              carServicesIncluded {
                id
                carService {
                  id
                  name
                  isExcluded
                }
                commercialName
              }
            }
            packageType
            description
            name
          }
        }
      }
      model {
        ...modelInfo
      }
      mileage
      licensePlate
      engineCapacity
      registrationDate
      carPictures {
        type
        url
      }
    }
  }
  ${brandInfoFragment}
  ${modelInfoFragment}
`
