import * as React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@apollo/client'
import styled from 'styled-components/macro'
import toast from 'utilities/toast'
import {
  GET_CONTRACT_EXTENSION_REQUESTS,
  GET_CONTRACT_EXTENSION_REQUESTS_MINIMAL,
  UPDATE_CONTRACT_EXTENSION_REQUEST,
} from 'config/graphql/csp'
import { CAR_FILE } from 'config/routes'
import LoadingIndicator from 'components/atoms/loading-indicator'
import TextLink from 'components/atoms/text-link'
import Typography from 'components/molecules/typography'
import Dialog from 'components/molecules/flexible-dialog'
import { formatLicenseplate } from 'utilities/utils'
import EnhancedTable, {
  TableDataText,
} from 'components/organisms/enhanced-table'
import LoadingButton from 'components/atoms/loading-button'
import { formatPrice } from 'utilities/format'
import { media } from 'utilities/styled'

const StyledDialog = styled(Dialog)`
  h2:first-child {
    margin-bottom: 0;
  }
`

const BodyTypography = styled(Typography)`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const StyledTableDataText = styled(TableDataText)`
  font-weight: 400;
  margin-top: ${({ theme }) => theme.sizings.lvl0};
`

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${({ theme }) => theme.sizings.lvl0};
  margin-top: ${({ theme }) => theme.sizings.lvl4};

  ${media.phone`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`

const ExtensionRequestsTable = styled(EnhancedTable)`
  max-width: 100%;

  table {
    min-width: 0;
  }
`

function RateExtensionRequestsDialog({ onClose, extensionRequestIds }) {
  const { t } = useTranslation()

  const mutationOptions = {
    refetchQueries: [{ query: GET_CONTRACT_EXTENSION_REQUESTS }],
    awaitRefetchQueries: true,
  }

  // {
  //   variables: {
  //     ids: extensionRequestIds,
  //   },
  // }
  const { data, loading, error } = useQuery(
    GET_CONTRACT_EXTENSION_REQUESTS_MINIMAL,
    {
      variables: {
        ids: extensionRequestIds,
      },
    },
  )
  const [updateExtensionRequest, { loading: isSaving }] = useMutation(
    UPDATE_CONTRACT_EXTENSION_REQUEST,
    mutationOptions,
  )
  const [errorsFound, setErrorsFound] = React.useState(false)

  const handleUpdateExtensionRequest = async (decline) => {
    setErrorsFound(false)
    try {
      const promises = extensionRequestIds.map((id) =>
        updateExtensionRequest({
          variables: {
            id,
            status: decline ? 'DECLINED' : 'APPROVED',
          },
        }),
      )
      let errors = 0
      await Promise.all(promises)
        .then((results) => {
          results.forEach((result) => {
            if (result.error) {
              errors++
            }
          })
        })
        .catch((e) => {
          console.error(e)
        })

      if (errors === 0) {
        const message = decline
          ? extensionRequestIds.length === 1
            ? 'carServicePlanExtensionRequestDialog.successfullyDeclined'
            : 'carServicePlanExtensionRequestDialog.successfullyDeclinedPlural'
          : extensionRequestIds.length === 1
            ? 'carServicePlanExtensionRequestDialog.successfullyApproved'
            : 'carServicePlanExtensionRequestDialog.successfullyApprovedPlural'
        toast.success(t(message))
        onClose()
      } else {
        setErrorsFound(true)
      }
    } catch (error) {
      console.error(error)
      setErrorsFound(true)
    }
  }

  const formattedRows = !data?.extensionRequests
    ? []
    : data.extensionRequests
        .map((request) => ({
          id: request.id,
          licensePlate: request.contract.car.licensePlate,
          licensePlateLink: request.contract.car?.carFileId
            ? `${CAR_FILE}/${request.contract.car.carFileId}`
            : null,
          clientLastName: request.contract.customer.lastName,
          newPrice: request.packageCalculationResult.resultNet
            ? formatPrice(request.packageCalculationResult.resultNet)
            : '',
        }))
        .map((row) => ({
          _attrs: {
            'data-test-e2e': `contract-row-${row.id}`,
          },
          licensePlate: {
            component: (
              <StyledTableDataText>
                {row.licensePlateLink ? (
                  <TextLink
                    to={row.licensePlateLink}
                    text={formatLicenseplate(row.licensePlate)}
                  />
                ) : (
                  formatLicenseplate(row.licensePlate)
                )}
              </StyledTableDataText>
            ),
            data: '',
          },
          clientLastName: {
            component: row.clientLastName ? (
              <StyledTableDataText>{row.clientLastName}</StyledTableDataText>
            ) : (
              <></>
            ),
            data: '',
          },
          newPrice: {
            component: (
              <StyledTableDataText>{row.newPrice}</StyledTableDataText>
            ),
            data: '',
          },
        }))

  return (
    <StyledDialog
      closeHandler={onClose}
      open
      title={t('carServicePlanExtensionRequestDialog.heading')}
      overflowVisible
      content={
        loading || error ? (
          <LoadingIndicator error={error} size="small" />
        ) : (
          <>
            <BodyTypography type="ExplanationParagraph">
              {t('carServicePlanExtensionRequestDialog.body')}
            </BodyTypography>
            <ExtensionRequestsTable
              selectable={false}
              columns={[
                {
                  id: 'licensePlate',
                  label: t('licensePlate'),
                },
                {
                  id: 'clientLastName',
                  label: t(
                    'carServicePlanContractExtensionRequestsTable.clientLastName',
                  ),
                },
                {
                  id: 'newPrice',
                  label: t(
                    'carServicePlanContractExtensionRequestsTable.newPrice',
                  ),
                },
              ]}
              rows={formattedRows}
            />
            {errorsFound && (
              <Typography>
                {t('carServicePlanExtensionRequestDialog.errorsFound')}
              </Typography>
            )}
            <Controls>
              <LoadingButton
                isLoading={isSaving}
                type="submit"
                onClick={() => {
                  handleUpdateExtensionRequest(true)
                }}
              >
                {t('carServicePlanExtensionRequestDialog.decline')}
              </LoadingButton>
              <LoadingButton
                isLoading={isSaving}
                type="submit"
                level="cta"
                onClick={() => {
                  handleUpdateExtensionRequest(false)
                }}
              >
                {t('carServicePlanExtensionRequestDialog.approve')}
              </LoadingButton>
            </Controls>
          </>
        )
      }
    />
  )
}

RateExtensionRequestsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  extensionRequestIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default RateExtensionRequestsDialog
