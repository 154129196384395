import React from 'react'
// import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import moment from 'moment'
import Typography from 'components/molecules/typography'

import { useTranslation } from 'react-i18next'
import Todos from 'components/molecules/todos'
import PieChart from 'components/atoms/pie-chart'
import FilterableInvoicesTable from 'components/views/settings/car-service-plan/admin/monitoring/filterable-invoices-table'
import PageHeader from 'components/organisms/page-header'

import { GET_INVOICE_STATUSES } from 'config/graphql/csp'
import LoadingIndicator from 'components/atoms/loading-indicator'
import {
  invoiceStatuses,
  invoiceStatusColors,
  getInvoiceStatusLabels,
} from 'config/data'
import GeneralDataError from 'components/organisms/general-data-error'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: ${({ theme }) => theme.sizings.lvl3} ${({ theme }) => theme.sizings.lvl6};

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}
`

const Column = styled.div`
  ${media.tablet`
    grid-column: span ${({ columnSpan }) => columnSpan};
  `}
`

const PieChartContainer = styled.div`
  max-width: 320px;
`

const HeadingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;

  ${media.phone`
    flex-wrap: nowrap;
  `}
`

const SplitHeading = styled(Typography)`
  width: 100%;

  ${media.phone`
    width: auto;
  `}
`

const SplitHeadingRight = styled(SplitHeading)`
  margin-top: 0;
`

const ReportHeading = styled(SplitHeading)`
  ${media.tablet`
    min-width: 170px;
    text-align: ${({ $centered }) => ($centered ? 'center' : 'left')};
  `}
`

const { UNPAID, PAID, DOWNLOADED } = invoiceStatuses

export const getTodoStatusTexts = (t) => ({
  [UNPAID]: t('carServicePlanAdminMonitoringOverview.invoicesUnpaid'),
  [PAID]: t('carServicePlanAdminMonitoringOverview.invoicesPaid'),
  [DOWNLOADED]: t(
    'carServicePlanAdminMonitoringOverview.collectionFilesDownloaded',
  ),
})

const formatTodosData = (invoices, t) => {
  const items = []
  const todoStatuses = [UNPAID, PAID, DOWNLOADED]
  const todoStatusTexts = getTodoStatusTexts(t)
  todoStatuses.forEach((status) => {
    const count = invoices.filter((invoice) => invoice.status === status).length
    if (count > 0) {
      items.push({
        count,
        title: todoStatusTexts[status],
      })
    }
  })
  return items
}

const formatReportData = (invoices, t) => {
  const items = []
  const invoiceStatusLabels = getInvoiceStatusLabels(t)
  const reportStatuses = [UNPAID, PAID, DOWNLOADED]
  reportStatuses.forEach((status) => {
    const count = invoices.filter((invoice) => invoice.status === status).length
    if (count > 0) {
      items.push({
        value: count,
        label: invoiceStatusLabels[status],
        color: invoiceStatusColors[status],
      })
    }
  })
  return items
}

const CarServicePlanAdminMonitoringOverview = () => {
  const { t } = useTranslation()

  const { loading, error, data } = useQuery(GET_INVOICE_STATUSES)

  if (loading) {
    return <LoadingIndicator error={null} />
  }
  if (error) {
    console.error(error)
    return (
      <>
        <PageHeader
          mainTitle={t('CarServicePlan')}
          status={t('dashboard')}
          hasBorderBottom={false}
        />
        <GeneralDataError />
      </>
    )
  }

  const previousMonthObject = moment().subtract(1, 'month')
  const previousMonth = previousMonthObject.month()
  const previousMonthYear = previousMonthObject.year()
  const monthText = t(`months.${previousMonth + 1}`)

  const invoices = data.invoices.edges
    .map((edge) => edge.node)
    .filter((invoice) => {
      const invoiceDate = moment(invoice.date, 'YYYY-MM-DD')
      return (
        invoiceDate.month() === previousMonth &&
        invoiceDate.year() === previousMonthYear
      )
    })
  const todosData = formatTodosData(invoices, t)
  const reportData = formatReportData(invoices, t)

  return (
    <>
      <Typography type="Level1Heading">
        {t('carServicePlanAdminMonitoringOverview.title')}
      </Typography>
      <Container>
        <Column columnSpan={1}>
          <HeadingContainer>
            <SplitHeading type="Level2Heading">
              {t('carServicePlanAdminMonitoringOverview.collectionStatus')}
            </SplitHeading>
            <SplitHeadingRight type="ExplanationParagraph">
              {t('month')}: {monthText} {previousMonthYear}
            </SplitHeadingRight>
          </HeadingContainer>
          <Todos loading={false} columns={1} items={todosData} />
        </Column>
        <Column columnSpan={1}>
          <HeadingContainer>
            <ReportHeading
              type="Level2Heading"
              $centered={reportData.length > 0}
            >
              {t('carServicePlanAdminMonitoringOverview.report')}
            </ReportHeading>
            <SplitHeadingRight type="ExplanationParagraph">
              {t('month')}: {monthText} {previousMonthYear}
            </SplitHeadingRight>
          </HeadingContainer>
          <PieChartContainer>
            {reportData.length > 0 ? (
              <PieChart data={reportData} />
            ) : (
              <Typography type="Level5Heading">
                {t(
                  'carServicePlanAdminMonitoringOverview.noDataAvailableForLastMonth',
                )}
              </Typography>
            )}
          </PieChartContainer>
        </Column>
        <Column columnSpan={2}>
          <Typography type="Level2Heading">
            {t('carServicePlanAdminMonitoringOverview.invoiceOverview')}
          </Typography>
          <Typography type="ExplanationParagraph">
            {t('carServicePlanAdminMonitoringOverview.clickOnContractForInfo')}
          </Typography>
          <FilterableInvoicesTable />
        </Column>
      </Container>
    </>
  )
}

CarServicePlanAdminMonitoringOverview.propTypes = {}

CarServicePlanAdminMonitoringOverview.defaultProps = {}

export default CarServicePlanAdminMonitoringOverview
