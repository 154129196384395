import { gql } from '@apollo/client'

export default gql`
  fragment warrantyProviderInfo on WarrantyProvider {
    id
    name
    inputFields {
      id
      name
      type
      label
    }
  }
`
