import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GET_CUSTOMERS } from 'config/graphql/csp'

import TypeaheadTextInput from './typeahead-text-input'
import LoadingIndicator from 'components/atoms/loading-indicator'

function CustomerSearchInput({ onChange, className, ...restProps }) {
  const customers = useQuery(GET_CUSTOMERS)
  const { t } = useTranslation()

  return (
    <>
      {customers.loading || customers.error ? (
        <LoadingIndicator size="small" error={customers.error} />
      ) : (
        <TypeaheadTextInput
          options={customers.data.customers.edges
            .map((edge) => edge.node)
            .filter(
              (option) =>
                option.lastName || option.firstName || option.companyName,
            )}
          getOptionLabel={(option) =>
            option.isCompany
              ? `${option.companyName} (${option.city})`
              : `${option.lastName}${option.firstName ? `, ${option.firstName}` : ` (${option.zipCode})`}`
          }
          onChange={onChange}
          className={className}
          filled
          label={t(
            'cspSalesFlow.steps.saveAndCloseModal.step2.searchForCustomer',
          )}
          {...restProps}
        />
      )}
    </>
  )
}

CustomerSearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

CustomerSearchInput.defaultProps = {
  className: null,
}

export default CustomerSearchInput
