import api from 'utilities/api'
import { createFormData } from 'utilities/utils'

function addBackgroundImage(instance, { id, image }) {
  return api.post('/cl/v3/company/createachtergrond', image, instance, {
    location_id: id,
  })
}

// @TODO replace this dummy function when it is known how a expected photo
// should be fetched.
function getBackgroundImage(instance, data) {
  return api
    .post('/cl/v3/company/read', createFormData(data), instance)
    .then((response) => response.data.data.foto_bg)
}

function addCompanyLogo(instance, { id, image }) {
  return api.post('/cl/v3/company/createlogo', image, instance, {
    location_id: id,
  })
}

// @TODO replace this dummy function when it is known how a expected photo
// should be fetched.
function getCompanyLogo(instance, data) {
  return api
    .post('/cl/v3/company/read', createFormData(data), instance)
    .then((response) => response.data.data.foto_logo)
}

function addDeliveryAccessories(instance, { id, ...otherData }) {
  return api
    .post('/cl/v3/afleverpakketten/updateaccessoire', otherData, instance, {
      vestiging_id: id,
    })
    .then((response) => response.data)
}

function getDeliveryAccessories(instance, { id }) {
  return api
    .get('/cl/v3/afleverpakketten/accessoires', instance, { vestiging_id: id })
    .then((response) => response.data.data)
}

function getDeliveryPackages(instance, { id }) {
  return api
    .get('/cl/v3/afleverpakketten/pakketten', instance, { vestiging_id: id })
    .then((response) => response.data.data)
}

function deleteDeliverySegment(instance, data) {
  return api
    .get('/cl/v3/afleverpakketten/deletegroep', instance, data)
    .then((response) => response.data.data)
}

function getDeliverySegments(instance, { id }) {
  return api
    .get('/cl/v3/afleverpakketten/segmenten', instance, { vestiging_id: id })
    .then((response) => response.data.data)
}

function getLibraryLayers(instance) {
  return api
    .get('/cl/v3/company/layerliblist', instance)
    .then((response) => response.data.data)
}

/**
 * Retrieve all layers for a location (which is what a 'vestiging' is).
 */
function getCarPhotoLayers(instance, data) {
  return api
    .get('/cl/v3/company/readlayers2', instance, {
      vestiging_id: data.vestiging_id,
    })
    .then((response) => response.data.data)
}

function getCarPhotoLayersForCarFile(instance, data) {
  return api
    .get('/cl/v3/company/readlayeroptions', instance, {
      vestiging_id: data.vestiging_id,
    })
    .then((response) => response.data.data)
}

function updateLayersSettings(instance, data) {
  return api
    .post('/cl/v3/company/updatelayers2', data, instance)
    .then((response) => response.data.data)
}

/**
 * Deletes a photolayer.
 */
function deleteLayer(instance, { id }) {
  return api.get('/cl/v3/company/deletelayer', instance, { id })
}

/**
 * Uploads or updates a photolayer.
 */
function updateLayer(instance, data) {
  const { locationId, id, image, omschrijving, defaultLayer } = data
  return api.post('/cl/v3/company/createlayer2', image, instance, {
    id: id || -1,
    vestiging_id: locationId,
    omschrijving,
    defaultLayer,
  })
}

/**
 * Uploads or updates a photolayer with content from the layer library.
 */
function updateLayerWithLibrary(instance, data) {
  const { locationId, id, image, omschrijving, libId, defaultLayer } = data
  return api.post('/cl/v3/company/createlayerfromlib', image, instance, {
    id: id || -1,
    lib_id: libId,
    vestiging_id: locationId,
    omschrijving,
    defaultLayer,
  })
}

// NEW Methods for 2023
function addExpectedPhotos(instance, { id, image }) {
  return api.post('/cl/v3/carimagesstandaard/create', image, instance, {
    vestiging_id: id,
  })
}

function getExpectedPhotos(instance, { id }) {
  return api
    .get('/cl/v3/carimagesstandaard/list', instance, { vestiging_id: id })
    .then((response) => response.data.data)
}

function deleteExpectedPhotos(instance, ids) {
  return api.post('/cl/v3/carimagesstandaard/delete', ids, instance)
}

function sortExpectedPhotos(instance, data) {
  return api.post('/cl/v3/carimagesstandaard/update', data, instance)
}
// /NEW Methods for 2023

function getTransportCompanies(instance) {
  return api
    .get('/cl/v3/externepartij/overviewtransport', instance)
    .then((response) => response.data.data || [])
}

function deleteTransportCompany(instance, data) {
  return api
    .get('/cl/v3/externepartij/delete', instance, data)
    .then((response) => response.data.data)
}

function getCleaningCompanies(instance) {
  return api
    .get('/cl/v3/externepartij/overviewpoets', instance)
    .then((response) => response.data.data || [])
}

function getB2bPurchasingCompanies(instance) {
  return api
    .get('/cl/v3/externepartij/overviewcarshareimport', instance)
    .then((response) => response.data.data || [])
}

function deleteCleaningCompany(instance, data) {
  return api
    .get('/cl/v3/externepartij/delete', instance, data)
    .then((response) => response.data.data)
}

function deleteB2bPurchasingCompany(instance, data) {
  return api
    .get('/cl/v3/externepartij/delete', instance, data)
    .then((response) => response.data.data)
}

function deleteDeliveryPackage(instance, data) {
  return api
    .get('/cl/v3/afleverpakketten/deletepakket', instance, data)
    .then((response) => response.data.data)
}

function getRdwCertificate(instance, data) {
  return api
    .get('/cl/v3/rdwcertificaten/status', instance, data)
    .then((response) => response.data.data)
}

function uploadRdwCertificate(instance, data) {
  // The data variable will be an instance of FormData, since we are dealing with an upload.
  return api.post('/cl/v3/rdwcertificaten/upload', data, instance, {
    vestiging_id: data.get('id'),
  })
}

export default {
  addBackgroundImage,
  getBackgroundImage,
  addCompanyLogo,
  deleteB2bPurchasingCompany,
  deleteCleaningCompany,
  deleteDeliverySegment,
  deleteTransportCompany,
  getTransportCompanies,
  getCompanyLogo,
  getCleaningCompanies,
  getB2bPurchasingCompanies,
  addDeliveryAccessories,
  getDeliveryAccessories,
  getDeliveryPackages,
  getDeliverySegments,
  addExpectedPhotos,
  getExpectedPhotos,
  deleteExpectedPhotos,
  sortExpectedPhotos,
  getLibraryLayers,
  getCarPhotoLayers,
  getCarPhotoLayersForCarFile,
  updateLayersSettings,
  updateLayer,
  updateLayerWithLibrary,
  deleteLayer,
  deleteDeliveryPackage,
  getRdwCertificate,
  uploadRdwCertificate,
}
