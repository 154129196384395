import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { formatDataTestE2eAttr } from 'utilities/format'
import ExpandButton from 'components/molecules/expand-button'

const List = styled.ol`
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.brandDelta};
  list-style: none;
  padding: 0;

  & > li {
    border-bottom: 1px solid ${({ theme }) => theme.colors.brandDelta};
  }

  & > li:last-child {
    border-bottom: 0 none;
  }
`

const Header = styled.header`
  padding: ${(props) => props.theme.sizings.lvl2};
  line-height: ${(props) => props.theme.sizings.lvl2};
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};

  > * {
    flex: 1 1;
  }

  * {
    ${({ $isDisabled, theme }) =>
      $isDisabled && `color: ${theme.colors.textTonedDown};`}
  }
`

const PositionedExpandButton = styled(ExpandButton)`
  flex: 0 0 ${(props) => props.theme.sizings.lvl2};
  justify-self: end;
`

const Body = styled.section`
  max-height: ${({ isExpanded }) => (isExpanded ? 15000 : 0)}px;
  transition: ease 0.25s;

  > div {
    padding: ${(props) => props.theme.sizings.lvl2};
  }

  > svg {
    width: calc(100% - ${(props) => props.theme.sizings.lvl4});
    display: block;
    margin: 0 ${(props) => props.theme.sizings.lvl2};
  }

  > svg line {
    stroke: ${(props) => props.theme.colors.darkOnLightBorder};
    stroke-width: 1px;
    stroke-dasharray: ${(props) => props.theme.sizings.lvl1}
      ${(props) => props.theme.sizings.lvl1};
    height: 1px;
  }
`

/**
 * Display a collection of information with the details initially hidden.
 * This allows the user to focus the high level information to find some details.
 *
 * ExpandableRows only allows one row to be open at a time.
 *
 * Use SummaryLine to display some initial info in the headers.
 */
function ExpandableBars({
  rows,
  initialExpandedRowId,
  fullRowIsClickable,
  ...restProps
}) {
  const [expandedRow, setExpandedRow] = useState(initialExpandedRowId)

  const handleExpandCollapse = (row) => {
    setExpandedRow(expandedRow === row.id ? -1 : row.id)
    if (typeof row.onRowClick === 'function') {
      row.onRowClick({
        isExpanded: expandedRow === row.id,
      })
    }
  }

  return (
    <List {...restProps}>
      {rows.map((row) => (
        <li key={row.id} data-test-e2e={formatDataTestE2eAttr('bar', row.id)}>
          <Header
            $clickable={fullRowIsClickable}
            onClick={
              fullRowIsClickable ? () => handleExpandCollapse(row) : undefined
            }
            $isDisabled={row.isDisabled}
            title={
              row.isDisabled && row.isDisabledMessage
                ? row.isDisabledMessage
                : undefined
            }
          >
            {row.header}
            <PositionedExpandButton
              data-test-e2e="expandable-bar-button"
              onClick={() => {
                setExpandedRow(expandedRow === row.id ? -1 : row.id)
                if (typeof row.onRowClick === 'function') {
                  row.onRowClick({
                    isExpanded: expandedRow === row.id,
                  })
                }
              }}
              isExpanded={expandedRow === row.id}
            />
          </Header>
          <Body
            isExpanded={expandedRow === row.id}
            data-test-e2e="bar-body"
            data-test-e2e-expanded={expandedRow === row.id}
          >
            {expandedRow === row.id && (
              <>
                <svg height="2">
                  <line x1="0" y1="1" x2="4000" y2="1" />
                </svg>
                <div data-test-e2e="bar-body-content">{row.details}</div>
              </>
            )}
          </Body>
        </li>
      ))}
    </List>
  )
}

ExpandableBars.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.node,
      id: PropTypes.string.isRequired,
      details: PropTypes.node,
      isDisabled: PropTypes.bool,
      isDisabledMessage: PropTypes.string,
      /**
       * Trigger something when a row is opened up or closed.
       * Like rendering it's content or retrieving data.
       */
      onRowClick: PropTypes.func,
    }),
  ),
  /**
   * The id of the row that should be expanded when the component is rendered for the first time
   */
  initialExpandedRowId: PropTypes.string,
  /**
   * Make the full header of every row clickable? If this is false, you can only expand/collapse the row by pressing the button on the right side of the row header.
   */
  fullRowIsClickable: PropTypes.bool,
}

ExpandableBars.defaultProps = {
  rows: [],
  initialExpandedRowId: null,
  fullRowIsClickable: true,
}

export default ExpandableBars
