import { connect } from 'react-redux'
import {
  clearData,
  deleteB2bRelation,
  getB2bRelations,
  getB2bInterests,
  deleteB2bInterests,
  postDynamicEndpoint,
} from 'redux/actions/data'

import { openOverlay } from 'redux/actions/ui'
import B2bSalesSettings from '../views/settings/b2b-sales-settings'

const mapStateToProps = ({ data }) => ({
  b2bRelations: data.b2bRelations,
  b2bInterests: data.b2bInterests,
})

const mapDispatchToProps = {
  clearData,
  getB2bRelations,
  deleteB2bRelation,
  getB2bInterests,
  deleteB2bInterests,
  openOverlay,
  postDynamicEndpoint,
}

export default connect(mapStateToProps, mapDispatchToProps)(B2bSalesSettings)
