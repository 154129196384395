import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDiv = styled.div`
  position: relative;
`

/**
 * Note: Only use this component in the context of the management info page.
 * It's seperated into a molecule because it's being re-used in different charts,
 * yet it's not meant to be used outside of the management info page.
 */
function ManagementInfoChartContainer({ className, children }) {
  return <StyledDiv className={className}>{children}</StyledDiv>
}

ManagementInfoChartContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ManagementInfoChartContainer
