/*
 * @TODO This view and all its children are preferably refactored in line with, /
 * using the components used in, the RdwStockView. The RdwStockView is pretty
 * stateless: deriving props from the querystring in the url, and setting
 * state by updating the url. That's a much easier solution than all the state
 * used in this view and it's children and therefore should be implemented /
 * reused in here to (since the two views are practically the same with some
 * small differences
 */

import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { media } from 'utilities/styled'
import {
  getObjectFromQueryString,
  handleChangeQuery,
  scrollToTop,
} from 'utilities/utils'

import {
  CarFileStatusEnumLowerCase,
  CarFileStatusEnumUpperCase,
  DisplayModeEnum,
} from 'config/enums'
import * as routes from 'config/routes'

import useSelectedLocationId from 'hooks/use-selected-location-id'

import LoadingIndicator from 'components/atoms/loading-indicator'
import SearchResultsOverview from 'components/layouts/search-results-overview'
import CarShareUploadButton from 'components/molecules/car-share-upload-button'
import Filters from 'components/molecules/filters'
import Overlay from 'components/molecules/overlay'
import StockHeader from 'components/organisms/stock-header'
import StockItems from 'components/organisms/stock-items'
import { storageKeys } from 'config/storage'
import storage from 'utilities/storage'

const ResultsContainer = styled.div`
  align-items: start;
  display: grid;
  column-gap: ${({ theme }) => theme.widths.gutter};
  margin-bottom: 40px;

  ${media.desktop`
    grid-template-areas: "spacing header"
                   "filters results";
    grid-template-rows: auto auto;
    grid-template-columns: ${({ theme }) =>
      theme.widths.navigationBarWidth} 1fr;
  `};
`

const StyledStockHeader = styled(StockHeader)`
  margin-bottom: 20px;

  ${media.desktop`
    grid-area: header;
    margin-left: calc(${({ theme }) =>
      `${theme.widths.navigationBarWidth} + ${theme.widths.gutter}`});
  `};
`
//
// @TODO Refactor RdwFiklters component to regular Filters component when
// it is reusable for both views
const StyledFilters = styled(Filters)`
  display: ${({ filtersVisibleOnMobile }) =>
    filtersVisibleOnMobile ? 'block' : 'none'};
  bottom: 0;
  position: ${({ filtersVisibleOnMobile }) =>
    filtersVisibleOnMobile ? 'fixed' : 'static'};
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;

  ${media.desktop`
    bottom: auto;
    display: block;
    grid-area: filters;
    left: auto;
    position: static;
    right: auto;
    top: auto;
    z-index: auto;
  `};
`

const MobileFilters = styled(Filters)`
  width: 100%;
`

const StockResultsContainer = styled.div`
  ${media.desktop`
    grid-area: results;
  `};
`

function Stock({
  getStockSortOptions,
  getStockFilters,
  getStockResults,
  history,
  location,
  toggleSalesView,
  salesView,
  stockFilters,
  stockResults,
  stockSortOptions,
}) {
  const { t } = useTranslation()
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [currentDisplayMode, setCurrentDisplayMode] = useState(
    storage.getPermanent(storageKeys.STOCK_DISPLAY_MODE) ||
      DisplayModeEnum.grid,
  )
  const setDisplayMode = (mode) => {
    storage.setPermanent(storageKeys.STOCK_DISPLAY_MODE, mode)
    setCurrentDisplayMode(mode)
  }
  const carShareUploadEnabled = useSelector(
    (state) => state?.auth?.userDetails?.carshare_import,
  )

  // Redirect to ?status=binnen if no status is set
  const isInitialized = useRef(false)

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true
      const searchParams = new URLSearchParams(location.search)
      const status = searchParams.get('status')
      if (!status) {
        searchParams.set(
          'status',
          CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.binnen],
        )
        history.replace(`${routes.STOCK}?${searchParams.toString()}`)
      }
    }

    return () => {
      isInitialized.current = false
    }
  }, [location.search, history])

  // Sorting
  const parseSortOptionFromQuery = (query) => {
    const params = new URLSearchParams(query)
    return {
      sort: params.get('sort'),
      dir: params.get('dir'),
    }
  }
  const getSortOptionValuesFromQuery = useCallback(() => {
    return parseSortOptionFromQuery(location.search)
  }, [location.search])

  const sortOptions =
    stockSortOptions &&
    stockSortOptions.data &&
    stockSortOptions.data.length &&
    stockSortOptions.data.map((item) => ({
      ...item,
      label: `${item.label} (${t(item.dir)})`,
      value: `sort=${item.field}&dir=${item.dir}`,
    }))
  const defaultSelectedSortOption =
    sortOptions && sortOptions.find((option) => option.selected)

  const sortOptionValuesFromQuery = getSortOptionValuesFromQuery()
  const selectedSortOption =
    sortOptions &&
    sortOptions.find(
      (option) =>
        option.field === sortOptionValuesFromQuery.sort &&
        option.dir === sortOptionValuesFromQuery.dir,
    )
  const sortOption = selectedSortOption || defaultSelectedSortOption
  const sortOptionQuery =
    sortOption && `sort=${sortOption.field}&dir=${sortOption.dir}`
  // /Sorting

  const queryFilters = getObjectFromQueryString(location.search)
  if (!queryFilters.status) {
    queryFilters.status =
      CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.binnen]
  }
  const { status, vestiging_id: vestigingId } = queryFilters

  // Update the selected location ID
  const normalizedVestigingId = vestigingId || '0'
  // eslint-disable-next-line no-unused-vars
  const [_, setSelectedLocationId] = useSelectedLocationId(
    normalizedVestigingId,
  )
  useEffect(() => {
    setSelectedLocationId(normalizedVestigingId)
  }, [normalizedVestigingId, setSelectedLocationId])

  const loading = !stockFilters || stockFilters.loading || !sortOption
  const stockResultsTotal =
    stockResults && stockResults.data && stockResults.data.total

  // determine which page we are one from query string:
  const page = queryFilters && (queryFilters.page || 1)
  const resultsPerPage = 36

  function handleResetFilters() {
    history.push(
      `${routes.STOCK}?status=${
        CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.binnen]
      }`,
    )
    setMobileFiltersOpen(false)
  }

  function handleFilterChange(filter, value) {
    handleChangeQuery(location, history, filter, value, filter !== 'page')
  }

  function handleSortOptionChange(value) {
    const values = parseSortOptionFromQuery(value)
    const newQuery = new URLSearchParams(location.search)
    newQuery.set('sort', values.sort)
    newQuery.set('dir', values.dir)
    history.push({
      pathname: location.pathname,
      search: newQuery.toString(),
    })
  }

  function handleToggleMobileFilters() {
    setMobileFiltersOpen(!mobileFiltersOpen)
  }

  function handleChangePage(newPage) {
    handleFilterChange('page', newPage)
    // scroll to top because that's were the new results will be:
    scrollToTop()
  }

  useEffect(() => {
    if (status) {
      getStockSortOptions({ status })
    }
  }, [status, getStockSortOptions])

  useDeepCompareEffect(() => {
    if (!sortOption || !status) {
      return
    }

    const filters = {
      ...queryFilters,
      dir: sortOption.dir,
      sort: sortOption.field,
    }
    getStockFilters(filters)
    getStockResults(filters, page, resultsPerPage)
  }, [queryFilters, getStockResults, page, resultsPerPage, sortOption])

  const linkGenerator = (item) => {
    const carId = item.id || item.auto_id

    // When this item is a CarShare car, when a UCC user offers for bids it in their own group, link to the carshare carfile url
    if (item.is_carshare) {
      return `${routes.CAR_SHARE_CAR_FILE}/${carId}/${item.carshare_vestiging_id}`
    }

    return carId
      ? `${routes.CAR_FILE}/${carId}`
      : `${routes.CAR_ENTRY}/1${
          routes.LICENSE_PLATE_SEARCH_BY_LICENSE
        }?kenteken=${item.kenteken}&mileage=${item.km || item.rdwkm}`
  }

  return (
    <>
      <SearchResultsOverview
        pageTitle={`${t('stock')} ${t('overview')} ${t(
          `carFileStatussesV3.${status}`,
        )}`}
        mainTitle={t('overview')}
        subTitle={t('stock')}
        statusTitle={t(`carFileStatussesV3.${status}`)}
        pageHeaderOptionsSlot={
          carShareUploadEnabled ? <CarShareUploadButton /> : null
        }
      >
        {loading && <LoadingIndicator />}
        {!loading &&
          stockResults &&
          stockResults.data &&
          stockResults.data.items && (
            <>
              <StyledStockHeader
                onSortOptionChange={handleSortOptionChange}
                salesView={salesView}
                selectedSortOption={sortOptionQuery}
                sortOptions={sortOptions}
                toggleMobileFilters={handleToggleMobileFilters}
                toggleSalesView={toggleSalesView}
                total={Number(stockResultsTotal)}
                displayMode={currentDisplayMode}
                setDisplayMode={setDisplayMode}
              />
              <ResultsContainer>
                <StyledFilters
                  onUpdate={handleFilterChange}
                  selectedFilters={queryFilters}
                  filters={stockFilters.data}
                  onResetFilters={handleResetFilters}
                />
                <StockResultsContainer>
                  <StockItems
                    linkGenerator={linkGenerator}
                    items={
                      stockResults &&
                      stockResults.data &&
                      stockResults.data.items
                    }
                    onChangePage={handleChangePage}
                    page={Number(page)}
                    itemsPerPage={resultsPerPage}
                    salesView={salesView}
                    status={status}
                    totalItems={
                      stockResults &&
                      stockResults.data &&
                      stockResults.data.total
                    }
                    displayMode={currentDisplayMode}
                    data-test-e2e="stock-items"
                  />
                </StockResultsContainer>
              </ResultsContainer>
            </>
          )}
      </SearchResultsOverview>
      {mobileFiltersOpen && (
        <Overlay
          close={handleToggleMobileFilters}
          title={t('filters')}
          data-test-e2e="mobile-filters"
        >
          <MobileFilters
            onUpdate={handleFilterChange}
            selectedFilters={queryFilters}
            filters={stockFilters.data}
            onResetFilters={handleResetFilters}
          />
        </Overlay>
      )}
    </>
  )
}

Stock.propTypes = {
  getStockFilters: PropTypes.func.isRequired,
  getStockSortOptions: PropTypes.func.isRequired,
  getStockResults: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  salesView: PropTypes.bool,
  stockFilters: PropTypes.object,
  stockResults: PropTypes.object,
  stockSortOptions: PropTypes.object,
  toggleSalesView: PropTypes.func,
}

Stock.defaultProps = {
  salesView: false,
  stockFilters: null,
  stockResults: null,
  stockSortOptions: null,
  toggleSalesView: null,
}

export default withRouter(Stock)
