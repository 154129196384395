import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import LoadingIndicator from 'components/atoms/loading-indicator'
import RadioButton from 'components/atoms/radio-button'
import Button from 'components/atoms/button'
import NewTextInput from 'components/atoms/new-text-input'
import OptionsBar from 'components/layouts/options-bar'
import SelectFileButton from 'components/molecules/select-file-button'
import Typography from 'components/molecules/typography'
import FlexibleDialog from 'components/molecules/flexible-dialog'
import EnhancedTable from 'components/organisms/enhanced-table'
import SelectMediaOverlay from 'components/organisms/select-media-overlay'

const SettingsContainer = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
  > * {
    flex: 1;
    flex-basis: 100%;
  }

  ${media.tablet`
    > * {
      flex-basis: 50%;
    }
  `};
`

const LayerImageContainer = styled.div`
  display: flex;
  > * {
    flex: 1;
    /* flex-basis: 100%; */
  }
`
const LayerImage = styled.img`
  max-height: 100px;
  max-width: 156px;
  margin-right: ${({ theme }) => theme.sizings.lvl2};
`

const StyledSelectFileButton = styled(SelectFileButton)`
  > label {
    justify-content: start;
  }
`

function CarPhotoLayersSettings({
  getLibraryLayers,
  getCarPhotoLayers,
  updateLayersSettings,
  updateLayer,
  updateLayerWithLibrary,
  deleteLayer,
  carPhotoLayers,
  libraryLayers,
  locationId,
}) {
  const { t } = useTranslation()
  const [defaultLayer, setDefaultLayer] = useState('')
  const [validationError, setValidationError] = useState('')
  const [layerLibraryAssigning, setLayerLibraryAssigning] = useState(null)
  const [stagingDeleteLayer, setStagingDeleteLayer] = useState(null)
  const [layers, setLayers] = useState([])
  // which photo should the layer be applied to:
  const [photoTarget, setPhotoTarget] = useState('eerste')
  const radioGroupName = `layers-${locationId}`
  const photoTargetGroupName = `photoTarget-${locationId}`

  useEffect(() => {
    getCarPhotoLayers({
      id: locationId,
      vestiging_id: locationId,
    })
  }, [getCarPhotoLayers, locationId])

  useEffect(() => {
    if (!libraryLayers) {
      getLibraryLayers()
    }
  }, [getLibraryLayers, libraryLayers])

  useEffect(() => {
    if (
      carPhotoLayers &&
      carPhotoLayers[locationId] &&
      carPhotoLayers[locationId].data
    ) {
      setLayers(carPhotoLayers[locationId].data.overlays)
    }
  }, [carPhotoLayers, locationId])

  useEffect(() => {
    if (
      carPhotoLayers &&
      !carPhotoLayers.loading &&
      carPhotoLayers[locationId] &&
      carPhotoLayers[locationId].data &&
      carPhotoLayers[locationId].data.image_layers_id
    ) {
      setDefaultLayer(String(carPhotoLayers[locationId].data.image_layers_id))
      setPhotoTarget(carPhotoLayers[locationId].data.image_layer_target)
    }
  }, [carPhotoLayers, locationId])

  const persistChanges = async () => {
    const valid = !layers.find((l) => (!l.image && !l.url) || !l.omschrijving)

    if (!valid) {
      setValidationError(t('layerValidation'))
      return
    }
    setValidationError(null)

    Promise.all(
      layers
        .filter(
          (layer) =>
            layer.edited ||
            layer.localId === defaultLayer ||
            layer.id === defaultLayer,
        )
        .map((layer) => {
          const id = layer.localId || layer.id
          return layer.libId
            ? updateLayerWithLibrary({
                ...layer,
                locationId,
                defaultLayer: defaultLayer === id,
              })
            : updateLayer({
                ...layer,
                locationId,
                defaultLayer: defaultLayer === id,
              })
        }),
    )

    updateLayersSettings({
      vestiging_id: String(locationId),
      image_layer_target: photoTarget,
    })
  }

  const handleDeleteLayer = (layer) => {
    if (layer.localId) {
      setLayers((oldLayers) =>
        oldLayers.filter((oldLayer) => oldLayer.localId !== layer.localId),
      )
    } else {
      deleteLayer({ id: layer.id, locationId })
    }
    setStagingDeleteLayer(null)
  }

  const handleEditLayer = (layer) => {
    const id = layer.localId || layer.id
    return setLayers((oldLayers) =>
      oldLayers.map((stagingLayer) => {
        if ((stagingLayer.localId || stagingLayer.id) === id) {
          return { ...stagingLayer, ...layer, edited: true }
        }
        return stagingLayer
      }),
    )
  }
  const handleNewLayer = (layer) =>
    setLayers((oldLayers) => [
      ...oldLayers,
      {
        ...layer,
        edited: true,
        localId: `${oldLayers.length}_new`, // Local ID used to identify the layer locally, because all staging layers have id -1
      },
    ])

  const isLoading =
    !carPhotoLayers ||
    !carPhotoLayers[locationId] ||
    carPhotoLayers[locationId].loading ||
    !libraryLayers ||
    libraryLayers.loading

  const optionsBarRightAligned = [
    // eslint-disable-next-line react/jsx-key
    <Button level="cta" text={t('saveChanges')} onClick={persistChanges} />,
  ]
  if (validationError) {
    optionsBarRightAligned.unshift(
      <Typography type="ErrorText">{validationError}</Typography>,
    )
  }

  return (
    <section>
      <Typography type="Level1Heading">{t('layers')}</Typography>

      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Typography type="ExplanationParagraph">
            {t('layersExplanation')}
          </Typography>
          <Typography type="Level2Heading">{t('layerSettings')}</Typography>

          <SettingsContainer>
            <div>
              <Typography type="Level3Heading">{t('layerApply')}</Typography>
              <RadioButton
                title={t('applyToAllPhotos')}
                checked={photoTarget === 'alle'}
                value="alle"
                name={photoTargetGroupName}
                onChange={() => setPhotoTarget('alle')}
              />
              <RadioButton
                title={t('applyToFirstPhoto')}
                checked={photoTarget === 'eerste'}
                value="eerste"
                name={photoTargetGroupName}
                onChange={() => setPhotoTarget('eerste')}
              />
            </div>
            <div>
              <Typography type="Level3Heading">
                {t('useStandardLayer')}
              </Typography>
              <RadioButton
                title={t('doNotUseStandardLayer')}
                checked={defaultLayer === ''}
                // the value is an empty string
                value=""
                name={radioGroupName}
                onChange={() => setDefaultLayer('')}
              />
            </div>
          </SettingsContainer>

          <Typography type="Level2Heading">{t('activeLayers')}</Typography>

          <EnhancedTable
            selectable={false}
            alignCellContent="middle"
            noDataMessage={t('noLayersYetClickToCreate')}
            columns={[
              {
                id: 'layerImage',
                label: t('layerImage'),
              },
              {
                id: 'name',
                label: t('name'),
              },
              {
                id: 'standardLayer',
                label: t('standardLayer'),
              },
              {
                id: 'actions',
                label: t('actions'),
              },
            ]}
            rows={layers.map((layer) => {
              const id = String(layer.localId || layer.id)
              const imageUrl = layer.image
                ? URL.createObjectURL(layer.image.get('file'))
                : layer.url
              return {
                layerImage: {
                  component: (
                    <LayerImageContainer>
                      <LayerImage src={imageUrl} alt={`layer ${id}`} />
                      <div>
                        <StyledSelectFileButton
                          allowedFileExtensions={['.png']}
                          id={`file-button-${id}`}
                          onChange={(formData) =>
                            handleEditLayer({ ...layer, image: formData })
                          }
                          multiple={false}
                          level="option"
                          icon="upload"
                          iconSize="lg"
                          text={t('uploadLayer')}
                        />
                        <Button
                          level="option"
                          icon="search"
                          iconSize="lg"
                          text={t('fromLibrary')}
                          onClick={() => {
                            setLayerLibraryAssigning(layer)
                          }}
                        />
                      </div>
                    </LayerImageContainer>
                  ),
                  data: layer.url,
                },
                name: {
                  component: (
                    <NewTextInput
                      filled
                      label={t('nameOfLayer')}
                      value={layer.omschrijving}
                      onChange={(value) =>
                        handleEditLayer({
                          ...layer,
                          omschrijving: value,
                        })
                      }
                    />
                  ),
                  data: layer.omschrijving,
                },
                standardLayer: {
                  component: (
                    <RadioButton
                      checked={defaultLayer === id}
                      id={`${radioGroupName}-${id}`}
                      name={radioGroupName}
                      onChange={(event) => setDefaultLayer(event.target.value)}
                      title={t('standardLayerToUse')}
                      value={id}
                    />
                  ),
                  value: false,
                },
                actions: {
                  component: (
                    <Button
                      level="option"
                      icon="deleteCarfile"
                      iconSize="lg"
                      onClick={() => setStagingDeleteLayer(layer)}
                    />
                  ),
                  data: 'delete',
                },
              }
            })}
          />

          <OptionsBar
            leftAligned={[
              // eslint-disable-next-line react/jsx-key
              <Button
                level="option"
                text={`+ ${t('layerAdd')}`}
                onClick={() =>
                  handleNewLayer({
                    id: -1,
                    omschrijving: '',
                    url: '/images/no-layer.svg',
                  })
                }
              />,
            ]}
            rightAligned={optionsBarRightAligned}
          />
        </>
      )}

      {layerLibraryAssigning && (
        <SelectMediaOverlay
          title={t('layerLibrary')}
          text={t('selectLayer')}
          onSubmitText={t('useLayer')}
          items={libraryLayers.libraryLayers.data.map((layer) => ({
            id: layer.id,
            src: layer.url,
            name: layer.omschrijving,
            type: 'image',
          }))}
          closeOverlay={() => setLayerLibraryAssigning((oldState) => !oldState)}
          onSubmit={(ids) => {
            const id = ids[0]
            const foundLayerLibraryImage =
              libraryLayers.libraryLayers.data.find((layer) => layer.id === id)
            setLayerLibraryAssigning(null)
            handleEditLayer({
              ...layerLibraryAssigning,
              libId: id,
              url: foundLayerLibraryImage.url,
            })
          }}
          exclusiveSelect
        />
      )}

      {stagingDeleteLayer && (
        <FlexibleDialog
          submitText={t('saveChanges')}
          closeHandler={() => setStagingDeleteLayer(null)}
          content={t('layerDeleteExplanation')}
          onSubmit={() => handleDeleteLayer(stagingDeleteLayer)}
          title={t('areYouSureYouWantToDeleteThisLayer')}
          open
          cancel
        />
      )}
    </section>
  )
}
CarPhotoLayersSettings.propTypes = {
  carPhotoLayers: PropTypes.object,
  libraryLayers: PropTypes.object,
  getCarPhotoLayers: PropTypes.func.isRequired,
  getLibraryLayers: PropTypes.func.isRequired,
  updateLayersSettings: PropTypes.func.isRequired,
  updateLayer: PropTypes.func.isRequired,
  updateLayerWithLibrary: PropTypes.func.isRequired,
  deleteLayer: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
}

CarPhotoLayersSettings.defaultProps = {
  carPhotoLayers: null,
  libraryLayers: null,
}

export default CarPhotoLayersSettings
