import auth from 'utilities/auth'
import language from 'utilities/language'

export const types = {
  SET_LOGIN: 'SET_LOGIN',
  SET_LOGOUT: 'SET_LOGOUT',
  SET_VERIFY_AUTH: 'SET_VERIFY_AUTH',
  SET_FORGOT_PASSWORD: 'SET_FORGOT_PASSWORD',
  SET_RESET_PASSWORD: 'SET_RESET_PASSWORD',
}

const REQUEST = 'REQUEST'
const SUCCESS = 'SUCCESS'
const FAILURE = 'FAILURE'

const setLogin = (status, payload = {}) => ({
  type: types.SET_LOGIN,
  status,
  payload,
})

export function login(formData) {
  const { customer, password, stayLoggedIn, rememberCredentials, user } =
    formData
  return (dispatch) => {
    dispatch(setLogin(REQUEST))

    // const instanceId = Math.random().toString(36).substring(2);

    auth
      .login(formData)
      .then((response) => {
        // @TODO Rename instanceId / instance to token troughout the whole codebase
        dispatch(
          setLogin(SUCCESS, {
            customerId: customer,
            instanceId: response.data.token,
            jwt: response.data.jwt,
            password,
            rememberCredentials,
            stayLoggedIn,
            username: user,
          }),
        )
      })
      .catch((error) => {
        dispatch(setLogin(FAILURE, { error }))
      })
  }
}

const setLogout = (status, payload = {}) => ({
  type: types.SET_LOGOUT,
  status,
  payload,
})

export function logout(push) {
  return (dispatch, getState) => {
    const {
      auth: { instanceId },
    } = getState()

    dispatch(setLogout(REQUEST))

    auth
      .logout(instanceId)
      .then(() => {
        push('/login')
        dispatch(setLogout(SUCCESS, { instanceId }))
      })
      .catch((error) => {
        dispatch(setLogout(FAILURE, { error }))
      })
  }
}

const setVerifyAuth = (status, payload = {}) => ({
  type: types.SET_VERIFY_AUTH,
  status,
  payload,
})

export function verifyAuthentication(callback) {
  return (dispatch, getState) => {
    const {
      auth: { instanceId },
    } = getState()

    dispatch(setVerifyAuth(REQUEST))

    auth
      .getUserDetails(instanceId)
      .then((response) => {
        // Updates the preferred language after a response from the BE (after logging in - also when localStorage has another language stored)
        language.setLanguage(response?.taal_code)

        dispatch(
          setVerifyAuth(SUCCESS, {
            instanceId,
            userDetails: response,
          }),
        )

        if (typeof callback === 'function') {
          callback(response)
        }
      })
      .catch((error) => {
        dispatch(setVerifyAuth(FAILURE, { error: error.toString() }))
      })
  }
}

const setForgotPassword = (status, payload = {}) => ({
  type: types.SET_FORGOT_PASSWORD,
  status,
  payload,
})

export function forgotPassword(formData) {
  return (dispatch) => {
    dispatch(setForgotPassword(REQUEST))

    auth
      .forgotPassword(formData)
      .then(() => {
        dispatch(setForgotPassword(SUCCESS))
      })
      .catch((error) => {
        dispatch(setForgotPassword(FAILURE, { error: error.toString() }))
      })
  }
}

const setResetPassword = (status, payload = {}) => ({
  type: types.SET_RESET_PASSWORD,
  status,
  payload,
})

export function resetPassword(formData) {
  return (dispatch) => {
    dispatch(setResetPassword(REQUEST))

    auth
      .resetPassword(formData)
      .then(() => {
        dispatch(setResetPassword(SUCCESS))
      })
      .catch((error) => {
        dispatch(setResetPassword(FAILURE, { error: error.toString() }))
      })
  }
}
