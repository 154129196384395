import ActionBar from 'components/organisms/action-bar'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  clearData,
  getActionBarItems,
  getDynamicEndpoint,
  postDynamicEndpoint,
} from 'redux/actions/data'
import { closeSearchbox, openOverlay, openSearchbox } from 'redux/actions/ui'

const mapStateToProps = ({ auth, data, forms, ui }) => {
  let dataProp = {}

  if (data.newCar) {
    dataProp = {
      ...data.newCar.data,
    }
  }

  if (data.carfile) {
    dataProp = {
      ...data.carfile.data,
    }
  }

  if (auth) {
    dataProp.token = auth.instanceId
  }

  return {
    data: dataProp,
    dynamicEndpoint: data.dynamicEndpoint,
    forms,
    items: data.actionBarItems && data.actionBarItems.data,
    searchboxVisible: ui.searchbox,
    todos: data.todos,
  }
}

const mapDispatchToProps = {
  clearData,
  closeSearchbox,
  getActionBarItems,
  getDynamicEndpoint,
  openOverlay,
  openSearchbox,
  postDynamicEndpoint,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ActionBar))
