// Regular CSS is used for global styles instead of styled-components
// due to https://github.com/styled-components/styled-components/issues/1593
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './styles/global.css'

// React
import React from 'react'
import ReactDOM from 'react-dom'

// External dependencies
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

// Internal dependencies
import i18n from 'utilities/i18n'

// Component dependencies
import App from './app'
import * as serviceWorker from './serviceWorker'

// Bugherd snippet is placed her so we can make it dependent on the environment
if (process.env.REACT_APP_NO_BUGHERD === false) {
  if (process.env.NODE_ENV !== 'production') {
    const tagName = 'script'
    const bugherdTag = document.createElement(tagName)
    const firstScript = document.getElementsByTagName(tagName)[0]
    bugherdTag.type = 'text/javascript'
    bugherdTag.src =
      'https://www.bugherd.com/sidebarv2.js?apikey=orwzzksxoc7q4q9ndo2wcg'
    firstScript.parentNode.insertBefore(bugherdTag, firstScript)
  }
}

// Initializes i18n
i18n()

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
