import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import DonutChart from 'components/atoms/donut-chart'
import LegendBoxes from 'components/molecules/legend-boxes'

const ContainerSet = styled.div`
  display: flex;
  flex-direction: column;
  /**
    reserves the space for the 200x200 canvas of the donut chart.
   */
  height: calc(${(props) => props.theme.sizings.lvl1} * 55);

  > *:first-child {
    // needs to be the 200px + the relative height of the
    // multiline heading:
    flex: 4.5 0;
  }

  > *:last-child {
    /**
     The LegendBoxes need a fair amount of space, because
     the labels can cover to 4 lines of the box:
    */
    flex: 1.8 1;
  }
`

/**
 * A combination donutchart with two legend-boxes
 */

function DonutChartWithLegends({
  parentIsDarker,
  mainTitle,
  percentage,
  legendColor,
  leftTitle,
  leftValue,
  rightTitle,
  rightValue,
  unit,
}) {
  return (
    <ContainerSet>
      <DonutChart
        color={legendColor}
        value={percentage}
        title={mainTitle}
        filledTitle={leftTitle}
        unfilledTitle={rightTitle}
      />
      <LegendBoxes
        items={[
          {
            label: leftTitle,
            value: leftValue,
            color: legendColor,
          },
          {
            label: rightTitle,
            value: rightValue,
            color: 'isolatedDarkerBackground',
          },
        ]}
        parentIsDarker={parentIsDarker}
        unit={unit}
        arrangeAs="row"
      />
    </ContainerSet>
  )
}

DonutChartWithLegends.propTypes = {
  /** parentIsDarker mode bool */
  parentIsDarker: PropTypes.bool,
  /** Main title or label */
  mainTitle: PropTypes.string.isRequired,
  /** legend color for whole set, one of the theme color names */
  legendColor: PropTypes.string,
  /** Value shown in middle of donut */
  percentage: PropTypes.number,
  /** Label for left legend box */
  leftTitle: PropTypes.string.isRequired,
  /** Unit used for comparing */
  unit: PropTypes.string,
  /** Value for left legend box */
  leftValue: PropTypes.number,
  /** Label for right legend box */
  rightTitle: PropTypes.string.isRequired,
  /** Value for right legend box */
  rightValue: PropTypes.number,
}

DonutChartWithLegends.defaultProps = {
  parentIsDarker: false,
  leftValue: undefined,
  legendColor: undefined,
  percentage: undefined,
  rightValue: undefined,
  unit: null,
}

export default DonutChartWithLegends
