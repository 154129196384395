import React, { useContext } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  GET_DEALER_AND_GET_CAR_MODELS,
  GET_FUEL_TYPES,
  GET_PRICE_MATRICES,
  CREATE_PRICE_MATRIX,
  CREATE_PRICE_MATRIX_RULE,
} from 'config/graphql/csp'
import Overlay from 'components/molecules/overlay'
import PriceMatrixTableForm from 'components/organisms/car-service-plan/price-matrix-table-form'
import LoadingIndicator from 'components/atoms/loading-indicator'
import { DealerContext } from 'contexts/dealer'

const LayoutContainer = styled.div`
  flex: 1;
`

function PriceMatrixNew() {
  const { dealerId } = useParams()
  const { t } = useTranslation()
  const history = useHistory()

  const {
    data: dealerAndCarModelsData,
    loading: dealerAndCarModelsLoading,
    error: dealerAndCarModelsError,
  } = useQuery(GET_DEALER_AND_GET_CAR_MODELS, {
    variables: { dealerId },
  })

  const {
    data: fuelTypesData,
    loading: fuelTypesLoading,
    error: fuelTypesError,
  } = useQuery(GET_FUEL_TYPES)

  const carServicePlanId =
    dealerAndCarModelsData?.dealer?.carServicePlan?.id || ''
  const { setPriceMatrixMutating } = useContext(DealerContext)

  const [createPriceMatrix] = useMutation(CREATE_PRICE_MATRIX)
  const [createPriceMatrixRule] = useMutation(CREATE_PRICE_MATRIX_RULE, {
    refetchQueries: [
      {
        query: GET_PRICE_MATRICES,
        variables: { carServicePlanId },
        skip: !carServicePlanId,
      },
    ],
    awaitRefetchQueries: true,
  })

  const handleCreatePriceMatrix = async ({
    carServicePlanId,
    name,
    maxMileagePerYear,
    priceMatrixRules,
  }) => {
    setPriceMatrixMutating(true)
    const createPriceMatrixPayload = {
      variables: {
        name,
        maxMileagePerYear,
        carServicePlanId,
      },
    }

    try {
      const priceMatrix = await createPriceMatrix(createPriceMatrixPayload)
      if (priceMatrix.data && priceMatrix.data.createPriceMatrix.id) {
        return Promise.all(
          priceMatrixRules.map((rule) => {
            const payload = rule
            delete payload.actions
            const result = createPriceMatrixRule({
              variables: {
                ...payload,
                priceMatrixId: priceMatrix.data.createPriceMatrix.id,
              },
            })

            if (result.errors) {
              result.errors.forEach((error) => console.error(error))
            }

            return result
          }),
        ).then((_) => {
          setPriceMatrixMutating(false)
          history.goBack()
        })
      }
      setPriceMatrixMutating(false)
      history.goBack()
      throw new Error('Error submitting form')
    } catch (e) {
      setPriceMatrixMutating(false)
      history.goBack()
      throw new Error(e)
    }
  }

  const noCspError =
    dealerAndCarModelsData &&
    dealerAndCarModelsData.dealer &&
    !dealerAndCarModelsData.dealer.carServicePlan
      ? t('carServicePlanAdminPriceMatrixOverlay.dealerHasNoCsp')
      : undefined
  const dataError = dealerAndCarModelsError || fuelTypesError || noCspError
  const dataLoading = dealerAndCarModelsLoading || fuelTypesLoading

  return (
    <Overlay
      close={() => history.goBack()}
      title={t('carServicePlanAdminPriceMatrixOverlay.addHeading')}
      icon="cleaning"
    >
      <LayoutContainer>
        {dataLoading || dataError ? (
          <LoadingIndicator error={dataError} />
        ) : (
          <PriceMatrixTableForm
            onSubmit={handleCreatePriceMatrix}
            dealerName={dealerAndCarModelsData.dealer.name}
            carServicePlanId={dealerAndCarModelsData.dealer.carServicePlan.id}
            carBrandsAndModels={
              dealerAndCarModelsData ? dealerAndCarModelsData.brands : []
            }
            fuelTypes={fuelTypesData ? fuelTypesData.fuelTypes : []}
          />
        )}
      </LayoutContainer>
    </Overlay>
  )
}

export default PriceMatrixNew
