import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.brandAlfa};
  display: flex;
  justify-content: center;
`

const StyledSvg = styled.svg`
  width: 200px;

  * {
    fill: ${({ theme }) => theme.colors.brandGolf};
  }
`

function Svg() {
  return (
    <StyledSvg
      x="0px"
      y="0px"
      viewBox="0 0 90 90"
      enableBackground="new 0 0 90 90"
      xmlSpace="preserve"
    >
      <polygon points="0,0 0,0 0,0 " />
      <g>
        <path d="M71.545,48.145h-31.98V20.743c0-2.627-2.138-4.765-4.765-4.765H18.456c-2.628,0-4.767,2.138-4.767,4.765v42.789   c0,2.628,2.138,4.766,4.767,4.766h5.535v0.959c0,2.628,2.138,4.765,4.766,4.765h42.788c2.628,0,4.766-2.137,4.766-4.765V52.914   C76.311,50.284,74.173,48.145,71.545,48.145z M18.455,16.935h16.344c2.1,0,3.808,1.708,3.808,3.808v27.401H37.25V22.636   c0-0.264-0.215-0.478-0.479-0.478H16.482c-0.264,0-0.479,0.214-0.479,0.478v36.585c0,0.264,0.215,0.478,0.479,0.478h7.507v7.644   h-5.534c-2.101,0-3.81-1.709-3.81-3.81V20.743C14.645,18.643,16.354,16.935,18.455,16.935z M16.96,23.116h19.331v25.031h-7.535   c-2.628,0-4.766,2.139-4.766,4.768v5.828h-7.03V23.116z M71.545,73.064H28.757c-2.101,0-3.81-1.708-3.81-3.808V52.914   c0-2.102,1.709-3.812,3.81-3.812h42.788c2.1,0,3.809,1.71,3.809,3.812v16.343C75.354,71.356,73.645,73.064,71.545,73.064z" />
        <path d="M28.919,58.424c-1.466,0-2.659,1.193-2.659,2.66c0,1.466,1.193,2.658,2.659,2.658c1.468,0,2.662-1.192,2.662-2.658   C31.581,59.617,30.387,58.424,28.919,58.424z M28.919,62.786c-0.939,0-1.703-0.764-1.703-1.702c0-0.939,0.764-1.704,1.703-1.704   c0.94,0,1.705,0.765,1.705,1.704C30.623,62.022,29.858,62.786,28.919,62.786z" />
        <path d="M69.654,50.461H33.069c-0.264,0-0.479,0.215-0.479,0.479v20.288c0,0.264,0.215,0.478,0.479,0.478h36.585   c0.263,0,0.477-0.214,0.477-0.478V50.939C70.131,50.676,69.917,50.461,69.654,50.461z M69.174,51.417V70.75H33.548V51.417H69.174z" />
        <path d="M45.201,30.296c6.651,0,12.233,5.351,12.551,11.977l-3.033-2.638c-0.193-0.165-0.507-0.142-0.675,0.048   c-0.174,0.198-0.153,0.501,0.045,0.676l3.883,3.375c0.09,0.075,0.198,0.115,0.312,0.115c0.141,0,0.273-0.061,0.362-0.166   l3.371-3.877c0.173-0.2,0.151-0.502-0.047-0.675c-0.194-0.166-0.508-0.144-0.676,0.048l-2.592,2.979   c-0.18-3.417-1.629-6.605-4.099-9.001c-2.538-2.461-5.877-3.817-9.404-3.817c-0.264,0-0.479,0.215-0.479,0.479   C44.72,30.083,44.936,30.296,45.201,30.296z" />
      </g>
    </StyledSvg>
  )
}

function ScreenRotateOverlay({ className }) {
  return (
    <Container className={className}>
      <Svg />
    </Container>
  )
}

ScreenRotateOverlay.propTypes = {
  className: PropTypes.string,
}

ScreenRotateOverlay.defaultProps = {
  className: null,
}

export default ScreenRotateOverlay
