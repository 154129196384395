import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import Icon from '../atoms/icon'
import InlineBodyText from 'components/atoms/inline-body-text'

const IconListContainer = styled.ul`
  columns: ${({ columns }) => columns};
  margin: 0 auto;
`

const IconListItem = styled.li`
  display: flex;
  align-items: center;
  text-align: left;
  break-inside: avoid;
  line-height: 21px;
  margin: 8px 0;
`

const StyledIcon = styled(Icon)`
  min-width: 24px;
  margin-right: ${({ theme }) => theme.sizings.lvl1};
`

const IconList = ({ columns, items, icon, iconColour, className }) => (
  <IconListContainer columns={columns} className={className}>
    {items.map((item) => (
      <IconListItem key={`listItem_${item.content}`}>
        {icon && (
          <StyledIcon
            type={item.disabled ? 'cross' : icon}
            color={item.disabled ? 'inputLabel' : iconColour}
          />
        )}
        {/* HACK: replaced this with a simpler component, which does not trigger an infinite re-render loop */}
        <InlineBodyText variant="tonedDown">{item.content}</InlineBodyText>
      </IconListItem>
    ))}
  </IconListContainer>
)

IconList.propTypes = {
  columns: PropTypes.number,
  icon: PropTypes.string,
  iconColour: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  className: PropTypes.string,
}
IconList.defaultProps = {
  columns: 1,
  icon: undefined,
  iconColour: undefined,
  className: undefined,
}

export default IconList
