import React from 'react'
import PropTypes from 'prop-types'
import MuiCheckbox from '@material-ui/core/Checkbox'

/**
 * Checkbox component wrapping
 * [Material UI's Checkbox](https://material-ui.com/components/checkboxes/)
 */
function NewCheckbox({
  checked,
  disabled,
  id,
  onChange,
  indeterminate,
  className,
}) {
  return (
    <MuiCheckbox
      className={className}
      checked={checked}
      disabled={disabled}
      value={id}
      onChange={(event) => onChange(event.target.checked)}
      indeterminate={indeterminate}
    />
  )
}

NewCheckbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  indeterminate: PropTypes.bool,
}

NewCheckbox.defaultProps = {
  disabled: false,
  onChange: null,
}

export default NewCheckbox
