import { useQuery } from '@apollo/client'
import Button from 'components/atoms/button'
import Icon from 'components/atoms/icon'
import Label from 'components/atoms/label'
import LoadingIndicator from 'components/atoms/loading-indicator'
import EnhancedTable, {
  TableDataText,
} from 'components/organisms/enhanced-table'
import GeneralDataError from 'components/organisms/general-data-error'
import {
  getInvoiceStatusLabels,
  invoiceStatusColors,
  invoiceStatuses,
} from 'config/data'
import { GET_INVOICES_BY_DEALER_ID } from 'config/graphql/csp'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { formatPrice } from 'utilities/utils'

const StyledTableDataText = styled(TableDataText)`
  font-weight: 400;
  margin-top: ${({ theme }) => theme.sizings.lvl0};
`

const InvoicesTable = styled(EnhancedTable)`
  thead th {
    // Subscriptions column
    &:nth-child(3) {
      width: 20%;
    }

    // CSP Factuur action column
    &:nth-child(6) {
      width: 11%;
    }

    // Direct debit action column
    &:nth-child(7) {
      width: 8%;
    }
  }
`

const ActionButtonContainer = styled.div`
  display: inline-flex;
  align-items: center;
`

// Using a link for the iDeal button because the icon needs it's own coloring
// and <Buttons> override the colors of icons
const IDealLink = styled.a`
  background-color: transparent;
  padding: 0;
  width: auto;
  height: auto;
  display: inline-flex;
  position: relative;
  top: ${({ theme }) => theme.sizings.lvl0};
`

const StyledButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  width: auto;
  height: auto;
  display: inline-flex;

  & + & {
    margin-left: ${({ theme }) => theme.sizings.lvl1};
  }
`

const ActionIconButton = ({ icon, ...restProps }) => (
  <StyledButton
    level="option"
    icon={icon}
    iconSize="lg"
    iconColor="actionsStandard"
    {...restProps}
  />
)

ActionIconButton.propTypes = {
  icon: PropTypes.string.isRequired,
}

const { UNPAID } = invoiceStatuses

const DirectDebitDealerDetails = ({ dealerId }) => {
  const { t } = useTranslation()
  const invoiceStatusLabels = getInvoiceStatusLabels(t)
  const { loading, error, data } = useQuery(GET_INVOICES_BY_DEALER_ID, {
    variables: {
      dealerId,
    },
  })

  if (loading) {
    return <LoadingIndicator />
  }
  if (error) {
    console.error(error)
    return <GeneralDataError />
  }

  // Format the data for the EnhancedTable
  const formattedRows = data.invoicesByDealerId
    .slice() // Make a copy of the array so it's mutable
    // Sort the results by date
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    // Normalize the data
    .map((invoice) => {
      const invoiceDate = moment(invoice.date, 'YYYY-MM-DD')

      return {
        month: t(`months.${invoiceDate.month() + 1}`),
        year: invoiceDate.year(),
        subscriptions: invoice.contractCount,
        amount: formatPrice(invoice.amount, true),
        status: {
          label: invoiceStatusLabels[invoice.status],
          color: invoiceStatusColors[invoice.status],
          value: invoice.status,
        },
        cspInvoiceUri: invoice.invoiceFullUri,
        sepaUri: invoice.sepaFullUri,
        sepaExcelUri: invoice.sepaExcelFullUri,
        paymentUri: invoice.paymentUri,
      }
    })
    // Create the rows for the table
    .map((rowData) => ({
      month: {
        component: <StyledTableDataText>{rowData.month}</StyledTableDataText>,
        data: '',
      },
      year: {
        component: <StyledTableDataText>{rowData.year}</StyledTableDataText>,
        data: '',
      },
      subscriptions: {
        component: (
          <StyledTableDataText>{rowData.subscriptions}</StyledTableDataText>
        ),
        data: '',
      },
      amount: {
        component: <StyledTableDataText>{rowData.amount}</StyledTableDataText>,
        data: '',
      },
      status: {
        component: (
          <StyledTableDataText>
            <Label
              text={rowData.status.label}
              backgroundColor={rowData.status.color}
              size="small"
            />
          </StyledTableDataText>
        ),
        data: '',
      },
      cspInvoice: {
        component: (
          <ActionIconButton
            icon="download"
            iconWidth="24"
            iconHeight="24"
            onClick={() => {
              window.open(rowData.cspInvoiceUri, '_blank')
            }}
          />
        ),
        data: '',
      },
      directDebit: {
        component:
          rowData.status.value === UNPAID ? (
            <IDealLink href={rowData.paymentUri} target="_blank">
              <Icon type="ideal" width="24" height="21" />
            </IDealLink>
          ) : (
            <ActionButtonContainer>
              {rowData.sepaExcelUri && (
                <ActionIconButton
                  icon="excelDownload"
                  iconWidth="24"
                  iconHeight="24"
                  onClick={() => {
                    window.open(rowData.sepaExcelUri, '_blank')
                  }}
                />
              )}
              <ActionIconButton
                icon="sepaDownload"
                iconWidth="24"
                iconHeight="24"
                onClick={() => {
                  window.open(rowData.sepaUri, '_blank')
                }}
              />
            </ActionButtonContainer>
          ),
        data: '',
      },
    }))

  return (
    <InvoicesTable
      selectable={false}
      columns={[
        {
          id: 'month',
          label: t('month'),
        },
        {
          id: 'year',
          label: t('year'),
        },
        {
          id: 'subscriptions',
          label: t('directDebitFiles.invoicesTableHeadings.subscriptions'),
        },
        {
          id: 'amount',
          label: t('directDebitFiles.invoicesTableHeadings.amount'),
        },
        {
          id: 'status',
          label: t('status'),
        },
        {
          id: 'cspInvoice',
          label: t('directDebitFiles.invoicesTableHeadings.cspInvoice'),
        },
        {
          id: 'directDebit',
          label: t('directDebitFiles.invoicesTableHeadings.directDebit'),
          alignRight: true,
        },
      ]}
      rows={formattedRows}
    />
  )
}

DirectDebitDealerDetails.propTypes = {
  dealerId: PropTypes.string.isRequired,
}

export default DirectDebitDealerDetails
