import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const Card = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.sizings.lvl2};
  border-top: solid 4px ${({ legendColor, theme }) => theme.colors[legendColor]};
  background-color: ${({ parentIsDarker, $legendBackgroundColor, theme }) =>
    $legendBackgroundColor
      ? theme.colors[$legendBackgroundColor]
      : parentIsDarker
        ? '#FFF'
        : ({ theme }) => theme.colors.isolatedBackground};
  box-shadow: ${({ parentIsDarker }) =>
    parentIsDarker ? ({ theme }) => theme.shadows.bottom : 'none'};
`

/**
 * Legend box container

 */

function LegendBoxContainer({
  legendColor,
  legendBackgroundColor,
  parentIsDarker,
  children,
  className,
}) {
  return (
    <Card
      className={className}
      legendColor={legendColor}
      $legendBackgroundColor={legendBackgroundColor}
      parentIsDarker={parentIsDarker}
    >
      {children}
    </Card>
  )
}

LegendBoxContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  /** The color of the top border */
  legendColor: PropTypes.string,
  legendBackgroundColor: PropTypes.string,
  /** For enabling the parentIsDarker mode */
  parentIsDarker: PropTypes.bool,
}

LegendBoxContainer.defaultProps = {
  className: undefined,
  legendColor: 'actionsStandard',
  legendBackgroundColor: 'isolatedBackground',
  parentIsDarker: false,
}

export default LegendBoxContainer
