import { useHistory } from 'react-router-dom'
import { getObjectFromQueryString } from 'utilities/utils'
import { useEffect } from 'react'

// This hook takes the current url and gets a query parameter from it. If it is not found, it
// returns undefined. If you set `removeFromUrl` to true, the parameter will be removed from the url
// using history.replace().
export const useQueryParam = (name, removeFromUrl) => {
  const history = useHistory()
  const { location } = history
  const queryParams = getObjectFromQueryString(location.search)
  const param = queryParams?.[name]
  if (removeFromUrl && param !== undefined) {
    let search = new URLSearchParams(location.search)
    search.delete(name)
    search = search.toString()
    if (search.length) {
      search = `?${search}`
    }
    history.replace(`${location.pathname}${search}${location.hash}`)
  }

  return param
}

// Useful for debugging
export const useLogMountUnmount = (componentName) =>
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(componentName ? `${componentName} mount` : 'Mount')

    return () => {
      // eslint-disable-next-line no-console
      console.log(componentName ? `${componentName} unmount` : 'Unmount')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
