// eslint-disable-next-line no-unused-vars
import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const CustomParagraph = styled.p`
  color: ${({ theme }) => theme.colors.textTonedDown};
  font-family: ${({ theme }) => theme.font};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin: 1em 0;
  strong {
    font-weight: 600;
  }
`

/**
  A longer piece of text that is allowed to span several lines or sentences.
  Perfect for short introduction or explanations for app functionalities.
 */
const ExplanationParagraph = ({ children, className, ...restProps }) => (
  <CustomParagraph className={className} {...restProps}>
    {children}
  </CustomParagraph>
)

ExplanationParagraph.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

ExplanationParagraph.defaultProps = {
  className: null,
}

export default ExplanationParagraph
