import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'
import styled from 'styled-components/macro'

import { media } from 'utilities/styled'
import {
  addCarShareStockData,
  getDataProviders,
  clearData,
} from 'redux/actions/data'
import { getB2bPurchasingCompanies } from 'redux/actions/settings'

import {
  CarKindEnum,
  CarFileStatusEnumLowerCase,
  CarFileStatusEnumUpperCase,
} from 'config/enums'
import { carShareUploadDialogFormValidation } from 'config/validation-schemas'

import ErrorText from 'components/atoms/error-text'
import {
  FormikSelectInput,
  FormikTextInput,
} from 'components/molecules/formik-field'
import Button from 'components/atoms/button'
import LoadingButton from 'components/atoms/loading-button'
import SelectFileButton from 'components/molecules/select-file-button'
import Typography from 'components/molecules/typography'

const StyledTypography = styled(Typography)`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
    margin-bottom: ${({ theme }) => theme.sizings.lvl1};
  `}
`

const UploadFields = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.sizings.lvl3};
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

const UploadInputContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 100%;

  ${media.tablet`
    margin-bottom: ${({ theme }) => theme.sizings.lvl0};
    min-height: ${({ theme }) => theme.sizeByFactor(6.75)};
  `}
`

const StyledFormikTextInput = styled(FormikTextInput)`
  position: absolute;
  width: 100%;
  z-index: 0;
  display: none;

  ${media.tablet`
    display: block;
  `}
`

const UploadInputFile = styled(SelectFileButton)`
  position: relative;
  z-index: 1;
  width: 100%;

  ${media.tablet`
    margin-right:  ${({ theme }) => theme.sizings.lvl1};
    width: auto;
  `}
`

const UploadInputFileRequirement = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
  margin: 0;
`

const StockFields = styled.div`
  display: grid;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl1};

  ${media.tablet`
    display: grid;
    grid-template-rows: min-content;
    grid-template-columns: 1fr 1fr;
    width: auto;
    gap: ${({ theme }) => theme.sizings.lvl2};
  `}
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: ${({ theme }) => theme.sizings.lvl1};
  align-items: center;
  margin-top: ${({ theme }) => theme.sizings.lvl3};

  ${media.tablet`
    flex-direction: row;
    gap: ${({ theme }) => theme.sizings.lvl3};
  `}
`

const createFormData = ({
  file,
  enrichment,
  status,
  purchasingCompany,
  type,
}) => {
  const formData = new FormData()

  formData.append('file', file)
  formData.append('verrijkings_provider', enrichment)
  formData.append('status', status)
  formData.append('relatie', purchasingCompany)
  formData.append('soort', type)

  return formData
}

const CarShareUploadDialogForm = ({ open, onClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const initialSelectItems = []
  const enrichmentProviders = useSelector((state) => state?.data?.dataProviders)
  const enrichmentItems =
    enrichmentProviders?.data
      ?.filter((provider) => !!provider.key)
      .map(({ key, label }) => ({ value: key, label })) || initialSelectItems
  const purchasingCompanies = useSelector(
    (state) => state?.settings?.b2bPurchasingCompanies?.all,
  )
  const purchasingCompanyItems =
    purchasingCompanies?.data?.map(({ id, bedrijf }) => ({
      value: id,
      label: bedrijf,
    })) || initialSelectItems
  const typeItems =
    Object.keys(CarKindEnum).map((key) => ({
      value: key,
      label: `${t(`carKinds.${CarKindEnum[key]}`)}`,
    })) || initialSelectItems

  // Check the loading state
  const addCarShareStockDataResult = useSelector(
    (state) => state?.data?.addCarShareStockData,
  )

  // Disable the input fields when the submit was succesfull OR when the dynamic inputfields are loading
  const isDisabled =
    !!addCarShareStockDataResult?.loading ||
    !!enrichmentProviders?.loading ||
    !!purchasingCompanies?.loading

  const statusItems = [
    {
      label: t('carShare.uploadDialog.form.status.labels.lopend'),
      value: CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.lopend],
    },
    {
      label: t('carShare.uploadDialog.form.status.labels.verwacht'),
      value: CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verwacht],
    },
    {
      label: t('carShare.uploadDialog.form.status.labels.binnen'),
      value: CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.binnen],
    },
  ]

  const handleSubmit = (values) => {
    // Creates the form data for the backend
    const formData = createFormData(values)

    // Send the form data to the backend
    dispatch(
      addCarShareStockData(formData, t('carShare.uploadDialog.form.success')),
    )
  }

  useEffect(() => {
    // Retrieve the data providers (for the enrichment dropdown) once the component mounts
    dispatch(getDataProviders())
    dispatch(getB2bPurchasingCompanies({ id: 'all' }))
  }, [dispatch])

  useEffect(() => {
    // Return early when the data hasn't been uploaded, or when the modal has been closed
    if (!addCarShareStockDataResult?.data?.success || !open) {
      return
    }

    // Close the modal
    onClose()

    // Clear the uploaded data from the Redux store
    dispatch(clearData('addCarShareStockData'))
  }, [addCarShareStockDataResult, dispatch, onClose, open])

  return (
    <Formik
      validationSchema={carShareUploadDialogFormValidation(t)}
      enableReinitialize
      validateOnMount
      isSubmitting={isDisabled}
      onSubmit={handleSubmit}
      initialValues={{
        file: '',
        fileName: '',
        status: '',
        enrichment: '',
        purchasingCompany: '',
        type: '',
      }}
    >
      {({ isValid, values, errors, setValues }) => {
        const fileIsInvalid = values.fileName && !!errors?.fileName

        return (
          <Form>
            <UploadFields>
              <StyledTypography type="Level2Heading">
                {t('carShare.uploadDialog.form.file.title')}
              </StyledTypography>
              <UploadInputContainer>
                <Field
                  name="fileName"
                  label={t('carShare.uploadDialog.form.fileName.label')}
                  component={StyledFormikTextInput}
                  filled
                  readOnly
                  required
                />
                <UploadInputFile
                  disabled={isDisabled}
                  id="file"
                  onChange={(formData) => {
                    const file = formData.get('file')
                    setValues({ ...values, file, fileName: file.name })
                  }}
                  text={t('carShare.uploadDialog.form.file.label')}
                  allowedFileExtensions={['.xlsx']}
                />
              </UploadInputContainer>
              {fileIsInvalid ? (
                <ErrorText>{errors?.fileName}</ErrorText>
              ) : (
                <UploadInputFileRequirement type="BodyParagraph">
                  {t('carShare.uploadDialog.form.file.maxFileSize')}
                </UploadInputFileRequirement>
              )}
            </UploadFields>

            <StyledTypography type="Level2Heading">
              {t('carShare.uploadDialog.form.stock.title')}
            </StyledTypography>

            <StockFields>
              <Field
                disabled={isDisabled}
                name="status"
                label={t('carShare.uploadDialog.form.status.label')}
                items={statusItems}
                filled
                component={FormikSelectInput}
                required
              />
              <Field
                disabled={isDisabled}
                name="enrichment"
                label={t('carShare.uploadDialog.form.enrichment.label')}
                items={enrichmentItems}
                filled
                component={FormikSelectInput}
                required
              />
              <Field
                disabled={isDisabled}
                name="purchasingCompany"
                label={t('carShare.uploadDialog.form.purchasingCompany.label')}
                items={purchasingCompanyItems}
                filled
                component={FormikSelectInput}
                required
              />
              <Field
                disabled={isDisabled}
                name="type"
                label={t('carShare.uploadDialog.form.type.label')}
                items={typeItems}
                filled
                component={FormikSelectInput}
                required
              />
            </StockFields>

            <Buttons>
              <Button
                disabled={isDisabled}
                level="option"
                onClick={onClose}
                text={t('cancel')}
                noPadding
              />
              <LoadingButton
                disabled={isDisabled || !isValid}
                isLoading={addCarShareStockDataResult?.loading === true} // Whenever the submit goes wrong (e.g. validation error in .xslx), isLoading is false
                level="cta"
                type="submit"
              >
                {t('carShare.uploadDialog.form.submitText')}
              </LoadingButton>
            </Buttons>
          </Form>
        )
      }}
    </Formik>
  )
}

CarShareUploadDialogForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CarShareUploadDialogForm
