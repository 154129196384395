import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'

import {
  GET_BMS_DATA,
  GET_VIN_BY_LICENSE_PLATE,
} from 'config/graphql/v4/queries/bms'

import { withApolloV4Provider } from 'utilities/apollo'
import { getCarfile, getCarAssets, updateCarfile } from 'redux/actions/data'

import LoadingButton, {
  propTypes as loadingButtonPropTypes,
} from 'components/atoms/loading-button'
import AddVinNumberDialog from 'components/views/car-file/dialogs/add-vin-number-dialog'
import AddLicensePlateDialog from 'components/views/car-file/dialogs/add-license-plate-dialog'

const DialogIds = {
  VIN: 'VIN',
  LICENSE_PLATE: 'LICENSE_PLATE',
}

const CarfileRequestBmsDataButton = ({ carfile, isLoading }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const carfileId = carfile?.auto_id
  const carfileVin = carfile?.chassisnr ?? ''
  const carfileLicensePlate = carfile?.kenteken ?? ''

  const [showDialogWithId, setShowDialogWithId] = useState(undefined)
  const [
    showToggleToLicensePlateDialogButton,
    setShowToggleToLicensePlateDialogButton,
  ] = useState(true)
  const [hasUpdatedCarfile, setHasUpdatedCarfile] = useState(false)
  const [processBmsData, setProcessBmsData] = useState(false)

  const [vin, setVin] = useState('')
  const [licensePlate, setLicensePlate] = useState('')

  const handleCloseDialog = () => {
    setShowDialogWithId(undefined)

    // Unsets the vin and license plate data when closing the dialogs
    setVin(carfileVin)
    setLicensePlate(carfileLicensePlate)

    // Enable toggling from the VIN to the license plate dialog
    setShowToggleToLicensePlateDialogButton(true)

    // If the carfile has no license plate and the user adds the license plate through the modal, but there's no VIN number received,
    // refresh the carfile when the user closes the modal so the correct license plate shows up in the carfile summary.
    if (hasUpdatedCarfile) {
      dispatch(getCarfile(carfileId))
    }
  }

  const [
    getVinNumberByLicensePlate,
    { loading: vinNumberByLicensePlateLoading },
  ] = useLazyQuery(GET_VIN_BY_LICENSE_PLATE, {
    fetchPolicy: 'network-only',
    onCompleted: async ({ vin: { vin } }) => {
      // If a VIN number came back from the getVinNumberByLicensePlate query, set the local vin. This wil in effect run the getBmsData query.
      if (vin) {
        setVin(vin)
        setProcessBmsData(true)
      }

      // If a VIN number came back from the getVinNumberByLicensePlate query and it's different than from what it previously was,
      // refresh the carfile
      if (vin !== carfileVin) {
        setHasUpdatedCarfile(true)
      }

      // If a VIN number did not come back from the getVinNumberByLicensePlate query, show the modal where the user can manually enter their vin
      if (!vin) {
        setShowDialogWithId(DialogIds.VIN)
      }
    },
    onError: () => {
      // If the user has added a license plate in the modal, but this isn't found, show the pop-up of the vin number
      if (
        !!licensePlate === true &&
        showDialogWithId === DialogIds.LICENSE_PLATE
      ) {
        setLicensePlate(licensePlate)
        setShowToggleToLicensePlateDialogButton(false)
        setShowDialogWithId(DialogIds.VIN)

        return
      }

      // If the user hasn't opened the license plate modal, open the VIN number modal
      if (showDialogWithId !== DialogIds.LICENSE_PLATE) {
        setShowDialogWithId(DialogIds.VIN)
        setShowToggleToLicensePlateDialogButton(false)
      }
    },
  })

  const [getBmsData, { loading: bmsDataLoading }] = useLazyQuery(GET_BMS_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: async () => {
      // After a succesful BMS data query, the backend adds:
      // - An image with the overview of the battery data
      // - A dataset with the overview of the MOBA data
      // We need to refresh the assets and the carfile to show this to the user
      dispatch(getCarAssets(carfileId))
      dispatch(getCarfile(carfileId))
    },
    onError: () => {
      // If the BMS data isn't found, but the user is currently in a modal, close the modal
      if (showDialogWithId === undefined) {
        handleCloseDialog()
      }

      // If the VIN number was updated in the carfile, but the MOBA data wasn't found, refresh the carfile to reflect the updated VIN number
      if (hasUpdatedCarfile) {
        dispatch(getCarfile(carfileId))
      }
    },
  })

  const handleClickRequestBmsButton = useCallback(() => {
    // When there's no license plate and no VIN, open the 'addLicensePlateDialog'
    if (!!carfileLicensePlate === false && !!carfileVin === false) {
      setShowDialogWithId(DialogIds.LICENSE_PLATE)
      return
    }

    // When there is a license plate but no VIN, open the 'addVinNumberDialog'
    if (!!carfileLicensePlate === true && !!carfileVin === false) {
      // Tries to get the VIN number by the just added license plate
      getVinNumberByLicensePlate({
        variables: {
          carfileId,
          licenseplate: carfileLicensePlate,
        },
      })

      return
    }

    // When there's both a license plate and a VIN number, get the BMS data
    if (carfileVin) {
      getBmsData({
        variables: {
          carfileId,
          vin: carfileVin,
        },
      })
    }
  }, [
    carfileId,
    carfileLicensePlate,
    carfileVin,
    getBmsData,
    getVinNumberByLicensePlate,
  ])

  const handleLicensePlateChange = useCallback(
    async (licensePlate) => {
      if (!licensePlate) {
        return
      }

      // Updates the added license plate in the carfile
      dispatch(
        updateCarfile({ kenteken: licensePlate }, carfileId, null, false),
      )

      setLicensePlate(licensePlate)
      setHasUpdatedCarfile(true)

      // Tries to get the VIN number by the just added license plate
      getVinNumberByLicensePlate({
        variables: {
          carfileId,
          licenseplate: licensePlate,
        },
      })
    },
    [carfileId, dispatch, getVinNumberByLicensePlate],
  )

  const handleVinChange = useCallback(
    async (vin) => {
      if (!!vin === false) {
        return
      }

      // Updates the added license plate in the carfile
      dispatch(updateCarfile({ chassisnr: vin }, carfileId, null, false))
      setHasUpdatedCarfile(true)

      // Tries to get the VIN number by the just added license plate
      getBmsData({
        variables: {
          carfileId,
          vin,
        },
      })
    },
    [carfileId, getBmsData, dispatch],
  )

  const handleToggleModal = () => {
    // Sets the vin and license plate to default data
    setVin(carfileVin)
    setLicensePlate(carfileLicensePlate)

    // Open VIN dialog when license plate dialog is open
    if (showDialogWithId === DialogIds.LICENSE_PLATE) {
      setShowDialogWithId(DialogIds.VIN)
    }

    // Open license plate dialog when VIN dialog is open
    if (showDialogWithId === DialogIds.VIN) {
      setShowDialogWithId(DialogIds.LICENSE_PLATE)
    }
  }

  // This useEffect runs after the user updated their license plate and a VIN number was returned
  // from the backend.
  useEffect(() => {
    // Safeguard to not run this useEffect. setProcessBmsData is only 'true' when the VIN was sucessfully retreived by license plate
    if (!processBmsData) {
      return
    }

    // If the license plate dialog was opened, but no license plate has been manually entered by the user, do not run this effect
    if (
      showDialogWithId === DialogIds.LICENSE_PLATE &&
      !!licensePlate === false
    ) {
      return
    }

    // If the VIN dialog was opened, but no VIN has been manually entered by the user, do not run this effect
    if (showDialogWithId === DialogIds.VIN && !!vin === false) {
      return
    }

    // Safeguard for only processing the BMS data once
    setProcessBmsData(false)

    // Update the added license plate in the carfile
    dispatch(updateCarfile({ chassisnr: vin }, carfileId, null, false))

    // If the carfile has no VIN and the user just added the licensePlate, run the getBmsData query
    getBmsData({
      variables: {
        carfileId,
        vin,
      },
    })
  }, [
    carfileId,
    vin,
    hasUpdatedCarfile,
    processBmsData,
    licensePlate,
    showDialogWithId,
    getBmsData,
    dispatch,
  ])

  // Combined loading state
  const combinedIsLoading =
    isLoading || bmsDataLoading || vinNumberByLicensePlateLoading

  return (
    <>
      <LoadingButton
        level="option"
        icon="requestBms"
        iconSize="md"
        onClick={handleClickRequestBmsButton}
        isLoading={combinedIsLoading}
      >
        {t('requestBmsData')}
      </LoadingButton>

      {showDialogWithId === DialogIds.LICENSE_PLATE && (
        <AddLicensePlateDialog
          onToggleClick={handleToggleModal}
          onClose={handleCloseDialog}
          isLoading={combinedIsLoading}
          licensePlate={licensePlate}
          handleLicensePlateChange={handleLicensePlateChange}
        />
      )}

      {showDialogWithId === DialogIds.VIN && (
        <AddVinNumberDialog
          onToggleClick={
            showToggleToLicensePlateDialogButton ? handleToggleModal : undefined
          }
          onClose={handleCloseDialog}
          isLoading={combinedIsLoading}
          vin={vin}
          handleVinChange={handleVinChange}
        />
      )}
    </>
  )
}

CarfileRequestBmsDataButton.propTypes = {
  ...loadingButtonPropTypes,
  carfile: PropTypes.object,
}

CarfileRequestBmsDataButton.defaultProps = {
  carfile: {},
  rounding: 0,
}

export default withApolloV4Provider(CarfileRequestBmsDataButton)
