import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const Container = styled.label`
  cursor: pointer;
  display: inline-block;
  height: ${({ ratio }) => (ratio ? 0 : 100)}%;
  overflow: hidden;
  padding-top: ${({ ratio }) => (ratio ? 100 / ratio : 0)}%;
  position: relative;
  vertical-align: bottom;
  width: 100%;
`

const BlurredBackground = styled.img`
  height: 110%;
  filter: blur(5px);
  left: -5%;
  object-fit: cover;
  opacity: 0.75;
  position: absolute;
  top: -5%;
  width: 110%;
`

const StyledImg = styled.img`
  height: 100%;
  left: 50%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  // Max width because uploading images with the custom camera
  // solution / view results in images with a width of 1024px
  // and we don't want to make them appear pixelated
  max-width: 1024px;
  transform: translate(-50%, -50%);
  width: 100%;
`

function AspectRatioImage({
  alt,
  className,
  onError,
  ratio,
  src,
  blurredBackground,
  ...restProps
}) {
  return (
    <Container className={className} ratio={ratio} {...restProps}>
      {blurredBackground && (
        <BlurredBackground src={src} data-test-e2e="blurred-background" />
      )}
      <StyledImg alt={alt} onError={onError} src={src} data-test-e2e="image" />
    </Container>
  )
}

AspectRatioImage.propTypes = {
  alt: PropTypes.string.isRequired,
  blurredBackground: PropTypes.bool,
  className: PropTypes.string,
  onError: PropTypes.func,
  ratio: PropTypes.number,
  src: PropTypes.string.isRequired,
}

AspectRatioImage.defaultProps = {
  blurredBackground: false,
  className: null,
  onError: null,
  ratio: undefined,
}

export default AspectRatioImage
