import { connect } from 'react-redux'
import { addCarComment } from 'redux/actions/data'
import { getForms } from 'redux/actions/forms'
import { closeOverlay } from 'redux/actions/ui'
import Overlays from '../organisms/overlays'

const mapStateToProps = ({ data, forms }) => ({
  forms,
  todos: data.todos,
})

const mapDispatchToProps = {
  addCarComment,
  closeOverlay,
  getForms,
}

export default connect(mapStateToProps, mapDispatchToProps)(Overlays)
