import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import EnhancedTable, {
  TableDataText,
} from 'components/organisms/enhanced-table'
import Label from 'components/atoms/label'
import Button from 'components/atoms/button'

const StyledTableDataText = styled(TableDataText)`
  font-weight: 400;
  margin-top: ${({ theme }) => theme.sizings.lvl0};
`

const ActionsContainer = styled.div`
  display: inline-flex;
  position: relative;
  top: 2px;
`

const InvoicesTable = ({ rows }) => {
  const { t } = useTranslation()

  const formattedRows = rows.map((row) => ({
    dealer: {
      component: <StyledTableDataText>{row.dealer}</StyledTableDataText>,
      data: '',
    },
    dealerId: {
      component: <StyledTableDataText>{row.dealerId}</StyledTableDataText>,
      data: '',
    },
    address: {
      component: <StyledTableDataText>{row.address}</StyledTableDataText>,
      data: '',
    },
    city: {
      component: <StyledTableDataText>{row.city}</StyledTableDataText>,
      data: '',
    },
    date: {
      component: <StyledTableDataText>{row.date}</StyledTableDataText>,
      data: '',
    },
    status: {
      component: (
        <StyledTableDataText>
          <Label
            text={row.status.label}
            backgroundColor={row.status.color}
            size="small"
          />
        </StyledTableDataText>
      ),
      data: '',
    },
    actions: {
      component: <ActionsContainer>{row.actions}</ActionsContainer>,
      data: '',
    },
  }))

  return (
    <EnhancedTable
      selectable={false}
      columns={[
        {
          id: 'dealer',
          label: t('carServicePlanInvoicesTable.dealer'),
        },
        {
          id: 'dealerId',
          label: t('carServicePlanInvoicesTable.dealerId'),
        },
        {
          id: 'address',
          label: t('carServicePlanInvoicesTable.address'),
        },
        {
          id: 'city',
          label: t('carServicePlanInvoicesTable.city'),
        },
        {
          id: 'date',
          label: t('carServicePlanInvoicesTable.invoiceDate'),
        },
        {
          id: 'status',
          label: t('carServicePlanInvoicesTable.status'),
        },
        {
          id: 'actions',
          label: t('carServicePlanInvoicesTable.actions'),
          alignRight: true,
        },
      ]}
      rows={formattedRows}
    />
  )
}

InvoicesTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      dealer: PropTypes.string.isRequired,
      dealerId: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      status: PropTypes.shape({
        label: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      }).isRequired,
      actions: PropTypes.node.isRequired,
    }),
  ).isRequired,
}

InvoicesTable.defaultProps = {}

export default InvoicesTable

const StyledButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  width: auto;
  height: auto;
  display: inline-flex;

  &[disabled] {
    opacity: 0.3;
    * {
      fill: ${({ theme }) => theme.colors.actionsStandard};
    }

    &:hover,
    &:focus {
      & * {
        fill: ${({ theme }) => theme.colors.actionsStandard};
      }
    }
  }
`

export const ActionIconButton = ({ icon, width, height, ...restProps }) => (
  <StyledButton
    level="option"
    icon={icon}
    iconWidth={width || '22'}
    iconHeight={height || '22'}
    iconColor="actionsStandard"
    {...restProps}
  />
)

ActionIconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
}

ActionIconButton.defaultProps = {
  width: '22',
  height: '22',
}
