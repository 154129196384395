import { css } from 'styled-components'
import { createMuiTheme } from '@material-ui/core/styles'
import { nlNL } from '@material-ui/core/locale'
import { themes } from 'config'

const sizes = themes.ucc.metrics

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em), print {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

export function getMuiTheme(theme) {
  return createMuiTheme(
    {
      overrides: {
        MuiInput: {
          input: {
            '&::placeholder': {
              color: theme.colors.inputPlaceholder,
              opacity: 1,
            },
          },
          underline: {
            '&:before': {
              borderBottom: `1px solid ${theme.colors.inputLabel}`,
            },
            '&:hover:not($disabled):before': {
              borderBottom: `1px solid ${theme.colors.brandBravo}`,
            },
            '&$disabled:before': {
              borderBottom: 'none',
              borderBottomStyle: 'none',
            },
          },
        },
        MuiInputLabel: {
          root: {
            '&::first-letter': {
              textTransform: 'uppercase',
            },
          },
          filled: {
            '&$shrink': {
              transform: 'translate(12px, 10px) scale(0.86)',
            },
            '&$error': {
              color: theme.colors.inputPlaceholder,
            },
          },
          shrink: {
            transform: 'translate(0, 1.5px) scale(0.86)',
            transformOrigin: 'top left',
          },
        },
        MuiInputAdornment: {
          root: {
            flex: '0 0 auto',
          },
        },
        MuiFormLabel: {
          root: {
            '&$focused': {
              color: theme.colors.inputPlaceholder,
            },
          },
        },
        MuiFormControlLabel: {
          // root: {
          //   marginLeft: 0,
          // },
          label: {
            display: 'block',
            width: '100%',
          },
        },
        MuiFilledInput: {
          root: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: theme.colors.fieldBackground,
            '&:hover': {
              backgroundColor: theme.colors.isolatedBackground,
            },
            '&$focused': {
              backgroundColor: theme.colors.brandDelta,
            },
            '&$disabled': {
              backgroundColor: theme.colors.disabledInputBackground,
            },
            '&$disabled:hover': {
              backgroundColor: theme.colors.disabledInputBackground,
            },
            '&$error': {
              backgroundColor: theme.colors.errorInputBackground,
            },
          },
          input: {
            '&::placeholder': {
              color: theme.colors.inputPlaceholder,
              opacity: 1,
            },
          },
          underline: {
            '&:before': {
              borderBottom: `1px solid ${theme.colors.inputLabel}`,
            },
            '&:hover:not($disabled):before': {
              borderBottom: `1px solid ${theme.colors.brandBravo}`,
            },
            '&$disabled:before': {
              borderBottom: 'none',
              borderBottomStyle: 'none',
            },
          },
        },
        MuiCheckbox: {
          root: {
            color: theme.colors.text,
          },
        },
        MuiTypography: {
          root: {
            color: theme.colors.text,
          },
        },
      },
      palette: {
        primary: {
          main: theme.colors.brandAlfa,
        },
        secondary: {
          main: theme.colors.brandAlfa,
        },
        text: {
          primary: theme.colors.text,
          secondary: theme.colors.inputPlaceholder,
          disabled: theme.colors.textTonedDown,
          hint: theme.colors.brandAlfa,
        },
      },
      typography: {
        fontFamily: theme.font,
        fontSize: 12,
      },
    },
    nlNL,
  )
}
