import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableFooter from '@material-ui/core/TableFooter'
import Toolbar from '@material-ui/core/Toolbar'
import Checkbox from '@material-ui/core/Checkbox'

import { convertTableRowsToObject } from 'utilities/utils'
import { media } from 'utilities/styled'
import { formatDataTestE2eAttr } from 'utilities/format'
import marketviewData from 'utilities/marktview-data'

import Tooltip from 'components/atoms/new-tooltip'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import TextLink from 'components/atoms/text-link'
import ContentSeparator from 'components/atoms/content-separator'
import Paging from 'components/molecules/paging'
import StaticValuesCollection from 'components/molecules/static-values-collection'
import ExpandButton from 'components/molecules/expand-button'
import Typography from 'components/molecules/typography'

import useAbortController from 'hooks/use-abort-controller'

const Container = styled.div`
  width: 100%;
`

const StyledToolbar = styled(Toolbar)`
  background: ${({ theme }) => theme.colors.brandAlfa};
  justify-content: space-between;
  min-height: 0;
  height: ${({ theme }) => theme.sizings.lvl4};
`

const StyledTableHead = styled(TableHead)`
  th {
    border-top: solid 1px ${({ theme }) => theme.colors.inactiveBackground};
    vertical-align: top;
  }

  /**Overrule material UI's styling to make icon more accessible */
  .MuiTableSortLabel-root {
    display: inline-flex;
    align-items: flex-start;
  }
  .MuiTableSortLabel-icon {
    opacity: 0.4;
  }
`

const StyledTooltip = styled(Tooltip)`
  margin-left: ${({ theme }) => theme.sizings.lvl1};
  vertical-align: top;
`

const StyledTableRow = styled(TableRow)`
  background-color: ${({ expanded, theme }) =>
    expanded ? theme.colors.fieldBackground : ''};
  cursor: ${(props) => (props.expandable ? 'pointer' : 'initial')};
  td {
    border-top: solid 1px ${({ theme }) => theme.colors.inactiveBackground};
  }
  &.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: ${({ theme }) => theme.colors.fieldBackground};
  }
`

const StyledTableHeadCell = styled(TableCell)`
  padding-bottom: 0;
  padding-top: 0;

  ${({ $width }) => {
    if (typeof $width !== 'undefined') {
      return `width: ${typeof $width === 'number' ? `${$width}%` : $width};`
    }
  }}

  border-right: ${({ theme, $verticalCellBorders }) =>
    $verticalCellBorders
      ? `solid 1px ${theme.colors.inactiveBackground}`
      : 'none'};
  &:last-child {
    border-right: none;
  }

  &.MuiTableCell-root {
    padding: ${({ theme }) => theme.sizings.lvl1};
  }
`

const StyledTable = styled(Table)`
  min-width: 750px;
`

const StyledTableCell = styled(TableCell)`
  vertical-align: ${({ $alignCellContent }) => $alignCellContent || 'top'};

  border-right: ${({ theme, $verticalCellBorders }) =>
    $verticalCellBorders
      ? `solid 1px ${theme.colors.inactiveBackground}`
      : 'none'};
  &:last-child {
    border-right: none;
  }

  &.MuiTableCell-root {
    padding: ${({ theme }) => theme.sizings.lvl1};
  }
`

const FooterTableCell = styled(TableCell)`
  vertical-align: top;

  &.MuiTableCell-root {
    padding: ${({ theme }) => theme.sizings.lvl1};
    color: inherit;
    font-size: ${({ theme }) => theme.baseFontSize};
    font-family: work-sans;
    font-weight: 500;
    text-transform: uppercase;
  }
`

const StyledFooterTableRow = styled(TableRow)`
  background-color: ${({ $isPrimaryRow, theme }) =>
    $isPrimaryRow ? theme.colors.darkBackground : 'inherit'};
  color: ${({ $isPrimaryRow, theme }) =>
    $isPrimaryRow ? theme.colors.textOnDarkBackground : 'inherit'};

  &::last-child {
    border-bottom: solid 1px ${({ theme }) => theme.colors.inactiveBackground};
  }
`

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`

const ExpandedRowCell = styled.td`
  background-color: ${({ theme }) => theme.colors.fieldBackground};
  border-bottom: solid 1px ${({ theme }) => theme.colors.inactiveBackground};
  padding-top: ${({ theme }) => theme.sizings.lvl1};
  padding-bottom: ${({ theme }) => theme.sizings.lvl1};
  padding-left: ${({ theme }) => theme.sizings.lvl2};
  padding-right: ${({ theme }) => theme.sizings.lvl2};
  ${media.tablet`
    padding: ${({ theme }) => theme.sizings.lvl3};
    padding-top: ${({ theme }) => theme.sizings.lvl2};
    padding-bottom: ${({ theme }) => theme.sizings.lvl2};
  `};
  ${media.desktop`
    padding: ${({ theme }) => theme.sizings.lvl5};
    padding-top: ${({ theme }) => theme.sizings.lvl4};
    padding-bottom: ${({ theme }) => theme.sizings.lvl4};
  `};
`
const ExpandIconCell = styled(StyledTableCell)`
  width: 42px;
`
const ExpandIconContainer = styled.div`
  display: inline-block;
  padding: ${({ theme }) => theme.sizings.lvl1};
`

const StyledPaging = styled(Paging)`
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`

const DownloadAllLink = styled(TextLink)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

const ShowMoreButtonLink = styled(Button)`
  margin-top: ${({ theme }) => theme.sizings.lvl1};
`

const TableDataText = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.font};
  font-size: ${({ theme }) => theme.baseFontSize};
  font-weight: 500;
  display: inline-block;
  line-height: 1.5em;
`

const FooterTableRow = ({ isPrimaryRow, ...restProps }) => (
  <StyledFooterTableRow $isPrimaryRow={isPrimaryRow} {...restProps} />
)

FooterTableRow.propTypes = {
  isPrimaryRow: PropTypes.bool,
}

FooterTableRow.defaultProps = {
  isPrimaryRow: false,
}

function EnhancedTableHead(props) {
  const {
    columns,
    expandable,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onOrder,
    selectable,
    verticalCellBorders,
  } = props
  const createOrderHandler = (property) => (event) => {
    onOrder(event, property)
  }

  return (
    <StyledTableHead>
      <TableRow>
        {selectable && (
          <StyledTableCell $verticalCellBorders={verticalCellBorders}>
            <StyledCheckbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
              size="small"
            />
          </StyledTableCell>
        )}
        {columns.map((column) => {
          const tableHeaderTooltip = column.tooltip ? (
            <StyledTooltip title={column.tooltip} arrow placement="left">
              <Icon type="info" size="md" color="actionsStandard" />
            </StyledTooltip>
          ) : (
            <></>
          )
          return (
            <StyledTableHeadCell
              key={column.id}
              align={
                column.numeric || column.alignRight
                  ? 'right'
                  : column.alignCenter
                    ? 'center'
                    : 'left'
              }
              sortDirection={orderBy === column.orderId ? order : false}
              $width={column.width}
              $verticalCellBorders={verticalCellBorders}
            >
              {onOrder && column.orderId ? (
                <TableSortLabel
                  active={orderBy === column.orderId}
                  direction={orderBy === column.orderId ? order : 'asc'}
                  onClick={createOrderHandler(column.orderId)}
                >
                  <Text type="floatingLabelLabel">
                    {column.component || column.label}
                  </Text>
                  {tableHeaderTooltip}
                </TableSortLabel>
              ) : (
                <>
                  <Text type="floatingLabelLabel">
                    {column.component || column.label}
                  </Text>

                  {tableHeaderTooltip}
                </>
              )}
            </StyledTableHeadCell>
          )
        })}
        {expandable && <StyledTableHeadCell />}
      </TableRow>
    </StyledTableHead>
  )
}

EnhancedTableHead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  expandable: PropTypes.bool,
  selectable: PropTypes.bool,
  numSelected: PropTypes.number.isRequired,
  onOrder: PropTypes.func,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
  verticalCellBorders: PropTypes.bool,
}

EnhancedTableHead.defaultProps = {
  expandable: false,
  selectable: true,
  onOrder: null,
  order: null,
  orderBy: null,
  verticalCellBorders: false,
}

const EnhancedToolbarSlot = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.sizings.lvl3};
  align-items: center;
`

export const EnhancedToolbarLink = styled(TextLink)`
  text-decoration: underline;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.actionBarItem};
  }
`

EnhancedToolbarLink.defaultProps = {
  color: 'brandGolf',
}

const EnhancedTableToolbar = ({
  numSelected,
  onClickDownload,
  selectedText,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <StyledToolbar>
      <Text color="brandGolf" text={`${numSelected} ${selectedText}`} />
      <EnhancedToolbarSlot>
        {onClickDownload && (
          <Tooltip title="Download">
            <EnhancedToolbarLink
              aria-label="download"
              onClick={onClickDownload}
              text={t('downloadSelection', { extension: '.xls' })}
            />
          </Tooltip>
        )}
        {children}
      </EnhancedToolbarSlot>
    </StyledToolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onClickDownload: PropTypes.func,
  selectedText: PropTypes.string.isRequired,
  children: PropTypes.node,
}

EnhancedTableToolbar.defaultProps = {
  onClickDownload: null,
}

/**
 * A table in which the rows can be ordered and selected, using Material-UI's
 * components derived from the [example](https://material-ui.com/components/tables/#sorting-amp-selecting).
 *

 */

function EnhancedTable({
  className,
  columns,
  ExpandedRow,
  selectable,
  alignCellContent,
  onSelect,
  onChangePage,
  onDownloadAll,
  onOrder,
  orderBy,
  orderDirection,
  page,
  resultsPerPage,
  totalResults,
  rows,
  selectedText,
  noDataMessage,
  clearSelectionOnUpdate,
  onMobileSwitchToValueCollection,
  hideTableHead,
  verticalCellBorders,
  customTableFooter,
  hasStickyHeader,
  selectedToolbarSlot,
  ...restProps
}) {
  const theme = useTheme()
  const screenSizeAboveMd = useMediaQuery(theme.breakpoints.up('md'))
  const columnIds = columns.map((column) => column.id)
  const [selected, setSelected] = React.useState([])
  const [showMore, setShowMore] = useState(false)
  const [expandedRowIndex, setExpandedRowIndex] = React.useState()
  const { t } = useTranslation()
  const token = useSelector(({ auth }) => auth?.instanceId)
  const {
    ref: createExcelFileAbortControllerRef,
    abort: abortCreateExcelFileRequest,
  } = useAbortController()

  const parsedDataMessage = noDataMessage || t('noDataCanBeShown')

  function handleOrder(event, property) {
    const isAsc = orderBy === property && orderDirection === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'

    if (onOrder) {
      onOrder(newOrder, property)
    }
  }

  function handleClickSelectAll(event) {
    if (event.target.checked) {
      const newSelected = rows.map((row, index) => index)

      if (onSelect) {
        onSelect(newSelected)
      }
      setSelected(newSelected)
      return
    }

    if (onSelect) {
      onSelect([])
    }

    setSelected([])
  }

  function handleClickSelect(event, index) {
    event.stopPropagation()

    const selectedIndex = selected.indexOf(index)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    if (onSelect) {
      onSelect(newSelected)
    }
    setSelected(newSelected)
  }

  function handleClickRow(index) {
    if (expandedRowIndex === index) {
      setExpandedRowIndex(null)
    } else {
      setExpandedRowIndex(index)
    }
  }

  function handleClickDownload() {
    const selectedRows = rows.filter((_, index) => selected.includes(index))

    abortCreateExcelFileRequest()

    marketviewData.getMarketviewExport({
      type: 'createExcelFile',
      token,
      body: {
        selected_data: convertTableRowsToObject({
          rows: selectedRows,
          columns,
        }),
      },
      signal: createExcelFileAbortControllerRef.current.signal,
    })
  }

  function handleChangePage(newPage) {
    setSelected([])
    onChangePage(newPage)
  }

  // Reset selection when a new set of rows comes into the component:
  React.useEffect(() => {
    if (clearSelectionOnUpdate) {
      setSelected([])
    }
  }, [clearSelectionOnUpdate, rows])

  const isSelected = (index) => selected.indexOf(index) !== -1
  const expandable = !!ExpandedRow
  const columnCount = expandable ? columnIds.length + 2 : columnIds.length + 1

  if (onMobileSwitchToValueCollection && !screenSizeAboveMd) {
    let collections = rows.map((row) =>
      Object.keys(row)
        .filter((key) => columns.some((column) => column.id === key))
        .map((key) => ({
          value: row[key].component,
          title: columns.find((column) => column.id === key).label,
        })),
    )
    if (!showMore) {
      collections = collections.slice(0, 3)
    }
    return (
      <>
        {collections.map((collection, index) => (
          <div key={`staticValueCollection_${index.toString()}`}>
            <StaticValuesCollection collection={collection} />
            {index !== collections.length - 1 && (
              <ContentSeparator paddingLevel={2} />
            )}
          </div>
        ))}
        {!showMore && collections.length > 2 && (
          <ShowMoreButtonLink
            level="option"
            onClick={() => setShowMore(true)}
            text={t('showMore')}
            noPadding
          />
        )}
      </>
    )
  }

  return (
    <Container className={className} {...restProps}>
      {rows.length ? (
        <>
          {onDownloadAll && (
            <DownloadAllLink
              onClick={onDownloadAll}
              text={t('downloadCompleteTable')}
            />
          )}
          {selected.length > 0 ? (
            <EnhancedTableToolbar
              numSelected={selected.length}
              onClickDownload={
                !selectedToolbarSlot ? handleClickDownload : undefined
              }
              selectedText={selectedText}
            >
              {selectedToolbarSlot}
            </EnhancedTableToolbar>
          ) : null}
          <TableContainer
            style={{
              maxHeight: hasStickyHeader ? 'inherit' : 'initial',
            }}
          >
            <StyledTable
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              stickyHeader={hasStickyHeader}
            >
              {!hideTableHead && (
                <EnhancedTableHead
                  columns={columns}
                  numSelected={selected.length}
                  order={orderDirection}
                  orderBy={orderBy}
                  onSelectAllClick={handleClickSelectAll}
                  onOrder={onOrder && handleOrder}
                  rowCount={rows.length}
                  expandable={expandable}
                  selectable={selectable}
                  verticalCellBorders={verticalCellBorders}
                />
              )}
              <>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(index)
                  const labelId = `enhanced-table-checkbox-${index}`
                  const expanded = expandedRowIndex === index

                  return (
                    <tbody key={index.toString()}>
                      <StyledTableRow
                        expandable={expandable ? 1 : 0}
                        expanded={expanded ? 1 : 0}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index.toString()}
                        selected={isItemSelected}
                        onClick={
                          expandable ? () => handleClickRow(index) : null
                        }
                        hover
                        {...row._attrs}
                      >
                        {selectable && (
                          <StyledTableCell
                            $verticalCellBorders={verticalCellBorders}
                            $alignCellContent={alignCellContent}
                          >
                            <StyledCheckbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) =>
                                handleClickSelect(event, index)
                              }
                              size="small"
                            />
                          </StyledTableCell>
                        )}
                        {columns.map(({ id, alignRight }) => (
                          <StyledTableCell
                            key={id}
                            align={alignRight ? 'right' : 'left'}
                            $verticalCellBorders={verticalCellBorders}
                            $alignCellContent={alignCellContent}
                            data-test-e2e={formatDataTestE2eAttr(
                              `table-cell-${id}`,
                            )}
                          >
                            {row[id] && (
                              <>
                                {typeof row[id].component === 'string' ||
                                typeof row[id].component === 'number' ? (
                                  <TableDataText>
                                    {row[id].component}
                                  </TableDataText>
                                ) : (
                                  row[id].component
                                )}
                              </>
                            )}
                          </StyledTableCell>
                        ))}
                        {expandable && (
                          <ExpandIconCell>
                            <ExpandIconContainer>
                              <ExpandButton isExpanded={expanded} />
                            </ExpandIconContainer>
                          </ExpandIconCell>
                        )}
                      </StyledTableRow>
                      {expanded && (
                        <tr>
                          <ExpandedRowCell colSpan={columnCount}>
                            <ExpandedRow
                              data={row}
                              orderBy={orderBy}
                              orderDirection={orderDirection}
                              page={page}
                              resultsPerPage={resultsPerPage}
                            />
                          </ExpandedRowCell>
                        </tr>
                      )}
                    </tbody>
                  )
                })}
              </>
              {customTableFooter && (
                <TableFooter>{customTableFooter}</TableFooter>
              )}
            </StyledTable>
          </TableContainer>
          {onChangePage &&
            resultsPerPage &&
            totalResults &&
            totalResults > resultsPerPage && (
              <StyledPaging
                activePage={page}
                onChangePage={handleChangePage}
                perPage={resultsPerPage}
                total={totalResults}
              />
            )}
        </>
      ) : (
        <Typography type="ExplanationParagraph">{parsedDataMessage}</Typography>
      )}
    </Container>
  )
}

export const propTypes = {
  /** Alignment of content in the table body cells */
  alignCellContent: PropTypes.oneOf(['top', 'middle', 'bottom']),
  className: PropTypes.string,
  /** The columns of the table. The order of the columns in this
   * prop dictate the order of the columns for the whole component */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      alignRight: PropTypes.bool,
      /* The width of this column. This will be set in CSS as a value on the column.
       * This can be defined as a number, or a string. A numberic value will be
       * treated as a percentage, while strings will be used as-is. Examples:
       * 20: will be used as "width: 20%;"
       * "100px": will be used as: "width: 100px;"
       */
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  /** The component to be rendered inside an expanded row which will
   * get the data of the row passed in its data prop. If passed, the row
   * implicitly becomes expandable */
  ExpandedRow: PropTypes.func,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      /** For every column a `component` and `data` prop holding a
       * React component to be rendered in the column and a string
       * with data for ordering purposes. */
      columnId: PropTypes.shape({
        component: PropTypes.node.isRequired,
        data: PropTypes.string.isRequired,
      }),
      /** attrs are applied to the <tr> element directly.
       * This can be useful to apply `data-test-e2e` attributes. */
      _attrs: PropTypes.object,
    }),
  ).isRequired,
  /** Event handler for changing the page */
  onChangePage: PropTypes.func,
  /** Handler for the link to download all results of the table */
  onDownloadAll: PropTypes.func,
  /** Event handler called when a row is selected with the newly selected
   * row indices */
  onSelect: PropTypes.func,
  /** Event handler called when a ordering a column */
  onOrder: PropTypes.func,
  /** The column name of which the data is ordered by */
  orderBy: PropTypes.string,
  /** The direction of which the data is ordered */
  orderDirection: PropTypes.oneOf(['asc', 'desc']),
  page: PropTypes.number,
  resultsPerPage: PropTypes.number,
  /** The text that will be displayed in the `Toolbar` after the
   * number of selected rows. */
  selectedText: PropTypes.string,
  // Use this to set your own custom actions in the toolbar if one or more rows are selected
  selectedToolbarSlot: PropTypes.node,
  totalResults: PropTypes.number,
  /** The message to show when no data was found */
  noDataMessage: PropTypes.string,
  clearSelectionOnUpdate: PropTypes.bool,
  onMobileSwitchToValueCollection: PropTypes.bool,
  selectable: PropTypes.bool,
  hideTableHead: PropTypes.bool,
  /** Shows vertical borders inbetween cells */
  verticalCellBorders: PropTypes.bool,
  /**
   * provide a custom Table footer (see example)
   */
  customTableFooter: PropTypes.node,
  /**
   * Useful for displaying row content in a restricted area.
   * When set to `true` the EnhancedTable's
   * root element needs to have a max-height specified.
   * Otherwise it does not work.
   */
  hasStickyHeader: PropTypes.bool,
}

EnhancedTable.propTypes = propTypes

EnhancedTable.defaultProps = {
  alignCellContent: 'top',
  className: null,
  ExpandedRow: null,
  onChangePage: null,
  onDownloadAll: null,
  onSelect: null,
  onOrder: null,
  orderBy: null,
  orderDirection: null,
  page: null,
  resultsPerPage: null,
  selectedText: '',
  totalResults: null,
  clearSelectionOnUpdate: true,
  onMobileSwitchToValueCollection: false,
  selectable: true,
  hideTableHead: false,
  noDataMessage: '',
  verticalCellBorders: false,
  customTableFooter: null,
  hasStickyHeader: false,
}

export default EnhancedTable
export { TableDataText, FooterTableRow, FooterTableCell }
