import { gql } from '@apollo/client'
import brandInfoFragment from './brand'
import modelInfoFragment from './model'

export default gql`
  fragment warrantyPriceRuleInfo on WarrantyPriceRule {
    id
    warrantyProgramId
    brand {
      ...brandInfo
    }
    model {
      ...modelInfo
    }
    ageFrom
    ageTo
    mileageFrom
    mileageTo
    duration
    priceForDuration
    engineCapacityFrom
    engineCapacityTo
    kilowattFrom
    kilowattTo
  }
  ${brandInfoFragment}
  ${modelInfoFragment}
`
