import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/atoms/link'
import Text from 'components/atoms/text'

/**
 * Links that are rendered as text with the default text-link styling.
 *
 * It accepts either an 'href' prop for external links, 'to' prop for internal
 * links or an 'onClick' prop for click events. These props are mutually exclusive.
 *

 */
function TextLink({
  className,
  color,
  disabled,
  href,
  onClick,
  text,
  to,
  type,
  ...restProps
}) {
  const handleClick = (e) => {
    if (!disabled && onClick) {
      onClick(e)
    }
  }

  return (
    <Link
      className={className}
      disabled={disabled}
      href={href}
      onClick={handleClick}
      to={to}
      color={color}
      {...restProps}
    >
      {type ? <Text text={text} type={type} weight={400} /> : text}
    </Link>
  )
}

TextLink.propTypes = {
  className: PropTypes.string,
  /** Color of the text */
  color: PropTypes.string,
  /** Boolean to disable the link */
  disabled: PropTypes.bool,
  /** Link to an external source that will be opened in a new tab */
  href: PropTypes.string,
  /** Click handler */
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  /** Link to an internal source */
  to: PropTypes.string,
  /** Overrides the default text-link styling if specified */
  type: PropTypes.string,
}

TextLink.defaultProps = {
  className: null,
  color: 'actionsStandard',
  disabled: false,
  href: null,
  onClick: null,
  to: null,
  type: undefined,
}

export default TextLink
