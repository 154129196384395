import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import { marketViewFilterIds } from 'config/data'
import {
  getYearOptions,
  handleChangeQuery,
  handleChangeQueries,
} from 'utilities/utils'
import { InputParamsContext, ParamOptionsContext } from 'contexts/marketview'
import NewDropdownSelect from 'components/molecules/new-dropdown-select'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.filters.length}, 1fr);
  grid-column-gap: ${({ theme }) => theme.sizings.lvl3};
`

/**
 * A combination of dropdown selectors to filter tables in Marketview
 * on make, fuel and build year. This controls the main filters of
 * the Marketview trough the apps URL.
 *

 */
function MarketviewTableFilters({ className }) {
  const paramOptions = useContext(ParamOptionsContext)
  const inputParams = useContext(InputParamsContext)
  const currentYear = moment().year()

  function getSelectedValue(id) {
    const inputParam = inputParams.find((param) => param.key === id)
    const selectedValue = inputParam ? inputParam.value : ''

    // By default set the builYearTo to buildYearFrom value if no value is set yet
    // for buildYearTo
    // if (id === marketViewFilterIds.buildYearTo) {
    //   const fromYearValue = getSelectedValue(marketViewFilterIds.buildYearFrom);
    //   if (getSelectedValue(marketViewFilterIds.buildYearFrom) && !selectedValue) {
    //     return currentYear;
    //   }
    // }

    return selectedValue
  }

  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const maxYearsInThePast = 100
  const initYearFrom = getSelectedValue(marketViewFilterIds.buildYearFrom)
  const [toYearOptions, setToYearOptions] = useState(
    getYearOptions(
      initYearFrom || currentYear - maxYearsInThePast,
      currentYear,
    ),
  )
  let filters = paramOptions.filter(
    (option) =>
      option.id === 'make' || option.id === 'fuel' || option.id === 'buildYear',
  )
  filters = [
    ...filters,
    {
      label: t('fromBuildYear'),
      id: marketViewFilterIds.buildYearFrom,
      items: getYearOptions(currentYear - maxYearsInThePast, currentYear),
    },
    {
      label: t('toBuildYear'),
      id: marketViewFilterIds.buildYearTo,
      items: toYearOptions,
    },
  ]

  function handleChangeFilters(filterId, value) {
    if (filterId === marketViewFilterIds.buildYearFrom) {
      if (!value) {
        setToYearOptions(
          getYearOptions(currentYear - maxYearsInThePast, currentYear),
        )
      } else {
        setToYearOptions(getYearOptions(value, currentYear))
      }
    }

    if (filterId === marketViewFilterIds.buildYearFrom) {
      let toValue = getSelectedValue(marketViewFilterIds.buildYearTo)
      if (toValue) {
        // make sure that fromYear is never above toYear
        toValue = value > toValue ? null : toValue
      } else {
        // set currentYear as a fallback for buildYearTo, otherwise the endpoint fails:
        toValue = currentYear
      }
      handleChangeQueries(
        location,
        history,
        [filterId, marketViewFilterIds.buildYearTo],
        [value, toValue],
      )
    } else if (filterId === marketViewFilterIds.buildYearTo) {
      handleChangeQueries(
        location,
        history,
        [filterId, marketViewFilterIds.buildYearFrom],
        [value, getSelectedValue(marketViewFilterIds.buildYearFrom)],
      )
    } else {
      handleChangeQuery(location, history, filterId, value)
    }
  }

  useEffect(() => {
    const toYearIndex = filters.findIndex(
      (item) => item.id === marketViewFilterIds.buildYearTo,
    )
    filters[toYearIndex].items = toYearOptions
  }, [filters, toYearOptions])

  return (
    <Container className={className} filters={filters}>
      {filters.map((filter, index) => (
        <NewDropdownSelect
          key={index.toString()}
          items={filter.items || []}
          onChange={(value) => handleChangeFilters(filter.id, value)}
          label={filter.label}
          value={getSelectedValue(filter.id)}
        />
      ))}
    </Container>
  )
}

MarketviewTableFilters.propTypes = {
  className: PropTypes.string,
}

MarketviewTableFilters.defaultProps = {
  className: undefined,
}

export default MarketviewTableFilters
