import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components/macro'
import { CAR_FILE, CSP_CONTRACT_FILE, CSP_SALES_FLOW } from 'config/routes'
import { GET_CONTRACTS } from 'config/graphql/csp'
import Typography from 'components/molecules/typography'
import NewTextInput from 'components/atoms/new-text-input'
import NewDropDownSelect from 'components/molecules/new-dropdown-select'
import Icon from 'components/atoms/icon'
import ContractsTable from 'components/views/car-service-plan/contracts-table'
import Button from 'components/atoms/button'
import { useTranslation } from 'react-i18next'
import LoadingIndicator from 'components/atoms/loading-indicator'
import {
  contractStatuses,
  contractStatusColors,
  getContractStatusLabels,
  carServicePlanApi,
} from 'config/data'
import { formatDate } from 'utilities/format'
import storage from 'utilities/storage'
import { JWT_STORAGEID } from 'redux/reducers/auth'
import { media } from 'utilities/styled'
import ChangeContractStatusDialog from './dialogs/change-contract-status-dialog'
import { useSelector } from 'react-redux'
import ActionIconButton from 'components/atoms/action-icon-button'
import { CSPDashboardContext } from 'contexts/csp-dashboard'

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};

  ${media.tv`
    flex-wrap: nowrap;
  `}
`

const HeadingContainer = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};

  ${media.tv`
    width: auto;
    margin-right: auto;
    margin-bottom: 0;
  `}
`

const Heading = styled(Typography)`
  margin-top: 0;
  margin-right: 0;
  width: 100%;
`

const ExplanationParagraph = styled(Typography)`
  margin-bottom: 0;
`

const FiltersContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
  gap: ${({ theme }) => theme.sizings.lvl1};

  ${media.tablet`
    align-items: center;
  `}

  ${media.desktop`
    flex-wrap: nowrap;
    gap: ${({ theme }) => theme.sizings.lvl2};
  `}

  ${media.tv`
    width: auto;
  `}
`

const ContractStatusSelect = styled(NewDropDownSelect)`
  width: 100%;

  ${media.tablet`
    width: auto;
    min-width: 200px;
    margin-bottom: 0;
  `}

  ${media.tv`
    min-width: 150px;
  `}

  ${media.hd`
    min-width: 200px;
  `}
`

const SearchQueryInput = styled(NewTextInput)`
  width: 100%;

  ${media.tablet`
    width: auto;
    min-width: 225px;
  `}

  ${media.hd`
    min-width: 300px;
  `}
`

const ExportButton = styled(Button)`
  ${media.tablet`
    margin-left: auto;
  `}

  ${media.tv`
    order: -1;
  `}
`

const Line = styled.span`
  display: block;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.brandDelta};
  margin: 0 ${({ theme }) => theme.sizeByFactor(1.5)};
  height: 24px;
`

const {
  ALL,
  ALL_EXCLUDING_CANCELED,
  ACTIVE,
  EXPIRED,
  CANCELED,
  STOPPED,
  UNSIGNED,
  INCOMPLETE,
  INCOMPLETE_AND_UNSIGNED,
  ACTIVE_BUT_CHANGES_PENDING,
} = contractStatuses

const ContractsOverview = (props) => {
  const { t } = useTranslation()
  const [cancelContract, setCancelContract] = useState()
  const userIsAdmin = useSelector((state) => !!state.auth?.userDetails?.admin)
  const jwt = storage.getValue(JWT_STORAGEID, undefined)
  const exportUrl =
    carServicePlanApi && jwt
      ? `${new URL(carServicePlanApi).origin}/export/contracts?token=${jwt}`
      : undefined

  const {
    contractStatusFilter: statusFilter,
    setContractStatusFilter: setStatusFilter,
  } = React.useContext(CSPDashboardContext)
  const [searchQuery, setSearchQuery] = React.useState('')

  const { loading, error, data } = useQuery(GET_CONTRACTS)
  if (loading) {
    return <LoadingIndicator error={null} size="small" />
  }
  if (error) {
    console.error(error)
    return (
      <>
        <Typography type="Level2Heading">
          {t('problemsFoundHeading')}
        </Typography>
        <Typography type="ExplanationParagraph">
          {t('problemsWhenRetrievingData')}
        </Typography>
      </>
    )
  }

  const contractStatusLabels = getContractStatusLabels(t)

  // Setup the values for the contract status dropdown filter
  const contractStatusItems = Object.values(contractStatuses).map((status) => ({
    value: contractStatuses[status],
    label: contractStatusLabels[status],
  }))

  // Filter the results and format for enhanced table
  const contractRows = data.contracts.edges
    // Normalize the data
    .map((edge) => ({
      id: edge.node.id,
      licensePlate: edge.node.car.licensePlate,
      carFileId: edge.node.car.carFileId,
      clientLastName: edge.node.customer.lastName,
      postalcode: edge.node.customer.zipCode,
      package: edge.node.package.name,
      startDate: edge.node.activationDate,
      endDate: edge.node.expirationDate,
      duration: edge.node.period,
      status: edge.node.status,
      contract: edge.node,
    }))
    // Filter by status
    .filter((row) => {
      return (
        statusFilter.status === ALL ||
        (row.status === ACTIVE &&
          statusFilter.status === ACTIVE_BUT_CHANGES_PENDING &&
          row.contract.mutations.length > 0 &&
          row.contract.mutations.some(
            (mutation) => mutation.status === 'PENDING',
          )) ||
        (statusFilter.status === ALL_EXCLUDING_CANCELED &&
          row.status !== CANCELED) ||
        (statusFilter.status === INCOMPLETE_AND_UNSIGNED &&
          (row.status === INCOMPLETE || row.status === UNSIGNED)) ||
        row.status === statusFilter.status
      )
    })
    // Filter by search query
    .filter((row) => {
      if (!searchQuery) {
        return true
      } // No search query set, no filtering required
      // Look at every column's data prop and see if the search query occurs there
      const lowerCaseQuery = searchQuery.toLowerCase()
      return Object.keys(row).filter((column) => {
        // Try to convert the data to a string so the query can be checked
        try {
          const stringValue = row[column].toString()
          // Check to see if the search query occurs in the data value
          return stringValue.toLowerCase().indexOf(lowerCaseQuery) >= 0
        } catch (e) {
          // Not convertible to a string, so not searchable
          return false
        }
      }).length
    })
    .map((contract) => {
      const { status, duration, startDate, endDate, carFileId, ...restProps } =
        contract

      const startDateFormatted = startDate ? formatDate(startDate) : ''
      const endDateFormatted = endDate ? formatDate(endDate) : ''

      let editToLink = `${CSP_CONTRACT_FILE}/${contract.id}`
      if (contract.status === UNSIGNED) {
        editToLink = `${CSP_SALES_FLOW}/3?id=${contract.id}`
      }
      if (contract.status === INCOMPLETE) {
        editToLink = `${CSP_SALES_FLOW}/2?id=${contract.id}`
      }

      const actions = (
        <>
          <ActionIconButton
            icon="forbidden"
            disabled={[EXPIRED, CANCELED, STOPPED].includes(status)}
            onClick={() => setCancelContract(contract)}
            data-test-e2e="button-cancel-contract"
          />
          <Line />
          <ActionIconButton
            icon="edit"
            to={editToLink}
            data-test-e2e="button-edit-contract"
          />
        </>
      )
      return {
        duration: t('carServicePlanContractsTable.durationMonths', {
          months: duration,
        }),
        startDate: startDateFormatted,
        endDate: endDateFormatted,
        status: {
          label: contractStatusLabels[status],
          color: contractStatusColors[status],
        },
        actions,
        licensePlateLink: carFileId ? `${CAR_FILE}/${carFileId}` : null,
        ...restProps,
      }
    })

  return (
    <>
      <Header>
        <HeadingContainer>
          <Heading type="Level1Heading">
            {t('carServicePlanDashboard.contractOverview')}
          </Heading>
          <ExplanationParagraph type="ExplanationParagraph">
            {t('carServicePlanContractsTable.clickOnContractForInfo')}
          </ExplanationParagraph>
        </HeadingContainer>
        <FiltersContainer data-test-e2e="contracts-table-filters">
          <ContractStatusSelect
            label={t('carServicePlanContractsTable.contractStatus')}
            filled
            onChange={(status) =>
              setStatusFilter({
                status,
              })
            }
            value={statusFilter.status}
            selectionRequired
            items={contractStatusItems}
            id="contract-status"
          />
          <SearchQueryInput
            label={t('carServicePlanContractsTable.searchForContracts')}
            filled
            icon={<Icon type="search" />}
            onChange={setSearchQuery}
            value={searchQuery}
            id="search"
          />
          {userIsAdmin && exportUrl && (
            <ExportButton
              level="option"
              icon="download"
              href={exportUrl}
              to={exportUrl}
            >
              {t('carServicePlanContractsTable.exportContracts')}
            </ExportButton>
          )}
        </FiltersContainer>
      </Header>
      <ContractsTable rows={contractRows} />
      {cancelContract && (
        <ChangeContractStatusDialog
          contract={cancelContract}
          onClose={() => setCancelContract(undefined)}
        />
      )}
    </>
  )
}

ContractsOverview.propTypes = {}

export default ContractsOverview
