import React from 'react'
import { useTranslation } from 'react-i18next'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import {
  RDW_TRANSFERS_RECEIVED_CARS,
  RDW_TRANSFERS_RETRACT_CAR,
  RDW_TRANSFERS_SENT_CARS,
  RDW_TRANSFER_CHECK_CAR,
  RDW_TRANSFER_RECORD_CAR,
} from 'config/routes'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import Tabs from 'components/layouts/tabs'
import PageHeader from 'components/organisms/page-header'
import RdwRecordCarForm from './rdw-record-car-form'
import RdwRetractCarForm from './rdw-retract-car-form'
import RdwCheckCarForm from './rdw-check-car-form'
import { useLocation } from 'react-router'
import RdwSentCarsOverview from './rdw-sent-cars'
import RdwReceivedCarsOverview from './rdw-received-cars'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.alternativeBackground};
  padding: 0 20px;

  ${media.desktop`
    padding: 0 60px;
  `};
`

const RdwCarTransfer = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const carFileId = urlParams.get('auto_id')

  // record a ownership transfer with RDW
  const recordCarTab = {
    id: 'recordCarTab',
    label: `${t('rdwCarTransfer.recordCarTab.title')}`,
    to: RDW_TRANSFER_RECORD_CAR,
    component: React.useCallback(
      () => <RdwRecordCarForm carFileId={carFileId} />,
      [carFileId],
    ),
  }

  // retract a previously transferred ownership with RDW
  const retractCar = {
    id: 'retractCar',
    label: `${t('rdwCarTransfer.retractCarTab.title')}`,
    to: RDW_TRANSFERS_RETRACT_CAR,
    component: React.useCallback(
      () => <RdwRetractCarForm carFileId={carFileId} />,
      [carFileId],
    ),
  }

  // check with RDW where the ownership lies (RDW is the authority on this)
  const checkCar = {
    id: 'CSPTransactions',
    label: `${t('rdwCarTransfer.checkCarTab.title')}`,
    to: RDW_TRANSFER_CHECK_CAR,
    component: React.useCallback(
      () => <RdwCheckCarForm carFileId={carFileId} />,
      [carFileId],
    ),
  }

  // Overview of cars that the location released ownership of (via RDW):
  const sentCarsTab = {
    id: 'CSPTransactions',
    label: `${t('rdwCarTransfer.sentCarsTab.title')}`,
    to: RDW_TRANSFERS_SENT_CARS,
    component: React.useCallback(() => <RdwSentCarsOverview />, []),
  }

  // Overview of cars that the location can idemnify ('vrijwaren' in Dutch) via RDW
  // and add to their stock:
  const receivedCarsTab = {
    id: 'CSPTransactions',
    label: `${t('rdwCarTransfer.receivedCarsTab.title')}`,
    to: RDW_TRANSFERS_RECEIVED_CARS,
    component: React.useCallback(() => <RdwReceivedCarsOverview />, []),
  }

  const tabItems = [
    recordCarTab,
    retractCar,
    checkCar,
    sentCarsTab,
    receivedCarsTab,
  ]

  return (
    <AppLayoutContainer pageTitle={t('rdwCarTransfer.pageTitle')}>
      <Container>
        <PageHeader
          mainTitle={t('rdwCarTransfer.mainTitle')}
          hasBorderBottom={false}
        />
        <Tabs items={tabItems}>
          {tabItems.map((item) =>
            item.subItems ? (
              <Tabs items={item.subItems} key={item.id} small>
                {item.subItems.map((subItem) => (
                  <subItem.component key={subItem.id} />
                ))}
              </Tabs>
            ) : (
              <item.component key={item.id} />
            ),
          )}
        </Tabs>
      </Container>
    </AppLayoutContainer>
  )
}

export default RdwCarTransfer
