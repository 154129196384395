import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { media } from 'utilities/styled'
import { ApolloProvider } from '@apollo/client'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import PageHeader from 'components/organisms/page-header'
import SettingsTabs from 'components/redux-containers/settings-tabs-container'
import { CSPapolloClient } from '../../../config/apollo'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.brandFoxtrot};
  padding: 0 20px;

  ${media.desktop`
    padding: 0 60px;
  `};
`

function SettingsView() {
  const { t } = useTranslation()
  // Once it is loaded there is no need to load it again on each re-render:
  const cspApolloClient = React.useMemo(() => CSPapolloClient(), [])
  return (
    <ApolloProvider client={cspApolloClient}>
      <AppLayoutContainer pageTitle={t('settings')}>
        <Container>
          <PageHeader mainTitle={t('settings')} />
          <SettingsTabs />
        </Container>
      </AppLayoutContainer>
    </ApolloProvider>
  )
}

SettingsView.propTypes = {}

export default SettingsView
