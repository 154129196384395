import React, { useState } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ImageCategories } from 'config/enums'

import { addCarAssets, addCarImageAssetsForCategory } from 'redux/actions/data'

import { media } from 'utilities/styled'
import { base64ImageToBlob, getUid } from 'utilities/utils'

import CameraCapture from 'components/organisms/camera-capture'
import ScreenRotateOverlay from 'components/molecules/screen-rotate-overlay'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'

function createFormData (capture, position, positionOffset) {
  const formData = new FormData()
  const file = base64ImageToBlob(capture)

  formData.append('file', file)
  formData.append('name', `camera_capture_${getUid()}`)
  formData.append('position', position + positionOffset)

  return formData
}

const StyledCameraCapture = styled(CameraCapture)`
  height: 100%;
  position: relative;

  ${media.desktop`
    height: 100%;
  `};
`

const StyledScreenRotateOverlay = styled(ScreenRotateOverlay)`
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 105;

  @media (orientation: portrait) {
    display: flex;
  }
`

const CloseButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.defaultBackground};
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;
`

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75vh;
  max-width: 450px;
  margin: auto;
  text-align: center;

  > * {
    margin-bottom: ${({ theme }) => theme.sizings.lvl3};
  }

  > *:last-child {
    margin-top: ${({ theme }) => theme.sizings.lvl4};
  }
`

function Camera ({
  carId,
  existingAssetsCount,
  referrerImageCategory,
  referrerUrl,
}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [error, setError ] = useState(false)

  function handleUpload (image) {
    const formData = createFormData(image, 0, existingAssetsCount)

    // urlParams "category" is being set in the media-form, when the user is uploading bulk images in the valuation
    // flow. This is the only place this call is used.
    if (referrerImageCategory === ImageCategories.INTERNAL) {
      dispatch(
        addCarImageAssetsForCategory(
          formData,
          carId,
          ImageCategories.INTERNAL,
          t('photoSuccesfullyUploaded'),
        ),
      )
    } else {
      dispatch(
        addCarAssets(
          formData,
          carId,
          t('photoSuccesfullyUploaded'),
        ),
      )
    }
  }

  function handleError () {
    setError(true)
  }

  function handleClickPrevious () {
    // If referrerUrl isn't set, go back one page (this is a fallback)
    if (!referrerUrl) {
      window.history.go(-1)
      return
    }

    // If referrerUrl is set, go back to the page where the user navigated from (e.g. media in the valuation flow, or media in a carfile)
    history.push(referrerUrl)
  }

  return (
    <>
      <StyledScreenRotateOverlay />
      <CloseButton
        iconColor="text"
        icon="close"
        level="minimal"
        onClick={handleClickPrevious}
      />
      {error
        ? (
          <ErrorContainer>
            <Text
              text={t('bulkPhotosNotSupported')}
              type="h2"
            />
            <Text
              text={t('goBackAndChooseFromFile')}
              type="bodyLarge"
            />
            <Button
              level="cta"
              onClick={handleClickPrevious}
              text={t('previousPage')}
            />
          </ErrorContainer>
        ) : (
          <StyledCameraCapture
            onError={handleError}
            onUpload={handleUpload}
          />
        )}
    </>
  )
}

Camera.propTypes = {
  carId: PropTypes.string.isRequired,
  existingAssetsCount: PropTypes.number.isRequired,
  referrerImageCategory: PropTypes.oneOf(Object.values(ImageCategories)),
  referrerUrl: PropTypes.string,
}

Camera.defaultProps = {
  referrerUrl: '',
  referrerImageCategory: '',
}

export default Camera
