// EXTERNAL
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

// INTERNAL
import toast from 'utilities/toast'
import { mapToLabelAndValue } from 'utilities/utils'
import data from 'utilities/data'
import ComplexForm from 'components/molecules/complex-form'
import { getEditionsForBpmDeclaration } from 'redux/actions/data'

const {
  getCarTypesForBpmDeclaration,
  getCarBrandsForBpmDeclaration,
  getCarModelsForBpmDeclaration,
} = data

const formatDate = (value) => moment(value).format('YYYY-MM-DD')

const getOptions = async (params, getter, stateSetter, instanceId) => {
  const options = await getter(instanceId, params)
  stateSetter({ data: options })
}

const CarBpmDeclarationStep1Form = () => {
  const { t } = useTranslation()
  const [carBrands, setCarBrands] = useState({})
  const [carTypes, setCarTypes] = useState({})
  const [carModels, setCarModels] = useState({})
  const instanceId = useSelector((state) => state.auth.instanceId)
  const dispatch = useDispatch()

  useEffect(() => {
    getOptions({}, getCarTypesForBpmDeclaration, setCarTypes, instanceId)
  }, [instanceId])

  function handleSubmit(submitData) {
    if (moment(submitData.datum).isBefore('2013-01-01')) {
      // 2013-01-01 is the minimum allowed date for the BPM flow (business requirement)
      toast.error(t('bpmErrorMessageYearShouldBeAfter2013'))
    } else {
      dispatch(
        getEditionsForBpmDeclaration({
          ...submitData,
          datum: formatDate(submitData.datum),
        }),
      )
    }
  }

  // This onChange is called for every change of the ComplexForm. Which
  // previously resulted in brands options and model options being
  // fetched for every request. Therefore there is a check of relevant
  // values actually have changed before new options are fetched.
  //
  // dispatch and props arguments are unused but have to be defined
  // in order to get previousValues.
  // https://redux-form.com/8.3.0/docs/api/reduxform.md/#-code-onchange-function-code-optional-
  function handleChange(values, _, props, previousValues) {
    if (!values) {
      return
    }

    const dateFirstAdmission = values?.datum ? formatDate(values.datum) : ''
    const carType = values.voertuigsoort
    const brand = values.merk

    const dateFirstAdmissionChanged =
      dateFirstAdmission !== previousValues.datum
    const carTypeChanged = carType !== previousValues.voertuigsoort
    const brandChanged = brand !== previousValues.merk

    if (
      dateFirstAdmission &&
      carType &&
      (carTypeChanged || dateFirstAdmissionChanged)
    ) {
      getOptions(
        {
          datum: dateFirstAdmission,
          voertuigsoort: carType,
        },
        getCarBrandsForBpmDeclaration,
        setCarBrands,
        instanceId,
      )
    }

    if (
      carType &&
      dateFirstAdmission &&
      brand &&
      (dateFirstAdmissionChanged || carTypeChanged || brandChanged)
    ) {
      getOptions(
        {
          merk: brand,
          datum: dateFirstAdmission,
          voertuigsoort: carType,
        },
        getCarModelsForBpmDeclaration,
        setCarModels,
        instanceId,
      )
    }
  }

  const mappedCarBrands =
    carBrands &&
    carBrands.data &&
    mapToLabelAndValue(carBrands.data, 'merk', 'id')

  const mappedCarModels =
    carModels &&
    carModels.data &&
    mapToLabelAndValue(carModels.data, 'model', 'id')

  const fields = [
    {
      name: 'datum',
      required: true,
      title: t('datePartOne'),
      type: 'date',
    },
    {
      name: 'voertuigsoort',
      title: t('carType'),
      type: 'select',
      options: carTypes && carTypes.data ? carTypes.data : [],
      required: true,
    },
    {
      name: 'merk',
      title: t('brand'),
      type: 'select',
      options: mappedCarBrands || [],
      required: true,
    },
    {
      name: 'model',
      title: t('model'),
      type: 'select',
      options: mappedCarModels || [],
      required: true,
    },
    {
      name: 'km',
      title: t('mileage'),
      variety: 'floatingLabel',
      required: true,
    },
    {
      name: 'vin',
      title: t('vinNumber'),
      variety: 'floatingLabel',
      required: false,
    },
  ]

  const fieldsets = [
    {
      fields,
    },
  ]

  return (
    <ComplexForm
      fieldsets={fieldsets}
      formId="bpmDeclaration"
      level="cta"
      onChange={handleChange}
      onSubmit={handleSubmit}
      submitText={t('searchVersions')}
    />
  )
}

export default CarBpmDeclarationStep1Form
