import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import LocationsSettings from 'components/redux-containers/locations-settings-container'
import LocationContactSettings from 'components/redux-containers/location-contact-settings-container'
import LocationUserSettings from 'components/redux-containers/location-user-settings-container'

function LocationsMainSettings({ match }) {
  const { t } = useTranslation()
  const tabItems = [
    {
      label: t('contactData'),
      to: `${match.url}/contact-details`,
      component: (props) => <LocationContactSettings {...props} />,
    },
    {
      label: t('users'),
      to: `${match.url}/users`,
      component: (props) => <LocationUserSettings {...props} />,
    },
  ]

  return (
    <LocationsSettings title={t('locationsOverview')} tabItems={tabItems} />
  )
}

LocationsMainSettings.propTypes = {
  match: PropTypes.object.isRequired,
}

LocationsMainSettings.defaultProps = {}

export default withRouter(LocationsMainSettings)
