import { connect } from 'react-redux'
import {
  addCarAssets,
  deleteCarAssets,
  getCarfile,
  getCarAssets,
  rotateImages,
  setCarKind,
  setCarStatus,
  sortCarAssets,
  updateCarfile,
  getBids,
} from 'redux/actions/data'
import {
  openOverlay,
  openImageOverlay,
  toggleSalesView,
} from 'redux/actions/ui'
import { getForms } from 'redux/actions/forms'
import CarFile from '../views/car-file/index'

const mapStateToProps = ({ data, forms, ui, auth }) => ({
  userDetails: auth.userDetails,
  carAssets: data.carAssets,
  carfile: data.carfile,
  carKind: data.carkind,
  forms: forms.carfile,
  salesView: ui.salesView,
  carAssetsSort: data.carAssetsSort,
})

const mapDispatchToProps = {
  addCarAssets,
  deleteCarAssets,
  getCarfile,
  getCarAssets,
  getForms,
  getBids,
  openOverlay,
  openImageOverlay,
  rotateImages,
  setCarKind,
  setCarStatus,
  sortCarAssets,
  toggleSalesView,
  updateCarfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(CarFile)
