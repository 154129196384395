/* Triggering toasts from a reducer is little bit nasty, but this way we have one
 * central point where all toasts get triggered. The 'right' way to do it is to trigger
 * them in actions as demonstrated here: https://codesandbox.io/s/2485wxy78j .
 * (Please refactor it whenever that seems more appropriate.) Edit: @TODO The data actions already
 * have been rewritten to trigger toasts from them, this should be done for login as well
 */

import { types as authTypes } from 'redux/actions/auth'
import { types as uiTypes } from 'redux/actions/ui'
import toast from 'utilities/toast'
import i18next from 'i18next'

let timeoutId
let loadingToastId

export const initialState = {
  imageOverlayOpen: false,
  imagesSetType: null, // 'regular-car-file' or ''b2b-car-file'
  imageOverlayImageIdx: 0,
  salesView: true,
  searchbox: false,
  carFileChecklistProgressNeedsUpdate: false,
  toasts: {
    infos: [],
    warnings: [],
    errors: [],
  },
}

export function reducer(state = initialState, action) {
  const { status, payload, type } = action

  switch (type) {
    case uiTypes.OPEN_IMAGE_OVERLAY: {
      return {
        ...state,
        imageOverlayOpen: true,
        imageOverlayImageIdx: payload.imageIdx,
        imagesSetType: payload.imagesSetType,
      }
    }

    case uiTypes.CLOSE_IMAGE_OVERLAY: {
      return {
        ...state,
        imageOverlayOpen: false,
      }
    }

    case uiTypes.TOGGLE_SALES_VIEW: {
      return {
        ...state,
        salesView: !state.salesView,
      }
    }

    case uiTypes.CLOSE_SEARCHBOX: {
      return {
        ...state,
        searchbox: false,
      }
    }

    case uiTypes.OPEN_SEARCHBOX: {
      return {
        ...state,
        searchbox: true,
      }
    }
    case uiTypes.OPEN_OVERLAY: {
      return {
        ...state,
        overlay: {
          id: payload.type,
          props: payload.props,
        },
      }
    }

    case uiTypes.CLOSE_OVERLAY: {
      return {
        ...state,
        overlay: null,
      }
    }

    case authTypes.SET_LOGIN: {
      if (status === 'REQUEST') {
        timeoutId = setTimeout(() => {}, 100)
      }
      if (status === 'SUCCESS') {
        clearTimeout(timeoutId)
        toast.dismiss(loadingToastId)
        toast.success(i18next.t('successfullyLoggedIn'))
      }
      if (status === 'FAILURE') {
        clearTimeout(timeoutId)
        toast.dismiss(loadingToastId)
        toast.error(i18next.t('failedToLogin'))
      }

      return state
    }

    case authTypes.SET_VERIFY_AUTH: {
      if (status === 'SUCCESS') {
        return {
          ...state,
          salesView: payload.userDetails.salesView,
        }
      }

      return state
    }

    case uiTypes.CAR_FILE_CHECKLIST_PROGRESS_NEEDS_UPDATE: {
      return {
        ...state,
        carFileChecklistProgressNeedsUpdate: payload.update,
      }
    }

    case uiTypes.SET_SELECTED_LOCATION_ID: {
      return {
        ...state,
        selectedLocationId: payload.id,
      }
    }

    default:
      return state
  }
}
