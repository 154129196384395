import { Drawer } from '@material-ui/core'
import ActionIconButton from 'components/atoms/action-icon-button'
import BodyHeading from 'components/atoms/body-heading'
import Button from 'components/atoms/button'
import PopupMenu from 'components/molecules/popup-menu'
import Typography from 'components/molecules/typography'
import EnhancedTable from 'components/organisms/enhanced-table'
import Menu from 'components/organisms/menu'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components/macro'
import { useMediaLayout } from 'use-media'
import { truncate } from 'utilities/format'
import { media } from 'utilities/styled'

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.sizings.lvl1};
`

const RowText = styled(Typography)`
  margin-top: 0;
  margin-bottom: 0;
`

const StyledPopupMenu = styled(PopupMenu)`
  text-align: left;

  > .actual-menu {
    right: 0;
    min-width: 230px;
  }
`

const Table = styled(EnhancedTable)`
  position: relative;

  ${media.tv`
    > .MuiTableContainer-root {
      overflow: visible;
    }
  `}
`

const CloseMenuOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  z-index: 100;
  ${media.tv`
    position: fixed;
    width: 100%;
    height: 100%;
  `}
`

const DrawerHeader = styled.header`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.sizings.lvl2}
    ${({ theme }) => theme.sizings.lvl2} ${({ theme }) => theme.sizings.lvl2}
    ${({ theme }) => theme.sizings.lvl4};
`

const DrawerHeading = styled(BodyHeading)`
  flex: 1 0 auto;
  margin: 0;
`

const CloseButton = styled(Button)`
  flex: 0 0 auto;
`

const ChecklistTable = ({
  items,
  onOrderChange,
  onItemEditClick,
  onItemAddAtIndexClick,
  onItemDeleteClick,
  disableActions,
  ...restProps
}) => {
  const { t } = useTranslation()

  const columns = [
    { id: 'name', label: t('checklistsSettings.tableHeadings.name') },
    {
      id: 'alertDescription',
      label: t('checklistsSettings.tableHeadings.alertDescription'),
    },
    { id: 'optional', label: t('checklistsSettings.tableHeadings.optional') },
    {
      id: 'maxDuration',
      label: t('checklistsSettings.tableHeadings.maxDuration'),
    },
    { id: 'reminder', label: t('checklistsSettings.tableHeadings.reminder') },
    {
      id: 'description',
      label: t('checklistsSettings.tableHeadings.description'),
    },
    { id: 'position', label: t('checklistsSettings.tableHeadings.position') },
    {
      id: 'actions',
      label: t('checklistsSettings.tableHeadings.actions'),
      alignRight: true,
    },
  ]

  const [activeMenuItemId, setActiveMenuItemId] = React.useState()

  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tv - 1 })

  const generateMenuItems = (rowId, rowIndex) => {
    return [
      {
        icon: 'edit',
        label: t('edit'),
        onClick: () => {
          onItemEditClick(rowId, rowIndex)
          setActiveMenuItemId(null)
        },
      },
      {
        icon: 'plus',
        label: t('checklistsSettings.tab.addItemAbove'),
        onClick: () => {
          onItemAddAtIndexClick(rowIndex)
          setActiveMenuItemId(null)
        },
      },
      {
        icon: 'plus',
        label: t('checklistsSettings.tab.addItemBelow'),
        onClick: () => {
          onItemAddAtIndexClick(rowIndex + 1)
          setActiveMenuItemId(null)
        },
      },
      {
        icon: 'delete',
        label: t('delete'),
        onClick: () => {
          onItemDeleteClick(rowId)
          setActiveMenuItemId(null)
        },
      },
    ]
  }

  const closeDrawer = () => setActiveMenuItemId(null)

  const rows = items.map((item, i) => {
    return {
      _attrs: {
        'data-test-e2e': 'checklist-item-row',
        'data-test-e2e-item-id': item.id,
      },
      name: {
        data: item.name,
        component: <RowText type="InlineBodyText">{item.name}</RowText>,
      },
      alertDescription: {
        data: item.alertMessage || '',
        component: (
          <RowText type="InlineBodyText">{item.alertMessage || '-'}</RowText>
        ),
      },
      optional: {
        data: t(item.blocking ? 'no' : 'yes'),
        component: (
          <RowText type="InlineBodyText">
            {t(item.blocking ? 'no' : 'yes')}
          </RowText>
        ),
      },
      maxDuration: {
        data: item.maxDuration
          ? t(item.maxDuration === 1 ? 'day' : 'day_plural', {
              count: item.maxDuration,
            })
          : '',
        component: (
          <RowText type="InlineBodyText">
            {item.maxDuration
              ? t(item.maxDuration === 1 ? 'day' : 'day_plural', {
                  count: item.maxDuration,
                })
              : '-'}
          </RowText>
        ),
      },
      reminder: {
        data: item.reminderAfterDays
          ? t(item.reminderAfterDays === 1 ? 'day' : 'day_plural', {
              count: item.reminderAfterDays,
            })
          : '',
        component: (
          <RowText type="InlineBodyText">
            {item.reminderAfterDays
              ? t(item.reminderAfterDays === 1 ? 'day' : 'day_plural', {
                  count: item.reminderAfterDays,
                })
              : '-'}
          </RowText>
        ),
      },
      description: {
        data: item.description
          ? truncate(item.description, 80, false, '…')
          : '',
        component: (
          <RowText type="InlineBodyText">
            {item.description
              ? truncate(item.description, 80, false, '…')
              : '-'}
          </RowText>
        ),
      },
      position: {
        data: item.position,
        component: (
          <ActionsContainer>
            <ActionIconButton
              icon="arrowUp"
              width="24"
              height="24"
              onClick={(e) => {
                e.preventDefault()
                onOrderChange(item.id, false)
              }}
              disabled={i === 0 || disableActions}
              data-test-e2e="button-position-up"
            />
            <ActionIconButton
              icon="arrowDown"
              width="24"
              height="24"
              onClick={(e) => {
                e.preventDefault()
                onOrderChange(item.id, true)
              }}
              disabled={i === items.length - 1 || disableActions}
              data-test-e2e="button-position-down"
            />
          </ActionsContainer>
        ),
      },
      actions: {
        data: '',
        component: (
          <>
            {activeMenuItemId === item.id && (
              <CloseMenuOverlay
                onClick={() => {
                  setActiveMenuItemId(undefined)
                }}
                data-test-e2e="checklist-actions-menu-close-overlay"
              />
            )}
            {isMobile ? (
              <>
                <ActionIconButton
                  icon="dots"
                  width="24"
                  height="24"
                  onClick={() => {
                    setActiveMenuItemId((id) =>
                      id === item.id ? undefined : item.id,
                    )
                  }}
                  disabled={disableActions}
                  data-test-e2e="button-actions-menu"
                />
                <Drawer
                  anchor="bottom"
                  open={activeMenuItemId === item.id}
                  onClose={closeDrawer}
                  data-test-e2e="checklist-actions-menu"
                >
                  <DrawerHeader>
                    <DrawerHeading level="5">
                      {t('checklistsSettings.tableRowOptionsHeading')}
                    </DrawerHeading>
                    <CloseButton
                      iconColor="text"
                      level="minimal"
                      icon="close"
                      onClick={closeDrawer}
                      $isAbsolute
                    />
                  </DrawerHeader>
                  <Menu items={generateMenuItems(item.id, i)} />
                </Drawer>
              </>
            ) : (
              <StyledPopupMenu
                items={generateMenuItems(item.id, i)}
                positionMenu={{ toLeft: true }}
                isMenuActive={activeMenuItemId === item.id}
                data-test-e2e="checklist-actions-menu"
              >
                <ActionIconButton
                  icon="dots"
                  width="24"
                  height="24"
                  onClick={() => {
                    setActiveMenuItemId((id) =>
                      id === item.id ? undefined : item.id,
                    )
                  }}
                  disabled={disableActions}
                  data-test-e2e="button-actions-menu"
                />
              </StyledPopupMenu>
            )}
          </>
        ),
      },
    }
  })

  return (
    <Table columns={columns} rows={rows} selectable={false} {...restProps} />
  )
}

ChecklistTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      alertMessage: PropTypes.string,
      blocking: PropTypes.bool,
      maxDuration: PropTypes.number,
      reminderAfterDays: PropTypes.number,
      description: PropTypes.string,
      order: PropTypes.number,
    }),
  ),
  disableActions: PropTypes.bool,
  onOrderChange: PropTypes.func.isRequired,
  onItemEditClick: PropTypes.func.isRequired,
  onItemAddAtIndexClick: PropTypes.func.isRequired,
  onItemDeleteClick: PropTypes.func.isRequired,
}

export default ChecklistTable
