import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Text from 'components/atoms/text'
import { formatLicenseplate } from 'utilities/utils'

const StyledLicensePlate = styled.div`
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.licensePlate};
  color: ${({ theme }) => theme.colors.text};
  display: inline-block;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.font};
  text-align: center;

  /**
    Using em values, because the the font-size can vary based
    on the required size of the component (small, medium).
    This way the paddings grow along with the font-size and
    everything will remain visually balanced.
  */
  > * {
    padding: 0.25em 0.5em;
  }

  /**
    Using em values, because the the font-size can vary based
    on the required size of the component (small, medium).
    This way the sizings grow along with the font-size and
    everything will remain visually balanced.
  */
  > small {
    display: inline-block;
    height: 1.5em;
    line-height: 1.5em;
    padding: 0em 0.5em;
    font-size: ${({ theme }) => theme.baseFontSize};
    font-weight: 500;
    font-family: ${({ theme }) => theme.font};
  }
`

/**
 * General component to display license plates as actual license plates. This helps people
 * to easily recognise the letter-digit combinations as license plates.
 */
function LicensePlate({
  className,
  size,
  value,
  formatLicensePlate,
  ...restProps
}) {
  const formattedLicensePlate = formatLicensePlate
    ? formatLicenseplate(value)
    : value

  return (
    <StyledLicensePlate className={className} {...restProps}>
      {size === 'tiny' ? (
        <small>{formatLicenseplate(value)}</small>
      ) : (
        <Text
          type={size === 'small' ? 'h5' : 'h2'}
          tag="p"
          text={formattedLicensePlate || 'XX-XX-XX'}
        />
      )}
    </StyledLicensePlate>
  )
}

LicensePlate.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
  formatLicensePlate: PropTypes.bool,
}

LicensePlate.defaultProps = {
  className: null,
  size: null,
  value: 'XXXXXX',
  formatLicensePlate: true,
}

export default LicensePlate
