import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { media } from 'utilities/styled'
import TextLink from 'components/atoms/text-link'
import Text from 'components/atoms/text'

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

const PagesContainer = styled.div`
  display: flex;
`

const Dots = styled(Text)`
  display: none;
  margin: auto;

  ${media.phone`
    display: block;
  `}
`

const PrevNextLink = styled(TextLink)`
  margin: 0 ${({ theme }) => theme.sizings.lvl2};
`

const StyledTextLink = styled(TextLink)`
  display: none;
  flex: 0 0 auto;
  margin: 0 ${({ theme }) => theme.sizings.lvl1};
  border: ${({ selected, theme }) =>
    selected ? `1px solid ${theme.colors.brandAlfa}` : ''};
  padding: ${({ theme }) => theme.sizings.lvl1}
    ${({ theme }) => theme.sizings.lvl2};

  ${media.phone`
    display: block;
  `}
`

function Paging({
  activePage,
  className,
  onChangePage,
  perPage,
  total,
  isDisabled,
  ...restProps
}) {
  const { t } = useTranslation()
  const maxPages = 6
  const totalPages = Math.ceil(total / perPage)
  const pagerPageSize = 4
  const dots = totalPages > maxPages
  const firstPagerPage = activePage >= pagerPageSize
  const lastPagerPage = activePage - 1 <= totalPages - pagerPageSize
  const pagesArray = Array.from(
    new Array(dots ? pagerPageSize : totalPages - 2).keys(),
  )

  return (
    <Container className={className} {...restProps}>
      <PrevNextLink
        disabled={activePage === 1 || isDisabled}
        onClick={() => onChangePage(activePage - 1)}
        text={t('previous')}
      />
      <PagesContainer>
        <StyledTextLink
          disabled={activePage === 1 || isDisabled}
          onClick={() => onChangePage(1)}
          selected={activePage === 1}
          text="1"
        />
        {dots && firstPagerPage && <Dots color="brandCharlie" text="..." />}
        {totalPages > 2 &&
          pagesArray.map((index) => {
            const offset = Math.max(
              Math.min(activePage - 1, totalPages - pagerPageSize),
              2,
            )
            const idx = index + offset
            const page = idx.toString()

            return (
              <StyledTextLink
                key={page}
                disabled={idx === activePage || isDisabled}
                onClick={() => onChangePage(idx)}
                selected={idx === activePage}
                text={page}
              />
            )
          })}
        {dots && lastPagerPage && <Dots color="brandCharlie" text="..." />}
        <StyledTextLink
          disabled={activePage === totalPages || isDisabled}
          onClick={() => onChangePage(totalPages)}
          selected={activePage === totalPages}
          text={totalPages.toString()}
        />
      </PagesContainer>
      <PrevNextLink
        disabled={activePage === totalPages || isDisabled}
        onClick={() => onChangePage(activePage + 1)}
        text={t('next')}
      />
    </Container>
  )
}

Paging.propTypes = {
  activePage: PropTypes.number,
  className: PropTypes.string,
  onChangePage: PropTypes.func,
  perPage: PropTypes.number,
  total: PropTypes.number,
  /**
   * disables the whole
   */
  isDisabled: PropTypes.bool,
}

Paging.defaultProps = {
  activePage: 1,
  className: null,
  onChangePage: () => {},
  perPage: 12,
  total: 0,
  isDisabled: false,
}

export default Paging
