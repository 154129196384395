import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import AspectRatioImage from 'components/atoms/aspect-ratio-image'

const Container = styled.label`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  width: 100%;
`

const Checkbox = styled.input`
  left: 12px;
  position: absolute;
  top: 12px;
`

const Overlay = styled.div`
  background: ${({ secondary, theme }) =>
    secondary ? theme.colors.brandCharlie : theme.colors.brandBravo};
  height: 100%;
  left: 0;
  opacity: ${({ visible }) => (visible ? 0.7 : 0)};
  position: absolute;
  top: 0;
  width: 100%;
`

// @TODO Replace the checkbox input here with the generic Checkbox component
// when the label is removed from that component and the Checkbox with label
// is renamed to LabeledCheckbox
function MediaImage({
  alt,
  className,
  disabled,
  selected,
  onSelect,
  ratio,
  secondarySelected,
  src,
  onClick,
  exclusiveSelect,
}) {
  return (
    <Container className={className} onClick={onClick} $disabled={disabled}>
      <AspectRatioImage alt={alt} ratio={ratio} src={src} blurredBackground />
      <Overlay
        secondary={secondarySelected}
        visible={selected || secondarySelected}
      />
      {onSelect && (
        <Checkbox
          type={exclusiveSelect ? 'radio' : 'checkbox'}
          checked={selected}
          disabled={disabled}
          onChange={onSelect}
        />
      )}
    </Container>
  )
}

MediaImage.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  ratio: PropTypes.number,
  selected: PropTypes.bool.isRequired,
  secondarySelected: PropTypes.bool,
  src: PropTypes.string.isRequired,
  exclusiveSelect: PropTypes.bool,
}

MediaImage.defaultProps = {
  className: null,
  disabled: false,
  onSelect: null,
  onClick: null,
  ratio: undefined,
  secondarySelected: false,
  exclusiveSelect: false,
}

export default MediaImage
