import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isValid } from 'redux-form'
import { useRouteMatch, useLocation } from 'react-router-dom'
import FlowStepper from 'components/molecules/flow-stepper'

const CarEntryValuationStepper = ({ formId, onChange, carFile }) => {
  const { t } = useTranslation()
  const match = useRouteMatch()
  const location = useLocation()

  const formValid = useSelector((state) => state?.form?.[formId] && isValid(formId)(state))

  const paths = {
    basic: `${match.url}/basic`,
    equipment: `${match.url}/equipment`,
    damage: `${match.url}/damage`,
    media: `${match.url}/media`,
    value: `${match.url}/value`,
    calculation: `${match.url}/calculation`,
  }

  const isMotorbikeOrMicrocar = ['brommobiel', 'motor'].includes(carFile?.voertuigsoort_mapped)

  const stepperItems = [{
    key: 'basic',
    active: location.pathname === paths.basic,
    disabled: location.pathname === paths.basic,
    label: t('basicData'),
    link: `${paths.basic}${location.search}`,
  }, {
    key: 'equipment',
    active: location.pathname === paths.equipment,
    disabled: location.pathname === paths.basic && !formValid,
    label: t('equipment'),
    link: `${match.url}/equipment${location.search}`,
  }, {
    key: 'damage',
    active: location.pathname === paths.damage,
    disabled: location.pathname === paths.basic && !formValid,
    label: t('damage'),
    link: `${match.url}/damage${location.search}`,
  }, {
    key: 'media',
    active: location.pathname === paths.media,
    disabled: location.pathname === paths.basic && !formValid,
    label: t('media'),
    link: `${match.url}/media${location.search}`,
  }, {
    key: 'value',
    active: location.pathname === paths.value,
    disabled: location.pathname === paths.basic && !formValid,
    label: t('value'),
    link: `${match.url}/value${location.search}`,
  }, {
    key: 'calculation',
    active: location.pathname === paths.calculation,
    disabled: location.pathname === paths.basic && !formValid,
    label: t('calculation'),
    link: `${match.url}/calculation${location.search}`,
  }]

  // When the carFile is a motorbike or microcar, remove the 'damage' and 'value' tabs
  const filteredStepperItems = isMotorbikeOrMicrocar ? stepperItems.filter((item) => !['damage', 'value'].includes(item.key)) : stepperItems

  return (
    <FlowStepper
      coloredSteps
      items={filteredStepperItems}
      onChange={onChange}
    />
  )
}

CarEntryValuationStepper.propTypes = {
  onChange: PropTypes.func,
  formId: PropTypes.string.isRequired,
  carFile: PropTypes.object,
}

CarEntryValuationStepper.defaultProps = {
  onChange: undefined,
  formId: null,
  carFile: {},
}

export default CarEntryValuationStepper
