import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import OptionsBar from 'components/layouts/options-bar'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import ExpandableBars from 'components/layouts/expandable-bars'
import SummaryLine from 'components/molecules/summary-line'
import SettingsForm from 'components/redux-containers/settings-form-container'

function TransportCompaniesSettings({
  getTransportCompanies,
  transportCompanies,
  deleteTransportCompany,
  openOverlay,
  postDynamicEndpoint,
}) {
  const { t } = useTranslation()
  const transportCompaniesData =
    transportCompanies && transportCompanies.all && transportCompanies.all.data
  const transportCompaniesLoading =
    !transportCompanies ||
    !transportCompanies.all ||
    transportCompanies.all.loading
  const [currentRelation, setCurrentRelation] = useState(null)
  // Normally the subId is used to specify for which a location
  // a setting is loaded but these settings are cmpany wide and
  // therefore the dummy id 'all' is used which can later be changed
  // in case these settings have to become location specific.
  const subId = 'all'

  useEffect(() => {
    getTransportCompanies({ id: subId })
  }, [getTransportCompanies])

  function handleDeleteTransportCompany(id, companyId) {
    if (window.confirm(t('areYouSure'))) {
      deleteTransportCompany({
        id,
        company_id: companyId,
      })
    }
  }

  function handleClickAddRelation() {
    openOverlay('dynamicForm', {
      formId: 'transportCompany',
      onSubmit: (values, endpoint) => {
        postDynamicEndpoint({
          endpoint,
          ...values,
        })
      },
      icon: 'preCalculation',
      onUpdateSuccess: () => getTransportCompanies({ id: subId }),
      submitText: t('saveChanges'),
      title: t('addTransportCompany'),
    })
  }

  return (
    <>
      <OptionsBar
        leftAligned={[
          <Text
            key="left-aligned-text"
            type="h2"
            text={t('transportCompanies')}
          />,
        ]}
        rightAligned={[
          <Button
            key="right-aligned-button"
            onClick={handleClickAddRelation}
            text={t('addTransportCompany')}
          />,
        ]}
      />
      {transportCompaniesData && !!transportCompaniesData.length && (
        <ExpandableBars
          fullRowIsClickable={false}
          rows={transportCompaniesData.map((relation, index) => ({
            id: relation.id,
            header: (
              <SummaryLine
                key={index.toString()}
                name={relation.bedrijf}
                details={[]}
                actions={[
                  {
                    action: () =>
                      handleDeleteTransportCompany(subId, relation.id),
                    text: t('delete'),
                  },
                ]}
              />
            ),
            onRowClick: () => setCurrentRelation(relation.id),
            details: (
              <SettingsForm
                formId="transportCompany"
                subId={currentRelation}
                params={{ company_id: currentRelation }}
                onUpdateSuccess={() => getTransportCompanies({ id: subId })}
              />
            ),
          }))}
        />
      )}
      {!transportCompaniesLoading && !transportCompaniesData.length && (
        <Text text={t('noTransportCompaniesYet')} type="floatingLabelLabel" />
      )}
    </>
  )
}

TransportCompaniesSettings.propTypes = {
  getTransportCompanies: PropTypes.func.isRequired,
  transportCompanies: PropTypes.object,
  deleteTransportCompany: PropTypes.func.isRequired,
  openOverlay: PropTypes.func.isRequired,
  postDynamicEndpoint: PropTypes.func.isRequired,
}

TransportCompaniesSettings.defaultProps = {
  transportCompanies: null,
}

export default TransportCompaniesSettings
