export const unitsOfMeasurement = {
  capacity_cc: 'cc',
  currency_euro: '€',
  emission_gram_km: 'g/km',
  consumption_l_km: 'l/km',
  consumption_l_100km: 'l/100km',
  km: 'km',
  power_kw: 'kW',
  power_pk: 'PK',
  per_month: 'p/m',
  per_km: 'p/km',
  percent: '%',
  size_km2: 'km2',
  size_m2: 'm2',
  size_cm: 'cm',
  size_mm: 'mm',
  speed_kmh: 'Km/h',
  weight_kg: 'Kg',
  euro_per_km: '€/km',
}

export const unitPrefixes = {
  capacity_cc: '',
  currency_euro: unitsOfMeasurement.currency_euro,
  emission_gram_km: '',
  consumption_l_km: '',
  consumption_l_100km: '',
  km: '',
  power_kw: '',
  power_pk: '',
  per_month: '',
  per_km: '',
  percent: '',
  size_km2: '',
  size_m2: '',
  size_cm: '',
  size_mm: '',
  speed_kmh: '',
  weight_kg: '',
  euro_per_km: '',
}
export const unitSuffixes = {
  ...unitsOfMeasurement,
  currency_euro: '',
}

export const unitTypes = {
  capacity_cc: 'number',
  currency_euro: 'number',
  emission_gram_km: '',
  consumption_l_km: '',
  km: 'number',
  power_kw: 'number',
  power_pk: 'number',
  per_month: 'number',
  per_km: '',
  percent: 'number',
  size_km2: '',
  size_m2: '',
  size_mm: '',
  speed_kmh: 'number',
  weight_kg: 'number',
  euro_per_km: 'number',
}
