import { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import languageUtilities from 'utilities/language'

import { getNews } from 'redux/actions/data'

import {
  newsItemWordPressTagShowInNavigation,
  newsItemWordPressTagOpenOnLoad,
  newsItemWordPressTagLanguage,
  newsItemWordPressTagLocation,
  userLicensesMap,
} from 'config/news'

import useDealerLocationCountryCode from './use-location-country-code'

const stripNonAlphanumericToLowerCase = (string) =>
  string.replace(/\W/g, '').toLowerCase()

const usePrioritizeNavigationBarNewsItem = () => {
  const dispatch = useDispatch()
  const newsItems = useSelector((state) => state.data.news)
  const backEndUserLicenses =
    useSelector((state) => state?.auth?.userDetails?.licenses) || []
  const userLicenses =
    backEndUserLicenses.length > 0
      ? backEndUserLicenses.map((license) => userLicensesMap[license])
      : []
  const userLanguage = languageUtilities.getLanguage().toUpperCase()
  const { locationCountryCode } = useDealerLocationCountryCode()
  const userLocation = locationCountryCode.toUpperCase()

  useEffect(() => {
    dispatch(getNews())
  }, [dispatch])

  const filteredNewsItems = useMemo(() => {
    if (!newsItems || !newsItems?.data || !newsItems?.data?.length) {
      return []
    }

    const today = moment()
    const dateTwoWeeksEarlier = today.subtract(2, 'weeks')
    const twoWeekOldNewsItems = Array.isArray(newsItems.data)
      ? newsItems.data.filter((item) =>
          moment(item.date_published).isAfter(dateTwoWeeksEarlier),
        )
      : []
    const mappedLicensesToWordPressTags = userLicenses
      .map((license) => `${newsItemWordPressTagShowInNavigation} ${license}`)
      .concat([
        newsItemWordPressTagOpenOnLoad,
        newsItemWordPressTagShowInNavigation,
      ])
      .map((wordPressTag) => stripNonAlphanumericToLowerCase(wordPressTag))

    const filteredLocationCountryNewsItems = twoWeekOldNewsItems
      // Filter news items that have the current language (e.g. "WebApp taal BE") tag in WordPress
      .filter((newsItem) =>
        newsItem.tags.includes(
          `${newsItemWordPressTagLanguage} ${userLanguage}`,
        ),
      )
      // Filter news items that have the current location (e.g. "WebApp locatie NL") tag in WordPress
      .filter((newsItem) =>
        newsItem.tags.includes(
          `${newsItemWordPressTagLocation} ${userLocation}`,
        ),
      )

    // Filter news items that have any of the licenses (e.g. "WebApp UCC" or "WebApp FF")
    const filteredSpecificNewsItems = filteredLocationCountryNewsItems.filter(
      (newsItem) =>
        newsItem.tags
          .filter(
            (tag) =>
              tag !== newsItemWordPressTagShowInNavigation &&
              tag !== newsItemWordPressTagOpenOnLoad,
          )
          .some((tag) =>
            mappedLicensesToWordPressTags.includes(
              stripNonAlphanumericToLowerCase(tag),
            ),
          ),
    )

    // Filter news items that have the tag "WebApp" or "Aandacht" in WordPress
    const filteredGenericNewsItems = filteredLocationCountryNewsItems.filter(
      (newsItem) =>
        newsItem.tags.includes(newsItemWordPressTagShowInNavigation) ||
        newsItem.tags.includes(newsItemWordPressTagOpenOnLoad),
    )

    // Remove duplicates, some news items have both the generic tags ("Aandacht" or "WebApp") and license specific tags (WebApp MV)
    return [...filteredSpecificNewsItems, ...filteredGenericNewsItems].filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i,
    )
  }, [newsItems, userLicenses, userLanguage, userLocation])

  const prioritizedNewsItemId = useMemo(() => {
    if (!filteredNewsItems.length) {
      return null
    }

    const aandachtTagSlug = stripNonAlphanumericToLowerCase(
      newsItemWordPressTagOpenOnLoad,
    )
    const webAppTagSlug = stripNonAlphanumericToLowerCase(
      newsItemWordPressTagShowInNavigation,
    )
    const initialPriorities = filteredNewsItems.map((item) => ({
      id: item.id.slice(item.id.indexOf('=') + 1),
      tags: item.tags.map((tag) => stripNonAlphanumericToLowerCase(tag)),
      priority: 0,
    }))
    const priorities = initialPriorities.map((item) => {
      let priority = item.priority

      item.tags.forEach((tag) => {
        const tagSlug = stripNonAlphanumericToLowerCase(tag)
        if (aandachtTagSlug === tagSlug || webAppTagSlug === tagSlug) {
          priority = priority += 1
        }

        userLicenses.forEach((license) => {
          const licenseSlug = `${webAppTagSlug}${stripNonAlphanumericToLowerCase(license)}`

          if (licenseSlug === tagSlug) {
            priority = priority += 1
          }
        })
      })

      return {
        ...item,
        priority,
      }
    })

    const prioritizedNewsItem = priorities.reduce((prev, current) =>
      prev.priority === current.priority || prev.priority > current.priority
        ? prev
        : current,
    )

    return prioritizedNewsItem?.id || null
  }, [filteredNewsItems, userLicenses])

  const newsItem =
    newsItems?.data?.find(
      (newsItem) => newsItem.id === prioritizedNewsItemId,
    ) || null

  return newsItem
}

export default usePrioritizeNavigationBarNewsItem
