import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import GalleryImage from 'components/atoms/gallery-image'
import Text from 'components/atoms/text'
import LoadingIndicator from 'components/atoms/loading-indicator'
import NoImagePlaceholder from 'components/atoms/no-image-placeholder'
import { useTranslation } from 'react-i18next'
import { formatDataTestE2eAttr } from 'utilities/format'

const maxThumbnails = 8

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
`

const Item = styled.div`
  cursor: pointer;
  display: none;
  position: relative;
  width: 25%;

  ${media.desktop`
    display: block;
  `}

  &:first-child {
    display: block;
    width: 100%;
  }
`
/** @todo: Taken form GalleryImage, needs to be updated to use Typography instead */
const Label = styled(Text)`
  background-color: ${({ theme }) => theme.colors.brandGolf};
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 16px;
  text-transform: capitalize;
`

const MoreThumbnails = styled(Text)`
  display: none;

  ${media.desktop`
    width: 100%;
    cursor: pointer;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    z-index: 10;
    line-height: 16%;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.brandAlfaTransparent};
  `}
`

function Gallery({
  images,
  label,
  openImageOverlay,
  loading,
  imagesSetType,
  ...restProps
}) {
  const { t } = useTranslation()
  return (
    <Container {...restProps}>
      {loading && <LoadingIndicator />}
      {!loading &&
        Array.isArray(images) &&
        images
          .filter((image, index) => index <= maxThumbnails)
          .map((image, index) => {
            const firstImage = index === 0
            const firstLabel = firstImage ? label : null
            const src = firstImage ? image.src : image.thumb
            return (
              <Item
                key={index.toString()}
                onClick={() => openImageOverlay(index, imagesSetType)}
              >
                <GalleryImage
                  ratio={4 / 3}
                  src={src}
                  label={firstLabel}
                  alt=""
                  blurredBackground
                />
                {!loading &&
                images &&
                images.length - 1 > maxThumbnails &&
                index === maxThumbnails ? (
                  <MoreThumbnails
                    onClick={() =>
                      openImageOverlay(maxThumbnails, imagesSetType)
                    }
                    type="buttonLarge"
                    tag="button"
                    text={`+${images.length - maxThumbnails - 1}`}
                  />
                ) : null}
              </Item>
            )
          })}
      {!loading && images && !images.length && (
        <NoImagePlaceholder message={t('noCarPhotosAvailable')}>
          {label && (
            <Label
              type="input"
              text={label}
              data-test-e2e={formatDataTestE2eAttr('gallery-label', label)}
            />
          )}
        </NoImagePlaceholder>
      )}
    </Container>
  )
}

Gallery.propTypes = {
  images: PropTypes.array,
  label: PropTypes.string,
  loading: PropTypes.bool,
  openImageOverlay: PropTypes.func.isRequired,
  imagesSetType: PropTypes.oneOf(['regular-car-file', 'b2b-car-file']),
}

Gallery.defaultProps = {
  images: null,
  label: null,
  loading: false,
  imagesSetType: 'regular-car-file',
}

export default Gallery
