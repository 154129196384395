import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components/macro'

import { getAppTheme } from 'utilities/utils'
import { media } from 'utilities/styled'

import { AppThemes } from 'config/enums'

import Button from 'components/atoms/button'
import FramedImage from 'components/atoms/framed-image'
import Link from 'components/atoms/link'
import TextLink from 'components/atoms/text-link'
import ActionsMenu from 'components/molecules/actions-menu'
import Menu from 'components/organisms/menu'
import NavigationBarNews from 'components/molecules/navigation-bar-news'

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.brandBravo};
  display: flex;
  flex-direction: column;
  height: inherit;
  overflow-y: auto;
`

const Header = styled.div`
  margin: 0 24px;
  min-height: auto;
`

const StyledMenuContainer = styled.div`
  min-height: auto;
`

const UccLogo = styled(FramedImage)`
  height: 56px;
  margin: 40px auto
    ${({ showActionsMenu }) => (showActionsMenu ? '16px' : '40px')};
  width: 100px;

  @media (max-height: 900px) {
    margin: 20px auto
      ${({ showActionsMenu }) => (showActionsMenu ? '0' : '20px')};
  }
`

const AutopresentatieLogo = styled(FramedImage)`
  height: auto;
  margin: 40px auto
    ${({ showActionsMenu }) => (showActionsMenu ? '16px' : '30px')};
  width: 100%;

  @media (max-height: 900px) {
    margin: 32px auto
      ${({ showActionsMenu }) => (showActionsMenu ? '0' : '20px')};
  }
`

const ToDriveLogo = styled(FramedImage)`
  height: 26px;
  margin: 40px auto
    ${({ showActionsMenu }) => (showActionsMenu ? '16px' : '30px')};
  width: 130px;

  @media (max-height: 900px) {
    margin: 32px auto
      ${({ showActionsMenu }) => (showActionsMenu ? '0' : '20px')};
  }
`

const StyledActionsMenu = styled(ActionsMenu)`
  a {
    justify-content: flex-start;
    white-space: nowrap;
  }

  @media (max-height: 900px) {
    > * {
      margin-bottom: 8px;
    }
  }
`

const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.brandCharlie};
  height: 1px;
  margin: ${({ theme }) => theme.sizings.lvl3} 0;
  width: 100%;

  @media (max-height: 900px) {
    margin: ${({ theme }) => theme.sizings.lvl2} 0;
  }
`

const FooterLine = styled(Line)`
  margin: 0;
  @media (max-height: 900px) {
    display: none;
  }
`

const Footer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 24px;
  margin-top: auto;
  margin-bottom: 32px;
  gap: ${({ theme }) => theme.sizings.lvl2};
  flex-shrink: 0;

  @media (max-height: 900px) {
    gap: ${({ theme }) => theme.sizings.lvl1};
  }
`

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0;
  margin-top: ${({ theme }) => theme.sizings.lvl2};

  /**patching the minimal button variant's hover with custom styling */
  button {
    &:focus,
    &:hover {
      background-color: ${({ theme }) => theme.colors.actionsStandardText};
      color: ${({ theme }) => theme.colors.text};

      /* using * selector for the svg icon, otherwise it does not overrule
      the orginal css selector. Required for the focus selector to work. */
      * {
        fill: currentColor;
      }
    }
  }
`

const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
`

const StyledMenu = styled(Menu)`
  ${media.tablet`
    a {
      padding-left: ${({ theme }) => theme.sizeByFactor(3)};
    }
  `}
`

function NavigationBar({
  actionsMenuItems,
  className,
  items,
  logout,
  openPopupMenu,
  showPopout,
  setShowPopout,
  notificationCount,
  newsItem,
  ...restProps
}) {
  const { themeName } = getAppTheme()
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const { logoMark } = theme
  const showActionsMenu =
    Array.isArray(actionsMenuItems) && actionsMenuItems.length > 0

  return (
    <Container className={className} {...restProps}>
      <Header>
        <LogoLink to="/">
          {themeName === AppThemes.UCC && (
            <UccLogo
              alt="UCC"
              showActionsMenu={showActionsMenu}
              src={logoMark}
            />
          )}

          {themeName === AppThemes.AUTOPRESENTATIE && (
            <AutopresentatieLogo
              alt="UCC"
              showActionsMenu={showActionsMenu}
              src={logoMark}
            />
          )}

          {themeName === AppThemes.TODRIVE && (
            <ToDriveLogo
              alt="UCC"
              showActionsMenu={showActionsMenu}
              src={logoMark}
            />
          )}
        </LogoLink>
        {showActionsMenu && (
          <>
            <Line />
            <StyledActionsMenu items={actionsMenuItems} />
            <Line />
          </>
        )}
      </Header>
      <StyledMenuContainer data-test-e2e="navigation-bar">
        <StyledMenu
          backgroundColor="brandBravo"
          color="brandGolf"
          items={items}
          data-test-e2e="main-menu"
        />
      </StyledMenuContainer>
      <Footer data-test-e2e="footer">
        <FooterButtons data-test-e2e="footer-buttons">
          <Button
            testId="supportButton"
            icon="support"
            level="minimal"
            onClick={(e) => openPopupMenu(e, 'support')}
            data-test-e2e="button-support"
          />
          {theme.showNewsFeed && (
            <NavigationBarNews
              showPopout={showPopout}
              setShowPopout={setShowPopout}
              notificationCount={notificationCount}
              newsItem={newsItem}
            />
          )}
          <Button
            testId="settingsButton"
            icon="settings"
            level="minimal"
            onClick={(e) => openPopupMenu(e, 'settings')}
            data-test-e2e="button-settings"
          />
        </FooterButtons>
        <FooterLine />
        <TextLink
          onClick={logout}
          text={t('logout')}
          data-test-e2e="logout-link"
        />
      </Footer>
    </Container>
  )
}

NavigationBar.propTypes = {
  actionsMenuItems: PropTypes.array,
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  logout: PropTypes.func.isRequired,
  openPopupMenu: PropTypes.func.isRequired,
  showPopout: PropTypes.bool.isRequired,
  setShowPopout: PropTypes.func.isRequired,
  notificationCount: PropTypes.number.isRequired,
  newsItem: PropTypes.shape({
    img: PropTypes.string,
    title: PropTypes.string.isRequired,
    intro: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
}

NavigationBar.defaultProps = {
  actionsMenuItems: null,
  className: null,
  newsItem: null,
}

export default NavigationBar
