import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SettingsForm from 'components/redux-containers/settings-form-container'
import Button from 'components/atoms/button'
import OptionsBar from 'components/layouts/options-bar'
import { useSelector } from 'react-redux'

function LocationContactSettings({
  openOverlay,
  sendPawnListRequest,
  ...restProps
}) {
  const { t } = useTranslation()
  const isCspOnlyUser = useSelector(
    (state) =>
      state.data.auth &&
      state.data.auth.userDetails &&
      !state.data.auth.userDetails.pakket &&
      !state.data.auth.userDetails.marketview,
  )
  return (
    <>
      <OptionsBar>
        {isCspOnlyUser && (
          <Button
            onClick={() => {
              // allows user to retrieve a list containing the residual value for all cars:
              openOverlay('form', {
                formCategory: 'pawnList',
                formId: 'requestPawnList',
                storeFormId: 'requestPawnList',
                icon: 'preCalculation',
                onSubmit: (referenceDate) => {
                  sendPawnListRequest({
                    ...referenceDate,
                    // eslint-disable-next-line
                    vestiging_id: parseInt(restProps.params.vestiging_id, 10),
                  })
                },
                submitText: t('requestPawnList'),
                title: t('requestingResidualValueFromAutoTelex'),
              })
            }}
          >
            {t('pawnList')}
          </Button>
        )}
      </OptionsBar>
      <SettingsForm formId="companyLocationAddress" {...restProps} />
    </>
  )
}

LocationContactSettings.propTypes = {
  openOverlay: PropTypes.func.isRequired,
  sendPawnListRequest: PropTypes.func.isRequired,
}

LocationContactSettings.defaultProps = {}

export default LocationContactSettings
