import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import InlineBodyText from './inline-body-text'

const StyledText = styled(InlineBodyText)`
  background-color: ${({ $backgroundColor, theme }) =>
    theme.colors[$backgroundColor] || $backgroundColor};
  color: ${({ $color, theme }) =>
    $color ? theme.colors[$color] : theme.colors.text};
  display: inline-block;
  padding: ${({ theme, $size }) =>
    `${$size === 'small' ? theme.sizeByFactor(0.25) : theme.sizings.lvl0} ${theme.sizings.lvl1}`};
  border-radius: ${({ theme, $size }) =>
    $size === 'small' ? theme.sizings.lvl0 : theme.sizings.lvl1};
  font-size: ${({ theme, $size }) =>
    $size === 'small' ? theme.smallFontSize : theme.baseFontSize};
  line-height: ${({ theme, $size }) =>
    $size === 'small' ? theme.sizings.lvl2 : theme.sizeByFactor(2.25)};
  font-weight: ${({ $backgroundColor, $weight }) => $weight};
`

/**
 * A simple colored label
 */

const Label = ({
  backgroundColor,
  text,
  textColor,
  size,
  className,
  weight,
}) => (
  <StyledText
    $backgroundColor={backgroundColor}
    $size={size}
    className={className}
    $color={textColor}
    $weight={weight}
  >
    {text}
  </StyledText>
)

export const propTypes = {
  /** One of the color names from the theme */
  backgroundColor: PropTypes.string,
  /** Any text */
  text: PropTypes.string.isRequired,
  /** One of the color names from the theme */
  textColor: PropTypes.string,
  className: PropTypes.string,
  /** The size of the label */
  size: PropTypes.oneOf(['small', 'medium']),
  weight: PropTypes.oneOf([400, 500, 600, 700]),
}

Label.propTypes = propTypes

Label.defaultProps = {
  backgroundColor: 'stateNegative',
  className: undefined,
  size: 'medium',
  textColor: 'textOnDarkBackground',
  weight: 400,
}

export default Label
