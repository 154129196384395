// EXTERNAL
import React, { useState, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// INTERNAL
import InlineBodyText from 'components/atoms/inline-body-text'
import NewToolTip from 'components/atoms/new-tooltip'

const StyledBodyText = styled(InlineBodyText)`
  display: block;
`

const StyledBodyTextWithBorder = styled(InlineBodyText)`
  display: block;
  border-bottom: dashed 1px ${({ theme }) => theme.colors.brandBravo};
  text-overflow: ellipsis;
  overflow: hidden;
`

const StyledDiv = styled('div')`
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
  }
`

const OverflowingTextWithToolTip = ({ text }) => {
  const spanRef = useRef()
  const [isEllipsisActive, setIsEllipsisActive] = useState(false)

  useLayoutEffect(() => {
    const { current: span } = spanRef
    if (span.scrollWidth > span.clientWidth) {
      setIsEllipsisActive(true)
    }
  }, [spanRef])

  return (
    <StyledDiv ref={spanRef}>
      {isEllipsisActive ? (
        <NewToolTip title={text} placement="right" arrow width={260}>
          <StyledBodyTextWithBorder>{text}</StyledBodyTextWithBorder>
        </NewToolTip>
      ) : (
        <StyledBodyText>{text}</StyledBodyText>
      )}
    </StyledDiv>
  )
}

OverflowingTextWithToolTip.propTypes = {
  text: PropTypes.string.isRequired,
}

export default OverflowingTextWithToolTip
