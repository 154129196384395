import { useQuery } from '@apollo/client'
import { Field, Form, Formik } from 'formik'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { getMileagePerYearOptions, releaseCSPEmail } from 'config/data'
import {
  GET_PRICE_ADDITIONS_BY_LICENSE_PLATE,
  GET_WARRANTY_PROGRAMS_BY_LICENSE_PLATE,
} from 'config/graphql/csp'
import {
  CSPSalesFlowStep1Schema,
  CSPSalesFlowStep1WorkshopSchema,
} from 'config/validation-schemas'

import Button from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import TextLink from 'components/atoms/text-link'
import CspSalesBasicAdditionalInputField from 'components/molecules/csp-sales-basic-additional-input-field'
import {
  FormikDatePicker,
  FormikLabeledCheckBox,
  FormikSelectInput,
} from 'components/molecules/formik-field'
import Typography from 'components/molecules/typography'

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    padding-right: ${({ theme }) => theme.sizings.lvl3};
    &:last-child {
      padding-right: 0;
    }
  }
`
const StyledDropDownSelect = styled(FormikSelectInput)`
  min-width: 120px;
`
const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.sizings.lvl3};
`
const StyledParagraph = styled(Typography)`
  margin-top: 0;
  margin-bottom: 0;
  min-width: 120px;
`
const ErrorText = styled(Typography)`
  margin-right: ${({ theme }) => theme.sizings.lvl3};
`
const ErrorTextLink = styled(TextLink)`
  font-size: 12px;
  font-family: sans-serif;
  text-decoration: underline;
`

const CspSalesBasic = ({
  onSubmit,
  hideWarrantyProgramSelection,
  hasUCCLicense,
  payload,
  disabledDealer,
}) => {
  const { licensePlate, dealerId, currentMileage, hasFactoryWarranty, carId } =
    payload

  const { t } = useTranslation()
  const mileagePerYearOptions = getMileagePerYearOptions(t, dealerId)
  const [mileagePerYear, setMileagePerYear] = useState(
    payload && payload.mileagePerYear
      ? payload.mileagePerYear
      : mileagePerYearOptions[0].value,
  )
  const {
    data: warrantyProgramsData,
    loading: warrantyProgramsLoading,
    error: warrantyProgramsError,
    refetch: warrantyProgramsRefetch,
  } = useQuery(GET_WARRANTY_PROGRAMS_BY_LICENSE_PLATE, {
    variables: {
      dealerId,
      carId,
      licensePlate,
      currentMileage,
      hasFactoryWarranty,
    },
    skip: hideWarrantyProgramSelection || !licensePlate,
  })

  const hasLicensePlateOrCarId = licensePlate || carId
  const { data: priceAdditionsData } = useQuery(
    GET_PRICE_ADDITIONS_BY_LICENSE_PLATE,
    {
      variables: {
        dealerId,
        carId,
        licenseplate: licensePlate,
        mileagePerYear,
        currentMileage,
      },
      skip: !(hasLicensePlateOrCarId && mileagePerYear),
    },
  )

  const warrantyPrograms =
    !hideWarrantyProgramSelection && warrantyProgramsData
      ? warrantyProgramsData.warrantyProgramsByLicenseplate.map(
          (warrantyProgram) => warrantyProgram,
        )
      : []

  const warrantyProgramSelectOptions =
    !hideWarrantyProgramSelection && warrantyPrograms.length > 0
      ? warrantyPrograms.map((warrantyProgram) => ({
          label: warrantyProgram.name,
          value: warrantyProgram.id,
        }))
      : []

  const initialValues = {
    mileagePerYear,
    activationDate:
      payload && payload.activationDate
        ? moment(payload.activationDate)
        : moment(),
    noFactoryWarranty:
      payload &&
      Object.prototype.hasOwnProperty.call(payload, 'hasFactoryWarranty')
        ? !payload.hasFactoryWarranty
        : false,
    additionalFeeIds: [],
  }

  if (!hideWarrantyProgramSelection) {
    if (payload && payload.warrantyProgramId) {
      initialValues.warrantyProgramId = payload.warrantyProgramId
    } else if (warrantyPrograms && warrantyPrograms.length > 0) {
      initialValues.warrantyProgramId = warrantyPrograms[0].id
    } else {
      initialValues.warrantyProgramId = ''
    }
  }

  if (payload.additionalInputOptions?.length) {
    // Create empty object for additional inputs
    initialValues.additionalInput = {}

    // Add individual additional inputs to the array
    payload.additionalInputOptions.forEach((additionalInputField) => {
      initialValues.additionalInput[additionalInputField.id] =
        additionalInputField.choices[0].id
    })
  }

  let warrantyProgramsDisplayError = null
  let noWarrantyProgramsFound = false
  if (warrantyProgramsError) {
    warrantyProgramsDisplayError = t(
      'cspSalesFlow.steps.basicData.errorLoadingWarrantyPrograms',
    )
  } else if (
    !warrantyProgramsLoading &&
    warrantyProgramSelectOptions.length === 0
  ) {
    noWarrantyProgramsFound = true
  }

  const canSubmitForm =
    (!hideWarrantyProgramSelection &&
      !warrantyProgramsLoading &&
      !warrantyProgramsError &&
      !disabledDealer) ||
    hideWarrantyProgramSelection

  return (
    <div>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={
          hideWarrantyProgramSelection
            ? CSPSalesFlowStep1WorkshopSchema(t)
            : CSPSalesFlowStep1Schema(t)
        }
        onSubmit={(values) => {
          const { noFactoryWarranty, ...remainingValues } = values
          const selectedWarrantyProgram = warrantyPrograms.find(
            (warrantyProgram) =>
              // eslint-disable-next-line eqeqeq
              warrantyProgram.id == remainingValues.warrantyProgramId,
          )
          const submissionPayload = {
            ...remainingValues,
            activationDate: moment(values.activationDate).format('YYYY-MM-DD'),
            hasFactoryWarranty: !noFactoryWarranty,
          }
          if (!hideWarrantyProgramSelection && selectedWarrantyProgram) {
            submissionPayload.warrantyProviderId =
              selectedWarrantyProgram.warrantyProvider.id
            submissionPayload.warrantyProviderName =
              selectedWarrantyProgram.warrantyProvider.name
          }

          onSubmit(submissionPayload)
        }}
      >
        {() => (
          <Form>
            {payload.additionalInputOptions?.length ? (
              <>
                <Typography type="Level1Heading">
                  {t('cspSalesFlow.steps.basicData.headingAdditionalData')}
                </Typography>
                <FormRow>
                  {payload.additionalInputOptions?.map(
                    (additionalInputField) => (
                      <CspSalesBasicAdditionalInputField
                        key={`additional-input-field-${additionalInputField.id}`}
                        {...additionalInputField}
                      />
                    ),
                  )}
                </FormRow>
              </>
            ) : null}
            <Typography type="Level1Heading">
              {t('cspSalesFlow.steps.basicData.heading')}
            </Typography>
            <FormRow>
              <Field
                name="mileagePerYear"
                items={mileagePerYearOptions}
                label={t('cspSalesFlow.steps.basicData.mileagePerYear')}
                filled
                required
                disabled={disabledDealer}
                component={StyledDropDownSelect}
                onChange={setMileagePerYear}
              />

              <Field
                name="activationDate"
                label={
                  hideWarrantyProgramSelection || !hasUCCLicense
                    ? t('cspSalesFlow.steps.basicData.startingDate')
                    : t('cspSalesFlow.steps.basicData.activationDate')
                }
                filled
                required
                disabled={disabledDealer}
                component={FormikDatePicker}
              />

              {!hideWarrantyProgramSelection && (
                <>
                  {warrantyProgramsLoading && <LoadingIndicator size="small" />}
                  {noWarrantyProgramsFound && (
                    <StyledParagraph type="BodyParagraph">
                      {t(
                        'cspSalesFlow.steps.basicData.noRelevantWarrantyProgramsFound',
                      )}
                    </StyledParagraph>
                  )}
                  {warrantyProgramsDisplayError && (
                    <Typography type="ErrorText">
                      {warrantyProgramsDisplayError}
                    </Typography>
                  )}
                  {!warrantyProgramsLoading &&
                    !warrantyProgramsDisplayError &&
                    warrantyProgramSelectOptions.length > 0 && (
                      <Field
                        name="warrantyProgramId"
                        items={warrantyProgramSelectOptions}
                        label={t(
                          'cspSalesFlow.steps.basicData.warrantyProgram',
                        )}
                        filled
                        disabled={disabledDealer}
                        component={StyledDropDownSelect}
                      />
                    )}
                </>
              )}
            </FormRow>
            {!hideWarrantyProgramSelection && (
              <FormRow>
                <Field
                  name="noFactoryWarranty"
                  label={t('cspSalesFlow.steps.basicData.noFactoryWarranty')}
                  component={FormikLabeledCheckBox}
                  onChange={(val) =>
                    warrantyProgramsRefetch({
                      dealerId,
                      licensePlate,
                      currentMileage,
                      hasFactoryWarranty: !val,
                    })
                  }
                  disabled={disabledDealer}
                />
              </FormRow>
            )}
            {Array.isArray(priceAdditionsData?.priceAdditionByLicenseplate) &&
              priceAdditionsData.priceAdditionByLicenseplate.length > 0 && (
                <>
                  <Typography
                    id="additions"
                    styledAs="subheading"
                    type="Level2Heading"
                  >
                    Optionele toeslagen
                  </Typography>
                  <Typography type="ExplanationParagraph">
                    Geef aan of onderstaande optionele toeslagen van toepassing
                    zijn.
                  </Typography>
                  <div role="group" aria-labelledby="additions">
                    {priceAdditionsData.priceAdditionByLicenseplate.map(
                      (addition) => (
                        <Field
                          key={addition.id}
                          value={addition.id}
                          name="additionalFeeIds"
                          label={addition.reason}
                          price={addition.amount}
                          wrapLabelOnLineBreak
                          filled
                          component={FormikLabeledCheckBox}
                        />
                      ),
                    )}
                  </div>
                </>
              )}
            <ActionsContainer>
              {disabledDealer && (
                <ErrorText type="ErrorText">
                  {t('carServicePlanDashboard.disabledCspMessage')}
                  <ErrorTextLink
                    text={releaseCSPEmail}
                    href={`mailto:${releaseCSPEmail}`}
                  />
                </ErrorText>
              )}
              <Button
                disabled={!canSubmitForm}
                level="cta"
                type="submit"
                data-test-e2e="button-csp-next"
              >
                {t('cspSalesFlow.steps.basicData.nextButtonLabel')}
              </Button>
            </ActionsContainer>
          </Form>
        )}
      </Formik>
    </div>
  )
}

CspSalesBasic.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hideWarrantyProgramSelection: PropTypes.bool,
  hasUCCLicense: PropTypes.bool,
  disabledDealer: PropTypes.bool,
  payload: PropTypes.object.isRequired,
}

CspSalesBasic.defaultProps = {
  hasUCCLicense: false,
  disabledDealer: false,
  hideWarrantyProgramSelection: false,
}

export default CspSalesBasic
