import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Text from 'components/atoms/text'

const LEVELS = ['positive', 'negative', 'neutral']

const StyledPill = styled(Text)`
  border: 1px solid;
  border-radius: 1em;
  display: inline-block;
  padding: 2px 8px;
`

function Pill({ className, text, level }) {
  const colorMap = {
    [LEVELS[0]]: 'statePositive',
    [LEVELS[1]]: 'stateNegative',
    [LEVELS[2]]: 'inactiveTextColor',
  }
  const color = colorMap[level]
  return (
    <StyledPill
      className={className}
      color={color}
      level={level}
      text={text}
      type="count"
    />
  )
}

Pill.propTypes = {
  className: PropTypes.string,
  level: PropTypes.oneOf(LEVELS).isRequired,
  text: PropTypes.string.isRequired,
}

Pill.defaultProps = {
  className: null,
}

export default Pill
