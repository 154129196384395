import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import NoImagePlaceholder from 'components/atoms/no-image-placeholder'

const StyledImg = styled.img`
  max-width: 100%;
  vertical-align: bottom;
`

/**
 * A component for rendering an image that will render the NoImagePlaceholder
 * component if an error occurs when fetching the image.
 *

 */
function FramedImage({ alt, src, errorMessage, className, ...restProps }) {
  const [inError, setInError] = useState(!src)
  return (
    <div className={className}>
      {inError && errorMessage ? (
        <NoImagePlaceholder message={errorMessage} />
      ) : (
        <StyledImg
          alt={alt}
          src={src}
          onError={() => {
            setInError(true)
          }}
          {...restProps}
        />
      )}
    </div>
  )
}

FramedImage.propTypes = {
  className: PropTypes.string,
  /** Alt text */
  alt: PropTypes.string.isRequired,
  /** Source URL (is something not there) */
  src: PropTypes.string,
  /** Error message to be shown in the NoImagePlaceholder */
  errorMessage: PropTypes.string,
}

FramedImage.defaultProps = {
  errorMessage: '',
  className: null,
  src: '',
}

export default FramedImage
