// EXTERNAL
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// INTERNAL
import BodyParagraph from 'components/atoms/body-paragraph'

const Container = styled(BodyParagraph)`
  display: flex;
  justify-content: space-between;
  font-weight: ${({ styles }) => (styles.includes('BOLD') ? 700 : 300)};
  font-style: ${({ styles }) => styles.includes('ITALIC') && 'italic'};
  text-decoration: ${({ styles }) =>
    styles.includes('UNDERLINE') && 'underline'};
  text-decoration: ${({ styles }) =>
    styles.includes('STRIKETHROUGH') && 'line-through'};
  & span {
    text-align: left;
  }
  & span:last-child {
    flex-shrink: 0;
  }
`

const BpmCalculationResultLines = ({ lines }) =>
  Array.isArray(lines)
    ? lines.map((line, index) => (
        <Container key={`line-${index.toString()}`} styles={line.styles}>
          <span>{line.label}</span>
          <span>{line.value}</span>
        </Container>
      ))
    : null

BpmCalculationResultLines.propTypes = {
  lines: PropTypes.array.isRequired,
}

export default BpmCalculationResultLines
