import { gql } from '@apollo/client'

export default gql`
  fragment document on Document {
    id
    type
    fullUri
    status
    documentId
    contractId
    filename
  }
`
