import { connect } from 'react-redux'

import {
  getCarfile,
  getBids,
  getCarAssets,
  updateCarfile,
  postDynamicEndpoint,
  clearData,
  getDamage,
} from 'redux/actions/data'
import { openImageOverlay } from 'redux/actions/ui'
import { getForms } from 'redux/actions/forms'

import CarShareCarFile from 'components/views/car-share-car-file/index'

const mapStateToProps = ({ data, forms }) => ({
  carAssets: data.carAssets,
  carFile: data.carfile,
  carDamage: data.damage,
  forms,
  dynamicEndpoint: data.dynamicEndpoint,
})

const mapDispatchToProps = {
  clearData,
  getDamage,
  getCarfile,
  getBids,
  getCarAssets,
  getForms,
  updateCarfile,
  openImageOverlay,
  postDynamicEndpoint,
}

export default connect(mapStateToProps, mapDispatchToProps)(CarShareCarFile)
