import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Typography from 'components/molecules/typography'

const Text = styled(Typography)`
  display: inline-block;
  height: 100px;
  text-align: left;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  margin-top: 0;
  margin-bottom: 0;
`

const VerticalTableColumnHeader = ({ children, ...restProps }) => (
  <Text {...restProps} type="ExplanationParagraph">
    {children}
  </Text>
)

VerticalTableColumnHeader.propTypes = {
  children: PropTypes.string.isRequired,
}

export default VerticalTableColumnHeader
