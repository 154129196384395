import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components/macro'

import { media } from 'utilities/styled'
import { getAppTheme } from 'utilities/utils'

import Text from 'components/atoms/text'

const Section = styled.section`
  background-image: url(${({ theme }) => theme.login.backgroundImage});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 48px;
  height: 100%;
  width: 100%;

  > * {
    text-shadow: ${({ theme }) => theme.textShadow};
  }
`

const Title = styled(Text)`
  margin-bottom: ${({ $madeThanksTo, theme }) =>
    $madeThanksTo ? theme.sizings.lvl5 : theme.sizings.lvl8};
  font-size: 5vw;
  line-height: 0.85em;

  ${({ $forceLowerCase }) => $forceLowerCase && 'text-transform: lowercase;'};

  ${media.tv`
    font-size: 80px;
    line-height: 68px;
  `}
`

function PayoffPoster() {
  const { t } = useTranslation()
  const { themeNameCamelCase } = getAppTheme()
  const theme = useContext(ThemeContext)

  const showMadeThanksTo = theme.login.showMadeThanksTo // Show / hide the "madeThanksTo" message. In whitelabelled apps it's shown, in UCC it isn't
  const madeThanksTo = t(
    `themeOptions.${themeNameCamelCase}.login.madeThanksTo`,
  )
  const forceLowerCase = !!theme.login.payoffForceLowercase

  return (
    <Section>
      <Title
        $forceLowerCase={forceLowerCase}
        $madeThanksTo={showMadeThanksTo}
        color="brandGolf"
        text={t(`themeOptions.${themeNameCamelCase}.login.payoff`)}
        type="display"
      />
      {showMadeThanksTo && (
        <Text type="h3" text={madeThanksTo} color="brandGolf" />
      )}
    </Section>
  )
}

export default PayoffPoster
