import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components/macro'
import { useMediaLayout } from 'use-media'

import { media } from 'utilities/styled'

import LoadingButton from 'components/atoms/loading-button'
import Text from 'components/atoms/text'
import EmphasisTag from 'components/atoms/emphasis-tag'
import InlineBodyText from 'components/atoms/inline-body-text'
import Typography from 'components/molecules/typography'
import NewExpandableRow from 'components/molecules/new-expandable-row'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.brandFoxtrot};
`

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => theme.sizings.lvl2};
  padding: ${({ theme }) => theme.sizings.lvl2}
    ${({ theme }) => theme.sizings.lvl2} 0;
  justify-content: space-between;

  > div:first-of-type {
    flex-grow: 1;
  }

  ${media.tablet`  
    align-items: center;
    padding: ${({ theme }) => theme.sizings.lvl3} ${({ theme }) => theme.sizings.lvl3} 0;
  `}
`

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`

const Line = styled.div`
  background: ${({ theme }) => theme.colors.brandDelta};
  height: 1px;
  margin: ${({ theme }) => theme.sizings.lvl3} 0 0 0;
  width: 100%;
`

const Title = styled(Typography)`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

const DetailsContainer = styled.div`
  padding: 0 ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
    padding: 0 ${({ theme }) => theme.sizings.lvl3};
  `}
`

const DetailContainer = styled.div`
  display: flex;
`
const DetailText = styled(InlineBodyText)`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledNewExpandableRow = styled(NewExpandableRow)`
  border-top: none;

  h5 {
    font-weight: 300;
    font-size: ${({ theme }) => theme.sizeByFactor(1.75)};
  }

  > div:nth-child(2) {
    display: grid;
    grid-gap: ${({ theme }) => theme.sizings.lvl1};
    grid-template-columns: repeat(2, 1fr);

    ${media.tablet`
      grid-template-columns: repeat(4, 1fr);
    `}
  }
`

const StyledLoadingButton = styled(LoadingButton)`
  min-width: ${({ theme }) => theme.sizeByFactor(14)};

  ${media.tablet`
    min-width: ${({ theme }) => theme.sizeByFactor(18)};
  `}
`

const getVariantForDetail = (detail) => {
  if (detail.uniek) {
    return 'bold'
  }
  return detail.selected || detail.val ? 'normal' : 'notPresent'
}

function DetailsCard({
  className,
  details,
  heading,
  index,
  onSelect,
  selectText,
  disableSelectButton,
  isLoading,
  loadingText,
  subtitle,
  title,
  emphasisText,
  ...restProps
}) {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })

  const hasUniqueAccessoires = details.some((detail) => !!detail.uniek)
  const uniqueAccessoires = details.filter((detail) => !!detail.uniek)

  const hasRegularAccessoires = details.some((detail) => !detail.uniek)
  const regularAccessoires = details.filter((detail) => !detail.uniek)

  return (
    <Container className={className} {...restProps}>
      {emphasisText && (
        <EmphasisTag data-test-e2e="emphasis-tag">{emphasisText}</EmphasisTag>
      )}
      <Header>
        <div>
          <Title type="BodyParagraph" data-test-e2e="title">
            {title}
          </Title>
          {subtitle && (
            <Title type="BodyParagraph" data-test-e2e="subtitle">
              {subtitle}
            </Title>
          )}
          <Text text={heading} type="h5" data-test-e2e="heading" />
        </div>
        <ActionsContainer data-test-e2e="actions-container">
          <StyledLoadingButton
            level="cta"
            onClick={() => onSelect(index)}
            text={selectText || t('select')}
            isLoading={isLoading}
            loadingText={isMobile ? '' : loadingText}
            disabled={disableSelectButton}
            data-test-e2e="select-button"
          />
        </ActionsContainer>
      </Header>

      <Line />

      {details && (
        <DetailsContainer data-test-e2e="details-container">
          {hasUniqueAccessoires && (
            <StyledNewExpandableRow
              title={t('detailsUnique')}
              isInitiallyExpanded
            >
              {uniqueAccessoires.map((detail, idx) => (
                <DetailContainer
                  key={idx.toString()}
                  data-test-e2e="detail-container"
                >
                  <>
                    {detail.key && (
                      <DetailText variant="normal">
                        {`${detail.key}: `}
                      </DetailText>
                    )}
                    <DetailText variant={getVariantForDetail(detail)}>
                      {detail.value || detail.val}
                    </DetailText>
                  </>
                </DetailContainer>
              ))}
            </StyledNewExpandableRow>
          )}

          {hasRegularAccessoires && (
            <StyledNewExpandableRow title={t('detailsRegular')}>
              {regularAccessoires.map((detail, idx) => (
                <DetailContainer
                  key={idx.toString()}
                  data-test-e2e="detail-container"
                >
                  <>
                    {detail.key && (
                      <DetailText variant="normal">
                        {`${detail.key}: `}
                      </DetailText>
                    )}
                    <DetailText variant={getVariantForDetail(detail)}>
                      {detail.value || detail.val}
                    </DetailText>
                  </>
                </DetailContainer>
              ))}
            </StyledNewExpandableRow>
          )}
        </DetailsContainer>
      )}
    </Container>
  )
}

DetailsCard.propTypes = {
  className: PropTypes.string,
  details: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  heading: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectText: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  disableSelectButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  emphasisText: PropTypes.string,
}

DetailsCard.defaultProps = {
  className: null,
  selectText: null,
  isLoading: null,
  loadingText: null,
  subtitle: null,
  emphasisText: '',
}

export default DetailsCard
