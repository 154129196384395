import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import NewTextInput from 'components/atoms/new-text-input'

const TypeaheadTextInput = ({
  className,
  options,
  getOptionLabel,
  onChange,
  label,
  filled,
  ...restProps
}) => (
  <Autocomplete
    className={className}
    options={options}
    getOptionLabel={getOptionLabel}
    onChange={(e, val) => onChange(val)}
    renderInput={(params) => (
      <NewTextInput
        params={params}
        inputProps={params.inputProps}
        filled={filled}
        label={label}
        data-test-e2e="typeahead"
      />
    )}
    {...restProps}
  />
)

TypeaheadTextInput.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  filled: PropTypes.bool,
  label: PropTypes.string,
}

TypeaheadTextInput.defaultProps = {
  className: undefined,
  getOptionLabel: undefined,
  filled: true,
  label: undefined,
}

export default TypeaheadTextInput
