import { connect } from 'react-redux'

import {
  getPortals,
  getActivePortals,
  getPortalPreaddConfirmation,
  addPortal,
  removePortal,
  addAllVehiclesToPortal,
  removeAllVehiclesFromPortal,
  setPortalDefault,
} from 'redux/actions/data'
import PortalsSettings from '../views/settings/portals-settings'

const mapStateToProps = ({ data }) => ({
  portals: data.portals,
  activePortals: data.activePortals,
  portalPreaddConfirmation: data.portalPreaddConfirmation,
})
const mapDispatchToProps = {
  getPortals,
  getActivePortals,
  getPortalPreaddConfirmation,
  addPortal,
  removePortal,
  addAllVehiclesToPortal,
  removeAllVehiclesFromPortal,
  setPortalDefault,
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalsSettings)
