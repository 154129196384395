import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import DealerDetails from './dealer-details'
import WarrantyProgramDetails from '../warranty-programs-overview/warranty-program-details'
import WarrantyProgramLink from '../warranty-programs-overview/warranty-program-link'
import DealersOverview from './dealers-overview'
import PriceMatrixNew from '../price-matrices-overview/price-matrix-new'
import PriceMatrixDetails from '../price-matrices-overview/price-matrix-details'
import { DealerProvider } from 'contexts/dealer'

function CarServicePlanAdminDealers({ basePath }) {
  return (
    <>
      <DealerProvider>
        <Switch>
          <Route path={`${basePath}`} exact component={DealersOverview} />
          <Route
            path={`${basePath}/details/:dealerId`}
            exact
            component={DealerDetails}
          />
          <Route
            path={`${basePath}/details/:dealerId/warranty-program/:warrantyProgramId`}
            exact
            component={() => <WarrantyProgramDetails initiallyDisabled />}
          />
          <Route
            path={`${basePath}/details/:dealerId/warranty-program-link`}
            exact
            component={WarrantyProgramLink}
          />
          <Route
            path={`${basePath}/details/:dealerId/price-matrix/new`}
            exact
            component={PriceMatrixNew}
          />
          <Route
            path={`${basePath}/details/:dealerId/price-matrix/:priceMatrixId`}
            exact
            component={() => <PriceMatrixDetails initiallyDisabled />}
          />
        </Switch>
      </DealerProvider>
    </>
  )
}

CarServicePlanAdminDealers.propTypes = {
  basePath: PropTypes.string.isRequired,
}

export default CarServicePlanAdminDealers
