import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'

const StyledEmphasisTag = styled.em`
  background-color: ${(props) => props.theme.colors.darkBackground};
  height: ${(props) => props.theme.sizings.lvl4};
  line-height: ${(props) => props.theme.sizings.lvl4};
  padding: 0 ${(props) => props.theme.sizings.lvl2};
  color: ${(props) => props.theme.colors.brandGolf};
  font-size: ${({ theme }) => theme.baseFontSize};
  font-family: ${({ theme }) => theme.font};
  font-weight: 300;
  display: block;
  text-align: center;
  ${media.tablet`
  display: inline-block;
  text-align: left;
  height: ${(props) => props.theme.sizings.lvl5};
  line-height: ${(props) => props.theme.sizings.lvl5};
`}
`

function EmphasisTag({ className, children, ...restProps }) {
  return (
    <StyledEmphasisTag className={className} {...restProps}>
      {children}
    </StyledEmphasisTag>
  )
}

EmphasisTag.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
}

EmphasisTag.defaultProps = {
  className: null,
}

export default EmphasisTag
