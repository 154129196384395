import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Text from 'components/atoms/text'

const Container = styled.div`
  margin-top: ${({ theme }) => theme.sizings.lvl2};
`

/**
 * Header for use above tabs in market view

 */
function TabHeader({ text }) {
  return (
    <Container>
      <Text type="h2b" text={text} />
    </Container>
  )
}

TabHeader.propTypes = {
  /**
   * Text value for header
   */
  text: PropTypes.string,
}

TabHeader.defaultProps = {
  text: null,
}

export default TabHeader
