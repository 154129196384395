import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import CompareTopicsChart from 'components/organisms/compare-topics-chart'

function SalesPotential({ data }) {
  const { t } = useTranslation()
  function toPercentage(value) {
    if (!value) {
      return ''
    }
    if (value > 0) {
      return `+ ${value}%`
    }
    return `${value}%`
  }

  // map the data into a more simple structure:
  const simplified = {
    myOwn: {
      value: data.competitivePosition.length
        ? data.competitivePosition[0].own
        : 0,
    },
    potBasedOnTargets: {
      value:
        data.salesPotential && data.salesPotential.length
          ? data.salesPotential[0].salesPotential
          : 0,
      percentage:
        data.salesPotential && data.salesPotential.length
          ? data.salesPotential[0].salesPotentialPercentage
          : null,
    },
    salesComparisonGroup: {
      value:
        data.competitivePositionComparisongroup &&
        data.competitivePositionComparisongroup.length
          ? data.competitivePositionComparisongroup[0].comparisongroupAverage
          : 0,
      percentage:
        data.competitivePositionComparisongroup &&
        data.competitivePositionComparisongroup.length
          ? data.competitivePositionComparisongroup[0]
              .competitivePositionComparisonGroupPercentage
          : null,
    },
    potBasedOnComparisonGroup: {
      value:
        data.salesPotentialComparisongroup &&
        data.salesPotentialComparisongroup.length
          ? data.salesPotentialComparisongroup[0].salesPotentialComparisonGroup
          : 0,
      percentage:
        data.salesPotentialComparisongroup &&
        data.salesPotentialComparisongroup.length
          ? data.salesPotentialComparisongroup[0]
              .salesPotentialComparisonGroupPercentage
          : 0,
    },
  }

  // map data into the desired structure for itemsToCompare prop of CompareTopicsChart
  const preparedData = [
    {
      label: t('mySalesLabel'),
      color: 'chartsMyself',
      value: simplified.myOwn.value,
    },
    {
      label: t('potentialBasedOnTargetLabel'),
      extendedLabel: t('salesPotentialBasedOnTarget'),
      color: 'chartsTarget',
      value: simplified.potBasedOnTargets.value,
      additionalValue: toPercentage(simplified.potBasedOnTargets.percentage),
    },
    {
      label: t('salesComparisonGroupLabel'),
      color: 'chartsComparisonGroup',
      value: simplified.salesComparisonGroup.value,
      additionalValue: toPercentage(simplified.salesComparisonGroup.percentage),
    },
    {
      label: t('potentialBasedOnComparisonGroupLabel'),
      color: 'chartsOtherTarget',
      value: simplified.potBasedOnComparisonGroup.value,
      additionalValue: toPercentage(
        simplified.potBasedOnComparisonGroup.percentage,
      ),
    },
  ]
  return (
    <CompareTopicsChart
      heading={t('salesPotential')}
      itemsToCompare={preparedData}
      unit={t('unitLabelCars')}
    />
  )
}

SalesPotential.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SalesPotential
