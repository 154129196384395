import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import {
  getObjectFromQueryString,
  handleChangeQuery,
  scrollToTop,
} from 'utilities/utils'
import SearchResultsOverview from 'components/layouts/search-results-overview'
import SearchResults from 'components/organisms/search-results'
import StockHeader from 'components/organisms/stock-header'

const StyledStockHeader = styled(StockHeader)`
  margin-bottom: 20px;
`

function SearchResultsView({
  location,
  getSearchResults,
  toggleSalesView,
  salesView,
  searchResults,
  history,
}) {
  const { t } = useTranslation()
  const queryFilters = getObjectFromQueryString(location.search)

  // determine which page we are one from query string:
  const page = queryFilters && (queryFilters.page || 1)
  const resultsPerPage = 36

  function handleFilterUpdate(filter, value) {
    handleChangeQuery(location, history, filter, value)
  }

  function handleChangePage(newPage) {
    handleFilterUpdate('page', newPage)
    // scroll to top because that's were the new results will be:
    scrollToTop()
  }

  const params = getObjectFromQueryString(location.search)
  const { q } = params

  return (
    <SearchResultsOverview
      pageTitle={t('searchResults')}
      mainTitle={q}
      subTitle={t('searchResults')}
      showSearchbox
    >
      <StyledStockHeader
        salesView={salesView}
        toggleSalesView={toggleSalesView}
        total={searchResults.data && searchResults.data.total}
        hideMobileFiltersButton
      />
      <SearchResults
        getSearchResults={getSearchResults}
        page={Number(page)}
        q={q}
        resultsPerPage={resultsPerPage}
        salesView={salesView}
        searchResults={searchResults}
        setPage={handleChangePage}
      />
    </SearchResultsOverview>
  )
}

SearchResultsView.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getSearchResults: PropTypes.func.isRequired,
  salesView: PropTypes.bool,
  searchResults: PropTypes.object,
  toggleSalesView: PropTypes.func,
}

SearchResultsView.defaultProps = {
  salesView: false,
  searchResults: {},
  toggleSalesView: () => {},
}

export default SearchResultsView
