import { gql } from '@apollo/client'

export const REQUEST_APPROVAL = gql`
  query requestApproval($warrantyProviderId: ID!) {
    requestApproval(warrantyProviderId: $warrantyProviderId) {
      approvalRequired
      message
    }
  }
`

export const HANDLE_APPROVAL = gql`
  mutation handleApproval($dealerId: ID!, $calculationId: ID!) {
    handleApproval(dealerId: $dealerId, calculationId: $calculationId) {
      id
    }
  }
`
