import moment from 'moment'
import { marketViewFilterIds } from 'config/data'
import { getYearOptions } from 'utilities/utils'
import storage from 'utilities/storage'

/*
 * Function to map the reqponse of the meta data querie to
 * an object with the input structure for our Filters component.
 */
export function getFiltersFromMetaData(metaData, selectedParams) {
  const currentYear = moment().year()
  const makes = metaData.makes || []
  const models = metaData.models || []
  const fuelTypes = metaData.fueltypes || []
  const bodies = metaData.bodies || []
  const comparisongroups = metaData.comparisongroups || []
  const areas = metaData.areas || []
  const companies = metaData.companies || []

  const filters = [
    {
      type: 'select',
      label: 'make',
      id: marketViewFilterIds.make,
      items: makes.map((make) => ({
        label: make.make,
        value: make.make,
      })),
    },
    {
      type: 'select',
      label: 'model',
      id: marketViewFilterIds.model,
      items: models.map((model) => ({
        label: model.model,
        value: model.model,
      })),
    },
    {
      type: 'fromTo',
      label: 'age',
      fromId: marketViewFilterIds.buildYearFrom,
      fromLabel: 'from',
      fromOptions: getYearOptions(
        1940,
        // If buildYearTo is set make sure no higher buildYearFrom
        // can be selected
        selectedParams[marketViewFilterIds.buildYearTo] || currentYear,
      ),
      toId: marketViewFilterIds.buildYearTo,
      toLabel: 'till',
      toOptions: getYearOptions(
        // If buildYearFrom is set make sure no lower buildYearTo
        // can be selected
        selectedParams[marketViewFilterIds.buildYearFrom] || 1940,
        currentYear,
      ),
    },
    {
      type: 'text',
      label: 'goal',
      id: marketViewFilterIds.competitivePosition,
      inputType: 'number',
      placeholder: 'competitivePosition',
      unit: '%',
    },
    // Since RDW stores ownership changes:
    {
      type: 'dateRange',
      id: 'timePeriod',
      label: 'showOwnershipChangesInPeriod',
      fromId: marketViewFilterIds.datefrom,
      toId: marketViewFilterIds.dateto,
      title: 'showOwnershipChangesInPeriod',
    },
    {
      type: 'radio',
      label: 'company',
      id: marketViewFilterIds.company,
      items: companies.map((company) => ({
        label: company.bedrijfsnaam,
        value: company.dealernr,
      })),
    },
    {
      type: 'radio',
      label: 'comparisongroup',
      id: marketViewFilterIds.comparisonGroup,
      items: comparisongroups.map((comparisongroup) => ({
        label: comparisongroup.comparisongroupName,
        value: comparisongroup.comparisongroup,
      })),
    },
    {
      type: 'radio',
      label: 'area',
      id: marketViewFilterIds.area,
      items: [
        ...areas.map((area) => ({
          label: area.areaName,
          value: area.area,
        })),
        {
          label: 'radius10Km',
          value: '10',
        },
        {
          label: 'radius25Km',
          value: '25',
        },
      ],
    },
    {
      type: 'select',
      label: marketViewFilterIds.fuel,
      id: 'fuel',
      items: fuelTypes.map((fuelType) => ({
        label: fuelType.fueltype,
        value: fuelType.fueltype,
      })),
    },
    {
      type: 'select',
      label: marketViewFilterIds.body,
      id: 'body',
      items: bodies.map((body) => ({
        label: body.body,
        value: body.body,
      })),
    },
    {
      type: 'radio',
      label: 'destination',
      id: marketViewFilterIds.destination,
      items: [
        {
          label: 'allEndusers',
          value: 'A',
        },
        {
          label: 'private',
          value: 'N',
        },
        {
          label: 'companies',
          value: 'R',
        },
      ],
    },
  ]

  return filters
}

/**
 * Function to map an object to an array with objects with key
 * value pairs
 *
 * Input:
 * {
 *   keyName1: 'value1',
 *   keyName2: 'value2',
 * }
 *
 * Output:
 * [{
 *   key: "keyName1",
 *   value: "value1",
 * }, {
 *   key: "keyName2",
 *   value: "value2",
 * }]
 */
export function getInputParamsFromParams(params) {
  const inputParams = Object.entries(params).map(([key, value]) => ({
    key,
    value,
  }))

  return inputParams
}

export function translateParamOptions(paramOptions, translate) {
  return paramOptions.map((paramOption) => ({
    ...paramOption,
    items:
      paramOption.items &&
      paramOption.items.map((item) => ({
        ...item,
        label: translate(item.label),
      })),
    label: translate(paramOption.label),
    fromLabel: translate(paramOption.fromLabel),
    placeholder: translate(paramOption.placeholder),
    toLabel: translate(paramOption.toLabel),
  }))
}

/**
 * Returns an object of all values in storage with the marketViewStorageIdsPrefix.
 */
export function getSelectedParamsFromStorage(storagePrefix) {
  const permanentValues = storage.getAllPermanentValues()

  return Object.entries(permanentValues).reduce((result, [key, value]) => {
    if (key.indexOf(storagePrefix) === 0 && value !== 'null') {
      return {
        ...result,
        [key.replace(storagePrefix, '')]: value,
      }
    }
    return result
  }, {})
}
