import { connect } from 'react-redux'
import { postDynamicEndpoint } from 'redux/actions/data'
import {
  getDeliveryPackages,
  deleteDeliveryPackage,
} from 'redux/actions/settings'
import { openOverlay } from 'redux/actions/ui'
import DeliveryPackagesSettings from '../views/settings/delivery-packages-settings'

const mapStateToProps = ({ data, settings }) => ({
  deliveryPackages: settings.deliveryPackages,
  dynamicEndpoint: data.dynamicEndpoint,
})

const mapDispatchToProps = {
  getDeliveryPackages,
  openOverlay,
  deleteDeliveryPackage,
  postDynamicEndpoint,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryPackagesSettings)
