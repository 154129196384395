import { connect } from 'react-redux'
import DynamicFormOverlay from '../organisms/dynamic-form-overlay'

const mapStateToProps = ({ data }) => ({
  dynamicEndpoint: data.dynamicEndpoint,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicFormOverlay)
