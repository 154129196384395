import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const FlexedInlinedItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: ${({ hasCenteredChildren }) =>
    hasCenteredChildren ? 'center' : 'flex-start'};
  margin: ${({ hasVerticalMargins, theme }) =>
      hasVerticalMargins ? theme.sizings.lvl2 : 0}
    0;

  > * {
    flex: 0 0 auto;
    margin-left: ${({ theme, hasCenteredChildren }) =>
      hasCenteredChildren ? theme.sizings.lvl2 : 0};
    margin-right: ${({ theme, hasCenteredChildren }) =>
      hasCenteredChildren ? theme.sizings.lvl2 : theme.sizings.lvl4};
  }
`

/**
 * A layout to simply place boxes of content side by side in a horizontal/inlined way
 */
const InlinedItems = ({
  children,
  className,
  hasVerticalMargins,
  hasCenteredChildren,
}) => (
  <FlexedInlinedItems
    className={className}
    hasVerticalMargins={hasVerticalMargins}
    hasCenteredChildren={hasCenteredChildren}
  >
    {children}
  </FlexedInlinedItems>
)

InlinedItems.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasCenteredChildren: PropTypes.bool,
  hasVerticalMargins: PropTypes.bool,
}

InlinedItems.defaultProps = {
  className: null,
  hasVerticalMargins: true,
  hasCenteredChildren: false,
}

export default InlinedItems
