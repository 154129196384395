import MediaForm from 'components/organisms/media-form'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

function ExpectedPhotoForm({
  expectedPhotos,
  addExpectedPhotos,
  getExpectedPhotos,
  deleteExpectedPhotos,
  sortExpectedPhotos,
  locationId,
}) {
  const loading = expectedPhotos && expectedPhotos.loading
  const photoData = expectedPhotos?.[locationId]?.data || []
  const items = photoData.map(({ url, ...rest }) => ({
    src: url,
    name: url,
    type: 'image',
    ...rest,
  }))

  useEffect(() => {
    getExpectedPhotos({ id: locationId })
  }, [getExpectedPhotos, locationId])

  function handleUpload(image) {
    addExpectedPhotos({
      id: locationId,
      image,
    })
  }

  function handleDelete(selectedImageIds) {
    deleteExpectedPhotos({ id: locationId, imageIds: selectedImageIds })
  }

  function handleSort(newSortOrderData) {
    sortExpectedPhotos({ id: locationId, sortOrder: newSortOrderData })
  }

  return (
    <>
      {!loading && items && (
        <MediaForm
          level="standard"
          items={items}
          onUpload={handleUpload}
          onDelete={handleDelete}
          onSort={handleSort}
          type="images"
          enableReinitialize
        />
      )}
    </>
  )
}

ExpectedPhotoForm.propTypes = {
  expectedPhotos: PropTypes.object,
  locationId: PropTypes.string.isRequired,
  addExpectedPhotos: PropTypes.func.isRequired,
  getExpectedPhotos: PropTypes.func.isRequired,
  deleteExpectedPhotos: PropTypes.func.isRequired,
  sortExpectedPhotos: PropTypes.func.isRequired,
}

ExpectedPhotoForm.defaultProps = {}

export default ExpectedPhotoForm
