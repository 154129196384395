import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import MomentPropTypes from 'react-moment-proptypes'
import { ThemeContext } from 'styled-components'
import { Chart, Bar } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'
import 'chartjs-plugin-zoom'

import { stackTotalizer } from 'utilities/chart'

/**
 * Chart that shows data both in lines and bars.
 *
 * Wrapper around the
 * [Chart.js line chart](https://www.chartjs.org/docs/latest/charts/line.html)
 * using [react-chartjs-2](https://github.com/jerairrest/react-chartjs-2).
 */
function LineAndBarChart({ className, datasets, chartOptions, fullTimeRange }) {
  Chart.pluginService.register(stackTotalizer)
  const theme = useContext(ThemeContext)

  const getDataSetTypeUnit = (datasetType) => {
    const datasetWithType = datasets.find((ds) => ds.type === datasetType)
    return datasetWithType ? datasetWithType.unit : ''
  }

  const lineUnit = getDataSetTypeUnit('line')

  const processedData = {
    type: 'bar',
    datasets: datasets.map((dataset) => {
      const color =
        dataset.color[0] === '#'
          ? dataset.color
          : theme.chartColorScheme[dataset.color]
      let extraParams = {}

      if (dataset.type === 'line') {
        extraParams = {
          steppedLine: 'before',
          borderWidth: 3,
          borderColor: color,
          pointBackgroundColor: color,
          backgroundColor: color,
          pointRadius: 4,
          fill: false,
          spanGaps: true,
        }
      } else {
        extraParams = {
          backgroundColor: color,
          borderWidth: 0,
          borderColor: color,
          maxBarThickness: 48,
          stacked: true,
        }
      }
      return {
        ...dataset,
        ...extraParams,
        yAxisID: dataset.type,
      }
    }),
  }

  const processedOptions = {
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false, // By default hide the data labels
      },
    },
    tooltips: {
      callbacks: {
        title(tooltipItem) {
          return new Date(tooltipItem[0].label).toLocaleDateString('nl-NL')
        },
        label(tooltipItem, data) {
          const unit = data.datasets[tooltipItem.datasetIndex].unit || ''
          return `${data.datasets[tooltipItem.datasetIndex].label} ${unit}${
            tooltipItem.value
          }`
        },
      },
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          bounds: 'data',
          time: {
            unit: 'week',
          },
          ticks: {
            min: fullTimeRange[0],
            max: fullTimeRange[1],
            fontFamily: theme.font,
            fontStyle: 300,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          id: 'line',
          type: 'linear',
          position: 'left',
          ticks: {
            beginAtZero: true,
            fontFamily: theme.font,
            fontStyle: 300,
            callback: (value) => lineUnit + value,
          },
        },
        {
          id: 'bar',
          type: 'linear',
          position: 'right',
          stacked: true,
          ticks: {
            beginAtZero: true,
            fontFamily: theme.font,
            fontStyle: 300,
          },
        },
      ],
    },
    ...chartOptions,
  }

  return (
    <figure className={className}>
      <Bar
        className={className}
        data={processedData}
        options={processedOptions}
      />
    </figure>
  )
}

LineAndBarChart.propTypes = {
  className: PropTypes.string,
  datasets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['bar', 'line']).isRequired,
      data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    }),
  ).isRequired,
  chartOptions: PropTypes.object,
  fullTimeRange: PropTypes.arrayOf(MomentPropTypes.momentObj).isRequired,
}

LineAndBarChart.defaultProps = {
  className: undefined,
  chartOptions: undefined,
}

export default LineAndBarChart
