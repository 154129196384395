import { connect } from 'react-redux'
import { openOverlay } from 'redux/actions/ui'
import ComplexForm from '../molecules/complex-form'

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  openOverlay,
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplexForm)
