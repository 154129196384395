import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ApolloProvider, useMutation, useQuery } from '@apollo/client'
import LoadingIndicator from 'components/atoms/loading-indicator'
import RadioButton from 'components/atoms/radio-button'
import { ApiV4ApolloClient } from 'config/apollo'
import {
  CAR_CUTTER_SETTINGS,
  CAR_CUTTER_ACTIVATION_OPTIONS,
  UPDATE_CAR_CUTTER_SETTINGS,
} from 'config/graphql/v4'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components/macro'
import BodyHeading from '../../atoms/body-heading'
import ExplanationParagraph from '../../atoms/explanation-paragraph'
import Button from '../../atoms/button'
import PhotoStudioSettingsOverlay from './photo-studio-settings-overlay'
import toast from 'utilities/toast'
import { useSelector } from 'react-redux'

const Grid = styled.section`
  display: grid;
  gap: ${(props) => props.theme.sizings.lvl4}
    ${(props) => props.theme.sizings.lvl8};
  grid-template-areas:
    'header header'
    'subsection1 subsection2';
`

const restrictContent = css`
  & > *:last-child {
    margin-bottom: 0;
  }
  & > *:first-child {
    margin-top: 0;
  }
`

const Header = styled.header`
  grid-area: header;
  ${restrictContent}
`
const Subsection1 = styled.section`
  grid-area: subsection1;
  ${restrictContent}
`
const Subsection2 = styled.section`
  grid-area: subsection2;
  ${restrictContent}
`
const StyledRadioButton = styled(RadioButton)`
  margin: ${(props) => props.theme.sizings.lvl1};
`

const ThumnailContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizeByFactor(4)};
  flex-direction: row;
  flex-wrap: wrap;
  > img {
    flex: 0 0 auto;
  }
`
const ThumnailImage = styled.img`
  height: ${(props) => props.theme.sizeByFactor(20)};
`

const StyledOptionButton = styled(Button)`
  position: relative;
  top: -12px; // tweak, so it aligns with the paragraph to the left.
`

/**
 * The UCC PhotoStudio is powered by the API of CarCutter. This is why the GraphQL queries and mutations
 * are prefixed with that name.
 */
const PhotoStudioSettings = ({ dealerId }) => {
  const { t } = useTranslation()
  const [overlayIsOpen, setOverlayIsOpen] = useState(false)
  const [selectedActivation, setSelectedActivation] = useState(false)
  const dealerRights = useSelector((state) => state?.auth?.userDetails?.dealers)
  const dealersWithAccess = Array.isArray(dealerRights)
    ? dealerRights
        .filter((dealer) => dealer.rechten.carcutter)
        .map((dealer) => dealer.dealernr)
    : []

  const activationOptions = useQuery(CAR_CUTTER_ACTIVATION_OPTIONS, {
    variables: {
      dealerId: dealerId,
    },
  })

  const settings = useQuery(CAR_CUTTER_SETTINGS, {
    variables: {
      dealerId: dealerId,
    },
  })

  useEffect(() => {
    if (settings?.data?.carCutterSettings.activation) {
      setSelectedActivation(settings?.data?.carCutterSettings.activation)
    }
  }, [settings])

  const [updateSettingsMutation] = useMutation(UPDATE_CAR_CUTTER_SETTINGS, {
    refetchQueries: [{ query: CAR_CUTTER_SETTINGS, variables: { dealerId } }],
    awaitRefetchQueries: true,
  })

  const updateActivation = (event) => {
    if (event?.target?.value) {
      const updatedActivation = event.target.value
      if (updatedActivation !== selectedActivation) {
        setSelectedActivation(updatedActivation)
        updateSettingsMutation({
          variables: {
            activation: updatedActivation,
            dealerId,
          },
        }).then(
          () => {},
          () => {
            toast.error(t('photoStudioSettings.layerApplicationMethodNotSaved'))
          },
        )
      }
    }
  }

  if (!dealersWithAccess.includes(dealerId)) {
    return (
      <ExplanationParagraph>
        {t('photoStudioSettings.noAccess')}
      </ExplanationParagraph>
    )
  }

  return (
    <Grid>
      <Header>
        <BodyHeading level="2" styledAs="oldheading">
          {t('photoStudioSettings.mainHeading')}
        </BodyHeading>
        <ExplanationParagraph>
          {t('photoStudioSettings.explanation')}
        </ExplanationParagraph>
      </Header>
      {settings.loading && activationOptions.loading && (
        <LoadingIndicator size="extraSmall" error={settings.error} />
      )}
      {settings?.data && activationOptions?.data && (
        <>
          <Subsection1>
            <BodyHeading level="3" styledAs="subheading">
              {t('photoStudioSettings.subsection1.heading')}
            </BodyHeading>
            <ExplanationParagraph>
              {t('photoStudioSettings.subsection1.explanation')}
            </ExplanationParagraph>
            {settings.data.activation}
            <form>
              <BodyHeading level="5">{t('layerApply')}</BodyHeading>
              {activationOptions.data.carCutterActivationOptions.map(
                (option) => (
                  <StyledRadioButton
                    title={option.label}
                    checked={selectedActivation === option.value}
                    value={option.value}
                    id={option.id}
                    key={option.id}
                    name="activtionOptions"
                    onChange={updateActivation}
                  />
                ),
              )}
            </form>
          </Subsection1>
          <Subsection2>
            <BodyHeading level="3" styledAs="subheading">
              {t('photoStudioSettings.subsection2.heading')}
            </BodyHeading>
            <StyledOptionButton
              noPadding
              type="button"
              level="option"
              onClick={() => setOverlayIsOpen(true)}
            >
              {t(
                'photoStudioSettings.subsection2.manageBackgroundAndLayerButton',
              )}
            </StyledOptionButton>
            <ThumnailContainer>
              {settings.data.carCutterSettings.background && (
                <ThumnailImage
                  src={settings.data.carCutterSettings.background.thumbnailUri}
                  alt={t('photoStudioSettings.subsection2.currentBackground')}
                />
              )}
              {settings.data.carCutterSettings.layer && (
                <ThumnailImage
                  src={settings.data.carCutterSettings.layer.thumbnailUri}
                  alt={t('photoStudioSettings.subsection2.currentLayer')}
                />
              )}
            </ThumnailContainer>
            {!settings.data.carCutterSettings.background &&
              !settings.data.carCutterSettings.layer && (
                <ExplanationParagraph>
                  {t(
                    'photoStudioSettings.subsection2.noBackgroundChosenMessage',
                  )}
                </ExplanationParagraph>
              )}
          </Subsection2>
        </>
      )}
      {overlayIsOpen && (
        <PhotoStudioSettingsOverlay
          closeOverlay={() => {
            setOverlayIsOpen(false)
            settings.refetch()
          }}
          dealerId={dealerId}
          activation={selectedActivation}
        />
      )}
    </Grid>
  )
}

PhotoStudioSettings.propTypes = {
  dealerId: PropTypes.string.isRequired,
}

const PhotoStudioSettingsProvider = ({ dealerId }) => {
  const MemoedApolloClient = React.useMemo(() => ApiV4ApolloClient(), [])
  return (
    <ApolloProvider client={MemoedApolloClient}>
      <PhotoStudioSettings dealerId={dealerId} />
    </ApolloProvider>
  )
}

PhotoStudioSettingsProvider.propTypes = {
  ...PhotoStudioSettings.propTypes,
}

export default PhotoStudioSettingsProvider
