import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'

const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: ${({ theme }) => theme.sizings.lvl2};
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.desktop`
    grid-template-columns: repeat(4, 1fr);
    gap: ${({ theme }) => theme.sizings.lvl3};
  `}
`

const FiltersOptionsBar = ({ children, className }) => (
  <FiltersContainer className={className}>{children}</FiltersContainer>
)

FiltersOptionsBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

FiltersOptionsBar.defaultProps = {
  className: null,
}

export default FiltersOptionsBar
