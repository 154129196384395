import * as React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'

import { withApolloV4Provider } from 'utilities/apollo'

import LocationTopDownSettings from 'components/organisms/location-topdown-settings'
import LocationsSettings from 'components/redux-containers/locations-settings-container'

const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl0};
`

const LocationsTopDownSettings = ({ match }) => {
  const { t } = useTranslation()

  const tabItems = [
    {
      label: t('topdownCalculationSettings.tabTitle'),
      to: `${match.url}/configure-topdown'`,
      // eslint-disable-next-line react/prop-types
      component: (props) => <LocationTopDownSettings {...props} />,
    },
  ]

  return (
    <StyledArticle>
      <LocationsSettings
        title={t('topdownCalculationSettings.title')}
        tabItems={tabItems}
        appFeatureNotAllowedMessageId="topdownCalculationSettings.locationNotAllowed"
      />
    </StyledArticle>
  )
}

LocationsTopDownSettings.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(withApolloV4Provider(LocationsTopDownSettings))
