import React from 'react'
import PropTypes from 'prop-types'
import SimpleTable from 'components/layouts/simple-table'
import SearchResultsHeader from 'components/molecules/search-results-header'
import Text from 'components/atoms/text'
import Paging from 'components/molecules/paging'
import styled from 'styled-components/macro'

const Container = styled.article`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'header'
    'content'
    'footer';
  margin-bottom: 80px;

  > .simple-search-results-header {
    grid-area: 'header';
  }

  > .simple-search-results-content {
    grid-area: 'content';
  }

  > .simple-search-results-paging {
    grid-area: 'footer';
  }
`

/**
 * Displays search results in a simple tabular format. It is
 * meant to display data in a non-interactive way. If you want
 * interactive elements per row TabularResults might be a better
 * fit.
 * Uses the SimpleTable molecule to achieve this.
 */
function SimpleSearchResults({
  filtering,
  sorting,
  data,
  dataMapping,
  ordering,
  noDataMessage,
  page,
  onChangePage,
  itemsPerPage,
  totalItems,
}) {
  return (
    <Container>
      <SearchResultsHeader
        noOfResults={totalItems}
        className="simple-search-results-header"
        sorting={sorting}
        filtering={filtering}
      />
      {!!data.length && (
        <SimpleTable
          className="simple-search-results-content"
          columns={ordering.map((id) => (
            <Text
              key={`column-${id}`}
              type="floatingLabelLabel"
              text={dataMapping[id].label}
            />
          ))}
          rows={data.map((row) =>
            ordering.map((id) => (
              <Text
                key={`row-${id}`}
                type="boldLabel"
                dangerouslySetInnerHTML={{
                  __html: dataMapping[id].getter(row),
                }}
              />
            )),
          )}
        />
      )}
      {data.length === 0 && <p>{noDataMessage}</p>}
      {totalItems > itemsPerPage && (
        <Paging
          className="simple-search-results-paging"
          activePage={page}
          onChangePage={onChangePage}
          perPage={itemsPerPage}
          total={totalItems}
        />
      )}
    </Container>
  )
}

SimpleSearchResults.propTypes = {
  data: PropTypes.array.isRequired,
  ordering: PropTypes.array.isRequired,
  dataMapping: PropTypes.object.isRequired,
  noDataMessage: PropTypes.string.isRequired,
  filtering: PropTypes.arrayOf(PropTypes.node),
  sorting: PropTypes.node,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
}

SimpleSearchResults.defaultProps = {
  filtering: [],
  sorting: null,
}

export default SimpleSearchResults
