import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'

import { CarFileStatusEnumLowerCase } from 'config/enums'

import LocationsSettings from 'components/redux-containers/locations-settings-container'
import { StatusChecklistsSettingsForLocation } from 'components/views/settings/checklists/status-checklists-settings'

const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl0};
`

const LocationsChecklistsSettings = ({ match }) => {
  const { t } = useTranslation()
  const tabItems = Object.keys(CarFileStatusEnumLowerCase).map((status) => ({
    label: t(`carFileStatusses.${status}`),
    to: `${match.url}/${CarFileStatusEnumLowerCase[status]}`,
    component: (props) => (
      <StatusChecklistsSettingsForLocation status={status} {...props} />
    ),
  }))

  return (
    <StyledArticle>
      <LocationsSettings
        title={t('checklistsSettings.checklistsPerLocation.sectionHeading')}
        tabItems={tabItems}
        appFeature="flexflow"
        appFeatureNotAllowedMessageId="checklistsSettings.locationNotAllowed"
      />
    </StyledArticle>
  )
}

LocationsChecklistsSettings.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(LocationsChecklistsSettings)
