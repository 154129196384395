import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Chip from '@material-ui/core/Chip'

import Icon from 'components/atoms/icon'

const StyledChip = styled(Chip)`
  color: ${({ theme, $isClickable, $level }) =>
    ['option', 'cta'].includes($level)
      ? theme.colors.actionsStandard
      : $isClickable
        ? theme.colors.brandCharlie
        : theme.colors.text};
  border-radius: ${({ theme }) => theme.sizings.lvl0};
  background-color: ${({ theme, $level }) =>
    $level === 'option' ? 'transparent' : theme.colors.brandGolf};

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme, $level }) =>
      $level === 'option' ? 'transparent' : theme.colors.actionsStandardText};
    color: ${({ theme, $isClickable }) =>
      $isClickable ? theme.colors.actionsStandardSecondary : theme.colors.text};

    svg {
      fill: ${({ theme, $isClickable }) =>
        $isClickable ? theme.colors.actionsStandardSecondary : undefined};
    }
  }

  & .MuiChip-label {
    font-family: ${({ theme }) => theme.font};
    font-size: ${({ theme, $chipSize }) =>
      $chipSize === 'small' ? theme.smallFontSize : theme.baseFontSize};
    padding-left: ${({ theme }) => theme.sizeByFactor(1)};
    padding-right: ${({ theme }) => theme.sizeByFactor(1)};
    line-height: 1.2;
  }
`

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  margin: 0;
  margin-left: ${({ theme, $chipSize }) =>
    $chipSize === 'small' ? theme.sizeByFactor(1) : theme.sizeByFactor(1.25)};
  margin-right: ${({ theme, $chipSize }) =>
    $chipSize === 'small' ? 0 : theme.sizeByFactor(0.125)};
  width: ${({ theme, $chipSize }) =>
    $chipSize === 'small' ? theme.sizeByFactor(1.8) : theme.sizeByFactor(2.3)};
  height: auto;

  svg {
    fill: ${({ theme, $isClickable }) =>
      $isClickable ? theme.colors.actionsStandard : undefined};
    width: 100%;
  }
`

const Tag = ({
  className,
  size,
  icon,
  iconColor,
  level,
  isEditTag,
  ...restProps
}) => {
  const clickable = typeof restProps?.onClick === 'function'

  /**
   * The "disableRipple" prop is conditionally added because of a warning in the console when we're setting it to undefined:
   * React does not recognize the `disableRipple` prop on a DOM element.
   */
  return (
    <StyledChip
      $isClickable={clickable}
      $chipSize={size}
      $level={level}
      className={className}
      clickable={clickable}
      icon={
        icon ? (
          <StyledIcon
            $chipSize={size}
            $isClickable={clickable}
            type={icon}
            color={iconColor}
          />
        ) : undefined
      }
      size={size}
      {...(clickable ? { disableRipple: true } : {})}
      {...restProps}
    />
  )
}

Tag.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  onClick: PropTypes.func,
  level: PropTypes.oneOf(['standard', 'cta', 'option']),
  isEditTag: PropTypes.bool,
}

Tag.defaultProps = {
  className: null,
  size: 'small',
  icon: undefined,
  iconColor: 'text',
  onClick: null,
  level: 'standard',
  isEditTag: false,
}

export default Tag
