import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import DonutChartWithLegends from 'components/organisms/donut-chart-with-legends'

const Container = styled.div`
  display: grid;
  grid-column-gap: ${({ theme }) => theme.sizings.lvl2};
  grid-template-columns: 1fr;
  position: relative;
  width: 100%;

  ${media.desktop`
    grid-template-columns: 1fr 1fr;
  `}
`

const StyledDonutChartWithLegends = styled(DonutChartWithLegends)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};

  ${media.desktop`
    margin-bottom: 0;
  `}
`

/**
 * Competitors position
 *

 */

function ComparisonDonutCharts({
  parentIsDarker,
  unit,
  mainTitleLeft,
  percentageLeft,
  legendColorLeft,
  leftTitleLeft,
  leftValueLeft,
  rightTitleLeft,
  rightValueLeft,

  mainTitleRight,
  percentageRight,
  legendColorRight,
  leftTitleRight,
  leftValueRight,
  rightTitleRight,
  rightValueRight,
}) {
  return (
    <Container>
      <StyledDonutChartWithLegends
        parentIsDarker={parentIsDarker}
        unit={unit}
        legendColor={legendColorLeft}
        percentage={percentageLeft}
        mainTitle={mainTitleLeft}
        leftTitle={leftTitleLeft}
        leftValue={leftValueLeft}
        rightTitle={rightTitleLeft}
        rightValue={rightValueLeft}
      />

      <StyledDonutChartWithLegends
        parentIsDarker={parentIsDarker}
        unit={unit}
        legendColor={legendColorRight}
        percentage={percentageRight}
        mainTitle={mainTitleRight}
        leftTitle={leftTitleRight}
        leftValue={leftValueRight}
        rightTitle={rightTitleRight}
        rightValue={rightValueRight}
      />
    </Container>
  )
}

ComparisonDonutCharts.propTypes = {
  /** ParentIsDarker mode bool */
  parentIsDarker: PropTypes.bool,
  /** Main label left set */
  mainTitleLeft: PropTypes.string.isRequired,
  /** Legendcolor left set, one of the theme color names */
  legendColorLeft: PropTypes.string,
  /** Percentage value left set */
  percentageLeft: PropTypes.number,
  /** Unit used for comparing */
  unit: PropTypes.string,
  /** Label left legend, left set */
  leftTitleLeft: PropTypes.string.isRequired,
  /** Value left legend, left set */
  leftValueLeft: PropTypes.number,
  /** Label right legend, left set */
  rightTitleLeft: PropTypes.string.isRequired,
  /** Value right legend left set */
  rightValueLeft: PropTypes.number,
  /** Main right label */
  mainTitleRight: PropTypes.string.isRequired,
  /** Legendcolor right set, one of the theme color names */
  legendColorRight: PropTypes.string,
  /** Percentage value right set */
  percentageRight: PropTypes.number,
  /** Value right legend right set */
  leftTitleRight: PropTypes.string.isRequired,
  /** Value right value right set */
  leftValueRight: PropTypes.number,
  /** Label right label right set */
  rightTitleRight: PropTypes.string.isRequired,
  /** Value right legend right set */
  rightValueRight: PropTypes.number,
}

ComparisonDonutCharts.defaultProps = {
  parentIsDarker: false,
  percentageLeft: undefined,
  percentageRight: undefined,
  unit: null,
  leftValueLeft: undefined,
  leftValueRight: undefined,
  legendColorLeft: undefined,
  legendColorRight: undefined,
  rightValueLeft: undefined,
  rightValueRight: undefined,
}

export default ComparisonDonutCharts
