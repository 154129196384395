// eslint-disable-next-line no-unused-vars
import React from 'react'
import styled from 'styled-components/macro'

/**
 * A simple utility layout to give text content a standard top and bottom margin
 */
const BodyTextWrapper = styled.div`
  display: block;
  line-height: ${({ theme }) => theme.sizings.lvl2};
  margin: ${({ theme }) => theme.baseFontSize} 0;

  &:first-child {
    margin-top: 0;
  }
`

/**
 * @component
 */
export default BodyTextWrapper
