import { connect } from 'react-redux'
import { getDeliveryPackages } from 'redux/actions/settings'
import DeliveryPortalsSettings from '../organisms/delivery-portals-settings'

const mapStateToProps = ({ settings }) => ({
  deliveryPackages: settings.deliveryPackages,
})

const mapDispatchToProps = {
  getDeliveryPackages,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryPortalsSettings)
