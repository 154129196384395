import React from 'react'
import PropTypes from 'prop-types'

function SegmentedFormStep({ className, onSubmit, children }) {
  return (
    <form className={className} onSubmit={onSubmit}>
      {children}
    </form>
  )
}

SegmentedFormStep.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.element,
}

SegmentedFormStep.defaultProps = {
  className: null,
  onSubmit: null,
  children: <>No content provided</>,
}

export default SegmentedFormStep
