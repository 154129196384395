import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import Link from 'components/atoms/link'
import Text from 'components/atoms/text'
import Counter from 'components/atoms/counter'
import { scrollTo } from 'utilities/utils'
import { formatDataTestE2eAttr } from 'utilities/format'

const StyledCounter = styled(Counter)`
  display: none;

  ${media.tablet`
    display: flex;
    margin: 0 0 0 8px;
  `};
`

const DefaultTab = styled(Link)`
  background: ${({ selected, theme }) =>
    selected ? theme.colors.brandGolf : ''};
  background-image: ${({ selected, theme }) =>
    selected ? '' : theme.gradients.tabBottomShadow};
  display: flex;
  margin: 0 2px 0 0;
  padding: 16px;

  &:hover {
    background-image: none;
    background: ${({ theme }) => theme.colors.brandGolf};
  }
`

const SmallTab = styled(Link)`
  border-bottom: 4px solid;
  border-color: ${({ selected, theme }) =>
    selected ? theme.colors.brandAlfa : theme.colors.transparent};
  display: flex;
  margin: 0 24px 0 0;
  padding: 24px 0 16px;
`

function getTextColor(disabled, selected) {
  if (disabled) {
    return 'brandDelta'
  }
  if (selected) {
    return 'brandAlfa'
  }
  return 'actionsStandard'
}

// @TODO fix monkey logic below
function getTextType(small, selected) {
  if (small && selected) {
    return 'tabSmall'
  }
  if (small && !selected) {
    return 'tabSmallDeselected'
  }
  if (!small && selected) {
    return 'tab'
  }
  if (!small && !selected) {
    return 'tabDeselected'
  }
  return 'tab'
}

function Tab({
  className,
  count,
  disabled,
  label,
  selected,
  small,
  to,
  bringInView,
  urlSearch,
}) {
  const TabComponent = small ? SmallTab : DefaultTab
  const tab = useRef()
  const timeoutId = useRef()

  /**
   * Give the component some time to calculate the height, then scroll
   */
  function bringTabIntoView() {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    timeoutId.current = setTimeout(() => {
      scrollTo(tab.current, {
        horizontal: 'center',
      })
    }, 100)
  }

  useEffect(
    () => () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
    },
    [],
  )

  /**
   * Not sure where this is code is used. Uncommented, the above function
   * gets called on changing tabs.
   */
  // Tab should slide into center of the view when selected.
  // if (selected && bringInView && tab.current) {
  //   bringTabIntoView()
  // }

  return (
    <div ref={tab}>
      <TabComponent
        className={className}
        selected={selected}
        to={disabled ? null : to + urlSearch}
        onClick={disabled ? null : () => bringTabIntoView()}
        data-test-e2e="tab-link"
        data-test-e2e-label={formatDataTestE2eAttr(label)}
        data-element="tab-tab-link"
      >
        <Text
          color={getTextColor(disabled, selected)}
          text={label}
          type={getTextType(small, selected)}
        />
        {count > 0 && <StyledCounter count={count} />}
      </TabComponent>
    </div>
  )
}

Tab.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  count: PropTypes.number,
  selected: PropTypes.bool.isRequired,
  small: PropTypes.bool,
  to: PropTypes.string,
  bringInView: PropTypes.bool,
  /**
   * Is the query string component of the url:
   */
  urlSearch: PropTypes.string,
}

Tab.defaultProps = {
  className: null,
  count: null,
  disabled: null,
  small: false,
  to: null,
  bringInView: false,
  urlSearch: '',
}

export default Tab
