import { connect } from 'react-redux'
import { getCarPhotoLayersForCarFile } from 'redux/actions/settings'
import CarLayersForm from '../views/car-file/tabs/car-layers-form'

const mapStateToProps = ({ settings }) => ({
  carPhotoLayers: settings.carPhotoLayers,
})

const mapDispatchToProps = {
  getCarPhotoLayersForCarFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(CarLayersForm)
