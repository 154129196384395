import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import DropdownMenu from 'components/atoms/dropdown-menu'

function getMonthOptions() {
  return moment.months().map((label, value) => ({
    label,
    value,
  }))
}

function getYearOptions() {
  const years = Array.from(Array(120), (_, x) => moment().year() + 20 - x)

  return years.map((year) => ({
    label: year.toString(),
    value: year,
  }))
}

const MonthContainer = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    margin: 0 ${({ theme }) => theme.sizings.lvl1};
  }
`

function MonthYearPicker({ month, onMonthSelect, onYearSelect }) {
  return (
    <MonthContainer style={{ display: 'flex', justifyContent: 'center' }}>
      <DropdownMenu
        items={getMonthOptions()}
        onChange={(value) => onMonthSelect(month, value)}
        selected={month.month()}
      />
      <DropdownMenu
        items={getYearOptions()}
        onChange={(value) => onYearSelect(month, value)}
        selected={month.year()}
      />
    </MonthContainer>
  )
}

MonthYearPicker.propTypes = {
  month: PropTypes.object.isRequired,
  onMonthSelect: PropTypes.func.isRequired,
  onYearSelect: PropTypes.func.isRequired,
}

export default MonthYearPicker
