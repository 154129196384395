import { useState, useEffect } from 'react'

import storage from 'utilities/storage'
import { storageKeys } from 'config/storage'
import { newsItemWordPressTagOpenOnLoad } from 'config/news'

const useNavigationBarNewsItem = (newsItem) => {
  const [hasSeenPopout, setHasSeenPopout] = useState(false)
  const [showPopout, setShowPopout] = useState(false)
  const [notificationCount, setNotificationCount] = useState(0)

  useEffect(() => {
    if (!newsItem) {
      return
    }

    const newsItemOpenOnPageLoad = newsItem.tags.includes(
      newsItemWordPressTagOpenOnLoad,
    )
    const currentNewsItem = JSON.parse(
      storage.getValue(storageKeys.UCC_NEWS_ITEM),
    )
    const isNewNewsItem = !currentNewsItem || currentNewsItem.id !== newsItem.id

    // When the app is loaded and there are new posts in WordPress
    if (isNewNewsItem) {
      storage.setPermanent(
        storageKeys.UCC_NEWS_ITEM,
        JSON.stringify({
          id: newsItem.id,
          date_published: newsItem.date_published,
          isOpened: false,
          openOnPageLoad: newsItemOpenOnPageLoad,
          tags: newsItem.tags,
        }),
      )

      if (newsItemOpenOnPageLoad) {
        setShowPopout(true)
      } else {
        setNotificationCount(1)
      }
    }

    // When the app is loaded and there's no new posts in WordPress
    if (!isNewNewsItem && !currentNewsItem.isOpened) {
      if (
        currentNewsItem.tags.includes(newsItemWordPressTagOpenOnLoad) ||
        newsItem.tags.includes(newsItemWordPressTagOpenOnLoad)
      ) {
        setShowPopout(true)
      } else {
        setNotificationCount(1)
      }
    }
  }, [newsItem, setShowPopout, setNotificationCount])

  useEffect(() => {
    if (!showPopout) {
      return
    }

    const currentNewsItem = JSON.parse(
      storage.getValue(storageKeys.UCC_NEWS_ITEM),
    )

    if (!currentNewsItem?.isOpened) {
      storage.setPermanent(
        storageKeys.UCC_NEWS_ITEM,
        JSON.stringify({ ...currentNewsItem, isOpened: true }),
      )
    }

    setNotificationCount(0)
  }, [showPopout, setNotificationCount])

  return {
    showPopout,
    setShowPopout,
    hasSeenPopout,
    setHasSeenPopout,
    notificationCount,
    setNotificationCount,
  }
}

export default useNavigationBarNewsItem
