import { gql } from '@apollo/client'

export const stockDaysByTypeFragment = gql`
  fragment stockDaysByTypeFragment on StockDaysResult {
    averageStockDays
    stockDays {
      average
      createdAt
    }
  }
`

export const stockTurnByTypeFragment = gql`
  fragment stockTurnByTypeFragment on StockTurnResult {
    averageStockTurn
    stockTurns {
      stockturn
      createdAt
    }
  }
`

export const stockDaysFragment = gql`
  fragment stockDaysFragment on StockDays123Result {
    averageStockDays1
    lastStockAmount1
    stockAmountRatio1
    stockDaysFund1
    stockDaysFundRatio1
    averageStockDays2
    lastStockAmount2
    stockAmountRatio2
    stockDaysFund2
    stockDaysFundRatio2
    averageStockDays3
    lastStockAmount3
    stockAmountRatio3
    stockDaysFund3
    stockDaysFundRatio3
    StockDays123s {
      stock_days_1
      car_amount_stock_days_1
      funds_stock_days_1
      stock_days_2
      car_amount_stock_days_2
      funds_stock_days_2
      stock_days_3
      car_amount_stock_days_3
      funds_stock_days_3
      createdAt
    }
  }
`

export const stockDaysFragmentMyAveragesAndComparisonGroupStockDays1 = gql`
  fragment stockDaysFragmentMyAveragesAndComparisonGroupStockDays1 on StockDays123Result {
    averageStockDays1
    lastStockAmount1
    stockAmountRatio1
    stockDaysFund1
    stockDaysFundRatio1
    StockDays123s {
      stock_days_1
      car_amount_stock_days_1
      funds_stock_days_1
      createdAt
    }
  }
`

export const stockDaysFragmentMyAveragesAndComparisonGroupStockDays2 = gql`
  fragment stockDaysFragmentMyAveragesAndComparisonGroupStockDays2 on StockDays123Result {
    averageStockDays2
    lastStockAmount2
    stockAmountRatio2
    stockDaysFund2
    stockDaysFundRatio2
    StockDays123s {
      stock_days_2
      car_amount_stock_days_2
      funds_stock_days_2
      createdAt
    }
  }
`

export const stockDaysFragmentMyAveragesAndComparisonGroupStockDays3 = gql`
  fragment stockDaysFragmentMyAveragesAndComparisonGroupStockDays3 on StockDays123Result {
    averageStockDays3
    lastStockAmount3
    stockAmountRatio3
    stockDaysFund3
    stockDaysFundRatio3
    StockDays123s {
      stock_days_3
      car_amount_stock_days_3
      funds_stock_days_3
      createdAt
    }
  }
`
