import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import BodyHeading from 'components/atoms/body-heading'

const ChartTitle = styled(BodyHeading)`
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: ${({ theme }) => theme.sizings.lvl1};
`

/**
 * Note: Only use this component in the context of the management info page.
 * It's seperated into a molecule because it's being re-used in different charts,
 * yet it's not meant to be used outside of the management info page.
 */
function ManagementInfoChartTitle({ className, children }) {
  return (
    <ChartTitle className={className} level="1" styledAs="oldheading">
      {children}
    </ChartTitle>
  )
}

ManagementInfoChartTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ManagementInfoChartTitle
