import Button from 'components/atoms/button'
import {
  FormikLicensePlateInput,
  FormikTextInput,
} from 'components/molecules/formik-field'
import Typography from 'components/molecules/typography'
import { CspDealerSiteWidgetCta as ValidationSchema } from 'config/validation-schemas'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

/**
 * Serves as the entry point/ call to action to pursuade people
 * to enter in their vehicle data and get an offer for a CSP
 * matching their vehicle.
 */

const WidgetLayout = styled.section`
  display: flex;
  gap: ${({ theme }) => theme.baseSize * 2}px;
  flex-direction: column;
  padding: ${({ theme }) => theme.baseSize * 3}px;
  background-color: ${({ theme }) => theme.colors.alternativeBackground};

  > * {
    flex: 0 0 auto;
    margin: 0;
  }
`
const StyledForm = styled(Form)`
  display: flex;
  gap: ${({ theme }) => theme.baseSize * 2}px;
  flex-direction: column;
`

const StyledFormikLicensePlateInput = styled(FormikLicensePlateInput)`
  font-size: 24px;
`

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.baseSize}px;
`

function CspDealerSiteWidgetCta() {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const validationSchema = ValidationSchema(t)

  const urlParams = new URLSearchParams(window.location.search)
  const iframeId = urlParams.get('widgetId')

  const sendDataToParentWindow = (values) => {
    const message = {
      iframeId,
      whatToDo: 'open-ucc-csp-popup',
      values,
    }

    window.parent.postMessage(JSON.stringify(message), '*')
  }

  return (
    <>
      <WidgetLayout>
        <Typography type="Level2Heading">
          {t('cspDealerSiteWidgetCta.heading')}
        </Typography>
        <Typography type="BodyParagraph">
          <Trans>{t('cspDealerSiteWidgetCta.teaser')}</Trans>
        </Typography>

        <Formik
          enableReinitialize
          initialValues={{
            licensePlate: '',
            currentMileage: '',
          }}
          onSubmit={async (values) => {
            setIsSubmitting(true)
            sendDataToParentWindow(values)
            setIsSubmitting(false)
          }}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <StyledForm>
              <Field
                data-test-e2e="CspDealerSiteWidgetCta-field-licensePlate"
                name="licensePlate"
                validationSchema={validationSchema}
                label={t('cspDealerSiteWidgetCta.form.licensePlate.label')}
                filled
                component={StyledFormikLicensePlateInput}
                disabled={isSubmitting}
              />
              <Field
                data-test-e2e="CspDealerSiteWidgetCta-field-currentMileage"
                name="currentMileage"
                validationSchema={validationSchema}
                label={t('cspDealerSiteWidgetCta.form.currentMileage.label')}
                placeholder={t(
                  'cspDealerSiteWidgetCta.form.currentMileage.placeholder',
                )}
                filled
                component={FormikTextInput}
                disabled={isSubmitting}
                type="number"
                unit="km"
              />
              <StyledButton rounding={0} onClick={handleSubmit}>
                {t('cspDealerSiteWidgetCta.form.submitLabel')}
              </StyledButton>
            </StyledForm>
          )}
        </Formik>
      </WidgetLayout>
    </>
  )
}

export default CspDealerSiteWidgetCta
