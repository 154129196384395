import { connect } from 'react-redux'
import {
  addDeliveryAccessories,
  getDeliveryAccessories,
} from 'redux/actions/settings'
import DeliveryAccessoriesSettings from '../views/settings/delivery-accessories-settings'

const mapStateToProps = ({ settings }) => ({
  deliveryAccessories: settings.deliveryAccessories,
})

const mapDispatchToProps = {
  addDeliveryAccessories,
  getDeliveryAccessories,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryAccessoriesSettings)
