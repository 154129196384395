import { gql } from '@apollo/client'
import brandFragment from './brand'
import warrantyProviderFragment from './warrantyProvider'
import warrantyPriceRuleFragment from './warrantyPriceRule'

export default gql`
  fragment warrantyProgramInfo on WarrantyProgram {
    id
    name
    ownRiskPeriod
    manufacturerWarranty
    priceCalculation
    documentationUri
    documentationFullUri
    maxMileage
    externalId
    warrantyPriceRules {
      ...warrantyPriceRuleInfo
    }
    brands {
      ...brandInfo
    }
    warrantyProvider {
      ...warrantyProviderInfo
    }
  }
  ${brandFragment}
  ${warrantyProviderFragment}
  ${warrantyPriceRuleFragment}
`
