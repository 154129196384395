import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Text from 'components/atoms/text'
import LoadingIndicator from 'components/atoms/loading-indicator'
import ExpandableBars from 'components/layouts/expandable-bars'
import SummaryLine from 'components/molecules/summary-line'
import Tabs from 'components/layouts/tabs'
import TabularResults from 'components/organisms/tabular-results'
import SettingsForm from 'components/redux-containers/settings-form-container'
import OptionsBar from 'components/layouts/options-bar'
import Button from 'components/atoms/button'

function B2bSalesSettings({
  clearData,
  getB2bRelations,
  b2bRelations,
  deleteB2bRelation,
  getB2bInterests,
  b2bInterests,
  deleteB2bInterests,
  openOverlay,
  postDynamicEndpoint,
  match,
}) {
  const [currentRelation, setCurrentRelation] = useState(null)
  const b2bInterestsData =
    b2bInterests && b2bInterests.data && b2bInterests.data.data
  const b2bRelationsLoading =
    !b2bRelations || !b2bRelations.data || b2bRelations.loading

  useEffect(() => {
    getB2bRelations()
  }, [clearData, getB2bRelations])

  const updateInterests = () =>
    getB2bInterests({
      company_id: currentRelation,
    })

  useEffect(() => {
    if (currentRelation !== null) {
      clearData('b2bInterests')
      getB2bInterests({
        company_id: currentRelation,
      })
    }
  }, [clearData, currentRelation, getB2bInterests])

  const { t } = useTranslation()

  function handleDeleteInterest(rowId, companyId) {
    if (window.confirm(t('areYouSure'))) {
      deleteB2bInterests({
        interesse_id: rowId,
        company_id: companyId,
      })
    }
  }

  function handleDeleteRelation(companyId) {
    if (window.confirm(t('areYouSure'))) {
      deleteB2bRelation({
        company_id: companyId,
      })
    }
  }

  const tabItems = [
    {
      label: t('interests'),
      to: `${match.url}/b2b-sales/interests/`,
      component: () => (
        <>
          {!b2bInterests || b2bInterests.loading ? (
            <LoadingIndicator />
          ) : (
            <TabularResults
              title={t('interests')}
              data={Array.isArray(b2bInterestsData) ? b2bInterestsData : []}
              ordering={[
                'brand',
                'buildYearMin',
                'buildYearMax',
                'distanceDrivenMin',
                'distanceDrivenMax',
                'carType',
              ]}
              dataMapping={{
                brand: {
                  label: t('brand'),
                  getter: (row) => row.merk,
                },
                buildYearMin: {
                  label: t('buildYearMin'),
                  getter: (row) => row.jaar_min,
                },
                buildYearMax: {
                  label: t('buildYearMax'),
                  getter: (row) => row.jaar_max,
                },
                distanceDrivenMin: {
                  label: t('distanceDrivenMin'),
                  getter: (row) => row.km_min,
                },
                distanceDrivenMax: {
                  label: t('distanceDrivenMax'),
                  getter: (row) => row.km_max,
                },
                carType: {
                  label: t('carType'),
                  getter: (row) => row.soort,
                },
              }}
              actions={[
                {
                  label: t('modify'),
                  method: (row) =>
                    openOverlay('dynamicForm', {
                      formId: 'editB2bSalesInterest',
                      formParams: {
                        interesse_id: row.id,
                      },
                      onSubmit: (values, endpoint) => {
                        postDynamicEndpoint({
                          endpoint,
                          ...values,
                        })
                      },
                      onUpdateSuccess: updateInterests,
                      subId: currentRelation,
                      icon: 'preCalculation',
                      submitText: t('saveChanges'),
                      title: t('modifyB2bSalesInterest'),
                    }),
                },
                {
                  label: t('delete'),
                  method: (row) =>
                    handleDeleteInterest(row.id, currentRelation),
                },
              ]}
              addNewOption={{
                label: t('addInterest'),
                method: () =>
                  openOverlay('dynamicForm', {
                    formId: 'addB2bSalesInterest',
                    formParams: {
                      company_id: currentRelation,
                    },
                    onSubmit: (values, endpoint) => {
                      postDynamicEndpoint({
                        endpoint,
                        ...values,
                      })
                    },
                    onUpdateSuccess: updateInterests,
                    subId: currentRelation,
                    icon: 'preCalculation',
                    submitText: t('addB2bSalesInterest'),
                    title: t('addingB2bSalesInterest'),
                  }),
              }}
              noDataMessage={t('noInterestsYet')}
            />
          )}
        </>
      ),
    },
    {
      label: t('locationData'),
      to: `${match.url}/b2b-sales/location-data/`,
      component: () => (
        <SettingsForm
          formId="formB2BSalesLocation"
          params={{ company_id: currentRelation }}
          onUpdateSuccess={getB2bRelations}
        />
      ),
    },
  ]

  return (
    <>
      <OptionsBar
        leftAligned={[
          <Text key="left-aligned-text" type="h2" text={t('b2bRelations')} />,
        ]}
        rightAligned={[
          <Button
            key="right-aligned-button"
            text={t('addB2bRelations')}
            onClick={() =>
              openOverlay('dynamicForm', {
                formId: 'addB2bRelations',
                onSubmit: (values, endpoint) => {
                  postDynamicEndpoint({
                    endpoint,
                    ...values,
                  })
                },
                onUpdateSuccess: getB2bRelations,
                icon: 'preCalculation',
                submitText: t('saveChanges'),
                title: t('addB2bRelations'),
              })
            }
          />,
        ]}
      />
      {b2bRelations && b2bRelations.data && !!b2bRelations.data.length && (
        <ExpandableBars
          fullRowIsClickable={false}
          rows={b2bRelations.data.map((relation, index) => ({
            id: relation.id,
            header: (
              <SummaryLine
                key={index.toString()}
                name={relation.bedrijf}
                details={[
                  {
                    label: t('contactPerson'),
                    value: `${relation.voornaam} ${relation.achternaam}`,
                  },
                  {
                    label: t('requests'),
                    value: relation.inquiries_count,
                  },
                  {
                    label: t('bid'),
                    value: relation.bids_count,
                  },
                  {
                    label: t('promised'),
                    value: relation.deals_count,
                  },
                ]}
                actions={[
                  {
                    action: () => handleDeleteRelation(relation.id),
                    text: t('delete'),
                  },
                ]}
              />
            ),
            onRowClick: () => setCurrentRelation(relation.id),
            details: (
              <Tabs items={tabItems} small>
                {tabItems.map((tabItem, subIdx) => (
                  <tabItem.component
                    key={subIdx.toString()}
                    relation={relation}
                  />
                ))}
              </Tabs>
            ),
          }))}
        />
      )}
      {!b2bRelationsLoading &&
        (!b2bRelations || !b2bRelations.data || !b2bRelations.data.length) && (
          <Text text={t('noB2bRelationsYet')} type="floatingLabelLabel" />
        )}
    </>
  )
}

B2bSalesSettings.propTypes = {
  clearData: PropTypes.func.isRequired,
  getB2bRelations: PropTypes.func.isRequired,
  deleteB2bRelation: PropTypes.func.isRequired,
  b2bRelations: PropTypes.object,
  match: PropTypes.object,
  getB2bInterests: PropTypes.func.isRequired,
  deleteB2bInterests: PropTypes.func.isRequired,
  openOverlay: PropTypes.func.isRequired,
  b2bInterests: PropTypes.object,
  postDynamicEndpoint: PropTypes.func.isRequired,
}

B2bSalesSettings.defaultProps = {
  match: null,
  b2bRelations: null,
  b2bInterests: null,
}

export default B2bSalesSettings
