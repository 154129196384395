import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import TextInput from 'components/atoms/text-input'

const FromInput = styled(TextInput)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

function NumberRangeSelector({
  minValue,
  maxValue,
  onUpdate,
  minValueId,
  maxValueId,
}) {
  const { t } = useTranslation()
  const onChangeMinValue = (event) => {
    const { value } = event.target
    onUpdate(minValueId, value)
  }
  const onChangeMaxValue = (event) => {
    const { value } = event.target
    onUpdate(maxValueId, value)
  }

  return (
    <div>
      <FromInput
        type="number"
        minNumber={0}
        maxNumber={maxValue && Number(maxValue)}
        value={minValue}
        onChange={onChangeMinValue}
        placeholder={t('from')}
        title={t('from')}
        name="minPrice"
      />
      <TextInput
        type="number"
        minNumber={minValue && Number(minValue)}
        maxNumber={4500}
        value={maxValue}
        onChange={onChangeMaxValue}
        placeholder={t('till')}
        title={t('till')}
        name="maxPrice"
      />
    </div>
  )
}

NumberRangeSelector.propTypes = {
  minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onUpdate: PropTypes.func.isRequired,
  minValueId: PropTypes.string.isRequired,
  maxValueId: PropTypes.string.isRequired,
}

NumberRangeSelector.defaultProps = {
  minValue: undefined,
  maxValue: undefined,
}

export default NumberRangeSelector
