import api, { API_ERROR_MESSAGES } from 'utilities/api'
import toast from 'utilities/toast'

const typeEndpointsMap = {
  acceptTerms: 'acceptav',
  b2bAlert: 'Addb2balert',
  competitivePosition: 'fetch/competitive-position',
  competitivePositionExport: 'fetch/competitive-position-export',
  priceCheck: 'bidservice',
  salesOutsideDistrict: 'fetch/sales-outside-territory',
  stock: 'fetch/stock',
  stockBuyThese: 'fetch/stock-buy-these',
  stockBuyTheseAll: 'fetch/stock-buy-these',
  stockSellThese: 'fetch/stock-sell-these',
  stockSellTheseAll: 'fetch/stock-sell-these',
  stockStayTime: 'fetch/stock-stay-time',
  createExcelFile: 'createexcelfile',
}

const mockData = process.env.REACT_APP_MOCK_MARKETVIEW_DATA

function getMarktviewParams(params, instance, signal) {
  const endpoint = '/cl/v3/marktview/fetch/metadata'

  if (mockData) {
    return api.getStatic('/data/marktview/params.mock.json')
  }

  return api.post(endpoint, params, instance, undefined, signal)
}

function getMarketviewData(type, params, instance, signal) {
  const typeEndpoint = typeEndpointsMap[type]

  const endpoint = `/cl/v3/marktview/${typeEndpoint}`

  if (mockData) {
    return api.getStatic(`/data/marktview/${typeEndpoint}.mock.json`)
  }

  return api.post(endpoint, params, instance, undefined, signal)
}

function postMarketviewData(type, data, instance, signal) {
  const typeEndpoint = typeEndpointsMap[type]

  const endpoint = `/cl/v3/marktview/${typeEndpoint}`

  if (mockData) {
    return api.getStatic(`/data/marktview/${typeEndpoint}.mock.json`)
  }

  return api.post(endpoint, data, instance, undefined, signal)
}

/**
 * Open a new tab to download an XLS export of the data.
 *
 * @param {string} type - A key of the typeEndpointsMap object.
 * @param {string} token - An authenticated access token.
 * @param {object} body - POST body.
 * @param {object} queryParams - Query parameters.
 * @param {object} signal - Abort Controller signal
 */
async function getMarketviewExport({ type, token, body, queryParams, signal }) {
  const typeEndpoint = typeEndpointsMap[type]
  const endpoint = `/cl/v3/marktview/${typeEndpoint}`

  try {
    const response = await api.post(endpoint, body, token, queryParams, signal)

    if (response?.data?.data?.message) {
      toast.success(JSON.stringify(response.data.data.message))
    }

    if (response?.data?.data?.url) {
      window.open(response?.data?.data?.url, '_blank')
    }
  } catch (error) {
    if (error === API_ERROR_MESSAGES.ABORT_CONTROLLER_CANCELLED_REQUEST) {
      return
    }

    toast.error(JSON.stringify(error))
  }
}

export default {
  getMarktviewParams,
  getMarketviewData,
  getMarketviewExport,
  postMarketviewData,
}
