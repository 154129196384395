// @TODO Rename this component to LabeledCheckbox and abstract
// the input tag here to a Checkbox component without a label
// so it can be reused (by MediaImage for instance).

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Text from 'components/atoms/text'

const Container = styled.div`
  align-items: center;
  display: flex;
`

const StyledInput = styled.input`
  margin: 0 ${({ theme }) => theme.sizings.lvl2} 0 0;
  min-width: auto;
  flex: 0 0 auto;
`

const Label = styled(Text)`
  &::first-letter {
    text-transform: capitalize;
  }
`

function Checkbox({
  checked,
  disabled,
  name,
  id,
  onChange,
  title,
  value,
  ...restProps
}) {
  return (
    <Container {...restProps}>
      <StyledInput
        checked={checked}
        data-test-e2e="checkbox"
        disabled={disabled}
        id={id || name}
        name={name}
        onChange={(e) => onChange(e.target.checked)}
        type="checkbox"
        value={value}
      />
      {title && (
        <Label tag="label" htmlFor={id || name} type="input" text={title} />
      )}
    </Container>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.string,
}

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  id: null,
  onChange: null,
  title: null,
  value: '',
}

export default Checkbox
