import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { media } from 'utilities/styled'
import { formatPrice } from 'utilities/format'

import Definition from 'components/atoms/definition'
import Typography from 'components/molecules/typography'
import SimpleTable from 'components/layouts/simple-table'
import Icon from 'components/atoms/icon'

const CollectionContainer = styled.section`
    display: grid;
    grid-gap: ${(props) => props.theme.sizings.lvl2} ${(props) => props.theme.sizings.lvl3};
    grid-template-columns: ${({ $columns }) => '1fr '.repeat($columns)};
    
    ${media.tablet`
      grid-gap: ${(props) => props.theme.sizings.lvl2} ${(props) => props.theme.sizings.lvl3};
    `}
`

const StaticCarAccessory = styled.span`
  word-break: break-word;
  hyphens: auto;
  display: block;
  padding-left: 1.2rem;
  position: relative;
  `

const StaticCarAccessoryPrice = styled(Typography)`
  display: block;
  // aligned with first letter of title
`

const StyledIcon = styled(Icon)`
  position:absolute;
  top: -0.4rem;
  left: -0.4rem;
  width: 2rem;
  height: 2rem;
`

/**
 * Default way for UCC to display static collections of data.
 * Constructed out of [Definition components](/#/Atoms/Definition).
 */
function StaticValuesCollection ({
  collection,
  className,
  columns,
}) {
  const { t } = useTranslation()
  const collectionContainsATable = collection.some(item => item.displayType === 'table')
  if (collection.length < 1) {
    return (
      <Typography className={className} type="BodyParagraph">{t('noDataSupplied')}</Typography>
    )
  }

  return (
    <CollectionContainer className={className} $columns={collectionContainsATable ? 1 : columns}>
      {collection.map((item) => {
        if (item) {
          switch (item.displayType) {
          case 'date':
            return (<Definition key={item.id || item.title} type="date" {...item} />)
          case 'message':
            return (
              <Typography
                key={item.id || item.title}
                type="BodyParagraph"
              >{item.value}
              </Typography>
            )
          case 'table':
            return (
              <SimpleTable
                key={item.id || item.title}
                columns={item.value.columns.map((column, index) => (
                  <Typography type="InlineBodyText" key={`${index}`}>{column}</Typography>
                ))}
                rows={item.value.rows.map((row, index) => [
                  ...row.map((rowItem, rowItemIndex) => (typeof rowItem === 'string' ? (
                    <Typography type="InlineBodyTextBold" key={`${index}-${rowItemIndex}`}>{rowItem}</Typography>
                  ) : (
                    <Typography type="InlineBodyTextBold" key={`${index}-${rowItemIndex}`}>{rowItem.value}</Typography>
                  ))),
                ])}
                data={item.value}
                legend={item.value.legend}
              />
            )
          case 'carAccessory':
            return (
              <StaticCarAccessory key={item.id || item.title}>
                <Typography type="InlineBodyTextBold">
                  <StyledIcon type={item.value?.selected ? 'check' : 'cross'} />
                  {item.title}
                </Typography>
                {item.hideAFalsyPrice
                  ? (item.price || item.value?.price) && (
                    <StaticCarAccessoryPrice type="InlineBodyText" variant="tonedDown">
                      {formatPrice(item.price || item.value?.price)}
                    </StaticCarAccessoryPrice>
                  ) : (
                    <StaticCarAccessoryPrice type="InlineBodyText" variant="tonedDown">
                      {formatPrice(item.price || item.value?.price)}
                    </StaticCarAccessoryPrice>
                  )}
              </StaticCarAccessory>
            )
          default:
            return (<Definition key={item.id || item.title} {...item} />)
          }
        } else {
          return '-'
        }
      })}
    </CollectionContainer>
  )
}

StaticValuesCollection.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    title: PropTypes.oneOfType([PropTypes.number, PropTypes.string ]),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string ]),
    /**
     * As what type this data,/datapoint needs to be displayed
     * (think number of kilograms, date, datetime, currency as opposed float, integer and string).
     */
    displayType: PropTypes.oneOf([
      'date',
      'link',
      'message',
      'text',
      'table',
      'carAccessory',
    ]),
  })),
  className: PropTypes.string,
  columns: PropTypes.number,
}

StaticValuesCollection.defaultProps = {
  collection: [],
  className: null,
  columns: 2,
}

export default StaticValuesCollection
