import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import {
  MANAGEMENT_INFO_STAND_TIME,
  MANAGEMENT_INFO_TAXATION,
} from 'config/routes'

import { media } from 'utilities/styled'

import useSelectedLocationId from 'hooks/use-selected-location-id'

import AppLayout from 'components/redux-containers/app-layout-container'
import PageHeader from 'components/organisms/page-header'
import Tabs from 'components/layouts/tabs'

import ManagementInfoTabStandTime from './tabs/stand-time'
import ManagementInfoTabTaxation from './tabs/taxation'

const Content = styled.main`
  padding: 0 20px;

  ${media.desktop`
    padding: 0 60px;
  `};
`

const StyledTabs = styled(Tabs)`
  [data-element='tab-list-subcontainer'] {
    background-color: transparent;
  }

  [data-element='tab-list-sub-item-container'] {
    margin: 0 0 ${({ theme }) => theme.sizings.lvl3} 0;
    padding-right: 0;
  }

  [data-element='tab-tab-link'] {
    padding-top: 0;
  }

  & .TabChildContainer {
    background-color: transparent;
    padding: 0;
  }
`

export const DEFAULT_VALUE_CAR_KIND = 'ALL'
export const DEFAULT_VALUE_STANDTIME = 'ALL_STANDTIMES'
export const DEFAULT_VALUE_QUARTER = 'CURRENT_QUARTER'

const ManagementInfo = ({ match, auth, getLocations, locations }) => {
  const { t } = useTranslation()
  const [selectedLocationId, setSelectedLocationId] = useSelectedLocationId()
  const selectedDealerNumber = auth?.userDetails?.dealers?.find(
    (dealer) => dealer.id === selectedLocationId,
  )?.dealernr
  const selectedDealerNumbers = auth?.userDetails?.dealers.map(
    (dealer) => dealer.dealernr,
  )

  const mappedLocations = locations?.data?.map((location) => ({
    ...location,
    id: location.id.length ? location.id : '0',
  }))

  useEffect(() => {
    getLocations()
  }, [getLocations])

  const tabItems = [
    {
      label: t('managementInfo.tabs.standtime'),
      to: `${match.url}${MANAGEMENT_INFO_STAND_TIME}`,
    },
    {
      label: t('managementInfo.tabs.taxation'),
      to: `${match.url}${MANAGEMENT_INFO_TAXATION}`,
    },
  ]

  return (
    <AppLayout pageTitle={t('managementInfo.page.metaTitle')}>
      <Content>
        <PageHeader
          locations={mappedLocations}
          selectedLocation={selectedLocationId}
          setSelectedLocation={setSelectedLocationId}
          mainTitle={t('managementInfo.page.pageTitle.primaryWord')}
          status={t('managementInfo.page.pageTitle.secondaryWord')}
          // dateRange="" // TODO: in the design this shows the data for "Data updated on {{ date }} - but BE still has to deliver"
          hasBorderBottom={false}
        />
        <StyledTabs items={tabItems} small>
          <ManagementInfoTabStandTime
            dealerNumbers={selectedDealerNumber || selectedDealerNumbers}
          />
          <ManagementInfoTabTaxation
            dealerNumbers={selectedDealerNumber || selectedDealerNumbers}
          />
        </StyledTabs>
      </Content>
    </AppLayout>
  )
}

ManagementInfo.propTypes = {
  match: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getLocations: PropTypes.func.isRequired,
  locations: PropTypes.object,
}

ManagementInfo.defaultProps = {
  locations: null,
}

export default withRouter(ManagementInfo)
