import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Button from 'components/atoms/button'

const Input = styled.input`
  display: none;
`

function createFormData(file, position, positionOffset) {
  const formData = new FormData()

  formData.append('file', file)
  formData.append('name', file.name)
  formData.append('position', position + positionOffset)

  return formData
}

function getButtonLevel(level, text) {
  if (!level) {
    return text ? 'standard' : 'minimal'
  }
  return text ? level : 'minimal'
}

function getButtonBackgroundColor(level, text) {
  if (level) {
    return {
      standard: 'actionsStandard',
      cta: 'actionsCta',
    }[level]
  }
  return text ? null : 'actionsStandard'
}

function SelectFileButton({
  allowedFileExtensions,
  className,
  existingAssetsCount,
  icon,
  iconSize,
  id,
  level,
  multiple,
  onChange,
  text,
  disabled,
  ...restProps
}) {
  const inputEl = useRef(null)

  function handleChange(event) {
    const files = Array.from(event.target.files)

    files.forEach((file, index) => {
      const formData = createFormData(file, index, existingAssetsCount)
      inputEl.current.value = null
      if (onChange) {
        onChange(formData)
      }
    })
  }

  return (
    <div className={className} {...restProps}>
      <Button
        as="label"
        background={getButtonBackgroundColor(level, text)}
        htmlFor={id}
        icon={icon}
        iconSize={iconSize}
        level={getButtonLevel(level, text)}
        text={text}
        disabled={disabled}
      />
      <Input
        accept={allowedFileExtensions.join(',')}
        id={id}
        ref={inputEl}
        type="file"
        onChange={handleChange}
        multiple={multiple}
        disabled={disabled}
      />
    </div>
  )
}

SelectFileButton.propTypes = {
  /**
   * what file types are allowed for uploading
   */
  allowedFileExtensions: PropTypes.array.isRequired,
  className: PropTypes.string,
  existingAssetsCount: PropTypes.number,
  /**
   * icon displayed in the button
   */
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  /**
   * unique id needed for the hidden input
   */
  id: PropTypes.string.isRequired,
  /**
   * button level property to use [see Button for details](/#/Atoms/Button)
   */
  level: PropTypes.string,
  /**
   * Allow for multiple files to be uploaded a the same time
   */
  multiple: PropTypes.bool,
  /**
   * onchange handler. See code example on how to use this.
   * Returns a [instance of FormData](https://developer.mozilla.org/en-US/docs/Web/API/FormData)
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Text displayed on the button
   */
  text: PropTypes.string,
  disabled: PropTypes.bool,
}

SelectFileButton.defaultProps = {
  className: null,
  existingAssetsCount: 0,
  icon: null,
  iconSize: null,
  level: null,
  multiple: false,
  text: null,
  disabled: false,
}

export default SelectFileButton
