/*
 * A reducer very similar to the generic data reducer
 * but different in the sense that a lot of settings fetch the same
 * data for a different location or a different user for instance.
 * That's why the 'id' in data is replaced by 'setting' here and 'id'
 * for the settings actions is used to indicate for which location, user
 * or other sub category the data is fetched.
 */

import { types } from 'redux/actions/settings'

export const initialState = {}

export function reducer(state = initialState, action) {
  const { data, error, id, setting, payload, type } = action

  switch (type) {
    case types.REQUEST_SETTING: {
      return {
        ...state,
        [setting]: {
          ...state[setting],
          [id]: {
            ...(state[setting] ? state[setting][id] : {}),
            error: null,
            loading: true,
            payload,
          },
        },
      }
    }

    case types.POST_SETTING: {
      return {
        ...state,
        [setting]: {
          ...state[setting],
          [id]: {
            error: null,
            loading: true,
            payload,
          },
        },
      }
    }

    case types.RECEIVE_SETTING: {
      return {
        ...state,
        [setting]: {
          ...state[setting],
          [id]: {
            loading: false,
            data,
          },
        },
      }
    }

    case types.FAIL_SETTING_REQUEST: {
      return {
        ...state,
        [setting]: {
          ...state[setting],
          [id]: {
            loading: false,
            error,
          },
        },
      }
    }

    default:
      return state
  }
}
