import { gql } from '@apollo/client'

export const GET_PROSPECTS = gql`
  query GetProspects {
    prospects {
      key
      label
    }
  }
`
