import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import { licensePlans } from 'config/data'

import Tabs from 'components/layouts/tabs'
import B2bSalesSettings from 'components/redux-containers/b2b-sales-settings-container'
import B2bPurchasingSettings from 'components/redux-containers/b2b-purchasing-settings-container'
import TransportCompaniesSettings from 'components/redux-containers/transport-companies-settings-container'
import CleaningCompaniesSettings from 'components/redux-containers/cleaning-companies-settings-container'
import B2bPurchasingProfileSettings from 'components/redux-containers/b2b-purchasing-profile-settings-container'
import PdfSettings from 'components/views/settings/pdf-settings'
import LocationsTagsForm from 'components/views/settings/tags/location-tags-form'
import LocationsAccessoriesSettings from 'components/views/settings/locations-accessories-settings'
import LocationsMainSettings from 'components/views/settings/locations-main-settings'
import LocationsAdvertisementSettings from 'components/views/settings/locations-advertisement-settings'
import LocationsMarktplaatsSettings from 'components/views/settings/locations-marktplaats-settings'
import LocationsDeliveryPackagesSettings from 'components/views/settings/locations-delivery-packages-settings'
import LocationsChecklistsSettings from 'components/views/settings/locations-checklists-settings'
import LocationsRDWSettings from 'components/views/settings/locations-rdw-settings'
import LocationsTopDownSettings from 'components/views/settings/locations-topdown-settings'
import MyDataSettings from 'components/views/settings/my-data-settings'
import CarServicePlanSettings from 'components/views/settings/car-service-plan'
import CarServicePlanAdminDealers from 'components/views/settings/car-service-plan/admin/dealers-overview'
import CarServicePlanAdminWarrantyProgramsOverview from 'components/views/settings/car-service-plan/admin/warranty-programs-overview'
import CarServicePlanAdminMonitoringOverview from 'components/views/settings/car-service-plan/admin/monitoring/monitoring-overview'
import CarServicePlanAdminContractContents from 'components/views/settings/car-service-plan/admin/contract-contents'

import useDealerLocationCountryCode from 'hooks/use-location-country-code'

import ClustersOverview from './checklists/clusters-overview'

function SettingsTab({ userDetails, match }) {
  const { t } = useTranslation()
  const { locationCountryCodeIsNl } = useDealerLocationCountryCode()

  const userIsAdmin = Boolean(userDetails.admin)
  // A system admin is a role for a user that works for UCC and manages clients:
  const userIsSystemAdmin = userDetails.systemAdmin
  const userHasUccLicense = !!userDetails.pakket
  const userHasCspLicense = userDetails.csp
  const userOnlyHasCspLicense = !userHasUccLicense && userHasCspLicense
  const userHasProPlan = userDetails.pakket === licensePlans.pro
  const userHasPortalManager = !!userDetails.portalmanager
  const userIsAllowedToUseChecklist = !!userDetails?.flexflow

  const mainItems = []

  if (!userOnlyHasCspLicense) {
    mainItems.push({
      label: t('configurePdf'),
      to: `${match.url}/main/pdf`,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      component: useCallback((props) => <PdfSettings {...props} />, []),
    })
  }

  // The main sub tab is accessible to all types of users:
  const locationItems = [
    {
      label: t('main'),
      to: `${match.url}/locations/main`,
      component: useCallback(
        (props) => <LocationsMainSettings {...props} />,
        [],
      ),
    },
  ]

  if (userHasProPlan) {
    locationItems.push({
      label: t('configureTopdown'),
      to: `${match.url}/locations/topdown`,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      component: useCallback(
        (props) => <LocationsTopDownSettings {...props} />,
        [],
      ),
    })
  }

  // These sub tabs are only available to UCC license holders:
  const otherLocationItems = [
    {
      label: t('advertising'),
      to: `${match.url}/locations/advertising`,
      component: useCallback(
        (props) => (
          <LocationsAdvertisementSettings
            {...props}
            userHasPortalManager={userHasPortalManager}
          />
        ),
        [userHasPortalManager],
      ),
    },
    {
      label: t('deliveryPackages'),
      to: `${match.url}/locations/delivery-packages`,
      component: useCallback(
        (props) => <LocationsDeliveryPackagesSettings {...props} />,
        [],
      ),
    },
  ]

  // Only add "marktplaats" when the company is NL
  if (locationCountryCodeIsNl) {
    otherLocationItems.splice(1, 0, {
      label: t('marktplaats'),
      to: `${match.url}/locations/marktplaats`,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      component: useCallback(
        (props) => <LocationsMarktplaatsSettings {...props} />,
        [],
      ),
    })
  }

  const checklistsTab = {
    label: t('checklists'),
    to: `${match.url}/locations/flexflows`,
    component: useCallback(
      (props) => <LocationsChecklistsSettings {...props} />,
      [],
    ),
  }

  const checklistsPerClustersTab = {
    label: t('checklistsPerCluster'),
    to: `${match.url}/locations/flexflows-per-cluster`,
    component: useCallback((props) => <ClustersOverview {...props} />, []),
  }

  if (userIsAllowedToUseChecklist) {
    otherLocationItems.push(...[checklistsTab, checklistsPerClustersTab])
  }

  if (locationCountryCodeIsNl) {
    otherLocationItems.push({
      label: t('rdwSettings'),
      to: `${match.url}/locations/rdw`,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      component: useCallback(
        (props) => <LocationsRDWSettings {...props} />,
        [],
      ),
    })
  }

  otherLocationItems.push({
    label: t('accessories'),
    to: `${match.url}/locations/accessories`,
    component: useCallback(
      (props) => <LocationsAccessoriesSettings {...props} />,
      [],
    ),
  })

  if (userIsAdmin) {
    otherLocationItems.push({
      label: t('tags'),
      to: `${match.url}/locations/tags`,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      component: useCallback((props) => <LocationsTagsForm />, []),
    })
  }

  if (userHasUccLicense) {
    locationItems.push(...otherLocationItems)
  }

  const relationsManagementItems = [
    {
      label: t('cleaningCompanies'),
      to: `${match.url}/relations-management/cleaning-companies`,
      component: useCallback(
        (props) => <CleaningCompaniesSettings {...props} />,
        [],
      ),
    },
  ]

  relationsManagementItems.splice(0, 0, {
    label: t('b2bPurchase'),
    to: `${match.url}/relations-management/b2b-purchasing`,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    component: useCallback((props) => <B2bPurchasingSettings {...props} />, []),
  })

  // Only add "b2bPurchase" when the company is NL
  if (locationCountryCodeIsNl) {
    relationsManagementItems.splice(1, 0, {
      label: t('b2bPurchaseProfile'),
      to: `${match.url}/relations-management/b2b-purchasing-profile`,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      component: useCallback(
        (props) => <B2bPurchasingProfileSettings {...props} />,
        [],
      ),
    })
  }

  // Only add "b2bsales" when a user has a PRO plan
  if (userHasProPlan) {
    relationsManagementItems.unshift({
      label: t('b2bSales'),
      to: `${match.url}/relations-management/b2b-sales`,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      component: useCallback((props) => <B2bSalesSettings {...props} />, []),
    })
  }

  if (userIsAdmin && userHasProPlan) {
    relationsManagementItems.splice(relationsManagementItems.length - 1, 0, {
      label: t('transportCompanies'),
      to: `${match.url}/relations-management/transport-companies`,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      component: useCallback(
        (props) => <TransportCompaniesSettings {...props} />,
        [],
      ),
    })
  }

  const tabItems = []

  if (userHasUccLicense && !userOnlyHasCspLicense && userIsAdmin) {
    tabItems.push({
      label: t('main'),
      to: `${match.url}/main/topdown`,
      matchTo: `${match.url}/main/:subitem?`,
      subItems: mainItems,
    })
  }

  if ((userHasUccLicense || userHasCspLicense) && userIsAdmin) {
    tabItems.push({
      label: t('locations'),
      to: `${match.url}/locations/main`,
      matchTo: `${match.url}/locations/:subitem?`,
      subItems: locationItems,
    })
  }

  tabItems.push({
    label: t('myData'),
    to: `${match.url}/my-data`,
    component: useCallback((props) => <MyDataSettings {...props} />, []),
  })

  if (userHasUccLicense && userIsAdmin) {
    tabItems.push({
      label: t('relationsManagement'),
      to: `${match.url}/relations-management/main`,
      matchTo: `${match.url}/relations-management/:subitem?`,
      subItems: relationsManagementItems,
    })
  }

  /**
   * Tabs for CarServicePlan:
   */

  const cspTab = {
    id: 'carServicePlan',
    hasSubTabs: true,
    label: t('carServicePlanTab'),
    to: `${match.url}/car-service-plan`,
    matchTo: `${match.url}/car-service-plan/:subitem?`,
    component: useCallback(
      (props) => <CarServicePlanSettings {...props} />,
      [],
    ),
  }
  const cspTabAdminDealersOverview = {
    id: 'CarServicePlanAdminDealersOverview',
    label: `${t('carServicePlanAdminDealersOverview.title')}`,
    to: `${match.url}/car-service-plan/dealers-overview`,
    component: useCallback(
      () => (
        <CarServicePlanAdminDealers
          basePath={`${match.url}/car-service-plan/dealers-overview`}
        />
      ),
      [match.url],
    ),
  }
  const cspTabAdminProvidersOverview = {
    id: 'CarServicePlanProvidersOverview',
    label: `${t('carServicePlanAdminWarrantyProgramsOverview.title')}`,
    to: `${match.url}/car-service-plan/programs-overview`,
    component: useCallback(
      (props) => (
        <CarServicePlanAdminWarrantyProgramsOverview
          {...props}
          basePath={`${match.url}/car-service-plan/programs-overview`}
        />
      ),
      [match.url],
    ),
  }
  const cspTabAdminMonitoringOverview = {
    id: 'CarServicePlanAdminMonitoringOverview',
    label: `${t('carServicePlanAdminMonitoringOverview.title')}`,
    to: `${match.url}/car-service-plan/monitoring-overview`,
    component: useCallback(
      (props) => <CarServicePlanAdminMonitoringOverview {...props} />,
      [],
    ),
  }
  const cspTabAdminContractContents = {
    id: 'CarServicePlanAdminContractContents',
    label: `${t('carServicePlanAdminContractContents.title')}`,
    to: `${match.url}/car-service-plan/contract-contents`,
    component: useCallback(
      (props) => <CarServicePlanAdminContractContents {...props} />,
      [],
    ),
  }
  if (userHasCspLicense) {
    if (userIsSystemAdmin) {
      tabItems.push(cspTabAdminDealersOverview)
      tabItems.push(cspTabAdminProvidersOverview)
      tabItems.push(cspTabAdminMonitoringOverview)
      tabItems.push(cspTabAdminContractContents)
    } else {
      tabItems.push(cspTab)
    }
  }

  return (
    <Tabs items={tabItems}>
      {tabItems.map((item, index) =>
        item.subItems ? (
          <Tabs items={item.subItems} key={index.toString()} small>
            {item.subItems.map((subItem, subIdx) => (
              <subItem.component key={subIdx.toString()} />
            ))}
          </Tabs>
        ) : (
          <item.component key={index.toString()} />
        ),
      )}
    </Tabs>
  )
}

SettingsTab.propTypes = {
  match: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
}

SettingsTab.defaultProps = {}

export default withRouter(SettingsTab)
