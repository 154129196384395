import { connect } from 'react-redux'
import { addBackgroundImage, getBackgroundImage } from 'redux/actions/settings'
import BackgroundImageForm from '../organisms/background-image-form'

const mapStateToProps = ({ settings }) => ({
  backgroundImage: settings.backgroundImage,
})

const mapDispatchToProps = {
  addBackgroundImage,
  getBackgroundImage,
}

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundImageForm)
