import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useDynamicEndpointDone from 'hooks/use-dynamic-endpoint-done'
import Button from 'components/atoms/button'
import OptionsBar from 'components/layouts/options-bar'
import SimpleTable from 'components/layouts/simple-table'
import Text from 'components/atoms/text'
import Link from 'components/atoms/link'
import DropDownFilter from 'components/molecules/drop-down-filter'

function LocationUserSettings({
  openOverlay,
  getUsersForLocation,
  usersForLocation,
  postDynamicEndpoint,
  dynamicEndpoint,
  params,
}) {
  const { t } = useTranslation()

  useEffect(() => {
    getUsersForLocation()
  }, [getUsersForLocation])

  useDynamicEndpointDone(dynamicEndpoint, getUsersForLocation, [
    getUsersForLocation,
    dynamicEndpoint,
  ])

  // Using negative wording, because this state is sent back from BE in negative form too.
  // This makes the code easier to read.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showEnabledUsers, setShowEnabledUsers] = useState('1') // aka thruthy.

  const switchFilters = (newValue) => {
    setShowEnabledUsers(newValue)
  }

  const disabledUserFilterOptions = [
    {
      id: '1',
      label: t('showEnabledUsers'),
    },
    {
      id: '0',
      label: t('showDisabledUsers'),
    },
  ]
  const rows =
    usersForLocation && !usersForLocation.loading
      ? usersForLocation.data.data
          .filter(
            (row) =>
              // eslint-disable-next-line
              row.location === params.vestiging_id &&
              String(row.disabled) !== showEnabledUsers,
          )
          .map((row) => [
            <Text type="boldLabel" key="username" text={row.username} />,
            <Text
              type="boldLabel"
              key="name"
              text={`${row.firstname} ${row.lastname}`}
            />,
            <Text type="boldLabel" key="phone" text={row.phone1} />,
            <Link
              key="overlayLink"
              onClick={() => {
                openOverlay('dynamicForm', {
                  formId: 'updateUser',
                  formParams: {
                    user_id: row.id,
                  },
                  onSubmit: (values, endpoint) => {
                    postDynamicEndpoint({
                      endpoint,
                      ...values,
                      id: row.id,
                    })
                  },
                  subId: String(row.id),
                  icon: 'user',
                  submitText: t('saveChanges'),
                  title: t('modifyUser'),
                })
              }}
            >
              {t('modify')}
            </Link>,
          ])
      : []

  return (
    <section>
      {!usersForLocation || usersForLocation.loading ? (
        <p>Uw gegevens worden opgehaald</p>
      ) : (
        <>
          <OptionsBar
            leftAligned={[
              <DropDownFilter
                key="dropDownFilter"
                filterOptions={disabledUserFilterOptions}
                onFilterUpdate={switchFilters}
                selectedFilter={showEnabledUsers}
              />,
            ]}
            rightAligned={[
              <Button
                key="button"
                onClick={() => {
                  openOverlay('dynamicForm', {
                    formId: 'updateUser',
                    onSubmit: (values, endpoint) => {
                      postDynamicEndpoint({
                        endpoint,
                        ...values,
                      })
                    },
                    subId: '',
                    icon: 'user',
                    submitText: t('addNewUser'),
                    title: t('newUser'),
                  })
                }}
              >
                {t('addNewUser')}
              </Button>,
            ]}
          />
          <Text type="h2" text="Gebruikers" />
          <SimpleTable
            columns={[
              <Text
                type="floatingLabelLabel"
                key="username"
                text={t('username')}
              />,
              <Text type="floatingLabelLabel" key="name" text={t('name')} />,
              <Text
                type="floatingLabelLabel"
                key="phoneNumber"
                text={t('phoneNumber')}
              />,
              <Text
                type="floatingLabelLabel"
                key="modifyDataAndRights"
                text={t('modifyDataAndRights')}
              />,
            ]}
            rows={rows}
            data={usersForLocation.data.data}
            legend={usersForLocation.legend}
          />
        </>
      )}
    </section>
  )
}

LocationUserSettings.propTypes = {
  openOverlay: PropTypes.func.isRequired,
  getUsersForLocation: PropTypes.func.isRequired,
  usersForLocation: PropTypes.object,
  postDynamicEndpoint: PropTypes.func.isRequired,
  dynamicEndpoint: PropTypes.object,
  params: PropTypes.object.isRequired,
}

LocationUserSettings.defaultProps = {
  usersForLocation: null,
  dynamicEndpoint: null,
}

export default LocationUserSettings
