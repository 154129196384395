import { gql } from '@apollo/client'

export const GET_YEARLY_MILEAGES = gql`
  query GetYearlyMileages($dealerId: ID) {
    yearlyMileages(dealerId: $dealerId) {
      label # String
      value # Int
    }
  }
`
