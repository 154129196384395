import { gql } from '@apollo/client'
import driverInfo from '../fragments/driver'

export const ADD_DRIVER = gql`
  mutation createDriver(
    $customerId: ID!
    $title: String!
    $firstName: String!
    $lastName: String!
    $zipCode: String!
    $street: String!
    $streetNr: String!
    $city: String!
    $email: String!
    $telephone: String!
  ) {
    createDriver(
      customerId: $customerId
      title: $title
      firstName: $firstName
      lastName: $lastName
      zipCode: $zipCode
      city: $city
      email: $email
      telephone: $telephone
      street: $street
      streetNr: $streetNr
    ) {
      ...driverInfo
    }
  }
  ${driverInfo}
`

export const UPDATE_DRIVER = gql`
  mutation updateDriver(
    $id: ID!
    $title: String!
    $firstName: String!
    $lastName: String!
    $zipCode: String!
    $street: String!
    $streetNr: String!
    $city: String!
    $email: String!
    $telephone: String!
  ) {
    updateDriver(
      id: $id
      title: $title
      firstName: $firstName
      lastName: $lastName
      zipCode: $zipCode
      street: $street
      streetNr: $streetNr
      city: $city
      email: $email
      telephone: $telephone
    ) {
      ...driverInfo
    }
  }
  ${driverInfo}
`
