import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { media } from 'utilities/styled'

import LabeledText from 'components/atoms/labeled-text'
import Typography from 'components/molecules/typography'

const Container = styled.div`
  ${media.desktop`
    align-items: start;
    display: grid;
    grid-template-columns: 1fr auto;
  `};
`

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.brandDelta};
  border-top: 1px solid ${({ theme }) => theme.colors.brandDelta};
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  margin-top: 15px;
  padding-bottom: 15px;
  padding-top: 15px;

  ${media.desktop`
    border-bottom: 0;
    border-top: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  `};
`

const SellingPriceContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;

  & > *:last-child {
    margin-bottom: ${({ theme }) => theme.sizeByFactor(0.25)};
  }
`

const StyledSellingPrice = styled(LabeledText)`
  padding-right: ${({ theme }) => theme.sizeByFactor(1)};
`

const StyledPurchachedPrice = styled(LabeledText)`
  ${media.desktop`
    border-left: 1px solid ${({ theme }) => theme.colors.brandDelta};
    padding-left: 15px;
  `};
`

const StyledMarginPrice = styled(LabeledText)`
  border-left: 1px solid ${({ theme }) => theme.colors.brandDelta};
  padding-left: 15px;
`

function CarfilePrices({
  carfile,
  purchaseprice,
  sellingprice,
  salesView,
  taxMargin,
}) {
  const { t } = useTranslation()
  const includingVAT = carfile.verkoopprijs_incbtw
  const showTax = taxMargin !== 'marge'
  const sellingPriceBtw = includingVAT ? t('inclBtw') : t('exclBtw')

  return (
    <Container>
      <SellingPriceContainer>
        <StyledSellingPrice
          title={t('sellingPrice')}
          value={sellingprice || t('unknown')}
          price={!!sellingprice}
          highlight
          data-test-e2e="selling-price"
        />
        {sellingprice && showTax && (
          <Typography type="InlineBodyTextBold">{sellingPriceBtw}</Typography>
        )}
      </SellingPriceContainer>
      {!salesView && purchaseprice ? (
        <Wrapper>
          <StyledPurchachedPrice
            title={t('purchasePrice')}
            value={purchaseprice}
            price
          />
          <StyledMarginPrice
            title={t('margin')}
            value={sellingprice - purchaseprice}
            price
          />
        </Wrapper>
      ) : (
        <></>
      )}
    </Container>
  )
}

CarfilePrices.propTypes = {
  carfile: PropTypes.object.isRequired,
  purchaseprice: PropTypes.number,
  sellingprice: PropTypes.string,
  salesView: PropTypes.bool.isRequired,
  taxMargin: PropTypes.string.isRequired,
}

CarfilePrices.defaultProps = {
  purchaseprice: null,
  sellingprice: null,
}

export default CarfilePrices
