/* eslint-disable import/no-cycle */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Link from 'components/atoms/link'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import Menu from 'components/organisms/menu'
import PopupMenu from './popup-menu'

function getHoverColor(color) {
  return (
    {
      brandBravo: 'brandCharlie',
      brandGolf: 'brandFoxtrot',
    }[color] || 'brandFoxtrot'
  )
}

const StyledIcon = styled(Icon)`
  margin-right: 16px;
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  background-color: ${({ $backgroundColor, theme }) =>
    theme.colors[$backgroundColor]};
  display: flex;
  padding: ${({ variant, theme }) =>
    variant === 'popup' ? theme.sizings.lvl1 : '12px 32px'};

  &:hover {
    background-color: ${({ $backgroundColor, theme }) =>
      theme.colors[getHoverColor($backgroundColor)]};
  }

  &::first-letter {
    text-transform: capitalize;
  }

  > * {
    flex: 0 0 24px;
    display: block;
  }
`

const MenuItemText = styled(Text)`
  flex: 1 1;
  color: inherit;
  white-space: nowrap;

  &::first-letter {
    text-transform: capitalize;
  }
`

const ArrowIcon = styled(Icon)`
  margin-left: auto;
  transform: ${({ rotate }) => (rotate ? 'rotateX(180deg)' : '')};
  svg {
    /* adopt the same color as used for text: */
    fill: currentColor;
  }
`

function MenuItem({
  // eslint-disable-next-line react/prop-types
  backgroundColor,
  closeMenu,
  color,
  icon,
  items,
  label,
  to,
  hasMobileLayout,
  hideMobile,
  ...restProps
}) {
  const [submenuOpen, setSubmenuOpen] = useState(false)

  function toggleSubmenu() {
    setSubmenuOpen(!submenuOpen)
  }

  if (items) {
    if (hasMobileLayout) {
      return (
        <>
          <StyledLink
            color={color}
            $backgroundColor={backgroundColor}
            onClick={toggleSubmenu}
            {...restProps}
          >
            {icon && <StyledIcon color={color} type={icon} />}
            {label}
            <ArrowIcon type="arrowDropDown" rotate={submenuOpen} />
          </StyledLink>
          {submenuOpen && <Menu closeMenu={closeMenu} items={items} />}
        </>
      )
    }
    return (
      <PopupMenu
        items={items}
        positionMenu={{ toLeft: true }}
        isMenuActive={submenuOpen}
      >
        <StyledLink
          color={color}
          $backgroundColor={backgroundColor}
          onClick={toggleSubmenu}
          {...restProps}
        >
          {icon && <StyledIcon color={color} type={icon} />}
          {label}
          <ArrowIcon type="arrowDropDown" rotate={submenuOpen} />
        </StyledLink>
      </PopupMenu>
    )
  }
  return (
    <Link onClick={closeMenu}>
      <StyledLink
        $backgroundColor={backgroundColor}
        color={color}
        to={to}
        {...restProps}
      >
        {icon && <StyledIcon color={color} type={icon} />}
        <MenuItemText>{label}</MenuItemText>
      </StyledLink>
    </Link>
  )
}

MenuItem.propTypes = {
  backgroundColor: PropTypes.string,
  closeMenu: PropTypes.func,
  color: PropTypes.string,
  icon: PropTypes.string,
  items: PropTypes.array,
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  hideMobile: PropTypes.bool,
  hasMobileLayout: PropTypes.bool,
}

MenuItem.defaultProps = {
  backgroundColor: 'brandGolf',
  closeMenu: () => {},
  color: 'brandAlfa',
  icon: null,
  items: null,
  to: null,
  hideMobile: false,
  hasMobileLayout: false,
}

export default MenuItem
