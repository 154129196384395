import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import Overlay from 'components/molecules/overlay'
import Searchbox from 'components/molecules/searchbox'

const InnerContainer = styled.div`
  width: 100%;
`

function SearchOverlay({ closeOverlay }) {
  const overlayId = 'search'
  const { t } = useTranslation()

  return (
    <Overlay
      close={() => {
        closeOverlay(overlayId)
      }}
      icon="search"
      title={t('search')}
    >
      <InnerContainer>
        <Searchbox
          onSubmit={() => {
            closeOverlay(overlayId)
          }}
        />
      </InnerContainer>
    </Overlay>
  )
}

SearchOverlay.propTypes = {
  closeOverlay: PropTypes.func.isRequired,
}

export default SearchOverlay
