import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import styled from 'styled-components/macro'

import {
  CarFileStatusEnumUpperCase,
  ChecklistItemsFilterOptions,
} from 'config/enums'
import {
  CHECKLIST_BASICS,
  SINGLE_CHECKLIST_OVERLAY_DETAILS,
} from 'config/graphql/v4'
import { CAR_FILE as CAR_FILE_ROUTE } from 'config/routes'

import { withApolloV4Provider } from 'utilities/apollo'
import { formatDate, formatSingleChecklistsOverlayUrl } from 'utilities/format'
import { media } from 'utilities/styled'
import { capitalizeFirstLetter } from 'utilities/utils'

import LoadingIndicator from 'components/atoms/loading-indicator'
import Labels from 'components/molecules/labels'
import NewDropdownSelect from 'components/molecules/new-dropdown-select'
import Overlay from 'components/molecules/overlay'
import Paging from 'components/molecules/paging'
import Typography from 'components/molecules/typography'
import SingleChecklistStatusTable from 'components/views/checklists/single-checklist-status-table'

const StyledOverlay = styled(Overlay)`
  max-height: 100vh;
  height: 100vh;
  bottom: 0;
  right: 0;
  padding: 0;
  overflow: hidden;

  &:after {
    content: none;
  }

  .header {
    padding-top: ${({ theme }) => theme.sizings.lvl3};
    flex-shrink: 0;
    flex-grow: 0;
    margin-bottom: ${({ theme }) => theme.sizings.lvl1};
    margin-left: ${({ theme }) => theme.sizings.lvl3};
    margin-right: ${({ theme }) => theme.sizings.lvl3};
  }

  .child-container {
    margin: 0; // Margin for this is set on
    padding-bottom: ${({ theme }) => theme.sizings.lvl3};
    flex-shrink: 999;
    flex-grow: 0;
    flex-direction: column;
    min-height: 0; // Required to make the scrolling work properly...
  }
`

const ChecklistInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.sizings.lvl6};
  margin-left: ${({ theme }) => theme.sizings.lvl4};
  margin-right: ${({ theme }) => theme.sizings.lvl8};

  > * {
    flex-shrink: 0;
  }
`

const Title = styled(Typography)`
  margin-top: 0;
  margin-bottom: 0;
  margin-right: ${({ theme }) => theme.sizings.lvl2};
`

const DropdownInHeader = styled(NewDropdownSelect)`
  display: block;
  min-width: ${({ theme }) => theme.sizeByFactor(20)};
  margin-right: ${({ theme }) => theme.sizings.lvl2};

  > div {
    width: 100%;
  }

  ${media.tablet`
    min-width: ${({ theme }) => theme.sizeByFactor(36)};
  `}
`

const StatussesTable = styled(SingleChecklistStatusTable)`
  padding-left: ${({ theme }) => theme.sizings.lvl4};

  .scroll-container {
    padding-right: ${({ theme }) => theme.sizings.lvl8};
  }
`

const HorizontalPaddingContainer = styled.div`
  margin-left: ${({ theme }) => theme.sizings.lvl4};
  margin-right: ${({ theme }) => theme.sizings.lvl8};
`

const StyledPaging = styled(Paging)`
  margin-top: ${({ theme }) => theme.sizings.lvl4};
`

export const STATUS_PARAM = 'status'
export const SORT_ON_PARAM = 'sortOn'
export const ACTIVE_PAGE_PARAM = 'p'

const SingleChecklistOverlay = ({
  checklistId: checklistIdFromProps,
  checklistItemId: checklistItemIdFromProps,
  ...restProps
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const url = useLocation()

  const checklistId = checklistIdFromProps || params.checklistId || undefined
  const checklistItemId =
    checklistItemIdFromProps || params.checklistItemId || undefined

  const searchParams = new URLSearchParams(url.search.replace(/^\?/, ''))
  const statusFromUrl = searchParams.get(STATUS_PARAM)
  const sortedOnIdFromUrl = searchParams.get(SORT_ON_PARAM)
  const activePageFromUrl = searchParams.get(ACTIVE_PAGE_PARAM)
  const initialStatus =
    statusFromUrl &&
    Object.values(ChecklistItemsFilterOptions).includes(statusFromUrl)
      ? statusFromUrl
      : ChecklistItemsFilterOptions.ALL
  const initialSortedOnId = sortedOnIdFromUrl || checklistItemId
  const intialActivePage =
    !isNaN(parseInt(activePageFromUrl)) && parseInt(activePageFromUrl) > 0
      ? parseInt(activePageFromUrl)
      : 1

  const [referrerUrl, setReferrerUrl] = React.useState(undefined)
  const [checklistItemStatusFilter, setChecklistItemStatusFilter] =
    React.useState(initialStatus)
  const [activePage, setActivePage] = React.useState(intialActivePage)
  const [sortedOnChecklistItemId, setSortedOnChecklistItemId] =
    React.useState(initialSortedOnId)
  const initialized = React.useRef(false)

  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      if (history.location.state && history.location.state.referrerUrl) {
        setReferrerUrl(history.location.state.referrerUrl)
      }
    }
  }, [history])

  React.useEffect(() => {
    // history.replace(formatChecklistsOverlayUrl(selectedLocationId, status, checklistId, url.search))
    history.replace(
      formatSingleChecklistsOverlayUrl(checklistId, checklistItemId, {
        [STATUS_PARAM]: checklistItemStatusFilter,
        [SORT_ON_PARAM]: sortedOnChecklistItemId,
        [ACTIVE_PAGE_PARAM]: activePage,
      }),
    )
  }, [
    history,
    checklistId,
    checklistItemId,
    checklistItemStatusFilter,
    sortedOnChecklistItemId,
    activePage,
  ])

  const {
    data: checklistData,
    loading: checklistLoading,
    error: checklistError,
  } = useQuery(CHECKLIST_BASICS, {
    variables: {
      id: checklistId,
    },
    skip: !checklistId,
  })

  const checklistStatus = checklistData?.checklist.status
    ? t(`carFileStatusses.${checklistData.checklist.status}`)
    : undefined
  const checklistItemName =
    checklistItemId &&
    Array.isArray(checklistData?.checklist?.items) &&
    checklistData.checklist.items.find((item) => item.id === checklistItemId)
      ?.name

  const perPage = 20
  const {
    data: statussesData,
    loading: statussesLoading,
    error: statussesError,
  } = useQuery(SINGLE_CHECKLIST_OVERLAY_DETAILS, {
    variables: {
      checklistId,
      checklistItemId, // Could be undefined
      first: perPage,
      page: activePage,
      filterState:
        checklistItemStatusFilter !== ChecklistItemsFilterOptions.ALL
          ? checklistItemStatusFilter
          : undefined,
      sortOnChecklistItemId: sortedOnChecklistItemId,
      sortDirection: sortedOnChecklistItemId ? 'DESC' : undefined,
    },
    skip: !checklistId,
  })

  const errorMessage = !checklistId
    ? t('singleChecklistOverlay.dataMissing')
    : statussesError || checklistError
  const status = checklistData?.flexFlowWidgetOverview?.checklist?.status

  const checklistItems = Array.isArray(
    statussesData?.flexFlowWidgetOverview?.checklist.items,
  )
    ? statussesData.flexFlowWidgetOverview.checklist.items.map((item) => ({
        id: item.id,
        name: item.name,
        isHighlighted: sortedOnChecklistItemId === item.id,
        isSelected: checklistItemId === item.id,
      }))
    : []

  const getDate = ({ maanddeel1, jaardeel1 }) => {
    const parts = []
    if (maanddeel1 && maanddeel1 !== '0') {
      parts.push(capitalizeFirstLetter(formatDate(maanddeel1, 'M', 'MMMM')))
    }
    if (jaardeel1) {
      parts.push(jaardeel1)
    }

    return parts.join(' ')
  }

  const rows = Array.isArray(statussesData?.flexFlowWidgetOverview?.cars)
    ? statussesData?.flexFlowWidgetOverview?.cars.map((result) => ({
        id: result.car.id,
        car: {
          id: result.car.id,
          licensePlate: result.car.kenteken,
          vin: result.car.vin,
          image: result.car.firstCarimage?.mediaUri,
          brand: result.car.merk,
          model: result.car.modelserie,
          type: result.car.type,
          mileage:
            status === CarFileStatusEnumUpperCase.lopend
              ? result.car.km_tax
              : result.car.km_binnen,
          date: getDate(result.car),
          link: `${CAR_FILE_ROUTE}/${result.car.id}/${checklistId}`,
        },
        checklistStatusses: result.checklistItemStates.map((item) => ({
          status: item.state,
          amount: item.leadTime,
        })),
      }))
    : []

  const numberOfResults =
    statussesData?.flexFlowWidgetOverview?.paginatorInfo?.total || 0
  const tableTitle =
    numberOfResults > 0
      ? t(
          numberOfResults === 1 ? 'numberOfResults' : 'numberOfResults_plural',
          { count: numberOfResults },
        )
      : undefined
  const tableSubtitle = t('checklistsOverlay.sortedOn', {
    item: sortedOnChecklistItemId
      ? checklistItems.find((item) => item.id === sortedOnChecklistItemId)?.name
      : t('checklistsOverlay.allCars'),
  })
  const checklistItemStatusFilterText = t(
    `checklistsFilterTypes.${checklistItemStatusFilter}`,
  )

  return (
    <StyledOverlay
      title={t('checklistsOverlay.title')}
      icon="checklist"
      close={() => {
        history.push(referrerUrl || '/')
      }}
      headerRightSlot={
        <DropdownInHeader
          items={Object.values(ChecklistItemsFilterOptions).map((value) => ({
            value,
            label: t(`checklistsFilterTypes.${value}`),
          }))}
          selectionRequired
          filled
          label={t('checklistsFilterLabel')}
          onChange={(value) => {
            setChecklistItemStatusFilter(value)
          }}
          value={checklistItemStatusFilter}
        />
      }
      {...restProps}
    >
      {checklistData && (
        <ChecklistInfo>
          <Title type="Level2Heading">
            {checklistStatus && `${checklistStatus}: `}
            {checklistData.checklist.name}
            {checklistItemName && ` - ${checklistItemName}`}
          </Title>
          <Labels
            texts={checklistData.checklist.soort.map((soort) =>
              t(`carKinds.${soort.soort}`),
            )}
            includeWrapper={false}
            gapLevel={0}
          />
        </ChecklistInfo>
      )}
      {statussesLoading || checklistLoading || errorMessage ? (
        <HorizontalPaddingContainer>
          <LoadingIndicator error={errorMessage} />
        </HorizontalPaddingContainer>
      ) : numberOfResults !== undefined && numberOfResults === 0 ? (
        <HorizontalPaddingContainer>
          <Typography type="BodyParagraph">
            {checklistItemStatusFilter === ChecklistItemsFilterOptions.ALL
              ? t('checklistsNoCarFound')
              : t('checklistsNoCarFoundForStatus', {
                  status: checklistItemStatusFilterText,
                })}
          </Typography>
        </HorizontalPaddingContainer>
      ) : (
        <>
          <StatussesTable
            checklistItems={checklistItems}
            rows={rows}
            title={tableTitle}
            subtitle={tableSubtitle}
            onHeadItemClick={(item, e) => {
              setSortedOnChecklistItemId((currentItemId) =>
                currentItemId === item.id ? undefined : item.id,
              )
            }}
            stickyFirstRow
            stickyFirstColumn
            firstRowBgColor="alternativeBackground"
            firstColumnBgColor="alternativeBackground"
          />
          {numberOfResults && numberOfResults > perPage && (
            <StyledPaging
              perPage={perPage}
              total={numberOfResults}
              activePage={activePage}
              onChangePage={(newPage) => {
                setActivePage(newPage)
              }}
            />
          )}
        </>
      )}
    </StyledOverlay>
  )
}

SingleChecklistOverlay.propTypes = {
  checklistId: PropTypes.string,
  checklistItemId: PropTypes.string,
}

export default withApolloV4Provider(SingleChecklistOverlay)
