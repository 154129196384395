import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { media } from 'utilities/styled'

import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import PageHeader from 'components/organisms/page-header'
import Tabs from 'components/layouts/tabs'
import AdsOverview from 'components/organisms/ads-overview'
import MarktplaatsForm from 'components/redux-containers/marktplaats-form-container'

import useDealerLocationCountryCode from 'hooks/use-location-country-code'

const Container = styled.div`
  padding: 0 20px 20px 20px;

  ${media.desktop`
    padding: 0 60px 60px 60px;
  `};
`

const StyledTab = styled.section`
  display: flex;
  flex-direction: column;
`

function Ads({
  ads,
  filters,
  getAds,
  getAdsFilters,
  getLocations,
  locations,
  match,
}) {
  const { t } = useTranslation()
  const { locationCountryCodeIsNl } = useDealerLocationCountryCode()

  const tabItems = [{ label: t('ads'), to: `${match.url}/general` }]

  if (locationCountryCodeIsNl) {
    tabItems.push({ label: t('marktplaats'), to: `${match.url}/marktplaats` })
  }

  const tabRef = React.createRef()

  useLayoutEffect(() => {
    if (tabRef.current) {
      const { top } = tabRef.current.getBoundingClientRect()
      const viewportHeight =
        (window.visualViewport && window.visualViewport.height) ||
        window.innerHeight
      const newHeight = viewportHeight - top
      tabRef.current.style.height = `${newHeight - 100}px`
    }
  })

  return (
    <AppLayoutContainer pageTitle={t('ads')}>
      <Container>
        <PageHeader mainTitle={t('overview')} subTitle={t('ad')} />
        <Tabs items={tabItems}>
          <StyledTab ref={tabRef}>
            <AdsOverview
              ads={ads}
              filters={filters}
              getAds={getAds}
              getAdsFilters={getAdsFilters}
              getLocations={getLocations}
              locations={locations}
            />
          </StyledTab>
          <StyledTab>
            <MarktplaatsForm />
          </StyledTab>
        </Tabs>
      </Container>
    </AppLayoutContainer>
  )
}

Ads.propTypes = {
  ads: PropTypes.object,
  filters: PropTypes.object,
  getAds: PropTypes.func.isRequired,
  getAdsFilters: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  locations: PropTypes.object,
  match: PropTypes.object.isRequired,
}

Ads.defaultProps = {
  ads: null,
  filters: null,
  locations: null,
}

export default withRouter(Ads)
