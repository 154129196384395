import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'

const CommonStyles = css`
  color: ${({ theme, $color }) => theme.colors[$color]};
  font-family: ${({ theme }) => theme.font};
  // Not using $size, to prevent any merge conflicts. A very pragmatic solution, I know, but I was time-constrained.
  font-size: ${({ theme, size }) =>
    size ? theme.smallFontSize : theme.baseFontSize};
  line-height: ${({ theme }) => theme.baseLineHeight};
  & + * {
    /**
      Spaces between sibling elements seem to be removed.
      This puts it back:
     */
    margin-left: 0.25em;
  }
`

const Span = styled.span`
  ${CommonStyles}
`

const Strong = styled.strong`
  ${CommonStyles}
  font-weight: 600;
`

const StrikeThrough = styled.s`
  ${CommonStyles}
`
const TonedDown = styled.span`
  ${CommonStyles}
`

/**
 * Displays a standard inlined element. Can generate a `span` and `strong`.
 * If you need a Block level element please use BodyParagraph.
 */
const InlineBodyText = ({
  className,
  children,
  color,
  weight,
  size,
  variant,
  ...restProps
}) => {
  const variantType = variant || weight
  switch (variantType) {
    default:
    case 'normal':
      return (
        <Span $color={color || 'text'} className={className} {...restProps}>
          {children}
        </Span>
      )
    case 'bold':
      return (
        <Strong $color={color || 'text'} className={className} {...restProps}>
          {children}
        </Strong>
      )
    case 'notPresent':
      return (
        <StrikeThrough
          $color="textTonedDown"
          className={className}
          {...restProps}
        >
          {children}
        </StrikeThrough>
      )
    case 'tonedDown':
      return (
        <TonedDown $color="textTonedDown" className={className} {...restProps}>
          {children}
        </TonedDown>
      )
  }
}

InlineBodyText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  /** One of the theme color names */
  color: PropTypes.string,
  variant: PropTypes.oneOf([
    'normal', // translates into a span tag
    'bold', // translates into a strong tag
    'notPresent', // used for things that are inactive/not applicable or not present
    'tonedDown', // used to show that something is not
  ]),
  /** deprecated, use variant instead */
  weight: PropTypes.oneOf([
    'normal', // translates into a span tag
    'bold', // translates into a strong tag
  ]),
  size: PropTypes.oneOf(['normal', 'small']),
}

InlineBodyText.defaultProps = {
  className: null,
  children: null,
  color: 'text',
  weight: 'normal',
  variant: 'normal',
  size: 'normal',
}

export default InlineBodyText
