import LoadingIndicator from 'components/atoms/loading-indicator'
import ComplexForm from 'components/molecules/complex-form'
import PropTypes from 'prop-types'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { reset } from 'redux-form'
import { clearData, postDynamicEndpoint } from 'redux/actions/data'
import { getForms } from 'redux/actions/forms'
import { translateForm } from 'utilities/form-utils'
import { useQueryParam } from 'utilities/hooks'
import { useHistory } from 'react-router-dom'
import toast from 'utilities/toast'

const formId = 'rdwRecordCar'

/**
 * Form that records a car (from within the location's stock)
 * in RDW as ready to be transferred to another location.
 * Allowing for transfer of ownership.
 */
const RdwRecordCarForm = ({ carFileId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const form = useSelector((state) => state?.forms?.rdwRecordCar?.data?.form)
  const licensePlate = useQueryParam('licenseplate') || ''
  const code = useQueryParam('code') || ''
  const mileage = useQueryParam('mileage') || ''
  const mileageUnit = useQueryParam('mileageunit') || ''
  const companyNumber = useQueryParam('companynumber') || ''
  const initialized = React.useRef(false)

  const initialFormData = {
    kenteken: licensePlate,
    meldcode: code,
    teller: mileage,
    teller_eenheid: mileageUnit,
    bedrijfsnummerkoper: companyNumber,
  }

  // Clean up the URL
  const history = useHistory()
  React.useEffect(() => {
    if (
      !initialized.current &&
      Object.values(initialFormData).some(
        (item) => item !== '' && item !== undefined,
      )
    ) {
      const { location } = history
      let search = new URLSearchParams(location.search)
      const queryParamNames = [
        'licenseplate',
        'code',
        'mileage',
        'mileageunit',
        'companynumber',
      ]
      queryParamNames.forEach((key) => {
        search.delete(key)
      })
      search = search.toString()
      if (search.length) {
        search = `?${search}`
      }
      history.replace(`${location.pathname}${search}${location.hash}`)

      initialized.current = true
    }
  }, [history, initialFormData])

  const [formValues, setFormValues] = React.useState(initialFormData)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  React.useEffect(() => {
    if (!form) {
      dispatch(getForms(formId, { auto_id: carFileId }))
    }
  }, [dispatch, form, carFileId])

  const handleFormChange = (values) => {
    setFormValues(values)
  }

  const onSubmit = async () => {
    setIsSubmitting(true)
    return dispatch(
      postDynamicEndpoint({
        endpoint: form.endpoint,
        ...formValues,
      }),
    )
      .then((response) => {
        if (response?.success) {
          toast.success(response.data.message, { autoClose: 10000 })
          dispatch(clearData('rdwSentCars'))
          dispatch(reset(formId))
          setFormValues({})
        }
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  if (form) {
    const translatedForm = translateForm(form.fieldsets, t)

    return (
      <ComplexForm
        level="cta"
        data={formValues}
        fieldsets={translatedForm}
        formId="rdwRecordCar"
        onChange={handleFormChange}
        onSubmit={onSubmit}
        submitText={t('rdwCarTransfer.recordCarTab.submitButtonText')}
        enableReinitialize
        isLoading={isSubmitting}
      />
    )
  } else {
    return <LoadingIndicator />
  }
}

RdwRecordCarForm.propTypes = {
  carFileId: PropTypes.string,
}

RdwRecordCarForm.defaultProps = {
  carFileId: null,
}

export default RdwRecordCarForm
