import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { data } from 'config'
import { media } from 'utilities/styled'
import * as routes from 'config/routes'
import {
  getObjectFromQueryString,
  handleChangeQuery,
  removeQueriesFromQueryString,
  scrollToTop,
} from 'utilities/utils'
import SearchResultsOverview from 'components/layouts/search-results-overview'
import Filters from 'components/molecules/filters'
import StockHeader from 'components/organisms/stock-header'
import StockItems from 'components/organisms/stock-items'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Overlay from 'components/molecules/overlay'
import useSelectedLocationId from 'hooks/use-selected-location-id'

const sortOptions = data.rdwStockSortOptions

const StyledStockHeader = styled(StockHeader)`
  margin-bottom: 20px;
`

const InnerContainer = styled.div`
  column-gap: 40px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  ${media.desktop`
    grid-template-columns: 200px 1fr;
  `};
`

const StyledFilters = styled(Filters)`
  display: none;

  ${media.desktop`
    display: inline-table;
  `};
`

const MobileFilters = styled(Filters)`
  width: 100%;
`

function RdwStockView({
  getLocations,
  getRdwStockResults,
  history,
  location,
  locations,
  rdwItemUpdate,
  rdwStockResults,
  updateRdwItem,
}) {
  const { t } = useTranslation()
  const prevRdwItemUpdate = useRef(rdwItemUpdate)
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const queryFilters = getObjectFromQueryString(location.search)
  const sortColumn = queryFilters && queryFilters.sort
  const sortDirection = queryFilters && queryFilters.dir
  const page = queryFilters && (queryFilters.page || 1)
  const itemsPerPage = 36
  const selectedSortOption = `sort=${sortColumn}&dir=${sortDirection}`
  const rdwStockResultsData =
    rdwStockResults && rdwStockResults.data && rdwStockResults.data.data
  const rdwStockResultsTotal =
    rdwStockResults && rdwStockResults.data && rdwStockResults.data.total

  const linkGenerator = (item) => {
    const carId = item.id || item.auto_id

    return carId
      ? `${routes.CAR_FILE}/${carId}`
      : `${routes.CAR_ENTRY}/1${routes.LICENSE_PLATE_SEARCH_BY_LICENSE}?kenteken=${item.kenteken}&mileage=${item.km || item.rdwkm}`
  }

  useEffect(() => {
    const filters = getObjectFromQueryString(location.search)

    getRdwStockResults({
      ...filters,
      lines: itemsPerPage,
    })
  }, [getRdwStockResults, location.search])

  useEffect(() => {
    getLocations()
  }, [getLocations])

  const { vestiging_id: vestigingId } = getObjectFromQueryString(
    location.search,
  )
  const normalizedVestigingId = vestigingId || '0'
  // eslint-disable-next-line no-unused-vars
  const [_, setSelectedLocationId] = useSelectedLocationId(
    normalizedVestigingId,
  )
  useEffect(() => {
    setSelectedLocationId(normalizedVestigingId)
  }, [normalizedVestigingId, setSelectedLocationId])

  useEffect(() => {
    const filters = getObjectFromQueryString(location.search)

    if (
      (!prevRdwItemUpdate.current || !prevRdwItemUpdate.current.loading) &&
      rdwItemUpdate &&
      rdwItemUpdate.loading
    ) {
      getRdwStockResults(filters)
    }

    prevRdwItemUpdate.current = rdwItemUpdate
  }, [getRdwStockResults, location.search, rdwItemUpdate])

  function handleSortOptionChange(newSortOption) {
    const newQueryString = removeQueriesFromQueryString(location.search, [
      'sort',
      'dir',
    ]).replace(/&$/, '')

    history.push({
      pathname: routes.RDW_STOCK,
      search: `${newQueryString}&${newSortOption}`,
    })
  }

  function handleToggleMobileFilters() {
    setMobileFiltersOpen(!mobileFiltersOpen)
  }

  const filters = [
    {
      id: 'filter',
      label: t('search'),
      type: 'search',
    },
    {
      type: 'radio',
      label: t('locations'),
      id: 'vestiging_id',
      items:
        locations && locations.data
          ? locations.data.map((lctn) => ({
              value: lctn.id || '0',
              label: lctn.label,
            }))
          : [],
    },
    {
      type: 'radio',
      label: t('rdwStock'),
      id: 'filter_enriched',
      items: [
        {
          label: t('rdwTotal'),
          value: '0',
        },
        {
          label: t('toEnrich'),
          value: '1',
        },
        {
          label: t('enriched'),
          value: '2',
        },
        {
          label: t('doNotEnrich'),
          value: '5',
        },
        {
          label: t('inStockButNotInRdw'),
          value: '3',
        },
        {
          label: t('inStockAndOrInRdw'),
          value: '4',
        },
      ],
    },
    {
      type: 'radio',
      label: t('owner'),
      id: 'filter_owner',
      items: [
        {
          label: t('all'),
          value: '0',
        },
        {
          label: t('company'),
          value: '1',
        },
        {
          label: t('legalPerson'),
          value: '2',
        },
        {
          label: t('blancoLicensePlates'),
          value: '3',
        },
      ],
    },
  ]

  function handleResetFilters() {
    history.push(routes.RDW_STOCK)
    setMobileFiltersOpen(false)
  }

  function handleFilterUpdate(filter, value) {
    handleChangeQuery(location, history, filter, value, filter !== 'page')
  }

  function handleChangePage(newPage) {
    handleFilterUpdate('page', newPage)
    scrollToTop()
  }

  return (
    <>
      <SearchResultsOverview
        pageTitle={`${t('rdwStock')}`}
        mainTitle={t('overview')}
        subTitle={t('stock')}
        statusTitle="rdw"
        showSearchbox
      >
        <InnerContainer>
          <div />
          {!rdwStockResultsData ? (
            <LoadingIndicator />
          ) : (
            <>
              <StyledStockHeader
                onSortOptionChange={handleSortOptionChange}
                selectedSortOption={selectedSortOption}
                sortOptions={sortOptions.map((option) => ({
                  ...option,
                  label: t(option.label),
                }))}
                toggleMobileFilters={handleToggleMobileFilters}
                total={Number(rdwStockResultsTotal)}
              />
              <StyledFilters
                filters={filters}
                selectedFilters={queryFilters}
                onUpdate={handleFilterUpdate}
                onResetFilters={handleResetFilters}
              />
              <StockItems
                linkGenerator={linkGenerator}
                items={rdwStockResultsData}
                itemsPerPage={itemsPerPage}
                onChangePage={handleChangePage}
                page={Number(page)}
                totalItems={Number(rdwStockResultsTotal)}
                onUpdate={updateRdwItem}
              />
            </>
          )}
        </InnerContainer>
      </SearchResultsOverview>
      {mobileFiltersOpen && (
        <Overlay close={handleToggleMobileFilters} title={t('filters')}>
          <MobileFilters
            filters={filters}
            selectedFilters={queryFilters}
            onUpdate={handleFilterUpdate}
            onResetFilters={handleResetFilters}
          />
        </Overlay>
      )}
    </>
  )
}

RdwStockView.propTypes = {
  getLocations: PropTypes.func.isRequired,
  getRdwStockResults: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  locations: PropTypes.object,
  rdwItemUpdate: PropTypes.object,
  rdwStockResults: PropTypes.object,
  updateRdwItem: PropTypes.func.isRequired,
}

RdwStockView.defaultProps = {
  locations: null,
  rdwItemUpdate: null,
  rdwStockResults: null,
}

export default withRouter(RdwStockView)
