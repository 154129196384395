import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useDeepCompareEffect from 'use-deep-compare-effect'
import styled from 'styled-components/macro'

import { getFlatFields, getFlatValues, getFormData } from 'utilities/form-utils'
import { media } from 'utilities/styled'

import { apiUrl } from 'config/data'
import { CarFileStatusEnumLowerCase, CarFileStatusEnumUpperCase } from 'config/enums'

import LoadingIndicator from 'components/atoms/loading-indicator'
import ComplexForm from 'components/redux-containers/complex-form-container'
import DropdownMenu from 'components/atoms/dropdown-menu'
import Text from 'components/atoms/text'
import TextLink from 'components/atoms/text-link'
import Button from 'components/atoms/button'

import useDynamicEndpointDone from 'hooks/use-dynamic-endpoint-done'

/*
 * @param {object} values - An object containing keys of which one or more
 * contains the string 'breedte_{index}' and 'weergeven_{index}'.
 */
function getTotalWidth (values) {
  // All columns containing width values have 'breedte' in their
  // name which we use to accumulate the total if the corresponding
  // 'weergeven' value is truthy.
  return Object.entries(values).reduce((result, [key, value ]) => {
    const index = key.match(/[0-9].*/)
    const correspondingSelected = index && values[`weergeven_${index[0]}`]

    if (key.indexOf('breedte') !== -1 && correspondingSelected) {
      return result + Number(value)
    }

    return result
  }, 0)
}

const Header = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: ${({ theme }) => theme.sizings.lvl4};

  > * {
    margin-bottom: ${({ theme }) => theme.sizings.lvl1};
    margin-top: ${({ theme }) => theme.sizings.lvl1};
  }

  ${media.tablet`
    align-items: center;
    flex-direction: row;

    > * {
      margin-bottom: 0;
      margin-top: 0;
    }

    > *:first-child {
      margin-right: ${({ theme }) => theme.sizings.lvl3};
    }
  `};
`

const Buttons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  ${media.tablet`
    align-items: center;
    flex-direction: row;
    margin-left: auto;

    > *:first-child {
      margin-right: ${({ theme }) => theme.sizings.lvl3};
    }
  `};
`

function PdfSettingsForm ({
  className,
  dynamicEndpoint,
  forms,
  getForms,
  getPdfPreview,
  pdfPreview,
  postDynamicEndpoint,
  auth,
}) {
  const { t } = useTranslation()
  const [
    statusOngoing,
    statusExpected,
    statusInStock,
    statusSold,
    statusDelivered,
  ] = [
    {
      label: t('carFileStatusses.LOPEND'),
      value: CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.lopend],
    }, {
      label: t('carFileStatusses.VERWACHT'),
      value: CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verwacht],
    }, {
      label: t('carFileStatusses.BINNEN'),
      value: CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.binnen],
    }, {
      label: t('carFileStatusses.VERKOCHT'),
      value: CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verkocht],
    }, {
      label: t('carFileStatusses.AFGELEVERD'),
      value: CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.afgeleverd],
    },
  ]
  let statusOptions = [
    statusOngoing,
    statusExpected,
    statusInStock,
    statusSold,
    statusDelivered,
  ]
  // TODO: This logic should be moved to the back-end:
  switch (auth.userDetails.pakket) {
  case 'light':
    statusOptions = [
      statusInStock,
    ]
    break
  case 'basic':
    statusOptions = [
      statusInStock,
      statusSold,
    ]
    break
    // default and pro have all the options:
  case 'pro':
  default:
    break
  }
  const defaultFormId = 'pdf'
  const factorySettingsFormId = 'pdfFactorySettings'
  const renderedOnce = useRef(false)
  const [formId, setFormId ] = useState(defaultFormId)
  const [totalWidth, setTotalWidth ] = useState()
  const [selectedStatusOption, setSelectedStatusOption ] = useState(statusOptions[0].value)
  const [formIsDirty, setFormIsDirty ] = useState(false)

  const [formValues, setFormValues ] = useState()
  const form = forms[formId]
  const maxWidth = 850
  const data = form && form.data
  const fieldsets = data && data.fieldsets
  const fields = fieldsets && getFlatFields(fieldsets)
  const defaultValues = fieldsets && getFlatValues(fieldsets, data)
  const defaultFormValues = (defaultValues && getFormData(defaultValues, fields).values) || {}
  const prevDefaultFormValues = useRef(defaultFormValues)
  const loading = form && form.loading
  const endpoint = form && form.data && form.data.endpoint

  const getForm = useCallback((id) => {
    const params = {
      lijst: selectedStatusOption,
    }
    setFormId(id)
    getForms(id, params)
  }, [getForms, selectedStatusOption ])

  useEffect(() => {
    getForm(defaultFormId)
  }, [getForm, selectedStatusOption ])

  useDeepCompareEffect(() => {
    if (prevDefaultFormValues.current !== defaultFormValues && !formIsDirty) {
      setFormValues(defaultFormValues)
      prevDefaultFormValues.current = defaultFormValues
    }
  }, [defaultFormValues ])

  useEffect(() => {
    if (formValues) {
      const fieldName = Object.keys(formValues)[1]
      const width = fieldName && getTotalWidth(formValues[fieldName])
      setTotalWidth(width)
    }
  }, [data, defaultFormValues, fields, formValues ])

  // Make sure the shown form is switched back to the saved one after saving
  // it in case it was showing the factory settings form.
  useDynamicEndpointDone(dynamicEndpoint, () => {
    getForm(defaultFormId)
  }, [dynamicEndpoint, getForm ])

  useDeepCompareEffect(() => {
    if (!pdfPreview.loading && pdfPreview.data && pdfPreview.data.url && renderedOnce.current) {
      window.open(
        `${apiUrl}${pdfPreview.data.url}`,
        '_blank',
      )
    }
    renderedOnce.current = true
  }, [pdfPreview, renderedOnce ])

  function onStatusOptionChange (option) {
    setFormIsDirty(false)
    setSelectedStatusOption(option)
  }

  function handleChange (values) {
    setFormIsDirty(true)
    setFormValues(values)
  }

  function handleClickReset () {
    setFormIsDirty(false)
    getForm(factorySettingsFormId)
  }

  function handleClickPreview () {
    getPdfPreview(formValues)
  }

  if (!data || loading) {
    return <LoadingIndicator />
  }

  function handleSubmit (formState) {
    postDynamicEndpoint({
      endpoint,
      ...formState,
    })
  }

  return (
    <div className={className}>
      <Header>
        <Text
          text={`${t('availableWidth')} ${maxWidth - totalWidth} px`}
          type="floatingLabelInput"
        />
        <DropdownMenu
          items={statusOptions}
          onChange={onStatusOptionChange}
          selected={selectedStatusOption}
        />
        <Buttons>
          <TextLink
            onClick={handleClickReset}
            text={t('reset')}
          />
          <Button
            onClick={handleClickPreview}
            text={t('pdfPreview')}
          />
        </Buttons>
      </Header>
      <ComplexForm
        fieldsets={data.fieldsets}
        enableReinitialize
        formId={formId}
        level="cta"
        onSubmit={handleSubmit}
        submitText={t('saveChanges')}
        onChange={handleChange}
      />
    </div>
  )
}

PdfSettingsForm.propTypes = {
  className: PropTypes.object,
  dynamicEndpoint: PropTypes.object,
  forms: PropTypes.object,
  getForms: PropTypes.func.isRequired,
  getPdfPreview: PropTypes.func.isRequired,
  pdfPreview: PropTypes.object,
  postDynamicEndpoint: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

PdfSettingsForm.defaultProps = {
  className: null,
  dynamicEndpoint: null,
  forms: null,
  pdfPreview: {},
}

export default PdfSettingsForm
