import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  ADMINISTRATION_TRANSACTIONS,
  ADMINISTRATION_DIRECT_DEBIT_FILES,
  ADMINISTRATION_CSP_TRANSACTIONS,
} from 'config/routes'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import Tabs from 'components/layouts/tabs'
import PageHeader from 'components/organisms/page-header'
import TransactionsOverviewContainer from 'components/redux-containers/transactions-overview-container'
import DirectDebitFiles from 'components/views/direct-debit-files'
import CSPTransactions from 'components/views/csp-transactions'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.alternativeBackground};
  padding: 0 20px;

  ${media.desktop`
    padding: 0 60px;
  `};
`

const Administration = () => {
  // The auth variable contains the logged in user's authentication details.
  // These can be used to check if the logged in user has a CSP license.
  // To access this variable, a redux selector is used.
  const auth = useSelector((state) => state.auth)
  const userHasUCCLicense =
    auth && auth.userDetails && auth.userDetails.pakket !== false
  const userHasCSPLicense = Boolean(
    auth && auth.userDetails && auth.userDetails.csp,
  )
  const { t } = useTranslation()
  const tabItems = []

  const transactionsTab = {
    id: 'TransactionsOverview',
    label: `${t('transactions')}`,
    to: ADMINISTRATION_TRANSACTIONS,
    component: useCallback(
      (props) => <TransactionsOverviewContainer {...props} />,
      [],
    ),
  }

  const directDebitFilesTab = {
    id: 'DirectDebitFiles',
    label: `${t('directDebitFiles.title')}`,
    to: ADMINISTRATION_DIRECT_DEBIT_FILES,
    component: useCallback((props) => <DirectDebitFiles {...props} />, []),
  }

  const CSPTransactionsTab = {
    id: 'CSPTransactions',
    label: `${t('carServicePlanTransactions.title')}`,
    to: ADMINISTRATION_CSP_TRANSACTIONS,
    component: useCallback((props) => <CSPTransactions {...props} />, []),
  }

  if (userHasUCCLicense) {
    tabItems.push(transactionsTab)
  }

  if (userHasCSPLicense) {
    tabItems.push(directDebitFilesTab)
    tabItems.push(CSPTransactionsTab)
  }

  return (
    <AppLayoutContainer pageTitle={t('administration')}>
      <Container>
        <PageHeader mainTitle={t('administration')} hasBorderBottom={false} />
        <Tabs items={tabItems}>
          {tabItems.map((item) =>
            item.subItems ? (
              <Tabs items={item.subItems} key={item.id} small>
                {item.subItems.map((subItem) => (
                  <subItem.component key={subItem.id} />
                ))}
              </Tabs>
            ) : (
              <item.component key={item.id} />
            ),
          )}
        </Tabs>
      </Container>
    </AppLayoutContainer>
  )
}

export default Administration
