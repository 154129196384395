import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import ContentSeparator from 'components/atoms/content-separator'
import Icon from 'components/atoms/icon'
import Link from 'components/atoms/link'
import NewTooltip from 'components/atoms/new-tooltip'
import Typography from 'components/molecules/typography'

const Container = styled(Link)`
  text-decoration: none;
`

const IconWrapper = styled.span`
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.sizings.lvl1};
`

const Wrapper = styled.div`
  text-decoration: none;
  display: flex;
  gap: ${({ theme }) => theme.sizings.lvl1};
  align-items: center;
  flex-direction: row;

  ${({ $layoutType }) =>
    $layoutType === CarFileCardBidsLayoutType.VERTICAL &&
    `
    align-items: flex-start;
    flex-direction: column;
  `}
`

const TypographyBids = styled(Typography)`
  line-height: 1.325;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.actionsStandard};
`

const TypographyDeadline = styled(Typography)`
  line-height: 1.325;
`

const StyledVerticalContentSeparator = styled(ContentSeparator)`
  height: ${({ theme }) => theme.sizings.lvl2};
  margin: 0;
  display: inline-block;
`
export const CarFileCardBidsSize = {
  SMALL: 'sm',
  MEDIUM: 'md',
}

export const CarFileCardBidsLayoutType = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
}

const CarFileCardBids = ({
  className,
  link,
  carShareBids,
  size,
  layoutType,
  breakDeadline,
}) => {
  const { t } = useTranslation()

  // When carShareBids isn't set, we shoulnd't show anything for this component
  if (!carShareBids) {
    return null
  }

  const deadline = carShareBids.deadline_formatted
  const deadlineFormatted = deadline ? deadline.split(' ') : null

  const bidsText = t('bidsTotal', {
    bidsReceived: carShareBids.received,
    bidsRequested: carShareBids.requested,
    bids:
      carShareBids.requested < 2
        ? t('bid').toLowerCase()
        : t('bids').toLowerCase(),
  })

  const biddingHasEnded = carShareBids.is_active === false // Needs strict check
  const bidsDeadlineText = biddingHasEnded
    ? t('biddingHasEnded')
    : `${t('biddingDeadline')}: ${carShareBids.deadline_formatted}` // Deadline formatted from the BE looks like this: 12-07-2024 10:00h GMT+02:00

  // When the layoutType isn't set, show nothing. The layoutType should be set, because this component
  // is used in both the "normal" cards and the cards for list
  if (!layoutType) {
    return null
  }

  return (
    <Container className={className} to={link}>
      <Wrapper $size={size} $layoutType={layoutType}>
        {size === CarFileCardBidsSize.MEDIUM ? (
          <>
            <IconWrapper>
              <Icon
                type="bids"
                color="actionsStandard"
                width="22"
                height="22"
              />
              <TypographyBids type="InlineBodyText">{bidsText}</TypographyBids>
            </IconWrapper>
            {layoutType === CarFileCardBidsLayoutType.HORIZONTAL && (
              <StyledVerticalContentSeparator variant="vertical" />
            )}
            <TypographyDeadline type="InlineBodyText">
              {biddingHasEnded ? (
                t('biddingHasEnded')
              ) : (
                <>
                  {t('biddingDeadline')}:
                  {breakDeadline
                    ? deadlineFormatted.map((item, index) => (
                        <Fragment key={`item-${index}`}>
                          <br />
                          {item}
                        </Fragment>
                      ))
                    : ` ${deadline}`}
                </>
              )}
            </TypographyDeadline>
          </>
        ) : (
          <NewTooltip title={bidsDeadlineText}>
            <TypographyBids type="InlineBodyText">{bidsText}</TypographyBids>
          </NewTooltip>
        )}
      </Wrapper>
    </Container>
  )
}

export const propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  carShareBids: PropTypes.shape({
    deadline: PropTypes.string, // Initially is null, when it's set it's a string
    deadline_formatted: PropTypes.string, // Initially is null, when it's set it's a string
    requested: PropTypes.number.isRequired,
    received: PropTypes.number.isRequired,
    has_accepted_bid: PropTypes.bool.isRequired,
    is_active: PropTypes.bool.isRequired,
  }),
  size: PropTypes.oneOf(Object.values(CarFileCardBidsSize)),
  layoutType: PropTypes.oneOf([
    PropTypes.bool,
    ...Object.values(CarFileCardBidsLayoutType),
  ]), // Initially is false, then once breakpoints are set, is one of the layout types
  breakDeadline: PropTypes.bool,
}

CarFileCardBids.propTypes = propTypes

export const defaultProps = {
  className: null,
  link: null,
  carShareBids: null,
  size: CarFileCardBidsSize.MEDIUM,
  layoutType: CarFileCardBidsLayoutType.HORIZONTAL,
  breakDeadline: true,
}

CarFileCardBids.defaultProps = defaultProps

export default CarFileCardBids
