import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Button from 'components/atoms/button'

const StyledButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  width: auto;
  height: auto;
  display: inline-flex;

  &[disabled] {
    opacity: 0.3;
    * {
      fill: ${({ theme }) => theme.colors.actionsStandard};
    }

    &:hover,
    &:focus {
      & * {
        fill: ${({ theme }) => theme.colors.actionsStandard};
      }
    }
  }
`

export const ActionIconButton = ({ icon, width, height, ...restProps }) => (
  <StyledButton
    level="option"
    icon={icon}
    iconWidth={width || '22'}
    iconHeight={height || '22'}
    iconColor="actionsStandard"
    {...restProps}
  />
)

ActionIconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
}

ActionIconButton.defaultProps = {
  width: '22',
  height: '22',
}

export default ActionIconButton
