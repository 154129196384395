import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import InlineBodyText from './inline-body-text'

const Container = styled.label`
  align-items: center;
  display: flex;
`

const StyledCheckbox = styled.input`
  margin: 0 6px;
`

const StyledText = styled(InlineBodyText)`
  text-transform: capitalize;
`

const StyledTextCaps = styled(InlineBodyText)`
  text-transform: uppercase;
`

function Toggle({ checked, name, onChange, title, className }) {
  const { t } = useTranslation()

  return (
    <Container className={className}>
      <StyledText type="menu" color="brandCharlie">
        {title}
      </StyledText>
      <StyledCheckbox
        checked={checked}
        id={name}
        name={name}
        onChange={onChange}
        type="checkbox"
        value={checked}
      />
      <StyledTextCaps type="menu" color="brandCharlie">
        {checked ? t('on') : t('off')}
      </StyledTextCaps>
    </Container>
  )
}

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
}

Toggle.defaultProps = {
  title: null,
  className: null,
}

export default Toggle
