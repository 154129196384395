import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import GalleryImage from 'components/atoms/gallery-image'
import Carousel from 'components/molecules/carousel'

const StyledGalleryImage = styled(GalleryImage)`
  height: ${({ fullHeight }) => (fullHeight ? '100%' : '')};
`

function ImageCarousel({ fullHeight, images, label, ratio, ...restProps }) {
  return (
    <Carousel fullHeight={fullHeight} {...restProps}>
      {images.map((image, index) => (
        <StyledGalleryImage
          label={label}
          key={index.toString()}
          alt={`car detail image ${index}`}
          src={image.src}
          fullHeight={fullHeight}
          ratio={ratio}
        />
      ))}
    </Carousel>
  )
}

ImageCarousel.propTypes = {
  fullHeight: PropTypes.bool,
  images: PropTypes.array.isRequired,
  label: PropTypes.string,
  ratio: PropTypes.number,
}

ImageCarousel.defaultProps = {
  fullHeight: false,
  label: null,
  ratio: undefined,
}

export default ImageCarousel
