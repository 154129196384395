/**
 * Importing formatters from format.js that were previously
 * present and exported from this file for backwards compatibility.
 * Please do not add any further formatters in here,
 * but instead in format.js.
 */
import {
  formatLicenseplate,
  formatMileage,
  formatPhoneNumber,
  formatPrice,
} from './format'

import { AppThemes, AppThemesInternationalization } from 'config/enums'
import theme from 'config/theme'

/**
 * @param {object} rows - An array of objects containing the columns
 * for a row. For example:
 * [{
 *    column1: {
 *      data: 'row1column1data',
 *    }
 *    column2: {
 *      data: 'row1column2data',
 *    }
 * }, {
 *    column1: {
 *      data: 'row2column1data',
 *    }
 *    column2: {
 *      data: 'row2column2data',
 *    }
 * }]
 * @param {object} rows - An array of objects containing data for
 * the columns. For example:
 * [{
 *    id: 'column1',
 *    label: 'Kolom 1',
 * }, {
 *    id: 'column2',
 *    label: 'Kolom 2',
 * }]
 *
 * @return {object} - For example:
 * [{
 *    'Kolom 1': 'row1column1data',
 *    'Kolom 2': 'row1column2data',
 * }, {
 *    'Kolom 1': 'row2column1data',
 *    'Kolom 2': 'row2column2data',
 * }]
 */
function convertTableRowsToObject({ rows, columns }) {
  const columnLabels = columns.reduce(
    (result, column) => ({
      ...result,
      [column.id]: column.label,
    }),
    {},
  )

  const converted = rows.map((row) =>
    Object.entries(row).reduce((result, [key, value]) => {
      const label = columnLabels[key]
      // Ignore the item if there is no column is defined
      // for it.
      if (!label) {
        return result
      }

      return {
        ...result,
        [label]: value.data,
      }
    }, {}),
  )

  return converted
}
function getObjectFromQueryString(queryString) {
  const params = new URLSearchParams(queryString)
  const entries = params.entries()
  const result = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const entry of entries) {
    const [key, value] = entry
    let processedValue = value.match(/,/) ? value.split(',') : value
    // Sometimes a query parameter ends up in the URL more than once
    // for a reason yet to be discovered. Here it is ensured no extra
    // ? and content afterwards is part of the processed value:
    if (Array.isArray(processedValue)) {
      processedValue = processedValue.map((item) => item.replace(/\?.*/, ''))
    } else {
      processedValue = processedValue.replace(/\?.*/, '')
    }
    result[key] = processedValue
  }
  return result
}
function getQueryStringFromObject(object) {
  const params = []
  Object.entries(object).forEach(([key, value]) => {
    if (value) {
      const processedValue = Array.isArray(value) ? value.join(',') : value
      params.push(`${encodeURIComponent(key)}=${processedValue}`)
    }
  })
  return params.join('&')
}

function removeQueriesFromQueryString(queryString, queries) {
  const newQueryString = new URLSearchParams(queryString)
  queries.forEach((query) => {
    newQueryString.delete(query)

    if (query !== 'page') {
      newQueryString.set(query, '')
    }
  })
  return newQueryString.toString()
}

function addQueryToQueryString(queryString, query, value) {
  const newQueryString = new URLSearchParams(queryString)

  if (!newQueryString.has(query)) {
    newQueryString.append(query, value)
  } else {
    newQueryString.set(query, value)
  }

  return newQueryString.toString().replace(/%2C/g, ',')
}

function objectToQueryString(params) {
  const newQueryString = new URLSearchParams('')
  Object.keys(params).forEach((param) => {
    newQueryString.append(param, params[param])
  })

  return newQueryString.toString().replace(/%2C/g, ',')
}

function handleChangeQuery(location, history, query, newValue, resetPage) {
  const queriesToRemove = [query]

  if (resetPage) {
    queriesToRemove.push('page')
  }

  const cleanedQueryString = removeQueriesFromQueryString(
    location.search,
    queriesToRemove,
  )

  const newValueHasContent =
    newValue && (Array.isArray(newValue) ? newValue.length : newValue !== null)

  const newQueryString = newValueHasContent
    ? addQueryToQueryString(cleanedQueryString, query, newValue)
    : cleanedQueryString

  history.push({
    pathname: location.pathname,
    search: newQueryString,
  })
}

/**
 * Change mulitple queries in the url at once.
 *
 * @param {object} location - The location object, usually got from react-router.
 * @param {object} history - The history object, usually got from react-router.
 * @param {string[]} queries - An array of queries to update.
 * @param {string[]} newValues - The values for the queries to be updated to.
 * @param {boolean} [resetPage] - Reset the page query if truthy.
 */
function handleChangeQueries(location, history, queries, newValues, resetPage) {
  const queriesToRemove = queries

  if (resetPage) {
    queriesToRemove.push('page')
  }

  const cleanedQueryString = removeQueriesFromQueryString(
    location.search,
    queriesToRemove,
  )

  const newValuesHaveContent = newValues.reduce(
    (result, newValue) => [
      ...result,
      newValue && Array.isArray(newValue) ? newValue.length : newValue !== null,
    ],
    [],
  )

  const newQueryString = newValuesHaveContent.reduce(
    (result, newValuesHasContent, index) =>
      newValuesHasContent
        ? addQueryToQueryString(result, queries[index], newValues[index])
        : result,
    cleanedQueryString,
  )

  history.push({
    pathname: location.pathname,
    search: newQueryString,
  })
}

function translateListItems(items, translate) {
  return items.map((item) => ({
    ...item,
    label: translate(item.label),
    items: item.items ? translateListItems(item.items, translate) : null,
  }))
}

function getFileExtension(fileName) {
  const match = fileName.match(/[0-9a-z]+$/i)

  return match && match[0]
}

function getFileName(uri) {
  return uri.split('\\').pop().split('/').pop().split('?')[0]
}

function getMonthNameByIndex(index) {
  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ]

  return months[index] || ''
}

function createFormData(data) {
  const formData = new FormData()

  Object.entries(data).forEach(([item, key]) => {
    formData.append(item, key)
  })

  return formData
}

function clip(value, min, max) {
  return Math.min(Math.max(value, min), max)
}

function objectToArray(array) {
  const entries = Object.entries(array)

  return entries.map(([key, value]) => ({
    key,
    value,
  }))
}

function convertToCamelCase(underscoreCase) {
  return underscoreCase.replace(/(_\w)/g, (match) => match[1].toUpperCase())
}

function getBackendNapValue(nap) {
  if (nap === true) {
    return 'ja'
  }
  if (nap === false) {
    return 'nee'
  }
  return 'onbekend'
}

function getNapPillLevel(nap) {
  if (nap === true || nap === 'ja') {
    return 'positive'
  }
  if (nap === false || nap === 'nee') {
    return 'negative'
  }
  return 'neutral'
}

function getNapPillText(nap) {
  if (nap === true || nap === 'ja') {
    return 'Logisch'
  }
  if (nap === false || nap === 'nee') {
    return 'Onlogisch'
  }
  return 'Onbekend'
}

function mapToLabelAndValue(inputData, labelFieldKey, valueFieldKey) {
  return inputData.map((item) => ({
    label: item[labelFieldKey],
    value: item[valueFieldKey],
  }))
}

function getAllYearsFrom(startYear) {
  const currentYear = new Date().getFullYear()
  const yearsCount = currentYear - startYear + 1

  return Array.from(
    { length: yearsCount },
    (value, index) => currentYear - index,
  )
}

function getTopLevelPath(pathname) {
  return pathname.split('/')[1]
}

function animateScrollTo(element, options) {
  const vertical = options && options.vertical
  const horizontal = options && options.horizontal
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: vertical || 'start',
      inline: horizontal || 'nearest',
    })
  }
}

function scrollTo(node, options) {
  animateScrollTo(node, options)
}

function scrollToTop() {
  document.getElementById('doc').scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

function roundWithDecimals(value, decimals) {
  return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`)
}

function formatInputValue(value, type) {
  if (type === 'number') {
    return value ? roundWithDecimals(value, 2) : value
  }
  if (type === 'date') {
    return value || null
  }
  return value
}

function cutoffText(text, limit) {
  if (text.length > limit) {
    return `${text.slice(0, limit)}...`
  }

  return text
}

function capitalizeFirstLetter(text) {
  return `${text.slice(0, 1).toUpperCase()}${text.slice(1)}`
}

function base64ImageToBlob(base64Image) {
  const base64ImageContent = base64Image.replace(
    /^data:image\/(png|jpg|jpeg);base64,/,
    '',
  )
  const sliceSize = 1024
  const byteChars = window.atob(base64ImageContent)
  const byteArraysLength = Math.ceil(byteChars.length / sliceSize)

  const byteArrays = Array.from(new Array(byteArraysLength)).map((_, index) => {
    const offset = index * sliceSize
    const slice = byteChars.slice(offset, offset + sliceSize)

    const byteNumbers = Array.from(new Array(slice.length)).map(
      (_slc, slcIndex) => slice.charCodeAt(slcIndex),
    )

    return new Uint8Array(byteNumbers)
  })

  return new Blob(byteArrays, { type: 'image/jpg' })
}

function getUid() {
  return Math.random().toString(36).substring(2) + Date.now().toString(36)
}

function getIsLandsape() {
  return window.innerWidth > window.innerHeight
}

function getYearOptions(start, end) {
  const years = Array.from(Array(end - start + 1), (_, x) => end - x)

  return years.map((year) => {
    const yearString = year.toString()
    return {
      label: yearString,
      value: yearString,
    }
  })
}

// HTML-encode a string. This string can be actual HTML too ;-)
const htmlEncode = (string) =>
  String(string)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')

// HTML-decode a string.
const htmlDecode = (string) =>
  String(string)
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")

/**
 * Converts a string to either a number or boolean based from regex tests
 * @param {String} value
 */
function convertStringToType(value) {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value)
  }
  if (/^(true|false|TRUE|FALSE)$/.test(value)) {
    return Boolean(JSON.parse(value))
  }
  return value
}

const debounce = (func, wait) => {
  let timeout
  return (...args) => {
    const context = this
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      timeout = null
      func.apply(context, args)
    }, wait)
  }
}

/**
 * This function takes a dot path string and traverses object's tree to find it's value.
 *
 * Example:
 * ```
 * const myObject = {
 *  a: "Some value",
 *  b: {
 *    nestedProperty: "Nested property value",
 *  },
 *  c: {
 *    objectA: {
 *      nestedPropA: "Value A",
 *      nestedPropB: "Value B"
 *    }
 *  }
 * }
 *
 * getDotPathValue('a', myObject) // Returns "Some value"
 * getDotPathValue('b.nestedProperty', myObject) // Returns "Nested property value"
 * getDotPathValue('c.objectA', myObject) // Returns { nestedPropA: "Value A", nestedPropB: "Value B" }
 * getDotPathValue('c.objectA.nestedPropA', myObject) // Returns "Value A"
 * ```
 */

const getDotPathValue = (dotPath, object) => {
  try {
    return dotPath.split('.').reduce((o, i) => o[i], object)
  } catch (e) {
    console.error(e)
    return undefined
  }
}

// Gets the current theme name based on what's set in the '.env' file.
// Default theme is "UCC", but for some whitelabel solutions we'd need a different theme. E.g. "AUTOPRESENTATIE" or "2DRIVE".
const getAppTheme = () => {
  const envThemeName = process.env.REACT_APP_THEME
  const themeName = AppThemes?.[envThemeName]
  const themeNameCamelCase = AppThemesInternationalization?.[envThemeName]

  // Fall back to UCC when the process.env.REACT_APP_THEME is not set, or when the theme name is not defined in the AppThemes enum
  if (!envThemeName || !themeName || !themeNameCamelCase) {
    if (!envThemeName) {
      console.warn(
        "process.env.REACT_APP_THEME is not set. Add it to the '.env' file.",
      )
    }

    if (!themeName) {
      console.warn(
        "Theme name not found. Add it to the 'AppThemes' enum in 'config/enums'",
      )
    }

    if (!themeNameCamelCase) {
      console.warn(
        "Theme camelCase name not found. Add it to the 'AppThemesInternationalization' enum in 'config/enums'",
      )
    }

    return {
      themeName: AppThemes.UCC,
      themeNameCamelCase: AppThemesInternationalization.UCC,
      theme: theme[AppThemes.UCC],
    }
  }

  // Return the app theme
  return {
    themeName,
    themeNameCamelCase,
    theme: theme[themeName],
  }
}

const isObject = (obj) =>
  typeof obj === 'object' && !Array.isArray(obj) && obj !== null

export {
  base64ImageToBlob,
  capitalizeFirstLetter,
  clip,
  convertStringToType,
  convertTableRowsToObject,
  convertToCamelCase,
  createFormData,
  cutoffText,
  debounce,
  formatInputValue,
  formatLicenseplate,
  formatMileage,
  formatPhoneNumber,
  formatPrice,
  getAllYearsFrom,
  getAppTheme,
  getBackendNapValue,
  getDotPathValue,
  getFileExtension,
  getFileName,
  getIsLandsape,
  getMonthNameByIndex,
  getNapPillLevel,
  getNapPillText,
  getObjectFromQueryString,
  getQueryStringFromObject,
  getTopLevelPath,
  getUid,
  getYearOptions,
  handleChangeQueries,
  handleChangeQuery,
  htmlDecode,
  htmlEncode,
  mapToLabelAndValue,
  objectToArray,
  objectToQueryString,
  removeQueriesFromQueryString,
  roundWithDecimals,
  scrollTo,
  scrollToTop,
  translateListItems,
  isObject,
}
