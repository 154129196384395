import React from 'react'

import SettingsForm from 'components/redux-containers/settings-form-container'

import {
  defaultProps as settingsFormDefaultProps,
  propTypes as settingsFormPropTypes,
} from 'components/molecules/settings-form'

const LocationTopDownSettings = ({ params }) => (
  <SettingsForm formId="topdown" params={params} />
)

LocationTopDownSettings.propTypes = {
  params: settingsFormPropTypes.params,
}

LocationTopDownSettings.defaultProps = {
  params: settingsFormDefaultProps.params,
}

export default LocationTopDownSettings
