import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  BarChart as ReBarChart,
  Bar,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { ThemeContext } from 'styled-components'

const Label = ({ x, y, value, width }) => (
  <text dy={-8} x={x + width / 2} y={y} fontSize={12} textAnchor="middle">
    {value}%
  </text>
)

Label.propTypes = {
  value: PropTypes.number,
  width: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
}

Label.defaultProps = {
  value: null,
  width: null,
  x: null,
  y: null,
}

const toPercent = (value) => `${value}%`

function formatTooltipValue(value, t) {
  return [toPercent(value), t('value')]
}

function BarChart({ data, onBarClick }) {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)

  const colors = [
    theme.colors.statePositive,
    theme.colors.statePositive,
    theme.colors.actionsCta,
    theme.colors.actionsCta,
    theme.colors.stateNegative,
  ]

  function handleBarClick({ name }) {
    if (!onBarClick) {
      return
    }
    onBarClick(name)
  }

  return (
    <div>
      <ResponsiveContainer minHeight="400px">
        <ReBarChart
          data={data}
          margin={{
            top: 20,
            right: 0,
            left: -10,
            bottom: 0,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis axisLine={false} tickLine={false} dataKey="name" />
          <YAxis axisLine={false} tickFormatter={toPercent} tickLine={false} />
          <Tooltip formatter={(value) => formatTooltipValue(value, t)} />
          <Bar dataKey="value" label={<Label />} onClick={handleBarClick}>
            {data.map((entry, index) => (
              <Cell key={index.toString()} fill={colors[index]} />
            ))}
          </Bar>
        </ReBarChart>
      </ResponsiveContainer>
    </div>
  )
}

BarChart.propTypes = {
  data: PropTypes.array.isRequired,
  onBarClick: PropTypes.func,
}

BarChart.defaultProps = {
  onBarClick: null,
}

export default BarChart
