/*!
 * numbro.js language configuration
 * language : French
 * locale: France
 * author : Adam Draper : https://github.com/adamwdraper
 */

export default {
  languageTag: 'fr',
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'M',
    billion: 'Mrd',
    trillion: 'billion',
  },
  ordinal: (number) => {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '€',
    position: 'postfix',
    code: 'EUR',
  },
  currencyFormat: {
    thousandSeparated: true,
    totalLength: 4,
    spaceSeparated: true,
    average: true,
  },
}
