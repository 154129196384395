import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import LoadingButton from 'components/atoms/loading-button'
import { useTranslation } from 'react-i18next'
import EnhancedTable from 'components/organisms/enhanced-table'
import SearchInput from 'components/molecules/search-input'
import { Formik, Form, Field } from 'formik'
import {
  FormikLabeledCheckBox,
  FormikTextInput,
  FormikSelectInput,
} from 'components/molecules/formik-field'
import {
  getObjectFromQueryString,
  handleChangeQuery,
  scrollToTop,
} from 'utilities/utils'
import OptionsBar from 'components/layouts/options-bar'
import MinimalCarInfo from 'components/organisms/minimal-car-info'
import CarBrandAndModel from 'components/molecules/car-brand-and-model'
import TinyStatsList from 'components/molecules/tiny-stats-list'
import styled from 'styled-components'
import NewDropDownSelect from 'components/molecules/new-dropdown-select'
import Link from 'components/atoms/link'
import toast from 'utilities/toast'
import Paging from 'components/molecules/paging'
import Typography from 'components/molecules/typography'
import { MarktplaatsFormSchema } from 'config/validation-schemas'
import useSelectedLocationId from 'hooks/use-selected-location-id'
import MarktplaatsBulkActionsDialog from 'components/views/marktplaats-bulk-actions-dialog'

const FieldCombo = styled.div`
  display: flex;
  width: 150px;
  align-items: center;

  > * {
    flex: 0 1 auto;
  }
`

const FieldComboVertical = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl1};

  > * {
    width: 100%;
    flex: 0 1 auto;
  }
`

const StyledEnhancedTable = styled(EnhancedTable)`
  /**
    The 450px refers to all the content above the table.
    Needed to trigger the sticky header of EnhancedTable.
  */
  max-height: calc(100vh - 450px);

  tbody tr > .MuiTableCell-root {
    vertical-align: middle;
    > span {
      position: relative;
      top: -3px;
    }
  }
`

const StyledOptionButton = styled(LoadingButton)`
  padding: 0;
`

const StyledPaging = styled(Paging)`
  margin-top: ${({ theme }) => theme.sizings.lvl4};
`

const StatusMessage = styled(Typography)`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.sizings.lvl4};
`

function MarktplaatsForm({
  getMarktplaats,
  getLocations,
  getMarktplaatsBulkActionStatus,
  updateMarktplaatsFeatures,
  locations,
  marktplaatsData,
  marktplaatsBulkActionStatus,
}) {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation() // This location refers to the URL bar of the browser
  const queryParams = getObjectFromQueryString(location.search)
  const [selectedLocationId, setSelectedLocationId] = useSelectedLocationId() // This location refers to a dealer location (vestiging)
  const normalizedSelectedLocationId =
    selectedLocationId === '0' ? undefined : selectedLocationId
  const [formIsUpdated, setFormIsUpdated] = useState(false)
  const [filterValue, setFilterValue] = useState('')
  const [filteredMpData, setFilteredMpData] = useState(
    marktplaatsData && marktplaatsData.data ? marktplaatsData.data.rows : [],
  )
  const [orderBy, setOrderBy] = useState('expires_in')
  const [orderDirection, setOrderDirection] = useState('asc')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const itemsPerPage = 10
  const page = Number(queryParams.page) || 1
  const [bulkActionsDialogOpen, setBulkActionsDialogOpen] = useState(false)

  function handleChangePage(newPage) {
    handleChangeQuery(location, history, 'page', newPage, false)

    scrollToTop()
  }

  function sortAndSetFilterData(data) {
    const type = typeof data[0][orderBy]
    let sortedMpData = []

    if (type === 'number') {
      sortedMpData = data.slice().sort((a, b) => a[orderBy] - b[orderBy])
    } else {
      sortedMpData = data
        .slice()
        .sort((a, b) => a[orderBy].localeCompare(b[orderBy]))
    }

    if (orderDirection === 'asc') {
      sortedMpData.reverse()
    }
    setFilteredMpData(sortedMpData)
  }

  useEffect(() => {
    if (!locations?.data && !locations?.loading && !locations?.error) {
      getLocations()
    }
  }, [getLocations, locations])

  useEffect(() => {
    if (
      !marktplaatsBulkActionStatus?.data &&
      !marktplaatsBulkActionStatus?.loading &&
      !marktplaatsBulkActionStatus?.error
    ) {
      getMarktplaatsBulkActionStatus()
    }
  }, [getMarktplaatsBulkActionStatus, marktplaatsBulkActionStatus])

  // If this component gets new `locations`, and the `selectedLocationId` is not part of that, reset
  // the selection to "All locations"
  useEffect(() => {
    if (locations?.data?.options) {
      const ids = locations.data.options.map((location) => location.id)

      if (!ids.includes(selectedLocationId)) {
        setSelectedLocationId('0')
      }
    }
  }, [locations, selectedLocationId, setSelectedLocationId])

  const persistMarktplaatsFeatures = async (values) => {
    await updateMarktplaatsFeatures(Object.values(values.marktplaatsFeatures))
    return getMarktplaats({
      vestiging_id: normalizedSelectedLocationId,
      lines: itemsPerPage,
      page,
    })
  }

  useEffect(() => {
    if (filteredMpData.length) {
      sortAndSetFilterData(filteredMpData)
    }
    // Otherwise the auto-fix-eslint feature adds sortAndSetFilterData and filteredMpData,
    // and the effect should not be trigger on those, because that creates an
    // infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, orderDirection])

  /**
   * Extract all possible features a user can filter on:
   */
  const marktplaatsRowsFromApi =
    marktplaatsData && marktplaatsData.data && marktplaatsData.data.rows
      ? marktplaatsData.data.rows
      : []
  const marktplaatsTariffsFromApi =
    marktplaatsData && marktplaatsData.data && marktplaatsData.data.tarieven
      ? marktplaatsData.data.tarieven
      : []
  useEffect(() => {
    // sorting only possible when data is not delivered in paginated form:
    if (marktplaatsRowsFromApi.length) {
      sortAndSetFilterData(marktplaatsRowsFromApi)
    }

    // Otherwise the auto-fix-eslint feature adds sortAndSetFilterData,
    // which blocks sorting:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marktplaatsRowsFromApi, marktplaatsTariffsFromApi])

  useEffect(() => {
    getMarktplaats({
      vestiging_id: normalizedSelectedLocationId,
      lines: itemsPerPage,
      page,
    })
  }, [getMarktplaats, page, normalizedSelectedLocationId])

  const parsedJobsRunning = parseInt(
    marktplaatsBulkActionStatus?.data?.status?.split('/')[0],
  )
  const bulkJobsRunning = marktplaatsBulkActionStatus?.data?.running
    ? parsedJobsRunning && isNaN(parsedJobsRunning)
      ? parsedJobsRunning
      : 1
    : 0

  // Poll the API for the status if there is a job running
  useEffect(() => {
    const interval = setInterval(() => {
      if (bulkJobsRunning > 0) {
        getMarktplaatsBulkActionStatus()
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [bulkJobsRunning, getMarktplaatsBulkActionStatus])

  if (!(marktplaatsData && marktplaatsData.data && marktplaatsData.data.rows)) {
    return ''
  }

  function constructMarktplaatsFeatures(rows) {
    const features = {}
    rows.forEach((row) => {
      features[row.id] = {
        ...row,
        minimaal_bod: row.minimaal_bod === null ? '' : row.minimaal_bod,
        herplaatsen: Boolean(row.herplaatsen),
        omhoogplaatsen: false,
      }
    })
    return features
  }

  function handleOrder(direction, by) {
    setOrderDirection(direction)
    setOrderBy(by)
  }

  const mappedLocations =
    locations &&
    locations.data &&
    locations.data.map((location) => ({
      ...location,
      label: location.label,
      value: location.id.length ? location.id : '0',
    }))

  const handleBulkActionDialogClose = () => {
    getMarktplaatsBulkActionStatus()
    setBulkActionsDialogOpen(false)
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          raiseAllPositions: false,
          marktplaatsFeatures: constructMarktplaatsFeatures(
            marktplaatsData.data.rows,
          ),
        }}
        onSubmit={async (values, { setValues }) => {
          const rows = Object.values(values.marktplaatsFeatures)
          const raisedItemsLength = rows.filter(
            (row) => row.omhoogplaatsen,
          ).length
          const shouldConfirm =
            raisedItemsLength === rows.length && selectedLocationId === '0'
          const confirmed =
            !shouldConfirm ||
            window.confirm(t('marktplaatsForm.confirmRaiseAll'))
          if (!confirmed) {
            return
          }
          setIsSubmitting(true)
          try {
            await persistMarktplaatsFeatures(values)
            setValues({
              raiseAllPositions: false,
              marktplaatsFeatures: constructMarktplaatsFeatures(
                marktplaatsData.data.rows,
              ),
            })
          } catch (e) {
            toast.error(t('problemsRefreshingData'))
          }
          setIsSubmitting(false)
        }}
        validationSchema={MarktplaatsFormSchema(t)}
      >
        {({ values, setValues, handleSubmit }) => (
          <>
            <OptionsBar
              leftAligned={[
                <SearchInput
                  key="search"
                  label="Filter op kenteken, merk of model"
                  onChange={(value) => {
                    setFilterValue(value)
                    const lowerCasedKeyword = value.toLowerCase()
                    const filteredResults = marktplaatsData.data.rows.filter(
                      (row) =>
                        String(row.merk)
                          .toLowerCase()
                          .includes(lowerCasedKeyword) ||
                        String(row.modelserie)
                          .toLowerCase()
                          .includes(lowerCasedKeyword) ||
                        String(row.kenteken)
                          .toLowerCase()
                          .includes(lowerCasedKeyword),
                    )

                    setFilteredMpData(filteredResults)
                  }}
                  value={filterValue}
                  onSubmit={() => {}}
                />,
                mappedLocations && (
                  <NewDropDownSelect
                    label={t('dealer')}
                    items={mappedLocations}
                    onChange={setSelectedLocationId}
                    value={selectedLocationId}
                    selectionRequired
                    filled
                  />
                ),
              ]}
              rightAligned={[
                <StyledOptionButton
                  key="bulk-actions"
                  text={t('marktplaatsForm.formLabels.bulkActions')}
                  level="option"
                  type="button"
                  // disabled={bulkJobsRunning > 0}
                  isLoading={bulkJobsRunning > 0}
                  onClick={() => {
                    setBulkActionsDialogOpen(true)
                  }}
                  data-test-e2e="button-bulk-actions"
                />,
                <LoadingButton
                  key="save-button"
                  text={t('save')}
                  level="cta"
                  type="submit"
                  onClick={() => {
                    handleSubmit()
                  }}
                  disabled={!formIsUpdated}
                  isLoading={isSubmitting}
                  data-test-e2e="button-submit-marktplaats-form"
                />,
              ]}
            />
            {bulkJobsRunning > 0 && (
              <StatusMessage type="ExplanationParagraph">
                {t('marktplaatsForm.bulkActionRunning')}
              </StatusMessage>
            )}
            <Form>
              <StyledEnhancedTable
                data-test-e2e="marktplaats-table"
                hasStickyHeader
                verticalCellBorders
                selectable={false}
                onOrder={handleOrder}
                orderBy={orderBy}
                orderDirection={orderDirection}
                columns={[
                  {
                    id: 'licensePlate',
                    orderId: 'kenteken',
                    label: t('marktplaatsForm.formLabels.licensePlate'),
                  },
                  {
                    id: 'brandAndModel',
                    orderId: 'merk',
                    label: t('marktplaatsForm.formLabels.brandAndModel'),
                  },
                  {
                    id: 'expiresIn',
                    orderId: 'expires_in',
                    label: t('marktplaatsForm.formLabels.expiresIn'),
                  },
                  {
                    id: 'raisePosition',
                    label: t('marktplaatsForm.formLabels.raisePosition'),
                  },
                  {
                    id: 'attentionSticker',
                    label: t('marktplaatsForm.formLabels.attentionSticker'),
                  },
                  {
                    id: 'dayTopper',
                    label: t('marktplaatsForm.formLabels.dayTopper'),
                  },
                  {
                    id: 'sevenDaysDayTopper',
                    label: t('marktplaatsForm.formLabels.sevenDaysDayTopper'),
                  },
                  {
                    id: 'rePost',
                    label: t('marktplaatsForm.formLabels.rePost'),
                  },
                  {
                    id: 'eyeCatcher',
                    label: t('marktplaatsForm.formLabels.eyeCatcher'),
                  },
                  {
                    id: 'priceType',
                    label: t('marktplaatsForm.formLabels.priceType'),
                  },
                ]}
                noDataMessage={t('marktplaatsForm.noCarsAddedForThisLocation')}
                // the row data is dependent on the marktplaatsFeatures object
                // and the marktplaatsFeatures are filled based on the data.
                // so when there are no row no features are set too.
                rows={
                  Object.keys(values.marktplaatsFeatures).length > 0
                    ? filteredMpData.map((row) => ({
                        _attrs: {
                          'data-test-e2e': 'marktplaats-table-row',
                          'data-test-e2e-car-id': row.auto_id,
                          'data-test-e2e-row-id': row.id,
                        },
                        licensePlate: {
                          component: (
                            <Link to={`/car/${row.auto_id}`}>
                              <MinimalCarInfo
                                licensePlate={row.kenteken}
                                image={row.image}
                                imageAlt="Afbeelding van auto"
                              />
                            </Link>
                          ),
                          data: row.kenteken,
                        },
                        brandAndModel: {
                          component: (
                            <Link to={`/car/${row.auto_id}`}>
                              <div
                                style={{
                                  maxWidth: '110px',
                                }}
                              >
                                <CarBrandAndModel
                                  carBrand={row.merk}
                                  carModel={row.modelserie}
                                />
                                <TinyStatsList
                                  stats={[
                                    {
                                      label: t('views'),
                                      icon: 'views',
                                      value: Number(row.views),
                                    },
                                    {
                                      label: t('bekeken'),
                                      icon: 'published',
                                      value: Number(row.keerbekeken),
                                    },
                                  ]}
                                />
                              </div>
                            </Link>
                          ),
                          data: row.merk,
                        },
                        expiresIn: {
                          component: `${row.expires_in} ${t('unitDaysAbbreviated')}`,
                          data: row.expires_in,
                        },
                        raisePosition: {
                          component: (
                            <Field
                              onChange={() => setFormIsUpdated(true)}
                              name={`marktplaatsFeatures[${row.id}].omhoogplaatsen`}
                              component={FormikLabeledCheckBox}
                              /* explicitly passing value to percent field from changing
                        from uncontrolled to controlled */
                              value={
                                (values.marktplaatsFeatures[row.id] &&
                                  values.marktplaatsFeatures[row.id]
                                    .omhoogplaatsen) ||
                                false
                              }
                            />
                          ),
                          data: '',
                        },
                        attentionSticker: {
                          component: (
                            <FieldCombo data-test-e2e="field-combo-attention-sticker">
                              <Field
                                onChange={() => setFormIsUpdated(true)}
                                name={`marktplaatsFeatures[${row.id}].has_opvalsticker`}
                                component={FormikLabeledCheckBox}
                                /* explicitly passing value to precent field from changing
                          from uncontrolled to controlled */
                                value={
                                  values.marktplaatsFeatures[row.id]
                                    .has_opvalsticker || false
                                }
                              />
                              <Field
                                onChange={() => setFormIsUpdated(true)}
                                name={`marktplaatsFeatures[${row.id}].opvaltekst`}
                                label={t(
                                  'marktplaatsForm.formLabels.attentionText',
                                )}
                                component={FormikTextInput}
                                /* explicitly passing value to precent field from changing
                          from uncontrolled to controlled */
                                value={
                                  values.marktplaatsFeatures[row.id]
                                    .attentionText || ''
                                }
                                disabled={
                                  !(
                                    values.marktplaatsFeatures[row.id] &&
                                    values.marktplaatsFeatures[row.id]
                                      .has_opvalsticker
                                  )
                                }
                                filled
                              />
                            </FieldCombo>
                          ),
                          data: row.has_opvalsticker,
                        },
                        dayTopper: {
                          component: (
                            <Field
                              onChange={() => setFormIsUpdated(true)}
                              name={`marktplaatsFeatures[${row.id}].has_dagtopper`}
                              component={FormikLabeledCheckBox}
                              /* explicitly passing value to precent field from changing
                        from uncontrolled to controlled */
                              value={
                                values.marktplaatsFeatures[row.id]
                                  .has_dagtopper || false
                              }
                            />
                          ),
                          data: row.has_dagtopper,
                        },
                        sevenDaysDayTopper: {
                          component: (
                            <Field
                              onChange={() => setFormIsUpdated(true)}
                              name={`marktplaatsFeatures[${row.id}].has_dagtopper7`}
                              component={FormikLabeledCheckBox}
                              /* explicitly passing value to precent field from changing
                        from uncontrolled to controlled */
                              value={
                                values.marktplaatsFeatures[row.id]
                                  .has_dagtopper7 || false
                              }
                            />
                          ),
                          data: row.has_dagtopper7,
                        },
                        rePost: {
                          component: (
                            <FieldCombo data-test-e2e="field-combo-re-post">
                              <Field
                                onChange={() => setFormIsUpdated(true)}
                                name={`marktplaatsFeatures[${row.id}].herplaatsen`}
                                component={FormikLabeledCheckBox}
                                /* explicitly passing value to precent field from changing
                          from uncontrolled to controlled */
                                value={
                                  values.marktplaatsFeatures[row.id]
                                    .herplaatsen || false
                                }
                              />
                              <Field
                                onChange={() => setFormIsUpdated(true)}
                                name={`marktplaatsFeatures[${row.id}].herplaatsen_aantal`}
                                label={t(
                                  'marktplaatsForm.formLabels.noOfTimes',
                                )}
                                items={[
                                  {
                                    label: t(
                                      'marktplaatsForm.noOfTimesValues.everyMonth',
                                    ),
                                    value: 0,
                                  },
                                  {
                                    label: t(
                                      'marktplaatsForm.noOfTimesValues.anotherSingleMonth',
                                    ),
                                    value: 1,
                                  },
                                  {
                                    label: t(
                                      'marktplaatsForm.noOfTimesValues.another2Months',
                                    ),
                                    value: 2,
                                  },
                                  {
                                    label: t(
                                      'marktplaatsForm.noOfTimesValues.another3Months',
                                    ),
                                    value: 3,
                                  },
                                  {
                                    label: t(
                                      'marktplaatsForm.noOfTimesValues.another4Months',
                                    ),
                                    value: 4,
                                  },
                                ]}
                                component={FormikSelectInput}
                                /* explicitly passing value to precent field from changing
                          from uncontrolled to controlled */
                                value={
                                  values.marktplaatsFeatures[row.id]
                                    .herplaatsen_aantal || 0
                                }
                                disabled={
                                  !(
                                    values.marktplaatsFeatures[row.id] &&
                                    values.marktplaatsFeatures[row.id]
                                      .herplaatsen
                                  )
                                }
                                filled
                              />
                            </FieldCombo>
                          ),
                          data: `${row.herplaatsen} times: ${row.herplaatsen_aantal}`,
                        },
                        eyeCatcher: {
                          component: (
                            <Field
                              onChange={() => setFormIsUpdated(true)}
                              name={`marktplaatsFeatures[${row.id}].has_blikvanger`}
                              component={FormikLabeledCheckBox}
                              /* explicitly passing value to precent field from changing
                        from uncontrolled to controlled */
                              value={
                                values.marktplaatsFeatures[row.id]
                                  .has_blikvanger || false
                              }
                            />
                          ),
                          data: row.has_blikvanger,
                        },
                        priceType: {
                          component: (
                            <FieldComboVertical>
                              <Field
                                onChange={() => setFormIsUpdated(true)}
                                name={`marktplaatsFeatures[${row.id}].prijstype`}
                                label={t(
                                  'marktplaatsForm.formLabels.priceType',
                                )}
                                items={[
                                  {
                                    label: t(
                                      'marktplaatsForm.priceTypeValues.askingPrice',
                                    ),
                                    value: '0',
                                  },
                                  {
                                    label: t(
                                      'marktplaatsForm.priceTypeValues.reserved',
                                    ),
                                    value: '1',
                                  },
                                  {
                                    label: t(
                                      'marktplaatsForm.priceTypeValues.priceOffer',
                                    ),
                                    value: '2',
                                  },
                                  {
                                    label: t(
                                      'marktplaatsForm.priceTypeValues.priceInAgreement',
                                    ),
                                    value: '3',
                                  },
                                  {
                                    label: t(
                                      'marktplaatsForm.priceTypeValues.priceOnRequest',
                                    ),
                                    value: '4',
                                  },
                                  {
                                    label: t(
                                      'marktplaatsForm.priceTypeValues.swapCars',
                                    ),
                                    value: '5',
                                  },
                                  {
                                    label: t(
                                      'marktplaatsForm.priceTypeValues.seeDescription',
                                    ),
                                    value: '6',
                                  },
                                  {
                                    label: t(
                                      'marktplaatsForm.priceTypeValues.priceOfferFrom',
                                    ),
                                    value: '8',
                                  },
                                ]}
                                component={FormikSelectInput}
                                /* explicitly passing value to precent field from changing
                        from uncontrolled to controlled */
                                value={
                                  values.marktplaatsFeatures[row.id]
                                    .prijstype || '0'
                                }
                                filled
                              />
                              {values.marktplaatsFeatures[row.id].prijstype ===
                                '8' && (
                                <Field
                                  onChange={() => setFormIsUpdated(true)}
                                  name={`marktplaatsFeatures[${row.id}].minimaal_bod`}
                                  id={`marktplaatsFeatures[${row.id}].minimaal_bod`}
                                  label={t(
                                    'marktplaatsForm.formLabels.offerFrom',
                                  )}
                                  component={FormikTextInput}
                                  type="number"
                                  unit="currency_euro"
                                  /* explicitly passing value to prevent field from changing
                            from uncontrolled to controlled */
                                  value={
                                    values.marktplaatsFeatures[row.id]
                                      .biedenvanaf || '0'
                                  }
                                  // data-test-e2e="minimum-offer"
                                  filled
                                />
                              )}
                            </FieldComboVertical>
                          ),
                          data: row.prijstype,
                        },
                      }))
                    : []
                }
              />
            </Form>
            {parseInt(marktplaatsData.data.total, 10) > 0 && (
              <>
                {parseInt(marktplaatsData.data.total, 10) > itemsPerPage ? (
                  <StyledPaging
                    activePage={page}
                    onChangePage={handleChangePage}
                    perPage={itemsPerPage}
                    total={Number(marktplaatsData.data.total)}
                  />
                ) : (
                  <Typography type="BodyParagraph">
                    {t('numberOfResults', {
                      count: parseInt(marktplaatsData.data.total, 10),
                    })}
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </Formik>
      {bulkActionsDialogOpen && (
        <MarktplaatsBulkActionsDialog
          preselectedLocationId={selectedLocationId}
          onClose={handleBulkActionDialogClose}
          open
        />
      )}
    </>
  )
}

MarktplaatsForm.propTypes = {
  getMarktplaats: PropTypes.func.isRequired,
  updateMarktplaatsFeatures: PropTypes.func.isRequired,
  getMarktplaatsBulkActionStatus: PropTypes.func.isRequired,
  marktplaatsData: PropTypes.object,
  getLocations: PropTypes.func.isRequired,
  locations: PropTypes.object,
  marktplaatsBulkActionStatus: PropTypes.object,
}

MarktplaatsForm.defaultProps = {
  marktplaatsData: null,
  locations: null,
  marktplaatsBulkActionStatus: null,
}

export default MarktplaatsForm
