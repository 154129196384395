import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import EnhancedTable, {
  TableDataText,
} from 'components/organisms/enhanced-table'
import TextLink from 'components/atoms/text-link'
import Label from 'components/atoms/label'
import { formatLicenseplate } from 'utilities/utils'

const StyledTableDataText = styled(TableDataText)`
  font-weight: 400;
`

const ActionsContainer = styled.div`
  display: inline-flex;
  position: relative;
  top: 2px;
  align-items: center;
`

const ContractsTable = ({ rows, ...restProps }) => {
  const { t } = useTranslation()

  const formattedRows = rows.map((row) => ({
    _attrs: {
      'data-test-e2e': `contract-row-${row.id}`,
    },
    licensePlate: {
      component: (
        <StyledTableDataText>
          {row.licensePlateLink ? (
            <TextLink
              to={row.licensePlateLink}
              text={formatLicenseplate(row.licensePlate)}
            />
          ) : (
            formatLicenseplate(row.licensePlate)
          )}
        </StyledTableDataText>
      ),
      data: '',
    },
    clientLastName: {
      component: row.clientLastName ? (
        <StyledTableDataText>{row.clientLastName}</StyledTableDataText>
      ) : (
        <></>
      ),
      data: '',
    },
    postalcode: {
      component: row.postalcode ? (
        <StyledTableDataText>{row.postalcode}</StyledTableDataText>
      ) : (
        <></>
      ),
      data: '',
    },
    package: {
      component: <StyledTableDataText>{row.package}</StyledTableDataText>,
      data: '',
    },
    startDate: {
      component: row.startDate ? (
        <StyledTableDataText>{row.startDate}</StyledTableDataText>
      ) : (
        <></>
      ),
      data: '',
    },
    endDate: {
      component: <StyledTableDataText>{row.endDate}</StyledTableDataText>,
      data: '',
    },
    duration: {
      component: <StyledTableDataText>{row.duration}</StyledTableDataText>,
      data: '',
    },
    status: {
      component: (
        <StyledTableDataText>
          <Label
            text={row.status.label}
            backgroundColor={row.status.color}
            size="small"
          />
        </StyledTableDataText>
      ),
      data: '',
    },
    actions: {
      component: <ActionsContainer>{row.actions}</ActionsContainer>,
      data: '',
    },
  }))

  return (
    <EnhancedTable
      data-test-e2e="contracts-table"
      selectable={false}
      alignCellContent="middle"
      columns={[
        {
          id: 'licensePlate',
          label: t('licensePlate'),
        },
        {
          id: 'clientLastName',
          label: t('carServicePlanContractsTable.clientLastName'),
        },
        {
          id: 'postalcode',
          label: t('postalcode'),
        },
        {
          id: 'package',
          label: t('carServicePlanContractsTable.package'),
        },
        {
          id: 'startDate',
          label: t('carServicePlanContractsTable.startDate'),
        },
        {
          id: 'endDate',
          label: t('carServicePlanContractsTable.endDate'),
        },
        {
          id: 'duration',
          label: t('carServicePlanContractsTable.contractTime'),
        },
        {
          id: 'status',
          label: t('carServicePlanContractsTable.status'),
        },
        {
          id: 'actions',
          label: t('carServicePlanContractsTable.actions'),
          alignRight: true,
        },
      ]}
      rows={formattedRows}
      {...restProps}
    />
  )
}

ContractsTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      licensePlate: PropTypes.string,
      licensePlateLink: PropTypes.string,
      clientLastName: PropTypes.string,
      postalcode: PropTypes.string,
      package: PropTypes.string.isRequired,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      duration: PropTypes.string.isRequired,
      status: PropTypes.shape({
        label: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      }).isRequired,
      actions: PropTypes.node.isRequired,
    }),
  ).isRequired,
}

ContractsTable.defaultProps = {}

export default ContractsTable
