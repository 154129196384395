import React, { useEffect, useRef } from 'react'

import styled from 'styled-components/macro'

import Camera from 'components/organisms/camera'
import { useLocation } from 'react-router-dom'

const Container = styled.div`
  height: 100%;
  position: relative;
`

export const CameraSearchParamsEnum = {
  CAR_ID: 'car_id',
  ASSETS_COUNT: 'assets_count',
  REFERRER_IMAGE_CATEGORY: 'referrer_image_category',
  REFERRER_URL: 'referrer_url',
}

function CameraView () {
  const { search } = useLocation()
  const containerRef = useRef(null)

  const searchParams = new URLSearchParams(search)

  const carId = searchParams.get(CameraSearchParamsEnum.CAR_ID)
  const assetsCount = searchParams.get(CameraSearchParamsEnum.ASSETS_COUNT)
  const referrerImageCategory = searchParams.get(CameraSearchParamsEnum.REFERRER_IMAGE_CATEGORY)
  const referrerUrl = searchParams.get(CameraSearchParamsEnum.REFERRER_URL)

  // Setting the dimensions of the container with JavaScript here to overcome the
  // iOS full height issue:
  // https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d
  useEffect(() => {
    function handleResize () {
      containerRef.current.style.height = `${window.innerHeight}px`
      containerRef.current.style.width = `${window.innerWidth}px`
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Container ref={containerRef}>
      <Camera
        carId={carId}
        existingAssetsCount={Number(assetsCount)}
        referrerImageCategory={referrerImageCategory}
        referrerUrl={referrerUrl}
      />
    </Container>
  )
}

export default CameraView
