import { useMutation, useQuery } from '@apollo/client'
import Button from 'components/atoms/button'
import LoadingButton from 'components/atoms/loading-button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import TextLink from 'components/atoms/text-link'
import InlinedItems from 'components/layouts/inlined-items'
import {
  FormikLabeledCheckBox,
  FormikTextInput,
} from 'components/molecules/formik-field'
import Typography from 'components/molecules/typography'
import EnhancedTable, {
  TableDataText,
} from 'components/organisms/enhanced-table'
import { cspPackageTypes } from 'config/data'
import { CSP_FOR_DEALER, UPDATE_CSP, UPDATE_PACKAGE } from 'config/graphql/csp'
import { CSP_DEMO_SALES_FLOW } from 'config/routes'
import { CSPGeneralSchema } from 'config/validation-schemas'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import toast from 'utilities/toast'
import CarServicesOverlay from './car-services-overlay'

const PriceSettingsContainer = styled.section`
  display: flex;
  align-items: flex-start;
`

const PriceInputContainer = styled.div`
  flex: 1;
`

const StyledInlinedItems = styled(InlinedItems)`
  margin-bottom: 0;
`

const ActionsContainer = styled.div`
  flex-basis: 30%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const ActionsLink = styled(TextLink)`
  margin-right: ${({ theme }) => theme.sizings.lvl3};
  line-height: 38px;
`

const StyledSection = styled.section`
  position: relative;
  margin: ${({ theme }) => theme.sizings.lvl4} 0;
`

const PositionedButton = styled(Button)`
  position: absolute;
  top: -${({ theme }) => theme.sizings.lvl1};
  right: 0;
`

const StyledEnhancedTable = styled(EnhancedTable)`
  * {
    vertical-align: middle !important;
  }
`

const CommercialNameCell = styled(TableDataText)`
  > * {
    display: inline-block;

    input {
      font-weight: 400;
    }
  }
`

const CommercialNameField = styled(Field)`
  min-width: 300px;
  margin-left: ${({ theme }) => theme.sizings.lvl1};
`

// Note. This might look confusing at first. The backend gives us back a packageType (e.g. LOYAL),
// But the name doesn't match, so we map it manually.
// The backend can't change the enums / names, that's why we have to do it manually in the frontend.
export const mapCspPackageTypeToCspPackageName = (packageType) => {
  switch (packageType) {
    case cspPackageTypes.LOYAL: {
      return 'Basic'
    }
    case cspPackageTypes.BASIC: {
      return 'Plus'
    }
    case cspPackageTypes.PLUS_24_MONTHS: {
      return 'Plus 24'
    }
    default: {
      console.warn('CSP package type not found. Showing empty string.')
      return ''
    }
  }
}

function CarServicePlanMainSettings({ dealerId, carServicePlanId }) {
  const history = useHistory()
  const { pathname } = useLocation()
  const trailingPathPattern = /edit-car-services\/\d+\/$/g

  const { t } = useTranslation()
  const { loading, error, data } = useQuery(CSP_FOR_DEALER, {
    variables: {
      id: dealerId,
    },
  })

  const [persistUpdatedPackage, { loading: updatePackageLoading }] =
    useMutation(UPDATE_PACKAGE)
  const [persistUpdatedCSP, { loading: updateCspLoading }] =
    useMutation(UPDATE_CSP)

  function onSubmitUpdates(values) {
    const { carServicePlan, servicePackages } = values
    const updates = []
    if (servicePackages.length) {
      servicePackages.forEach((servicePackage) => {
        const { isEnabled, ...restServicePackage } = servicePackage
        const packageUpdate = persistUpdatedPackage({
          variables: {
            ...restServicePackage,
            disabled: !isEnabled,
          },
        })
        updates.push(packageUpdate)
      })
    }

    if (carServicePlan) {
      const cspUpdate = persistUpdatedCSP({
        variables: {
          ...carServicePlan,
          mechanicCarRate: parseFloat(carServicePlan.mechanicCarRate),
        },
      })
      updates.push(cspUpdate)
    }

    Promise.all(updates)
      .then(() => {
        toast.success(t('formFeedback.settingsSaved'))
      })
      .catch(() => {
        toast.error(t('formFeedback.settingsSaveError'))
      })
  }

  if (loading) {
    return <LoadingIndicator error={null} />
  }
  if (error) {
    return (
      <>
        <Typography type="Level1Heading">
          {t('carServicePlanGeneralTab.heading')}
        </Typography>
        <Typography type="Level2Heading">
          {t('problemsFoundHeading')}
        </Typography>
        <Typography type="ExplanationParagraph">
          {t('problemsWhenRetrievingData')}
        </Typography>
      </>
    )
  }

  // The backend arranges the order, and should be ["LOYAL", "BASIC", "PLUS_24_MONTHS"],
  // in the frontend these values are mapped to the labels ["Basic", "Plus", "Plus 24"]
  // TODO BE: remove the "PLUS_36_MONTHS" package
  const servicePackages = data.dealer.carServicePlan.packages
    .filter(
      (cspPackage) => cspPackage.packageType !== cspPackageTypes.PLUS_36_MONTHS,
    )
    .map((cspPackage) => ({
      ...cspPackage,
      id: cspPackage.id,
      description: cspPackage.description || '',
      isEnabled:
        cspPackage.packageType === cspPackageTypes.LOYAL ||
        !cspPackage.disabled,
    }))

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          carServicePlan: {
            id: data.dealer.carServicePlan.id,
            mechanicCarRate: data.dealer.carServicePlan.mechanicCarRate,
            isDisabled: data.dealer.carServicePlan.isDisabled,
          },
          servicePackages,
        }}
        validationSchema={CSPGeneralSchema(t)}
        onSubmit={onSubmitUpdates}
      >
        {({ values, setValues }) => (
          <Form>
            <PriceSettingsContainer>
              <PriceInputContainer>
                <Typography type="Level2Heading">
                  {t('carServicePlanGeneralTab.priceSubheading')}
                </Typography>
                <Typography type="ExplanationParagraph">
                  {t('carServicePlanGeneralTab.priceDescription')}
                </Typography>
                <StyledInlinedItems>
                  <Field
                    name="carServicePlan.mechanicCarRate"
                    label={t('carServicePlanGeneralTab.priceFieldLabel')}
                    component={FormikTextInput}
                    unit="currency_euro"
                    type="number"
                    filled
                  />
                </StyledInlinedItems>
              </PriceInputContainer>
              <ActionsContainer>
                <ActionsLink
                  text={t('CarServicePlanGeneralTab.makeTestCalculation')}
                  to={`${CSP_DEMO_SALES_FLOW}?dealerId=${dealerId}`}
                />
                <LoadingButton
                  isLoading={updatePackageLoading || updateCspLoading}
                  type="submit"
                  level="cta"
                  text={t('save')}
                  inline
                  filled
                  onClick={() => {
                    setValues(values)
                  }}
                />
              </ActionsContainer>
            </PriceSettingsContainer>
            <StyledSection>
              <Typography type="Level2Heading">
                {t('carServicePlanGeneralTab.plansHeading')}
              </Typography>
              <PositionedButton
                type="button"
                level="option"
                icon="edit"
                text={t('CarServicePlanGeneralTab.editCarServices')}
                to={`${pathname}${pathname.endsWith('/') ? '' : '/'}edit-car-services/${carServicePlanId}/`}
              />
              <StyledEnhancedTable
                selectable={false}
                columns={[
                  {
                    id: 'isEnabled',
                    label: t(
                      'carServicePlanGeneralTab.plansTableHeadings.isEnabled',
                    ),
                    width: '120px',
                  },
                  {
                    id: 'packageType',
                    label: t(
                      'carServicePlanGeneralTab.plansTableHeadings.packageType',
                    ),
                  },
                  {
                    id: 'commercialName',
                    label: t(
                      'carServicePlanGeneralTab.plansTableHeadings.commercialName',
                    ),
                  },
                ]}
                rows={servicePackages.map((cspPackage, cspPackageIndex) => ({
                  isEnabled: {
                    component: (
                      <Field
                        name={`servicePackages[${cspPackageIndex}].isEnabled`}
                        component={FormikLabeledCheckBox}
                        checked={cspPackage.isEnabled}
                        disabled={
                          cspPackage.packageType === cspPackageTypes.LOYAL ||
                          cspPackage.packageType === cspPackageTypes.BASIC
                        }
                      />
                    ),
                  },
                  packageType: {
                    component: mapCspPackageTypeToCspPackageName(
                      cspPackage.packageType,
                    ),
                  },
                  commercialName: {
                    component: (
                      <CommercialNameCell>
                        {t('itAsPrefix')}
                        <CommercialNameField
                          name={`servicePackages[${cspPackageIndex}].description`}
                          label={t(
                            'CarServicePlanGeneralTab.plansTableCommercialName',
                          )}
                          component={FormikTextInput}
                          filled
                        />
                      </CommercialNameCell>
                    ),
                  },
                }))}
              />
            </StyledSection>
            <StyledSection>
              <Typography type="Level2Heading">
                {t('carServicePlanGeneralTab.providersHeading')}
              </Typography>
              <EnhancedTable
                selectable={false}
                columns={[
                  {
                    id: 'guaranteeProvider',
                    label: t(
                      'carServicePlanGeneralTab.tableHeadings.guaranteeProvider',
                    ),
                  },
                  {
                    id: 'programName',
                    label: t(
                      'carServicePlanGeneralTab.tableHeadings.programName',
                    ),
                  },
                  {
                    id: 'documentation',
                    label: t(
                      'carServicePlanGeneralTab.tableHeadings.documentation',
                    ),
                    alignRight: true,
                  },
                ]}
                rows={
                  Array.isArray(data.dealer.carServicePlan.usedWarrantyPrograms)
                    ? data.dealer.carServicePlan.usedWarrantyPrograms.map(
                        (item) => ({
                          guaranteeProvider: {
                            component:
                              item.warrantyProgram.warrantyProvider.name,
                          },
                          programName: {
                            component:
                              item.warrantyProgram.name ||
                              t('warrantyProgramNameUnknown'),
                          },
                          documentation: {
                            component: item.warrantyProgram
                              .documentationFullUri ? (
                              <TextLink
                                text={t('download')}
                                href={item.warrantyProgram.documentationFullUri}
                              />
                            ) : null,
                          },
                        }),
                      )
                    : []
                }
              />
            </StyledSection>
          </Form>
        )}
      </Formik>
      {Array.isArray(pathname.match(trailingPathPattern)) &&
        pathname.match(trailingPathPattern).length > 0 && (
          <CarServicesOverlay
            onClose={() =>
              history.push(pathname.replace(trailingPathPattern, ''))
            }
            // quite hacky, but there is just no time to do it properly:
            carServicePlanId={carServicePlanId}
            invoiceFee={data.dealer.carServicePlan.invoiceFee}
          />
        )}
    </>
  )
}

CarServicePlanMainSettings.propTypes = {
  dealerId: PropTypes.string.isRequired,
  carServicePlanId: PropTypes.string.isRequired,
}

CarServicePlanMainSettings.defaultProps = {}

export default CarServicePlanMainSettings
