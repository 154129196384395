import PropTypes from 'prop-types'
import React from 'react'

import useDealerLocationCountryCode from 'hooks/use-location-country-code'

import CarFileIdTag from 'components/atoms/carfile-id-tag'
import LicensePlate from 'components/atoms/license-plate'
import { CarIdentityPlateSizes } from 'config/enums'

// Requirements from UCC
// - When there's a license plate and the location country is 'nl', show the licence plate
// - When there's a license plate and the location country is not 'nl', don't show the license plate
// - When there's no license plate and the location country is 'nl' or not 'nl', but there is a VIN, show the last six characters of the VIN in the license plate field
// - When there's no license plate and the location country is 'nl' or not 'nl', but there is NO VIN, show the carFile ID

const CarIdentityPlate = ({
  className,
  carFileId,
  licensePlate,
  vin,
  size,
}) => {
  const { locationCountryCodeIsNl } = useDealerLocationCountryCode()

  // Check if this carfile has a license plate or a vin number
  // If this is 'true', show the formatted license plate or the vin number in the styled license plate field.
  const hasLicensePlate = !!licensePlate === true
  const hasVinNumber = !!vin === true

  // Logic for when the location is 'nl'
  if (locationCountryCodeIsNl) {
    if (hasLicensePlate || hasVinNumber) {
      const licensePlateValueRaw = licensePlate || vin
      const licensePlateValue = licensePlateValueRaw
        .replace(/[^a-z0-9]/gi, '')
        .slice(-6)

      return (
        <LicensePlate
          className={className}
          formatLicensePlate={hasLicensePlate}
          value={licensePlateValue}
          size={size}
        />
      )
    }
    // Logic for when the location is not 'nl'
    // If there is a VIN number, dispay it in the license plate field
  } else if (hasVinNumber) {
    return (
      <LicensePlate
        className={className}
        formatLicensePlate={false}
        value={vin.substr(vin.length - 6)}
        size={size}
      />
    )
  }

  // Always fallback to showing the car file ID tag
  return (
    <CarFileIdTag className={className} carFileId={carFileId} size={size} />
  )
}

CarIdentityPlate.propTypes = {
  carFileId: PropTypes.string.isRequired,
  className: PropTypes.string,
  licensePlate: PropTypes.string,
  vin: PropTypes.string,
  size: PropTypes.oneOf(Object.values(CarIdentityPlateSizes)), // For "components/atoms/license-plate"
}

CarIdentityPlate.defaultProps = {
  className: null,
  licensePlate: null,
  vin: null,
  size: CarIdentityPlateSizes.SMALL,
}

export default CarIdentityPlate
