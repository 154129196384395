import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const MainWrapper = styled.article`
  position: relative;
  width: 100%;
  height: 100%;
`

// positions the scrollbars fully in view:
const CrossLinkageOverview = styled.section`
  position: relative;
  left: ${({ rowHeaderWidth }) => rowHeaderWidth}px;
  top: ${({ columnHeaderHeight }) => columnHeaderHeight}px;
  height: calc(100% - ${({ columnHeaderHeight }) => columnHeaderHeight}px);
  max-width: calc(100% - ${({ rowHeaderWidth }) => rowHeaderWidth}px);
  overflow: auto;

  > * {
    height: ${() => 92}px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
  }
`

const ControlsSection = styled.div`
  height: ${({ columnHeaderHeight }) => columnHeaderHeight}px;
  width: ${({ rowHeaderWidth }) => rowHeaderWidth}px;
  padding: 0 ${({ theme }) => theme.sizings.lvl1}
    ${({ theme }) => theme.sizings.lvl1} 0;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
  border-right: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
`

const RowHeadings = styled.header`
  height: calc(100% - ${({ columnHeaderHeight }) => columnHeaderHeight}px);
  width: ${({ rowHeaderWidth }) => rowHeaderWidth}px;
  position: absolute;
  left: 0;
  top: ${({ columnHeaderHeight }) => columnHeaderHeight}px;
  overflow: hidden;
  z-index: 2;
  border-right: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};

  > div {
    height: 100%;
    width: 100%;
    position: relative;
    transform: translateY(-${({ scrollOffSet }) => scrollOffSet}px);
  }
`

const RowHeading = styled.div`
  height: calc(${({ rowHeight }) => rowHeight}px);
  padding: ${({ theme }) => theme.sizings.lvl1};
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
  margin-right: ${({ theme }) => theme.sizings.lvl1};

  &:last-child {
    border-bottom: 0 none;
  }
`

const ColumnHeadings = styled.header`
  height: ${({ columnHeaderHeight }) => columnHeaderHeight}px;
  width: calc(100% - ${({ rowHeaderWidth }) => rowHeaderWidth}px);
  position: absolute;
  left: ${({ rowHeaderWidth }) => rowHeaderWidth}px;
  top: 0;
  overflow: hidden;
  z-index: 2;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};

  > div {
    height: 100%;
    width: 100%;
    position: relative;
    transform: translateX(-${({ scrollOffSet }) => scrollOffSet}px);
    display: flex;
    flex-direction: row;
  }
`

const ColumnHeading = styled.div`
  height:${({ rowHeight }) => rowHeight}px;
  flex: 0 0 ${({ columnWidth }) => columnWidth}px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.sizings.lvl1} 0;
  border-right: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};


  > * {
    inline-block;
    vertical-align: middle;
  }

  &:last-child {
    > * {
      border-right: 0 none;
    }
  }
`

const TableCell = styled.div`
  height:${({ rowHeight }) => rowHeight}px;
  width: ${({ columnWidth }) => columnWidth}px;
  flex: 0 0 ${({ columnWidth }) => columnWidth}px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;

  > * {
    inline-block;
    vertical-align: middle;
  }
`

/**
 * A table in which there are two sets of entities. Displayed along the top and one along the side.
 * This way the user can see a thing in one set is linked to an entity in the other set.
 */
function CrossLinkedDataTable({
  controls,
  columnHeadings,
  rowHeadings,
  rowCells,
  rowHeight,
  columnWidth,
  rowHeaderWidth,
  columnHeaderHeight,
}) {
  const [scrollTop, setScrollTop] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  function trackScrolling(event) {
    setScrollLeft(event.nativeEvent.target.scrollLeft)
    setScrollTop(event.nativeEvent.target.scrollTop)
  }

  return (
    <MainWrapper>
      <CrossLinkageOverview
        onScroll={trackScrolling}
        rowHeaderWidth={rowHeaderWidth}
        columnHeaderHeight={columnHeaderHeight}
      >
        {rowCells.map((row, index) => (
          <div
            key={`row-${index.toString()}`}
            style={{ display: 'flex', flexDirection: 'row' }}
            data-test-e2e="cross-linkage-table-row"
          >
            {row.map((tableCell, colIndex) => (
              <TableCell
                key={`row-cell-${index.toString()}-${colIndex.toString()}`}
                rowHeight={rowHeight}
                columnWidth={columnWidth}
                data-test-e2e="cross-linkage-table-cell"
              >
                {tableCell}
              </TableCell>
            ))}
          </div>
        ))}
      </CrossLinkageOverview>
      <ControlsSection
        rowHeaderWidth={rowHeaderWidth}
        columnHeaderHeight={columnHeaderHeight}
      >
        {controls}
      </ControlsSection>
      <ColumnHeadings
        scrollOffSet={scrollLeft}
        rowHeaderWidth={rowHeaderWidth}
        columnHeaderHeight={columnHeaderHeight}
      >
        <div>
          {columnHeadings.map((columnHeading, index) => (
            <ColumnHeading
              key={`colHeading-${index.toString()}`}
              columnWidth={columnWidth}
            >
              {columnHeading}
            </ColumnHeading>
          ))}
        </div>
      </ColumnHeadings>
      <RowHeadings
        scrollOffSet={scrollTop}
        rowHeaderWidth={rowHeaderWidth}
        columnHeaderHeight={columnHeaderHeight}
      >
        <div>
          {rowHeadings.map((rowHeading, index) => (
            <RowHeading
              key={`rowHeading-${index.toString()}`}
              rowHeaderWidth={rowHeaderWidth}
              rowHeight={rowHeight}
              data-test-e2e="row-heading"
            >
              {rowHeading}
            </RowHeading>
          ))}
        </div>
      </RowHeadings>
    </MainWrapper>
  )
}

CrossLinkedDataTable.propTypes = {
  controls: PropTypes.element,
  rowCells: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.element)),
  columnHeadings: PropTypes.arrayOf(PropTypes.element),
  rowHeadings: PropTypes.arrayOf(PropTypes.element),
  rowHeight: PropTypes.number,
  columnWidth: PropTypes.number,
  rowHeaderWidth: PropTypes.number,
  columnHeaderHeight: PropTypes.number,
}

CrossLinkedDataTable.defaultProps = {
  controls: '',
  rowCells: [['']],
  columnHeadings: [''],
  rowHeadings: [''],
  rowHeight: 100,
  columnWidth: 70,
  rowHeaderWidth: 200,
  columnHeaderHeight: 100,
}

export default CrossLinkedDataTable
