import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import ToolTip from 'components/atoms/tooltip'
import Pill from 'components/atoms/pill'
import { useTranslation } from 'react-i18next'

const Container = styled.span`
  display: inline-block;
  margin-top: 8px;
`

/**
 * NAP tag should be attached to a car file indicating if the NAP
 * (Nationale Auto Pas) has the same KM count registered as
 * indicated in the by the user.
 * @param {Object} props
 */
function NapTag({ state, toolTipMessage, className }) {
  const { t } = useTranslation()

  let level = 'neutral'
  let text = t('NAP_unknown')
  if (state === true) {
    level = 'positive'
    text = t('NAP_logical')
  } else if (state === false) {
    level = 'negative'
    text = t('NAP_illogical')
  }

  return (
    <Container className={className}>
      {/*
        Tooltip opens to the left, so it is always fully visible
        within its parent's container.
      */}
      <ToolTip message={toolTipMessage} position="left">
        <Pill text={text} level={level} />
      </ToolTip>
    </Container>
  )
}

NapTag.propTypes = {
  state: PropTypes.bool,
  toolTipMessage: PropTypes.string,
  className: PropTypes.string,
}

NapTag.defaultProps = {
  state: null,
  toolTipMessage: null,
  className: null,
}

export default NapTag
