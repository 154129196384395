import { connect } from 'react-redux'
import { getLocationsDetails } from 'redux/actions/data'
import LocationsSettings from '../views/settings/locations-settings'

const mapStateToProps = ({ data }) => ({
  locationsDetails: data.locationsDetails,
})

const mapDispatchToProps = {
  getLocationsDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationsSettings)
