import React from 'react'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import { PropTypes } from 'prop-types'

/**
 * Lays out interactive options above or below some content.
 * All content is right aligned.
 */
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
  margin-top: ${({ theme }) => theme.sizings.lvl2};

  & > * {
    flex: 1 1 auto;
  }

  ${media.tablet`
    flex-direction: row;
  `}
`

const LeftAlignedContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    flex: 0 0 auto;
    min-width: 7em;
    margin-right: ${({ theme }) => theme.sizings.lvl3};
  }
`

const RightAlignedContent = styled.div`
  display: flex;
  align-items: center;

  & > * {
    flex: 0 0 auto;
    margin-left: ${({ theme }) => theme.sizings.lvl3};
  }

  ${media.tablet`
    justify-content: flex-end;
  `}
`

function OptionsBar({ className, children, leftAligned, rightAligned }) {
  return (
    <Layout className={className}>
      {!!leftAligned.length && (
        <LeftAlignedContent>
          {leftAligned.map(
            (element, index) =>
              element && <div key={String(index)}>{element}</div>,
          )}
        </LeftAlignedContent>
      )}
      <RightAlignedContent>
        {children}
        {!!rightAligned.length &&
          rightAligned.map((element, index) => (
            <div key={String(index)}>{element}</div>
          ))}
      </RightAlignedContent>
    </Layout>
  )
}

OptionsBar.propTypes = {
  className: PropTypes.string,
  /**
   * regular children will be displayed as rightAligned content.
   */
  children: PropTypes.node,
  leftAligned: PropTypes.arrayOf(PropTypes.node),
  rightAligned: PropTypes.arrayOf(PropTypes.node),
}

OptionsBar.defaultProps = {
  className: '',
  children: null,
  leftAligned: [],
  rightAligned: [],
}

export default OptionsBar
