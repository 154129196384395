import { connect } from 'react-redux'
import { addCompanyLogo, getCompanyLogo } from 'redux/actions/settings'
import CompanyLogoForm from '../organisms/company-logo-form'

const mapStateToProps = ({ settings }) => ({
  companyLogo: settings.companyLogo,
})

const mapDispatchToProps = {
  addCompanyLogo,
  getCompanyLogo,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLogoForm)
