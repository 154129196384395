import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import TextInput from 'components/atoms/text-input'
import FilledTextInput from 'components/atoms/filled-text-input'
import TextLink from 'components/atoms/text-link'
import Button from 'components/atoms/button'
import Checkbox from 'components/atoms/checkbox'
import CheckboxFloatingLabel from 'components/atoms/checkbox-floating-label'
import PackageSelect from 'components/atoms/package-select'
import Text from 'components/atoms/text'
import SimpleTable from 'components/layouts/simple-table'
import FormTable from 'components/molecules/form-table'
import Textarea from 'components/atoms/textarea'
import DropdownMenuFloatingLabel from 'components/atoms/dropdown-menu-floating-label'
import OptionsSelect from 'components/atoms/options-select'
import ToolTip from 'components/atoms/tooltip'

const Container = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`

const ExtraLabeling = styled.div`
  padding: 0 0 0 ${({ theme }) => theme.sizings.lvl1};
  line-height: ${({ theme }) => theme.sizings.lvl3};
  display: grid;
  grid-template-columns: 1fr ${({ theme }) => theme.sizings.lvl2};
  grid-gap: ${({ theme }) => theme.sizings.lvl1};
`

const ExplanationIcon = styled.i`
  display: inline-block;
  color: ${({ theme }) => theme.colors.darkOnLightBorder};
  height: ${({ theme }) => theme.sizings.lvl2};
  width: ${({ theme }) => theme.sizings.lvl2};
  line-height: ${({ theme }) => theme.sizings.lvl2};
  border: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
  border-radius: 50%;
  text-align: center;
  font-size: 0.8em;

  &::before {
    content: 'i';
  }
`

const FieldLink = styled(TextLink)`
  margin-top: ${({ theme }) => theme.sizings.lvl1};
`
/**
 * @deprecated @use [FormField](/#/molecules/FormField) instead.
 * TODO: check where it is used and remove from code-base if possible.
 */
function ComplexField({
  className,
  defaultValues,
  errors,
  explanation,
  field,
  fieldValueIndex,
  handleChange,
  handleClickOverlay,
  hint,
  openOverlay,
  values,
}) {
  const { t } = useTranslation()

  function handleButtonClick(fld) {
    if (fld.overlay) {
      openOverlay(fld.overlay.type, fld.overlay.params)
    }
  }

  return (
    <Container className={className} hidden={field.type === 'hidden'}>
      {(hint || explanation) && (
        <ExtraLabeling>
          <Text type="hint">{hint}</Text>
          {explanation && (
            <ToolTip message={explanation}>
              <ExplanationIcon />
            </ToolTip>
          )}
        </ExtraLabeling>
      )}
      {(() => {
        switch (field.type) {
          case 'button':
            return (
              <Button
                key={fieldValueIndex.toString()}
                onClick={() => handleButtonClick(field)}
                text={field.title}
              />
            )
          case 'checkbox':
            if (field.variety === 'floatingLabel') {
              return (
                <CheckboxFloatingLabel
                  checked={values[fieldValueIndex]}
                  key={fieldValueIndex.toString()}
                  name={field.name}
                  onChange={(checked) =>
                    handleChange(fieldValueIndex, checked, field.name)
                  }
                  title={field.title}
                />
              )
            }
            return (
              <Checkbox
                checked={values[fieldValueIndex]}
                key={fieldValueIndex.toString()}
                name={field.name}
                onChange={(checked) =>
                  handleChange(fieldValueIndex, checked, field.name)
                }
                title={field.title}
              />
            )
          case 'options_select':
            return (
              <OptionsSelect
                key={fieldValueIndex.toString()}
                defaultValue={field.defaultValue}
                label={field.title}
                name={field.name}
                items={field.options}
                onChange={(selected) => handleChange(fieldValueIndex, selected)}
                placeholder={t('searchOrAddAccessory')}
                newIndicator
              />
            )
          case 'package_select':
            return (
              <PackageSelect
                checked={!!values[fieldValueIndex]}
                items={field.items}
                key={fieldValueIndex.toString()}
                name={field.name}
                onChange={(vals) => handleChange(fieldValueIndex, vals)}
                fieldValueIndex={fieldValueIndex}
                title={field.title}
              />
            )
          case 'select':
            return (
              <DropdownMenuFloatingLabel
                key={fieldValueIndex.toString()}
                initialValue={defaultValues[fieldValueIndex]}
                error={errors[fieldValueIndex]}
                label={field.title}
                name={field.name}
                items={field.options}
                onChange={(e) =>
                  handleChange(fieldValueIndex, e.target.value, field.name)
                }
                required={!!field.required}
                value={values[fieldValueIndex]}
              />
            )
          case 'table':
            return (
              <SimpleTable
                columns={field.data.columns.map((column, index) => (
                  <Text
                    key={`${index}`}
                    type="floatingLabelLabel"
                    text={column}
                  />
                ))}
                rows={field.data.rows.map((row, index) => [
                  ...row.map((rowItem, rowItemIndex) =>
                    typeof rowItem === 'string' ? (
                      <Text
                        key={`${index}-${rowItemIndex}`}
                        type="floatingLabelInput"
                        text={rowItem}
                      />
                    ) : (
                      <Text
                        key={`${index}-${rowItemIndex}`}
                        type="floatingLabelInput"
                        text={rowItem.value}
                      />
                    ),
                  ),
                ])}
                data={field.data}
                legend={field.legend}
              />
            )
          case 'formTable':
            return (
              <FormTable
                field={field}
                onChange={(vals) => handleChange(fieldValueIndex, vals)}
                values={values[fieldValueIndex]}
              />
            )
          case 'textarea':
            return (
              <Textarea
                {...field}
                key={fieldValueIndex.toString()}
                error={errors[fieldValueIndex]}
                label={field.title}
                onChange={(e) => handleChange(fieldValueIndex, e.target.value)}
                title={field.placeholder}
                value={values[fieldValueIndex]}
              />
            )
          case 'link':
            return (
              <TextLink
                key={fieldValueIndex.toString()}
                href={field.href}
                to={field.link}
                text={field.value}
              />
            )
          case 'info':
            return (
              <Text
                key={fieldValueIndex.toString()}
                hidden={field.type === 'hidden'}
                type="body"
                text={field.value}
              />
            )
          default:
            if (field.variety === 'floatingLabel') {
              return (
                <FilledTextInput
                  {...field}
                  key={fieldValueIndex.toString()}
                  error={errors[fieldValueIndex]}
                  onChange={(e) =>
                    handleChange(fieldValueIndex, e.target.value, field.name)
                  }
                  value={values[fieldValueIndex]}
                />
              )
            }
            return (
              <TextInput
                {...field}
                key={fieldValueIndex.toString()}
                error={errors[fieldValueIndex]}
                onChange={(e) => handleChange(fieldValueIndex, e.target.value)}
                value={values[fieldValueIndex]}
                unit={field.unit}
              />
            )
        }
      })()}
      {field.link && field.link.overlay && (
        <FieldLink
          onClick={() =>
            handleClickOverlay(field.link, fieldValueIndex, field.name)
          }
          text={t(field.link.label)}
        />
      )}
    </Container>
  )
}

ComplexField.propTypes = {
  defaultValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  errors: PropTypes.array.isRequired,
  explanation: PropTypes.string,
  field: PropTypes.object.isRequired,
  fieldValueIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  handleChange: PropTypes.func,
  handleClickOverlay: PropTypes.func,
  hint: PropTypes.string,
  openOverlay: PropTypes.func,
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  className: PropTypes.string,
}

ComplexField.defaultProps = {
  defaultValues: null,
  explanation: null,
  handleClickOverlay: null,
  hint: null,
  openOverlay: null,
  handleChange: () => {},
  className: '',
}

export default ComplexField
